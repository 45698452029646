import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import LauncIcon from '@material-ui/icons/Launch';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import { Parser as Json2csvParser } from 'json2csv';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, submit } from 'redux-form';
import { LICENSE_TYPES, MAX_GUEST_TAGS_LITE_LIMIT, PERMISSIONS, PERMISSION_ENTITIES, RED } from '../../../_config/consts';
import { isUserWithoutEmail } from '../../../_config/utils';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as TagsManagementActions from '../../../redux/actions/tagsManagement.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import MDButton from '../../MDButton/MDButton.jsx';
import Permission from '../../Permission/Permission.jsx';
import FormCardContainer from '../../forms/Elements/FormCardContainer.jsx';
import TagForm from '../../forms/TagForm.jsx';
import TagGuestsForm from '../../forms/TagGuestsForm.jsx';

@connect((state) => ({
  language: state.settings.language,
  tagsManagement: state.tagsManagement,
  userTagsNumber: state.tagsManagement.userTags.number,
}))
class GuestsTagsOperationalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isListLoading: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(TagsManagementActions.fetchGuestsSpecialTagsNumber());
  }

  async onSaveTag(values) {
    if (values.id) {
      this.onUpdateTag(values);
    } else {
      this.onCreateNewTag(values);
    }
  }

  async onUpdateTag(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(TagsManagementActions.updateGuestSpecialTag(values.id, values));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(TagsManagementActions.setSelectedTag(null));
      dispatch(TagsManagementActions.setOperationalMode(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'tagUpdated' }} /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onCreateNewTag(values) {
    const { dispatch, userTagsNumber } = this.props;
    const hasActiveIntegrations = dispatch(UtilsActions.hasIntegrationWithSynchronizationPlugin())
    const isCreateTagForbidden = !hasActiveIntegrations && AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    if (isCreateTagForbidden && userTagsNumber >= MAX_GUEST_TAGS_LITE_LIMIT) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="tooManyTagsForLicenseError" /> },
      }));
    } else {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      try {
  
        const updatedTag = await dispatch(TagsManagementActions.createGuestSpecialTag(values));
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(TagsManagementActions.setSelectedTag({ ...updatedTag, guestsNumber: 0 }));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'tagCreated' }} /></h6>),
          },
        }));
        dispatch(change('TagForm', 'id', updatedTag.id));
      } catch (error) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    }
  }

  onLoadMoreTagGuests(page) {
    const { dispatch, tagsManagement: { selectedTag } } = this.props;
    dispatch(TagsManagementActions.fetchAndAppendGuestsByTag(selectedTag.id, page));
  }

  async onRemoveGuest(guest) {
    const { dispatch, tagsManagement: { selectedTag } } = this.props;
    try {
      this.setState({ isListLoading: true });
      await dispatch(TagsManagementActions.removeGuestFromTag(selectedTag, guest));
      this.setState({ isListLoading: false });
    } catch (error) {
      this.setState({ isListLoading: false });
    }
  }

  async onConfirmAddGuest(guest) {
    const { dispatch, tagsManagement: { selectedTag } } = this.props;
    if (guest) {
      try {
        this.setState({ isListLoading: true });
        await dispatch(TagsManagementActions.addGuestToTag(selectedTag, guest));
        dispatch(change('TagGuestsForm', 'guestTagSelectedSearch', null));
        this.setState({ isListLoading: false });
      } catch (error) {
        this.setState({ isListLoading: false });
      }
    }
  }

  // TODO: REMOVE THIS, SHOULD BE DONE BACKEND SIDE
  async onDownloadGuestsCSV() {
    const { dispatch } = this.props;
    const fields = [
      {
        label: L20NContext.getSync('name'),
        value: 'firstname',
      }, {
        label: L20NContext.getSync('surname'),
        value: 'lastname',
      }, {
        label: L20NContext.getSync('email'),
        value: 'email',
      },
      {
        label: L20NContext.getSync('companyName'),
        value: 'companyName',
      }];
    const opts = { fields };
    const { tagsManagement: { selectedTag } } = this.props;
    try {
      const { guests, stopFetching } = await dispatch(TagsManagementActions.fetchAllGuestsByTag(selectedTag.id));
      if (!stopFetching) {
        const output = new Json2csvParser(opts).parse(guests);
        const blob = new Blob([output], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, `GuestsTag-${selectedTag.name || ''}-users.csv`);
      }
    } catch (error) {
      let errorMessage = 'unexpectedErrorMessage';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilsActions.hideProgressVisibleAndReset(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }

  async onDeleteGuestTag(tagId) {  
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(TagsManagementActions.deleteGuestTag(tagId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(TagsManagementActions.setSelectedTag(null));
      dispatch(TagsManagementActions.setOperationalMode(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'tagDeleted' }} /></h6>),
        },
      }));
    } catch (error) {
      let errorMessage = 'errorDeleteTag';
      if (error && error.response && error.response.data && error.response.data.code && error.response.data.code === 10305) errorMessage = 'errorDeleteTagUsedInCredentialRule';
      if (error && error.response && error.response.data && error.response.data.code && error.response.data.code === 10306) errorMessage = 'errorDeleteTagWithUsers';
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }

  onOpenGuestDetails(item) {
    window.open(`/guests?guestId=${item.id}`);
  }

  render() {
    const { dispatch, tagsManagement: { selectedTag, userTags: { guests: { content, pagination } }, userTagsCategories: { content: guestTagsCategories } } } = this.props;
    const { isListLoading } = this.state;
    const hasDeleteTagPermission = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.USER_TAG);
    const canSaveGuestTag = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE, PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER_TAG);
    const canEditSmartCredentials = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.CREDENTIAL_RULE);
    const canEditCardCredentials = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canUpdateGuests = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.USER);
    const canReadGuests = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const canModifyGuestInTag = canEditSmartCredentials && canUpdateGuests && canEditCardCredentials;
    const canDeleteTag = hasDeleteTagPermission && canEditSmartCredentials && canUpdateGuests && canEditCardCredentials;
    return (
      <div>
        <FormCardContainer title="insertTagDataTitle" subtitle="insertGuestTagDataSubtitle">
          <TagForm
            tagType="guest"
            isDisabled={!canSaveGuestTag}
            categories={guestTagsCategories}
            doAsyncValidate={selectedTag && _.isEmpty(selectedTag)}
            onSubmit={values => this.onSaveTag(values)}
          />
          {canSaveGuestTag ? (
            <MDButton
              title={<Entity entity="save" />}
              containerStyle={{ width: '100%', margin: 0, marginTop: 30 }}
              style={{ height: 40, borderRadius: 0 }}
              onClick={() => dispatch(submit('TagForm'))}
            />
          ) : null}
        </FormCardContainer>
        {selectedTag && selectedTag.id && canDeleteTag ? (
          <FormCardContainer title="deleteTagDataTitle" subtitle="deleteGuestTagDataSubtitle">
            <MDButton
              title={<Entity entity="delete" />}
              backgroundColor={RED}
              containerStyle={{ width: '100%', margin: 0, marginTop: 30 }}
              style={{ height: 40, borderRadius: 0 }}
              onClick={() => this.onDeleteGuestTag(selectedTag.id)}
            />
          </FormCardContainer>
        ) : null}
        <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.USER} abilityHelper={AbilityProvider.getAbilityHelper()}>
          <TagGuestsForm
            items={content}
            canAddGuests={selectedTag && selectedTag.id && canModifyGuestInTag}
            pagination={pagination}
            isListLoading={isListLoading}
            onFetchMoreItems={(page) => this.onLoadMoreTagGuests(page)}
            onAddGuestToTag={(guest) => this.onConfirmAddGuest(guest)}
            onDownloadListCSV={() => this.onDownloadGuestsCSV()}
            rowElement={(item, index) => (
              <div
                style={{ backgroundColor: index % 2 === 0 ? 'white' : 'ghostwhite', borderBottom: '1px solid', borderTop: '1px solid', alignItems: 'center', justifyContent: 'space-between', display: 'flex', padding: 5, borderColor: '#f0f0f0' }}
                key={item.id}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h5 style={{ color: '#3f3f3f', marginLeft: 10 }}>{`${item.firstname} ${item.lastname} ${isUserWithoutEmail(item) ? '': ` - ${item.email}`}`}</h5>
                  {canReadGuests ? <IconButton style={{ marginLeft: 10 }} onClick={() => this.onOpenGuestDetails(item)}><LauncIcon style={{ width: 20, color: '#90CAF9' }} /></IconButton> : null}
                </div>
                {canModifyGuestInTag ? (
                  <IconButton
                    style={{ color: RED }}
                    onClick={() => this.onRemoveGuest(item)}
                  >
                    <DeleteIcon style={{ fontSize: 20 }} />
                  </IconButton>
                ) : null}
              </div>
            )}
          />
        </Permission>
      </div>
    );
  }
} 

export default GuestsTagsOperationalSection;
