import { Icon, ControlLabel } from '@sketchpixy/rubix';
import Dropzone from 'react-dropzone';
import React from 'react';
import _ from 'lodash';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

export default class DropFileField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropzoneActive: false,
    };
  }

  onDrop(acceptedFiles, rejectedFiles, input) {
    const { onFileRejected, onFileAccepted } = this.props;
    if (_.size(rejectedFiles) && onFileRejected) {
      onFileRejected();
    } else {
      input.onChange(acceptedFiles);
      if (onFileAccepted) onFileAccepted(acceptedFiles);
      this.setState({ dropzoneActive: true });
    }
  }

  onDragEnter() {
    this.setState({ dropzoneActive: true });
  }

  onDragLeave() {
    this.setState({ dropzoneActive: false });
  }

  onDeleteFile(e, f) {
    const { onDeleteFile } = this.props;
    e.stopPropagation();
    if (onDeleteFile) onDeleteFile(f);
    this.setState({ dropzoneActive: false });
  }

  render() {
    const { style, input, disabled, meta, accept, children, multiple, maxSize, minSize, className, activeClassName, onFileRejected, onDeleteFile } = this.props;
    const { dropzoneActive } = this.state;
    return (
      <div>
        <Dropzone
          className={`${className} ${dropzoneActive && activeClassName}`}
          style={style}
          accept={accept}
          onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles, input)}
          disabled={disabled}
          multiple={multiple}
          maxSize={maxSize}
          minSize={minSize}
          onDragEnter={() => this.onDragEnter()}
          onDragLeave={() => this.onDragLeave()}
        >
          {input.value && _.isArray(input.value) ? _.map(input.value, (f, index) =>
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                onClick={e => this.onDeleteFile(e, f)}
                style={{ marginRight: '5px', color: 'red', fontSize: 25 }}
                glyph="icon-fontello-cancel-circled-1"
              />
              <h5>{f.name}</h5>
            </div>,
          ) : children}
          
        </Dropzone>
        {meta.touched && meta.error &&
          <ControlLabel style={{ color: 'deepred' }}>
            <h6 style={{ color: 'red', marginTop: 4 }}>
              <Entity key={meta.error} entity={meta.error} />
            </h6>
          </ControlLabel>
        }
      </div>
    );
  }
}
