// @ts-nocheck
import { Divider } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import { CUSTOM_FIELDS_FORM_TYPE, CUSTOM_FIELDS_TYPES, EAGLE_EYE_CUSTOM_FIELD_DTO, PERMISSION_ENTITIES } from '../../../_config/consts';
import { hasFormWritePermission } from '../../../_config/utils';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import CustomField from '../Fields/CustomField.jsx';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  } else if (_.trim(values.name).length > 255) {
    errors.name = 'maximumLenghtTwelve';
  }
  return errors;
};

@reduxForm({ form: 'LockCustomFieldsForm', validate })
@connect(state => ({ form: state.form.LockCustomFieldsForm, customFields: state.customFields.locks }))
class LockCustomFieldsForm extends React.Component {
  render() {
    const { customFields: { content: customFieldsData } } = this.props;
    const filteredCustomFields = _.filter(customFieldsData, customField => customField.type !== CUSTOM_FIELDS_TYPES.INTEGRATION_ID && customField.name !== EAGLE_EYE_CUSTOM_FIELD_DTO.name);
    return (
      <div style={{ marginLeft: 15, marginRight: 25 }}>
        <FormCardContainer title="locksData">
          <Entity
            componentClass={Field}
            name="name"
            componentAttribute="placeholder"
            entity="insertName"
            title={<Entity entity="lockName" />}
            component={CustomField}
            type="text"
            mandatory
          />
          {filteredCustomFields && !_.isEmpty(filteredCustomFields) ? (
            <div style={{ marginTop: 20 }}>
              <Divider />
              <h4 style={{ fontSize: 22, color: '#3f3f3f', fontWeight: 'bold', marginTop: 15 }}>{<Entity entity="customFields" />}</h4>
              {_.map(filteredCustomFields, customField => (
                <Entity
                  name={customField.keyValue}
                  componentClass={Field}
                  componentAttribute="placeholder"
                  entity="insertDescription"
                  type={customField.type && CUSTOM_FIELDS_FORM_TYPE[customField.type] ? CUSTOM_FIELDS_FORM_TYPE[customField.type] : 'text'}
                  title={customField.name}
                  component={CustomField}
                  disabled={!hasFormWritePermission(PERMISSION_ENTITIES.SMART_LOCK, true)}
                />
              ))}
            </div>
            ) : null}
        </FormCardContainer>
      </div>
    );
  }
} 

export default LockCustomFieldsForm;
