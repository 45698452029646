// @ts-nocheck
import { Divider, withStyles } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';


const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  titleText: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'left',
  },
  sectionText: {
    margin: 0,
    marginRight: 5,
    fontSize: 16,
    lineHeight: 1.4,
    color: '#3f3f3f',
    textAlign: 'left',
  },
  exampleContainer: {
    marginTop: 15,
    borderRadius: 5,
  },
  exampleText: {
    marginLeft: 12,
    marginTop: 10,
    fontSize: 15,
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

const RedeemEventRow = ({ event, isLast }) => (
  <div style={{ height: 50, marginBottom: isLast ? -5 : 0, marginLeft: 10 }}>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <h4 style={{ color: '#3f3f3f', marginRight: 10, fontWeight: 'bold' }}><Entity entity="codeRedeemed" /></h4>
      <h4 style={{ color: '#3f3f3f', marginRight: 10 }}>{'-'}</h4>
      <h4 style={{ color: '#3f3f3f' }}>{moment(event.createdAt).format('LLL')}</h4>
    </div>
    {!isLast ? <Divider /> : null}
  </div>
);

const InvitationRedeemEventsView = ({ eventsData, pagination, onAppendRedeemEvents }) => {
  return (
    <div style={{ backgroundColor: '#f0f0f0', padding: 10, borderRadius: 10 }}>
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => onAppendRedeemEvents(pagination.number + 1)}
        hasMore={pagination.number + 1 < pagination.totalPages}
        loader={<ListLoadingView />}
        useWindow={false}
      >
        <div>
          {!_.isEmpty(eventsData) ?
          _.map(eventsData, (event, key) =>
            <RedeemEventRow
              key={event.id}
              event={event}
              isLast={key === _.size(eventsData) - 1}
            />)
          : (
            <div style={{ display: 'flex', flexDirection: 'center', justifyContent: 'center' }}>
              <EmptyView
                title="noRedeemEventFound"
                subtitle="noRedeemEventFoundDescription"
                iconName="icon-simple-line-icons-layers"
                containerStyle={{ marginTop: 20, marginBottom: -20 }}
              />
            </div>
          )}
        </div>
      </InfiniteScroll>
      </div>
  );
};
export default withStyles(styles)(InvitationRedeemEventsView);
