import React, { Component } from 'react';
import _ from 'lodash';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import { FormControl } from '@sketchpixy/rubix';
import { CUSTOM_FIELDS_FORM_TYPE, CUSTOM_FIELDS_TYPES } from '../../../../_config/consts';
import CustomField from '../CustomField.jsx';
import { Field } from 'redux-form';

export default class CustomFieldsSearchInputField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedCustomField: undefined,
    };
  }

  onHandleChange(value, keyValue, type) {
    const { onCustomFieldFilterSearch, input } = this.props;
    if (input && input.onChange) {
      input.onChange(value);
    }
    setTimeout(() => {
      if (onCustomFieldFilterSearch) {
        onCustomFieldFilterSearch(value, keyValue, type);
      }
    }, 600);
  }

  onSelectCustomFieldType(customFieldKey) {
    const { customFields } = this.props;
    const selectedCustomField = _.find(customFields, field => field.keyValue === customFieldKey);
    this.setState({ selectedCustomField });
  }

  render() {
    const { input, containerstyle, showErrorLabel, meta, customFields } = this.props;
    const { selectedCustomField } = this.state;
    const customFieldsTypesOptions = [
      { label: L20NContext.getSync('selectCustomFieldType'), value: undefined },
      ..._.map(customFields, customField => (
        { label: customField.name, value: customField.keyValue }
      )),
    ];
    const hasError = meta && meta.touched && meta.error ? true : false;
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15, marginBottom: 15, ...containerstyle }}>
        <FormControl
          componentClass="select"
          className="form-control-select"
          style={{ width: 250 }}
          onChange={e => this.onSelectCustomFieldType(e.target.value)}
        >
          {_.map(customFieldsTypesOptions, field =>
            <option value={field.value}>
              {field.label}
            </option>,
          )}
        </FormControl>
        {selectedCustomField ? (
          <Entity
            key={selectedCustomField.keyValue}
            name={selectedCustomField.keyValue}
            componentClass={Field}
            componentAttribute="placeholder"
            entity="insertDescription"
            type={selectedCustomField.type && CUSTOM_FIELDS_FORM_TYPE[selectedCustomField.type] ? CUSTOM_FIELDS_FORM_TYPE[selectedCustomField.type] : 'text'}
            component={CustomField}
            onChange={selectedCustomField.type === CUSTOM_FIELDS_TYPES.DATE ? value => this.onHandleChange(value, selectedCustomField.keyValue, selectedCustomField.type) : null}
            onHandleChange={value => this.onHandleChange(value, selectedCustomField.keyValue, selectedCustomField.type)}
            containerStyle={{ marginLeft: 20, marginBottom: -15, marginRight: 10, marginTop: selectedCustomField.type === CUSTOM_FIELDS_TYPES.DATE ? -6 : -2, width: '40%' }}
          />
        ): null}
        {showErrorLabel && hasError && <h6 style={{ color: 'red', marginTop: 4, marginLeft: 10, marginBottom: 0 }}><Entity entity={meta.error} /></h6>}
      </div>
    );
  }
};

