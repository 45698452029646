
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isMobileBrowser } from '../_config/utils';
import AppHeader from './AppHeader.jsx';

const HeaderLayout = ({ children, match }) => (
  <div style={{ zoom: '80%', backgroundColor: 'white' }}>
    { /* TODO: workaround to make header update when location changes */ }
      <AppHeader match={match} />
      <div style={{ paddingLeft: isMobileBrowser() ? 10 : 250 }}>
        {children}
      </div>
  </div>
);

export default connect()(withRouter(HeaderLayout));
