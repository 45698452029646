import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as GatewaysActions from '../../../redux/actions/gateways.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import ListLoadingView from '../../ListLoadingView.jsx';
import EmptyView from '../../EmptyView.jsx';
import AtlasGatewayRow from './AtlasGatewayRow.jsx';

@connect((state) => ({ themeName: state.settings.items.theme.data.themeName }))
class AtlasGatewayListView extends React.Component {

  componentWillUnmount() {
    this.closeOperationalSection();
  }

  appendGateways(page) {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.fetchAndAppendAtlasGateways(page));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setOperationalMode(false));
  }

  render() {
    const { gateways: { content: gatewaysData, pagination }, viewLoading, selectedGateway, onSelectGateway } = this.props;
    return (
      <div className="list-view details-section" style={{ top: 140, paddingBottom: 40, width: '90%' }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => this.appendGateways(pagination.number + 1)}
          hasMore={pagination.number + 1 < pagination.totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '40%' }}>
            {!_.isEmpty(gatewaysData) ?
            _.map(gatewaysData, gateway =>
              <AtlasGatewayRow
                key={gateway.deviceId}
                atlas={gateway}
                isSelected={selectedGateway && selectedGateway.deviceId === gateway.deviceId}
                onClick={() => onSelectGateway(gateway)}
              />)
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title="noAtlasGatewayFound"
                    subtitle="noAtlasGatewayFoundDescription"
                    iconName="icon-simple-line-icons-layers"
                    newEntityTitle="discoverMore"
                  />) : <ListLoadingView />}
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
} 

export default AtlasGatewayListView;
