import { IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import InfoOutline from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { OFFLINE_TIME_UNIT, PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts';
import { hasFormWritePermission, localizeHelpCenterLink } from '../../../_config/utils.js';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import MDButton from '../../MDButton/MDButton.jsx';
import CustomField from '../Fields/CustomField.jsx';
import MDSwitchField from '../Fields/MDSwitch/MDSwitchField.jsx';
import TranslatableOption from '../Fields/TranslatableOption.jsx';


const validate = (values) => {
  const errors = {};

  if (values.availableOffline && values.availableOfflineMinutes <= 0) {
    errors.availableOfflineMinutes = 'required';
  }
  return errors;
};

const timeUnitsOptions = [
  {
    entity: OFFLINE_TIME_UNIT.minutes,
    value: OFFLINE_TIME_UNIT.minutes,
  },
  {
    entity: OFFLINE_TIME_UNIT.hours,
    value: OFFLINE_TIME_UNIT.hours,
  },
  {
    entity: OFFLINE_TIME_UNIT.days,
    value: OFFLINE_TIME_UNIT.days,
  },
];

@reduxForm({ form: 'LockOfflineAvailabilityForm', validate })
class LockOfflineAvailabilityForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isAvailableOfflineToggleOn: false,
      selectedTimeUnit: 'hours',
    };
  }

  onToggleAvailableOffline(value) {
    this.setState({ isAvailableOfflineToggleOn: false });
  }


  render() {
    const { onOfflineAvailabilityFormSubmit, selectedTimeUnit, selectedMinutes, onTimeUnitChange, isAvailableOffline } = this.props;
    const { isAvailableOfflineToggleOn } = this.state;
    const canEditLock = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.SMART_LOCK);
    const helpURL = localizeHelpCenterLink('smart-locks-offline-mode');
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, marginLeft: 10 }}>
          <h2 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f' }}><Entity entity="offlineAvailability" /></h2>
          <IconButton onClick={() => window.open(helpURL)} style={{ marginTop: 5, marginLeft: 5 }}>
            <InfoOutline style={{ color: '#3f3f3f', fontSize: 20 }} />
          </IconButton>
        </div>
        <h4 style={{ color: '#3f3f3f', fontSize: 18, marginLeft: 15, lineHeight: '1.3em' }}><Entity entity="availableOfflineDescription" /></h4>
        <Field
          name="availableOffline"
          label={<h4><Entity entity="availableOffline" /></h4>}
          component={MDSwitchField}
          containerstyle={{ paddingLeft: 15, marginTop: 10 }}
          horizontal
          onHandleChange={(value) => this.onToggleAvailableOffline(value)}
          disabled={!canEditLock}
        />
        {isAvailableOfflineToggleOn || isAvailableOffline ? (
          <div>
            <div style={{ marginLeft: 14, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 0 }}>{<Entity entity="maxtimeToSyncronizeOffline" />}</h4>
              <Entity
                componentClass={Field}
                name="availableOfflineMinutes"
                type="number"
                componentAttribute="placeholder"
                component={CustomField}
                hideErrorLabel
                containerStyle={{ width: 100 }}
                className="form-control-custom"
              />
              <Field
                id="timeUnit"
                name="timeUnit"
                component={CustomField}
                containerStyle={{ marginLeft: 20 }}
                className="form-control-select"
                componentClass="select"
              >
                {_.map(timeUnitsOptions, type =>
                  <Entity
                    key={type.value}
                    componentClass={TranslatableOption}
                    value={type.value}
                    componentAttribute="text"
                    entity={type.entity}
                  />)}
              </Field>
            </div>
            <div style={{ marginTop: -10, marginLeft: 14, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <HelpIcon style={{ color: '#3f3f3f', marginRight: 12, fontSize: 20 }} />
              <h5 style={{ fontSize: 15, fontWeight: 100, color: '#3f3f3f', lineHeight: '1.5em' }}>
                <Entity
                  key={`${selectedMinutes}-${selectedTimeUnit}`}
                  entity="forceSyncronizationDescriptionOffline"
                  data={{
                    time: selectedMinutes,
                    timeUnit: L20NContext.getSync(selectedTimeUnit),
                  }}
                />
              </h5>
            </div>
          </div>
        ) : null}
        <hr style={{ marginBottom: 10, marginTop: 10 }} />
        {isAvailableOfflineToggleOn || isAvailableOffline ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <WarningIcon style={{ color: 'red', fontSize: 20 }} />
            <h5 style={{ color: '#3f3f3f', marginLeft: 10, lineHeight: '1.5em' }}><Entity entity="availableOfflineDescriptionWarning" /></h5>
          </div>
        ) : null}
        {canEditLock ? (
          <MDButton
            title={<Entity entity="save" />}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.SMART_LOCK, true)}
            style={{ height: 35, borderRadius: 5 }}
            containerStyle={{ margin: 10 }}
            onClick={() => onOfflineAvailabilityFormSubmit()}
          />
        ) : null}
      </div>
    );
  }
} 

export default LockOfflineAvailabilityForm;