import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';

import Alert from './Alert.jsx';

export default class ConfirmToContinueModal extends React.Component {
  hideModal() {
    const { hideModal } = this.props;
    hideModal();
  }

  render() {
    const { title, message, body } = this.props;

    return (
      <Alert
        {...this.props}
        title={<Entity entity="modalTitle" data={{ modal: title }} />}
        message={message ? <Entity entity="modalMessage" data={{ modal: message }} />: body}
        type="warning"
        visible
        onOutsideClick={() => this.hideModal()}
      />
    );
  }
}
