import React from 'react';
import { Card } from "@material-ui/core";
import { GREEN } from '../../_config/consts';

const TreeNodeCustom = ({ title, subtitle, icon, containerStyle, canChangeDomain, titleStyle, subtitleStyle, children, isRoot, onTreeNodeClick }) => (
  <Card
    className={`card card-rounded ${!onTreeNodeClick && 'card-noHover'}`}
    style={{ marginTop: 0, paddingTop: 15, ...containerStyle, cursor: !canChangeDomain ? 'not-allowed' : 'pointer' }}
    onClick={onTreeNodeClick ? () => onTreeNodeClick() : null}
  >
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ marginRight: 10, width: 40, height: 40, borderRadius: 20, backgroundColor: isRoot || canChangeDomain ? GREEN : '#f0f0f0', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
        {icon}
      </div>
      <div>
      <h4 style={{ color: '#3f3f3f', marginTop: 10, marginBottom: 5, textAlign: 'left', fontWeight: 'bold', ...titleStyle }}>{title}</h4>
      {subtitle ? <h6 style={{ color: '#3f3f3f', marginTop: 0, fontWeight: 'normal', ...subtitleStyle }}>{subtitle}</h6> : null}
      </div>
    </div>
    {children}
  </Card>
)
export default TreeNodeCustom;
