module.exports = {
  SAVE_ACCESSORIES: 'SAVE_ACCESSORIES',
  APPEND_ACCESSORIES: 'APPEND_ACCESSORIES',
  SAVE_ACCESSORIES_PAGINATION: 'SAVE_ACCESSORIES_PAGINATION',
  SET_SELECTED_ACCESSORY: 'SET_SELECTED_ACCESSORY',
  UPDATE_ACCESSORY: 'UPDATE_ACCESSORY',
  REMOVE_ACCESSORY: 'REMOVE_ACCESSORY',
  RESET_ACCESSORIES_DATA: 'RESET_ACCESSORIES_DATA',
  SET_ACCESSORIES_FILTER: 'SET_ACCESSORIES_FILTER',
  RESET_ACCESSORIES_FILTERS: 'RESET_ACCESSORIES_FILTERS',
  SAVE_ACCESSORY_HISTORY: 'SAVE_ACCESSORY_HISTORY',
  APPEND_ACCESSORY_HISTORY: 'APPEND_ACCESSORY_HISTORY',
  SAVE_ACCESSRY_HISTORY_PAGINATION: 'SAVE_ACCESSRY_HISTORY_PAGINATION',
  SET_ACCESSORY_HISTORY_FILTER: 'SET_ACCESSORY_HISTORY_FILTER',
  RESET_ACCESSORY_HISTORY_FILTER: 'RESET_ACCESSORY_HISTORY_FILTER',
  RESET_ACCESSORY_HISTORY_DATA: 'RESET_ACCESSORY_HISTORY_DATA',
};
