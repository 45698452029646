import { IconButton } from '@material-ui/core';
import { DeleteForeverOutlined, Edit, Search } from '@material-ui/icons';
import {
  FormControl
} from '@sketchpixy/rubix';
import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { BLUE, DND_ELEMENTS, ZOOM_LEVEL } from '../../_config/consts.js';
import CloseCircleIconCustom from '../CustomIcons/CloseCircleIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MapLockRow from '../Maps/MapLockRow.jsx';
import FloorElementPoint from './FloorElementPoint.jsx';

function MapBuilderLocksList({ searchText, selectedMapId, onAppendSmartLocks }) {
  const smartLocks = useSelector((state) => state.locks.data);
  
  if (!smartLocks || smartLocks.content.length === 0) {
    return <EmptyView
      title="noLock"
      iconName="icon-simple-line-icons-user-following"
    />
  }
  const filteredSmartLocks = searchText && searchText.length >= 0 ? _.filter(smartLocks.content, res => res.name.toLowerCase().includes(searchText.toLowerCase())) : smartLocks.content;
  return (
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => onAppendSmartLocks(smartLocks.pagination && smartLocks.pagination.number + 1)}
        hasMore={smartLocks.pagination && !smartLocks.pagination.last}
        loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
        useWindow={false}
      >
        {_.map(filteredSmartLocks, (el) => (<MapLockRow key={el.id} locationMedia={el.locationMedia} model={el.model} selectedMapId={selectedMapId} id={el.id} name={el.name} />))}
      </InfiniteScroll>
  )
}

export default function FloorPlanEditor({ onPositionElementOnMap, onRemoveElementFromMap, onAppendSmartLocks }) {
  const [dropCanvasBbox, setDropCanvasBbox] = useState({});
  const floorPlan = useSelector((state) => state.floorPlans.selectedFloorPlan);
  const smartLocks = useSelector((state) => state.locks.data);
  const [searchText, setSearchText] = useState('');
  const imageSource = floorPlan && floorPlan.link ? floorPlan.link : '';

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [DND_ELEMENTS.SMART_LOCK_ROW, DND_ELEMENTS.SMART_LOCK_POINT],
      canDrop: () => true,
      drop: (item, monitor) => {
        const dropCoordinate = monitor.getClientOffset();

        let dropCanvas = document.getElementById("dropCanvas");
        let dropCanvasCoordinates = dropCanvas.getBoundingClientRect();
        const normalizedDropCoordinates = {
          x: _.round((dropCoordinate?.x - dropCanvasCoordinates.x) / dropCanvasCoordinates.width, 10),
          y: _.round((dropCoordinate?.y - dropCanvasCoordinates.y) / dropCanvasCoordinates.height, 10),
        }
        onPositionElementOnMap(item, normalizedDropCoordinates);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }));

  const setDropCanvasBBoxInState = () => {
    const dropCanvas = document.getElementById("dropCanvas");
    setDropCanvasBbox(dropCanvas ? dropCanvas.getBoundingClientRect() : {});
  }

  useEffect(() => {
    setDropCanvasBBoxInState();
    window.addEventListener('resize', setDropCanvasBBoxInState);
    return () => window.removeEventListener('resize', setDropCanvasBBoxInState);
  }, []);


  return (
    <div className="card-noHover" style={{ margin: 20, marginLeft:0, marginTop: 0, padding: 0, overflow: 'scroll', height: '120vh', width: '98%', zIndex: 0 }}>
      <div style={{ height: '99vh', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ height: '95vh', position: 'relative', overflow: 'scroll' }}>
            <img id="dropCanvas" ref={drop} src={imageSource} style={{ height: '100%', display: 'flex', flex: 1, objectFit: 'contain' }} onLoad={() => setDropCanvasBBoxInState()} />
            {_.map(smartLocks && smartLocks.content, (smartLock) =>  {
              if (!smartLock.locationCoordinates || !smartLock.locationMedia || smartLock.locationMedia.id !== floorPlan.id) {
                return null;
              }
              const resourceX = smartLock.locationCoordinates.split(';')[0];
              const resourceY = smartLock.locationCoordinates.split(';')[1];
              return dropCanvasBbox ? <FloorElementPoint key={smartLock.id} element={smartLock} x={resourceX * dropCanvasBbox.width / ZOOM_LEVEL} y={resourceY * dropCanvasBbox.height / ZOOM_LEVEL} onRemoveElementFromMap={() => onRemoveElementFromMap(smartLock)} /> : null
            })}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column',  backgroundColor: 'lightgray', paddingTop: 10 }}>
            <div style={{ marginBottom: 5, display: 'flex', paddingLeft: 10, position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <FormControl
                type="text"
                name="search"
                value={searchText}
                placeholder={L20NContext.getSync('searchSmartLock')}
                className="form-control-rounded"
                onChange={e => setSearchText(e.target.value)}
              />
              {searchText ? (
                <IconButton style={{ position: 'absolute', right: '10px' }} onClick={() => setSearchText('')}>
                  <CloseCircleIconCustom style={{ width: 20 }} />
                </IconButton>
              ) : (
                <Search style={{ position: 'absolute', right: '20px', fontSize: 20 }} />
              )}
            </div>
            <div style={{ height: '100vh', width: 300, overflow: 'auto', paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 200 }}>
              <MapBuilderLocksList
                searchText={searchText}
                selectedMapId={floorPlan && floorPlan.id}
                onAppendSmartLocks={page => onAppendSmartLocks(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
