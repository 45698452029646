import WarningIcon from '@material-ui/icons/Warning';
import { Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Field, change } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';
import MDCheckBoxGroupField from '../Fields/MDCheckBox/MDCheckBoxGroupField.js';
import { ORANGE, LANGUAGES } from '../../../_config/consts.js';
import MDRadioButtonView from './MDRadioButtonsGroup/MDRadioButtonView.js';
import { connect } from 'react-redux';
import { formatDomainHostNameToFriendlyName } from '../../../_config/utils';
import { Divider } from '@material-ui/core';

const timeOptions = [
  {
    value: 1,
    label: <Entity entity="twentifourhours" />,
  },
  {
    value: 2,
    label: <Entity entity="moringTime" />,
  },
  {
    value: 3,
    label: <Entity entity="afternoonTime" />,
  },
  {
    value: 4,
    label: <Entity entity="moringAndAfternoon" />,
  },
];
@connect(state => ({ form: state.form.CredentialForm, language: state.settings.language, companyConfigurations: state.user.companyConfigurations }))
class CredentialTimeForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      timeOptionSelected: undefined,
    };
  }
  
  onDateCheckboxChange(value) {
    const { dispatch, formName } = this.props;
    this.setState({ timeOptionSelected: value });
    switch (value) {
      case 1: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().endOf('day').valueOf()));
        break;
      }
      case 2: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().startOf('day').set('hours', 13).valueOf()));
        break;
      }
      case 3: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').set('hours', 14).valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      case 4: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      default: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().endOf('day').valueOf()));
        break;
      }
    }
  }


  render() {
    const { canEdit, areDeviceDatesLocked, plantMaster, language, companyConfigurations } = this.props;
    const { timeOptionSelected } = this.state;
    const options = _.map(moment.weekdays(true), (weekday) => ({
      label: weekday,
      value: moment().isoWeekday(weekday).isoWeekday(),
    }));
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    const plantMasterFormatted = plantMaster ? formatDomainHostNameToFriendlyName(plantMaster) : '';
    return (
      <div>
        {areDeviceDatesLocked ? (
          <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
            <WarningIcon style={{ color: ORANGE }} />
            <h4 style={{ color: ORANGE, margin: 0, marginLeft: 10 }}>
              <Entity entity="credentialAlreadyPresentInPlantWarning" data={{ plant: _.startCase(plantMasterFormatted) }} />
            </h4>
          </div>
          ) : null}
        <Row>
          <input style={{ display: 'none' }} type="username" name="username" />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Entity
              componentClass={Field}
              title={<Entity entity="credentialTimeFrame" />}
              name="credentialTimeframe"
              containerStyle={{ paddingLeft: 30 }}
              component={CustomField}
              type="airbnbDateRange"
              disabled={!canEdit || areDeviceDatesLocked}
              minimumNights={0}
            />
            {companyConfigurations.checkincheckoutActive ?
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ marginLeft: 8, marginRight: 30, marginLeft: 30 }}>
                  <Entity
                    componentClass={Field}
                    title={<Entity entity="validityTimeStart" />}
                    name="dateIntervalTimeFrom"
                    component={CustomField}
                    type="antdTimePicker"
                    is12HoursFormat={is12HoursFormat}
                    placeholder="Select a time"
                    disabled={!canEdit || areDeviceDatesLocked}
                  />
                </div>
                <Entity
                  componentClass={Field}
                  title={<Entity entity="validityTimeEnd" />}
                  name="dateIntervalTimeTo"
                  component={CustomField}
                  is12HoursFormat={is12HoursFormat}
                  type="antdTimePicker"
                  placeholder="Select a time"
                  disabled={!canEdit || areDeviceDatesLocked}
                />
              </div> : null
            }
          </div>
          {companyConfigurations.checkincheckoutActive ? <Divider style={{ marginBottom: 20, marginTop: 10, marginRight: 25, marginLeft: 25 }} /> : null}
          <h4 style={{ paddingLeft: 30, margin: 0, marginTop: 10, marginBottom: 15, fontWeight: 'bold' }}><Entity entity="invitationTimeProfile" /></h4>
          <div style={{ paddingLeft: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ marginLeft: 8, marginRight: 60 }}>
              <Entity
                componentClass={Field}
                title={<Entity entity="timeIntervalFrom" />}
                name="timeIntervalFrom"
                component={CustomField}
                type="antdTimePicker"
                is12HoursFormat={is12HoursFormat}
                placeholder="Select a time"
                disabled={!canEdit || areDeviceDatesLocked}
              />
            </div>
            <Entity
              componentClass={Field}
              title={<Entity entity="timeIntervalTo" />}
              name="timeIntervalTo"
              component={CustomField}
              is12HoursFormat={is12HoursFormat}
              type="antdTimePicker"
              placeholder="Select a time"
              disabled={!canEdit || areDeviceDatesLocked}
            />
            <div style={{ marginLeft: 30, marginTop: 20 }}>
              {_.map(timeOptions, timeOption => (
                <MDRadioButtonView
                  key={timeOption.value}
                  checked={timeOptionSelected === timeOption.value}
                  value={(timeOption.value).toString()}
                  label={timeOption.label}
                  disabled={!canEdit || areDeviceDatesLocked}
                  handleChange={value => this.onDateCheckboxChange(timeOption.value)}
                />
              ))}
            </div>
          </div>
        </Row>
        <Field
          name="daysOfTheWeek"
          title={<Entity entity="daysOfTheWeek" />}
          component={MDCheckBoxGroupField}
          containerstyle={{ paddingLeft: 15, marginTop: 10 }}
          horizontal
          options={options}
          disabled={!canEdit || areDeviceDatesLocked}
        />
      </div>
    );
  }
} 

export default CredentialTimeForm;
