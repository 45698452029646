import React from 'react';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 40,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
};

const Spinner = ({ classes, style, spinnerStyle, children, isDark }) => (
  <div className={classes.container}>
    <img src={isDark ? '/imgs/common/logoJagoDark.png' : '/imgs/common/logoJagoWhite.png'} alt="Luckey" style={{ margin: 5, marginTop: 15, alignSelf: 'center', height: 60 }} />
    <CircularProgress className={classes.circularProgress} style={{ ...spinnerStyle }} />
  </div>
);

export default withStyles(styles)(Spinner);
