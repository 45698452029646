import React from 'react';
import { Card, withStyles, IconButton, createTheme } from '@material-ui/core';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/Warning';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { BLUE, ORANGE } from '../../../_config/consts';
import { formatDomainHostNameToFriendlyName } from '../../../_config/utils';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 25,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '110%',
    borderRadius: 10,
    margin: 10,
    padding: 15,
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 15,
    borderLeft: '4px solid #157495',
  },
  descriptionText: {
    color: '#3f3f3f',
    margin: 0,
    fontWeight: 'bold',
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  warningContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 13,
  },
  logo: {
    position: 'absolute',
    height: 30,
    right: 10,
    top: 10,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const DomainLicenseCard = ({ classes, isEnterpise, license }) => {
  const serviceName = formatDomainHostNameToFriendlyName(window.location.hostname.toLowerCase());
  const monthDifferenceFromNow = moment(moment(license.expirationDate)).diff(moment(), 'months');
  const isExpiringWarning = monthDifferenceFromNow > 2 && monthDifferenceFromNow <= 6;
  const isExpiringError = monthDifferenceFromNow <= 2;
  let statusColor = isExpiringWarning ? ORANGE : '#009688';
  statusColor = isExpiringError ? '#e45353' : statusColor;
  statusColor = isEnterpise ? '#009688' : statusColor;
  const isExpirationDateBroken = license.expirationDate === 0;
  return (
    <MuiThemeProvider theme={theme}>
      <Card className={classes.container}>
        <img className={classes.logo} src="/imgs/common/logoJagoDarkSmall.png" alt="luckey" />
        <h2 className={classes.titleText}><Entity entity="cloudLicenseTitle" /></h2>
        <h4 className={classes.descriptionText} style={{ fontWeight: 'normal', marginTop: 10 }}><Entity entity="domainName" data={{ domain: serviceName }} /></h4>
        {!isExpirationDateBroken ? (
          <div>
          {isEnterpise ? (
            <div className={classes.sectionContainer}>
              <h4 className={classes.descriptionText} style={{ fontSize: 20, color: statusColor }}><Entity entity="enterpriseLicense" /></h4>
            </div>
            ) : (
              <div className={classes.sectionContainer}>
                <h4 className={classes.descriptionText} style={{ fontSize: 20, color: statusColor }}><Entity entity="expiringDate" /></h4>
                <h4 className={classes.descriptionText} style={{ fontSize: 20, color: statusColor }}>{`: ${moment(license.expirationDate).format('LL')} (${moment(license.expirationDate).fromNow()})`}</h4>
              </div>
          )}
          {(isExpiringError || isExpiringWarning) && !isEnterpise ? (
            <div className={classes.warningContainer}>
              <WarningIcon style={{ color: statusColor, fontSize: 15 }} />
              <h5 style={{ margin: 0, fontWeight: 'bold', marginLeft: 5, color: statusColor }}><Entity entity="domainIsExpiringWarning" /></h5>
            </div>
          ) : null}
          </div>
         ) : null}
      </Card>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DomainLicenseCard);
