import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SAVE_USERS_CUSTOM_FIELDS,
  SAVE_USERS_CUSTOM_FIELDS_PAGINATION,
  SET_USERS_CUSTOM_FIELDS_FILTERS,
  RESET_USERS_CUSTOM_FIELDS_FILTERS,
  SAVE_LOCKS_CUSTOM_FIELDS,
  SAVE_LOCKS_CUSTOM_FIELDS_PAGINATION,
  SET_LOCKS_CUSTOM_FIELDS_FILTERS,
  RESET_LOCKS_CUSTOM_FIELDS_FILTERS,
} from '../actions/actionTypes/customFields';

const initialState = Immutable({
  users: {
    content: [],
    pagination: {},
    filters: {},
    keyValues: {},
  },
  locks: {
    content: [],
    pagination: {},
    filters: {},
    keyValues: {},
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_USERS_CUSTOM_FIELDS: {
      const keys = _.map(action.customFields, customField => customField.keyValue);
      return state.setIn(['users', 'content'], action.customFields).setIn(['users', 'keyValues'], keys);
    }
    case SET_USERS_CUSTOM_FIELDS_FILTERS:
      return state.setIn(['users', 'filters', action.field], action.value);
    case SAVE_USERS_CUSTOM_FIELDS_PAGINATION:
      return state.setIn(['users', 'pagination'], action.pagination);
    case RESET_USERS_CUSTOM_FIELDS_FILTERS:
      return state.setIn(['users', 'filters'], initialState.users.filters);
    case SAVE_LOCKS_CUSTOM_FIELDS: {
      const keys = _.map(action.customFields, customField => customField.keyValue);
      return state.setIn(['locks', 'content'], action.customFields).setIn(['locks', 'keyValues'], keys);
    }
    case SAVE_LOCKS_CUSTOM_FIELDS_PAGINATION:
      return state.setIn(['locks', 'pagination'], action.pagination);
    case SET_LOCKS_CUSTOM_FIELDS_FILTERS:
      return state.setIn(['locks', 'filters', action.field], action.value);
    case RESET_LOCKS_CUSTOM_FIELDS_FILTERS:
      return state.setIn(['locks', 'filters'], initialState.locks.filters);
    default:
      return state;
  }
}
