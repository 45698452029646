import { IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import CameraIconCustom from '../../CustomIcons/CameraIconCustom.jsx';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { getEventDescriptionFromOutcome, getEventTranslatedFailureReasonFromSplittedOutcome, getEventTranslatedOperationFailedFromSplittedOutcome, getLogIconFromDeviceType } from '../../../_config/utils';
import { GREEN } from '../../../_config/consts.js';


export default class OpenFailedWithOutcomeEventRow extends React.Component {
  
  onActorClick() {
    const { event, onUserClick, onInvitationCodeClick } = this.props;
    const isInvitationEvent = event && !event.actorId && event.data && event.data.actor && event.data.actor.invitationCode;
    if (isInvitationEvent) {
      onInvitationCodeClick(event.data.actor.invitationCode);
    } else {
      onUserClick(event.actorId);
    }
  }

  render() {
    const { event, onLockClick, isAnonymized, splittedOutcome, lockHasCameraAssociated, onShowCameraSnippet } = this.props;
    const lock = event && event.data && event.data.lock && event.lockId ? `${event.data.lock.name} (${event.data.lock.serialNumber})` : '';
    const isUserEvent = event && event.data && event.data.actor && event.actorId;
    const isInvitationEvent = event && !event.actorId && event.data && event.data.actor && event.data.actor.invitationCode;
    let actor = event && event.data && event.data.actor && event.actorId ? `${event.data.actor.firstname} ${event.data.actor.lastname} ${event.data.actor.email || ''}` : event.description;
    actor = isInvitationEvent ? L20NContext.getSync('invitationCodeLog', { code: event.data.actor.invitationCode }) : actor;
    const deviceId = event && event.data && event.deviceId ? ` (#${event.deviceId})` : '';
    const eventDescription = getEventDescriptionFromOutcome(event.eventOutcome);

    const isActorClickable = (event.actorId && isUserEvent) || isInvitationEvent;

    return (
      <div className="log-row">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
              {getLogIconFromDeviceType(splittedOutcome.deviceType)}
              <CancelIcon style={{ marginTop: 20, marginLeft: -15, fontSize: 20, alignSelf: 'flex-end', color: '#D32F2F', backgroundColor: 'white', borderRadius: 100 }} />
            </div>
            <div>
              <h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>
                <Entity key={event.id} entity="openRequestGrantedButFailedTitle" data={{ deviceType: L20NContext.getSync(splittedOutcome.deviceType) }} />
                {lockHasCameraAssociated ? (
                  <IconButton onClick={() => onShowCameraSnippet(event.lockId, event)} style={{ height: 20, marginLeft: 5, marginBottom: 3 }}>
                    <CameraIconCustom style={{ width: 20, fontSize: 20, color: GREEN }} />
                  </IconButton>
                ) : null}
              </h3>
              {isAnonymized ? (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 15, marginLeft: -3 }}>
                  <WarningIcon style={{ fontSize: 20, color: '#efa638', marginRight: 5 }} />
                  <h4 style={{ color: '#efa638', margin: 0 }}>{<Entity entity="smartLockEventUserAnonymized" />}</h4>
                </div>
              ) : (
                <div>
                  <h4 style={{ lineHeight: '1.5em', color: '#3f3f3f', fontWeight: '100', margin: 0, marginTop: 15 }}>
                    <Entity
                      key={event.id}
                      entity={eventDescription}
                      data={{
                        lock: lock || '',
                        actor: isInvitationEvent ? L20NContext.getSync('withInvitationCodeLog', { code: event.data.actor.invitationCode }) : (actor || ''),
                        deviceType: L20NContext.getSync(splittedOutcome.deviceType),
                        action: L20NContext.getSync(getEventTranslatedOperationFailedFromSplittedOutcome(splittedOutcome)),
                        reason: L20NContext.getSync(getEventTranslatedFailureReasonFromSplittedOutcome(splittedOutcome)),
                      }}
                    />
                  </h4>
                  {event && event.eventOutcome && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15 }}>
                      <h5 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginRight: 5 }}>
                        <Entity entity="errorCode" />:
                      </h5>
                      <h5 style={{ color: '#3f3f3f', fontWeight: '100', margin: 0 }}>
                        {event.eventOutcome}
                      </h5>
                    </div>
                  )}
                  {actor && actor.length && (
                    <div
                      onClick={isActorClickable ? () => this.onActorClick() : null}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15, cursor: isActorClickable ? 'pointer' : 'normal', width: 'fit-content' }}
                    >
                      <h5 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginRight: 5 }}>
                        <Entity entity="user" />:
                      </h5>
                      <h5 style={{ color: '#3f3f3f', fontWeight: '100', textDecoration: isActorClickable ? 'underline' : 'none', margin: 0 }}>
                        {actor}{deviceId}
                      </h5>
                    </div>
                  )}
                  {lock && lock.length ? (
                    <div
                      onClick={event.lockId ? () => onLockClick(event.lockId) : null}
                      style={{ cursor: 'pointer', display: 'inline-flex', flexDirection: 'row', alignItems: 'center', marginTop: 15 }}
                    >
                      <h5 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginRight: 5 }}>
                        <Entity entity="lock" />:
                      </h5>
                      <h5 className="smart-link" style={{ textDecoration: 'underline', color: '#3f3f3f', fontWeight: '100', margin: 0 }}>
                        {lock}
                      </h5>
                    </div>
                  ) : null}
                  {event.uploadByUser ? (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 8, marginLeft: -3 }}>
                      <InfoIcon style={{ fontSize: 20, color: '#efa638', marginRight: 5 }} />
                      <h5 style={{ color: '#efa638', margin: 0 }}>{<Entity entity="logUploadByUserWarning" />}</h5>
                    </div>
                ) : null}
                </div>
              )}
            </div>
          </div>
          <h4 style={{ minWidth: 240, textAlign: 'right', color: '#3f3f3f', marginTop: 0 }}>{moment(event.timestamp).format('LLL')}</h4>
        </div>
      </div>
    );
  }
}
