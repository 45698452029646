// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { BLUE, CUSTOM_FIELDS_TYPES_ICONS, GREEN } from '../../_config/consts';
import CustomFieldsIconCustom from '../CustomIcons/CustomFieldsIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';

const styles = theme => ({
  container: {
    overflowY: 'auto',
    height: '120vh',
    paddingLeft: 15,
    paddingBottom: 220,
    paddingRight: 20,
    paddingTop: 15,
  },
  rowContainer: {
    padding: 20,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginBottom: 15,
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 0,
  },
  rowDescription: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 16,
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyCustomFieldsView = ({ onCreateNew }) => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 20, marginTop: 50, marginRight: 50 }}>
    <CustomFieldsIconCustom style={{ color: '#bdbdbd', width: 50, alignSelf: 'center' }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', textAlign: 'center', marginTop: 0 }}><Entity entity="noCustomFieldsFound" /></h3>
    <h4 style={{ color: '#bdbdbd', fontWeight: 'normal',lineHeight: '1.3em', textAlign: 'center', marginTop: 0 }}><Entity entity="customFieldsDescription" /></h4>
    <MDButton
      title={<Entity entity="createNewCustomField" />}
      titleStyle={{ marginTop: 0, fontSize: 13 }}
      backgroundColor={GREEN}
      containerStyle={{ margin: 0, marginTop: 10 }}
      onClick={() => onCreateNew()}
    />
  </div>
);

const CustomFieldRow = ({ customField, classes, onRowClick }) => (
  <Card className={`card card-rounded ${classes.rowContainer}`} onClick={() => onRowClick()}>
    <h4 className={classes.rowTitle}>{customField.name}</h4>
    {customField.type ? (
      <div className={classes.rowBody}>
        <h4 className={classes.rowDescription} style={{ marginRight: 5 }}>{<Entity entity="customFieldsTypes" data={{ type: customField.type }} />}</h4>
        {CUSTOM_FIELDS_TYPES_ICONS[customField.type]}
      </div>
    ) : null}
  </Card>
);

class CustomFieldsListView extends React.Component {
  render() {
    const { customFieldsData, pagination, onRowClick,onCreateNew, onLoadMoreEvents, classes } = this.props;
    const { number, totalPages } = pagination;
    return (
      <div className={classes.container}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => onLoadMoreEvents(number + 1)}
          hasMore={number + 1 < totalPages}
          useWindow={false}
          loader={
            <div style={{ marginTop: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingVertical: 5, }}>
              <h4 style={{ textAlign: 'center', color: '#3f3f3f' }}><Entity entity="loadingLogs" /></h4>
              <CircularProgress className={classes.circularProgress} />
            </div>
          }
        >
          <div>
            {!_.isEmpty(customFieldsData) ?
              _.map(customFieldsData, (customField) =>
                <CustomFieldRow
                  key={customField.id}
                  customField={customField}
                  classes={classes}
                  onRowClick={() => onRowClick(customField)}
                />)
                : (
                  <div>
                    <EmptyCustomFieldsView
                      onCreateNew={() => onCreateNew()}
                    />
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }

}
export default withStyles(styles)(CustomFieldsListView);