import _ from 'lodash';
import { Observable } from 'rxjs';
import { from } from 'rxjs/observable/from';
import { of } from 'rxjs/observable/of';
import { mergeMap, concatMap, switchMap, map, delay, takeUntil, catchError, finalize, endWith } from 'rxjs/operators';
import {
  FETCH_BOOKEY_USERS,
  CANCEL_FETCH_BOOKEY_USERS,
} from '../redux/actions/actionTypes/metrics';

import * as RestService from '../_config/rest';
import { rxFetch } from './utils';
import moment from 'moment';
import { setBookeyUsersEpic } from '../redux/actions/metrics.actions';

export const fetchUsersIdsEpic = (action$, store) =>
  action$.ofType(FETCH_BOOKEY_USERS)
    .pipe(
      switchMap((action) => {
        const { userIds } = action;
        
        const batchedUserIds = _.chunk(userIds, 100);
        const lastUserID = userIds.length > 0 ? userIds[userIds.length - 1] : undefined;
        return from(batchedUserIds).pipe(
          concatMap((userIdsBatch) => 
            of(userIdsBatch).pipe(
              delay(300),
              switchMap(() => 
                rxFetch(RestService.fetchGuests({roleIds: [5], userIds: userIdsBatch, page:0, pageSize:100})).pipe(
                  map((response) => {
                    if (response.data&&response.data.content) {
                      return setBookeyUsersEpic(response.data.content, lastUserID);
                    }
                    return Observable.empty();
                  }),
                  takeUntil(action$.ofType(CANCEL_FETCH_BOOKEY_USERS)),
                  catchError(() => Observable.empty())
                )
              )
            )
          ),
          finalize(() => {
            
          })
        );
      })
    );

