import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as SettingActions from '../../redux/actions/setting.actions';
import LicenseNotValidView from '../../routes/LicenseNotValidView.jsx';

const AppRoute = ({ component: Component, layout: Layout, licenses: licenses, theme, ...rest }) => {
  useEffect(() => {
    const { dispatch, avoidFetchSettings } = rest;
    try {
      if (!avoidFetchSettings) dispatch(SettingActions.fetchThemeSettings())
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme.themeName]);
  const hasLicense = AbilityProvider.getAbilityHelper().hasLicenseType(licenses);
  return (
    <div>
      {hasLicense ? (
        <Route
          {...rest}
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ) : (
        <div>
          <LicenseNotValidView />
          </div>
      )}
    </div>
  );
};

export default connect((state) => ({ theme: state.settings.items.theme }))(AppRoute);
