import React from 'react';
import moment from 'moment';
import WarningIcon from '@material-ui/icons/Warning';
import SecureIcon from '@material-ui/icons/VerifiedUser';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import AccessRuleIconCustom from '../../CustomIcons/AccessRuleIconCustom.jsx';

/* The first control is due to the fact that when editing a card the system create two log with only
the entityID (that is the id of the credential associated to the standard device).
Moreover every time a card is edited there are two logs, one for the creation of the credential and one 
for deletion. This logs show no usefull information and are misleading, so we need to remove them

*/
export default class RuleCreatedEventRow extends React.Component {
  render() {
    const { event: { id, actorId, timestamp, data: { actor, entity } }, isAnonymized } = this.props;
    return (
      <div style={{ minHeight: 110, padding: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <AccessRuleIconCustom style={{ width: 27, marginRight: 25, color: '#3f3f3f' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>
              <Entity key={id} entity="credentialRuleCreation" />
            </h3>
            {isAnonymized ? (
              <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 20 }} />
                <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0 }}>
                  <Entity
                    key={id}
                    entity="adminEventRowAnonymizedDescription"
                  />
                </h4>
              </div>
              ) : (
                <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0, marginTop: 10 }}>
                  <Entity
                    key={id}
                    entity="credentialRuleCreateDescription"
                    data={{
                      actor: actor && actorId ? `${actor.firstname || ''} ${actor.lastname || ''} ${actor.email || ''}` : '---',
                      entity: entity ? `${entity.name || entity.entityId || ''}` : '---',
                    }}
                  />
                </h4>
            )}
          </div>
        </div>
        <h4 style={{ minWidth: 240, textAlign: 'right', marginTop: 10, color: '#3f3f3f' }}>{moment(timestamp).format('LLL')}</h4>
      </div>
    );
  }
}