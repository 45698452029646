import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { submit } from 'redux-form';
import MDButton from '../components/MDButton/MDButton.jsx';
import ChainelsSelectCommunityForm from '../components/forms/Integrations/ChainelsSelectCommunityForm.jsx';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UserActions from '../redux/actions/user.actions';
import * as UtilsActions from '../redux/actions/utils.actions';


@connect(state => ({ language: state.settings.language, chainelsData: state.user.chainelsAuthParameters }))
class ChainelsActivationView extends React.Component {
  
  async onActivateChainelsIntegration(selectedBusiness) {
    const { dispatch, chainelsData } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(UserActions.activateChainelsIntegrationForCommunity(selectedBusiness.business));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(UserActions.applicationStartup(chainelsData.jago_token));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionError" /></h6>),
        },
      }));
    }
  }

  onReloadApplication() {
    const { dispatch } = this.props;
    dispatch(push('/dashboard'));
  }

  render() {
    const { dispatch, chainelsData: { companies } } = this.props;
    return (
      <div id="auth-container" style={{ height: '100vh', overflow: 'scroll', display: 'flex', alignItems: 'center', backgroundColor: '#12637f', paddingTop: 30, flexDirection: 'column' }} className="login">
        <img alt="chainels" src="/imgs/common/integrationLogos/chainels-white.png" style={{ height: 50, alignSelf: 'center' }} />
        <div style={{ width: '70%' }}>
          <h4 style={{ color: 'white', textAlign: 'center', marginTop: 10, marginBottom: 30 }}><Entity entity="activateChainelsIntegration" /></h4>
          {!companies ? (
            <div style={{ marginTop: 20 }}>
              <h4 style={{ color: 'white', textAlign: 'center' }}><Entity entity="noChainelsCompanyFound" /></h4>
              <MDButton
                title={<Entity key={companies} entity="reload" />}
                titleStyle={{ marginTop: 0, fontSize: 13, color: 'black' }}
                backgroundColor="white"
                containerStyle={{ margin: 0, marginTop: 10 }}
                onClick={() => this.onReloadApplication()}
              />
            </div>
          ) : (
            <div>
              <div style={{ marginTop: 20 }}>
                <ChainelsSelectCommunityForm
                  communities={companies}
                  onSubmit={businessData => this.onActivateChainelsIntegration(businessData)}
                />
              </div>
              <MDButton
                title={<Entity key={companies} entity="connectYourAccount" />}
                titleStyle={{ marginTop: 0, fontSize: 13, color: 'black' }}
                backgroundColor="white"
                containerStyle={{ margin: 0, marginTop: 10 }}
                onClick={() => dispatch(submit('ChainelsSelectCommunityForm'))}
              />
            </div>
        )}
        </div>
      </div>
    );
  }
}

export default ChainelsActivationView;
