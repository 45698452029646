import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import * as CredentialActions from '../../redux/actions/credential.actions';
import { MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';
import CredentialDetailsRow from '../Credentials/CredentialDetailsRow.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';

@connect(state => ({
  credentials: state.credentials,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
  routing: state.router,
}))
class CredentialsListView extends React.Component {


  appendCredentials(page) {
    const { dispatch } = this.props;
    const append = true;
    dispatch(CredentialActions.fetchSmartphoneCredentialRules(page, append));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CredentialActions.setOperationalMode(false));
  }

  render() {
    const { credentials, onShowInfoModal, listContainerStyle, credentials: { selectedCredential, data: { pagination: { number: currentPage, totalPages } } }, onSelectCredential, onNewCredential, viewLoading, themeName } = this.props;
    const canCreateCredentialRules = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.CREDENTIAL_RULE);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 40, width: '90%', overflow: 'auto', ...listContainerStyle }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => this.appendCredentials(currentPage + 1)}
          hasMore={currentPage + 1 < totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '41%' }}>
            {!_.isEmpty(credentials.data.data) ?
              _.map(credentials.data.data, credential =>
                <CredentialDetailsRow
                  themeColor={themeName}
                  key={credential.id}
                  isSelected={selectedCredential && selectedCredential.id === credential.id}
                  credential={credential}
                  onClick={() => onSelectCredential(credential)}
                />)
                : (
                  <div>
                    {!viewLoading ? (
                      <EmptyView
                        title="noCredential"
                        onNewEntity={canCreateCredentialRules ? () => onNewCredential() : null}
                        newEntityTitle="addCredential"
                        subtitle="startAddCredentials"
                        iconName="icon-simple-line-icons-user-following"
                        onShowInfo={() => onShowInfoModal()}
                      />) : <ListLoadingView />}
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }
} 

export default CredentialsListView;
