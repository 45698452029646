import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import * as UserActions from '../../../redux/actions/user.actions';
import * as CamerasActions from '../../../redux/actions/cameras.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import * as ModalActions from '../../../redux/actions/modal.actions';
import { EAGLE_EYE_CLIENT_ID } from '../../../_config/conf';
import { GREEN, RED, SUBSCRIPTION_TYPES } from '../../../_config/consts';
import CheckOutlineIconCustom from '../../CustomIcons/CheckOutlineIconCustom.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import { localizeHelpCenterLink } from '../../../_config/utils.js';

@connect(state => ({ language: state.settings.language }))
class EagleEyeSettingsOperationalSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isActive: null,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const isActive = dispatch(UserActions.userHasIntegrationActive(SUBSCRIPTION_TYPES.EAGLE_EYE_CAMERAS_PLUGIN));
    this.setState({ isActive });
  }

  onOpenLinkEagleEyeAccount() {
    const currentURL = `${window.location.protocol}//${window.location.host}`;
    window.location.replace(`https://auth.eagleeyenetworks.com/oauth2/authorize?client_id=${EAGLE_EYE_CLIENT_ID}&response_type=code&scope=vms.all&redirect_uri=${currentURL}`);
  }

  openCameras() {
    const { dispatch } = this.props;
    dispatch(push('/cameras'));
  }

  onDeactivateIntegrationRequest() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="disableIntegrationConfirm" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDisableIntegration(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDisableIntegration() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const { accessToken } = await dispatch(CamerasActions.getEagleEyeAccessTokenAndBaseUrl());
      const eagleEyeDTO = dispatch(UserActions.getActiveIntegration(SUBSCRIPTION_TYPES.EAGLE_EYE_CAMERAS_PLUGIN));
      if (accessToken && eagleEyeDTO && eagleEyeDTO.id) {
        await dispatch(UserActions.disableIntegration(eagleEyeDTO.id, accessToken));
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(UserActions.fetchCurrentActiveIntegrations());
        this.setState({ isActive: false });
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedSuccess" /></h6>),
          },
        }));
      } else {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
          },
        }));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
        },
      }));
    }
  }

  onOpenActivationInfo() {
    const helpURL = localizeHelpCenterLink('activate-eagle-eye-networks-integration');
    window.open(helpURL);
  }

  render() {
    const { isActive } = this.state;
    const { dispatch } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25, paddingTop: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <img alt="nexudus" src="/imgs/common/integrationLogos/eagle_eye.png" style={{ height: 70, alignSelf: 'center' }} />
        {isActive ? (
          <div style={{ width: '70%', margin: 'auto', marginTop: 20 }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CheckOutlineIconCustom style={{ color: GREEN, width: 30, marginRight: 10 }} />
              <h4 style={{ color: GREEN, fontWeight: 'bold', fontSize: 20 }}><Entity entity="integrationCorrectlyConfigured" /></h4>
            </div>
            <MDButton
              title={<Entity entity="goToCameraSection" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor={GREEN}
              containerStyle={{ margin: 0, marginTop: 10 }}
              onClick={() => this.openCameras()}
            />
            <MDButton
              title={<Entity entity="disableIntegration" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor={RED}
              containerStyle={{ margin: 0, marginTop: 10 }}
              onClick={() => this.onDeactivateIntegrationRequest()}
            />
          </div>
        ) : (
          <div style={{ width: '70%', margin: 'auto' }}>
            <h4 style={{ color: '#3f3f3f', textAlign: 'center', marginTop: 10, marginBottom: 30 }}><Entity entity="connectYoutAccount" /></h4>
            <MDButton
              title={<Entity entity="connectYourAccount" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor={GREEN}
              containerStyle={{ margin: 0, marginTop: 10 }}
              onClick={() => this.onOpenLinkEagleEyeAccount()}
            />
          </div>
        )}
        <h5
          className="link-label"
          style={{ margin: 'auto', width: 'fit-content', marginTop: 20, fontSize: 16, textDecoration: 'underline' }}
          onClick={() => this.onOpenActivationInfo()}
        >
          <Entity entity="findOutMore" />
        </h5>
      </div>
    );
  }
}

export default EagleEyeSettingsOperationalSection;
