import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import TimeIcon from '@material-ui/icons/AccessTime';
import CardIcon from '@material-ui/icons/CreditCard';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import LockIcon from '@material-ui/icons/LockOpen';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { CARDS_CREDENDIAL_RULES_STATUSES, CARD_TYPES, RED } from '../../../_config/consts';
import { elaborateCredentialDaysArray, getLockImage, localizeHelpCenterLink } from '../../../_config/utils';
import CardCredentialStatus from '../../Credentials/CardCredentialStatus.jsx';
import PinIconCustom from '../../CustomIcons/PinIconCustom.jsx';
import { WarningOutlined } from '@material-ui/icons';

@connect((state) => ({ settings: state.settings }))
class GuestCardCredentialRow extends React.Component {

  onSelectRule() {
    const { credential, onSelectCardCredentialRule, onSelectPINCredentialRule } = this.props;
    if (credential.deviceType !== CARD_TYPES.ISEO_PIN) {
      onSelectCardCredentialRule(credential.deviceId)
    } else {
      onSelectPINCredentialRule(credential.deviceId)
    }

  }
  render() {
    const { credential, hiddenHeader, onSelectPINCredentialRule, onSelectCardCredentialRule } = this.props;
    const days = elaborateCredentialDaysArray(credential);
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
      selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
    }));

    const additionalDay = credential.additionalTimeProfile ?  elaborateCredentialDaysArray(credential.additionalTimeProfile) : [];
    const additionalDaysOptions = _.map(moment.weekdays(true), weekday => ({
      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
      selected: _.indexOf(additionalDay, moment().isoWeekday(weekday).isoWeekday()) !== -1,
    }));
    const isIncompatible = credential.state === CARDS_CREDENDIAL_RULES_STATUSES.NOT_COMPATIBLE;
    let statusColor = credential.state !== CARDS_CREDENDIAL_RULES_STATUSES.TO_BE_INSERTED ? '#43A047' : '#EF6C00';
    statusColor = credential.state === CARDS_CREDENDIAL_RULES_STATUSES.FAILED ? 'red' : statusColor;
    statusColor =  isIncompatible ? '#3f3f3f' : statusColor;
    const canSelect = onSelectCardCredentialRule || onSelectPINCredentialRule
    const lockSection = (
      <div style={{ paddingRight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {credential && credential.smartLock && credential.smartLock.model ? (
          <img className="lock-icon" style={{ marginRight: 10, marginLeft: 4, width: 30, height: 30 }} src={getLockImage(credential.smartLock.model)} />
        ) : (
          <LockIcon style={{ fontSize: 30, color: '#3f3f3f', paddingLeft: 5, marginRight: 10 }} />
        )}
        <h4 style={{ margin: 0, color: '#3f3f3f', textAlign: 'center' }}>{credential.smartLock.name}</h4>
      </div>
    );
    return (
      <Card
        className={`card card-rounded ${!canSelect && 'card-noHover'}`}
        onClick={canSelect ? () => this.onSelectRule() : null}
        style={{ borderRadius: 10, marginBottom: 30, borderLeft: `4px solid ${statusColor}`, marginLeft: 2, marginRight: 2 }}
        elevation={3}
      >
        <div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {!hiddenHeader ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {credential.deviceType !== CARD_TYPES.ISEO_PIN ? (
                  <CardIcon style={{ fontSize: 33, color: '#3f3f3f', paddingLeft: 5, marginRight: 5 }} />
                ) : (
                  <PinIconCustom style={{ width: 33, color: '#3f3f3f', paddingLeft: 5, marginRight: 5 }} />
                )}
                <h4 style={{ fontWeight: 'bold', color: '#3f3f3f', margin: 0, marginTop: 4, marginLeft: 2, marginBottom: 4 }}>
                  <Entity key={credential.deviceType} entity={credential.deviceType !== CARD_TYPES.ISEO_PIN ? 'cardRule' : 'pinRule'} />
                </h4>
              </div>
            ) : lockSection}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <CardCredentialStatus
                status={credential.state}
                onShowStatusInfo={() => {
                  const helpURL = localizeHelpCenterLink('cards-synchronization-status');
                  window.open(helpURL);
                }}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 5, marginBottom: 5, height: 2 }} />
          <div style={{ paddingTop: 5, flexDirection: 'column', paddingBottom: 5, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', marginTop: 15 }}>
            {!hiddenHeader ? lockSection : null}
            <div style={{ marginTop: !hiddenHeader ? 15 : 0, display: 'flex', alignItems: 'center' }}>
              <CalendarIcon style={{ fontSize: 30, color: '#3f3f3f', paddingLeft: 5, marginRight: 10 }} />
              <h5 style={{ margin: 0, color: '#3f3f3f' }}>{`${moment(credential.fromDate).format('LL')} - ${moment(credential.toDate).format('LL')}`}</h5>
              <TimeIcon style={{ marginLeft: 20, fontSize: 25, color: '#3f3f3f', marginRight: 10 }} />
              <h5 style={{ margin: 0, color: '#3f3f3f' }}>{`${moment().startOf('day').add(credential.timeInSecondsFrom, 'seconds').format('LT')}-${moment().startOf('day').add(credential.timeInSecondsTo, 'seconds').format('LT')}`}</h5>
              <div style={{ marginLeft: 20, display: 'flex' }}>
                {_.map(daysOptions, option => (
                  <h5 style={{ margin: 0, marginRight: 5, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
                    {option.label.toUpperCase()}
                  </h5>
                ))}
              </div>
            </div>
          </div>
          {credential && credential.additionalTimeProfile && !_.isEmpty(credential.additionalTimeProfile) ? (
            <div>
              <Divider style={{ marginTop: 5, marginBottom: 5, height: 2 }} />
              <div style={{ paddingTop: 5, flexDirection: 'column', paddingBottom: 5, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', marginTop: 0 }}>
                <div style={{ marginTop: !hiddenHeader ? 0 : 0, display: 'flex', alignItems: 'center' }}>
                  <CalendarIcon style={{ fontSize: 30, color: '#3f3f3f', paddingLeft: 5, marginRight: 10 }} />
                  <h5 style={{ margin: 0, color: '#3f3f3f' }}>{`${moment(credential.additionalTimeProfile.fromDate).format('LL')} - ${moment(credential.additionalTimeProfile.toDate).format('LL')}`}</h5>
                  <TimeIcon style={{ marginLeft: 20, fontSize: 25, color: '#3f3f3f', marginRight: 10 }} />
                  <h5 style={{ margin: 0, color: '#3f3f3f' }}>{`${moment().startOf('day').add(credential.additionalTimeProfile.timeInSecondsFrom, 'seconds').format('LT')}-${moment().startOf('day').add(credential.additionalTimeProfile.timeInSecondsTo, 'seconds').format('LT')}`}</h5>
                  <div style={{ marginLeft: 20, display: 'flex' }}>
                    {_.map(additionalDaysOptions, option => (
                      <h5 style={{ margin: 0, marginRight: 5, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
                        {option.label.toUpperCase()}
                      </h5>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {isIncompatible ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 8, marginTop: 10 }}>
              <WarningOutlined style={{ fontSize: 20, color: RED, marginRight: 10 }} />
              <h5 style={{ fontSize: 16, color: RED, margin: 0, fontWeight: 'bold' }}><Entity entity="lockIncompatibleCardCredentialWarning" /></h5>
            </div>
          ) : null}
        </div>
      </Card>
    );
  }
} 

export default GuestCardCredentialRow;
