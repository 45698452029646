import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { change } from 'redux-form';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import {
  CACHE_GUEST_TAG,
  CACHE_LOCK_TAG,
  CANCEL_GUEST_TAGS_BY_FILTER,
  CANCEL_LOCK_TAGS_BY_FILTER,
  FETCH_GUEST_SPECIAL_TAGS_BY_FILTER,
  FETCH_GUEST_TAGS_BY_FILTER,
  FETCH_LOCK_TAGS_BY_FILTER,
  SAVE_GUEST_TAG,
  SAVE_GUEST_TAGS,
  SAVE_GUEST_TAG_CATEGORIES,
  SAVE_LOCK_TAG,
  SAVE_LOCK_TAGS,
  SAVE_LOCK_TAG_CATEGORIES,
} from './actionTypes/tag';
import * as ModalActions from './modal.actions';

export function saveUserTags(tags) {
  return {
    type: SAVE_GUEST_TAGS,
    tags,
  };
}

export function saveUserTag(tag) {
  return {
    type: SAVE_GUEST_TAG,
    tag,
  };
}

export function cacheUserTag(guestTagId, tagDetails) {
  return {
    type: CACHE_GUEST_TAG,
    guestTagId,
    tagDetails,
  };
}

export function cacheLockTag(lockTagId, tagDetails) {
  return {
    type: CACHE_LOCK_TAG,
    lockTagId,
    tagDetails,
  };
}

export function saveLockTags(tags) {
  const orderedTags = _.orderBy(tags, tag => tag.type === 'lock');
  return {
    type: SAVE_LOCK_TAGS,
    tags: orderedTags,
  };
}

export function saveLockTag(tag) {
  return {
    type: SAVE_LOCK_TAG,
    tag,
  };
}

export function saveGuestTagsCategories(categories) {
  return {
    type: SAVE_GUEST_TAG_CATEGORIES,
    categories,
  };
}


export function saveLockTagsCategories(categories) {
  return {
    type: SAVE_LOCK_TAG_CATEGORIES,
    categories,
  };
}

export function fetchLockTagsByFilter(value, pageSize = 200, includeSpecialTags = false, tagsType) {
  return {
    type: FETCH_LOCK_TAGS_BY_FILTER,
    value,
    pageSize,
    tagsType,
    includeSpecialTags,
  };
}

export function cancelFetchLockTagsByFilter() {
  return {
    type: CANCEL_LOCK_TAGS_BY_FILTER,
  };
}


export function fetchGuestTagsByFilter(value, pageSize = 5, includeSpecialTags = false, tagsType) {
  return {
    type: FETCH_GUEST_TAGS_BY_FILTER,
    value,
    pageSize,
    tagsType,
    includeSpecialTags,
  };
}

export function fetchSpecialGuestTagsByFilter(value, pageSize = 5, includeSpecialTags = false, tagsType) {
  return {
    type: FETCH_GUEST_SPECIAL_TAGS_BY_FILTER,
    value,
    pageSize,
    tagsType,
    includeSpecialTags,
  };
}


export function cancelFetchGuestTagsByFilter() {
  return {
    type: CANCEL_GUEST_TAGS_BY_FILTER,
  };
}

export function fetchGuestTags(includeSpecialTags, tagsType, page = 0, pageSize = 10) {
  return (dispatch, getState) => {
    let params = {
      includeSpecialTags,
      page,
      pageSize,
    };
    if (tagsType) {
      params = { ...params, type: tagsType };
    }
    RestService.fetchGuestTags(params)
    .then((response) => {
      dispatch(saveUserTags(response.data.data));
    })
    .catch(() => {

    });
  };
}


export function fetchGuestTagsCategories() {
  return (dispatch, getState) => {
    RestService.fetchGuestTagsCategories()
    .then((response) => {
      dispatch(saveGuestTagsCategories(response.data));
    })
    .catch(() => {});
  };
}

export function createGuestTag(values, addTagToForm) {
  return async (dispatch, getState) => {
    const formattedTag = formatter.formatOutputData(formatter.GUEST_TAG, values);
    try {
      const response = await RestService.createGuestTag(formattedTag);
      if (addTagToForm) {
        const selectedGuest = getState().guests.selectedGuest;
        const formName = selectedGuest && !_.isEmpty(selectedGuest) ? 'GuestProfileViewForm' : 'GuestForm';
        const guestForm = getState().form[formName];
        if (guestForm && guestForm.values && _.isArray(guestForm.values.tags)) {
          dispatch(change(formName, 'tags', [...guestForm.values.tags, response.data]));
        } else {
          dispatch(change(formName, 'tags', [response.data]));
        }
      }
      dispatch(fetchGuestTags());
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'tagCreated' }} /></h6>),
        },
      }));
    } catch (error) {
      throw error;
    }
  };
}

function cacheGuestTagDetails(guestTagId) {
  return async (dispatch) => {
    try {
      const tagsDetails = await RestService.fetchGuestTagDetails(guestTagId);
      if (tagsDetails && tagsDetails.data) dispatch(cacheUserTag(guestTagId, tagsDetails.data));
      return tagsDetails;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchGuestTagDetails(guestTagId) {
  return async (dispatch) => {
    try {
      const tagsDetails = await RestService.fetchGuestTagDetails(guestTagId);
      if (tagsDetails && tagsDetails.data) return tagsDetails.data;
      return tagsDetails;
    } catch (error) {
      throw error;
    }
  };
}

export function cacheGuestTagDetailsIfNeeded(guestTagId) {
  return async (dispatch, getState) => {
    try {
      if (guestTagId) {
        const cachedTagsMap = getState().tags.user.cachedTagsMap;
        if (!(guestTagId in cachedTagsMap)) {
          await dispatch(cacheGuestTagDetails(guestTagId));
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function cacheGuestTagsDetailsIfNeeded(userTagIds) {
  return async (dispatch, getState) => {
    _.each(userTagIds, tagId => dispatch(cacheGuestTagDetailsIfNeeded(tagId)));
  };
}

export function updateGuestTag(values) {
  return async (dispatch, getState) => {
    const formattedTag = formatter.formatOutputData(formatter.GUEST_TAG, values);
    const guestTagId = formattedTag.id;
    try {
      const response = await RestService.updateGuestTag(guestTagId, formattedTag);
      dispatch(fetchGuestTags());
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'tagUpdated' }} /></h6>),
        },
      }));
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchLockTags(includeSpecialTags, page = 0, pageSize = 10) {
  return (dispatch, getState) => {
    const params = {
      includeSpecialTags,
      page,
      pageSize,
    };
    RestService.fetchLockTags(params)
    .then((response) => {
      dispatch(saveLockTags(response.data.data));
    })
    .catch(() => {

    });
  };
}

export function fetchLockTagsCategories() {
  return (dispatch, getState) => {
    RestService.fetchLockTagsCategories()
    .then((response) => {
      dispatch(saveLockTagsCategories(response.data));
    })
    .catch(() => {});
  };
}

function cacheLockTagDetails(lockTagId) {
  return async (dispatch) => {
    try {
      const tagsDetails = await RestService.fetchLockTagDetails(lockTagId);
      if (tagsDetails && tagsDetails.data) dispatch(cacheLockTag(lockTagId, tagsDetails.data));
      return tagsDetails;
    } catch (error) {
      throw error;
    }
  };
}

export function cacheLockTagDetailsIfNeeded(lockTagId) {
  return async (dispatch, getState) => {
    try {
      if (lockTagId) {
        const cachedTagsMap = getState().tags.lock.cachedTagsMap;
        if (!(lockTagId in cachedTagsMap)) {
          await dispatch(cacheLockTagDetails(lockTagId));
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function cacheLockTagsDetailsIfNeeded(lockTagIds) {
  return async (dispatch, getState) => {
    _.each(lockTagIds, tagId => dispatch(cacheLockTagDetailsIfNeeded(tagId)));
  };
}

export function createLockTag(values, addTagToForm) {
  return async (dispatch, getState) => {
    try {
      const formattedTag = formatter.formatOutputData(formatter.LOCK_TAG, values);
      const response = await RestService.createLockTag(formattedTag);
      if (addTagToForm) {
        const lockTagsForm = getState().form.LockTagsForm;
        if (lockTagsForm && lockTagsForm.values && _.isArray(lockTagsForm.values.tags)) {
          dispatch(change('LockTagsForm', 'tags', [...lockTagsForm.values.tags, response.data]));
        } else {
          dispatch(change('LockTagsForm', 'tags', [response.data]));
        }
      }
      dispatch(fetchLockTags());
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'tagCreated' }} /></h6>),
        },
      }));
      
    } catch (error) {
      throw error;
    }
  };
}


export function updateLockTag(values) {
  return (dispatch, getState) => {
    const formattedTag = formatter.formatOutputData(formatter.LOCK_TAG, values);
    const lockTagId = formattedTag.id;
    return RestService.updateLockTag(lockTagId, formattedTag)
    .then((response) => {
      dispatch(fetchLockTags());
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'tagUpdated' }} /></h6>),
        },
      }));
      return Promise.resolve(response.data);
    })
    .catch(() => {
    });
  };
}


export function fetchGuestTagsByIds(guestsTagIds, includeUserTags = true, page = 0, pageSize = 10) {
  return async (dispatch) => {
    const params = {
      ids: guestsTagIds,
      includeUserTags,
      page,
      pageSize,
    };
    try {
      const tagsDetails = await RestService.fetchUserTags(params);
      if (tagsDetails && tagsDetails.data && tagsDetails.data.content) return tagsDetails.data.content;
      return tagsDetails;
    } catch (error) {
      throw error;
    }
  };
}

export function fetchLockTagsByIds(lockTagIds, includeSpecialTags = true, page = 0, pageSize = 10) {
  return async (dispatch) => {
    const params = {
      ids: lockTagIds,
      includeSpecialTags,
      page,
      pageSize,
    };
    try {
      const tagsDetails = await RestService.fetchSmartLockTags(params);
      if (tagsDetails && tagsDetails.data && tagsDetails.data.content) return tagsDetails.data.content;
      return tagsDetails;
    } catch (error) {
      throw error;
    }
  };
}