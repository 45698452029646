
import { Card, CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import CheckoutIcon from '@material-ui/icons/ExitToApp.js';
import {
  FormControl
} from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { BLUE, GREEN, ORANGE } from '../../_config/consts';
import UserDisableIconCustom from '../CustomIcons/UserDisableIconCustom.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  progressAreaOccupancy: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '30px !important',
    width: '30px !important',
  },
  headerText: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
  },
  chartContainer: {
    width: '100%',
    maxHeight: 400,
    overflowY: 'scroll',
    marginTop: 10,
  },
  refreshHeader: {
    position: 'absolute',
    right: 10,
    top: 5,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '30%',
    marginTop: 10,
    borderRadius: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  tableColumnText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  button: {
    color: 'red',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAnalyticsView = () => (
  <div style={{ height: 100, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <UserDisableIconCustom style={{ color: '#bdbdbd', width: 35 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0, fontSize: 18 }}><Entity entity="noActiveVisitorsFound" /></h3>
  </div>
);

class DailyActiveVisitorsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredVisitors: props.visitors,
      filterText: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { visitors: newVisitors } = nextProps;
    const { visitors: oldVisitors } = this.props;
    if (newVisitors !== oldVisitors) {
      this.setState({ filteredVisitors: newVisitors });
    }
  }

  onSearchUsers(text) {
    const { visitors } = this.props;
    this.setState({ filterText: text });
    const filteredVisitors = _.filter(visitors, user => user.firstname.toLowerCase().indexOf(text) > -1 || user.lastname.toLowerCase().indexOf(text) > -1 || user.email.toLowerCase().indexOf(text) > -1);
    this.setState({ filteredVisitors });
  }

  onSearchReset() {
    const { visitors } = this.props;
    this.setState({ filterText: '', filteredVisitors: visitors });
  }

  render() {
    const { visitors, classes, textColor, isRefreshingAccessChartData, onCheckoutVisitor, onExportActiveVisitors, lastUpdate, onShowGuestLogs, onRefreshMetric, isRefreshingAreasOccupancy } = this.props;
    const { filteredVisitors, filterText } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshingAccessChartData ? 0.5 : 1 }} elevation={3}>
          {visitors && !_.isEmpty(visitors) ? (
            <div className={classes.searchHeader}>
              <Card className={classes.searchContainer}>
                <FormControl
                  type="text"
                  value={filterText}
                  placeholder={L20NContext.getSync('guestNameSearch')}
                  className="form-control-noBorder"
                  style={{ width: '100%', borderRadius: 5 }}
                  onChange={e => this.onSearchUsers(e.target.value)}
                />
                {filterText ? (
                  <IconButton style={{ height: 20, width: 20, marginLeft: -60, marginRight: 10 }} onClick={() => this.onSearchReset()}>
                    <CloseIcon />
                  </IconButton>
                ) : null}
                <SearchIcon style={{ marginLeft: filterText ? 0 : -30 }} />
              </Card>
              <SimpleExportMenu
                titleContainerStyle={{ marginLeft: 25, marginTop: 5 }}
                iconStyle={{ color: textColor || GREEN }}
                exportTitleStyle={{ color: textColor || GREEN }}
                onExportLogs={format => onExportActiveVisitors(format)}
              />
            </div>
          ) : null}
          <div className={classes.chartContainer}>
            {filteredVisitors && !_.isEmpty(filteredVisitors) ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="visitor" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="contactEmail" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="visitorCode" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="checkinDate" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="actions" /></h5></TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                {_.map(filteredVisitors, (visitor) => {
                  return (
                    <TableRow
                      key={visitor.id}
                      className={classes.row}
                      classes={{
                        selected: classes.rowSelected,
                        hover: classes.rowHovered,
                      }}
                    >
                      <TableCell>
                        <h5 style={{ color: '#3f3f3f' }}>
                          {`${visitor.firstname || '--'} ${visitor.lastname || '--'} - ${visitor.email || '--'}`}
                        </h5>
                      </TableCell>
                      <TableCell>
                        <h5 style={{ margin: 0, color: '#3f3f3f' }}>{visitor.contactEmail}</h5>
                      </TableCell>
                      <TableCell>
                        <h5 style={{ margin: 0, color: '#3f3f3f' }}>{visitor.code}</h5>
                      </TableCell>
                      <TableCell>
                        <h5 style={{ margin: 0, color: '#3f3f3f' }}>{visitor.checkInDate ? moment(visitor.checkInDate).format('LLL') : '---'}</h5>
                      </TableCell>
                      <TableCell>
                      <Button
                        onClick={(event) => {
                          event.stopPropagation();
                          onCheckoutVisitor(visitor);
                        }}
                        className={classes.button}
                        style={{ color: ORANGE, marginRight: 20 }}
                      >
                        <Entity entity="checkOut" />
                        <CheckoutIcon style={{ fontSize: 20, marginLeft: 5 }} />
                      </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              </Table>
            ) : <EmptyAnalyticsView />}
          </div>
          <div className={classes.refreshHeader}>
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}:{moment(lastUpdate).format('LLL')}</h5>
            <IconButton
              disabled={isRefreshingAccessChartData}
              style={{ opacity: isRefreshingAccessChartData ? 0.5 : 1 }}
              onClick={() => onRefreshMetric('smartLocksEvents')}
            >
              <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
            </IconButton>
          </div>
          {isRefreshingAccessChartData ? <CircularProgress color="primary" className={classes.progress} /> : null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(DailyActiveVisitorsList);
