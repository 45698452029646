import Immutable from 'seamless-immutable';

import {
  LOGOUT,
  SAVE_USER_DATA,
  SAVE_CURRENT_ACTIVE_INTEGRATIONS,
  SET_USER_AUTHENTICATION,
  SET_LOGIN_FIELD,
  SAVE_SET_PASSWORD_FIELD,
  RESET_SET_PASSWORD_FIELDS,
  SET_USER_TOKEN_INFO,
  SAVE_COMPANY_CONFIGURATIONS,
  SAVE_USER_DOMAINS,
  SAVE_DOMAIN_LICENSE,
  SAVE_STORE_ITEMS,
  SAVE_COMPANY_INFO,
  SAVE_SYSTEM_HAS_ATLAS,
  SET_NEXUDUS_CLIENT_ID,
  SET_CHAINELS_AUTH_PARAMETERS,
  SET_OCTORATE_ACTIVATION_PARAMETERS,
} from '../actions/actionTypes/user';

const initialState = Immutable({
  data: {},
  userLogin: {},
  setPassword: {},
  isAuthenticated: false,
  systemHasAtlas: false,
  token: {},
  companyConfigurations: {},
  companyInfo: {},
  domains: {},
  license: {
    mainTier: undefined,
  },
  activeIntegrations: [],
  nexudusClientId: null,
  store: {
    items: [],
  },
  chainelsAuthParameters: {},
  octorateActivationParameters: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return Immutable({
        ...initialState,
        companyConfigurations: state.companyConfigurations,
      });
    case SAVE_SET_PASSWORD_FIELD:
      return state.setIn(['setPassword', action.field], action.value);
    case RESET_SET_PASSWORD_FIELDS:
      return state.set('setPassword', initialState.setPassword);
    case SAVE_USER_DATA:
      return state.set('data', action.userData);
    case SET_NEXUDUS_CLIENT_ID:
      return state.set('nexudusClientId', action.clientId);
    case SET_USER_AUTHENTICATION:
      return state.set('isAuthenticated', action.authenticated);
    case SET_USER_TOKEN_INFO:
      return state.set('token', action.tokenInfo);
    case SET_LOGIN_FIELD:
      return state.setIn(['userLogin', action.payload.field], action.payload.value);
    case SAVE_SYSTEM_HAS_ATLAS:
      return state.set('systemHasAtlas', action.hasAtlas);
    case SAVE_COMPANY_CONFIGURATIONS:
      return state.set('companyConfigurations', action.configurations);
    case SAVE_COMPANY_INFO:
      return state.set('companyInfo', action.companyInfo);
    case SAVE_USER_DOMAINS:
      return state.set('domains', action.domains);
    case SAVE_DOMAIN_LICENSE:
      return state.set('license', action.licenseInfo);
    case SAVE_STORE_ITEMS:
      return state.setIn(['store', 'items'], action.items);
    case SAVE_CURRENT_ACTIVE_INTEGRATIONS:
      return state.set('activeIntegrations', action.integrations);
    case SET_CHAINELS_AUTH_PARAMETERS:
      return state.set('chainelsAuthParameters', action.authParameters);
    case SET_OCTORATE_ACTIVATION_PARAMETERS:
      return state.set('octorateActivationParameters', action.activationParameters);
    default:
      return state;
  }
}
