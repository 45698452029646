import ErrorIcon from '@material-ui/icons/Error';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { RED } from '../../../_config/consts.js';
import MDButton from '../../MDButton/MDButton.jsx';

const GatewayNotificationFailedView = ({ notification, onClose }) => {
  const isWrongPuk = notification && notification.error && notification.error.detail && notification.error.detail === 'Wrong PUK';
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ErrorIcon
        style={{ color: RED, fontSize: 50 }}
      />
      <h4 style={{ color: '#3f3f3f', marginTop: 10, fontSize: 20, fontWeight: 'bold' }}>
        {isWrongPuk ? <Entity entity="notificationErrorWrongPuk" /> : <Entity entity="notificationError" />}
      </h4>
      <MDButton
        title={<Entity entity="close" />}
        backgroundColor={RED}
        onClick={() => onClose()}
      />
    </div>
  );
}

export default GatewayNotificationFailedView;