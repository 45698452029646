// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as CardActions from '../../redux/actions/card.actions';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';
import CardDetailsRow from '../Cards/CardDetailsRow.jsx';

@connect(state => ({
  cards: state.cards,
  credentials: state.credentials,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
}))
class PinsListView extends React.Component {

  
  componentWillUnmount() {
    this.closeOperationalSection();
  }

  appendCards(page) {
    const { dispatch } = this.props;
    const append = true;
    dispatch(CardActions.fetchCards(page, append));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
  }

  render() {
    const { cards, onShowInfoModal, listContainerStyle, cards: { selectedPin, pinsData: { pagination: { number: currentPage, totalPages } } }, onSelectPin, onNewPinClick, themeName, viewLoading } = this.props;
    const canCreateNewCard = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 40, width: '90%', overflow: 'auto', ...listContainerStyle }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => this.appendCards(currentPage + 1)}
          hasMore={currentPage + 1 < totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '40%' }}>
            {!_.isEmpty(cards.pinsData.data) ?
            _.map(cards.pinsData.data, card =>
              <CardDetailsRow
                themeColor={themeName}
                key={card.id}
                isSelected={selectedPin && selectedPin.id === card.id}
                card={card}
                onClick={() => onSelectPin(card)}
              />)
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title="noPin"
                    onNewEntity={canCreateNewCard ? () => onNewPinClick() : null}
                    onShowInfo={() => onShowInfoModal()}
                    newEntityTitle="addPin"
                    subtitle="startCreatingPin"
                    iconName="icon-simple-line-icons-credit-card"
                  />) : <ListLoadingView />}
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
} 

export default PinsListView;
