/* eslint-disable react/prop-types */
// @ts-nocheck
import { CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import BarChartIcon from '@material-ui/icons/InsertChart';
import UserIcon from '@material-ui/icons/PersonOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as CloudCreditsActions from '../../redux/actions/cloudCredits.actions';
import { BLUE, GREEN } from '../../_config/consts';
import { getColorFromThemeName } from '../../_config/utils';
import UserSettingIconCustom from '../CustomIcons/UserSettingIconCustom.jsx';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';
import WalletChartFilters from './ChartFilters/WalletChartFilters.jsx';
import PlatformUserIconCustom from '../CustomIcons/PlatformUserIconCustom.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 20,
    paddingTop: 30,
    justifyContent: 'center',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  chartContainer: {
    paddingTop: 10,
  },
  refreshHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 25,
    color: '#3f3f3f',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px #f1f1f1 solid',
    marginTop: 10,
    paddingBottom: 10,
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 5,
    marginLeft: 0,
  },
  listBodyContainer: {
    maxHeight: 400,
    overflow: 'scroll',
  },
  rowBodyLowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const WalletEventRow = ({ event, classes }) => {
  const subscriptionName = event.subscription && event.subscription.name ? event.subscription.name : '';
  const createdByUser = (event.activationSource === 'USER' && event.activatedByUserId) || (event.deletionSource === 'USER' && event.deletedByUserId);
  const createdBySupport = (event.activationSource === 'SUPPORT' && !event.activatedByUserId) || (event.deletionSource === 'SUPPORT' && !event.deletedByUserId);
  return (
    <div className={classes.row}>
      <div className={classes.rowBodyContainer}>
        <h4 className={classes.rowTitle}><Entity entity={event.deleted ? 'subscriptionDeleted' : 'subscriptionActivated'} /></h4>
        <h5 className={classes.tableBodyText} style={{ fontSize: 16, marginTop: 10, color: '#3f3f3f' }}>
          <Entity entity={event.deleted ? 'subscriptionDeletedDescription' : 'subscriptionActivatedDescription'} data={{ subscriptionName }} />
        </h5>
        {createdBySupport ? (
          <div className={classes.rowBodyLowContainer}>
            <UserSettingIconCustom style={{ fontSize: 20, marginRight: 10 }} />
            <Entity entity="createdBySupportEvent" />
          </div>
          ) : null}
        {createdByUser && event.userData ? (
          <div className={classes.rowBodyLowContainer}>
            <PlatformUserIconCustom style={{ width: 22, marginRight: 5, color: '#3f3f3f' }} />
            <h5 style={{ margin: 0, marginTop: 3, color: '#3f3f3f' }}>{`${event.userData.firstname} ${event.userData.lastname} ${event.userData.email}` }</h5>
          </div>
          ) : null}
      </div>
      <h5 className={classes.tableBodyText} style={{ color: '#3f3f3f' }}>{event.deleted ? moment(event.deletedAt).format('LLL') : moment(event.createdAt).format('LLL')}</h5>
    </div>
  );
}

const EmptyAnalyticsView = () => (
  <div style={{ height: 100, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <BarChartIcon style={{ color: '#bdbdbd', fontSize: 50 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFound" /></h3>
  </div>
);

@connect(state => ({ walletEvents: state.cloudCredits.wallet.events, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class WalletEventsSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isRefreshing: false,
    };
  }

  onRefreshData() {
    const { dispatch } = this.props;
    dispatch(CloudCreditsActions.fetchWalletEvents());
  }

  onExportConsumptionData(format) {
    const { dispatch } = this.props;
    dispatch(CloudCreditsActions.exportWalletEvents(format));
  }

  onFilterData(filters) {
    const { dispatch } = this.props;
    const { fromDate, toDate } = filters;
    dispatch(CloudCreditsActions.setWalletEventsFilter('fromDate', fromDate));
    dispatch(CloudCreditsActions.setWalletEventsFilter('toDate', toDate));
    dispatch(CloudCreditsActions.fetchWalletEvents());
  }

  render() {
    const { walletEvents: { content: walletEventsData, pagination: { number, totalPages }}, classes, themeName } = this.props;
    const { isRefreshing } = this.state;
    const textColor = getColorFromThemeName(themeName);
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1, marginRight: 20 }}>
          <div className={classes.header}>
            <div className={classes.refreshHeader}>
              <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="subscriptionHistory" /></h3>
            </div>
            <div className={classes.refreshHeader}>
              <SimpleExportMenu
                titleContainerStyle={{ marginRight: 25, marginTop: 5 }}
                iconStyle={{ color: textColor || GREEN }}
                exportTitleStyle={{ color: textColor || GREEN }}
                onExportLogs={format => this.onExportConsumptionData(format)}
              />
              <IconButton
                disabled={isRefreshing}
                style={{ opacity: isRefreshing ? 0.5 : 1 }}
                onClick={() => this.onRefreshData()}
              >
                <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
              </IconButton>
            </div>
          </div>
          <WalletChartFilters
            onSearch={(filters) => this.onFilterData(filters)}
          />
          {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
          <div style={{ overflowY: 'auto', height: '120vh', paddingLeft: 15, paddingBottom: 220 }}>
            <InfiniteScroll
              initialLoad={false}
              loadMore={() => this.onLoadMoreEvents(number + 1)}
              hasMore={number + 1 < totalPages}
              useWindow={false}
              loader={
                <div style={{ marginTop: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingVertical: 5, }}>
                  <h4 style={{ textAlign: 'center', color: '#3f3f3f' }}><Entity entity="loadingLogs" /></h4>
                  <CircularProgress className={classes.circularProgress} />
                </div>
              }
            >
              <div>
                {!_.isEmpty(walletEventsData) ?
                  _.map(walletEventsData, (log, key) =>
                    <WalletEventRow
                      key={log.id}
                      event={log}
                      classes={classes}
                    />)
                    : (
                      <div>
                        <EmptyAnalyticsView title="noLogs" subtitle="tryResetSearchFilter" />
                      </div>
                    )
                  }
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}


export default withStyles(styles)(WalletEventsSection);
