import {
  Form,
} from '@sketchpixy/rubix';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ShowIcon from '@material-ui/icons/Visibility';
import HideIcon from '@material-ui/icons/VisibilityOff';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from './Fields/CustomField.jsx';
import MDButton from '../MDButton/MDButton.jsx';


const validate = values => {
  const errors = {};

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords does not match';
  }
  if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[_0-9a-zA-Z\W]{8,}$/.test(values.password)) {
    errors.password = 'Password invalid';
  }

  if (!values.password) {
    errors.password = 'required';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'required';
  }

  return errors;
};

const ConditionStatus = ({ conditionText, isValid}) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <CheckIcon style={{ color: isValid ? '#4DB6AC' : 'gray', marginRight: 10 }} />
    <h5 style={{ color: 'white', marginTop: 10, marginBottom: 10 }}>{conditionText}</h5>
  </div>
);
@reduxForm({ form: 'ConfirmPasswordForm', validate })
class ConfirmPasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMinimum8CharactersValid: false,
      isUpperLowerValid: false,
      isNumberValid: false,
      isPasswordVisible: false,
    };
  }

  onTextChange(textValue) {
    if (textValue && textValue.length >= 8) {
      this.setState({ isMinimum8CharactersValid: true });
    } else {
      this.setState({ isMinimum8CharactersValid: false });
    }

    if (/[A-Z]/.test(textValue)) {
      this.setState({ isUpperLowerValid: true });
    } else {
      this.setState({ isUpperLowerValid: false });
    }

    if (/\d/.test(textValue)) {
      this.setState({ isNumberValid: true });
    } else {
      this.setState({ isNumberValid: false });
    }
  }
  
  onTogglePasswordVisibility() {
    const { isPasswordVisible } = this.state;
    this.setState({ isPasswordVisible: !isPasswordVisible });
  }

  onToggleConfirmPasswordVisibility() {
    const { isConfirmPasswordVisible } = this.state;
    this.setState({ isConfirmPasswordVisible: !isConfirmPasswordVisible });
  }

  render() {
    const { onValidateForm } = this.props;
    const { isMinimum8CharactersValid, isConfirmPasswordVisible, isPasswordVisible, isNumberValid, isUpperLowerValid } = this.state;
    return (
      <Form>
        <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <input type="hidden" name="password" style={{ display: 'none' }} />
          <Entity
            componentClass={Field}
            name="password"
            type={isPasswordVisible ? 'text' : 'password'}
            className="_lr-hide"
            componentAttribute="placeholder"
            entity="password"
            titleColor="white"
            title={<Entity entity="password" />}
            component={CustomField}
            containerStyle={{ width: '100%' }}
            hideErrorLabel
            onHandleChange={text => this.onTextChange(text)}
            mandatory
          />
          <IconButton style={{ position: 'absolute', right: '5px', bottom: '9px' }} onClick={() => this.onTogglePasswordVisibility()}>
            {!isPasswordVisible ? <ShowIcon style={{ color: '#3f3f3f', fontSize: 20 }} /> : <HideIcon style={{ color: '#3f3f3f', fontSize: 20 }} />}
          </IconButton>
        </div>
        <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Entity
            componentClass={Field}
            name="confirmPassword"
            type={isConfirmPasswordVisible ? 'text' : 'password'}
            className="_lr-hide"
            componentAttribute="placeholder"
            entity="confirmPassword"
            titleColor="white"
            containerStyle={{ width: '100%' }}
            title={<Entity entity="confirmPassword" />}
            component={CustomField}
            mandatory
            hideErrorLabel
          />
          <IconButton style={{ position: 'absolute', right: '5px', bottom: '9px' }} onClick={() => this.onToggleConfirmPasswordVisibility()}>
          {!isPasswordVisible ? <ShowIcon style={{ color: '#3f3f3f', fontSize: 20 }} /> : <HideIcon style={{ color: '#3f3f3f', fontSize: 20 }} />}
          </IconButton>
        </div>
        <ConditionStatus
          isValid={isMinimum8CharactersValid}
          conditionText={<Entity entity="characterPasswordWarning" />}
        />
        <ConditionStatus
          isValid={isUpperLowerValid}
          conditionText={<Entity entity="upperLowerPasswordWarning" />}
        />
        <ConditionStatus
          isValid={isNumberValid}
          conditionText={<Entity entity="numberPasswordWarning" />}
        />
        <MDButton
          disabled={!(isMinimum8CharactersValid && isUpperLowerValid && isNumberValid)}
          title={<Entity entity="save" />}
          onClick={() => onValidateForm()}
        />
      </Form>
    );
  }
} 

export default ConfirmPasswordForm;
