// @ts-nocheck
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import NewFromTemplateIcon from '@material-ui/icons/FileCopyOutlined';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import Warning from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as CardActions from '../../redux/actions/card.actions';
import * as UtilActions from '../../redux/actions/utils.actions';
import { BLUE, CARD_TYPES, CREDENTIAL_RULE_STATUSES, PERMISSIONS, PERMISSION_ENTITIES, RESERVATION_STATUSES, RESERVATION_STATUSES_PARAMETERS } from '../../_config/consts';
import { getColorFromThemeName, isUserWithoutEmail } from '../../_config/utils';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import Tag from '../Tag.jsx';
import RemoveEmailIconCustom from '../CustomIcons/RemoveEmailIconCustom.jsx';
import ShareIconCustom from '../CustomIcons/ShareIconCustom.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: 'red',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  buttonPositive: {
    color: BLUE,
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    marginRight: 10,
    width: 'max-content',
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, companyConfigurations: state.user.companyConfigurations, cards: state.cards, themeName: state.settings.items.theme.data.themeName }))
class PinsTableView extends React.Component {


  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
  }

  render() {
    const {
      classes,
      cards: { pinsData: { data, pagination: { number: currentPage, size, totalPages, totalElements }, filters } },
      cards: { selectedPin },
      viewLoading,
      themeName,
      onNewPin,
      onSelectPin,
      onFetchPinsOnPage,
      onRefreshPins,
      onCreatePinCredentialFromModel,
      onShowInfoModal,
      onDeletePin,
      dispatch,
      onSharePin
    } = this.props;
    const canCreateNewCard = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canDeletePin = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const validityDateFormat = dispatch(UtilActions.getCredentialValidityDateFormat());
    
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 140, paddingLeft: 10, width: '87%' }}>
        <div>
          {!_.isEmpty(data) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell colSpan={3}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="platformPins" /></h3>
                        <IconButton onClick={() => onRefreshPins()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TablePagination
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="pinsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchPinsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchPinsOnPage(page)}
                        />
                      )}
                    />
                    </TableRow>
                  <TableRow>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="pinCodeUnlock" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="authorizedUser" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="cardDescription" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="selectedLocks" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="validity" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="cardStatus" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="actions" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(data, (pin) => {
                    const { credentialRule: credential } = pin;
                    const days = credential && credential.daysOfTheWeek && _.orderBy(credential.daysOfTheWeek);
                    const daysOptions = _.map(moment.weekdays(true), weekday => ({
                      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
                      selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
                    }));
                    const isSuperError = _.isEmpty(pin.credentialRule.lockTags) || !pin.user;
                    let statusColor = pin.credentialRule.state !== CREDENTIAL_RULE_STATUSES.NOT_UPDATED ? '#43A047' : '#EF6C00';
                    statusColor = isSuperError ? 'red' : statusColor;
                
                    let syncStatusLabel = pin.credentialRule.state === CREDENTIAL_RULE_STATUSES.NOT_UPDATED ? 'notUpdated' : 'updated';
                    syncStatusLabel = pin.deleted ? 'cardDeleting' : syncStatusLabel;
                    syncStatusLabel = isSuperError ? 'credentialRuleInvalid' : syncStatusLabel;

                    const isInvalidNoTags = _.isEmpty(credential.lockTags) || _.isEmpty(credential.guestTags);
                    const credentialStatus = isInvalidNoTags ? RESERVATION_STATUSES.NO_TAGS : credential.status;

                    const isUserDeleted = pin && pin.user && pin.user.email && !_.includes(pin.user.email, '@');
                    
                    return (
                      <TableRow
                        key={pin.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={event => onSelectPin(pin)}
                        selected={selectedPin && selectedPin.id === pin.id}
                      >
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ minWidth: 150 }}>
                            <h5 style={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}>
                              {pin.deviceId}
                            </h5>
                            <IconButton onClick={(e) => {
                              e.stopPropagation();
                              onSharePin(pin);
                            }}>
                              <ShareIconCustom style={{ width: 20, color: BLUE }} />
                            </IconButton>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', alignItems: 'center' }}>
                            {pin && pin.user && (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {!isUserDeleted ? (
                                  <div>
                                    <h5 style={{ fontWeight: 'bold', fontSize: 16 }}>{`${pin.user.firstname} ${pin.user.lastname}`}</h5>
                                    {isUserWithoutEmail(pin.user) ?  (
                                      <div style={{ display: 'flex', marginTop: 7, marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}>
                                        <RemoveEmailIconCustom style={{ color: '#3f3f3f', width: 15, marginRight: 5 }} />
                                        <h5 style={{ color: '#3f3f3f', margin: 0 }}><Entity entity="noEmailSet" /></h5>
                                      </div>
                                    ) : (<h5 style={{ marginTop: 0 }}>{pin.user.email}</h5>)}
                                  </div>
                                ) : (
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Warning style={{ fontSize: 15, color: 'red', marginTop: 2 }} />
                                    <h5 style={{ fontWeight: 'bold', margin: 0, marginLeft: 5, fontSize: 16, color: 'red' }}><Entity entity="userDeleted" /></h5>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer}>
                            {pin.notes ? <h5 style={{ fontSize: 14, fontStyle: 'italic' }}>{`${pin.notes}`}</h5> : null}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tags-container">
                            {_.map(pin.credentialRule.lockTags, tag =>
                              <Tag
                                key={tag.id}
                                tag={tag}
                              />)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ paddingBottom: 6, minWidth: 260, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            {credentialStatus ? (
                              <h5 style={{ fontWeight: 'bold', marginTop: 4, marginBottom: 6 }}>
                                <Entity
                                  key={`status_${credentialStatus}`}
                                  style={{ textTransform: 'uppercase', color: RESERVATION_STATUSES_PARAMETERS[credentialStatus].color }}
                                  entity={RESERVATION_STATUSES_PARAMETERS[credentialStatus].string}
                                />
                              </h5>
                            ) : null}
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                                {`${moment(credential.dateInterval.from).format(validityDateFormat)} - ${moment(credential.dateInterval.to).format(validityDateFormat)}`}
                              </h5>
                            </div>
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 5 }}>{`${moment(credential.timeIntervalFrom).format('LT')} - ${moment(credential.timeIntervalTo).format('LT')}`}</h5>
                            <div style={{ marginTop: 5, display: 'flex', justifyContent: 'flex-end' }}>
                              {_.map(daysOptions, option => (
                                <h6 key={option.label} style={{ margin: 0, marginRight: 7, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
                                  {option.label.toUpperCase()}
                                </h6>
                                ))}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer}>
                            <h5 style={{ fontWeight: 'bold', fontSize: 13 }}>
                              <Entity
                                key={`status_${pin.credentialRule.state}`}
                                style={{ textTransform: 'uppercase', color: statusColor }}
                                entity={syncStatusLabel}
                              />
                            </h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          {canCreateNewCard ? (
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                onCreatePinCredentialFromModel(pin);
                              }}
                              className={classes.buttonPositive}
                            >
                              <Entity entity="copy" />
                              <NewFromTemplateIcon style={{ fontSize: 15, marginLeft: 5 }} />
                            </Button>
                          ) : null}
                          {canDeletePin ? (
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                onDeletePin(pin);
                              }}
                              className={classes.button}
                            >
                              <Entity entity="delete" />
                              <DeleteIcon style={{ fontSize: 15, marginLeft: 5 }} />
                            </Button>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="pinsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchPinsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchPinsOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title="noPin"
                    onNewEntity={canCreateNewCard ? () => onNewPin() : null}
                    newEntityTitle="addPin"
                    subtitle="startCreatingPin"
                    iconName="icon-simple-line-icons-credit-card"
                    onShowInfo={() => onShowInfoModal()}
                  />) : <ListLoadingView />}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PinsTableView);
