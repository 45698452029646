import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as LockActions from '../../redux/actions/lock.actions';
import ListLoadingView from '../ListLoadingView.jsx';
import ResourceInfoRow from './ResourceInfoRow.jsx';
import EmptyView from '../EmptyView.jsx';


@connect((state) => ({ locks: state.locks, themeName: state.settings.items.theme.data.themeName }))
class ResourceList extends React.Component {


  componentWillUnmount() {
    this.closeOperationalSection();
  }
  
  appendResources(page) {
    const { onLoadNextResources } = this.props;
    if (onLoadNextResources) onLoadNextResources(page);
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(LockActions.setOperationalMode(false));
  }

  render() {
    const { resources: { content, pagination }, viewLoading, onNewEntity, selectedResource, onResourcePressed, locks: { cachedLocksMap } } = this.props;
    return (
      <div className="list-view details-section" style={{ width: '100%', top: 190, }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => this.appendResources(pagination && pagination.number + 1)}
          hasMore={pagination && !pagination.last}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '40%' }}>
            {!_.isEmpty(content) ?
              _.map(content, (resource, index) =>
                <ResourceInfoRow
                  key={resource.id}
                  resource={resource}
                  isSelected={selectedResource && selectedResource.id === resource.id}
                  checkInLock={resource.checkInSmartLockId && !_.isEmpty(cachedLocksMap) ? cachedLocksMap[resource.checkInSmartLockId] : null}
                  onSelect={() => onResourcePressed(resource)}
                />)
              :
              <div>
                {!viewLoading && (
                  <EmptyView
                    title="noResources"
                    newEntityTitle="createResource"
                    subtitle="startCreatingResource"
                    onNewEntity={() => onNewEntity()}
                  />
                )}
              </div>
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }
} 

export default ResourceList;
