/* eslint-disable max-len */
import _ from 'lodash';
import {
  SAVE_USERS_CUSTOM_FIELDS,
  SAVE_USERS_CUSTOM_FIELDS_PAGINATION,
  SET_USERS_CUSTOM_FIELDS_FILTERS,
  RESET_USERS_CUSTOM_FIELDS_FILTERS,
  SAVE_LOCKS_CUSTOM_FIELDS,
  SAVE_LOCKS_CUSTOM_FIELDS_PAGINATION,
  SET_LOCKS_CUSTOM_FIELDS_FILTERS,
  RESET_LOCKS_CUSTOM_FIELDS_FILTERS,
} from './actionTypes/customFields';
import * as CustomFieldsAPI from '../../_config/customFieldsAPI';
import { CUSTOM_FIELDS_ENTITY_TYPES, EAGLE_EYE_CUSTOM_FIELD_DTO } from '../../_config/consts';


export function saveUsersCustomFields(customFields) {
  return {
    type: SAVE_USERS_CUSTOM_FIELDS,
    customFields,
  };
}

export function saveUsersCustomFieldsPagination(pagination) {
  return {
    type: SAVE_USERS_CUSTOM_FIELDS_PAGINATION,
    pagination,
  };
}

export function setUsersCustomFieldFilter(field, value) {
  return {
    type: SET_USERS_CUSTOM_FIELDS_FILTERS,
    field,
    value,
  };
}

export function resetUsersCustomFieldFilters() {
  return {
    type: RESET_USERS_CUSTOM_FIELDS_FILTERS,
  };
}

export function saveLocksCustomFields(customFields) {
  return {
    type: SAVE_LOCKS_CUSTOM_FIELDS,
    customFields,
  };
}

export function saveLocksCustomFieldsPagination(pagination) {
  return {
    type: SAVE_LOCKS_CUSTOM_FIELDS_PAGINATION,
    pagination,
  };
}

export function setLocksCustomFieldFilter(field, value) {
  return {
    type: SET_LOCKS_CUSTOM_FIELDS_FILTERS,
    field,
    value,
  };
}

export function resetLocksCustomFieldFilters() {
  return {
    type: RESET_LOCKS_CUSTOM_FIELDS_FILTERS,
  };
}

export function fetchUsersCustomFields(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().customFields.users.filters;
      const params = {
        page,
        pageSize,
        entity: CUSTOM_FIELDS_ENTITY_TYPES.USER,
        ...filters,
      };
      const response = await CustomFieldsAPI.fetchCustomFields(params);
      if (response.data && response.data.content) {
        const customFields = response.data.content;
        dispatch(saveUsersCustomFields(customFields));
        dispatch(saveUsersCustomFieldsPagination(_.omit(customFields, 'content')));
        return customFields;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function appendUsersCustomFields(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().customFields.users.filters;
      const params = {
        page,
        pageSize,
        entity: CUSTOM_FIELDS_ENTITY_TYPES.USER,
        ...filters,
      };
      const response = await CustomFieldsAPI.fetchCustomFields(params);
      if (response.data && response.data.content) {
        const customFields = response.data.content;
        const customFieldsToSave = [
          ...customFields,
          ...getState().customFields.users.content,
        ];
        dispatch(saveUsersCustomFields(customFieldsToSave));
        dispatch(saveUsersCustomFieldsPagination(_.omit(customFields, 'content')));
        return customFields;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchLocksCustomFields(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().customFields.locks.filters;
      const params = {
        page,
        pageSize,
        entity: CUSTOM_FIELDS_ENTITY_TYPES.LOCK,
        ...filters,
      };
      const response = await CustomFieldsAPI.fetchCustomFields(params);
      if (response.data && response.data.content) {
        const customFields = response.data.content;
        dispatch(saveLocksCustomFields(customFields));
        dispatch(saveLocksCustomFieldsPagination(_.omit(customFields, 'content')));
        return customFields;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function appendLocksCustomFields(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().customFields.locks.filters;
      const params = {
        page,
        pageSize,
        entity: CUSTOM_FIELDS_ENTITY_TYPES.LOCK,
        ...filters,
      };
      const response = await CustomFieldsAPI.fetchCustomFields(params);
      if (response.data && response.data.content) {
        const customFields = response.data.content;
        const customFieldsToSave = [
          ...customFields,
          ...getState().customFields.locks.content,
        ];
        dispatch(saveLocksCustomFields(customFieldsToSave));
        dispatch(saveLocksCustomFieldsPagination(_.omit(customFields, 'content')));
        return customFields;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCustomFields(entityType = CUSTOM_FIELDS_ENTITY_TYPES.USER) {
  return async (dispatch, getState) => {
    if (entityType === CUSTOM_FIELDS_ENTITY_TYPES.USER) {
      return dispatch(fetchUsersCustomFields());
    } else {
      return dispatch(fetchLocksCustomFields());
    }
  }
}


export function createCustomField(customFieldDTO) {
  return async (dispatch, getState) => {
    try {
      const createResponse = await CustomFieldsAPI.createCustomField(customFieldDTO);
      if (createResponse && createResponse.data) {
        return createResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function editCustomField(customFieldId, customFieldDTO) {
  return async (dispatch, getState) => {
    try {
      const updateResponse = await CustomFieldsAPI.updateCustomField(customFieldId, customFieldDTO);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createEagleEyeCustomField() {
  return async (dispatch, getState) => {
    try {
      const response = await dispatch(createCustomField(EAGLE_EYE_CUSTOM_FIELD_DTO));
      return response;
    } catch (error) {
      throw error;
    }
  };
}
