// @ts-nocheck
import CardIcon from '@material-ui/icons/CreditCard';
import { MenuItem, NavDropdownHover } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import RegistryIcon from '@material-ui/icons/HdrWeak';
import React from 'react';
import { ACCESS_METHODS } from '../../../_config/consts';
import AddCircleOutlineIconCustom from '../../CustomIcons/AddCircleOutlineIconCustom.jsx';
import KeyOutlinedIconCustom from '../../CustomIcons/KeyOutlinedIconCustom.jsx';
import SmartPhoneKeyIconCustom from '../../CustomIcons/SmartPhoneKeyIconCustom.jsx';
import PinIconCustom from '../../CustomIcons/PinIconCustom.jsx';
import { IconButton } from '@material-ui/core';


export default class AccessMethodCreateMenu extends React.Component {
  render() {
    const { onCreateNewAccessMethod, canAddSmartphone, titleContainerStyle, title, iconStyle, canAddF9000, canAddObject } = this.props;
    const menuItems = [
      <MenuItem key="export-header" header><Entity entity="newAccessMethod" /></MenuItem>,
      canAddSmartphone ?
      <MenuItem
        key={1}
        eventKey={ACCESS_METHODS.SMART}
        onClick={() => onCreateNewAccessMethod(ACCESS_METHODS.SMART)}
      >
        <div style={{ minWidth: 250, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SmartPhoneKeyIconCustom style={{ width: 22, marginTop: 0, marginRight: 15 }} />
          <h5>{<Entity entity="newAccessMethodSmartphone" />}</h5>
        </div>
      </MenuItem> : null,
      <MenuItem
        key={2}
        eventKey={ACCESS_METHODS.STANDARD}
        onClick={() => onCreateNewAccessMethod(ACCESS_METHODS.STANDARD)}
      >
        <div style={{ minWidth: 250, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <CardIcon style={{ fontSize: 23, marginTop: 0, marginRight: 15 }} />
          <h5>{<Entity entity="newAccessMethodCard" />}</h5>
        </div>
      </MenuItem>,
      <MenuItem
        key={3}
        eventKey={ACCESS_METHODS.PIN}
        onClick={() => onCreateNewAccessMethod(ACCESS_METHODS.PIN)}
      >
        <div style={{ minWidth: 250, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <PinIconCustom style={{ width: 23, marginTop: 0, marginRight: 15 }} />
          <h5>{<Entity entity="newAccessMethodPIN" />}</h5>
        </div>
      </MenuItem>,
      canAddF9000 ? (
        <MenuItem
          key={4}
          eventKey={ACCESS_METHODS.F9000}
          onClick={() => onCreateNewAccessMethod(ACCESS_METHODS.F9000)}
        >
          <div style={{ minWidth: 250, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <KeyOutlinedIconCustom style={{ width: 23, marginTop: 0, marginRight: 15 }} />
            <h5>{<Entity entity="newAccessMethodF9000" />}</h5>
          </div>
        </MenuItem>
      ) : null,
      canAddObject ? (
        <MenuItem
          key={5}
          eventKey={ACCESS_METHODS.ITEM}
          onClick={() => onCreateNewAccessMethod(ACCESS_METHODS.ITEM)}
        >
          <div style={{ minWidth: 250, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <RegistryIcon style={{ fontSize: 23, marginTop: 0, marginRight: 15 }} />
            <h5>{<Entity entity="newAccessMethodObject" />}</h5>
          </div>
        </MenuItem>
      ) : null,

    ];

    return (
      <div>
        <NavDropdownHover
          id="plant-menu-btn"
          className="header-menu header-menu menu-default dropdown"
          noCaret
          eventKey={4}
          title={
            <IconButton style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...titleContainerStyle }}>
              {title}
              <AddCircleOutlineIconCustom style={{ ...iconStyle }} />
            </IconButton>
          }
        >
          {menuItems}
        </NavDropdownHover>
      </div>
    );
  }
}
