import { axiosInstance } from './rest';

export function getRegistryItems(params) {
  const requestData = {
    params,
  };
  return axiosInstance.get('/api/v2/items', requestData);
}

export function createRegistryItem(itemDTO) {
  return axiosInstance.post('/api/v2/items', itemDTO);
}

export function getRegistryItemDetails(itemId) {
  return axiosInstance.get(`/api/v2/items/${itemId}`);
}

export function deleteRegistryItem(itemId) {
  return axiosInstance.delete(`/api/v2/items/${itemId}`);
}

export function updateRegistryItem(itemId, itemDTO) {
  return axiosInstance.put(`/api/v2/items/${itemId}`, itemDTO);
}
