import _ from 'lodash';
import { DEFAULT_PERMISSIONS } from '../../_config/consts';
import * as RestService from '../../_config/rest';
import * as RolesAPI from '../../_config/rolesAPI';
import PermissionsParserV0 from '../../permissionsUtils/PermissionsParserV0';
import {
  APPEND_PERMISSIONS,
  APPEND_ROLES,
  REMOVE_ROLE_FROM_STATE,
  RESET_ROLES_FILTERS,
  SAVE_EDITABLE_PERMISSIONS_PARSED,
  SAVE_PERMISSIONS,
  SAVE_PERMISSIONS_PAGINATION,
  SAVE_PERMISSIONS_PARSED,
  SAVE_ROLES,
  SAVE_ROLES_PAGINATION_DATA,
  SET_ROLES_FILTER_FIELD,
  SET_ROLES_OPERATIONAL_MODE,
  SET_SELECTED_ROLE,
  UPDATE_ROLE_IN_STATE,
} from './actionTypes/roles';

export function formatRole(role) {
  const isRoleGuest = role && (role.id === 5 || role === 'ROLE_MANAGER');
  const isRoleManager = role && (role.id === 1 || role === 'ROLE_GUEST');
  let label = _.upperFirst(_.lowerCase(_.replace(role.name, 'ROLE_', '')));
  label = isRoleGuest ? 'User' : label;
  label = isRoleManager ? 'Administrator' : label;
  return { ...role, label };
}

export function saveRoles(roles) {
  return {
    type: SAVE_ROLES,
    roles: _.map(roles, role => formatRole(role)),
  };
}

export function appendRoles(roles) {
  return {
    type: APPEND_ROLES,
    roles: _.map(roles, role => formatRole(role)),
  };
}

export function saveRolesPagination(pagination) {
  return {
    type: SAVE_ROLES_PAGINATION_DATA,
    pagination,
  };
}

export function setSelectedRole(role) {
  return {
    type: SET_SELECTED_ROLE,
    role: formatRole(role),
  };
}

export function setOperationalMode(value) {
  return {
    type: SET_ROLES_OPERATIONAL_MODE,
    value,
  };
}

export function savePermissions(permissions) {
  return {
    type: SAVE_PERMISSIONS,
    permissions,
  };
}

export function savePermissionsParsed(permissions) {
  return {
    type: SAVE_PERMISSIONS_PARSED,
    permissions,
  };
}

export function saveEditablePermissionsParsed(permissions) {
  return {
    type: SAVE_EDITABLE_PERMISSIONS_PARSED,
    permissions,
  };
}

export function savePermissionsPagination(pagination) {
  return {
    type: SAVE_PERMISSIONS_PAGINATION,
    pagination,
  };
}

export function appendPermissions(permissions) {
  return {
    type: APPEND_PERMISSIONS,
    permissions,
  };
}

export function setRolesFilter(field, value) {
  return {
    type: SET_ROLES_FILTER_FIELD,
    field,
    value,
  };
}

export function updateRoleInState(role) {
  return {
    type: UPDATE_ROLE_IN_STATE,
    role: formatRole(role),
  };
}

export function resetRolesFilters() {
  return { type: RESET_ROLES_FILTERS };
}

export function removeRoleFromState(roleId) {
  return {
    type: REMOVE_ROLE_FROM_STATE,
    roleId,
  };
}

/*
DEFAULT PERMISSION GRANTED:
  - USER_ME
  - USER_TAG_READ
  - USER_TAG_UPDATE
  - USER_TAG_CREATE
  - SMART_LOCK_TAG_READ
  - SMART_LOCK_TAG_CREATE
  - SMART_LOCK_TAG_UPDATE
  - CREDENTIAL_READ
  - OPEN
*/
function augmentPermissions(permissionsIds) {
  return (dispatch, getState) => {
    const augmentedPermissionIds = [...permissionsIds];
    _.each(DEFAULT_PERMISSIONS, (v) => {
      const permission = _.find(getState().roles.permissions.content, { name: v });
      if (permission) {
        augmentedPermissionIds.push(permission.id);
      }
    });

    return _.uniq(augmentedPermissionIds);
  };
}

export function fetchRoles(page = 0, pageSize = 200) {
  return async (dispatch, getState) => {
    const filters = getState().roles.roles.filters;
    try {
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const rolesResponse = await RolesAPI.fetchRoles(params);
      if (rolesResponse && rolesResponse.data) {
        dispatch(saveRoles(rolesResponse.data.content));
        dispatch(saveRolesPagination(_.omit(rolesResponse.data, 'content')));
        return rolesResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchRolesOptions(filters, page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const rolesResponse = await RolesAPI.fetchRoles(params);
      if (rolesResponse && rolesResponse.data) {
        return rolesResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAndAppendRoles(page = 0, pageSize = 50) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
      };
      const rolesResponse = await RolesAPI.fetchRoles(params);
      if (rolesResponse && rolesResponse.data) {
        dispatch(appendRoles(rolesResponse.data.content));
        dispatch(saveRolesPagination(_.omit(rolesResponse.data, 'content')));
        return rolesResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function fetchPermissions(page = 0, pageSize = 200) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
      };
      const permissionsResponse = await RolesAPI.fetchPermissions(params);
      if (permissionsResponse && permissionsResponse.data) {
        const permissionsData = permissionsResponse.data.content;
        dispatch(savePermissions(permissionsData));
        const editablePermissions = _.filter(permissionsData, permission => !_.includes(DEFAULT_PERMISSIONS, permission.name));
        const parsedPermissions = PermissionsParserV0.parseArray(permissionsData);
        const editableParsedPermissions = PermissionsParserV0.parseArray(editablePermissions);
        dispatch(savePermissionsParsed(_.groupBy(parsedPermissions, 'subject')));
        dispatch(saveEditablePermissionsParsed(_.groupBy(editableParsedPermissions, 'subject')));
        dispatch(savePermissionsPagination(_.omit(permissionsResponse.data, 'content')));
        return permissionsData;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAndAppendPermissions(page = 0, pageSize = 50) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
      };
      const permissionsResponse = await RolesAPI.fetchPermissions(params);
      if (permissionsResponse && permissionsResponse.data) {
        dispatch(appendPermissions(permissionsResponse.data.content));
        dispatch(savePermissionsPagination(_.omit(permissionsResponse.data, 'content')));
        return permissionsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchRoleDetails(roleId) {
  return async (dispatch) => {
    try {
      const response = await RolesAPI.fetchRoleDetails(roleId);
      if (response && response.data) {
        const abilities = PermissionsParserV0.parseArray(response.data.permissions);
        const detailedRole = { ...response.data, abilities };
        dispatch(updateRoleInState(detailedRole));
        return detailedRole;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchRolesAndPermissions() {
  return async (dispatch, getState) => {
    await dispatch(fetchRoles());
    const roles = getState().roles.data;
    const approvationCalls = _.map(roles, role => dispatch(fetchRoleDetails(role.id)));
    await Promise.all(approvationCalls);
  };
}

export function editRole(roleId, roleDTO) {
  return async (dispatch, getState) => {
    try {
      const augmentedPermissionIds = dispatch(augmentPermissions(roleDTO.permissionsIds));
      const response = await RolesAPI.updateRole(roleId, { ...roleDTO, permissionsIds: augmentedPermissionIds });
      if (response && response.data) {
        dispatch(updateRoleInState(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}

export function deleteRole(roleId) {
  return async (dispatch) => {
    try {
      const userResponse = await RestService.fetchPlatformUsers({ roleIds: roleId });
      if (userResponse && userResponse.data && userResponse.data.content && _.isEmpty(userResponse.data.content)) {
        const response = await RolesAPI.deleteRole(roleId);
        if (response && response.data) {
          dispatch(removeRoleFromState(roleId));
          return response.data;
        }
      } else {
        throw new Error('ROLE_IN_USE_ERROR');
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createRole(roleDTO) {
  return async (dispatch, getState) => {
    try {
      const augmentedPermissionIds = dispatch(augmentPermissions(roleDTO.permissionsIds));
      const response = await RolesAPI.createRole({ ...roleDTO, permissionsIds: augmentedPermissionIds });
      if (response && response.data) {
        dispatch(updateRoleInState(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
      ) throw (error.response.data.code);
      throw error;
    }
  };
}
