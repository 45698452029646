import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ShowIcon from '@material-ui/icons/Visibility';
import HideIcon from '@material-ui/icons/VisibilityOff';

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = 'required';
  }

  if (!values.email) {
    errors.email = 'required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    errors.email = 'invalidEmailAddress';
  }

  return errors;
};

@reduxForm({ form: 'LoginForm', validate })
class LoginForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isPasswordVisible: false,
    };
  }

  onTogglePasswordVisibility() {
    const { isPasswordVisible } = this.state;
    this.setState({ isPasswordVisible: !isPasswordVisible });
  }

  render() {
    const { isPasswordVisible } = this.state;
    return (
      <div>
        <Entity
          name="email"
          componentClass={Field}
          className="_lr-hide"
          componentAttribute="placeholder"
          entity="insertAccountEmail"
          titleColor="white"
          title={<Entity entity="email" />}
          component={CustomField}
        />
        <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Entity
            componentClass={Field}
            name="password"
            type={isPasswordVisible ? 'text' : 'password'}
            className="_lr-hide"
            componentAttribute="placeholder"
            entity="insertPassword"
            titleColor="white"
            containerStyle={{ width: '100%' }}
            title={<Entity entity="password" />}
            component={CustomField}
          />
          <IconButton style={{ position: 'absolute', right: '5px', bottom: '9px' }} onClick={() => this.onTogglePasswordVisibility()}>
            {!isPasswordVisible ? <ShowIcon style={{ color: '#3f3f3f', fontSize: 20 }} /> : <HideIcon style={{ color: '#3f3f3f', fontSize: 20 }} />}
          </IconButton>
        </div>
      </div>
    );
  }
} 

export default LoginForm;
