/* eslint-disable no-await-in-loop */
import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import {
  SAVE_REGISTRY_ITEMS,
  APPEND_REGISTRY_ITEMS,
  SET_ITEM_REGISTRY_PAGINATION_DATA,
  SELECT_REGISTRY_ITEM,
  SET_ITEM_REGISTRY_FILTER,
  UPDATE_ITEM,
  RESET_ITEM_REGISTRY_FILTERS,
  RESET_ITEM_REGISTRY_DATA,
  RESET_ITEM_PAGINATION_DATA,
  SET_ITEM_REGISTRY_OPERATIONAL_MODE,
} from './actionTypes/itemRegistry';
import * as UtilsActions from '../actions/utils.actions';
import * as formatter from '../../_config/formatter';
import * as ItemRegistryAPI from '../../_config/itemsRegistryAPI';
import * as ModalActions from '../actions/modal.actions';
import { BACKGROUND_TASK_TYPES, EXPORT_FORMATS, ITEM_DELIVERY_TYPES_NAMES, ITEM_TYPES_NAMES } from '../../_config/consts';

export function setItemRegistryOperationalMode(value) {
  return {
    type: SET_ITEM_REGISTRY_OPERATIONAL_MODE,
    value,
  };
}

export function saveRegistryItems(items) {
  return {
    type: SAVE_REGISTRY_ITEMS,
    items,
  };
}

export function appendRegistryItems(items) {
  return {
    type: APPEND_REGISTRY_ITEMS,
    items,
  };
}

export function saveRegistryItemsPaginationData(pagination) {
  return {
    type: SET_ITEM_REGISTRY_PAGINATION_DATA,
    pagination,
  };
}

export function setItemRegistryFilter(field, value) {
  return {
    type: SET_ITEM_REGISTRY_FILTER,
    field,
    value,
  };
}

export function setSelectedRegistryItem(item) {
  return {
    type: SELECT_REGISTRY_ITEM,
    item,
  };
}

export function updateRegistryItemOnState(item) {
  return {
    type: UPDATE_ITEM,
    item,
  };
}

export function resetItemRegistryData() {
  return {
    type: RESET_ITEM_REGISTRY_DATA,
  };
}

export function resetItemRegistryFilters() {
  return {
    type: RESET_ITEM_REGISTRY_FILTERS,
  };
}

export function resetItemRegistryPagination() {
  return {
    type: RESET_ITEM_PAGINATION_DATA,
  };
}

export function fetchRegistryItems(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().itemRegistry.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const itemResponse = await ItemRegistryAPI.getRegistryItems(params);
      if (itemResponse && itemResponse.data && itemResponse.data.content) {
        dispatch(saveRegistryItems(itemResponse.data.content));
        dispatch(saveRegistryItemsPaginationData(_.omit(itemResponse.data, 'content')));
        return itemResponse.data.content;
      }
      throw new Error();

    } catch (error) {
      throw error;
    }
  }
}

export function fetchAndAppendRegistryItems(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().itemRegistry.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const itemResponse = await ItemRegistryAPI.getRegistryItems(params);
      if (itemResponse && itemResponse.data && itemResponse.data.content) {
        dispatch(appendRegistryItems(itemResponse.data.content));
        dispatch(saveRegistryItemsPaginationData(_.omit(itemResponse.data, 'content')));
        return itemResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllItemsForExport(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().itemRegistry.data.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'creatingCardsExportFile',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        ...filters,
      };
      let itemsResponse = await ItemRegistryAPI.getRegistryItems(params);
      if (itemsResponse && itemsResponse.data && itemsResponse.data.content && !_.isEmpty(itemsResponse.data.content)) {
        const items = [...itemsResponse.data.content];
        let pagination = _.omit(itemsResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          itemsResponse = await ItemRegistryAPI.getRegistryItems(params);
          items.push(...itemsResponse.data.content);
          pagination = _.omit(itemsResponse.data, 'content');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { items, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function createRegistryItem(itemData) {
  return async (dispatch, getState) => {
    try {
      const formattedItemOutDTO = formatter.formatOutputData(formatter.REGISTRY_ITEM, itemData);
      const createResponse = await ItemRegistryAPI.createRegistryItem(formattedItemOutDTO);
      if (createResponse && createResponse.data) {
        return createResponse.data;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function updateRegistryItem(itemId, itemData) {
  return async (dispatch, getState) => {
    try {
      const formattedItemOutDTO = formatter.formatOutputData(formatter.REGISTRY_ITEM, itemData);
      const updateResponse = await ItemRegistryAPI.updateRegistryItem(itemId, formattedItemOutDTO);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteRegistryItem(itemId) {
  return async (dispatch, getState) => {
    try {
      const deleteResponse = await ItemRegistryAPI.deleteRegistryItem(itemId);
      if (deleteResponse && deleteResponse.data) {
        return deleteResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function getRegistryItemDetails(itemId) {
  return async (dispatch, getState) => {
    try {
      const detailsResponse = await ItemRegistryAPI.getRegistryItemDetails(itemId);
      if (detailsResponse && detailsResponse.data) {
        return detailsResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function exportItems(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { items, stopFetching } = await dispatch(fetchAllItemsForExport());
      if (!stopFetching) {
        const exportData = [];
        _.each(items, (item) => {
          exportData.push({
            ID: item.id,
            Name: item.name,
            Description: item.description,
            Type: L20NContext.getSync(ITEM_TYPES_NAMES[item.type]),
            'Master Card Id': item.masterCardId,
            'Master Card Number': item.masterCardNumber,
            'Physical Key Id': item.physicalKeyId,
            'Signature Collected': item.signatureCollected,
            'Delivery Mode': L20NContext.getSync(ITEM_DELIVERY_TYPES_NAMES[item.deliveryMode]),
            'Associated Locks': item.associatedLocks,
            'Associated User': item.recipient ? `${item.recipient.firstname || '---'} ${item.recipient.lastname || '---'} -  ${item.recipient.email || '---'}` : '---',
            Date: item.deliveredOn ? `${moment(item.deliveredOn).format('DD MMMM YYYY')}` : '---',
            Notes: item.notes,
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { items: ws }, SheetNames: ['items'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Registry_Export.${fileExtension}`);
        return items;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}