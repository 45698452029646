import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as LockActions from '../../redux/actions/lock.actions';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import LockDetailsRow from './LockDetailsRow.jsx';


@connect((state) => ({
  locks: state.locks,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
}))
class LocksListView extends React.Component {


  componentWillUnmount() {
    this.closeOperationalSection();
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(LockActions.setOperationalMode(false));
  }

  render() {
    const { locks: { data: { content: locksData, pagination: { number: currentPage, totalPages } } }, locks: { selectedLock }, listContainerStyle, onAppendLocks, onOpenLock, viewLoading, onSelectLock } = this.props;
    return (
        <div className="list-view details-section" style={{ top: 190, paddingBottom: 40, width: '90%', ...listContainerStyle }}>
          <InfiniteScroll
            initialLoad={false}
            loadMore={() => onAppendLocks(currentPage + 1)}
            hasMore={currentPage + 1 < totalPages}
            loader={<ListLoadingView />}
            useWindow={false}
          >
            <div style={{ width: '40%' }}>
              {!_.isEmpty(locksData) ?
                _.map(locksData, lock =>
                  <LockDetailsRow
                    key={lock.id}
                    lock={lock}
                    isSelected={selectedLock && selectedLock.id === lock.id}
                    onClick={() => onSelectLock(lock)}
                    onOpenLock={() => onOpenLock(lock)}
                  />) : (
                    <div>
                      {!viewLoading ? (
                        <EmptyView
                          title="noLock"
                          iconName="icon-simple-line-icons-lock"
                          subtitle="startAddLock"
                        />) : <ListLoadingView />}
                    </div>
                  )
              }
            </div>
          </InfiniteScroll>
        </div>
    );
  }
}

export default LocksListView;