
import axios from 'axios';
import { FRESHDESK_API_KEY } from './consts';

const config = {
  auth: {
    username: FRESHDESK_API_KEY,
    password: 'X',
  },
};

export const axiosInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://sofialocks.freshdesk.com/api/v2',
});

export function createFreshdeskTicket(ticketDTO) {
  return axiosInstance.post('/tickets', ticketDTO, config);
}


export function getGroups() {
  return axiosInstance.get('/groups', config);
}