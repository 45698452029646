import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_VISITORS,
  SAVE_VISITOR,
  APPEND_VISITORS,
  RESET_VISITORS_DATA,
  RESET_VISITORS_FILTERS,
  RESET_VISITORS_PAGINATION_DATA,
  SELECT_VISITOR,
  SET_VISITORS_FILTER,
  SET_VISITORS_OPERATIONAL_MODE,
  SET_VISITORS_PAGINATION_DATA,
  UPDATE_VISITOR,
  SAVE_VISITORS_CHART_DATA,
  SET_VISITORS_ORDER,
} from '../actions/actionTypes/visitors';
import moment from 'moment';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {
      fromDate: moment().startOf('day').valueOf(),
      toDate: moment().endOf('day').valueOf(),
    },
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    },
    chartData: [],
    lastUpdate: 0,
  },
  isOperationalMode: false,
  selectedVisitor: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_VISITORS:
      return state.setIn(['data', 'content'], action.visitors);
    case APPEND_VISITORS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.visitors]);
    case SAVE_VISITOR:
      return state.setIn(['data', 'content'], [...state.data.content, action.visitor]);
    case SET_VISITORS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SELECT_VISITOR:
      return state.set('selectedVisitor', action.visitor);
    case SET_VISITORS_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_VISITORS_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case UPDATE_VISITOR: {
      const index = _.findIndex(state.data.content, data => data.id === action.visitor.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.visitor);
      }
      return state;
    }
    case SET_VISITORS_ORDER:
      return state.setIn(['data', 'sorting', 'orderBy'], action.orderBy)
                  .setIn(['data', 'sorting', 'orderDir'], action.orderDir);
    case SAVE_VISITORS_CHART_DATA:
      return state.setIn(['data', 'chartData'], action.chartData).setIn(['data', 'lastUpdate'], action.lastUpdate);
    case RESET_VISITORS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case RESET_VISITORS_DATA:
      return state.set('data', initialState.data);
    case RESET_VISITORS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    default:
      return state;
  }
}
