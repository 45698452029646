import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import * as UserActions from '../../../redux/actions/user.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import * as ModalActions from '../../../redux/actions/modal.actions';
import { GREEN, INTEGRATIONS_VALUES_MAP, RED, SUBSCRIPTION_TYPES } from '../../../_config/consts';
import NexudusLoginForm from '../../forms/Integrations/NexudusLoginForm.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import CheckOutlineIconCustom from '../../CustomIcons/CheckOutlineIconCustom.jsx';
import NexudusSelectBusinessForm from '../../forms/Integrations/NexudusSelectBusinessForm.jsx';
import { IconButton } from '@material-ui/core';
import ArrowLeftIconCustom from '../../CustomIcons/ArrowLeftIconCustom.jsx';
import { Modal } from '@sketchpixy/rubix';
import NexudusDeactivationForm from '../../forms/Integrations/NexudusDeactivationForm.jsx';
import { localizeHelpCenterLink } from '../../../_config/utils.js';


@connect(state => ({ language: state.settings.language }))
class NexudusSettingsOperationalSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isActive: null,
      showNexudusLoginModal: false,
      nexudusData: {
        accessToken: null,
        refreshToken: null,
        businesses: null,
      },
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const isActive = dispatch(UserActions.userHasIntegrationActive(SUBSCRIPTION_TYPES.NEXUDUS_INTEGRATION));
    this.setState({ isActive });
  }

  async onNexudusLogin(loginData) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const loginResponse = await dispatch(UserActions.loginWithNexudusAndGetBusinesses(loginData.email, loginData.password));
      if (loginResponse && loginResponse.businesses && loginResponse.accessToken && loginResponse.refreshToken) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        const nexudusData = {
          accessToken: loginResponse.accessToken,
          refreshToken: loginResponse.refreshToken,
          businesses: loginResponse.businesses,
        };
        this.setState({ nexudusData });
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      let errorMessage = 'integrationConnectionError';
      if (error && error.message === 'NEXUDUS_ACTIVATION_FAILED_NO_BUSINESSES') {
        errorMessage = 'nexudusErrorNoBusiness';
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }

  async onActivateNexudusIntegration(selectedBusinessIds, syncAllUsers) {
    const { dispatch } = this.props;
    const { nexudusData: { accessToken, refreshToken } } = this.state;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(UserActions.activateNexudusIntegrationForBusiness(accessToken, refreshToken, selectedBusinessIds, syncAllUsers));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(UserActions.fetchCurrentActiveIntegrations());
      this.setState({ isActive: true });
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionError" /></h6>),
        },
      }));
    }
  }

  async onDeactivateIntegration(nexudusLoginData) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const loginResponse = await dispatch(UserActions.loginWithNexudus(nexudusLoginData.email, nexudusLoginData.password));
      const nexdusIntegrationDTO = dispatch(UserActions.getActiveIntegration(SUBSCRIPTION_TYPES.NEXUDUS_INTEGRATION));
      if (loginResponse && loginResponse.accessToken && nexdusIntegrationDTO && nexdusIntegrationDTO.id) {
        await dispatch(UserActions.disableIntegration(nexdusIntegrationDTO.id, loginResponse.accessToken));
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(UserActions.fetchCurrentActiveIntegrations());
        this.setState({ isActive: false, showNexudusLoginModal: false });
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedSuccess" /></h6>),
          },
        }));
      } else {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
          },
        }));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
        },
      }));
    }

  }

  onGoBackToNexudusLogin() {
    const { nexudusData } = this.state;
    const updatedState = {
      ...nexudusData,
      businesses: null,
    };
    this.setState({ nexudusData: updatedState });
  }

  onOpenActivationInfo() {
    const helpURL = localizeHelpCenterLink('activate-nexudus-integration');
    window.open(helpURL);
  }

  render() {
    const { isActive, showNexudusLoginModal, nexudusData: { businesses } } = this.state;
    const { dispatch } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25, paddingTop: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <img alt="nexudus" src="/imgs/common/integrationLogos/nexudus.png" style={{ height: 50, alignSelf: 'center' }} />
        {isActive ? (
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <div style={{ width: '70%', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CheckOutlineIconCustom style={{ color: GREEN, width: 30, marginRight: 10 }} />
              <h4 style={{ color: GREEN, fontWeight: 'bold', fontSize: 20 }}><Entity entity="integrationCorrectlyConfigured" /></h4>
            </div>
            <MDButton
              title={<Entity key={businesses} entity="disableIntegrationTitle" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor={RED}
              containerStyle={{ margin: 0, marginTop: 10, width: '80%' }}
              onClick={() => this.setState({ showNexudusLoginModal: true })}
            />
          </div>
        ) : (
          <div style={{ width: '70%', margin: 'auto' }}>
            <h4 style={{ color: '#3f3f3f', textAlign: 'center', marginTop: 10, marginBottom: 30 }}><Entity entity="connectYoutAccount" /></h4>
            {!businesses ? (
              <div>
                <div style={{ marginTop: 20 }}>
                  <NexudusLoginForm
                    onSubmit={loginData => this.onNexudusLogin(loginData)}
                  />
                </div>
                <MDButton
                  title={<Entity entity="login" key={businesses} />}
                  titleStyle={{ marginTop: 0, fontSize: 13 }}
                  backgroundColor="#ff9b00"
                  containerStyle={{ margin: 0, marginTop: 10, marginBottom: 10 }}
                  onClick={() => dispatch(submit('NexudusLoginForm'))}
                />
              </div>
            ) : (
              <div>
                <div style={{ marginTop: 20 }}>
                  <NexudusSelectBusinessForm
                    businesses={businesses}
                    onSubmit={businessData => this.onActivateNexudusIntegration(businessData.business, businessData.syncAllUsers)}
                  />
                </div>
                <MDButton
                  title={<Entity key={businesses} entity="connectYourAccount" />}
                  titleStyle={{ marginTop: 0, fontSize: 13 }}
                  backgroundColor="#ff9b00"
                  containerStyle={{ margin: 0, marginTop: 10 }}
                  onClick={() => dispatch(submit('NexudusSelectBusinessForm'))}
                />
                <IconButton onClick={() => this.onGoBackToNexudusLogin()}>
                  <ArrowLeftIconCustom style={{ color: '#3f3f3f', width: 20, marginRight: 5 }} />
                </IconButton>
                <Entity style={{ color: '#3f3f3f', marginTop: 2 }} entity="backToLogin" />
              </div>
          )}
          </div>
        )}
        <Modal
          show={showNexudusLoginModal}
          className="alert-container full-height-modal"
          onHide={() => this.setState({ showNexudusLogin: false })}
        >
          <div style={{ padding: 25, display: 'flex', flexDirection: 'column' }}>
            <img alt="nexudus" src="/imgs/common/integrationLogos/nexudus.png" style={{ height: 50, alignSelf: 'center' }} />
            <h4 style={{ color: '#3f3f3f', textAlign: 'center', marginTop: 10, marginBottom: 30 }}><Entity entity="disableIntegrationLoginDescription" /></h4>
            <NexudusDeactivationForm
              onSubmit={loginData => this.onDeactivateIntegration(loginData)}
            />
            <MDButton
              title={<Entity entity="login" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor="#ff9b00"
              containerStyle={{ margin: 0, marginTop: 10, marginBottom: 10 }}
              onClick={() => dispatch(submit('NexudusDeactivationForm'))}
            />
          </div>
        </Modal>
        <h5
          className="link-label"
          style={{ margin: 'auto', width: 'fit-content', marginTop: 20, fontSize: 16, textDecoration: 'underline' }}
          onClick={() => this.onOpenActivationInfo()}
        >
          <Entity entity="findOutMore" />
        </h5>
      </div>
    );
  }
}

export default NexudusSettingsOperationalSection;
