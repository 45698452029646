import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_ACCESS_PROFILES,
  APPEND_ACCESS_PROFILES,
  SAVE_ACCESS_PROFILE,
  SET_ACCESS_PROFILES_PAGINATION_DATA,
  SELECT_ACCESS_PROFILE,
  SET_ACCESS_PROFILES_FILTER,
  UPDATE_ACCESS_PROFILE,
  RESET_ACCESS_PROFILES_FILTERS,
  RESET_ACCESS_PROFILES_DATA,
  RESET_ACCESS_PROFILE_PAGINATION_DATA,
  SET_ACCESS_PROFILES_OPERATIONAL_MODE,
  SAVE_ACCESS_PROFILE_GUESTS_DATA,
  APPEND_ACCESS_PROFILE_GUESTS_DATA,
  SAVE_ACCESS_PROFILE_GUESTS_DATA_PAGINATION,
  RESET_ACCESS_PROFILE_GUESTS_DATA,
} from '../actions/actionTypes/accessProfiles';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
  },
  isOperationalMode: false,
  selectedAccessProfile: {},
  guests: {
    content: [],
    pagination: {},
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ACCESS_PROFILES:
      return state.setIn(['data', 'content'], action.accessProfiles);
    case APPEND_ACCESS_PROFILES:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.accessProfiles]);
    case SAVE_ACCESS_PROFILE:
      return state.setIn(['data', 'content'], [...state.data.content, action.accessProfile]);
    case SET_ACCESS_PROFILES_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SELECT_ACCESS_PROFILE:
      return state.set('selectedAccessProfile', action.accessProfile);
    case SET_ACCESS_PROFILES_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_ACCESS_PROFILES_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case UPDATE_ACCESS_PROFILE: {
      const index = _.findIndex(state.data.content, data => data.id === action.accessProfile.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.accessProfile);
      }
      return state;
    }
    case SAVE_ACCESS_PROFILE_GUESTS_DATA:
      return state.setIn(['guests', 'content'], action.guests);
    case APPEND_ACCESS_PROFILE_GUESTS_DATA:
      return state.setIn(['guests', 'content'], [...state.guests.content, ...action.guests]);
    case SAVE_ACCESS_PROFILE_GUESTS_DATA_PAGINATION:
      return state.setIn(['guests', 'pagination'], action.pagination);
    case RESET_ACCESS_PROFILE_GUESTS_DATA:
      return state.set('guests', initialState.guests);
    case RESET_ACCESS_PROFILES_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case RESET_ACCESS_PROFILES_DATA:
      return state.set('data', initialState.data);
    case RESET_ACCESS_PROFILE_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    default:
      return state;
  }
}
