import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import {
  Icon, Table
} from '@sketchpixy/rubix';

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  titleText: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'left',
  },
  sectionText: {
    margin: 0,
    marginRight: 5,
    fontSize: 16,
    lineHeight: 1.4,
    color: '#3f3f3f',
    textAlign: 'left',
  },
  exampleContainer: {
    marginTop: 15,
    borderRadius: 5,
  },
  exampleText: {
    marginLeft: 12,
    marginTop: 10,
    fontSize: 15,
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

const columns = [{
  id: 'action',
  title: 'action',
},
{
  id: 'effect',
  title: 'effect',
},
];

const AnalyticsRuleExplanationTable = ({ classes }) => (
  <div style={{ marginTop: 20, marginBottom: 30 }}>
    <Table responsive bordered>
      <thead>
        <tr>
          {_.map(columns, (column, index) => (
            <th className="table-align-left" style={{ backgroundColor: '#f0f0f0' }}>
              <h4 style={{color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}><Entity key={column.title} entity={column.title} /></h4>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            className="table-align-left label-responsive"
            style={{
              marginLeft: 15, color: 'dimgray', fontSize: 15,
            }}
          >
            <Entity entity="guestDeleteBeforeCheckInWindowAction" />
          </td>
          <td
            className="table-align-left label-responsive"
            style={{
              marginLeft: 15, color: 'dimgray', fontSize: 15,
            }}
          >
            <Entity entity="guestDeleteBeforeCheckInWindowEffect" />
          </td>
        </tr>
        <tr>
          <td
            className="table-align-left label-responsive"
            style={{
              marginLeft: 15, color: 'dimgray', fontSize: 15,
            }}
          >
            <Entity entity="guestDoNotCheckinAction" />
          </td>
          <td
            className="table-align-left label-responsive"
            style={{
              marginLeft: 15, color: 'dimgray', fontSize: 15,
            }}
          >
            <Entity entity="guestDoNotCheckinEffect" />
          </td>
        </tr>
        <tr>
          <td
            className="table-align-left label-responsive"
            style={{
              marginLeft: 15, color: 'dimgray', fontSize: 15,
            }}
          >
            <Entity entity="guestDeleteInCheckinWindowAction" />
          </td>
          <td
            className="table-align-left label-responsive"
            style={{
              marginLeft: 15, color: 'dimgray', fontSize: 15,
            }}
          >
            <Entity entity="guestDeleteInCheckinWindowEffect" />
          </td>
        </tr>
        <tr>
          <td
            className="table-align-left label-responsive"
            style={{
              marginLeft: 15, color: 'dimgray', fontSize: 15,
            }}
          >
            <Entity entity="guestReserveAndCheckInAction" />
          </td>
          <td
            className="table-align-left label-responsive"
            style={{
              marginLeft: 15, color: 'dimgray', fontSize: 15,
            }}
          >
            <Entity entity="guestReserveAndCheckInEffect" />
          </td>
        </tr>
        <tr>
          <td
            className="table-align-left label-responsive"
            style={{
              marginLeft: 15,
              fontSize: 15,
              color: 'dimgray',
            }}
          >
            <Entity entity="guestReserveAndDeleteAction" />
          </td>
          <td
            className="table-align-left label-responsive"
            style={{
              marginLeft: 15,
              fontSize: 15,
              color: 'dimgray',
            }}
          >
            <Entity entity="guestReserveAndDeleteEffect" />
          </td>
        </tr>
      </tbody>
    </Table>
  </div>
);
export default withStyles(styles)(AnalyticsRuleExplanationTable);
