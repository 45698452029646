import React, { Component } from 'react';
import {
  FormControl,
} from '@sketchpixy/rubix';
import _ from 'lodash';
import moment from 'moment';
import { Card, withStyles, IconButton, createTheme } from '@material-ui/core';
import GoToPageIcon from '@material-ui/icons/CallMissedOutgoing';
import BarChartIcon from '@material-ui/icons/InsertChart';
import TouchIcon from '@material-ui/icons/TouchApp';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Permission from '../../Permission/Permission.jsx';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import { CircularProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { BLUE, PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts';
import ScrollableBarChart from '../../Charts/ScrollableBarChart.jsx';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider';
import { filterAccessLocksData } from '../../../redux/actions/metrics.actions.js';


const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  subtitleText: {
    margin: 0,
    fontWeight: '100',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  header: {
    marginTop: -10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  chartContainer: {
    paddingTop: 10,
  },
  refreshHeader: {
    position: 'absolute',
    right: 10,
    top: 5,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
    color: '#3f3f3f',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '30%',
    marginTop: 10,
    borderRadius: 5,
  },
  searchHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAnalyticsView = () => (
  <div style={{ height: 100, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <BarChartIcon style={{ color: '#bdbdbd', fontSize: 50 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFound" /></h3>
  </div>
);


class SmartlocksAccessBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
      chartData: props.accessChartData,
    };
  }


  componentWillReceiveProps(nextProps) {
    const { accessChartData } = nextProps;
    const { accessChartData: oldAccessChartData } = this.props;
    if (accessChartData !== oldAccessChartData) {
      const { filterText } = this.state;
      if (filterText) {
        this.onSearchLock(filterText);
      } else {
        this.setState({ chartData: accessChartData });
      }
    }
  }

  onElementClick(chartElement) {
    const { onDataClick } = this.props;
    const { chartData } = this.state;
    if (onDataClick && chartElement && chartElement[0] && chartElement[0]['_index']) {
      const elementIndex = _.isArray(chartElement) ? chartElement[0]['_index'] : chartElement['_index'];
      const elementName = chartData.labels[elementIndex];
      const element = _.find(chartData.dataLocks, lockData => lockData.name === elementName);
      if (element) {
        onDataClick(element);
      }
    }
  }

  onSearchLock(text) {
    const { accessChartData } = this.props;
    this.setState({ filterText: text });
    if (text && _.size(text) >= 2) {
      const data = filterAccessLocksData(text, accessChartData);
      this.setState({ chartData: data });
    }
    if (!text) {
      this.onSearchReset();
    }
  }

  onSearchReset() {
    const { accessChartData } = this.props;
    this.setState({ filterText: '', chartData: accessChartData });
  }

  render() {
    const { accessChartData, lastUpdate, textColor, isRefreshing, classes, onGoToEventPage, onRefreshMetric } = this.props;
    const { filterText, chartData } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1 }} elevation={3}>
          <div className={classes.header}>
            <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="mostUsedLocksToday" /></h3>
            <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.LOG} abilityHelper={AbilityProvider.getAbilityHelper()}>
              <IconButton onClick={() => onGoToEventPage()} style={{ marginLeft: 10, marginTop: 2 }}>
                <GoToPageIcon className={classes.logIcon} style={{ color: textColor, fontSize: 20 }} />
              </IconButton>
            </Permission>
          </div>
          <div className={classes.searchHeader}>
            <Card className={classes.searchContainer}>
              <FormControl
                type="text"
                value={filterText}
                placeholder={L20NContext.getSync('searchSmartLock')}
                className="form-control-noBorder"
                style={{ width: '100%', borderRadius: 5 }}
                onChange={e => this.onSearchLock(e.target.value)}
              />
              {filterText ? (
                <IconButton style={{ height: 20, width: 20, marginLeft: -60, marginRight: 10 }} onClick={() => this.onSearchReset()}>
                  <CloseIcon />
                </IconButton>
              ) : null}
              <SearchIcon style={{ marginLeft: filterText ? 0 : -30 }} />
            </Card>
          </div>
          <div className={classes.chartContainer}>
            {accessChartData && !_.isEmpty(accessChartData) ? (
              <ScrollableBarChart
                height={100}
                data={_.cloneDeep(chartData)}
                dataSetSize={_.size(chartData.labels)}
                showLegend
                onElementClick={(chartElement) => this.onElementClick(chartElement) }
              />
            ) : <EmptyAnalyticsView />}
          </div>
          {accessChartData && !_.isEmpty(accessChartData) ? (
            <div className={classes.header} style={{ alignSelf: 'flex-start', marginLeft: 10, marginBottom: 10 }}>
              <TouchIcon className={classes.logIcon} style={{ color: textColor, marginRight: 5 }} />
              <h5 className={classes.subtitleText} style={{ color: textColor || '#3f3f3f' }}><Entity entity="clickChartShowDetails" /></h5>
            </div>
          ) : null}
          <div className={classes.refreshHeader}>
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}:{moment(lastUpdate).format('LLL')}</h5>
            <IconButton
              disabled={isRefreshing}
              style={{ opacity: isRefreshing ? 0.5 : 1 }}
              onClick={() => onRefreshMetric('smartLocksEvents')}
            >
              <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
            </IconButton>
          </div>
          {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(SmartlocksAccessBarChart);
