import axios from 'axios';

const configurationInstance = axios.create({
  timeout: 30000,
  baseURL: typeof window !== 'undefined' && window.location.origin,
});

export function fetchConfiguration() {
  return configurationInstance.get('/api/v1/backendInfo');
}
