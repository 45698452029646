import { Card, createTheme, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { BLUE } from '../../../_config/consts';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginBottom: 5,
    fontSize: 25,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '110%',
    borderRadius: 10,
    margin: 10,
    padding: 15,
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 15,
    borderLeft: '4px solid #157495',
  },
  descriptionText: {
    color: '#3f3f3f',
    margin: 0,
    fontWeight: 'normal',
    marginTop: 10,
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  logo: {
    position: 'absolute',
    height: 27,
    right: 10,
    top: 10,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const CommunicationLicenseCard = ({ classes, license }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Card className={classes.container}>
        <h2 className={classes.titleText}><Entity entity="communicationsPluginTitle" /></h2>
        <h4 className={classes.descriptionText}><Entity entity="communicationsPluginDescription" /></h4>
      </Card>
    </MuiThemeProvider>
  );
};
export default withStyles(styles)(CommunicationLicenseCard);
