import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_FLOOR_PLANS,
  APPEND_FLOOR_PLANS,
  RESET_FLOOR_PLANS_DATA,
  RESET_FLOOR_PLANS_FILTERS,
  RESET_FLOOR_PLANS_PAGINATION_DATA,
  SELECT_FLOOR_PLAN,
  SET_FLOOR_PLANS_FILTER,
  SET_FLOOR_PLANS_OPERATIONAL_MODE,
  SET_FLOOR_PLANS_PAGINATION_DATA,
  SAVE_OR_UPDATE_FLOOR_PLAN,
} from '../actions/actionTypes/floorplans';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
  },
  isOperationalMode: false,
  selectedFloorPlan: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_FLOOR_PLANS:
      return state.setIn(['data', 'content'], action.floorPlans);
    case APPEND_FLOOR_PLANS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.floorPlans]);
    case SET_FLOOR_PLANS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SELECT_FLOOR_PLAN:
      return state.set('selectedFloorPlan', action.floorPlan);
    case SET_FLOOR_PLANS_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_FLOOR_PLANS_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case SAVE_OR_UPDATE_FLOOR_PLAN: {
      const index = _.findIndex(state.data.content, data => data.id === action.floorPlan.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.floorPlan);
      } else {
        return state.setIn(['data', 'content'], [...state.data.content, action.floorPlan]);
      }
    }
    case RESET_FLOOR_PLANS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case RESET_FLOOR_PLANS_DATA:
      return state.set('data', initialState.data);
    case RESET_FLOOR_PLANS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    default:
      return state;
  }
}
