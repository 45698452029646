import React from 'react';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import { connect } from 'react-redux';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import RolesListView from '../components/Roles/RolesListView.jsx';
import RolesOperationalSection from '../components/Roles/RolesOperationalSection.jsx';
import * as RolesActions from '../redux/actions/roles.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import { initialize, destroy, reset } from 'redux-form';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import { PERMISSIONS, PERMISSION_ENTITIES, DEFAULT_PERMISSIONS } from '../_config/consts.js';


let filterTimeout;
@connect(state => ({ roles: state.roles, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class Roles extends React.Component {

  async componentWillMount() {
    const { dispatch } = this.props;
    dispatch(RolesActions.resetRolesFilters());
    dispatch(RolesActions.setSelectedRole({}));
    this.onFetchRoles();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(RolesActions.setSelectedRole({}));
    dispatch(RolesActions.setOperationalMode(false));
  }

  async onFetchRoles() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    this.onCloseOperationalSection();
    try {
      await dispatch(RolesActions.fetchRoles());
      dispatch(RolesActions.fetchPermissions());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onNewRoleClicked() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      dispatch(destroy('RoleForm'));
      const permissions = await dispatch(RolesActions.fetchPermissions());
      dispatch(RolesActions.setSelectedRole({ ...permissions }));
      dispatch(RolesActions.setOperationalMode(true));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }
  /*
  INITIALIZE ROLE FORM
  permission ={ subject, action, id }
  acc = { ROLE: [roles.permissions], CREDENTIAL: [credentials.permissions], ...};
  */
  async onSelectRole(role) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const roleDetailed = await dispatch(RolesActions.fetchRoleDetails(role.id));
      const preFormattedPermissions = _.reduce(roleDetailed.abilities, (acc, permission) => {
        const p = _.find(roleDetailed.permissions, { id: permission.id });
        if (!_.includes(_.values(DEFAULT_PERMISSIONS), p.name)) {
          // Handle first iteration case per permission when acc[ROLE] (es) it's not preset
          if (!acc[permission.subject]) {
            acc[permission.subject] = [permission];
          // If present append permission to permission array
          } else {
            acc[permission.subject] = [...acc[permission.subject], permission];
          }
        }
        return acc;
      }, {});

      const formattedPermissions = _.mapValues(preFormattedPermissions, (v) => {
        const permissionIds = [];
        const permissionPartitions = _.partition(v, { action: PERMISSIONS.READ });
        const [readPermissions, controlPermissions] = permissionPartitions;
        if (readPermissions && readPermissions[0]) {
          permissionIds.push(String(readPermissions[0].id));
        }
        const controlPermissionIds = _.map(controlPermissions, p => p.id);
        const joinedControlPermissions = controlPermissionIds.join(',');
        if (joinedControlPermissions && joinedControlPermissions.length) {
          permissionIds.push(joinedControlPermissions);
        }
        return permissionIds;
      });

      dispatch(RolesActions.setSelectedRole(roleDetailed));
      dispatch(initialize('RoleForm', {
        id: roleDetailed.id,
        name: role.label,
        ...formattedPermissions,
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(RolesActions.setOperationalMode(true));
    } catch (error) {
      dispatch(RolesActions.setSelectedRole(role));
      dispatch(RolesActions.setOperationalMode(true));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onCloseOperationalSection() {
    const { dispatch } = this.props;
    dispatch(RolesActions.setSelectedRole({}));
    dispatch(RolesActions.setOperationalMode(false));
  }

  render() {
    const { roles, themeName, dispatch, viewLoading } = this.props;
    const canCreateRole = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.ROLE);
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={canCreateRole ? () => this.onNewRoleClicked() : null}
          onSearchReset={() => this.onFetchRoles()}
          newEntityTitle="addRole"
          newEntityIconName="icon-simple-line-icons-user-following"
          isLoading={viewLoading}
        />
        <RolesListView
          onNewRole={() => this.onNewRoleClicked()}
          onSelectRole={role => this.onSelectRole(role)}
        />
        <OperationalView
          themeName={themeName}
          isVisible={roles.isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          title={<Entity entity="sectionTitle" data={{ name: 'roleOperational' }} />}
        >
          <RolesOperationalSection />
        </OperationalView>
      </div>
    );
  }
} 

export default Roles;
