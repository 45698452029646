import { Card, CircularProgress, IconButton, createTheme, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React, { Component } from 'react';
import { BLUE } from '../../../_config/consts';
import UsersIconCustom from '../../CustomIcons/UsersIconCustom.jsx';


const styles = theme => ({
  titleText: {
    margin: 0,
    color: '#3f3f3f',
    marginLeft: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    borderRadius: 10,
    margin: 5,
    justifyContent: 'center',
    zIndex: -6000,
  },
  icon: {
    fontSize: 30,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '37px !important',
    width: '38px !important',
  },
  chartsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  refreshButton: {
    position: 'absolute',
    right: 5,
    top: -5,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  refreshIcon: {
    fontSize: 20,
    color: '#3f3f3f',
  },
  numberContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    margin: 10,
    marginBottom: 0,
    height: 2,
  },
  linkText: {
    textAlign: 'center',
    paddingTop: 12,
    margin: 0,
    paddingBottom: 4,
    width: '100%',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

class GuestsMetricChart extends Component {
  render() {
    const { metric: { count }, isRefreshing, classes, onMetricClick, onShortcutClick, onRefreshMetric } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Card elevation={3} className={classes.container}>
          <div style={{ cursor: 'pointer' }} onClick={onMetricClick ? () => onMetricClick('/guests') : null}>
            <div className={classes.header}>
              <div className={classes.titleContainer}>
                <UsersIconCustom className={classes.icon} style={{ width: 30 }} />
                <h3 className={classes.titleText}><Entity entity="guests" /></h3>
              </div>
              <IconButton
                disabled={isRefreshing}
                style={{ opacity: isRefreshing ? 0.5 : 1 }}
                className={classes.refreshButton}
                onClick={(e) => {
                  e.stopPropagation()
                  onRefreshMetric('guests');
                }}
              >
                <RefreshIcon className={classes.refreshIcon} />
              </IconButton>
            </div>
            <div className={classes.chartsContainer}>
              {isRefreshing ? (
                <CircularProgress color="primary" className={classes.progress} />
                ) : (
                  <h1 className={classes.countText}>{count}</h1>
              )}
            </div>
          </div>
          <hr className={classes.separator} />
          <h4 className={`link-label ${classes.linkText}`} style={{ fontWeight: 'bold' }} onClick={() => onShortcutClick()}><Entity entity="addGuest" /></h4>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(GuestsMetricChart);
