import { Card, CircularProgress, IconButton, withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { BLUE, GREEN, ORANGE, WALLET_STATUSES, WALLET_STATUS_MAP } from '../../_config/consts';
import { calculateNextRechargeDate, getColorFromThemeName } from '../../_config/utils.js';
import AdminEventsIconCustom from '../../components/CustomIcons/AdminEventsIconCustom.jsx';
import CloudPlusIconCustom from '../../components/CustomIcons/CloudPlusIconCustom.jsx';
import CreditsIconCustom from '../../components/CustomIcons/CreditsIconCustom.jsx';
import MDButton from '../../components/MDButton/MDButton.jsx';
import WalletConsumptionSection from '../../components/Wallet/WalletConsumptionSection.jsx';
import WalletEventsSection from '../../components/Wallet/WalletEventsSection.jsx';
import WalletRechargeSection from '../../components/Wallet/WalletRechargeSection.jsx';
import WalletSubscriptionsSection from '../../components/Wallet/WalletSubscriptionsSection.jsx';
import * as CreditsActions from '../../redux/actions/cloudCredits.actions';
import * as PlatformUserActions from '../../redux/actions/platformUsers.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions.js'
import { WarningOutlined } from '@material-ui/icons';
import WarningOutlineIconCustom from '../../components/CustomIcons/WarningOutlineIconCustom.jsx';

const styles = {
  container: {
    marginTop: 20,
    height: '120vh',
    overflowY: 'scroll',
    overflowX: 'clip',
  },
  cardContainer: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 10,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 15,
    marginTop: 5,
    borderLeft: '4px solid #1B97C2',
    width: '100%',
    marginBottom: 20,
    position: 'relative',
  },
  cardTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boldText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  cardInfoContainer: {
    position: 'absolute',
    right: -10,
    top: 1,
  },
  cardBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
  metricsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  counterHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  counterContainer: {
    padding: 10,
    borderRadius: 10,
    margin: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 200,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  progress: {
    margin: 0,
    padding: 0,
    height: '50px !important',
    width: '50px !important',
    marginLeft: 10,
    color: GREEN,
  },
  refreshButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  refreshIcon: {
    fontSize: 25,
  },
};


const WalletStatusView = ({ status }) => {
  const walletStatusVariables = status && WALLET_STATUS_MAP[status];
  if (!walletStatusVariables) return null;
  return (
    <Card style={{ width: '100%', borderRadius: 10, marginBottom: 10, padding: 10, alignItems: 'center', display: 'flex', borderLeft: `4px solid ${walletStatusVariables.color || 'gray'}` }}>
      <WarningOutlineIconCustom style={{ marginBottom: 5, marginRight: 10, width: 25, color: ORANGE }} />
      <div>
        <div style={{display: 'flex', flexDirection: 'column' }}>
          <h3 style={{ fontWeight: 'bold', color: walletStatusVariables.color || GREEN, marginRight: 10, marginTop: 0, marginBottom: 7 }}>{<Entity entity={walletStatusVariables.description} />}</h3>
          {walletStatusVariables && walletStatusVariables.descriptionLong ? (
            <h4 style={{ color: walletStatusVariables.color || GREEN, marginRight: 10, marginTop: 0, marginBottom: 7 }}>{<Entity entity={walletStatusVariables.descriptionLong} />}</h4>
          ): null}
        </div>
      </div>
    </Card>
  )
}

@connect(state => ({ wallet: state.cloudCredits.wallet, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class WalletManagementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewLoading: false,
      selectedTab: 0,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    this.fetchAllWalletInfo();
  }

  async fetchAllWalletInfo() {
    const { dispatch } = this.props;
    try {
      this.setState({ isViewLoading: true });
      await dispatch(CreditsActions.fetchWalletAvailableCredits());
      this.setState({ isViewLoading: false });
    } catch (error) {
      this.setState({ isViewLoading: false });
    }
    dispatch(CreditsActions.fetchWalletCredits());
    dispatch(CreditsActions.fetchWalletSubscriptions());
    dispatch(CreditsActions.fetchWalletCreditsUsage());
    dispatch(CreditsActions.fetchWalletInfo());
    await dispatch(PlatformUserActions.fetchPlatformUsers(0, 100));
    dispatch(CreditsActions.fetchWalletEvents());
  }

  onSelectTab(tab) {
    this.setState({ selectedTab: tab });
  }

  onRechargeWalletRequest() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'confirmRechargeWallet',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onRechargeWalletConfirmed(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onRechargeWalletConfirmed() {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CreditsActions.sendRechargeWalletRequest());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="demoRequestSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="demoRequestError" /></h6>),
        },
      }));
    }
  }


  render() {
    const { wallet, classes, themeName } = this.props;
    const { isViewLoading, selectedTab } = this.state;
    const textColor = getColorFromThemeName(themeName);
    const nextRechargeDate = calculateNextRechargeDate(wallet.availableCredits, wallet.burnRateDaily);
    const walletStatus =  wallet && wallet.info && wallet.info.walletStatus;
    const isWalletActive = walletStatus && walletStatus === WALLET_STATUSES.ACTIVE;
    return (
      <div className={classes.container}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: 25, paddingRight: 25 }}>
          {!isWalletActive && WALLET_STATUS_MAP[walletStatus] ? <WalletStatusView status={walletStatus} /> : null}
          <Card className={classes.cardContainer}>
            <div className={classes.cardHeader}>
              <h2 className={classes.boldText} style={{ marginTop: 20, color: textColor, textAlign: 'center' }}><Entity entity="yourCredits" /></h2>
            </div>
            <div className={classes.cardBodyContainer}>
              <div className={classes.cardTitleContainer} style={{ justifyContent: 'center' }}>
                <CreditsIconCustom style={{ color: GREEN, width: 30, marginRight: 5, marginTop: 8, }} />
                {isViewLoading ? <CircularProgress color="primary" className={classes.progress} /> : <h1 className={classes.boldText} style={{ color: GREEN, fontSize: 50 }} >{wallet.availableCredits}</h1>}
              </div>
              <div className={classes.cardTitleContainer} style={{ marginTop: 20 }}>
                <h4 className={classes.boldText} style={{ color: ORANGE }}><Entity entity="monthlyConsumption" />: <Entity entity="walletCreditsConsumptionText" key={wallet.burnRate} data={{ credits: wallet.burnRate }} /></h4>
                <CreditsIconCustom style={{ color: ORANGE, width: 20, marginLeft: 5 }} />
              </div>
              {isWalletActive ? (
                <div className={classes.cardTitleContainer} style={{ marginTop: 10 }}>
                  <h4 className={classes.boldText} style={{ fontWeight: 'normal', fontSize: 15 }}><Entity entity="nextRechargeForecast" key={wallet.burnRate} data={{ date: moment(nextRechargeDate).format('LL') }} /> ({moment(nextRechargeDate).fromNow()})</h4>
                </div>
              ): null}
              <div className={classes.metricsContainer}>
                <MDButton
                  backgroundColor={GREEN}
                  onClick={() => this.onRechargeWalletRequest()}
                  style={{ width: 400 }}
                  title={<Entity entity="rechargeWallet" />}
                />
              </div>
            </div>
            <IconButton
              onClick={() => this.fetchAllWalletInfo()}
              className={classes.refreshButton}
            >
              <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
            </IconButton>
          </Card>
          <WalletSubscriptionsSection
            onRefreshWallet={() => this.fetchAllWalletInfo()} />
        </div>
        <div style={{ marginTop: 30 }}>
          <AppBar position="sticky" color="default">
            <Tabs
              value={selectedTab}
              textColor="primary"
              indicatorColor="primary"
              scrollable
              onChange={(e, index) => this.onSelectTab(index)}
              style={{ paddingLeft: 25 }}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab icon={<CreditsIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="consumptionHistory" /></h5>} />
              <Tab icon={<CloudPlusIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="rechargeHistory" /></h5>} />
              <Tab icon={<AdminEventsIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="subscriptionHistory" /></h5>} />
            </Tabs>
          </AppBar>
          {selectedTab === 0 ? (
            <WalletConsumptionSection />
          ) : null}
          {selectedTab === 1 ? (
            <WalletRechargeSection />
          ) : null}
          {selectedTab === 2 ? (
            <WalletEventsSection />
          ) : null}
        </div>
      </div>
    );
  }
}


export default withStyles(styles)(WalletManagementPage);
