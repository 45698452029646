import qs from 'qs';
import { axiosInstance } from './rest';
import axios from 'axios';

let locksExportCancelToken;

export function fetchLocksForExport(params) {
  locksExportCancelToken = axios.CancelToken.source();
  const config = {
    params,
    timeout: 0,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
    cancelToken: locksExportCancelToken.token,
  };
  return axiosInstance.get('/api/v2/smartLocks', config);
}

export function getLocksExportCancelToken() {
  return locksExportCancelToken;
}

export function openLockRemote(data) {
  return axiosInstance.post('/api/v2/ACS/remoteOpen', data);
}

export function getSmartLockDetails(smartLockId) {
  return axiosInstance.get(`/api/v2/smartLocks/${smartLockId}`);
}

export function getSmartLockPrograms(smartLockId, params) {
  const restParams = {
    params,
  };
  return axiosInstance.get(`/api/v2/smartLocks/${smartLockId}/programs`, restParams);
}

export function createSmartLockProgram(smartLockId, data) {
  return axiosInstance.post(`/api/v2/smartLocks/${smartLockId}/programs`, data);
}

export function editSmartLockProgram(smartLockId, programId, data) {
  return axiosInstance.put(`/api/v2/smartLocks/${smartLockId}/programs/${programId}`, data);
}

export function enableSmartLockProgram(smartLockId, programId) {
  return axiosInstance.post(`/api/v2/smartLocks/${smartLockId}/programs/${programId}/enable`);
}

export function disableSmartLockProgram(smartLockId, programId) {
  return axiosInstance.post(`/api/v2/smartLocks/${smartLockId}/programs/${programId}/disable`);
}

export function deleteSmartLockProgram(smartLockId, programId) {
  return axiosInstance.delete(`/api/v2/smartLocks/${smartLockId}/programs/${programId}`);
}

export function fetchSmartLocksOptions(smartLockId, params) {
  const restParams = {
    params,
  };
  return axiosInstance.get(`/api/v2/smartLocks/${smartLockId}/options`, restParams);
}

export function createSmartLocksOption(smartLockId, optionDTO) {
  return axiosInstance.post(`/api/v2/smartLocks/${smartLockId}/options`, optionDTO);
}

export function deleteSmartLocksOption(smartLockId, optionId) {
  return axiosInstance.delete(`/api/v2/smartLocks/${smartLockId}/options/${optionId}`);
}

export function fetchSharedSmartLocks(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get(`/api/v2/smartLocks/shared`, restParams);
}

export function shareSmartLockWithCompany(shareDTO) {
  return axiosInstance.post(`/api/v2/smartLocks/shared`, shareDTO);
}

export function removeSharedSmartLockFromCompany(smartLockId) {
  return axiosInstance.delete(`/api/v2/smartLocks/shared/${smartLockId}`);
}
