/* eslint-disable no-await-in-loop */
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import * as XLSX from 'xlsx';
import { BACKGROUND_TASK_TYPES, EXPORT_FORMATS } from '../../_config/consts';
import { isActiveVisitor, saveDataToLocalStorage } from '../../_config/utils';
import * as VisitorsAPI from '../../_config/visitorsAPI';
import * as ModalActions from '../actions/modal.actions';
import * as UtilsActions from '../actions/utils.actions';
import {
  APPEND_VISITORS,
  RESET_VISITORS_DATA,
  RESET_VISITORS_FILTERS,
  RESET_VISITORS_PAGINATION_DATA,
  SAVE_VISITORS,
  SELECT_VISITOR,
  SET_VISITORS_FILTER,
  SET_VISITORS_OPERATIONAL_MODE,
  SET_VISITORS_PAGINATION_DATA,
  UPDATE_VISITOR,
  SAVE_VISITORS_CHART_DATA,
  SET_VISITORS_ORDER,
} from './actionTypes/visitors';

export function setVisitorsOperationalMode(value) {
  return {
    type: SET_VISITORS_OPERATIONAL_MODE,
    value,
  };
}

export function saveVisitors(visitors) {
  return {
    type: SAVE_VISITORS,
    visitors,
  };
}

export function appendVisitors(visitors) {
  return {
    type: APPEND_VISITORS,
    visitors,
  };
}

export function saveVisitorsPaginationData(pagination) {
  return {
    type: SET_VISITORS_PAGINATION_DATA,
    pagination,
  };
}

export function setVisitorsFilter(field, value) {
  return {
    type: SET_VISITORS_FILTER,
    field,
    value,
  };
}

export function setSelectedVisitor(visitor) {
  return {
    type: SELECT_VISITOR,
    visitor,
  };
}

export function updateVisitorOnState(visitor) {
  return {
    type: UPDATE_VISITOR,
    visitor,
  };
}

export function resetVisitorsData() {
  return {
    type: RESET_VISITORS_DATA,
  };
}

export function resetVisitorsFilters() {
  return {
    type: RESET_VISITORS_FILTERS,
  };
}

export function resetVisitorsPagination() {
  return {
    type: RESET_VISITORS_PAGINATION_DATA,
  };
}

export function saveVisitorsChartData(chartData) {
  return {
    type: SAVE_VISITORS_CHART_DATA,
    chartData,
    lastUpdate: moment().valueOf(),
  }
}

export function setOrder(orderBy, orderDir) {
  return {
    type: SET_VISITORS_ORDER,
    orderBy,
    orderDir,
  };
}

export function fetchVisitors(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().visitors.data.filters;
      const orderBy = getState().visitors.data.sorting.orderBy;
      const orderDir = orderBy?getState().visitors.data.sorting.orderDir:undefined;
      const params = {
        page,
        pageSize,
        ...filters,
        orderBy,
        orderDir,
      };
      const visitorsResponse = await VisitorsAPI.getVisitors(params);
      if (visitorsResponse && visitorsResponse.data && visitorsResponse.data.content) {
        const visitorsData = visitorsResponse.data.content;
        const visitorsDataOrdered = _.orderBy(visitorsData, 'expectedDate');
        dispatch(saveVisitors(visitorsDataOrdered));
        dispatch(saveVisitorsPaginationData(_.omit(visitorsResponse.data, 'content')));
        const orderedVisitorsEvents = _.groupBy(visitorsData, event => moment(event.createdAt).hours());
        const data = _.reduce(_.keys(orderedVisitorsEvents), (acc, curr) => {
          acc = {
            ...acc,
            [curr]: _.size(orderedVisitorsEvents[curr]),
          };
          return acc;
          
        }, {});
        const chartData = {
          labels: _.times(24, n => moment().hours(n).minutes(0).format('LT')),
          datasets: [
            {
              label: L20NContext.getSync('todayVisitors'),
              backgroundColor: 'rgba(27,151,194,0.2)',
              borderColor: 'rgba(27,151,194,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(27,151,194,0.4)',
              hoverBorderColor: 'rgba(27,151,194,1)',
              data: _.times(24, n => data[n] || 0),
            },
          ],
        };
        dispatch(saveVisitorsChartData(chartData));
        return { visitorsData, chartData };
      }
      throw new Error();

    } catch (error) {
      throw error;
    }
  }
}


export function fetchAndAppendVisitors(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().visitors.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const visitorsResponse = await VisitorsAPI.getVisitors(params);
      if (visitorsResponse && visitorsResponse.data && visitorsResponse.data.content) {
        dispatch(appendVisitors(_.orderBy(visitorsResponse.data.content, 'expectedDate')));
        dispatch(saveVisitorsPaginationData(_.omit(visitorsResponse.data, 'content')));
        return visitorsResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllVisitorsForExport(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().visitors.data.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'creatingCardsExportFile',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        ...filters,
      };
      let visitorsResponse = await VisitorsAPI.getVisitors(params);
      if (visitorsResponse && visitorsResponse.data && visitorsResponse.data.content && !_.isEmpty(visitorsResponse.data.content)) {
        const visitors = [...visitorsResponse.data.content];
        let pagination = _.omit(visitorsResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          visitorsResponse = await VisitorsAPI.getVisitors(params);
          visitors.push(...visitorsResponse.data.content);
          pagination = _.omit(visitorsResponse.data, 'content');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { visitors, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function createVisitor(visitorsDTO) {
  return async (dispatch, getState) => {
    try {
      const createResponse = await VisitorsAPI.createVisitor(visitorsDTO);
      if (createResponse && createResponse.data) {
        return createResponse.data;
      }
    } catch (error) {
      throw error;
    }
  };
}


export function exportVisitors(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { visitors, stopFetching } = await dispatch(fetchAllVisitorsForExport());
      if (!stopFetching) {
        const exportData = [];
        _.each(visitors, (visitor) => {
          exportData.push({
            ID: visitor.id,
            Name: visitor.firstname,
            Lastname: visitor.lastname,
            Email: visitor.email,
            'Contact Email': visitor.contactEmail,
            'Expected Date': visitor.expectedDate ? `${moment(visitor.expectedDate).format('DD MMMM YYYY')}` : '---',
            'Check-in Date': visitor.checkInDate ? `${moment(visitor.checkInDate).format('LLL')}` : '---',
            'Check-out Date': visitor.checkOutDate ? `${moment(visitor.checkOutDate).format('LLL')}` : '---',
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { visitors: ws }, SheetNames: ['visitors'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Visitors_Export.${fileExtension}`);
        return visitors;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function exportActiveVisitors(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { visitors, stopFetching } = await dispatch(fetchAllVisitorsForExport());
      const filters = getState().visitors.data.filters;
      if (!stopFetching) {
        const exportData = [];
        _.each(visitors, (visitor) => {
          if (visitor && isActiveVisitor(visitor, filters.fromDate, filters.toDate)) {
            exportData.push({
              ID: visitor.id,
              Name: visitor.firstname,
              Lastname: visitor.lastname,
              Email: visitor.email,
              'Contact Email': visitor.contactEmail,
              'Expected Date': visitor.expectedDate ? `${moment(visitor.expectedDate).format('DD MMMM YYYY')}` : '---',
              'Check-in Date': visitor.checkInDate ? `${moment(visitor.checkInDate).format('LLL')}` : '---',
              'Check-out Date': visitor.checkOutDate ? `${moment(visitor.checkOutDate).format('LLL')}` : '---',
            });
          }
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { visitors: ws }, SheetNames: ['visitors'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Active_Visitors_Export.${fileExtension}`);
        return visitors;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function setVisitorsViewMode(viewMode) {
  saveDataToLocalStorage('visitorsViewMode', viewMode);
}

export function deleteVisitor(visitorId) {
  return async (dispatch, getState) => {
    try {
      const deleteResponse = await VisitorsAPI.deleteVisitor(visitorId);
      if (deleteResponse && deleteResponse.data) {
        return deleteResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function checkOutVisitor(visitorCode) {
  return async (dispatch, getState) => {
    try {
      const checkOutResponse = await VisitorsAPI.checkOutVisitor({ code: visitorCode });
      if (checkOutResponse && checkOutResponse.data) {
        return checkOutResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function checkInVisitor(visitorCode) {
  return async (dispatch, getState) => {
    try {
      const checkOutResponse = await VisitorsAPI.checkInVisitor({ code: visitorCode, privacyPolicyAccepted: true });
      if (checkOutResponse && checkOutResponse.data) {
        return checkOutResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}