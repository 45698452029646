import { axiosInstance } from "./rest";
import { PERMISSION_ENTITIES, PERMISSIONS } from './consts';
import { executeRequestOrDefault } from './utils';

export function fetchGateways(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/gateways',
    params,
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.GATEWAY, [PERMISSIONS.READ], requestData);
}

export function fetchAtlasGateways(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/gateways/wired/atlas',
    params,
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.GATEWAY, [PERMISSIONS.READ], requestData);
}

export function fetchAtlasGatewayDetails(atlasId) {
  return axiosInstance.get(`/api/v2/gateways/wired/atlas/${atlasId}`);
}

export function createGateway(gatewayDTO) {
  return axiosInstance.post('/api/v2/gateways', gatewayDTO);
}

export function deleteGateway(gatewayId) {
  return axiosInstance.delete(`/api/v2/gateways/${gatewayId}`);
}

export function addLockToGateway(gatewayId, lockId) {
  return axiosInstance.post(`/api/v2/gateways/${gatewayId}/smartLocks/${lockId}`);
}

export function removeLockToGateway(gatewayId, lockId) {
  return axiosInstance.delete(`/api/v2/gateways/${gatewayId}/smartLocks/${lockId}`);
}

export function fetchGatewayDetails(gatewayId) {
  return axiosInstance.get(`/api/v2/gateways/${gatewayId}`);
}

export function editGatewayDetails(gatewayId, editDTO) {
  return axiosInstance.put(`/api/v2/gateways/${gatewayId}`, editDTO);
}

export function sendGatewayNotification(gatewayId, payload) {
  return axiosInstance.post(`/api/v2/gateways/${gatewayId}/notifications`, payload);
}

export function openHighFlexGatewaySmartLock(gatewayId, payload) {
  return axiosInstance.post(`/api/v2/gateways/${gatewayId}/open`, payload);
}

export function fetchGatewayNotification(gatewayId, notificationId) {
  return axiosInstance.get(`/api/v2/gateways/${gatewayId}/notifications/${notificationId}`);
}

export function fetchGatewaySmartlocks(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/smartLocks',
    params,
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.SMART_LOCK, [PERMISSIONS.READ], requestData);
}
