module.exports = {
  SAVE_FLOOR_PLANS: 'SAVE_FLOOR_PLANS',
  APPEND_FLOOR_PLANS: 'APPEND_FLOOR_PLANS',
  SET_FLOOR_PLANS_PAGINATION_DATA: 'SET_FLOOR_PLANS_PAGINATION_DATA',
  SELECT_FLOOR_PLAN: 'SELECT_FLOOR_PLAN',
  SET_FLOOR_PLANS_FILTER: 'SET_FLOOR_PLANS_FILTER',
  SAVE_OR_UPDATE_FLOOR_PLAN: 'SAVE_OR_UPDATE_FLOOR_PLAN',
  RESET_FLOOR_PLANS_FILTERS: 'RESET_FLOOR_PLANS_FILTERS',
  RESET_FLOOR_PLANS_DATA: 'RESET_FLOOR_PLANS_DATA',
  RESET_FLOOR_PLANS_PAGINATION_DATA: 'RESET_FLOOR_PLANS_PAGINATION_DATA',
  SET_FLOOR_PLANS_OPERATIONAL_MODE: 'SET_FLOOR_PLANS_OPERATIONAL_MODE',
};
