module.exports = {
  SAVE_CREDENTIALS: 'SAVE_CREDENTIALS',
  APPEND_CREDENTIALS: 'APPEND_CREDENTIALS',
  SAVE_CREDENTIAL: 'SAVE_CREDENTIAL',
  SELECT_CREDENTIAL: 'SELECT_CREDENTIAL',
  SET_CREDENTIAL_OPERATIONAL_MODE: 'SET_CREDENTIAL_OPERATIONAL_MODE',
  FETCH_LOCKS_BY_TAGS: 'FETCH_LOCKS_BY_TAGS',
  CANCEL_FETCH_LOCKS_BY_TAGS: 'CANCEL_FETCH_LOCKS_BY_TAGS',
  FETCH_GUESTS_BY_TAGS: 'FETCH_GUESTS_BY_TAGS',
  CANCEL_FETCH_GUESTS_BY_TAGS: 'CANCEL_FETCH_GUESTS_BY_TAGS',
  SET_GUESTS_FILTERED_BY_TAGS: 'SET_GUESTS_FILTERED_BY_TAGS',
  SET_LOCKS_FILTERED_BY_TAGS: 'SET_LOCKS_FILTERED_BY_TAGS',
  APPEND_GUESTS_FILTERED_BY_TAGS: 'APPEND_GUESTS_FILTERED_BY_TAGS',
  APPEND_LOCKS_FILTERED_BY_TAGS: 'APPEND_LOCKS_FILTERED_BY_TAGS',
  SET_CREDENTIAL_PAGINATION_DATA: 'SET_CREDENTIAL_PAGINATION_DATA',
  SET_CREDENTIAL_GUESTS_PAGINATION_DATA: 'SET_CREDENTIAL_GUESTS_PAGINATION_DATA',
  SET_CREDENTIAL_LOCKS_PAGINATION_DATA: 'SET_CREDENTIAL_LOCKS_PAGINATION_DATA',
  SET_CREDENTIAL_FILTER: 'SET_CREDENTIAL_FILTER',
  RESET_CREDENTIAL_FILTER: 'RESET_CREDENTIAL_FILTER',
  RESET_CREDENTIALS_DATA: 'RESET_CREDENTIALS_DATA',
  RESET_CREDENTIAL_PAGINATION_DATA: 'RESET_CREDENTIAL_PAGINATION_DATA',
};
