module.exports = {
  SAVE_METRIC: 'SAVE_METRIC',
  RESET_METRICS: 'RESET_METRICS',
  SAVE_ACCESS_CHART_DATA: 'SAVE_ACCESS_CHART_DATA',
  SAVE_ACCESS_CHART_DATA_CAPACITY_MANAGEMENT: 'SAVE_ACCESS_CHART_DATA_CAPACITY_MANAGEMENT',
  SAVE_ACCESS_CHART_USERS: 'SAVE_ACCESS_CHART_USERS',
  SAVE_ACCESS_CHART_USERS_CAPACITY_MANAGEMENT: 'SAVE_ACCESS_CHART_USERS_CAPACITY_MANAGEMENT',
  SAVE_ACCESS_CHART_LOCKS: 'SAVE_ACCESS_CHART_LOCKS',
  SAVE_LOCKS_BATTERY_CHART_DATA: 'SAVE_LOCKS_BATTERY_CHART_DATA',
  SAVE_ACCESS_CHART_FILTER: 'SAVE_ACCESS_CHART_FILTER',
  RESET_ACCESS_CHART_FILTER: 'RESET_ACCESS_CHART_FILTER',
  SAVE_ACCESSORIES_CHART_DATA: 'SAVE_ACCESSORIES_CHART_DATA',
  SAVE_VISITORS_CHART_DATA: 'SAVE_VISITORS_CHART_DATA',
  SAVE_VISITORS_CHART_USERS: 'SAVE_VISITORS_CHART_USERS',
  SAVE_VISITORS_CHART_FILTER: 'SAVE_VISITORS_CHART_FILTER',
  RESET_VISITORS_CHART_FILTER: 'RESET_VISITORS_CHART_FILTER',
  SAVE_BOOKEY_ANALYTICS_CARDS: 'SAVE_BOOKEY_ANALYTICS_CARDS',
  SAVE_BOOKEY_ANALYTICS_RESOURCES: 'SAVE_BOOKEY_ANALYTICS_RESOURCES',
  SAVE_BOOKEY_ANALYTICS_USERS: 'SAVE_BOOKEY_ANALYTICS_USERS',
  SAVE_BOOKEY_ANALYTICS_RESERVATIONS: 'SAVE_BOOKEY_ANALYTICS_RESERVATIONS',
  FETCH_BOOKEY_USERS: 'FETCH_BOOKEY_USERS',
  CANCEL_FETCH_BOOKEY_USERS: 'CANCEL_FETCH_BOOKEY_USERS',
  SAVE_BOOKEY_USERS: 'SAVE_BOOKEY_USERS',
  RESET_BOOKEY_USERS: 'RESET_BOOKEY_USERS',
  SET_BOOKEY_USERS_LOADING: 'SET_BOOKEY_USERS_LOADING',
  SAVE_ACCESS_CHART_ACTIVE_VISITORS: 'SAVE_ACCESS_CHART_ACTIVE_VISITORS',
};
