import ManualIcon from '@material-ui/icons/BookOutlined.js';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { BLUE, LANGUAGE_DETAILS, LUCKEY_MANUALS_LINK } from '../../../_config/consts';
import { localizeHelpCenterLink } from '../../../_config/utils';
import * as UtilsActions from '../../../redux/actions/utils.actions.js';
import MDButton from '../../MDButton/MDButton.jsx';
import FormCardContainer from '../../forms/Elements/FormCardContainer.jsx';
import BookOpenIconCustom from '../../CustomIcons/BookOpenIconCustom.jsx';


const formatDomain = (domain) => {
  return domain.replace('.jago.cloud', '').replace('api.', '').replace('.internal', '').replace('.sandbox', '')
}


@connect(state => ({ language: state.settings.language }))
class AssistanceSettingsOperationalSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: null,
    };
  }

  componentWillMount() {
    const { userData, language } = this.props;
    let selectedLanguage = 'en';
    if (language) {
      selectedLanguage = LANGUAGE_DETAILS[language].lang;
    }
    this.setState({ selectedLanguage });
  }

  openOnlineGuide() {
    const formattedURL = localizeHelpCenterLink('');
    window.open(formattedURL);
  }

  render() {
    const { dispatch } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        <FormCardContainer
          title="assistanceContactUsTitle"
          subtitle="assistanceContacUsBody"
        >
          <div>
            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
              <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ManualIcon style={{ fontSize: 30, marginRight: 20 }} />
                <h4 style={{ margin: 0, marginLeft: 5 }}>
                  <a
                    style={{ color: BLUE, cursor: 'pointer', textDecoration: 'underline' }}
                    href={LUCKEY_MANUALS_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Entity entity="pdfManual" />
                  </a>
                </h4>
              </div>
              <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <BookOpenIconCustom style={{ width: 30, marginRight: 20 }} />
                <h4 style={{ margin: 0, marginLeft: 5 }}>
                  <a
                    style={{ color: BLUE, cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => this.openOnlineGuide()}
                  >
                    <Entity entity="onlineManual" />
                  </a>
                </h4>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: 20, marginBottom: 10}}>
              <MDButton
                containerStyle={{ margin: 0, width: '70%' }}
                title={<Entity entity="reportProblem" />}
                onClick={() => dispatch(UtilsActions.setSupportTicketFormVisible(true))}
              />
            </div>
          </div>
        </FormCardContainer>
        
      </div>
    );
  }
}

export default AssistanceSettingsOperationalSection;