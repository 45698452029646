import { domainMediaActions as DomainMediaActions, resourceActions as ResourceActions } from '@bottega52/bookey-redux-module';
import { IconButton, List, ListItem } from '@material-ui/core';
import { AddCircleOutline, DeleteForever, Edit } from '@material-ui/icons';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { TimePicker } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { initialize, reset } from 'redux-form';
import { GREEN, LANGUAGES, RED } from '../../_config/consts.js';
import { getFileFromBase64, localizeHelpCenterLink } from '../../_config/utils.js';
import MapIconCustom from '../../components/CustomIcons/MapIconCustom.jsx';
import EmptyView from '../../components/EmptyView.jsx';
import ListLoadingView from '../../components/ListLoadingView.jsx';
import ResourceMapBuilderEditor from '../../components/Maps/ResourceMapBuilderEditor.jsx';
import ResourceMapOperationalSection from '../../components/Maps/ResourceMapOperationalSection.jsx';
import ResourceMapViewer from '../../components/Maps/ResourceMapViewer.jsx';
import OperationalView from '../../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../../components/PresentationalView/PresentationalViewHeader.jsx';
import DatePickerView from '../../components/forms/Fields/DatePickers/SingleDatePicker/DatePickerView.jsx';
import MDSwitchView from '../../components/forms/Fields/MDSwitch/MDSwitchView.jsx';
import * as ModalActions from '../../redux/actions/modal.actions.js';
import * as UtilsActions from '../../redux/actions/utils.actions';
import MDButton from '../../components/MDButton/MDButton.jsx';
import { push } from 'connected-react-router';


const MenuItem = ({ isSelected, title, onClick, onEditMap, onDeleteMap }) => (
  <div className={`sidebar-row ${isSelected ? 'sidebar-row-active' : ''}`}>
    <ListItem button selected={isSelected} style={{ borderRadius: 5, height: 50, margin: 0, padding: 0, paddingLeft: 20, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }} onClick={() => onClick()}>
      <h4 className={`sidebar-row-text ${isSelected ? 'sidebar-row-text-active' : ''}`}>{title}</h4>
      {isSelected ? (
        <div>
          <IconButton style={{ padding: 5}} onClick={(event) => { event.stopPropagation(); onEditMap(); }}>
            <Edit />
          </IconButton>
          <IconButton style={{ padding: 5, marginRight: 5, marginLeft: 5}} onClick={(event) => { event.stopPropagation; onDeleteMap(); }}>
            <DeleteForever />
          </IconButton>
        </div>
      ) : null}
    </ListItem>
  </div>
);

let filterTimeout;
@connect((state) => ({ domainMedia: state.domainMedia, language: state.settings.language , themeName: state.settings.items.theme.data.themeName, resources: state.resources.data, availableResources: state.resources.availableResources.content  }))
class BookeyResourceMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOperationalMode: false,
      isMapBuilderEnabled: false,
      viewLoading: false,
      isLoadingAvailabilities: false,
      activeIndex: 0,
      isEditorViewActive: false,
      timeFrom: moment().set('minute', 0).valueOf(),
      timeTo: moment().add(1 ,'hour').set('minute', 0).valueOf(),
      selectedDate: moment().startOf('day').valueOf(),
    };
  }



  async componentWillMount() {
    const { dispatch } = this.props;
    dispatch(DomainMediaActions.setSelectedDomainMedia({}));
    this.setState({ viewLoading: true });
    try {
      dispatch(DomainMediaActions.resetDomainMediaFilters());
      await this.fetchDomainMedia();
      await dispatch(ResourceActions.fetchResourcesWithMedia(0, 200, {}, false));
      this.onChangeMap(0);
      this.onDatesConfirm();
      this.setState({ viewLoading: false });
    } catch (error) {
      this.setState({ viewLoading: false });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(DomainMediaActions.setSelectedDomainMedia({}));
  }

  async fetchDomainMedia(page = 0, filters = {}, append = false) {
    const { dispatch } = this.props;
    try {
      this.setState({ viewLoading: true });
      await dispatch(DomainMediaActions.fetchDomainMediaWithData(page, 20, filters, append));
      this.setState({ viewLoading: false });
    } catch (error) {
      this.setState({ viewLoading: false });
    }
  }
  
  onOpenOperationalSection() {
    this.setState({ isOperationalMode: true });
  }

  onCloseOperationalSection() {
    this.setState({ isOperationalMode: false });
  }
  
  onNewRource() {
    this.setState({ isOperationalMode: true });
  }

  onNewDomainMediaClicked() {
    const { dispatch } = this.props;
    dispatch(reset('BookeyMapForm'));
    this.onOpenOperationalSection();
  } 

  async setFilter(field, value) {
    const { dispatch } = this.props;
    try {
      dispatch(DomainMediaActions.setDomainMediaFilter(field, value));
      if (!value || (value.length >= 2)) {
        if (filterTimeout) clearTimeout(filterTimeout);
        filterTimeout = setTimeout(async () => {
          const { domainMedia: { filters } } = this.props;
          this.setState({ viewLoading: true });
          this.fetchDomainMediaWithFilters({ ...filters });
          this.setState({ viewLoading: false });
        }, 500);
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async fetchDomainMediaWithFilters(filters) {
    this.setState({ viewLoading: true });
    await this.fetchDomainMedia(0, filters);
    this.setState({ viewLoading: false });
  }

  async resetResourcesFilters() {
    const { dispatch } = this.props;
    dispatch(DomainMediaActions.resetDomainMediaFilters());
    dispatch(DomainMediaActions.resetDomainMedia());
    this.fetchDomainMediaWithFilters({});
  }

  onMapPressed(map) {
  }

  onDeleteMapRequest(map) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'warning',
        body: <Entity entity="deleteBookeyMapConfirm" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onDeleteMapConfirm(map),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteMapConfirm(map) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(DomainMediaActions.deleteDomainMedia(map.id))
      this.onCloseMapBuilderView();
      const { domainMedia: { filters } } = this.props;
      await this.fetchDomainMediaWithFilters({ ...filters });
      const { domainMedia: { data: { content } }} = this.props;
      if (!_.isEmpty(content)) {
        this.onChangeMap(_.size(content) -1)
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationOpenApplied" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationError" /></h6>),
        },
      }));
    }
  }

  async onOpenMapBuilder(bookeyMap) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(ResourceActions.fetchResourcesWithMedia(0, 100, {}, false));
      this.setState({ isMapBuilderEnabled: true });
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(DomainMediaActions.setSelectedDomainMedia(bookeyMap));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onAppendResources(page) {
    const { dispatch } = this.props;
    try {
      await dispatch(ResourceActions.fetchResourcesWithMedia(page, 100, {}, true));
      this.setState({ isMapBuilderEnabled: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onEditMap(map) {
    const { dispatch } = this.props;
    dispatch(initialize('BookeyMapForm', { ...map } ));
    dispatch(DomainMediaActions.setSelectedDomainMedia(map));
    this.onOpenOperationalSection();
  }

  onCloseMapBuilderView() {
    const { dispatch } = this.props;
    this.setState({ isMapBuilderEnabled: false });
    dispatch(DomainMediaActions.setSelectedDomainMedia({}));
  }

  onSubmitMapForm(values) {
    if (values.id) {
      this.onModifyBookeyMap(values);
    } else {
      this.onCreateNewBookeyMap(values)
    }
  }

  async onCreateNewBookeyMap(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(DomainMediaActions.createDomainMedia(values, values.data[0]));
      await this.fetchDomainMedia();
      this.onCloseOperationalSection();
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationOpenApplied" /></h6>),
        },
      }));
      const { domainMedia: { data: { content: domainMediaData }}} = this.props;
      this.onChangeMap(_.size(domainMediaData) -1);
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationError" /></h6>),
        },
      }));
    }
  }

  async onModifyBookeyMap(values) {
    const { dispatch, domainMedia: { selectedDomainMedia }, } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    const media = values && values.data && _.isString(values.data) ? getFileFromBase64(values.data) : values.data[0];
    try {
      const domainMedia = await dispatch(DomainMediaActions.editDomainMedia(selectedDomainMedia.id, values.name, media));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationOpenApplied" /></h6>),
        }}));
      this.fetchDomainMedia();
      this.onCloseOperationalSection();
      const domainMediaUpdated = await dispatch(DomainMediaActions.fetchDomainMediaData(domainMedia));
      dispatch(DomainMediaActions.setSelectedDomainMedia(domainMediaUpdated));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch(error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationError" /></h6>),
        },
      }));
    }
  }

  async onPositionElementOnMap(resource, coordinates) {
    const { dispatch, domainMedia: { selectedDomainMedia }, resources } = this.props;
    let resourceToUpdate = resources && resources.content ? _.find(resources.content, stateResource => stateResource.id === resource.id) : null;
    if (resourceToUpdate) {
      resourceToUpdate = {
        ...resourceToUpdate,
        locationMediaId: selectedDomainMedia.id,
        locationCoordinates: `${coordinates.x};${coordinates.y}`,
      }
      try {
        await dispatch(ResourceActions.editResourceCoordinates(resource.id, resourceToUpdate));
      } catch (error) {
      }
    }
  }

  async onRemoveElementFromMap(resource) {
    const { dispatch, resources } = this.props;
    let resourceToUpdate = resources && resources.content ? _.find(resources.content, stateResource => stateResource.id === resource.id) : null;
    if (resourceToUpdate) {
      resourceToUpdate = {
        ...resourceToUpdate,
        locationMediaId: 0,
        locationCoordinates: "",
      }
      try {
        await dispatch(ResourceActions.editResourceCoordinates(resource.id, resourceToUpdate));
      } catch (error) {
      }
    }
  }

  onChangeMap(index) {
    const { dispatch, domainMedia: { data: { content: domainMediaData } }  } = this.props;
    this.setState({ activeIndex: index});
    dispatch(DomainMediaActions.setSelectedDomainMedia(domainMediaData[index]));
    setTimeout(() => this.onDatesConfirm(), 200);
  }

  onToggleEditor(value) {
    this.setState({ isEditorViewActive: value });
  }

  async onDatesConfirm() {
    const { dispatch, resources: { content: resourcesData }, domainMedia: { selectedDomainMedia } } = this.props;
    try {
      this.setState({ isLoadingAvailabilities: true });
      const mediaResources = _.filter(resourcesData, resource => resource.locationMedia && resource.locationMedia.id   === selectedDomainMedia.id);
      const {  timeFrom, timeTo, selectedDate } = this.state;
      const fromDate = moment(selectedDate).set('hour', moment(timeFrom).hour()).set('minutes', moment(timeFrom).minute()).valueOf();
      const toDate = moment(selectedDate).set('hour', moment(timeTo).hour()).set('minutes', moment(timeTo).minute()).valueOf();
      const filters = {
        fromDate,
        toDate,
        ids: _.map(mediaResources, 'id'),
      };
      await dispatch(ResourceActions.fetchAvailableResources(0, 200, filters, false));
      setTimeout(() => this.setState({ isLoadingAvailabilities: false }), 800);
    } catch (error) {
      this.setState({ isLoadingAvailabilities: false });
    }
  }

  onDateChange(value) {
    this.setState({ selectedDate: value });
  }

  onBookResource(resource) {
    const { dispatch } = this.props;
    const { timeFrom, timeTo, selectedDate  } = this.state;
    dispatch(push(`/reservations?resourceId=${resource.id}&startTime=${timeFrom}&endTime=${timeTo}&dateFrom=${selectedDate}`)); 
  }

  onInfo() {
    const url = localizeHelpCenterLink('bookey-resource-map');
    window.open(url);
  }

  render() {
    const { domainMedia, domainMedia: { data: { content: domainMediaData } }, themeName, language, availableResources } = this.props;
    const { isOperationalMode, viewLoading, activeIndex, isEditorViewActive, timeFrom, timeTo, selectedDate, isLoadingAvailabilities } = this.state;
    const  { filters } = domainMedia;
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={() => this.onNewDomainMediaClicked()}
          newEntityTitle="newBookeyMap"
          newEntityIconName="icon-simple-line-icons-user-following"
          onSearchReset={() => this.resetResourcesFilters()}
          onFilterChange={value => this.setFilter('name', value)}
          onInfo={() => this.onInfo()}
          onSearchSubmit={() => this.fetchDomainMediaWithFilters({ ...filters })}
        />
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', paddingTop: 60, paddingLeft: 10, margin: 20, marginLeft: 10 }}>
          <h3 style={{ fontWeight: 'bold', margin: 0 }}><Entity entity="bookeyMaps" /></h3>
          <MDSwitchView
            label={<Entity key={isEditorViewActive} entity={!isEditorViewActive ? 'activateEditorMode' : 'editorModeActive'} />}
            checked={isEditorViewActive}
            titleStyle={{ fontWeight: isEditorViewActive ? 'bold' : 'normal', color: isEditorViewActive ? GREEN : '#3f3f3f' }}
            containerStyle={{ marginLeft: 20, marginTop: 5 }}
            onSelect={selected => this.onToggleEditor(selected)}
          />
          {!isEditorViewActive ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 30 }}>
              <div>
                <DatePickerView
                  id="bookey-date-picker"
                  date={moment(selectedDate)}
                  onChange={(value) => this.onDateChange(value)}
                />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                  <TimePicker
                    showSecond={false}
                    defaultValue={moment(timeFrom)}
                    placeholder={`${moment(timeFrom).format('LT')}` || `${moment().format('LT')}`}
                    className="time-picker"
                    format={is12HoursFormat ? 'h:mm a' : 'HH:mm'}
                    allowClear={false}
                    onChange={(value) => this.setState({ timeFrom: value })}
                    use12Hours={is12HoursFormat}
                    onClose={(value) => this.setState({ timeFrom: value })}
                    style={{ marginRight: 10 }}
                  />
                  <TimePicker
                    showSecond={false}
                    defaultValue={moment(timeTo)}
                    placeholder={`${moment(timeTo).format('LT')}` || `${moment().format('LT')}`}
                    className="time-picker"
                    format={is12HoursFormat ? 'h:mm a' : 'HH:mm'}
                    allowClear={false}
                    onChange={(value) => this.setState({ timeTo: value })}
                    use12Hours={is12HoursFormat}
                    onClose={(value) => this.setState({ timeTo: value })}
                  />
                </div>
              </div>
            <MDButton
              title={<Entity entity="verifyAvailability" />}
              isLoading={isLoadingAvailabilities}
              onClick={() => this.onDatesConfirm()}
            />
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: GREEN }} />
                <h5 style={{ marginLeft: 10 }}><Entity entity="freeResource" /></h5>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: RED }} />
                <h5 style={{ marginLeft: 10 }}><Entity entity="occupiedResource" /></h5>
              </div>
            </div>
          </div>
          ) : null}
        </div>
        {domainMediaData && !_.isEmpty(domainMediaData) ? (
          <div style={{ display: 'flex', flexDirection: 'row', zIndex: 1 }}>
            <List component="nav" style={{ paddingBottom: 70, paddingTop: 0, width: 220 }}>
            {_.map(domainMediaData, (floorPlan, index) => (
              <MenuItem
                title={floorPlan.name}
                isSelected={activeIndex === index}
                onClick={() => this.onChangeMap(index)}
                onEditMap={() => this.onEditMap(floorPlan)}
                onDeleteMap={() => this.onDeleteMapRequest(floorPlan)}
              />
            ))}
            <IconButton onClick={() => this.onNewDomainMediaClicked()} style={{ margin: 'auto', display: 'flex', marginTop: 10 }}>
              <AddCircleOutline style={{ fontSize: 20, color: GREEN }} />
            </IconButton>
            </List>
              {isEditorViewActive ? (
                <ResourceMapBuilderEditor
                  onPositionElementOnMap={(resource, coordinates) => this.onPositionElementOnMap(resource, coordinates)}
                  onRemoveElementFromMap={resource => this.onRemoveElementFromMap(resource)}
                  onAppendResources={page => this.onAppendResources(page)}
                />
              ): (
                <ResourceMapViewer
                  availableResources={availableResources}
                  onBookResource={(resource) => this.onBookResource(resource)}
                />
              )}
          </div>
        ) : (
          <div>
          {!viewLoading ? (
            <EmptyView
              title="noMaps"
              icon={<MapIconCustom style={{ color: '#a5a3a3', width: 50 }} />}
              newEntityTitle="createMaps"
              subtitle="startCreatingMap"
              onNewEntity={() => this.onNewDomainMediaClicked()}
            />
          ): <ListLoadingView containerStyle={{ marginTop: 100 }} />}
          </div>
        )}
        <OperationalView
          themeName={themeName}
          isVisible={isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          title={<Entity entity="newBookeyMap" />}
          
        >
          <ResourceMapOperationalSection
            onSubmitMapForm={values => this.onSubmitMapForm(values)}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default BookeyResourceMaps;
