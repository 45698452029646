import React, { Component } from 'react';
import MDCheckBoxView from './MDCheckBoxView';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

export default class MDCheckBoxField extends Component {
  onHandleChange(value, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(value);
    }
    if (onHandleChange) {
      onHandleChange(value);
    }
  }

  render() {
    const { input, checkBoxColor, containerstyle, showErrorLabel, meta, disabled } = this.props;
    const checked = input.value;
    const hasError = meta.touched && meta.error ? true : false;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', ...containerstyle }}>
        <MDCheckBoxView
          checked={checked}
          color={checkBoxColor}
          disabled={disabled}
          onSelect={value => this.onHandleChange(value, input)}
          {...this.props}
        />
        {showErrorLabel && hasError && <h6 style={{ color: 'red', marginTop: 4, marginLeft: 10, marginBottom: 0 }}><Entity entity={meta.error} /></h6>}
      </div>
    );
  }
};

