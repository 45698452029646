import { withStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { Col, Grid, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { withRouter } from 'react-router';
import PrivacyTermsView from '../components/Footer/PrivacyTermsView.jsx';
import BasicModal from '../components/Modals/BasicModal.jsx';
import { getLuckeyLogoSource, getPlantName, isMobileBrowser } from '../_config/utils';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
    backgroundColor: 'white',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30,
    margin: 100,
    backgroundColor: 'white'
  },
  image: {
    height: 50,
    alignSelf: 'flex-start',
    marginBottom: 20,
  },
});
@withRouter
class ServiceNotFoundView extends React.Component {

  render() {
    const isMobile = isMobileBrowser();
    const year = new Date().getFullYear();
    const luckeyLogoSource = getLuckeyLogoSource('white');
    const plantName = getPlantName();
    return (
      <div id="auth-container" style={{ height: '100vh', paddingTop: 0, backgroundImage: isMobile ? 'unset' : `url(/imgs/common/loginBackground.jpg)`, backgroundSize: 'cover'}} className="login">
        <Grid>
          <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Col
              id="login-container"
              xs={12}
              sm={12}
              style={{
                height: isMobile ? '100vh' : 'unset',
                width: isMobile ? null : 900,
                opacity: isMobile ? 1 : 0.9,
                margin: isMobile ? 0 : 100,
                overflow: 'scroll',
                position: 'relative',
                borderRadius: isMobile ? 0 : 10,
                boxShadow: isMobile ? 'unset' : '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
              }}
            >
              <img src={luckeyLogoSource} alt="Sofia" style={{ margin: 10, marginTop: 30, marginBottom: 15, alignSelf: 'center', height: 60 }} />
              <img src="/imgs/common/tagLine.png" alt="SofiaTagline" style={{ alignSelf: 'center', height: isMobile ? 13 : 23 }} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 25, paddingTop: 0, paddingBottom: 0, marginBottom: 25, marginTop: 35 }}>
                <WarningIcon style={{ fontSize: 60, color: 'red' }} />
                <h3 style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 5, color: 'white' }}><Entity entity="plantNotFoundTitle" /></h3>
                <h4 style={{ marginBottom: 50, marginTop: 0, textAlign: 'center', color: 'white' }}><Entity entity="plantNotFoundDescription" data={{ plantName }} /></h4>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src="/imgs/common/logoSofiaWhite.png" alt="Sofia" style={{ height: 12, marginRight: 4 }} />
                  <h6 style={{ fontSize: 10, margin: 0, marginTop: 3, color: 'white' }}>©{year}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
        <BasicModal
          ref={(m) => { this.privacySupportModal = m; }}
          title={<h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}><Entity entity="privacyInfo" /></h3>}
          body={
            <div>
              <PrivacyTermsView />
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(ServiceNotFoundView);
