import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as RolesActions from '../../redux/actions/roles.actions';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import RoleRow from './RoleRow.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';

@connect(state => ({
  roles: state.roles,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
}))
class RolesListView extends React.Component {

  componentWillUnmount() {
    this.closeOperationalSection();
  }

  appendRoles(page) {
    const { dispatch } = this.props;
    const append = true;
    dispatch(RolesActions.fetchAndAppendRoles(page, append));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(RolesActions.setOperationalMode(false));
  }

  render() {
    const { roles: { selectedRole, roles: { pagination, content: rolesData } }, themeName, onNewRole, onSelectRole, viewLoading } = this.props;
    const rolesFilterData = _.filter(rolesData, r => (r.id !== 5 && r.id !== 1)); // Omit guest and manager role
    const canCreateRole = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.ROLE);
    return (
      <div className="list-view details-section" style={{ width: '100%', top: 140, paddingBottom: 40 }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => this.appendRoles(pagination.number + 1)}
          hasMore={pagination.number + 1 < pagination.totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '40%' }}>
            {!_.isEmpty(rolesFilterData) ?
              _.map(rolesFilterData, role =>
                <RoleRow
                  key={role.id}
                  isSelected={selectedRole && selectedRole.id === role.id}
                  role={role}
                  themeName={themeName}
                  onClick={() => onSelectRole(role)}
                />)
                : (
                  <div>
                    {!viewLoading ? (
                      <EmptyView
                        title="noRoles"
                        onNewEntity={canCreateRole ? () => onNewRole() : null}
                        newEntityTitle="addRole"
                        subtitle="startAddRoles"
                        iconName="icon-simple-line-icons-user-following"
                    />) : <ListLoadingView />}
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

export default RolesListView;