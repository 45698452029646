import { Card, withStyles } from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOpen';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import LogEventsList from '../LogEvents/LogEventsList.jsx';

import MDButton from '../MDButton/MDButton.jsx';
import SmartLocksEvents from '../../routes/LogEvents/SmartLocksEvents.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  cardContainer: {
    paddingBottom: 20,
    marginTop: 0,
    marginRight: 20,
    marginLeft: 15,
    marginBottom: 20,
    borderRadius: 10,
    padding: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  listContainer: {
    maxHeight: 300,
    overflow: 'scroll',
  }
});
@connect((state) => ({ viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class LockEventsOperationalSection extends React.Component {
  
  render() {
    const { smartLock, onLockClick, onLoadMoreEvents } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <Card style={{ padding: 10, borderRadius: 0, position: 'absolute', left: 0, right: 0, backgroundColor: '#F8F9FA', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {smartLock ? (
            <div style={{ display: 'flex', paddingLeft: 15, alignItems: 'center' }}>
              <LockIcon style={{ fontSize: 35, color: '#3f3f3f' }} />
              <div style={{ marginLeft: 20 }}>
                <h3 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}>
                  {`${smartLock.name}`}
                </h3>
                <h4 style={{ margin: 0, marginTop: 10, color: '#3f3f3f' }}>
                  {`${smartLock.serialNumber || '--'}`}
                </h4>
              </div>
            </div>
          ) : null}
          <MDButton
            icon={<LockIcon style={{ color: 'white', fontSize: 25, marginLeft: 10 }} />}
            containerStyle={{ width: '25%' }}
            title={<Entity entity="lockDetails" />}
            onClick={() => onLockClick(smartLock.id)}
          />
        </Card>
        <SmartLocksEvents
          hideFilters
          avoidOfflineWarning
          avoidReloading
          containerStyle={{ marginTop: -20, paddingLeft: 15, paddingRight: 15 }}
          listContainerStyle={{ marginTop: 0, marginLeft: -20, marginBottom: 0, paddingLeft: 0, paddingTop: 100 }}
        />
      </div>
    );
  }
}


export default withStyles(styles)(LockEventsOperationalSection);
