import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import * as SettingsActions from '../../../redux/actions/setting.actions';
import { LICENSES, SUBSCRIPTION_TYPES } from '../../../_config/consts';
import BaseLicenseCard from '../../License/BaseLicenseCard.jsx';

@connect(state => ({ license: state.settings.items.license, userLicense: state.user.license }))
class LicenseSettingsOperationalSection extends React.Component {

  componentWillMount() {
    const { dispatch, setting } = this.props;
    //dispatch(SettingsActions.fetchSettingsByType(setting.name));
  }

  render() {
    const { license: { data: subscriptionData }, userLicense: { domainProductType } } = this.props;
    const orderedSubscriptionData = _.orderBy(subscriptionData, subscription => subscription.resourceType === SUBSCRIPTION_TYPES.DOMAIN || subscription.resourceType === SUBSCRIPTION_TYPES['USERS+LOCKS'] || subscription.resourceType === SUBSCRIPTION_TYPES.USERS || subscription.resourceType === SUBSCRIPTION_TYPES.LOCKS, 'desc');
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        {_.map(orderedSubscriptionData, subscription => (
          <BaseLicenseCard
            subscriptionType={subscription.resourceType}
            license={subscription}
            isEnterpise={domainProductType === LICENSES.ENTERPRISE}
          />
        ))}
      </div>
    );
  }

} 

export default LicenseSettingsOperationalSection;
