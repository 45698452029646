import WarningIcon from '@material-ui/icons/ReportProblem';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React, { Component } from 'react';
import { connect } from 'react-redux';

@connect(state => ({
  card: state.cards.selectedCard,
}))
class CardDeletedDetails extends Component {
  render() {
    const { card } = this.props;
    return (
      <div style={{ marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <WarningIcon style={{ fontSize: 70, marginTop: 10, marginBottom: 10, color: 'red' }} />
        <h4 style={{ fontWeight: 'bold', textAlign: 'center', color: '#3f3f3f', margin: 5 }}><Entity entity="cardDeletingTitle" /></h4>
        <h4 style={{ textAlign: 'center', margin: 0, color: '#3f3f3f' }}><Entity entity="cardDeletingTitleDescription" /></h4>
      </div>
    );
  }
}

export default CardDeletedDetails;
