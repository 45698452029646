// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as CommunicationActions from '../../redux/actions/communications.actions';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import CommunicationRow from './CommunicationRow.jsx';

@connect(state => ({
  communications: state.communications,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
  routing: state.router,
}))
class CommunicationListView extends React.Component {
  
  appendCommunications(page) {
    const { dispatch } = this.props;
    dispatch(CommunicationActions.appendCommunications(page));
  }


  render() {
    const { canCreateItem, communications: { selectedCommunication, data: { content: communicationData, pagination: { number: currentPage, totalPages } } }, onSelectCommunication, onNewCommunication, viewLoading, themeName } = this.props;
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 40, width: '90%', overflow: 'auto' }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => this.appendCredentials(currentPage + 1)}
          hasMore={currentPage + 1 < totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '41%' }}>
            {!_.isEmpty(communicationData) ?
              _.map(communicationData, communication =>
                <CommunicationRow
                  themeColor={themeName}
                  key={communication.id}
                  isSelected={selectedCommunication && selectedCommunication.id === communication.id}
                  communication={communication}
                  onClick={() => onSelectCommunication(communication)}
                />)
                : (
                  <div>
                    {!viewLoading ? (
                      <EmptyView
                        title="noCommunications"
                        onNewEntity={canCreateItem ? () => onNewCommunication() : null}
                        newEntityTitle="addCommunications"
                        subtitle="startCommunications"
                        iconName="icon-simple-line-icons-user-following"
                      />) : <ListLoadingView />}
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

export default CommunicationListView;
