import { axiosInstance } from "./rest";

export function fetchFloorPlans(params) {
  const requestData = {
    params,
  };
  return axiosInstance.get('/api/v2/companies/media', requestData);
}

export function createFloorPlan(name, data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: { name },
  };
  const fileData = new FormData();
  fileData.append('file', data);
  return axiosInstance.post('/api/v2/companies/media', fileData, config);
}

export function deleteFloorPlan(floorPlanId) {
  return axiosInstance.delete(`/api/v2/companies/media/${floorPlanId}`);
}

export function updateFloorPlanDisplayName(floorPlanId, name) {
  return axiosInstance.put(`/api/v2/companies/media/${floorPlanId}/displayName`, { displayName: name });
}