// @ts-nocheck
import { Card, CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import GoToPageIcon from '@material-ui/icons/CallMissedOutgoing';
import BarChartIcon from '@material-ui/icons/InsertChart';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';
import { BLUE, PERMISSION_ENTITIES, PERMISSIONS } from '../../../_config/consts';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider';
import ScrollableBarChart from '../../Charts/ScrollableBarChart.jsx';
import Permission from '../../Permission/Permission.jsx';
import AreasHourlyOccupancyCustomIcon from '../../CustomIcons/AreasHourlyOccupancyCustomIcon.jsx';


const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  header: {
    marginTop: -40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  refreshHeader: {
   position: 'absolute',
   right: 0,
   top: 0,
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center'
  },
  filterHeader: {
   position: 'absolute',
   left: 0,
   top: 0,
   minWidth: 400,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
    color: '#3f3f3f',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAnalyticsView = () => (
  <div style={{ height: 290, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <BarChartIcon style={{ color: '#bdbdbd', fontSize: 50 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFound" /></h3>
  </div>
);

class AreasHourlyOccupancyBarChart extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      selectedArea: null,
    }
  }

  async componentWillMount() {
    const { initialArea } = this.props;
    if (initialArea&&initialArea.id&&initialArea.name)
      this.setState({ selectedArea: {value: initialArea.id, label: initialArea.name} });
  }

  componentWillReceiveProps(nextProps) {
    const { initialArea: oldValue } = this.props;
    const { initialArea: newValue } = nextProps;
    const { selectedArea } = this.state;
    if (!selectedArea && oldValue!=newValue && newValue.id&&newValue.name) {
      this.setState({ selectedArea: {value: newValue.id, label: newValue.name} });
    }
  }

  onSelectArea(area) {
    const { onSelectArea, areas } = this.props;
    this.setState({ selectedArea: area });
    const areasSearch = area&&area.value?areas.filter(e=>e.id===area.value):[]
    onSelectArea(areasSearch.length?areasSearch[0] : null)
  }

  render() {
    const { areas, occupancyHourlyData, lastUpdate, textColor, fullWidth, isRefreshing, classes, onRefreshMetric} = this.props;
    const { selectedArea } = this.state;
    const chartWidth = fullWidth ? window.screen.width - 270 : window.screen.width - 520;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1, marginRight: fullWidth ? 0 : 20 }} elevation={3}>
          <div className={classes.titleHeader}>
            <div className={classes.filterHeader}>
              <Select
                value={selectedArea}
                placeholder={<Entity entity="selectArea" />}
                noResultsText={<Entity entity="noResultsFound" />}
                onChange={val => this.onSelectArea(val)}
                options={_.map(areas, area => ({ value: area.id, label: area.name }))}
                isClearable={false}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </div>
            <AreasHourlyOccupancyCustomIcon style={{ width: 35, color: textColor || '#3f3f3f' }} />
            <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="areaHourlyOccupancy" /></h3>
            <div className={classes.refreshHeader}>
              <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}: {moment(lastUpdate).format('LLL')}</h5>
              <IconButton
                disabled={isRefreshing}
                style={{ opacity: isRefreshing ? 0.5 : 1 }}
                onClick={() => onRefreshMetric('NOT_USED')}
              >
                <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
              </IconButton>
            </div>
            
          </div>
          <div style={{ paddingTop: 30 }}>
          {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
            {occupancyHourlyData && !_.isEmpty(occupancyHourlyData) ? (
              <ScrollableBarChart
                height={100}
                chartMaxWidth={chartWidth}
                data={_.cloneDeep(occupancyHourlyData)}
                dataSetSize={24}
                showLegend={false}
                stackedBars={true}
                key={JSON.stringify(occupancyHourlyData)}
              />
            ) : <EmptyAnalyticsView />}
          </div>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(AreasHourlyOccupancyBarChart);
