import React from 'react';
import { connect } from 'react-redux';
import GuestsMetricChart from './MetricChartComponents/GuestsMetricChart.jsx';
import SmartLocksMetricChart from './MetricChartComponents/SmartLocksMetricChart.jsx';
import StandardDevicesMetricChart from './MetricChartComponents/StandardDevicesMetricChart.jsx';
import AccessBarChart from './MetricChartComponents/AccessBarChart.jsx';
import SmartlocksAccessBarChart from './MetricChartComponents/SmartlocksAccessBarChart.jsx';
import SmartLocksBatteryChart from './MetricChartComponents/SmartLocksBatteryChart.jsx';
import SmartPhoneRulesMetricChart from './MetricChartComponents/SmartPhoneRulesMetricChart.jsx';
import PinsMetricChart from './MetricChartComponents/PinsMetricChart.jsx';
import StatusSensorMetricChart from './MetricChartComponents/StatusSensorMetricChart.jsx';
import HyperKeysMetricChart from './MetricChartComponents/HyperKeysMetricChart.jsx';
import AreasHourlyOccupancyBarChart from './MetricChartComponents/AreasHourlyOccupancyBarChart.jsx';
import BookeyResourcesUsagePieChart from './MetricChartComponents/BookeyResourcesUsagePieChart.jsx';
import BookeyReservationsPieChart from './MetricChartComponents/BookeyReservationsPieChart.jsx';
import BookeyResourceTypesBarChart from './MetricChartComponents/BookeyResourceTypesBarChart.jsx';
import BookeyHourlyReservationsChart from './MetricChartComponents/BookeyHourlyReservationsChart.jsx';


const chartComponents = {
  guests: GuestsMetricChart,
  smartLocks: SmartLocksMetricChart,
  standardDevices: StandardDevicesMetricChart,
  smartPhoneRules: SmartPhoneRulesMetricChart,
  pins: PinsMetricChart,
  hyperKeys: HyperKeysMetricChart,
  accesses: AccessBarChart,
  smartlocksEvents: SmartlocksAccessBarChart,
  smartlocksBattery: SmartLocksBatteryChart,
  accessoriesStatusSensor: StatusSensorMetricChart,
  areasHourlyOccupancy: AreasHourlyOccupancyBarChart,
  resourcesUsagePieChartData: BookeyResourcesUsagePieChart,
  reservationsPieChartData: BookeyReservationsPieChart,
  resourceTypesChartData: BookeyResourceTypesBarChart,
  bookeyHourlyReservations: BookeyHourlyReservationsChart,
};

const BaseMetricChart = (props) => {
  if (!props.metricType) {
    return null;
  }
  const ChartComponent = chartComponents[props.metricType];
  const componentProps = props;
  return (
    ChartComponent ? <ChartComponent {...componentProps} /> : null
  );
};

export default connect((state) => ({ modals: state.modals.data }))(BaseMetricChart);
