import TextIcon from '@material-ui/icons/HelpOutline';
import {
  Form
} from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { Warning } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { ORANGE, PERMISSIONS, PERMISSION_ENTITIES, RED } from '../../_config/consts.js';
import { elaborateForbiddenDaysString, hasFormWritePermission, isUserWithoutEmail } from '../../_config/utils.js';
import MDButton from '../MDButton/MDButton.jsx';
import CredentialTimeSelector from './Elements/CredentialTimeSelector.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import TagsSelectorListField from './Elements/TagsSelectorListField.jsx';
import CustomField from './Fields/CustomField.jsx';

const validate = (values) => {
  const errors = {};
  const canReadUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
  const canReadLock = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
  if (values) {
    if (!values.name) {
      errors.name = 'required';
    }
    
    if (!values.guestTags || (values.guestTags && _.isEmpty(values.guestTags))) {
      errors.guestTags = 'required';
    }

    if (!values.lockTags || (values.lockTags && _.isEmpty(values.lockTags))) {
      errors.lockTags = 'required';
    }

    if (!values.credentialTimeframe || !values.credentialTimeframe.startDate || !values.credentialTimeframe.endDate) {
      errors.credentialTimeframe = 'reservationDateIntevalErrorMessage';
    }

    if (!values.timeIntervalFrom) {
      errors.timeIntervalFrom = 'required';
    }

    if (!values.timeIntervalTo) {
      errors.timeIntervalTo = 'required';
    }
    if (values.timeIntervalTo && values.timeIntervalFrom) {
      const toTime = moment(values.timeIntervalTo);
      const fromTime = moment(values.timeIntervalFrom);
      const toDate = moment(0).hour(toTime.hour()).minute(toTime.minute()).second(0);
      const fromDate = moment(0).hour(fromTime.hour()).minute(fromTime.minute()).second(0);
      if (toDate.isSameOrBefore(fromDate)) {
        errors.timeIntervalTo = 'Interval not valid';
      }
    }
  }

  return errors;
};

@reduxForm({ form: 'CredentialForm', validate })
@connect(state => ({ form: state.form.CredentialForm }))
class CredentialForm extends React.Component {

  render() {
    const {
      form,
      availableLockTags,
      availableUserTags,
      onGuestTagsChange,
      onLockTagsChange,
      guestsFilteredByTags,
      locksFilteredByTags,
      onLockFilterModeChange,
      onGuestInputChange,
      onLockInputChange,
      onFetchMoreLocks,
      onFetchMoreGuests,
      onDeleteCredential,
      onGuestFilterModeChange,
      onCredentialFormSubmit,
    } = this.props;
    const canDeleteCredentialRule = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.CREDENTIAL_RULE);
    const canReadUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const canReadLock = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const isEditing = form && form.values && form.values.id;
    const dateFrom = form && form.values && form.values.credentialTimeframe && form.values.credentialTimeframe.startDate;
    const dateTo = form && form.values && form.values.credentialTimeframe && form.values.credentialTimeframe.endDate;
    const timeFrom = form && form.values && form.values.timeIntervalFrom;
    const timeTo = form && form.values && form.values.timeIntervalTo;
    const dateIntervalTimeFrom = form && form.values && form.values.dateIntervalTimeFrom;
    const dateIntervalTimeTo = form && form.values && form.values.dateIntervalTimeTo;
    const daysString = form && form.values && form.values.daysOfTheWeek && elaborateForbiddenDaysString(form.values.daysOfTheWeek) ? elaborateForbiddenDaysString(form.values.daysOfTheWeek) : '';
    const usersSelected = form && form.values && form.values.selectedGuestTags;
    const usersHaveError = usersSelected ? _.find(usersSelected, user => isUserWithoutEmail(user)) : false;
    const credentialId = form && form.values.id ? form.values.id : null;
    return (
      <Form>
        <FormCardContainer
          title={isEditing ? 'credentialData' : 'newSmartCredential'}
          headerStyle={{ marginBottom: 20 }}
        >
          <Entity
            componentClass={Field}
            name="name"
            componentAttribute="placeholder"
            entity="insertCredentialName"
            title={<Entity entity="credentialName" />}
            component={CustomField}
            type="text"
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.CREDENTIAL_RULE, isEditing)}
            mandatory
          />
          <Entity
            componentClass={Field}
            name="description"
            componentAttribute="placeholder"
            entity="insertDescription"
            title={<Entity entity="description" />}
            component={CustomField}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.CREDENTIAL_RULE, isEditing)}
            type="text"
          />
        </FormCardContainer>
        <FormCardContainer
          title="credentialGuestTitle"
          subtitle="credentialTagGuestSubtitle"
        >
          <TagsSelectorListField
            formName="CredentialForm"
            name="guestTags"
            selectorFieldName="guestTagMatchingMode"
            selectedTagsFieldName="selectedGuestTags"
            tags={availableUserTags}
            selectedTags={guestsFilteredByTags}
            form={form}
            tagsType="GUEST"
            title="usersTags"
            placeholder="insertGuestTagsForCredential"
            listTitle="selectedUsers"
            emptyTagSelectionText={canReadUser ? 'noUsersForSelectedTag' : null}
            onFetchMoreItems={page => onFetchMoreGuests(page)}
            onTagsChange={values => onGuestTagsChange(values)}
            onInputChange={value => onGuestInputChange(value)}
            itemHasErrorCondition={item => isUserWithoutEmail(item)}
            onFilterModeChange={values => onGuestFilterModeChange(values)}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.CREDENTIAL_RULE, isEditing)}
          />
          {usersHaveError ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Warning style={{ color: ORANGE, marginRight: 5, fontSize: 20 }} />
              <h5 style={{ color: ORANGE, fontWeight: 'bold', lineHeight: '1.4em', marginLeft: 5 }}><Entity entity="selectedUsersNoEmailSmartphoneError" /></h5>
            </div>
          ) : null}
        </FormCardContainer>
        <FormCardContainer
          title="credentialLockTitle"
          subtitle="credentialTagLockSubtitle"
        >
          <TagsSelectorListField
            name="lockTags"
            selectedTagsFieldName="selectedLocksTags"
            formName="CredentialForm"
            tags={availableLockTags}
            selectedTags={locksFilteredByTags && locksFilteredByTags[0]}
            form={form}
            tagsType="LOCK"
            title="lockTags"
            placeholder="insertLockTagsForCredential"
            listTitle="selectedLocks"
            emptyTagSelectionText={canReadLock ? 'noLocksSelectedWithTag' : null}
            onFetchMoreItems={page => onFetchMoreLocks(page)}
            onTagsChange={values => onLockTagsChange(values)}
            onInputChange={value => onLockInputChange(value)}
            onFilterModeChange={value => onLockFilterModeChange(value)}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.CREDENTIAL_RULE, isEditing)}
          />
        </FormCardContainer>
        <FormCardContainer
          title="credentialTimeAndDate"
          subtitle="credentialTimeAndDateSubtitle"
        >
          <CredentialTimeSelector formName="CredentialForm" canEdit={hasFormWritePermission(PERMISSION_ENTITIES.CREDENTIAL_RULE, isEditing)} />
            {dateFrom && dateTo ? (
              <div style={{ paddingTop: 10, borderTop: '2px solid #157495', marginLeft: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <TextIcon style={{ color: '#3f3f3f', marginRight: 12, fontSize: 20 }} />
                <h5 style={{ fontSize: 15, color: '#3f3f3f' }}>
                  <Entity
                    entity="credentialVerbalDescription"
                    key={`${dateFrom}-${dateTo}-${timeFrom}-${timeTo}-${dateIntervalTimeTo}-${dateIntervalTimeFrom}`}
                    data={{
                      dateFrom: moment(dateFrom).format('LL'),
                      dateTo: moment(dateTo).format('LL'),
                      dateTimeFrom: moment(dateIntervalTimeFrom).format('LT'),
                      dateTimeTo: moment(dateIntervalTimeTo).format('LT'),
                      timeFrom: moment(timeFrom).format('LT'),
                      timeTo: moment(timeTo).format('LT'),
                    }}
                  />
                  {!daysString ? '' : <span style={{ marginLeft: 5 }}><Entity entity="except" />{daysString}</span>}
                </h5>
              </div>
            ) : null}
        </FormCardContainer>
        {isEditing && canDeleteCredentialRule &&
          <FormCardContainer
            title="deleteCredential"
            subtitle="deleteCredentialWarning"
          >
            <MDButton
              title={<Entity entity="deleteCredential" />}
              backgroundColor={RED}
              onClick={() => onDeleteCredential(credentialId)}
            />
          </FormCardContainer>
        }
        {hasFormWritePermission(PERMISSION_ENTITIES.CREDENTIAL_RULE, credentialId) && (
          <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
            <MDButton
              title={<Entity entity="save" />}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              disabled={usersHaveError}
              onClick={() => onCredentialFormSubmit()}
            />
          </div>
        )}
      </Form>
    );
  }
} 

export default CredentialForm;
