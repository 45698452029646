import { Form, Icon, Row, Button } from '@sketchpixy/rubix';
import moment from 'moment';
import React from 'react';
import TimeIcon from '@material-ui/icons/AccessTime';
import TextIcon from '@material-ui/icons/Description';
import LinkIcon from '@material-ui/icons/Link';
import LocationIcon from '@material-ui/icons/PinDrop';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import MDButton from '../../MDButton/MDButton.jsx';
import { BLUE, RED } from '../../../_config/consts';

export default class EventDetails extends React.Component {
  
  render() {
    const { event: { fromDate, toDate, title, description, media, link, address }, onDeleteEvent, onEditEvent } = this.props;
    return (
      <Form>
      {media ? (
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200, overflow: 'hidden' }}>
          <img
            src={`data:image/jpeg;base64,${media}`}
            style={{ width: '100%' }}
          />
        </Row>
      ): null}
        <h1 style={{ textAlign: 'center', fontWeight: 'bold', color: '#3f3f3f', marginTop: 15 }}>
          {title}
        </h1>
        <FormCardContainer title="eventDetails" containerStyle={{ marginTop: 20, position: 'relative' }} headerStyle={{ marginBottom: 10 }}>
          <IconButton style={{ position: 'absolute', right: '5px', top: '9px' }} onClick={() => onEditEvent()}>
            <EditIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
          </IconButton>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', alignItems: 'center' }} >
              <CalendarIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
              <h4 style={{ fontWeight: 100, margin: 0, marginLeft: 10, color: '#3f3f3f' }}>
                {moment(fromDate).format('LL')} - {moment(toDate).format('LL')}
              </h4>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25 }}>
              <TimeIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
              <h4 style={{ fontWeight: 100, color: '#3f3f3f', margin: 0, marginLeft: 10 }}>
                {moment(fromDate).format('LT')} - {moment(toDate).format('LT')}
              </h4>
            </div>
          </div>
          {description ? (
            <div style={{ marginTop: 25, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <TextIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
              <h4 style={{ fontWeight: 100, margin: 0, marginLeft: 10, color: '#3f3f3f' }}>{description}</h4>
            </div>
          ): null}
          {address ? (
            <div style={{ marginTop: 25, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <LocationIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
              <h4 style={{ fontWeight: 100, margin: 0, marginLeft: 10, color: '#3f3f3f' }}>{address}</h4>
            </div>
          ) : null}
          {link ? (
            <div style={{ marginTop: 25, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <LinkIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
              <h4 style={{ fontWeight: 100, margin: 0, marginLeft: 10, color: '#3f3f3f' }}><a href={link} target style={{ textDecoration: 'underline' }}>{link}</a></h4>
            </div>
          ) : null}
        </FormCardContainer>
        <FormCardContainer
          title="deleteEvent"
          subtitle="deleteEventWarning"
        >
          <MDButton
            title={<Entity entity="deleteEvent" />}
            backgroundColor={RED}
            onClick={() => onDeleteEvent()}
          />
        </FormCardContainer>
      </Form>
    );
  }
}
