import ErrorIcon from '@material-ui/icons/ErrorOutline.js';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import CredentialRow from '../Credentials/CredentialRow.jsx';
import ListLoadingView from '../ListLoadingView.jsx';

@connect((state) => ({}))
class CardCredentialsList extends React.Component {
  render() {
    const { credentials, useWindow, appendCardCredentials } = this.props;
    const weekdays = _.map(moment.weekdays(), weekday => ({
      label: weekday,
      value: moment().isoWeekday(weekday).isoWeekday(),
    }));
    const groupedCredentials = credentials && credentials.data ? _.groupBy(credentials.data, cred => cred.smartLock.id) : {};
    const credentialsData = credentials && credentials.data ? _.map(_.keys(groupedCredentials), key => ({
      smartLock: groupedCredentials[key][0].smartLock,
      additionalTimeProfile: { ...groupedCredentials[key][1] },
      ...groupedCredentials[key][0]
    })) : null;
    return (
      <div className="logs-list" style={{ overflow: 'auto', height: '100vh', marginBottom: 100 }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={credentials ? () => appendCardCredentials(credentials.currentPage + 1) : null}
          hasMore={credentials && credentials.currentPage + 1 < credentials.totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
            {credentialsData && !_.isEmpty(credentialsData) ?
              _.map(credentialsData, (credential, index) =>
                <CredentialRow
                  credential={credential}
                  options={weekdays}
                  hiddenHeader
                  key={index}
                />)
                :
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ErrorIcon style={{ fontSize: 50, marginTop: 10, marginBottom: 10, color: '#3f3f3f' }} />
                <h4 style={{ fontWeight: 'bold', textAlign: 'center', color: '#3f3f3f', margin: 5 }}><Entity entity="noLocksForTags" /></h4>
                <h4 style={{ textAlign: 'center', margin: 0, color: '#3f3f3f' }}><Entity entity="noLocksForTagsSuggestion" /></h4>
              </div>
              }
        </InfiniteScroll>
      </div>
    );
  }
} 

export default CardCredentialsList;
