// @ts-nocheck
import { Modal } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, initialize } from 'redux-form';
import CameraLiveFeedView from '../../components/Cameras/CameraLiveFeedView.jsx';
import CamerasGridView from '../../components/Cameras/CamerasGridView.jsx';
import CameraLocksAssociationForm from '../../components/forms/Cameras/CameraLocksAssociationForm.jsx';
import OperationalView from '../../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../../components/PresentationalView/PresentationalViewHeader.jsx';
import * as CamerasActions from '../../redux/actions/cameras.actions';
import * as LocksActions from '../../redux/actions/lock.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as GatewayActions from '../../redux/actions/gateways.actions.js';
import * as CustomFieldsActions from '../../redux/actions/customFields.actions';
import { EAGLE_EYE_CLIENT_ID } from '../../_config/conf.js';
import { SUBSCRIPTION_TYPES } from '../../_config/consts.js';
import BasicModal from '../../components/Modals/BasicModal.jsx';
import LockRemoteOpenView from '../../components/Locks/LockRemoteOpenView.jsx';


let filterTimeout;
@connect(state => ({ cameras: state.cameras, lockTags: state.tags.lock.data, locksCustomFields: state.customFields.locks.content, cameraLocksAssociationForm: state.form.CameraLocksAssociationForm, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class CamerasView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      activeTab: 0,
      showCameraLiveFeed: false,
      showCameraAssociationForm: false,
      showLinkAccountButton: false,
    };
  }

  async componentWillMount() {
    const { cameras, dispatch } = this.props;
    const isIntegrationActive = dispatch(UserActions.userHasIntegrationActive(SUBSCRIPTION_TYPES.EAGLE_EYE_CAMERAS_PLUGIN));
    this.setState({ showLinkAccountButton: !isIntegrationActive });
    if (_.isEmpty(cameras.data.content)) {
      this.onFetchCameras();
    }
    await dispatch(CamerasActions.setEagleEyeMediaCookieSession());
  }

  async onFetchCameras() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(CustomFieldsActions.fetchLocksCustomFields());
      await dispatch(CamerasActions.fetchLocksWithCameras());
      await dispatch(CamerasActions.fetchCameras());
      dispatch(UtilsActions.setViewLoading(false));
      this.setState({ showLinkAccountButton: false });
    } catch (error) {
      if (error && error.message === 'ACCOUNT_NOT_LINKED') {
        this.setState({ showLinkAccountButton: true });
      }
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }
  
  async onSelectCamera(camera) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      if (camera.liveFeedURL || camera.isFakeCamera) {
        dispatch(CamerasActions.setSelectedCamera(camera));
      } else {
        const liveFeedURL = await dispatch(CamerasActions.fetchCameraLiveFeed(camera.id));
        dispatch(CamerasActions.setSelectedCamera({ ...camera, liveFeedURL }));
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showCameraLiveFeed: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorGettingCameraLiveFeed" /></h6>),
        },
      }));
    }
  }

  onFilterCameras(value) {
    const formattedValue = value && _.trim(value).toLowerCase();
    this.setState({ searchText: formattedValue });
  }

  onFilterLockTags(value) {
    const { dispatch } = this.props;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 200, true));
  }

  async onFetchLocksByTags(page, append) {
    setTimeout(async () => {
      try {
        const { dispatch, cameraLocksAssociationForm } = this.props;
        const tags = cameraLocksAssociationForm && cameraLocksAssociationForm.values && cameraLocksAssociationForm.values.lockTags ? cameraLocksAssociationForm.values.lockTags : [];
        const lockTagsMatchingMode = cameraLocksAssociationForm && cameraLocksAssociationForm.values && cameraLocksAssociationForm.values.lockTagMatchingMode;
        const smartLocksData = await dispatch(LocksActions.fetchAllLocksByTags(tags, lockTagsMatchingMode, 0, 50));
        dispatch(change('CameraLocksAssociationForm', 'selectedLocks', { data: smartLocksData.locks, pagination: smartLocksData.pagination }));
      } catch (error) {
      }
    }, 500);
  }

  async onSaveCameraLockAssociation(lock) {
    const { dispatch, locksCustomFields, cameras: { selectedCamera } } = this.props;
    const eagleEyeCustomField = _.find(locksCustomFields, lockField => lockField.type === 'INTEGRATION_ID' && lockField && lockField.name && lockField.name.toLowerCase().includes('eagle'));
    if (eagleEyeCustomField) {
      const lockWithCustomField = {
        ...lock,
        [eagleEyeCustomField.keyValue]: selectedCamera.id,
      };
      try {
        dispatch(UtilsActions.setSpinnerVisibile(true));
        const lockUpdated = await dispatch(LocksActions.updateLock(lockWithCustomField));
        const newSmartLocks = selectedCamera.smartLocks && !_.isEmpty(selectedCamera.smartLocks) ? [...selectedCamera.smartLocks, lockUpdated] : [lockUpdated];
        const camera = {
          ...selectedCamera,
          smartLocks: newSmartLocks,
        };
        dispatch(CamerasActions.updateCamera(camera));
        await dispatch(CamerasActions.fetchLocksWithCameras());
        this.onEditCamera(camera);
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } catch (error) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    }
  }

  async onRemoveLockAssociation(lock) {
    const { dispatch, locksCustomFields, cameras: { selectedCamera } } = this.props;
    const eagleEyeCustomField = _.find(locksCustomFields, lockField => lockField.type === 'INTEGRATION_ID' && lockField && lockField.name && lockField.name.toLowerCase().includes('eagle'));
    if (eagleEyeCustomField) {
      const lockWithCustomField = {
        ...lock,
        [eagleEyeCustomField.keyValue]: '',
      };
      try {
        dispatch(UtilsActions.setSpinnerVisibile(true));
        const locksUpdated = await dispatch(LocksActions.updateLock(lockWithCustomField));
        const newLockList = _.filter(selectedCamera.smartLocks, cameraLock => cameraLock.id !== locksUpdated.id);
        const camera = {
          ...selectedCamera,
          smartLocks: newLockList,
        };
        dispatch(CamerasActions.updateCamera(camera));
        await dispatch(CamerasActions.fetchLocksWithCameras());
        this.onEditCamera(camera);
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } catch (error) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    }
  }

  onEditCamera(camera) {
    const { dispatch } = this.props;
    dispatch(CamerasActions.setSelectedCamera(camera));
    const lockTags = _.map(camera.smartLocks, lock => lock.lockTag);
    const selectedLocks = {
      data: camera.smartLocks,
      pagination: { totalElements: _.size(camera.smartLocks), number: 1, totalPages: 1 },
    };
    dispatch(initialize('CameraLocksAssociationForm', { lockTags, selectedLocks }));
    dispatch(change('CameraLocksAssociationForm', 'selectedLocks', selectedLocks));
    this.setState({ showCameraAssociationForm: true });
  }

  onLinkEagleEyeAccount() {
    const currentURL = `${window.location.protocol}//${window.location.host}`;
    window.location.replace(`https://auth.eagleeyenetworks.com/oauth2/authorize?client_id=${EAGLE_EYE_CLIENT_ID}&response_type=code&scope=vms.all&redirect_uri=${currentURL}`);
  }

 

  async onOpenLockForCamera(lock) {
    if (lock && lock.configuration && lock.configuration.remoteOpenEnabled) {
      this.onOpenLockRemotely(lock);
    } else {
      this.onOpenLockByGateway(lock);
    }
  }

  async onOpenLockRemotely(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LocksActions.onOpenFullOnlineLockRemote(lock));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="doorOpenSuccess" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(LocksActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="doorOpenFailed" /></h6>),
        },
      }));
    }
  }

  async onOpenLockByGateway(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const gatewayDetailed = await dispatch(GatewayActions.getGatewayDetails(lock.gatewayId));
      dispatch(GatewayActions.setSelectedGateway(gatewayDetailed));
      dispatch(LocksActions.selectLock(lock));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.lockOpenModal.open();
    } catch (error) {
      dispatch(LocksActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { cameras: { data: { content, pagination }, selectedCamera }, themeName, dispatch, viewLoading, lockTags } = this.props;
    const { showCameraLiveFeed, searchText, showCameraAssociationForm, showLinkAccountButton } = this.state;
    const camerasData = _.filter(content, camera => camera && camera.name && camera.name.toLowerCase().includes(searchText));
    const showLinkButton = showLinkAccountButton && !dispatch(UtilsActions.isEagleEyeIntegrationActive());
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onSearchReset={() => this.setState({ searchText: '' })}
          onFilterChange={(value) => this.onFilterCameras(value)}
          onRefresh={() => this.onFetchCameras()}
          isLoading={viewLoading}
        />
        <CamerasGridView
          viewLoading={viewLoading}
          camerasData={camerasData}
          pagination={pagination}
          onRowClick={camera => this.onSelectCamera(camera)}
          onEditCamera={camera => this.onEditCamera(camera)}
          showLinkAccountButton={showLinkButton}
          onLinkEagleEyeAccount={() => this.onLinkEagleEyeAccount()}
        />
        <Modal
          show={showCameraLiveFeed}
          className="alert-container full-height-modal"
          onHide={() => {
            this.setState({ showCameraLiveFeed: false });
            dispatch(CamerasActions.setSelectedCamera({}));
          }}
        >
          <CameraLiveFeedView
            camera={selectedCamera}
            onCloseModal={() => {
              this.setState({ showCameraLiveFeed: false });
              dispatch(CamerasActions.setSelectedCamera({}));
            }}
            onOpenLockForCamera={lock => this.onOpenLockForCamera(lock)}
          />
        </Modal>
        <BasicModal
          ref={(m) => { this.lockOpenModal = m; }}
          hideCloseButton
          body={
            <div>
              <LockRemoteOpenView
                onCloseModal={() => {
                  this.lockOpenModal.close();
                  dispatch(LocksActions.selectLock({}));
                }}
              />
            </div>
          }
        />
        <OperationalView
          title={<Entity entity="cameraDetails" />}
          isVisible={showCameraAssociationForm}
          themeName={themeName}
          style={{ marginTop: 0 }}
          onClose={() => {
            this.setState({ showCameraAssociationForm: false });
            dispatch(CamerasActions.setSelectedCamera({}));
          }}
        >
          <CameraLocksAssociationForm
            onAddLockToCamera={lock => this.onSaveCameraLockAssociation(lock)}
            onRemoveLockAssociation={lock => this.onRemoveLockAssociation(lock)}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default CamerasView;
