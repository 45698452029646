import { Card, CardContent, Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccessTime, Event } from '@material-ui/icons';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
    marginTop: 15,
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    borderRadius: theme.spacing(1),
    transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
      transform: 'translateY(-2px)',
    },
  },
  content: {
    padding: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(1),
    fontSize: 12,
    height: '35px',
    padding: '0 12px',
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '2.2rem',
  },
  resourceName: {
    fontWeight: 'bold',
    marginBottom: 15,
    fontSize: '2rem',
  },
  reservationId: {
    color: theme.palette.text.secondary,
    fontSize: '1.5rem',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: '1.4rem',
  },
}));

const BookeyReservationsListCard = ({ reservation, onRowClick }) => {
  const classes = useStyles();
  const { id, fromDate, toDate, resource, state, userId } = reservation;

  const formatDate = (date) => {
    return new Date(date).toLocaleString(undefined, {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handleClick = () => {
    if (onRowClick) {
      onRowClick(reservation);
    }
  };

  const isReservationPenalty = (status) => status.endsWith("WITH_PENALTY");
  const isReservationLost = (status) => status === "LOST";

  const getChipProps = (state) => {
    if (isReservationLost(state)) {
      return { label: L20NContext.getSync('reservationStatus_LOST').toUpperCase(), style: { borderColor: 'rgba(241, 130, 141,1)', color: 'rgba(241, 130, 141,1)', fontWeight: 'bold', borderRadius: 10, borderWidth: 2 } };
    } else if (isReservationPenalty(state)) {
      return { label: L20NContext.getSync('bookeyPenalty').toUpperCase(), style: { borderColor: 'rgba(255,167,38,1)', color: 'rgba(255,167,38,1)', fontWeight: 'bold', borderRadius: 10, borderWidth: 2  } };
    } else {
      return { label: L20NContext.getSync('bookeyActiveReservation').toUpperCase(), style: { borderColor: 'rgba(77,182,172,1)', color: 'rgba(77,182,172,1)', fontWeight: 'bold', borderRadius: 10, borderWidth: 2 } };
    }
  };

  const chipProps = getChipProps(state);

  return (
    <Card className={classes.card} onClick={handleClick} elevation={5}>
      <CardContent className={classes.content}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" className={classes.resourceName}>
              {resource.name}
            </Typography>
            <div className={classes.infoItem}>
              <PersonOutlineOutlinedIcon className={classes.icon} />
              <Typography variant="body1" className={classes.text}>
              {`${reservation.user&&reservation.user.firstname?reservation.user.firstname:""} ${reservation.user&&reservation.user.lastname?reservation.user.lastname:""} ${!reservation.user?"-":""}`}
              </Typography>
            </div>
            <div className={classes.infoItem}>
              <Event className={classes.icon} />
              <Typography variant="body1" className={classes.text}>
                {formatDate(fromDate)} - {formatDate(toDate)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} style={{ marginTop: 30 }}>
          {resource.workspace ? (
            <div className={classes.infoItem}>
              <RoomOutlinedIcon className={classes.icon} />
              <Typography variant="body1" className={classes.text}>{resource.workspace}</Typography>
            </div>
            ) : null}
            <div className={classes.infoItem}>
              <AccessTime className={classes.icon} />
              <Typography variant="body1" className={classes.text}>
                {Math.round((toDate - fromDate) / (1000 * 60))} <Entity entity="minutes" />
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} container justifyContent="flex-end" alignItems="center">
            <Chip
              {...chipProps}
              variant="outlined"
              className={classes.chip}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BookeyReservationsListCard;