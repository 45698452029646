import React from 'react';
import { RESOURCE_IMAGE_MAP } from '../../_config/consts';

export default class ResourceRow extends React.Component {
  render() {
    const { resource, themeName, onClick } = this.props;
    const resourceElement = (
      <div className="card-body">
        <div className={`rounded-view bg-${themeName || 'default'}-theme`}>
          <img className={`rounded-view`} src={resource.imageUrl ? RESOURCE_IMAGE_MAP[resource.imageUrl] : `data:image/jpeg;base64,${resource.media}`} />
        </div>
        <div className="card-inner-body" style={{ overflowWrap: 'break-word' }}>
          <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', marginBottom: 0 }}>{`${resource.name}`}</h4>
          <h5 style={{ color: '#3f3f3f', fontWeight: 'normal', marginTop: 5 }}>{`${resource.description}`}</h5>
        </div>
      </div>
    );

    if (onClick) {
      return (
        <div className="card" onClick={() => onClick()}>
          {resourceElement}
        </div>
      );
    }
    return (
      <div className="card-noHover">
        {resourceElement}
      </div>
    )
  }
}
