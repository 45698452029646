import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { setUp as BookeySetup } from '@bottega52/bookey-redux-module';
import CheckIcon from '@material-ui/icons/CheckCircle';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import * as ReservationConfirmAPI from '../../../_config/reservationConfirmAPI';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import { WarningOutlined } from '@material-ui/icons';
import { GREEN, ORANGE } from '../../../_config/consts';

@connect((state) => ({ routing: state.router }))
class ReservationConfirmView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      confirmationStatus: null,

    }
  }
  
  async componentDidMount(values) {
    const { dispatch, routing } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
      const reservationCode = parsed.reservationCode;
      let bookeyURL = 'https://api.bookey.staging.sofialocks.cloud';
      if (process.env.NODE_ENV === 'production') {
        bookeyURL = 'https://api.bookey.sofialocks.cloud';
      }
      BookeySetup(bookeyURL, 30000);
      ReservationConfirmAPI.setReservationConfirmBaseUrl(bookeyURL);
      await ReservationConfirmAPI.confirmBookeyReservation(reservationCode);
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ confirmationStatus: 'SUCCESS', isLoading: false });
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="reservationConfirmSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ confirmationStatus: 'ERROR', isLoading: false });
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="reservationConfirmError" /></h6>),
        },
      }));
    }
  }
  render() {
    const { dispatch } = this.props;
    const { isLoading, confirmationStatus } = this.state;
    return (
      <div id="auth-container" style={{ height: '168vh', display: 'flex', alignItems: 'center', backgroundColor: '#12637f', flexDirection: 'column', overflow: 'scroll', paddingLeft: 30, paddingRight: 30, paddingTop: 50 }} className="login">
        <img src="/imgs/common/logoJagoWhite.png" alt="Sofia" style={{ margin: 10, marginTop: 60, marginBottom: 40, alignSelf: 'center', height: 65 }} />
      {!isLoading && confirmationStatus === 'SUCCESS' ? (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <CheckIcon style={{ alignSelf: 'center', marginBottom: 10, color: GREEN, fontSize: 50 }} />
          <h4 style={{ textAlign: 'center', marginBottom: 10, color: 'white', fontSize: 30, fontWeight: 'bold' }}>{<Entity entity="reservationConfirmSuccess" />}</h4>
          <h4 style={{ textAlign: 'center', marginBottom: 10, color: 'white', fontSize: 23, lineHeight: '1.2em', fontWeight: 'normal' }}>{<Entity entity="reservationConfirmSuccessDescription" />}</h4>
        </div>
      ): null}
      {!isLoading && confirmationStatus === 'ERROR' ? (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <WarningOutlined style={{ alignSelf: 'center', marginBottom: 10, color: ORANGE, fontSize: 50 }} />
          <h4 style={{ textAlign: 'center', marginBottom: 10, color: 'white', fontSize: 25, fontWeight: 'bold' }}>{<Entity entity="reservationConfirmError" />}</h4>
          <h4 style={{ textAlign: 'center', marginBottom: 10, color: 'white', fontSize: 23, lineHeight: '1.2em', fontWeight: 'normal' }}>{<Entity entity="reservationConfirmErrorDescription" />}</h4>
          
        </div>
      ): null}
      </div>
    );
  }
} 

export default ReservationConfirmView;
