import { Warning } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/HelpOutline';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm, submit } from 'redux-form';
import { BLUE, ORANGE, PERMISSIONS, PERMISSION_ENTITIES, RED } from '../../_config/consts';
import { elaborateForbiddenDaysString, hasFormWritePermission, localizeHelpCenterLink } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import MDButton from '../MDButton/MDButton.jsx';
import CredentialTimeSelector from './Elements/CredentialTimeSelector.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import LocksSelectorField from './Elements/LocksSelectorField.jsx';
import TagsSelectorListField from './Elements/TagsSelectorListField.jsx';
import CustomField from './Fields/CustomField.jsx';
import MDSwitchField from './Fields/MDSwitch/MDSwitchField.jsx';
import GuestNoEmailForm from './GuestNoEmailForm.jsx';
import StandardDeviceValidationForm from './StandardDeviceValidationForm/StandardDeviceValidationForm.jsx';



const validate = (values) => {
  const errors = {};
  if (values) {
    if (!values.deviceId) {
      errors.deviceId = 'required';
    } else if (values.deviceId && !((/^[0-9A-F]{8}$/i.test(values.deviceId)) || (/^[0-9A-F]{14}$/i.test(values.deviceId)))) {
      errors.deviceId = 'genericCardNumberInvalid';
    }

    if (!values.guestTags || (values.guestTags && _.isEmpty(values.guestTags))) {
      errors.guestTags = 'required';
    }

    if (!values.lockTags || (values.lockTags && _.isEmpty(values.lockTags))) {
      errors.lockTags = 'required';
    }


    if (!values.credentialTimeframe
        || !values.credentialTimeframe.startDate
        || !values.credentialTimeframe.endDate) {
      errors.credentialTimeframe = 'reservationDateIntevalErrorMessage';
    }
    
    if (values.credentialTimeframe && values.credentialTimeframe.endDate && moment(values.credentialTimeframe.endDate).isAfter('2099-12-31')) {
      errors.credentialTimeframe = 'errorEndDateTooBig';
    }
    
    if (!values.timeIntervalFrom) {
      errors.timeIntervalFrom = 'required';
    }

    if (!values.timeIntervalTo) {
      errors.timeIntervalTo = 'required';
    }
    if (values.timeIntervalTo && values.timeIntervalFrom) {
      const toTime = moment(values.timeIntervalTo);
      const fromTime = moment(values.timeIntervalFrom);
      const toDate = moment(0).hour(toTime.hour()).minute(toTime.minute()).second(0);
      const fromDate = moment(0).hour(fromTime.hour()).minute(fromTime.minute()).second(0);
      if (toDate.isSameOrBefore(fromDate)) {
        errors.timeIntervalTo = 'Interval not valid';
      }
    }
  }

  return errors;
};

@reduxForm({ form: 'HyperKeyCredentialsForm', validate })
@connect(state => ({ form: state.form.HyperKeyCredentialsForm }))
class HyperKeyCredentialsForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showGuestNoEmailForm: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(change('HyperKeyCredentialsForm', 'type', 'F9000'));
  }

  onToggleShowGuestNoEmailForm(value) {
    this.setState({ showGuestNoEmailForm: value });
  }

  onOpenValidationInfo() {
    const helpURL = localizeHelpCenterLink('f9000-keys-validation-period');
    window.open(helpURL);
  }

  render() {
    const {
      form,
      availableLockTags,
      availableUserTags,
      onGuestTagsChange,
      onLockTagsChange,
      guestsFilteredByTags,
      locksFilteredByTags,
      onLockFilterModeChange,
      onGuestInputChange,
      onLockInputChange,
      onFetchMoreGuests,
      onFetchMoreLocks,
      onDeleteCard,
      onSaveKeyNotes,
      dispatch,
      onCreateAnonymousUserForF9000
    } = this.props;
    const { showGuestNoEmailForm } = this.state;
    const notes = form && form.values && form.values.notes ? form.values.notes : '';
    const isEditing = form && form.values && form.values.deviceBackendId;
    const canDeleteStandardDevices = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canReadUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const canReadLock = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const dateFrom = form && form.values && form.values.credentialTimeframe && form.values.credentialTimeframe.startDate;
    const guestTags = form && form.values && form.values.guestTags;
    const dateTo = form && form.values && form.values.credentialTimeframe && form.values.credentialTimeframe.endDate;
    const timeFrom = form && form.values && form.values.timeIntervalFrom;
    const timeTo = form && form.values && form.values.timeIntervalTo;
    const daysString = form && form.values && form.values.daysOfTheWeek && elaborateForbiddenDaysString(form.values.daysOfTheWeek) ? elaborateForbiddenDaysString(form.values.daysOfTheWeek) : '';
    const smartLocksSelected = form && form.values && form.values.selectedLocksTags;
    const locksHaveError = smartLocksSelected && smartLocksSelected.data ? _.find(smartLocksSelected.data, smartLock => smartLock.model !== 'F9000') : false;
    const hideGuestNoEmailSwitch = isEditing || !_.isEmpty(guestTags);
    return (
      <Form>
        <FormCardContainer
          title="insertHyperKeyIdTitle"
          headerStyle={{ marginBottom: 20 }}
        >
          <Entity
            componentClass={Field}
            name="deviceId"
            componentAttribute="placeholder"
            entity="insertHyperKeyNumber"
            component={CustomField}
            helpText={<Entity entity="insertHyperKeyIdTitleHelpText" />}
            textFormattingFunction={value => value.replace(/\s+/g, '')}
            type="text"
            disabled={isEditing}
          />
        </FormCardContainer>
        <FormCardContainer
          title="cardDescription"
          subtitle="cardDescriptionSubtitle"
        >
          <Entity
            componentClass={Field}
            name="notes"
            componentAttribute="placeholder"
            entity="insertCardDescription"
            component={CustomField}
            type="textarea"
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
            className="form-control-custom"
          />
          {isEditing && hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing) && (
            <MDButton
              title={<Entity entity="saveNotes" />}
              backgroundColor={BLUE}
              onClick={() => onSaveKeyNotes(notes)}
            />
          )}
        </FormCardContainer>
        <FormCardContainer
          title="cardTagGuestTitle"
          subtitle="cardTagGuestSubtitle"
        >
          <TagsSelectorListField
            name="guestTags"
            formName="HyperKeyCredentialsForm"
            selectedTagsFieldName="selectedGuestTags"
            tags={availableUserTags}
            selectedTags={guestsFilteredByTags}
            form={form}
            tagsType="GUEST"
            title="guestTagsCard"
            placeholder="insertGuestTagsForCard"
            listTitle="selectedUsers"
            emptyTagSelectionText={canReadUser ? 'noUsersForSelectedTag' : null}
            onFetchMoreItems={page => onFetchMoreGuests(page)}
            onTagsChange={values => onGuestTagsChange(values)}
            onInputChange={value => onGuestInputChange(value)}
            isSingleChoice
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
          />
          {hideGuestNoEmailSwitch ? null : (
            <Field
              name="withoutEmail"
              label={<Entity entity="createNewUserForF9000" />}
              titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
              containerstyle={{ marginTop: 10 }}
              component={MDSwitchField}
              activeText="createNewUserForCardWarning"
              activeTextIcon={<Warning style={{ color: ORANGE, marginRight: 10, marginTop: 0, fontSize: 20 }} />}
              onHandleChange={value => this.onToggleShowGuestNoEmailForm(value)}
            />
          )}
          {showGuestNoEmailForm && !hideGuestNoEmailSwitch ? (
            <div>
              <GuestNoEmailForm
                onSubmit={(guestData) => onCreateAnonymousUserForF9000(guestData)}
              />
              <MDButton
                title={<Entity entity="createUser" />}
                backgroundColor={BLUE}
                disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing)}
                onClick={() => dispatch(submit('GuestNoEmailForm'))}
              />
            </div>
          ) : null}
        </FormCardContainer>
        <FormCardContainer
          title="hyperKeyLockTitle"
          subtitle="hyperKeyTagLockSubtitle"
        >
          <LocksSelectorField
            name="lockTags"
            formName="HyperKeyCredentialsForm"
            selectedTagsFieldName="selectedLocksTags"
            tags={availableLockTags}
            selectedTags={locksFilteredByTags && locksFilteredByTags[0]}
            form={form}
            tagsType="LOCK"
            placeholder="insertLockTagsForCredential"
            listTitle="selectedLocks"
            emptyTagSelectionText={canReadLock ? 'noLocksSelectedWithTag' : null}
            title="cylinderTags"
            lockHasErrorCondition={lock => lock.model !== 'F9000'}
            onFetchMoreItems={page => onFetchMoreLocks(page)}
            onTagsChange={values => onLockTagsChange(values)}
            onInputChange={value => onLockInputChange(value)}
            onFilterModeChange={values => onLockFilterModeChange(values)}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
          />
          {locksHaveError ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Warning style={{ color: ORANGE, marginRight: 5, fontSize: 20 }} />
              <h5 style={{ color: ORANGE, fontWeight: 'bold' }}><Entity entity="selectedLocksIncompatibleError" /></h5>
            </div>
          ) : null}
        </FormCardContainer>
        <FormCardContainer title="credentialTimeAndDate">
          <CredentialTimeSelector
            formName="HyperKeyCredentialsForm"
            canEdit={hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
          />
          {dateFrom && dateTo ? (
            <div style={{ paddingTop: 10, borderTop: '2px solid #157495', marginLeft: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <InfoIcon style={{ color: '#3f3f3f', marginRight: 12, fontSize: 20 }} />
              <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: '#3f3f3f' }}>
                <Entity
                  entity="hyperKeyVerbalDescription"
                  key={`${dateFrom}-${dateTo}-${timeFrom}-${timeTo}`}
                  data={{
                    dateFrom: moment(dateFrom).format('LL'),
                    dateTo: moment(dateTo).format('LL'),
                    timeFrom: moment(timeFrom).format('LT'),
                    timeTo: moment(timeTo).format('LT'),
                  }}
                />
                {!daysString ? '' : <span style={{ marginLeft: 5 }}><Entity entity="except" />{daysString}</span>}
              </h5>
            </div>
          ) : null}
        </FormCardContainer>
        {!isEditing ? (
          <StandardDeviceValidationForm
            form={form}
            formName="HyperKeyCredentialsForm"
            onInfo={() => this.onOpenValidationInfo()}
          />
        ) : null}
        {hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing) && (
          <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
            <MDButton
              title={<Entity entity="save" />}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              disabled={locksHaveError}
              onClick={() => dispatch(submit('HyperKeyCredentialsForm'))}
            />
          </div>
        )
        }
        {onDeleteCard && canDeleteStandardDevices && (
          <FormCardContainer
            title="deleteCredential"
            subtitle="deleteCredentialCardWarning"
          >
            <MDButton
              title={<Entity entity="deleteHyperKey" />}
              backgroundColor={RED}
              onClick={() => onDeleteCard()}
            />
          </FormCardContainer>
        )}
      </Form>
    );
  }
} 

export default HyperKeyCredentialsForm;
