// @ts-nocheck
import { withStyles } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { initialize, submit } from 'redux-form';
import { } from '../_config/consts';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import TicketCategoriesListView from '../components/Tickets/TicketCategoriesListView.jsx';
import TicketCategoriesFiltersForm from '../components/forms/TicketCategoriesFiltersForm.jsx';
import TicketCategoriesForm from '../components/forms/TicketCategoriesForm.jsx';
import * as ModalActions from '../redux/actions/modal.actions';
import * as TicketActions from '../redux/actions/ticket.actions';
import * as UtilsActions from '../redux/actions/utils.actions';

const styles = {
  container: {
  },
  boldText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
};
@withRouter
@connect(state => ({ tickets: state.tickets, themeName: state.settings.items.theme.data.themeName }))
class TicketCategoriesPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isViewLoading: false,
      isFormVisible: false,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(TicketActions.resetTicketCategoriesFilters());
    this.onFetchTicketCategories();
  }

  async onFetchTicketCategories() {
    try {
      const { dispatch } = this.props;
      this.setState({ isViewLoading: true });
      await dispatch(TicketActions.fetchTicketCategories());
      this.setState({ isViewLoading: false });
    } catch (error) {
      this.setState({ isViewLoading: false });
    }
  }

  onCreateNewTicketCategory() {
    this.setState({ isFormVisible: true });
  }

  onTicketCategoryClick(ticketCategory) {
    const { dispatch } = this.props;
    dispatch(initialize('TicketCategoriesForm', ticketCategory));
    this.setState({ isFormVisible: true });
  }

  async onTicketCategoryFormSubmit(formData) {
    const { dispatch } = this.props;
    const isEditing = formData.id;
    const data = {
      ...formData,
    };
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      if (isEditing) {
        await dispatch(TicketActions.editTicketCategory(formData.id, data));
      } else {
        await dispatch(TicketActions.createTicketCategory(data));
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity key={isEditing} entity={'ticketCategoryEditSuccess'} /></h6>),
        },
      }));
      this.setState({ isFormVisible: false });
      this.onFetchTicketCategories();
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity key={isEditing} entity={'ticketCategoryEditError'} /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onSearch(searchValue) {
    const { dispatch } = this.props;
    _.mapKeys(searchValue, (value, key) => {
      dispatch(TicketActions.setTicketCategoriesFilter(key, value));
    });
    this.onFetchTicketCategories();
  }

  onSearchReset() {
    const { dispatch } = this.props;
    dispatch(TicketActions.resetTicketCategoriesFilters());
    this.onFetchTicketCategories();
  }

  async onDeleteTicketCategoryRequest(ticketCategoryId) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(TicketActions.deleteTicketCategory(ticketCategoryId));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="ticketCategoryDeleted" /></h6>),
        },
      }));
      this.setState({ isFormVisible: false });
      this.onFetchTicketCategories();
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { classes, themeName, dispatch, tickets: { ticketCategories: { content: ticketCategoriesData } } } = this.props;
    const { isViewLoading, isFormVisible } = this.state;
    return (
      <div className={classes.container}>
        <div style={{ position: 'absolute', top: 65, right: 0, left: 0 }}>
          <TicketCategoriesFiltersForm
            onSubmit={searchValue => this.onSearch(searchValue)}
            onSearchReset={() => this.onSearchReset()}
            onCreateNew={() => this.onCreateNewTicketCategory()}
          />
        </div>
        <div style={{ marginTop: 100 }}>
          <TicketCategoriesListView
            isViewLoading={isViewLoading}
            ticketCategoriesData={ticketCategoriesData}
            onRowClick={(ticketCategory) => this.onTicketCategoryClick(ticketCategory)}
            onCreateNew={() => this.onCreateNewTicketCategory()}
          />
        </div>
        <OperationalView
          themeName={themeName}
          isVisible={isFormVisible}
          onClose={() => this.setState({ isFormVisible: false })}
          style={{ margin: 0, padding: 0 }}
          title={<Entity key={1} entity={"ticketCategoryForm"} />}
        >
          <div style={{ padding: 10, paddingBottom: '30%' }}>
            <TicketCategoriesForm
              onSubmit={formData => this.onTicketCategoryFormSubmit(formData)}
              onValidateForm={() => dispatch(submit('TicketCategoriesForm'))}
              onDeleteTicketCategoryRequest={(ticketCategoryId) => this.onDeleteTicketCategoryRequest(ticketCategoryId)}
            />
          </div>
        </OperationalView>
      </div>
    )
  }

}

export default withStyles(styles)(TicketCategoriesPage);
