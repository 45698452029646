import { axiosInstance } from './rest';

export function fetchCustomFields(params) {
  const requestData = {
    params,
  };
  return axiosInstance.get('/api/v2/customFields', requestData);
}

export function createCustomField(customFieldDTO) {
  return axiosInstance.post('/api/v2/customFields', customFieldDTO);
}

export function updateCustomField(customFieldId, customFieldDTO) {
  return axiosInstance.put(`/api/v2/customFields/${customFieldId}`, customFieldDTO);
}
