import { combineEpics } from 'redux-observable';
import { fetchLocksByTagsEpic, fetchGuestsByTagsEpic } from './credential.epics';
import { fetchLockTagsByFilterEpic, fetchGuestTagsByFilterEpic, fetchGuestSpecialTagsByFilterEpic } from './tag.epics';
import { fetchResourceTypesByFilterEpic } from './resourcesTypes.epics';
import { fetchAreaLocksByTagsEpic, fetchAllAreasOccupancyEpic } from './area.epics';
import { fetchUsersIdsEpic } from './user.epics';

const rootEpics = combineEpics(
  fetchLocksByTagsEpic,
  fetchGuestsByTagsEpic,
  fetchLockTagsByFilterEpic,
  fetchGuestTagsByFilterEpic,
  fetchResourceTypesByFilterEpic,
  fetchGuestSpecialTagsByFilterEpic,
  fetchAreaLocksByTagsEpic,
  fetchAllAreasOccupancyEpic,
  fetchUsersIdsEpic,
);

export default rootEpics;
