
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import LockIcon from '@material-ui/icons/LockOpen';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../_config/consts';
import UsersIconCustom from '../components/CustomIcons/UsersIconCustom.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import TagsManagementList from '../components/TagsManagement/TagsManagementList.jsx';
import TagsManagementOperationalSection from '../components/TagsManagement/TagsManagementOperationalSection.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider';
import * as TagsManagementActions from '../redux/actions/tagsManagement.actions';


@connect(state => ({ tagsManagement: state.tagsManagement, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class TagsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  componentWillUnmount() {
    this.onCloseOperationalSection();
  }

  onChangeTab(tabIndex) {
    this.setState({ activeTab: tabIndex });
    this.onCloseOperationalSection();
  }

  onCloseOperationalSection() {
    const { dispatch } = this.props;
    dispatch(TagsManagementActions.setOperationalMode(false));
    dispatch(TagsManagementActions.setSelectedTag(null));
    dispatch(TagsManagementActions.resetTagGuestsData());
  }

  render() {
    const { themeName, tagsManagement } = this.props;
    const { activeTab } = this.state;
    const canReadGuestsTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER_TAG);
    const canReadLocksTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK_TAG);
    return (
      <div style={{ top: 65, position: 'fixed', width: '100%' }}>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, index) => this.onChangeTab(index)}
          TabIndicatorProps={{
            style: { display: 'none' }
          }}
        >
          {canReadGuestsTags ?
            <Tab icon={<UsersIconCustom style={{ width: 25 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="guestsGroups" /></h5>} />
          : null}
          {canReadLocksTags ?
            <Tab icon={<LockIcon style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="locksGroups" /></h5>} />
            : null}
        </Tabs>
        <TagsManagementList
          tagListType={activeTab === 0 && canReadGuestsTags ? 'guests' : 'locks'}
        />
        <OperationalView
          themeName={themeName}
          isVisible={tagsManagement.isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          title={<Entity entity="sectionTitle" data={{ name: activeTab === 0 && canReadGuestsTags ? 'guestTagsOperationalTitle' : 'lockTagsOperationalTitle' }} />}
        >
          <TagsManagementOperationalSection
            tagOperationalType={activeTab === 0 && canReadGuestsTags ? 'guests' : 'locks'}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default TagsManagement;