import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import { IconButton, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import InfoIcon from '@material-ui/icons/HelpOutline';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';
import DateRangeView from '../Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import SimpleExportMenu from '../../ExportMenus/SimpleExportMenu.jsx';
import { RED } from '../../../_config/consts.js';


const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: 20,
  },
  dateHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
});

const validate = (values) => {
  const errors = {};
  return errors;
};

@reduxForm({ form: 'AnalyticsDataFilters', validate })
@connect(state => ({ form: state.form.AnalyticsDataFilters }))
class AnalyticsDataFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  handleDatesChanged(startDate,endDate,refreshAnalytics) {
    const { onDatesChanged } = this.props;
    if (startDate && endDate && moment(startDate) && moment(endDate)) {
      const maxEndDate = moment(startDate).add(365, 'days').endOf('day').valueOf();
      if (moment(endDate).isAfter(maxEndDate))
        endDate = maxEndDate;
    }
    onDatesChanged(startDate,endDate,refreshAnalytics)
  }

  handleFocusChange(focusedInput) {
    if (!Boolean(focusedInput)) {
      setTimeout(async () => {
        const { startDate, endDate } = this.props;
        this.handleDatesChanged(startDate,endDate,true);
      }, 100);
    }
  };

  render() {
    const { startDate, endDate, onResetDateFilters, hideCompanyName, onSearchGuestsFromCompanyName, disableComponents,
      hideResourceType, hideWorkspace, onSelectResourceType, resourceTypeOptions, onSelectWorkspace, workspaceOptions, hideExportButton,
      onResetFilters, onShowAnalyticsInfo, onExportAnalytics, classes } = this.props;
    return (
      <div className={classes.header}>
        <div className={classes.dateHeader} style={{pointerEvents: disableComponents? 'none':'unset', opacity: disableComponents? 0.7:1, }}>
          <DateRangeView
            id="bookey-date-selector"
            startDateId="bookey-date-selector-start"
            endDateId="bookey-date-selector-end"
            startDate={startDate ? moment(startDate).startOf('day') : moment().startOf('month')}
            endDate={endDate ? moment(endDate).endOf('day') : moment().endOf('month')}
            noBorder={false}
            endDatePlaceholderText="--/--/--"
            startDatePlaceholderText="--/--/--"
            minimumNight={0}
            numberOfMonths={6}
            style={{ marginTop: 50, marginLeft: 20 }}
            containerStyle={{ display:'flex', flexDirection:'column', alignItems:'center' }}
            isOutsideRange={(day) => false}
            onDatesChange={({ startDate, endDate }) => {this.handleDatesChanged(startDate, endDate, false)}}
            onChange={({ startDate, endDate }) => {this.handleDatesChanged(startDate, endDate, true)}}
            onFocus={(focusedInput) => this.handleFocusChange(focusedInput)}
            onResetOptionSelection={() => onResetDateFilters()}
            helpersOptions={[
              {
                title: L20NContext.getSync('today'),
                startDate: moment().startOf('day'),
                endDate: moment().endOf('day'),
              },
              {
                title: L20NContext.getSync('thisWeek'),
                startDate: moment().startOf('week'),
                endDate: moment().endOf('week'),
              },
              {
                title: L20NContext.getSync('thisMonth'),
                startDate: moment().startOf('month'),
                endDate: moment().endOf('month'),
              },
              {
                title: L20NContext.getSync('thisYear'),
                startDate: moment().startOf('year'),
                endDate: moment().endOf('year'),
              },
            ]}
          />
          {!hideCompanyName ? (
            <Entity
              componentClass={Field}
              name="companyName"
              componentAttribute="placeholder"
              entity="insertCompanyName"
              title={""}
              component={CustomField}
              containerStyle={{ marginLeft: 25, width: 250, marginTop: 3 }}
              onHandleChange={value => onSearchGuestsFromCompanyName(value)}
            />
          ) : null}
          {!hideResourceType ? (
            <Field
              id="selectResourceType"
              name={"type"}
              title={''}
              component={CustomField}
              className="form-control-select"
              componentClass="select"
              onHandleChange={value => onSelectResourceType(value)}
              containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12, marginTop: 3 }}
            >
              {resourceTypeOptions}
            </Field>
          ) : null}
          {!hideWorkspace ? (
            <Field
              id="selectWorkspace"
              name={"workspace"}
              title={''}
              component={CustomField}
              className="form-control-select"
              componentClass="select"
              onHandleChange={value => onSelectWorkspace(value)}
              containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12, marginTop: 3 }}
            >
              {workspaceOptions}
            </Field>
          ) : null}
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 0, marginTop: 0 }}>
            <MDButton
              title={<Entity entity="reset" />}
              containerStyle={{margin:0, marginLeft: 25, marginTop: 5}}
              backgroundColor={RED}
              onClick={() => onResetFilters()}
            />
          </div>
          <IconButton style={{ padding:10, margin:-10, marginLeft:50 }} onClick={() => onShowAnalyticsInfo()}>
            <h5 onClick={() => onShowAnalyticsInfo()} className="link-label" style={{ fontWeight: 'bold', color: '#1B97C2', fontSize: 18 }}>{<Entity entity="information" />}</h5>
            <InfoIcon style={{ color: '#1B97C2', fontSize: 25, marginLeft: 10 }} />
          </IconButton>
          {!hideExportButton? (
            <SimpleExportMenu
              titleContainerStyle={{ marginLeft: 50, marginTop: 0 }}
              iconStyle={{ color: '#1B97C2' }}
              exportTitleStyle={{ color: '#1B97C2' }}
              onExportLogs={format => onExportAnalytics(format)}
            />
          ):null}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AnalyticsDataFilters);