// @ts-nocheck
import { Card, CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import GoToPageIcon from '@material-ui/icons/CallMissedOutgoing';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { BLUE, PERMISSION_ENTITIES, PERMISSIONS } from '../../../_config/consts';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider';
import ScrollableBarChart from '../../Charts/ScrollableBarChart.jsx';
import ChartBoxIconCustom from '../../CustomIcons/ChartBoxIconCustom.jsx';
import Permission from '../../Permission/Permission.jsx';


const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  header: {
    marginTop: -40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  refreshHeader: {
   position: 'absolute',
   right: 0,
   top: 0,
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
    color: '#3f3f3f',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAnalyticsView = () => (
  <div style={{ height: 290, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <ChartBoxIconCustom style={{ color: '#bdbdbd', width: 45 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 5 }}><Entity entity="emptyAccessAnalytics" /></h3>
  </div>
);

class AccessBarChart extends Component {

  render() {
    const { accessChartData, lastUpdate, textColor, fullWidth, isRefreshing, classes, onGoToEventPage, onRefreshMetric} = this.props;
    const chartWidth = fullWidth ? window.screen.width - 270 : window.screen.width - 520;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1, marginRight: fullWidth ? 0 : 20 }} elevation={3}>
          <div className={classes.titleHeader}>
            <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="todayAccesses" /></h3>
            <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.LOG} abilityHelper={AbilityProvider.getAbilityHelper()}>
              <IconButton onClick={() => onGoToEventPage()} style={{ marginLeft: 10, marginTop: 2 }}>
                <GoToPageIcon className={classes.logIcon} style={{ color: textColor, fontSize: 20 }} />
              </IconButton>
            </Permission>
            <div className={classes.refreshHeader}>
              <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}: {moment(lastUpdate).format('LLL')}</h5>
              <IconButton
                disabled={isRefreshing}
                style={{ opacity: isRefreshing ? 0.5 : 1 }}
                onClick={() => onRefreshMetric('smartLocksEvents')}
              >
                <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
              </IconButton>
            </div>
          </div>
          <div style={{ paddingTop: 30 }}>
          {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
            {accessChartData && !_.isEmpty(accessChartData) ? (
              <ScrollableBarChart
                height={100}
                chartMaxWidth={chartWidth}
                data={_.cloneDeep(accessChartData)}
                dataSetSize={24}
                showLegend={false}
              />
            ) : <EmptyAnalyticsView />}
          </div>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(AccessBarChart);
