// @ts-nocheck
import ReactTooltip from 'react-tooltip';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import GatewayIcon from '@material-ui/icons/Cast';
import Tag from '../../components/Tag.jsx';
import { ACCESSORIES_TYPES, BATTERY_IMAGES, PERMISSIONS, PERMISSION_ENTITIES, SMARTLOCK_DOOR_STATUS, SMARTLOCK_DOOR_STATUS_MAP } from '../../_config/consts';
import { getLockImage, getStatusColorFromBatteryLevel } from '../../_config/utils';
import Card from '@material-ui/core/Card';
import LockOpen from '@material-ui/icons/LockOpen';
import SmartClassicIcon from '@material-ui/icons/WifiTethering';
import HyperTagIcon from '@material-ui/icons/Nfc';
import OfflineIcon from '@material-ui/icons/CloudOff';
import { ShareOutlined } from '@material-ui/icons';
import MDButton from '../MDButton/MDButton.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import KeyOutlinedIconCustom from '../CustomIcons/KeyOutlinedIconCustom.jsx';
import PrivacyIconCustom from '../CustomIcons/PrivacyIconCustom.jsx';
import CustomFieldsDisplayView from '../CustomFields/CustomFieldsDisplayView.jsx';
import CameraIconCustom from '../CustomIcons/CameraIconCustom.jsx';
import SmartReaderIconCustom from '../CustomIcons/SmartReaderIconCustom.jsx';


const PowerIcon = ({ isPlugged, batteryLevel }) =>
  (
    <div style={{ flexDirection: 'row', alignSelf: 'flex-end', paddingBottom: 2 }}>
      {isPlugged ?
        <img alt="battery" src={'/imgs/common/batteryIcons/lightning.png'} style={{ width: 15, height: 22, marginRight: 10 }} />
        :
        <img alt="battery" src={BATTERY_IMAGES[batteryLevel]} style={{ width: 32, height: 12.5 }} />
      }
    </div>
  );

export default class LockDetailsRow extends React.Component {
  
  onOpenLock(event) {
    const { onOpenLock } = this.props;
    event.stopPropagation();
    if (onOpenLock) onOpenLock();
  }

  render() {
    const { lock: { hyperTagId, battery, doorStatusSensorId, doorStatus, availableOffline, connectionType, tags, model, serialNumber, name, configuration, powerSourceType, lockTag, gatewayId }, lock, isSelected, onClick } = this.props;
    const canSendGatewayNotification = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.GATEWAY_NOTIFICATION);
    let batteryStatusColor = battery && battery !== -1 && battery <= 50 ? '#f4b350' : 'transparent';
    batteryStatusColor = battery && battery !== -1 && battery <= 25 ? 'red' : batteryStatusColor;
    const textStatusColor = getStatusColorFromBatteryLevel(battery);
    const isF9000 = model === 'F9000';
    const isSmartClassic = connectionType && connectionType === 'SMART_CLASSIC_OFFLINE' && !isF9000;
    const hasGayeway = gatewayId;
    const hasHyperTag = hyperTagId;
    const isAvailableOffline = availableOffline;
    const isFullOnlineGate = configuration && configuration.remoteOpenEnabled;
    const canBeOpenRemotely = (gatewayId && canSendGatewayNotification) || isFullOnlineGate;
    const lockSensor = lock.accessories ? _.find(lock.accessories, accessory => accessory.type === ACCESSORIES_TYPES.DOOR_SENSOR) : null;
    const lockReader = lock.accessories ? _.find(lock.accessories, accessory => accessory.type === ACCESSORIES_TYPES.BT_READER) : null;
    const doorStatusVariables = lockSensor && lockSensor.value && SMARTLOCK_DOOR_STATUS_MAP[lockSensor.value] ? SMARTLOCK_DOOR_STATUS_MAP[lockSensor.value] : SMARTLOCK_DOOR_STATUS_MAP[SMARTLOCK_DOOR_STATUS.UNKNOWN];
    const showDoorStatus = doorStatusVariables && lockSensor;
    return (
      <Card
        style={{ padding: 5, borderRadius: 10, borderLeft: `solid 4px ${batteryStatusColor}`, position: 'relative', }}
        className={`card card-rounded ${!onClick && 'card-noHover'} ${isSelected && 'card-selected'}`}
        onClick={onClick ? () => onClick() : null}
        elevation={2}
      >
        <div className="card-body" style={{ marginLeft: 10, position: 'relative' }}>
          <div>
            <div className="rounded-view">
              <img className="lock-icon" style={{ marginTop: 20, width: 80, height: 80 }} src={getLockImage(model)} />
            </div>
          </div>
          <div className="card-inner-body">
            <div className="card-header" style={{ paddingLeft: 0, paddingRight: 0, display: 'flex', flexDirection: 'column' }}>
              <h4 className="card-title" style={{ width: '89%', margin: 0, marginTop: 20, marginLeft: 5, color: '#3f3f3f', textOverflow: 'ellipsis', whiteSpace: 'break-spaces' }}>{name}</h4>
              <h5 className="card-title" style={{ marginBottom: 0, marginTop: 10, color: '#3f3f3f', fontWeight: 'normal', marginLeft: 5 }}>{serialNumber}</h5>
              {canBeOpenRemotely && (
                <MDButton
                  containerStyle={{ margin: 0, marginRight: 5, position: 'absolute', right: 5 }}
                  onClick={(e) => this.onOpenLock(e)}
                  icon={<LockOpen style={{ color: 'white', marginLeft: 10, fontSize: 15 }} />}
                  title={<Entity entity="openLock" />}
                >
                </MDButton>
              )}
            </div>
            <CustomFieldsDisplayView
              textStyle={{ fontSize: 15 }}
              iconStyle={{ width: 15 }}
              containerStyle={{ marginLeft: 5, marginTop: 5 }}
              customFields={lock.customFields}
            />
            <div className="tags-container" style={{ marginTop: 5, marginBottom: 0, marginRight: 35 }}>
              <Tag tag={lockTag} />
              {_.map(tags, tag =>
                <Tag
                  key={tag.id}
                  tag={tag}
                />,
            )}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {doorStatusVariables && showDoorStatus ? (
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
              {doorStatusVariables.icon}
              <Entity key={doorStatusVariables.title} style={{ marginLeft: 5, fontWeight: 'bold', color: doorStatusVariables.color }} entity={doorStatusVariables.title} />
            </div>
          ) : null}
          {isSmartClassic ? <p style={{ margin: 0, display: 'flex' }} data-tip={L20NContext.getSync('lockIsSmartClassicOrOnline')}><SmartClassicIcon style={{ fontSize: 20, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 15 }} /></p> : null}
          {isF9000 ? <p style={{ margin: 0, display: 'flex' }} data-tip={L20NContext.getSync('lockIsMechatronicCylinder')}><KeyOutlinedIconCustom style={{ width: 20, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 0 }} /></p> : null}
          {hasHyperTag && <p style={{ margin: 0, display: 'flex' }} data-tip={L20NContext.getSync('lockHasHyperTag')}><HyperTagIcon style={{ fontSize: 20, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 15 }} /></p>}
          {isAvailableOffline && <p style={{ margin: 0, display: 'flex' }} data-tip={L20NContext.getSync('availableOffline')}><OfflineIcon style={{ fontSize: 20, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 15 }} /></p>}
          {hasGayeway ? <p style={{ margin: 0, display: 'flex' }} data-tip={L20NContext.getSync('lockIsConnectedToGateway')}><GatewayIcon style={{ fontSize: 20, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 15 }} /></p> : null}
          {lock.privacyModeEnabled ? <p style={{ margin: 0, display: 'flex' }} data-tip={L20NContext.getSync('privacyModeEnabled')}><PrivacyIconCustom style={{ width: 20, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 15 }} /></p> : null}
          {lock.lockHasCamera ? <p style={{ margin: 0, display: 'flex' }} data-tip={L20NContext.getSync('lockIsConnectedToCamera')}><CameraIconCustom style={{ width: 20, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 15 }} /></p> : null}
          {lockReader ? <p style={{ margin: 0, marginTop: 2, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('lockIsConnectedWithAReader')}><SmartReaderIconCustom style={{ width: 18, alignSelf: 'flex-end', color: '#3f3f3f', marginTop: -2, marginBottom: 0, marginRight: 15 }} /></p> : null}
          {lock.shared || lock.sharedFromHostname ? (<p style={{ margin: 0, marginTop: 2, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('lockIsSharedWithDomain')}><ShareOutlined style={{ width: 18, alignSelf: 'flex-end', color: '#3f3f3f', marginTop: -2, marginBottom: 0, marginRight: 15 }} /></p>) : null}
          {battery !== undefined && battery !== -1 && !isF9000 ?
            <PowerIcon
              isPlugged={powerSourceType === 'PLUGGED'}
              batteryLevel={battery}
              style={{ alignSelf: 'flex-end', color: '#3f3f3f', paddingBottom: 5 }}
            />
            : <div style={{ padding: 5 }} />}
          {powerSourceType !== 'PLUGGED' && battery !== -1 && !isF9000 ? <h5 style={{ margin: 0, marginLeft: 10, color: textStatusColor, fontWeight: 'bold' }}>{battery}%</h5> : null}
        </div>
        <ReactTooltip delayShow={200} effect="float" />
      </Card>
    );
  }
}
