// @ts-nocheck
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import RoleIcon from '@material-ui/icons/Security';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as RolesActions from '../redux/actions/roles.actions';
import UsersIconCustom from './CustomIcons/UsersIconCustom.jsx';

@connect(state => ({ user: state.user }))
class ProfileSection extends Component {
  render() {
    const { user: { data: userData } } = this.props;
    return (
      <Card style={{ padding: 10, marginBottom: 15, paddingLeft: 15, marginTop: 10 }}>
        <h3 style={{ fontWeight: 'bold', color: '#3f3f3f', marginTop: 0 }}><Entity entity="yourUser" /></h3>
        <Divider style={{ height: 2 }} />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div>
            <h4 style={{ color: '#3f3f3f' }}>
              {`${userData.firstname} ${userData.lastname}`}
            </h4>
            <h4 style={{ color: '#3f3f3f' }}>
              {userData.email}
            </h4>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {_.map(userData.roles, (role) => {
                const isRoleGuest = role && role.name === 'ROLE_GUEST';
                const formattedRole = RolesActions.formatRole(role);
                return (
                  <div key={role.id} style={{ display: 'flex', marginTop: 5 }}>
                    {!isRoleGuest ? <RoleIcon style={{ color: '#3f3f3f', marginRight: 5, marginTop: 3, fontSize: 20 }} /> : <UsersIconCustom style={{ color: '#3f3f3f', marginTop: 0, marginBottom: 5, marginRight: 5, width: 20 }} />}
                    <h5 style={{ color: '#3f3f3f', marginTop: 6, marginRight: 10, fontWeight: 'bold' }}>{formattedRole.label}</h5>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default ProfileSection;
