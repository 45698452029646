import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';

export default class PieChart extends Component {

  componentDidMount() {
    document.addEventListener("visibilitychange", () => this.handleVisibilityChange());
  }
  
 componentWillUnmount() {
    document.removeEventListener("visibilitychange", () => this.handleVisibilityChange());
  }

  handleVisibilityChange() {
    if (document.visibilityState === "visible" && this.chartRef && this.chartRef.chartInstance) {
      this.chartRef?.chartInstance.update();
    }
  }


  render() {
    const { chartData, legendPosition, onElementClick, showLegend, height, width, unsetWidth } = this.props;
    return (
      <div style={{ zoom: '125%', overflowX: 'scroll', width: '100%' }}>
        <Doughnut
          data={chartData}
          ref={(c) => { this.chartRef = c; }}
          height={height!==undefined?height:165}
          width={width!==undefined?width:unsetWidth?undefined:200}
          type="doughnut"
          onElementsClick={onElementClick ? (elems) => onElementClick(elems) : null}
          options={{
            cutoutPercentage: 75,
            events: ['click', 'mousemove'],
            legend: {
              display: showLegend,
              padding: 10,
              position: legendPosition || 'bottom',
            },
          }}
        />
      </div>
    );
  }
};
