import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Icon from '@sketchpixy/rubix/lib/Icon';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { FormGroup, ControlLabel } from '@sketchpixy/rubix';
import { connect } from 'react-redux';


const Option = ({ value, onClick, isSelected }) => (
  <div style={{ color: (isSelected ? 'white' : 'gray') }} onClick={() => onClick(value)}>
    <Icon glyph={(isSelected ? 'icon-simple-line-icons-check' : 'icon-simple-line-icons-ban')} style={{ paddingRight: 5 }} />
    {_.capitalize(moment.weekdays(value))}
  </div>
);

const Selector = ({ options, selectedOptions, onConfirm, onChange }) => {
  const buttons = _.map(options, (option, index) => (
    <Option
      onClick={value => onChange(value)}
      value={option}
      isSelected={_.includes(selectedOptions, option) || _.isEqual(selectedOptions, option)}
      key={index}
    />
  ));
  return (
    <div className="card" style={{ padding: 0, position: 'absolute', backgroundColor: 'white', bottom: -15, left: 0, zIndex: 10000, minWidth: 150, minHeight: 100 }}>
      <div style={{ padding: 5, paddingLeft: 10, backgroundColor: '#157495', color: 'white' }}>
        {buttons}
      </div>
      <div onClick={() => onConfirm()} style={{ color: '#1b97c2', fontWeight: 'bold', textAlign: 'center', lineHeight: '220%' }}>
        <Entity entity="close" />
      </div>
    </div>
  );
}
@connect((state) => ({ language: state.settings.language }))
class DaysOfTheWeekPicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSelecting: false,
      selectableOptions: _.times(7, option => option + 1),
    };
    this.selectorBox = null;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onConfirm() {
    this.toggleSelector();
  }

  handleClickOutside = (event) => {
    if (this.selectorBox && !this.selectorBox.contains(event.target)) {
      this.setState({ isSelecting: false });
    }
  }

  toggleSelector() {
    const { isSelecting } = this.state;
    this.setState({ isSelecting: !isSelecting });
  }

  onHandleChange(e, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(e);
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }

  toggleOption(selection) {
    const { input: { value: selectedOptions }, input, isSingleChoice } = this.props;
    if (_.includes(selectedOptions, selection) || _.isEqual(selectedOptions, selection)) { // if already selected
      this.onHandleChange(_.filter(selectedOptions, i => i !== selection), input);
    } else if (isSingleChoice) {
      this.onHandleChange(selection, input);
    } else {
      this.onHandleChange([...selectedOptions, selection], input);
    }
  }

  render() {
    const { icon, title, mandatory, containerstyle, titleColor, input: { value: selectedOptions } } = this.props;
    const { isSelecting, selectableOptions } = this.state;
    return (
      <FormGroup style={{ ...containerstyle }}>
        <ControlLabel style={{ color: titleColor || '#3f3f3f', fontSize: 16 }}>{title}{mandatory ? <span style={{ color: '#D71F4B' }}>*</span> : ''}</ControlLabel>
        <div ref={(c) => { this.selectorBox = c; }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          { icon && <Icon glyph={icon} /> }
            <div onClick={() => this.toggleSelector()}>
              {_.map(selectableOptions, (option) => {
                const isOptionSelected = _.indexOf(selectedOptions, option) >= 0;
                return (
                  <span key={option}>
                    <span style={{ color: (isOptionSelected ? '#1b97c2' : 'gray'), fontWeight: (isOptionSelected ? 'bold' : 'normal'), fontSize: (isOptionSelected ? 16 : 14) }}>
                      {moment.weekdaysShort(option).toUpperCase()}
                    </span>
                    { (option < 7) && <span> - </span> }
                  </span>
                );
              })}
            </div>
          {isSelecting &&
            <Selector
              options={selectableOptions}
              selectedOptions={selectedOptions}
              onChange={selection => this.toggleOption(selection)}
              onConfirm={() => this.onConfirm()}
            />}
        </div>
      </FormGroup>
    );
  }
}

export default DaysOfTheWeekPicker;
