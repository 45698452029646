import { reduxForm, Field } from 'redux-form';
import React from 'react';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import MDCheckBoxField from './Fields/MDCheckBox/MDCheckBoxField';


const validate = (values, props) => {
  const errors = _.reduce(props.agreements, (acc, curr) => {
    if (curr.mandatory && !values[curr.id]) {
      acc[curr.id] = 'required';
    } else {
      acc[curr.id] = undefined;
    }
    return acc;
  }, {});
  return errors;
};

const AgreementsForm = ({ agreements }) =>
  (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {_.map(agreements, a => (
        <Field
          key={a.id}
          name={String(a.id)}
          component={MDCheckBoxField}
          showErrorLabel
          label={<Entity entity={a.acceptanceField} />}
        />
      ),
      )}
    </div>
  );

export default reduxForm({
  form: 'AgreementsForm',
  validate,
})(AgreementsForm);
