import React from 'react';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { Modal, Button } from '@sketchpixy/rubix';

import Alert from './Alert.jsx';
import errors from '../../_config/errors';

export default class InfoModal extends React.Component {
  hideModal() {
    const { hideModal } = this.props;
    hideModal();
  }

  render() {
    const { title, message } = this.props;

    return (
      <Alert
        {...this.props}
        title={<Entity entity="modalTitle" data={{ modal: title }} />}
        message={<Entity entity="modalMessage" data={{ modal: message }} />}
        type="info"
        visible
        onOutsideClick={() => this.hideModal()}
      />
    );
  }
}
