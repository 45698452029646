import React from 'react';
import { connect } from 'react-redux';
import MDButton from '../../components/MDButton/MDButton.jsx';
import { getChainelsBaeUrl } from '../../_config/utils.js';

@connect((state) => ({ settings: state.settings }))
class LoginWithChainels extends React.Component {

  async onChainelsLoginCode() {
    const { idp } = this.props;
    const currentURL = `${window.location.protocol}//${window.location.host}`;
    const chainelsURL = getChainelsBaeUrl();
    window.location.replace(`${chainelsURL}/oauth/authorize?grant_type=authorization_code&client_id=${idp.clientId}&redirect_uri=${currentURL}&scope=openid&response_type=code&state=CHAINELS`);
  }

  render() {
    return (
      <div>
        <MDButton
          type="submit"
          backgroundColor="white"
          icon={<img src="/imgs/common/integrationLogos/chainels-black.png" style={{ height: 22 }} />}
          containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
          onClick={() => this.onChainelsLoginCode()}
        />
      </div>
    );
  }
} 

export default LoginWithChainels;
