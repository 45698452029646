module.exports = {
  SAVE_USER_DATA: 'SAVE_USER_DATA',
  LOGIN: 'LOGIN',
  SET_LOGIN_FIELD: 'SET_LOGIN_FIELD',
  SET_USER_AUTHENTICATION: 'SET_USER_AUTHENTICATION',
  LOGOUT: 'LOGOUT',
  SAVE_SET_PASSWORD_FIELD: 'SAVE_SET_PASSWORD_FIELD',
  RESET_SET_PASSWORD_FIELDS: 'RESET_SET_PASSWORD_FIELDS',
  SET_USER_TOKEN_INFO: 'SET_USER_TOKEN_INFO',
  SAVE_COMPANY_CONFIGURATIONS: 'SAVE_COMPANY_CONFIGURATIONS',
  SAVE_USER_DOMAINS: 'SAVE_USER_DOMAINS',
  SAVE_DOMAIN_LICENSE: 'SAVE_DOMAIN_LICENSE',
  SAVE_STORE_ITEMS: 'SAVE_STORE_ITEMS',
  SAVE_COMPANY_INFO: 'SAVE_COMPANY_INFO',
  SAVE_CURRENT_ACTIVE_INTEGRATIONS: 'SAVE_CURRENT_ACTIVE_INTEGRATIONS',
  SAVE_SYSTEM_HAS_ATLAS: 'SAVE_SYSTEM_HAS_ATLAS',
  SET_NEXUDUS_CLIENT_ID: 'SET_NEXUDUS_CLIENT_ID',
  SET_CHAINELS_AUTH_PARAMETERS: 'SET_CHAINELS_AUTH_PARAMETERS',
  SET_OCTORATE_ACTIVATION_PARAMETERS: 'SET_OCTORATE_ACTIVATION_PARAMETERS',
};
