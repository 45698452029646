/* eslint-disable no-await-in-loop */
import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import {
  SAVE_ACCESS_PROFILES,
  APPEND_ACCESS_PROFILES,
  SAVE_ACCESS_PROFILE,
  SET_ACCESS_PROFILES_PAGINATION_DATA,
  SELECT_ACCESS_PROFILE,
  SET_ACCESS_PROFILES_FILTER,
  UPDATE_ACCESS_PROFILE,
  RESET_ACCESS_PROFILES_FILTERS,
  RESET_ACCESS_PROFILES_DATA,
  RESET_ACCESS_PROFILE_PAGINATION_DATA,
  SET_ACCESS_PROFILES_OPERATIONAL_MODE,
  SAVE_ACCESS_PROFILE_GUESTS_DATA,
  APPEND_ACCESS_PROFILE_GUESTS_DATA,
  SAVE_ACCESS_PROFILE_GUESTS_DATA_PAGINATION,
  RESET_ACCESS_PROFILE_GUESTS_DATA,
} from './actionTypes/accessProfiles';
import * as UtilsActions from '../actions/utils.actions';
import * as formatter from '../../_config/formatter';
import * as AccessProfilesAPI from '../../_config/accessProfilesAPI';
import * as GuestsAPI from '../../_config/rest';
import * as ModalActions from '../actions/modal.actions';
import { BACKGROUND_TASK_TYPES, EXPORT_FORMATS, ITEM_DELIVERY_TYPES_NAMES, ITEM_TYPES_NAMES } from '../../_config/consts';
import { saveDataToLocalStorage } from '../../_config/utils';

export function setAccessProfilesOperationalMode(value) {
  return {
    type: SET_ACCESS_PROFILES_OPERATIONAL_MODE,
    value,
  };
}

export function saveAccessProfiles(accessProfiles) {
  return {
    type: SAVE_ACCESS_PROFILES,
    accessProfiles,
  };
}

export function appendAccessProfiles(accessProfiles) {
  return {
    type: APPEND_ACCESS_PROFILES,
    accessProfiles,
  };
}

export function saveAccessProfilesPaginationData(pagination) {
  return {
    type: SET_ACCESS_PROFILES_PAGINATION_DATA,
    pagination,
  };
}

export function setAccessProfilesFilter(field, value) {
  return {
    type: SET_ACCESS_PROFILES_FILTER,
    field,
    value,
  };
}

export function setSelectedAccessProfile(accessProfile) {
  return {
    type: SELECT_ACCESS_PROFILE,
    accessProfile,
  };
}

export function updateAccessProfileOnState(accessProfile) {
  return {
    type: UPDATE_ACCESS_PROFILE,
    accessProfile,
  };
}

export function resetAccessProfilesData() {
  return {
    type: RESET_ACCESS_PROFILES_DATA,
  };
}

export function resetAccessProfilesFilters() {
  return {
    type: RESET_ACCESS_PROFILES_FILTERS,
  };
}

export function resetAccessProfilesPagination() {
  return {
    type: RESET_ACCESS_PROFILE_PAGINATION_DATA,
  };
}

export function saveAccessProfileGuestsData(guests) {
  return {
    type: SAVE_ACCESS_PROFILE_GUESTS_DATA,
    guests
  }
}
export function appendAccessProfileGuestsData(guests) {
  return {
    type: APPEND_ACCESS_PROFILE_GUESTS_DATA,
    guests
  }
}
export function saveAccessProfileGuestsDataPagination(pagination) {
  return {
    type: SAVE_ACCESS_PROFILE_GUESTS_DATA_PAGINATION,
    pagination
  }
}

export function resetAccessProfileGuestsData() {
  return {
    type: RESET_ACCESS_PROFILE_GUESTS_DATA
  }
}

export function fetchAccessProfiles(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().accessProfiles.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const accessProfileResponse = await AccessProfilesAPI.getAccessProfiles(params);
      if (accessProfileResponse && accessProfileResponse.data && accessProfileResponse.data.content) {
        const formattedAccessProfiles = _.map(accessProfileResponse.data.content, accessProfile => formatter.formatInputData(formatter.ACCESS_PROFILE, accessProfile));
        dispatch(saveAccessProfiles(formattedAccessProfiles));
        dispatch(saveAccessProfilesPaginationData(_.omit(accessProfileResponse.data, 'content')));
        return accessProfileResponse.data.content;
      }
      throw new Error();

    } catch (error) {
      throw error;
    }
  }
}


// Fetch guests special tags and number of guests associated to each tag
export function fetchAccessProfilesWithUsers(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    const filters = getState().accessProfiles.filters;
    try {
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const accessProfilesResponse = await AccessProfilesAPI.getAccessProfiles(params);
      if (accessProfilesResponse && accessProfilesResponse.data) {
        const accessProfilesFetched = accessProfilesResponse.data.content;
        const accessProfilesToSave = [];
        const approvationCalls = _.map(accessProfilesFetched, async (accessProfile) => {
          try {
            const guestsNumber = await dispatch(fetchGuestNumberByAccessProfile(accessProfile.id));
            const formattedAccessProfiles = formatter.formatInputData(formatter.ACCESS_PROFILE, accessProfile);
            accessProfilesToSave.push({ ...formattedAccessProfiles, guestsNumber });
          } catch (error) {
            const formattedAccessProfiles = formatter.formatInputData(formatter.ACCESS_PROFILE, accessProfile);
            accessProfilesToSave.push({ ...formattedAccessProfiles, guestsNumber: 0 });
          }
        });
        try {
          await Promise.all(approvationCalls);
          dispatch(saveAccessProfiles(accessProfilesToSave));
          dispatch(saveAccessProfilesPaginationData(_.omit(accessProfilesResponse.data, 'content')));
          return accessProfilesResponse.data.content;
        } catch (error) {
          dispatch(saveAccessProfiles(accessProfilesToSave));
          dispatch(saveAccessProfilesPaginationData(_.omit(accessProfilesResponse.data, 'content')));
        }
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


// Fetch number of guest associated to a tag
export function fetchGuestNumberByAccessProfile(accessProfileId) {
  return async (dispatch) => {
    try {
      const params = {
        page: 0,
        pageSize: 1,
        roleIds: [5],
        accessProfileId: accessProfileId,
      };
      const guestsResponse = await GuestsAPI.fetchGuests(params);
      if (guestsResponse && guestsResponse.data) {
        const pagination = _.omit(guestsResponse.data, 'content');
        return pagination.totalElements;
      }
      return 0;
    } catch (error) {
      return 0;
    }
  };
}

export function fetchAccessProfilesOptions(filters, page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const accessProfileResponse = await AccessProfilesAPI.getAccessProfiles(params);
      if (accessProfileResponse && accessProfileResponse.data) {
        return accessProfileResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAndAppendAccessProfiles(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().accessProfiles.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const accessProfileResponse = await AccessProfilesAPI.getAccessProfiles(params);
      if (accessProfileResponse && accessProfileResponse.data && accessProfileResponse.data.content) {
        const formattedAccessProfiles = _.map(accessProfileResponse.data.content, accessProfile => formatter.formatInputData(formatter.ACCESS_PROFILE, accessProfile));
        dispatch(appendAccessProfiles(formattedAccessProfiles));
        dispatch(saveAccessProfilesPaginationData(_.omit(accessProfileResponse.data, 'content')));
        return accessProfileResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// Given a tags fetch the guest associated to that tag
export function fetchGuestsByAccessProfiles(accessProfileId, page = 0, pageSize = 30) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
        roleIds: [5],
        accessProfileId: accessProfileId,
      };
      const guestsResponse = await GuestsAPI.fetchGuests(params);
      if (guestsResponse && guestsResponse.data && guestsResponse.data.content) {
        dispatch(saveAccessProfileGuestsData(guestsResponse.data.content));
        dispatch(saveAccessProfileGuestsDataPagination(_.omit(guestsResponse.data, 'content')));
        return guestsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


// Given a tags fetch the guest associated to that tag
export function fetchAndAppendGuestsByTag(accessProfileId, page = 0, pageSize = 30) {
  return async (dispatch) => {
    try {
      const params = {
        page,
        pageSize,
        roleIds: [5],
        accessProfileId: accessProfileId,
      };
      const guestsResponse = await GuestsAPI.fetchGuests(params);
      if (guestsResponse && guestsResponse.data && guestsResponse.data.content) {
        dispatch(appendAccessProfileGuestsData(guestsResponse.data.content));
        dispatch(saveAccessProfileGuestsDataPagination(_.omit(guestsResponse.data, 'content')));
        return guestsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllAccessProfilesForExport(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().accessProfiles.data.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'creatingCardsExportFile',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        ...filters,
      };
      let accessProfileResponse = await AccessProfilesAPI.getAccessProfiles(params);
      if (accessProfileResponse && accessProfileResponse.data && accessProfileResponse.data.content && !_.isEmpty(accessProfileResponse.data.content)) {
        const accessProfiles = [...accessProfileResponse.data.content];
        let pagination = _.omit(accessProfileResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          accessProfileResponse = await AccessProfilesAPI.getAccessProfiles(params);
          accessProfiles.push(...accessProfileResponse.data.content);
          pagination = _.omit(accessProfileResponse.data, 'data');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { accessProfiles, stopFetching };
      } else {
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { accessProfiles: [], stopFetching };
      }
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function createAccessProfile(accessProfileData) {
  return async (dispatch, getState) => {
    try {
      const additionalTimeRangeDefault = accessProfileData && accessProfileData.additionalTimeRange_default && !_.isEmpty(accessProfileData.additionalTimeRange_default) ? formatter.formatOutputData(formatter.TIME_RANGE, _.first(accessProfileData.additionalTimeRange_default)) : null;
      const accessProfileDataWithTimeRange = {...accessProfileData, additionalTimeRange: additionalTimeRangeDefault };
      const formattedItemOutDTO = formatter.formatOutputData(formatter.ACCESS_PROFILE, accessProfileDataWithTimeRange);
      const createResponse = await AccessProfilesAPI.createAccessProfile(formattedItemOutDTO);
      if (createResponse && createResponse.data) {
        return createResponse.data;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function updateAccessProfile(accessProfileId, accessProfileData) {
  return async (dispatch, getState) => {
    try {
      const additionalTimeRangeDefault = accessProfileData && accessProfileData.additionalTimeRange_default && !_.isEmpty(accessProfileData.additionalTimeRange_default) ? formatter.formatOutputData(formatter.TIME_RANGE, _.first(accessProfileData.additionalTimeRange_default)) : null;
      const accessProfileDataWithTimeRange = {...accessProfileData, additionalTimeRange: additionalTimeRangeDefault };
      const formattedItemOutDTO = formatter.formatOutputData(formatter.ACCESS_PROFILE, accessProfileDataWithTimeRange);
      const updateResponse = await AccessProfilesAPI.updateAccessProfile(accessProfileId, formattedItemOutDTO);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteAccessProfile(accessProfileId) {
  return async (dispatch, getState) => {
    try {
      const deleteResponse = await AccessProfilesAPI.deleteAccessProfile(accessProfileId);
      if (deleteResponse && deleteResponse.data) {
        return deleteResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function exportAccessProfiles(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { accessProfiles, stopFetching } = await dispatch(fetchAllAccessProfilesForExport());
      if (!stopFetching) {
        const exportData = [];
        _.each(accessProfiles, (accessProfile) => {
          exportData.push({
            ID: accessProfile.id,
            Name: accessProfile.name,
            Description: accessProfile.description,
            'Dates': accessProfile.fromDate && accessProfile.toDate ? `${moment(accessProfile.fromDate).format('DD MMMM YYYY')} - ${moment(accessProfile.toDate).format('DD MMMM YYYY')}` : '---',
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { accessProfiles: ws }, SheetNames: ['accessProfiles'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `AccessProfile_Export.${fileExtension}`);
        return accessProfiles;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function setAccessProfilesViewMode(viewMode) {
  saveDataToLocalStorage('accessProfilesViewMode', viewMode);
}