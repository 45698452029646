// @ts-nocheck
import React, { PropTypes } from 'react';
import { Radio } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { BLUE } from '../../../../../_config/consts';
import { Button } from '@material-ui/core';
// // import DateRangePickerShape from '../src/shapes/DateRangePickerShape';
// import { START_DATE, END_DATE, HORIZONTAL_ORIENTATION, ANCHOR_LEFT } from '../constants';
// import isInclusivelyAfterDay from '../src/utils/isInclusivelyAfterDay';


const DISPLAY_FORMAT = 'L';
const ISO_FORMAT = 'YYYY-MM-DD';

const START_DATE = 'startDate';
const END_DATE = 'endDate';

const HORIZONTAL_ORIENTATION = 'horizontal';
const VERTICAL_ORIENTATION = 'vertical';
const VERTICAL_SCROLLABLE = 'verticalScrollable';

const ANCHOR_LEFT = 'left';
const ANCHOR_RIGHT = 'right';

// const defaultProps = {
//   // example props for the demo
//   autoFocus: false,
//   autoFocusEndDate: false,
//   initialStartDate: null,
//   initialEndDate: null,

//   // input related props
//   startDateId: START_DATE,
//   startDatePlaceholderText: 'Start Date',
//   endDateId: END_DATE,
//   endDatePlaceholderText: 'End Date',
//   disabled: false,
//   required: false,
//   screenReaderInputMessage: '',
//   showClearDates: false,
//   showDefaultInputIcon: false,
//   customInputIcon: null,
//   customArrowIcon: null,

//   // calendar presentation and interaction related props
//   orientation: HORIZONTAL_ORIENTATION,
//   anchorDirection: ANCHOR_LEFT,
//   horizontalMargin: 0,
//   withPortal: false,
//   withFullScreenPortal: false,
//   // initialVisibleMonth: null,
//   numberOfMonths: 2,
//   keepOpenOnDateSelect: false,
//   reopenPickerOnClearDates: false,

//   // navigation related props
//   navPrev: null,
//   navNext: null,
//   onPrevMonthClick() {},
//   onNextMonthClick() {},

//   // day presentation and interaction related props
//   renderDay: null,
//   minimumNights: 1,
//   enableOutsideDays: false,
//   isDayBlocked: () => false,
//   isDayHighlighted: () => false,

//   // internationalization
//   displayFormat: () => moment.localeData().longDateFormat('L'),
//   monthFormat: 'MMMM YYYY',
//   phrases: {
//     closeDatePicker: 'Close',
//     clearDates: 'Clear Dates',
//   },
// };

const DateHelperOptionButton = ({ title, isSelected, onSelect }) => (
  <Button
    variant={isSelected ? 'contained' : 'outlined'}
    style={{ backgroundColor: isSelected ? BLUE : 'white', width: 'fit-conter', padding: 1, paddingLeft: 15, paddingRight: 15, border: '1px #1B97C2 solid', borderRadius: 200, marginLeft: 5, marginRight: 5, height: 33, minWidth: 53 }}
    onClick={() => onSelect()}
    size="small"
  >
    <h5 style={{ color: isSelected ? 'white' : BLUE, margin: 0, fontSize: 12 }}>{title}</h5>
  </Button>
)
export default class DateRangeView extends React.Component {
  constructor(props) {
    super(props);

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }

    this.state = {
      focusedInput,
      selectedButtonIndex: null,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDatesChange({ startDate, endDate }) {
    this.props.onChange({ startDate, endDate });
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
    if (this.props.onFocus) {
      this.props.onFocus(focusedInput);
    }
  }

  onHelperSelected(startDate, endDate, buttonIndex) {
    const { selectedButtonIndex } = this.state;
    if (selectedButtonIndex === buttonIndex) {
      const { onResetOptionSelection } = this.props;
      this.setState({ selectedButtonIndex: undefined });
      onResetOptionSelection();
    } else {
      this.setState({ selectedButtonIndex: buttonIndex });
      this.onDatesChange({ startDate, endDate });
    }
  }

  render() {
    const { helpersOptions, containerStyle } = this.props;
    const { focusedInput, selectedButtonIndex } = this.state;
    return (
      <div style={containerStyle?containerStyle:{}}>
        <DateRangePicker
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          disabled={this.props.disabled}
          noBorder
          hideKeyboardShortcutsPanel
          {...this.props}
        />
        {helpersOptions ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {_.map(helpersOptions, (option, index) => (
              <DateHelperOptionButton
                title={option.title}
                isSelected={selectedButtonIndex === index}
                onSelect={() => this.onHelperSelected(option.startDate, option.endDate, index)}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
