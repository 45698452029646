module.exports = {
  SAVE_GUEST_TAGS: 'SAVE_GUEST_TAGS',
  SAVE_GUEST_TAG: 'SAVE_GUEST_TAG',
  SAVE_LOCK_TAGS: 'SAVE_LOCK_TAGS',
  SAVE_LOCK_TAG: 'SAVE_LOCK_TAG',
  SAVE_GUEST_TAG_CATEGORIES: 'SAVE_GUEST_TAG_CATEGORIES',
  SAVE_LOCK_TAG_CATEGORIES: 'SAVE_LOCK_TAG_CATEGORIES',
  FETCH_LOCK_TAGS_BY_FILTER: 'FETCH_LOCK_TAGS_BY_FILTER',
  CANCEL_LOCK_TAGS_BY_FILTER: 'CANCEL_LOCK_TAGS_BY_FILTER',
  FETCH_GUEST_TAGS_BY_FILTER: 'FETCH_GUEST_TAGS_BY_FILTER',
  FETCH_GUEST_SPECIAL_TAGS_BY_FILTER: 'FETCH_GUEST_SPECIAL_TAGS_BY_FILTER',
  CANCEL_GUEST_TAGS_BY_FILTER: 'CANCEL_GUEST_TAGS_BY_FILTER',
  CACHE_GUEST_TAG: 'CACHE_GUEST_TAG',
  CACHE_LOCK_TAG: 'CACHE_LOCK_TAG',
};
