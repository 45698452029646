module.exports = {
  BAD_CREDENTIALS: { title: 'badCredentialsTitle', message: 'badCredentialsMessage' },
  UNEXPECTED_ERROR: { title: 'unexpectedErrorTitle', message: 'unexpectedErrorMessage' },
  NETWORK_ERROR: { title: 'networkErrorTitle', message: 'networkErrorMessage' },
  TIMEOUT: { title: 'timeoutTitle', message: 'timeoutMessage' },
  UNAUTHORIZED: { title: 'unauthorizedTitle', message: 'unauthorizedMessage' },
  JAGO_USER_TAG_ALREADY_PRESENT: { title: 'warning', message: 'tagAlreadyPresentMessage' },
  JAGO_LOCK_TAG_ALREADY_PRESENT: { title: 'warning', message: 'tagAlreadyPresentMessage' },
  JAGO_USER_TAG_NOT_VALID: { title: 'warning', message: 'tagNotValidMessage' },
  JAGO_LOCK_TAG_NOT_VALID: { title: 'warning', message: 'tagNotValidMessage' },
  JAGO_USER_DISABLED: { title: 'warning', message: 'userNotEnabledMessage' },
  JAGO_USER_NOT_FOUND: { title: 'warning', message: 'userNotFoundMessage' },
  SELF_DISABLE: { title: 'operationNotPermitted', message: 'selfDisable' },
  JAGO_USER_TOKEN_NOT_VALID: { title: 'warning', message: 'tokenNotValidMessage' },
  JAGO_USER_USERNAME_ALREADY_PRESENT: { title: 'warning', message: 'usernameAlreadyPresentMessage' },
  JAGO_USER_EMAIL_ALREADY_PRESENT: { title: 'warning', message: 'emailAlreadyPresentMessage' },
  JAGO_STANDARD_DEVICE_ALREADY_PRESENT: { title: 'warning', message: 'cardAlreadyPresent' },
  TOO_MANY_DAY_SELECTED: { title: 'warning', message: 'tooManyDaysSelected' },
  SELECTED_DAY_BEFORE_TODAY: { title: 'warning', message: 'selectedDayBeforeToday' },
  FILE_TOO_LARGE: { title: 'warning', message: 'fileTooLargeException' },

  BOOKEY_USER_NOT_FOUND_CODE: { title: 'warning', message: 'userNotFoundMessage' },
  BOOKEY_USER_GET_ERROR_CODE: { title: 'warning', message: 'userGetErrorMessage' },
  BOOKEY_RESOURCE_NOT_FOUND_CODE: { title: 'warning', message: 'resourceNotFoundMessage' },
  BOOKEY_RESERVATION_CREATION_ERROR_CODE: { title: 'warning', message: 'reservationCreationErrorMessage' },
  BOOKEY_RESERVATION_NOT_FOUND_CODE: { title: 'warning', message: 'reservationNotFoundMessage' },
  BOOKEY_RESERVATION_DATE_INTERVAL_CODE: { title: 'warning', message: 'reservationDateIntevalErrorMessage' },
  BOOKEY_RESERVATION_MAX_DURATION_CODE: { title: 'warning', message: 'reservationMaxDurationErrorMessage' },
  BOOKEY_RESERVATION_CHECKIN_NOT_PERMITTED_CODE: { title: 'warning', message: 'reservationcheckinNotPermittedMessage' },
  BOOKEY_RESERVATION_IN_THE_PAST_CODE: { title: 'warning', message: 'reservationInThePastMessage' },
  BOOKEY_RESOURCE_TYPE_ALREADY_PRESENT_CODE: { title: 'warning', message: 'resourceTypeAlreadyPresetMessage' },
  BOOKEY_RESOURCE_TYPE_NOT_FOUND_CODE: { title: 'warning', message: 'resourceTypeNotFoundMessage' },
  BOOKEY_EVENT_NOT_FOUND_CODE: { title: 'warning', message: 'eventNotFoundMessage' },
  BOOKEY_EVENT_INVALID_DATE_ERROR_CODE: { title: 'warning', message: 'invalidDateErrorMessage' },
  BOOKEY_MEDIA_BUCKET_CREATION_ERROR_CODE: { title: 'warning', message: 'mediaUploadErrorMessage' },
  BOOKEY_MEDIA_UPLOAD_ERROR_CODE: { title: 'warning', message: 'mediaUploadErrorMessage' },
  BOOKEY_MEDIA_NOT_FOUND_CODE: { title: 'warning', message: 'mediaNotFoundMessage' },
  BOOKEY_MEDIA_READ_ERROR_CODE: { title: 'warning', message: 'userReadErrorMessage' },
};
