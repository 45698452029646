import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import Alert from '../Alert.jsx';


export default class CredentialUpdateModal extends React.Component {
  hideModal() {
    const { hideModal } = this.props;
    hideModal();
  }

  render() {
    return (
      <Alert
        type="success"
        visible
        onOutsideClick={() => this.hideModal()}
        title={<Entity entity="modalTitle" data={{ modal: 'credentialUpdated' }} />}
        message={<Entity entity="modalMessage" data={{ modal: 'credentialUpdated' }} />}
        onConfirm={() => this.hideModal()}
      />
    );
  }
}
