import Immutable from 'seamless-immutable';

import {
  SAVE_GUEST_TAGS,
  SAVE_GUEST_TAG,
  SAVE_LOCK_TAGS,
  SAVE_LOCK_TAG,
  SAVE_GUEST_TAG_CATEGORIES,
  SAVE_LOCK_TAG_CATEGORIES,
  CACHE_GUEST_TAG,
  CACHE_LOCK_TAG,
} from '../actions/actionTypes/tag';

const initialState = Immutable({
  user: {
    data: [],
    categories: [],
    cachedTagsMap: {},
  },
  lock: {
    data: [],
    categories: [],
    cachedTagsMap: {},
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_GUEST_TAGS:
      return state.setIn(['user', 'data'], action.tags);
    case SAVE_GUEST_TAG:
      return state.setIn(['user', 'data'], [...state.data, action.tag]);
    case CACHE_GUEST_TAG:
      return state.setIn(['user', 'cachedTagsMap', action.guestTagId], action.tagDetails);
    case CACHE_LOCK_TAG:
      return state.setIn(['lock', 'cachedTagsMap', action.lockTagId], action.tagDetails);
    case SAVE_LOCK_TAGS:
      return state.setIn(['lock', 'data'], action.tags);
    case SAVE_LOCK_TAG:
      return state.setIn(['lock', 'data'], [...state.data, action.tag]);
    case SAVE_GUEST_TAG_CATEGORIES:
      return state.setIn(['user', 'categories'], action.categories);
    case SAVE_LOCK_TAG_CATEGORIES:
      return state.setIn(['lock', 'categories'], action.categories);
    default:
      return state;
  }
}
