import React, { Component } from 'react';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import RoleIcon from '@material-ui/icons/Security';
import GuestsIcon from '@material-ui/icons/AccountCircle';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import * as RolesActions from '../../redux/actions/roles.actions';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';

export default class PlatformUserRow extends Component {
  render() {
    const { isSelected, onClick, user } = this.props;
    const statusColor = !user.enabled ? '#E53935' : '#43A047';
    
    return (
      <Card
        className={`card card-rounded ${isSelected && 'card-selected'}`}
        style={{ borderRadius: 10, padding: 20, paddingTop: 25, paddingBottom: 25, position: 'relative', borderLeft: `4px solid ${statusColor}` }}
        onClick={() => onClick()}
        elevation={2}
      >
        <div className="card-body">
          <div className="card-inner-body" style={{ paddingLeft: 5 }}>
            <h4 className="card-title" style={{ marginLeft: 5 }}>{`${user.firstname} ${user.lastname}`}</h4>
            <h5 className="card-description" style={{ margin: 5 }}>{user.email}</h5>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {_.map(user.roles, (role) => {
                const formattedRole = RolesActions.formatRole(role);
                const isRoleGuest = role && role.id === 5;
                return (
                  <div key={role.id} style={{ display: 'flex', marginRight: 10, marginTop: 5 }}>
                    {!isRoleGuest ? <RoleIcon style={{ color: '#3f3f3f', marginTop: 3, fontSize: 20 }} /> : <UsersIconCustom style={{ color: '#3f3f3f', marginTop: 3, width: 20 }} />}
                    <h5 className="card-description" style={{ margin: 5, fontWeight: 'bold', marginTop: 6 }}>{formattedRole.label}</h5>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Entity
          key={`${user.enabled}`}
          style={{ fontWeight: 'bold', position: 'absolute', right: 6, bottom: 6, textTransform: 'uppercase', color: statusColor, textAlign: 'right', fontSize: 12, margin: 0, marginTop: 10 }}
          entity={!user.enabled ? 'userDisabled' : 'userEnabled'}
        />
      </Card>
    );
  }
}
