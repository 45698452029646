import _ from 'lodash';
import {
  SAVE_AGREEMENTS,
  RESET_AGREEMENTS,
  SAVE_ACCEPTED_AGREEMENTS,
  SET_AGREEMENT_FILTER,
  RESET_AGREEMENT_FILTERS,
} from './actionTypes/agreements';

import * as AgreementsAPI from '../../_config/agreementsAPI';
import { formatDomainHostForAgreements, formatDomainHostNameToFriendlyName } from '../../_config/utils';

export function saveAgreements(agreements) {
  return {
    type: SAVE_AGREEMENTS,
    agreements,
  };
}

export function saveAcceptedAgreements(agreements) {
  return {
    type: SAVE_ACCEPTED_AGREEMENTS,
    agreements,
  };
}

export function setAgreementFilter(field, value) {
  return {
    type: SET_AGREEMENT_FILTER,
    field,
    value
  }
}

export function resetAgreementFilters() {
  return {
    type: RESET_AGREEMENT_FILTERS
  }
}

export function resetAgreements() {
  return {
    type: RESET_AGREEMENTS,
  };
}

export function fetchLatestAgreements(params) {
  return async (dispatch, getState) => {
    const { filters } = getState().agreements;
    const formattedParams = {
      ...filters,
      ...params,
    };
    try {
      const agreements = await AgreementsAPI.fetchLatestAgreements(formattedParams);
      if (agreements.data) {
        dispatch(saveAgreements(agreements.data));
        return agreements.data;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function checkUpdatedAgreementsStandard(params) {
  return async (dispatch, getState) => {
    const formattedParams = {
      serviceName: 'com.sofialocks.luckeyManager',
      ...params,
    };
    try {
      const response = await AgreementsAPI.checkUpdatedAgreements(formattedParams);
      if (response.data) {
        dispatch(setAgreementFilter('serviceName', 'com.sofialocks.luckeyManager'));
        return (response.data);
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function checkUpdatedAgreements(params) {
  return async (dispatch, getState) => {
    try {
      const plantName = formatDomainHostForAgreements(window.location.hostname.toLowerCase());
      const serviceName = `web-${plantName}`;
      const formattedParams = {
        serviceName,
        ...params,
      };
      const response = await AgreementsAPI.checkUpdatedAgreements(formattedParams);
      if (response.data) {
        dispatch(setAgreementFilter('serviceName', serviceName));
        return (response.data);
      }
      throw new Error();
    } catch (error) {
      if (error
        && error.response
        && error.response.data
        && error.response.data.code
        && error.response.data.code === 11601) {
        return dispatch(checkUpdatedAgreementsStandard(params));
      }
      throw new Error(error);
    }
  };
}

export function acceptAgreements(acceptAgreementsDTO) {
  return async (dispatch, getState) => {
    try {
      const response = await AgreementsAPI.acceptAgreements(acceptAgreementsDTO);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}
