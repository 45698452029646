import { Form } from '@sketchpixy/rubix';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { Icon } from '@sketchpixy/rubix';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import CustomField from './Fields/CustomField.jsx';
import DropFileMultiField from './Fields/DropFileMultiField.jsx';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import SelectableField from './Fields/SelectableInput/SelectableField.jsx';

const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = 'required';
  }

  if (!values.message) {
    errors.message = 'required';
  }

  if (!values.category) {
    errors.category = 'required';
  }

  return errors;
};

@reduxForm({ form: 'TicketForm', validate })
@connect(state => ({ form: state.form.TicketForm, companyConfigurations: state.user.companyConfigurations }))
class TicketForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeHoverIndex: -1,
    };
  }
  
  onSelectCategory(value) {
    const { onSelectReferentUser } = this.props;
    if (guest) {
      if (onSelectReferentUser) onSelectReferentUser(guest.value);
    } else {
      if (onSelectReferentUser) onSelectReferentUser(null);
    }
  }

  render() {
    const {
      form,
      formStyle,
      canEditTicket,
      categoriesArray,
      imagePreviews,
      onFileRejected,
      onDeleteFile,
    } = this.props;
    const { activeHoverIndex } = this.state;
    return (
      <Form style={formStyle} autoComplete="off">
        <FormCardContainer title="newTicketForm" subtitle="newTicketFormDescription" containerStyle={{ margin: 0, marginRight: 25 }}>
          <Entity
            componentClass={Field}
            name="title"
            componentAttribute="placeholder"
            entity="insertTitle"
            title={<Entity entity="ticketTitle" />}
            component={CustomField}
            disabled={!canEditTicket}
            mandatory
          />
          {form&&form.values&&form.values.title&&form.values.title.length>100?
            <div style={{ color: 'red', marginBottom:10 }}>{L20NContext.getSync('ticketTitleTooLong')}</div>
          :null}
          <h4 style={{ fontWeight: 'bold', fontSize: 16, color: '#3f3f3f', margin: 0 }}><Entity entity="ticketCategory" /><span style={{ color: 'red' }}>*</span></h4>
          <Field
            name="category"
            component={SelectableField}
            noCache
            options={categoriesArray}
            placeholder={<Entity entity="ticketCategory" />}
            style={{ marginTop: 15 }}
            onInputChange={null}
            disabled={!canEditTicket}
            onSelect={null}
          />
          <Entity
            componentClass={Field}
            name="message"
            componentAttribute="placeholder"
            entity="ticketNewMessageEmpty"
            containerStyle={{ marginTop: 15 }}
            title={<Entity entity="ticketMessage" />}
            type="textarea"
            disabled={!canEditTicket}
            component={CustomField}
            mandatory
          />
          <h4 style={{ fontWeight: 'bold', fontSize: 16, color: '#3f3f3f', margin: 0, marginBottom: 10 }}><Entity entity="ticketAttachments" /></h4>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
            {imagePreviews && _.isArray(imagePreviews) ? _.map(imagePreviews, (imagePreview, index) =>
              <Card style={{ width: 'fit-content', padding: 20, display: 'flex', justifyContent: 'center', margin: 20 }}
                onMouseEnter={() => this.setState({activeHoverIndex: index})}
                onMouseLeave={() => this.setState({activeHoverIndex: -1})}>
                <img src={imagePreview} style={{ width: 'auto', maxHeight: 150 }} />
                {(activeHoverIndex === index) &&
                  <Icon
                    glyph="icon-fontello-cancel-circled-1"
                    style={{ position: 'relative', top: -20, fontSize: 20, cursor: 'pointer', color: 'red', width: 0 }}
                    onClick={() => {onDeleteFile(index)}}
                  />
                }
              </Card>
            ) : null}
          </div>
          <Field
            name="image"
            accept="image/*"
            className="drop-file-container"
            activeClassName="drop-file-container-active"
            multiple={true}
            maxSize={2*1048576}
            disabled={!canEditTicket}
            component={DropFileMultiField}
            onFileRejected={() => onFileRejected()}
            onDeleteFile={null}
            style={{}}
          >
              <h5 style={{ marginBottom: 5 }}><Entity entity="dropFile" /></h5>
              <h5 style={{ marginTop: 0, marginBottom: 5 }}><Entity entity="orSelect" /></h5>
              <h5 style={{ marginTop: 0 }}>(<Entity entity="maxUploadSizeDynamic" data={{ megabytes: 2 }} />)</h5>
          </Field>
        </FormCardContainer>
      </Form>
    );
  }
} 

export default TicketForm;
