import InfoIcon from '@material-ui/icons/HelpOutline';
import { Form } from '@sketchpixy/rubix';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { RED } from '../../../_config/consts.js';
import MDButton from '../../MDButton/MDButton.jsx';
import CustomField from '../Fields/CustomField.jsx';

const validate = (values) => {
  const errors = {};
  if (values) {
    if (!values.name) {
      errors.name = 'required';
    }
    if (!values.description) {
      errors.description = 'required';
    }
    if (!values.communicationTimeFrame || !values.communicationTimeFrame.startDate || !values.communicationTimeFrame.endDate) {
      errors.communicationTimeFrame = 'required';
    }
  }

  return errors;
};

@reduxForm({ form: 'CommunicationForm', validate })
@connect(state => ({ language: state.settings.language }))
class CommunicationForm extends React.Component {
  render() {
    const {
      startDate,
      endDate,
      onDeleteCommunication,
      communication,
      canCreateItem,
      language,
    } = this.props;
    const startDateString = moment(startDate)
    startDateString.locale(moment().locale());
    const endDateString = moment(endDate);
    endDateString.locale(moment().locale());
    return (
      <Form>
        <input style={{ display: 'none' }} type="username" name="username" />
        <Entity
          componentClass={Field}
          name="name"
          componentAttribute="placeholder"
          entity="insertCommunicationName"
          title={<Entity entity="communicationName" />}
          component={CustomField}
          type="text"
          mandatory
        />
        <Entity
          componentClass={Field}
          name="description"
          title={<Entity entity="communicationDescription" />}
          componentAttribute="placeholder"
          entity="insertCommunicationDescription"
          component={CustomField}
          type="textarea"
          className="form-control-custom"
          mandatory
        />
        <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginTop: 20, fontSize: 16 }}>
          {<Entity entity="communicationTimeFrame" />}
          <span style={{ color: 'red' }}>*</span>
        </h4>
        <h4 style={{ color: '#3f3f3f', margin: 0, marginTop: 10, marginBottom: 15, fontSize: 15, fontWeight: 'light' }}>
          <Entity entity="communicationTimeFrameDescription" />
        </h4>
        <Entity
          componentClass={Field}
          name="communicationTimeFrame"
          containerStyle={{ paddingLeft: 0 }}
          component={CustomField}
          type="airbnbDateRange"
          minimumNights={0}
        />
        {startDate && endDate ? (
          <div style={{ marginTop: -10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InfoIcon style={{ color: '#3f3f3f', fontSize: 20, marginRight: 12 }} />
            <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: '#3f3f3f' }}>
              <Entity
                entity="communicationDateDescription"
                key={`${startDate}-${endDate}-${language}`}
                data={{
                  startDate: startDateString.format('LL'),
                  endDate: endDateString.format('LL'),
                }}
              />
            </h5>
          </div>
        ) : null}
        {communication && !_.isEmpty(communication) && canCreateItem ? (
          <MDButton
            title={<Entity entity="delete" />}
            backgroundColor={RED}
            onClick={() => onDeleteCommunication(communication)}
          />
        ) : null}
      </Form>
    );
  }
} 

export default CommunicationForm;
