import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';

import Card from '@material-ui/core/Card';
import { getSettingIcon } from '../../_config/utils.js';

export default class SettingDetailsRow extends React.Component {
  render() {
    const { setting, isSelected, onClick } = this.props;
    return (
      <Card
        className={`card card-rounded ${isSelected && 'card-selected'}`}
        style={{ borderRadius: 10, padding: 20, paddingTop: setting.iconOnly ? 20 : 25, paddingBottom: setting.iconOnly ? 20: 25 }}
        onClick={() => onClick()}
        elevation={2}
      >
        <div className="card-body">
          {getSettingIcon(setting.name)}
          {!setting.iconOnly ? (
            <div className="card-inner-body">
              <h4 className="card-title" style={{ margin: 2, marginTop: 5 }}>{<Entity entity="settingTitle" data={{ name: setting.name }} />}</h4>
            </div>
          ) : null}
        </div>
      </Card>
    );
  }
}
