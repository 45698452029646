import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { initialize, submit } from 'redux-form';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import * as SettingsActions from '../../../redux/actions/setting.actions';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts';
import CompanySettingsForm from '../../forms/CompanySettingsForm.jsx';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import * as ModalActions from '../../../redux/actions/modal.actions';
import MDButton from '../../MDButton/MDButton.jsx';
import Permission from '../../Permission/Permission.jsx';


@connect(() => ({}))
class CompanySettingsOperationalSection extends React.Component {


  componentWillMount() {
    const { dispatch, setting } = this.props;
    dispatch(SettingsActions.fetchSettingsByType(setting.name))
    .then((response) => {
      dispatch(initialize('CompanySettingsForm', response));
    });
  }

  async saveCompanySettings(values) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(SettingsActions.updateSettings(values));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateSuccessfully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateSuccessfully" /></h6>),
        },
      }));
    }
  }

  render() {
    const { dispatch } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        <CompanySettingsForm
          onSubmit={values => this.saveCompanySettings(values)}
        />
        <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
          <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.COMPANY} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MDButton
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              title={<Entity entity="save" />}
              onClick={() => dispatch(submit('CompanySettingsForm'))}
            />
          </Permission>
          </div>
      </div>
    );
  }
} 

export default CompanySettingsOperationalSection;
