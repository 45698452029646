import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, destroy, initialize } from 'redux-form';
import KeyOutlinedIconCustom from '../components/CustomIcons/KeyOutlinedIconCustom.jsx';
import HyperKeysOperationalSection from '../components/HyperKeys/HyperKeysOperationalSection.jsx';
import HyperKeysTableView from '../components/HyperKeys/HyperKeysTableView.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as CardActions from '../redux/actions/card.actions';
import * as CredentialActions from '../redux/actions/credential.actions';
import * as GuestsActions from '../redux/actions/guest.actions';
import * as LogEventsActions from '../redux/actions/logEvents.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as SettingsActions from '../redux/actions/setting.actions';
import * as TagActions from '../redux/actions/tag.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import { CARD_TYPES, DEFAULT_CREDENTIAL_TIME_FRAME, MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES, VALIDATION_MODES, VALIDATION_MODES_SELECTION_OPTIONS, VIEW_MODES } from '../_config/consts';


let filterTimeout;
@connect(state => ({ cards: state.cards, companyConfigurations: state.user.companyConfigurations, languange: state.settings.language, viewLoading: state.utils.viewLoading, routing: state.router, themeName: state.settings.items.theme.data.themeName }))
class HyperKeys extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('cardsViewMode');
    this.state = {
      showDeletedCardDetails: false,
      showMasterElectionForm: false,
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    };
  }

  async componentWillMount() {
    const { dispatch, routing } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    dispatch(CardActions.setSelectedHyperKey({}));
    dispatch(CardActions.getStandardDevicesValidationPeriodDefaults());
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(GuestsActions.resetGuestsFilters());
      dispatch(CardActions.resetHyperKeyFilters());

      await dispatch(CardActions.fetchHyperKeys());
      dispatch(SettingsActions.fetchCompanyMetrics());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
    this.closeOperationalSection();

    const searchGuest = parsed.searchGuest;
    if (searchGuest) {
      try {
        const guest = JSON.parse(searchGuest);
        const guestOption = { value: guest.id, label: `${guest.firstname} ${guest.lastname} ${guest.email}` };
        this.onSelectGuest(guestOption);
        dispatch(change('SearchBarForm', 'guestSelected', guestOption));
      } catch (error) {}
    }
    const deviceId = parsed.deviceId;
    if (deviceId) {
      const f9000Key = await dispatch(CardActions.fetchF9000FromDeviceId(deviceId));
      if (f9000Key) {
        this.onSelectKey(f9000Key);
      }
    }

    const newEntity = parsed.newEntity;
    if (newEntity) {
      this.onNewCredentialButtonClicked();
    }
    
    const newElementForGuest = parsed.newElementForGuest;
    if (newElementForGuest) {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      try {
        const guestTagId = JSON.parse(newElementForGuest);
        await dispatch(CredentialActions.fetchGuestsByTags({ id: guestTagId }, 'AT_LEAST_ONE_TAG'));
        const guestTagDetails = await dispatch(TagActions.fetchGuestTagDetails(guestTagId));
        this.onNewCredentialButtonClicked();
        dispatch(change('HyperKeyCredentialsForm', 'guestTags', guestTagDetails));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } catch (error) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CardActions.selectCard({}));
  }

  onNewCredentialButtonClicked() {
    const { dispatch, cards } = this.props;
    const validationPeriodDefault = cards.validationPeriodDefaults;
    dispatch(CardActions.setSelectedHyperKey({}));
    dispatch(CredentialActions.selectCredential({}));
    const validationDTO = {
      validationPeriod: validationPeriodDefault.validationPeriod,
      validationMode: validationPeriodDefault.validationMode !== VALIDATION_MODES.ALWAYS_VALIDATED && validationPeriodDefault.validationMode !== 'DEFAULT' ? VALIDATION_MODES_SELECTION_OPTIONS.TIME_CONSTRAINED_VALIDATION : validationPeriodDefault.validationMode,
      validationPeriodTimeUnit: validationPeriodDefault.validationMode,
    };
    dispatch(initialize('HyperKeyCredentialsForm', {
      timeIntervalFrom: moment().startOf('day').valueOf(),
      timeIntervalTo: moment().endOf('day').valueOf(),
      daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
      lockTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
      type: CARD_TYPES.GENERIC_CARD,
      credentialTimeframe: DEFAULT_CREDENTIAL_TIME_FRAME,
      ...validationDTO,
    }));
    if (this.cardsOperationalSection) {
      this.cardsOperationalSection.getWrappedInstance().selectTab(0);
    }
    dispatch(CardActions.setOperationalMode(true));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(true));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
    dispatch(CardActions.setSelectedHyperKey({}));
  }

  async fetchHyperKeysCredentials() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    dispatch(CardActions.setOperationalMode(false));
    try {
      dispatch(CardActions.setSelectedHyperKey({}));
      await dispatch(CardActions.fetchHyperKeys());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
      dispatch(CardActions.setOperationalMode(false));
    }
  }

  async onSetHyperKeysFilter(field, value) {
    const { dispatch } = this.props;
    if (value && ((_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2))) {
      dispatch(CardActions.setHyperKeyFilter(field, _.trim(value)));
      if (filterTimeout) clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => {
        this.setState({ viewLoading: true });
        await this.fetchHyperKeysCredentials();
        this.setState({ viewLoading: false });
      }, 500);
    } else {
      dispatch(CardActions.setHyperKeyFilter(field, null));
      await this.fetchHyperKeysCredentials();
    }
  }

  async onSelectGuest(guestValue) {
    const { dispatch } = this.props;
    if (guestValue) {
      dispatch(CardActions.setHyperKeyFilter('userId', guestValue.value));
      this.setState({ viewLoading: true });
      await this.fetchHyperKeysCredentials();
      this.setState({ viewLoading: false });
    } else {
      dispatch(CardActions.setHyperKeyFilter('userId', undefined));
      await this.fetchHyperKeysCredentials();
    }
  }

  async onSelectLocksTags(tags) {
    const { dispatch } = this.props;
    if (tags && !_.isEmpty(tags)) {
      dispatch(UtilsActions.setViewLoading(true));
      const formattedTag = _.map(tags, tag => tag.value);
      this.onSetHyperKeysFilter('lockTagIds', formattedTag);
    } else {
      this.onSetHyperKeysFilter('lockTagIds', null);
      dispatch(UtilsActions.setViewLoading(true));
      await this.fetchHyperKeysCredentials();
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onSelectKey(key) {
    const { dispatch, companyConfigurations } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    this.setState({ showDeletedCardDetails: false });
    try {
      dispatch(CardActions.setSelectedHyperKey(key));
      dispatch(CardActions.fetchKeyStatus(key));
      try {
        dispatch(CardActions.fetchHyperKeyValidationStatus(key));
      } catch (error) {}
      dispatch(LogEventsActions.resetSmartLocksEventsData());
      await dispatch(CardActions.fetchKeysCredentials(key));
      let newCredendialDefaults;
      if (!key.credentialRule) {
        newCredendialDefaults = {
          timeIntervalFrom: moment().startOf('day').valueOf(),
          timeIntervalTo: moment().endOf('day').valueOf(),
          dateIntervalTimeFrom: moment().startOf('day').valueOf(),
          dateIntervalTimeTo: moment().endOf('day').valueOf(),
          daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
          lockTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
        };
      }
      // Fetch guests and locks by tag to show them when credential is selected
      const lockTags = key.credentialRule ? key.credentialRule.lockTags : [];
      const guestTags = key.credentialRule ? key.credentialRule.guestTags : [];
      const lockMatchingMode = key.credentialRule ? key.credentialRule.lockTagMatchingMode : MATCH_TAG_MODE.AT_LEAST_ONE_TAG;

      // Handle Custom card Ids map
      const isCardMappingEnabled = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.enabled;
      const cardIdsMap = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.cardIdsMap ? companyConfigurations.customCardsMapping.cardIdsMap : [];
      const originalCard = isCardMappingEnabled ? _.find(cardIdsMap, cardToTranslate => cardToTranslate.luckeyCardId === key.deviceId) : null;

      let formDefaultValues = {
        deviceBackendId: key.id,
        deviceId: key.deviceId,
        selectedGuestTags: guestTags,
        selectedLocksTags: lockTags,
        type: key.type,
        notes: key.notes,
        ...key.credentialRule,
        ...newCredendialDefaults,
      };

      if (originalCard) {
        formDefaultValues = {
          ...formDefaultValues,
          originalCardId: {
            value: originalCard,
            label: originalCard && originalCard.originalId,
          },
        };
      }
      dispatch(initialize('HyperKeyCredentialsForm', formDefaultValues));
      
      dispatch(CredentialActions.fetchLocksByTags(lockTags, lockMatchingMode));
      dispatch(CredentialActions.fetchGuestsByTags(guestTags, MATCH_TAG_MODE.EVERY_TAG));
      dispatch(CardActions.setOperationalMode(true));
    } catch (error) {
      dispatch(CardActions.setSelectedHyperKey({}));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onCreateKeyCredentialFromModel(card) {
    const { dispatch } = this.props;
    dispatch(destroy('HyperKeyCredentialsForm'));
    dispatch(CardActions.setSelectedHyperKey({}));
    dispatch(CredentialActions.selectCredential({}));
    const lockTags = card.lockTags || [];
    const lockMatchingMode = card.lockTagMatchingMode || MATCH_TAG_MODE.AT_LEAST_ONE_TAG;
    const formInitValues = {
      selectedLocksTags: lockTags,
      type: card.type,
      notes: card.notes,
      guestTags: [],
      guestTagIds: [],
      selectedType: card.type,
      ..._.omit(card, ['id', 'deviceId', 'deviceBackendId', 'guestTags', 'guestTagMatchingMode', 'guestTagIds']),
    };
    dispatch(initialize('HyperKeyCredentialsForm', formInitValues));
    dispatch(CredentialActions.fetchLocksByTags(lockTags, lockMatchingMode));
    dispatch(CardActions.setOperationalMode(true));
  }
  
  onShowDeletedCardDetails(card) {
    const { dispatch } = this.props;
    dispatch(CardActions.setSelectedHyperKey(card));
    dispatch(CardActions.setOperationalMode(false));
    this.setState({ showDeletedCardDetails: true });
  }

  onHideDeletedCardDetails() {
    const { dispatch } = this.props;
    this.setState({ showDeletedCardDetails: false });
    dispatch(CardActions.setSelectedHyperKey({}));
  }

  async onSearchReset() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    this.setState({ viewLoading: true });
    dispatch(CardActions.resetHyperKeyFilters());
    await dispatch(CardActions.fetchHyperKeys());
    this.setState({ viewLoading: false });
    dispatch(UtilsActions.setViewLoading(false));
  }


  onExportElements(format) {
    const { dispatch } = this.props;
    dispatch(CardActions.exportHyperKeys(format));
  }
  

  onFetchKeysOnPage(page) {
    const { dispatch } = this.props;
    dispatch(CardActions.fetchHyperKeys(page));
  }

  onSelectOriginalCard(cardOptionValue) {
    if (!cardOptionValue) {
      this.onSetHyperKeysFilter('deviceId', '');
    } else {
      const { luckeyCardId } = cardOptionValue;
      this.onSetHyperKeysFilter('deviceId', luckeyCardId);
    }
  }

  async deleteKey(cardId) {
    const { dispatch } = this.props;
    await dispatch(CardActions.deleteKey(cardId));
    dispatch(CardActions.fetchHyperKeys());
    
  }

  onDeleteKeyRequest(card) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteCardConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.deleteKey(card.id),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  render() {
    const { cards, cards: { selectedHyperKey }, companyConfigurations, themeName, viewLoading } = this.props;
    const canCreateNewCard = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canSearchGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const numberOfCards = cards && cards.hyperKeysData && cards.hyperKeysData.pagination && cards.hyperKeysData.pagination.totalElements ? cards.hyperKeysData.pagination.totalElements : 0;
    const cardIdsMap = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.cardIdsMap ? companyConfigurations.customCardsMapping.cardIdsMap : [];
    const isCardMappingEnabled = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.enabled;
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          isLockTagsMultiSelectionActive
          newEntityTitle="addHyperKeys"
          newEntityIconName="icon-simple-line-icons-credit-card"
          searchPlaceholderEntityName="cardNumber"
          searchGuestContainerStyle={{ width: 280 }}
          isLoading={viewLoading}
          onSearchReset={() => this.onSearchReset()}
          onNewEntity={canCreateNewCard ? () => this.onNewCredentialButtonClicked() : null}
          onSearchSubmit={() => this.fetchHyperKeysCredentials()}
          onFilterChange={value => this.onSetHyperKeysFilter('deviceId', value)}
          onSelectGuest={canSearchGuest ? value => this.onSelectGuest(value) : null}
          onSelectLockTag={tags => this.onSelectLocksTags(tags)}
          onExportElements={format => this.onExportElements(format)}
          cardIdsMap={cardIdsMap}
          isCardMappingEnabled={isCardMappingEnabled}
          numberOfElements={numberOfCards}
          onSelectOriginalCard={selectedOriginalCard => this.onSelectOriginalCard(selectedOriginalCard)}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <KeyOutlinedIconCustom style={{ width: 22, color: 'white' }} />
              <h4 style={{ marginTop: 0 }}>{numberOfCards}</h4>
            </div>
          }
        />
        <HyperKeysTableView
          onNewCard={() => this.onNewCredentialButtonClicked()}
          onSelectKey={hyperKey => this.onSelectKey(hyperKey)}
          onFetchKeysOnPage={page => this.onFetchKeysOnPage(page)}
          onRefreshCards={() => this.fetchHyperKeysCredentials()}
          onDeleteHyperKey={hyperKey => this.onDeleteKeyRequest(hyperKey)}
          onCreateHyperKeyCredentialFromModel={hyperKey => this.onCreateKeyCredentialFromModel(hyperKey)}
        />
        <OperationalView
          title={<Entity entity="hyperKeysOperationalTitle" />}
          themeName={themeName}
          isVisible={cards.isOperationalMode}
          style={{ margin: 0, padding: 0 }}
          onRefresh={selectedHyperKey ? () => this.onSelectKey(selectedHyperKey) : null}
          onClose={() => this.closeOperationalSection()}
        >
          <HyperKeysOperationalSection
            ref={(c) => { this.cardsOperationalSection = c; }}
            onDeleteHyperKeyRequest={hyperKey => this.onDeleteKeyRequest(hyperKey)}
            onShowMasterElectionForm={() => this.setState({ showMasterElectionForm: true })}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default HyperKeys;
