import React, { Component } from 'react';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import MDSwitchView from './MDSwitchView.jsx';

export default class MDSwitchField extends Component {
  onHandleChange(value, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(value);
    }
    if (onHandleChange) {
      onHandleChange(value);
    }
  }

  render() {
    const { input, switchColor, containerstyle, showErrorLabel, meta, disabled, activeTextStyle, activeText, activeTextIcon, activeTextContainerStyle } = this.props;
    const checked = input.value;
    const hasError = meta.touched && meta.error ? true : false;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', ...containerstyle }}>
        <MDSwitchView
          checked={checked}
          color={switchColor}
          disabled={disabled}
          onSelect={value => this.onHandleChange(value, input)}
          {...this.props}
        />
        {activeText && checked ? (
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, alignItems: 'center', ...activeTextContainerStyle }}>
          {activeTextIcon}
          <h5 style={{ color: '#3f3f3f', margin: 0, lineHeight: '1.2em', ...activeTextStyle }}>
            <Entity entity={activeText} />
          </h5>
          </div> ): null}
        {showErrorLabel && hasError ? <h6 style={{ color: 'red', marginTop: 4, marginLeft: 10, marginBottom: 0 }}><Entity entity={meta.error} /></h6> : null}
      </div>
    );
  }
};

