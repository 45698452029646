import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_COMMUNICATIONS,
  APPEND_COMMUNICATIONS,
  SET_COMMUNICATIONS_PAGINATION_DATA,
  SELECT_COMMUNICATION,
  SAVE_COMMUNICATION,
  SET_COMMUNICATIONS_FILTER,
  UPDATE_COMMUNICATION,
  RESET_COMMUNICATIONS_FILTERS,
  RESET_COMMUNICATIONS_DATA,
  RESET_COMMUNICATION_PAGINATION_DATA,
  SET_COMMUNICATIONS_OPERATIONAL_MODE,
} from '../actions/actionTypes/communications';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
  },
  isOperationalMode: false,
  selectedCommunication: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_COMMUNICATIONS:
      return state.setIn(['data', 'content'], action.communications);
    case APPEND_COMMUNICATIONS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.communications]);
    case SAVE_COMMUNICATION:
      return state.setIn(['data', 'content'], [...state.data.content, action.communication]);
    case SET_COMMUNICATIONS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SELECT_COMMUNICATION:
      return state.set('selectedCommunication', action.communication);
    case SET_COMMUNICATIONS_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_COMMUNICATIONS_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case UPDATE_COMMUNICATION: {
      const index = _.findIndex(state.data.content, data => data.id === action.communication.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.communication);
      }
      return state;
    }
    case RESET_COMMUNICATIONS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case RESET_COMMUNICATIONS_DATA:
      return state.set('data', initialState.data);
    case RESET_COMMUNICATION_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    default:
      return state;
  }
}
