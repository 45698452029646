import _ from 'lodash';
import { Observable } from 'rxjs';
import {
  FETCH_LOCK_TAGS_BY_FILTER,
  CANCEL_LOCK_TAGS_BY_FILTER,
  FETCH_GUEST_TAGS_BY_FILTER,
  CANCEL_GUEST_TAGS_BY_FILTER,
  FETCH_GUEST_SPECIAL_TAGS_BY_FILTER,
} from '../redux/actions/actionTypes/tag';

import * as RestService from '../_config/rest';
import {
  saveLockTags,
  saveUserTags,
} from '../redux/actions/tag.actions';
import { rxFetch } from './utils';

export const fetchLockTagsByFilterEpic = (action$, store) =>
  action$.ofType(FETCH_LOCK_TAGS_BY_FILTER)
    .debounceTime(500)
    .switchMap((action) => {
      const { value, pageSize, includeSpecialTags, tagsType } = action;

      const params = {
        name: value,
        type: tagsType,
        pageSize,
        includeSpecialTags,
      };

      return rxFetch(RestService.fetchLockTags(params))
        .map((response) => {
          if (response.data) {
            return saveLockTags(response.data.data);
          }
          return saveLockTags([]);
        })
        .takeUntil(action$.ofType(CANCEL_LOCK_TAGS_BY_FILTER))
        .catch(() => Observable.empty());
    });

export const fetchGuestTagsByFilterEpic = (action$, store) =>
  action$.ofType(FETCH_GUEST_TAGS_BY_FILTER)
    .debounceTime(500)
    .switchMap((action) => {
      const { value, pageSize, includeSpecialTags, tagsType } = action;

      const params = {
        name: value,
        type: tagsType,
        pageSize,
        includeSpecialTags,
      };

      return rxFetch(RestService.fetchGuestTags(params))
        .map((response) => {
          if (response.data) {
            return saveUserTags(response.data.data);
          }
          return saveUserTags([]);
        })
        .takeUntil(action$.ofType(CANCEL_GUEST_TAGS_BY_FILTER))
        .catch(() => Observable.empty());
    });


export const fetchGuestSpecialTagsByFilterEpic = (action$, store) =>
  action$.ofType(FETCH_GUEST_SPECIAL_TAGS_BY_FILTER)
    .debounceTime(500)
    .switchMap((action) => {
      const { value, pageSize, includeSpecialTags, tagsType } = action;

      const params = {
        name: value,
        type: tagsType,
        pageSize,
        includeSpecialTags,
      };

      return rxFetch(RestService.fetchGuestTags(params))
        .map((response) => {
          if (response.data) {
            const userSpecialTags = _.filter(response.data.data, tag => tag.type !== 'user');
            return saveUserTags(userSpecialTags);
          }
          return saveUserTags([]);
        })
        .takeUntil(action$.ofType(CANCEL_GUEST_TAGS_BY_FILTER))
        .catch(() => Observable.empty());
    });
