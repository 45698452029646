import React, { PropTypes } from 'react';
import moment from 'moment';

import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { isMobileBrowser } from '../../../_config/utils';

import { Entity } from '@sketchpixy/rubix/lib/L20n';

// // import DateRangePickerShape from '../src/shapes/DateRangePickerShape';
// import { START_DATE, END_DATE, HORIZONTAL_ORIENTATION, ANCHOR_LEFT } from '../constants';
// import isInclusivelyAfterDay from '../src/utils/isInclusivelyAfterDay';


const DISPLAY_FORMAT = 'L';
const ISO_FORMAT = 'YYYY-MM-DD';

const START_DATE = 'startDate';
const END_DATE = 'endDate';

const HORIZONTAL_ORIENTATION = 'vertical';
const VERTICAL_ORIENTATION = 'vertical';
const VERTICAL_SCROLLABLE = 'verticalScrollable';

const ANCHOR_LEFT = 'left';
const ANCHOR_RIGHT = 'right';

// const defaultProps = {
//   // example props for the demo
//   autoFocus: false,
//   autoFocusEndDate: false,
//   initialStartDate: null,
//   initialEndDate: null,

//   // input related props
//   startDateId: START_DATE,
//   startDatePlaceholderText: 'Start Date',
//   endDateId: END_DATE,
//   endDatePlaceholderText: 'End Date',
//   disabled: false,
//   required: false,
//   screenReaderInputMessage: '',
//   showClearDates: false,
//   showDefaultInputIcon: false,
//   customInputIcon: null,
//   customArrowIcon: null,

//   // calendar presentation and interaction related props
//   orientation: HORIZONTAL_ORIENTATION,
//   anchorDirection: ANCHOR_LEFT,
//   horizontalMargin: 0,
//   withPortal: false,
//   withFullScreenPortal: false,
//   // initialVisibleMonth: null,
//   numberOfMonths: 2,
//   keepOpenOnDateSelect: false,
//   reopenPickerOnClearDates: false,

//   // navigation related props
//   navPrev: null,
//   navNext: null,
//   onPrevMonthClick() {},
//   onNextMonthClick() {},

//   // day presentation and interaction related props
//   renderDay: null,
//   minimumNights: 1,
//   enableOutsideDays: false,
//   isDayBlocked: () => false,
//   isDayHighlighted: () => false,

//   // internationalization
//   displayFormat: () => moment.localeData().longDateFormat('L'),
//   monthFormat: 'MMMM YYYY',
//   phrases: {
//     closeDatePicker: 'Close',
//     clearDates: 'Clear Dates',
//   },
// };

export default class AirbnbDateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }
    this.state = {
      focusedInput,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDatesChange({ startDate, endDate }) {
    let newStartDate = startDate;
    this.props.onChange({ startDate, endDate });
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
    this.props.onFocus(focusedInput);
  }

  render() {
    const { focusedInput } = this.state;
    const { minimumNights, value, showClearDates, isOutsideRange, disabled } = this.props;
    const visibleMonth = focusedInput === END_DATE ? moment(value.endDate) : moment(value.startDate);
    return (
      <div>
        <DateRangePicker
          startDateId="your_unique_start_date_id"
          endDateId="your_unique_end_date_id"
          showClearDates={showClearDates}
          numberOfMonths={isMobileBrowser() ? 1 : 2}
          minimumNights={0}
          onDatesChange={this.onDatesChange}
          disabled={disabled}
          enableOutsideDays
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          initialVisibleMonth={() => visibleMonth.isValid() ? visibleMonth : moment()}
          hideKeyboardShortcutsPanel
          startDate={value && value.startDate ? moment(value.startDate) : null}
          endDate={value && value.endDate ? moment(value.endDate) : null}
          isOutsideRange={isOutsideRange ? isOutsideRange : () => false}
          startDatePlaceholderText={'--/--/----'}
          endDatePlaceholderText={'--/--/----'}
        />
      </div>
    );
  }
}
