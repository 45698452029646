module.exports = {
  SAVE_RESERVATIONS: 'SAVE_RESERVATIONS',
  RESET_RESERVATIONS_DATA: 'RESET_RESERVATIONS_DATA',
  SET_RESERVATIONS_FILTER: 'SET_RESERVATIONS_FILTER',
  RESET_RESERVATIONS_FILTER: 'RESET_RESERVATIONS_FILTER',
  SET_SELECTED_RESERVATION: 'SET_SELECTED_RESERVATION',
  SET_RESERVATIONS_PAGINATION_DATA: 'SET_RESERVATIONS_PAGINATION_DATA',
  SET_RESERVATIONS_CURRENT_PAGE: 'SET_RESERVATIONS_CURRENT_PAGE',
  APPEND_RESERVATIONS: 'APPEND_RESERVATIONS',
  SAVE_RESERVATION_TO_SHARE: 'SAVE_RESERVATION_TO_SHARE',
  UPDATE_RESERVATION: 'UPDATE_RESERVATION',
  SAVE_RESERVATION_TO_SAVE_FIELD: 'SAVE_RESERVATION_TO_SAVE_FIELD',
  RESET_RESERVATION_TO_SAVE: 'RESET_RESERVATION_TO_SAVE',
  SET_AVAILABLE_RESOURCES: 'SET_AVAILABLE_RESOURCES',
  APPEND_AVAILABLE_RESOURCES: 'APPEND_AVAILABLE_RESOURCES',
  SAVE_AVAILABILITY_PAGINATION_DATA: 'SAVE_AVAILABILITY_PAGINATION_DATA',
};
