module.exports = {
  SAVE_GUESTS_SPECIAL_TAGS: 'SAVE_GUESTS_SPECIAL_TAGS',
  APPEND_GUESTS_SPECIAL_TAGS: 'APPEND_GUESTS_SPECIAL_TAGS',
  SAVE_GUESTS_SPECIAL_TAGS_PAGINATION_DATA: 'SAVE_GUESTS_SPECIAL_TAGS_PAGINATION_DATA',
  SAVE_LOCKS_SPECIAL_TAGS: 'SAVE_LOCKS_SPECIAL_TAGS',
  APPEND_LOCKS_SPECIAL_TAGS: 'APPEND_LOCKS_SPECIAL_TAGS',
  SAVE_LOCKS_SPECIAL_TAGS_PAGINATION_DATA: 'SAVE_LOCKS_SPECIAL_TAGS_PAGINATION_DATA',
  SET_SELECTED_TAG: 'SET_SELECTED_TAG',
  SET_TAGS_OPERATIONAL_MODE: 'SET_TAGS_OPERATIONAL_MODE',
  UPDATE_TAG_IN_STATE: 'UPDATE_TAG_IN_STATE',
  SET_GUESTS_TAGS_FILTER_FIELD: 'SET_GUESTS_TAGS_FILTER_FIELD',
  RESET_GUESTS_TAGS_FILTERS: 'RESET_GUESTS_TAGS_FILTERS',
  RESET_GUESTS_TAGS_DATA: 'RESET_GUESTS_TAGS_DATA',
  SET_LOCKS_TAGS_FILTER_FIELD: 'SET_LOCKS_TAGS_FILTER_FIELD',
  RESET_LOCKS_TAGS_FILTERS: 'RESET_LOCKS_TAGS_FILTERS',
  RESET_LOCKS_TAGS_DATA: 'RESET_LOCKS_TAGS_DATA',
  UPDATE_GUEST_TAG_IN_STATE: 'UPDATE_GUEST_TAG_IN_STATE',
  UPDATE_LOCK_TAG_IN_STATE: 'UPDATE_LOCK_TAG_IN_STATE',
  SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES: 'SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES',
  APPEND_GUESTS_SPECIAL_TAGS_CATEGORIES: 'APPEND_GUESTS_SPECIAL_TAGS_CATEGORIES',
  SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES_PAGINATION: 'SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES_PAGINATION',
  SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES: 'SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES',
  SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES_PAGINATION: 'SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES_PAGINATION',
  SAVE_TAG_GUESTS_DATA: 'SAVE_TAG_GUESTS_DATA',
  SAVE_TAG_GUESTS_PAGINATION: 'SAVE_TAG_GUESTS_PAGINATION',
  APPEND_TAG_GUESTS_DATA: 'APPEND_TAG_GUESTS_DATA',
  RESET_TAG_GUESTS_DATA: 'RESET_TAG_GUESTS_DATA',
  SAVE_TAG_LOCKS_DATA: 'SAVE_TAG_LOCKS_DATA',
  APPEND_TAG_LOCKS_DATA: 'APPEND_TAG_LOCKS_DATA',
  SAVE_TAG_LOCKS_PAGINATION: 'SAVE_TAG_LOCKS_PAGINATION',
  RESET_TAG_LOCKS_DATA: 'RESET_TAG_LOCKS_DATA',
  REMOVE_GUEST_TAG_IN_STATE: 'REMOVE_GUEST_TAG_IN_STATE',
  REMOVE_LOCK_TAG_IN_STATE: 'REMOVE_LOCK_TAG_IN_STATE',
  SAVE_GUESTS_SPECIAL_TAGS_NUMBER: 'SAVE_GUESTS_SPECIAL_TAGS_NUMBER',
  SAVE_LOCKS_SPECIAL_TAGS_NUMBER: 'SAVE_LOCKS_SPECIAL_TAGS_NUMBER',
};
