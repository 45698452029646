'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('@babel/runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('@babel/runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _getPrototypeOf = require('core-js/modules/es6.object.get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('@babel/runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('@babel/runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('@babel/runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('@babel/runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

var _SafeAnchor = require('./SafeAnchor');

var _SafeAnchor2 = _interopRequireDefault(_SafeAnchor);

var _bootstrapUtils = require('react-bootstrap/lib/utils/bootstrapUtils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var propTypes = {};

var defaultProps = {
  open: false,
  useAnchor: false,
  bsRole: 'toggle'
};

var DropdownToggle = function (_React$Component) {
  (0, _inherits3.default)(DropdownToggle, _React$Component);

  function DropdownToggle() {
    (0, _classCallCheck3.default)(this, DropdownToggle);
    return (0, _possibleConstructorReturn3.default)(this, (DropdownToggle.__proto__ || (0, _getPrototypeOf2.default)(DropdownToggle)).apply(this, arguments));
  }

  (0, _createClass3.default)(DropdownToggle, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          noCaret = _props.noCaret,
          open = _props.open,
          useAnchor = _props.useAnchor,
          bsClass = _props.bsClass,
          className = _props.className,
          children = _props.children,
          props = (0, _objectWithoutProperties3.default)(_props, ['noCaret', 'open', 'useAnchor', 'bsClass', 'className', 'children']);


      delete props.bsRole;

      var Component = useAnchor ? _SafeAnchor2.default : _Button2.default;
      var useCaret = !noCaret;

      // This intentionally forwards bsSize and bsStyle (if set) to the
      // underlying component, to allow it to render size and style variants.

      // FIXME: Should this really fall back to `title` as children?

      return _react2.default.createElement(
        Component,
        (0, _extends3.default)({}, props, {
          role: 'button',
          className: (0, _classnames2.default)(className, bsClass),
          'aria-haspopup': true,
          'aria-expanded': open
        }),
        children || props.title,
        useCaret && ' ',
        useCaret && _react2.default.createElement('span', { className: 'caret' })
      );
    }
  }]);
  return DropdownToggle;
}(_react2.default.Component);

DropdownToggle.propTypes = propTypes;
DropdownToggle.defaultProps = defaultProps;

exports.default = (0, _bootstrapUtils.bsClass)('dropdown-toggle', DropdownToggle);