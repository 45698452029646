import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import GoogleIconCustom from '../CustomIcons/GoogleIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';

const GoogleSSOButton = ({ idp }) => (
  <MDButton
    type="submit"
    backgroundColor="#fff"
    icon={<img src="/imgs/common/integrationLogos/google.png" style={{ width: 15 }} />}
    title={L20NContext.getSync(idp.labelToken)}
    titleStyle={{ color: '#3f3f3f', fontWeight: 'bold', marginRight: 10 }}
    containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
  />
);

export default GoogleSSOButton;
