import { Box, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import NewFromTemplateIcon from '@material-ui/icons/FileCopyOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Warning from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { BLUE, CREDENTIAL_RULE_STATUSES, PERMISSIONS, PERMISSION_ENTITIES, RESERVATION_STATUSES, RESERVATION_STATUSES_PARAMETERS } from '../../_config/consts';
import { getColorFromThemeName, isUserWithoutEmail } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as CardActions from '../../redux/actions/card.actions';
import * as UtilActions from '../../redux/actions/utils.actions.js';
import RemoveEmailIconCustom from '../CustomIcons/RemoveEmailIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import Tag from '../Tag.jsx';
import TimeRangeView from './TimeRangeView.jsx';
import { InfoOutlined } from '@material-ui/icons';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: '#E53935',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  buttonPositive: {
    color: BLUE,
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
  },
});


const CardCredentialTimeProfileComponent = ({ credentialRule, classes, dispatch }) => {
  const days = credentialRule && credentialRule.daysOfTheWeek && _.orderBy(credentialRule.daysOfTheWeek);
  const daysOptions = _.map(moment.weekdays(true), weekday => ({
    label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
    selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
  }));
  const isInvalidNoTags = _.isEmpty(credentialRule.lockTags) || _.isEmpty(credentialRule.guestTags);
  const credentialStatus = isInvalidNoTags ? RESERVATION_STATUSES.NO_TAGS : credentialRule.status;
  const validityDateFormat = dispatch(UtilActions.getCredentialValidityDateFormat());

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <div className={classes.guestRowContainer} style={{ paddingBottom: 6, minWidth: 260, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {credentialStatus ? (
            <h5 style={{ fontWeight: 'bold', marginTop: 4, marginBottom: 6 }}>
              <Entity
                key={`status_${credentialStatus}`}
                style={{ textTransform: 'uppercase', color: RESERVATION_STATUSES_PARAMETERS[credentialStatus].color }}
                entity={RESERVATION_STATUSES_PARAMETERS[credentialStatus].string}
              />
            </h5>
          ) : null}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
              {`${moment(credentialRule.dateInterval.from).format(validityDateFormat)} - ${moment(credentialRule.dateInterval.to).format(validityDateFormat)}`}
            </h5>
          </div>
          <TimeRangeView
            timeRange={credentialRule}
          />
          {credentialRule.additionalTimeRange ? (
            <div>
            <Divider style={{ marginTop: 5 }} />
            <TimeRangeView
              timeRange={credentialRule.additionalTimeRange}
            />
            </div>
          ) : null}
        </div>
        <div className="tags-container" style={{ marginLeft: 20 }}>
          {_.map(credentialRule.lockTags, tag =>
            <Tag
              key={tag.id}
              tag={tag}
            />)}
        </div>
      </div>
    </div>
  );
}

@connect((state) => ({ viewLoading: state.utils.viewLoading, companyConfigurations: state.user.companyConfigurations, cards: state.cards, themeName: state.settings.items.theme.data.themeName }))
class CardsTableView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expandedRows: [],
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
  }

  onToggleRowExpansion(isExpanded, card) {
    const { expandedRows } = this.state;
    const newExpandedRows = expandedRows;
    if (!isExpanded) {
      newExpandedRows.push(card);
    } else {
      const index = _.findIndex(newExpandedRows, existingCard => existingCard.id === card.id);
      _.pullAt(newExpandedRows, index);
    }
    this.setState({ expandedRows: newExpandedRows });
  }

  render() {
    const {
      classes,
      cards: { cardsData: { data, pagination: { number: currentPage, size, totalPages, totalElements }, filters } },
      cards: { selectedCard },
      onRefreshCards,
      onNewCard,
      onFetchCardsOnPage,
      viewLoading,
      onSelectCard,
      themeName,
      companyConfigurations,
      onCreateCardCredentialFromModel,
      onShowInfoModal,
    } = this.props;
    const { expandedRows } = this.state;
    const canCreateNewCard = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 140, paddingLeft: 10, width: '87%' }}>
        <div>
          {!_.isEmpty(data) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell colSpan={3}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="platformCards" /></h3>
                        <IconButton onClick={() => onRefreshCards()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TablePagination
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="cardsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchCardsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchCardsOnPage(page)}
                        />
                      )}
                    />
                    </TableRow>
                  <TableRow>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="cardNumber" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="authorizedUser" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="cardDescription" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="defaultTimeProfileTitle" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="cardStatus" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="actions" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(data, (card) => {

                    const isSuperError = _.isEmpty(card.credentialRule.lockTags) || !card.user;
                    let statusColor = card.credentialRule.state !== CREDENTIAL_RULE_STATUSES.NOT_UPDATED ? '#43A047' : '#EF6C00';
                     statusColor = isSuperError ? 'red' : statusColor;
                   
                    let syncStatusLabel = card.credentialRule.state === CREDENTIAL_RULE_STATUSES.NOT_UPDATED ? 'notUpdated' : 'updated';
                    syncStatusLabel = card.deleted ? 'cardDeleting' : syncStatusLabel;
                    syncStatusLabel = isSuperError ? 'credentialRuleInvalid' : syncStatusLabel;

                    const isCardMappingEnabled = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.enabled;
                    const cardIdsMap = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.cardIdsMap ? companyConfigurations.customCardsMapping.cardIdsMap : [];

                    const originalCard = isCardMappingEnabled ? _.find(cardIdsMap, cardToTranslate => cardToTranslate.luckeyCardId === card.deviceId) : null;

                    const isUserDeleted = card && card.user && card.user.email && !_.includes(card.user.email, '@');

                    const isCollapsible = card && card.additionalCredentialRules && !_.isEmpty(card.additionalCredentialRules);
                    const isRowExpanded = _.indexOf(expandedRows, card) !== -1;
                    
                    return (
                        <TableRow
                          key={card.id}
                          className={classes.row}
                          hover
                          classes={{
                            selected: classes.rowSelected,
                            hover: classes.rowHovered,
                          }}
                          onClick={event => onSelectCard(card)}
                          selected={selectedCard && selectedCard.id === card.id}
                        >
                          <TableCell>
                            <div className={classes.guestRowContainer} style={{ minWidth: 150 }}>
                              {originalCard && originalCard.originalId ? (
                                <h5 style={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f', marginRight: 5 }}>
                                  {`${originalCard && originalCard.originalId} -`}
                                </h5>
                              ) : null}
                              <h5 style={{ flex: 1, fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}>
                                {`#${card.deviceId}`}
                              </h5>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.guestRowContainer} style={{ display: 'flex', alignItems: 'center' }}>
                              {card && card.user && (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  {!isUserDeleted ? (
                                    <div>
                                      <h5 style={{ fontWeight: 'bold', fontSize: 16 }}>{`${card.user.firstname} ${card.user.lastname}`}</h5>
                                      {isUserWithoutEmail(card.user) ?  (
                                      <div style={{ display: 'flex', marginTop: 7, marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}>
                                        <RemoveEmailIconCustom style={{ color: '#3f3f3f', width: 15, marginRight: 5 }} />
                                        <h5 style={{ color: '#3f3f3f', margin: 0 }}><Entity entity="noEmailSet" /></h5>
                                      </div>
                                    ) : (<h5 style={{ marginTop: 0 }}>{card.user.email}</h5>)}
                                    </div>
                                  ) : (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                      <Warning style={{ fontSize: 15, color: 'red', marginTop: 2 }} />
                                      <h5 style={{ fontWeight: 'bold', margin: 0, marginLeft: 5, fontSize: 16, color: 'red' }}><Entity entity="userDeleted" /></h5>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.guestRowContainer} style={{ minWidth: 100 }}>
                              {card.notes ? <h5 style={{ fontSize: 14, fontStyle: 'italic' }}>{`${card.notes}`}</h5> : null}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                              <CardCredentialTimeProfileComponent
                                credentialRule={card.credentialRule}
                                classes={classes}
                                dispatch={this.props.dispatch}
                              />
                            {isCollapsible ? (
                              <Button
                                aria-label="expand row"
                                size="small"
                                style={{ marginRight: 0 }}
                                endIcon={isRowExpanded ? <KeyboardArrowUpIcon style={{ color: BLUE }}/> : <KeyboardArrowDownIcon style={{ color: BLUE }}/>}
                                onClick={(event) => { event.stopPropagation(); this.onToggleRowExpansion(isRowExpanded, card); }}
                              >
                                <h5 style={{ margin: 0, color: BLUE }}>{`Show ${_.size(card.additionalCredentialRules)} More`}</h5>
                              </Button>
                            ) : null}
                            </div>
                            <Collapse in={isRowExpanded} timeout="auto" unmountOnExit>
                              <Box margin={1} style={{ width: 'max-content' }}>
                                {_.map(card.additionalCredentialRules, (additionalCredentialRule, index) => {
                                  return (
                                    <div style={{ marginLeft: -8 }}>
                                      <Divider style={{ marginBottom: 5 }} />
                                      <CardCredentialTimeProfileComponent
                                        credentialRule={additionalCredentialRule}
                                        classes={classes}
                                        dispatch={this.props.dispatch}
                                      />
                                      <Divider style={{ marginTop: 5 }} />
                                    </div>
                                )})}
                              </Box>
                            </Collapse>
                          </TableCell>
                          <TableCell>
                            <div className={classes.guestRowContainer}>
                              <h5 style={{ fontWeight: 'bold', fontSize: 13 }}>
                                <Entity
                                  key={`status_${card.credentialRule.state}`}
                                  style={{ textTransform: 'uppercase', color: statusColor }}
                                  entity={syncStatusLabel}
                                />
                              </h5>
                            </div>
                          </TableCell>
                          <TableCell>
                            {canCreateNewCard ? (
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onCreateCardCredentialFromModel(card);
                                }}
                                className={classes.buttonPositive}
                              >
                                <Entity entity="copy" />
                                <NewFromTemplateIcon style={{ fontSize: 15, marginLeft: 5 }} />
                              </Button>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow style={{ backgroundColor: headerColor }}>
                      <TablePagination
                        colSpan={0}
                        count={!_.isUndefined(totalElements) ? totalElements : 0}
                        rowsPerPage={!_.isUndefined(size) ? size : 0}
                        page={!_.isUndefined(currentPage) ? currentPage : 0}
                        labelRowsPerPage=" "
                        classes={{
                          input: classes.disabledInput,
                        }}
                        labelDisplayedRows={({ from, to, count }) => (
                          <h4 className={classes.paginationText}>
                            <Entity
                              key={`${from}-${totalPages}-${count}`}
                              entity="cardsTablePagination"
                              data={{
                                currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                                totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                                count: !_.isUndefined(count) ? count : 0,
                              }}
                            />
                          </h4>
                        )}
                        onChangePage={(event, page) => onFetchCardsOnPage(page)}
                        ActionsComponent={() => (
                          <MDTablePaginationActions
                            count={totalElements}
                            page={currentPage}
                            iconColor="white"
                            lastPage={totalPages - 1}
                            rowsPerPage={size}
                            onChangePage={(event, page) => onFetchCardsOnPage(page)}
                          />
                        )}
                      />
                    </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title="noCard"
                    onNewEntity={canCreateNewCard ? () => onNewCard() : null}
                    newEntityTitle="addCard"
                    subtitle="startCreatingCard"
                    iconName="icon-simple-line-icons-credit-card"
                    onShowInfo={() => onShowInfoModal()}
                  />) : <ListLoadingView />}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CardsTableView);
