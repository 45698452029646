// @ts-nocheck
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Field, reduxForm, reset, submit } from 'redux-form';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as GuestActions from '../../redux/actions/guest.actions';
import * as LocksActions from '../../redux/actions/lock.actions';
import * as ActionProfileActions from '../../redux/actions/accessProfiles.actions';
import { LICENSE_TYPES } from '../../_config/consts';
import { formatOutputCustomFieldValue } from '../../_config/utils';
import SearchFiltersAccordion from '../Logs/SearchFiltersAccordion.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import CustomField from './Fields/CustomField.jsx';
import CustomFieldsSearchInputField from './Fields/CustomFieldsSearchInput/CustomFieldsSearchInputField.jsx';
import MDCheckBoxField from './Fields/MDCheckBox/MDCheckBoxField';
import SelectableField from './Fields/SelectableInput/SelectableField.jsx';
import DateRangeView from './Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import TagCategorySelectorField from './Fields/TagCategorySelectorField.jsx';

@connect(state => ({}))
@reduxForm({ form: 'SearchBarForm' })
class SearchBarForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guestsOptions: [],
      guestTagsOptions: [],
      locksOptions: [],
      accessProfilesOptions: [],
      cardIdsMapState: props.cardIdsMap,
    };
  }
  componentDidMount() {
    document.addEventListener('keydown', e => this.onSubmitPressed(e), false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', e => this.onSubmitPressed(e), false);
  }
  
  onSubmitPressed(e) {
    if (e.keyCode === 13) {
      this.onSearchSubmit();
    }
  }

  onSearchSubmit() {
    const { dispatch, onSearchSubmit } = this.props;
    if (onSearchSubmit) {
      dispatch(submit('SearchBarForm'));
    }
  }

  onFilterChange(value) {
    const { onFilterChange } = this.props;
    if (onFilterChange) onFilterChange(value);
  }

  onGuestCompanyNameChange(value) {
    const { onGuestCompanyNameChange } = this.props;
    if (onGuestCompanyNameChange) onGuestCompanyNameChange(value);
  }

  async onFilterGuests(value) {
    const { dispatch } = this.props;
    dispatch(GuestActions.setFilter('search', _.trim(value)));
    const guests = await dispatch(GuestActions.fetchGuests());
    const options = _.map(guests, guest => ({ value: guest.id, label: `${guest.firstname} ${guest.lastname} ${guest.email}` }));
    this.setState({ guestsOptions: options });
    return options;
  }

  async onFilterLocksTags(name) {
    const { dispatch } = this.props;
    const tags = await dispatch(LocksActions.fetchLocksTagsByName(name));
    const options = _.map(tags, tag => ({ value: tag.id, label: tag.name }));
    this.setState({ locksOptions: options });
    return options;
  }

  async onFilterGuestTags(name) {
    const { dispatch } = this.props;
    const tags = await dispatch(GuestActions.fetchGuestsTagsByName(name));
    const options = _.map(tags, tag => ({ value: tag.id, label: tag.name }));
    this.setState({ guestTagsOptions: options });
    return options;
  }

  async onFilterGuestProfiles(name) {
    const { dispatch } = this.props;
    const tags = await dispatch(ActionProfileActions.fetchAccessProfilesOptions({ name }));
    const options = _.map(tags, tag => ({ value: tag.id, label: tag.name }));
    this.setState({ accessProfilesOptions: options });
    return options;
  }

  onSearchReset() {
    const { dispatch, onSelectLockTag, onSearchReset } = this.props;
    dispatch(reset('SearchBarForm'));
    this.setState({ locksOptions: [], guestsOptions: [], guestTagsOptions: [] });
    if (onSearchReset) {
      onSearchReset();
    } else {
      this.onSearchSubmit();
    }
  }

  onSelectGuest(guest) {
    const { onSelectGuest } = this.props;
    if (onSelectGuest) {
      onSelectGuest(guest);
      setTimeout(() => this.onSearchSubmit(), 500);
    }
  }

  onSelectGuestTag(guestTag) {
    const { onSelectGuestTag } = this.props;
    if (onSelectGuestTag) {
      onSelectGuestTag(guestTag);
    }
  }

  onSelectProfile(guestProfile) {
    const { onSelectProfile } = this.props;
    if (onSelectProfile) {
      onSelectProfile(guestProfile);
    }
  }
  
  onSelectLockTag(lockTag) {
    const { onSelectLockTag } = this.props;
    if (onSelectLockTag) {
      onSelectLockTag(lockTag);
    }
  }

  onFilterOriginalCardId(cardId) {
    const { cardIdsMap } = this.props;
    const filteredIds = _.filter(cardIdsMap, card => card.originalId.includes(cardId));
    this.setState({ cardIdsMapState: filteredIds });
    return filteredIds;
  }

  onCustomFieldFilterSearch(value, key, type) {
    const { onCustomFieldSearch } = this.props;
    const formattedValue = formatOutputCustomFieldValue(value, type);
    if (onCustomFieldSearch) {
      onCustomFieldSearch(formattedValue, key);
    }
  }

  render() {
    const { onSelectOption, customFields, mainFilterTextFormattingFunction, onSelectLockHasGateway, onSelectProfile, containerStyle, onSelectOriginalCard, onGuestCompanyNameChange, searchGuestContainerStyle, searchNameContainerStyle, isLockTagsMultiSelectionActive, isGuestTagsMultiSelectionActive, selectableFieldTitle, selectableFieldName, selectableOptions, onSelectGuest, onSelectGuestTag, onFilterChange, onSelectLockTag, searchPlaceholderEntityName, onSelectPriority, priorityOptions, onSelectGuestTagCategory, guestTagCategories, onSelectLockTagCategory, lockTagCategories, onSelectCategory, categoryOptions, onSelectAssignee, assigneeOptions, onSelectResourceType, resourceTypeOptions, onDatesChange, startDate, endDate, datesHelpersOptions, dispatch } = this.props;
    const { guestsOptions, guestTagsOptions, locksOptions, cardIdsMapState, accessProfilesOptions } = this.state;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const searchFilters = (
      <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {onSelectOriginalCard && (
          <Field
            name="originalCardId"
            component={SelectableField}
            options={_.map(cardIdsMapState, card => ({ value: card, label: card.originalId }))}
            titleStyle={{ fontSize: 16 }}
            containerstyle={{ minWidth: 200, marginRight: 10 }}
            placeholder={<Entity entity="searchCardOriginalId" />}
            onInputChange={value => this.onFilterOriginalCardId(value)}
            onSelect={cardOption => onSelectOriginalCard(cardOption && cardOption.value)}
          />
        )}
        {onFilterChange && (
          <Entity
            componentClass={Field}
            name="type"
            type="text"
            componentAttribute="placeholder"
            component={CustomField}
            textFormattingFunction={mainFilterTextFormattingFunction ? (value) => mainFilterTextFormattingFunction(value) : null}
            className="form-control"
            entity={searchPlaceholderEntityName || 'search'}
            onHandleChange={value => this.onFilterChange(value)}
            containerStyle={{ marginRight: 10, minWidth: 200, marginBottom: -10, ...searchNameContainerStyle }}
          />
        )}
        {onGuestCompanyNameChange && (
          <Entity
            componentClass={Field}
            name="companyName"
            type="text"
            componentAttribute="placeholder"
            component={CustomField}
            className="form-control"
            entity="companyName"
            onHandleChange={value => this.onGuestCompanyNameChange(value)}
            containerStyle={{ marginRight: 10, minWidth: 200, marginBottom: -10 }}
          />
        )}
        {onSelectGuest && (
          <Field
            name="guestSelected"
            component={SelectableField}
            noCache
            options={guestsOptions}
            placeholder={<Entity entity="guestNameSearch" />}
            containerstyle={{ minWidth: 450, marginRight: 10, ...searchGuestContainerStyle }}
            onInputChange={value => this.onFilterGuests(value)}
            onSelect={guestData => this.onSelectGuest(guestData)}
          />
        )}
        {onSelectGuestTag && (
          <Field
            name="guestTagSelectedSearch"
            component={SelectableField}
            noCache
            multi={isGuestTagsMultiSelectionActive}
            options={guestTagsOptions}
            placeholder={<Entity entity="guestTagOrGroup" />}
            containerstyle={{ minWidth: 220, marginRight: 10 }}
            onInputChange={value => this.onFilterGuestTags(value)}
            onSelect={tagData => this.onSelectGuestTag(tagData)}
          />
        )}
        {onSelectProfile && (
          <Field
            name="guestProfileSearch"
            component={SelectableField}
            noCache
            options={accessProfilesOptions}
            placeholder={<Entity entity="accessProfile" />}
            containerstyle={{ minWidth: 220, marginRight: 10 }}
            onInputChange={value => this.onFilterGuestProfiles(value)}
            onSelect={tagData => this.onSelectProfile(tagData)}
          />
        )}
        {onSelectLockTag && (
          <Field
            name="tagSelected"
            component={SelectableField}
            noCache
            multi={isLockTagsMultiSelectionActive}
            options={locksOptions}
            placeholder={<Entity entity="lockTags" />}
            containerstyle={{ minWidth: 220, marginRight: 10 }}
            onInputChange={value => this.onFilterLocksTags(value)}
            onSelect={tagData => this.onSelectLockTag(tagData)}
          />
        )}
        {onSelectOption && selectableFieldName && (
          <Field
            id="selectType"
            name={selectableFieldName}
            title={selectableFieldTitle || ''}
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            onHandleChange={value => onSelectOption(value)}
            containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12 }}
          >
            {selectableOptions}
          </Field>
        )}
        {onSelectGuestTagCategory && guestTagCategories!==undefined && (
          <Field
            id="selectTagCategory"
            name={"Category"}
            title={''}
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            onHandleChange={value => onSelectGuestTagCategory(value)}
            containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12 }}
            >
            {guestTagCategories}
          </Field>
        )}
        {onSelectLockTagCategory && lockTagCategories!==undefined && (
          <Field
            id="selectTagCategory"
            name={"Category"}
            title={''}
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            onHandleChange={value => onSelectLockTagCategory(value)}
            containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12 }}
            >
            {lockTagCategories}
          </Field>
        )}
        {onSelectCategory && (
          <Field
            id="selectCategory"
            name={"Category"}
            title={''}
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            onHandleChange={value => onSelectCategory(value)}
            containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12 }}
          >
            {categoryOptions}
          </Field>
        )}
        {onSelectPriority && (
          <Field
            id="selectPriority"
            name={"Priority"}
            title={''}
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            onHandleChange={value => onSelectPriority(value)}
            containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12 }}
          >
            {priorityOptions}
          </Field>
        )}
        {onSelectAssignee && (
          <Field
            id="selectAssignee"
            name={"Assignee"}
            title={''}
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            onHandleChange={value => onSelectAssignee(value)}
            containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12 }}
          >
            {assigneeOptions}
          </Field>
        )}
        {onSelectResourceType && (
          <Field
            id="selectCategory"
            name={"Category"}
            title={''}
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            onHandleChange={value => onSelectResourceType(value)}
            containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12 }}
          >
            {resourceTypeOptions}
          </Field>
        )}
        {onDatesChange && (
          <DateRangeView
            id="ticket-date-selector"
            startDateId="ticket-date-selector-start"
            endDateId="ticket-date-selector-end"
            startDate={startDate}
            endDate={endDate}
            noBorder={false}
            endDatePlaceholderText="--/--/--"
            startDatePlaceholderText="--/--/--"
            minimumNight={0}
            style={{ marginTop: 50 }}
            onDatesChange={({ startDate, endDate }) => onDatesChange(startDate, endDate)}
            onChange={({ startDate, endDate }) => onDatesChange(startDate, endDate)}
            onResetOptionSelection={() => onDatesChange(null, null)}
            helpersOptions={datesHelpersOptions?datesHelpersOptions:[]}
          />
        )}
        {onSelectLockHasGateway ? (
          <Field
            name="connectedWithGateway"
            label={<Entity entity="connectedWithGatewayFilter" />}
            titleStyle={{ fontSize: 16, color: 'white' }}
            component={MDCheckBoxField}
            onHandleChange={value => onSelectLockHasGateway(value)}
          />
        ) : null}
        <MDButton
          containerStyle={{ margin: 0 }}
          backgroundColor="#D32F2F"
          title={<Entity entity="resetFilters" />}
          onClick={() => this.onSearchReset()}
        />
      </div>
    );
    return (
      <div>
        {customFields && !_.isEmpty(customFields) && !isLuckeyLite ? (
          <SearchFiltersAccordion
            containerstyle={{ marginTop: 5 }}
            primaryFilters={
              <h3 style={{ fontWeight: 'bold', margin: 0, color: 'white' }}><Entity entity="searchFilters" /></h3>
            }
          >
            <div style={{ marginTop: -10, display: 'flex', flexDirection: 'column', alignItems: 'center', ...containerStyle }}>
              {searchFilters}
            </div>
            <CustomFieldsSearchInputField
              customFields={customFields}
              onCustomFieldFilterSearch={(value, keyValue, type) => this.onCustomFieldFilterSearch(value, keyValue, type)}
            />
          </SearchFiltersAccordion>
      ) : searchFilters}
      </div>
    );
  }
} 

export default SearchBarForm;
