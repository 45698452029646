import { Card } from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOpen';
import InfiniteScroll from 'react-infinite-scroller';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as LockActions from '../../redux/actions/lock.actions';
import ListLoadingView from '../ListLoadingView.jsx';
import EmptyView from '../EmptyView.jsx';
import { getLockImage, getStatusColorFromBatteryLevel } from '../../_config/utils';
import { BATTERY_IMAGES } from '../../_config/consts';


const PowerIcon = ({ isPlugged, batteryLevel }) =>
  (
    <div style={{ flexDirection: 'row', alignSelf: 'flex-end', paddingBottom: 2 }}>
      {isPlugged ?
        <img alt="battery" src={'/imgs/common/batteryIcons/lightning.png'} style={{ width: 15, height: 25 }} />
        :
        <img alt="battery" src={BATTERY_IMAGES[batteryLevel]} style={{ width: 32, height: 12.5 }} />
      }
    </div>
  );

@connect((state) => ({ locks: state.locks }))
class LockDashboardList extends React.Component {

  async onAppendLocks(page) {
    const { dispatch } = this.props;
    const append = true;
    await dispatch(LockActions.fetchLocks(page, append));
  }

  render() {
    const {  onLockClick, locks: { data: { content: locksData, pagination: { number: currentPage, totalPages } } } } = this.props;
    return (
      <div className="list-view" style={{ height: '100%', position: 'relative' }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => setTimeout(() => this.onAppendLocks((currentPage + 1), 200))}
          hasMore={currentPage + 1 < totalPages}
          loader={<ListLoadingView />}
          style={{ paddingBottom: 250, marginLeft: 10, marginRight: 20 }}
          useWindow={false}
        >
          <div>
            {!_.isEmpty(locksData) ?
              _.map(locksData, (lock) => {
                const batteryStatusColor = getStatusColorFromBatteryLevel(lock.battery);
                return (
                  <Card key={lock.id} style={{ padding: 15, margin: 10, borderRadius: 10 }} onClick={() => onLockClick(lock.id)} className="card">
                    <div key={lock.id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div className="rounded-view">
                        <img className="lock-icon" style={{ width: 70, height: 70 }} src={getLockImage(lock.model)} />
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <h4 style={{ marginTop: 0, fontWeight: 'bold', color: '#3f3f3f', textOverflow: 'ellipsis', whiteSpace: 'break-spaces' }}>{lock.name}</h4>
                        <h4 style={{ margin: 0 }}>{lock.serialNumber}</h4>
                      </div>
                      <div style={{ marginLeft: 'auto' }}>
                        {lock.battery !== undefined && lock.battery !== -1 ?
                          <PowerIcon
                            isPlugged={lock.powerSourceType === 'PLUGGED'}
                            batteryLevel={lock.battery}
                            style={{ alignSelf: 'flex-end', color: '#3f3f3f', paddingBottom: 5 }}
                          />
                          : <div style={{ padding: 5 }} />}
                        {lock.powerSourceType !== 'PLUGGED' && lock.battery !== -1 ? <h5 style={{ margin: 0, marginLeft: 'auto', fontWeight: 'bold', color: batteryStatusColor }}>{lock.battery}%</h5> : null}
                      </div>
                    </div>
                  </Card>
                );
              }) : (
                <div>
                  <EmptyView
                    title="noLock"
                    iconName="icon-simple-line-icons-lock"
                    subtitle="startAddLock"
                  />)
                </div>
              )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
} 

export default LockDashboardList;
