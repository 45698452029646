import { axiosInstance } from './rest';

export function fetchUserCredentials(params) {
  const config = {
    headers: {
      'SL-UUID': 'ANedhovfvouoivnosidjnonddoikl'
    },
    params
  };
  return axiosInstance.get('/api/v2/credentials', config);
}