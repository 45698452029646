import _ from 'lodash';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';

const validate = values => {
  const errors = {};

  if (!values.business) {
    errors.business = 'required';
  }
  return errors;
};

@reduxForm({ form: 'OptixActivationForm', validate })
class OptixActivationForm extends React.Component {
  
  render() {
    const { resourceTypes } = this.props;
    const resourcesOptions = _.map(resourceTypes, resource => ({
      name: resource.name,
      id: resource.resourceTypeId,
      type: { name: 'resource' },
    }));
    return (
      <div>
        <h4><Entity entity="selectResourceTypesOptix" /></h4>
        <Field
          name="resources"
          component={SelectableRowField}
          options={{
            content: resourcesOptions,
          }}
          listContainerStyle={{ marginTop: 0 }}
          listStyle={{ marginTop: 0 }}
        />
        <Field
          name="coldStartResources"
          label={<Entity entity="synchronizeAllResource" />}
          titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
          component={MDCheckBoxField}
        />
        <h5 style={{ marginBottom: 50, marginTop: -10 }}><Entity entity="synchronizeAllResourcesWhenActivate" /></h5>
      </div>
    );
  }
} 

export default OptixActivationForm;
