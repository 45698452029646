/* eslint-disable react/prop-types */
// @ts-nocheck
import { CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import BarChartIcon from '@material-ui/icons/InsertChart';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import * as AccessoriesActions from '../../redux/actions/accessories.actions';
import { BLUE, GREEN } from '../../_config/consts';
import { elaborateAccessoryHistoryChartData, formatAccessHistoryValueToLabel, getColorFromThemeName } from '../../_config/utils';
import ScrollableLineChart from '../Charts/ScrollableLineChart.jsx';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';
import DateRangeView from '../forms/Fields/DatePickers/DateRangePicker/DateRangeView.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 20,
    justifyContent: 'center',
    position: 'relative',
    overflow: 'scroll',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  chartContainer: {
    paddingTop: 60,
  },
  refreshHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 25,
    color: '#3f3f3f',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px #f1f1f1 solid',
    marginTop: 10,
    paddingBottom: 10,
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 5,
    marginLeft: 0,
  },
  listBodyContainer: {
    maxHeight: 400,
    overflow: 'scroll',
  },
  dateHeader: {
    display: 'flex',
    alignItems: 'center',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const WalletConsumptionList = ({ creditUsageData, classes }) => {
  return (
    <div className={classes.listBodyContainer}>
      {creditUsageData && !_.isEmpty(creditUsageData) ? (
        <div>
          {_.map(creditUsageData, (walletUsage) => (
            <div className={classes.row}>
              <div className={classes.rowBodyContainer}>
                <h4 className={classes.rowTitle}>{<Entity entity="creditConsumptionTypeDescription" data={{ type: walletUsage.usageType }} />}</h4>
                <h5 className={classes.tableBodyText} style={{ fontSize: 16, marginTop: 10 }}>{<Entity entity="consumptionCreditsDescription" data={{ credits: walletUsage.quantity }}/>}</h5>
              </div>
              <h5 className={classes.tableBodyText} style={{ color: '#3f3f3f' }}>{moment(walletUsage.createdAt).format('LL')}</h5>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

const EmptyAnalyticsView = () => (
  <div style={{ height: 100, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <BarChartIcon style={{ color: '#bdbdbd', fontSize: 50 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFound" /></h3>
  </div>
);

@connect(state => ({ accessoryHistory: state.accessories.history, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class LockSensorHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isRefreshing: false,
    };
  }

  componentDidMount() {
    const { dispatch, doorSensor } = this.props;
    if (doorSensor) {
      dispatch(AccessoriesActions.fetchAccessoryHistory(doorSensor.id));
    }
  }

  onRefreshData() {
    const { dispatch, doorSensor } = this.props;
    if (doorSensor) {
      dispatch(AccessoriesActions.fetchAccessoryHistory(doorSensor.id));
    }
  }

  onExportHistoryData(format) {
    const { dispatch, doorSensor } = this.props;
    dispatch(AccessoriesActions.exportAccessoryHistory(doorSensor.id, format));
  }

  onDatesChanges(fromDate, toDate) {
    const { dispatch, doorSensor } = this.props;
    if (doorSensor) {
      const startDate = fromDate ? moment(fromDate).startOf('day').valueOf() : moment().subtract(1, 'month').startOf('day').valueOf();
      const endDate = toDate ? moment(toDate).endOf('day').valueOf() : moment().add(1, 'day').endOf('day').valueOf();
      dispatch(AccessoriesActions.setAccessoryHistoryFilter('fromDate', startDate));
      dispatch(AccessoriesActions.setAccessoryHistoryFilter('toDate', endDate));
      dispatch(AccessoriesActions.fetchAccessoryHistory(doorSensor.id));
    }
  }

  resetDateFilter() {
    const { dispatch, doorSensor } = this.props;
    if (doorSensor) {
      dispatch(AccessoriesActions.setAccessoryHistoryFilter('fromDate', moment().startOf('day').subtract(1, 'month').valueOf()));
      dispatch(AccessoriesActions.setAccessoryHistoryFilter('toDate', moment().endOf('day').valueOf()));
      setTimeout(() => dispatch(AccessoriesActions.fetchAccessoryHistory(doorSensor.id)), 300);
    }
  }

  render() {
    const { accessoryHistory, classes, themeName } = this.props;
    const { isRefreshing } = this.state;
    const historyData = elaborateAccessoryHistoryChartData(accessoryHistory.content);
    const textColor = getColorFromThemeName(themeName);
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1, marginRight: 20 }}>
          <div className={classes.header}>
            <div className={classes.refreshHeader}>
              <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="sensorHistory" /></h3>
            </div>
            <div className={classes.refreshHeader}>
              <SimpleExportMenu
                titleContainerStyle={{ marginRight: 25 }}
                iconStyle={{ color: textColor || GREEN }}
                exportTitleStyle={{ color: textColor || GREEN }}
                onExportLogs={format => this.onExportHistoryData(format)}
              />
              <IconButton
                disabled={isRefreshing}
                style={{ opacity: isRefreshing ? 0.5 : 1 }}
                onClick={() => this.onRefreshData()}
              >
                <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
              </IconButton>
            </div>
          </div>
          <div className={classes.dateHeader}>
            <DateRangeView
              id="dashboard-date-selector"
              startDateId="dashboard-date-selector-start"
              endDateId="dashboard-date-selector-end"
              startDate={moment(accessoryHistory.filters.fromDate).startOf('day')}
              endDate={moment(accessoryHistory.filters.toDate).endOf('day')}
              noBorder={false}
              onClearDates={() => this.resetDateFilter()}
              endDatePlaceholderText="--/--/--"
              startDatePlaceholderText="--/--/--"
              showClearDates
              minimumNight={0}
              style={{ marginTop: 50 }}
              isOutsideRange={day => moment(day).isAfter(moment()) || moment(day).isBefore(moment().subtract(1, 'year'))}
              onDatesChange={({ startDate, endDate }) => this.onDatesChanges(startDate, endDate)}
              onChange={({ startDate, endDate }) => this.onDatesChanges(startDate, endDate)}
              onResetOptionSelection={() => this.resetDateFilter()}
              helpersOptions={[
                {
                  title: L20NContext.getSync('today'),
                  startDate: moment().startOf('day'),
                  endDate: moment().endOf('day'),
                },
                {
                  title: L20NContext.getSync('thisWeek'),
                  startDate: moment().startOf('week'),
                  endDate: moment().endOf('week'),
                },
                {
                  title: L20NContext.getSync('thisMonth'),
                  startDate: moment().startOf('month'),
                  endDate: moment().endOf('month'),
                },
              ]}
            />
          </div>
          <div className={classes.chartContainer}>
            {accessoryHistory.content && !_.isEmpty(accessoryHistory.content) ? (
              <ScrollableLineChart
                height={100}
                data={_.cloneDeep(historyData)}
                showLegend={false}
                chartOptions={{
                  tooltips: {
                    callbacks: {
                      label: function (tooltipItem, data) {
                        if (data && data['datasets'] && data['datasets'][0] && data['datasets'][0]['data'] && !_.isNull(data['datasets'][0]['data'][tooltipItem['index']])) {
                          return formatAccessHistoryValueToLabel(data['datasets'][0]['data'][tooltipItem['index']])
                        }
                      },
                    },
                  },
                  scales: {
                    yAxes: [{
                      ticks: {
                        stepSize: 1,
                        min: 0,
                        beginAtZero: true,
                        callback: value => `${formatAccessHistoryValueToLabel(value)}`,
                      },
                    }],
                  },
                }}
              />
              
            ) : <EmptyAnalyticsView />}
          </div>
          {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
        </div>
      </MuiThemeProvider>
    );
  }
}


export default withStyles(styles)(LockSensorHistory);
