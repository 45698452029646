// @ts-nocheck
import { Card, CircularProgress, createTheme, IconButton, LinearProgress, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import BarChartIcon from '@material-ui/icons/InsertChart';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import ResourcesIcon from '@material-ui/icons/FilterNone';
import {
  FormControl
} from '@sketchpixy/rubix';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { BLUE, GREEN } from '../../_config/consts';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';
import DateRangeView from '../forms/Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import CopyIconCustom from '../CustomIcons/CopyIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
    display: 'flex',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    margin: 0,
    marginTop: 15,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '100%',
    marginTop: 10,
    marginLeft: 10,
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  progressAreaOccupancy: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '30px !important',
    width: '30px !important',
  },
  headerText: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
  },
  chartContainer: {
    width: '100%',
    maxHeight: 320,
    minHeight: 320,
    overflowY: 'scroll',
    marginTop: 20,
  },
  refreshHeader: {
    position: 'absolute',
    right: 10,
    top: 5,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '90%',
    marginTop: 0,
    borderRadius: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginLeft: 20,
  },
  tableColumnText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  barColor25: {
    backgroundColor: 'rgba(74, 143, 150, 1.0)'//'rgba(241, 130, 141, 1.0)'
  },
  barColor50: {
    backgroundColor: 'rgba(74, 143, 150, 1.0)'//'rgba(244, 179, 80, 1.0)'
  },
  barColor75: {
    backgroundColor: 'rgba(74, 143, 150, 1.0)'//'rgba(92, 178, 188, 1.0)'
  },
  barColor100: {
    backgroundColor: 'rgba(74, 143, 150, 1.0)'
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});


class BookeyBestResourcesList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredResources: props.resources?props.resources.slice(0, 20):[],
      filterText: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { resources: newResources } = nextProps;
    const { users: oldResources } = this.props;
    if (newResources !== oldResources) {
      this.setState({ filteredResources: newResources?newResources.slice(0, 20):[] });
    }
  }

  onSearchResources(text) {
    const { resources } = this.props;
    this.setState({ filterText: text });
    const filteredResources = _.filter(resources, resource => resource.name.toLowerCase().indexOf(text) > -1).slice(0, 20);
    this.setState({ filteredResources });
  }

  onSearchReset() {
    const { resources } = this.props;
    this.setState({ filterText: '', filteredResources: resources?resources.slice(0, 20):[] });
  }

  render() {
    const { resources, classes, textColor, isRefreshing, lastUpdate, onRefreshMetric } = this.props;
    const { filteredResources, filterText } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1 }} elevation={3}>
          <div className={classes.header}>
            <CopyIconCustom style={{ width: 25, color: textColor || '#3f3f3f', marginRight: 10 }} />
            <h4 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}>
              <Entity entity="bookeyTopResources" style={{width: 'max-content'}}/>
            </h4>
            {resources && !_.isEmpty(resources) ? (
            <div className={classes.searchHeader}>
              <Card className={classes.searchContainer}>
                <FormControl
                  type="text"
                  value={filterText}
                  placeholder={L20NContext.getSync('search')}
                  className="form-control-noBorder"
                  style={{ width: '100%', borderRadius: 5 }}
                  onChange={e => this.onSearchResources(e.target.value)}
                />
                {filterText ? (
                  <IconButton style={{ height: 20, width: 20, marginLeft: -60, marginRight: 10 }} onClick={() => this.onSearchReset()}>
                    <CloseIcon />
                  </IconButton>
                ) : null}
                <SearchIcon style={{ marginLeft: filterText ? 0 : -30 }} />
              </Card>
            </div>
          ) : null}
          </div>
          <div className={classes.chartContainer}>
            {filteredResources && !_.isEmpty(filteredResources) ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="resource" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="resourceType" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationsNumber" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationsHoursTotal" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray', minWidth:200 }}><Entity entity="resourceUsagePercentage" /></h5></TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                {_.map(filteredResources, (resource) => {
                  const barClassCss = resource.utilizationPercentage<25?classes.barColor25:resource.utilizationPercentage<50?classes.barColor50:
                    resource.utilizationPercentage<75?classes.barColor75:classes.barColor100
                  return (
                  <TableRow
                    key={resource.id}
                    className={classes.row}
                    hover
                    classes={{
                      selected: classes.rowSelected,
                      hover: classes.rowHovered,
                    }}
                    onClick={() => {}}
                  >
                    <TableCell>
                      <h5 style={{ color: '#3f3f3f' }}>
                        {`${resource.name}`}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ color: '#3f3f3f' }}>
                        {`${resource?.type?.name}`}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {resource.totalReservations}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {(resource.totalReservedMinutes/60).toFixed(1)}
                      </h5>
                    </TableCell>
                    <TableCell style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                      <LinearProgress
                        variant={"determinate"}
                        value={resource.utilizationPercentage}
                        style={{ height: 15, borderRadius: 10, width:'100%', backgroundColor:'aliceblue'}}
                        classes={{ barColorPrimary: barClassCss }}
                      />
                      <h5 style={{ marginLeft: 10, color: '#3f3f3f' }}>
                        {(Math.round((resource.utilizationPercentage)*100)/100).toFixed(2) + "%"}
                      </h5>
                    </TableCell>
                  </TableRow>
                  )
                })}
              </TableBody>
              </Table>
            ) : (
              <EmptyView
                title="noResources"
                containerStyle={{ marginTop: 60}}
              />
            )}
          </div>
          {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(BookeyBestResourcesList);
