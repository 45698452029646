import React from 'react';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { ATLAS_NETWORK_STATUS_PARAMETERS, ATLAS_SYNCHRONIZATION_STATUS_PARAMETERS } from '../../_config/consts.js';
import FormCardContainer from '../forms/Elements/FormCardContainer.jsx';
import { Divider } from '@material-ui/core';

@connect((state) => ({ gateways: state.gateways }))
class AtlasGatewayDetails extends React.Component {
  render() {
    const { gateways: { wired: { selectedWiredGateway: gateway } } } = this.props;
    const networkStatusParameters = ATLAS_NETWORK_STATUS_PARAMETERS[gateway.networkStatus];
    const credentialsStatusParameters = ATLAS_SYNCHRONIZATION_STATUS_PARAMETERS[gateway.synchronizationStatus];
    return (
      <div style={{ paddingTop: 0 }}>
        <FormCardContainer headerStyle={{ marginBottom: 0 }}>
          <h4 style={{ fontSize: 30, margin: 0, marginBottom: 10, color: '#3f3f3f', fontWeight: 'bold' }}>
            {gateway.name}
          </h4>
          <h4 style={{ fontSize: 20, margin: 0, marginBottom: 10, color: '#3f3f3f' }}>
            {gateway.description}
          </h4>
          {networkStatusParameters && networkStatusParameters.title && networkStatusParameters.description ? (
            <div style={{ marginTop: 30 }}>
              <h4><Entity key={gateway.deviceId} style={{ color: '#3f3f3f', fontWeight: 'bold' }} entity="atlasNetworkStatusTitle" />:
                <span style={{ color: networkStatusParameters.color, marginLeft: 10, fontWeight: 'bold' }}>
                  <Entity key={gateway.deviceId} entity={networkStatusParameters.title} />
                </span>
              </h4>
              <h5><Entity key={gateway.deviceId} entity="atlasNetworkStatusDescription" /></h5>
            </div>
          ) : null}
          <Divider />
          {credentialsStatusParameters && credentialsStatusParameters.title && credentialsStatusParameters.description ? (
            <div style={{ marginTop: 20 }}>
              <h4><Entity key={gateway.deviceId} style={{ color: '#3f3f3f', fontWeight: 'bold' }} entity="atlasSyncronizationStatusTitle" />:
                <span style={{ color: networkStatusParameters.color, marginLeft: 10, fontWeight: 'bold' }}>
                  <Entity key={gateway.deviceId} entity={credentialsStatusParameters.title} />
                </span>
              </h4>
              <h5><Entity key={gateway.deviceId} entity="atlasSyncronizationStatusDescription" /></h5>
            </div>
          ) : null}
        </FormCardContainer>
      </div>
    );
  }
} 

export default AtlasGatewayDetails;
