import Card from '@material-ui/core/Card';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { RESERVATION_CONFIRMATION_STATUSES_MAP } from '../../../_config/consts.js';

export default class ReservationStatusView extends React.Component {

  render() {
    const { reservation } = this.props;
    const reservationStatus = reservation.state;
    const viewValues = reservationStatus ? RESERVATION_CONFIRMATION_STATUSES_MAP[reservationStatus] : null;
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15 }}>
        {viewValues && viewValues.icon ? (viewValues.icon) : null}
        <div style={{ marginLeft: 10 }}>
          {viewValues && viewValues.title ? (
            <h4 className="card-title" style={{ marginLeft: 5 }}><Entity key={viewValues.title} entity={viewValues.title} /></h4>
          ) : null}
          {viewValues && viewValues.description ? (
            <h5 className="card-description" style={{ margin: 5 }}><Entity entity={viewValues.description} key={viewValues.description} /></h5>
          ) : null}
        </div>
      </div>
    );
  }
}
