import React from 'react';
import { withStyles } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';


const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  titleText: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'left',
  },
  sectionText: {
    margin: 0,
    marginRight: 5,
    fontSize: 16,
    lineHeight: 1.5,
    color: '#3f3f3f',
    textAlign: 'left',
  },
  exampleContainer: {
    marginTop: 15,
    borderRadius: 5,
  },
  exampleText: {
    marginLeft: 12,
    marginTop: 10,
    fontSize: 15,
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

const LockOfficeModeExplanationView = ({ classes, languageImagePath }) => (
  <div style={{ marginLeft: 13, marginBottom: 30 }}>
    <h2 className={classes.sectionText}><Entity entity="officeModeWhatIsItDescription" /></h2>
    <h2 className={classes.sectionText}><Entity entity="officeModeWhatIsItDescription2" />:</h2>
    <ol>
      <li>
        <h2 className={classes.sectionText}>1. <Entity entity="officeModeWhatIsItDescriptionSub1" /></h2>
      </li>
      <li>
        <h2 className={classes.sectionText}>2. <Entity entity="officeModeWhatIsItDescriptionSub2" /></h2>
      </li>
      <li>
        <h2 className={classes.sectionText}>3. <Entity entity="officeModeWhatIsItDescriptionSub3" /></h2>
      </li>
    </ol>
    <h2 className={classes.titleText}><Entity entity="officeModeSemiAutomatic" /></h2>
    <h2 className={classes.sectionText}><Entity entity="officeModeSemiAutomaticDescription" /></h2>
    <img src={`${languageImagePath}/officeModeSemi.png`} alt="Office Mode semi automatic" className="imageStyle" style={{ width: '70%', marginTop: 15 }} />
    <h2 className={classes.titleText}><Entity entity="officeModeAutomatic" /></h2>
    <h2 className={classes.sectionText}><Entity entity="officeModeAutomaticDescription" /></h2>
    <img src={`${languageImagePath}/officeModeAuto.png`} alt="Office Mode semi automatic" className="imageStyle" style={{ width: '70%', marginTop: 15 }} />
    <h2 className={classes.titleText}><Entity entity="officeModeAutomaticFirstAccess" /></h2>
    <h2 className={classes.sectionText}><Entity entity="officeModeAutomaticFirstAccessDescription" /></h2>
    <img src={`${languageImagePath}/officeModeAutoFirst.png`} alt="Office Mode semi automatic" className="imageStyle" style={{ width: '70%', marginTop: 15 }} />
    <h2 className={classes.titleText} style={{ marginTop: 50 }}><Entity entity="officeModeManualDeactivation" /></h2>
    <h2 className={classes.sectionText}><Entity entity="officeModeManualDeactivationDescription" /></h2>
    <h2 className={classes.titleText} style={{ marginTop: 30 }}><Entity entity="officeProgramSynchronization" /></h2>
    <h2 className={classes.sectionText}><Entity entity="officeProgramSynchronizationDescription" /></h2>
  </div>
);
export default withStyles(styles)(LockOfficeModeExplanationView);
