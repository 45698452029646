import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: '100%',
    zIndex: 100,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  pannello: {
    backgroundColor: 'transparent',
    margin: 0,
  },
  entered: {
    overflow: 'visible',
  },
  summary: {
    height: 40,
  },
  details: {
    overflow: 'visible',
  },
});

const SearchFiltersAccordion = ({ classes, containerstyle, primaryFilters, defaultExpanded, children }) => (
  <div className={classes.root} style={{ ...containerstyle }}>
    <ExpansionPanel
      className={classes.pannello}
      defaultExpanded={defaultExpanded}
      CollapseProps={{
        classes: {
          entered: classes.entered,
        },
      }}
    >
      <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon style={{ color: 'white', fontSize: 26 }} />}>
        {primaryFilters}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details} style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </div>
);

export default withStyles(styles)(SearchFiltersAccordion);
