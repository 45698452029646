import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { CircularProgress, withStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as GuestAPI from '../../_config/usersAPI';

const styles = {
  container: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    zIndex: 10000,
    backgroundColor: '#12637f',
    borderRadius: 5,
  },
  innerContainer: {
    boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: 50,
    minWidth: 240,
    padding: 10,
    borderRadius: 5,
    paddingLeft: 20,
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    marginLeft: 10,
    marginBottom: 13,
  },
  circularProgress: {
    color: 'white',
  },
  closeIcon: {
    color: 'white',
  }
};

@connect(state => ({ backgroundTasks: state.utils.backgroundTasks }))
class SpinnerBackground extends React.Component {
  
  onCancelRequest(taskId) {
    const { dispatch, backgroundTasks } = this.props;
    const taskFound = _.find(backgroundTasks, task => task.id === taskId);
    if (taskFound.cancelCallback) {
      taskFound.cancelCallback();
    }
    dispatch(UtilsActions.removeBackgroundTask(taskId));
  }

  render() {
    const { backgroundTasks, classes, spinnerStyle } = this.props;
    return (
      <div>
        {_.map(backgroundTasks, (task, key) => (
          <span className={classes.container} style={{ bottom: 10 + (60 * key) }}>
            <div key={task.id} className={classes.innerContainer}>
              <div className={classes.loaderContainer}>
                <CircularProgress className={classes.circularProgress} size={20} style={{ ...spinnerStyle }} />
                <h5 className={classes.title}>{<Entity entity={task.title ? task.title : 'loading'} />}</h5>
              </div>
              <IconButton onClick={() => this.onCancelRequest(task.id)}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>
          </span>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(SpinnerBackground);
