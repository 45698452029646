// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

@connect((state) => ({ }))
class CustomFieldsDisplayView extends React.Component {
  render() {
    const { dispatch, customFields, containerStyle, textStyle } = this.props;
    const customFieldsToShow = _.filter(customFields, customField => customField && customField !== '' );
    return (
      customFields && !_.isEmpty(customFieldsToShow) ? (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', ...containerStyle }}>
          {_.map(_.keys(customFields), (guestFieldKey, key) => (
            customFields[guestFieldKey] ? (
              <h4 key={guestFieldKey} style={{ fontSize: 17, margin: 0, marginLeft: 5, lineHeight: '1.4em', fontWeight: 200, ...textStyle }}>
                {guestFieldKey}: {customFields[guestFieldKey]}
                {key === _.size(customFieldsToShow)  ?  null : ' | '}
              </h4>
            ) : null))
          }
        </div>
      ) : null
    )
  }
} 

export default CustomFieldsDisplayView;

