import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_INSTALLERS,
  SAVE_INSTALLERS_PAGINATION_DATA,
  APPEND_INSTALLERS,
  SET_SELECTED_INSTALLER,
  SET_INSTALLERS_OPERATIONAL_MODE,
  REMOVE_INSTALLER,
  UPDATE_INSTALLER,
  SAVE_INSTALLER,
  SET_INSTALLERS_FILTER,
  RESET_INSTALLERS_FILTERS,
  RESET_INSTALLERS_DATA,
  RESET_INSTALLERS_PAGINATION_DATA,
  SET_INSTALLERS_ORDER,
} from '../actions/actionTypes/installers';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {
      roleIds: [2], // Default filter value it's installer
    },
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    }
  },
  selectedUser: {},
  isOperationalMode: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_INSTALLERS:
      return state.setIn(['data', 'content'], action.users);
    case APPEND_INSTALLERS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.users]);
    case RESET_INSTALLERS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    case SAVE_INSTALLER:
      return state.setIn(['data', 'content'], [action.user, ...state.data.content]);
    case SAVE_INSTALLERS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SET_SELECTED_INSTALLER:
      return state.set('selectedUser', action.user);
    case REMOVE_INSTALLER: {
      const users = _.filter(state.data.content, user => user.id !== action.userId);
      return state.setIn(['data', 'content'], users);
    }
    case UPDATE_INSTALLER: {
      const index = _.findIndex(state.data.content, data => data.id === action.user.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.user);
      }
      return state;
    }
    case SET_INSTALLERS_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_INSTALLERS_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case SET_INSTALLERS_ORDER:
      return state.setIn(['data', 'sorting', 'orderBy'], action.orderBy)
                  .setIn(['data', 'sorting', 'orderDir'], action.orderDir);
    case RESET_INSTALLERS_FILTERS:
      return state.setIn(['data', 'filters'], initialState.filters);
    case RESET_INSTALLERS_DATA:
      return state.set('data', initialState.data);
    default:
      return state;
  }
}
