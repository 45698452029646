import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SaveIcon from '@material-ui/icons/Save';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as GuestActions from '../../redux/actions/guest.actions';
import ListLoadingView from '../ListLoadingView.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import SelectableField from './Fields/SelectableInput/SelectableField.jsx';
import GuestGroupIcon from '@material-ui/icons/SupervisorAccount';
import { CircularProgress } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { isUserWithoutEmail } from '../../_config/utils';


const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  }

  if (!values.type) {
    errors.type = 'required';
  }

  if (!values.color) {
    errors.color = 'required';
  }

  return errors;
};


@reduxForm({ form: 'TagGuestsForm', validate })
@connect(state => ({ form: state.form.TagGuestsForm }))
class TagGuestsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guestsOptions: [],
      guestSelected: null
    };
  }

  async componentDidMount() {
    this.onFetchGuestsAndSetOptions();
  }

  async onFilterGuests(value) {
    const { dispatch } = this.props;
    dispatch(GuestActions.setFilter('search', value));
    this.onFetchGuestsAndSetOptions();
  }
    
  async onFetchGuestsAndSetOptions() {
    const { dispatch, items } = this.props;
    const guests = await dispatch(GuestActions.fetchGuests());
    const guestOptions = _.map(guests, guest => ({ value: guest, label: `${guest.firstname} ${guest.lastname} ${isUserWithoutEmail(guest) ? '': ` - ${guest.email}`}` }));
    const filteredGuestsOptions = _.filter(guestOptions, guestOption => !_.find(items, { id: guestOption.value.id }));
    this.setState({ guestsOptions: filteredGuestsOptions });
    return filteredGuestsOptions;
  }
  
  onSelectGuest(guest) {
    if (guest && guest.value) {
      this.setState({ guestSelected: guest.value });
    }
  }

  onAddGuestToTag(guest) {
    const { onAddGuestToTag } = this.props;
    onAddGuestToTag(guest);
    this.setState({ guestSelected: null });
  }

  render() {
    const { canAddGuests, isListLoading, onDownloadListCSV, items, rowElement, pagination, onFetchMoreItems } = this.props;
    const { guestsOptions, guestSelected } = this.state;
    return (
      <Form>
        <FormCardContainer title="guestWithinTag" subtitle="guestWithinTagDescription">
          {canAddGuests ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Field
                name="guestTagSelectedSearch"
                component={SelectableField}
                noCache
                options={guestsOptions}
                placeholder={<Entity entity="findGuestToAdd" />}
                containerstyle={{ minWidth: 480, marginRight: 10 }}
                onInputChange={value => this.onFilterGuests(value)}
                onSelect={tagData => this.onSelectGuest(tagData)}
              />
              <MDButton
                disabled={!guestSelected}
                title={<Entity entity="addGuestToTag" />}
                style={{ borderRadius: 5 }}
                onClick={() => this.onAddGuestToTag(guestSelected)}
              />
              {isListLoading ? <CircularProgress /> : null}
            </div>
          ) : null}
          {!_.isEmpty(items) ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <SaveIcon style={{ marginRight: 5, fontSize: 20, color: '#90CAF9' }} />
              <h4 onClick={() => onDownloadListCSV()} className="label-responsive link-label"><Entity entity="downloadCSV" /></h4>
            </div>
          ) : null}
          {!_.isEmpty(items) ? (
            <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <WarningIcon style={{ color: 'red', marginRight: 15, marginLeft: 10, fontSize: 25 }} />
                <h5 style={{ color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="modifyUserTagWarning" /></h5>
              </div>
              <div style={{ maxHeight: 400, padding: 5, overflowY: 'scroll' }}>
                <InfiniteScroll
                  pageStart={0}
                  useWindow={false}
                  initialLoad={false}
                  loadMore={() => onFetchMoreItems(pagination.number + 1)}
                  hasMore={pagination.number + 1 < pagination.totalPages}
                  loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    {_.map(items, (item, index) => (
                      <div key={item.id}>
                        {rowElement(item, index)}
                      </div>
                    ))}
                    
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <GuestGroupIcon style={{ color: '#3f3f3f', marginTop: 3, fontSize: 35 }} />
              <h4 style={{ color: '#3f3f3f', textAlign: 'center' }}>
                <Entity key={canAddGuests} entity={canAddGuests ? 'noGuestsInTag' : 'createTagToAssignGuests'} />
              </h4>
            </div>
          )}
        </FormCardContainer>
      </Form>
    );
  }
} 

export default TagGuestsForm;