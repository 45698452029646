import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { BLUE } from '../../../_config/consts';

const styles = {
  circularProgress: {
    color: BLUE,
  },
};


const GatewayNotificationWaitingView = ({ classes, notification }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <h4 style={{ color: '#3f3f3f' }}><Entity entity="waitForOpening" /></h4>
    <CircularProgress className={classes.circularProgress} />
  </div>
);

export default withStyles(styles)(GatewayNotificationWaitingView);
