/* eslint-disable max-len */
import { Form } from '@sketchpixy/rubix';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import { VALIDATION_MODES, VALIDATION_MODES_NAMES, VALIDATION_MODES_SELECTION_OPTIONS, VALIDATION_MODES_TIME_UNIT_OPTIONS } from '../../../_config/consts.js';
import CustomField from '../Fields/CustomField.jsx';
import TranslatableOption from '../Fields/TranslatableOption.jsx';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import { InfoOutlined } from '@material-ui/icons';

const validationModeOptions = [{ label: 'itemDeliveryModeSelect', value: '' }, ..._.map(VALIDATION_MODES_SELECTION_OPTIONS, itemType => ({ label: VALIDATION_MODES_NAMES[itemType], value: itemType }))];
const validationTimeUnitsOptions = _.map(VALIDATION_MODES_TIME_UNIT_OPTIONS, itemType => ({ label: VALIDATION_MODES_NAMES[itemType], value: itemType }));


@connect(state => ({}))
class StandardDeviceValidationForm extends React.Component {

  onSelectValidationMode(validationMode) {
    const { dispatch, formName } = this.props;
    if (validationMode !== VALIDATION_MODES.ALWAYS_VALIDATED) {
      dispatch(change(formName, 'validationPeriod', 10));
      dispatch(change(formName, 'validationPeriodTimeUnit', VALIDATION_MODES_TIME_UNIT_OPTIONS.ONE_MINUTE_VALIDATION));
    }
  }

  render() {
    const { form, hideCard, title, subtitle, onInfo } = this.props;
    const selectedValidationMode = form && form.values && form.values.validationMode;
    const selectedValidationPeriod = form && form.values && form.values.validationPeriod;
    const selectedValidationPeriodTimeUnit = form && form.values && form.values.validationPeriodTimeUnit;
    return (
      <Form>
        <FormCardContainer
          title={title || 'validationRules'}
          subtitle={subtitle || 'validationRulesDescription'}
          hideCard={hideCard}
          iconButtonAction={() => onInfo()}
          icon={<InfoOutlined style={{ fontSize: 25, marginTop: -3, color: '#3f3f3f' }} />}
        >
          {/* <div style={{ marginTop: 0, marginBottom: 12 }}>
            <h5 style={{ fontWeight: 'bold', fontSize: 16, color: '#3f3f3f', margin: 0 }}>{<Entity entity="validationModeTitle" />}</h5>
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 8, fontWeight: 'lighter' }}><Entity entity="validationModeDescription" /></h5>
          </div> */}
          <Field
            id="type"
            name="validationMode"
            title=""
            component={CustomField}
            className="form-control-select"
            componentClass="select"
            onHandleChange={(validationMode) => this.onSelectValidationMode(validationMode)}
          >
            {_.map(validationModeOptions, type =>
              <Entity
                key={type.value}
                componentClass={TranslatableOption}
                value={type.value}
                componentAttribute="text"
                entity={type.label}
              />)
            }
        </Field>
        {selectedValidationMode && selectedValidationMode !== VALIDATION_MODES.ALWAYS_VALIDATED ? (
          <div style={{ marginLeft: 14, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 0 }}>{<Entity entity="validationPeriodFieldSentence" />}</h4>
            <Entity
              componentClass={Field}
              name="validationPeriod"
              type="number"
              componentAttribute="placeholder"
              component={CustomField}
              hideErrorLabel
              containerStyle={{ width: 100 }}
              className="form-control-custom"
            />
            <Field
              id="timeUnit"
              name="validationPeriodTimeUnit"
              component={CustomField}
              containerStyle={{ marginLeft: 20 }}
              className="form-control-select"
              componentClass="select"
            >
              {_.map(validationTimeUnitsOptions, unitType =>
                <Entity
                  key={unitType.value}
                  componentClass={TranslatableOption}
                  value={unitType.value}
                  componentAttribute="text"
                  entity={unitType.label}
                />)}
            </Field>
          </div>
          ) : null}
          {selectedValidationMode ? (
            <div style={{ marginTop: -10, marginLeft: 14, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <HelpIcon style={{ color: '#3f3f3f', marginRight: 12, fontSize: 20 }} />
              {selectedValidationMode !== VALIDATION_MODES.ALWAYS_VALIDATED ? (
                <h5 style={{ fontSize: 15, fontWeight: 100, color: '#3f3f3f', lineHeight: '1.5em' }}>
                  <Entity
                    key={`${form}-${selectedValidationPeriod}-${selectedValidationPeriodTimeUnit}`}
                    entity="forceValidationDescription"
                    data={{
                      time: selectedValidationPeriod,
                      timeUnit: L20NContext.getSync(selectedValidationPeriodTimeUnit),
                    }}
                  />
                </h5>
              ) : (
                <h5 style={{ fontSize: 15, fontWeight: 100, color: '#3f3f3f', lineHeight: '1.5em' }}>
                  <Entity
                    key={`${selectedValidationPeriod}-${selectedValidationPeriodTimeUnit}`}
                    entity="selectedValidationModeInfiniteDescription"
                  />
                </h5>
              )}
          </div>
          ) : null}
        </FormCardContainer>
      </Form>
    );
  }
} 

export default StandardDeviceValidationForm;
