import qs from 'qs';
import { axiosInstance } from "./rest";
import { executeRequestOrDefault } from './utils';
import { PERMISSION_ENTITIES, PERMISSIONS } from './consts';
import axios from 'axios';

let smartLocksEventsCSVCancelToken;
let subcompaniesSmartLocksEventsCSVCancelToken;
let adminEventsCSVCancelToken;

export function fetchAdminEvents(params) {
  adminEventsCSVCancelToken = axios.CancelToken.source();
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/logs/adminEvents',
    cancelToken: adminEventsCSVCancelToken.token,
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.LOG, [PERMISSIONS.READ], requestData);
}

export function fetchSmartLockEvents(params) {
  smartLocksEventsCSVCancelToken = axios.CancelToken.source();
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/logs/smartLockEvents',
    cancelToken: smartLocksEventsCSVCancelToken.token,
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return executeRequestOrDefault(PERMISSION_ENTITIES.LOG, [PERMISSIONS.READ], requestData);
}

export function fetchSubcompaniesSmartLockEvents(params) {
  subcompaniesSmartLocksEventsCSVCancelToken = axios.CancelToken.source();
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/logs/smartLockEvents/shared',
    cancelToken: subcompaniesSmartLocksEventsCSVCancelToken.token,
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return executeRequestOrDefault(PERMISSION_ENTITIES.LOG, [PERMISSIONS.READ], requestData);
}

export function getSmartLocksEventsCancelToken() {
  return smartLocksEventsCSVCancelToken;
}

export function getSubcompaniesSmartLocksEventsCancelToken() {
  return subcompaniesSmartLocksEventsCSVCancelToken;
}

export function getAdminEventsCancelToken() {
  return adminEventsCSVCancelToken;
}
