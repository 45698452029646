import React, { Component } from 'react';
import RoleIcon from '@material-ui/icons/Security';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import { getColorFromThemeName } from '../../_config/utils';

export default class RoleRow extends Component {
  render() {
    const { isSelected, onClick, role, themeName } = this.props;
    const borderColor = getColorFromThemeName(themeName || 'default');
    return (
      <Card
        className={`card ${isSelected && 'card-selected'}`}
        style={{ padding: 20, paddingTop: 25, paddingBottom: 25, borderRadius: 10, borderLeft: `solid 4px ${borderColor}`  }}
        onClick={() => onClick()}
        elevation={3}
      >
        <div className="card-body">
          <RoleIcon style={{ color: '#3f3f3f', marginTop: 3, fontSize: 30 }} />
          <div className="card-inner-body">
            <h4 className="card-title" style={{ margin: 2, marginTop: 5, marginLeft: 0, fontSize: 20 }}>{role.label}</h4>
          </div>
        </div>
      </Card>
    );
  }
}
