'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends2 = require('@babel/runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('core-js/modules/es6.object.get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('@babel/runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('@babel/runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('@babel/runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('@babel/runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _DropdownButton = require('react-bootstrap/lib/DropdownButton');

var _DropdownButton2 = _interopRequireDefault(_DropdownButton);

var _DropdownButton3 = require('./DropdownButton');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DropdownHoverButton = (0, _DropdownButton3.DropdownHoverButtonHOC)(_class = function (_React$Component) {
  (0, _inherits3.default)(DropdownHoverButton, _React$Component);

  function DropdownHoverButton() {
    (0, _classCallCheck3.default)(this, DropdownHoverButton);
    return (0, _possibleConstructorReturn3.default)(this, (DropdownHoverButton.__proto__ || (0, _getPrototypeOf2.default)(DropdownHoverButton)).apply(this, arguments));
  }

  (0, _createClass3.default)(DropdownHoverButton, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(_DropdownButton2.default, (0, _extends3.default)({ open: this.props.open,
        onToggle: this.props.onToggle
      }, this.props.buttonProps));
    }
  }]);
  return DropdownHoverButton;
}(_react2.default.Component)) || _class;

exports.default = DropdownHoverButton;