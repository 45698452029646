// @ts-nocheck
import { Card, Checkbox, CircularProgress, createTheme, Popper, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import BarChartIcon from '@material-ui/icons/InsertChart';
import WarningIcon from '@material-ui/icons/Warning';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import { BLUE } from '../../../_config/consts';
import ScrollableBarChart from '../../Charts/ScrollableBarChart.jsx';
import AgendaIconCustom from '../../CustomIcons/AgendaIconCustom.jsx';


const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 15,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  header: {
    marginTop: -40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  refreshHeader: {
   position: 'absolute',
   right: 0,
   top: 0,
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center'
  },
  comparisonHeader: {
   position: 'absolute',
   right: 20,
   top: 0,
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center',
  },
  filterHeader: {
   position: 'absolute',
   left: 0,
   top: 0,
   minWidth: 200,
  },
  filterHeader2: {
   position: 'absolute',
   left: 210,
   top: 0,
   minWidth: 200,
  },
  filterHeader3: {
   minWidth: 200,
   marginLeft: 20,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
    color: '#3f3f3f',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAnalyticsView = () => (
  <div style={{ height: 290, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <BarChartIcon style={{ color: '#bdbdbd', fontSize: 50 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFound" /></h3>
  </div>
);

const metricsArray = [
  {
    value: 'RESERVATIONS_NUMBER',
    label: 'reservations',//L20NContext.getSync('reservations'),
  },
  {
    value: 'RESERVATIONS_DURATION',
    label: 'durationHours',//L20NContext.getSync('durationHours'),
  },
]

const scalesArray = [
  {
    value: 'TOTAL',
    label: 'total',//L20NContext.getSync('total'),
  },
  {
    value: 'AVERAGE',
    label: 'average',//L20NContext.getSync('average'),
  },
]

const segmentationArray = [
  {
    value: 'HOURLY',
    label: 'hourly',//L20NContext.getSync('hourly'),
  },
  {
    value: 'WEEKLY',
    label: 'weekly',//L20NContext.getSync('weekly'),
  },
  {
    value: 'MONTHLY',
    label: 'monthly',//L20NContext.getSync('monthly'),
  },
]

class BookeyHourlyReservationsChart extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      selectedMetric: null,
      selectedScale: null,
      selectedSegmentation: null,
      showDiff: false,
      warningHover: false,
      anchorEl: null,
    }
  }

  async componentWillMount() {
    this.setState({ selectedMetric: metricsArray[0] });
    this.setState({ selectedScale: scalesArray[0] });
    this.setState({ selectedSegmentation: segmentationArray[0] });
  }

  componentWillReceiveProps(nextProps) {
    
  }

  onSelectMetric(metric) {
    const {  } = this.props;
    this.setState({ selectedMetric: metric });
  }

  onSelectScale(scale) {
    const {  } = this.props;
    this.setState({ selectedScale: scale });
  }

  onSelectSegmentation(segmentation) {
    const {  } = this.props;
    const { selectedScale } = this.state;
    this.setState({ selectedSegmentation: segmentation });
    if (segmentation&&segmentation.value==='MONTHLY'&&selectedScale&&selectedScale.value==='AVERAGE')
      this.setState({ selectedScale: scalesArray[0] })
  }

  onElementClick(chartElement,chartData) {
    const { onDataClick } = this.props;
    if (chartElement && chartElement[0] && chartElement[0]['_index']!==undefined) {
      const elementIndex = _.isArray(chartElement) ? chartElement[0]['_index'] : chartElement['_index'];
      const elementName = chartData.labels[elementIndex];
      const allReservations = chartData.datasets.flatMap(dataset => 
        dataset.reservations[elementIndex] || []
      );
      if (onDataClick && allReservations) {
        onDataClick(allReservations, elementName);
      }
    }
  }

  render() {
    const { 
      reservationsHourlyChartData, hoursHourlyChartData, reservationsHourlyChartData_avg, hoursHourlyChartData_avg, 
      reservationsHourlyChartDataPrev, hoursHourlyChartDataPrev, reservationsHourlyChartData_avgPrev, hoursHourlyChartData_avgPrev, 
      reservationsDayChartData, hoursDayChartData, reservationsDayChartData_avg, hoursDayChartData_avg, 
      reservationsDayChartDataPrev, hoursDayChartDataPrev, reservationsDayChartData_avgPrev, hoursDayChartData_avgPrev, 
      reservationsMonthChartData, hoursMonthChartData, reservationsMonthChartData_avg, hoursMonthChartData_avg, 
      reservationsMonthChartDataPrev, hoursMonthChartDataPrev, reservationsMonthChartData_avgPrev, hoursMonthChartData_avgPrev, 
      lastUpdate, textColor, fullWidth, isRefreshing, classes, onRefreshMetric, forcedChartWidth, warningTextWeekly
    } = this.props;
    const { selectedMetric, selectedScale, selectedSegmentation, showDiff, warningHover, anchorEl, } = this.state;
    const chartWidth = forcedChartWidth? forcedChartWidth : fullWidth ? window.screen.width - 270 : window.screen.width - 520;

    let chartData = {};
    if (selectedMetric&&selectedScale&&selectedSegmentation) {
      if (showDiff) {
        if (selectedSegmentation.value==='HOURLY') {
          chartData = 
            selectedMetric.value==='RESERVATIONS_NUMBER'?(selectedScale.value==='TOTAL'?reservationsHourlyChartDataPrev:reservationsHourlyChartData_avgPrev):
            selectedMetric.value==='RESERVATIONS_DURATION'?(selectedScale.value==='TOTAL'?hoursHourlyChartDataPrev:hoursHourlyChartData_avgPrev):
            {}
        }
        if (selectedSegmentation.value==='WEEKLY') {
          chartData = 
            selectedMetric.value==='RESERVATIONS_NUMBER'?(selectedScale.value==='TOTAL'?reservationsDayChartDataPrev:reservationsDayChartData_avgPrev):
            selectedMetric.value==='RESERVATIONS_DURATION'?(selectedScale.value==='TOTAL'?hoursDayChartDataPrev:hoursDayChartData_avgPrev):
            {}
        }
        if (selectedSegmentation.value==='MONTHLY') {
          chartData = 
            selectedMetric.value==='RESERVATIONS_NUMBER'?(selectedScale.value==='TOTAL'?reservationsMonthChartDataPrev:reservationsMonthChartData_avgPrev):
            selectedMetric.value==='RESERVATIONS_DURATION'?(selectedScale.value==='TOTAL'?hoursMonthChartDataPrev:hoursMonthChartData_avgPrev):
            {}
        }
      }
      else {
        if (selectedSegmentation.value==='HOURLY') {
          chartData = 
            selectedMetric.value==='RESERVATIONS_NUMBER'?(selectedScale.value==='TOTAL'?reservationsHourlyChartData:reservationsHourlyChartData_avg):
            selectedMetric.value==='RESERVATIONS_DURATION'?(selectedScale.value==='TOTAL'?hoursHourlyChartData:hoursHourlyChartData_avg):
            {}
        }
        if (selectedSegmentation.value==='WEEKLY') {
          chartData = 
            selectedMetric.value==='RESERVATIONS_NUMBER'?(selectedScale.value==='TOTAL'?reservationsDayChartData:reservationsDayChartData_avg):
            selectedMetric.value==='RESERVATIONS_DURATION'?(selectedScale.value==='TOTAL'?hoursDayChartData:hoursDayChartData_avg):
            {}
        }
        if (selectedSegmentation.value==='MONTHLY') {
          chartData = 
            selectedMetric.value==='RESERVATIONS_NUMBER'?(selectedScale.value==='TOTAL'?reservationsMonthChartData:reservationsMonthChartData_avg):
            selectedMetric.value==='RESERVATIONS_DURATION'?(selectedScale.value==='TOTAL'?hoursMonthChartData:hoursMonthChartData_avg):
            {}
        }
      }
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1, marginRight: fullWidth ? 0 : 20 }} elevation={3}>
          <div className={classes.titleHeader} style={{ marginBottom: 20}}>
            <AgendaIconCustom style={{ width: 35, color: textColor || '#3f3f3f', marginRight: 10 }} />
            <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="reservationsTrend" /></h3>
            {warningTextWeekly && selectedSegmentation && selectedSegmentation.value==='WEEKLY' && 
              <WarningIcon 
                style={{ color: 'orangered', fontSize: 30, marginLeft: 20 }}
                onMouseEnter={(e) => { this.setState({ anchorEl: e.currentTarget, warningHover: true }) }}
                onMouseLeave={(e) => this.setState({ anchorEl: null, warningHover: false })}
              />
            }
            {warningTextWeekly && selectedSegmentation && selectedSegmentation.value==='WEEKLY'?(
              <Popper
                open={warningHover}
                anchorEl={anchorEl}
                disablePortal={true}
                placement='bottom'
                modifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: false,
                    boundariesElement: 'viewport',
                  }
                }}
                style={{ zIndex: 9999, paddingTop: 10, marginBottom: 10, maxWidth: 200 }}
              >
                <Card style={{ backgroundColor:BLUE, color: 'white', padding:10 }}>
                  {warningTextWeekly}
                </Card>
              </Popper>
            ):null}
            <div className={classes.comparisonHeader}>
              <h4 style={{ color: '#3f3f3f', margin: 0, fontWeight:'bold', fontSize: 16 }}>{<Entity entity="bookeyCompareWithPrevious"/>}</h4>
              <Checkbox
                checked={showDiff}
                size='medium'
                onChange={(e)=>{this.setState({ showDiff: e.target.checked})}}
                inputProps={{  }}
                style={{zoom:'150%'}}
                color='primary'
              />
            </div>
          </div>
          <div style={{display:'flex', flexDirection:'row', alignSelf:'self-start'}}>
            <div style={{minWidth: 200, marginRight:10, marginLeft:10}}>
              <Select
                value={selectedScale?{value: selectedScale.value, label: L20NContext.getSync(selectedScale.label)}:null}
                placeholder={<Entity entity="selectScale" />}
                noResultsText={<Entity entity="noResultsFound" />}
                onChange={val => this.onSelectScale(val)}
                options={scalesArray.map(e=>{return {value: e.value, label: L20NContext.getSync(e.label)}})}
                disabled={selectedSegmentation&&selectedSegmentation.value==='MONTHLY'}
                isClearable={false}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </div>
            <div style={{minWidth: 200, marginRight:10, marginLeft:10}}>
              <Select
                value={selectedMetric?{value: selectedMetric.value, label: L20NContext.getSync(selectedMetric.label)}:null}
                placeholder={<Entity entity="selectMetric" />}
                noResultsText={<Entity entity="noResultsFound" />}
                onChange={val => this.onSelectMetric(val)}
                options={metricsArray.map(e=>{return {value: e.value, label: L20NContext.getSync(e.label)}})}
                isClearable={false}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </div>
            <div style={{minWidth: 200, marginRight:10, marginLeft:10}}>
              <Select
                value={selectedSegmentation?{value: selectedSegmentation.value, label: L20NContext.getSync(selectedSegmentation.label)}:null}
                placeholder={<Entity entity="selectSegmentation" />}
                noResultsText={<Entity entity="noResultsFound" />}
                onChange={val => this.onSelectSegmentation(val)}
                options={segmentationArray.map(e=>{return {value: e.value, label: L20NContext.getSync(e.label)}})}
                isClearable={false}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </div>
          </div>
          <div style={{ paddingTop: 20 }}>
            {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
            {chartData && !_.isEmpty(chartData) ? (
              <ScrollableBarChart
                chartMaxHeight={350}
                chartMaxWidth={chartWidth}
                data={_.cloneDeep(chartData)}
                dataSetSize={24}
                showLegend={true}
                stackedBars={!showDiff}
                key={JSON.stringify(chartData)}
                onElementClick={(chartElement) => this.onElementClick(chartElement,chartData) }
              />
            ) : <EmptyAnalyticsView />}
          </div>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(BookeyHourlyReservationsChart);
