import InfoIcon from '@material-ui/icons/HelpOutline';
import { Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Field, change } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';
import MDCheckBoxGroupField from '../Fields/MDCheckBox/MDCheckBoxGroupField.js';
import { ORANGE, LANGUAGES } from '../../../_config/consts.js';
import MDRadioButtonView from './MDRadioButtonsGroup/MDRadioButtonView.js';
import { connect } from 'react-redux';
import { formatDomainHostNameToFriendlyName } from '../../../_config/utils';
import FormCardContainer from './FormCardContainer.jsx';
import DatePickerField from '../Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import TimePickerField from '../Fields/TimePicker/TimePickerField.jsx';
import { Divider } from '@material-ui/core';

const timeOptions = [
  {
    value: 1,
    label: <Entity entity="twentifourhours" />,
  },
  {
    value: 2,
    label: <Entity entity="moringTime" />,
  },
  {
    value: 3,
    label: <Entity entity="afternoonTime" />,
  },
  {
    value: 4,
    label: <Entity entity="moringAndAfternoon" />,
  },
];
@connect(state => ({ language: state.settings.language }))
class InvitationsTimeSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      timeOptionSelected: undefined,
    };
  }
  
  onDateCheckboxChange(value) {
    const { dispatch, formName } = this.props;
    this.setState({ timeOptionSelected: value });
    switch (value) {
      case 1: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().endOf('day').valueOf()));
        break;
      }
      case 2: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().startOf('day').set('hours', 13).valueOf()));
        break;
      }
      case 3: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').set('hours', 14).valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      case 4: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      default: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().endOf('day').valueOf()));
        break;
      }
    }
  }


  render() {
    const { canEdit, validityDateFrom, dispatch, formName, validityDateTo, validityTimeFrom, validityTimeTo, timeIntervalTo, timeIntervalFrom, daysString, language } = this.props;
    const { timeOptionSelected } = this.state;
    const options = _.map(moment.weekdays(true), (weekday) => ({
      label: weekday,
      value: moment().isoWeekday(weekday).isoWeekday(),
    }));
    const is12HoursFormat = language === LANGUAGES.ENGLISH;

    const validityDateFromString = moment(validityDateFrom);
    validityDateFromString.locale(moment().locale());
    const validityDateToString = moment(validityDateTo);
    validityDateToString.locale(moment().locale());
    const timeIntervalFromString = moment(timeIntervalFrom);
    timeIntervalFromString.locale(moment().locale());
    const timeIntervalToString = moment(timeIntervalTo);
    timeIntervalToString.locale(moment().locale());

    const validityTimeFromString = moment(validityTimeFrom);
    validityTimeFrom.locale(moment().locale());
    const validityTimeToString = moment(validityTimeTo);
    validityTimeTo.locale(moment().locale());

    return (
      <div>
        <input style={{ display: 'none' }} type="username" name="username" />
        <FormCardContainer
          title="invitationTimeAndDate"
          subtitle="invitationTimeAndDateSubtitle"
        >
          <div style={{ padding: 15, marginTop: 15, marginBottom: 15, display: 'flex', alignItems: 'center' }}>
            <h3 style={{ fontWeight: 'bold', color: '#3f3f3f', marginTop: 0, marginBottom: 20, marginRight: 53, alignSelf: 'flex-end', fontSize: 20 }}><Entity entity="checkIn" /></h3>
            <Field
              title={<Entity entity="date" />}
              id="validityDateFrom"
              name="validityDateFrom"
              onHandleChange={value => dispatch(change(formName, 'validityDateFrom', value))}
              component={DatePickerField}
              containerStyle={{ marginRight: 50 }}
              iconStyle={{ fontSize: 18 }}
              titleStyle={{ fontSize: 17 }}
              isOutsideRange={date => date.isBefore(moment().startOf('day'))}
            />
            <Field
              title={<Entity entity="invitationTimeIntervalFrom" />}
              name="validityTimeFrom"
              component={TimePickerField}
              iconStyle={{ fontSize: 20, marginRight: 5, color: '#3f3f3f' }}
              titleStyle={{ fontSize: 17, fontWeight: 'bold' }}
              containerStyle={{ marginTop: 3 }}
              onHandleChange={value => dispatch(change(formName, 'validityTimeFrom', value))}
            />
          </div>
          <Divider />
          <div style={{ padding: 15, marginTop: 15, marginBottom: 15, display: 'flex', alignItems: 'center' }}>
            <h3 style={{ fontWeight: 'bold', color: '#3f3f3f', marginTop: 0, marginBottom: 20, marginRight: 40, alignSelf: 'flex-end', fontSize: 20 }}><Entity entity="checkOut" /></h3>
            <Field
              id="validityDateTo"
              title={<Entity entity="date" />}
              name="validityDateTo"
              iconStyle={{ fontSize: 18 }}
              titleStyle={{ fontSize: 17 }}
              onHandleChange={value => dispatch(change(formName, 'validityDateTo', value))}
              component={DatePickerField}
              containerStyle={{ marginRight: 50 }}
              isOutsideRange={date => date.isBefore(moment().startOf('day'))}
            />
            <Field
              title={<Entity entity="invitationTimeIntervalTo" />}
              name="validityTimeTo"
              component={TimePickerField}
              iconStyle={{ fontSize: 20, marginRight: 5, color: '#3f3f3f' }}
              titleStyle={{ fontSize: 17, fontWeight: 'bold' }}
              containerStyle={{ marginTop: 3 }}
              onHandleChange={value => dispatch(change(formName, 'validityTimeTo', value))}
            />
          </div>
          {validityDateFrom && validityDateTo ? (
            <div style={{ paddingTop: 10, marginLeft: 5, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <InfoIcon style={{ color: '#3f3f3f', marginRight: 12, fontSize: 20 }} />
              <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: '#3f3f3f' }}>
                <Entity
                  entity="invitationValidityVerbalDescription"
                  key={`${validityDateFrom}-${validityDateTo}-${validityTimeFrom}-${validityTimeTo}`}
                  data={{
                    dateFrom: validityDateFromString.format('LL'),
                    dateTo: validityDateToString.format('LL'),
                    timeFrom: validityTimeFromString.format('LT'),
                    timeTo: validityTimeToString.format('LT'),
                  }}
                />
              </h5>
            </div>
        ) : null}
        </FormCardContainer>
        <FormCardContainer
          title="invitationCredentialTimeAndDate"
          subtitle="invitationCredentialTimeAndDateSubtitle"
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ marginLeft: 8, marginRight: 60 }}>
                <Entity
                  componentClass={Field}
                  title={<Entity entity="timeIntervalFrom" />}
                  name="timeIntervalFrom"
                  component={CustomField}
                  type="antdTimePicker"
                  is12HoursFormat={is12HoursFormat}
                  placeholder="Select a time"
                  disabled={!canEdit}
                />
              </div>
              <Entity
                componentClass={Field}
                title={<Entity entity="timeIntervalTo" />}
                name="timeIntervalTo"
                component={CustomField}
                is12HoursFormat={is12HoursFormat}
                type="antdTimePicker"
                placeholder="Select a time"
                disabled={!canEdit}
              />
              <div style={{ marginLeft: 30, marginTop: 20 }}>
                {_.map(timeOptions, timeOption => (
                  <MDRadioButtonView
                    key={timeOption.value}
                    checked={timeOptionSelected === timeOption.value}
                    value={(timeOption.value).toString()}
                    label={timeOption.label}
                    disabled={!canEdit}
                    handleChange={value => this.onDateCheckboxChange(timeOption.value)}
                  />
                ))}
              </div>
            </div>
            <Field
              name="daysOfTheWeek"
              title={<Entity entity="daysOfTheWeek" />}
              component={MDCheckBoxGroupField}
              containerstyle={{ paddingLeft: 15, marginTop: 10 }}
              horizontal
              options={options}
              disabled={!canEdit}
            />
            {validityDateFrom && validityDateTo && timeIntervalTo && timeIntervalFrom ? (
              <div style={{ paddingTop: 10, borderTop: '2px solid #157495', marginLeft: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <InfoIcon style={{ fontSize: 20, color: '#3f3f3f', marginRight: 12 }} />
                <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: '#3f3f3f' }}>
                  <Entity
                    entity="invitationCredentialVerbalDescription"
                    key={`${validityDateFrom}-${validityDateTo}-${timeIntervalFrom}-${timeIntervalTo}`}
                    data={{
                      dateFrom: validityDateFromString.format('LL'),
                      dateTo: validityDateToString.format('LL'),
                      timeFrom: timeIntervalFromString.format('LT'),
                      timeTo: timeIntervalToString.format('LT'),
                    }}
                  />
                  {!daysString ? '' : <span style={{ marginLeft: 5 }}><Entity entity="except" />{daysString}</span>}
                </h5>
              </div>
            ) : null}
          </div>
        </FormCardContainer>
      </div>
    );
  }
} 

export default InvitationsTimeSelector;
