import CheckIcon from '@material-ui/icons/CheckCircle';
import { Col, Grid, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import MDButton from '../../components/MDButton/MDButton.jsx';
import Card from '@material-ui/core/Card';
import ManagerIcon from '@material-ui/icons/Computer';
import SmartPhoneIcon from '@material-ui/icons/Smartphone';
import InstallerIcon from '@material-ui/icons/Build';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import Divider from '@material-ui/core/Divider';

@connect((state) => ({ routing: state.router }))
class ResetPassowordLanding extends React.Component {
  render() {
    const { dispatch } = this.props;
    return (
      <div id="auth-container" style={{ height: '100vh', display: 'flex', alignItems: 'center', backgroundColor: '#12637f', justifyContent: 'center', flexDirection: 'column', overflow: 'scroll' }} className="login">
        <Grid style={{ width: '100%' }}>
          <Row>
            <Col style={{ display: 'flex', flexDirection: 'column' }}>
              <img src="/imgs/common/logoJagoWhite.png" alt="Sofia" style={{ margin: 10, marginBottom: 15, alignSelf: 'center', height: 60 }} />
              <div style={{ maxWidth: 400, width: '100%', padding: 20, paddingTop: 0, paddingBottom: 0, alignSelf: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <CheckIcon style={{ alignSelf: 'center', marginBottom: 10, color: 'white', fontSize: 40 }} />
                <h3 style={{ marginTop: 5, textAlign: 'center', color: 'white' }}><Entity entity="passwordRecoverSuccess" /></h3>
                <Card
                  style={{ alignSelf: 'center', backgroundColor: '#009688', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderRadius: 10, cursor: 'pointer', width: 300, marginTop: 20, padding: 10, paddingRight: 5 }}
                  onClick={() => dispatch(push('/resetPassOnboardingGuest'))}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <SmartPhoneIcon style={{ fontSize: 20, color: 'white' }} />
                    <div>
                      <h5 style={{ fontSize: 12, textAlign: 'center', margin: 5, color: 'white' }}><Entity entity="continueAsGuestInstruction" /></h5>
                    </div>
                  </div>
                  <RightArrowIcon style={{ color: 'white', fontSize: 30 }} />
                </Card>
                <Divider style={{ height: 2, marginTop: 20, marginBottom: 10, backgroundColor: 'white' }} />
                <Card style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderRadius: 10, cursor: 'pointer', width: 300, marginTop: 20, padding: 10, paddingRight: 5 }} onClick={() => dispatch(push('/login'))}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <ManagerIcon style={{ fontSize: 20, color: '#3f3f3f' }} />
                    <div>
                      <h4 style={{ fontWeight: 'bold', fontSize: 12, textAlign: 'left', margin: 0, marginBottom: 5, marginLeft: 10, color: '#3f3f3f' }}><Entity entity="areYouAManager" /></h4>
                      <h5 style={{ fontSize: 12, textAlign: 'left', margin: 0, marginLeft: 10, color: '#3f3f3f' }}><Entity entity="goToWebPlatform" /></h5>
                    </div>
                  </div>
                  <RightArrowIcon style={{ color: '#3f3f3f', fontSize: 30 }} />
                </Card>
                <Card style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', borderRadius: 10, width: 300, marginTop: 20, padding: 10, paddingRight: 5 }} onClick={() => dispatch(push('/resetPassOnboardingInstaller'))}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <InstallerIcon style={{ fontSize: 20, color: '#3f3f3f' }} />
                    <h5 style={{ fontSize: 12, textAlign: 'left', margin: 0, marginLeft: 10, color: '#3f3f3f' }}><Entity entity="continueAsInstaller" /></h5>
                  </div>
                  <RightArrowIcon style={{ color: '#3f3f3f', fontSize: 30 }} />
                </Card>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
} 

export default ResetPassowordLanding;
