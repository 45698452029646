module.exports = {
  SAVE_REGISTRY_ITEMS: 'SAVE_REGISTRY_ITEMS',
  APPEND_REGISTRY_ITEMS: 'APPEND_REGISTRY_ITEMS',
  SAVE_REGISTRY_ITEM: 'SAVE_REGISTRY_ITEM',
  SET_ITEM_REGISTRY_PAGINATION_DATA: 'SET_ITEM_REGISTRY_PAGINATION_DATA',
  SELECT_REGISTRY_ITEM: 'SELECT_REGISTRY_ITEM',
  SET_ITEM_REGISTRY_FILTER: 'SET_ITEM_REGISTRY_FILTER',
  UPDATE_ITEM: 'UPDATE_ITEM',
  RESET_ITEM_REGISTRY_FILTERS: 'RESET_ITEM_REGISTRY_FILTERS',
  RESET_ITEM_REGISTRY_DATA: 'RESET_ITEM_REGISTRY_DATA',
  RESET_ITEM_PAGINATION_DATA: 'RESET_ITEM_PAGINATION_DATA',
  SET_ITEM_REGISTRY_OPERATIONAL_MODE: 'SET_ITEM_REGISTRY_OPERATIONAL_MODE',
};
