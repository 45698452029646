// @ts-nocheck
import UserIcon from '@material-ui/icons/AccountCircle';
import ProfileIcon from '@material-ui/icons/AssignmentInd';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import LogEventsList from '../LogEvents/LogEventsList.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { Card } from '@material-ui/core';
import { createChartDataFromLogs } from '../../_config/utils.js';
import ScrollableBarChart from '../Charts/ScrollableBarChart.jsx';

@connect((state) => ({ viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class GuestDashboardDetails extends React.Component {
  render() {
    const { guest, onOpenGuestProfile, onLockClick } = this.props;
    const chartData = guest && guest.guestEvents ? createChartDataFromLogs(guest.guestEvents): null;
    return (
      <div style={{ position: 'relative' }}>
        <Card style={{ padding: 10, position: 'absolute', left: 0, right: 0, backgroundColor: '#F8F9FA', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {guest ? (
            <div style={{ display: 'flex', paddingLeft: 15, alignItems: 'center' }}>
              <UserIcon style={{ fontSize: 40, color: '#3f3f3f' }} />
              {guest.invitationCode ? (
                <h3 style={{ margin: 0, marginLeft: 10, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="invitationCodeLog" data={{ code: guest.invitationCode }} /></h3>
                ) : (
                <div style={{ marginLeft: 20 }}>
                  <h3 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}>
                    {`${guest.firstname || '--'} ${guest.lastname || '--'}`}
                  </h3>
                  <h4 style={{ margin: 0, marginTop: 10, color: '#3f3f3f' }}>
                    {`${guest.email || '--'}`}
                  </h4>
                </div>
              )}
            </div>
          ) : null}
          {guest.invitationCode ? (
            null
          ) : (
            <MDButton
              icon={<ProfileIcon style={{ color: 'white', fontSize: 25, marginLeft: 10 }} />}
              containerStyle={{ width: '25%' }}
              title={<Entity entity="showGuestProfile" />}
              onClick={() => onOpenGuestProfile(guest)}
            />
            )}
        </Card>
        {chartData && !_.isEmpty(chartData) ? (
          <Card style={{ top: 99, paddingTop: 10, left: 0, right: 0, backgroundColor: 'white', position: 'absolute' }}>
            <ScrollableBarChart
              chartMaxHeight={150}
              chartMaxWidth={window.screen.width / 2}
              data={_.cloneDeep(chartData)}
              dataSetSize={24}
              showLegend={false}
            />
          </Card>
        ) : null}
        {guest ? (
          <LogEventsList
            eventType="DOOR"
            listStyle={{ paddingTop: 330, overflow: 'scroll' }}
            events={guest && guest.guestEvents}
            pagination={{ pagination: { number: 0, last: true } }}
            onLockClick={(lockId) => onLockClick(lockId)}
          />
        ) : null}
      </div>
    );
  }
} 

export default GuestDashboardDetails;
