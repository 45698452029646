import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';

const validate = values => {
  const errors = {};

  if (!values.orgSlug) {
    errors.orgSlug = 'required';
  }
  if (!values.clientId) {
    errors.clientId = 'required';
  }
  if (!values.clientSecret) {
    errors.clientSecret = 'required';
  }
  return errors;
};

@reduxForm({ form: 'AndcardsLoginForm', validate })
export default class AndcardsLoginForm extends React.Component {
  render() {
    return (
      <div>
        <Entity
          name="clientId"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertClientId"
          title="Spacebring Client ID"
          component={CustomField}
        />
        <Entity
          name="clientSecret"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertClientSecret"
          title="Spacebring Client Secret"
          component={CustomField}
        />
      </div>
    );
  }
}
