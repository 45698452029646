import _ from 'lodash';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';
import React from 'react';
import { DOOR_LOCK_EVENTS, DOOR_LOCK_EVENTS_MAP } from '../../../_config/consts';

export default class UserUpdatedEventRow extends React.Component {

  onActorClick() {
    const { event, onUserClick, onInvitationCodeClick } = this.props;
    const isInvitationEvent = event && !event.actorId && event.data && event.data.actor && event.data.actor.invitationCode;
    if (isInvitationEvent) {
      onInvitationCodeClick(event.data.actor.invitationCode);
    } else {
      onUserClick(event.actorId);
    }
  }

  render() {
    const { event, onLockClick } = this.props;
    const eventKey = DOOR_LOCK_EVENTS[event.eventType];
    const isLockGroupEvent = event.eventType === 'JAGO_7';
    let lock = event && event.data && event.data.lock && event.lockId ? `${event.data.lock.name} (${event.data.lock.serialNumber})` : '';
    lock = isLockGroupEvent ? event.data.lock.name : lock;

    const isUserEvent = event && event.actorId && event.data && event.data.actor;
    const isInvitationEvent = event && !event.actorId && event.data && event.data.actor && event.data.actor.invitationCode;
    let actor = isUserEvent ? `${event.data.actor.firstname} ${event.data.actor.lastname} - ${event.data.actor.email}` : event.description;
    actor = isInvitationEvent ? L20NContext.getSync('invitationCodeLog', { code: event.data.actor.invitationCode }) : actor;
    const deviceId = event && event.data && event.deviceId ? ` (#${event.deviceId})` : '';
    const isAnonymized = event && !event.data || (event.data && !event.data.actor && !event.data.lock);
    
    const isActorClickable = isUserEvent || isInvitationEvent;

    return !eventKey ? null : (
      <div className="log-row">
        {eventKey && DOOR_LOCK_EVENTS_MAP[eventKey] && (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {DOOR_LOCK_EVENTS_MAP[eventKey].icon}
              <div>
                <h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>
                  <Entity key={event.id} entity={DOOR_LOCK_EVENTS_MAP[eventKey].title} />
                </h3>
                <div>
                  {isAnonymized ? (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 15, marginLeft: -3 }}>
                      <WarningIcon style={{ fontSize: 20, color: '#efa638', marginRight: 5 }} />
                      <h4 style={{ color: '#efa638', margin: 0 }}>{<Entity entity="smartLockEventUserAnonymized" />}</h4>
                    </div>
                  ) : (
                    <div>
                      <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0, marginTop: 15 }}>
                        <Entity
                          key={event.id}
                          entity={DOOR_LOCK_EVENTS_MAP[eventKey].body}
                          data={{
                            lock: lock || '',
                            actor: isInvitationEvent ? L20NContext.getSync('withInvitationCodeLog', { code: event.data.actor.invitationCode }) : (actor || ''),
                          }}
                        />
                      </h4>
                      {actor && actor.length && (
                        <div
                          onClick={isActorClickable ? () => this.onActorClick() : null}
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15, cursor: isActorClickable ? 'pointer' : 'normal', width: 'fit-content' }}
                        >
                          <h5 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginRight: 5 }}>
                            <Entity entity={isActorClickable ? 'user' : 'actor'} />:
                          </h5>
                          <h5 style={{ color: '#3f3f3f', textDecoration: isActorClickable ? 'underline' : 'none', fontWeight: '100', margin: 0 }}>
                            {actor}{deviceId}
                          </h5>
                        </div>
                      )}
                      {lock ? (
                        <div
                          onClick={event.lockId && !isLockGroupEvent ? () => onLockClick(event.lockId) : null}
                          style={{ cursor: isLockGroupEvent ? 'normal' : 'pointer', display: 'inline-flex', flexDirection: 'row', alignItems: 'center', marginTop: 15 }}
                        >
                          <h5 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginRight: 5 }}>
                            <Entity entity="lock" />:
                          </h5>
                          <h5 className="smart-link" style={{ textDecoration: isLockGroupEvent ? 'none' : 'underline', color: '#3f3f3f', fontWeight: '100', margin: 0 }}>
                            {lock}
                          </h5>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <h4 style={{ minWidth: 240, textAlign: 'right', color: '#3f3f3f', marginTop: 0 }}>{moment(event.timestamp).format('LLL')}</h4>
          </div>
          )}
      </div>
    );
  }
}