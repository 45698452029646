import {
  Form
} from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { fetchGuestTags, fetchLockTags } from '../../_config/rest';
import ColorSelectorField from './Fields/ColorSelectorField.jsx';
import CustomField from './Fields/CustomField.jsx';
import TagCategorySelectorField from './Fields/TagCategorySelectorField.jsx';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  }

  if (!values.type) {
    errors.type = 'required';
  }

  if (!values.color) {
    errors.color = 'required';
  }

  return errors;
};

const asyncValidate = (values, dispatch, props) => {
  if (props.doAsyncValidate) {
    if (values.name) {
      if (props.tagType === 'lock') {
        return fetchLockTags({ name: values.name })
          .then((response) => {
            if (response.data && response.data.data && response.data.data.length) {
              if (_.find(response.data.data, { name: values.name })) {
                throw { name: <Entity entity="tagAlreadyPresent" /> };
              }
            }
          });
      } else if (props.tagType === 'guest') {
        return fetchGuestTags({ name: values.name })
          .then((response) => {
            if (response.data && response.data.data && response.data.data.length) {
              if (_.find(response.data.data, { name: values.name })) {
                throw { name: <Entity entity="tagAlreadyPresent" /> };
              }
            }
          });
      }
    }
  }
  return Promise.resolve();
};

@reduxForm({ form: 'TagForm', validate, asyncValidate, asyncBlurFields: ['name'] })
@connect(state => ({ form: state.form.TagForm }))
class TagForm extends React.Component {
  render() {
    const { form, categories, isDisabled } = this.props;
    return (
      <Form>
        <Entity
          componentClass={Field}
          name="name"
          componentAttribute="placeholder"
          entity="insertName"
          disabled={isDisabled}
          title={<Entity entity="name" />}
          component={CustomField}
          mandatory
        />
        <Entity
          componentClass={Field}
          name="type"
          componentAttribute="placeholder"
          entity="insertCategory"
          categories={categories}
          isCreatable
          isDisabled={isDisabled}
          title={<Entity entity="category" />}
          component={TagCategorySelectorField}
          mandatory
        />
        <Entity
          componentClass={Field}
          name="color"
          componentAttribute="placeholder"
          entity="insertColor"
          isDisabled={isDisabled}
          color={form.values ? form.values.color : '#d9e3f0'}
          title={<Entity entity="color" />}
          component={ColorSelectorField}
          mandatory
        />
      </Form>
    );
  }
} 

export default TagForm;
