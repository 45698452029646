import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import AdminEventsSearchBar from '../../components/forms/LogsEvents/AdminEventsSearchBar.jsx';
import LogEventsList from '../../components/LogEvents/LogEventsList.jsx';
import * as EventsActions from '../../redux/actions/logEvents.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { ADMIN_EVENTS_MAP, ADMIN_EVENT_TYPE_FILTERS, LICENSE_TYPES } from '../../_config/consts.js';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';

const eventTypesOptions = _.map(ADMIN_EVENT_TYPE_FILTERS, adminEvent => ({ entity: ADMIN_EVENTS_MAP[adminEvent].title, value: adminEvent }));
@connect((state) => ({
  adminEvents: state.logEvents.adminEvents,
  themeName: state.settings.items.theme.data.themeName,
  viewLoading: state.utils.viewLoading,
}))
class AdminEvents extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 1,
      filters: {
        text: '',
      },
      csvData: [
        ['Event', 'Description', 'Date'],
      ],
    };
  }
  async componentWillMount() {
    const { dispatch, avoidReloading } = this.props;
    if (!avoidReloading) {
      dispatch(EventsActions.resetAdminEventsFilters());
      dispatch(EventsActions.resetAdminEventsData());
      const initialFromDate = moment().subtract(1, 'month').valueOf();
      dispatch(change('AdminEventsSearchBar', 'fromDate', initialFromDate));
      dispatch(EventsActions.setAdminEventsFilter('fromDate', initialFromDate));
      await this.onFetchLogs();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { adminEvents: { content: newAdminEventsData } } = nextProps;
    const { adminEvents: { content: oldAdminEventsData } } = this.props;
    if (oldAdminEventsData !== newAdminEventsData) {
      const logsFormatted = [];
      const excelData = [];
      _.each(newAdminEventsData, (log) => {
        const eventInfo = ADMIN_EVENTS_MAP[log.eventType];
        if (eventInfo) {
          const type = L20NContext.getSync(eventInfo.title);
          const date = moment(log.timestamp).format('llll');
          const entity = log && log.data && log.data.entity ? `${log.data.entity.name || log.data.entity.deviceId || log.data.entity.entityId}` : '';
          const actor = log && log.data && log.data.actor && log.actorId ? `${log.data.actor.firstname} ${log.data.actor.lastname} - ${log.data.actor.email}` : '---';
          const description = L20NContext.getSync(eventInfo.body, { entity, actor });
          logsFormatted.push([type, description, date]);
          excelData.push({ Event: type, Description: description, Date: date });
        }
      });
      this.setState({ csvData: logsFormatted, excelData });
    }
  }

  onAppendLogs(page) {
    const { dispatch } = this.props;
    dispatch(EventsActions.fetchAndAppendAdminEvents(page));
  }

  async onFetchLogs() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(EventsActions.fetchAdminEvents());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onResetSearchFilters() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(EventsActions.resetAdminEventsData());
      dispatch(EventsActions.resetAdminEventsFilters());
      await dispatch(EventsActions.fetchAdminEvents());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onHandleFilerChange(field, value) {
    const { dispatch } = this.props;
    if (value) {
      dispatch(UtilsActions.setViewLoading(true));
      try {
        dispatch(EventsActions.setAdminEventsFilter(field, value));
        await dispatch(EventsActions.fetchAdminEvents());
        dispatch(UtilsActions.setViewLoading(false));
      } catch (error) {
        dispatch(UtilsActions.setViewLoading(false));
      }
    } else {
      this.onClearFilterField(field);
    }
  }

  async onClearFilterField(field) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(EventsActions.clearAdminEventsFilter(field));
      await dispatch(EventsActions.fetchAdminEvents());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  oneEportAdminEvents(format) {
    const { dispatch } = this.props;
    dispatch(EventsActions.exportAdminEvents(`luckey-admin-events-export-${moment().format('DD-MM-YYYY-HH:mm')}`, format));
  }

  render() {
    const { themeName, hideFilters, viewLoading, containerStyle, listContainerStyle, adminEvents: { content: adminEventsData, pagination: adminEventsPagination } } = this.props;
    const { csvData, excelData } = this.state;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    return (
      <div className="logs-main-container" style={{ marginTop: 0, backgroundColor: 'white', ...containerStyle }}>
      {!hideFilters && (
        <AdminEventsSearchBar
          themeName={themeName}
          csvData={csvData}
          excelData={excelData}
          numberOfEvents={adminEventsPagination.totalElements}
          eventTypesOptions={eventTypesOptions}
          onFilterChange={(field, value) => this.onHandleFilerChange(field, value)}
          onSearchReset={() => this.onResetSearchFilters()}
          onResetFilterField={field => this.onClearFilterField(field)}
          onFetchLogs={() => this.onFetchLogs()}
          exportAdminEvents={!isLuckeyLite ? format => this.oneEportAdminEvents(format) : null}
        />)}
        <div className="logs-container" style={{ marginTop: 120, overflow: 'auto', backgroundColor: 'white', ...listContainerStyle }}>
          <LogEventsList
            eventType="ADMIN"
            viewLoading={viewLoading}
            events={adminEventsData}
            pagination={adminEventsPagination}
            onLoadMore={page => this.onAppendLogs(page)}
          />
        </div>
      </div>
    );
  }
} 

export default AdminEvents;
