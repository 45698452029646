import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import SmartPhoneKeyIconCustom from '../../CustomIcons/SmartPhoneKeyIconCustom.jsx';

const AccessMethodSmartphone = ({ onClick }) => (
  <div
    style={{ cursor: 'pointer', padding: 5, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10, borderRadius: 5, marginLeft: 5, marginRight: 10 }}
    onClick={onClick ? () => onClick() : null}
  >
    <SmartPhoneKeyIconCustom style={{ width: 21, color: '#3f3f3f' }} />
    <h5 className="link-label" style={{ margin: 0, marginTop: 2, marginLeft: 10, color: '#3f3f3f' }}>
      <Entity entity="smartphone" />
    </h5>
  </div>
);

export default AccessMethodSmartphone;
