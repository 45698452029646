import { Observable } from 'rxjs';
import { resourceTypeActions } from '@bottega52/bookey-redux-module';
import {
  FETCH_RESOURCE_TYPES_TAGS_BY_FILTER,
  CANCEL_RESOURCES_TYPES_BY_FILTER,
} from '../redux/actions/actionTypes/resources';

export function fetchResourcesTypesByFilter(value, pageSize = 5) {
  return {
    type: FETCH_RESOURCE_TYPES_TAGS_BY_FILTER,
    value,
    pageSize,
  };
}

export function cancelFetchLockTagsByFilter() {
  return {
    type: CANCEL_RESOURCES_TYPES_BY_FILTER,
  };
}

export const fetchResourceTypesByFilterEpic = (action$, store) =>
  action$.ofType(FETCH_RESOURCE_TYPES_TAGS_BY_FILTER)
    .debounceTime(500)
    .switchMap((action) => {
      const { value, pageSize } = action;

      store.dispatch(resourceTypeActions.fetchResourceTypes(0, pageSize, { name: value }));
      return Observable.empty();
    })
    .takeUntil(action$.ofType(CANCEL_RESOURCES_TYPES_BY_FILTER))
    .catch(() => Observable.empty());
