import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { initialize, reduxForm, submit } from 'redux-form';
import { PERMISSION_ENTITIES, VALIDATION_MODES, VALIDATION_MODES_SELECTION_OPTIONS } from '../../../_config/consts';
import { hasFormWritePermission } from '../../../_config/utils.js';
import * as CardActions from '../../../redux/actions/card.actions.js';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import * as ModalsActions from '../../../redux/actions/modal.actions';
import MDButton from '../../MDButton/MDButton.jsx';
import StandardDevicesValidationFormDefault from '../../forms/StandardDeviceValidationForm/StandardDevicesValidationFormDefault.jsx';


const validate = (values) => {
  const errors = {};
  if (!values.validationMode || values.validationMode === '') {
    errors.validationMode = 'required';
  }
  return errors;
};

@connect((state) => ({ form: state.form.StandardDevicesValidationFormDefault, validationPeriodDefaults: state.cards.validationPeriodDefaults }))
class DevicesDefaultsSettingsOperationalSection extends React.Component {


  async componentWillMount() {
    const { dispatch  } = this.props;
    await dispatch(CardActions.getStandardDevicesValidationPeriodDefaults());
    const { validationPeriodDefaults } = this.props;
    const validationDTO = {
      validationPeriod: validationPeriodDefaults.validationPeriod,
      validationMode: validationPeriodDefaults.validationMode !== VALIDATION_MODES.ALWAYS_VALIDATED && validationPeriodDefaults.validationMode !== 'DEFAULT' ? VALIDATION_MODES_SELECTION_OPTIONS.TIME_CONSTRAINED_VALIDATION : validationPeriodDefaults.validationMode,
      validationPeriodTimeUnit: validationPeriodDefaults.validationMode,
    };
    dispatch(initialize('StandardDevicesValidationFormDefault', validationDTO));
  }

  async saveDefaults(values) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CardActions.updateStandardDevicesValidationPeriodDefaults(values));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalsActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingsSavedSuccessfully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalsActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingsSavedError" /></h6>),
        },
      }));
    }
  }

  render() {
    const { dispatch, form } = this.props;
    return (
      <div style={{ paddingLeft: 0, paddingRight:0 }}>
        <StandardDevicesValidationFormDefault
          onSubmit={(validationData) => this.saveDefaults(validationData)}
        />
        {hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, true) && (
          <MDButton
            title={<Entity entity="save" />}
            containerStyle={{ width: '80%', margin: 'auto', marginTop: 30 }}
            style={{ height: 45, borderRadius: 0 }}
            onClick={() => dispatch(submit('StandardDevicesValidationFormDefault'))}
          />
        )}
      </div>
    );
  }
} 

export default DevicesDefaultsSettingsOperationalSection;
