import React from 'react';
import { connect } from 'react-redux';
import { submit, initialize, change } from 'redux-form';
import _ from 'lodash';
import ThemeForm from '../../forms/ThemeForm.jsx';
import * as SettingsActions from '../../../redux/actions/setting.actions';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions.js';
import { THEMES, BLUE, PERMISSION_ENTITIES, PERMISSIONS } from '../../../_config/consts';
import MDButton from '../../MDButton/MDButton.jsx';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';

@connect((state) => ({ form: state.form.ThemeForm, themeData: state.settings.items.theme.data }))
class ThemeSettingsOperationalSection extends React.Component {


  componentWillMount() {
    const { dispatch, setting } = this.props;
    dispatch(SettingsActions.fetchSettingsByType(setting.name))
    .then((response) => {
      dispatch(initialize('ThemeForm', response));
    });
  }

  async saveThemeSettings(values) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(SettingsActions.updateSettings(values));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateSuccessfully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateSuccessfully" /></h6>),
        },
      }));
    }
  }

  onFileRejected() {
    const { dispatch } = this.props;
    dispatch(ModalActions.showModal({
      modalType: 'ERROR_MODAL',
      modalProps: { type: 'FILE_TOO_LARGE' },
    }));
  }

  onDeleteFile(file) {
    const { dispatch } = this.props;
    dispatch(change('ThemeForm', 'image', null));
  }

  render() {
    const { dispatch, form, themeData } = this.props;
    const canEditCompanyData = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.COMPANY);

    const oldLogo = themeData.logo;
    let imagePreview = form && form.values && form.values.image;
    if (imagePreview && _.isArray(imagePreview)) {
      imagePreview = imagePreview[0].preview;
    } else if (imagePreview && _.isString(imagePreview)) {
      imagePreview = `data:image/jpeg;base64,${form.values.image}`;
    } else if (imagePreview) {
      imagePreview = form.values.image.preview;
    }
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        <ThemeForm
          themes={THEMES}
          onDeleteFile={(file) => this.onDeleteFile(file)}
          onFileRejected={() => this.onFileRejected()}
          imagePreview={imagePreview || oldLogo}
          onSubmit={values => this.saveThemeSettings(values)}
        />
        {canEditCompanyData && (
          <MDButton
            containerStyle={{ marginTop: 40 }}
            backgroundColor={BLUE}
            title={<Entity entity="save" />}
            onClick={() => dispatch(submit('ThemeForm'))}
          />
        )}
      </div>
    );
  }
} 

export default ThemeSettingsOperationalSection;
