import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, submit } from 'redux-form';
import * as ModalActions from '../../redux/actions/modal.actions.js';
import MDButton from '../MDButton/MDButton.jsx';
import BookeyMapForm from '../forms/Bookey/BookeyMapForm.jsx';
import { BLUE, PERMISSIONS, THEMES } from '../../_config/consts.js';

@connect((state) => ({ form: state.form.BookeyMapForm, themeData: state.settings.items.theme.data }))
class ResourceMapOperationalSection extends React.Component {

  onFileRejected() {
    const { dispatch } = this.props;
    dispatch(ModalActions.showModal({
      modalType: 'ERROR_MODAL',
      modalProps: { type: 'FILE_TOO_LARGE' },
    }));
  }

  onDeleteFile(file) {
    const { dispatch } = this.props;
    dispatch(change('BookeyMapForm', 'data', null));
  }

  render() {
    const { dispatch, form, onSubmitMapForm } = this.props;

    let imagePreview = form && form.values && form.values.data;
    if (imagePreview && _.isArray(imagePreview)) {
      imagePreview = imagePreview[0].preview;
    } else if (imagePreview && _.isString(imagePreview)) {
      imagePreview = `data:image/jpeg;base64,${form.values.data}`;
    } else if (imagePreview) {
      imagePreview = form.values.data.preview;
    } else if (imagePreview) {
      imagePreview = form.values.data.link;
    }
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        <BookeyMapForm
          themes={THEMES}
          onDeleteFile={(file) => this.onDeleteFile(file)}
          onFileRejected={() => this.onFileRejected()}
          imagePreview={imagePreview}
          onSubmit={values => onSubmitMapForm(values)}
        />
        <MDButton
          containerStyle={{ marginTop: 40 }}
          backgroundColor={BLUE}
          title={<Entity entity="save" />}
          onClick={() => dispatch(submit('BookeyMapForm'))}
        />
      </div>
    );
  }
} 

export default ResourceMapOperationalSection;
