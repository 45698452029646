import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import Alert from './Alert.jsx';

export default class SuccessModal extends React.Component {
  hideModal() {
    const { hideModal } = this.props;
    hideModal();
  }

  render() {
    const { type, title, message } = this.props;

    return (
      <Alert
        type="success"
        visible
        onOutsideClick={() => this.hideModal()}
        title={title ? <Entity entity={title} /> : <Entity entity="wellDone" />}
        message={<Entity entity={message} />}
        onConfirm={() => this.hideModal()}
      />
    );
  }
}
