import Card from '@material-ui/core/Card';
import InfoIcon from '@material-ui/icons/HelpOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import * as LogEventsActions from '../../redux/actions/logEvents.actions';
import SmartLocksEvents from '../../routes/LogEvents/SmartLocksEvents.jsx';

@connect((state) => ({ }))
class CardEventsView extends React.Component {
  componentDidMount() {
    const { dispatch, card } = this.props;
    dispatch(LogEventsActions.resetSmartLocksEventsFilters());
    const initialFromDate = moment().subtract(1, 'month').valueOf();
    dispatch(LogEventsActions.setSmartLocksEventsFilter('fromDate', initialFromDate));
    dispatch(LogEventsActions.setSmartLocksEventsFilter('deviceId', card.deviceId));
    dispatch(LogEventsActions.fetchSmartLocksEvents());
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <Card style={{ backgroundColor: 'gold', position: 'absolute', top: 0, right: 0, left: 0, padding: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          <InfoIcon style={{ fontSize: 18, color: '#3f3f3f' }} />
          <h5 style={{ color: '#3f3f3f', margin: 0, textAlign: 'center', marginLeft: 10 }}>
            <Entity entity="cardEventsWarning" />
          </h5>
        </Card>
        <div style={{ padding: 40 }}>
          <SmartLocksEvents
            hideFilters
            avoidReloading
            containerStyle={{ marginTop: 0 }}
            listContainerStyle={{ marginTop: 0, marginLeft: -20, marginBottom: 80, paddingLeft: 0 }}
          />
        </div>
      </div>
    );
  }
} 

export default CardEventsView;
