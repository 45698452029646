import React from 'react';
import GuestsTagsManagementRow from './TagsManagementListsComponents/GuestsTagsManagementRow.jsx';
import LocksTagsManagementRow from './TagsManagementListsComponents/LocksTagsManagementRow.jsx';

const rowComponents = {
  guests: GuestsTagsManagementRow,
  locks: LocksTagsManagementRow,
};

export default class TagsManagementList extends React.Component {
  render() {
    const { tagRowType } = this.props;
   
    const Component = rowComponents[tagRowType || 'guest'];

    return (
      Component ? <Component {...this.props} /> : null
    );
  }
}
