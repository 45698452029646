/* eslint-disable no-await-in-loop */
import _ from 'lodash';
import * as FloorsPlansAPI from '../../_config/floorPlansAPI';
import {
  SAVE_FLOOR_PLANS,
  APPEND_FLOOR_PLANS,
  RESET_FLOOR_PLANS_DATA,
  RESET_FLOOR_PLANS_FILTERS,
  RESET_FLOOR_PLANS_PAGINATION_DATA,
  SELECT_FLOOR_PLAN,
  SET_FLOOR_PLANS_FILTER,
  SET_FLOOR_PLANS_OPERATIONAL_MODE,
  SET_FLOOR_PLANS_PAGINATION_DATA,
  SAVE_OR_UPDATE_FLOOR_PLAN
} from './actionTypes/floorplans';

export function setFloorPlanOperationalMode(value) {
  return {
    type: SET_FLOOR_PLANS_OPERATIONAL_MODE,
    value,
  };
}

export function saveFloorPlans(floorPlans) {
  return {
    type: SAVE_FLOOR_PLANS,
    floorPlans,
  };
}

export function appendFloorPlans(floorPlans) {
  return {
    type: APPEND_FLOOR_PLANS,
    floorPlans,
  };
}

export function saveFloorPlansPaginationData(pagination) {
  return {
    type: SET_FLOOR_PLANS_PAGINATION_DATA,
    pagination,
  };
}

export function setFloorPlansFilter(field, value) {
  return {
    type: SET_FLOOR_PLANS_FILTER,
    field,
    value,
  };
}

export function setSelectedFloorPlan(floorPlan) {
  return {
    type: SELECT_FLOOR_PLAN,
    floorPlan,
  };
}

export function saveOrUpdateFloorPlanOnState(floorPlan) {
  return {
    type: SAVE_OR_UPDATE_FLOOR_PLAN,
    floorPlan,
  };
}

export function resetFloorPlanData() {
  return {
    type: RESET_FLOOR_PLANS_DATA,
  };
}

export function resetFloorPlanFilters() {
  return {
    type: RESET_FLOOR_PLANS_FILTERS,
  };
}

export function resetFloorPlanPagination() {
  return {
    type: RESET_FLOOR_PLANS_PAGINATION_DATA,
  };
}

export function fetchFloorPlans(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().floorPlans.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const floorPlansResponse = await FloorsPlansAPI.fetchFloorPlans(params);
      if (floorPlansResponse && floorPlansResponse.data && floorPlansResponse.data.content) {
        dispatch(saveFloorPlans(floorPlansResponse.data.content));
        dispatch(saveFloorPlansPaginationData(_.omit(floorPlansResponse.data, 'content')));
        return floorPlansResponse.data.content;
      }
      throw new Error();

    } catch (error) {
      throw error;
    }
  }
}

export function fetchAndAppendFloorPlans(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().floorPlans.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const floorPlansResponse = await FloorsPlansAPI.fetchFloorPlans(params);
      if (floorPlansResponse && floorPlansResponse.data && floorPlansResponse.data.content) {
        dispatch(appendFloorPlans(floorPlansResponse.data.content));
        dispatch(saveFloorPlansPaginationData(_.omit(floorPlansResponse.data, 'content')));
        return floorPlansResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createFloorPlan(floorPlanData) {
  return async (dispatch, getState) => {
    try {
      const createResponse = await FloorsPlansAPI.createFloorPlan(floorPlanData.name, floorPlanData.file);
      if (createResponse && createResponse.data) {
        return createResponse.data;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function updateFloorPlan(floorPlanId, floorPlanData) {
  return async (dispatch, getState) => {
    try {
      const updateResponse = await FloorsPlansAPI.updateFloorPlanDisplayName(floorPlanId, floorPlanData.displayName);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteFloorPlan(floorPlanId) {
  return async (dispatch, getState) => {
    try {
      const deleteResponse = await FloorsPlansAPI.deleteFloorPlan(floorPlanId);
      if (deleteResponse && deleteResponse.data) {
        return deleteResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}