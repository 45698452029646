import Card from '@material-ui/core/Card';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import DropFileField from '../Fields/DropFileField.jsx';
import ImagePickerField from '../Fields/ImagePickerField.jsx';


const validate = (values) => {
  const errors = [];
  if (values) {
    if (!values.media && !values.customImage) {
      errors.media = 'required';
      errors.customImage = 'required';
    }
  }
  return errors;
};


@reduxForm({ form: 'ImageSelectorForm', validate })
@connect(state => ({ form: state.form.ImageSelectorForm }))
class ImageSelectorForm extends React.Component {

  render() {
    const { form, images, previewTitle, previewDescription, title, subtitle, onFileRejected, onDeleteFile } = this.props;
    let imagePreview = form && form.values && form.values.media;
    let customImagePreview = form && form.values && form.values.customImage;
    if (customImagePreview && _.isArray(customImagePreview)) {
      imagePreview = customImagePreview[0].preview;
    } else if (customImagePreview && _.isString(customImagePreview)) {
      imagePreview = `data:image/jpeg;base64,${form.values.customImage}`;
    } else if (customImagePreview) {
      imagePreview = form.values.media.preview;
    }  else if (form && form.values && form.values.media) {
      imagePreview = images[form.values.media];
    }
    return (
      <Form>
          <FormCardContainer
            title="uploadCustomImage"
            subtitle={subtitle}
            hideCard
          >
          <Field
            name="customImage"
            accept="image/*"
            className="drop-file-container"
            activeClassName="drop-file-container-active"
            maxSize={1000000}
            component={DropFileField}
            onFileRejected={() => onFileRejected()}
            onDeleteFile={f => onDeleteFile(f)}
          >
            <h5 style={{ marginBottom: 5 }}><Entity entity="dropFile" /></h5>
            <h5 style={{ marginTop: 0, marginBottom: 0 }}><Entity entity="orSelect" /></h5>
            <h5 style={{ marginTop: 5 }}>{'(Max 1MB)'}</h5>
          </Field>
        </FormCardContainer>
        <FormCardContainer
          title={title || 'loadAnImage'}
          subtitle={subtitle}
          hideCard
        >
          <Field
            name="media"
            component={ImagePickerField}
            isSingleChoice
            images={images}
          />
        </FormCardContainer>
        {imagePreview &&
        <div>
          <hr style={{ borderTop: '2px solid #eee' }} />
          <FormCardContainer
            title="preview"
            subtitle="resourceImagePreviewSubtitle"
            hideCard
          >
            <Card className="card-noHover" style={{ padding: 0, display: 'flex', flexDirection: 'column', margin: 'auto', maxWidth: '70%', justifyContent:'flex-start', alignItems: 'center' }}>
              <div style={{ maxHeight: 150, overflow: 'hidden', width: '100%' }}>
                <img alt="previee" src={imagePreview} style={{ marginTop: -100, width: '100%', height: 'auto' }} />
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', padding: 15, flexDirection: 'column', alignItems: 'flex-start' }}>
                <h4 style={{ color: '#3f3f3f' }}>{previewTitle || <Entity entity="previewTitle" />}</h4>
                <p style={{ color: '#3f3f3f', fontSize: 14 }}>{previewDescription || <Entity entity="previewSubtitle" />}</p>
              </div>
            </Card>
          </FormCardContainer>
          </div>
          }
      </Form>
    );
  }
} 

export default ImageSelectorForm;
