import { Card, createTheme, IconButton, withStyles } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React, { Component } from 'react';
import { BLUE, SMARTLOCK_DOOR_STATUS_MAP, SMARTLOCK_DOOR_STATUS, GREEN } from '../../../_config/consts';
import { getLockImage } from '../../../_config/utils';
import DoorSensorIconCustom from '../../CustomIcons/DoorSensorIconCustom.jsx';


const styles = theme => ({
  titleText: {
    margin: 0,
    color: '#3f3f3f',
    marginLeft: 10,
    fontWeight: 'bold',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'scroll',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    marginTop: -5,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    margin: 10,
    borderRadius: 10,
    justifyContent: 'center',
    width: 'fit-content',
    paddingLeft: 15,
    paddingRight: 15,
  },
  icon: {
    width: 30,
    color: '#3f3f3f',
  },
  nameText: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    margin: 0,
    marginBottom: 5,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '38px !important',
    width: '38px !important',
  },
  chartsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  refreshButton: {
    fontSize: 15,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  refreshIcon: {
    fontSize: 25,
    color: '#3f3f3f',
  },
  separator: {
    margin: 10,
    marginBottom: 0,
    height: 2,
  },
  linkText: {
    textAlign: 'center',
    paddingTop: 12,
    margin: 0,
    paddingBottom: 4,
    width: '100%',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

class StatusSensorMetricChart extends Component {
  render() {
    const { accessoriesChartData, isRefreshing, onRefreshMetric,textColor, classes, onOpenLockDetails } = this.props;
    return (
      <div>
        <div className={classes.titleContainer}>
        <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="doorStatusChartTitle" /></h3>
          <IconButton
            disabled={isRefreshing}
            style={{ opacity: isRefreshing ? 0.5 : 1 }}
            className={classes.refreshButton}
            onClick={(e) => {
              e.stopPropagation();
              onRefreshMetric();
            }}
          >
            <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || '#3f3f3f' }} />
          </IconButton>
        </div>
        <div className={classes.gridContainer} style={{ opacity: isRefreshing ? 0.5 : 1 }}>
          {_.map(accessoriesChartData, (data) => {
            const doorStatusVariables = data.sensor && data.sensor.value && SMARTLOCK_DOOR_STATUS_MAP[data.sensor.value] ? SMARTLOCK_DOOR_STATUS_MAP[data.sensor.value] : null;
            const showDoorStatus = doorStatusVariables && data.sensor;
            const lockAssociated = data.lock;
            if (!showDoorStatus) return null;
            return (
              <Card
                className={`${classes.cardContainer} ${lockAssociated && lockAssociated.id ? 'card card-rounded' : 'card-noHover'}`}
                style={{ cursor: lockAssociated && lockAssociated.id ? 'pointer' : 'normal', backgroundColor: data.sensor.value === SMARTLOCK_DOOR_STATUS.OPENED ? '#ffc4c4' : '', zIndex: 100 }}
                key={lockAssociated ? lockAssociated.id : data.sensor.name}
                onClick={lockAssociated && lockAssociated.id ? () => onOpenLockDetails(lockAssociated.id) : null}
                elevation={3}
              >
                {lockAssociated ? (
                  <img alt="lock" className="lock-icon" style={{ width: 45, height: 45, marginRight: 15 }} src={getLockImage(lockAssociated.model)} />
                ) : (
                    <DoorSensorIconCustom style={{ width: 40, height: 40, marginRight: 15, marginTop: 2, color: '#3f3f3f' }} />
                  )}
                <div>
                  <h4 className={classes.nameText}>
                    {lockAssociated ? data.lock.name : data.sensor.name}
                  </h4>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 15 }}>
                    {doorStatusVariables && doorStatusVariables.icon}
                    <Entity key={doorStatusVariables.title} style={{ fontSize: 12, marginLeft: 5, fontWeight: 'bold', color: doorStatusVariables.color }} entity={doorStatusVariables.title} />
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(StatusSensorMetricChart);
