import { withStyles } from '@material-ui/core';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { BLUE, GREEN } from '../../_config/consts';
import { convertCSVToJSON, generateTemplateCSV, sanitizeUserImportTagsSections } from '../../_config/utils';
import * as TagActions from '../../redux/actions/tag.actions';
import * as TagManagemetActions from '../../redux/actions/tagsManagement.actions.js';
import * as UtilsActions from '../../redux/actions/utils.actions';
import ExcelIconCustom from '../CustomIcons/ExcelIconCustom.jsx';
import ImportGuestsCSVForm from '../forms/ImportGuestsCSVForm.jsx';
import ImportGuestsCSVMissingTagsView from './ImportGuestsCSVMissingTagsView.jsx';


const styles = {
  boldText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  warningContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  warningIcon: {
    marginRight: 10,
  },
  descriptionText: {
    color: '#3f3f3f',
  }
};

@connect((state) => ({ availableUserTagsForSelect: state.tags.user.data, systemUserTags: state.tagsManagement.userTags.content, credential: state.credentials.selectedCredential,}))
class ImportGuestsCSV extends React.Component {  
  
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      tagsToCreate: [],
    };
  }

  onDownloadCSVTemplate() {
    const { dispatch } = this.props;
    generateTemplateCSV();
  }

  async onImportGuestsCSV(fileData, users) {
    const { onImportGuests } = this.props;
    const additionalTags = fileData.guestTags;
    // TODO CONVERT USERS TO CSV FILE INSTEAD OF USING THE ORIGINAL FILE
    let tagsName = '';
    _.map(additionalTags, additionalTag => tagsName += `,${additionalTag.name}`); // Format new tags
    const newUsers = _.map(users, (user) => {
      const userTags = user.tags || '';
      const newUserTags = tagsName ? `${userTags},${tagsName}` : userTags;
      return (
      {
        ..._.omit(user, 'tags'),
        tags: newUserTags,
        roles: 'ROLE_GUEST',
        phone: '',
      });
    });
    onImportGuests(newUsers);
  }


  onFilterGuestTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchSpecialGuestTagsByFilter(value, 50, includeSpecialTags));
  }

  onDeleteFile() {
    const { dispatch } = this.props;
    dispatch(change('ImportGuestsCSVForm', 'csvFile', null));
    this.setState({ users: [] });
  }

  //For every user check if tag exists and add it to the user or 
  onAnalizeUserTagsToCreate(users) {
    const { systemUserTags } = this.props;
    const tagsToCreate = [];
    const formattedUsers = [];
    try {
        _.map(users, user => {
        const userTags = user && user.tags ? _.split(user.tags, ',') : [];
        let formattedUser = user;
        _.map(userTags, tag => {
          const formattedTag = _.trim(tag);
          const existingTag = _.find(systemUserTags, systemTag => systemTag.name.toLowerCase() === formattedTag.toLowerCase());
          if (existingTag) {
            // if tag exists add it to the user DTO as Tag DTO
            const userTagsToUpdate = formattedUser.userSpecialTags || [];
            userTagsToUpdate.push(existingTag);
            formattedUser = ({ ...formattedUser, userSpecialTags: userTagsToUpdate  });
          } else {
            // if tags doesn't exists save it in a separate array with the user reference
            tagsToCreate.push({ name: formattedTag, color: BLUE, type: L20NContext.getSync('users'), userEmail: user.email });
          }
        })

        // reformat the tags to avoid user mistakes on the file (removing spaces, etc)
        formattedUser = { ...formattedUser, tags: user && user.tags ? sanitizeUserImportTagsSections(user.tags) : []}
        formattedUsers.push(formattedUser);
      })
    } catch (error) {
      console.log(error);
    }
    this.setState({ users: formattedUsers, tagsToCreate });
  }

  async onFileAccepted(file) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const users = await convertCSVToJSON(file[0]);
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onAnalizeUserTagsToCreate(users);
      //console.log(users)
      //this.setState({ users });
    } catch (error) {
      // ERRRO MODAL
    }
  }

  onRemoveUser(userToRemove) {
    const { users } = this.state;
    const finalUsers = _.filter(users, user => user.username !== userToRemove.username);
    this.setState({ users: finalUsers });
  }
  
  onTagsSelected(tags) {
    // TODO Nothing to do here
  }

  async onCreateMissingTags() {
    const { tagsToCreate, users } = this.state;
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(TagManagemetActions.createBatchUserTags(tagsToCreate));
      await dispatch(TagManagemetActions.fetchAllGuestSpecialTags());
      this.setState({ users: [] });
      this.onAnalizeUserTagsToCreate(users);
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onRemoveTagToCreate(tagName) {
    const { tagsToCreate } = this.state;
    const finalTagsToCreate = _.filter(tagsToCreate, tag => tag.name !== tagName);
    this.setState({ tagsToCreate: finalTagsToCreate });
  }

  render() {
    const { availableUserTagsForSelect } = this.props;
    const { users, tagsToCreate } = this.state;
    return (
      <div style={{ padding: 15, paddingTop: 0 }}>
        {!tagsToCreate || _.isEmpty(tagsToCreate) ? (
          <div>
            <ImportGuestsCSVForm
              users={users}
              availableUserTags={availableUserTagsForSelect}
              tagsToCreate={tagsToCreate}
              onSubmit={(fileData) => this.onImportGuestsCSV(fileData, users)}
              onGuestInputChange={value => this.onFilterGuestTags(value)}
              onFileAccepted={(file) => this.onFileAccepted(file)}
              onDeleteFile={() => this.onDeleteFile()}
              onRemoveUser={user => this.onRemoveUser(user)}
              onTagsSelected={tags => this.onTagsSelected(tags)}
            />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginLeft: 10 }}>
              <ExcelIconCustom style={{ width: 25, color: GREEN }} />
              <h4 onClick={() => this.onDownloadCSVTemplate()} className="label-responsive link-label" style={{ margin: 0, color: GREEN, fontWeight: 'bold', fontSize: 16, marginLeft: 10 }}>
                <Entity entity="downloadCSVTemplate" />
              </h4>
            </div>
          </div>
        ) : (
          <ImportGuestsCSVMissingTagsView
            tagsToCreate={tagsToCreate}
            onRemoveTag={tagName => this.onRemoveTagToCreate(tagName)}
            onConfirmCreateTags={() => this.onCreateMissingTags()}
            onCancelCreateTags={() => this.setState({ tagsToCreate: [] })}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ImportGuestsCSV);
