import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';

const validate = values => {
  const errors = {};
  if (!values.codeClub) {
    errors.codeClub = 'required';
  }
  return errors;
};

@reduxForm({ form: 'TenupActivationForm', validate })
class TenupActivationForm extends React.Component {
  
  render() {
    return (
      <div>
        <Entity
          name="codeClub"
          componentClass={Field}
          componentAttribute="placeholder"
          entity=""
          title="Ten'Up Club Id"
          component={CustomField}
        />
      </div>
    );
  }
} 

export default TenupActivationForm;
