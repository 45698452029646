import React from 'react';
import { connect } from 'react-redux';
import Spinner from './Spinner.jsx';

@connect(state => ({ spinnerVisible: state.utils.spinnerVisible }))
class SpinnerOverlay extends React.Component {
  render() {
    const { spinnerVisible } = this.props;
    return (
      <span>
        {spinnerVisible &&
          <div className="spinner-overlay">
            <Spinner className="inner-spinner-overlay" />
          </div>
        }
      </span>
    );
  }
} 

export default SpinnerOverlay;
