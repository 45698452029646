
  module.exports = {
    SAVE_ADMIN_EVENTS: 'SAVE_ADMIN_EVENTS',
    SAVE_ADMIN_EVENTS_PAGINATION: 'SAVE_ADMIN_EVENTS_PAGINATION',
    RESET_ADMIN_EVENTS: 'RESET_ADMIN_EVENTS',
    SET_ADMIN_EVENT_FILTERS: 'SET_ADMIN_EVENT_FILTERS',
    RESET_ADMIN_EVENT_FILTERS: 'RESET_ADMIN_EVENT_FILTERS',
    SAVE_SMART_LOCKS_EVENTS: 'SAVE_SMART_LOCKS_EVENTS',
    SAVE_SMART_LOCKS_EVENTS_PAGINATION: 'SAVE_SMART_LOCKS_EVENTS_PAGINATION',
    RESET_SMART_LOCKS_EVENTS: 'RESET_SMART_LOCKS_EVENTS',
    SET_SMART_LOCKS_EVENT_FILTER: 'SET_SMART_LOCKS_EVENT_FILTER',
    RESET_SMART_LOCKS_EVENT_FILTERS: 'RESET_SMART_LOCKS_EVENT_FILTERS',
    APPEND_ADMIN_EVENTS: 'APPEND_ADMIN_EVENTS',
    APPEND_SMART_LOCKS_EVENTS: 'APPEND_SMART_LOCKS_EVENTS',
    CLEAR_SMART_LOCKS_EVENT_FILTER: 'CLEAR_SMART_LOCKS_EVENT_FILTER',
    CLEAR_ADMIN_EVENT_FILTER: 'CLEAR_ADMIN_EVENT_FILTER',
    SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS: 'SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS',
    APPEND_SUBCOMPANIES_SMART_LOCKS_EVENTS: 'APPEND_SUBCOMPANIES_SMART_LOCKS_EVENTS',
    SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS_PAGINATION: 'SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS_PAGINATION',
    RESET_SUBCOMPANIES_SMART_LOCKS_EVENTS: 'RESET_SUBCOMPANIES_SMART_LOCKS_EVENTS',
    SET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER: 'SET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER',
    CLEAR_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER: 'CLEAR_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER',
    RESET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTERS: 'RESET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTERS',
  };
