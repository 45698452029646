import axios from 'axios';

export const axiosInstance = axios.create({
  timeout: 30000,
});

export function confirmBookeyReservation(code) {
  return axiosInstance.post('api/v1/reservations/confirm', { code });
}

export function setReservationConfirmBaseUrl(baseURL) {
  axiosInstance.defaults.baseURL = baseURL;
}

export function checkinBookeyReservation(reservationId, code) {
  return axiosInstance.post(`api/v1/reservations/${reservationId}/checkIn/otp/verify`, { code });
}
