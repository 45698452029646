import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_CAMERAS,
  SAVE_ALL_CAMERAS,
  APPEND_CAMERAS,
  SAVE_CAMERAS_PAGINATION_DATA,
  SET_SELECTED_CAMERA,
  RESET_CAMERA_DATA,
  SET_CAMERA_FILTER,
  RESET_CAMERA_FILTERS,
  UPDATE_CAMERA,
  SAVE_SMART_LOCKS_WITH_CAMERAS,
} from '../actions/actionTypes/cameras';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {
      number: 1,
      totalPages: 1,
    },
    filters: {},
  },
  allCameras: [],
  locksWithCameras: [],
  selectedCamera: {},
  isOperationalMode: false,
});

export default function (state = initialState, action) {
  let tmp;
  switch (action.type) {
    case SAVE_CAMERAS:
      return state.setIn(['data', 'content'], action.cameras);
    case SAVE_ALL_CAMERAS:
      return state.set('allCameras', action.cameras);
    case APPEND_CAMERAS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.cameras]);
    case SAVE_CAMERAS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SET_SELECTED_CAMERA:
      return state.set('selectedCamera', action.camera);
    case SET_CAMERA_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case RESET_CAMERA_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case SAVE_SMART_LOCKS_WITH_CAMERAS:
      return state.set('locksWithCameras', action.locks);
    case UPDATE_CAMERA: {
      const index = _.findIndex(state.data.content, data => data.id === action.camera.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.camera);
      }
      return state.setIn(['data', 'content'], [...state.data.content, action.camera]);
    }
    case RESET_CAMERA_DATA:
      return state.set('data', initialState.data);
    default:
      return state;
  }
}
