// @ts-nocheck
import { Card, IconButton } from '@material-ui/core';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import SearchIcon from '@material-ui/icons/Search';
import ResetIcon from '@material-ui/icons/HighlightOff';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset, submit } from 'redux-form';
import { BLUE, GREEN, RED } from '../../_config/consts';
import MDButton from '../MDButton/MDButton.jsx';
import CustomField from './Fields/CustomField.jsx';


@connect(state => ({}))
@reduxForm({ form: 'TicketCategoriesFiltersForm' })
class TicketCategoriesFiltersForm extends React.Component {

  onSearchReset() {
    const { dispatch } = this.props;
    dispatch(reset('TicketCategoriesFiltersForm'));
    const { onSearchReset } = this.props;
    onSearchReset();
  }

  render() {
    const { dispatch, onCreateNew } = this.props;
    
    return (
      <Card style={{ marginBottom: 20, padding: 20, paddingBottom: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Entity
            componentClass={Field}
            name="search"
            componentAttribute="placeholder"
            entity="ticketCategoryName"
            component={CustomField}
            containerStyle={{ marginLeft: 10, width: 250, marginRight: 10 }}
            type="text"
          />
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20, marginBottom: 10 }}>
            <IconButton onClick={() => dispatch(submit('TicketCategoriesFiltersForm'))}>
              <SearchIcon style={{ color: BLUE, fontSize: 20 }} />
            </IconButton>
            <IconButton onClick={() => this.onSearchReset()}>
              <ResetIcon style={{ color: RED, fontSize: 20 }} />
            </IconButton>
            <MDButton
              containerStyle={{ margin: 0, marginLeft: 15, marginRight: 10, marginTop: 5 }}
              backgroundColor={GREEN}
              title={<Entity entity="createNewTicketCategory" />}
              onClick={() => onCreateNew()}
            />
          </div>
        </div>
      </Card>
    );
  }
} 

export default TicketCategoriesFiltersForm;
