import { Modal } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import MDButton from '../../components/MDButton/MDButton.jsx';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import NexudusLoginForm from '../forms/Integrations/NexudusLoginForm.jsx';

@connect((state) => ({ settings: state.settings }))
class LoginWithNexudus extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showNexudusLogin: false,
    };
  }

  async onNexudusLogin(loginData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const loginResponse = await dispatch(UserActions.loginWithNexudus(loginData.email, loginData.password));
      if (loginResponse && loginResponse.accessToken) {
        await dispatch(UserActions.loginWithOauthProvider('NEXUDUS', loginResponse.accessToken));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } else {
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'BAD_CREDENTIALS' },
        }));
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { idp, dispatch } = this.props;
    const { showNexudusLogin } = this.state;

    return (
      <div>
        <MDButton
          type="submit"
          backgroundColor="#ff9b00"
          icon={<img src="/imgs/common/integrationLogos/nexudus-white.png" style={{ width: 90 }} />}
          containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
          onClick={() => this.setState({ showNexudusLogin: true })}
        />
        <Modal
          show={showNexudusLogin}
          className="alert-container full-height-modal"
          onHide={() => this.setState({ showNexudusLogin: false })}
        >
          <div style={{ padding: 25, display: 'flex', flexDirection: 'column' }}>
            <img alt="nexudus" src="/imgs/common/integrationLogos/nexudus.png" style={{ height: 50, alignSelf: 'center' }} />
            <NexudusLoginForm
              onSubmit={loginData => this.onNexudusLogin(loginData)}
            />
            <MDButton
              title={<Entity entity="login" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor="#ff9b00"
              containerStyle={{ margin: 0, marginTop: 10, marginBottom: 10 }}
              onClick={() => dispatch(submit('NexudusLoginForm'))}
            />
          </div>
        </Modal>
      </div>
    );
  }
} 

export default LoginWithNexudus;
