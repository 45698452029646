import { axiosInstance } from './rest';

export function getVisitors(params) {
  const requestData = {
    params,
  };
  return axiosInstance.get('/api/v2/visitors', requestData);
}

export function createVisitor(visitorDTO) {
  return axiosInstance.post('/api/v2/visitors', visitorDTO);
}

export function deleteVisitor(visitorId) {
  return axiosInstance.delete(`/api/v2/visitors/${visitorId}`);
}

export function checkOutVisitor(checkoutDTO) {
  return axiosInstance.post('/api/v2/visitors/checkOut', checkoutDTO);
}

export function checkInVisitor(checkInDTO) {
  return axiosInstance.post('/api/v2/visitors/checkIn', checkInDTO);
}

