import React from 'react';

import { Modal, Button } from '@sketchpixy/rubix';

export default class BasicModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  close() {
    this.setState({ showModal: false });
  }

  open() {
    this.setState({ showModal: true });
  }

  render() {
    const { title, body, footer, hideCloseButton } = this.props;
    const { showModal } = this.state;
    return (
      <Modal bsSize="large" show={this.state.showModal} onHide={() => this.close()}>
        {title && (
          <Modal.Header closeButton={!hideCloseButton}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {body}
        </Modal.Body>
        {footer && (
          <Modal.Footer>
            {footer}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

