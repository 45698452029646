// @ts-nocheck
import _ from 'lodash';
import WarningIcon from '@material-ui/icons/Warning';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm, submit } from 'redux-form';
import * as ModalActions from '../../redux/actions/modal.actions';
import { ORANGE, RED } from '../../_config/consts';
import { convertCSVToJSON } from '../../_config/utils';
import MDButton from '../MDButton/MDButton.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import TagsSelectorListField from './Elements/TagsSelectorListField.jsx';
import DropFileField from './Fields/DropFileField.jsx';
import Tag from '../Tag.jsx';
import DeleteOutlineIconCustom from '../CustomIcons/DeleteOutlineIconCustom.jsx';
import { IconButton } from '@material-ui/core';

const validate = (values) => {
  const errors = [];
  if (values) {
    // if (!values.csvFile) {
    //   errors.csvFile = 'required';
    // }
  }
  return errors;
};

const UserRow = ({ item, onRemoveUser, isEaven }) => {
  const fakeUserTag = {
    color: '#ba68c8',
    name: item && item.username ? item.username.toLowerCase() : '',
    type: 'user',
  };
  const backgroundColor = !isEaven ? '#FAFAFA' : 'white';
  return (
    <div
      className="card-noHover tag-element-selected"
      style={{ display: 'flex', zIndex: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 5, marginBottom: 0, marginTop: 0, backgroundColor }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h5 style={{ margin: 0, color: '#3f3f3f' }}>{item.name || `${item.firstname} ${item.lastname}`}{item.email ? <h6 style={{ margin: 0, marginTop: 2, color: '#3f3f3f' }}>{item.email}</h6> : null}</h5>
        
      </div>
      <div className="tags-container" style={{ margin: 0, marginLeft: 55 }}>
        {item && item.username &&
          <Tag textStyle={{ fontSize: 12 }} style={{ paddingTop: 4, paddingBottom: 4 }} tag={fakeUserTag} />
        }
        {_.map(item.userSpecialTags, tag =>
          <Tag
            key={tag.id}
            tag={tag}
          />,
        )}
        <IconButton onClick={() => onRemoveUser()}>
          <DeleteOutlineIconCustom style={{ color: RED, width: 25 }} />
        </IconButton>
      </div>
    </div>
  );
};


@reduxForm({ form: 'ImportGuestsCSVForm', validate })
@connect(state => ({ form: state.form.ImportGuestsCSVForm }))
class ImportGuestsCSVForm extends React.Component {


  render() {
    const { dispatch, users, availableUserTags, onTagsSelected, onRemoveUser, onGuestInputChange, onDeleteFile, onFileAccepted, form } = this.props;
    return (
      <Form>
        <FormCardContainer
          title="importGuestsFromCSV"
        >
          <Field
            name="csvFile"
            className="drop-file-container"
            activeClassName="drop-file-container-active"
            multiple={false}
            accept={['.xlsx', '.csv']}
            maxSize={20000000}
            component={DropFileField}
            onFileAccepted={(file) => onFileAccepted(file)}
            onFileRejected={() => dispatch(ModalActions.showModal({
              modalType: 'ERROR_MODAL',
              modalProps: { type: 'FILE_TOO_LARGE' },
            }))}
            onDeleteFile={f => onDeleteFile(f)}
          >
            <h5 style={{ marginBottom: 5 }}><Entity entity="dropFile" /></h5>
            <h5 style={{ marginTop: 0 }}><Entity entity="orSelect" /></h5>
          </Field>
          {users && !_.isEmpty(users) ? (
            <div style={{ marginTop: 20 }}>
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', marginBottom: 0 }}>
                {<Entity entity="assignTagsToImportUsers" />}
              </h4>
              <h4 style={{ color: '#3f3f3f', fontSize: 15, marginTop: 5 }}>
                {<Entity entity="assignTagsToImportUsersDescription" />}
              </h4>
              <TagsSelectorListField
                formName="ImportGuestsCSVForm"
                name="guestTags"
                selectorFieldName="guestTagMatchingMode"
                selectedTagsFieldName="selectedGuestTags"
                tags={availableUserTags}
                form={form}
                tagsType="GUEST"
                showList={false}
                title={<Entity entity="" />}
                isOptional
                placeholder="searchTags"
                listTitle="selectedUsers"
                onInputChange={value => onGuestInputChange(value)}
                onTagsChange={tags => onTagsSelected(tags)}
              />
              <div style={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
                <h5><Entity entity="selectedUsers" /></h5>
                <h5 style={{ marginLeft: 5 }}>{`(${_.size(users)})`}</h5>
              </div>
              <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
                <div style={{ maxHeight: 200, overflowY: 'scroll' }}>
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    {_.map(users, (item, index) =>
                      <UserRow
                        item={item}
                        isEaven={index % 2 === 0}
                        onRemoveUser={() => onRemoveUser(item)}
                      />,
                    )}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 20, marginBottom: -10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <WarningIcon style={{ fontSize: 20, marginRight: 10, color: ORANGE }} />
                <h4 style={{ color: '#3f3f3f', lineHeight: '1.3em', fontSize: 14, margin: 0 }}><Entity entity="importGuestsFromCSVWarning" /></h4>
              </div>
            </div>
          ) : null}
          <MDButton
            title={<Entity entity="import" />}
            disabled={_.isEmpty(users)}
            containerStyle={{ margin: 0, marginTop: 20 }}
            onClick={() => dispatch(submit('ImportGuestsCSVForm'))}
          />
        </FormCardContainer>
      </Form>
    );
  }
} 

export default ImportGuestsCSVForm;
