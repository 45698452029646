import { createTheme, withStyles } from '@material-ui/core';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import Select from 'react-select';
import { BLUE } from '../../_config/consts';
import DateRangeView from "../forms/Fields/DatePickers/DateRangePicker/DateRangeView.jsx";

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  dateHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

class DashboardFilters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedSubcompany: null,
    }
  }
  onDatesChange(startDate, endDate) {
    const { onDateRangeSelected } = this.props;
    if (!startDate && !endDate) {
      this.resetDateFilter();
    }

    if (onDateRangeSelected && startDate && endDate) {
      onDateRangeSelected(startDate, endDate);
    }
  }

  resetDateFilter() {
    const { onResetDateFilters } = this.props;
    onResetDateFilters();
  }

  onSelectSubcompany(company) {
    const { onSelectSubcompany } = this.props;
    this.setState({ selectedSubcompany: company });
    onSelectSubcompany(company ? company.value : null)
  }

  render()  {
    const { showSubcompanySelector, subcompanies, classes, startDate, endDate } = this.props;
    const { selectedSubcompany } = this.state;
    return (
      <div className={classes.header}>
        <div className={classes.dateHeader}>
          <DateRangeView
            id="dashboard-date-selector"
            startDateId="dashboard-date-selector-start"
            endDateId="dashboard-date-selector-end"
            startDate={moment(startDate).startOf('day')}
            endDate={moment(endDate).endOf('day')}
            noBorder={false}
            show
            onClearDates={() => this.resetDateFilter()}
            endDatePlaceholderText="--/--/--"
            startDatePlaceholderText="--/--/--"
            showClearDates
            minimumNights={0}
            style={{ marginTop: 50 }}
            isOutsideRange={day => moment(day).isAfter(moment().endOf('day')) || moment(day).isBefore(moment().subtract(1, 'year').subtract(1, 'day'))}
            onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
            onChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
            onResetOptionSelection={() => this.resetDateFilter()}
            helpersOptions={[
              {
                title: L20NContext.getSync('today'),
                startDate: moment().startOf('day'),
                endDate: moment().endOf('day'),
              },
              {
                title: L20NContext.getSync('thisWeek'),
                startDate: moment().startOf('week'),
                endDate: moment().endOf('week'),
              },
              {
                title: L20NContext.getSync('thisMonth'),
                startDate: moment().startOf('month'),
                endDate: moment().endOf('month'),
              },
            ]}
          />
          {showSubcompanySelector ? (
            <div style={{ minWidth: 325, marginLeft: 30, marginBottom: 32 }}>
              <Select
                value={selectedSubcompany}
                placeholder={<Entity entity="selectSubcompany" />}
                noResultsText={<Entity entity="noResultsFound" />}
                onChange={val => this.onSelectSubcompany(val)}
                options={_.map(subcompanies, subcompany => ({ value: subcompany.id, label: subcompany.plantName }))}
              />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(DashboardFilters);