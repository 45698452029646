import { Card, CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import LockIcon from '@material-ui/icons/LockOpen';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import BarChartIcon from '@material-ui/icons/InsertChart';
import UsersIcon from '@material-ui/icons/People';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import {
  FormControl
} from '@sketchpixy/rubix';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { BLUE, GREEN } from '../../_config/consts';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';
import DateRangeView from '../forms/Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';
import SmartLockRemoveDeleteIconCustom from '../CustomIcons/SmartLockRemoveDeleteIconCustom.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  progressAreaOccupancy: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '30px !important',
    width: '30px !important',
  },
  headerText: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
  },
  chartContainer: {
    width: '100%',
    maxHeight: 400,
    overflowY: 'scroll',
    marginTop: 10,
  },
  refreshHeader: {
    position: 'absolute',
    right: 10,
    top: 5,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '30%',
    marginTop: 10,
    borderRadius: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  tableColumnText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAnalyticsView = () => (
  <div style={{ height: 100, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <SmartLockRemoveDeleteIconCustom style={{ color: '#bdbdbd', width: 32 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 5, fontSize: 18 }}><Entity entity="noActiveLocksFound" /></h3>
  </div>
);

class DailyActiveSmartLocksList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredLocks: props.locks,
      filterText: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { locks: newLocks } = nextProps;
    const { locks: oldLocks } = this.props;
    if (newLocks !== oldLocks) {
      this.setState({ filteredLocks: newLocks });
    }
  }

  onSearchLock(text) {
    const { locks } = this.props;
    this.setState({ filterText: text });
    const filteredLocks = _.filter(locks, lock => lock && lock.name.toLowerCase().indexOf(text) > -1);
    this.setState({ filteredLocks });
  }

  onSearchReset() {
    const { locks } = this.props;
    this.setState({ filterText: '', filteredLocks: locks });
  }

  render() {
    const { locks, classes, textColor, isRefreshingAccessChartData, isHeaderHidden, onExportActiveSmartLocksList, lastUpdate, onShowSmartLockLogs, onRefreshMetric, isCapacityManagementActive, isRefreshingAreasOccupancy, areasData, areasOccupancy, accessesFilterEndDate } = this.props;
    const { filteredLocks, filterText } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshingAccessChartData ? 0.5 : 1 }} elevation={3}>
          {!isHeaderHidden ? (
            <div className={classes.header}>
              <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f' }}><Entity entity="mostUsedLocksToday" /></h3>
              <div className={classes.headerTextIconContainer}>
                <LockIcon style={{ fontSize: 35, color: textColor || '#3f3f3f' }} />
                <h2 className={classes.titleText} style={{ marginTop: 0, marginLeft: 10, color: textColor || '#3f3f3f' }}>{_.size(locks)}</h2>
              </div>
            </div>
          ) : null}
          {locks && !_.isEmpty(locks) ? (
            <div className={classes.searchHeader}>
              <Card className={classes.searchContainer}>
                <FormControl
                  type="text"
                  value={filterText}
                  placeholder={L20NContext.getSync('lockNameSearch')}
                  className="form-control-noBorder"
                  style={{ width: '100%', borderRadius: 5 }}
                  onChange={e => this.onSearchLock(e.target.value)}
                />
                {filterText ? (
                  <IconButton style={{ height: 20, width: 20, marginLeft: -60, marginRight: 10 }} onClick={() => this.onSearchReset()}>
                    <CloseIcon />
                  </IconButton>
                ) : null}
                <SearchIcon style={{ marginLeft: filterText ? 0 : -30 }} />
              </Card>
              <SimpleExportMenu
                titleContainerStyle={{ marginLeft: 25, marginTop: 5 }}
                iconStyle={{ color: textColor || GREEN }}
                exportTitleStyle={{ color: textColor || GREEN }}
                onExportLogs={format => onExportActiveSmartLocksList(format)}
              />
            </div>
          ) : null}
          <div className={classes.chartContainer}>
            {filteredLocks && !_.isEmpty(filteredLocks) ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="lock" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="firstAccess" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="lastAccess" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="lastActiveUser" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="guestDetailsEvents" /></h5></TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                {_.map(filteredLocks, (lock) => {
                  return (
                  <TableRow
                    key={lock.id}
                    className={classes.row}
                    hover
                    classes={{
                      selected: classes.rowSelected,
                      hover: classes.rowHovered,
                    }}
                    onClick={() => onShowSmartLockLogs(lock)}
                  >
                    <TableCell>
                      <h5 style={{ color: '#3f3f3f', marginBottom: 5 }}>
                        {`${lock.name || '--'}`}
                      </h5>
                      <h5 style={{ color: '#3f3f3f', marginTop: 0, fontSize: 12 }}>
                        {`${lock.serialNumber || '--'}`}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>{lock.firstAccessTimestamp ? moment(lock.firstAccessTimestamp).format('LLL') : '---'}</h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>{lock.lastAccessTimestamp ? moment(lock.lastAccessTimestamp).format('LLL') : '---'}</h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>{lock.lastActiveUser ? `${lock.lastActiveUser.firstname} ${lock.lastActiveUser.lastname} ${lock.lastActiveUser.email}`  : '---'}</h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ color: '#3f3f3f' }}>{_.size(lock.events)}</h5>
                    </TableCell>
                  </TableRow>
                  )
                })}
              </TableBody>
              </Table>
            ) : <EmptyAnalyticsView />}
          </div>
          <div className={classes.refreshHeader}>
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}:{moment(lastUpdate).format('LLL')}</h5>
            <IconButton
              disabled={isRefreshingAccessChartData}
              style={{ opacity: isRefreshingAccessChartData ? 0.5 : 1 }}
              onClick={() => onRefreshMetric('smartLocksEvents')}
            >
              <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
            </IconButton>
          </div>
          {isRefreshingAccessChartData ? <CircularProgress color="primary" className={classes.progress} /> : null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(DailyActiveSmartLocksList);
