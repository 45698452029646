import { Chip, CircularProgress, createTheme, withStyles } from '@material-ui/core';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { BLUE } from '../../_config/consts.js';
import BookeyReservationsListCard from './BookeyReservationsListCard.jsx';

const styles = theme => ({
  container: {
    overflowY: 'auto',
    maxHeight: '120vh',
    paddingLeft: 15,
    paddingRight: 20,
    paddingTop: 0,
  },
  boldText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  rowContainer: {
    padding: 20,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginBottom: 15,
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 0,
  },
  rowDescription: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 16,
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  list: {
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap',
    position: 'absolute',
    top: 50,
    right: 0,
    left: 0,
    zoom: '125%',
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    backgroundColor: 'white',
    zIndex: 2,
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: '1rem',
    fontWeight: 'bold',
    '&.inactive': {
      opacity: 0.5,
      '&:hover': {
        opacity: 0.75,
      },
    },
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

@withRouter
@connect(state => ({ themeName: state.settings.items.theme.data.themeName }))
class BookeyReservationsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeFilter: null,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
  }

  handleFilterClick = (filter) => {
    this.setState(prevState => ({
      activeFilter: prevState.activeFilter === filter ? null : filter,
    }));
  }

  isReservationPenalty = (status) => status.endsWith("WITH_PENALTY");
  isReservationLost = (status) => status === "LOST";

  getFilteredAndSortedReservations() {
    const { reservations } = this.props;
    const { activeFilter } = this.state;

    return [...reservations]
      .filter(reservation => {
        if (!activeFilter) return true;
        if (activeFilter === 'SUCCESSFUL') return !this.isReservationPenalty(reservation.state) && !this.isReservationLost(reservation.state);
        if (activeFilter === 'LOST') return this.isReservationLost(reservation.state);
        if (activeFilter === 'PENALTY') return this.isReservationPenalty(reservation.state);
        return true;
      })
      .sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate));
  }

  render() {
    const { classes, themeName, dispatch, usersDetails, onRowClick } = this.props;
    const { activeFilter } = this.state;

    const filteredReservations = this.getFilteredAndSortedReservations();

    const parsedReservations = filteredReservations.map(reservation => {
      const userSearch = usersDetails.filter(e => e.id === reservation.userId)
      return {
        ...reservation,
        user: userSearch.length ? userSearch[0] : undefined
      }
    })

    const filterChips = [
      { label: L20NContext.getSync('bookeyActive').toUpperCase(), value: 'SUCCESSFUL', color: 'rgba(77,182,172,1)', textColor: '#FFF' },
      { label: L20NContext.getSync('bookeyLost').toUpperCase(), value: 'LOST', color: 'rgba(241, 130, 141,1)', textColor: '#000' },
      { label: L20NContext.getSync('bookeyPenalty').toUpperCase(), value: 'PENALTY', color: 'rgba(255,167,38,1)', textColor: '#000' },
    ];

    return (
      <div>
        <div className={classes.headerContainer}>
          <div className={classes.filterContainer}>
            {filterChips.map(chip => (
              <Chip
                key={chip.label}
                label={chip.label}
                variant='outlined'
                onClick={() => this.handleFilterClick(chip.value)}
                className={`${classes.chip} ${activeFilter && activeFilter !== chip.value ? 'inactive' : ''}`}
                style={{
                  backgroundColor: activeFilter === chip.value ? chip.color : undefined,
                  color: activeFilter === chip.value ? chip.textColor : undefined,
                }}
              />
            ))}
          </div>
        </div>
        <div style={{ marginTop: 50 }}>
          <div className={classes.container}>
            <InfiniteScroll
              initialLoad={false}
              loadMore={() => {}}
              hasMore={false}
              useWindow={false}
              loader={
                <div style={{ marginTop: 0, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <h4 style={{ textAlign: 'center', color: '#3f3f3f' }}><Entity entity="loadingLogs" /></h4>
                  <CircularProgress className={classes.circularProgress} />
                </div>
              }
            >
              <div>
                {!_.isEmpty(parsedReservations) ?
                  _.map(parsedReservations, (reservation) =>
                    <BookeyReservationsListCard
                      key={reservation.id}
                      reservation={reservation}
                      onRowClick={() => onRowClick(reservation)}
                    />)
                :null}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(BookeyReservationsList);