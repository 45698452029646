import { Col, Form, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import CustomField from '../Fields/CustomField.jsx';
import DatePickerField from '../Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import EventImageSelector from '../Fields/EventImageSelector.jsx';
import TimePickerField from '../Fields/TimePicker/TimePickerField.jsx';



const validate = (values) => {

  const errors = {};
  const period = moment(values.dateTo).diff(moment(values.dateFrom), 'hours');
  if (!values.title) {
    errors.title = 'required';
  }
  
  if (period < 0) {
    errors.dateTo = 'endDateAfterFromDate';
  }
  if (!values.description) {
    errors.description = 'required';
  }
  if (!values.media) {
    errors.media = 'required';
  }
  return errors;
};

@reduxForm({ form: 'EventForm', validate })
@connect(state => ({ form: state.form.EventForm }))
class EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onHandleChange(value, field) {
    const { dateHasBeenChanged, form } = this.props;
    let dateInterval = {};
    switch (field) {
      case 'dateFrom':
        dateInterval = {
          start: value,
          end: form.values.dateTo,
          startTime: form.values.timeFrom,
          endTime: form.values.timeTo,
        };
        break;
      case 'dateTo':
        dateInterval = {
          start: form.values.dateFrom,
          end: value,
          startTime: form.values.timeFrom,
          endTime: form.values.timeTo,
        };
        break;
      case 'timeFrom':
        dateInterval = {
          start: form.values.dateFrom,
          end: form.values.dateTo,
          startTime: value,
          endTime: form.values.timeTo,
        };
        break;
      case 'timeTo':
        dateInterval = {
          start: form.values.dateFrom,
          end: form.values.dateTo,
          startTime: form.values.timeFrom,
          endTime: value,
        };
        break;
      default:
        dateInterval = {
          start: form.values.dateFrom,
          end: form.values.dateTo,
          startTime: form.values.timeFrom,
          endTime: form.values.timeTo,
        };
    }
    const dateToParse = {
      dateFrom: dateInterval.start,
      dateTo: dateInterval.end,
      startTime: dateInterval.startTime,
      endTime: dateInterval.endTime,
    };

    dateHasBeenChanged(dateToParse);
  }

  render() {
    const { form } = this.props;
    const minimumDateTo = form && form.values && form.values.dateFrom ? moment(form.values.dateFrom) : moment();
    return (
      <Form>
        <FormCardContainer
          title="eventDetails"
          subtitle="nameWarning"
        >
          <div style={{ display: 'flex' }}>
            <Field
              id="resourceAvatar"
              name="media"
              component={EventImageSelector}
            />
            <div style={{ marginLeft: 15, width: '90%' }}>
              <Entity
                componentClass={Field}
                name="title"
                componentAttribute="placeholder"
                entity="insertName"
                title={<Entity entity="nameEvent" />}
                component={CustomField}
                mandatory
              />
            </div>
          </div>
          <Entity
            componentClass={Field}
            name="location"
            componentAttribute="placeholder"
            entity="insertEventLocation"
            title={<Entity entity="eventLocation" />}
            component={CustomField}
          />
          <Entity
            componentClass={Field}
            name="link"
            componentAttribute="placeholder"
            entity="insertEventLink"
            title={<Entity entity="eventLink" />}
            component={CustomField}
          />
        </FormCardContainer>

        <FormCardContainer
          title="eventDateAndTime"
          subtitle="dateAndTimeWarning"
        >
          <Row style={{ display: 'flex', justifyContent: 'center' }} >
            <Col collapseRight xs={6} sm={6} md={6} >
              <Field
                title={<Entity entity="dateFrom" />}
                id="dateFrom"
                name="dateFrom"
                dateContainer={{ width: 120 }}
                onHandleChange={value => this.onHandleChange(value, 'dateFrom')}
                component={DatePickerField}
                mandatory
              />
            </Col>
            <Col collapseRight xs={6} sm={6} md={4} >
              <Field
                title={<Entity entity="eventTimeFrom" />}
                name="timeFrom"
                component={TimePickerField}
                onHandleChange={value => this.onHandleChange(value, 'timeFrom')}
              />
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
            <Col collapseRight xs={6} sm={6} md={6} >
              <Field
                style={{ marginLeft: 55 }}
                title={<Entity entity="eventDateTo" />}
                id="dateTo"
                name="dateTo"
                dateContainer={{ width: 120 }}
                onHandleChange={value => this.onHandleChange(value, 'dateTo')}
                component={DatePickerField}
                isOutsideRange={date => date.isBefore(moment(minimumDateTo).startOf('day'))}
                mandatory
              />
            </Col>
            <Col collapseRight xs={6} sm={6} md={4} >
              <Field
                title={<Entity entity="eventTimeTo" />}
                name="timeTo"
                component={TimePickerField}
                onHandleChange={value => this.onHandleChange(value, 'timeTo')}
              />

            </Col>
          </Row>

        </FormCardContainer>

        <FormCardContainer
          title="descriptionEvent"
          subtitle="descriptionWarning"
        >
          <Entity
            componentClass={Field}
            name="description"
            componentAttribute="placeholder"
            entity="insertDescription"
            title={<Entity entity="descriptionEvent" />}
            component={CustomField}
            type="textarea"
            className="form-control-custom"
            mandatory
          />
        </FormCardContainer>
      
      </Form>
    );
  }
} 

export default EventForm;
