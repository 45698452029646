import _ from 'lodash';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';

const validate = values => {
  const errors = {};

  if (!values.business) {
    errors.business = 'required';
  }
  return errors;
};

@reduxForm({ form: 'ChainelsSelectCommunityForm', validate })
class ChainelsSelectCommunityForm extends React.Component {
  
  render() {
    const { communities } = this.props;
    const communitiesOptions = _.map(communities, community => ({
      name: community.name,
      id: community.id,
      type: { name: 'Community' },
    }));
    return (
      <div>
        <Field
          name="business"
          component={SelectableRowField}
          isSingleChoice
          options={{
            content: communitiesOptions,
          }}
          listContainerStyle={{ marginTop: 0 }}
          listStyle={{ marginTop: 0 }}
        />
      </div>
    );
  }
} 

export default ChainelsSelectCommunityForm;
