import _ from 'lodash';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

const validate = values => {
  const errors = {};
  /*
  if (!values.pin && !values.credentialRule) {
    errors.pin = 'select one';
    errors.credentialRule = 'select one';
  }
  */
  return errors;
};

@reduxForm({ form: 'OctorateSelectAccomodationsForm', validate })
class OctorateSelectAccomodationsForm extends React.Component {
  
  render() {
    const { accommodations } = this.props;
    const accommodationsOptions = _.map(accommodations, accomodation => ({
      name: accomodation.name,
      id: accomodation.id,
      type: { name: `ID ${accomodation.id}` },
    }));
    return (
      <div>
        <h4 style={{color:'white'}}><Entity entity="selectAccommodationsOctorate" /></h4>
        <Field
          name="accommodations"
          component={SelectableRowField}
          options={{
            content: accommodationsOptions,
          }}
          listContainerStyle={{ marginTop: 0 }}
          listStyle={{ marginTop: 0 }}
        />

        <h4 style={{color:'white'}}><Entity entity="selectColdStartOctorate" /></h4>
        <Field
          name="coldStartResources"
          label={<Entity entity="synchronizeAllResource" style={{color:'#DDD'}} />}
          titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
          component={MDCheckBoxField}
        />
        <h5 style={{ marginBottom: 50, marginTop: -10, color:'#DDD' }}><Entity entity="synchronizeAllResourcesWhenActivate" /></h5>

        <h4 style={{ marginBottom: 20, marginTop: 20, color:'white'}}><Entity entity="synchronizeReservationsAsChoose" /></h4>
        <Field
          name="pin"
          label={<Entity entity="synchronizeReservationsAsPinsTitle" style={{color:'#DDD'}} />}
          titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
          component={MDCheckBoxField}
        />
        <h5 style={{ marginBottom: 20, marginTop: -10, color:'#DDD' }}><Entity entity="synchronizeReservationsAsPins" /></h5>
        <Field
          name="credentialRule"
          label={<Entity entity="synchronizeReservationsAsCredentialRulesTitle" style={{color:'#DDD'}} />}
          titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
          component={MDCheckBoxField}
        />
        <h5 style={{ marginBottom: 50, marginTop: -10, color:'#DDD' }}><Entity entity="synchronizeReservationsAsCredentialRules" /></h5>
      </div>
    );
  }
} 

export default OctorateSelectAccomodationsForm;
