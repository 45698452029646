/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import RegistryIcon from '@material-ui/icons/HdrWeak';
import ItemRegistryTableView from '../components/ItemRegistry/ItemRegistryTableView.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider';
import * as ItemRegistryActions from '../redux/actions/itemRegistry.actions';
import * as TagActions from '../redux/actions/tag.actions';
import * as CredentialActions from '../redux/actions/credential.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import { EXPORT_FORMATS, ITEM_DELIVERY_TYPES, ITEM_DELIVERY_TYPES_NAMES, ITEM_TYPES, ITEM_TYPES_NAMES, MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES, VIEW_MODES } from '../_config/consts';
import ItemRegistryForm from '../components/forms/RegistryForm/ItemRegistryForm.jsx';
import { change, destroy, initialize, reset, submit } from 'redux-form';
import MDButton from '../components/MDButton/MDButton.jsx';
import TranslatableOption from '../components/forms/Fields/TranslatableOption.jsx';
import { localizeHelpCenterLink } from '../_config/utils.js';


let filterTimeout;
const itemTypesOptions = [{ label: 'itemTypeSelect', value: '' }, ..._.map(ITEM_TYPES, itemType => ({ label: ITEM_TYPES_NAMES[itemType], value: itemType }))];
const deliveryTypeOptions = [{ label: 'itemDeliveryModeSelect', value: '' }, ..._.map(ITEM_DELIVERY_TYPES, itemType => ({ label: ITEM_DELIVERY_TYPES_NAMES[itemType], value: itemType }))];
@connect((state) => ({
  routing: state.router,
  itemRegistry: state.itemRegistry,
  viewLoading: state.utils.viewLoading,
  userLicense: state.user.license,
  themeName: state.settings.items.theme.data.themeName,
  credential: state.credentials.selectedCredential,
  userTags: state.tags.user.data,
  form: state.form.ItemRegistryForm,
}))
class ItemRegistry extends React.Component {

  async componentDidMount() {
    const { routing, dispatch } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    this.onFetchRegistryItems();
    const searchGuest = parsed.searchGuest;
    if (searchGuest) {
      try {
        const guest = JSON.parse(searchGuest);
        this.onSetFilter('recipient', guest.id);
        dispatch(change('SearchBarForm', 'guestSelected', { value: guest.id, label: `${guest.firstname} ${guest.lastname} ${guest.email}` }));
      } catch (error) {}
    }

    
    const newElementForGuest = parsed.newElementForGuest;
    if (newElementForGuest) {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      try {
        const guestTagId = JSON.parse(newElementForGuest);
        await dispatch(CredentialActions.fetchGuestsByTags({ id: guestTagId }, 'AT_LEAST_ONE_TAG'));
        const guestTagDetails = await dispatch(TagActions.fetchGuestTagDetails(guestTagId));
        this.onNewRegistryItem();
        dispatch(change('ItemRegistryForm', 'guestTags', guestTagDetails));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } catch (error) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CredentialActions.selectCredential({}));
    dispatch(ItemRegistryActions.setItemRegistryOperationalMode(false));
  }

  onFetchRegistryItems() {
    const { dispatch } = this.props;
    dispatch(ItemRegistryActions.fetchRegistryItems());
  }

  onDeleteItemRequest(itemId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteItemConfirm',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteItem(itemId),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteItem(itemId) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(ItemRegistryActions.deleteRegistryItem(itemId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onFetchRegistryItems();
      dispatch(ItemRegistryActions.setItemRegistryOperationalMode(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="itemDeletedSuccessFully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="itemActionError" /></h6>),
        },
      }));
    }
  }

  async onSelectItem(item) {
    const { dispatch } = this.props;
    const selectedGuestTags = item && item.recipient && item.recipient.tags ? [_.find(item.recipient.tags, tag => tag.type === 'user')] : [];
    dispatch(initialize('ItemRegistryForm', {
      ...item,
      guestTags: selectedGuestTags,
    }));
    await dispatch(CredentialActions.fetchGuestsByTags(selectedGuestTags, MATCH_TAG_MODE.EVERY_TAG));
    dispatch(ItemRegistryActions.setItemRegistryOperationalMode(true));
  }

  onRefreshRegistry() {
    this.onFetchRegistryItems();
  }

  onFetchItemsOnPage(page) {
    const { dispatch } = this.props;
    dispatch(ItemRegistryActions.fetchRegistryItems(page));
  }

  onFetchGuestsByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchGuestsByTags());
    dispatch(CredentialActions.fetchGuestsByTagsStardardCredentialsForm('ITEM', page, append));
  }

  onFilterGuestTags(value) {
    const { dispatch } = this.props;
    const tagsType = 'USER';
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchGuestTagsByFilter(value, 50, true, tagsType));
  }

  onNewRegistryItem() {
    const { dispatch } = this.props;
    dispatch(CredentialActions.selectCredential({}));
    dispatch(destroy('ItemRegistryForm'));
    dispatch(ItemRegistryActions.setItemRegistryOperationalMode(true));
  }
  
  onCloseOperationalSection() {
    const { dispatch } = this.props;
    dispatch(ItemRegistryActions.setItemRegistryOperationalMode(false));
    dispatch(CredentialActions.selectCredential({}));
  }

  onSaveItemData(itemData) {
    if (itemData && itemData.id) {
      this.onEditItem(itemData);
    } else {
      this.onCreateNewItem(itemData);
    }
  }

  async onCreateNewItem(itemData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(ItemRegistryActions.createRegistryItem(itemData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onFetchRegistryItems();
      dispatch(ItemRegistryActions.setItemRegistryOperationalMode(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="itemCreatedSuccessFully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="itemActionError" /></h6>),
        },
      }));
    }
  }

  async onEditItem(itemData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(ItemRegistryActions.updateRegistryItem(itemData.id, itemData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onFetchRegistryItems();
      dispatch(ItemRegistryActions.setItemRegistryOperationalMode(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="itemUpdatedSuccessFully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="itemActionError" /></h6>),
        },
      }));
    }
  }
  
  onResetItemsFilters() {
    const { dispatch } = this.props;
    dispatch(ItemRegistryActions.resetItemRegistryFilters());
    this.onFetchRegistryItems();
  }

  onSetFilter(field, value) {
    const { dispatch } = this.props;
    if (value && ((_.isNumber(value) && value >= 1) || (_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2))) {
      dispatch(ItemRegistryActions.setItemRegistryFilter(field, value));
      if (filterTimeout) clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => {
        this.onFetchRegistryItems();
      }, 500);
    } else {
      dispatch(ItemRegistryActions.setItemRegistryFilter(field, null));
      this.onFetchRegistryItems();
    }
  }

  onExportItems(format) {
    const { dispatch } = this.props;
    dispatch(ItemRegistryActions.exportItems(format));
  }

  onShowItemRegistryInfoPage() {
    const helpURL = localizeHelpCenterLink('luckey-object-register-add-on');
    window.open(helpURL);
  }
  
  render() {
    const { form, itemRegistry, userTags, credential, dispatch, themeName, viewLoading } = this.props;
    const canCreateItem = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.ITEM);
    const numberOfItems = itemRegistry && itemRegistry.data && itemRegistry.data.pagination && itemRegistry.data.pagination.totalElements ? itemRegistry.data.pagination.totalElements : 0;
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={canCreateItem ? () => this.onNewRegistryItem() : null}
          newEntityTitle="newItem"
          searchPlaceholderEntityName="searchItemName"
          searchNameContainerStyle={{ width: 220 }}
          newEntityIconName="icon-simple-line-icons-user-follow"
          isLoading={viewLoading}
          onSelectGuest={user => this.onSetFilter('recipient', user && user.value)}
          onFilterChange={value => this.onSetFilter('name', _.trim(value))}
          onSearchReset={() => this.onResetItemsFilters()}
          onInfo={() => this.onShowItemRegistryInfoPage()}
          onExportElements={format => this.onExportItems(format)}
          selectableFieldName="roleIds"
          onSelectOption={value => this.onSetFilter('type', value)}
          numberOfElements={numberOfItems}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <RegistryIcon style={{ fontSize: 25 }} />
              <h4 style={{ marginTop: 0 }}>{numberOfItems}</h4>
            </div>
          }
          selectableOptions={_.map(itemTypesOptions, role =>
            <Entity
              key={role.value}
              componentClass={TranslatableOption}
              value={role.value}
              componentAttribute="text"
              entity={role.label}
            />)}
        />
        <ItemRegistryTableView
          onNewItem={() => this.onNewRegistryItem()}
          onDeleteItem={itemId => this.onDeleteItemRequest(itemId)}
          onSelectItem={item => this.onSelectItem(item)}
          onRefreshRegistry={() => this.onRefreshRegistry()}
          onFetchItemsOnPage={page => this.onFetchItemsOnPage(page)}
        />
        <OperationalView
          themeName={themeName}
          isVisible={itemRegistry.isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          style={{ margin: 0, padding: 0 }}
          title={<Entity entity="registryItem" />}
        >
          <div style={{ padding: 10, paddingBottom: '30%' }}>
            <ItemRegistryForm
              selectedType={form && form.values && form.values.type}
              themeName={themeName}
              availableUserTags={userTags}
              itemTypesOptions={itemTypesOptions}
              deliveryTypeOptions={deliveryTypeOptions}
              guestsFilteredByTags={credential.guestsFilteredByTags}
              onFetchMoreGuests={page => this.onFetchGuestsByTags(page, true)}
              onGuestInputChange={value => this.onFilterGuestTags(value)}
              onGuestTagsChange={() => this.onFetchGuestsByTags()}
              onSubmit={itemData => this.onSaveItemData(itemData)}
            />
            {canCreateItem ? (
              <MDButton
                title={<Entity entity="save" />}
                containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
                style={{ height: 45, borderRadius: 0 }}
                onClick={() => dispatch(submit('ItemRegistryForm'))}
              />
            ) : null}
          </div>
        </OperationalView>
      </div>
    );
  }
} 

export default ItemRegistry;
