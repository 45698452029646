import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import AtlasGatewayDetails from '../components/Gateways/AtlasGatewayDetails.jsx';
import AtlasGatewayListView from '../components/Gateways/AtlasList/AtlasGatewayListView.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import * as GatewaysActions from '../redux/actions/gateways.actions';
import * as UtilsActions from '../redux/actions/utils.actions';

let filterTimeout;
@connect((state) => ({ gateways: state.gateways, user: state.user, viewLoading: state.utils.viewLoading, language: state.settings.language, themeName: state.settings.items.theme.data.themeName }))
class GatewaysAtlasView extends React.Component {

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setSelectedWiredGateway({}));
    dispatch(GatewaysActions.resetWiredGatewayFilters());
    this.onFetchAtlasGateways();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setSelectedWiredGateway({}));
  }

  async onFetchAtlasGateways() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(GatewaysActions.fetchAtlasGateways());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onFilterGateways(filterValue) {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setWiredGatewaysFilter('name', _.trim(filterValue)));
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      await this.onFetchAtlasGateways();
    }, 500);
  }


  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setOperationalMode(false));
    dispatch(GatewaysActions.setSelectedWiredGateway({}));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setOperationalMode(true));
  }

  onSearchReset() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.resetWiredGatewaysData());
    dispatch(GatewaysActions.resetWiredGatewayFilters());
    this.onFetchAtlasGateways();
  }

  async onSelectGateway(gateway) {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setSelectedWiredGateway(gateway));
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const gatewayDetailed = await dispatch(GatewaysActions.getAtlasGatewayDetails(gateway.deviceId));
      dispatch(GatewaysActions.setSelectedWiredGateway(gatewayDetailed));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(GatewaysActions.setOperationalMode(true));
    } catch (error) {
      dispatch(GatewaysActions.setSelectedWiredGateway({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onRefreshGateway() {
    const { dispatch, gateways: { wired: { selectedWiredGateway: gateway } } } = this.props;
    dispatch(GatewaysActions.setSelectedWiredGateway(gateway));
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const gatewayDetailed = await dispatch(GatewaysActions.getAtlasGatewayDetails(gateway.deviceId));
      dispatch(GatewaysActions.setSelectedGateway(gatewayDetailed));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { gateways, gateways: { wired: { selectedWiredGateway } }, themeName, viewLoading } = this.props;
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onSearchReset={() => this.onSearchReset()}
          isLoading={viewLoading}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h3 style={{ marginTop: 0 }}>{<Entity entity="hyperGatesAtlas" />}</h3>
            </div>
          }
        />
        <AtlasGatewayListView
          selectedGateway={selectedWiredGateway}
          gateways={gateways.wired}
          viewLoading={viewLoading}
          onSelectGateway={gateway => this.onSelectGateway(gateway)}
        />
        <OperationalView
          themeName={themeName}
          isVisible={gateways.isOperationalMode}
          onClose={() => this.closeOperationalSection()}
          onRefresh={() => this.onRefreshGateway()}
          title={<Entity entity="sectionTitle" data={{ name: 'gatewayDetails' }} />}
        >
          <AtlasGatewayDetails />
        </OperationalView>
      </div>
    );
  }
} 

export default GatewaysAtlasView;
