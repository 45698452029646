import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import {
  Row,
  Col,
  Icon,
  Grid,
  Button,
} from '@sketchpixy/rubix';
import { APP_STORE_LINKS } from '../../_config/consts';
import CheckIcon from '@material-ui/icons/CheckCircle';

@connect((state) => ({ setPassword: state.user.setPassword }))
class ResetPassowordOnboardingGuest extends React.Component {
  openAppLink(operativeSystem) {
    const { setPassword } = this.props;
    const appLink = setPassword && setPassword[operativeSystem] ? setPassword[operativeSystem] : APP_STORE_LINKS[operativeSystem];
    window.open(appLink);
  }
  render() {
    const { dispatch } = this.props;
    const plantName = window && window.location && window.location.host && window.location.host.replace('.jago.cloud', '');
    return (
      <div id="auth-container" style={{ height: '100vh', display: 'flex', alignItems: 'center', backgroundColor: '#12637f', paddingTop: 30, overflow: 'scroll', flexDirection: 'column' }} className="login">
        <Grid style={{ width: '100%' }}>
          <Row>
            <Col style={{ display: 'flex', flexDirection: 'column' }}>
              <img src="/imgs/common/logoJagoWhite.png" alt="Sofia" style={{ margin: 10, marginBottom: 15, alignSelf: 'center', height: 30 }} />
              <h4 style={{ marginTop: 5, textAlign: 'center', color: 'white' }}><Entity entity="followStepToLogin" /></h4>
              <div style={{ marginTop: 10, maxWidth: 400, width: '100%', padding: 20, alignSelf: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <h4 style={{ fontWeight: 800, marginTop: 5, textAlign: 'center', color: 'white', marginBottom: 0 }}>
                  1. <Entity entity="stepOne" />
                </h4>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div onClick={() => this.openAppLink('ios')} style={{ marginTop: 20, cursor: 'pointer'}}>
                    <img style={{ width: '125px' }} src="https://s3-eu-west-1.amazonaws.com/sofia-static-images/appleStore.png" alt="appleStore.png" />
                  </div>
                  <div onClick={() => this.openAppLink('android')} style={{ marginTop: 20, cursor: 'pointer'}}>
                    <img style={{ width: '135px' }} src="https://s3-eu-west-1.amazonaws.com/sofia-static-images/playStore.png" alt="playStore.png" />
                  </div>
                </div>
                <h4 style={{ fontWeight: 800, marginTop: 30, textAlign: 'center', color: 'white', marginBottom: 0 }}>
                  2. <Entity entity="stepTwo" />
                </h4>
                <h4 style={{ alignSelf: 'center', marginTop: 10, textAlign: 'center', color: 'white', marginBottom: 0 }}>
                  {plantName || ''}
                </h4>
                <h4 style={{ textAlign: 'center', fontWeight: 800, marginTop: 30, color: 'white', marginBottom: 0 }}>
                  3. <Entity entity="stepThree" />
                </h4>
                <div style={{ display: 'flex', marginTop: 30, flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
                  <CheckIcon style={{ color: '#06d4c0', fontSize: 25, marginTop: -5 }} />
                  <h3 style={{ color: '#06d4c0', fontWeight: 800, marginTop: 0, marginLeft: 10, textAlign: 'center', marginBottom: 5 }}>
                    <Entity entity="stepFour" />
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
} 

export default ResetPassowordOnboardingGuest;
