import Immutable from 'seamless-immutable';

import {
  SAVE_CREDENTIALS,
  APPEND_CREDENTIALS,
  SAVE_CREDENTIAL,
  SELECT_CREDENTIAL,
  SET_CREDENTIAL_OPERATIONAL_MODE,
  SET_GUESTS_FILTERED_BY_TAGS,
  SET_LOCKS_FILTERED_BY_TAGS,
  APPEND_GUESTS_FILTERED_BY_TAGS,
  APPEND_LOCKS_FILTERED_BY_TAGS,
  SET_CREDENTIAL_PAGINATION_DATA,
  SET_CREDENTIAL_GUESTS_PAGINATION_DATA,
  SET_CREDENTIAL_LOCKS_PAGINATION_DATA,
  SET_CREDENTIAL_FILTER,
  RESET_CREDENTIAL_FILTER,
  RESET_CREDENTIALS_DATA,
  RESET_CREDENTIAL_PAGINATION_DATA,
} from '../actions/actionTypes/credential';

const initialState = Immutable({
  data: {
    data: [],
    pagination: {},
  },
  filters: {},
  selectedCredential: {},
  isOperationalMode: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_CREDENTIALS:
      return state.setIn(['data', 'data'], action.credentials);
    case APPEND_CREDENTIALS:
      return state.setIn(['data', 'data'], [...state.data.data, ...action.credentials]);
    case SAVE_CREDENTIAL:
      return state.setIn(['data', 'data'], [...state.data.data, action.credential]);
    case SET_CREDENTIAL_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case RESET_CREDENTIAL_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    case SELECT_CREDENTIAL:
      return state.set('selectedCredential', action.credential);
    case SET_CREDENTIAL_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_GUESTS_FILTERED_BY_TAGS:
      return state.setIn(['selectedCredential', 'guestsFilteredByTags', 'data'], action.guests);
    case APPEND_GUESTS_FILTERED_BY_TAGS:
      return state.setIn(['selectedCredential', 'guestsFilteredByTags', 'data'],
        [...state.selectedCredential.guestsFilteredByTags.data, ...action.guests]);
    case SET_CREDENTIAL_GUESTS_PAGINATION_DATA:
      return state.setIn(['selectedCredential', 'guestsFilteredByTags', 'pagination'], action.pagination);
    case SET_LOCKS_FILTERED_BY_TAGS:
      return state.setIn(['selectedCredential', 'locksFilteredByTags', action.index, 'data'], action.locks);
    case APPEND_LOCKS_FILTERED_BY_TAGS:
      return state.setIn(['selectedCredential', 'locksFilteredByTags', action.index, 'data'],
        [...state.selectedCredential.locksFilteredByTags[action.index].data, ...action.locks]);
    case SET_CREDENTIAL_LOCKS_PAGINATION_DATA:
      return state.setIn(['selectedCredential', 'locksFilteredByTags', action.index, 'pagination'], action.pagination);
    case SET_CREDENTIAL_FILTER:
      return state.setIn(['filters', action.field], action.value);
    case RESET_CREDENTIAL_FILTER:
      return state.set('filters', initialState.filter);
    case RESET_CREDENTIALS_DATA:
      return state.set('data', initialState.data);
    default:
      return state;
  }
}
