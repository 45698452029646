import React from 'react';
import GuestsTagsOperationalSection from './TagsManagementOperationalSections/GuestsTagsOperationalSection.jsx';
import LocksTagsOperationalSection from './TagsManagementOperationalSections/LocksTagsOperationalSection.jsx';

const operationalComponent = {
  guests: GuestsTagsOperationalSection,
  locks: LocksTagsOperationalSection,
};

export default class TagsManagementOperationalSection extends React.Component {
  render() {
    const { tagOperationalType } = this.props;
   
    const Component = operationalComponent[tagOperationalType || 'guest'];

    return (
      Component ? <Component {...this.props } /> : null
    );
  }
}
