import { Divider } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

export default class WhatsNewView extends React.Component {

  componentDidMount() {
    const { articles, onSetWhatsNewHasUpdate } = this.props;
    const lastUpdate = _.first(articles) && _.first(articles).date;
    localStorage.setItem('lastWhatsNewUpdate', lastUpdate);
    onSetWhatsNewHasUpdate(false);
  }

  render() {
  const { articles } = this.props;
  return (
    <div>
      {_.map(articles, article => (
        <>
        {article && article.preview ? (
          <div className="hover-container" style={{ paddingBottom: 60 }} onClick={() => window.open(article.link)}>
            <div style={{ padding: 20, paddingBottom: 0 }}>
              <h3 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f', marginBottom: 5 }}>{article.renderedTitle ? article.renderedTitle : <Entity entity={articles.title} />}</h3>
              {article.date ? <h4>{moment(article.date).format('LL')}</h4> : null}
            </div>
            <div className="storeContentContainer" style={{ maxHeight: 600, overflow: 'scroll', padding: 25, paddingLeft: 20, paddingTop: 10, marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: article.preview }} />
            {article && article.previewImage ? <img style={{ minHeight: 150, width: '45%', margin: 'auto', display: 'flex' }} src={article.previewImage} /> : null}
          </div>
        ): null}
        <Divider style={{ zIndex: 100 }} />
        </>
      ))}
    </div>
  );
}
}