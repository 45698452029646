// @ts-nocheck
import { Card, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/HighlightOff';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { Form } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Field, change, reduxForm } from 'redux-form';
import { BLUE, MATCH_TAG_MODE, PERMISSION_ENTITIES, PERMISSIONS } from '../../_config/consts.js';
import * as FloorPlansActions from '../../redux/actions/floorplans.actions.js';
import * as LocksActions from '../../redux/actions/lock.actions';
import FloorPlanViewer from '../FloorPlans/FloorPlanViewer.jsx';
import BasicModal from '../Modals/BasicModal.jsx';
import AreaLocksSelectorField from '../forms/Elements/AreaLocksSelectorField.jsx';
import FormCardContainer from '../forms/Elements/FormCardContainer.jsx';
import CustomField from '../forms/Fields/CustomField.jsx';
import SelectableField from '../forms/Fields/SelectableInput/SelectableField.jsx';
import TranslatableOption from '../forms/Fields/TranslatableOption.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';

const styles = theme => ({
  attachment: {
    backgroundColor: '#BBBBBB',
    color: 'white',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: BLUE,
    }
  },
});

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'required';
  }

  if (!values.capacity) {
    errors.capacity = 'required';
  }
 
  if (values.capacity!=null&&values.capacity<=0) {
    errors.capacity = 'areaGreaterThanZero';
  }

  if (!values.timeoutNumber) {
    errors.timeoutNumber = 'required';
  }
 
  if (values.timeoutNumber!=null&&values.timeoutNumber<=0) {
    errors.timeoutNumber = 'required';
  }

  if (values.resetTime===null||values.resetTime===undefined) {
    errors.resetTime = 'required';
  }

  if (!values.resetTimeTimezone) {
    errors.resetTimeTimezone = 'required';
  }

  return errors;
};

@reduxForm({ form: 'AreaDetailsViewForm', validate })
@connect(state => ({ areas: state.areas, floorPlans: state.floorPlans, form: state.form.AreaDetailsViewForm, companyConfigurations: state.user.companyConfigurations }))
class AreaDetailsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      timezones: momentTz.tz.names(),
      guessedTimezone: momentTz.tz.guess(),
      floorPlanSmartLocks: [],
      selectedFloorPlanOption: null,
    }
  }

  async componentDidMount() {
    const { area } = this.props;
    this.init(area)
  }

  componentWillReceiveProps(nextProps) {
    const { area: areaOld } = this.props;
    const { area } = nextProps;
    if (area.id!==areaOld.id)
      this.init(area)
  }

  async init(area) {
    const { dispatch, timeUnitsOptions } = this.props;

    if (area.timeout) {
      if (area.timeout%(24*60)===0) {
        dispatch(change('AreaDetailsViewForm', 'timeoutNumber', area.timeout/(24*60)));
        dispatch(change('AreaDetailsViewForm', 'timeoutTimeUnit', timeUnitsOptions[2].value));
      }
      else if (area.timeout%60===0) {
        dispatch(change('AreaDetailsViewForm', 'timeoutNumber', area.timeout/(60)));
        dispatch(change('AreaDetailsViewForm', 'timeoutTimeUnit', timeUnitsOptions[1].value));
      }
      else {
        dispatch(change('AreaDetailsViewForm', 'timeoutNumber', area.timeout));
        dispatch(change('AreaDetailsViewForm', 'timeoutTimeUnit', timeUnitsOptions[0].value));
      }
    }
    else {
      dispatch(change('AreaDetailsViewForm', 'timeoutNumber', 1));
      dispatch(change('AreaDetailsViewForm', 'timeoutTimeUnit', timeUnitsOptions[1].value));
    }
    
    const { guessedTimezone } = this.state;

    let resetTime_initial = area.resetTime?area.resetTime:0
    let timezone_initial = area.resetTimeTimezone?area.resetTimeTimezone:guessedTimezone

    const resetTimeTimestamp_initial = moment().hour(parseInt(resetTime_initial)).minute(0).second(0).millisecond(0).valueOf();
    dispatch(change('AreaDetailsViewForm', 'resetTimeTimestamp', resetTimeTimestamp_initial));
    dispatch(change('AreaDetailsViewForm', 'resetTimeTimezone', timezone_initial));
    dispatch(change('AreaDetailsViewForm', 'resetTime', resetTime_initial));

    dispatch(change('AreaDetailsViewForm', 'smartLocksInIds', area.smartLocksInIds?area.smartLocksInIds:[]));
    dispatch(change('AreaDetailsViewForm', 'smartLocksOutIds', area.smartLocksOutIds?area.smartLocksOutIds:[]));
    
    dispatch(change('AreaDetailsViewForm', 'lockTagMatchingMode', MATCH_TAG_MODE.AT_LEAST_ONE_TAG));
    
    dispatch(change('AreaDetailsViewForm', 'capacity', area.capacity?area.capacity:1));
  }

  onFilterTimezones(value) {
    const timezones = momentTz.tz.names().filter(timezone => timezone.toLowerCase().includes(value.toLowerCase()));
    this.setState({ timezones });
  }

  onSelectResetTime(value) {
    const { dispatch, form } = this.props;
    let resetTimeHour = 0
    if (value!==undefined)
      resetTimeHour = (new Date(value).getHours())
    dispatch(change('AreaDetailsViewForm', 'resetTime', resetTimeHour));
  }

  onSelectTimezone(selectedOption) {
    const { dispatch } = this.props;
    const selectedTimezone = selectedOption && selectedOption.value ? selectedOption.value : momentTz.tz.guess();
    dispatch(change('AreaDetailsViewForm', 'resetTimeTimezone', selectedTimezone));
    this.setState({ guessedTimezone: selectedTimezone });
  }

  updateLockInIds(locks) {
    const { dispatch } = this.props;
    const smartLocksInIds_new = _.map(locks,e=>{return e.id})
    dispatch(change('AreaDetailsViewForm', 'smartLocksInIds', smartLocksInIds_new));
  }

  updateLockOutIds(locks) {
    const { dispatch } = this.props;
    const smartLocksOutIds_new = _.map(locks,e=>{return e.id})
    dispatch(change('AreaDetailsViewForm', 'smartLocksOutIds', smartLocksOutIds_new));
  }

  lockInToggleFromMap(lockId) {
    const { dispatch, form } = this.props;
    const smartLocksInIds_current = form && form.values && form.values.smartLocksInIds ? form.values.smartLocksInIds : [];
    const smartLocksOutIds_current = form && form.values && form.values.smartLocksOutIds ? form.values.smartLocksOutIds : [];

    const smartLocksInIds_new = smartLocksInIds_current.some(e=>e===lockId)?smartLocksInIds_current.filter(e=>e!==lockId):[lockId,...smartLocksInIds_current]
    dispatch(change('AreaDetailsViewForm', 'smartLocksInIds', smartLocksInIds_new));
    if (smartLocksOutIds_current.some(e=>e===lockId)) {
      const smartLocksOutIds_new = smartLocksOutIds_current.filter(e=>e!==lockId)
      dispatch(change('AreaDetailsViewForm', 'smartLocksOutIds', smartLocksOutIds_new));
    }
  }

  lockOutToggleFromMap(lockId) {
    const { dispatch, form } = this.props;
    const smartLocksInIds_current = form && form.values && form.values.smartLocksInIds ? form.values.smartLocksInIds : [];
    const smartLocksOutIds_current = form && form.values && form.values.smartLocksOutIds ? form.values.smartLocksOutIds : [];

    const smartLocksOutIds_new = smartLocksOutIds_current.some(e=>e===lockId)?smartLocksOutIds_current.filter(e=>e!==lockId):[lockId,...smartLocksOutIds_current]
    dispatch(change('AreaDetailsViewForm', 'smartLocksOutIds', smartLocksOutIds_new));
    if (smartLocksInIds_current.some(e=>e===lockId)) {
      const smartLocksInIds_new = smartLocksInIds_current.filter(e=>e!==lockId)
      dispatch(change('AreaDetailsViewForm', 'smartLocksInIds', smartLocksInIds_new));
    }
  }

  async onShowFloors() {
    const { dispatch } = this.props;
    this.lockMapModal.open();
  }
  
  onSelectFloorPlanDropdown(floorPlanOptionSelected) {
    const { dispatch } = this.props;
    this.setState({ selectedFloorPlanOption: floorPlanOptionSelected });
    if (floorPlanOptionSelected)
      this.onSelectFloorPlan(floorPlanOptionSelected.floorPlan)
    else {
      dispatch(FloorPlansActions.setSelectedFloorPlan({}));
      this.setState({ floorPlanSmartLocks: [], selectedFloorPlanOption: null });
    }
  }

  async onSelectFloorPlan(floorPlan) {
    const { dispatch } = this.props;
    const floorPlanSmartLocks = await dispatch(LocksActions.fetchLocksOnFloorPlan(floorPlan.id));
    dispatch(FloorPlansActions.setSelectedFloorPlan(floorPlan));
    this.setState({ selectedFloorPlanOption: { floorPlan, label: floorPlan.name, value: floorPlan.id }, floorPlanSmartLocks });
  }

  render() {
    const { classes, dispatch, areas, form, formStyle, timeUnitsOptions,
      locksFilteredByTags, availableLockTags, onLockTagsChange, onLockInputChange, onFetchMoreLocks, onLockFilterModeChange,
      floorPlans: { data: { content: floorPlansData }, selectedFloorPlan } } = this.props;
    const { timezones } = this.state;
    const { floorPlanSmartLocks, selectedFloorPlanOption } = this.state;

    const locks = areas?.locks?.content?areas.locks.content:[];

    const currentTimeoutTimeUnit = form?.values?.timeoutTimeUnit

    const resetTimeTimestamp_current = form?.values?.resetTimeTimestamp;
    const resetTimeHour_current = resetTimeTimestamp_current?(new Date(resetTimeTimestamp_current).getHours()):undefined
    const resetTimeString_current = resetTimeHour_current!==undefined?((resetTimeHour_current<10?"0":"")+resetTimeHour_current):undefined
    
    const smartLocksInIds_current = form && form.values && form.values.smartLocksInIds ? form.values.smartLocksInIds : [];
    const smartLocksOutIds_current = form && form.values && form.values.smartLocksOutIds ? form.values.smartLocksOutIds : [];
    const selectedLockDataIN = _.filter(locks, e=>{return _.some(smartLocksInIds_current,f=>f===e.id)});
    const selectedLockDataOUT = _.filter(locks, e=>{return _.some(smartLocksOutIds_current,f=>f===e.id)});

    const floor_selectedLockDataOUT_count = selectedFloorPlan && !_.isEmpty(selectedFloorPlan) && selectedFloorPlanOption && !_.isEmpty(selectedFloorPlanOption)?
      (selectedLockDataOUT.filter(e=>floorPlanSmartLocks.some(f=>f.id===e.id)).length):0
    const floor_selectedLockDataIN_count = selectedFloorPlan && !_.isEmpty(selectedFloorPlan) && selectedFloorPlanOption && !_.isEmpty(selectedFloorPlanOption)?
      (selectedLockDataIN.filter(e=>floorPlanSmartLocks.some(f=>f.id===e.id)).length):0;

    const canEditArea = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.AREA);

    return (
      <Form style={formStyle} autoComplete="off" style={{padding:20,paddingBottom:120}}>
        <FormCardContainer title="areaDataTitle" subtitle="areaDataSubtitle">
          <Entity
            componentClass={Field}
            name="name"
            componentAttribute="placeholder"
            entity="insertName"
            disabled={!canEditArea}
            title={<Entity entity="name" />}
            component={CustomField}
            mandatory
          />
          <div style={{fontSize:16,color:'rgb(63, 63, 63)',fontWeight:'bold',marginTop:20}}>
            {L20NContext.getSync('areaCapacity')}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row',paddingLeft:0 }}>
            <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="areaCapacityDescription"/>}</h4>
            <Entity
              componentClass={Field}
              name="capacity"
              type="number"
              textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
              componentAttribute="placeholder"
              component={CustomField}
              disabled={!canEditArea}
              containerStyle={{ width: 100 }}
              className="form-control-custom"
            />
          </div>
          <div style={{fontSize:16,color:'rgb(63, 63, 63)',fontWeight:'bold',marginTop:0}}>
            {L20NContext.getSync('areaTimeout')}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row',paddingLeft:0 }}>
            <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="areaInactivityInfo"/>}</h4>
            <Entity
              componentClass={Field}
              name="timeoutNumber"
              type="number"
              textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
              componentAttribute="placeholder"
              component={CustomField}
              disabled={!canEditArea}
              containerStyle={{ width: 100 }}
              className="form-control-custom"
            />
            <Field
              id="timeoutTimeUnit"
              name="timeoutTimeUnit"
              key={JSON.stringify(currentTimeoutTimeUnit)}
              component={CustomField}
              containerStyle={{ marginLeft: 20 }}
              className="form-control-select"
              componentClass="select"
              disabled={!canEditArea}
            >
              {_.map(timeUnitsOptions, type =>
                <Entity
                  key={type.value}
                  componentClass={TranslatableOption}
                  value={type.value}
                  componentAttribute="text"
                  entity={type.entity}
                />)}
            </Field>
            <h4 style={{ color: '#3f3f3f', marginLeft: 10, marginTop: 11, fontSize:16 }}>{<Entity entity="areaInactivityInfo2"/>}</h4>
          </div>
          <div style={{display:'flex',flexDirection:'row',marginTop:5}}>
            <div>
            <Entity
              componentClass={Field}
              title={<Entity entity="areaResetTime" />}
              name="resetTimeTimestamp"
              component={CustomField}
              is12HoursFormat={false}
              disabledMinutes={() => {
                return Array.from({ length: 60 }, (_, i) => i).filter(minute => minute !== 0);
              }}
              type="antdTimePicker"
              placeholder="--"
              disabled={!canEditArea}
              onInputChange_antdTimePicker={(value) => this.onSelectResetTime(value)}
              mandatory
            />
            </div>
            <div style={{flexGrow:'1',marginLeft:40,maxWidth:500}}>
              <Field
                name="resetTimeTimezone"
                title={<Entity entity="selectTimezone" />}
                component={SelectableField}
                options={_.map(timezones, timezone => ({ value: timezone, label: timezone }))}
                titleStyle={{ fontSize: 15, margin:0, marginBottom:6, marginTop:4 }}
                placeholder={<Entity entity="search" />}
                containerstyle={{ widht: '100%' }}
                onInputChange={value => this.onFilterTimezones(value)}
                onSelect={value => this.onSelectTimezone(value)}
                required
                disabled={!canEditArea}
              />
            </div>
          </div>
          
          {resetTimeString_current!==undefined ? (
            <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <InfoIcon style={{ fontSize: 20, color: '#3f3f3f', marginRight: 12 }} />
              <h5 style={{ fontSize: 14, lineHeight: '1.5em', color: '#3f3f3f', margin:0 }}>
                <Entity
                  entity="areaResetTimeInfo"
                  key={`${resetTimeString_current}`}
                  data={{
                    resetTime: resetTimeString_current,
                  }}
                />
              </h5>
            </div>
          ) : null}
        </FormCardContainer>
        <FormCardContainer title="areaSmartLocks" subtitle="areaSmartLocksSubtitle">
          <AreaLocksSelectorField
            selectorFieldName="lockTagMatchingMode"
            name="resourceSmartLockTags"
            tags={availableLockTags}
            selectedTags={locksFilteredByTags}
            form={form}
            title="locksTags"
            placeholder="insertLockTagsForArea"
            listTitle="selectedLocksForArea"
            selectionSectionTitleIN="areaLocksSelectionTitleIN"
            selectionSectionSubtitleIN="areaLocksSelectionSubtitleIN"
            selectionSectionEmptySubtitleIN="areaLocksSelectionEmptyIN"
            selectionSectionTitleOUT="areaLocksSelectionTitleOUT"
            selectionSectionSubtitleOUT="areaLocksSelectionSubtitleOUT"
            selectionSectionEmptySubtitleOUT="areaLocksSelectionEmptyOUT"
            isOptional={true}
            disabled={!canEditArea}
            initialValuesIN={selectedLockDataIN ? selectedLockDataIN : []}
            initialValuesOUT={selectedLockDataOUT ? selectedLockDataOUT : []}
            onFetchMoreItems={page => onFetchMoreLocks(page)}
            onTagsChange={values => onLockTagsChange(values)}
            onInputChange={value => onLockInputChange(value)}
            onFilterModeChange={values => onLockFilterModeChange(values)}
            onToggleLockInId={locks => this.updateLockInIds(locks)}
            onToggleLockOutId={locks => this.updateLockOutIds(locks)}
            onShowFloors={() => this.onShowFloors()}
          />
        </FormCardContainer>
        <BasicModal
          ref={(m) => { this.lockMapModal = m; }}
          hideCloseButton
          body={
            <div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
                <h3 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f' }} >{L20NContext.getSync('locks')}</h3>
                <IconButton onClick={() => this.lockMapModal.close()}>
                  <CloseIcon style={{ color: '#e95841', fontSize: 35 }} />
                </IconButton>
              </div>
              <div style={{ minWidth: 325, marginRight: 15, marginTop: 10 }}>
                <Select
                  value={selectedFloorPlanOption}
                  placeholder={<Entity entity="selectFloorPlan" />}
                  noResultsText={<Entity entity="noResultsFound" />}
                  onChange={val => this.onSelectFloorPlanDropdown(val)}
                  options={_.map(floorPlansData, floorPlan => ({ value: floorPlan.id, label: floorPlan.name, floorPlan: floorPlan }))}
                />
              </div>
              {selectedFloorPlan && !_.isEmpty(selectedFloorPlan) && selectedFloorPlanOption && !_.isEmpty(selectedFloorPlanOption) ? (
                <div style={{display:'flex',flexDirection:'column'}}>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                    <Card
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 40, width: 25, height: 25, backgroundColor: 'white',}}
                      elevation={2}
                    >
                      <MeetingRoomIcon style={{ fontSize: 17, marginRight: 0, color: '#00987C', marginLeft: 0 }} />
                    </Card>
                    <h4 style={{ marginLeft: 10, color: '#3f3f3f', fontWeight:'bold' }} >
                      {floor_selectedLockDataIN_count}
                    </h4>
                    <h4 style={{ marginLeft: 5, color: '#3f3f3f' }} >
                      {L20NContext.getSync('areaFloorLocksIN')}
                    </h4>
                  </div>
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:0}}>
                    <Card
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 40, width: 25, height: 25, backgroundColor: 'white',}}
                      elevation={2}
                    >
                      <ExitToAppIcon style={{ fontSize: 17, marginRight: 0, color: '#98007C', marginLeft: 0 }} />
                    </Card>
                    <h4 style={{ marginLeft: 10, color: '#3f3f3f', fontWeight:'bold' }} >
                      {floor_selectedLockDataOUT_count}
                    </h4>
                    <h4 style={{ marginLeft: 5, color: '#3f3f3f' }} >
                      {L20NContext.getSync('areaFloorLocksOUT')}
                    </h4>
                  </div>
                </div>
              ):null}
              {selectedFloorPlan && !_.isEmpty(selectedFloorPlan) && selectedFloorPlanOption && !_.isEmpty(selectedFloorPlanOption) ? (
                <FloorPlanViewer
                  hideCard
                  showActions
                  selectedElements={[]}
                  selectedLocksAreaIN={selectedLockDataIN?selectedLockDataIN:[]}
                  selectedLocksAreaOUT={selectedLockDataOUT?selectedLockDataOUT:[]}
                  floorPlanSmartLocks={floorPlanSmartLocks}
                  onToggleAreaIN={(lockId)=>{this.lockInToggleFromMap(lockId)}}
                  onToggleAreaOUT={(lockId)=>{this.lockOutToggleFromMap(lockId)}}
                  onOpenLockDetails={() => {}}
                  onCloseModal={() => {
                    this.lockMapModal.close();
                  }}
                />
              ) : null}
            </div>
          }
        />
      </Form>
    );
  }
} 

export default withStyles(styles)(AreaDetailsView);
