// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as AccessProfiles from '../../redux/actions/accessProfiles.actions';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import AccessProfileRow from './AccessProfileRow.jsx';

@connect(state => ({
  accessProfiles: state.accessProfiles,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
  routing: state.router,
}))

class AccessProfilesListView extends React.Component {
  
  appendAccessProfiles(page) {
    const { dispatch } = this.props;
    dispatch(AccessProfiles.appendAccessProfiles(page));
  }


  render() {
    const { canCreateItem, accessProfiles: { selectedAccessProfile, data: { content: accessProfileData, pagination: { number: currentPage, totalPages } } }, onSelectAccessProfile, onNewAccessProfile, viewLoading, themeName } = this.props;
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 40, width: '90%', overflow: 'auto' }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => this.appendAccessProfiles(currentPage + 1)}
          hasMore={currentPage + 1 < totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '41%' }}>
            {!_.isEmpty(accessProfileData) ?
              _.map(accessProfileData, accessProfile =>
                <AccessProfileRow
                  themeColor={themeName}
                  key={accessProfile.id}
                  isSelected={selectedAccessProfile && selectedAccessProfile.id === accessProfile.id}
                  accessProfile={accessProfile}
                  onClick={() => onSelectAccessProfile(accessProfile)}
                />)
                : (
                  <div>
                    {!viewLoading ? (
                      <EmptyView
                        title="noAccessProfiles"
                        onNewEntity={canCreateItem ? () => onNewAccessProfile() : null}
                        newEntityTitle="addAccessProfiles"
                        subtitle="startAccessProfiles"
                        iconName="icon-simple-line-icons-user-following"
                      />) : <ListLoadingView />}
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

export default AccessProfilesListView;
