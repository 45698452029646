import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import qs from 'qs';
import _ from 'lodash';
import { connect } from 'react-redux';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PlatformUsersListView from '../components/PlatformUsers/PlatformUsersListView.jsx';
import PlatformUsersTable from '../components/PlatformUsers/PlatformUsersTable.jsx';
import PlatformUsersOperationalSection from '../components/PlatformUsers/PlatformUsersOperationalSection.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import * as PlatformUsersActions from '../redux/actions/platformUsers.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import * as UserActions from '../redux/actions/user.actions';
import * as RolesActions from '../redux/actions/roles.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as GuestActions from '../redux/actions/guest.actions.js';
import TranslatableOption from '../components/forms/Fields/TranslatableOption.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import { LICENSE_TYPES, PERMISSIONS, PERMISSION_ENTITIES, SUBSCRIPTION_TYPES, VIEW_MODES } from '../_config/consts.js';
import { change, reset, initialize, destroy } from 'redux-form';
import { localizeHelpCenterLink, saveDataToLocalStorage } from '../_config/utils.js';
import BasicModal from '../components/Modals/BasicModal.jsx';
import GuestDeleteView from '../components/Guests/GuestDeleteView.jsx';


let filterTimeout;
@connect(state => ({ form: state.form.PlatformUserForm, routing: state.router, roles: state.roles, platformUsers: state.platformUsers, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class PlatformUsers extends React.Component {

  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('managersViewMode');
    this.state = {
      selectedTags: null,
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
      selectedUserToDelete: null,
    };
  }

  async componentWillMount() {
    const { dispatch, routing } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    dispatch(PlatformUsersActions.setSelectedPlatformUser({}));
    await dispatch(RolesActions.fetchRoles());
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    const userId = parsed.userId;
    if (userId) {
      const newGuest = await dispatch(GuestActions.getGuestDetails(userId));
      this.onOpenUser(newGuest);
    }
    this.onFetchAllRolesUsers();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(PlatformUsersActions.setSelectedPlatformUser({}));
    dispatch(PlatformUsersActions.setOperationalMode(false));
  }

  async onFetchUsers() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(PlatformUsersActions.fetchPlatformUsers());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onOrderChanged() {
    const { dispatch } = this.props;
    dispatch(PlatformUsersActions.fetchPlatformUsers())
  }

  onListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    saveDataToLocalStorage('managersViewMode', selectedViewMode);
    if (activeTab === 0) {
      dispatch(PlatformUsersActions.resetPlatformUsersPaginationData());
      dispatch(PlatformUsersActions.fetchPlatformUsers(0));
    }
  }

  async onFetchAllRolesUsers() {
    const { dispatch, roles: { roles: { content: rolesData } } } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    dispatch(PlatformUsersActions.resetPlatformUsersFilters());
    const allRolesIds = _.map(_.filter(rolesData, r => r.id !== 5), role => role.id);
    
    dispatch(PlatformUsersActions.setPlatformUsersFilter('roleIds', [allRolesIds]));
    try {
      await dispatch(PlatformUsersActions.fetchPlatformUsers());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onCloseOperationalSection() {
    const { dispatch } = this.props;
    dispatch(PlatformUsersActions.setSelectedPlatformUser({}));
    dispatch(PlatformUsersActions.setOperationalMode(false));
  }

  onNewUser() {
    const { dispatch } = this.props;
    dispatch(PlatformUsersActions.setOperationalMode(true));
    dispatch(destroy('PlatformUserForm'));
    dispatch(PlatformUsersActions.setSelectedPlatformUser({}));
    dispatch(change('PlatformUserForm', 'canPromoteGuests', true));
    const isACLPluginActive = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENTERPRISE]);
    if (!isACLPluginActive) {
      dispatch(change('PlatformUserForm', 'roleIds', [1]));
    }
  }
  
  onFilterUsers(value) {
    const { dispatch } = this.props;
    dispatch(PlatformUsersActions.setPlatformUsersFilter('search', _.trim(value)));
    if (!value || (value.length >= 2)) {
      if (filterTimeout) clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => this.onFetchUsers(), 500);
    }
  }

  onResetUsersFilters() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    dispatch(PlatformUsersActions.resetPlatformUsersFilters());
    dispatch(PlatformUsersActions.resetPlatformUsersData());
    this.onCloseOperationalSection();
    this.onFetchAllRolesUsers();
  }

  onSelectRoleFilter(value) {
    const { dispatch, roles: { roles: { content: rolesData } } } = this.props;
    if (value === 'ALL') return this.onFetchAllRolesUsers();
    const selectedRole = _.find(rolesData, role => role.name === value);
    dispatch(PlatformUsersActions.setPlatformUsersFilter('roleIds', [selectedRole.id]));
    this.onCloseOperationalSection();
    this.onFetchUsers();
  }

  onOpenUser(user) {
    const { dispatch } = this.props;
    dispatch(PlatformUsersActions.setSelectedPlatformUser(user));
    const isGuestManager = _.find(user.roles, role => role.id === 5);
    const rolesIds = _.map(user.roles, role => role.id);
    const filteredRoles = _.filter(user.roles, role => role.id !== 5);
    const userRole = _.map(filteredRoles, (role) => {
      const formattedRole = RolesActions.formatRole(role);
      return { value: String(role.id), label: formattedRole.label };
    });
    const isACLPluginActive = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENTERPRISE]);
    const userDTO = isACLPluginActive ? { ...user, userRole } : { ...user, userRole: { label: 'Manager', value: '1' } };
    dispatch(initialize('PlatformUserForm', userDTO));
    dispatch(change('PlatformUserForm', 'isGuestManager', isGuestManager));
    dispatch(change('PlatformUserForm', 'roleIds', rolesIds));
    dispatch(PlatformUsersActions.setOperationalMode(true));
  }


  async onResetUserPassword(user) {
    const { dispatch } = this.props;
    try {
      dispatch(UserActions.resetPassword(user.id));
      dispatch(PlatformUsersActions.setOperationalMode(false));
      dispatch(PlatformUsersActions.setSelectedPlatformUser({}));
    } catch (error) {}
  }

  onConfirmDisablePlatformUser(user) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'disableManagerConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDisablePlatformUser(user),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };

    dispatch(ModalActions.showModal(params));
  }

  async onDisablePlatformUser(user) {
    const { dispatch, onFetchPlatformUsers } = this.props;
    try {
      await dispatch(UserActions.disableUser(user.id));
      if (onFetchPlatformUsers) onFetchPlatformUsers();
      await dispatch(PlatformUsersActions.fetchPlatformUsers());
      dispatch(PlatformUsersActions.setOperationalMode(false));
      dispatch(PlatformUsersActions.setSelectedPlatformUser({}));
    } catch (error) {}
  }

  onDeleteUserRequest(guest) {
    this.setState({ selectedUserToDelete: guest });
    this.userDeleteModal.open();
  }

  async onDeleteUserConfirm(user, oblivionSelected, deleteAllStandardDevices) {
    const { dispatch, form } = this.props;
    try {
      const keepManagerAsGuest = form && form.values && form.values.keepManagerAsGuest;
      dispatch(UtilsActions.setSpinnerVisibile(true));
      if (keepManagerAsGuest) {
        await dispatch(PlatformUsersActions.removeManagerAccessToUser(user));
      } else {
        await dispatch(UserActions.deleteUser(user.id, oblivionSelected, deleteAllStandardDevices));
      }
      this.userDeleteModal.close();
      dispatch(PlatformUsersActions.removePlatformUser(user.id));
      dispatch(PlatformUsersActions.setOperationalMode(false));
      dispatch(PlatformUsersActions.setSelectedPlatformUser({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'userDeleted' }} /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorDeleteUserMessage" /></h6>),
        },
      }));
    }
  }

  async onFetchUserOnPage(page) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(PlatformUsersActions.fetchPlatformUsers(page));
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onOpenUsersInfoPage() {
    const formattedURL = localizeHelpCenterLink('luckey-users-and-roles-a-small-guide');
    window.open(formattedURL);
  }


  render() {
    const { roles: { roles: { content: rolesData } }, dispatch, platformUsers: { selectedUser, data: { content: platformUsersData }, isOperationalMode }, themeName, viewLoading } = this.props;
    const { activeTab, selectedUserToDelete } = this.state;
    const rolesFilterData = [{ id: 0, name: 'ALL', label: 'allRoles' }, ..._.filter(rolesData, r => r.id !== 5)]; // Omit guest role
    const canCreateGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    const isACLPluginActive = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENTERPRISE]);
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={canCreateGuest ? () => this.onNewUser() : null}
          newEntityTitle="addPlatformUser"
          newEntityIconName="icon-simple-line-icons-user-following"
          isLoading={viewLoading}
          onFilterChange={value => this.onFilterUsers(value)}
          onSearchReset={() => this.onResetUsersFilters()}
          onSelectOption={isACLPluginActive ? value => this.onSelectRoleFilter(value) : null}
          onInfo={() => this.onOpenUsersInfoPage()}
          selectableFieldName="roleIds"
          selectableOptions={_.map(rolesFilterData, role =>
            <Entity
              key={role.name}
              componentClass={TranslatableOption}
              value={role.name}
              componentAttribute="text"
              entity={role.label}
            />)}
        />
        {!isLuckeyLite && platformUsersData && !_.isEmpty(platformUsersData) ? (
          <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onListModeChange(index)}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          </div>
        ) : null}
        {activeTab === 0 && !isLuckeyLite ? (
          <PlatformUsersTable
            onSelectUser={user => this.onOpenUser(user)}
            onDisableUserRequest={user => this.onConfirmDisablePlatformUser(user)}
            onResetUserPassword={user => this.onResetUserPassword(user)}
            onDeleteUserRequest={user => this.onDeleteUserRequest(user)}
            onFetchUserOnPage={page => this.onFetchUserOnPage(page)}
            onNewPlatformUser={() => this.onNewUser()}
            onRefreshUsers={() => this.onFetchUsers()}
            onOrderChanged={() => this.onOrderChanged()}
          />
        ) : null}
        {activeTab === 1 || isLuckeyLite ? (
          <PlatformUsersListView
            listContainerStyle={{ top: isLuckeyLite ? 140 : 190 }}
            viewLoading={viewLoading}
            onSelectUser={user => this.onOpenUser(user)}
            onNewUser={() => this.onNewUser()}
          />
        ) : null}
        <OperationalView
          themeName={themeName}
          isVisible={isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          title={<Entity entity="sectionTitle" data={{ name: 'platformUsersOperationals' }} />}
        >
          <PlatformUsersOperationalSection
            selectedUser={selectedUser}
            onDeleteUser={user => this.onDeleteUserRequest(user)}
            onDisableUserRequest={user => this.onConfirmDisablePlatformUser(user)}

          />
        </OperationalView>
        <BasicModal
          ref={(m) => { this.userDeleteModal = m; }}
          hideCloseButton
          body={
            <div>
              <GuestDeleteView
                guest={selectedUserToDelete}
                onCloseModal={() => this.userDeleteModal.close()}
                onDeleteGuestConfirm={(oblivionSelected, deleteAllStandardDevices) => this.onDeleteUserConfirm(selectedUserToDelete, oblivionSelected, deleteAllStandardDevices)}
              />
            </div>
          }
        />
      </div>
    );
  }
} 

export default PlatformUsers;
