import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';

const validate = values => {
  const errors = {};
  if (!values.code) {
    errors.code = 'required';
  }

  if (!values.spaceName) {
    errors.spaceName = 'required';
  }
  if (!values.clientId) {
    errors.clientId = 'required';
  }
  if (!values.clientSecret) {
    errors.clientSecret = 'required';
  }
  return errors;
};

@reduxForm({ form: 'CobotActivationForm', validate })
export default class CobotActivationForm extends React.Component {

  render() {
    return (
      <div>
        <Entity
          name="spaceName"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertCobotSpaceName"
          title="Cobot Space"
          component={CustomField}
        />
        <Entity
          name="code"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertCobotToken"
          title="Cobot Access Token"
          component={CustomField}
        />
        <Entity
          name="clientId"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertCobotToken"
          title="Cobot Client Id"
          component={CustomField}
        />
        <Entity
          name="clientSecret"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertCobotToken"
          title="Cobot Client Secret"
          component={CustomField}
        />
        <Field
          name="syncAllUsers"
          label={<Entity entity="synchronizeAllUsers" />}
          titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
          component={MDCheckBoxField}
        />
        <h5 style={{ marginBottom: 50 }}><Entity entity="synchronizeAllUsersWhenActivate" /></h5>
      </div>
    );
  }
}
