// @ts-nocheck
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as CardActions from '../../redux/actions/card.actions';
import { BLUE, GREEN, INVITATION_STATUS_PARAMETERS, PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';
import { getColorFromThemeName } from '../../_config/utils';
import RegistryIconCustom from '../CustomIcons/RegistryIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  button: {
    color: 'red',
    fontSize: 15,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, invitations: state.invitations, themeName: state.settings.items.theme.data.themeName }))
class InvitationTableView extends React.Component {

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
  }

  render() {
    const {
      classes,
      invitations: { data: { content: invitationsData, pagination: { number: currentPage, size, totalPages, totalElements }, filters } },
      invitations: { selectedInvitation },
      viewLoading,
      themeName,
      onDeleteInvitation,
      onNewInvitation,
      onSelectInvitation,
      onFetchInvitationsOnPage,
      onShowInfo,
      onShowInvitationEventsView,
    } = this.props;
    const canCreateNewCredentials = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.CREDENTIAL_RULE);
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(invitationsData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell colSpan={3}>
                      <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="invitations" /></h3>
                    </TableCell>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="invitationsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchInvitationsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchInvitationsOnPage(page)}
                        />
                      )}
                    />
                    </TableRow>
                  <TableRow>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="invitationName" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="invitationCode" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="numberOfRedeems" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="invitationTimeProfile" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="invitationValidity" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="actions" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(invitationsData, (invitation) => {
                    const days = invitation && invitation.daysOfTheWeek && _.orderBy(invitation.daysOfTheWeek);
                    const daysOptions = _.map(moment.weekdays(true), weekday => ({
                      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
                      selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
                    }));
                    const numberOfDevicesString = invitation && invitation.numberOfDevices === 0 ? ` - ${L20NContext.getSync('unlimited')}` : ` / ${invitation.numberOfDevices}`;
                    return (
                      <TableRow
                        key={invitation.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={event => onSelectInvitation(invitation)}
                        selected={selectedInvitation && selectedInvitation.id === invitation.id}
                      >
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h5 style={{ fontWeight: 'bold', fontSize: 16 }}>{`${invitation.name}`}</h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h5 style={{ fontWeight: 'bold', fontSize: 16 }}>{`${invitation.code}`}</h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <h5 style={{ fontWeight: 'bold', fontSize: 16 }}>{`${invitation.numberOfRedeems} ${numberOfDevicesString}`}</h5>
                            <IconButton
                              style={{ marginLeft: 5 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                onShowInvitationEventsView(invitation);
                              }}
                            >
                              <RegistryIconCustom style={{ width: 22, color: BLUE }} />
                            </IconButton>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ paddingBottom: 6, minWidth: 280, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 5 }}>{`${moment(invitation.invitationCredentialTimeIntervalFrom).format('LT')} - ${moment(invitation.invitationCredentialTimeIntervalTo).format('LT')}`}</h5>
                            <div style={{ marginTop: 5, display: 'flex', justifyContent: 'flex-end' }}>
                              {_.map(daysOptions, option => (
                                <h6 key={option.label} style={{ margin: 0, marginRight: 7, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
                                  {option.label.toUpperCase()}
                                </h6>
                                ))}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ paddingBottom: 6, minWidth: 280, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            {invitation.invitationStatus ? (
                              <h5 style={{ fontWeight: 'bold', marginTop: 4, marginBottom: 6 }}>
                                <Entity
                                  key={`status_${invitation.stainvitationStatustus}`}
                                  style={{ textTransform: 'uppercase', color: INVITATION_STATUS_PARAMETERS[invitation.invitationStatus].color }}
                                  entity={INVITATION_STATUS_PARAMETERS[invitation.invitationStatus].string}
                                />
                              </h5>
                            ) : null}
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                                {`${moment(invitation.dateInterval.from).format('DD MMMM YYYY')} - ${moment(invitation.dateInterval.to).format('DD MMMM YYYY')}`}
                              </h5>
                            </div>
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 5 }}>{`${moment(invitation.dateInterval.from).format('LT')} - ${moment(invitation.dateInterval.to).format('LT')}`}</h5>
                          </div>
                        </TableCell>
                        <TableCell>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            onDeleteInvitation(invitation);
                          }}
                          className={classes.button}
                        >
                          <Entity entity="delete" />
                        </Button>
                          </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="invitationsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchInvitationsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchInvitationsOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title="noInvitations"
                    onNewEntity={onNewInvitation ? () => onNewInvitation() : null}
                    onShowInfo={() => onShowInfo()}
                    newEntityTitle="createInvitation"
                    subtitle="startAddInvitations"
                    iconName="icon-simple-line-icons-user-following"
                  />) : <ListLoadingView />}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(InvitationTableView);
