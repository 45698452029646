import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import ObjectIcon from '@material-ui/icons/HdrWeak';

const ItemAccessMethod = ({ onClick }) => (
  <div
    style={{ padding: 5, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10, borderRadius: 5, marginLeft: 5, marginRight: 10 }}
    onClick={onClick ? () => onClick() : null}
  >
    <ObjectIcon style={{ color: '#3f3f3f', fontSize: 20, marginTop: 3 }} />
    <h5 className="link-label" style={{ margin: 0, marginTop: 2, fontSize: 15, marginLeft: 10, color: '#3f3f3f' }}>
      <Entity entity="objectAssigned" />
    </h5>
  </div>
);

export default ItemAccessMethod;
