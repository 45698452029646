import React from 'react';
import { connect } from 'react-redux';
import MDButton from '../../components/MDButton/MDButton.jsx';

@connect((state) => ({ settings: state.settings }))
class LoginWithCobot extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      showCobotLogin: false,
    };
  }

  async onCobotLoginCode() {
    const { idp } = this.props;
    const currentURL = `${window.location.protocol}//${window.location.host}`;
    window.location.replace(`https://www.cobot.me/oauth/authorize?response_type=code&client_id=${idp.clientId}&response_type=code&scope=vms.all&state=COBOT&redirect_uri=${currentURL}`);
  }

  render() {
    return (
      <div>
        <MDButton
          type="submit"
          backgroundColor="#145ba2"
          icon={<img src="/imgs/common/integrationLogos/cobot-white.png" style={{ height: 25 }} />}
          containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
          onClick={() => this.onCobotLoginCode()}
        />
      </div>
    );
  }
} 

export default LoginWithCobot;
