import {
  Col,
  Row
} from '@sketchpixy/rubix';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { LANGUAGES } from '../../../_config/consts.js';
import CustomField from '../Fields/CustomField.jsx';
import DateRangeField from '../Fields/DatePickers/DateRangePicker/DateRangeField.jsx';
import DaysOfTheWeekPicker from '../Fields/DaysOfTheWeekPicker.jsx';


@connect(state => ({ form: state.form.TagDatesForm, language: state.settings.language }))
class ResourcesTimeSelector extends React.Component {
  render() {
    const { checkedInterval, language, disabled } = this.props;
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    return (
      <div style={{ marginTop: 30 }}>
        <Field
          title={<Entity entity="resourceTimeFrame" />}
          name="credentialTimeframe"
          checkedInterval={checkedInterval}
          component={DateRangeField}
          minimumNights={0}
          disabled={disabled}
          options={[
            { value: 1, label: L20NContext.getSync('singleYear', { year: 1 }) },
            { value: 5, label: L20NContext.getSync('multipleYears', { years: 5 }) },
            { value: 10, label: L20NContext.getSync('multipleYears', { years: 10 }) },
          ]}
        />
        <Col xs={6}>
          <Entity
            componentClass={Field}
            title={<Entity entity="resourceIntervalFrom" />}
            name="timeIntervalFrom"
            component={CustomField}
            type="antdTimePicker"
            is12HoursFormat={is12HoursFormat}
            placeholder="Select a time"
          />
        </Col>
        <Col xs={6}>
          <Entity
            componentClass={Field}
            title={<Entity entity="resourceIntervalTo" />}
            name="timeIntervalTo"
            component={CustomField}
            type="antdTimePicker"
            is12HoursFormat={is12HoursFormat}
            placeholder="Select a time"
          />
        </Col>
        <Row>
          <Col xs={12}>
            <Entity
              componentClass={Field}
              title={<Entity entity="daysOfTheWeek" />}
              name="daysOfTheWeek"
              component={DaysOfTheWeekPicker}
              containerstyle={{ paddingLeft: 25, marginTop: 10 }}
              type="Selection"
            />
          </Col>
        </Row>
      </div>
    );
  }
} 

export default ResourcesTimeSelector;
