import {
  Icon, Table
} from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import TablePaginator from './TablePaginator.jsx';

const SortByButton = ({ title, onClick, value, selected }) => (
  <div onClick={() => onClick(value)} style={{ textDecoration: (selected ? 'underline' : 'none') }}>
    <h4 style={{ fontSize: 14, margin: 5, fontWeight: 'bold' }}><Entity key={title} entity={title} /></h4>
  </div>
);

const columns = [{
  id: 'resourceName',
  title: 'resource',
},
{
  id: 'resourceType',
  title: 'type',
},
{
  id: 'users',
  title: 'users',
},
{
  id: 'companyName',
  title: 'companyName',
},
{
  id: 'totalReservations',
  title: 'totalReservations',
},
{
  id: 'lostReservations',
  title: 'lostReservations',
},
{
  id: 'successfulReservations',
  title: 'successfulReservations',
},
{
  id: 'penaltyHours',
  title: 'penaltyHours',
},
{
  id: 'usageHours',
  title: 'usageHours',
},
{
  id: 'totalHours',
  title: 'totalHours',
},
];

export default class ResourcesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsAccordions: [],
      sortBy: null,
    };
  }

  onRowClick(index, value) {
    const { onAccordionOpened } = this.props;
    const { rowsAccordions } = this.state;
    const accordions = _.reject(rowsAccordions, el => el === index);
    if (_.size(rowsAccordions) === _.size(accordions)) {
      accordions.push(index);
      if (onAccordionOpened) onAccordionOpened(index, value);
    }
    this.setState({ rowsAccordions: accordions });
  }

  onSetSortBy(value) {
    const { sortBy } = this.state;
    this.setState({ sortBy: sortBy === value ? null : value });
  }

  resourcePaginationSelected(index) {
    const { onFetchResources } = this.props;
    if (onFetchResources) onFetchResources(index - 1);
  }

  resourceDetailedPaginationSelected(resourceId, index) {
    const { onFetchDetailedResources } = this.props;
    if (onFetchDetailedResources) onFetchDetailedResources(resourceId, index - 1);
  }

  render() {
    const { rows, highlightGreaterThan, cachedGuestsMap, resourceFilter } = this.props;
    const { rowsAccordions, sortBy } = this.state;
    const resources = !resourceFilter ? rows.data.content : _.filter(rows.data.content, res => res.resource.name.toLowerCase().includes(resourceFilter));
    return (
      <div>
        <Table responsive hover bordered>
          <thead>
            <tr>
              <th />
              {_.map(columns, (column, index) => {
                return (<th className="table-align-center" style={{ backgroundColor: '#f0f0f0' }}>
                  <SortByButton
                    title={column.title}
                    value={index}
                    selected={(sortBy === index)}
                    onClick={value => this.onSetSortBy(value)}
                  />
                </th>);
              })
              }
            </tr>
          </thead>
          <tbody>
            {_.map(resources, (row, index) =>
              [<tr onClick={() => this.onRowClick(index, row)}>
                <td className="table-align-center">
                  <Icon
                    className="label-responsive"
                    style={{ fontSize: 15 }}
                    glyph={_.includes(rowsAccordions, index) ? 'icon-fontello-up-open' : 'icon-fontello-down-open'}
                  />
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {row.resource.name}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {row.resource.type.name}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {row.users}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  -
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {row.totalReservations}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {row.lostReservations}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {row.totalReservations - row.lostReservations}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    backgroundColor: 'white',
                    fontSize: 14
                  }}
                >
                  {row.deletedByUserWithPenaltyHours + row.lostHours}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    backgroundColor: 'white',
                    fontSize: 14
                  }}
                >
                  {row.usageHours - row.lostHours}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    backgroundColor: (row.usageHours > highlightGreaterThan ? 'yellow' : 'white'),
                    fontWeight: 'bold',
                    fontSize: 14
                  }}
                >
                  {row.usageHours + row.deletedByUserWithPenaltyHours}
                </td>
              </tr>,
                _.includes(rowsAccordions, index) && rows.detailedDataById[row.resource.id] && _.map(rows.detailedDataById[row.resource.id].content, value =>
                  <tr>
                    <td></td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      -
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      -
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      {cachedGuestsMap[value.userId] && cachedGuestsMap[value.userId].firstname ? `${cachedGuestsMap[value.userId].firstname} ${cachedGuestsMap[value.userId].lastname}` : '/'}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      {(cachedGuestsMap[value.userId] && cachedGuestsMap[value.userId].companyName) || '/'}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      {value.totalReservations}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      {value.lostReservations}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      {value.totalReservations - value.lostReservations}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        backgroundColor: 'white',
                        fontSize: 14
                      }}
                    >
                      {value.deletedByUserWithPenaltyHours + value.lostHours}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        backgroundColor: 'white',
                        fontSize: 14
                      }}
                    >
                      {value.usageHours - value.lostHours}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        backgroundColor: (value.usageHours > highlightGreaterThan ? 'yellow' : 'white'),
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      {value.usageHours + value.deletedByUserWithPenaltyHours}
                    </td>
                  </tr>),
                _.includes(rowsAccordions, index) && rows.detailedDataById[row.resource.id] &&
                  <tr>
                    <td colSpan={columns.length + 1} style={{ backgroundColor: 'white' }}>
                      <TablePaginator
                        style={{ marginBottom: 0, marginLeft: 40 }}
                        pagination={{
                          page: rows.detailedDataById[row.resource.id].pagination && rows.detailedDataById[row.resource.id].pagination.number ? rows.detailedDataById[row.resource.id].pagination.number + 1 : 1,
                          beginPages: 1,
                          endPages: 1,
                          sidePages: 3,
                          pages: rows.detailedDataById[row.resource.id].pagination && rows.detailedDataById[row.resource.id].pagination.totalPages ? rows.detailedDataById[row.resource.id].pagination.totalPages : 1,
                          onSelect: pageIndex => this.resourceDetailedPaginationSelected(row.resource.id, pageIndex),
                        }}
                      />
                    </td>
                  </tr>,
              ],
            )}
          </tbody>
        </Table>
        <TablePaginator
          style={{ marginBottom: 0 }}
          pagination={{
            page: rows.data.pagination && rows.data.pagination.number ? rows.data.pagination.number + 1 : 1,
            beginPages: 1,
            endPages: 1,
            sidePages: 3,
            pages: rows.data.pagination && rows.data.pagination.totalPages ? rows.data.pagination.totalPages : 1,
            onSelect: pageIndex => this.resourcePaginationSelected(pageIndex),
          }}
        />
      </div>
    );
  }
}
