import PrivacyIcon from '@material-ui/icons/Lock';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import CloudDownIconCustom from '../components/CustomIcons/CloudDownIconCustom.jsx';
import MDButton from '../components/MDButton/MDButton.jsx';
import { LUCKEY_SERVICE_STATUS_LINK, ORANGE } from '../_config/consts.js';


@connect(state => ({}))
class ServiceUnavailableView extends React.Component {

  onReload() {
    const { dispatch } = this.props;
    dispatch(push('/'));
  }

  onGoToLogin() {
    const { dispatch } = this.props;
    dispatch(push('/login'));
  }

  render() {
    return (
      <div style={{ overflow: 'scroll', display: 'flex', flexDirection: 'column', backgroundColor: 'white', height: '100vh', padding: 10 }}>
        <img src="/imgs/common/logoJagoDark.png" alt="Sofia" style={{ margin: 'auto', marginTop: 60, marginBottom: 15, alignSelf: 'center', height: 70 }} />
        <CloudDownIconCustom style={{ color: '#3f3f3f', alignSelf: 'center', width: 70 }} />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
          <h3 style={{ color: '#3f3f3f', textAlign: 'center', margin: 0, marginTop: 5, fontWeight: 'bold' }}>
            <Entity entity="serviceUnavailableTitle" />
          </h3>
        </div>
        <h4 style={{ color: '#3f3f3f', textAlign: 'center', margin: 0, marginTop: 10, width: '50%', alignSelf: 'center', lineHeight: '1.5em' }}>
          <Entity entity="serviceUnavailableDescription" />
        </h4>
        <h4 className="link-label" style={{ textAlign: 'center', margin: 0, marginTop: 5 }} onClick={() => window.open(LUCKEY_SERVICE_STATUS_LINK)}>
          <Entity entity="verifyServiceStatus" />
        </h4>
        <MDButton
          title={<Entity entity="reload" />}
          containerStyle={{ display: 'flex', justifyContent: 'center', width: '50%', alignSelf: 'center', marginTop: 30 }}
          onClick={() => this.onReload()}
        />
        <MDButton
          title={<Entity entity="backToLogin" />}
          backgroundColor={ORANGE}
          containerStyle={{ display: 'flex', justifyContent: 'center', width: '50%', alignSelf: 'center', marginTop: 10 }}
          onClick={() => this.onGoToLogin()}
        />
      </div>
    );
  }
}

export default ServiceUnavailableView;
