import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';

const MDCheckBoxView = ({ checked, label, disabled, titleStyle, subtitleStyle, subtitle, onSelect }) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox size="medium" color="primary" checked={checked} classes={{root: 'custom-checkbox-root'}} disabled={disabled} onChange={() => onSelect(!checked)} value="gilad" />
        }
        label={<h5 style={{ margin: 0, ...titleStyle }}>{label}</h5>}
      />
      {subtitle && <h5 style={{ marginTop: -13, marginLeft: 32, ...subtitleStyle }}>{subtitle}</h5>}
    </div>
);

export default MDCheckBoxView;

