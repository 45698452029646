// @ts-nocheck
import Warning from '@material-ui/icons/Warning.js';
import { Form } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { ORANGE, PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts.js';
import MDButton from '../MDButton/MDButton.jsx';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import Permission from '../Permission/Permission.jsx';
import CustomField from './Fields/CustomField.jsx';
import { MaximizeTwoTone } from '@material-ui/icons';
import * as ModalActions from '../../redux/actions/modal.actions';


const styles = theme => ({
  button: {
    color: 'red',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
  },
});

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  } else if (_.trim(values.name).length > 50) {
    errors.name = 'ticketCategoryTooLong';
  }
  return errors;
};

@reduxForm({ form: 'TicketCategoriesForm', validate })
@connect(state => ({ form: state.form.TicketCategoriesForm }))
class TicketCategoriesForm extends React.Component {
  
  onDeleteTicketCategoryClick() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deleteTicketCategoryConfirmation" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteTicketCategoryRequest(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onDeleteTicketCategoryRequest() {
    const { onDeleteTicketCategoryRequest, form } = this.props;
    onDeleteTicketCategoryRequest(form.values.id)
  }

  render() {
    const { onValidateForm, form, classes } = this.props;
    return (
      <Form style={{ paddingLeft: 15, paddingRight: 15, marginTop: 20 }}>
        <input style={{ display: 'none' }} type="number" name="id" />
        <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
          <div style={{width:400}}>
            <Entity
              componentClass={Field}
              name="name"
              componentAttribute="placeholder"
              entity="insertTicketCategoryName"
              title={<Entity entity="ticketCategoryName" />}
              component={CustomField}
              type="text"
              mandatory
            />
          </div>
          {form?.values?.id?(
            <Permission do={[PERMISSIONS.ALL]} on={PERMISSION_ENTITIES.TICKET} abilityHelper={AbilityProvider.getAbilityHelper()}>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  this.onDeleteTicketCategoryClick();
                }}
                className={classes.button}
                style={{marginBottom:10,marginLeft:50}}
              >
                <Entity entity="deleteTicketCategory" />
                <DeleteIcon style={{ fontSize: 15, marginLeft: 5 }} />
              </Button>
            </Permission>
          ):null}
        </div>
        <Permission do={[PERMISSIONS.ALL]} on={PERMISSION_ENTITIES.TICKET} abilityHelper={AbilityProvider.getAbilityHelper()}>
          <MDButton
            title={<Entity entity="save" />}
            onClick={() => onValidateForm()}
          />
        </Permission>
      </Form>
    );
  }
} 

export default withStyles(styles)(TicketCategoriesForm);