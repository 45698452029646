import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { PERMISSION_ENTITIES, PERMISSIONS, TICKETS_DEFAULT_CATEGORIES, TICKETS_PRIORITY_FILTER_OPTIONS, TICKETS_STATUS_FILTER_OPTIONS, VIEW_MODES } from '../_config/consts';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import TicketTableView from '../components/Tickets/TicketTableView.jsx';
import TicketsFilters from '../components/Tickets/TicketsFilters.jsx';
import TicketsOperationalSection from '../components/Tickets/TicketsOperationalSection.jsx';
import * as ModalActions from '../redux/actions/modal.actions';
import * as TicketActions from '../redux/actions/ticket.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';

@connect((state) => ({ tickets: state.tickets, routing: state.router, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName, }))
class Tickets extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('ticketViewMode');
    this.state = {
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0
    };
  }
  async componentWillMount() {
    const { dispatch, routing, tickets } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    dispatch(TicketActions.selectTicket({}));
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      dispatch(TicketActions.resetTicketsFilters());
      await dispatch(TicketActions.fetchTicketManagers());
      await dispatch(TicketActions.fetchTicketCategories());
      await dispatch(TicketActions.fetchTickets());
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
    const ticketId = parsed.ticketId;
    const newEntity = parsed.newEntity;
    if (ticketId) {
      const newTicket = tickets.filter(e=>e.id===ticketId).length>0?tickets.filter(e=>e.id===ticketId)[0]:undefined;
      if (newTicket) {
        this.selectTicket(newTicket);
      }
    }
    if (newEntity) {
      this.onOpenNewTicket();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(TicketActions.resetTicketsFilters());
    dispatch(TicketActions.selectTicket({}));
  }

  async closeOperationalSection() {
    const { dispatch } = this.props;
    await dispatch(TicketActions.setOperationalMode(false));
    await dispatch(TicketActions.selectTicket({}));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(TicketActions.setOperationalMode(true));
  }

  async onResetFilters() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    await dispatch(TicketActions.resetTicketsFilters());
    dispatch(initialize('TicketsFilters', {}));
    await dispatch(TicketActions.fetchTickets());
    dispatch(UtilsActions.setSpinnerVisibile(false));
  }

  async selectTicket(ticket) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    await dispatch(TicketActions.fetchTicketDetails(ticket.id))
    const ticketCategory = ticket.categories&&ticket.categories.length>0?ticket.categories[0]:undefined
    const parsedTicket = {
      ...ticket,
      category: ticketCategory?{
        id: ticketCategory.id,
        name: ticketCategory.name,
        value: ticketCategory.name,
        label: L20NContext.getSync(_.filter(TICKETS_DEFAULT_CATEGORIES,f=>f.value===ticketCategory.name).length>0?
          _.filter(TICKETS_DEFAULT_CATEGORIES,f=>f.value===ticketCategory.name)[0].label:ticketCategory.name),
      }:undefined
    }
    try {
      dispatch(TicketActions.selectTicket(parsedTicket));
      dispatch(initialize('TicketDetailsViewForm', parsedTicket));
      dispatch(TicketActions.setOperationalMode(true));
    } catch (error) {
      dispatch(TicketActions.selectTicket({}));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onAppendTickets(page) {
    const { dispatch } = this.props;
    const append = true;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(TicketActions.fetchTickets(page, append));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  refreshTicket(ticket) {
    const { tickets: { selectedTicket } } = this.props;
    if (!_.isEmpty(selectedTicket)) this.selectTicket(ticket);
  }

  onFetchTicketOnPage(page) {
    const { dispatch } = this.props;
    dispatch(TicketActions.fetchTickets(page));
  }

  async onTicketEditConfirmed(ticketId,ticketData) {
    const { dispatch, tickets } = this.props;
    const data = {};
    if (ticketData.assignee&&ticketData.assignee.id)
      data.managerId = ticketData.assignee.id;
    if (ticketData.priority&&ticketData.priority.value)
      data.priority = ticketData.priority.value;
    if (ticketData.category&&ticketData.category.id)
      data.categories = [ticketData.category.id];
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const ticketResponse = await dispatch(TicketActions.editTicketAdmin(ticketId,data));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="ticketPropertiesAssigned" /></h6>),
        },
      }));
      if (tickets.isOperationalMode) {
        this.refreshTicket(ticketResponse);
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Rimuovo la prima parte, i.e. "data:application/pdf;base64,"
      let resultAsString = (reader.result);
      let resultSplitted = resultAsString.split(',');
      if (resultSplitted.length>1)
        resolve(resultSplitted[1])
    };
    reader.onerror = reject;
  });

  async onTicketCreateConfirmed(ticketData) {
    const { dispatch, tickets } = this.props;
    const attachments = {}
    if (ticketData.image && _.isArray(ticketData.image)) {
      for (const item of ticketData.image) {
        try {
          let fileName = item.name
          if (fileName && attachments[fileName]===undefined) {
            let encodedFile = await this.toBase64(item)
            attachments[fileName] = encodedFile
          }
        } catch(e) {
          console.error("Error encoding file:", e);
          continue
        }
      }
    }
    const data = {
      title: ticketData.title,
      comment: ticketData.message,
      categories: [ticketData.category.id],
      attachments: !_.isEmpty(attachments)?attachments:undefined,
    };
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const ticketResponse = await dispatch(TicketActions.createTicket(data));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="ticketCreated" /></h6>),
        },
      }));
      if (tickets.isOperationalMode) {
        this.closeOperationalSection()
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(TicketActions.resetTicketsFilters());
      await dispatch(TicketActions.fetchTickets());
    }
  }

  async onNewMessageRequest(ticketId,newMessage,attachments) {
    const { dispatch, tickets } = this.props;
    const data = {
      comment: newMessage,
      attachments: !_.isEmpty(attachments)?attachments:undefined,
    }
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const updatedTicket = await dispatch(TicketActions.ticketNewMessage(ticketId,data));
      dispatch(TicketActions.selectTicket(updatedTicket));
      const ticketCategory = updatedTicket.categories&&updatedTicket.categories.length>0?updatedTicket.categories[0]:undefined
      const parsedTicket = {
        ...updatedTicket,
        category: ticketCategory?{
          id: ticketCategory.id,
          name: ticketCategory.name,
          value: ticketCategory.name,
          label: L20NContext.getSync(_.filter(TICKETS_DEFAULT_CATEGORIES,f=>f.value===ticketCategory.name).length>0?
            _.filter(TICKETS_DEFAULT_CATEGORIES,f=>f.value===ticketCategory.name)[0].label:ticketCategory.name),
        }:undefined
      }
      dispatch(initialize('TicketDetailsViewForm', parsedTicket));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="ticketMessageSent" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="ticketMessageError" /></h6>),
        },
      }));
      throw error;
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onCloseTicketRequest(ticketId) {
    const { dispatch, tickets } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const data = {
        status: "CLOSED",
      }
      const ticketResponse = await dispatch(TicketActions.editTicketAdmin(ticketId,data));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="ticketClosed" /></h6>),
        },
      }));
      if (tickets.isOperationalMode) {
        this.closeOperationalSection();
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onResolveTicketRequest(ticketId) {
    const { dispatch, tickets } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const data = {
        status: "RESOLVED",
      }
      const ticketResponse = await dispatch(TicketActions.editTicketAdmin(ticketId,data));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="ticketResolved" /></h6>),
        },
      }));
      if (tickets.isOperationalMode) {
        this.closeOperationalSection();
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onOpenTicketRequest(ticketId) {
    const { dispatch, tickets } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const data = {
        status: "OPEN",
      }
      const ticketResponse = await dispatch(TicketActions.editTicketAdmin(ticketId,data));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="ticketOpened" /></h6>),
        },
      }));
      if (tickets.isOperationalMode) {
        this.refreshTicket(ticketResponse);
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onExportElements(format) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    await dispatch(TicketActions.exportTickets(format));
    dispatch(UtilsActions.setSpinnerVisibile(false));
  }
  
  async onRefreshTickets() {
    const { tickets } = this.props;
    const page = tickets && tickets.data && tickets.data.pagination && tickets.data.pagination.number;
    this.onFetchTicketOnPage(page);
  }

  onOrderChanged() {
    const { dispatch } = this.props;
    dispatch(TicketActions.fetchTickets())
  }

  async onOpenNewTicket() {
    const { dispatch } = this.props;
    dispatch(TicketActions.setOperationalMode(true));
    dispatch(initialize('TicketForm', { }));
  }


  render() {
    const { dispatch, tickets, themeName } = this.props;
    const selectedTicketId = tickets?.selectedTicket?.id

    const prioritiesArray = TICKETS_PRIORITY_FILTER_OPTIONS.filter(e=>e.value!=="ALL").map(e=>{
      return {value:e.value,label:L20NContext.getSync(e.label)}
    })
    const statusArray = TICKETS_STATUS_FILTER_OPTIONS.filter(e=>e.value!=="ALL").map(e=>{
      return {value:e.value,label:L20NContext.getSync(e.label)}
    })

    const categoriesArray = tickets?.ticketCategories?.content && !_.isEmpty(tickets?.ticketCategories?.content) ? tickets.ticketCategories.content : TICKETS_DEFAULT_CATEGORIES;
    const categoriesParsed = _.map(categoriesArray.filter(e=>e.name),e=>{
      return {
        id: e.id,
        name: e.name,
        value: e.name,
        label: L20NContext.getSync(_.filter(TICKETS_DEFAULT_CATEGORIES,f=>f.value===e.name).length>0?
          _.filter(TICKETS_DEFAULT_CATEGORIES,f=>f.value===e.name)[0].label:e.name),
      }
    })
    const categoriesParsedForFilters = [{id:0,name:"ALL",value:"ALL",label:"ticketCategory"},...categoriesParsed]
    console.log('Parsed', categoriesParsed)
    console.log('Array', categoriesArray)

    const canEditCreateTicket = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.VISITORS);

    return (
      <div style={{ backgroundColor: 'white' }}>
        <div style={{ marginTop: 0, display: 'flex', flexDirection: 'row', width: '100%' }}>
          <TicketsFilters
            themeName={themeName}
            fromTicketAnalytics={false}
            categoriesArray={categoriesParsedForFilters}
            onResetFilters={() => this.onResetFilters()}
            onExportElements={format => this.onExportElements(format)}
            onOpenNewTicket={canEditCreateTicket ? () => this.onOpenNewTicket() : null}
          />
        </div>
        <TicketTableView
          onSelectTicket={ticket => this.selectTicket(ticket)}
          onFetchTicketOnPage={page => this.onFetchTicketOnPage(page)}
          onRefreshTickets={() => this.onRefreshTickets()}
          prioritiesArray={prioritiesArray}
          categoriesArray={categoriesParsed}
          statusArray={statusArray}
          onNewTicket={canEditCreateTicket ? () => this.onOpenNewTicket() : null}
          onOrderChanged={() => this.onOrderChanged()}
        />
        <OperationalView
          themeName={themeName}
          isVisible={tickets.isOperationalMode}
          onClose={() => this.closeOperationalSection()}
          style={{ margin: 0, padding: 0 }}
          title={<Entity entity={selectedTicketId?"manageTicket":"createTicket"} data={{ name: 'ticketsOperational' }} />}
        >
          <TicketsOperationalSection
            themeName={themeName}
            onTicketEditConfirmed={(ticketId,ticketData)=>this.onTicketEditConfirmed(ticketId,ticketData)}
            onTicketCreateConfirmed={(ticketData)=>this.onTicketCreateConfirmed(ticketData)}
            onNewMessageRequest={(ticketId,newMessage,attachments)=>this.onNewMessageRequest(ticketId,newMessage,attachments)}
            onCloseTicketRequest={(ticketId)=>this.onCloseTicketRequest(ticketId)}
            onResolveTicketRequest={(ticketId)=>this.onResolveTicketRequest(ticketId)}
            onOpenTicketRequest={(ticketId)=>this.onOpenTicketRequest(ticketId)}
            prioritiesArray={prioritiesArray}
            categoriesArray={categoriesParsed}
            statusArray={statusArray}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default Tickets;
