import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Bar } from 'react-chartjs-2';


class ScrollableBarChart extends React.Component {


  componentDidMount() {
    document.addEventListener("visibilitychange", () => this.handleVisibilityChange());
  }
  
 componentWillUnmount() {
    document.removeEventListener("visibilitychange", () => this.handleVisibilityChange());
 }

  handleVisibilityChange() {
    if (document.visibilityState === "visible" && this.chartRef && this.chartRef.chartInstance) {
      this.chartRef?.chartInstance.update();
    }
  }

  render() {
    const {
      data,
      dataSetSize,
      xAxeTitle,
      yAxeTitle,
      showLegend,
      legendPosition,
      chartMaxWidth,
      chartMaxHeight,
      chartOptions,
      containerStyle,
      stackedBars,
    } = this.props;
    const maxWidth = chartMaxWidth || window.screen.width - 250 - 20; // 280 sidebar menu, 80 margin and padding
    const chartWidth = dataSetSize * 20 < maxWidth ? maxWidth : dataSetSize * 20;

    return (
      <div style={{ zoom: '125%', overflowX: 'scroll', width: '100%', marginBottom: 10, ...containerStyle }}>
        <PerfectScrollbar style={{ paddingBottom: 15 }}>
          <div style={{ overflowX: 'scroll', margin: 'auto', width: chartWidth }}>
            <Bar
              data={data}
              ref={(c) => { this.chartRef = c; }}
              height={chartMaxHeight || 300}
              onElementsClick={this.props.onElementClick ? (elems) => this.props.onElementClick(elems) : null}
              options={{
                responsive: true,
                events: ['click', 'mousemove'],
                maintainAspectRatio: false,
                legend: {
                  display: showLegend,
                  position: legendPosition || 'top',
                },
                scales: {
                  xAxes: [{
                    stacked: Boolean(stackedBars),
                    categorySpacing: 2,
                    scaleLabel: {
                      display: xAxeTitle,
                      labelString: xAxeTitle,
                    },
                  }],
                  yAxes: [{
                    stacked: Boolean(stackedBars),
                    ticks: {
                      stepSize: 1,
                      min: 0,
                    },
                    scaleLabel: {
                      display: yAxeTitle,
                      labelString: yAxeTitle,
                    },
                  }],
                },
                ...chartOptions,
              }}
            />
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default ScrollableBarChart;
