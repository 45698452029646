import { withStyles } from '@material-ui/core';
import { Col, Grid, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { submit } from 'redux-form';
import { ORANGE } from '../../_config/consts.js';
import { getLuckeyLogoSource, isMobileBrowser } from '../../_config/utils';
import MDButton from '../../components/MDButton/MDButton.jsx';
import InsertInvitationCodeForm from '../../components/forms/LoginForm/EmailOTPLogin/InsertInvitationCodeForm.jsx';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
    backgroundColor: 'white',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30,
    margin: 100,
    backgroundColor: 'white',
  },
  image: {
    height: 50,
    alignSelf: 'flex-start',
    marginBottom: 20,
  },
});

@withRouter
@connect(state => ({ userData: state.user.data, companyConfigurations: state.user.companyConfigurations }))
class LoginInvitationView extends React.Component {

  async onLoginWithInvitationCode(data) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      this.setState({ isLoadingLogin: true });
      await dispatch(UserActions.loginWithInvitationCode(data.code));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ isLoadingLogin: false });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ isLoadingLogin: false });
      setTimeout(() => {
        dispatch(ModalsActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="loginInvitationCodeFailed" /> },
        }));
      }, 500);
    }
  }

  onBackToLogin() {
    const { dispatch } = this.props;
    dispatch(UserActions.logout());
  }


  render() {
    const { dispatch, classes, companyConfigurations } = this.props;
    const isMobile = isMobileBrowser();
    const year = new Date().getFullYear();
    const luckeyLogoSource = getLuckeyLogoSource('white');
    return (
      <div id="auth-container" style={{ height: '100vh', paddingTop: 0, backgroundImage: isMobile ? 'unset' : 'url(/imgs/common/loginBackground.jpg)', backgroundSize: 'cover' }} className="login">
        <Grid>
          <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Col
              id="login-container"
              xs={12}
              sm={12}
              style={{
                height: isMobile ? '100vh' : 'unset',
                width: isMobile ? null : 900,
                opacity: isMobile ? 1 : 0.9,
                margin: isMobile ? 0 : 100,
                overflow: 'scroll',
                position: 'relative',
                borderRadius: isMobile ? 0 : 10,
                boxShadow: isMobile ? 'unset' : '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
              }}
            >
              <img src={luckeyLogoSource} alt="Sofia" style={{ margin: 10, marginTop: 0, marginBottom: 15, alignSelf: 'center', height: 65 }} />
              <img src="/imgs/common/tagLine.png" alt="SofiaTagline" style={{ alignSelf: 'center', height: isMobile ? 13 : 23 }} />
              <div style={{ padding: 25, paddingTop: 0, paddingBottom: 0, marginBottom: 25, marginTop: 25 }}>
              <div>
                <h3 style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginBottom: 0 }}><Entity entity="invitationCode" /></h3>
                <h5 style={{ color: 'white', fontWeight: 'lighter', textAlign: 'center', marginBottom: 10 }}><Entity entity="invitationCodeDescription" /></h5>
                <InsertInvitationCodeForm
                  onSubmit={codeData => this.onLoginWithInvitationCode(codeData)}
                />
                <MDButton
                  title={<Entity entity="confirm" />}
                  containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                  onClick={() => dispatch(submit('InsertInvitationCodeForm'))}
                />
              </div>
              <MDButton
                title={<Entity entity="backToLogin" />}
                backgroundColor={ORANGE}
                containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                onClick={() => this.onBackToLogin()}
              />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src="/imgs/common/logoSofiaWhite.png" alt="Sofia" style={{ height: 12, marginRight: 4 }} />
                <h6 style={{ fontSize: 10, margin: 0, marginTop: 3, color: 'white' }}>©{year}</h6>
              </div>
            </div>
          </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(LoginInvitationView);
