import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { CARDS_CREDENDIAL_RULES_STATUSES, RED } from '../../_config/consts';
import { IconButton } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const CardCredentialStatus = ({ status, date, onShowStatusInfo }) => {
  let backgroundColor = '#43A047';
  if (status === CARDS_CREDENDIAL_RULES_STATUSES.TO_BE_INSERTED) backgroundColor = '#EF6C00';
  if (status === CARDS_CREDENDIAL_RULES_STATUSES.FAILED) backgroundColor = RED;
  if (status === CARDS_CREDENDIAL_RULES_STATUSES.NOT_COMPATIBLE) backgroundColor = '#3f3f3f';
  const label = <Entity key={`status_${status}`} entity="cardCredentialStatuses" data={{ status }} style={{ fontWeight: 'bold', textTransform: 'uppercase', color: backgroundColor }} />;
  return (
    <div
      style={{ fontSize: 12, border: '1px solid transparent', padding: 3, borderRadius: 3, textAlign: 'center', color: 'white', flexDirection: 'row', display: 'flex', alignItems: 'center' }}
    >
      {label}
      <div style={{ fontSize: 10, lineHeight: '12px' }}>
        {date ? `(${moment(date).format('lll')})` : null}
      </div>
      {onShowStatusInfo ? (
        <IconButton
          style={{ width: 25, height: 25, marginLeft: 5 }}
          onClick={e => {
            e.stopPropagation();
            onShowStatusInfo();
          }}
        >
          <InfoOutlined style={{ width: 25, color: '#3f3f3f' }} />
      </IconButton>
      ) : null}
    </div>
  );
};

export default CardCredentialStatus;
