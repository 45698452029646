import { Divider } from '@material-ui/core';
import EffectIcon from '@material-ui/icons/FlashOn';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import { OFFICE_MODE_PROGRAM_TYPES, ORANGE } from '../../../_config/consts';
import MDRadioButtonsGroupField from '../Elements/MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import MDRadioButtonView from '../Elements/MDRadioButtonsGroup/MDRadioButtonView.js';
import CustomField from '../Fields/CustomField.jsx';
import MDCheckBoxGroupField from '../Fields/MDCheckBox/MDCheckBoxGroupField.js';
import MDCheckBoxView from '../Fields/MDCheckBox/MDCheckBoxView';
import MDSwitchField from '../Fields/MDSwitch/MDSwitchField.jsx';

const timeOptions = [
  {
    value: 1,
    label: <Entity entity="twentifourhours" />,
  },
  {
    value: 2,
    label: <Entity entity="moringTime" />,
  },
  {
    value: 3,
    label: <Entity entity="afternoonTime" />,
  },
  {
    value: 4,
    label: <Entity entity="moringAndAfternoon" />,
  },
];
@connect((state) => ({}))
class LockOfficeModeSubProgramForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeOptionSelected: 0,
    };
  }

  onOpenOfficeModeInfoModal() {
    // TODO SHOW MODAL WITH MORE INFO
  }

  onProgramTypeSelected(type) {
    const { dispatch, formId, programIndex } = this.props;
    switch (type) {
      case OFFICE_MODE_PROGRAM_TYPES.AUTOMATIC: {
        dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programStartTime`, moment().startOf('day').set('hours', 9).valueOf()));
        break;
      }
      case OFFICE_MODE_PROGRAM_TYPES.SEMIAUTOMATIC: {
        dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programStartTime`, null));
        dispatch(change('LockOfficeModeForm', `programs[${programIndex}].enableOnFirstAccess`, false));
        break;
      }
      default:
        break;
    }
  }

  onDateCheckboxChange(value) {
    const { dispatch, programIndex, formData } = this.props;
    this.setState({ timeOptionSelected: value });
    const isProgramAutomatic = formData && formData.programType === OFFICE_MODE_PROGRAM_TYPES.AUTOMATIC;
    switch (value) {
      case 1: {
        if (isProgramAutomatic) {
          dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programStartTime`, moment().startOf('day').valueOf()));
        }
        dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programEndTime`, moment().endOf('day').valueOf()));
        break;
      }
      case 2: {
        if (isProgramAutomatic) {
          dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programStartTime`, moment().startOf('day').set('hours', 9).valueOf()));
        }
        dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programEndTime`, moment().startOf('day').set('hours', 13).valueOf()));
        break;
      }
      case 3: {
        if (isProgramAutomatic) {
          dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programStartTime`, moment().startOf('day').set('hours', 14).valueOf()));
        }
        dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programEndTime`, moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      case 4: {
        if (isProgramAutomatic) {
          dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programStartTime`, moment().startOf('day').set('hours', 9).valueOf()));
        }
        dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programEndTime`, moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      default: {
        if (isProgramAutomatic) {
          dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programStartTime`, moment().startOf('day').set('hours', 9).valueOf()));
        }
        dispatch(change('LockOfficeModeForm', `programs[${programIndex}].programEndTime`, moment().startOf('day').set('hours', 13).valueOf()));
        break;
      }
    }
  }


  render() {
    const { formData, formId, program, is12HoursFormat } = this.props;
    const { timeOptionSelected } = this.state;
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: weekday,
      value: moment().isoWeekday(weekday).isoWeekday(),
    }));
    const selectedProgramType = formData && formData.programType;
    const isProgramAutomatic = formData && formData.programType === OFFICE_MODE_PROGRAM_TYPES.AUTOMATIC;
    const timeFromSelected = formData && formData.programStartTime;
    const timeToSelected = formData && formData.programEndTime;
    const isFirstAccessEnabled = isProgramAutomatic && formData && formData.enableOnFirstAccess;

    let descriptionString = isProgramAutomatic ? 'officeModeAutomaticProgramVerbalDescription' : 'officeModeSemiAutomaticProgramVerbalDescription';
    descriptionString = isFirstAccessEnabled ? 'officeModeAutomaticFirstAccessProgramVerbalDescription': descriptionString;

    return (
      <div style={{ paddingLeft: 15 }}>
        <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginBottom: 10, fontSize: 16 }}><Entity entity="officeModeProgramTypeTitle" /></h4>
        <Field
          name={`${program}.programType`}
          title={<Entity entity="daysOfTheWeek" />}
          component={MDRadioButtonsGroupField}
          onHandleChange={values => this.onProgramTypeSelected(values, 1)}
          containerstyle={{ paddingLeft: 15, marginTop: 0 }}
          horizontal={false}
          options={[
            {
              label: <Entity entity="officeModeAutomaticSelectionTitle" />,
              value: OFFICE_MODE_PROGRAM_TYPES.AUTOMATIC,
            },
            {
              label: <Entity entity="officeModeSemiAutomaticSelectionTitle" />,
              value: OFFICE_MODE_PROGRAM_TYPES.SEMIAUTOMATIC,
            },
          ]}
        />
        {timeToSelected ? (
          <div style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <EffectIcon style={{ fontSize: 20, marginRight: 10, color: ORANGE }} />
            <h5 style={{ color: '#3f3f3f', margin: 0, lineHeight: '1.5em' }}>
              <Entity
                entity={descriptionString}
                key={`${isFirstAccessEnabled}-${selectedProgramType}-${timeFromSelected}-${timeToSelected}`}
                data={{
                  timeFrom: moment(timeFromSelected).format('LT'),
                  timeTo: moment(timeToSelected).format('LT'),
                }}
              />
            </h5>
          </div>
        ) : null}
        <Divider style={{ marginTop: 10, marginBottom: 20, height: 2 }} />
        <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginTop: 15, marginBottom: 10, fontSize: 16 }}><Entity entity="programActivationMode" /></h4>
        {isProgramAutomatic ? (
          <div>
            <Field
              name={`${program}.enableOnFirstAccess`}
              label={<h4 style={{ fontSize: 15 }}><Entity entity="officeModeFirstAccess" /></h4>}
              component={MDSwitchField}
              containerstyle={{ marginTop: 10 }}
              horizontal
            />
            <h5 style={{ marginTop: -10, lineHeight: '1.4em' }}><Entity key={selectedProgramType} entity="officeModeFirstAccessDescription" /></h5>
          </div>
        ) : (
          <div>
            <MDCheckBoxView
              checked
              disabled
              label={<h4 style={{ fontSize: 15 }}><Entity entity="officeModeManualActivation" /></h4>}
              containerstyle={{ marginTop: 10 }}
            />
            <h5 style={{ marginTop: -5, lineHeight: '1.4em' }}><Entity key={selectedProgramType} entity="officeModeManualActivationDescription" /></h5>
          </div>
        )}
        <Divider style={{ marginTop: 10, marginBottom: 20, height: 2 }} />
        <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginTop: 15, marginBottom: 10, fontSize: 16 }}><Entity entity="timeAndDaysOfficeMode" /></h4>
        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {isProgramAutomatic ? (
            <div style={{ marginLeft: 8, marginRight: 60 }}>
              <Entity
                componentClass={Field}
                title={<Entity entity="programStartTime" />}
                name={`${program}.programStartTime`}
                component={CustomField}
                type="antdTimePicker"
                is12HoursFormat={is12HoursFormat}
                placeholder="Select a time"
              />
            </div>
            ) : null}
          <Entity
            componentClass={Field}
            title={isProgramAutomatic ? <Entity key={selectedProgramType} entity="programEndTime" /> : <Entity key={selectedProgramType} entity="officeModeSemiAutomaticEndTimeFieldTitle" />}
            name={`${program}.programEndTime`}
            component={CustomField}
            is12HoursFormat={is12HoursFormat}
            type="antdTimePicker"
            placeholder="Select a time"
          />
          <div style={{ marginLeft: 30, marginTop: 20 }}>
            {_.map(timeOptions, timeOption => (
              <MDRadioButtonView
                key={timeOption.value}
                checked={timeOptionSelected === timeOption.value}
                value={(timeOption.value).toString()}
                label={timeOption.label}
                handleChange={value => this.onDateCheckboxChange(timeOption.value)}
              />
            ))}
          </div>
        </div>
        <Field
          name={`${program}.daysOfTheWeek`}
          component={MDCheckBoxGroupField}
          containerstyle={{ paddingLeft: 10 }}
          horizontal
          options={daysOptions}
        />
      </div>
    );
  }
} 

export default LockOfficeModeSubProgramForm;
