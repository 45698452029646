import React, { Component } from 'react';
import _ from 'lodash';
import EffectIcon from '@material-ui/icons/FlashOn';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { ORANGE } from '../../../_config/consts';

const EffectActionSection = ({ effectDescription, children, headerStyle, containerStyle, subtitleStyle }) =>
  (
    <div style={{ ...containerStyle }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...headerStyle }}>
        <EffectIcon style={{ fontSize: 20, marginTop: 4, color: ORANGE }} />
        <h4 style={{ fontSize: 17, color: '#3f3f3f', margin: 0, fontWeight: 'bold' }}>
          <Entity entity="effect" />
        </h4>
        <h5 style={{ fontSize: 16, lineHeight: 1.3, margin: 0, color: '#3f3f3f', fontWeight: 200, marginLeft: 5, ...subtitleStyle }}>
          <Entity entity={effectDescription} />
        </h5>
        
      </div>
      {children}
    </div>
    );

export default EffectActionSection;
