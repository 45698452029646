import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Row,
  Col,
  Button,
  Icon,
  FormGroup,
  ControlLabel,
} from '@sketchpixy/rubix';

import TagsSelectorField from '../Fields/TagsSelectorField.jsx';
import Tag from '../../Tag.jsx';
import * as CredentialActions from '../../../redux/actions/credential.actions';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

class CustomOption extends React.Component {
  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  }

  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  }

  render() {
    const { option } = this.props;
    const tag = {
      id: option.id,
      name: option.type,
      color: option.color,
      type: option.type,
    };
    return (
      <div
        className={this.props.className}
        onMouseDown={e => this.handleMouseDown(e)}
        onMouseEnter={e => this.handleMouseEnter(e)}
        onMouseMove={e => this.handleMouseMove(e)}
        title={option.title}
      >
        <Tag tag={tag} />
        {option.name}
      </div>
    );
  }
}

class CustomValue extends React.Component {
  render() {
    const { value, onRemove } = this.props;
    const isUserTag = value && value.type === 'user';
    return (
      <div
        className="Select-value"
        title={value.name}
        style={{ borderColor: value.color ? value.color : 'white', backgroundColor: 'white' }}
      >
        <span
          className="Select-value-label"
          style={{ backgroundColor: value.color, color: 'white' }}
        >
          {isUserTag ?
            <Icon
              className="edit-icon"
              glyph="icon-fontello-user"
              style={{ color: 'white' }}
            />
            :
            <span>{value.type}</span>
          }
        </span>
        <span
          className="Select-value-label"
          style={{ color: value.color }}
        >
          <span>{value.name}</span>
        </span>
      </div>
    );
  }
}
@connect()
class GuestSelector extends React.Component {


  onHandleChange(value, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(value);
    }
    if (onHandleChange) {
      onHandleChange(value);
    }
  }

  onInputChange(value, input) {
    const { onInputChange } = this.props;
    if (onInputChange) {
      onInputChange(value);
    }
    return value;
  }
  render() {
    const { mandatory, title, id, meta, tags, input, multi, placeholder, name, guests, onGuestTagsChange, onGuestInputChange, onFetchMoreGuests, singleTag } = this.props;

    let val = input.value;
    if (!multi) {
      if (_.isArray(input.value) && input.value.length) {
        val = _.first(input.value);
      }
    }

    let validationState;
    if (meta.touched) {
      if (meta.error) {
        validationState = 'error';
      } else {
        validationState = 'success';
      }
    }

    return (
      <FormGroup controlId={id} validationState={validationState}>
        <ControlLabel style={{ color: '#3f3f3f', fontSize: 16 }}>{title}{mandatory ? <span style={{ color: '#D71F4B' }}>*</span> : ''}</ControlLabel>
        <Select
          style={{ borderColor: '#dddddd', borderWidth: 2, borderRadius: 5, marginTop: 5, marginBottom: 2 }}
          multi={multi}
          name={name}
          placeholder={placeholder}
          labelKey="name"
          valueKey="id"
          options={tags ? tags.asMutable({ deep: true }) : []}
          optionComponent={CustomOption}
          valueComponent={CustomValue}
          {...input}
          value={val}
          onValueClick={(value, actionType) => this.onValueClick(value, actionType)}
          onChange={(value) => { this.onHandleChange(value, input); }}
          onInputChange={(value) => this.onInputChange(value, input)}
          onBlur={() => input.onBlur(input.value)}
        />
        {meta.touched && meta.error &&
          <ControlLabel style={{ color: 'deepred' }}>
            <h6 style={{ color: 'red', marginTop: 4 }}>
              <Entity key={meta.error} entity={meta.error} />
            </h6>
          </ControlLabel>
        }
      </FormGroup>
    );
  }
} 

export default GuestSelector;
