import React from 'react';
import { SUBSCRIPTION_TYPES } from '../../_config/consts.js';
import DomainLicenseCard from './LicenseCard/DomainLicenseCard.jsx';
import LocksLicenseCard from './LicenseCard/LocksLicenseCard.jsx';
import GuestLicenseCard from './LicenseCard/GuestLicenseCard.jsx';
import BookeyLicenseCard from './LicenseCard/BookeyLicenseCard.jsx';
import UsersAndLockLicenseCard from './LicenseCard/UsersAndLockLicenseCard.jsx';
import InvitationsLicenseCard from './LicenseCard/InvitationsLicenseCard.jsx';
import RegistryLicenseCards from './LicenseCard/RegistryLicenseCards.jsx';
import CommunicationLicenseCard from './LicenseCard/CommunicationLicenseCard.jsx';
import AclLicenseCard from './LicenseCard/AclLicenseCard.jsx';
import F9000LicenseCard from './LicenseCard/F9000LicenseCard.jsx';

const licenseCardComponent = {
  [SUBSCRIPTION_TYPES.LOCKS]: LocksLicenseCard,
  [SUBSCRIPTION_TYPES.USERS]: GuestLicenseCard,
  [SUBSCRIPTION_TYPES.DOMAIN]: DomainLicenseCard,
  [SUBSCRIPTION_TYPES.BOOKEY]: BookeyLicenseCard,
  [SUBSCRIPTION_TYPES.INVITATIONS]: InvitationsLicenseCard,
  [SUBSCRIPTION_TYPES.REGISTRY]: RegistryLicenseCards,
  [SUBSCRIPTION_TYPES['USERS+LOCKS']]: UsersAndLockLicenseCard,
  [SUBSCRIPTION_TYPES.COMMUNICATIONS]: CommunicationLicenseCard,
  [SUBSCRIPTION_TYPES.F9000]: F9000LicenseCard,
  [SUBSCRIPTION_TYPES.ACL]: AclLicenseCard,
};

const BaseLicenseCard = (props) => {
  if (!props.subscriptionType) {
    return null;
  }
  const LicenseCardComponent = licenseCardComponent[props.subscriptionType];
  const componentProps = props;
  return (
    LicenseCardComponent ? <LicenseCardComponent {...componentProps} /> : null
  );
};

export default BaseLicenseCard;
