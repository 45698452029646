
import { Card, createTheme, IconButton, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { BLUE, GREEN } from '../../_config/consts.js';
import { getColorFromThemeName } from '../../_config/utils.js';
import * as FloorPlanActions from '../../redux/actions/floorplans.actions.js';
import * as LocksActions from '../../redux/actions/lock.actions.js';
import MapIconCustom from '../CustomIcons/MapIconCustom.jsx';
import FloorPlanViewer from './FloorPlanViewer.jsx';


const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    position: 'relative',
  },
  header: {
    marginTop: -40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  refreshHeader: {
   position: 'absolute',
   right: 0,
   top: 0,
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center'
  },
  dateHeader: {
    left: 15,
    top: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
    color: '#3f3f3f',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAnalyticsView = () => (
  <div style={{ height: 290, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <MapIconCustom style={{ color: '#bdbdbd', width: 50 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noMaps" /></h3>
  </div>
);
@connect((state) => ({ floorPlans: state.floorPlans, locks: state.locks, themeName: state.settings.items.theme.data.themeName }))
class FloorPlanDashboardViewer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedFloorPlanOption: null,
      floorPlanSmartLocks: [],
    }
  }

  async componentDidMount() {
    const { dispatch, floorPlans: { data: { content: floorPlansData }}} = this.props;
    if (floorPlansData && !_.isEmpty(floorPlansData)) {
      const floorPlan = _.first(floorPlansData);
      if (floorPlan) {
        this.onSelectFloorPlan(floorPlan);
      }
    }
  }

  componentDidUpdate(prevProps ) {
    const { floorPlans: { data: { content: currentFloorPlansData }} } = this.props;
    const { floorPlans: { data: { content: previousFloorPlansData }} } = prevProps;
    if (currentFloorPlansData !== previousFloorPlansData && !_.isEmpty(currentFloorPlansData)) {
      const floorPlan = _.first(currentFloorPlansData);
      if (floorPlan) {
        this.onSelectFloorPlan(floorPlan)
      }
    }
  }

  onDatesChange(startDate, endDate) {
    const { onDateRangeSelected } = this.props;
    if (!startDate && !endDate) {
      this.resetDateFilter();
    }

    if (onDateRangeSelected && startDate && endDate) {
      onDateRangeSelected(startDate, endDate);
    }
  }

  resetDateFilter() {
    const { onResetDateFilters } = this.props;
    onResetDateFilters();
  }

  async onSelectFloorPlan(floorPlan) {
    const { dispatch } = this.props;
    const floorPlanSmartLocks = await dispatch(LocksActions.fetchLocksOnFloorPlan(floorPlan.id));
    dispatch(FloorPlanActions.setSelectedFloorPlan(floorPlan));
    this.setState({ floorPlanSmartLocks, selectedFloorPlanOption: { floorPlan, label: floorPlan.name, value: floorPlan.id } });
  }
  
  onSelectFloorPlanDropdown(floorPlanOptionSelected) {
    this.setState({ selectedFloorPlanOption: floorPlanOptionSelected });
    this.onSelectFloorPlan(floorPlanOptionSelected.floorPlan)
  }

  onOpenSmartLocksEvents(element) {
    const { locksChartData, onOpenSmartLocksEvents } = this.props;
    const locksEvents = locksChartData && _.find(locksChartData.dataLocks, dataLocks => dataLocks.lockId === element.id);
    const locksEventsToShow = locksEvents ||  { name: element.name, serialNumber: element.serialNumber, lockId: element.id, chartData: [] }
    onOpenSmartLocksEvents(locksEventsToShow)
  }

  render() {
    const { lastUpdate, locksChartData, onOpenCameraFeed, onLockClick, floorPlans: { data: { content: floorPlansData }, selectedFloorPlan }, onRefreshFloorPlan, themeName, fullWidth, isRefreshing, classes, onRemoteOpenRequest } = this.props;
    const textColor = getColorFromThemeName(themeName);
    const { floorPlanSmartLocks, selectedFloorPlanOption } = this.state;
    const locksWithEvents = locksChartData ? _.map(locksChartData.dataLocks, dataLocks => ({ id: dataLocks.lockId })) : [];
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1, marginRight: fullWidth ? 0 : 20 }} elevation={3}>
          <div className={classes.titleHeader}>
            <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="floorPlans" /></h3>
            <div className={classes.refreshHeader}>
              <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}: {moment(lastUpdate).format('LLL')}</h5>
              <IconButton
                disabled={isRefreshing}
                style={{ opacity: isRefreshing ? 0.5 : 1 }}
                onClick={() => onRefreshFloorPlan()}
              >
                <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
              </IconButton>
            </div>
          </div>
          <div className={classes.header}>
            <div className={classes.dateHeader}>
              <div style={{ minWidth: 325, marginRight: 15, marginTop: 10 }}>
                <Select
                  value={selectedFloorPlanOption}
                  placeholder={<Entity entity="selectFloorPlan" />}
                  noResultsText={<Entity entity="noResultsFound" />}
                  onChange={val => this.onSelectFloorPlanDropdown(val)}
                  options={_.map(floorPlansData, floorPlan => ({ value: floorPlan.id, label: floorPlan.name, floorPlan: floorPlan }))}
                />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: GREEN }} />
                <h5 style={{ marginLeft: 10 }}><Entity entity="doorWithEvents" /></h5>
              </div>
              </div>
            </div>    
          </div>
          <div style={{ paddingTop: 30 }}>
          {selectedFloorPlan && !_.isEmpty(selectedFloorPlan) ? (
            <FloorPlanViewer
              hideCard
              showActions
              selectedElements={locksWithEvents}
              floorPlanSmartLocks={floorPlanSmartLocks}
              onOpenSmartLocksEvents={(element) => this.onOpenSmartLocksEvents(element)}
              onRemoteOpenRequest={element => onRemoteOpenRequest(element)}
              onOpenCameraFeed={element => onOpenCameraFeed(element)}
              onOpenLockDetails={lockId => onLockClick(lockId)}
            />
          ) : <EmptyAnalyticsView />}
          </div>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(FloorPlanDashboardViewer);
