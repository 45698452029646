import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SAVE_ROLES,
  SAVE_ROLES_PAGINATION_DATA,
  APPEND_ROLES,
  SET_SELECTED_ROLE,
  SET_ROLES_OPERATIONAL_MODE,
  SAVE_PERMISSIONS,
  SAVE_PERMISSIONS_PAGINATION,
  APPEND_PERMISSIONS,
  REMOVE_ROLE_FROM_STATE,
  SET_ROLES_FILTER_FIELD,
  RESET_ROLES_FILTERS,
  UPDATE_ROLE_IN_STATE,
  SAVE_PERMISSIONS_PARSED,
  SAVE_EDITABLE_PERMISSIONS_PARSED,
} from '../actions/actionTypes/roles';

const initialState = Immutable({
  roles: {
    content: [],
    pagination: {},
    filters: {},
  },
  permissions: {
    content: [],
    pagination: {},
  },
  permissionsParsed: [],
  editablePermissionsParsed: [], //Only for RoleForm
  selectedRole: null,
  isOperationalMode: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ROLES:
      return state.setIn(['roles', 'content'], action.roles);
    case APPEND_ROLES:
      return state.setIn(['roles', 'content'], [...state.roles.content, action.roles]);
    case SAVE_ROLES_PAGINATION_DATA:
      return state.setIn(['roles', 'pagination'], action.pagination);
    case SET_SELECTED_ROLE:
      return state.set('selectedRole', action.role);
    case SET_ROLES_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SAVE_PERMISSIONS:
      return state.setIn(['permissions', 'content'], action.permissions);
    case APPEND_PERMISSIONS:
      return state.setIn(['permissions', 'content'], [...state.permissions.content, action.permissions]);
    case SAVE_PERMISSIONS_PAGINATION:
      return state.setIn(['permissions', 'pagination'], action.pagination);
    case UPDATE_ROLE_IN_STATE: {
      const index = _.findIndex(state.roles.content, { id: action.role.id });
      if (index !== -1) {
        return state.setIn(['roles', 'content', index], action.role);
      }
      return state.setIn(['roles', 'content'], [ action.role, ...state.roles.content]);
    }
    case REMOVE_ROLE_FROM_STATE: {
      const roles = _.filter(state.roles.content, roleState => roleState.id !== action.roleId);
      return state.setIn(['roles', 'content'], roles);
    }
    case SET_ROLES_FILTER_FIELD:
      return state.setIn(['roles', 'filters', action.field], action.value);
    case RESET_ROLES_FILTERS:
      return state.setIn(['roles', 'filters'], {});
    case SAVE_PERMISSIONS_PARSED:
      return state.set('permissionsParsed', action.permissions);
    case SAVE_EDITABLE_PERMISSIONS_PARSED:
      return state.set('editablePermissionsParsed', action.permissions);
    default:
      return state;
  }
}
