// @ts-nocheck
import { CircularProgress, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import {
  Form
} from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import { PERMISSIONS, PERMISSION_ENTITIES, RED, VENDORS_LIST } from '../../../_config/consts.js';
import { hasFormWritePermission } from '../../../_config/utils.js';
import ListLoadingView from '../../ListLoadingView.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import LocksSelectorField from '../Elements/LocksSelectorField.jsx';
import * as LocksActions from '../../../redux/actions/lock.actions';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';

const validate = (values) => {
  const errors = {};
  return errors;
};

@reduxForm({ form: 'CameraLocksAssociationForm', validate })
@connect(state => ({ form: state.form.CameraLocksAssociationForm, companyConfigurations: state.user.companyConfigurations }))
class CameraLocksAssociationForm extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      lockSelected: null,
      locksOptions: [],
      isListLoading: false,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const locks = await dispatch(LocksActions.fetchLocks());
    const options = _.map(locks, lock => ({ value: lock, label: `${lock.name}` }));
    this.setState({ locksOptions: options });
    return options;
  }

  async onFilterLocks(value) {
    const { dispatch } = this.props;
    dispatch(LocksActions.setFilter('name', value));
    const locks = await dispatch(LocksActions.fetchLocks());
    const options = _.map(locks, lock => ({ value: lock, label: lock.name }));
    this.setState({ locksOptions: options });
    return options;
  }

  onSelectLock(lock) {
    if (lock && lock.value) {
      this.setState({ lockSelected: lock.value });
    }
  }

  onAddLockToCamera(lock) {
    const { onAddLockToCamera } = this.props;
    onAddLockToCamera(lock);
    this.setState({ lockSelected: null });
  }

  async onRemoveLockAssociation(lock) {
    const { onRemoveLockAssociation } = this.props;
    try {
      this.setState({ isListLoading: true });
      await onRemoveLockAssociation(lock);
      this.setState({ isListLoading: false });
    } catch (error) {
      this.setState({ isListLoading: false });
    }
  }
 
  render() {
    const {
      form,
      dispatch,
    } = this.props;
    const { lockSelected, locksOptions, isListLoading } = this.state;
    const selectedLocks = form && form.values && form.values.selectedLocks;
    return (
      <Form>
        <FormCardContainer
          title="associateLockCamera"
          subtitle="associateLockCameraDescription"
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Field
              name="lockTagSelectedSearch"
              component={SelectableField}
              noCache
              options={locksOptions}
              placeholder={<Entity entity="findLockToAdd" />}
              containerstyle={{ minWidth: 480, marginRight: 10 }}
              onInputChange={value => this.onFilterLocks(value)}
              onSelect={tagData => this.onSelectLock(tagData)}
            />
            <MDButton
              disabled={!lockSelected}
              title={<Entity entity="associateLockToCamera" />}
              style={{ borderRadius: 5 }}
              onClick={() => this.onAddLockToCamera(lockSelected)}
            />
          </div>
          {isListLoading ? <CircularProgress /> : null}
          {selectedLocks && !_.isEmpty(selectedLocks.data) ? (
            <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
              <div style={{ maxHeight: 400, padding: 5, overflowY: 'scroll' }}>
                <InfiniteScroll
                  pageStart={0}
                  useWindow={false}
                  initialLoad={false}
                  hasMore={selectedLocks.pagination.number + 1 < selectedLocks.pagination.totalPages}
                  loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    {_.map(selectedLocks.data, (item, index) => (
                      <div key={item.id}>
                        <div
                          style={{ backgroundColor: index % 2 === 0 ? 'white' : 'ghostwhite', borderBottom: '1px solid', borderTop: '1px solid', alignItems: 'center', justifyContent: 'space-between', display: 'flex', padding: 5, borderColor: '#f0f0f0' }}
                          key={item.id}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <h5 style={{ color: '#3f3f3f', marginLeft: 10 }}>{item.name}</h5>
                          </div>
                          <IconButton
                            style={{ color: RED }}
                            onClick={() => this.onRemoveLockAssociation(item)}
                          >
                            <DeleteIcon style={{ fontSize: 20 }}/>
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          ) : null}
        </FormCardContainer>
        <MDButton
          title={<Entity entity="save" />}
          containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
          style={{ height: 45, borderRadius: 0 }}
          onClick={() => dispatch(submit('CameraLocksAssociationForm'))}
        />
      </Form>
    );
  }
} 

export default CameraLocksAssociationForm;
