import React from 'react';
import GuestsTagsManagementList from './TagsManagementListsComponents/GuestsTagsManagementList.jsx';
import LocksTagsManagementList from './TagsManagementListsComponents/LocksTagsManagementList.jsx';

const listComponents = {
  guests: GuestsTagsManagementList,
  locks: LocksTagsManagementList,
};

export default class TagsManagementList extends React.Component {
  render() {
    const { tagListType } = this.props;
   
    const Component = listComponents[tagListType || 'guest'];

    return (
      Component ? <Component {...this.props } /> : null
    );
  }
}
