// @ts-nocheck
import { InfoOutlined, ShareOutlined } from '@material-ui/icons';
import { Form } from '@sketchpixy/rubix';
import { resourceActions as ResourceActions, resourceTypeActions as ResourceTypeActions } from '@bottega52/bookey-redux-module';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { ACCESSORIES_TYPES, BLUE, GREEN, LICENSE_TYPES, RED } from '../../../_config/consts.js';
import { elaborateReservationCheckedInterval, formatDomainHostNameToFriendlyName, localizeHelpCenterLink, minutesOfDay } from '../../../_config/utils';
import MDAccordion from '../../MDAccordion/MDAccordion.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import TagsSelectorListField from '../Elements/TagsSelectorListField.jsx';
import CustomField from '../Fields/CustomField.jsx';
import MDSwitchView from '../Fields/MDSwitch/MDSwitchView.jsx';
import * as UtilsActions from '../../../redux/actions/utils.actions.js';
import ResourceAvatarSelector from '../Fields/ResourceAvatarSelector.jsx';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';
import SentenceSelectorInputField from '../Fields/SentenceSelectorInputField.jsx';
import TagsSelectorField from '../Fields/TagsSelectorField.jsx';
import ResourcesTimeSelector from './ResourcesTimeSelector.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';
import { Divider } from '@material-ui/core';
import ShareIconCustom from '../../CustomIcons/ShareIconCustom.jsx';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';


const validate = (values) => {
  const errors = [];
  if (values) {
    if (!values.name) {
      errors.name = 'required';
    }
    if (!values.type) {
      errors.type = 'required';
    }

    if (!values.type || (values.type && values.type.length === 0)) {
      errors.type = 'required';
    }

    if (!values.credentialTimeframe
        || !values.credentialTimeframe.startDate
        || !values.credentialTimeframe.endDate) {
      errors.credentialTimeframe = 'required';
    }

    if (!values.timeIntervalFrom) {
      errors.timeIntervalFrom = 'required';
    }

    if (!values.timeIntervalTo) {
      errors.timeIntervalTo = 'required';
    }
    if (values.timeIntervalTo && values.timeIntervalFrom) {
      if (minutesOfDay(values.timeIntervalTo) <= minutesOfDay(values.timeIntervalFrom)) {
        errors.timeIntervalTo = 'Interval not valid';
      }
    }
    if (parseInt(values.deleteByUserWithPenaltyBeforeMinutes, 10) < 0) {
      errors.deleteByUserWithPenaltyBeforeMinutes = 'nonNegative';
    }
    
    if (parseInt(values.checkInWindowMinutes, 10) < 0) {
      errors.checkInWindowMinutes = 'nonNegative';
    }

    if (parseInt(values.checkInWindowBeforeMinutes, 10) < 0) {
      errors.checkInWindowBeforeMinutes = 'nonNegative';
    }

    if (parseInt(values.locksOpenValidAfterMinutes, 10) < 0) {
      errors.locksOpenValidAfterMinutes = 'nonNegative';
    }
    if (parseInt(values.maximumReservationTimeHours, 10) < 0) {
      errors.maximumReservationTimeHours = 'nonNegative';
    } else if (parseInt(values.maximumReservationTimeHours, 10) * 60 > minutesOfDay(values.timeIntervalTo) - minutesOfDay(values.timeIntervalFrom)) {
      errors.maximumReservationTimeHours = 'nonMoreThanReservationTimeInterval';
    }

    if (parseInt(values.freeBeforeMinutes, 10) < 0) {
      errors.freeBeforeMinutes = 'nonNegative';
    }

    if (parseInt(values.freeAfterMinutes, 10) < 0) {
      errors.freeAfterMinutes = 'nonNegative';
    }
    
  }
  return errors;
};


@reduxForm({ form: 'ResourcesForm', validate })
@connect(state => ({ form: state.form.ResourcesForm, locksDetailsMap: state.locks.cachedLocksMap }))
class ResourcesForm extends React.Component {

  constructor(props) {
    super(props);
    const hasTagsSelected = props && props.form && props.form.values && props.form.values.resourceUserTags && !_.isEmpty(props.form.values.resourceUserTags);
    const workspacesOptions =  _.map(_.compact(props.availableWorkspaces), workspace => ({ label: workspace, value: workspace }));
    this.state = {
      showLimitVisibility: hasTagsSelected,
      workspacesOptions: workspacesOptions,
    };
  }

  onToggleLimitVisibility(selected) {
    this.setState({ showLimitVisibility: selected });
    if (!selected) {
      const { dispatch } = this.props;
      dispatch(change('ResourcesForm', 'resourceUserTags', []));
    }
  }
  onOpenCheckinLockInfoPage() {
    const helpURL = localizeHelpCenterLink('bookey-add-on-smart-check-in');
    window.open(helpURL);
  }

  onFilterWorkspaces(value) {
    const { availableWorkspaces } = this.props;
    const filteredWorkspaces = _.filter(_.compact(availableWorkspaces), workspace => workspace.toLowerCase().includes(value.toLowerCase()));
    const workspacesOptions =  _.map(filteredWorkspaces, workspace => ({ label: workspace, value: workspace }));
    this.setState({ workspacesOptions });
    return value;
  }

  render() {
    const {
      availableLockTags,
      availableUserTags,
      availableTypesTags,
      form,
      locksFilteredByTags,
      guestsFilteredByTags,
      onFetchMoreGuests,
      onGuestTagsChange,
      onGuestInputChange,
      onFetchMoreLocks,
      onLockTagsChange,
      onLockInputChange,
      onLockFilterModeChange,
      onGuestFilterModeChange,
      onNewTag,
      onTypeInputChange,
      onDeleteResource,
      onSelectCheckInLock,
      resourcesOptions,
      onFetchMoreAccessories,
      onFetchMoreResources,
      accessoriesData,
      dispatch,
      onWorkspaceSelected,
      subcompanies,
      onOpenResourceSharingSettings,
      resource,
    } = this.props;
    const { showLimitVisibility, workspacesOptions } = this.state;
    const resourceID = form && form.values && form.values.id;
    const checkinLockId = form && form.values && form.values.checkInSmartLockId;
    const selectedLockData = _.find(locksFilteredByTags && locksFilteredByTags.data, { id: checkinLockId });
    
    const checkedInterval = form && form.values && form.values.credentialTimeframe && elaborateReservationCheckedInterval(form.values.credentialTimeframe.startDate, form.values.credentialTimeframe.endDate);
    const checkInAccessories = accessoriesData && accessoriesData.content ? _.filter(accessoriesData.content, accessory => accessory.type && accessory.type !== ACCESSORIES_TYPES.DOOR_SENSOR) : [];

    const workspaceTitleLabel = dispatch(UtilsActions.getResourcesWorkspaceLabel());
    const isOffice365IntegrationActive = dispatch(UtilsActions.isOffice365IntegrationActive());
    const automaticCheckIn = form && form.values && form.values.automaticCheckIn;
    const isSharedFromOtherSystem = form && form.values && form.values.sharedFromHostname;
    const isSharedWithOtherSystem = form && form.values && form.values.shared;
    const canEditResource = !AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENGINE]);

    return (
      <Form>
        <FormCardContainer
          title="resourceDetails"
          subtitle="resourceDetailsSubtitle"
          containerStyle={{ position: 'relative' }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <Field
              id="imageUrl"
              name="imageUrl"
              component={ResourceAvatarSelector}
            />
            <div style={{ width: '100%', marginLeft: 20 }}>
              <Entity
                id="name"
                name="name"
                componentClass={Field}
                componentAttribute="placeholder"
                entity="insertName"
                title={<Entity entity="resourceName" />}
                component={CustomField}
                disabled={isSharedFromOtherSystem || !canEditResource}
                mandatory
              />
              <Entity
                id="description"
                name="description"
                componentClass={Field}
                title={<Entity entity="description" />}
                componentAttribute="placeholder"
                entity="insertDescription"
                type="text"
                disabled={isSharedFromOtherSystem || !canEditResource}
                component={CustomField}
              />
              <Field
                name="workspace"
                title={<Entity entity={workspaceTitleLabel} />}
                component={SelectableField}
                options={workspacesOptions}
                canCreate={true}
                placeholder={<Entity entity="insertresourceWorkspace" />}
                containerstyle={{ marginBottom: 15, marginTop: -10 }}
                onNewOption={workspace => onWorkspaceSelected(workspace)}
                helpText={<Entity entity="resourceWorkspaceDescription" />}
                onSelect={value => onWorkspaceSelected(value)}
                onInputChange={name => this.onFilterWorkspaces(name)}
                disabled={isSharedFromOtherSystem || !canEditResource}
              />
              <Entity
                componentClass={Field}
                name="type"
                componentAttribute="placeholder"
                entity="insertType"
                title={<Entity entity="type" />}
                component={TagsSelectorField}
                helpText={<Entity entity="resourceTypeTagDescription" />}
                onNewTag={onNewTag}
                tags={availableTypesTags}
                onInputChange={onTypeInputChange}
                disabled={isSharedFromOtherSystem || !canEditResource}
                mandatory
              />
              {isOffice365IntegrationActive ? (
                <Entity
                  id="email"
                  name="email"
                  componentClass={Field}
                  componentAttribute="placeholder"
                  entity="insertEmail"
                  helpText={<Entity entity="resourceEmailOutlookDescription" />}
                  title={<Entity entity="resourceEmailOutlook" />}
                  component={CustomField}
                  mandatory
                />
              ) : null}
            </div>
          </div>
            {isSharedFromOtherSystem ? (
              <div style={{ display: 'flex', marginTop: 6, flexDirection: 'row', alignItems: 'center', position: 'absolute', right: 20, top: 20 }}>
                <ShareOutlined style={{ width: 19, color: '#3f3f3f', marginRight: 5 }} />
                <h5 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}>{<Entity entity="sharedFromCompany" data={{ company: formatDomainHostNameToFriendlyName(form.values.sharedFromHostname) }} />}</h5>
              </div>
            ) : null}
        </FormCardContainer>
        <FormCardContainer
          title="resourceSmartLocks"
          subtitle="resourceSmartLocksSubtitle"
          iconButtonAction={() => this.onOpenCheckinLockInfoPage()}
          icon={<InfoOutlined style={{ fontSize: 25, color: '#3f3f3f' }} />}
          iconButtonActionStyle={{ marginBottom: 5 }}
        >
          <TagsSelectorListField
            name="resourceSmartLockTags"
            tags={availableLockTags}
            selectedTags={locksFilteredByTags}
            form={form}
            tagsType="LOCK"
            title="locksTags"
            placeholder="insertLockTagsForCredential"
            listTitle="selectedLocksForResource"
            selectionSectionTitle="lockCheckinSelectionTitle"
            selectionSectionSubtitle="lockCheckinSelectionSubtitle"
            selectionSectionEmptySubtitle="selectionLockEmptySubtitle"
            isOptional
            isSingleSelection
            initialValues={selectedLockData ? [selectedLockData] : []}
            onFetchMoreItems={page => onFetchMoreLocks(page)}
            onTagsChange={values => onLockTagsChange(values)}
            onInputChange={value => onLockInputChange(value)}
            disabled={!canEditResource}
            onFilterModeChange={values => onLockFilterModeChange(values)}
            onSelectItem={lock => onSelectCheckInLock(lock)}
          />
        </FormCardContainer>
        <FormCardContainer
          title="checkIn"
          subtitle="checkInDescription"
          containerStyle={{ paddingBottom: 30 }}
        >
          <Field
            name="automaticCheckIn"
            label={<Entity entity="avoidBookeyCheckin" />}
            subtitle={<Entity entity="avoidBookeyCheckinDescription" />}
            titleStyle={{ fontSize: 16, marginBottom: 4, fontWeight: 'bold', color: '#3f3f3f' }}
            component={MDCheckBoxField}
            disabled={!canEditResource}
          />
          <Divider style={{ marginTop: 15, marginBottom: 15 }} />
          <div style={{ opacity: automaticCheckIn ? 0.5 : 1 }}>
            <h4 style={{ fontWeight: 'bold', marginBottom: 5 }}>{<Entity entity="checkInWindow" />}</h4>
            <h4 style={{ color: '#3f3f3f', fontWeight: 200, marginTop: 0, fontSize: 16 }}>{<Entity entity="checkInExplanation" />}</h4>
            <div style={{ marginTop: 20, marginLeft: 10, padding: 20, borderRadius: 10, backgroundColor: '#f0f0f0' }}>
              <Field
                id="checkInWindowBeforeMinutes"
                name="checkInWindowBeforeMinutes"
                component={SentenceSelectorInputField}
                disabled={automaticCheckIn || !canEditResource}
                sentence={L20NContext.getSync('checkInWindowBeforeMinutesSentence')}
              />
              <Field
                id="checkInWindowMinutes"
                name="checkInWindowMinutes"
                component={SentenceSelectorInputField}
                disabled={automaticCheckIn || !canEditResource}
                sentence={L20NContext.getSync('checkInWindowMinutesSentence')}
              />
            </div>
          </div>
        </FormCardContainer>
        <FormCardContainer
          title="resourceUsers"
          subtitle="resourceUsersSubtitle"
        >
          <MDSwitchView
            label={<Entity key={showLimitVisibility} entity={!showLimitVisibility ? 'limitUsers' : 'activeLimitation'} />}
            checked={showLimitVisibility}
            titleStyle={{ fontWeight: showLimitVisibility ? 'bold' : 'normal', color: showLimitVisibility ? GREEN : '#3f3f3f' }}
            onSelect={selected => this.onToggleLimitVisibility(selected)}
            disabled={!canEditResource}
          />
          {showLimitVisibility ? (
            <TagsSelectorListField
              name="resourceUserTags"
              selectorFieldName="userTagMatchingMode"
              tags={showLimitVisibility ? availableUserTags : []}
              selectedTags={showLimitVisibility ? guestsFilteredByTags : []}
              form={form}
              tagsType="GUEST"
              title="resourceUsersTags"
              placeholder="insertGuestTagsForCredential"
              listTitle="selectedUsers"
              isOptional
              showList
              disabled={!canEditResource}
              onFetchMoreItems={page => onFetchMoreGuests(page)}
              onFilterModeChange={values => onGuestFilterModeChange(values)}
              onTagsChange={values => onGuestTagsChange(values)}
              onInputChange={value => onGuestInputChange(value)}
            />
          ) : null}
        </FormCardContainer>
        {!isSharedFromOtherSystem ? (
          <FormCardContainer
            title="reservationTimeRules"
            subtitle="reservationTimeRulesDescription"
          >
            <ResourcesTimeSelector
              checkedInterval={checkedInterval}
              disabled={!canEditResource}
            />
          </FormCardContainer>
        ) : null}
        
        <FormCardContainer
          title="reservationCheckinRules"
          subtitle="reservationCheckinRulesDescription"
          containerStyle={{ paddingBottom: 30 }}
        >
          <Entity
            id="maximumReservationTimeHours"
            name="maximumReservationTimeHours"
            componentClass={Field}
            componentAttribute="sentence"
            component={SentenceSelectorInputField}
            unit="hours"
            disabled={isSharedFromOtherSystem || !canEditResource}
            entity="maximumReservationTimeMinutes"
          />
          <Field
            id="freeBeforeMinutes"
            name="freeBeforeMinutes"
            component={SentenceSelectorInputField}
            disabled={isSharedFromOtherSystem || !canEditResource}
            sentence={L20NContext.getSync('freeBeforeMinutesSentence')}
          />
          <Field
            id="freeAfterMinutes"
            name="freeAfterMinutes"
            component={SentenceSelectorInputField}
            disabled={isSharedFromOtherSystem || !canEditResource}
            sentence={L20NContext.getSync('freeAfterMinutesSentence')}
          />
          <Field
            id="locksOpenValidAfterMinutes"
            name="locksOpenValidAfterMinutes"
            component={SentenceSelectorInputField}
            disabled={isSharedFromOtherSystem || !canEditResource}
            sentence={L20NContext.getSync('locksOpenValidAfterMinutesSentence')}
          />
        </FormCardContainer>
        {isSharedFromOtherSystem ? (
          null
        ) : (
          <MDAccordion
            title={<Entity entity="advancedSettings" />}
            containerstyle={{ marginTop: 10, marginLeft: 2 }}
            innerContainerStyle={{ backgroundColor: '#fdfdfd', paddingTop: 0 }}
            titleStyle={{ fontSize: 27 }}
          >
            {isSharedWithOtherSystem || subcompanies ? (
              <FormCardContainer
                title="shareResource"
                subtitle="shareResourceDescription"
                containerStyle={{ zIndex: 0 }}
              >
                <div>
                  <h4><Entity entity="shareResourceDescriptionLong" /></h4>
                  {isSharedWithOtherSystem && form && form.values && form.values.subcompanies && !_.isEmpty(form.values.subcompanies) ? (
                    _.map(form.values.subcompanies, subcompany => (
                      <div style={{ display: 'flex', marginTop: 20, flexDirection: 'row', alignItems: 'center', padding: 20, backgroundColor: '#f0f0f0', borderRadius: 10 }}>
                        <ShareOutlined style={{ width: 22, color: '#3f3f3f', marginRight: 5 }} />
                        <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}>{<Entity entity="sharedWithCompany" data={{ company: formatDomainHostNameToFriendlyName(subcompany.hostname) }} />}</h4>
                      </div>
                    ))
                  ) : null}
                  <MDButton
                    title={<Entity entity="shareResource" />}
                    containerStyle={{ margin: 0, marginRight: 20, marginTop: 25 }}
                    icon={<ShareIconCustom style={{ width: 15, color: 'white', marginLeft: 10, marginTop: 0 }} />}
                    style={{ minHeight: 40 }}
                    backgroundColor={BLUE}
                    disabled={!canEditResource}
                    onClick={() => onOpenResourceSharingSettings(resource)}
                  />
                </div>
              </FormCardContainer>
            ) : null}
            <FormCardContainer
              title="penaltyInWindow"
              subtitle="penaltyWindowExplanation"
              containerStyle={{ paddingBottom: 30, zIndex: 0 }}
            >
              <Field
                id="deleteByUserWithPenaltyBeforeMinutes"
                name="deleteByUserWithPenaltyBeforeMinutes"
                component={SentenceSelectorInputField}
                disabled={!canEditResource}
                sentence={L20NContext.getSync('penaltyWindowSentence')}
              />
            </FormCardContainer>
            <FormCardContainer
              title="confirmationWindowMinutesTitle"
              subtitle="confirmationWindowMinutesDescription"
              containerStyle={{ paddingBottom: 30, zIndex: 0 }}
            >
              <Field
                id="confirmationWindowMinutes"
                name="confirmationWindowMinutes"
                component={SentenceSelectorInputField}
                disabled={!canEditResource}
                sentence={L20NContext.getSync('confirmationWindowMinutesSentence')}
              />
            </FormCardContainer>
            <FormCardContainer
              title="linkedResources"
              subtitle="linkedResourcesSubtitle"
              containerStyle={{ zIndex: 0 }}
            >
              <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
                <Field
                  name="linkedResourcesIds"
                  component={SelectableRowField}
                  options={resourcesOptions}
                  listContainerStyle={{ marginTop: 0 }}
                  listStyle={{ marginTop: 0 }}
                  disabled={!canEditResource}
                  emptyOptionsText={<Entity entity="availableResourcesSelectDates" />}
                  onFetchMoreItems={page => onFetchMoreResources(page)}
                />
              </div>
            </FormCardContainer>
            {checkInAccessories && !_.isEmpty(checkInAccessories) ? (
              <FormCardContainer
                title="linkedAccessory"
                subtitle="linkedAccessorySubtitle"
                containerStyle={{ zIndex: 0 }}
              >
                <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
                  <Field
                    name="resourceAccessoryIds"
                    component={SelectableRowField}
                    options={{
                      content: _.map(checkInAccessories, (accessory) => (
                        {
                          ...accessory,
                          type: <Entity entity="accessoryTypes" data={{ type: accessory.type }} />,
                        }
                      )),
                      pagination: accessoriesData.pagination,
                    }}
                    listContainerStyle={{ marginTop: 0 }}
                    listStyle={{ marginTop: 0 }}
                    emptyOptionsText={<Entity entity="availableResourcesSelectDates" />}
                    onFetchMoreItems={page => onFetchMoreAccessories(page)}
                  />
                </div>
              </FormCardContainer>
            ) : null}
          </MDAccordion>
        )}
        {resourceID && canEditResource && 
          <FormCardContainer
            title="deleteResource"
            subtitle="deleteResourceWarning"
          >
            <MDButton
              title={<Entity entity="deleteResource" />}
              backgroundColor={RED}
              onClick={() => onDeleteResource(resource)}
            />
          </FormCardContainer>
        }
      </Form>
    );
  }
} 

export default ResourcesForm;
