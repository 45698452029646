import Immutable from 'seamless-immutable';
import _ from 'lodash';
import moment from 'moment';

import {
  SAVE_TICKETS,
  APPEND_TICKETS,
  SET_TICKETS_PAGINATION_DATA,
  RESET_TICKETS_PAGINATION_DATA,
  SELECT_TICKET,
  RESET_TICKETS_FILTERS,
  SET_TICKET_FILTER,
  SAVE_TICKET_MANAGERS,
  UPDATE_TICKET,
  SET_TICKET_OPERATIONAL_MODE,
  SAVE_TICKET_CATEGORIES,
  UPDATE_TICKET_CATEGORY,
  SET_TICKET_CATEGORIES_FILTERS,
  RESET_TICKET_CATEGORIES_FILTERS,
  SET_TICKET_ORDER,
} from '../actions/actionTypes/ticket';

const initialState = Immutable({
  data: {
    content: [],
    filters: {},
    pagination: {},
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    },
    lastUpdate: 0,
  },
  selectedTicket: {},
  ticketManagers: {
    content: [],
  },
  ticketCategories: {
    content: [],
    filters: {},
    lastUpdate: 0,
  },
  isOperationalMode: false, // Se lista di 2° tipo
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_TICKETS:
      return state.setIn(['data', 'content'], action.tickets).setIn(['data', 'lastUpdate'], moment().valueOf());
    case APPEND_TICKETS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.tickets]).setIn(['data', 'lastUpdate'], moment().valueOf());
    case SET_TICKETS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case RESET_TICKETS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    case SELECT_TICKET:
      return state.set('selectedTicket', action.ticket);
    case RESET_TICKETS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case SET_TICKET_FILTER:
      return state.setIn(['data', 'filters', action.name], action.value);
    case SAVE_TICKET_MANAGERS:
      return state.setIn(['ticketManagers', 'content'], action.ticketManagers);
    case UPDATE_TICKET: {
      const index = _.findIndex(state.data.content, data => data.id === action.ticket.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.ticket);
      }
      return state;
    }
    case SET_TICKET_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SAVE_TICKET_CATEGORIES:
      return state.setIn(['ticketCategories', 'content'], action.categories).setIn(['ticketCategories', 'lastUpdate'], moment().valueOf());
    case UPDATE_TICKET_CATEGORY: {
      const index = _.findIndex(state.ticketCategories.content, data => data.id === action.category.id);
      if (index !== -1) {
        return state.setIn(['ticketCategories', 'content', index], action.category);
      }
      return state;
    }
    case SET_TICKET_ORDER:
      return state.setIn(['data', 'sorting', 'orderBy'], action.orderBy)
                  .setIn(['data', 'sorting', 'orderDir'], action.orderDir);
    case SET_TICKET_CATEGORIES_FILTERS:
      return state.setIn(['ticketCategories', 'filters', action.name], action.value);
    case RESET_TICKET_CATEGORIES_FILTERS:
      return state.setIn(['ticketCategories', 'filters'], {});
    default:
      return state;
  }
}
