module.exports = {
  SET_MARKETPLACE_SUBSCRIPTIONS: 'SET_MARKETPLACE_SUBSCRIPTIONS',
  SET_MARKETPLACE_SUBSCRIPTIONS_FILTER: 'SET_MARKETPLACE_SUBSCRIPTIONS_FILTER',
  SET_MARKETPLACE_SUBSCRIPTIONS_PAGINATION: 'SET_MARKETPLACE_SUBSCRIPTIONS_PAGINATION',
  SET_WALLET_CREDITS: 'SET_WALLET_CREDITS',
  SET_WALLET_INFO: 'SET_WALLET_INFO',
  SET_WALLET_CREDITS_FILTER: 'SET_WALLET_CREDITS_FILTER',
  SET_WALLET_CREDITS_PAGINATION: 'SET_WALLET_CREDITS_PAGINATION',
  SET_WALLET_USAGE: 'SET_WALLET_USAGE',
  SET_WALLET_USAGE_FILTER: 'SET_WALLET_USAGE_FILTER',
  SET_WALLET_USAGE_PAGINATION: 'SET_WALLET_USAGE_PAGINATION',
  SET_WALLET_SUBSCRIPTIONS: 'SET_WALLET_SUBSCRIPTIONS',
  SET_WALLET_SUBSCRIPTIONS_FILTER: 'SET_WALLET_SUBSCRIPTIONS_FILTER',
  SET_WALLET_SUBSCRIPTIONS_PAGINATION: 'SET_WALLET_SUBSCRIPTIONS_PAGINATION',
  SET_WALLET_AVAILABLE_CREDITS: 'SET_WALLET_AVAILABLE_CREDITS',
  RESET_MARKETPLACE_SUBSCRIPTIONS_FILTER: 'RESET_MARKETPLACE_SUBSCRIPTIONS_FILTER',
  RESET_WALLET_CREDITS_FILTER: 'RESET_WALLET_CREDITS_FILTER',
  RESET_WALLET_USAGE_FILTER: 'RESET_WALLET_USAGE_FILTER',
  RESET_WALLET_SUBSCRIPTIONS_FILTER: 'RESET_WALLET_SUBSCRIPTIONS_FILTER',
  SET_WALLET_BURN_RATE: 'SET_WALLET_BURN_RATE',
  SET_WALLET_BURN_RATE_DAILY: 'SET_WALLET_BURN_RATE_DAILY',
  SET_WALLET_EVENTS: 'SET_WALLET_EVENTS',
  SET_WALLET_EVENTS_PAGINATION: 'SET_WALLET_EVENTS_PAGINATION',
  SET_WALLET_EVENTS_FILTER: 'SET_WALLET_EVENTS_FILTER',
  RESET_WALLET_EVENTS_FILTERS: 'RESET_WALLET_EVENTS_FILTERS',
  SAVE_MARKETPLACE_ITEMS_CONTENT: 'SAVE_MARKETPLACE_ITEMS_CONTENT',
  SAVE_MARKETPLACE_ITEM_CONTENT: 'SAVE_MARKETPLACE_ITEM_CONTENT',
  SAVE_MARKETPLACE_BANNER_CONTENT: 'SAVE_MARKETPLACE_BANNER_CONTENT',
};
