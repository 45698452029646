import { Card, Divider, withStyles } from '@material-ui/core';
import { Col, Grid, Row } from '@sketchpixy/rubix';
import CheckInIcon from '@material-ui/icons/CheckCircleOutline.js';
import CheckoutIcon from '@material-ui/icons/ExitToApp.js';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { GREEN, ORANGE } from '../../_config/consts.js';
import { getLuckeyLogoSource, isMobileBrowser } from '../../_config/utils';
import MDButton from '../../components/MDButton/MDButton.jsx';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as VisitorsActions from '../../redux/actions/visitors.actions.js';
import VisitorsIconCustom from '../../components/CustomIcons/VisitorsIconCustom.jsx';
import moment from 'moment';
import ExitIconCustom from '../../components/CustomIcons/ExitIconCustom.jsx';
import { showModal } from '@bottega52/redux-modals';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
    backgroundColor: 'white',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30,
    margin: 100,
    backgroundColor: 'white',
  },
  image: {
    height: 50,
    alignSelf: 'flex-start',
    marginBottom: 20,
  },
});

@withRouter
@connect(state => ({ userData: state.user.data, routing: state.router, companyConfigurations: state.user.companyConfigurations }))
class VisitorsActionsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visitorCode: null,
      visitDate: null,
    };
  }
  
  componentDidMount() {
    const { routing } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    const code = parsed.code;
    const visitDate = parsed.visitDate;
    this.setState({ visitorCode: code, visitDate });
  }

  async onCheckIn() {
    const { dispatch } = this.props;
    const { visitorCode } = this.state;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(VisitorsActions.checkInVisitor(visitorCode));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalsActions.showModal({
        modalType: 'SUCCESS_MODAL',
        modalProps: {
          message: "checkInVisitorSuccess",
        },
      }))
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalsActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: {
            message: "checkInVisitorError",
          },
        }))
    }
  }

  async onCheckOut() {
    const { dispatch } = this.props;
    const { visitorCode } = this.state;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(VisitorsActions.checkOutVisitor(visitorCode));
      dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalsActions.showModal({
          modalType: 'SUCCESS_MODAL',
          modalProps: {
            message: "checkoutVisitorSuccess",
          },
        }))
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalsActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: {
            message: "checkoutVisitorError",
          },
        }))
    }
  }

  onBackToLogin() {
    const { dispatch } = this.props;
    dispatch(UserActions.logout());
  }


  render() {
    const { visitorCode, visitDate } = this.state;
    const year = new Date().getFullYear();
    const luckeyLogoSource = getLuckeyLogoSource();
    return (
      <div id="auth-container" style={{ height: '100vh', overflow: 'scroll', display: 'flex', alignItems: 'center', backgroundColor: 'white', paddingTop: 30, flexDirection: 'column' }} className="login">
        <img src={luckeyLogoSource} alt="Sofia" style={{ margin: 10, marginTop: 60, marginBottom: 15, alignSelf: 'center', height: 65 }} />
        <div style={{ padding: 5, paddingTop: 0, paddingBottom: 0, marginBottom: 25, marginTop: 10 }}>
          <div>
            <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', textAlign: 'center', marginBottom: 0, marginTop: 0 }}><Entity key={visitorCode} entity="visitDate" />{visitDate}</h4>
            <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', textAlign: 'center', marginBottom: 0 }}><Entity key={visitorCode} entity="visitorCode" /></h4>
            <h1 style={{ color: '#3f3f3f', fontWeight: 'bold', textAlign: 'center', marginBottom: 0, marginTop: 5 }}>{visitorCode}</h1>
          </div>
          <div style={{ marginTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Card raised onClick={() => this.onCheckIn()} style={{ padding: 10, height: 230, width: 250, borderRadius: 10, marginRight: 40, backgroundColor: GREEN, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <VisitorsIconCustom style={{ color: 'white', width: 35 }} />
              <h4 style={{ marginTop: 15, color: 'white', fontWeight: 'bold' }}><Entity entity="checkIn" /></h4>
              <h5 style={{ color: 'white', marginTop: 0, textAlign: 'center' }}><Entity entity="checkinVisitorExplanation" /></h5>
            </Card>
            <Card raised onClick={() => this.onCheckOut()}style={{ padding: 10, height: 230, width: 250, borderRadius: 10, backgroundColor: ORANGE, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <ExitIconCustom style={{ color: 'white', width: 35 }} />
              <h4 style={{ marginTop: 15, color: 'white', fontWeight: 'bold' }}><Entity entity="checkOut" /></h4>
              <h5 style={{ color: 'white', marginTop: 0, textAlign: 'center' }}><Entity entity="checkoutVisitorExplanation" /></h5>
            </Card>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
            <img src="/imgs/common/logoSofiaDark.png" alt="Sofia" style={{ height: 12, marginRight: 4 }} />
            <h6 style={{ fontSize: 10, margin: 0, marginTop: 3, color: '#3f3f3f' }}>©{year}</h6>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(VisitorsActionsView);
