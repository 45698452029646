// @ts-nocheck
import { Card, Checkbox, CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import GoToPageIcon from '@material-ui/icons/CallMissedOutgoing';
import BarChartIcon from '@material-ui/icons/InsertChart';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';
import { BLUE, PERMISSION_ENTITIES, PERMISSIONS } from '../../../_config/consts';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider';
import ResourcesIcon from '@material-ui/icons/FilterNone';
import ScrollableBarChart from '../../Charts/ScrollableBarChart.jsx';
import Permission from '../../Permission/Permission.jsx';
import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';


const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 15,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  header: {
    marginTop: -40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    justifyContent: 'center'
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  comparisonHeader: {
   position: 'absolute',
   right: 20,
   top: 0,
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center',
  },
  refreshHeader: {
   position: 'absolute',
   right: 0,
   top: 0,
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center'
  },
  filterHeader: {
   position: 'absolute',
   left: 0,
   top: 0,
   minWidth: 300,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
    color: '#3f3f3f',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAnalyticsView = () => (
  <div style={{ height: 328, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <BarChartIcon style={{ color: '#bdbdbd', fontSize: 50 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFound" /></h3>
  </div>
);

const metricsArray = [
  {
    value: 'AVERAGE_RESERVATION_TIME',
    label: 'averageReservationTime',
  },
  {
    value: 'UTILIZATION_PERCENTAGE',
    label: 'resourceUsagePercentage',
  },
]

class BookeyResourceTypesBarChart extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      selectedMetric: null,
      showDiff: false,
    }
  }

  async componentWillMount() {
    this.setState({ selectedMetric: metricsArray[0] });
  }

  componentWillReceiveProps(nextProps) {
    
  }

  onSelectMetric(metric) {
    const {  } = this.props;
    this.setState({ selectedMetric: metric });
  }

  render() {
    const { resourceTypesReservationTimeChartData, resourceTypesUtilizationChartData, 
      resourceTypesReservationTimeChartDataPrev, resourceTypesUtilizationChartDataPrev, 
      resourceReservationTimeChartData, resourceUtilizationChartData, 
      resourceReservationTimeChartDataPrev, resourceUtilizationChartDataPrev, 
      filters, lastUpdate, textColor, fullWidth, isRefreshing, classes, onRefreshMetric, forcedChartWidth} = this.props;
    const { selectedMetric, showDiff } = this.state;
    const chartWidth = forcedChartWidth? forcedChartWidth : fullWidth ? window.screen.width - 270 : window.screen.width - 520;
    
    const isResourceTypeFilterOn = filters&&filters.values&&filters.values.type&&filters.values.type!=="__ANY__"
    
    const chartData = selectedMetric?(
      showDiff?(
        selectedMetric.value==='AVERAGE_RESERVATION_TIME'?(isResourceTypeFilterOn?resourceReservationTimeChartDataPrev:resourceTypesReservationTimeChartDataPrev):
        selectedMetric.value==='UTILIZATION_PERCENTAGE'?(isResourceTypeFilterOn?resourceUtilizationChartDataPrev:resourceTypesUtilizationChartDataPrev):
        {}
      ):(
        selectedMetric.value==='AVERAGE_RESERVATION_TIME'?(isResourceTypeFilterOn?resourceReservationTimeChartData:resourceTypesReservationTimeChartData):
        selectedMetric.value==='UTILIZATION_PERCENTAGE'?(isResourceTypeFilterOn?resourceUtilizationChartData:resourceTypesUtilizationChartData):
        {}
      )
    ):{}

    const completelyEmpty = chartData && !_.isEmpty(chartData) && chartData.datasets && !chartData.datasets.filter(e=>e.data.filter(f=>f>0).length).length

    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1, marginRight: fullWidth ? 0 : 20 }} elevation={3}>
          <div className={classes.titleHeader}>
            <div className={classes.filterHeader}>
              <Select
                value={selectedMetric?{value: selectedMetric.value, label: L20NContext.getSync(selectedMetric.label)}:null}
                placeholder={<Entity entity="selectMetric" />}
                noResultsText={<Entity entity="noResultsFound" />}
                onChange={val => this.onSelectMetric(val)}
                options={metricsArray.map(e=>{return {value: e.value, label: L20NContext.getSync(e.label)}})}
                isClearable={false}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </div>
            {/*<ResourcesIcon style={{ fontSize: 35, color: textColor || '#3f3f3f', marginRight: 10 }} />*/}
            <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}>
              <Entity key={isResourceTypeFilterOn} entity={isResourceTypeFilterOn?"resources":"resourceTypes"} />
            </h3>
            <div className={classes.comparisonHeader} style={{marginTop:-10}}>
              <h4 style={{ color: '#3f3f3f', margin: 0, fontWeight:'bold', fontSize: 16 }}>{<Entity entity="bookeyCompareWithPrevious"/>}</h4>
              <Checkbox
                checked={showDiff}
                size='medium'
                onChange={(e)=>{this.setState({ showDiff: e.target.checked})}}
                inputProps={{  }}
                style={{zoom:'150%'}}
                color='primary'
              />
            </div>
            {/* 
            <div className={classes.refreshHeader}>
              <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}: {moment(lastUpdate).format('LLL')}</h5>
              <IconButton
                disabled={isRefreshing}
                style={{ opacity: isRefreshing ? 0.5 : 1 }}
                onClick={() => onRefreshMetric('NOT_USED')}
              >
                <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
              </IconButton>
            </div>
            */}
          </div>
          <div style={{ paddingTop: 20 }}>
            {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
            {chartData && !_.isEmpty(chartData) && !completelyEmpty ? (
              <ScrollableBarChart
                chartMaxHeight={270}
                chartMaxWidth={chartWidth}
                data={_.cloneDeep(chartData)}
                dataSetSize={24}
                showLegend={showDiff}
                stackedBars={!showDiff}
                key={JSON.stringify(chartData)}
              />
            ) : <EmptyAnalyticsView />}
          </div>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(BookeyResourceTypesBarChart);
