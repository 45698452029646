import { Icon, ControlLabel } from '@sketchpixy/rubix';
import Dropzone from 'react-dropzone';
import React from 'react';
import _ from 'lodash';
import { Entity } from '@sketchpixy/rubix/lib/L20n';


const ImageSelector = ({ source, onClick, isSelected }) => (
  <img
    alt="preview"
    src={source}
    style={{ height: '20%', width: '20%', border: isSelected ? '2px solid green' : '0px', marginLeft: 10, marginRight: 10, marginTop: 20, }}
    onClick={() => onClick()}
  />
);
export default class ImagePickerField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndexes: [],
    };
  }

  onHandleChange(e, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(e);
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }

  onImageClick(imageKey) {
    const { input, isSingleChoice } = this.props;
    const { selectedIndexes } = this.state;
    const checkedItems = isSingleChoice ? null : (input.value || []);
    if (_.includes(checkedItems, imageKey) || _.isEqual(checkedItems, imageKey)) { // if already selected
      this.onHandleChange(_.filter(checkedItems, i => i !== imageKey), input);
    } else if (isSingleChoice) {
      this.onHandleChange(imageKey, input);
    } else {
      this.onHandleChange([...checkedItems, imageKey], input);
    }
  }

  render() {
    const { input: { value: selectedValues }, meta, images } = this.props;
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
          {_.map(images, (image, key) =>
            <ImageSelector
              key={key}
              source={image}
              isSelected={_.includes(selectedValues, key) || _.isEqual(selectedValues, key)}
              onClick={() => this.onImageClick(key)}
            />,
          )}
        </div>
        {meta.touched && meta.error &&
          <ControlLabel style={{ color: 'deepred' }}>
            <h6 style={{ color: 'red', marginTop: 4 }}>
              <Entity key={meta.error} entity={meta.error} />
            </h6>
          </ControlLabel>
        }
      </div>
    );
  }
}
