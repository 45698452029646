import UserIcon from '@material-ui/icons/AccountBox';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { MOBILE_UDID_STATES } from '../../_config/consts.js';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import MDRadioButtonsGroupField from './Elements/MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import BuildingSettingIconCustom from '../CustomIcons/BuildingSettingIconCustom.jsx';
import { HelpOutline } from '@material-ui/icons';
import CustomField from './Fields/CustomField.jsx';
import SupportAgentIconCustom from '../CustomIcons/SupportAgentIconCustom.jsx';


const validate = (values) => {
  const errors = {};
  if (values) {
    if (!values.subject) {
      errors.subject = 'required';
    }
    if (!values.comment) {
      errors.comment = 'required';
    }
  }
  return errors;
};

@reduxForm({ form: 'SupportTicketForm', validate })
class SupportTicketForm extends React.Component {

  render() {
    
    return (
      <div style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
        <SupportAgentIconCustom style={{ width: 60, margin: 'auto' }} />
        <h4 style={{ fontSize: 20, color: '#3f3f3f', textAlign: 'center', fontWeight: 'bold', marginBottom: 40, marginTop: 10 }}><Entity entity="sendTicketToSupportDescription" /></h4>
        <Entity
          componentClass={Field}
          name="subject"
          componentAttribute="placeholder"
          entity="insertTitle"
          title={<Entity entity="ticketTitle" />}
          component={CustomField}
          mandatory
        />
        <Entity
          componentClass={Field}
          name="comment"
          componentAttribute="placeholder"
          entity="ticketNewMessageEmpty"
          containerStyle={{ marginTop: 10 }}
          title={<Entity entity="description" />}
          type="textarea"
          component={CustomField}
          mandatory
        />
      </div>
    );
  }
} 

export default SupportTicketForm;
