import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { initialize, change } from 'redux-form';
import * as InstallersActions from '../../redux/actions/installers.actions';
import * as RolesActions from '../../redux/actions/roles.actions';
import ListLoadingView from '../ListLoadingView.jsx';
import EmptyView from '../EmptyView.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';
import InstallerRow from './InstallerRow.jsx';

@connect(state => ({ installers: state.installers, themeName: state.settings.items.theme.data.themeName }))
class InstallersListView extends React.Component {


  render() {
    const { onAppendUsers, listContainerStyle, onSelectUser, onNewUser, installers: { data: { pagination, content: installersData }, selectedUser }, viewLoading } = this.props;
    const canCreateUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    return (
      <div className="list-view details-section" style={{ top: 320, paddingBottom: 180, overflow: 'auto', width: '100%', ...listContainerStyle }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => onAppendUsers(pagination.number + 1)}
          hasMore={pagination.number + 1 < pagination.totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '40%' }}>
            {!_.isEmpty(installersData) ?
              _.map(installersData, user =>
                <InstallerRow
                  key={user.id}
                  user={user}
                  isSelected={selectedUser && selectedUser.id === user.id}
                  onClick={() => onSelectUser(user)}
                />)
                : (
                  <div>
                    {!viewLoading ? (
                      <EmptyView
                        title="noInstallerUsers"
                        onNewEntity={canCreateUser ? () => onNewUser() : null}
                        newEntityTitle="addInstallerUser"
                        subtitle="startAddInstallers"
                        iconName="icon-simple-line-icons-user-following"
                      />) : <ListLoadingView />}
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }


} 

export default InstallersListView;
