// @ts-nocheck
import Card from '@material-ui/core/Card';
import CloudIcon from '@material-ui/icons/ImportExport';
import CompanyIcon from '@material-ui/icons/Business';
import ReactTooltip from 'react-tooltip';
import RoleIcon from '@material-ui/icons/VerifiedUser';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import Tag from '../../components/Tag.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts.js';
import CustomFieldsDisplayView from '../CustomFields/CustomFieldsDisplayView.jsx';

export default class GuestDetailsRow extends React.Component {
  render() {
    const { guest, themeName, hideFakeTag, onClick, isSelected } = this.props;
    const userShort = `${guest.firstname.charAt(0).toUpperCase()}${guest.lastname.charAt(0).toUpperCase()}`;
    const canReadUserTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER_TAG);
    const statusColor = !guest.enabled ? '#E53935' : '#43A047';
    return (
      <Card
        style={{ marginBottom: 20,borderRadius: 10, padding: 20, position: 'relative', borderLeft: `4px solid ${statusColor}` }}
        className={`card card-rounded ${!onClick && 'card-noHover'} ${isSelected && 'card-selected'}`}
        onClick={onClick ? () => onClick() : null}
        elevation={2}
      >
        <div className="card-body">
          <div className={`rounded-view lock-icon bg-${themeName || 'default'}-theme`}>
            <h3 style={{ color: 'white', margin: 0 }}>{userShort}</h3>
          </div>
          <div className="card-inner-body">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ width: '90%', display: 'flex', overflow: 'overlay', flexDirection: 'column' }}>
                <h4 className="card-title" style={{ marginLeft: 5 }}>{`${guest.firstname} ${guest.lastname}`}</h4>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                  <h5 className="card-description" style={{ margin: 5 }}>{guest.email}</h5>
                  {guest.companyName ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10, marginBottom: 2 }}>
                      |
                      <CompanyIcon style={{ fontSize: 17, marginLeft: 5, color: '#3f3f3f' }} />
                      <h5 style={{ fontSize: 14, margin: 0, marginLeft: 5, color: '#3f3f3f', fontWeight: 200 }}>{guest.companyName}</h5>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <CustomFieldsDisplayView
              textStyle={{ fontSize: 15 }}
              iconStyle={{ width: 15 }}
              containerStyle={{ marginBottom: 5, marginLeft: 5 }}
              customFields={guest.customFields}
            />
            {canReadUserTags && (
              <div className="tags-container" style={{ margin: 0, marginRight: 20 }}>
                {guest && guest.userTag && !hideFakeTag && <Tag tag={guest.userTag} />}
                {_.map(guest.tags, tag =>
                  <Tag
                    key={tag.id}
                    tag={tag}
                  />,
                )}
              </div>
            )}
          </div>
        </div>
        {guest.extId ? <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('guestSyncFromSource')}><CloudIcon style={{ color: '#4DB6AC', position: 'absolute', top: 12, right: guest.isGuestManager ? 27 : 6, marginRight: 10, marginLeft: 0, fontSize: 22 }} /></p> : null}
        {guest.isGuestManager ? <p style={{ margin: 0 }} data-tip={L20NContext.getSync('guestIsManager')}><RoleIcon style={{ color: 'orange', position: 'absolute', top: 6, right: 6, marginTop: 3, fontSize: 22 }} /> </p> : null}
        <Entity
          key={`${guest.enabled}`}
          style={{ fontWeight: 'bold', position: 'absolute', right: 6, bottom: 6, textTransform: 'uppercase', color: statusColor, textAlign: 'right', fontSize: 12, margin: 0, marginTop: 10 }}
          entity={!guest.enabled ? 'userDisabled' : 'userEnabled'}
        />
        <ReactTooltip delayShow={200} effect="float" />
      </Card>
    );
  }
}
