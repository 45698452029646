import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import LockSharingForm from '../forms/LockForms/LockSharingForm.jsx';


@connect((state) => ({
  lock: state.locks.selectedLock,
  subcompanies: state.settings.subcompanies,
}))

class LockSharingView extends React.Component {

  render() {
    const { subcompanies, dispatch, onSaveLockSharingOptions } = this.props;
    return (
      <div style={{ padding: 15, paddingTop: 0 }}>
        <h3 style={{ fontWeight: 'bold' }}>{<Entity entity="shareSmartLockDescriptionShort" />}</h3>
        <h4>{<Entity entity="shareSmartLockDescriptionLong" />}</h4>
        <LockSharingForm
          subcompanies={subcompanies}
          onSubmit={(sharingOptions) => onSaveLockSharingOptions(sharingOptions)}
          onValidateForm={() => dispatch(submit('LockSharingForm'))}
        />
      </div>
    );
  }
}

export default LockSharingView;