import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SET_SPINNER_VISIBILITY,
  SET_VIEW_LOADING,
  SET_LOADING_PROGRESS_VALUE,
  SET_LOADING_PROGRESS_VISIBLE,
  ADD_BACKGROUND_TASK,
  REMOVE_BACKGROUND_TASK,
  SET_WHATS_NEW_VISIBLE,
  SET_SUPPORT_TICKET_FORM_VISIBLE
} from '../actions/actionTypes/utils';

const initialState = Immutable({
  spinnerVisible: false,
  viewLoading: false,
  istWhatsNewVisible: false,
  isSupportTicketFormVisible: false,
  progressBar: {
    isVisibile: false,
    title: undefined,
    progress: 0,
  },
  backgroundTasks: [],
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SPINNER_VISIBILITY:
      return state.set('spinnerVisible', action.spinnerVisible);
    case SET_VIEW_LOADING:
      return state.set('viewLoading', action.isLoading);
    case SET_LOADING_PROGRESS_VALUE:
      return state.setIn(['progressBar', 'progress'], action.progress);
    case SET_LOADING_PROGRESS_VISIBLE: {
      return state.setIn(['progressBar', 'isVisible'], action.isVisible).setIn(['progressBar', 'title'], action.isVisible ? action.title : undefined);
    }
    case SET_WHATS_NEW_VISIBLE:
      return state.set('istWhatsNewVisible', action.isVisible);
    case SET_SUPPORT_TICKET_FORM_VISIBLE:
      return state.set('isSupportTicketFormVisible', action.isVisible);
    case ADD_BACKGROUND_TASK: {
      return state.set('backgroundTasks', [...state.backgroundTasks, action.data]);
    }
    case REMOVE_BACKGROUND_TASK: {
      const updatedTasks = _.filter(state.backgroundTasks, task => task.id !== action.taskId);
      return state.set('backgroundTasks', updatedTasks);
    }
    default:
      return state;
  }
}
