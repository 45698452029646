import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CheckInIcon from '@material-ui/icons/CheckCircleOutline.js';
import CheckoutIcon from '@material-ui/icons/ExitToApp.js';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { BLUE, GREEN, ORANGE } from '../../_config/consts';
import * as CardActions from '../../redux/actions/card.actions';
import DeleteOutlineIconCustom from '../CustomIcons/DeleteOutlineIconCustom.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';

import { getColorFromThemeName } from '../../_config/utils.js';
import * as VisitorsActions from '../../redux/actions/visitors.actions.js';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumnHeaderText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeaderArrow: {
    padding: 10,
    marginLeft: 10,
  },
  tableColumnHeaderArrowIcon: {
    fontSize: 20,
    width: 20,
  },
  tableColumnHeaderSortIcon: {
    width: 24,
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    minHeight: 80,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  button: {
    color: 'red',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
  },
  buttonPositive: {
    color: BLUE,
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    marginRight: 10,
    width: 'max-content',
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, visitors: state.visitors, themeName: state.settings.items.theme.data.themeName }))
class VisitorsTableView extends React.Component {
  
  async handleOrderChange(column) {
    const { onOrderChanged, dispatch } = this.props;
    const { visitors: { data: { sorting: {orderBy, orderDir} } } } = this.props;
    const orderDir_new = orderBy===column?(orderDir==='ASC'?'DESC':'ASC'):'ASC';
    await dispatch(VisitorsActions.setOrder(column, orderDir_new));
    onOrderChanged()
  };

  async onResetOrder() {
    const { onOrderChanged, dispatch } = this.props;
    await dispatch(VisitorsActions.setOrder(undefined, undefined));
    onOrderChanged()
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
  }

  render() {
    const {
      classes,
      onCheckoutVisitor,
      visitors: { selectedVisitor, data: { content: visitorsData, pagination: { number: currentPage, size, totalPages, totalElements }, sorting: {orderBy, orderDir} }},
      onFetchVisitorsOnPage,
      onRefresh,
      onDeleteVisitor,
      onCheckInVisitor,
      onOpenReferentDetails,
      themeName,
      canCreateItem
    } = this.props;
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div>
        <div>
          {!_.isEmpty(visitorsData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    {/* {orderBy && 
                      <IconButton onClick={() => this.onResetOrder()} style={{ marginLeft: 5, marginTop: 5 }}>
                        <UnsortIconCustom style={{ color: '#3f3f3f', width: 22 }} />
                      </IconButton>
                    } */}
                    <TableCell colSpan={3}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="visitors" /></h3>
                        <IconButton onClick={() => onRefresh()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="visitorsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchVisitorsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchVisitorsOnPage(page)}
                        />
                      )}
                    />
                    </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="visitorExpectedDateTitle" /></h4>
                        {/* {orderBy === 'expectedDate'?(
                          <IconButton onClick={() => this.handleOrderChange('expectedDate')} style={{opacity: 1}} className={classes.tableColumnHeaderArrow}>
                            {orderDir==='ASC'||orderBy!=='expectedDate'? <SortDescendingIconCustom className={classes.tableColumnHeaderArrowIcon}/> : <SortAscendingIconCustom className={classes.tableColumnHeaderArrowIcon}/>}
                          </IconButton>
                        ):(
                          <IconButton onClick={() => this.handleOrderChange('expectedDate')} style={{opacity: 0.6}} className={classes.tableColumnHeaderArrow}>
                            {<SortIconCustom className={classes.tableColumnHeaderSortIcon}/>}
                          </IconButton>
                        )} */}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="visitor" /></h4>
                        {/* {orderBy === 'email'?(
                          <IconButton onClick={() => this.handleOrderChange('email')} style={{opacity: 1}} className={classes.tableColumnHeaderArrow}>
                            {orderDir==='ASC'||orderBy!=='email'? <SortDescendingIconCustom className={classes.tableColumnHeaderArrowIcon}/> : <SortAscendingIconCustom className={classes.tableColumnHeaderArrowIcon}/>}
                          </IconButton>
                        ):(
                          <IconButton onClick={() => this.handleOrderChange('email')} style={{opacity: 0.6}} className={classes.tableColumnHeaderArrow}>
                            {<SortIconCustom className={classes.tableColumnHeaderSortIcon}/>}
                          </IconButton>
                        )} */}
                      </div>
                    </TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="code" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="primaryContact" /></h4></TableCell>
                    <TableCell>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="checkIn" /></h4>
                        {/* {orderBy === 'checkinDate'?(
                          <IconButton onClick={() => this.handleOrderChange('checkinDate')} style={{opacity: 1}} className={classes.tableColumnHeaderArrow}>
                            {orderDir==='ASC'||orderBy!=='checkinDate'? <SortDescendingIconCustom className={classes.tableColumnHeaderArrowIcon}/> : <SortAscendingIconCustom className={classes.tableColumnHeaderArrowIcon}/>}
                          </IconButton>
                        ):(
                          <IconButton onClick={() => this.handleOrderChange('checkinDate')} style={{opacity: 0.6}} className={classes.tableColumnHeaderArrow}>
                            {<SortIconCustom className={classes.tableColumnHeaderSortIcon}/>}
                          </IconButton>
                        )} */}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="checkOut" /></h4>
                        {/* {orderBy === 'checkoutDate'?(
                          <IconButton onClick={() => this.handleOrderChange('checkoutDate')} style={{opacity: 1}} className={classes.tableColumnHeaderArrow}>
                            {orderDir==='ASC'||orderBy!=='checkoutDate'? <SortDescendingIconCustom className={classes.tableColumnHeaderArrowIcon}/> : <SortAscendingIconCustom className={classes.tableColumnHeaderArrowIcon}/>}
                          </IconButton>
                        ):(
                          <IconButton onClick={() => this.handleOrderChange('checkoutDate')} style={{opacity: 0.6}} className={classes.tableColumnHeaderArrow}>
                            {<SortIconCustom className={classes.tableColumnHeaderSortIcon}/>}
                          </IconButton>
                        )} */}
                      </div>
                      </TableCell>
                      <TableCell>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="notesForVisitor" /></h4>
                      </div>
                    </TableCell>
                    {canCreateItem ? <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="actions" /></h4></TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(visitorsData, (visitor) => {
                    return (
                      <TableRow
                        key={visitor.id}
                        className={classes.row}
                        // hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        selected={false}
                      >
                        <TableCell>
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5, fontSize: 14 }}>
                              {moment(visitor.expectedDate).format('LL')}
                            </h5>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h5 style={{ fontWeight: 'bold', fontSize: 15, margin: 0, marginTop: 5, marginRight: 5 }}>{`${visitor.firstname} ${visitor.lastname}`}</h5>
                            <h5 style={{ marginTop: 7 }}>{visitor.email}</h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h5 style={{ fontSize: 15, margin: 0, marginTop: 5, marginRight: 5 }}>{visitor.code}</h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h6 className="link-label" onClick={() => onOpenReferentDetails(visitor.contactEmail)} style={{ color: 'cornflowerBlue', textDecoration: 'underline', fontSize: 14, margin: 0, marginTop: 5, marginRight: 5 }}>{visitor.contactEmail}</h6>
                          </div>
                        </TableCell>
                        <TableCell>
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5, fontSize: 14 }}>
                              {visitor.checkInDate ? moment(visitor.checkInDate).format('LLL'): '---'}
                            </h5>
                        </TableCell>
                        <TableCell>
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5, fontSize: 14 }}>
                              {visitor.checkOutDate ? moment(visitor.checkOutDate).format('LLL'): '---'}
                            </h5>
                        </TableCell>
                        <TableCell>
                            <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5, fontSize: 14 }}>
                              {visitor.notes || '---'}
                            </h5>
                        </TableCell>
                        {canCreateItem ? (
                          <TableCell>
                            <div className={classes.actionContainer} style={{ justifyContent: 'flex-start', marginLeft: 10 }}>
                              {!visitor.checkInDate && visitor.code ? (
                                <Button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    onCheckInVisitor(visitor);
                                  }}
                                  className={classes.button}
                                  style={{ color: GREEN, marginRight: 20 }}
                                >
                                  <Entity entity="checkIn" />
                                  <CheckInIcon style={{ fontSize: 20, marginLeft: 5 }} />
                                </Button> 
                              ) : null}
                              {!visitor.checkOutDate && visitor.code ? (
                                <Button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    onCheckoutVisitor(visitor);
                                  }}
                                  className={classes.button}
                                  style={{ color: ORANGE, marginRight: 20 }}
                                >
                                  <Entity entity="checkOut" />
                                  <CheckoutIcon style={{ fontSize: 20, marginLeft: 5 }} />
                                </Button>
                              ) : null}
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onDeleteVisitor(visitor);
                                }}
                                className={classes.button}
                              >
                                <Entity entity="delete" />
                                <DeleteOutlineIconCustom style={{ width: 20, marginLeft: 5 }} />
                              </Button>
                            </div>
                          </TableCell>
                        ): null}
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="visitorsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchVisitorsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchVisitorsOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : null}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(VisitorsTableView);
