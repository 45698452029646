import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { submit } from 'redux-form';
import MDButton from '../components/MDButton/MDButton.jsx';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UserActions from '../redux/actions/user.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import OctorateSelectAccomodationsForm from '../components/forms/Integrations/OctorateSelectAccomodationsForm.jsx';


@connect(state => ({ routing: state.router, language: state.settings.language, octorateData: state.user.octorateActivationParameters, form: state.form.OctorateSelectAccomodationsForm }))
class OctorateActivationView extends React.Component {

  async componentWillMount() {
    const { dispatch, routing } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    const access_token = parsed.access_token;
    const refresh_token = parsed.refresh_token;
    try {
      const accommodations = await dispatch(UserActions.getOctorateAccommodations(access_token));
      await dispatch(UserActions.setOctorateActivationParameters({
        token: access_token,
        refreshToken: refresh_token,
        accommodationIds: [],
        accommodations: accommodations,
        reservationsDaysBefore: 2,
        reservationsDaysAfter: 2,
        coldStartResources: false,
        reservationsSyncMode: [],
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionError" /></h6>),
        },
      }));
    }
  }
  
  async onActivateOctorateIntegration(activationData) {
    const { dispatch, octorateData, routing } = this.props;
    if (!activationData.pin && !activationData.credentialRule)
      return;
    if (!activationData.accommodations && !activationData.accommodations.length)
      return;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const reservationsSyncMode = []
      if (activationData.pin)
        reservationsSyncMode.push("PIN")
      if (activationData.credentialRule)
        reservationsSyncMode.push("CREDENTIAL_RULE")
      const activationDataParsed = {
        ...octorateData,

        accommodationIds: activationData.accommodations,
        coldStartResources: activationData.coldStartResources,
        reservationsSyncMode: reservationsSyncMode,
        
        accommodations: undefined,
      }
      await dispatch(UserActions.activateOctorateIntegration(activationDataParsed));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionSuccess" /></h6>),
        },
      }));
      setTimeout(async () => {
        dispatch(push('/'));
      }, 200);
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionError" /></h6>),
        },
      }));
    }
  }

  onReloadApplication() {
    const { dispatch } = this.props;
    dispatch(push('/dashboard'));
  }

  render() {
    const { dispatch, octorateData: { accommodations }, form } = this.props;
    return (
      <div id="auth-container" style={{ height: '100vh', overflow: 'scroll', display: 'flex', alignItems: 'center', backgroundColor: '#12637f', paddingTop: 30, flexDirection: 'column' }} className="login">
        <img alt="chainels" src="/imgs/common/integrationLogos/octorate-logo.png" style={{ height: 50, alignSelf: 'center' }} />
        <div style={{ width: '70%' }}>
          <h4 style={{ color: 'white', textAlign: 'center', marginTop: 10, marginBottom: 30 }}><Entity entity="activateOctorateIntegration" /></h4>
          {!accommodations||!accommodations.accommodations ? (
            <div style={{ marginTop: 20 }}>
              <h4 style={{ color: 'white', textAlign: 'center' }}><Entity entity="noOctorateAccommodationsFound" /></h4>
              <MDButton
                title={<Entity key={accommodations} entity="reload" />}
                titleStyle={{ marginTop: 0, fontSize: 13, color: 'black' }}
                backgroundColor="white"
                containerStyle={{ margin: 0, marginTop: 10 }}
                onClick={() => this.onReloadApplication()}
              />
            </div>
          ) : (
            <div>
              <div style={{ marginTop: 20 }}>
                <OctorateSelectAccomodationsForm
                  accommodations={accommodations.accommodations}
                  onSubmit={activationData => this.onActivateOctorateIntegration(activationData)}
                />
              </div>
              {form&&form.values&&(form.values.pin||form.values.credentialRule)&&form.values.accommodations&&form.values.accommodations.length?(
                <MDButton
                  title={<Entity key={JSON.stringify(accommodations)} entity="connectYourAccount" />}
                  titleStyle={{ marginTop: 0, fontSize: 13, color: 'black' }}
                  backgroundColor="white"
                  containerStyle={{ margin: 0, marginTop: 10 }}
                  onClick={() => dispatch(submit('OctorateSelectAccomodationsForm'))}
                />
              ):null}
            </div>
        )}
        </div>
      </div>
    );
  }
}

export default OctorateActivationView;
