import axios from 'axios';

const luckeyHelpCenterInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://help-center.sofialocks.com',
});

export function fetchTroubleshootingArticles(languagePrexif) {
  return luckeyHelpCenterInstance.get(`/wp-json/wp/v2/posts?_fields=id,title,link,tags,content&_embed&categories=87&per_page=100&orderby=modified&order=asc&lang=${languagePrexif}`);
}

// Fetch article By searchRef in the EXCERPT TEXT OF THE ARTICLE
export function fetchTroubleshootingArticle(searchRef, languagePrexif) {
  return luckeyHelpCenterInstance.get(`${languagePrexif}/wp-json/wp/v2/posts?_fields=id,title,link,tags,content&_embed&categories=87&per_page=100&search=${searchRef}`);
}