import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CardIcon from '@material-ui/icons/CreditCard';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { change, initialize, submit } from 'redux-form';
import { CARD_TYPES, DEFAULT_CREDENTIAL_TIME_FRAME, LICENSE_TYPES, MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES, VIEW_MODES } from '../_config/consts';
import * as formatter from '../_config/formatter';
import CardDeletedDetails from '../components/Cards/CardDeletedDetails.jsx';
import CardsExplanationView from '../components/Cards/CardsExplanationView.jsx';
import CardsListView from '../components/Cards/CardsListView.jsx';
import CardsOperationalSection from '../components/Cards/CardsOperationalSection.jsx';
import CardsTableView from '../components/Cards/CardsTableView.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import MasterElectionForm from '../components/forms/MasterElectionForm.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as AccessProfilesActions from '../redux/actions/accessProfiles.actions.js';
import * as CardActions from '../redux/actions/card.actions';
import * as CredentialActions from '../redux/actions/credential.actions';
import * as GuestsActions from '../redux/actions/guest.actions';
import * as LogEventsActions from '../redux/actions/logEvents.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as SettingsActions from '../redux/actions/setting.actions';
import * as TagActions from '../redux/actions/tag.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import { localizeHelpCenterLink } from '../_config/utils.js';


let filterTimeout;
@connect(state => ({ cards: state.cards, companyConfigurations: state.user.companyConfigurations, languange: state.settings.language, viewLoading: state.utils.viewLoading, routing: state.router, themeName: state.settings.items.theme.data.themeName }))
class Cards extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('cardsViewMode');
    this.state = {
      showDeletedCardDetails: false,
      showMasterElectionForm: false,
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    };
  }

  async componentWillMount() {
    const { dispatch, routing } = this.props;
    dispatch(CardActions.selectCard({}));
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(GuestsActions.resetGuestsFilters());
      dispatch(CardActions.resetCardsData());
      dispatch(CardActions.resetCardsFilters());
      await dispatch(CardActions.fetchCards());
      dispatch(SettingsActions.fetchCompanyMetrics());
      const isAccessProfileAddonActive = dispatch(UtilsActions.isAccessProfileAddonActive());
      if (isAccessProfileAddonActive) {
        dispatch(AccessProfilesActions.fetchAccessProfiles());
      }
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
    this.closeOperationalSection();
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    const deviceId = parsed.deviceId;
    if (deviceId) {
      const card = await dispatch(CardActions.fetchCardsFromDeviceId(deviceId));
      if (card) {
        this.onSelectCard(card);
      }
    }
    const newEntity = parsed.newEntity;
    if (newEntity) {
      this.onNewCredentialButtonClicked();
    }

    const searchGuest = parsed.searchGuest;
    if (searchGuest) {
      try {
        const guest = JSON.parse(searchGuest);
        const guestOption = { value: guest.id, label: `${guest.firstname} ${guest.lastname} ${guest.email}` };
        this.onSelectGuest(guestOption);
        dispatch(change('SearchBarForm', 'guestSelected', guestOption));
      } catch (error) {}
    }

    const newElementForGuest = parsed.newElementForGuest;
    if (newElementForGuest) {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      try {
        const guestTagId = JSON.parse(newElementForGuest);
        await dispatch(CredentialActions.fetchGuestsByTags({ id: guestTagId }, 'AT_LEAST_ONE_TAG'));
        const guestTagDetails = await dispatch(TagActions.fetchGuestTagDetails(guestTagId));
        this.onNewCredentialButtonClicked();
        dispatch(change('CardCredentialForm', 'guestTags', guestTagDetails));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } catch (error) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CardActions.selectCard({}));
  }

  onNewCredentialButtonClicked() {
    const { dispatch } = this.props;
    dispatch(CardActions.selectCard({}));
    dispatch(CredentialActions.selectCredential({}));
    dispatch(initialize('CardCredentialForm', {
      timeIntervalFrom: moment().startOf('day').valueOf(),
      timeIntervalTo: moment().endOf('day').valueOf(),
      dateIntervalTimeFrom: moment().startOf('day').valueOf(),
      dateIntervalTimeTo: moment().endOf('day').valueOf(),
      daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
      lockTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
      type: CARD_TYPES.GENERIC_CARD,
      credentialTimeframe: DEFAULT_CREDENTIAL_TIME_FRAME,
    }));
    if (this.cardsOperationalSection) {
      this.cardsOperationalSection.getWrappedInstance().selectTab(0);
    }
    dispatch(CardActions.setOperationalMode(true));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(true));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
    dispatch(CardActions.selectCard({}));
  }

  async fetchCards() {
    const { dispatch, cards: { selectedCard } } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    dispatch(CardActions.setOperationalMode(false));
    try {
      dispatch(CardActions.selectCard({}));
      await dispatch(CardActions.fetchCards());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
      dispatch(CardActions.setOperationalMode(false));
    }
  }

  async onSetCardFilter(field, value) {
    const { dispatch } = this.props;
    if (value && ((_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2))) {
      dispatch(CardActions.setCardFilter(field, _.trim(value)));
      if (filterTimeout) clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => {
        this.setState({ viewLoading: true });
        await this.fetchCards();
        this.setState({ viewLoading: false });
      }, 500);
    } else {
      dispatch(CardActions.setCardFilter(field, null));
      await this.fetchCards();
    }
  }

  async onSelectGuest(guestValue) {
    const { dispatch } = this.props;
    if (guestValue) {
      dispatch(CardActions.setCardFilter('userId', guestValue.value));
      this.setState({ viewLoading: true });
      await this.fetchCards();
      this.setState({ viewLoading: false });
    } else {
      dispatch(CardActions.setCardFilter('userId', undefined));
      await this.fetchCards();
    }
  }

  async onSelectLocksTags(tags) {
    const { dispatch } = this.props;
    if (tags && !_.isEmpty(tags)) {
      dispatch(UtilsActions.setViewLoading(true));
      const formattedTag = _.map(tags, tag => tag.value);
      this.onSetCardFilter('lockTagIds', formattedTag);
    } else {
      this.onSetCardFilter('lockTagIds', null);
      dispatch(UtilsActions.setViewLoading(true));
      await this.fetchCards();
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onSelectCard(card, avoidSetSelectedCard = false) {
    const { dispatch, companyConfigurations } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    this.setState({ showDeletedCardDetails: false });
    try {
      let cardToSelect = card;
      if (card.deviceId) {
        const validation = await dispatch(CardActions.validateDeviceId(card.deviceId));
        cardToSelect = { ...card, validation };
      } else {
        dispatch(change('CardCredentialForm', 'areDeviceDatesLocked', false));
      }
      if (!avoidSetSelectedCard) {
        dispatch(CardActions.selectCard(cardToSelect));
      }
      dispatch(CardActions.fetchCardsStatus(card));
      try {
        dispatch(CardActions.fetchCardValidationStatus(card));
      } catch (error) {}
      dispatch(LogEventsActions.resetSmartLocksEventsData());
      await dispatch(CardActions.fetchCardCredentials(card));
      let newCredendialDefaults;
      if (!card.credentialRule) {
        newCredendialDefaults = {
          timeIntervalFrom: moment().startOf('day').valueOf(),
          timeIntervalTo: moment().endOf('day').valueOf(),
          dateIntervalTimeFrom: moment().startOf('day').valueOf(),
          dateIntervalTimeTo: moment().endOf('day').valueOf(),
          daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
          lockTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
        };
      }
      // Fetch guests and locks by tag to show them when credential is selected
      const lockTags = card.credentialRule ? card.credentialRule.lockTags : [];
      const guestTags = card.credentialRule ? card.credentialRule.guestTags : [];
      const lockMatchingMode = card.credentialRule ? card.credentialRule.lockTagMatchingMode : MATCH_TAG_MODE.AT_LEAST_ONE_TAG;

      // Handle Custom card Ids map
      const isCardMappingEnabled = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.enabled;
      const cardIdsMap = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.cardIdsMap ? companyConfigurations.customCardsMapping.cardIdsMap : [];
      const originalCard = isCardMappingEnabled ? _.find(cardIdsMap, cardToTranslate => cardToTranslate.luckeyCardId === card.deviceId) : null;

      
      let formDefaultValues = {
        deviceBackendId: card.id,
        deviceId: card.deviceId,
        selectedGuestTags: guestTags,
        selectedLocksTags: lockTags,
        type: card.type,
        notes: card.notes,
        accessProfileSelection: card.credentialRule.accessProfileId,
        ...card.credentialRule,
        ...newCredendialDefaults,
      };

      // Handle credential rule  additional time range -> if not present avoid to init the form
      const defaultAdditionalTimeRange = card.credentialRule && card.credentialRule.additionalTimeRange;
      if (defaultAdditionalTimeRange) {
        formDefaultValues = {
          ...formDefaultValues,
          additionalTimeRange_default: [formatter.formatInputData(formatter.TIME_RANGE, defaultAdditionalTimeRange)],
        }
      }

      if (originalCard) {
        formDefaultValues = {
          ...formDefaultValues,
          originalCardId: {
            value: originalCard,
            label: originalCard && originalCard.originalId,
          },
        };
      }
      if (card.additionalCredentialRules && !_.isEmpty(card.additionalCredentialRules)) {
        const formattedAdditionalTimeProfiles = _.map(card.additionalCredentialRules, additionalCredentialRule => {
          let formattedAdditionalTimeProfile = {
            ...additionalCredentialRule,
            additionalTimeRange_additional: [formatter.formatInputData(formatter.TIME_RANGE, additionalCredentialRule.additionalTimeRange)],
          };
          // handle additional timeRange for form -> if not present don't initi
          if (!additionalCredentialRule.additionalTimeRange) {
            formattedAdditionalTimeProfile = _.omit(formattedAdditionalTimeProfile, 'additionalTimeRange_additional');
          }
          return formattedAdditionalTimeProfile;
        });
        formDefaultValues = {
          ...formDefaultValues,
          additionalCredentialRules: card.additionalCredentialRules,
          additionalTimeProfiles: formattedAdditionalTimeProfiles
        }
      }
      dispatch(initialize('CardCredentialForm', formDefaultValues));
      dispatch(CredentialActions.fetchLocksByTagsNoDebounce(lockTags, lockMatchingMode));
      _.each(card.additionalCredentialRules, (acr, index) => {
        dispatch(CredentialActions.fetchLocksByTagsNoDebounce(acr.lockTags, acr.lockTagMatchingMode, index + 1));
      })
      dispatch(CredentialActions.fetchGuestsByTags(guestTags, MATCH_TAG_MODE.EVERY_TAG));
      dispatch(CardActions.setOperationalMode(true));
    } catch (error) {
      dispatch(CardActions.selectCard({}));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onCreateCardCredentialFromModel(card) {
    const { dispatch } = this.props;
    dispatch(CardActions.selectCard({}));
    dispatch(CredentialActions.selectCredential({}));
    const lockTags = card.credentialRule ? card.credentialRule.lockTags : [];
    const lockMatchingMode = card.credentialRule ? card.credentialRule.lockTagMatchingMode : MATCH_TAG_MODE.AT_LEAST_ONE_TAG;
    const formInitValues = {
      selectedLocksTags: lockTags,
      type: card.type,
      notes: card.notes,
      selectedType: card.type,
      ..._.omit(card.credentialRule, ['guestTags', 'guestTagMatchingMode', 'guestTagIds']),
      ..._.omit(card, ['id', 'deviceId', 'deviceBackendId']),
    };
    dispatch(initialize('CardCredentialForm', formInitValues));
    dispatch(CredentialActions.fetchLocksByTags(lockTags, lockMatchingMode));
    dispatch(CardActions.setOperationalMode(true));
  }
  
  onShowDeletedCardDetails(card) {
    const { dispatch } = this.props;
    dispatch(CardActions.selectCard(card));
    dispatch(CardActions.setOperationalMode(false));
    this.setState({ showDeletedCardDetails: true });
  }

  onHideDeletedCardDetails() {
    const { dispatch } = this.props;
    this.setState({ showDeletedCardDetails: false });
    dispatch(CardActions.selectCard({}));
  }

  async onSearchReset() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    this.setState({ viewLoading: true });
    dispatch(CardActions.resetCardsFilters());
    await dispatch(CardActions.fetchCards());
    this.setState({ viewLoading: false });
    dispatch(UtilsActions.setViewLoading(false));
  }

  async onDeleteCardWithMaster(masterSelected) {
    const { dispatch, cards: { selectedCard } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CardActions.deleteCardAndElectMaster(selectedCard.id, masterSelected));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showMasterElectionForm: false });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorDeletingCard" /> },
      }));
    }
  }

  async onDeleteCardFromAllPlants() {
    const { dispatch, cards: { selectedCard } } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CardActions.deleteCard(selectedCard.id));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showMasterElectionForm: false });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorDeletingCard" /> },
      }));
    }
  }

  onShowInfoModal() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="cards" />,
        content: <CardsExplanationView />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onExportElements(format) {
    const { dispatch } = this.props;
    dispatch(CardActions.exportCards(format));
  }
  
  onCardListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    CardActions.setCardsViewMode(selectedViewMode);
    if (activeTab === 0) {
      dispatch(CardActions.resetCardsPaginationData());
      dispatch(CardActions.fetchCards(0));
    }
  }

  onFetchCardsOnPage(page) {
    const { dispatch } = this.props;
    dispatch(CardActions.fetchCards(page));
  }

  onSelectOriginalCard(cardOptionValue) {
    if (!cardOptionValue) {
      this.onSetCardFilter('deviceId', '');
    } else {
      const { luckeyCardId } = cardOptionValue;
      this.onSetCardFilter('deviceId', luckeyCardId);
    }
  }

  render() {
    const { cards, cards: { selectedCard }, companyConfigurations, themeName, dispatch, viewLoading } = this.props;
    const { showDeletedCardDetails, showMasterElectionForm, activeTab } = this.state;
    const canCreateNewCard = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canSearchGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const numberOfCards = cards && cards.cardsData && cards.cardsData.pagination && cards.cardsData.pagination.totalElements ? cards.cardsData.pagination.totalElements : 0;
    const cardIdsMap = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.cardIdsMap ? companyConfigurations.customCardsMapping.cardIdsMap : [];
    const isCardMappingEnabled = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.enabled;
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          isLockTagsMultiSelectionActive
          newEntityTitle="addCard"
          newEntityIconName="icon-simple-line-icons-credit-card"
          searchPlaceholderEntityName="cardNumber"
          searchGuestContainerStyle={{ width: 280 }}
          isLoading={viewLoading}
          onSearchReset={() => this.onSearchReset()}
          onInfo={() => this.onShowInfoModal()}
          onNewEntity={canCreateNewCard ? () => this.onNewCredentialButtonClicked() : null}
          onSearchSubmit={() => this.fetchCards()}
          onFilterChange={value => this.onSetCardFilter('deviceId', value)}
          onSelectGuest={canSearchGuest ? value => this.onSelectGuest(value) : null}
          onSelectLockTag={tags => this.onSelectLocksTags(tags)}
          mainFilterTextFormattingFunction={value => value.replace(/\s+/g, '')}
          onExportElements={!isLuckeyLite ? format => this.onExportElements(format) : null}
          cardIdsMap={cardIdsMap}
          isCardMappingEnabled={isCardMappingEnabled}
          numberOfElements={numberOfCards}
          onSelectOriginalCard={selectedOriginalCard => this.onSelectOriginalCard(selectedOriginalCard)}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CardIcon style={{ fontSize: 25 }} />
              <h4 style={{ marginTop: 0 }}>{numberOfCards}</h4>
            </div>
          }
        />
        {!isLuckeyLite && cards && cards.cardsData && !_.isEmpty(cards.cardsData) ? (
          <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onCardListModeChange(index)}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          </div>
        ) : null}
        {activeTab === 0 && !isLuckeyLite ? (
          <CardsTableView
            onNewCard={() => this.onNewCredentialButtonClicked()}
            onShowInfoModal={() => this.onShowInfoModal()}
            onSelectCard={(card) => card.deleted ? this.onShowDeletedCardDetails(card) : this.onSelectCard(card)}
            onFetchCardsOnPage={page => this.onFetchCardsOnPage(page)}
            onRefreshCards={() => this.fetchCards()}
            onCreateCardCredentialFromModel={card => this.onCreateCardCredentialFromModel(card)}
          />
          ) : null}
        {activeTab === 1 || isLuckeyLite ? (
          <CardsListView
            listContainerStyle={{ top: isLuckeyLite ? 140 : 190 }}
            onSelectCard={(card) => card.deleted ? this.onShowDeletedCardDetails(card) : this.onSelectCard(card)}
            onNewCardClick={() => this.onNewCredentialButtonClicked()}
            onShowInfoModal={() => this.onShowInfoModal()}
          />) : null}
        <OperationalView
          title={<Entity entity="sectionTitle" data={{ name: 'cardsOperational' }} />}
          themeName={themeName}
          isVisible={cards.isOperationalMode}
          style={{ margin: 0, padding: 0 }}
          onRefresh={selectedCard ? () => this.onSelectCard(selectedCard) : null}
          onClose={() => this.closeOperationalSection()}
        >
          <CardsOperationalSection
            ref={(c) => { this.cardsOperationalSection = c; }}
            onShowInfoModal={() => this.onShowInfoModal()}
            onCardFormSubmit={() => dispatch(submit('CardCredentialForm'))}
            onShowMasterElectionForm={() => this.setState({ showMasterElectionForm: true })}
          />
        </OperationalView>
        <OperationalView
          title={<Entity entity="sectionTitle" data={{ name: 'cardsOperational' }} />}
          themeName={themeName}
          isVisible={showDeletedCardDetails}
          style={{ margin: 0, padding: 0 }}
          onClose={() => this.onHideDeletedCardDetails()}
        >
          <CardDeletedDetails />
        </OperationalView>
        <OperationalView
          title={<Entity entity="sectionTitle" data={{ name: 'cardsOperational' }} />}
          themeName={themeName}
          isVisible={showMasterElectionForm}
          style={{ margin: 0, padding: 0 }}
          onClose={() => this.setState({ showMasterElectionForm: false })}
        >
          <MasterElectionForm
            onDeleteCardWithMaster={(masterSelected) => this.onDeleteCardWithMaster(masterSelected)}
            onDeleteCardFromAllPlants={() => this.onDeleteCardFromAllPlants()}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default Cards;
