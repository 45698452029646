module.exports = {
  SET_SELECTED_GATEWAY: 'SET_SELECTED_GATEWAY',
  SAVE_GATEWAYS: 'SAVE_GATEWAYS',
  APPEND_GATEWAYS: 'APPEND_GATEWAYS',
  SAVE_GATEWAYS_PAGINATION: 'SAVE_GATEWAYS_PAGINATION',
  UPDATE_GATEWAY: 'UPDATE_GATEWAY',
  REMOVE_GATEWAY: 'REMOVE_GATEWAY',
  RESET_GATEWAYS_DATA: 'RESET_GATEWAYS_DATA',
  SET_GATEWAYS_FILTER: 'SET_GATEWAYS_FILTER',
  RESET_GATEWAYS_FILTERS: 'RESET_GATEWAYS_FILTERS',
  SET_GATEWAYS_OPERATIONAL_MODE: 'SET_GATEWAYS_OPERATIONAL_MODE',
  SET_SELECTED_GATEWAY_NOTIFICATION: 'SET_SELECTED_GATEWAY_NOTIFICATION',
  SAVE_WIRED_GATEWAYS: 'SAVE_WIRED_GATEWAYS',
  APPEND_WIRED_GATEWAYS: 'APPEND_WIRED_GATEWAYS',
  SAVE_WIRED_GATEWAYS_PAGINATION: 'SAVE_WIRED_GATEWAYS_PAGINATION',
  SET_SELECTED_WIRED_GATEWAY: 'SET_SELECTED_WIRED_GATEWAY',
  RESET_WIRED_GATEWAYS_DATA: 'RESET_WIRED_GATEWAYS_DATA',
  SET_WIRED_GATEWAYS_FILTER: 'SET_WIRED_GATEWAYS_FILTER',
  RESET_WIRED_GATEWAYS_FILTERS: 'RESET_WIRED_GATEWAYS_FILTERS',
  UPDATE_WIRED_GATEWAY: 'UPDATE_WIRED_GATEWAY',
  RESET_GATEWAYS_PAGINATION_DATA: 'RESET_GATEWAYS_PAGINATION_DATA',
  SET_GATEWAYS_ORDER: 'SET_GATEWAYS_ORDER',
};
