import { Modal } from '@sketchpixy/rubix';
import React from 'react';
import { BLUE, RED } from '../../_config/consts';
import MDButton from '../MDButton/MDButton.jsx';


const icons = {
  warning: '!',
  info: 'i',
  question: '?',
  error: (
    <span className="swal2-x-mark swal2-animate-x-mark">
      <span className="swal2-x-mark-line-left" />
      <span className="swal2-x-mark-line-right" />
    </span>
  ),
  success: (
    <span>
      <div className="swal2-success-circular-line-left" />
      <span className="swal2-success-line-tip swal2-animate-success-line-tip" />
      <span className="swal2-success-line-long swal2-animate-success-line-long" />
      <div className="swal2-success-ring" />
      <div className="swal2-success-fix" />
      <div className="swal2-success-circular-line-right" />
    </span>
  ),
};

export default class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  close() {
    this.setState({ show: false });
  }

  open() {
    this.setState({ show: true });
  }

  render() {
    const { title, message, onConfirm, onCancel, onOutsideClick, confirmButtonStyle, cancelButtonStyle, type, onConfirmText, onCancelText, visible } = this.props;
    return (
      <Modal
        show={this.state.show || visible}
        className="swal2-modal alert-container"
        onHide={onOutsideClick ? () => onOutsideClick() : undefined}
      >
        { type &&
          <div className={`swal2-icon swal2-${type} swal2-animate-${type}-icon`}>
            {icons[type]}
          </div>
        }
        <h2
          className="swal2-title"
          id="modalTitleId"
          style={{ marginTop: type ? 0 : 20 }}
        >{title}</h2>
        <div id="modalContentId" style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }} className="swal2-content">{message}</div>
        <br />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          {onConfirm && (
            <MDButton
              containerStyle={{ marginTop: 0 }}
              title={onConfirmText || 'OK'}
              backgroundColor={BLUE}
              style={{ marginTop: 0, width: 100, ...confirmButtonStyle }}
              onClick={() => onConfirm()}
            />
          )}
          {onCancel && (
            <MDButton
              containerStyle={{ marginTop: 0 }}
              title={onCancelText || 'Cancel'}
              backgroundColor={RED}
              style={{ marginTop: 0, width: 100, ...cancelButtonStyle }}
              onClick={() => onCancel()}
            />
          )}
        </div>
      </Modal>
    );
  }
}
