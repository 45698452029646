import LocationIcon from '@material-ui/icons/Business';
import { Card, Divider, List, ListItem, Popper } from '@material-ui/core';
import OpenNewWindow from '@material-ui/icons/Launch';
import CaretIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import { MenuItem, NavDropdownHover } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import * as UserActions from '../redux/actions/user.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import { getPlantName } from '../_config/utils';


@connect((state) => ({ user: state.user.data }))
class LocationsMenu extends React.Component {
  constructor(props) {
    super(props);
    const currentLocation = getPlantName();
    this.state = {
      selectedLocation: _.startCase(currentLocation),
      isOpend: false,
      anchorEl: null,
      currentlyHovering: false,
    };
  }

  async onSelectLocation(location, newWindow = false) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.changeDomain(location, newWindow));
      if (!newWindow) this.setState({ selectedLocation: location.name });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { domains } = this.props;
    const { selectedLocation, isOpend, anchorEl } = this.state;
    const allowPopperToFlip = _.size(domains) <= 12;
    return (
      <div
        onMouseEnter={(e) => this.setState({ anchorEl: e.currentTarget, isOpend: true }) }
        onMouseLeave={(e) => this.setState({ anchorEl: null, isOpend: false })}
      >
        <div
          aria-controls="language-menu"
          aria-haspopup="true"
          onClick={(e) => this.setState({ anchorEl: e.currentTarget, isOpend: true })}
        >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4, marginRight: 10, marginLeft: 20 }}>
            <LocationIcon style={{ marginRight: 10, marginBottom: 0, fontSize: 23, color: 'white'}} />
            <h4 style={{ color: 'white', fontWeight: 'bold', marginTop: 12 }}>{selectedLocation}</h4>
            <CaretIcon style={{ marginBottom: -2, fontSize: 20, color: 'white', marginLeft: 10 }} />
          </div>
        </div>
        <Popper
          open={isOpend}
          anchorEl={anchorEl}
          disablePortal={true}
          placement='bottom-start'
          modifiers={{
            flip: {
              enabled: allowPopperToFlip,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: 'viewport',
            }
          }}
          style={{ zIndex: 9999, minWidth: 350, marginLeft: 150, paddingTop: 10 }}
        >
          <Card style={{ backgroundColor: 'white' }}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem key='flag-header' header style={{ backgroundColor: '#f0f0f0', marginTop: -10, height: 40 }}>
                <h5 style={{ margin: 0 }}><Entity entity="chooseLocation" /></h5>
                </ListItem>
              <Divider />
             {_.map(domains, (domain, key) => (
              <ListItem
                key={key}
                button
                onClick={() => this.onSelectLocation(domain)}
                active={selectedLocation === domain.name}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <LocationIcon style={{ marginTop: 0, marginRight: 15, fontSize: 20 }} />
                    <h5>{domain.name}</h5>
                  </div>
                  <IconButton
                    style={{ marginLeft: 15 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onSelectLocation(domain, true);
                    }}
                  >
                    <OpenNewWindow style={{ fontSize: 18 }} />
                  </IconButton>
                </div>
              </ListItem>))}
            </List>
          </Card>
        </Popper>
      </div>
    );
  }
} 

export default LocationsMenu;
