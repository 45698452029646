import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { Field, reduxForm } from 'redux-form';
import { Form } from '@sketchpixy/rubix';
import React from 'react';
import _ from 'lodash';

import CustomField from './Fields/CustomField.jsx';
import TranslatableOption from './Fields/TranslatableOption.jsx';
import DropFileField from './Fields/DropFileField.jsx';
import Card from '@material-ui/core/Card';

const validate = (values) => {
  const errors = {};

  return errors;
};

@reduxForm({ form: 'ThemeForm', validate })
class ThemeForm extends React.Component {
  render() {
    const { themes, onFileRejected, imagePreview, onDeleteFile } = this.props;
    
    return (
      <Form>
        <Field
          id="themeNameInputControl"
          name="themeName"
          className="form-control-select"
          title={<Entity entity="theme" />}
          mandatory
          component={CustomField}
          componentClass="select"
        >
          {_.map(themes, (theme, index) =>
            <Entity
              key={theme}
              componentClass={TranslatableOption}
              value={theme}
              componentAttribute="text"
              entity="themes"
              data={{ name: theme }}
            />)
          }
        </Field>
        <h3 style={{ fontWeight: 'bold', fontSize: 14, color: '#3f3f3f' }}><Entity entity="logo" /></h3>
        {imagePreview ? (
          <Card style={{ width: 'fit-content', padding: 20, display: 'flex', justifyContent: 'center', margin: 'auto', marginBottom: 20 }}>
            <img src={imagePreview} style={{ width: 'auto', maxHeight: 150 }} />
          </Card>
        ): null}
        <Field
          name="image"
          accept="image/*"
          className="drop-file-container"
          activeClassName="drop-file-container-active"
          maxSize={1048576}
          component={DropFileField}
          onFileRejected={() => onFileRejected()}
          onDeleteFile={f => onDeleteFile(f)}
        >
            <h5 style={{ marginBottom: 5 }}><Entity entity="dropFile" /></h5>
            <h5 style={{ marginTop: 0 }}><Entity entity="orSelect" /></h5>
        </Field>
      </Form>
    );
  }
} 

export default ThemeForm;
