import Card from '@material-ui/core/Card';
import Popover from '@material-ui/core/Popover';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import ReactTooltip from 'react-tooltip';
import { BLUE, RED, RESOURCE_IMAGE_MAP, DND_ELEMENTS } from '../../_config/consts';
import MDButton from '../MDButton/MDButton.jsx';


export default function MapResourcePoint({ resource, x, y, onRemoveResourceFromMap }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: DND_ELEMENTS.RESOURCE_POINT,
      item: { name: resource.name, id: resource.id },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    []
  )

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  let imageSource = resource.imageUrl && RESOURCE_IMAGE_MAP[resource.imageUrl] ? RESOURCE_IMAGE_MAP[resource.imageUrl] : null;
  imageSource = resource.media ? `data:image/jpeg;base64,${resource.media}` : imageSource;

  return (
    <>
      <Card
        data-tip={resource.name}
        ref={dragRef}
        className={`card card-rounded`}
        style={{ margin: 0, position: 'absolute', left: x, top: y, opacity: 1, borderRadius: 40, width: 50, height: 50, backgroundColor: BLUE, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        elevation={2}
        onClick={handleClick}
      >
        {imageSource ? <img className="lock-icon" style={{ width: 40, height: 40, borderRadius: 50 }} src={imageSource} /> : <div style={{ width: 40, height: 40, borderRadius: 50, backgroundColor: BLUE }} />}
      </Card>
      <ReactTooltip delayShow={0} effect="float" />
      <ReactTooltip delayShow={0} effect="float" />
      <Popover
        id={id}
        open={open}
        style={{ zoom: '80%'}}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 15 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
            {imageSource ? <img className="card card-rounded" style={{ width: 60, height: 60, borderRadius: 50, marginTop: 5, padding: 5, marginBottom: 0 }} src={imageSource} /> : null}
            <div className="card-inner-body" style={{ overflowWrap: 'break-word' }}>
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', marginTop:0,  marginBottom: 0 }}>{`${resource.name}`}</h4>
              {resource.description && resource.description.length >= 2 ? <h5 style={{ color: '#3f3f3f', fontWeight: 'normal', marginTop: 5, marginBottom: 0 }}>{`${resource.description}`}</h5> : null}
            </div>
          </div>
          <MDButton
            title={<Entity entity="remove" />}
            containerStyle={{width: '100%',  margin: 0, marginTop: 5 }}
            style={{ height: 35, borderRadius: 0  }}
            backgroundColor={RED}
            onClick={() => {
              handleClick();
              onRemoveResourceFromMap();
            }}
          />
        </div>
      </Popover>
    </>
  );
}
