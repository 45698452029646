import _ from 'lodash';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';

const validate = values => {
  const errors = {};

  if (!values.business) {
    errors.business = 'required';
  }
  return errors;
};

@reduxForm({ form: 'NexudusSelectBusinessForm', validate })
class NexudusSelectBusinessForm extends React.Component {
  
  render() {
    const { businesses } = this.props;
    const businessesOptions = _.map(businesses, business => ({
      name: business.Name,
      id: business.Id,
      type: { name: 'Business' },
    }));
    return (
      <div>
        <Field
          name="business"
          component={SelectableRowField}
          options={{
            content: businessesOptions,
          }}
          listContainerStyle={{ marginTop: 0 }}
          listStyle={{ marginTop: 0 }}
        />
        
        <Field
          name="syncAllUsers"
          label={<Entity entity="synchronizeAllUsers" />}
          titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
          component={MDCheckBoxField}
        />
        <h5 style={{ marginBottom: 50 }}><Entity entity="synchronizeAllUsersWhenActivate" /></h5>
      </div>
    );
  }
} 

export default NexudusSelectBusinessForm;
