// @ts-nocheck
import { createTheme, withStyles } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { BLUE } from '../../_config/consts';
import CameraIconCustom from '../CustomIcons/CameraIconCustom.jsx';
import MDAccordion from '../MDAccordion/MDAccordion.jsx';

const styles = theme => ({
  container: {
    overflowY: 'auto',
    height: '120vh',
    paddingLeft: 15,
    paddingBottom: 220,
    paddingRight: 20,
    paddingTop: 15,
  },
  rowContainer: {
    padding: 20,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginBottom: 15,
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 0,
  },
  rowDescription: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 16,
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyCameraView = () => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 20, marginTop: 50, marginRight: 50 }}>
    <CameraIconCustom style={{ color: '#bdbdbd', width: 50, alignSelf: 'center' }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', textAlign: 'center', marginTop: 0 }}><Entity entity="noCameraFoundTitle" /></h3>
    <h4 style={{ color: '#bdbdbd', fontWeight: 'normal', lineHeight: '1.3em', textAlign: 'center', marginTop: 0 }}><Entity entity="noCameraFoundDescription" /></h4>
  </div>
);

const CameraSnippetRow = ({ index, cameraSnippet, classes, onRowClick }) => (
  <MDAccordion
    title={(
      <div>
        <h4 className={classes.rowTitle}>{`Video #${index}`}</h4>
        <h4 className={classes.rowBody} style={{ marginBottom: 0 }}>{`${moment(cameraSnippet.startTimestamp).format('LLL')} - ${moment(cameraSnippet.endTimestamp).format('LLL')}`}</h4>
      </div>
    )}
    containerstyle={{ marginTop: 10, marginLeft: 2 }}
    innerContainerStyle={{ backgroundColor: '#fdfdfd', paddingTop: 0 }}
    titleStyle={{ fontSize: 27 }}
    defaultExpanded={index === 1}
  >
    <video height="300" src={cameraSnippet.mp4Url} controls type="video/mp4" style={{ marginTop: 10 }} />
  </MDAccordion>
);

class CameraSnippetsListView extends React.Component {
  render() {
    const { cameraSnippets, pagination, onRowClick, onLoadMoreCameras, viewLoading, classes } = this.props;
    return (
      <div style={{ backgroundColor: 'white' }}>
        <div>
          {!_.isEmpty(cameraSnippets) ?
            _.map(cameraSnippets, (cameraSnippet, index) =>
              <CameraSnippetRow
                index={index + 1}
                key={cameraSnippet.endTimestamp}
                cameraSnippet={cameraSnippet}
                classes={classes}
              />)
              : (
                <EmptyCameraView />
              )
            }
        </div>
      </div>
    );
  }

}
export default withStyles(styles)(CameraSnippetsListView);
