import qs from 'qs';
import { axiosInstance } from './rest';
import { executeRequestOrDefault } from './utils';
import { PERMISSIONS, PERMISSION_ENTITIES } from './consts';
import { GUEST } from './formatter';


export function fetchGuestsSpecialTags(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/userTags', restParams);
}

export function fetchGuestsBySpecialTags(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/users/tags',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.USER, [PERMISSIONS.READ], requestData);
}


export function fetchGuestsTagsCategories(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/userTags/types', restParams);
}

export function createGuestsSpecialTag(tagDTO) {
  return axiosInstance.post('/api/v2/userTags', tagDTO);
}

export function updateGuestsSpecialTag(tagId, tagDTO) {
  return axiosInstance.put(`/api/v2/userTags/${tagId}`, tagDTO);
}

export function fetchGuestTagsTypes(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get('/api/v2/userTags/types', restParams);
}

export function deleteGuestsSpecialTag(tagId) {
  return axiosInstance.delete(`/api/v2/userTags/${tagId}`);
}

export function fetchLocksSpecialTags(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/smartLockTags', restParams);
}

export function fetchLocksBySpecialTags(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/smartLocks/tags',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.SMART_LOCK, [PERMISSIONS.READ], requestData);
}


export function createLocksSpecialTag(tagDTO) {
  return axiosInstance.post('/api/v2/smartLockTags', tagDTO);
}

export function updateLocksSpecialTag(tagId, tagDTO) {
  return axiosInstance.put(`/api/v2/smartLockTags/${tagId}`, tagDTO);
}


export function deleteLocksSpecialTag(tagId) {
  return axiosInstance.delete(`/api/v2/smartLockTags/${tagId}`);
}

export function fetchLocksTagsCategories(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get('/api/v2/smartLockTags/types', restParams);
}

export function updateGuestTags(guestId, guestTagsDTO) {
  return axiosInstance.put(`/api/v2/users/${guestId}`, guestTagsDTO);
}

export function updateLockTags(lockId, lockTagsDTO) {
  return axiosInstance.put(`/api/v2/smartLocks/${lockId}`, lockTagsDTO);
}
