
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isMobileBrowser } from '../_config/utils';

const EmptyLayout = ({ children, match }) => (
  <div style={{ zoom: isMobileBrowser() ? '100%' : '80%', backgroundColor: 'white' }}>
    <div>
      {children}
    </div>
  </div>
);

export default connect()(withRouter(EmptyLayout));
