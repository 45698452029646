// @ts-nocheck
import { Card, createTheme, IconButton, LinearProgress, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BLUE, ORANGE } from '../../_config/consts';

const styles = theme => ({
  barColorPrimary: {
    backgroundColor: '#009688',
  },
  barColorError: {
    backgroundColor: '#e45353',
  },
  barColorWarning: {
    backgroundColor: ORANGE,
  },
  totalText: {
    marginTop: 10,
    color: '#157495',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
  },
  consumptionContainer: {
    marginTop: 10,
    width: '100%',
  }
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

class MainSubscriptionCounter extends React.Component {
  render() {
    const { classes, used, max, title, icon } = this.props;
    const usedKeysPerc = parseInt(((used / max) * 100), 10);
    const isWarning = usedKeysPerc < 95 && usedKeysPerc >= 80;
    const isError = usedKeysPerc >= 100;
    let statusColor = isWarning ? ORANGE : '#009688';

    statusColor = isError ? '#e45353' : statusColor;
    let barClassCss = isWarning ? classes.barColorWarning : classes.barColorPrimary;
    barClassCss = isError ? classes.barColorError : barClassCss;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.consumptionContainer}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4 className={classes.totalText}>{title}</h4>
            <h4 className={classes.totalText}>{`${used} / ${max}`}</h4>
          </div>
          <LinearProgress
            variant="determinate"
            value={usedKeysPerc}
            style={{ height: 15, borderRadius: 10 }}
            classes={{ barColorPrimary: barClassCss }}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}
export default withStyles(styles)(MainSubscriptionCounter);
