import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { GREEN, ORANGE, RED, VALIDATION_MODES, VALIDATION_STATUS, VALIDATION_STATUS_MAP } from '../../_config/consts';
import { Card } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

@connect(state => ({}))
class StandardDeviceValidationStatus extends React.Component {
  render() {
    const { validationInfo, validationMode, validationPeriod } = this.props;
    const validationStatusVariable = validationInfo && validationInfo.status && VALIDATION_STATUS_MAP[validationInfo.status];
    let isValidationExpired = false;
    let newValidationNeeded = null;
    const standardDeviceV364Id = validationInfo && validationInfo.id;
    if (!validationInfo) {
      return null;
    }
    if (validationMode === VALIDATION_MODES.ONE_HOUR_VALIDATION || validationMode === VALIDATION_MODES.ONE_MINUTE_VALIDATION) {
      newValidationNeeded = validationInfo.lastValidation && moment(validationInfo.lastValidation).add(validationPeriod, validationMode === VALIDATION_MODES.ONE_HOUR_VALIDATION ? 'hours' : 'minutes');
      isValidationExpired = validationInfo.lastValidation && newValidationNeeded.isBefore(moment());
    }
    return (
      <Card style={{ borderRadius: 10, marginLeft: 15, marginTop: 15, padding: 10, alignItems: 'center', display: 'flex', borderLeft: `4px solid ${validationStatusVariable.color || 'gray'}` }}>
        <InfoOutlined style={{ marginRight: 15, fontSize: 25 }} />
        <div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {validationInfo.status ? <h3 style={{ fontWeight: 'bold', color: validationStatusVariable.color || GREEN, marginRight: 10, marginTop: 0, marginBottom: 7 }}>{<Entity entity="validationStates" data={{ status: validationInfo.status }} />}</h3> : null}
            {isValidationExpired ? <h3 style={{ fontWeight: 'bold', color: RED, marginTop: 0, marginBottom: 7, fontSize: 15 }}>{<Entity entity="validationExpired" />}</h3> : null}
          </div>
          {validationStatusVariable.description && !isValidationExpired ? <h4 style={{ margin: 0 }}>{<Entity entity={validationStatusVariable.description} />}</h4> : null}
          {isValidationExpired ? <h4 style={{ margin: 0 }}>{<Entity entity="validationPeriodExpired" />}</h4> : null}
          {validationInfo.lastValidation ? (
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 10, marginRight: 5 }}>
              {<Entity entity="lastValidationDate" />}: {`${moment(validationInfo.lastValidation).format('LLL')}`}
            </h5>
          ): null}
          {newValidationNeeded ? (
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 10, marginRight: 5 }}>
              {<Entity entity="newValidationNeededDate" />}: {`${newValidationNeeded.format('LLL')}`}
            </h5>
          ): null}
          {validationInfo.status === VALIDATION_STATUS.ACTIVE && !validationInfo.lastVersionEncoded ?
            <h4 style={{ margin: 0, marginTop: 10, color: ORANGE }}>{<Entity entity="warningNotLastVersionEncoded" />}</h4>
          : null}
        </div>
        {standardDeviceV364Id ? <h4 style={{ marginLeft: 'auto', marginRight: 10 }}>#{standardDeviceV364Id}</h4> : null}
      </Card>
    );
  }
}

export default StandardDeviceValidationStatus;