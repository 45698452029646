import ImageIcon from '@material-ui/icons/Image';
import { Button, Modal } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { change, initialize, submit } from 'redux-form';
import OperationalViewHeader from '../../OperationalView/OperationalViewHeader.jsx';
import ImageUploadForm from '../Bookey/ImageUploadForm.jsx';
import FormSubmitBar from '../Elements/FormSubmitBar.jsx';

@connect(state => ({ form: state.form.EventForm }))
class EventImageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  onSelection(selection) {
    this.setState({ selection });
  }
  onImageSubmit(imageMedia) {
    const { input } = this.props;
    input.onChange(imageMedia && imageMedia.media && imageMedia.media[0]);
    this.toggleModal();
  }

  toggleModal() {
    const { dispatch, input } = this.props;
    const { showModal } = this.state;
    if (!showModal) dispatch(initialize('ImageUploadForm', { media: input.value }));
    this.setState({ showModal: !showModal });
  }
  render() {
    const { input: { value }, meta, form, dispatch } = this.props;
    const { showModal } = this.state;
    let imagePreview = form && form.values && form.values.media;
    if (imagePreview && _.isArray(imagePreview)) {
      imagePreview = imagePreview[0].preview;
    } else if (imagePreview && _.isString(imagePreview)) {
      imagePreview = `data:image/jpeg;base64,${form.values.media}`;
    } else if (imagePreview) {
      imagePreview = form.values.media.preview;
    }
    const eventName = form && form.values && form.values.name;
    const resourceDescription = form && form.values && form.values.description;
    const hasError = meta.touched && meta.error;
    return (
      <div>
        <Button onClick={() => this.toggleModal()} bsStyle={!hasError ? 'white' : 'red'} className="card avatarSelector">
          {imagePreview ? (
            <img
              src={imagePreview}
              style={{ width: 'auto', height: 45 }}
            />) : (<ImageIcon style={{ fontSize: 30, color: '#bdbdbd' }} />)}
        </Button>
        <div>
          <Modal show={showModal}>
            <OperationalViewHeader
              onClose={() => this.toggleModal()}
              title={<Entity entity="eventImage" />}
            />
            <ImageUploadForm
              title="selectAnImage"
              previewTitle={eventName}
              previewDescription={resourceDescription}
              previewSectionTitle="eventPreview"
              previewSectionSubtitle="eventPreviewDescription"
              onSubmit={file => this.onImageSubmit(file)}
              onDeleteFile={() => dispatch(change('ImageUploadForm', 'media', null))}
            />
            <FormSubmitBar
              onConfirm={() => dispatch(submit('ImageUploadForm'))}
            />
          </Modal>
        </div>
      </div>
    );
  }
} 

export default EventImageSelector;
