module.exports = {
  SAVE_CARDS: 'SAVE_CARDS',
  APPEND_CARDS: 'APPEND_CARDS',
  SAVE_CARD: 'SAVE_CARD',
  UPDATE_CARD_CREDENTIAL_STATUS: 'UPDATE_CARD_CREDENTIAL_STATUS',
  SET_CARD_PAGINATION_DATA: 'SET_CARD_PAGINATION_DATA',
  SELECT_CARD: 'SELECT_CARD',
  SET_CARD_OPERATIONAL_MODE: 'SET_CARD_OPERATIONAL_MODE',
  APPEND_CARD_CREDENTIALS: 'APPEND_CARD_CREDENTIALS',
  SAVE_CARD_CREDENTIALS: 'SAVE_CARD_CREDENTIALS',
  SET_CARD_CREDENTIALS_PAGINATION_DATA: 'SET_CARD_CREDENTIALS_PAGINATION_DATA',
  SET_CARD_FILTER: 'SET_CARD_FILTER',
  RESET_CARD_FILTERS: 'RESET_CARD_FILTERS',
  RESET_CARDS_DATA: 'RESET_CARDS_DATA',
  UPDATE_CARD_IN_STATE: 'UPDATE_CARD_IN_STATE',
  SAVE_CARD_VALIDATION_STATUS: 'SAVE_CARD_VALIDATION_STATUS',
  REMOVE_CARD_FROM_STATE: 'REMOVE_CARD_FROM_STATE',
  UPDATE_CARD_NOTES_IN_STATE: 'UPDATE_CARD_NOTES_IN_STATE',
  RESET_CARD_PAGINATION_DATA: 'RESET_CARD_PAGINATION_DATA',
  SAVE_HYPERKEYS: 'SAVE_HYPERKEYS',
  APPEND_HYPERKEYS: 'APPEND_HYPERKEYS',
  SAVE_HYPERKEY: 'SAVE_HYPERKEY',
  SAVE_HYPERKEY_VALIDATION_STATUS: 'SAVE_HYPERKEY_VALIDATION_STATUS',
  SET_SELECTED_HYPERKEY: 'SET_SELECTED_HYPERKEY',
  SET_HYPERKEYS_FILTER: 'SET_HYPERKEYS_FILTER',
  RESET_HYPERKEYS_FILTERS: 'RESET_HYPERKEYS_FILTERS',
  SAVE_HYPERKEYS_PAGINATION_DATA: 'SAVE_HYPERKEYS_PAGINATION_DATA',
  UPDATE_KEY_CREDENTIAL_STATUS: 'UPDATE_KEY_CREDENTIAL_STATUS',
  APPEND_KEY_CREDENTIALS: 'APPEND_KEY_CREDENTIALS',
  SAVE_KEY_CREDENTIALS: 'SAVE_KEY_CREDENTIALS',
  SET_KEY_CREDENTIALS_PAGINATION_DATA: 'SET_KEY_CREDENTIALS_PAGINATION_DATA',
  UPDATE_KEY_NOTES_IN_STATE: 'UPDATE_KEY_NOTES_IN_STATE',
  UPDATE_KEY_IN_STATE: 'UPDATE_KEY_IN_STATE',
  SAVE_PINS: 'SAVE_PINS',
  APPEND_PINS: 'APPEND_PINS',
  SAVE_PINS_PAGINATION_DATA: 'SAVE_PINS_PAGINATION_DATA',
  SET_SELECTED_PIN: 'SET_SELECTED_PIN',
  SET_PINS_FILTER: 'SET_PINS_FILTER',
  RESET_PINS_FILTERS: 'RESET_PINS_FILTERS',
  APPEND_PIN_CREDENTIALS: 'APPEND_PIN_CREDENTIALS',
  SAVE_PIN_CREDENTIALS: 'SAVE_PIN_CREDENTIALS',
  SET_PIN_CREDENTIALS_PAGINATION_DATA: 'SET_PIN_CREDENTIALS_PAGINATION_DATA',
  UPDATE_PIN_NOTES_IN_STATE: 'UPDATE_PIN_NOTES_IN_STATE',
  UPDATE_PIN_CREDENTIAL_STATUS: 'UPDATE_PIN_CREDENTIAL_STATUS',
  UPDATE_PIN_IN_STATE: 'UPDATE_PIN_IN_STATE',
  RESET_PINS_PAGINATION_DATA: 'RESET_PINS_PAGINATION_DATA',
  SAVE_PIN: 'SAVE_PIN',
  SAVE_STANDARD_DEVICES_VALIDATION_DEFAULTS: 'SAVE_STANDARD_DEVICES_VALIDATION_DEFAULTS',
  REMOVE_KEY_FROM_STATE: 'REMOVE_KEY_FROM_STATE',
};
