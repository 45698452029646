import { Divider, withStyles, Button } from '@material-ui/core';
import React from "react";

const styles = (theme) => ({
  zoomControlContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 20,
    right: 20,
    zIndex: 1000,
    alignItems: "center",
  },
  zoomControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 40,
    backgroundColor: "white",
    border: "1px solid lightgray",
    borderRadius: 5,
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
  },
  zoomBtn: {
    width: "100%",
    height: "40px",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  divider: {
    width: "80%",
    borderTop: "1px solid lightgray",
    margin: "0",
  },
});


function ZoomControl({ classes, increaseZoom, decreaseZoom, currentZoom }) {
  return (
    <div className={classes.zoomControlContainer}>
      <div>
        <p>Zoom: {Math.round(currentZoom * 100)}%</p>
      </div>
      <div className={classes.zoomControl}>
        <Button disableRipple className={classes.zoomBtn} onClick={increaseZoom}>+</Button>
        <Divider className={classes.divider} />
        <Button disableRipple className={classes.zoomBtn} onClick={decreaseZoom}>−</Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(ZoomControl);