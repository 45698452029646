module.exports = {
  SAVE_PLATFORM_USERS: 'SAVE_PLATFORM_USERS',
  SAVE_PLATFORM_USERS_PAGINATION_DATA: 'SET_PLATFORM_USERS_PAGINATION_DATA',
  APPEND_PLATFORM_USERS: 'APPEND_PLATFORM_USERS',
  SET_SELECTED_PLATFORM_USER: 'SET_SELECTED_PLATFORM_USER',
  SET_PLATFORM_USERS_OPERATIONAL_MODE: 'SET_PLATFORM_USERS_OPERATIONAL_MODE',
  REMOVE_PLATFORM_USER: 'REMOVE_PLATFORM_USER',
  UPDATE_PLATFORM_USER: 'UPDATE_PLATFORM_USER',
  SET_PLATFORM_USERS_FILTER: 'SET_PLATFORM_USERS_FILTER',
  RESET_PLATFORM_USERS_FILTERS: 'RESET_PLATFORM_USERS_FILTERS',
  RESET_PLATFORM_USERS_DATA: 'RESET_PLATFORM_USERS_DATA',
  SAVE_PLATFORM_USER: 'SAVE_PLATFORM_USER',
  RESET_PLATFORM_USERS_PAGINATION_DATA: 'RESET_PLATFORM_USERS_PAGINATION_DATA',
  SET_PLATFORM_USERS_ORDER: 'SET_PLATFORM_USERS_ORDER',
};
