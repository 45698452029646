import { IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  FormControl
} from '@sketchpixy/rubix';
import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { DND_ELEMENTS, ZOOM_LEVEL } from '../../_config/consts.js';
import CloseCircleIconCustom from '../CustomIcons/CloseCircleIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MapResourcePoint from './MapResourcePoint.jsx';
import MapResourceRow from './MapResourceRow.jsx';

function MapBuilderResourceList({ searchText, selectedMapId, onAppendResources }) {
  const resources = useSelector((state) => state.resources.data);
  
  if (!resources || resources.content.length === 0) {
    return <EmptyView
      title="noResources"
      iconName="icon-simple-line-icons-user-following"
    />
  }
  const filteredResources = searchText && searchText.length >= 0 ? _.filter(resources.content, res => res.name.toLowerCase().includes(searchText.toLowerCase())) : resources.content;
  return (
      <InfiniteScroll
        initialLoad={false}
        loadMore={() => onAppendResources(resources.pagination && resources.pagination.number + 1)}
        hasMore={resources.pagination && !resources.pagination.last}
        loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
        useWindow={false}
      >
        {_.map(filteredResources, (el) => (<MapResourceRow key={el.id} locationMedia={el.locationMedia} selectedMapId={selectedMapId} id={el.id} media={el.media} imageUrl={el.imageUrl} name={el.name} />))}
      </InfiniteScroll>
  )
}

export default function ResourceMapBuilderEditor({ onPositionElementOnMap, onRemoveElementFromMap, onAppendResources }) {
  const [dropCanvasBbox, setDropCanvasBbox] = useState({});
  const domainMedia = useSelector((state) => state.domainMedia.selectedDomainMedia);
  const resources = useSelector((state) => state.resources.data);
  const [searchText, setSearchText] = useState('');
  const imageSource = domainMedia && domainMedia.data ? `data:image/jpeg;base64,${domainMedia.data}` : '';
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [DND_ELEMENTS.RESOURCE_ROW, DND_ELEMENTS.RESOURCE_POINT],
      canDrop: () => true,
      drop: (item, monitor) => {
        const dropCoordinate = monitor.getClientOffset();
        const dropScaledCoordinates = {
          x: dropCoordinate && dropCoordinate?.x,
          y: dropCoordinate && dropCoordinate?.y,
        }
        let dropCanvas = document.getElementById("dropCanvas");
        let dropCanvasCoordinates = dropCanvas.getBoundingClientRect();
        const normalizedDropCoordinates = {
          x: _.round((dropScaledCoordinates.x - dropCanvasCoordinates.x) / dropCanvasCoordinates.width, 10),
          y: _.round((dropScaledCoordinates.y - dropCanvasCoordinates.y) / dropCanvasCoordinates.height, 10),
        }
        onPositionElementOnMap(item, normalizedDropCoordinates);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }));

  const setDropCanvasBBoxInState = () => {
    const dropCanvas = document.getElementById("dropCanvas");
    setDropCanvasBbox(dropCanvas ? dropCanvas.getBoundingClientRect() : {});
  }

  useEffect(() => {
    setDropCanvasBBoxInState();
    window.addEventListener('resize', setDropCanvasBBoxInState);
    return () => window.removeEventListener('resize', setDropCanvasBBoxInState);
  }, []);

  useEffect(() => {
    setDropCanvasBBoxInState();
  }, [domainMedia]);

  return (
    <div className="card-noHover" style={{ margin: 20, marginLeft:0, marginTop: 0, padding: 0, overflow: 'scroll', height: '120vh', width: '98%', zIndex: 0 }}>
      <div style={{ height: '99vh', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ height: '95vh', width: '85%', position: 'relative', overflow: 'scroll' }}>
          <img id="dropCanvas" ref={drop} src={imageSource} style={{ height: '100%', display: 'flex', flex: 1, objectFit: 'contain' }} onLoad={() => setDropCanvasBBoxInState()} />
          {_.map(resources && resources.content, (resource) =>  {
            if (!resource.locationCoordinates || !resource.locationMedia || resource.locationMedia.id !== domainMedia.id) {
              return null;
            }
            const resourceX = resource.locationCoordinates.split(';')[0];
            const resourceY = resource.locationCoordinates.split(';')[1];
            return dropCanvasBbox ? <MapResourcePoint key={resource.id} resource={resource} x={resourceX * dropCanvasBbox.width / ZOOM_LEVEL} y={resourceY * dropCanvasBbox.height / ZOOM_LEVEL} onRemoveResourceFromMap={() => onRemoveElementFromMap(resource)} /> : null
          })}
        </div>
        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column',  backgroundColor: 'lightgray', borderRadius: 10, paddingTop: 10 }}>
          <div style={{ marginBottom: 5, display: 'flex', paddingLeft: 10, paddingRight: 10, position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControl
              type="text"
              name="search"
              value={searchText}
              placeholder={L20NContext.getSync('searchResource')}
              className="form-control-rounded"
              onChange={e => setSearchText(e.target.value)}
            />
            {searchText ? (
              <IconButton style={{ position: 'absolute', right: '10px' }} onClick={() => setSearchText('')}>
                <CloseCircleIconCustom style={{ width: 20 }} />
              </IconButton>
            ) : (
              <Search style={{ position: 'absolute', right: '20px', fontSize: 20 }} />
            )}
          </div>
        <div style={{ height: '100vh', width: 300, overflow: 'auto', paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 200 }}>
          <MapBuilderResourceList
            searchText={searchText}
            selectedMapId={domainMedia.id}
            onAppendResources={page => onAppendResources(page)}
          />
        </div>
      </div>
    </div>
    </div>
    </div>
  )
}
