import WarningIcon from '@material-ui/icons/ReportProblem';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { ORANGE, PERMISSION_ENTITIES, PERMISSIONS, BLUE } from '../../_config/consts.js';
import FormCardContainer from '../forms/Elements/FormCardContainer.jsx';
import LockDetailsRow from '../Locks/LockDetailsRow.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import InfoIcon from '@material-ui/icons/HelpOutline';
import { formatGatewayVersion, getHyperGateImage } from '../../_config/utils.js';
import GatewayNameInlineForm from '../forms/GatewayForm/GatewayNameForm.jsx';
import { submit } from 'redux-form';

@connect((state) => ({ gateways: state.gateways }))
class GatewayDetails extends React.Component {

  render() {
    const { dispatch, gateways: { selectedGateway: gateway }, onModifyGatewayName, onForceSynchronization, onRestartGateway, onOpenLock } = this.props;
    const canControlGateway = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.GATEWAY_NOTIFICATION);
    const difference = gateway.lastConnectionDate ? moment(moment().valueOf()).diff(moment(gateway.lastConnectionDate), 'minutes') : 0;
    const isHyperGatePro = gateway && gateway.deviceOs === 'linux';
    return (
      <div style={{ paddingTop: 0 }}>
        <FormCardContainer headerStyle={{ marginBottom: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
            <div className="rounded-view" style={{ marginRight: 15 }}>
                <img className="lock-icon" style={{ marginTop: 0, width: '100%', height: '100%', borderRadius: '50%' }} src={getHyperGateImage(gateway.deviceType)} />
              </div>
            {/* <GatewayNameInlineForm
              onValidateForm={() => dispatch(submit('GatewayNameInlineForm'))}
              onSubmit={values => onModifyGatewayName(values)}
              disabled={!canControlGateway}
              hideEditIcon={false}
            /> */}
            {gateway.highFlex ? (
              <div style={{ position: 'absolute', top: 15, right: 15, backgroundColor: BLUE, padding: 10, width: 'fit-content', borderRadius: 5, display: 'flex', alignItems: 'center', height: 30 }}>
                <h5 style={{ color: 'white', margin: 0}}>{'High flex'}</h5>
              </div>
              ): null}
            <h4 style={{ fontSize: 30, margin: 0, marginLeft: 20, color: '#3f3f3f', fontWeight: 'bold' }}>
              {gateway.name}
            </h4>
          </div>
          <div>
            <h5 style={{ fontSize: 18, margin: 0, marginBottom: 10, marginTop: 20, color: '#3f3f3f', fontWeight: 'bold' }}>
              {'ID'}
            </h5>
            <h4 style={{ fontSize: 18, margin: 0, color: '#3f3f3f' }}>
              {gateway.id}
            </h4>
          </div>
          {gateway.deviceType ? (
            <div>
            <h5 style={{ fontSize: 18, margin: 0, marginBottom: 10, marginTop: 20, color: '#3f3f3f', fontWeight: 'bold' }}>
              <Entity entity="gatewayDeviceType" />
            </h5>
            <h4 style={{ fontSize: 18, margin: 0, color: '#3f3f3f' }}>
              <Entity entity="gatewayDeviceTypes" data={{ type: gateway.deviceType }} />
            </h4>
          </div>
          ): null}
          {gateway.deviceId && isHyperGatePro ? (
            <div>
              <h5 style={{ fontSize: 18, margin: 0, marginBottom: 10, marginTop: 20, color: '#3f3f3f', fontWeight: 'bold' }}>
                <Entity entity="serialNumber" />
              </h5>
              <h4 style={{ fontSize: 18, margin: 0, color: '#3f3f3f' }}>
                {gateway.deviceId}
              </h4>
            </div>
          ) : null}
          {gateway.lastConnectionDate && (
            <div>
              <h5 style={{ fontSize: 18, margin: 0, marginBottom: 10, marginTop: 20, color: '#3f3f3f', fontWeight: 'bold' }}>
                <Entity entity="lastConnectionDate" />
              </h5>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4 style={{ fontSize: 18, margin: 0, color: '#3f3f3f' }}>
                  {moment(gateway.lastConnectionDate).format('LLL')}
                </h4>
                {difference > 5 && <WarningIcon style={{ marginLeft: 10, fontSize: 20, color: 'red' }} />}
              </div>
            </div>
          )}
          {gateway.deviceVersion ? (
            <div>
              <h5 style={{ fontSize: 18, margin: 0, marginBottom: 10, marginTop: 20, color: '#3f3f3f', fontWeight: 'bold' }}>
                <Entity entity="firmwareVersion" />
              </h5>
              <h4 style={{ fontSize: 18, margin: 0, color: '#3f3f3f' }}>
                {formatGatewayVersion(gateway.deviceVersion) || ''}
              </h4>
            </div>
          ): null}
          {gateway.iseoSdkVersion && gateway.iseoSdkVersion.length > 0 ? (
            <div>
            <h5 style={{ fontSize: 18, margin: 0, marginBottom: 10, marginTop: 20, color: '#3f3f3f', fontWeight: 'bold' }}>
              <Entity entity="hyperGateIseoSdkVersion" />
            </h5>
            <h4 style={{ fontSize: 18, margin: 0, color: '#3f3f3f' }}>
              {gateway.iseoSdkVersion || '---'}
            </h4>
          </div>
          ) : null}
          {gateway.kernelVersion ? (
            <div>
            <h5 style={{ fontSize: 18, margin: 0, marginBottom: 10, marginTop: 20, color: '#3f3f3f', fontWeight: 'bold' }}>
              <Entity entity="kernelVersion" />
            </h5>
            <h4 style={{ fontSize: 18, margin: 0, color: '#3f3f3f' }}>
              {gateway.kernelVersion || '---'}
            </h4>
          </div>
          ) : null}
          
          {gateway.minFeatureLevel && gateway.maxFeatureLevel ? (
            <div>
              <h5 style={{ fontSize: 18, margin: 0, marginBottom: 10, marginTop: 20, color: '#3f3f3f', fontWeight: 'bold' }}>
                <Entity entity="hyperGateFeatureLevelSupported" />
              </h5>
              <h4 style={{ fontSize: 18, margin: 0, color: '#3f3f3f' }}>
                {`${gateway.minFeatureLevel} - ${gateway.maxFeatureLevel}`}
              </h4>
            </div>
          ): null}
        </FormCardContainer>
        {canControlGateway &&
          <FormCardContainer containerStyle={{ marginTop: 20 }} title="restartGatewayTitle" subtitle="restartGatewayDescription">
            <MDButton
              title={<Entity entity="restart" />}
              backgroundColor={ORANGE}
              onClick={() => onRestartGateway()}
            />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <InfoIcon style={{ color: '#FFA726', fontSize: 20 }} />
              <h4 style={{ fontSize: 16, margin: 0, marginLeft: 10, color: '#3f3f3f' }}>
                <Entity entity="restartGatewayDescriptionWarning" />
              </h4>
            </div>
            
          </FormCardContainer>
        }
        {canControlGateway &&
          <FormCardContainer containerStyle={{ marginTop: 20 }} title="forceSyncronization" subtitle="forceSyncronizationDescription">
            <MDButton
              title={<Entity entity="forceSyncronization" />}
              backgroundColor={BLUE}
              onClick={() => onForceSynchronization()}
            />
          </FormCardContainer>
        }
        {!_.isEmpty(gateway.smartLocks) && (
          <div style={{ marginTop: 50 }}>
            <h4 style={{ fontSize: 25, margin: 0, marginBottom: 20, color: '#3f3f3f', fontWeight: 'bold' }}>
              <Entity entity="gatewayLocks" />
            </h4>
            {_.map(gateway.smartLocks, smartLock => (
              <LockDetailsRow
                key={smartLock.id}
                lock={smartLock}
                onOpenLock={() => onOpenLock(smartLock)}
              />
            ),
            )}
          </div>
        )}
        
      </div>
    );
  }
} 

export default GatewayDetails;
