import React from 'react';
import _ from 'lodash';
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  // Checkbox,
  
} from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

import { TimePicker, DatePicker, Radio, Checkbox } from 'antd';
import moment from 'moment';

import AirbnbDateRangePicker from './AirbnbDateRangePicker.jsx';
import AirbnbDatePicker from './AirbnbDatePicker.jsx';
import SelectableField from './SelectableInput/SelectableField.jsx';

const { RangePicker } = DatePicker;

export default class CustomField extends React.Component {
  onHandleChange(e, input) {
    const { onHandleChange, textFormattingFunction } = this.props;
    if (input) {
      let value = e;
      if (textFormattingFunction) value = textFormattingFunction(e);
      input.onChange(value);
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }

  onHandleBlur(value, input) {
    const { onInputBlur } = this.props;
    if (onInputBlur) onInputBlur(value);
  }

  render() {
    const { input, meta, name, type, title, options,
      id, componentClass, placeholder, rows,
      helpText, mandatory, multiple, inline, active, hideErrorLabel, containerStyle, className, disabled, titleColor, isOutsideRange } = this.props;
    let validationState;
    if (meta.touched) {
      if (meta.error) {
        validationState = 'error';
      } else {
        validationState = 'success';
      }
    }
    let formElement;
    switch (type) {
      case 'checkbox':
        formElement = <Checkbox {...input} disabled={disabled}>{this.props.children}</Checkbox>;
        break;
      case 'radio':
        formElement = _.map(options, (option, index) =>
          <Radio
            key={index}
            defaultValue={option.value}
            disabled={disabled || option.disabled}
            checked={option.checked}
            name={name}
            onChange={() => this.onHandleChange(option.value, input)}
          >
            {option.label}
          </Radio>);
        break;
      case 'airbnbDateRange':
        formElement = (
          <AirbnbDateRangePicker
            {...input}
            disabled={disabled}
            showClearDates={this.props.showClearDates}
            onChange={(value) => this.onHandleChange(value, input)}
            minimumNights={this.props.minimumNights}
            isOutsideRange={isOutsideRange}
          />
        );
        break;
      case 'airbnbDate':
        formElement = (
          <AirbnbDatePicker
            {...input}
            placeholder={this.props.placeholder}
          />
        );
        break;
      case 'antdTimePicker':
        formElement = (
          <TimePicker
            onChange={(e) => {
              const newValue = e?e.valueOf():undefined;
              input.onChange(newValue); 
              if(this.props.onInputChange_antdTimePicker) 
                this.props.onInputChange_antdTimePicker(newValue)
            }}
            disabledMinutes={this.props.disabledMinutes}
            hideDisabledOptions
            placeholder="HH:mm"
            allowClear={false}
            use12Hours={this.props.is12HoursFormat}
            disabled={disabled}
            format={this.props.is12HoursFormat ? 'h:mm a' : 'HH:mm'}
            value={input.value ? moment(input.value) : moment().hour(0).minute(0).second(0)}
          />
        );
        break;
      case 'staticField':
        formElement = (
          <FormControl.Static>
            {input.value ? input.value : <Entity entity="noInfoToShow" />}
          </FormControl.Static>
        );
        break;
      case 'antdCheckboxGroup':
        formElement = (
          <Checkbox.Group
            {...input}
            // defaultValue={this.props.defaultValue}
            options={this.props.options}
            value={input.value === '' ? [] : input.value}
          />
        );
        break;
      case 'textarea':
        formElement = (
          <FormControl
            {...input}
            disabled={disabled}
            componentClass="textarea"
            placeholder={placeholder}
            value={input.value || ''}
            className={className || 'form-control-custom'}
            style={{ height: 150 }}
          />
        );
        break;
      case 'selectable':
        formElement = (
          <SelectableField
            {...input}
            {...this.props}
          />
        );
        break;
      default: {
        formElement = (
          <FormControl
            {...input}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            rows={rows}
            componentClass={componentClass}
            multiple={multiple}
            value={input.value || ''}
            className={className || 'form-control-custom'}
            onBlur={this.props.onInputBlur ? (e) => this.onHandleBlur(e.target.value, input) : null}
            onChange={e => this.onHandleChange(e.target.value, input)}
          >
            {this.props.children}
          </FormControl>
        );
        break;
      }
    }
    const hasError = meta.touched && meta.error;
    const titleColorStyle = titleColor || '#3f3f3f';
    return (
      <div style={{ ...containerStyle }}>
      <FormGroup controlId={id} validationState={validationState}>
        <ControlLabel style={{ color: hasError ? 'red' : titleColorStyle, marginBottom: 3, fontSize: 16 }}>{title}{mandatory ? <span style={{ color: '#D71F4B' }}>*</span> : ''}</ControlLabel>
        {formElement}
        {helpText ? <HelpBlock style={{ lineHeight: 1.1, fontSize: 13, color: '#3f3f3f', fontWeight: '200', marginTop: 10 }}>{helpText}</HelpBlock> : null}
        {hasError && !hideErrorLabel &&
          <h6 style={{ color: 'red', marginTop: 10, marginBottom: 0 }}>
            <Entity key={meta.error} entity={meta.error} />
          </h6>
        }
      </FormGroup>
      </div>
    );
  }
}
