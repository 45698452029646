import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/DeleteSweep';
import Refresh from '@material-ui/icons/Refresh';
import React, { Component } from 'react';

export default class OperationalViewHeader extends Component {

  render() {
    const { themeName, title, onClose, onRefresh, onDelete, onInfo } = this.props;
    return (
      <div className={`operational-view-title-container bg-${themeName || 'default'}-theme`}>
        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <IconButton onClick={() => onClose()} style={{ marginLeft: 10 }}>
            <Cancel style={{ color: 'white', fontSize: 30 }} />
          </IconButton>
          <h3 className="operational-view-title">
            {title}
          </h3>
        </div>
        {onRefresh ?
          <IconButton onClick={() => onRefresh()}>
            <Refresh style={{ fontSize: 30, color: 'white' }} />
          </IconButton> : null}
        {onDelete ?
          <IconButton onClick={() => onDelete()}>
            <DeleteIcon style={{ fontSize: 30, color: 'white' }} />
          </IconButton> : null}
        {onInfo ?
          <IconButton onClick={() => onInfo()}>
            <InfoIcon style={{ fontSize: 30, color: 'white' }} />
          </IconButton> : null}
      </div>
    );
  }
};

