import { setUp as BookeySetup } from '@bottega52/bookey-redux-module';
import qs from 'qs';
import React from 'react';
import { push } from 'react-router-redux';
import * as BookeyAPI from '../../_config/bookeyAPI';
import { getApiBackendPrefix, getDefaultAPIBaseURL, getDefaultBookeyApiBaseUrl } from '../../_config/conf';
import * as ConfigurationAPI from '../../_config/configurationAPI';
import * as RestService from '../../_config/rest';
import { setBaseUrl } from '../../_config/rest';
import fetchConfig from '../../_hoc/fetchConfig.jsx';

import { EXTERNAL_IDENTITY_PROVIDERS } from '../../_config/consts';
import * as CustomFieldsActions from '../../redux/actions/customFields.actions';
import * as UserActions from '../../redux/actions/user.actions';

async function fetchConfigFunction(dispatch) {
  let config = {
    baseUrl: getDefaultAPIBaseURL(),
    bookeyBaseUrl: getDefaultBookeyApiBaseUrl(),
  };

  if (process.env.NODE_ENV === 'production') {
    const response = await ConfigurationAPI.fetchConfiguration();
    config = response.data;

    const bePrefix = getApiBackendPrefix();
    config = {
      ...config,
      baseUrl: `${window.location.protocol}//${bePrefix}${window.location.host}`,
    };
  }

  BookeySetup(config.bookeyBaseUrl, 30000);
  setBaseUrl(config.baseUrl);


  const queryString = window.location.search;
  const urlParams = qs.parse(queryString, { ignoreQueryPrefix: true });

  if (urlParams && urlParams['p']) {
    localStorage.clear();
    const token = urlParams['p'];
    dispatch(UserActions.processAndStoreAccessToken(token));
  } else if (urlParams && urlParams['r']) {
    localStorage.clear();
    const refreshToken = urlParams['r'];
    await dispatch(UserActions.refreshTokenAndStoreAccessToken(refreshToken));
  } else if (urlParams && urlParams['otpCode']) {
    const platform = urlParams['RelayState'];
    if (platform === 'WEB_PORTAL') {
      localStorage.clear();
      const otpCode = urlParams['otpCode'];
      await dispatch(UserActions.getTokensFromSAMLCode(otpCode));
    }
  } else if (urlParams && urlParams['code']) {
    const ssoPlatform = urlParams['state'];
    const authCode = urlParams['code'];
    if (ssoPlatform === EXTERNAL_IDENTITY_PROVIDERS.COBOT) {
      localStorage.clear();
      await dispatch(UserActions.authorizationCodeExchangeCobot(authCode));
    } else if (ssoPlatform === 'CHAINELS_ACTIVATION') {
      await dispatch(UserActions.loginWithChainelsandGetCommunities(authCode));
      dispatch(push('/chainelsActivation'))
    }
     else if (ssoPlatform === 'CLOUDBEDS_ACTIVATION') {
      if (!RestService.getRequestInterceptors().length && localStorage.token) {
        RestService.setRequestInterceptor(localStorage.token);
      }
      await dispatch(UserActions.loginWithCloudBedsAndActivate(authCode));
    } else if (ssoPlatform === EXTERNAL_IDENTITY_PROVIDERS.CHAINELS) {
      // TODO SSO WITH CHAINELS
      await dispatch(UserActions.authorizationCodeExchangeChainels(authCode));
    } else if (!ssoPlatform) {
      try {
        if (!RestService.getRequestInterceptors().length && localStorage.token) {
          RestService.setRequestInterceptor(localStorage.token);
        }
        await dispatch(UserActions.authorizationCodeExchangeEagleEye(authCode));
        dispatch(CustomFieldsActions.createEagleEyeCustomField());
      } catch (error) {}
    }
  }

  await dispatch(UserActions.fetchCompanyConfiguration());
  try {
    if (!RestService.getRequestInterceptors().length && localStorage.token) {
      RestService.setRequestInterceptor(localStorage.token);
      BookeyAPI.setRequestInterceptor(localStorage.token);
    }
    await dispatch(UserActions.fetchCurrentUser());
  } catch (error) {}
}

class ConfigComponent extends React.Component {
  
  render() {
    return (this.props.children);
  }
}

export default fetchConfig(fetchConfigFunction, null)(ConfigComponent);
