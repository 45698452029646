import React from 'react';
import _ from 'lodash';

import { CREDENTIAL_CATEGORIES, CREDENTIAL_TYPES } from '../../_config/consts';
import GuestSmartphoneCredentialsRow from './GuestCredentialsRows/GuestSmartphoneCredentialRow.jsx';
import GuestCardCredentialsRow from './GuestCredentialsRows/GuestCardCredentialRow.jsx';
import GuestF9000CredentialRow from './GuestCredentialsRows/GuestF9000CredentialRow.jsx';

const rowComponents = {
  [CREDENTIAL_TYPES.SMART]: GuestSmartphoneCredentialsRow,
  [CREDENTIAL_TYPES.STANDARD]: GuestCardCredentialsRow,
};

export default class CredentialRow extends React.Component {


  render() {
    const { credential, className } = this.props;
    if (!credential || !credential.type) {
      return null;
    }
    let Component = rowComponents[credential.type];
    if (credential.deviceType === CREDENTIAL_CATEGORIES.F9000) {
      Component = GuestF9000CredentialRow;
    }
    return (
      <div>
        {Component ? <Component className={className} {...this.props} /> : null}
      </div>
    );
  }
}
