module.exports = {
  SAVE_INVITATIONS: 'SAVE_INVITATIONS',
  SAVE_INVITATION: 'SAVE_INVITATION',
  APPEND_INVITATIONS: 'APPEND_INVITATIONS',
  UPDATE_INVITATION: 'UPDATE_INVITATION',
  SELECT_INVITATION: 'SELECT_INVITATION',
  SET_INVITATION_OPERATIONAL_MODE: 'SET_INVITATION_OPERATIONAL_MODE',
  SET_INVITATIONS_PAGINATION_DATA: 'SET_INVITATIONS_PAGINATION_DATA',
  SET_INVITATION_FILTER: 'SET_INVITATION_FILTER',
  RESET_INVITATIONS_FILTERS: 'RESET_INVITATIONS_FILTERS',
  RESET_INVITATIONS_DATA: 'RESET_INVITATIONS_DATA',
  SAVE_INVITATION_REDEEM_EVENTS_DATA: 'SAVE_INVITATION_REDEEM_EVENTS_DATA',
  APPEND_INVITATION_REDEEM_EVENTS_DATA: 'APPEND_INVITATION_REDEEM_EVENTS_DATA',
  SAVE_INVITATION_REDEEM_EVENTS_PAGINATION: 'SAVE_INVITATION_REDEEM_EVENTS_PAGINATION',
  RESET_INVITATION_REDEEM_EVENTS_DATA: 'RESET_INVITATION_REDEEM_EVENTS_DATA',
};
