
import { Card, CircularProgress, Popper, createTheme, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { BLUE, GREEN, ORANGE, RED } from '../../_config/consts';


const styles = theme => ({
  titleText: {
    margin: 0,
    color: '#3f3f3f',
    fontWeight: 100,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
    borderRadius: 10,
    margin: 5,
    justifyContent: 'center',
    zIndex: -6000,
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 28,
  },
  countTextPrevPerc: {
    color: '#3f3f3f',
    margin: 0,
    marginLeft: 20,
    fontSize: 18,
    padding: 4,
    border: '1px solid grey',
    borderRadius: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  refreshButton: {
    position: 'absolute',
    right: 5,
    top: -5,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  refreshIcon: {
    fontSize: 20,
    color: '#3f3f3f',
  },
  separator: {
    margin: 10,
    marginBottom: 0,
    height: 2,
  },
  linkText: {
    textAlign: 'center',
    paddingTop: 12,
    margin: 0,
    paddingBottom: 4,
    width: '100%',
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '26px !important',
    width: '26px !important',
    zIndex: 10000,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

class MetricCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      prevPercHover: false,
      anchorEl: null,
    };
  }

  render() {
    const { title, icon, metricNumber, metricNumberPrevPerc, classes, isRefreshing, prevPercText } = this.props;
    const { prevPercHover, anchorEl } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <Card elevation={3} className={classes.container}>
          <div className={classes.header}>
            <div className={classes.titleContainer}>
              {icon}
              <div style={{ marginLeft: 20 }}>
                {isRefreshing?(
                  <CircularProgress color="primary" className={classes.progress} />
                ):(
                  <div style={{display:'flex',flexDirection:'row',alignItems:'end'}}>
                    <h1 className={classes.countText}>{metricNumber}</h1>
                    {metricNumberPrevPerc!==undefined&&metricNumberPrevPerc!==null?(
                      <h3 className={classes.countTextPrevPerc} 
                        style={{
                          color:metricNumberPrevPerc==="-"?'':metricNumberPrevPerc>=0?GREEN:RED,
                          borderColor:metricNumberPrevPerc==="-"?'':metricNumberPrevPerc>=0?GREEN:RED
                        }}
                        onMouseEnter={(e) => { this.setState({ anchorEl: e.currentTarget, prevPercHover: true }) }}
                        onMouseLeave={(e) => this.setState({ anchorEl: null, prevPercHover: false })}
                      >
                        {(metricNumberPrevPerc>=0?"+":"")+metricNumberPrevPerc+"%"}
                      </h3>
                    ):null}
                  </div>
                )}
                <h3 className={classes.titleText}>{title}</h3>
              </div>
            </div>
          </div>
          {metricNumberPrevPerc!==undefined&&metricNumberPrevPerc!==null&&prevPercText?(
            <Popper
              open={prevPercHover}
              anchorEl={anchorEl}
              disablePortal={true}
              placement='top-start'
              modifiers={{
                flip: {
                  enabled: false,
                },
                preventOverflow: {
                  enabled: false,
                  boundariesElement: 'viewport',
                }
              }}
              style={{ zIndex: 9999, paddingTop: 10, marginBottom: 10 }}
            >
              <Card style={{ backgroundColor:BLUE, color: 'white', padding:10 }}>
                {prevPercText}
              </Card>
            </Popper>
          ):null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(MetricCard);
