// @ts-nocheck
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { LICENSE_TYPES, PERMISSION_ENTITIES, PERMISSIONS } from '../../_config/consts';
import { hasFormWritePermission } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import CustomField from './Fields/CustomField.jsx';

const validate = values => {
  const errors = {};
  if (!values.firstname) {
    errors.firstname = 'required';
  }
  if (!values.lastname) {
    errors.lastname = 'required';
  }
  if (!values.username) {
    errors.username = 'required';
  }
  return errors;
};


@reduxForm({ form: 'GuestNoEmailForm', validate })
@connect(state => ({ form: state.form.GuestNoEmailForm }))
class GuestNoEmailForm extends React.Component {

  componentDidMount() {
    const { dispatch } = this.props; 
    dispatch(change('GuestNoEmailForm', 'email', null));
  }
  
  render() {
    const {
      form,
      formStyle,
    } = this.props;
    const canEditUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE, PERMISSIONS.UPDATE], PERMISSION_ENTITIES.USER);
    return (
      <Form style={formStyle} autoComplete="off">
        <input style={{ display: 'none' }} type="username" name="username" />
        <Entity
          componentClass={Field}
          name="firstname"
          componentAttribute="placeholder"
          entity="insertFirstName"
          title={<Entity entity="firstName" />}
          component={CustomField}
          mandatory
          disabled={!canEditUser}
        />
        <Entity
          componentClass={Field}
          name="lastname"
          componentAttribute="placeholder"
          entity="insertSurname"
          title={<Entity entity="surname" />}
          component={CustomField}
          mandatory
          disabled={!canEditUser}
        />
        <Entity
          componentClass={Field}
          name="username"
          componentAttribute="placeholder"
          entity="insertBaseGuestTag"
          title={<Entity entity="guestTag" />}
          component={CustomField}
          textFormattingFunction={text => text.toLowerCase()}
          mandatory
          disabled={!canEditUser}
        />
      </Form>
    );
  }
} 

export default GuestNoEmailForm;
