import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import errors from '../../_config/errors';
import Alert from './Alert.jsx';

export default class WarningModal extends React.Component {
  hideModal() {
    const { hideModal } = this.props;
    hideModal();
  }

  render() {
    const { type, defaultMessage, title, message} = this.props;
    let { title: errorTitle, message: errorMessage } = errors[type] || {};

    return (
      <Alert
        type="warning"
        visible
        onOutsideClick={() => this.hideModal()}
        title={errorTitle ? <Entity entity={errorTitle} /> : <Entity entity={title} />}
        message={errorMessage ? <Entity entity={errorMessage} /> : (defaultMessage || <Entity entity={message} />)}
        onConfirm={() => this.hideModal()}
      />
    );
  }
}
