import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import * as CredentialActions from '../../redux/actions/credential.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as RolesActions from '../../redux/actions/roles.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import RoleForm from '../forms/RoleForm.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';
import { hasFormWritePermission } from '../../_config/utils';

@connect((state) => ({
  role: state.roles.selectedRole,
  language: state.settings.language,
  user: state.user.data,
}))
class RolesOperationalSection extends React.Component {

  async onSaveRole(values) {
    const { dispatch, onRoleCreationSuccess } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const permissionsIds = [];
      _.map(_.keys(_.omit(values, 'name')), (permissionGroup) => {
        return _.map(values[permissionGroup], permissionIds => 
          permissionsIds.push(...permissionIds.split(','))
        );
      });
      const roleDTO = {
        name: _.trim(values.name),
        permissionsIds: _.map(permissionsIds, permissionId => Number(permissionId)),
      };
      let newRole;
      if (values.id) {
        newRole = await dispatch(RolesActions.editRole(values.id, roleDTO));
      } else {
        newRole = await dispatch(RolesActions.createRole(roleDTO));
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(RolesActions.setOperationalMode(false));
      dispatch(RolesActions.setSelectedRole({}));
      let successMessage = 'roleCreatedSuccess';
      if (values.id) successMessage = 'roleEditSuccess';
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: successMessage }} /></h6>),
        },
      }));
      if (onRoleCreationSuccess) onRoleCreationSuccess(newRole);
    } catch (error) {
      let errorMessage = values.id ? 'editRoleError' : 'createRoleError';
      if (error && error === 11602) errorMessage = 'createRoleSameNameError'; // ROLE WITH SAME SAME ALREADY EXISTS
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity={errorMessage} /> },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onDeleteRole(roleId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteRoleConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteRoleConfirmed(roleId),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };

    dispatch(ModalActions.showModal(params));
  }
  async onDeleteRoleConfirmed(roleId) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(RolesActions.deleteRole(roleId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(RolesActions.setOperationalMode(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'roleDeletedSuccess' }} /></h6>),
        },
      }));
    } catch (error) {
      let errorEntity = 'deleteRoleError';
      if (error && error.message === 'ROLE_IN_USE_ERROR') errorEntity = 'roleInUseError';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity={errorEntity} /> },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { dispatch, role, user } = this.props;
    const isEditing = role && role.id;
    const isSameRole = user && user.roles && _.first(user.roles) === role.name;
    return (
      <div>
        <RoleForm
          isSameRole={isSameRole}
          onDeleteRole={(roleId) => this.onDeleteRole(roleId)}
          onSubmit={values => this.onSaveRole(values)}
        />
        {hasFormWritePermission(PERMISSION_ENTITIES.ROLE, isEditing) &&
          <MDButton
            title={<Entity entity="save" />}
            containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
            style={{ height: 45, borderRadius: 0 }}
            onClick={() => dispatch(submit('RoleForm'))}
          />
        }
      </div>
    );
  }
} 

export default RolesOperationalSection;
