import { IconButton } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as SettingsActions from '../../redux/actions/setting.actions.js';
import NewspaperIconCustom from '../CustomIcons/NewspaperIconCustom.jsx';
import WhatsNewView from '../WhatsNewView.jsx';


@connect(state => ({ istWhatsNewVisible: state.utils.istWhatsNewVisible, whatsNew: state.settings.whatsNew, themeName: state.settings.items.theme.data.themeName }))
export default class WhatsNewOperationalSection extends React.Component {

  componentDidMount() {
    document.addEventListener('keydown', e => this.onKeyPressed(e), false);
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', e => this.onKeyPressed(e), false);
  }
  
  onKeyPressed(e) {
    if (e.key === 'Escape') {
      const { dispatch } = this.props;
      dispatch(UtilsActions.setIsWhatsNewVisible(false));
    }
  }
  
  onSetWhatsNewHasUpdate(hasUpdate) {
    const { dispatch } = this.props;
    dispatch(SettingsActions.saveWhatsNewHasUpdate(hasUpdate));
  }

  render() {
    const { dispatch, themeName, istWhatsNewVisible, whatsNew: { content: whatsNewContent } } = this.props;
    return (
      <ReactCSSTransitionGroup
        style={{ paddingBottom: 0 }}
        transitionName="bounce"
        transitionEnterTimeout={0}
        transitionLeaveTimeout={0}
      >
        {istWhatsNewVisible &&
          <div className="operational-view" style={{ top: 0, zoom: '70%', padding: 0, margin: 0 }}>
            <div className={`operational-view-title-container bg-${themeName || 'default'}-theme`} style={{ height: 'unset'}}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1, flexDirection: 'row' }}>
              <IconButton onClick={() => dispatch(UtilsActions.setIsWhatsNewVisible(false))} style={{ marginLeft: 10 }}>
                <Cancel style={{ color: 'white', fontSize: 40 }} />
              </IconButton>
              <div style={{ marginLeft: 20 }}>
                <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', marginTop: 20 }}>
                  <NewspaperIconCustom style={{ width: 25, color: 'white', marginRight: 10, marginTop: 5 }} />
                  <h2 style={{ fontWeight: 'bold', color: 'white', margin: 0, alignSelf: 'center', textAlign: 'center' }}>{'Luckey what\'s new!'}</h2>
                </div>
                <h3 style={{ fontWeight: 'bold', color: 'white', margin: 0, alignSelf: 'center', textAlign: 'center', marginTop: 10, marginBottom: 20 }}>{<Entity entity="whatsNewDescription" />}</h3>
              </div>
            </div>
            </div>
            <div className="operational-view-container" style={{ padding: 0, paddingBottom: 70 }}>
              <WhatsNewView articles={whatsNewContent} onSetWhatsNewHasUpdate={mustRead => this.onSetWhatsNewHasUpdate(mustRead)} />
            </div>
            
          </div>
        }
      </ReactCSSTransitionGroup>
    );
  }
}
