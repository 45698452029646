import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../../Fields/CustomField.jsx';


const validate = values => {
  const errors = {};

  if (values && !values.twoFactorCode) {
    errors.twoFactorCode = 'required';
  }

  return errors;
};

@reduxForm({ form: 'InsertOTPEmailForm', validate })
class InsertOTPEmailForm extends React.Component {
  render() {
    return (
      <div style={{ margin: 15 }}>
        <Entity
          name="code"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="twoFactorCode"
          titleColor="#3f3f3f"
          component={CustomField}
        />
      </div>
    );
  }
} 

export default InsertOTPEmailForm;
