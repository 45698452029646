import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_REGISTRY_ITEMS,
  APPEND_REGISTRY_ITEMS,
  SAVE_REGISTRY_ITEM,
  SET_ITEM_REGISTRY_PAGINATION_DATA,
  SELECT_REGISTRY_ITEM,
  SET_ITEM_REGISTRY_FILTER,
  UPDATE_ITEM,
  RESET_ITEM_REGISTRY_FILTERS,
  RESET_ITEM_REGISTRY_DATA,
  RESET_ITEM_PAGINATION_DATA,
  SET_ITEM_REGISTRY_OPERATIONAL_MODE,
} from '../actions/actionTypes/itemRegistry';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
  },
  isOperationalMode: false,
  selectedItem: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_REGISTRY_ITEMS:
      return state.setIn(['data', 'content'], action.items);
    case APPEND_REGISTRY_ITEMS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.items]);
    case SAVE_REGISTRY_ITEM:
      return state.setIn(['data', 'content'], [...state.data.content, action.item]);
    case SET_ITEM_REGISTRY_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SELECT_REGISTRY_ITEM:
      return state.set('selectedItem', action.item);
    case SET_ITEM_REGISTRY_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_ITEM_REGISTRY_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case UPDATE_ITEM: {
      const index = _.findIndex(state.data.content, data => data.id === action.item.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.item);
      }
      return state;
    }
    case RESET_ITEM_REGISTRY_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case RESET_ITEM_REGISTRY_DATA:
      return state.set('data', initialState.data);
    case RESET_ITEM_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    default:
      return state;
  }
}
