import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { MOBILE_UDID_STATES } from '../../_config/consts.js';
import BuildingSettingIconCustom from '../CustomIcons/BuildingSettingIconCustom.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import MDRadioButtonsGroupField from './Elements/MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import CustomField from './Fields/CustomField.jsx';


const validate = (values) => {
  const errors = {};
  return errors;
};

@reduxForm({ form: 'SystemSettingsForm', validate })
class SystemSettingsForm extends React.Component {

  render() {
    const { isDeviceManagementActive, onSelectInvitationGuestOption, isInvitationActive,onHandleChangeInvitationLimit, formValues } = this.props;
    const deviceUUIDModeOptions = [
      {
        label: <Entity entity="limitDeviceUUIDToOne" />,
        value: MOBILE_UDID_STATES.NEVER_SET,
      },
      {
        label: <Entity entity="unlimitDeviceUUID" />,
        value: MOBILE_UDID_STATES.ALWAYS_VALID,
      },
    ];
    
    const invitationModeOptions = [
      {
        label: <Entity entity="invitationGuestModeDisabled" />,
        value: 0,
      },
      {
        label: <Entity entity="invitationGuestModeEnabledWitoutLimit" />,
        value: -1,
      },
      // {
      //   label: <Entity entity="invitationGuestModeEnabledWithLimit" />,
      //   value: 1,
      // }
    ];
    const isInvitationGuestEnabled = formValues && formValues.values && formValues.values.guestInvitationLimitDefault && (formValues.values.guestInvitationLimitDefault !== 0 && formValues.values.guestInvitationLimitDefault !== -1);
    return (
      <div>
        <FormCardContainer
          title="settings"
          subtitle="systemSettingsDescription"
          icon={<BuildingSettingIconCustom style={{ color: '#3f3f3f', width: 20 }} />}
        >
          {isDeviceManagementActive ? (
            <div style={{ marginTop: 40 }}>
              <h4 style={{ fontSize: 20, marginBottom: 5, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="mobileUUIDModeTitle" /></h4>
              <h4 style={{ fontSize: 17, marginTop: 0, color: '#3f3f3f', fontWeight: 200, lineHeight: '1.2em' }}><Entity entity="mobileUUIDModeDescription" /></h4>
              <Field
                name="mobileUuidDefaultState"
                title={<Entity entity="deviceUUIDMode" />}
                component={MDRadioButtonsGroupField}
                containerstyle={{ paddingLeft: 15, marginTop: 0 }}
                showErrorLabel
                options={deviceUUIDModeOptions}
              />
            </div>
          ) : null}
          {isInvitationActive ? (
            <div style={{ marginTop: 40 }}>
              <h4 style={{ fontSize: 20, marginBottom: 5, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="invitationGuestModeTitle" /></h4>
              <h4 style={{ fontSize: 17, marginTop: 0, color: '#3f3f3f', fontWeight: 200, lineHeight: '1.2em' }}><Entity entity="invitationGuestModeDescription" /></h4>
              <Field
                name="guestInvitationLimitDefault"
                title={<Entity entity="invitationGuestSettingState" />}
                component={MDRadioButtonsGroupField}
                containerstyle={{ paddingLeft: 15, marginTop: 0 }}
                showErrorLabel
                options={invitationModeOptions}
                onHandleChange={(value) => onSelectInvitationGuestOption(value)}
              />
              {/* {isInvitationGuestEnabled ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h5 style={{ color: '#3f3f3f', marginRight: 10, marginLeft: 30, marginTop: 0 }}>{<Entity entity="maxNumberActiveInvitations" />}</h5>
                  <Entity
                    componentClass={Field}
                    name="invitationLimit"
                    title=""
                    type="number"
                    componentAttribute="placeholder"
                    component={CustomField}
                    hideErrorLabel
                    containerStyle={{ width: 100 }}
                    className="form-control-custom"
                    onHandleChange={value => onHandleChangeInvitationLimit(value)}
                  />  
                </div>
              ) : null} */}
            </div>
          ) : null}
        </FormCardContainer>
      </div>
    );
  }
} 

export default SystemSettingsForm;
