import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { BLUE, GREEN } from '../../../../_config/consts';

const theme = createTheme({
  palette: {
    primary: { 500: GREEN },
  },
});


const MDSwitchView = ({ checked, label, disabled, titleStyle, subtitleStyle, subtitle, onSelect, containerStyle }) => (
  <MuiThemeProvider theme={theme}>
    <div style={{ ...containerStyle }}>
      <FormControlLabel
        control={
          <Switch key={checked} color="primary" checked={checked} disabled={disabled} onChange={() => onSelect(!checked)} value="gilad" />
        }
        label={<h5 style={{ margin: 0, ...titleStyle }}>{label}</h5>}
      />
      {subtitle && <h5 style={{ marginTop: -15, marginLeft: 35, ...subtitleStyle }}>{subtitle}</h5>}
    </div>
  </MuiThemeProvider>
);

export default MDSwitchView;

