// @ts-nocheck
import AppBar from '@material-ui/core/AppBar';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import UpdatedStatusIcon from '@material-ui/icons/Update';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { change, initialize } from 'redux-form';
import { ACCESS_METHODS, BLUE, CARD_TYPES, LANGUAGES, MATCH_TAG_MODE, PERMISSION_ENTITIES, PERMISSIONS, VALIDATION_MODES, VALIDATION_MODES_SELECTION_OPTIONS } from '../../_config/consts';
import * as formatter from '../../_config/formatter.js';
import { hasFormWritePermission } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as CardActions from '../../redux/actions/card.actions';
import * as CredentialActions from '../../redux/actions/credential.actions';
import * as GuestActions from '../../redux/actions/guest.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import ClipboardIconCustom from '../CustomIcons/ClipboardIconCustom.jsx';
import SmartLocksEventsIconCustom from '../CustomIcons/SmartLocksEventsIconCustom.jsx';
import CardCredentialForm from '../forms/CardCredentialForm.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import CardCredentialsList from './CardCredentialList.jsx';
import CardEventsView from './CardEventsView.jsx';
import DeviceValidationPeriodView from './DeviceValidationPeriodView.jsx';
import ClockCloudIconCustom from '../CustomIcons/ClockCloudIconCustom.jsx';

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

@connect(state => ({
  card: state.cards.selectedCard,
  cardForm: state.form.CardCredentialForm,
  credential: state.credentials.selectedCredential,
  lockTags: state.tags.lock.data,
  userTags: state.tags.user.data,
  language: state.settings.language,
  userToken: state.user.token,
  companyConfigurations: state.user.companyConfigurations,
  companyInfo: state.user.companyInfo,
  accessProfiles: state.accessProfiles.data,
}))
class CardsOperationalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,

    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.fetchGuestTags(includeSpecialTags, 'USER', 0, 100));
    dispatch(TagActions.fetchLockTags(includeSpecialTags, 0, 100));
  }

  async createCard(values) {
    const { dispatch, credential } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      if (values.deviceBackendId) {
        await dispatch(CardActions.updateCard(values));
        dispatch(CardActions.fetchCards());
      } else {
        if (values.accessProfileSelection > 0) {
          const guestId = credential && credential.guestsFilteredByTags && credential.guestsFilteredByTags.data && credential.guestsFilteredByTags.data[0] ? credential.guestsFilteredByTags.data[0].id : null;
          await dispatch(CardActions.createCardWithAccessProfile(values, guestId));
        } else {
          await dispatch(CardActions.createCard(values));
        }
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  fetchGuestsByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchGuestsByTags());
    dispatch(CredentialActions.fetchGuestsByTagsStardardCredentialsForm('CARD', page, append));
  }

  fetchLocksByTags(page, index, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchLocksByTags());
    dispatch(CredentialActions.fetchLocksByTagsStardardCredentialsForm('CARD', index, page, append));
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    const tagsType = 'USER';
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchGuestTagsByFilter(value, 50, true, tagsType));
  }

  filterLockTags(value) {
    const { dispatch } = this.props;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 200, true));
  }

  deleteCard(cardId) {
    const { dispatch } = this.props;
    dispatch(CardActions.deleteCard(cardId));
  }

  confirmDeleteCard(card, plantMaster) {
    const { dispatch, userToken, onShowMasterElectionForm } = this.props;
    // CHECK IF MASTER CURRENT PLANT IS MASTER AND NOT THE ONLY OWNER -> ASK SELECTION FOR OTHER MASTER
    const currentPlant = userToken.hostname;
    const cardOwners = card && card.validation && card.validation.assigned ? _.filter(card.validation.assigned, masterPlant => masterPlant === currentPlant) : [];
    if (plantMaster === currentPlant && cardOwners && _.size(cardOwners)) {
      onShowMasterElectionForm();
    } else {
      const params = {
        modalType: 'CONFIRM_TO_CONTINUE_MODAL',
        modalProps: {
          title: 'confirmBeforeContinue',
          message: 'deleteCardConfirmation',
          onConfirmText: <Entity entity="yes" />,
          onConfirm: () => this.deleteCard(card.id),
          onCancelText: <Entity entity="no" />,
          onCancel: () => dispatch(ModalActions.hideModal()),
        },
      };
      dispatch(ModalActions.showModal(params));
    }
  }

  showTagDatesModal(values) {
    const { dispatch } = this.props;
    dispatch(initialize('TagForm', values));
    this.tagDatesModal.open();
  }


  async onSaveCardNotes(cardId, notes) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(CardActions.updateCardNotes(cardId, notes));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
  }

  appendCardCredentials(page) {
    const { dispatch, card } = this.props;
    dispatch(CardActions.fetchCardCredentials(card, page, true));
  }

  async onVerifyDeviceId(deviceId) {
    const { dispatch } = this.props;
    if (deviceId) {
      await dispatch(CardActions.validateDeviceId(deviceId));
    } else {
      dispatch(change('CardCredentialForm', 'areDeviceDatesLocked', false));
    }
  }

  selectTab(index, canReadCredential) {
    const { card, dispatch } = this.props;
    this.setState({ selectedTab: index });
    if(card && card.id && (index === 2 || (!canReadCredential && index === 1))) {
      const validationDTO = {
        validationPeriod: card.validationPeriod,
        validationMode: card.validationMode !== VALIDATION_MODES.ALWAYS_VALIDATED && card.validationMode !== 'DEFAULT' ? VALIDATION_MODES_SELECTION_OPTIONS.TIME_CONSTRAINED_VALIDATION : card.validationMode,
        validationPeriodTimeUnit: card.validationMode,
      };
      dispatch(initialize('DeviceValidationPeriodViewForm', validationDTO));
    }
  }

  onSharePinWithCommunication(pin) {
    const { dispatch } = this.props;
    dispatch(push(`/communications?pin=${JSON.stringify(pin)}`));
  }

  onInitializeNewTimeProfile(profileId) {
    const { dispatch } = this.props;
    dispatch(change('CardCredentialForm', `additionalTimeProfiles[${profileId}].daysOfTheWeek`, [1, 2, 3, 4, 5]));
    dispatch(change('CardCredentialForm', `additionalTimeProfiles[${profileId}].name`, `timeProfile_${profileId}`));
    dispatch(change('CardCredentialForm', `additionalTimeProfiles[${profileId}].timeIntervalFrom`, moment().startOf('day').valueOf()));
    dispatch(change('CardCredentialForm', `additionalTimeProfiles[${profileId}].timeIntervalTo`, moment().endOf('day').valueOf()));
    dispatch(change('CardCredentialForm', `additionalTimeProfiles[${profileId}].lockTagMatchingMode`, MATCH_TAG_MODE.AT_LEAST_ONE_TAG));
  }

  onInitializeNewTimeRange(timeRangeId, fieldName) {
    const { dispatch } = this.props;
    dispatch(change('CardCredentialForm', `${fieldName}[${timeRangeId}].daysOfTheWeek`, [1, 2, 3, 4, 5]));
    dispatch(change('CardCredentialForm', `${fieldName}[${timeRangeId}].timeIntervalFrom`, moment().startOf('day').valueOf()));
    dispatch(change('CardCredentialForm', `${fieldName}[${timeRangeId}].timeIntervalTo`, moment().endOf('day').valueOf()));
  }
  
  async onCreateAnonymousUserForCard(userData) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const newGuest = await dispatch(GuestActions.createGuest(userData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const guestTagId = newGuest && newGuest.userTag && newGuest.userTag.id;
      if (guestTagId) {
        await dispatch(CredentialActions.fetchGuestsByTags({ id: guestTagId }, 'AT_LEAST_ONE_TAG'));
        const guestTagDetails = await dispatch(TagActions.fetchGuestTagDetails(guestTagId));
        dispatch(change('CardCredentialForm', 'guestTags', guestTagDetails));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onSelectAccessProfile(accessProfileId) {
    const { accessProfiles: { content: accessProfilesData }, dispatch } = this.props;
    const accessProfile = _.find(accessProfilesData, { id: accessProfileId });

    if (accessProfile) {
      dispatch(change('CardCredentialForm', 'daysOfTheWeek', accessProfile.daysOfTheWeek));
      dispatch(change('CardCredentialForm', 'timeIntervalFrom', accessProfile.timeIntervalFrom));
      dispatch(change('CardCredentialForm', 'timeIntervalTo', accessProfile.timeIntervalTo));
      dispatch(change('CardCredentialForm', 'credentialTimeframe', accessProfile.credentialTimeframe));
      dispatch(change('CardCredentialForm', 'lockTags', accessProfile.lockTags));
      const defaultAdditionalTimeRange = accessProfile.additionalTimeRange;
      if (defaultAdditionalTimeRange) {
        dispatch(change('CardCredentialForm', 'additionalTimeRange_default', [formatter.formatInputData(formatter.TIME_RANGE, defaultAdditionalTimeRange)]));
      }
    }
  }

  async onUpdateValidationPeriod(validationDTO) {
    const { dispatch , card } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CardActions.updateStandardDeviceValidationMode({ ...validationDTO, id: card.id }, ACCESS_METHODS.STANDARD));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialUpdated' }} /></h6>)
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="credentialUpdatedError" /></h6>),
        },
      }));
    }
  }

  onOpenValidationInfo() {
    const helpURL = localizeHelpCenterLink('rfid-cards-validation-period');
    window.open(helpURL);
  }

  render() {
    const { userTags, lockTags, accessProfiles: { content: accessProfilesData }, cardForm, language, companyInfo, companyConfigurations, card, credential, onCardFormSubmit } = this.props;
    const { selectedTab } = this.state;
    const canReadCredential = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.CREDENTIAL);
    const canReadSmartLocksEvents = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.LOG);
    const isCardMappingEnabled = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.enabled;
    const cardIdsMap = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.cardIdsMap ? companyConfigurations.customCardsMapping.cardIdsMap : [];
    const cardFormData = cardForm && cardForm.values;
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    const systemHasV364Locks = companyInfo && companyInfo.numOfVegaLocks;
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          {card.id ? (
            <AppBar position="sticky" color="default">
              <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                scrollable
                onChange={(e, index) => this.selectTab(index, canReadCredential)}
                style={{ paddingLeft: 25 }}
                TabIndicatorProps={{
                  style: { display: 'none' }
                }}
              >
                <Tab icon={<ClipboardIconCustom style={{ width: 25 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="cardInformation" /></h5>} />
                {card.id && canReadCredential &&
                  <Tab icon={<UpdatedStatusIcon style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="cardStatus" /></h5>} />
                }
                {card && card.id && systemHasV364Locks && (
                  <Tab icon={<ClockCloudIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="validationInfo" /></h5>} />
                )}
                {card.id && canReadSmartLocksEvents && (
                  <Tab icon={<SmartLocksEventsIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="guestSmartLocksLogs" /></h5>} />
                )}
              </Tabs>
            </AppBar>
          ) : null}
          <div style={{ marginBottom: 500, padding: 20, display: selectedTab === 0 ? 'block' : 'none' }}>
            <CardCredentialForm
              card={card}
              formData={cardFormData}
              systemHasV364Locks={systemHasV364Locks}
              accessProfilesData={accessProfilesData}
              is12HoursFormat={is12HoursFormat}
              cardTypes={_.omit(CARD_TYPES, CARD_TYPES.ISEO_PIN)}
              availableUserTags={userTags}
              availableLockTags={lockTags}
              isCardMappingEnabled={isCardMappingEnabled}
              cardIdsMap={cardIdsMap}
              guestsFilteredByTags={credential.guestsFilteredByTags}
              locksFilteredByTags={credential.locksFilteredByTags}
              onFetchMoreLocks={(page, index) => this.fetchLocksByTags(page, index, true)}
              onFetchMoreGuests={page => this.fetchGuestsByTags(page, true)}
              onGuestInputChange={value => this.filterGuestTags(value)}
              onGuestTagsChange={() => this.fetchGuestsByTags()}
              onLockInputChange={value => this.filterLockTags(value)}
              onLockTagsChange={(_, index) => this.fetchLocksByTags(0, index)}
              onLockFilterModeChange={(_, index) => this.fetchLocksByTags(0, index)}
              onVerifyDeviceId={(value) => this.onVerifyDeviceId(value)}
              onSelectAccessProfile={accessProfileId => this.onSelectAccessProfile(accessProfileId)}
              onInitializeNewTimeProfile={timeProfileId => this.onInitializeNewTimeProfile(timeProfileId)}
              onInitializeNewTimeRange={(timeRangeId, fieldName) => this.onInitializeNewTimeRange(timeRangeId, fieldName)}
              onSubmit={values => this.createCard(values)}
              onDeleteCard={card.id ? (plantMaster) => this.confirmDeleteCard(card, plantMaster) : null}
              onSaveCardNotes={(notes) => this.onSaveCardNotes(card.id, notes)}
              onSharePinWithCommunication={pin => this.onSharePinWithCommunication(pin)}
              onCreateAnonymousUserForCard={userData => this.onCreateAnonymousUserForCard(userData)}
            />
            {hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, card.id) && (
              <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
                <MDButton
                  title={<Entity entity="save" />}
                  containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
                  style={{ height: 45, borderRadius: 0 }}
                  onClick={() => onCardFormSubmit()}
                />
              </div>
            )
            }
          </div>
          {card.id && canReadCredential && selectedTab === 1 && (
            <div style={{ padding: 20 }}>
              <CardCredentialsList
                credentials={card.credentials}
                appendCardCredentials={page => this.appendCardCredentials(page)}
              />
            </div>
          )}
          {card && card.id && systemHasV364Locks && (selectedTab === 2 || (!canReadCredential && selectedTab === 1)) ? (
            <DeviceValidationPeriodView
              selectedDevice={card}
              onOpenValidationInfo={() => this.onOpenValidationInfo()}
              onSubmit={validationPeriodDTO => this.onUpdateValidationPeriod(validationPeriodDTO)}
            />
          ) : null}
          {card.id && (selectedTab === 3 || (!systemHasV364Locks && selectedTab === 2) || (!canReadCredential && selectedTab === 2)) ? (
            <CardEventsView
              card={card}
            />
          ) : null}
        </div>
      </MuiThemeProvider>
    );
  }
} 

export default CardsOperationalSection;
