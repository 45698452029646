import axios from 'axios';
import _ from 'lodash';
import { axiosInstanceProvider } from '@bottega52/bookey-redux-module';

export function setRequestInterceptor(accessToken) {
  axiosInstanceProvider.getBookeyAxiosInstance().setRequestInterceptor(config => {
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  }, error => {
    if (error instanceof Error) {
    }
  });
}

export function setResponseInterceptor(req, error) {
  axiosInstanceProvider.getBookeyAxiosInstance().setResponseInterceptor(req, error);
}

export function getResponseInterceptors() {
  return axiosInstanceProvider.getBookeyAxiosInstance().getAxiosInstance().interceptors.response;
}

export function getRequestInterceptors() {
  return axiosInstanceProvider.getBookeyAxiosInstance().getAxiosInstance().interceptors.request ? axiosInstanceProvider.getBookeyAxiosInstance().getAxiosInstance().interceptors.request.handlers : [];
}

export function interceptorEjectRequest() {
  _.each(
    _.keys(axiosInstanceProvider.getBookeyAxiosInstance().getAxiosInstance().interceptors.request.handlers), (key) => {
      axiosInstanceProvider.getBookeyAxiosInstance().ejectRequestInterceptor(key);
    },
  );
}
