import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import ShowIcon from '@material-ui/icons/Visibility';
import HideIcon from '@material-ui/icons/VisibilityOff';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import momentTz from 'moment-timezone';
import React from 'react';
import { Field, change, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';
import { GREEN } from '../../../_config/consts.js';
import MDSwitchView from '../Fields/MDSwitch/MDSwitchView.jsx';
import TimezoneIconCustom from '../../CustomIcons/TimezoneIconCustom.jsx';

const validate = values => {
  const errors = {};

  if (!values.clientId) {
    errors.clientId = 'required';
  }

  if (!values.clientSecret) {
    errors.clientSecret = 'required';
  }

  if (!values.email) {
    errors.email = 'required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    errors.email = 'invalidEmailAddress';
  }

  return errors;
};

@reduxForm({ form: 'CloudbedsActivationForm', validate })
class CloudbedsActivationForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isPasswordVisible: false,
      timezones: momentTz.tz.names(),
      guessedTimezone: momentTz.tz.guess(),
      showTimezoneSelector: false,
    };
  }

  componentDidMount() {
    const { guessedTimezone } = this.state;
    const { dispatch } = this.props;
    dispatch(change('CloudbedsActivationForm', 'timezone', guessedTimezone));
  }

  onTogglePasswordVisibility() {
    const { isPasswordVisible } = this.state;
    this.setState({ isPasswordVisible: !isPasswordVisible });
  }

  onFilterTimezones(value) {
    const timezones = momentTz.tz.names().filter(timezone => timezone.toLowerCase().includes(value.toLowerCase()));
    this.setState({ timezones });
  }

  onSelectTimezone(selectedOption) {
    const { dispatch } = this.props;
    const selectedTimezone = selectedOption && selectedOption.value ? selectedOption.value : momentTz.tz.guess();
    dispatch(change('CloudbedsActivationForm', 'timezone', selectedTimezone));
    this.setState({ guessedTimezone: selectedTimezone });
  }

  render() {
    const { isPasswordVisible, guessedTimezone, showTimezoneSelector, timezones } = this.state;
    return (
      <div>
        <Entity
          name="clientId"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertClientId"
          title="Client ID"
          component={CustomField}
        />
        <div style={{ marginTop: 10, display: 'flex', position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Entity
            name="clientSecret"
            componentClass={Field}
            type={isPasswordVisible ? 'text' : 'password'}
            componentAttribute="placeholder"
            entity="insertClientSecret"
            title="Client Secret"
            containerStyle={{ width: '100%' }}
            component={CustomField}
          />
          <IconButton style={{ position: 'absolute', right: '5px', bottom: '9px' }} onClick={() => this.onTogglePasswordVisibility()}>
            {!isPasswordVisible ? <ShowIcon style={{ color: '#3f3f3f', fontSize: 20 }} /> : <HideIcon style={{ color: '#3f3f3f', fontSize: 20 }} />}
          </IconButton>
        </div>
        {guessedTimezone ? (
          <div>
            <h4 style={{ fontWeight: 'bold', fontSize: 16 }}><Entity entity="buildingTimeZone" /></h4>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, paddingLeft: 15, borderRadius: 10, background: '#f0f0f0'}}>
              <TimezoneIconCustom style={{ width: 20, marginRight: 10 }} />
              <h5 style={{ fontWeight: 'bold', fontSize: 16 }}>{guessedTimezone}</h5>
            </div>
            <MDSwitchView
              label={<Entity key={showTimezoneSelector} entity="notYourTimeZone" />}
              checked={showTimezoneSelector}
              titleStyle={{ fontWeight: showTimezoneSelector ? 'bold' : 'normal', color: showTimezoneSelector ? GREEN : '#3f3f3f' }}
              onSelect={selected => this.setState({ showTimezoneSelector: selected })}
            />
          </div>
        ) : null}
        {showTimezoneSelector ? (
          <Field
            name="timezone"
            title={<Entity entity="selectTimezone" />}
            component={SelectableField}
            options={_.map(timezones, timezone => ({ value: timezone, label: timezone }))}
            titleStyle={{ fontSize: 16 }}
            placeholder={<Entity entity="search" />}
            containerstyle={{ widht: '100%', marginBottom: 20 }}
            onInputChange={value => this.onFilterTimezones(value)}
            onSelect={value => this.onSelectTimezone(value)}
          />
        ) : null}
      </div>
    );
  }
} 

export default CloudbedsActivationForm;
