import React from 'react';
import { withStyles, IconButton } from '@material-ui/core';
import _ from 'lodash';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { Form } from '@sketchpixy/rubix';
import Warning from '@material-ui/icons/Warning';
import MDButton from '../MDButton/MDButton.jsx';
import moment from 'moment';
import { BLUE, GREEN, ORANGE, PERMISSION_ENTITIES, RED, VISIBLE_TAGS_NUMBER } from '../../_config/consts';
import Tag from '../Tag.jsx';
import { change, Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import TagsSelectorField from '../forms/Fields/TagsSelectorField.jsx';
import { hasFormWritePermission } from '../../_config/utils.js';
import * as TagActions from '../../redux/actions/tag.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import TagIconCustom from '../CustomIcons/TagIconCustom.jsx';
import ArrowLeftIconCustom from '../CustomIcons/ArrowLeftIconCustom.jsx';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import CloseIcon from '@material-ui/icons/HighlightOff';
import TagRemoveIconCustom from '../CustomIcons/TagRemoveIconCustom.jsx';
import { WarningOutlined } from '@material-ui/icons';

const validate = values => {
  const errors = {};
  
  if (!values||!values.tags||!values.tags.length) {
    errors.tags = 'required';
  }

  return errors;
};

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  titleText: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'left',
  },
  sectionText: {
    margin: 0,
    marginRight: 5,
    fontSize: 18,
    lineHeight: 1.4,
    color: '#878787',
    textAlign: 'left',
  },
  exampleContainer: {
    marginTop: 15,
    borderRadius: 5,
  },
  exampleText: {
    marginLeft: 12,
    marginTop: 10,
    fontSize: 15,
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  warningTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: -20,
  },
  guestCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestDataText: {
    fontWeight: 'bold',
    margin: 0,
    color: '#3f3f3f',
    marginTop: 2,
  },
});

const UserRow = ({ item, isEaven }) => {
  const fakeUserTag = {
    color: '#ba68c8',
    name: item && item.username ? item.username.toLowerCase() : '',
    type: 'user',
  };
  const visibleTags = _.slice(item.tags, 0, VISIBLE_TAGS_NUMBER);
  const backgroundColor = !isEaven ? '#FAFAFA' : 'white';
  return (
    <div
      className="card-noHover tag-element-selected"
      style={{ display: 'flex', zIndex: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 5, marginBottom: 0, marginTop: 0, backgroundColor }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h5 style={{ margin: 0, color: '#3f3f3f' }}>{item.name || `${item.firstname} ${item.lastname}`}{item.email ? <h6 style={{ margin: 0, marginTop: 2, color: '#3f3f3f' }}>{item.email}</h6> : null}</h5>
      </div>
      <div className="tags-container" style={{ margin: 0, marginLeft: 55 }}>
        {item && item.username &&
          <Tag textStyle={{ fontSize: 12 }} style={{ paddingTop: 4, paddingBottom: 4, marginLeft: 10 }} tag={fakeUserTag} />
        }
        {_.map(visibleTags, tag =>
          <Tag
            key={tag.id}
            tag={tag}
            style={{ padding: 5 }}
            textStyle={{ fontSize: 14 }}
          />,
        )}
      </div>
    </div>
  );
};

@reduxForm({ form: 'GuestBatchTagsForm', validate })
@connect(state => ({ form: state.form.GuestBatchTagsForm, userTags: state.tags.user, companyConfigurations: state.user.companyConfigurations }))
class GuestsTagsBatchView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchGuestTagsByFilter(value, 5));
  }

  onConfirm() {
    const { onAddTagsGuestsBatchConfirm, onRemoveTagsGuestsBatchConfirm, form, guests, removeMode } = this.props;
    const tagsAdd = form&&form.values&&form.values.tags
    const tagsRemove = form&&form.values&&form.values.deletingTags
    if (removeMode&&(!tagsRemove||!tagsRemove.length))
      return;
    if (!removeMode&&(!tagsAdd||!tagsAdd.length))
      return;
    if (removeMode)
      onRemoveTagsGuestsBatchConfirm(tagsRemove)
    else
      onAddTagsGuestsBatchConfirm(tagsAdd)
  }

  async onAddToRemovable(item) {
    const { form, dispatch } = this.props;
    const { } = this.state;
    const removableTags = form&&form.values&&form.values.removableTags?form.values.removableTags:[]
    const deletingTags = form&&form.values&&form.values.deletingTags?form.values.deletingTags:[]
    await dispatch(change('GuestBatchTagsForm', 'removableTags', [...removableTags,item]));
    await dispatch(change('GuestBatchTagsForm', 'deletingTags', _.filter(deletingTags, i => i.id !== item.id)));
  }

  async onAddToDeleting(item) {
    const { form, dispatch } = this.props;
    const { } = this.state;
    const removableTags = form&&form.values&&form.values.removableTags?form.values.removableTags:[]
    const deletingTags = form&&form.values&&form.values.deletingTags?form.values.deletingTags:[]
    await dispatch(change('GuestBatchTagsForm', 'removableTags', _.filter(removableTags, i => i.id !== item.id)));
    await dispatch(change('GuestBatchTagsForm', 'deletingTags', [...deletingTags,item]));
  }

  render() { 
    const { 
      classes, guests, userTags, form, 
      formStyle,
      companyConfigurations, 
      removeMode,
    } = this.props;

    const isCovivioIntegrationEnabled = companyConfigurations && companyConfigurations.covivioConnectorEnabled;
    const cannotEditExternalUser = guests.filter(e=>e.extId).length && isCovivioIntegrationEnabled;

    const availableTags = userTags.data

    const uniqueGuests = _.uniqBy(guests, 'id')

    const removableTags = form&&form.values&&form.values.removableTags?form.values.removableTags:[]
    const deletingTags = form&&form.values&&form.values.deletingTags?form.values.deletingTags:[]

    return (
      <div style={{ marginLeft: 13, marginBottom: 30, padding: 10 }}>
        <div className={classes.warningTitleContainer}>
          <h2 className={classes.titleText} style={{ margin: 0, marginLeft: 10, fontSize: 25 }}><Entity entity={removeMode?"usersRemoveTagsBatchTitle":"usersAddTagsBatchTitle"} /></h2>
        </div>
        <div className={classes.guestCardContainer}>
          <h2 className={classes.sectionText} style={{ color: '#3f3f3f', fontWeight: 'bold', fontSize: 20 }}><Entity entity={removeMode?"guestsBatchToRemoveTags":"guestsBatchToAddTags"} data={{ number: _.size(uniqueGuests) }} /></h2>
        </div>
        <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10, marginTop: 20 }}>
          <div style={{ maxHeight: 200, overflowY: 'scroll' }}>
            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
              {_.map(uniqueGuests, (item, index) =>
                <UserRow
                  item={item}
                  isEaven={index % 2 === 0}
                />,
              )}
            </div>
          </div>
        </div>
        {!removeMode ? <h2 className={classes.sectionText} style={{ marginTop: 25, marginBottom: 10, color:'#3f3f3f' }}><Entity entity="usersAddTagsBatchDescription" /></h2> : null}
        <Form style={formStyle} autoComplete="off">
          {!removeMode ? (
            <Entity
              componentClass={Field}
              name="tags"
              multi
              componentAttribute="placeholder"
              entity="insertGuestsTags"
              component={TagsSelectorField}
              tagType="GUEST"
              onNewTag={null}
              onEditTag={null}
              isDisabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, true) || cannotEditExternalUser}
              onEditTagDates={null}
              tags={availableTags}
              onInputChange={(value)=>this.filterGuestTags(value)}
            />
          ):(
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
              <div style={{display: 'flex', flexDirection: 'column', width: '50%', marginRight: 10 }}>
                <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                  <TagIconCustom style={{ width: 26, marginRight: 10, color: BLUE, marginLeft: 2 }}/>
                  <h4 style={{ marginTop: 0, color: '#3f3f3f', marginBottom: 0, fontWeight: 'bold' }}><Entity entity={"usersRemoveTagsBatchAvailable"} /></h4>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', borderRadius:5, backgroundColor: 'rgb(241, 241, 241)', maxHeight: 200, minHeight: 70, overflowY: 'auto', padding: 10 }}>
                  {_.map(removableTags, tag =>
                    <div style={{ display: 'flex', alignItems: 'center', margin: 10, width: 'fit-content', border: '1px solid #CCC', borderRadius: 4, backgroundColor: 'white', cursor: 'pointer' }} key={tag.id} onClick={()=> this.onAddToDeleting(tag)}>
                      <CloseIcon 
                        style={{ fontSize: 20, margin: 5, marginRight: 0, cursor:'pointer', color: 'indianred' }} 
                        onClick={()=> this.onAddToDeleting(tag)}
                      />
                      <Tag
                        key={tag.id}
                        tag={tag}
                        style={{ padding: 5, marginTop:1, marginRight:2 }}
                        textStyle={{ fontSize: 14 }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', width: '50%', marginRight: 10 }}>
                <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                  <TagRemoveIconCustom style={{ width: 20, marginRight: 10, color: 'indianred', marginLeft: 2 }} />
                  <h4 style={{ marginTop: 0, color: '#3f3f3f', marginBottom: 0, fontWeight: 'bold' }}><Entity entity={"usersRemoveTagsBatchRemoving"} /></h4>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', borderRadius: 5, backgroundColor: 'rgb(241, 241, 241)', maxHeight: 200, minHeight: 70, marginTop: 6, overflowY: 'auto', padding: 10 }}>
                  {_.map(deletingTags, tag =>
                    <div style={{ display: 'flex', alignItems: 'center', margin: 10, width: 'fit-content', border: '1px solid #DDD', borderRadius: 4, cursor: 'pointer', backgroundColor: 'white' }} key={tag.id} onClick={()=> this.onAddToRemovable(tag)}>
                      <IconButton onClick={()=> this.onAddToRemovable(tag)} style={{padding:0}}>
                        <ArrowLeftIconCustom style={{ color: 'grey', width: 20, margin: 5, marginRight: 0, cursor:'pointer' }} />
                      </IconButton>
                      <Tag
                        key={tag.id}
                        tag={tag}
                        style={{ padding: 5, marginTop:1, marginRight:2 }}
                        textStyle={{ fontSize: 14 }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Form>
        {removeMode ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 25 }}>
            <WarningOutlined style={{ color: ORANGE, fontSize: 20, marginRight: 10 }} />
            <h2 className={classes.sectionText} style={{ color:'gray' }}>
              <Entity entity={"usersRemoveTagsBatchNote"} />
            </h2>
          </div>
        ) : null}
        <MDButton
          title={<Entity entity="confirmAddTagsBatch" />}
          style={{
            marginTop:10,
            opacity:(removeMode && !(deletingTags.length))|| 
              (!removeMode && !(form&&form.values&&form.values.tags&&form.values.tags.length))?0.5:1
          }}
          backgroundColor={GREEN}
          disabled={
            (removeMode && !(deletingTags.length)) 
            || 
            (!removeMode && !(form&&form.values&&form.values.tags&&form.values.tags.length))
          }
          onClick={() => this.onConfirm()}
        />
      </div>
    );
  }
}
export default withStyles(styles)(GuestsTagsBatchView);
