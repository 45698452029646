import { axiosInstance } from './rest';

export function getCommunications(params) {
  const requestData = {
    params,
  };
  return axiosInstance.get('/api/v2/communications', requestData);
}

export function createCommunication(communicationDTO) {
  return axiosInstance.post('/api/v2/communications', communicationDTO);
}

export function getCommunicationDetails(communicationId) {
  return axiosInstance.get(`/api/v2/communications/${communicationId}`);
}

export function deleteCommunication(communicationId) {
  return axiosInstance.delete(`/api/v2/communications/${communicationId}`);
}

export function updateCommunication(communicationId, communicationDTO) {
  return axiosInstance.put(`/api/v2/communications/${communicationId}`, communicationDTO);
}
