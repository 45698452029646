import React from 'react';
import _ from 'lodash';

export default class Permission extends React.Component {
  render() {
    const {
      children,
      do: canDo,
      on,
      withLicenses,
      roles,
      rolesNot,
      abilityHelper, 
    } = this.props;

    let isAllowed = false;

    isAllowed = abilityHelper.hasPermission(canDo, on) && abilityHelper.hasLicenseType(withLicenses);
    return (isAllowed ? children : null);
  }
}
