import { Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, FieldArray } from 'redux-form';
import MDRadioButtonView from '../Elements/MDRadioButtonsGroup/MDRadioButtonView.js';
import CustomField from '../Fields/CustomField.jsx';
import MDCheckBoxGroupField from '../Fields/MDCheckBox/MDCheckBoxGroupField.js';
import TagsSelectorListField from '../Elements/TagsSelectorListField.jsx';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import AdditionalTimeRangeForm from './AdditionalTimeRangeForm.jsx';
import * as UtilsActions from '../../../redux/actions/utils.actions.js';
import { Card, IconButton } from '@material-ui/core';
import { ORANGE, RED } from '../../../_config/consts.js';
import { WarningOutlined } from '@material-ui/icons';

const timeOptions = [
  {
    value: 1,
    label: <Entity entity="twentifourhours" />,
  },
  {
    value: 2,
    label: <Entity entity="moringTime" />,
  },
  {
    value: 3,
    label: <Entity entity="afternoonTime" />,
  },
  {
    value: 4,
    label: <Entity entity="moringAndAfternoon" />,
  },
];

const onRenderTimeRange = (props) => {
  const { fields, areDeviceDatesLocked, formName, canEdit, formData, timeRangesFieldName, is12HoursFormat, onInitializeNewTimeRange } = props;
  const numberOfTimeRanges = _.size(fields);
  return (
    <div>
      {fields && fields.map((timeRange, index) => (
        <div key={`timeRange${index + 1}`} elevation={2} style={{ marginTop: 20}}>
          <Divider />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10, paddingRight: 10 }}>
            <h4 style={{ fontWeight: 'bold', margin: 10 }}><Entity key={index+1} entity="additionalTimeRange" /></h4>
            <IconButton
              style={{ marginLeft: 10, marginBottom: 5 }}
              onClick={(e) => {
                e.stopPropagation();
                if (!formData.isEditing) {
                  fields.remove(index);
                }
              }}
            >
              <DeleteIcon style={{ color: RED, fontSize: 20 }} />
            </IconButton>
          </div>
          <AdditionalTimeRangeForm
            formName={formName}
            timeRange={timeRange}
            timeRangeIndex={index}
            timeRangesFieldName={timeRangesFieldName}
            areDeviceDatesLocked={areDeviceDatesLocked}
            canEdit={canEdit}
            is12HoursFormat={is12HoursFormat}
          />
        </div>
      ))}
      {numberOfTimeRanges < 1 ? (
        <Card
          style={{ height: 60, marginBottom: 20, marginTop: 20, padding: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          className="card card-rounded"
          onClick={() => {
            fields.push({});
            onInitializeNewTimeRange(numberOfTimeRanges);
          }}
        >
          <AddIcon style={{ color: '#4DB6AC', marginRight: 15, fontSize: 25 }} />
          <h4 style={{ fontWeight: 'bold' }}><Entity entity="newTimeRange" /></h4>
        </Card>
      ) : null}
    </div>
  );
}

@connect((state) => ({ companyConfigurations: state.user.companyConfigurations }))
export default class DefaultTimeProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeOptionSelected: 0,
    };
  }

  onDateCheckboxChange(value) {
    const { dispatch, formName } = this.props;
    this.setState({ timeOptionSelected: value });
    switch (value) {
      case 1: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().endOf('day').valueOf()));
        break;
      }
      case 2: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().startOf('day').set('hours', 13).valueOf()));
        break;
      }
      case 3: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').set('hours', 14).valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      case 4: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      default: {
        dispatch(change(formName, 'timeIntervalFrom', moment().startOf('day').valueOf()));
        dispatch(change(formName, 'timeIntervalTo', moment().endOf('day').valueOf()));
        break;
      }
    }
  }

  render() {
    const { canEdit, formData, form, formName, lockHasErrorCondition, locksHaveError, dispatch, emptyTagSelectionText, credentialTimeframeText, onInitializeNewTimeRange, areDeviceDatesLocked, availableLockTags, locksFilteredByTags, timeProfile, is12HoursFormat, onFetchMoreLocks, onLockTagsChange, onLockInputChange, onLockFilterModeChange, companyConfigurations } = this.props;
    const { timeOptionSelected } = this.state;
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: weekday,
      value: moment().isoWeekday(weekday).isoWeekday(),
    }));
    const isAdditionalTimeProfileActive = dispatch(UtilsActions.isMultiTimeProfileActive());
    const isArgoAdditionalTimeProfileActive = dispatch(UtilsActions.isArgoMultiTimeProfileActive());
    return (
      <div style={{ paddingLeft: 5, marginTop: 0 }}>
        <Row>
          <input style={{ display: 'none' }} type="username" name="username" />
          <FormCardContainer
            hideCard
            containerStyle={{ marginTop: -10 }}
          >
            <TagsSelectorListField
              name="lockTags"
              formName={formName}
              selectedTagsFieldName="selectedLocksTags"
              tags={availableLockTags}
              selectedTags={locksFilteredByTags}
              form={form}
              tagsType="LOCK"
              placeholder="insertLockTagsForCredential"
              listTitle="selectedLocks"
              emptyTagSelectionText={emptyTagSelectionText || null}
              title="cardLockTitle"
              onFetchMoreItems={page => onFetchMoreLocks(page)}
              onTagsChange={values => onLockTagsChange(values)}
              onInputChange={value => onLockInputChange(value)}
              itemHasErrorCondition={lockHasErrorCondition ? (value) => lockHasErrorCondition(value) : null}
              onFilterModeChange={values => onLockFilterModeChange(values)}
              disabled={!canEdit}
            />
            {locksHaveError ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <WarningOutlined style={{ color: ORANGE, marginRight: 5, fontSize: 20 }} />
                <h5 style={{ color: ORANGE, fontWeight: 'bold' }}><Entity entity="selectedLocksRFIDIncompatibleError" /></h5>
              </div>
          ) : null}
          </FormCardContainer>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Entity
              componentClass={Field}
              title={<Entity entity={credentialTimeframeText || 'credentialTimeFrame'} />}
              name="credentialTimeframe"
              containerStyle={{ paddingLeft: 30 }}
              component={CustomField}
              type="airbnbDateRange"
              disabled={!canEdit || areDeviceDatesLocked}
              minimumNights={0}
            />
            {companyConfigurations.checkincheckoutActive ?
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ marginLeft: 8, marginRight: 30, marginLeft: 30 }}>
                    <Entity
                      componentClass={Field}
                      title={<Entity entity="validityTimeStart" />}
                      name="dateIntervalTimeFrom"
                      component={CustomField}
                      type="antdTimePicker"
                      is12HoursFormat={is12HoursFormat}
                      placeholder="Select a time"
                      disabled={!canEdit || areDeviceDatesLocked}
                    />
                  </div>
                  <Entity
                    componentClass={Field}
                    title={<Entity entity="validityTimeEnd" />}
                    name="dateIntervalTimeTo"
                    component={CustomField}
                    is12HoursFormat={is12HoursFormat}
                    type="antdTimePicker"
                    placeholder="Select a time"
                    disabled={!canEdit || areDeviceDatesLocked}
                  />
                </div> : null
              }
          </div>
          {companyConfigurations.checkincheckoutActive ? <Divider style={{ marginBottom: 20, marginTop: 10, marginRight: 25, marginLeft: 25 }} /> : null}
          <h4 style={{ paddingLeft: 35, margin: 0, marginTop: 10, marginBottom: 15, fontWeight: 'bold' }}><Entity entity="invitationTimeProfile" /></h4>
          <div style={{ paddingLeft: 30, marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ marginLeft: 8, marginRight: 60 }}>
              <Entity
                componentClass={Field}
                title={<Entity entity="timeIntervalFrom" />}
                name="timeIntervalFrom"
                component={CustomField}
                type="antdTimePicker"
                is12HoursFormat={is12HoursFormat}
                placeholder="Select a time"
                disabled={!canEdit || areDeviceDatesLocked}
              />
            </div>
            <Entity
              componentClass={Field}
              title={<Entity entity="timeIntervalTo" />}
              name="timeIntervalTo"
              component={CustomField}
              is12HoursFormat={is12HoursFormat}
              type="antdTimePicker"
              placeholder="Select a time"
              disabled={!canEdit || areDeviceDatesLocked}
            />
            <div style={{ marginLeft: 30, marginTop: 20 }}>
              {_.map(timeOptions, timeOption => (
                <MDRadioButtonView
                  key={timeOption.value}
                  checked={timeOptionSelected === timeOption.value}
                  value={(timeOption.value).toString()}
                  label={timeOption.label}
                  disabled={!canEdit || areDeviceDatesLocked}
                  handleChange={value => this.onDateCheckboxChange(timeOption.value)}
                />
              ))}
            </div>
          </div>
        </Row>
        <Field
          title={<Entity entity="daysOfTheWeek" />}
          name="daysOfTheWeek"
          component={MDCheckBoxGroupField}
          containerstyle={{ paddingLeft: 15, marginTop: 10 }}
          horizontal
          options={daysOptions}
          disabled={!canEdit || areDeviceDatesLocked}
        />
        <FieldArray
          name="additionalTimeRange_default"
          timeRangesFieldName="additionalTimeRange_default"
          component={onRenderTimeRange}
          formData={formData}
          form={form}
          formName={formName}
          is12HoursFormat={is12HoursFormat}
          onInitializeNewTimeRange={programId => onInitializeNewTimeRange(programId, 'additionalTimeRange_default')}
          areDeviceDatesLocked={areDeviceDatesLocked}
          canEdit={canEdit}
        />
      </div>
    );
  }
}
