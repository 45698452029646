import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SAVE_GUESTS_SPECIAL_TAGS,
  APPEND_GUESTS_SPECIAL_TAGS,
  SAVE_GUESTS_SPECIAL_TAGS_PAGINATION_DATA,
  SAVE_LOCKS_SPECIAL_TAGS,
  APPEND_LOCKS_SPECIAL_TAGS,
  SAVE_LOCKS_SPECIAL_TAGS_PAGINATION_DATA,
  SET_SELECTED_TAG,
  SET_TAGS_OPERATIONAL_MODE,
  SET_GUESTS_TAGS_FILTER_FIELD,
  RESET_GUESTS_TAGS_FILTERS,
  RESET_GUESTS_TAGS_DATA,
  SET_LOCKS_TAGS_FILTER_FIELD,
  RESET_LOCKS_TAGS_FILTERS,
  RESET_LOCKS_TAGS_DATA,
  UPDATE_LOCK_TAG_IN_STATE,
  UPDATE_GUEST_TAG_IN_STATE,
  SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES,
  APPEND_GUESTS_SPECIAL_TAGS_CATEGORIES,
  SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES_PAGINATION,
  SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES,
  SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES_PAGINATION,
  SAVE_TAG_GUESTS_DATA,
  SAVE_TAG_GUESTS_PAGINATION,
  APPEND_TAG_GUESTS_DATA,
  RESET_TAG_GUESTS_DATA,
  SAVE_TAG_LOCKS_DATA,
  APPEND_TAG_LOCKS_DATA,
  SAVE_TAG_LOCKS_PAGINATION,
  RESET_TAG_LOCKS_DATA,
  REMOVE_GUEST_TAG_IN_STATE,
  REMOVE_LOCK_TAG_IN_STATE,
  SAVE_GUESTS_SPECIAL_TAGS_NUMBER,
  SAVE_LOCKS_SPECIAL_TAGS_NUMBER,
} from '../actions/actionTypes/tagsManagement';

const initialState = Immutable({
  userTags: {
    content: [],
    pagination: {},
    filters: {},
    number: 0,
    guests: {
      content: [],
      pagination: {},
    },
  },
  lockTags: {
    content: [],
    pagination: {},
    filters: {},
    number: 0,
    locks: {
      content: [],
      pagination: {},
    },
  },
  userTagsCategories: {
    content: [],
    pagination: {},
  },
  lockTagsCategories: {
    content: [],
    pagination: {},
  },
  selectedTag: null,
  isOperationalMode: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_GUESTS_SPECIAL_TAGS:
      return state.setIn(['userTags', 'content'], action.tags);
    case APPEND_GUESTS_SPECIAL_TAGS:
      return state.setIn(['userTags', 'content'], [...state.userTags.content, ...action.tags]);
    case SAVE_GUESTS_SPECIAL_TAGS_NUMBER:
      return state.setIn(['userTags', 'number'], action.number);
    case SAVE_GUESTS_SPECIAL_TAGS_PAGINATION_DATA:
      return state.setIn(['userTags', 'pagination'], action.pagination);
    case SAVE_LOCKS_SPECIAL_TAGS:
      return state.setIn(['lockTags', 'content'], action.tags);
    case APPEND_LOCKS_SPECIAL_TAGS:
      return state.setIn(['lockTags', 'content'], [...state.lockTags.content, ...action.tags]);
    case SAVE_LOCKS_SPECIAL_TAGS_PAGINATION_DATA:
      return state.setIn(['lockTags', 'pagination'], action.pagination);
    case SAVE_LOCKS_SPECIAL_TAGS_NUMBER:
      return state.setIn(['lockTags', 'number'], action.number);
    case SET_SELECTED_TAG:
      return state.set('selectedTag', action.tag);
    case SET_TAGS_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_GUESTS_TAGS_FILTER_FIELD:
      return state.setIn(['userTags', 'filters', action.field], action.value);
    case RESET_GUESTS_TAGS_FILTERS:
      return state.setIn(['userTags', 'filters'], {});
    case RESET_GUESTS_TAGS_DATA:
      return state.setIn(['userTags', 'content'], []);
    case SET_LOCKS_TAGS_FILTER_FIELD:
      return state.setIn(['lockTags', 'filters', action.field], action.value);
    case RESET_LOCKS_TAGS_FILTERS:
      return state.setIn(['lockTags', 'filters'], {});
    case RESET_LOCKS_TAGS_DATA:
      return state.setIn(['lockTags', 'content'], []);
    case UPDATE_GUEST_TAG_IN_STATE: {
      const index = _.findIndex(state.userTags.content, data => data.id === action.tag.id);
      if (index !== -1) {
        return state.setIn(['userTags', 'content', index], action.tag);
      }
      return state.setIn(['userTags', 'content'], [...state.userTags.content, action.tag]);
    }
    case UPDATE_LOCK_TAG_IN_STATE: {
      const index = _.findIndex(state.lockTags.content, data => data.id === action.tag.id);
      if (index !== -1) {
        return state.setIn(['lockTags', 'content', index], action.tag);
      }
      return state.setIn(['lockTags', 'content'], [...state.lockTags.content, action.tag]);
    }
    case REMOVE_GUEST_TAG_IN_STATE: {
      const usersTags = _.filter(state.userTags.content, tag => tag.id !== action.tagId);
      return state.setIn(['userTags', 'content'], usersTags);
    }
    case REMOVE_LOCK_TAG_IN_STATE: {
      const locksTags = _.filter(state.lockTags.content, tag => tag.id !== action.tagId);
      return state.setIn(['lockTags', 'content'], locksTags);
    }
    case SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES:
      return state.setIn(['userTagsCategories', 'content'], action.categories);
    case APPEND_GUESTS_SPECIAL_TAGS_CATEGORIES:
      return state.setIn(['userTagsCategories', 'content'], [...state.userTagsCategories.content, ...action.categories]);
    case SAVE_GUESTS_SPECIAL_TAGS_CATEGORIES_PAGINATION:
      return state.setIn(['userTagsCategories', 'pagination'], action.pagination);
    case SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES:
      return state.setIn(['lockTagsCategories', 'content'], action.categories);
    case SAVE_LOCKS_SPECIAL_TAGS_CATEGORIES_PAGINATION:
      return state.setIn(['lockTagsCategories', 'pagination'], action.pagination);
    case SAVE_TAG_GUESTS_DATA:
      return state.setIn(['userTags', 'guests', 'content'], action.guests);
    case SAVE_TAG_GUESTS_PAGINATION:
      return state.setIn(['userTags', 'guests', 'pagination'], action.pagination);
    case APPEND_TAG_GUESTS_DATA:
      return state.setIn(['userTags', 'guests', 'content'], [...state.userTags.guests.content, ...action.guests]);
    case RESET_TAG_GUESTS_DATA: {
      return state.setIn(['userTags', 'guests'], initialState.userTags.guests);
    }
    case SAVE_TAG_LOCKS_DATA:
      return state.setIn(['lockTags', 'locks', 'content'], action.locks);
    case SAVE_TAG_LOCKS_PAGINATION:
      return state.setIn(['lockTags', 'locks', 'pagination'], action.pagination);
    case APPEND_TAG_LOCKS_DATA:
      return state.setIn(['lockTags', 'locks', 'content'], [...state.lockTags.locks.content, ...action.locks]);
    case RESET_TAG_LOCKS_DATA: {
      return state.setIn(['lockTags', 'locks'], initialState.lockTags.locks);
    }
    default:
      return state;
  }
}
