import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { initialize, submit } from 'redux-form';
import { COMPANY_NOTIFICATIONS_ATLAS_STATES, COMPANY_NOTIFICATIONS_BATTERY_LEVELS, COMPANY_NOTIFICATIONS_BATTERY_STATES, COMPANY_NOTIFICATIONS_GATEWAY_STATES, COMPANY_NOTIFICATIONS_REPEAT_STATES, PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import * as ModalActions from '../../../redux/actions/modal.actions.js';
import * as PlatformUserActions from '../../../redux/actions/platformUsers.actions';
import * as SettingsActions from '../../../redux/actions/setting.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import MDButton from '../../MDButton/MDButton.jsx';
import Permission from '../../Permission/Permission.jsx';
import HardwareStatusReportForm from '../../forms/HardwareStatusReportForm.jsx';


@connect((state) => ({}))
class HardwareStatusReportOperationalSection extends React.Component {

  async componentWillMount() {
    const { dispatch } = this.props;
    dispatch(PlatformUserActions.fetchPlatformUsers(0,100));
    let gatewayMode = COMPANY_NOTIFICATIONS_GATEWAY_STATES.OFF;
    let batteryMode = COMPANY_NOTIFICATIONS_BATTERY_STATES.OFF;
    let atlasMode = COMPANY_NOTIFICATIONS_ATLAS_STATES.OFF;
    let repeatMode = COMPANY_NOTIFICATIONS_REPEAT_STATES.OFF;
    let smartLockBatteryLevelSelected = COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L75;
    try {
      const response = await dispatch(SettingsActions.fetchSettingsByType('companyNotifications'));
      if (response && response.gatewayOfflineForMinutes && response.gatewayOfflineForMinutes>0)
        gatewayMode = COMPANY_NOTIFICATIONS_GATEWAY_STATES.ON      
      if (response && response.smartLockBatteryLevel && response.smartLockBatteryLevel>0){
        batteryMode = COMPANY_NOTIFICATIONS_BATTERY_STATES.ON
        smartLockBatteryLevelSelected = 
          response.smartLockBatteryLevel===25?COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L25:
          response.smartLockBatteryLevel===50?COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L50:
          COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L75
      }
      if (response && response.atlasOffline)
        atlasMode = COMPANY_NOTIFICATIONS_ATLAS_STATES.ON
      if (response && response.sameNotificationAfterHours && response.sameNotificationAfterHours>0)
        repeatMode = COMPANY_NOTIFICATIONS_REPEAT_STATES.ON
      dispatch(initialize('HardwareStatusReportForm', { ...response, gatewayMode, batteryMode, atlasMode, repeatMode, smartLockBatteryLevelSelected }));
    } catch(error) {
      dispatch(initialize('HardwareStatusReportForm', { gatewayMode, batteryMode, atlasMode, repeatMode, smartLockBatteryLevelSelected }));
    }
  }

  async saveCompanySettings(values) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(SettingsActions.updateNotificationsSettings(values));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateSuccessfully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateError" /></h6>),
        },
      }));
    }
  }

  render() {
    const { dispatch, form } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        <HardwareStatusReportForm
          onSubmit={values => this.saveCompanySettings(values)}
        />
        <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
          <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.COMPANY} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MDButton
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              title={<Entity entity="save" />}
              onClick={() => dispatch(submit('HardwareStatusReportForm'))}
            />
          </Permission>
          </div>
      </div>
    );
  }
} 

export default HardwareStatusReportOperationalSection;
