import React from 'react';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import * as CredentialActions from '../../redux/actions/credential.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as ModalActions from '../../redux/actions/modal.actions.js';
import CredentialForm from '../forms/CredentialForm.jsx';
import LockDetailsRow from '../Locks/LockDetailsRow.jsx';
import GuestDetailsRow from '../Guests/GuestDetailsRow.jsx';

@connect((state) => ({
  credential: state.credentials.selectedCredential,
  userTags: state.tags.user.data,
  credentialForm: state.form.CredentialForm,
  lockTags: state.tags.lock.data,
  language: state.settings.language,
}))
class CredentialsOperationalSection extends React.Component {


  componentWillMount() {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.fetchGuestTags(includeSpecialTags, null, 0, 20));
    dispatch(TagActions.fetchLockTags(includeSpecialTags, 0, 20));
  }

  async saveCredential(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      if (values.id) {
        await dispatch(CredentialActions.updateCredential(values));
      } else {
        await dispatch(CredentialActions.createCredential(values));
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  fetchGuestsByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchGuestsByTags());
    dispatch(CredentialActions.fetchGuestsByTagsSmartCredentialsForm(page, append));
  }

  fetchLocksByTags(page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchLocksByTags());
    dispatch(CredentialActions.fetchLocksByTagsSmartCredentialsForm(page, append));
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchGuestTagsByFilter(value, 50, includeSpecialTags));
  }

  filterLockTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 200, includeSpecialTags));
  }


  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CredentialActions.setOperationalMode(false));
  }



  render() {
    const { onCredentialFormSubmit, userTags, lockTags, credential, onDeleteCredential } = this.props;
    return (
      <div style={{ marginBottom: 300 }}>
        <CredentialForm
          availableUserTags={userTags}
          availableLockTags={lockTags}
          guestsFilteredByTags={credential.guestsFilteredByTags}
          locksFilteredByTags={credential.locksFilteredByTags}
          onFetchMoreGuests={page => this.fetchGuestsByTags(page, true)}
          onFetchMoreLocks={page => this.fetchLocksByTags(page, true)}
          onGuestInputChange={value => this.filterGuestTags(value)}
          onLockInputChange={value => this.filterLockTags(value)}
          onGuestTagsChange={() => this.fetchGuestsByTags()}
          onLockTagsChange={() => this.fetchLocksByTags()}
          onLockFilterModeChange={() => this.fetchLocksByTags()}
          onGuestFilterModeChange={() => this.fetchGuestsByTags()}
          onSubmit={values => this.saveCredential(values)}
          onDeleteCredential={credentialId => onDeleteCredential(credentialId)}
          onCredentialFormSubmit={() => onCredentialFormSubmit()}
        />
      </div>
    );
  }
} 

export default CredentialsOperationalSection;
