import React from 'react';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import PinIconCustom from '../../CustomIcons/PinIconCustom.jsx';

const PINAccessMethod = ({ onClick }) => (
  <div
    style={{ padding: 5, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10, borderRadius: 5, marginLeft: 5, marginRight: 10 }}
    onClick={onClick ? () => onClick() : null}
  >
    <PinIconCustom style={{ width: 25, color: '#3f3f3f' }} />
    <h5 className="link-label" style={{ margin: 0, marginTop: 2, fontSize: 15, marginLeft: 10, color: '#3f3f3f' }}>
      <Entity entity="newAccessMethodPIN" />
    </h5>
  </div>
);

export default PINAccessMethod;
