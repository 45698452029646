export const getDefaultAPIBaseURL = () => {
  return 'https://api-test101.staging.jago.cloud';
};

export const getDefaultBookeyApiBaseUrl = () => {
  return 'https://api.bookey.staging.sofialocks.cloud';
};

export const getApiBackendPrefix = () => {
  return 'api-';
};


export const getDefaultCompanyConfiguration = () => {
  return {
    maximumExportableLogs: 100000,
  };
};

export const CLIENT_ID = 'luckeyfrontend';
export const CLIENT_SECRET = '';
export const JWT_ENCRIPTION_KEY = 'r4WjiyGgW3DH58brFJFP';
export const EAGLE_EYE_CLIENT_ID = '752d95438b564084b153ae851d07a2ef';
