// @ts-nocheck
import Warning from '@material-ui/icons/Warning.js';
import { Form } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import { CUSTOM_FIELDS_TYPES, ORANGE, PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts.js';
import MDButton from '../../MDButton/MDButton.jsx';
import Permission from '../../Permission/Permission.jsx';
import CustomField from '../Fields/CustomField.jsx';
import TranslatableOption from '../Fields/TranslatableOption.jsx';



const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  } else if (_.trim(values.name).length > 255) {
    errors.name = 'maximumLenghtTwelve';
  }
  if (!values.type) {
    errors.type = 'required';
  }
  return errors;
};

@reduxForm({ form: 'CustomFieldsForm', validate })
@connect(state => ({ form: state.form.CustomFieldsForm }))
class CustomFieldsForm extends React.Component {
  render() {
    const customFieldsTypesOptions = [
      { label: 'selectCustomFieldType', value: '' },
      ..._.map(CUSTOM_FIELDS_TYPES, customFieldType => (
        { label: L20NContext.getSync('customFieldsTypes', { type: CUSTOM_FIELDS_TYPES[customFieldType]}), value: CUSTOM_FIELDS_TYPES[customFieldType] }
      )),
    ];
    const { onValidateForm, form } = this.props;
    const isEditing = form && form.values && form.values.id;
    return (
      <Form style={{ paddingLeft: 15, paddingRight: 15, marginTop: 20 }}>
        <Entity
          componentClass={Field}
          name="name"
          componentAttribute="placeholder"
          entity="insertCustomFieldName"
          title={<Entity entity="customFieldName" />}
          component={CustomField}
          type="text"
          mandatory
        />
        <Field
          id="type"
          name="type"
          title={<Entity entity="customFieldType" />}
          component={CustomField}
          className="form-control-select"
          componentClass="select"
          disabled={isEditing}
        >
          {_.map(customFieldsTypesOptions, type =>
            <Entity
              key={type.value}
              componentClass={TranslatableOption}
              value={type.value}
              componentAttribute="text"
              entity={type.label}
            />)
          }
        </Field>
        {isEditing ? (
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
            <Warning style={{ fontSize: 15, color: ORANGE }} />
            <h5 style={{ margin: 0, marginLeft: 5 }}>{<Entity entity="customFieldTypeEditForbidden" />}</h5>
          </div>
        ) : null}
        <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.SMART_LOCK} abilityHelper={AbilityProvider.getAbilityHelper()}>
          <MDButton
            title={<Entity entity="save" />}
            onClick={() => onValidateForm()}
          />
        </Permission>
      </Form>
    );
  }
} 

export default CustomFieldsForm;
