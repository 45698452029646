import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import StandardDeviceValidationForm from '../../forms/StandardDeviceValidationForm/StandardDeviceValidationForm.jsx';


const validate = (values) => {
  const errors = {};
  if (!values.validationMode || values.validationMode === '') {
    errors.validationMode = 'required';
  }
  return errors;
};

@reduxForm({ form: 'StandardDevicesValidationFormDefault', validate })
@connect((state) => ({ form: state.form.StandardDevicesValidationFormDefault, validationPeriodDefaults: state.cards.validationPeriodDefaults }))
class StandardDevicesValidationFormDefault extends React.Component {

  render() {
    const { dispatch, form } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        <StandardDeviceValidationForm
          title="defaultValidationSettingsTitle"
          subtitle="defaultValidationSettingsSubtitle"
          hideCard={false}
          formName="StandardDevicesValidationFormDefault"
          form={form}
        />
      </div>
    );
  }
} 

export default StandardDevicesValidationFormDefault;
