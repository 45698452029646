/* eslint-disable arrow-parens */
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SAVE_ACCESSORIES,
  APPEND_ACCESSORIES,
  SAVE_ACCESSORIES_PAGINATION,
  SET_SELECTED_ACCESSORY,
  SET_ACCESSORIES_FILTER,
  RESET_ACCESSORIES_FILTERS,
  RESET_ACCESSORIES_DATA,
  UPDATE_ACCESSORY,
  REMOVE_ACCESSORY,
  SAVE_ACCESSORY_HISTORY,
  APPEND_ACCESSORY_HISTORY,
  SAVE_ACCESSRY_HISTORY_PAGINATION,
  SET_ACCESSORY_HISTORY_FILTER,
  RESET_ACCESSORY_HISTORY_FILTER,
  RESET_ACCESSORY_HISTORY_DATA,
} from '../actions/actionTypes/accessories';
import moment from 'moment';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
  },
  history: {
    content: [],
    pagination: {},
    filters: {
      fromDate: moment().subtract(1, 'M').startOf('day').valueOf(),
      toDate: moment().add(1, 'd').endOf('day').valueOf(),
    },
  },
  selectedAccessory: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ACCESSORIES:
      return state.setIn(['data', 'content'], action.accessories);
    case APPEND_ACCESSORIES:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.accessories]);
    case UPDATE_ACCESSORY: {
      const index = _.findIndex(state.data.content, data => data.id === action.accessory.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.accessory);
      }
      return state.setIn(['data', 'content'], [...state.data.content, action.accessory]);
    }
    case REMOVE_ACCESSORY: {
      const accessories = _.filter(state.data.content, accessoryState => accessoryState.id !== action.accessoryId);
      return state.setIn(['data', 'content'], accessories);
    }
    case SAVE_ACCESSORY_HISTORY: {
      return state.setIn(['history', 'content'], action.history);
    }
    case APPEND_ACCESSORY_HISTORY: {
      return state.setIn(['history', 'content'], [...state.history.content, ...action.history]);
    }
    case SAVE_ACCESSRY_HISTORY_PAGINATION: {
      return state.setIn(['history', 'pagination'], action.pagination);
    }
    case SET_ACCESSORY_HISTORY_FILTER: {
      return state.setIn(['history', 'filters', action.field], action.value);
    }
    case RESET_ACCESSORY_HISTORY_FILTER: {
      return state.setIn(['history', 'filters'], {});
    }
    case RESET_ACCESSORY_HISTORY_DATA: {
      return state.setIn(['history', 'content'], []).setIn(['history', 'pagination'], {});
    }
    case SAVE_ACCESSORIES_PAGINATION:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SET_SELECTED_ACCESSORY:
      return state.set('selectedAccessory', action.accessory);
    case SET_ACCESSORIES_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case RESET_ACCESSORIES_DATA:
      return state.setIn(['data', 'content'], []).setIn(['data', 'pagination'], {});
    case RESET_ACCESSORIES_FILTERS:
      return state.setIn(['data', 'filters'], {});
    default:
      return state;
  }
}
