import React, { Component } from 'react';
import LockIcon from '@material-ui/icons/Lock';
import _ from 'lodash';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import Card from '@material-ui/core/Card';
import { getColorFromThemeName } from '../../../_config/utils';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts';

export default class LocksTagsManagementRow extends Component {
  render() {
    const { isSelected, onClick, tag, themeName } = this.props;
    const borderColor = tag && tag.color ? tag.color : getColorFromThemeName(themeName || 'default');
    const canReadLocks = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    return (
      <Card
        className={`card ${isSelected && 'card-selected'}`}
        style={{ padding: 15, borderRadius: 10, borderLeft: `solid 5px ${borderColor}` }}
        onClick={() => onClick()}
      >
        <div className="card-body">
          {canReadLocks ? (
            <div style={{ marginLeft: 10, marginRight: 15 }}>
              <h4 className="card-title" style={{ textAlign: 'center', margin: 2, marginTop: 5, marginLeft: 0, fontSize: 22 }}>{tag.locksNumber}</h4>
              <h5 className="card-subtitle" style={{ textAlign: 'center', margin: 2, marginTop: 5, marginLeft: 0 }}><Entity entity="lockNumberInGroup" /></h5>
            </div>
          ) : null}
          <div className="card-inner-body" style={{ borderLeft: canReadLocks ? '2px solid #f0f0f0' : 'unset' }}>
            <h3 className="card-title" style={{ margin: 2, marginTop: 5, marginLeft: 0, fontSize: 22 }}>{tag.name}</h3>
            <h4 className="card-title" style={{ fontWeight: 100, margin: 2, marginTop: 5, marginLeft: 0, fontSize: 18 }}>{tag.type}</h4>
          </div>
          
        </div>
      </Card>
    );
  }
}
