import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_INVITATIONS,
  APPEND_INVITATIONS,
  SAVE_INVITATION,
  SELECT_INVITATION,
  SET_INVITATIONS_PAGINATION_DATA,
  SET_INVITATION_OPERATIONAL_MODE,
  SET_INVITATION_FILTER,
  RESET_INVITATIONS_FILTERS,
  RESET_INVITATIONS_DATA,
  UPDATE_INVITATION,
  SAVE_INVITATION_REDEEM_EVENTS_DATA,
  APPEND_INVITATION_REDEEM_EVENTS_DATA,
  SAVE_INVITATION_REDEEM_EVENTS_PAGINATION,
  RESET_INVITATION_REDEEM_EVENTS_DATA,
} from '../actions/actionTypes/invitations';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
  },
  invitationRedeemEvents: {
    content: [],
    pagination: {},
  },
  selectedInvitation: {},
  isOperationalMode: false,
});

export default function (state = initialState, action) {
  let tmp;
  switch (action.type) {
    case SAVE_INVITATIONS:
      return state.setIn(['data', 'content'], action.invitations);
    case APPEND_INVITATIONS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.invitations]);
    case SAVE_INVITATION:
      return state.setIn(['data', 'content'], [...state.data.content, action.invitation]);
    case SET_INVITATIONS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SELECT_INVITATION:
      return state.set('selectedInvitation', action.invitation);
    case SAVE_INVITATION_REDEEM_EVENTS_DATA:
      return state.setIn(['invitationRedeemEvents', 'content'], action.events);
    case APPEND_INVITATION_REDEEM_EVENTS_DATA:
      return state.setIn(['invitationRedeemEvents', 'content'], [...state.invitationRedeemEvents.content, ...action.events]);
    case SAVE_INVITATION_REDEEM_EVENTS_PAGINATION:
      return state.setIn(['invitationRedeemEvents', 'pagination'], action.pagination);
    case RESET_INVITATION_REDEEM_EVENTS_DATA:
      return state.setIn(['invitationRedeemEvents', 'content'], []).setIn(['invitationRedeemEvents', 'pagination'], {});
    case SET_INVITATION_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_INVITATION_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case UPDATE_INVITATION: {
      const index = _.findIndex(state.data.content, data => data.id === action.invitation.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.invitation);
      }
      return state;
    }
    case RESET_INVITATIONS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case RESET_INVITATIONS_DATA:
      return state.set('data', initialState.data);
    default:
      return state;
  }
}
