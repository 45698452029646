import LockIcon from '@material-ui/icons/LockOpen';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as LocksActions from '../../redux/actions/lock.actions';
import ListLoadingView from '../ListLoadingView.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import SelectableField from './Fields/SelectableInput/SelectableField.jsx';
import { CircularProgress } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  }

  if (!values.type) {
    errors.type = 'required';
  }

  if (!values.color) {
    errors.color = 'required';
  }

  return errors;
};


@reduxForm({ form: 'TagLocksForm', validate })
@connect(state => ({ form: state.form.TagLocksForm }))
class TagLocksForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locksOptions: [],
      lockSelected: null,
    };
  }

  async componentDidMount() {
    this.onFetchLocksAndSetOptions();
  }

  async onFilterLocksTags(name) {
    const { dispatch } = this.props;
    dispatch(LocksActions.setFilter('name', name));
    this.onFetchLocksAndSetOptions();
  }

  async onFetchLocksAndSetOptions() {
    const { dispatch, items } = this.props;
    const tags = await dispatch(LocksActions.fetchLocks());
    const options = _.map(tags, tag => ({ value: tag, label: tag.name }));
    const locksOptions = _.filter(options, smartLockOption => !_.find(items, { id: smartLockOption.value.id }));
    this.setState({ locksOptions });
  }

  onSelectLock(lock) {
    if (lock && lock.value) {
      this.setState({ lockSelected: lock.value });
    }
  }

  onAddLockToTag(lock) {
    const { onAddLockToTag } = this.props;
    onAddLockToTag(lock);
    this.setState({ lockSelected: null });
  }

  render() {
    const { canAddLocks, items, isListLoading, rowElement, pagination, onFetchMoreItems, onDownloadListCSV } = this.props;
    const { locksOptions, lockSelected } = this.state;
    return (
      <Form>
        <FormCardContainer title="lockWithinTag" subtitle="lockWithinTagDescription">
          {canAddLocks ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Field
                name="lockTagSelectedSearch"
                component={SelectableField}
                noCache
                options={locksOptions}
                placeholder={<Entity entity="findLockToAdd" />}
                containerstyle={{ minWidth: 480, marginRight: 10 }}
                onInputChange={value => this.onFilterLocksTags(value)}
                onSelect={tagData => this.onSelectLock(tagData)}
              />
              <MDButton
                disabled={!lockSelected}
                title={<Entity entity="addLockToTag" />}
                style={{ borderRadius: 5 }}
                onClick={() => this.onAddLockToTag(lockSelected)}
              />
              {isListLoading ? <CircularProgress /> : null}
            </div>
          ) : null}
          {!_.isEmpty(items) ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <SaveIcon style={{ marginRight: 5, fontSize: 20, color: '#90CAF9' }} />
              <h4 onClick={() => onDownloadListCSV()} className="label-responsive link-label"><Entity entity="downloadCSV" /></h4>
            </div>
          ) : null}
          {!_.isEmpty(items) ? (
            <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <WarningIcon style={{ color: 'red', marginRight: 15, marginLeft: 10, fontSize: 25 }} />
                <h5 style={{ color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="modifyLockTagWarning" /></h5>
              </div>
              <div style={{ maxHeight: 400, padding: 5, overflowY: 'scroll' }}>
                <InfiniteScroll
                  pageStart={0}
                  useWindow={false}
                  initialLoad={false}
                  loadMore={() => onFetchMoreItems(pagination.number + 1)}
                  hasMore={pagination.number + 1 < pagination.totalPages}
                  loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    {_.map(items, (item, index) => (
                      <div key={item.id}>
                        {rowElement(item, index)}
                      </div>
                    ))}
                    
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <LockIcon style={{ color: '#3f3f3f', marginTop: 3, fontSize: 35 }} />
              <h4 style={{ color: '#3f3f3f', textAlign: 'center' }}>
                <Entity key={canAddLocks} entity={canAddLocks ? 'noLocksInTag' : 'createTagToAssignLocks'} />
              </h4>
            </div>
          )}
        </FormCardContainer>
      </Form>
    );
  }
} 

export default TagLocksForm;
