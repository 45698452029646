import { Button, Modal } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, initialize, submit } from 'redux-form';
import ImageIcon from '@material-ui/icons/Image';
import { RESOURCE_IMAGE_MAP } from '../../../_config/consts';
import * as ModalsActions from '../../../redux/actions/modal.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import OperationalViewHeader from '../../OperationalView/OperationalViewHeader.jsx';
import ImageSelectorForm from '../Bookey/ImageSelectorForm.jsx';
import FormSubmitBar from '../Elements/FormSubmitBar.jsx';
import { showModal } from '@bottega52/redux-modals';

@connect(state => ({ form: state.form.ResourcesForm }))
class ResourceAvatarSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  onSelection(selection) {
    this.setState({ selection });
  }
  
  onImageSubmit(imageMedia) {
    const { input, dispatch } = this.props;
    const isCustomImage = imageMedia && imageMedia.customImage && _.isArray(imageMedia.customImage); 
    if (isCustomImage) {
      input.onChange(imageMedia && imageMedia.customImage && imageMedia.customImage[0] && imageMedia.customImage[0]);
      dispatch(change('ResourcesForm', 'isCustomMedia', true));
    } else {
      input.onChange(imageMedia && imageMedia.media);
      dispatch(change('ResourcesForm', 'isCustomMedia', false));
    }
    this.toggleModal();
  }

  toggleModal() {
    const { dispatch, input } = this.props;
    const { showModal } = this.state;
    if (!showModal) dispatch(initialize('ImageSelectorForm', { media: input.value }));
    this.setState({ showModal: !showModal });
  }

  onFileRejected() {
    const { dispatch } = this.props;
    dispatch(ModalsActions.showModal({
      modalType: 'ERROR_MODAL',
      modalProps: { type: 'FILE_TOO_LARGE' },
    }));
  }

  render() {
    const { input: { value }, form, dispatch } = this.props;
    const { showModal } = this.state;
    let imagePreview = form && form.values && form.values.imageUrl;
    if (imagePreview && _.isArray(imagePreview)) {
      imagePreview = imagePreview[0].preview;
    } else if (imagePreview && _.isString(imagePreview) && !RESOURCE_IMAGE_MAP[imagePreview]) {
      imagePreview = `data:image/jpeg;base64,${form.values.imageUrl}`;
    } else if (imagePreview && !RESOURCE_IMAGE_MAP[imagePreview]) {
      imagePreview = form.values.imageUrl.preview;
    }  else if(imagePreview) {
      imagePreview = RESOURCE_IMAGE_MAP[form.values.imageUrl];
    }
    const resourceName = form && form.values && form.values.name;
    const resourceDescription = form && form.values && form.values.description;
    return (
      <div>
        <Button onClick={() => this.toggleModal()} bsStyle="white" className="card card-avatar avatarSelector">
        {imagePreview ? (
          <img src={imagePreview} style={{ width: 'auto', height: 45 }} />
        ) : (<ImageIcon style={{ fontSize: 30, color: '#bdbdbd' }} />)}
        </Button>
        <div>
          <Modal show={showModal}>
            <OperationalViewHeader
              onClose={() => this.toggleModal()}
              title={<Entity entity="selectImageResource" />}
            />
            <ImageSelectorForm
              title="selectAnImage"
              previewTitle={resourceName}
              previewDescription={resourceDescription}
              onSubmit={file => this.onImageSubmit(file)}
              onDeleteFile={() => dispatch(change('ImageSelectorForm', 'customImage', null))}
              onFileRejected={() => this.onFileRejected()}
              images={RESOURCE_IMAGE_MAP}
            />
            <FormSubmitBar
              onConfirm={() => dispatch(submit('ImageSelectorForm'))}
            />
          </Modal>
        </div>
      </div>
    );
  }
} 

export default ResourceAvatarSelector;
