import { Card, createTheme, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BLUE, GREEN } from '../../_config/consts';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginBottom: 5,
  },
  container: {
    borderRadius: 10,
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 15,
  },
  cardBodyContainer: {
    padding: 20,
    paddingBottom: 10,
  },
  descriptionText: {
    color: '#3f3f3f',
    margin: 0,
    fontWeight: '100',
    fontSize: 16,
    marginTop: 10,
    lineHeight: '1.2em',
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  logo: {
    position: 'absolute',
    height: 27,
    right: 10,
    top: 10,
  },
  media: {
    height: 120,
    backgroundPosition: 'center',
  },
  activeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  activeText: {
    color: GREEN,
    margin: 0,
    marginRight: 5,
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const LuckeyStoreBanner = ({ classes, bannerHTMLContent, onPress }) => {
  if (!bannerHTMLContent) return null;
  return (
    <MuiThemeProvider theme={theme}>
      <Card className="card card-rounded" style={{ borderRadius: 10, height: 200, marginRight: 20, marginLeft: 10, padding: 0, marginBottom: 20 }} onClick={() => onPress()} elevation={3}>
      <div className="storeContentContainer" style={{ marginBottom: 0, height: 220 }} dangerouslySetInnerHTML={{ __html: bannerHTMLContent }} />
      </Card>
    </MuiThemeProvider>
  );
};
export default withStyles(styles)(LuckeyStoreBanner);
