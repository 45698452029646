import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import React from 'react';

const MDRadioButtonView = ({ checked, colorIndex, label, disabled, value, handleChange, labelStyle }) => (
    <FormControlLabel
      checked={checked}
      color={(colorIndex || 'primary')}
      value={value}
      disabled={disabled}
      onChange={e => handleChange(e)}
      control={<Radio color="primary" classes={{root: 'custom-checkbox-root'}} />}
      label={<h5 style={{ ...labelStyle }}>{label}</h5>}
    />
);

export default MDRadioButtonView;
