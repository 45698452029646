
import React from 'react';
import { connect } from 'react-redux';
import ConfigComponent from '../components/Config/ConfigComponent.jsx';
import BaseAlertModal from '../components/Modals/BaseAlertModal.jsx';
import SpinnerOverlay from '../components/Spinner/SpinnerOverlay.jsx';
import ProgressBarOverlay from '../components/LoadingProgressBar/ProgressBarOverlay.jsx';
import SpinnerBackground from '../components/Spinner/SpinnerBackground.jsx';

class AppContainer extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <ConfigComponent>
        <div style={{ backgroundColor: 'white' }}>
          {children}
          <SpinnerOverlay />
          <BaseAlertModal />
          <SpinnerBackground />
          <ProgressBarOverlay />
        </div>
      </ConfigComponent>
    );
  }
}

export default connect()(AppContainer);
