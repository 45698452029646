// @ts-nocheck
import { Card, CircularProgress, createTheme, IconButton, LinearProgress, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import BarChartIcon from '@material-ui/icons/InsertChart';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import {
  FormControl
} from '@sketchpixy/rubix';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { BLUE, GREEN } from '../../_config/consts';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';
import DateRangeView from '../forms/Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    margin: 0,
    marginTop: 15,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginTop: 10,
    marginLeft: 10,
    height: 36,
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  progressAreaOccupancy: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '30px !important',
    width: '30px !important',
  },
  headerText: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
  },
  chartContainer: {
    width: '100%',
    maxHeight: 320,
    minHeight: 320,
    overflowY: 'scroll',
    marginTop: 20,
  },
  refreshHeader: {
    position: 'absolute',
    right: 10,
    top: 5,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '30%',
    marginTop: 10,
    borderRadius: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  tableColumnText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});


class BookeyBestUsersList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    const { users, classes, textColor, isRefreshing } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1 }} elevation={3}>
          <div className={classes.header}>
            <UsersIconCustom style={{ width: 25, color: textColor || '#3f3f3f', marginRight: 10 }} />
            <h4 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="bookeyTopUsers" /></h4>
          </div>
          <div className={classes.chartContainer}>
            {users && !_.isEmpty(users) ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="user" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationsNumber" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationsHoursUsed" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationsHoursLost" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationsHoursPenalty" /></h5></TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                {_.map(users, (user) => {
                  return (
                  <TableRow
                    key={user.id}
                    className={classes.row}
                    hover
                    classes={{
                      selected: classes.rowSelected,
                      hover: classes.rowHovered,
                    }}
                    onClick={() => {}}
                  >
                    <TableCell>
                      <h5 style={{ color: '#3f3f3f' }}>
                        {`${user.user&&user.user.firstname?user.user.firstname:""} ${user.user&&user.user.lastname?user.user.lastname:""} ${!user.user?"-":""}`}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {user.totalReservations}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {(user.totalMinutes/60).toFixed(1)}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {(user.totalLostMinutes/60).toFixed(1)}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {(user.totalPenaltyMinutes/60).toFixed(1)}
                      </h5>
                    </TableCell>
                  </TableRow>
                  )
                })}
              </TableBody>
              </Table>
            ) : null}
          </div>
          {/* 
          <div className={classes.refreshHeader}>
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}:{moment(lastUpdate).format('LLL')}</h5>
            <IconButton
              disabled={isRefreshing}
              style={{ opacity: isRefreshing ? 0.5 : 1 }}
              onClick={() => onRefreshMetric()}
            >
              <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
            </IconButton>
          </div>
          */}
          {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(BookeyBestUsersList);
