import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { RESOURCE_UNAVAILABILITY_REASON_PROPERTIES_MAP } from '../../../_config/consts.js';
import ClockRemoveIconCustom from '../../../components/CustomIcons/ClockRemoveIconCustom.jsx';
import ResourceRow from '../../../components/Resources/ResourceRow.jsx';


export default class UnavailabilityDetailsView extends React.Component {

  render() {
    const { unavailability, dependencyReservation, onShowDependencyReservation } = this.props;
    const resourceUnavailable = unavailability.resource;
    const viewValues = unavailability ? RESOURCE_UNAVAILABILITY_REASON_PROPERTIES_MAP[unavailability.reason] : null;
    return (
      <div>
        {viewValues && viewValues.title ? (
          <h3 style={{ color: '#3f3f3f', marginTop: 10, fontWeight: 'bold' }}><Entity entity={viewValues.title} /></h3>
        ) : null}
        {viewValues && viewValues.description ? (
          <h4 style={{ color: '#3f3f3f' }}><Entity entity={viewValues.description} /></h4>
        ) : null}

        {unavailability.isSharedUnavailability ? (
          <h3 style={{ color: '#3f3f3f', marginTop: 10, fontWeight: 'bold' }}><Entity entity="sharedUnavailabilityExplanation" /></h3>
        ) : null}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ClockRemoveIconCustom style={{ color: '#3f3f3f', marginRight: 10, width: 25 }} />
          <h4 style={{ color: '#3f3f3f',  marginLeft: 5  }}>{`${moment(unavailability.fromDate).format('LL')} / ${moment(unavailability.fromDate).format('HH:mm')} - ${moment(unavailability.toDate).format('HH:mm')}`}</h4>
        </div>
        <ResourceRow
          resource={resourceUnavailable}
        />
        {unavailability.dependencyReservation && dependencyReservation && !unavailability.isSharedUnavailability ? (
          <div onClick={() => onShowDependencyReservation()}>
            <h5 style={{ fontSize: 15, color: '#1b97c2', textDecoration: 'underline', cursor: 'pointer' }}><Entity entity="showDependencyReservation" /></h5>
          </div>
        ) : null}
      </div>
    );
  }
}
