import { Entity } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import * as XLSX from 'xlsx';
import { BACKGROUND_TASK_TYPES, EXPORT_FORMATS, MATCH_TAG_MODE } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import { saveDataToLocalStorage } from '../../_config/utils';
import { APPEND_CREDENTIALS, APPEND_GUESTS_FILTERED_BY_TAGS, APPEND_LOCKS_FILTERED_BY_TAGS, CANCEL_FETCH_GUESTS_BY_TAGS, CANCEL_FETCH_LOCKS_BY_TAGS, FETCH_GUESTS_BY_TAGS, FETCH_LOCKS_BY_TAGS, RESET_CREDENTIALS_DATA, RESET_CREDENTIAL_FILTER, RESET_CREDENTIAL_PAGINATION_DATA, SAVE_CREDENTIAL, SAVE_CREDENTIALS, SELECT_CREDENTIAL, SET_CREDENTIAL_FILTER, SET_CREDENTIAL_GUESTS_PAGINATION_DATA, SET_CREDENTIAL_LOCKS_PAGINATION_DATA, SET_CREDENTIAL_OPERATIONAL_MODE, SET_CREDENTIAL_PAGINATION_DATA, SET_GUESTS_FILTERED_BY_TAGS, SET_LOCKS_FILTERED_BY_TAGS } from './actionTypes/credential';
import * as ModalActions from './modal.actions';
import * as UtilsActions from './utils.actions';

export function saveCredentials(credentials) {
  return {
    type: SAVE_CREDENTIALS,
    credentials,
  };
}
export function resetCredentialsData() {
  return { type: RESET_CREDENTIALS_DATA };
}
export function appendCredentials(credentials) {
  return {
    type: APPEND_CREDENTIALS,
    credentials,
  };
}

export function saveCredential(credential) {
  return {
    type: SAVE_CREDENTIAL,
    credential,
  };
}

export function selectCredential(credential) {
  return {
    type: SELECT_CREDENTIAL,
    credential,
  };
}

export function setOperationalMode(value) {
  return {
    type: SET_CREDENTIAL_OPERATIONAL_MODE,
    value,
  };
}

export function setGuestsFilteredByTags(guests) {
  return {
    type: SET_GUESTS_FILTERED_BY_TAGS,
    guests: _.map(guests, guest => formatter.formatInputData(formatter.GUEST, guest)),
  };
}

export function appendGuestsFilteredByTags(guests) {
  return {
    type: APPEND_GUESTS_FILTERED_BY_TAGS,
    guests,
  };
}

export function setLocksFilteredByTags(locks, index = 0) {
  return {
    type: SET_LOCKS_FILTERED_BY_TAGS,
    index: index,
    locks,
  };
}

export function appendLocksFilteredByTags(locks, index = 0) {
  return {
    type: APPEND_LOCKS_FILTERED_BY_TAGS,
    index: index,
    locks,
  };
}

export function fetchLocksByTags(tags, lockTagMatchingMode, page = 0, append = false, index = 0) {
  return {
    type: FETCH_LOCKS_BY_TAGS,
    tags,
    index,
    lockTagMatchingMode,
    page,
    append,
  };
}

export function setCredentialsPaginationData(pagination) {
  return {
    type: SET_CREDENTIAL_PAGINATION_DATA,
    pagination,
  };
}

export function resetCredentialsPaginationData() {
  return {
    type: RESET_CREDENTIAL_PAGINATION_DATA,
  };
}

export function setGuestsFilteredByTagsPaginationData(pagination) {
  return {
    type: SET_CREDENTIAL_GUESTS_PAGINATION_DATA,
    pagination,
  };
}

export function setLocksFilteredByTagsPaginationData(pagination, index) {
  return {
    type: SET_CREDENTIAL_LOCKS_PAGINATION_DATA,
    index: index || 0,
    pagination,
  };
}

export function cancelFetchLocksByTags() {
  return {
    type: CANCEL_FETCH_LOCKS_BY_TAGS,
  };
}

export function setCredentialFilter(field, value) {
  return {
    type: SET_CREDENTIAL_FILTER,
    field,
    value,
  };
}

export function resetCredentialFilters() {
  return { type: RESET_CREDENTIAL_FILTER };
}

export function fetchGuestsByTags(tags, userTagMatchingMode, page = 0, append = false) {
  return {
    type: FETCH_GUESTS_BY_TAGS,
    tags,
    userTagMatchingMode,
    page,
    append,
  };
}

export function cancelFetchGuestsByTags() {
  return {
    type: CANCEL_FETCH_GUESTS_BY_TAGS,
  };
}

export function fetchSmartphoneCredentialRules(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    const filters = getState().credentials.filters;
    try {
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await RestService.fetchSmartphoneRules(params);
      if (response.data && response.data.content) {
        const formattedCredential = _.map(response.data.content, credential => formatter.formatInputData(formatter.CREDENTIAL, credential));
        if (append) {
          dispatch(appendCredentials(formattedCredential));
        } else {
          dispatch(saveCredentials(formattedCredential));
        }
        dispatch(setCredentialsPaginationData(_.omit(response.data, 'content')));
        return formattedCredential;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchLocksByTagsNoDebounce(tags, lockTagMatchingMode, index = 0) {
  return async (dispatch, getState) => {
    try {
      const params = {
        tagIds: _.map(tags, tag => tag.id),
        lockTagMatchingMode,
      };
      const response = await RestService.fetchLocksByTags(params);
      if (response.data && response.data.content) {
        const locks = response.data.content;
        const pagination = _.omit(response.data, 'content');
        dispatch(setLocksFilteredByTagsEpic(locks, index, pagination, false));
      }
    } catch (error) {

    }
  }
}

export function createCredential(values) {
  return async (dispatch, getState) => {
    try {
      const formattedCredential = formatter.formatOutputData(formatter.CREDENTIAL, values);
      const response = await RestService.createCredential(formattedCredential);
      await dispatch(fetchSmartphoneCredentialRules());
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialCreated' }} /></h6>),
        },
      }));
      dispatch(setOperationalMode(false));
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function updateCredential(values) {
  return async (dispatch, getState) => {
    try {
      const formattedCredential = formatter.formatOutputData(formatter.CREDENTIAL, values);
      const response = await RestService.updateCredential(formattedCredential.id, formattedCredential);
      await dispatch(fetchSmartphoneCredentialRules());
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialUpdated' }} /></h6>),
        },
      }));
      dispatch(setOperationalMode(false));
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchLocksByTagsForm(form, index, page = 0, append = false) {
  return (dispatch, getState) => {
    let lockTagMatchingMode = MATCH_TAG_MODE.EVERY_TAG;
    if (form && form.values && form.values.lockTagMatchingMode) {
      lockTagMatchingMode = form.values.lockTagMatchingMode;
    }
    let tags = form && form.values && form.values.lockTags ? form.values.lockTags : [];
    if (index > 0) {
      tags = form && form.values ? form.values.additionalTimeProfiles[index - 1].lockTags : [];
    }
    dispatch(fetchLocksByTags(tags, lockTagMatchingMode, page, append, index));
  };
}

export function fetchGuestsByTagsForm(form, page = 0, append = false) {
  return (dispatch, getState) => {
    let userTagMatchingMode = MATCH_TAG_MODE.EVERY_TAG;
    if (form && form.values && form.values.guestTagMatchingMode) {
      userTagMatchingMode = form.values.guestTagMatchingMode;
    }
    const tags = form && form.values && form.values.guestTags ? form.values.guestTags : [];
    dispatch(fetchGuestsByTags(tags, userTagMatchingMode, page, append));
  };
}

export function fetchLocksByTagsSmartCredentialsForm(page = 0, append = false) {
  return (dispatch, getState) => {
    const state = getState();
    const { form: { CredentialForm: form } } = state;

    dispatch(fetchLocksByTagsForm(form, 0, page, append));
  };
}

export function fetchGuestsByTagsSmartCredentialsForm(page = 0, append = false) {
  return (dispatch, getState) => {
    const state = getState();
    const { form: { CredentialForm: form } } = state;
    dispatch(fetchGuestsByTagsForm(form, page, append));
  };
}

export function fetchLocksByTagsStardardCredentialsForm(type, index, page = 0, append = false) {
  return (dispatch, getState) => {
    const state = getState();
    let form;
    switch (type) {
      case 'CARD':
        form = state.form.CardCredentialForm;
        break;
      case 'HYPER_KEY':
        form = state.form.HyperKeyCredentialsForm;
        break;
      case 'CAMERA':
        form = state.form.CameraLocksAssociationForm;
        break;
      case 'PIN':
        form = state.form.PinCredentialsForm;
        break;
      case 'ACCESS_PROFILE':
          form = state.form.AccessProfileForm;
          break;
      default:
        form = state.form.CardCredentialForm;
        break;
    }

    dispatch(fetchLocksByTagsForm(form, index, page, append));
  };
}

export function fetchGuestsByTagsStardardCredentialsForm(type, page = 0, append = false) {
  return (dispatch, getState) => {
    const state = getState();
    let form;
    switch (type) {
      case 'CARD':
        form = state.form.CardCredentialForm;
        break;
      case 'HYPER_KEY':
        form = state.form.HyperKeyCredentialsForm;
        break;
      case 'PIN':
        form = state.form.PinCredentialsForm;
        break;
      case 'ITEM':
        form = state.form.ItemRegistryForm;
        break;
      default:
        form = state.form.CardCredentialForm;
        break;
    }

    dispatch(fetchGuestsByTagsForm(form, page, append));
  };
}

export function setGuestsFilteredByTagsEpic(guests, pagination, append = false) {
  return (dispatch, getState) => {
    if (append) {
      dispatch(appendGuestsFilteredByTags(guests));
    } else {
      dispatch(setGuestsFilteredByTags(guests));
    }
    dispatch(setGuestsFilteredByTagsPaginationData(pagination));
  };
}

export function setLocksFilteredByTagsEpic(locks, index, pagination, append = false) {
  return (dispatch, getState) => {
    if (append) {
      dispatch(appendLocksFilteredByTags(locks, index));
    } else {
      dispatch(setLocksFilteredByTags(locks, index));
    }
    dispatch(setLocksFilteredByTagsPaginationData(pagination, index));
  };
}

export function deleteCredential(credentialRuleId) {
  return (dispatch, getState) => {
    RestService.deleteCredential(credentialRuleId)
    .then((response) => {
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialDeleted' }} /></h6>),
        },
      }));
      dispatch(fetchSmartphoneCredentialRules());
      dispatch(setOperationalMode(false));
    })
    .catch(() => {
      
    });
  };
}

export function getCredentialRuleDetails(credentialId) {
  return async (dispatch, getState) => {
    try {
      const credentialResponse = await RestService.fetchCredentialRuleDetails(credentialId);
      if (credentialResponse && credentialResponse.data) {
        return formatter.formatInputData(formatter.CREDENTIAL, credentialResponse.data);
      }
    } catch (error) {

    }
  };
}

export function setCredentialsViewMode(viewMode) {
  saveDataToLocalStorage('credentialsViewMode', viewMode);
}

export function fetchAllCredentialsForExport(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().credentials.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_CREDENTIALS_CSV,
      title: 'creatingSmartphoneCredentialsExportFile',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        ...filters,
      };
      let credentialResponse = await RestService.fetchCredentialRules(params);
      if (credentialResponse && credentialResponse.data && credentialResponse.data.content && !_.isEmpty(credentialResponse.data.content)) {
        let formattedCredentials = _.map(credentialResponse.data.content, credential => formatter.formatInputData(formatter.CREDENTIAL, credential));
        const credentials = [...formattedCredentials];
        let pagination = _.omit(credentialResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          credentialResponse = await RestService.fetchCredentialRules(params);
          formattedCredentials = _.map(credentialResponse.data.content, credential => formatter.formatInputData(formatter.CREDENTIAL, credential));
          credentials.push(...formattedCredentials);
          pagination = _.omit(credentialResponse.data, 'data');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { credentials, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function exportCredentials(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { credentials, stopFetching } = await dispatch(fetchAllCredentialsForExport());
      if (!stopFetching) {
        const exportData = [];
        _.each(credentials, (credential) => {
          exportData.push({
            Name: credential.name,
            Description: credential.description,
            'User Tags': _.map(credential.guestTags, tag => tag.name).join(', '),
            'Smart Locks Tags': _.map(credential.lockTags, tag => tag.name).join(', '),
            'Valid From Date': moment(credential.dateInterval.from).format('DD MMMM YYYY'),
            'Valid From Time': moment(credential.timeIntervalFrom).format('LT'),
            'Valid To Date': moment(credential.dateInterval.to).format('DD MMMM YYYY'),
            'Valid To Time': moment(credential.timeIntervalTo).format('LT'),
            Days: _.map(credential.daysOfTheWeek, weekday => moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday())).join(', '),
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { credentials: ws }, SheetNames: ['credentials'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Credentials_Export.${fileExtension}`);
        return credentials;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}