import _ from 'lodash';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_API_KEY } from '../_config/consts';

export function initialize() {
  const plantName = window && window.location && window.location.hostname && window.location.hostname.replace('.jago.cloud', '');
  const isStagingSystem = _.includes(plantName, 'staging') || _.includes(plantName, 'localhost');
  let initOptions = { serverZone: 'EU', minIdLength: 1 };
  if (isStagingSystem) {
    initOptions = { ...initOptions, logLevel: amplitude.Types.LogLevel.Debug };
  
  }
  amplitude.init(AMPLITUDE_API_KEY, initOptions);
}

export function logEvent(eventType, eventProperties) {
  const event = {
    event_type: eventType,
    event_properties: eventProperties,
  };
  amplitude.track(event)
}

export function reset() {
  amplitude.reset();
}