import _ from 'lodash';
import { Observable } from 'rxjs';
import {
  FETCH_LOCKS_BY_TAGS,
  CANCEL_FETCH_LOCKS_BY_TAGS,
  FETCH_GUESTS_BY_TAGS,
  CANCEL_FETCH_GUESTS_BY_TAGS,
} from '../redux/actions/actionTypes/credential';

import * as RestService from '../_config/rest';
import {
  setLocksFilteredByTagsEpic,
  setGuestsFilteredByTagsEpic,
} from '../redux/actions/credential.actions';
import { rxFetch } from './utils';

export const fetchLocksByTagsEpic = (action$, store) =>
  action$.ofType(FETCH_LOCKS_BY_TAGS)
    .debounceTime(500)
    .switchMap((action) => {
      const { lockTagMatchingMode, page, append, index } = action;
      let tags = action.tags;
      if (!_.isArray(tags)) {
        tags = [tags];
      }

      if (tags && tags.length) {
        const params = {
          tagIds: _.map(tags, tag => tag.id || tag),
          page,
          lockTagMatchingMode,
        };

        return rxFetch(RestService.fetchLocksByTags(params))
          .map((response) => {
            if (response.data && response.data.content) {
              const locks = response.data.content;
              const pagination = _.omit(response.data, 'content');
              return setLocksFilteredByTagsEpic(locks, index, pagination, append);
            }
            return setLocksFilteredByTagsEpic([], index);
          })
          .takeUntil(action$.ofType(CANCEL_FETCH_LOCKS_BY_TAGS))
          .catch(() => Observable.empty());
      }
      return Observable.of(setLocksFilteredByTagsEpic([], index));
    });

export const fetchGuestsByTagsEpic = (action$, store) =>
  action$.ofType(FETCH_GUESTS_BY_TAGS)
    .debounceTime(500)
    .switchMap((action) => {
      const { userTagMatchingMode, page, append } = action;
      let tags = action.tags;
      if (!_.isArray(tags)) {
        tags = [tags];
      }
      if (tags && tags.length) {
        const params = {
          tagIds: _.map(tags, tag => tag.id || tag),
          page,
          append,
          userTagMatchingMode,
        };
        

        return rxFetch(RestService.fetchUsersByTags(params))
          .map((response) => {
            if (response.data && response.data.content) {
              const users = response.data.content;
              const pagination = _.omit(response.data, 'content');
              return setGuestsFilteredByTagsEpic(users, pagination, append);
            }
            return setGuestsFilteredByTagsEpic([]);
          })
          .takeUntil(action$.ofType(CANCEL_FETCH_GUESTS_BY_TAGS))
          .catch(() => Observable.empty());
      }
      return Observable.of(setGuestsFilteredByTagsEpic([]));
    });
