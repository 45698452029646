import { Card } from '@material-ui/core';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import UsersIcon from '@material-ui/icons/People';
import LockIcon from '@material-ui/icons/LockOpen';
import React from 'react';
import { connect } from 'react-redux';
import LogEventsList from '../LogEvents/LogEventsList.jsx';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import GuestsIcon from '@material-ui/icons/PeopleOutline';
import { createChartDataFromLogs } from '../../_config/utils.js';
import ScrollableBarChart from '../Charts/ScrollableBarChart.jsx';
import SmartLocksEventsIconCustom from '../CustomIcons/SmartLocksEventsIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  cardContainer: {
    paddingBottom: 20,
    marginTop: 0,
    marginRight: 20,
    marginLeft: 15,
    marginBottom: 20,
    borderRadius: 10,
    padding: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  listContainer: {
    maxHeight: 300,
    overflow: 'scroll',
  }
});
@connect((state) => ({ viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class LockDashboardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  selectTab(tabIndex) {
    this.setState({ selectedTab: tabIndex });
  }

  render() {
    const { lockData, onLockClick, textColor, classes } = this.props;
    const { selectedTab } = this.state;
    const chartData = lockData && lockData.events ? createChartDataFromLogs(lockData.events): null;
    const hideChart = !chartData || _.isEmpty(chartData);
    return (
      <div style={{ position: 'relative' }}>
        <Card style={{ padding: 10, position: 'absolute', left: 0, right: 0, backgroundColor: '#F8F9FA', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {lockData ? (
            <div style={{ display: 'flex', paddingLeft: 15, alignItems: 'center' }}>
              <LockIcon style={{ fontSize: 35, color: '#3f3f3f' }} />
              <div style={{ marginLeft: 20 }}>
                <h3 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}>
                  {`${lockData.name}`}
                </h3>
                <h4 style={{ margin: 0, marginTop: 10, color: '#3f3f3f' }}>
                  {`${lockData.serialNumber || '--'}`}
                </h4>
              </div>
            </div>
          ) : null}
          <MDButton
            icon={<LockIcon style={{ color: 'white', fontSize: 25, marginLeft: 10 }} />}
            containerStyle={{ width: '25%' }}
            title={<Entity entity="lockDetails" />}
            onClick={() => onLockClick(lockData.lockId)}
          />
        </Card>
        {!hideChart ? (
          <Card style={{ top: 95, paddingTop: 10, left: 0, right: 0, backgroundColor: 'white', position: 'absolute' }}>
            <ScrollableBarChart
              chartMaxHeight={150}
              chartMaxWidth={window.screen.width / 2}
              data={_.cloneDeep(chartData)}
              dataSetSize={24}
              showLegend={false}
            />
          </Card>
        ) : null}
        <div style={{ top: hideChart ? 100: 310, paddingTop: 10, left: 0, right: 0, position: 'absolute' }}>
          <AppBar position="sticky" color="default">
            <Tabs
              value={selectedTab}
              textColor="primary"
              indicatorColor="primary"
              scrollable
              onChange={(e, index) => this.selectTab(index)}
              style={{ paddingLeft: 25 }}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab icon={<SmartLocksEventsIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="dailyAccesses" /></h5>} />
              <Tab icon={<UsersIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="dailyUniqueUsersLock" /></h5>} />
            </Tabs>
          </AppBar>
        </div>
        {lockData && selectedTab === 1 ? (
          <div style={{ paddingTop: hideChart ? 200: 400, overflow: 'scroll' }}>
            <Card className={classes.cardContainer}>
              <div className={classes.header}>
                <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f' }}><Entity entity="dailyUniqueUsersLock" /></h3>
                <div className={classes.headerTextIconContainer}>
                  <UsersIconCustom style={{ width: 35, color: textColor || '#3f3f3f' }} />
                  <h2 className={classes.titleText} style={{ marginTop: 0, marginLeft: 10, color: textColor || '#3f3f3f' }}>{_.size(lockData.users)}</h2>
                </div>
              </div>
              <div className={classes.listContainer}>
                <List component="nav" aria-label="main mailbox folders">
                  {_.map(lockData.users, user => (
                    <ListItem
                      divider
                      key={user.id}
                    >
                      <div className={classes.guestRowContainer}>
                        <h5 style={{ color: '#3f3f3f' }}>{`${user.firstname || '--'} ${user.lastname || '--'} - ${user.email || '--'}`}</h5>
                      </div>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Card>
          </div>
        ) : null}
        {lockData && selectedTab === 0 ? (
          <LogEventsList
            eventType="DOOR"
            listStyle={{ paddingTop: hideChart ? 50 : 420, overflow: 'scroll' }}
            events={lockData && lockData.events}
            pagination={{ pagination: { number: 0, last: true } }}
            onLockClick={(lockId) => onLockClick(lockId)}
          />
        ) : null}
        
      </div>
    );
  }
}


export default withStyles(styles)(LockDashboardDetails);
