import _ from 'lodash';
import React from 'react';
import GatewayNotificationWaitingView from './GatewayNotificationWaitingView.jsx';
import GatewayNotificationFailedView from './GatewayNotificationFailedView.jsx';
import GatewayNotificationAppliedView from './GatewayNotificationAppliedView.jsx';

const statusViewComponents = {
  WAITING: GatewayNotificationWaitingView,
  IN_TRANSIT: GatewayNotificationWaitingView,
  RECEIVED: GatewayNotificationWaitingView,
  APPLIED: GatewayNotificationAppliedView,
  FAILED: GatewayNotificationFailedView,
};

const GatewayNotificationStatusView = (props) => {
  const { notification } = props;
  if (!notification || _.isEmpty(notification)) {
    return null;
  }
  const Component = statusViewComponents[notification.status];
  return <Component key={notification.id} notification={notification} {...props} />;
};

export default GatewayNotificationStatusView;
