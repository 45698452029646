import { Modal } from '@sketchpixy/rubix';
import React from 'react';
import { BLUE, RED } from '../../_config/consts';
import MDButton from '../MDButton/MDButton.jsx';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Cancel';

export default class ContentModal extends React.Component {

  render() {
    const { title, content, onConfirm, hideHeader, onCancel, onOutsideClick, modalContainerStyle, onConfirmText, onCancelText, hideModal, modalCustomClass } = this.props;
    return (
      <Modal
        show
        className={`alert-container full-height-modal ${modalCustomClass}`}
        onHide={onOutsideClick ? () => onOutsideClick() : undefined}
      >
        <div style={{ padding: 10, ...modalContainerStyle }}>
          {!hideHeader ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <h2 style={{ color: '#3f3f3f', margin: 10, fontWeight: 'bold' }}>{title}</h2>
              <IconButton onClick={() => hideModal()}>
                <CloseIcon style={{ color: '#3f3f3f', fontSize: 35 }} />
              </IconButton>
            </div>
          ) : null}
          {content}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            {onConfirm && (
              <MDButton
                containerStyle={{ marginTop: 0 }}
                title={onConfirmText || 'OK'}
                backgroundColor={BLUE}
                style={{ marginTop: 0 }}
                onClick={() => onConfirm()}
              />
            )}
            {onCancel && (
              <MDButton
                containerStyle={{ marginTop: 0 }}
                title={onCancelText || 'Cancel'}
                backgroundColor={RED}
                onClick={() => onCancel()}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
