import WarningIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/HelpOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { BLUE } from '../_config/consts.js';
import MDButton from './MDButton/MDButton.jsx';

export default class EmptyView extends Component {
  render() {
    const {
      title,
      subtitle,
      onNewEntity,
      newEntityTitle,
      iconName,
      icon,
      onShowInfo,
      showInfoTitle,
      externalContainerStyle,
      containerStyle
    } = this.props;
    return (
      <ReactCSSTransitionGroup
        style={{ paddingBottom: 50, ...externalContainerStyle }}
        transitionName="fadeInOut"
        transitionEnterTimeout={0}
        transitionLeaveTimeout={0}
      >
        <div className="flex-column-center no-entity" style={{ ...containerStyle }}>
          {icon ? icon : <WarningIcon style={{ color: '#a5a3a3', fontSize: 60 }} />}
          <h3 className="text-center" style={{ fontWeight: 'bold', margin: 5, color: '#a5a3a3' }}><Entity entity={title} /></h3>
          {subtitle && <h4 className="text-center" style={{ color: '#a5a3a3', margin: 5 }}><Entity entity={subtitle} /></h4>}
          {onNewEntity ?
            <MDButton
              onClick={() => onNewEntity()}
              title={<Entity entity={newEntityTitle || 'newEntity'} />}
            /> : null}
          {onShowInfo ?
            <MDButton
              onClick={() => onShowInfo()}
              backgroundColor={BLUE}
              containerStyle={{ margin: 0, marginTop: 10 }}
              icon={<InfoIcon style={{ marginLeft: 10, fontSize: 20 }} />}
              title={<Entity entity={showInfoTitle || 'howItWorks'} />}
            /> : null}
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}

