import React from 'react';

import AdminEventRow from './AdminRows/AdminEventRow.jsx';
import DoorEventRow from './DoorEventRows/DoorEventRow.jsx';


const eventRows = {
  ADMIN: AdminEventRow,
  DOOR: DoorEventRow,
};

const EventRow = (props) => {
  const { type } = props;
  if (!type) return null;
  const Component = eventRows[type];
  return Component ? <Component {...props} /> : null;
};

export default EventRow;
