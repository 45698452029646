import { InfoOutlined } from '@material-ui/icons';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';

const StoreItemIncompatibleView = ({ requestToQuote, onRequestQuotation, onRequestDemo, containerStyle, onRequestActivation }) => (
  <div style={{ marginTop: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 15, paddingTop: 20, paddingBottom: 10, ...containerStyle }}>
    <InfoOutlined style={{ fontSize: 30 }} />
    <h4 style={{ textAlign: 'center', lineHeight: '1.2em', margin: 5 }}><Entity entity="storeItemNotCompatibleDescription" /></h4>
  </div>
);
export default StoreItemIncompatibleView;
