
import React from 'react';

const SubcompanyEventIconCustom = ({ style, className }) => (
  <svg style={{ ...style }} className={className} viewBox="0 0 24 24">
    <path fill="currentColor" d="M3,13H15V11H3M3,6V8H21V6M3,18H9V16H3V18Z" />
  </svg>
);

export default SubcompanyEventIconCustom;
