import React from 'react';
import moment from 'moment';
import InvitationsIcon from '@material-ui/icons/InsertLink';
import WarningIcon from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

/* The first control is due to the fact that when editing a card the system create two log with only
the entityID (that is the id of the credential associated to the standard device).
Moreover every time a card is edited there are two logs, one for the creation of the credential and one 
for deletion. This logs show no usefull information and are misleading, so we need to remove them

*/
export default class InvitationCreationEventRow extends React.Component {
  render() {
    const { event: { id, actorId, timestamp, data: { actor, entity } }, isAnonymized } = this.props;
    return (
      <div style={{ minHeight: 110, padding: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <InvitationsIcon style={{ fontSize: 28, marginRight: 25, color: '#3f3f3f' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>
              <Entity key={id} entity="invitationCreation" />
            </h3>
            <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0, marginTop: 10 }}>
              {isAnonymized ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 20 }} />
                  <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: '100', margin: 0 }}>
                    <Entity
                      key={id}
                      entity="adminEventRowAnonymizedDescription"
                    />
                  </h4>
                </div>
              ) : (
                <Entity
                  key={id}
                  entity="invitationCreationDescription"
                  data={{
                    actor: actorId ? `${actor.firstname || ''} ${actor.lastname || ''} ${actor.email || ''}` : '---',
                    entity: `${entity.name || entity.entityId || ''}`,
                  }}
                />
              )}
            </h4>
          </div>
        </div>
        <h4 style={{ minWidth: 240, textAlign: 'right', marginTop: 10, color: '#3f3f3f' }}>{moment(timestamp).format('LLL')}</h4>
      </div>
    );
  }
}