import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Row,
  Checkbox,
  HelpBlock,
} from '@sketchpixy/rubix';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

import CustomField from './Fields/CustomField.jsx';
import TagCategorySelectorField from './Fields/TagCategorySelectorField.jsx';
import ColorSelectorField from './Fields/ColorSelectorField.jsx';

import { fetchGuestTags, fetchLockTags } from '../../_config/rest';
import MDCheckBoxField from './Fields/MDCheckBox/MDCheckBoxField.js';
import { LANGUAGES } from '../../_config/consts.js';

const validate = values => {
  const errors = {};
  if (!values.dateFrom) {
    errors.dateFrom = 'required';
  }

  if (!values.timeFrom) {
    errors.timeFrom = 'required';
  }

  if (!values.dateTo) {
    errors.dateTo = 'required';
  }

  if (!values.timeTo) {
    errors.timeTo = 'required';
  }

  if (values.dateFrom && values.dateTo && values.timeFrom && values.timeTo) {
    const toTime = moment(values.timeTo);
    const fromTime = moment(values.timeFrom);
    const toDate = moment(values.dateTo).hour(toTime.hour()).minute(toTime.minute()).second(0);
    const fromDate = moment(values.dateFrom).hour(fromTime.hour()).minute(fromTime.minute()).second(0);
    if (toDate.isSameOrBefore(fromDate)) {
      errors.dateTo = 'Interval not valid';
    }
  }

  return errors;
};

const disabledMinutes = _.pull(_.range(60), 0, 15, 30, 45);

@reduxForm({ form: 'TagDatesForm', validate })
@connect(state => ({ form: state.form.TagDatesForm, language: state.settings.language }))
class TagDatesForm extends React.Component {
  render() {
    const { form, language, categories } = this.props;
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    return (
      <Form>
        <h4 style={{ color: '#3f3f3f' }}>
          <Entity entity="timeTagDescription" />
        </h4>
        <Row>
          <Col xs={12}>
            <Field
              name="timedTagEnabled"
              component={MDCheckBoxField}
              label={<Entity entity="enableTimedTag" />}
            />
          </Col>
        </Row>
        {form.values.timedTagEnabled &&
          <span>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ width: '20%', marginRight: 40 }}>
                <Entity
                  componentClass={Field}
                  name="dateFrom"
                  type="airbnbDate"
                  entity="insertName"
                  title={<Entity entity="dateFrom" />}
                  component={CustomField}
                  mandatory
                />
              </div>
              <div style={{ marginTop: 5 }}>
                <Entity
                  componentClass={Field}
                  name="timeFrom"
                  title={<Entity entity="timeIntervalFrom" />}
                  type="antdTimePicker"
                  is12HoursFormat={is12HoursFormat}
                  disabledMinutes={() => disabledMinutes}
                  component={CustomField}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
              <div style={{ minWidth: '20%', marginRight: 40 }}>
                <Entity
                  componentClass={Field}
                  name="dateTo"
                  type="airbnbDate"
                  entity="insertName"
                  title={<Entity entity="dateTo" />}
                  component={CustomField}
                  mandatory
                />
              </div>
              <div style={{ marginTop: 5 }}>
                <Entity
                  componentClass={Field}
                  name="timeTo"
                  title={<Entity entity="timeIntervalTo" />}
                  type="antdTimePicker"
                  is12HoursFormat={is12HoursFormat}
                  disabledMinutes={() => disabledMinutes}
                  component={CustomField}
                />
              </div>
            </div>
          </span>
        }
      </Form>
    );
  }
} 

export default TagDatesForm;
