import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const styles = theme => ({
  textField: {
    width: '100%',
  },
  textStyle: {
    fontSize: 20,
    marginTop: 4,
  },
  floatingText: {
    fontSize: 22,
    margin: 0,
  },
});


const theme = createTheme({
  palette: {
    primary: { 500: '#4DB6AC' },
  },
  typography: { useNextVariants: true },
});

class MDTextInputView extends React.Component {

  
  handleChange(event) {
    event.preventDefault();
    const { onChange } = this.props;
    if (onChange) onChange(event.currentTarget.value);
  }
  render() {
    const { classes, label } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <TextField
          className={classes.textField}
          label={label || 'Text'}
          margin="normal"
          {...this.props}
          style={{ width: '100%' }}
          onChange={event => this.handleChange(event)}
          InputLabelProps={{
            className: classes.floatingText,
            input: classes.textStyle,
          }}
          InputProps={{
            classes: {
              input: classes.textStyle,
            },
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(MDTextInputView);
