import WarningIcon from '@material-ui/icons/ErrorOutline';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SelectableRow from './SelectableRow.jsx';
import ListLoadingView from '../../../ListLoadingView.jsx';

export default class SelectableRowField extends React.Component {

  onHandleChange(e, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(e);
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }
  onClick(o) {
    const { input, isSingleChoice } = this.props;
    const checkedItems = isSingleChoice ? null : (input.value || []);
    if (_.includes(checkedItems, o.id) || _.isEqual(checkedItems, o.id)) { // if already selected
      this.onHandleChange(_.filter(checkedItems, i => i !== o.id), input);
    } else if (isSingleChoice) {
      this.onHandleChange(o.id, input);
    } else {
      this.onHandleChange([...checkedItems, o.id], input);
    }
  }

  render() {
    const {
      options,
      input,
      meta,
      title,
      placeholder,
      emptyOptionsText,
      mandatory,
      containerStyle,
      listStyle,
      listContainerStyle,
      onFetchMoreItems,
      rowStyle,
    } = this.props;
    const checkedItems = input.value || [];
    return (
      <div style={{ ...containerStyle }}>
        {title ?
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h5 style={[{ fontSize: 15, color: 'black' }, meta.touched && meta.error && { color: '#EF5350' }]}>
              {title}
            </h5>
            {mandatory && <h5 style={{ color: 'red' }}>*</h5>}
          </div>
          : null}
        {placeholder ?
          <h5 style={[{ fontSize: 14 }, meta.touched && meta.error && { color: '#EF5350' }]}>
            {placeholder}
          </h5>
          : null}
        <div style={{ marginTop: 10, ...listContainerStyle }}>
          {options && !_.isEmpty(options.content) ?
            <div style={{ marginTop: 20, maxHeight: 400, overflowY: 'scroll', ...listStyle }}>
              <InfiniteScroll
                pageStart={0}
                key={options.content.id}
                useWindow={false}
                initialLoad={false}
                loadMore={options.pagination && !options.pagination.last ? () => onFetchMoreItems(options.pagination.number + 1) : () => false}
                hasMore={options.pagination && !options.pagination.last}
                loader={<ListLoadingView />}
              >
                {_.map(options.content, option => (
                  <SelectableRow
                    key={option.id}
                    hasError={meta.touched && meta.error}
                    customOptionElement={option.customOptionElement}
                    rowStyle={rowStyle}
                    onClick={() => this.onClick(option)}
                    isChecked={_.includes(checkedItems, option.id) || _.isEqual(checkedItems, option.id)}
                    title={option.name}
                    type={option.type && option.type.name ? option.type.name : option.type}
                    resourceImage={option.imageUrl}
                    resourceMedia={option.media}
                  />
                ))}
              </InfiniteScroll>
            </div>
            :
            <div>
              {emptyOptionsText ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                  <WarningIcon style={{ marginRight: 10, fontSize: 20, marginTop: 2, color: meta.touched && meta.error ? '#EF5350' : '#3f3f3f' }} />
                  <h4 style={{ color: meta.touched && meta.error ? '#EF5350' : '#3f3f3f' }}>{emptyOptionsText}</h4>
                </div>
              ): null}
            </div>
          }
        </div>
      </div>
    );
  }
}
