import React from 'react';
import { withStyles } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { localizeHelpCenterLink } from '../../../_config/utils';


const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  titleText: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'left',
  },
  sectionText: {
    margin: 0,
    marginRight: 5,
    fontSize: 16,
    color: '#3f3f3f',
    lineHeight: 1.4,
    textAlign: 'left',
  },
  exampleContainer: {
    marginTop: 20,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  exampleText: {
    marginLeft: 12,
    marginTop: 10,
    fontSize: 16,
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

const LocksTagsExplanationView = ({ classes }) => {
  const helpURL = localizeHelpCenterLink('smart-locks-tags-and-groups');
  return (
    <div style={{ marginLeft: 13, marginBottom: 30 }}>
      <h2 className={classes.titleText}><Entity entity="whatIsIt" /></h2>
      <h2 className={classes.sectionText}><Entity entity="whatIsItTagExplanationLocks" /></h2>
      <h2 className={classes.titleText}><Entity entity="howItWorks" /></h2>
      <h2 className={classes.sectionText}><Entity entity="howItWorksTagExplanationLocks" /></h2>
      <div className={classes.exampleContainer}>
        <img src='/imgs/common/TagExampleLocks.png' alt="tagExample" style={{ width: '70%', border: '2px solid #f0f0f0', padding: 5 }} />
        <h2 className={classes.exampleText}><Entity entity="tagExampleDescriptionLocks" /></h2>
      </div>
      <h2 className={classes.titleText}><Entity entity="warning" /></h2>
      <h2 className={classes.sectionText}><Entity entity="tagExplanationWarningLocks" /></h2>
      <h5
        className="link-label"
        style={{ margin: 'auto', width: 'fit-content', marginTop: 20, fontSize: 16, textDecoration: 'underline' }}
        onClick={() => window.open(helpURL)}
      >
        <Entity entity="findOutMore" />
      </h5>
    </div>
  );
}
export default withStyles(styles)(LocksTagsExplanationView);