import Card from '@material-ui/core/Card';
import TimeIcon from '@material-ui/icons/AccessTime';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import LockIcon from '@material-ui/icons/LockOpen';
import Divider from '@material-ui/core/Divider';
import MobileIcon from '@material-ui/icons/PhoneIphone';
import { Col, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { SUBSCRIPTION_TYPES, RESERVATION_STATUSES_PARAMETERS, CARDS_CREDENDIAL_RULES_STATUSES } from '../../../_config/consts';
import { elaborateCredentialDaysArray, getLockImage } from '../../../_config/utils';
import CardCredentialStatus from '../CardCredentialStatus.jsx';

@connect((state) => ({ settings: state.settings }))
class GuestSmartphoneCredentialRow extends React.Component {
  render() {
    const { credential, onSelectCredentialRule } = this.props;
    const days = elaborateCredentialDaysArray(credential);
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
      selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
    }));
    const additionalDay = credential.additionalTimeProfile ?  elaborateCredentialDaysArray(credential.additionalTimeProfile) : [];
    const additionalDaysOptions = _.map(moment.weekdays(true), weekday => ({
      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
      selected: _.indexOf(additionalDay, moment().isoWeekday(weekday).isoWeekday()) !== -1,
    }));
    let statusColor = credential.status && RESERVATION_STATUSES_PARAMETERS[credential.status] && RESERVATION_STATUSES_PARAMETERS[credential.status].color;
    statusColor = credential && credential.state === CARDS_CREDENDIAL_RULES_STATUSES.FAILED ? 'red' : statusColor;
    let statusString = credential && credential.status && RESERVATION_STATUSES_PARAMETERS[credential.status].string ? RESERVATION_STATUSES_PARAMETERS[credential.status].string : '';

    return (
      <Card
        className={`card card-rounded ${!onSelectCredentialRule && 'card-noHover'}`}
        onClick={onSelectCredentialRule ? () => onSelectCredentialRule() : null}
        style={{ borderRadius: 10, marginBottom: 30, marginLeft: 2, marginRight: 2, position: 'relative', borderLeft: `4px solid ${statusColor}` }}
      >
        <div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MobileIcon style={{ fontSize: 30, color: '#3f3f3f', paddingLeft: 5, marginRight: 5 }} />
              <h4 style={{ fontWeight: 'bold', color: '#3f3f3f', margin: 0, marginTop: 4, marginBottom: 4 }}><Entity entity="smartphoneRule" /></h4>
              {credential.serviceName === 'BOOKEY' ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4 style={{ fontWeight: 'bold', color: '#3f3f3f', margin: 0, marginLeft: 5, marginRight: 5, marginTop: 0, marginBottom: 0 }}>{`-`}</h4>
                  <h4 style={{ fontWeight: 'bold', color: '#3f3f3f', margin: 0, marginTop: 0, marginBottom: 0 }}> <Entity entity="bookeyReservation" /></h4>
                </div>
              ) : null}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: 10 }}>
            {credential && credential.state === CARDS_CREDENDIAL_RULES_STATUSES.FAILED ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <CardCredentialStatus
                  status={credential.state}
                />
              </div>
              ) : (
                <Entity entity={statusString} style={{ fontSize: 12, textTransform: 'uppercase', fontWeight: 'bold', color: statusColor }} />
              )}
            </div>
          </div>
          <Divider style={{ marginTop: 5, marginBottom: 5, height: 2 }} />
          <div style={{ paddingTop: 5, flexDirection: 'column', paddingBottom: 5, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', marginTop: 15 }}>
            <div style={{ paddingRight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {credential && credential.smartLock && credential.smartLock.model ? (
                <img className="lock-icon" style={{ marginRight: 10, marginLeft: 4, width: 30, height: 30 }} src={getLockImage(credential.smartLock.model)} />
              ) : (
                <LockIcon style={{ fontSize: 30, color: '#3f3f3f', paddingLeft: 5, marginRight: 10 }} />
              )}
              <h4 style={{ margin: 0, color: '#3f3f3f', textAlign: 'center' }}>{credential.smartLock.name}</h4>
            </div>
            <div style={{ marginTop: 15, display: 'flex', alignItems: 'center' }}>
              <CalendarIcon style={{ fontSize: 30, color: '#3f3f3f', paddingLeft: 5, marginRight: 10 }} />
              <h5 style={{ margin: 0, color: '#3f3f3f' }}>{`${moment(credential.fromDate).format('LL')} - ${moment(credential.toDate).format('LL')}`}</h5>
              <TimeIcon style={{ marginLeft: 20, fontSize: 25, color: '#3f3f3f', marginRight: 10 }} />
              <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                {
                  credential.serviceName === SUBSCRIPTION_TYPES.BOOKEY ?
                  `${moment(credential.fromDate).format('LT')} - ${moment(credential.toDate).format('LT')}` :
                  `${moment().startOf('day').add(credential.timeInSecondsFrom, 'seconds').format('LT')}-${moment().startOf('day').add(credential.timeInSecondsTo, 'seconds').format('LT')}`
                }
              </h5>
              <div style={{ marginLeft: 20, display: 'flex' }}>
                {_.map(daysOptions, option => (
                  <h5 key={option.label} style={{ margin: 0, marginRight: 5, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
                    {option.label.toUpperCase()}
                  </h5>
                ))}
              </div>
            </div>
          </div>
          {credential.additionalTimeProfile ? (
            <div>
              <Divider style={{ marginTop: 5, marginBottom: 5, height: 2 }} />
              <div style={{ paddingTop: 5, flexDirection: 'column', paddingBottom: 5, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', marginTop: 0 }}>
                <div style={{ marginTop: 0, display: 'flex', alignItems: 'center' }}>
                  <CalendarIcon style={{ fontSize: 30, color: '#3f3f3f', paddingLeft: 5, marginRight: 10 }} />
                  <h5 style={{ margin: 0, color: '#3f3f3f' }}>{`${moment(credential.additionalTimeProfile.fromDate).format('LL')} - ${moment(credential.additionalTimeProfile.toDate).format('LL')}`}</h5>
                  <TimeIcon style={{ marginLeft: 20, fontSize: 25, color: '#3f3f3f', marginRight: 10 }} />
                  <h5 style={{ margin: 0, color: '#3f3f3f' }}>{`${moment().startOf('day').add(credential.additionalTimeProfile.timeInSecondsFrom, 'seconds').format('LT')}-${moment().startOf('day').add(credential.additionalTimeProfile.timeInSecondsTo, 'seconds').format('LT')}`}</h5>
                  <div style={{ marginLeft: 20, display: 'flex' }}>
                    {_.map(additionalDaysOptions, option => (
                      <h5 style={{ margin: 0, marginRight: 5, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
                        {option.label.toUpperCase()}
                      </h5>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Card>
    );
  }
} 

export default GuestSmartphoneCredentialRow;
