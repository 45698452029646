import React from 'react';
import { Button, ButtonGroup } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import MDButton from '../../MDButton/MDButton.jsx';


const FormSubmitBar = ({ onCancel, onConfirm, containerStyle }) => (
  <ButtonGroup style={{  display: 'flex', flexDirection: 'row', textAlign: 'center', ...containerStyle }}>
    {onCancel && 
      <Button style={{ display: 'flex', flex: 1, textAlign: 'center', justifyContent: 'center', backgroundColor: 'white', color: '#1b97c2', borderRadius: 0, border: 0, borderLeft: '1px solid', borderTop: '1px solid' }} onClick={() => onCancel()}>
        <Entity entity="cancel" />
      </Button>
    }
    {onConfirm &&
      <MDButton
        title={<Entity entity="save" />}
        containerStyle={{ width: '100%', margin: 0 }}
        style={{ backgroundColor: '#66BB6A', height: 40, borderRadius: 0 }}
        onClick={() => onConfirm()}
      />
      
    }
  </ButtonGroup>
);

export default FormSubmitBar;
