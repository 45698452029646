import { withStyles } from '@material-ui/core';
import { Col, Grid, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { submit } from 'redux-form';
import InsertEmailOTPForm from '../../components/forms/LoginForm/EmailOTPLogin/InsertEmailOTPForm.jsx';
import InsertOTPEmailForm from '../../components/forms/LoginForm/EmailOTPLogin/InsertOTPEmailForm.jsx';
import MDButton from '../../components/MDButton/MDButton.jsx';
import * as ModalsActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { ORANGE } from '../../_config/consts.js';
import { getLuckeyLogoSource, isMobileBrowser } from '../../_config/utils';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
    backgroundColor: 'white',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30,
    margin: 100,
    backgroundColor: 'white',
  },
  image: {
    height: 50,
    alignSelf: 'flex-start',
    marginBottom: 20,
  },
});

@withRouter
@connect(state => ({ userData: state.user.data, companyConfigurations: state.user.companyConfigurations }))
class LoginEmailOTPView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      otpCodeSend: false,
    };
  }
  
  async onSendOTPEmail(data) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.sendOTPEmail(data.email));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ otpCodeSent: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      setTimeout(() => {
        dispatch(ModalsActions.showModal({
          modalType: 'ERROR_MODAL',
          modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="loginJagoOTPEmailFailed" /> },
        }));
      }, 500);
    }
  }

  async onLoginWithOTP(data) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      this.setState({ isLoadingLogin: true });
      await dispatch(UserActions.loginWithOTP(data.code));
      this.setState({ isLoadingLogin: false, otpCodeSent: false });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ isLoadingLogin: false, otpCodeSent: false });
      dispatch(ModalsActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="loginJagoOTPFailed" /> },
      }));
    }
  }

  onBackToLogin() {
    const { dispatch } = this.props;
    dispatch(UserActions.logout());
  }


  render() {
    const { dispatch, classes, companyConfigurations } = this.props;
    const { otpCodeSent } = this.state;
    const isMobile = isMobileBrowser();
    const year = new Date().getFullYear();
    const luckeyLogoSource = getLuckeyLogoSource('white');
    return (
      <div id="auth-container" style={{ height: '100vh', paddingTop: 0, backgroundImage: isMobile ? 'unset' : 'url(/imgs/common/loginBackground.jpg)', backgroundSize: 'cover' }} className="login">
        <Grid>
          <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Col
              id="login-container"
              xs={12}
              sm={12}
              style={{
                height: isMobile ? '100vh' : 'unset',
                width: isMobile ? null : 900,
                opacity: isMobile ? 1 : 0.9,
                margin: isMobile ? 0 : 100,
                overflow: 'scroll',
                position: 'relative',
                borderRadius: isMobile ? 0 : 10,
                boxShadow: isMobile ? 'unset' : '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
              }}
            >
              <img src={luckeyLogoSource} alt="Sofia" style={{ margin: 10, marginTop: 60, marginBottom: 15, alignSelf: 'center', height: 65 }} />
              <img src="/imgs/common/tagLine.png" alt="SofiaTagline" style={{ alignSelf: 'center', height: isMobile ? 13 : 23 }} />
              <div style={{ padding: 25, paddingTop: 0, paddingBottom: 0, marginBottom: 25, marginTop: 25 }}>
                {!otpCodeSent ? (
                  <div>
                    <h3 style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginBottom: 0 }}><Entity key={otpCodeSent} entity="emailLoginTitle" /></h3>
                    <h5 style={{ color: 'white', fontWeight: 'lighter', textAlign: 'center', marginBottom: 10 }}><Entity key={otpCodeSent} entity="emailLoginDescription" /></h5>
                    <InsertEmailOTPForm
                      onSubmit={codeData => this.onSendOTPEmail(codeData)}
                    />
                    <MDButton
                      title={<Entity entity="confirm" />}
                      containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                      onClick={() => dispatch(submit('InsertEmailOTPForm'))}
                    />
                  </div>
                ) : (
                  <div>
                    <h3 style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginBottom: 0 }}><Entity key={otpCodeSent} entity="verificationCode" /></h3>
                    <h5 style={{ color: 'white', fontWeight: 'lighter', textAlign: 'center', marginBottom: 10 }}><Entity key={otpCodeSent} entity="verificationCodeDescription" /></h5>
                    <InsertOTPEmailForm
                      onSubmit={codeData => this.onLoginWithOTP(codeData)}
                    />
                    <MDButton
                      title={<Entity entity="confirm" />}
                      containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                      onClick={() => dispatch(submit('InsertOTPEmailForm'))}
                    />
                  </div>
                )}
                <MDButton
                  title={<Entity entity="backToLogin" />}
                  backgroundColor={ORANGE}
                  containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                  onClick={() => this.onBackToLogin()}
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src="/imgs/common/logoSofiaWhite.png" alt="Sofia" style={{ height: 12, marginRight: 4 }} />
                  <h6 style={{ fontSize: 10, margin: 0, marginTop: 3, color: 'white' }}>©{year}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(LoginEmailOTPView);
