// @ts-nocheck
import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import * as FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as CloudCreditsAPI from '../../_config/cloudCreditsAPI';
import { BANNER_ITEMS, FRESHDESK_DEMO_REQUEST_GROUP_ID, STORE_ADDITIONAL_ITEMS, STORE_ITEMS } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as FreshDeskAPI from '../../_config/freshdeskAPI';
import * as LuckeyStoreAPI from '../../_config/luckeyStoreAPI';
import { calculateNextRechargeDate, decodeUnicode, formatDomainHostNameToFriendlyName, formatSubscriptionsToReadableEntity, getDisplayCredits, getMonthlyBurnRate, getPlantName, getStoreLanguagePrefix } from '../../_config/utils';
import {
  RESET_MARKETPLACE_SUBSCRIPTIONS_FILTER,
  SET_WALLET_INFO,
  RESET_WALLET_CREDITS_FILTER, RESET_WALLET_EVENTS_FILTERS, RESET_WALLET_SUBSCRIPTIONS_FILTER, RESET_WALLET_USAGE_FILTER, SAVE_MARKETPLACE_BANNER_CONTENT, SAVE_MARKETPLACE_ITEMS_CONTENT,
  SAVE_MARKETPLACE_ITEM_CONTENT, SET_MARKETPLACE_SUBSCRIPTIONS,
  SET_MARKETPLACE_SUBSCRIPTIONS_FILTER,
  SET_MARKETPLACE_SUBSCRIPTIONS_PAGINATION, SET_WALLET_AVAILABLE_CREDITS, SET_WALLET_BURN_RATE, SET_WALLET_CREDITS,
  SET_WALLET_CREDITS_FILTER,
  SET_WALLET_CREDITS_PAGINATION, SET_WALLET_EVENTS, SET_WALLET_EVENTS_FILTER, SET_WALLET_EVENTS_PAGINATION, SET_WALLET_SUBSCRIPTIONS,
  SET_WALLET_SUBSCRIPTIONS_FILTER,
  SET_WALLET_SUBSCRIPTIONS_PAGINATION, SET_WALLET_USAGE,
  SET_WALLET_BURN_RATE_DAILY,
  SET_WALLET_USAGE_FILTER,
  SET_WALLET_USAGE_PAGINATION
} from './actionTypes/cloudCredits';
import * as SettingsActions from './setting.actions';
import * as UserActions from './user.actions';
import * as AmplitudeService from '../../services/AmplitudeService';

export function setMarketplaceSubscriptionData(subscriptions) {
  return {
    type: SET_MARKETPLACE_SUBSCRIPTIONS,
    subscriptions,
  };
}

export function setMarketplaceSubscriptionPagination(pagination) {
  return {
    type: SET_MARKETPLACE_SUBSCRIPTIONS_PAGINATION,
    pagination,
  };
}

export function setMarketplaceSubscriptionFilter(field, value) {
  return {
    type: SET_MARKETPLACE_SUBSCRIPTIONS_FILTER,
    field,
    value,
  };
}

export function resetMarketPlaceSubscriptionFilters() {
  return {
    type: RESET_MARKETPLACE_SUBSCRIPTIONS_FILTER,
  };
}


export function setWalletCreditsData(credits) {
  return {
    type: SET_WALLET_CREDITS,
    credits,
  };
}

export function setWalletInfo(walletInfo) {
  return {
    type: SET_WALLET_INFO,
    walletInfo
  };
}

export function setWalletCreditsPagination(pagination) {
  return {
    type: SET_WALLET_CREDITS_PAGINATION,
    pagination,
  };
}

export function setWalletCreditsFilter(field, value) {
  return {
    type: SET_WALLET_CREDITS_FILTER,
    field,
    value,
  };
}

export function resetWalletCreditsFilter() {
  return {
    type: RESET_WALLET_CREDITS_FILTER,
  };
}

export function setWalletUsageData(usage) {
  return {
    type: SET_WALLET_USAGE,
    usage,
  };
}

export function setWalletUsagePagination(pagination) {
  return {
    type: SET_WALLET_USAGE_PAGINATION,
    pagination,
  };
}

export function setWalletUsageFilter(field, value) {
  return {
    type: SET_WALLET_USAGE_FILTER,
    field,
    value,
  };
}

export function resetWalletUsageFilter() {
  return {
    type: RESET_WALLET_USAGE_FILTER,
  };
}


export function setWalletSubscriptionsData(subscriptions) {
  return {
    type: SET_WALLET_SUBSCRIPTIONS,
    subscriptions,
  };
}

export function setWalletSubscriptionsPagination(pagination) {
  return {
    type: SET_WALLET_SUBSCRIPTIONS_PAGINATION,
    pagination,
  };
}

export function setWalletSubscriptionsFilter(field, value) {
  return {
    type: SET_WALLET_SUBSCRIPTIONS_FILTER,
    field,
    value,
  };
}

export function resetWalletSubscriptionsFilter() {
  return {
    type: RESET_WALLET_SUBSCRIPTIONS_FILTER,
  };
}

export function setWalletAvailableCredits(credits) {
  return {
    type: SET_WALLET_AVAILABLE_CREDITS,
    credits,
  };
}

export function setWalletBurnRate(burnRate) {
  return {
    type: SET_WALLET_BURN_RATE,
    burnRate,
  };
}

export function setWalletBurnRateDaily(burnRate) {
  return {
    type: SET_WALLET_BURN_RATE_DAILY,
    burnRate,
  };
}

export function setWalletEventsData(events) {
  return {
    type: SET_WALLET_EVENTS,
    events,
  };
}

export function setWalletEventsPagination(pagination) {
  return {
    type: SET_WALLET_EVENTS_PAGINATION,
    pagination,
  };
}

export function setWalletEventsFilter(field, value) {
  return {
    type: SET_WALLET_EVENTS_FILTER,
    field,
    value,
  };
}

export function resetWalletEventsFilter() {
  return {
    type: RESET_WALLET_EVENTS_FILTERS,
  };
}

export function saveLuckeyStoreItemsContent(content) {
  return {
    type: SAVE_MARKETPLACE_ITEMS_CONTENT,
    content,
  };
}

export function saveLuckeyStoreItemContent(item) {
  return {
    type: SAVE_MARKETPLACE_ITEM_CONTENT,
    item,
  };
}


export function saveLuckeyBannerItemContent(item) {
  return {
    type: SAVE_MARKETPLACE_BANNER_CONTENT,
    item,
  };
}

export function fetchWalletAvailableCredits() {
  return async (dispatch) => {
    try {
      const response = await CloudCreditsAPI.fetchWalletAvailableCredits();
      if (response && response.data) {
        const displayCredits = getDisplayCredits(response.data.availableQuantity);
        dispatch(setWalletAvailableCredits(displayCredits));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

// TODO HERE
export function fetchWalletInfo() {
  return async (dispatch) => {
    try {
      const response = await CloudCreditsAPI.fetchWalletInfo();
      if (response && response.data) {
        dispatch(setWalletInfo(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchWalletSubscriptions(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().cloudCredits.wallet.subscriptions.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await CloudCreditsAPI.fetchWalletSubscriptions(params);
      if (response.data && response.data.content) {
        const subscriptionsData = response.data.content;
        let burnRate = 0;
        let burnRateDaily = 0;
        const formattedSubscriptions = _.map(subscriptionsData, (walletSubscription) => {
          return {
            ...walletSubscription,
            subscription: formatter.formatInputData('WALLET_SUBSCRIPTION', walletSubscription.subscription),
          };
        });
        dispatch(setWalletSubscriptionsData(formattedSubscriptions));
        dispatch(setWalletSubscriptionsPagination(_.omit(response.data, 'content')));
        _.each(formattedSubscriptions, (subscription) => {
          burnRate += subscription.subscription.burnRate;
          burnRateDaily += subscription.subscription.burnRateDaily;
        });
        dispatch(setWalletBurnRate(burnRate));
        dispatch(setWalletBurnRateDaily(burnRateDaily));
        if (dispatch(UserActions.isFakeCloudCredits())) {
          dispatch(elaborateFakeLicenseFromWalletSubscriptions());
        }
        return formattedSubscriptions;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function appendWalletSubscriptions(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().cloudCredits.wallet.subscriptions.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await CloudCreditsAPI.fetchWalletSubscriptions(params);
      if (response.data && response.data.content) {
        const stateSubscriptions = getState().cloudCredits.wallet.subscriptions.content;
        const formattedSubscriptions = _.map(response.data.content, (walletSubscription) => {
          return {
            ...walletSubscription,
            subscription: formatter.formatInputData('WALLET_SUBSCRIPTION', walletSubscription.subscription),
          };
        });
        const subscriptionsData = {
          stateSubscriptions,
          ...formattedSubscriptions,
        };
        dispatch(setWalletSubscriptionsData(subscriptionsData));
        dispatch(setWalletSubscriptionsPagination(_.omit(response.data, 'content')));
        let burnRate = getState().cloudCredits.wallet.burnRate;
        let burnRateDaily = getState().cloudCredits.wallet.burnRateDaily;
        _.each(subscriptionsData, (subscription) => {
          burnRate += subscription.subscription.burnRate;
          burnRateDaily += subscription.subscription.burnRateDaily;
        });
        dispatch(setWalletBurnRate(burnRate));
        dispatch(setWalletBurnRateDaily(burnRateDaily));
        return subscriptionsData;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createWalletSubscription(subscriptionDTO) {
  return async (dispatch) => {
    try {
      const response = await CloudCreditsAPI.createWalletSubscription({ subscriptionId: subscriptionDTO.id });
      if (response && response.data) {
        AmplitudeService.logEvent('ACTIVATE_WALLET_SUBSCRIPTION', { extensionName: subscriptionDTO.name, extensionResource: subscriptionDTO.resource, plantName: getPlantName() });
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function deleteWalletSubscription(subscription) {
  return async (dispatch) => {
    try {
      const response = await CloudCreditsAPI.deleteWalletSubscription(subscription.id);
      if (response && response.data) {
        AmplitudeService.logEvent('DELETE_WALLET_SUBSCRIPTION', { extensionName: subscription.subscription.name, plantName: getPlantName() });
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function rechargeWallet(cardDTO) {
  return async (dispatch) => {
    try {
      const response = await CloudCreditsAPI.rechargeWallet(cardDTO);
      if (response && response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchWalletCredits(page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().cloudCredits.wallet.credits.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await CloudCreditsAPI.fetchWalletCredits(params);
      if (response.data && response.data.content) {
        const creditsData = response.data.content;
        const formattedCredits = _.map(creditsData, credit => ({
          ...credit,
          quantityUsed: getDisplayCredits(credit.quantityUsed),
          quantity: getDisplayCredits(credit.quantity),
        }));
        dispatch(setWalletCreditsData(formattedCredits));
        dispatch(setWalletCreditsPagination(_.omit(response.data, 'content')));
        return formattedCredits;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function appendWalletCredits(page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().cloudCredits.wallet.credits.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await CloudCreditsAPI.fetchWalletCredits(params);
      if (response.data && response.data.content) {
        const stateCredits = getState().cloudCredits.wallet.credits.content;
        const formattedCredits = _.map(response.data.content, credit => ({
          ...credit,
          quantityUsed: getDisplayCredits(credit.quantityUsed),
          quantity: getDisplayCredits(credit.quantity),
        }));
        const creditsData = {
          stateCredits,
          ...formattedCredits,
        };
        dispatch(setWalletCreditsData(creditsData));
        dispatch(setWalletCreditsPagination(_.omit(response.data, 'content')));
        return creditsData;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchWalletCreditsUsage(page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().cloudCredits.wallet.usage.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await CloudCreditsAPI.fetchWalletCreditsUsage(params);
      if (response.data && response.data.content) {
        const formattedCreditUsage = _.map(response.data.content, usage => ({
          ...usage,
          totalAvailable: getDisplayCredits(usage.totalAvailable),
          quantity: getDisplayCredits(usage.quantity),
        }));
        dispatch(setWalletUsageData(formattedCreditUsage));
        dispatch(setWalletUsagePagination(_.omit(response.data, 'content')));
        return formattedCreditUsage;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function appendWalletCreditsUsage(page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().cloudCredits.wallet.usage.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await CloudCreditsAPI.fetchWalletCreditsUsage(params);
      if (response.data && response.data.content) {
        const formattedCreditUsage = _.map(response.data.content, usage => ({
          ...usage,
          totalAvailable: getDisplayCredits(usage.totalAvailable),
          quantity: getDisplayCredits(usage.quantity),
        }));
        const stateCreditsUsage = getState().cloudCredits.wallet.usage.content;
        const creditsUsageData = {
          stateCreditsUsage,
          ...formattedCreditUsage,
        };
        dispatch(setWalletUsageData(creditsUsageData));
        dispatch(setWalletUsagePagination(_.omit(response.data, 'content')));
        return creditsUsageData;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function fetchMarketPlaceSubscriptions(page = 0, pageSize = 200) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().cloudCredits.marketPlace.subscriptions.filters;
      const mainTier = getState().user.license.mainTier;
      const params = {
        page,
        pageSize,
        mainTier,
        ...filters,
      };
      const subscriptionResponse = await CloudCreditsAPI.fetchMarketPlaceSubscriptions(params);
      const serviceResponse = await CloudCreditsAPI.fetchMarketPlaceSubscriptions(_.omit(params, 'mainTier'));
      if (subscriptionResponse.data && subscriptionResponse.data.content && serviceResponse.data && serviceResponse.data.content) {
        const allsubscriptions = [...subscriptionResponse.data.content, ...serviceResponse.data.content];
        const filteredSubscriptions = _.filter(allsubscriptions, sub => sub.resource !== 'DOMAIN' && sub.visible === true);
        // merge ONLY BE subscriptions data and STORE_ITEMS subscription data to have data about burnRate and fixedPrice
        const subscriptionsData = _.map(_.uniqBy(filteredSubscriptions, 'resource'), subscription => (
          {
            ...subscription,
            isActive: dispatch(UserActions.userHasSubscriptions(subscription.resource)),
            ...STORE_ITEMS[subscription.resource],
            burnRateDaily: getDisplayCredits(subscription.burnRate),
            burnRate: getMonthlyBurnRate(subscription.burnRate),
            fixedPrice: getDisplayCredits(subscription.fixedPrice),
          }
        ));

        //Manage Additional Item
        _.map(STORE_ADDITIONAL_ITEMS, additionalItem => {
          subscriptionsData.push(additionalItem);
        })
        const languagePrefix = getStoreLanguagePrefix();
        const content = await LuckeyStoreAPI.fetchAllStoreItemContent(languagePrefix);
        const subscriptionsDataWithContent = [];
        if (content && content.data) {
          const storeItemsContent = content.data;
          dispatch(saveLuckeyStoreItemsContent(content.data));
          _.map(subscriptionsData, (extension) => {
            // link the WP content with the right addon using the postId
            const itemContent = _.find(storeItemsContent, storeContent => extension.postIds && extension.postIds[languagePrefix] && extension.postIds[languagePrefix] === storeContent.id);
            const previewImage = itemContent && itemContent['_embedded'] && itemContent['_embedded']['wp:featuredmedia'] && itemContent['_embedded']['wp:featuredmedia'][0] ? itemContent['_embedded']['wp:featuredmedia'][0].source_url : null;
            // if WP content is not present -> don't show the item on the store
            if (itemContent) {
              subscriptionsDataWithContent.push({
                ...extension,
                preview: itemContent && itemContent.excerpt && itemContent.excerpt.rendered ? decodeUnicode(itemContent.excerpt.rendered) : undefined,
                previewImage,
                renderedTitle: itemContent && itemContent.title && itemContent.title.rendered ? decodeUnicode(itemContent.title.rendered) : '',
                renderedContent: itemContent && itemContent.content && itemContent.content.rendered ? itemContent.content.rendered : '',
              });
            }
          });

        }
        dispatch(setMarketplaceSubscriptionData(subscriptionsDataWithContent));
        dispatch(setMarketplaceSubscriptionPagination(_.omit(subscriptionResponse.data, 'content')));
        return subscriptionsDataWithContent;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}



export function appendMarketPlaceSubscriptions(page = 0, pageSize = 200) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().cloudCredits.marketPlace.subscriptions.filters;
      const mainTier = getState().user.license.mainTier;
      const params = {
        page,
        pageSize,
        mainTier,
        ...filters,
      };
      const response = await CloudCreditsAPI.fetchMarketPlaceSubscriptions(params);
      if (response.data && response.data.content) {
        const filteredSubscriptions = _.filter(response.data.content, sub => sub.resource !== 'DOMAIN');
        const stateSubscriptions = getState().cloudCredits.marketPlace.subscriptions.content;
        const formattedSubscriptions = _.map(filteredSubscriptions, subscription => (
          {
            ...subscription,
            isActive: dispatch(UserActions.userHasSubscriptions(subscription.resource)),
            burnRateDaily: getDisplayCredits(subscription.burnRate),
            burnRate: getMonthlyBurnRate(subscription.burnRate),
            fixedPrice: getDisplayCredits(subscription.fixedPrice),
          }
        ));
        const subscriptionsData = {
          stateSubscriptions,
          ...formattedSubscriptions,
        };
        dispatch(setMarketplaceSubscriptionData(subscriptionsData));
        dispatch(setMarketplaceSubscriptionPagination(_.omit(response.data, 'content')));
        return subscriptionsData;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function exportWalletUsageData(format) {
  return (dispatch, getState) => {
    const { wallet: { usage }} = getState().cloudCredits;
    if (usage.content) {
      const exportData = [];
      const fileName = 'Wallet Consumption';
      _.map(usage.content, (usageData) => {
        const usageType = L20NContext.getSync('creditConsumptionTypeDescription', { type: usageData.usageType });
        exportData.push({
          Quantity: usageData.quantity,
          'Usage Type': usageType || '---',
          'Residual Credits': usageData.totalAvailable,
          Date: moment(usageData.createdAt).format('LL'),
        });
      });
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = `.${format.toLowerCase()}`;
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { Usage: ws }, SheetNames: ['Usage'] };
      const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };
}


export function exportWalletRechargeData(format) {
  return (dispatch, getState) => {
    const { wallet: { credits }} = getState().cloudCredits;
    if (credits.content) {
      const exportData = [];
      const fileName = 'Wallet Recharge';
      _.map(credits.content, (rechargeData) => {
        exportData.push({
          Quantity: rechargeData.quantity,
          Date: moment(rechargeData.createdAt).format('LL'),
        });
      });
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = `.${format.toLowerCase()}`;
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { Usage: ws }, SheetNames: ['Usage'] };
      const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };
}


export function sendRemovedSubscriptionFeedback(subscriptionItem, feedbackText) {
  return async (dispatch, getState) => {
    try {
      const userData = getState().user.data;
      const licenseData = getState().user.token;
      const email = userData.email || 'no-email';
      const firstName = userData.firstname || 'no-firstname';
      const lastName = userData.lastname || 'no-lastname';
      const plantName = licenseData && licenseData.hostname ? formatDomainHostNameToFriendlyName(licenseData.hostname) : 'no-domain';
      const ticketDTO = {
        description: (
          `
          <div>
            <h4>Feedback for removing "${subscriptionItem.name}" from customer "${plantName}" - user ${firstName} ${lastName} ${email}</h4>
            <h4>"${feedbackText}"</h4>
          </div>
        `),
        email,
        subject: `REMOVE EXTENSION FEEDBACK - PLANT ${plantName}`,
        group_id: FRESHDESK_DEMO_REQUEST_GROUP_ID,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      AmplitudeService.logEvent('REQUEST_WALLET_SUBSCRIPTION_DEMO', { extensionName: subscriptionItem.name, plantName });
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  }
}



export function fetchLastWalletRecharge() {
  return async (dispatch, getState) => {
    try {
      const params = {
        page: 0,
        pageSize: 1,
        orderDir: 'DESC',
      };
      const response = await CloudCreditsAPI.fetchWalletCredits(params);
      if (response.data && response.data.content) {
        const creditsData = _.first(response.data.content);
        return getDisplayCredits(creditsData.quantity);
      }
    } catch (error) {
      return null;
    }
  }
}

/* 
productQuantity = ceil(lastWalletRecharge/(300 * 100))(dipende se ha i decimali)

renewalPeriodEstimate = (currentCredits + (productQuantity * 300)) / burnRate (d); 


*/
export function sendRechargeWalletRequest() {
  return async (dispatch, getState) => {
    try {
      const userData = getState().user.data;
      const licenseData = getState().user.token;
      const { wallet: { credits, burnRate, burnRateDaily, availableCredits }} = getState().cloudCredits;
      let lastRechargeAmount = 0;
      try {
        lastRechargeAmount = await(dispatch(fetchLastWalletRecharge()));
      } catch (error) {
        lastRechargeAmount = 0;
      }
      
      const productQuantity = _.ceil(lastRechargeAmount / 300);
      const renewalPeriodEstimateDays = moment(calculateNextRechargeDate(availableCredits + (productQuantity * 300), burnRateDaily)).fromNow(); 
      const currentDailyBurnRate = burnRateDaily;
      const currentYearlyBurnRate = _.ceil(burnRate * 12);
      const email = userData.email || 'no-email';
      const firstName = userData.firstname || 'no-firstname';
      const lastName = userData.lastname || 'no-lastname';
      const productCode = '004S320';
      const productDescription = 'LUCKEY CREDITS SMALL PACK 300 CREDITS';
      const plantName = licenseData && licenseData.hostname ? formatDomainHostNameToFriendlyName(licenseData.hostname) : 'no-domain';
      const ticketDTO = {
        description: (
          `
          <div>
            <h4 style="font-weight: 400;">Dear,</h4>
            <h4 style="font-weight: 400;">The customer ${firstName} ${lastName} ${email} related to the system ${plantName} requested a recharge of the wallet.</h4>
            <h4 style="font-weight: 400;">Here is some information:</h4>
            <ul>
              <li><h4 style="font-weight: 400;">The last wallet recharge was of ${lastRechargeAmount} credits.</h4></li>
              <li><h4 style="font-weight: 400;">The current burn rate is ${currentDailyBurnRate} credits per day (about ${currentYearlyBurnRate} credits per year)</h4></li>
            </ul>
            <h4 style="font-weight: 400;">Hence, given a recharge similar to the previous one, your renewal timeframe will be approximately ${renewalPeriodEstimateDays} (assuming no other changes are made by buying add-ons or plug-ins in the Luckey Store).</h4>
            <h4 style="font-weight: 400;">Please, send the customer the following quotation:</h4>
            <table style="margin-top: 15px;">
              <tr>
                <th style="padding: 8px; text-align: center; border: 1px solid #dddddd;">CODE</th>
                <th style="padding: 8px; text-align: center; border: 1px solid #dddddd;">DESCRIPTION</th>
                <th style="padding: 8px; text-align: center; border: 1px solid #dddddd;">QUANTITY</th>
              </tr>
              <tr>
                <td style="padding: 8px; text-align: left; border: 1px solid #dddddd;">${productCode || '---'}</td>
                <td style="padding: 8px; text-align: left; border: 1px solid #dddddd;">${productDescription || '---'}</td>
                <td style="padding: 8px; text-align: left; border: 1px solid #dddddd;">${productQuantity || '---'}</td>
              </tr>
            </table>
            <h4 style="font-weight: 400; margin-top: 30px;">Please remember to apply the last discount policy for this customer.
            </h4>
            <h4 style="font-weight: 400;">Notify the Sofia team once the request has been handled.</h4>
            <h4 style="font-weight: 400;">Best Regards</h4>
          </div>
        `),
        email,
        subject: `RECHARGE WALLET REQUEST - PLANT ${plantName}`,
        group_id: FRESHDESK_DEMO_REQUEST_GROUP_ID,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      return ticketResponse;
    } catch (error) {
      // FALLBACK TICKEY
      const userData = getState().user.data;
      const licenseData = getState().user.token;
      const email = userData.email || 'no-email';
      const firstName = userData.firstname || 'no-firstname';
      const lastName = userData.lastname || 'no-lastname';
      const plantName = licenseData && licenseData.hostname ? formatDomainHostNameToFriendlyName(licenseData.hostname) : 'no-domain';
      const ticketDTO = {
        description: (
          `
          <div>
          <h4>Recharge request from customer "${plantName}" - user ${firstName} ${lastName} ${email}</h4>
          </div>
        `),
        email,
        subject: `RECHARGE WALLET REQUEST - PLANT ${plantName}`,
        group_id: FRESHDESK_DEMO_REQUEST_GROUP_ID,
        status: 2,
        priority: 3,
        source: 9,
      };
      try {
        const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
        return ticketResponse;
      } catch (error) {
        throw error;
      }
    }
  }
}

export function sendRemovedSubscriptionRequest(subscriptionItem, feedbackText) {
  return async (dispatch, getState) => {
    try {
      const userData = getState().user.data;
      const licenseData = getState().user.token;
      const email = userData.email || 'no-email';
      const firstName = userData.firstname || 'no-firstname';
      const lastName = userData.lastname || 'no-lastname';
      const plantName = licenseData && licenseData.hostname ? formatDomainHostNameToFriendlyName(licenseData.hostname) : 'no-domain';
      const ticketDTO = {
        description: (
          `
          <div>
            <h4>Customer ask for removing subscription "${subscriptionItem.name}" from the system "${plantName}" - Requester User ${firstName} ${lastName} ${email}</h4>
            <h4>"${feedbackText}"</h4>
          </div>
        `),
        email,
        subject: `REMOVE EXTENSION REQUEST - PLANT ${plantName}`,
        group_id: FRESHDESK_DEMO_REQUEST_GROUP_ID,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      AmplitudeService.logEvent('DELETE_WALLET_SUBSCRIPTION_REQUEST', { extensionName: subscriptionItem.name, extensionResource: subscriptionItem.resource, plantName: plantName });
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  }
}

export function sendChangeTierRequest(subscriptionItem) {
  return async (dispatch, getState) => {
    try {
      const userData = getState().user.data;
      const licenseData = getState().user.token;
      const email = userData.email || 'no-email';
      const firstName = userData.firstname || 'no-firstname';
      const lastName = userData.lastname || 'no-lastname';
      const plantName = licenseData && licenseData.hostname ? formatDomainHostNameToFriendlyName(licenseData.hostname) : 'no-domain';
      const ticketDTO = {
        description: (
          `
          <div>
            <h4>Customer ask for change his subscription to the new subscription "${subscriptionItem.name}" from the system "${plantName}" - Requester User ${firstName} ${lastName} ${email}</h4>
          </div>
        `),
        email,
        subject: `CHANGE MAIN SUBSCRIPTION - SYSTEM ${plantName}`,
        group_id: FRESHDESK_DEMO_REQUEST_GROUP_ID,
        status: 2,
        priority: 3,
        source: 9,
      };
      const ticketResponse = await FreshDeskAPI.createFreshdeskTicket(ticketDTO);
      AmplitudeService.logEvent('CHANGE_MAIN_SUBSCRIPTION', { extensionName: subscriptionItem.name, plantName: plantName });
      return ticketResponse;
    } catch (error) {
      throw error;
    }
  }
}

export function fetchWalletEvents(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().cloudCredits.wallet.events.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await CloudCreditsAPI.fetchWalletSubscriptions(params);
      if (response.data && response.data.content) {
        const subscriptionsData = response.data.content;
        const eventsData = _.map(subscriptionsData, (event) => {
          const userId = event.activatedByUserId || event.deletedByUserId;
          const platformUsers = getState().platformUsers.data.content;
          let userData = {};
          if (userId) {
            userData = _.find(platformUsers, user => user.id === userId);
          }
          return {
            ...event,
            userData,
            subscription: formatter.formatInputData('WALLET_SUBSCRIPTION', event.subscription),
          };
        });
        dispatch(setWalletEventsData(eventsData));
        dispatch(setWalletEventsPagination(_.omit(response.data, 'content')));
        return subscriptionsData;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function appendWalletEvents(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().cloudCredits.wallet.subscriptions.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await CloudCreditsAPI.fetchWalletSubscriptions(params);
      if (response.data && response.data.content) {
        const stateSubscriptions = getState().cloudCredits.wallet.subscriptions.content;
        const eventsData = _.map(response.data.content, (event) => {
          const userId = event.activatedByUserId || event.deletedByUserId;
          const platformUsers = getState().platformUsers.data.content;
          let userData = {};
          if (userId) {
            userData = _.find(platformUsers, user => user.id === userId);
          }
          return {
            ...event,
            userData,
            subscription: formatter.formatInputData('WALLET_SUBSCRIPTION', event.subscription),
          };
        });
        const subscriptionsData = {
          stateSubscriptions,
          ...eventsData,
        };
        dispatch(setWalletEventsData(subscriptionsData));
        dispatch(setWalletEventsPagination(_.omit(response.data, 'content')));
        return subscriptionsData;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function exportWalletEvents(format) {
  return (dispatch, getState) => {
    const { wallet: { events }} = getState().cloudCredits;
    if (events.content) {
      const exportData = [];
      const fileName = 'Wallet Events';
      _.map(events.content, (eventData) => {
        let eventRow = {
          'Event Type': eventData.deleted ? L20NContext.getSync('subscriptionDeleted') : L20NContext.getSync('subscriptionActivated'),
          Subscription: eventData.subscription && eventData.subscription.name ? eventData.subscription.name : '---',
          Date: eventData.deleted ? moment(eventData.deletedAt).format('LL') : moment(eventData.createdAt).format('LL'),
        };
        if (eventData.userData && !_.isEmpty(eventData.userData)) {
          eventRow = {
            ...eventRow,
            User: `${eventData.userData.firstname} ${eventData.userData.lastname} ${eventData.userData.email}`,
          };
        }
        exportData.push(eventRow);
      });
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = `.${format.toLowerCase()}`;
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { Usage: ws }, SheetNames: ['Usage'] };
      const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };
}

export function elaborateFakeLicenseFromWalletSubscriptions() {
  return (dispatch, getState) => {
    const { content: walletSubscriptionsData } = getState().cloudCredits.wallet.subscriptions;
    const { wallet } = getState().cloudCredits;
    const domainSubscription = _.find(walletSubscriptionsData, walletSubscription => walletSubscription.subscription.resource === 'DOMAIN');
    /*
    domainSub = {
      burnRate: 1000
      category: null
      fixedPrice: 200000
      id: 3
      logic: "COUNT_LOCKS"
      mainTier: null
      maxElements: 0
      maxLocks: 200
      maxUsers: 0
      name: "LUCKEY-TIER-1"
      nextTier: 0
      prevTier: 0
      resource: "DOMAIN"
      sku: null
      visible: true
    }
     ----------------
     Lock license = {
      creationDate: 0
      expirationDate: 1952294400000
      expired: false
      id: null
      quantity: 50
      resourceType: "LOCKS"
      used: 34
    }

    Domain License = {
      creationDate: 0
      expirationDate: 0
      expired: false
      id: 3
      quantity: 0
      resourceType: "DOMAIN"
      used: 0
    }
    */
    if (domainSubscription && domainSubscription.subscription) {
      const { license } = getState().user;

      const nextRechargeDate = calculateNextRechargeDate(wallet.availableCredits, wallet.burnRateDaily);
      const fakeDomainLicense = {
        creationDate: 0,
        expirationDate: nextRechargeDate,
        expired: false,
        id: 3,
        quantity: 0,
        resourceType: 'DOMAIN',
        used: 0,
      };
      
      const userFakeLicense = {
        quantity: domainSubscription.subscription.maxUsers,
        creationDate: 0,
        expirationDate: 0,
        expired: false,
        id: null,
        resourceType: 'USERS',
        used: wallet && wallet.info && wallet.info.numOfUsers,
      };
      const lockFakeLicense = {
        quantity: domainSubscription.subscription.maxLocks,
        creationDate: 0,
        expirationDate: 0,
        expired: false,
        id: null,
        resourceType: 'LOCKS',
        used: wallet && wallet.info && wallet.info.numOfLocks,
      };
      const userslockFakeLicense = {
        quantity: domainSubscription.subscription.maxElements,
        creationDate: 0,
        expirationDate: 0,
        expired: false,
        id: null,
        resourceType: 'USERS+LOCKS',
        used: wallet && wallet.info && wallet.info.numOfUsers && wallet && wallet.info && wallet.info.numOfLocks ?  wallet.info.numOfUsers + wallet.info.numOfLocks : 0,
      };
      let fakeLicense = {};
      const normalSubscriptions = _.filter(license.subscriptions, subscription => subscription.resourceType !== 'DOMAIN');
      switch (domainSubscription.subscription.logic) {
        case 'COUNT_USERS_LOCKS': {
          fakeLicense = {
            ..._.omit(license, 'subscriptions'),
            subscriptions: [
              ...normalSubscriptions,
              fakeDomainLicense,
              userFakeLicense,
              lockFakeLicense,
            ],
          };
          break;
        }
        case 'COUNT_LOCKS': {
          fakeLicense = {
            ..._.omit(license, 'subscriptions'),
            subscriptions: [
              ...normalSubscriptions,
              fakeDomainLicense,
              lockFakeLicense,
            ],
          };
          break;
        }
        case 'COUNT_ELEMENTS': {
          fakeLicense = {
            ..._.omit(license, 'subscriptions'),
            subscriptions: [
              ...normalSubscriptions,
              fakeDomainLicense,
              userslockFakeLicense,
            ],
          };
          break;
        }
        default:
          fakeLicense = {
            ..._.omit(license, 'subscriptions'),
            subscriptions: [
              ...normalSubscriptions,
              fakeDomainLicense,
              lockFakeLicense,
              userFakeLicense,
            ],
          };
      }
      dispatch(UserActions.saveDomainLicenseInfo(fakeLicense));
      dispatch(SettingsActions.saveSetting('license', fakeLicense.subscriptions));
    }
  };
}

export function fetchAllItemStoreContent() {
  return async (dispatch, getState) => {
    try {
      const languagePrefix = getStoreLanguagePrefix();
      const content = await LuckeyStoreAPI.fetchAllStoreItemContent(languagePrefix);
      if (content && content.data) {
        const storeItemsContent = content.data;
        dispatch(saveLuckeyStoreItemsContent(content.data));
        const extensions = getState().cloudCredits.marketPlace.subscriptions.content;
        const updatedExtensions = _.map(extensions, (extension) => {
          // link the WP content with the right addon using the postId
          const itemContent = _.find(storeItemsContent, storeContent => extension.postIds && extension.postIds[languagePrefix] && extension.postIds[languagePrefix] === storeContent.id);
          const previewImage = itemContent && itemContent['_embedded'] && itemContent['_embedded']['wp:featuredmedia'] && itemContent['_embedded']['wp:featuredmedia'][0] ? itemContent['_embedded']['wp:featuredmedia'][0].source_url : null;
          return {
            ...extension,
            preview: itemContent && itemContent.excerpt ? itemContent.excerpt : undefined,
            previewImage,
          };
        });
        dispatch(setMarketplaceSubscriptionData(updatedExtensions));
        return content;
      }
      return [];
    } catch (error) {
      return [];
    }
  };
}

export function fetchStoreItemContent(itemId) {
  return async (dispatch, getState) => {
    try {
      const languagePrefix = getStoreLanguagePrefix();
      const stateContent = getState().cloudCredits.marketPlace.itemsContent;
      const cachedItem = _.find(stateContent, content => content.id === itemId && content.language === languagePrefix);
      if (cachedItem && cachedItem.content && cachedItem.content.rendered) {
        return cachedItem.content.rendered;
      }
      const content = await LuckeyStoreAPI.fetchStoreItemContent(itemId, languagePrefix);
      if (content && content.data && _.first(content.data)) {
        const plugin = _.first(content.data);
        const pluginHtmlContent = plugin && plugin.content && plugin.content.rendered ? plugin.content.rendered : undefined;
        const contentToSave = {
          ...plugin,
          id: itemId,
          language: languagePrefix,
        };
        dispatch(saveLuckeyStoreItemContent(contentToSave));
        return pluginHtmlContent;
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  };
}

// export function fetchBannerContent(itemId) {
//   return async (dispatch, getState) => {
//     try {
//       const languagePrefix = getStoreLanguagePrefix();
//       const stateContent = getState().cloudCredits.marketPlace.bannersContent;
//       const cachedItem = _.find(stateContent, content => content.id === itemId && content.language === languagePrefix);
//       if (cachedItem && cachedItem.content && cachedItem.content.rendered) {
//         return cachedItem.content.rendered;
//       }
//       const content = await LuckeyStoreAPI.fetchBannerContent(itemId, languagePrefix);
//       if (content && content.data && _.first(content.data)) {
//         const banner = _.first(content.data);
//         const bannerHtmlContent = banner && banner.content && banner.content.rendered ? banner.content.rendered : undefined;
//         const contentToSave = {
//           ...banner,
//           id: itemId,
//           language: languagePrefix,
//           bannerHtmlContent,
//         };
//         dispatch(saveLuckeyBannerItemContent(contentToSave));
//         return bannerHtmlContent;
//       }
//       return undefined;
//     } catch (error) {
//       return undefined;
//     }
//   };
// }


export function fetchBannerContent(bannerId = 'MAIN_BANNER') {
  return async (dispatch, getState) => {
    try {
      const { companyInfo } = getState().user;
      const languagePrefix = getStoreLanguagePrefix();
      const segmentType = companyInfo && companyInfo.segmentType;
      const itemId = segmentType && BANNER_ITEMS && BANNER_ITEMS[segmentType] && BANNER_ITEMS[segmentType].postIds[languagePrefix] ? BANNER_ITEMS[segmentType].postIds[languagePrefix] : BANNER_ITEMS[bannerId].postIds[languagePrefix];
      if (itemId) {
        const content = await LuckeyStoreAPI.fetchBannerContent(itemId, languagePrefix);
        if (content && content.data && _.first(content.data)) {
          const banner = _.first(content.data);
          const bannerHtmlContent = banner && banner.content && banner.content.rendered ? banner.content.rendered : undefined;
          const contentToSave = {
            ...banner,
            id: itemId,
            language: languagePrefix,
            bannerHtmlContent,
          };
          dispatch(saveLuckeyBannerItemContent(contentToSave));
          return bannerHtmlContent;
        }
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  };
}


export function fetchCloudCreditsTiers() {
  return async (dispatch, getState) => {
    try {
      const params = {
        resource: 'DOMAIN',
        page: 0,
        pageSize: 50
      };
      const subscriptions = await CloudCreditsAPI.fetchMarketPlaceSubscriptions(params);
      if (subscriptions && subscriptions.data && subscriptions.data.content) {
        const formattedSubscriptions = _.map(subscriptions.data.content, subscription => formatter.formatInputData('WALLET_SUBSCRIPTION', formatSubscriptionsToReadableEntity(subscription)));
        return formattedSubscriptions;
      }
    } catch (error) {
      throw error;
    }
  }
}