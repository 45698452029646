import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { initialize, change } from 'redux-form';
import * as PlatformUsersActions from '../../redux/actions/platformUsers.actions';
import * as RolesActions from '../../redux/actions/roles.actions';
import PlatformUserRow from './PlatformUserRow.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import EmptyView from '../EmptyView.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';

@connect(state => ({ platformUsers: state.platformUsers, userTags: state.tags.user, themeName: state.settings.items.theme.data.themeName }))
class PlatformUsersListView extends React.Component {
 
  onOpenUser(user) {
    const { dispatch } = this.props;
    dispatch(PlatformUsersActions.setSelectedPlatformUser(user));
    const isGuestManager = _.find(user.roles, role => role.id === 5);
    const rolesIds = _.map(user.roles, role => role.id);
    const filteredRoles = _.filter(user.roles, role => role.id !== 5);
    const userRole = _.map(filteredRoles, (role) => {
      const formattedRole = RolesActions.formatRole(role);
      return { value: String(role.id), label: formattedRole.label };
    });
    dispatch(initialize('PlatformUserForm', { ...user, userRole }));
    dispatch(change('PlatformUserForm', 'isGuestManager', isGuestManager));
    dispatch(change('PlatformUserForm', 'roleIds', rolesIds));
    dispatch(PlatformUsersActions.setOperationalMode(true));
  }

  onNewUser() {
    const { dispatch } = this.props;
    dispatch(PlatformUsersActions.setOperationalMode(true));
  }

  onAppendUsers(page) {
    const { dispatch } = this.props;
    dispatch(PlatformUsersActions.fetchAndAppendPlatformUsers(page));
  }

  render() {
    const { listContainerStyle, platformUsers: { data: { pagination, content: platformUsersData }, selectedUser }, viewLoading } = this.props;
    const canCreateUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 80, overflow: 'auto', width: '100%', ...listContainerStyle }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => this.onAppendUsers(pagination.number + 1)}
          hasMore={pagination.number + 1 < pagination.totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '40%' }}>
            {!_.isEmpty(platformUsersData) ?
              _.map(platformUsersData, user =>
                <PlatformUserRow
                  key={user.id}
                  user={user}
                  isSelected={selectedUser && selectedUser.id === user.id}
                  onClick={() => this.onOpenUser(user)}
                />)
                : (
                  <div>
                    {!viewLoading ? (
                      <EmptyView
                        title="noPlatformUsers"
                        onNewEntity={canCreateUser ? () => this.onNewUser() : null}
                        newEntityTitle="addPlatformUser"
                        subtitle="startAddPlatformUsers"
                        iconName="icon-simple-line-icons-user-following"
                      />) : <ListLoadingView />}
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }


} 

export default PlatformUsersListView;
