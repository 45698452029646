import { Card, Grid, withStyles } from '@material-ui/core';
import CheckInIcon from '@material-ui/icons/CheckCircleOutline.js';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { TICKETS_DEFAULT_CATEGORIES, TICKET_CATEGORY_COLORS, TICKET_CATEGORY_COLORS_OTHERS, TICKET_STATUS_COLORS } from '../_config/consts';
import PieChart from '../components/Charts/PieChart.jsx';
import ScrollableBarChart from '../components/Charts/ScrollableBarChart.jsx';
import ScrollableHorizontalBarChart from '../components/Charts/ScrollableHorizontalBarChart.jsx';
import ChartBoxIconCustom from '../components/CustomIcons/ChartBoxIconCustom.jsx';
import CloseCircleIconCustom from '../components/CustomIcons/CloseCircleIconCustom.jsx';
import MessageAlertIconCustom from '../components/CustomIcons/MessageAlertIconCustom.jsx';
import WarningOutlineIconCustom from '../components/CustomIcons/WarningOutlineIconCustom.jsx';
import TicketsFilters from '../components/Tickets/TicketsFilters.jsx';
import MetricCard from '../components/Visitors/MetricCard.jsx';
import * as TicketActions from '../redux/actions/ticket.actions.js';
import * as UtilsActions from '../redux/actions/utils.actions';

const styles = theme => ({
  chartsContainer: {
    flexWrap: 'unset !important',
  },
});


const EmptyAnalyticsView = () => (
  <div style={{ flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 80, width: window.screen.width - 250 - 20 - 40, zoom: "125%" }}>
    <ChartBoxIconCustom style={{ color: '#a5a3a3', width: 50 }} />
    <h3 style={{ color: '#a5a3a3', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFound" /></h3>
    <h4 style={{ color: '#a5a3a3', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFoundSelectDate" /></h4>
  </div>
)


@connect(state => ({ tickets: state.tickets, routing: state.router, languange: state.settings.language, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class TicketsAnalytics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      chartDataStatus: [],
      chartDataPercentage: [],
      chartDataAverageTime: [],
      windowWidth: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
  }
 
  componentDidUpdate(prevProps) {
    window.addEventListener('resize', this.handleResize);
    if (this.props.tickets.data.lastUpdate !== prevProps.tickets.data.lastUpdate)
      this.fetchTicketAnalytics()
  }
  
  async componentWillMount() {
    const { dispatch, routing, tickets } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(TicketActions.resetTicketsFilters());
      const fromDateDefault = moment().startOf('month').valueOf()
      const toDateDefault = moment().endOf('month').valueOf()
      await dispatch(TicketActions.setFilter('fromDate', fromDateDefault));
      await dispatch(TicketActions.setFilter('toDate', toDateDefault));
      await dispatch(TicketActions.fetchTicketManagers());
      await dispatch(TicketActions.fetchTicketCategories());
      await dispatch(TicketActions.fetchAllTickets());
      await this.fetchTicketAnalytics();
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(TicketActions.resetTicketsFilters());
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      windowWidth: window.innerWidth
    });
  }

  async onResetFilters() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    await dispatch(TicketActions.setFilter('priority', undefined));
    await dispatch(TicketActions.setFilter('managerId', undefined));
    await dispatch(TicketActions.setFilter('status', undefined));
    const { tickets } = this.props;
    dispatch(initialize('TicketsFilters', tickets.data.filters));
    await dispatch(TicketActions.fetchTickets());
    dispatch(UtilsActions.setSpinnerVisibile(false));
  }

  processReportDataStatus = (tickets, categoriesArrayValues) => {
    const counts = new Array(categoriesArrayValues.length).fill(0);
    return tickets.reduce((acc, ticket) => {
      const categoryName = ticket.categories&&ticket.categories.length>0?ticket.categories[0].name:undefined
      const index = categoryName?categoriesArrayValues.indexOf(categoryName):categoriesArrayValues.indexOf("ticketNoCategory");
      if (index !== -1)
        acc[index]++;
      return acc;
    }, counts);
  };

  processReportDataPercentage = (tickets, categoriesArrayValues) => {
    const counts = new Array(categoriesArrayValues.length).fill(0);
    //const total = tickets.length
    //if (total===0) return []
    return tickets.reduce((acc, ticket) => {
      const categoryName = ticket.categories&&ticket.categories.length>0?ticket.categories[0].name:undefined
      const index = categoryName?categoriesArrayValues.indexOf(categoryName):categoriesArrayValues.indexOf("ticketNoCategory");
      if (index !== -1)
        acc[index]++;
      return acc;
    }, counts);//.map(e=>{return Math.round((100*e/total)*100)/100});
  };

  processReportDataAverageTime = (tickets, categoriesArrayValues) => {
    const counts = new Array(categoriesArrayValues.length).fill(0);
    const occurrences = new Array(categoriesArrayValues.length).fill(0);
    tickets.forEach(ticket => {
      if (!Number.isInteger(ticket.createdAt) || !Number.isInteger(ticket.updatedAt)) {
        console.error(`Invalid data at ticket index ${idx}:`, ticket);
        return;
      }
      const categoryName = ticket.categories&&ticket.categories.length>0?ticket.categories[0].name:undefined
      const index = categoryName?categoriesArrayValues.indexOf(categoryName):categoriesArrayValues.indexOf("ticketNoCategory");
      if (index !== -1 && Number.isInteger(ticket.createdAt) && Number.isInteger(ticket.updatedAt)) {
        const timeDiff = ticket.updatedAt - ticket.createdAt;
        if (timeDiff >= 0) {
          counts[index] += timeDiff/1000/3600;
          occurrences[index]++;
        }
      }
    });
    const averages = counts.map((total, i) => occurrences[i] > 0 ? total / occurrences[i] : 0);
    return averages.map(e=>{return Math.round(e*100)/100});
  };

  async fetchTicketAnalytics() {
    const { dispatch, tickets } = this.props;

    const ticketsData = tickets?.data?.content?tickets.data.content:[]
    const noCategoryEntry = {
      id: 0,
      name: "ticketNoCategory",
    }
    const categoriesArray = tickets?.ticketCategories?.content?[...tickets.ticketCategories.content,noCategoryEntry]:[noCategoryEntry];
    const categoriesParsed = _.map(categoriesArray.filter(e=>e.name),e=>{
      return {
        id: e.id,
        name: e.name,
        value: e.name,
        label: _.filter(TICKETS_DEFAULT_CATEGORIES,f=>f.value===e.name).length>0?
          _.filter(TICKETS_DEFAULT_CATEGORIES,f=>f.value===e.name)[0].label:e.name,
      }
    })
    const chartLabels = _.map(categoriesParsed,item => {return L20NContext.getSync(item.label)})
    const categoriesArrayValues = _.map(categoriesParsed,item => {return item.value})

    try {
      const chartDataSet = [
        {
          label: L20NContext.getSync('openTicketStatus'),
          backgroundColor: 'rgba('+TICKET_STATUS_COLORS['OPEN']+',0.2)',
          borderColor: 'rgba('+TICKET_STATUS_COLORS['OPEN']+',1)',
          hoverBackgroundColor: 'rgba('+TICKET_STATUS_COLORS['OPEN']+',0.4)',
          hoverBorderColor: 'rgba('+TICKET_STATUS_COLORS['OPEN']+',1)',
          borderWidth: 1,
          data: this.processReportDataStatus(structuredClone(ticketsData.filter(e=>e.status==="OPEN")),categoriesArrayValues),
        },
        {
          label: L20NContext.getSync('pendingTicketStatus'),
          backgroundColor: 'rgba('+TICKET_STATUS_COLORS['PENDING']+',0.2)',
          borderColor: 'rgba('+TICKET_STATUS_COLORS['PENDING']+',1)',
          hoverBackgroundColor: 'rgba('+TICKET_STATUS_COLORS['PENDING']+',0.4)',
          hoverBorderColor: 'rgba('+TICKET_STATUS_COLORS['PENDING']+',1)',
          borderWidth: 1,
          data: this.processReportDataStatus(structuredClone(ticketsData.filter(e=>e.status==="PENDING")),categoriesArrayValues),
        },
        {
          label: L20NContext.getSync('closedTicketStatus'),
          backgroundColor: 'rgba('+TICKET_STATUS_COLORS['CLOSED']+',0.2)',
          borderColor: 'rgba('+TICKET_STATUS_COLORS['CLOSED']+',1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba('+TICKET_STATUS_COLORS['CLOSED']+',0.4)',
          hoverBorderColor: 'rgba('+TICKET_STATUS_COLORS['CLOSED']+',1)',
          data: this.processReportDataStatus(structuredClone(ticketsData.filter(e=>e.status==="CLOSED")),categoriesArrayValues),
        },
        {
          label: L20NContext.getSync('resolvedTicketStatus'),
          backgroundColor: 'rgba('+TICKET_STATUS_COLORS['RESOLVED']+',0.2)',
          borderColor: 'rgba('+TICKET_STATUS_COLORS['RESOLVED']+',1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba('+TICKET_STATUS_COLORS['RESOLVED']+',0.4)',
          hoverBorderColor: 'rgba('+TICKET_STATUS_COLORS['RESOLVED']+',1)',
          data: this.processReportDataStatus(structuredClone(ticketsData.filter(e=>e.status==="RESOLVED")),categoriesArrayValues),
        },
      ];
      const chartDataStatus = {
        labels: chartLabels,
        datasets: chartDataSet,
      };
      this.setState({ chartDataStatus });
    } catch (error) {
      throw error;
    }

    try {
      let colorCounter;
      colorCounter = 0;
      const backgroundColor = _.map(categoriesParsed,e=>{
        if (TICKET_CATEGORY_COLORS[e.value])
          return (
            'rgba('+
            (TICKET_CATEGORY_COLORS[e.value])+
            ',0.2)'
          )
        else {
          return (
            'rgba('+
            (TICKET_CATEGORY_COLORS_OTHERS[colorCounter++>TICKET_CATEGORY_COLORS_OTHERS.length?'128,128,128':colorCounter-1])+
            ',0.2)'
          )
        }
      })
      colorCounter = 0
      const borderColor = _.map(categoriesParsed,e=>{
        if (TICKET_CATEGORY_COLORS[e.value])
          return (
            'rgba('+
            (TICKET_CATEGORY_COLORS[e.value])+
            ',1)'
          )
        else {
          return (
            'rgba('+
            (TICKET_CATEGORY_COLORS_OTHERS[colorCounter++>TICKET_CATEGORY_COLORS_OTHERS.length?'128,128,128':colorCounter-1])+
            ',1)'
          )
        }
      })
      colorCounter = 0
      const hoverBackgroundColor = _.map(categoriesParsed,e=>{
        if (TICKET_CATEGORY_COLORS[e.value])
          return (
            'rgba('+
            (TICKET_CATEGORY_COLORS[e.value])+
            ',0.4)'
          )
        else {
          return (
            'rgba('+
            (TICKET_CATEGORY_COLORS_OTHERS[colorCounter++>TICKET_CATEGORY_COLORS_OTHERS.length?'128,128,128':colorCounter-1])+
            ',0.4)'
          )
        }
      })
      const chartDataSet = [
        {
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          hoverBackgroundColor: hoverBackgroundColor,
          data: this.processReportDataPercentage(structuredClone(ticketsData),categoriesArrayValues),
        },
      ];
      const chartDataPercentage = {
        labels: chartLabels,
        datasets: chartDataSet,
      };
      this.setState({ chartDataPercentage });
      
    } catch (error) {
      throw error;
    }

    try {
      const chartDataSet = [
        {
          label: L20NContext.getSync('allTickets'),
          backgroundColor: 'rgba('+TICKET_STATUS_COLORS['ALL']+',0.2)',
          borderColor: 'rgba('+TICKET_STATUS_COLORS['ALL']+',1)',
          hoverBackgroundColor: 'rgba('+TICKET_STATUS_COLORS['ALL']+',0.4)',
          hoverBorderColor: 'rgba('+TICKET_STATUS_COLORS['ALL']+',1)',
          borderWidth: 1,
          data: this.processReportDataAverageTime(structuredClone(ticketsData.filter(e=>e.status==="CLOSED"||e.status==="RESOLVED")),categoriesArrayValues),
        },
      ];
      const chartDataAverageTime = {
        labels: chartLabels,
        datasets: chartDataSet,
      };
      this.setState({ chartDataAverageTime });
      
    } catch (error) {
      throw error;
    }
  }

  async onExportElements(format) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    await dispatch(TicketActions.exportTickets(format));
    dispatch(UtilsActions.setSpinnerVisibile(false));
  }

  render() {
    const { classes, tickets, themeName, dispatch } = this.props;
    const { chartDataStatus, chartDataPercentage, chartDataAverageTime, windowWidth } = this.state;
    const ticketsData = tickets && tickets.data && tickets.data.content;
    const resolvedTicketsNumber = ticketsData.filter(e=>e.status==="RESOLVED").length
    const closedTicketsNumber = ticketsData.filter(e=>e.status==="CLOSED").length
    const openTicketsNumber = ticketsData.filter(e=>e.status==="OPEN"||e.status==="PENDING").length

    const categoriesArray = tickets?.ticketCategories?.content?tickets.ticketCategories.content:[];
    const categoriesParsed = _.map(categoriesArray.filter(e=>e.name),e=>{
      return {
        id: e.id,
        name: e.name,
        value: e.name,
        label: _.filter(TICKETS_DEFAULT_CATEGORIES,f=>f.value===e.name).length>0?
          _.filter(TICKETS_DEFAULT_CATEGORIES,f=>f.value===e.name)[0].label:e.name,
      }
    })
    const categoriesParsedForFilters = [{id:0,name:"ALL",value:"ALL",label:"ticketCategory"},...categoriesParsed]

    return (
      <div style={{ backgroundColor: 'white'}}>
        <TicketsFilters
          themeName={themeName}
          fromTicketAnalytics={true}
          categoriesArray={categoriesParsedForFilters}
          onResetFilters={() => this.onResetFilters()}
        />
        <div style={{ top: 200, position:'fixed', height: '120vh', paddingBottom: 50, overflowY: 'scroll', overflowX: 'hidden' }}>
          <div style={{ padding: 25, paddingBottom: 200, paddingRight: 0, width:'100%' }}>
            <Grid container spacing={2} className={classes.chartsContainer} style={{marginBottom:20}}>
              <Grid item md={3} xs={12}>
                <MetricCard
                  title={<Entity entity="tickets" />}
                  metricNumber={_.size(ticketsData)}
                  icon={<MessageAlertIconCustom style={{ width: 30 }} />}
                />
                </Grid>
                <Grid item md={3} xs={12}>
                  <MetricCard
                    title={<Entity entity="ticketsOpenOrPending" />}
                    metricNumber={openTicketsNumber}
                    icon={<WarningOutlineIconCustom style={{ width: 30 }} />}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <MetricCard
                    title={<Entity entity="ticketsResolved" />}
                    metricNumber={resolvedTicketsNumber}
                    icon={<CheckInIcon style={{ fontSize: 30 }} />}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <MetricCard
                    title={<Entity entity="ticketsClosed" />}
                    metricNumber={closedTicketsNumber}
                    icon={<CloseCircleIconCustom style={{ width: 30 }} />}
                  />
                </Grid>
            </Grid>
            {ticketsData && !_.isEmpty(ticketsData) ? (
              <div style={{display:'flex', flexDirection:'column', paddingLeft:20, paddingRight: 20}}>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                  <Card style={{ borderRadius: 10, marginTop: 10 }}>
                    <h3 style={{ color: '#3f3f3f', margin: 0, fontWeight: 'bold', marginTop: 10, marginBottom: 10, textAlign: 'center' }}><Entity entity="ticketStatusCategoryChartTitle" /></h3>
                    <ScrollableBarChart
                      data={chartDataStatus}
                      dataSetSize={1}
                      showLegend={true}
                      yAxeTitle={L20NContext.getSync('tickets')}
                      chartMaxWidth={window.screen.width - 520}
                      conainerStyle={{ marginRight: 200 }}
                    />
                  </Card>
                  <Card style={{ borderRadius: 10, marginTop: 10, padding: 20, marginLeft: 20,}}>
                    <h4 style={{ color: '#3f3f3f', margin: 0, fontWeight: 'bold', marginBottom: 10, textAlign: 'center' }}><Entity entity="ticketStatusCategoryDistribution" /></h4>
                    <PieChart
                      chartData={_.cloneDeep(chartDataPercentage)}
                      showLegend
                      height={300}
                      legendPosition="bottom"
                      onElementClick={(chartElement) => {}}
                    />
                  </Card>
                </div>
                <Card style={{ borderRadius: 10, marginTop: 20, paddingTop: 20 }}>
                  <h3 style={{ color: '#3f3f3f', margin: 0, fontWeight: 'bold', marginTop: 10, marginBottom: 10, textAlign: 'center' }}><Entity entity="averageResponseTimeChartTitle" /></h3>
                  <ScrollableHorizontalBarChart
                    height={100}
                    data={chartDataAverageTime}
                    dataSetSize={1}
                    xAxeTitle={L20NContext.getSync('ticketsAverageClosingTime')}
                    chartMaxWidth={1200}
                    containerStyle={{ paddingTop: 10}}
                  />
                </Card>
              </div>
            ) : <EmptyAnalyticsView />}
          </div>
        </div>
      </div>
      
    );
  }
} 

export default withStyles(styles)(TicketsAnalytics);
