// @ts-nocheck
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { withStyles } from '@material-ui/core';


const styles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 1.5,
    marginRight: 10,
  },
});

class MDTablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(event, this.props.lastPage);
  };

  render() {
    const { classes, count, page, iconColor, rowsPerPage } = this.props;
    const previousDisabled = page === 0;
    const nextDisabled = page >= Math.ceil(count / rowsPerPage) - 1;
    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={previousDisabled}
          style={{ color: previousDisabled ? 'gray' : iconColor }}
          aria-label="First Page"
        >
          <FirstPageIcon style={{ fontSize: 25 }} />
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={previousDisabled}
          style={{ color: previousDisabled ? 'gray' : iconColor }}
          aria-label="Previous Page"
        >
          <KeyboardArrowLeft style={{ fontSize: 25 }} />
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={nextDisabled}
          style={{ color: nextDisabled ? 'gray' : iconColor }}
          aria-label="Next Page"
        >
          <KeyboardArrowRight style={{ fontSize: 25 }} />
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          style={{ color: nextDisabled ? 'gray' : iconColor }}
          aria-label="Last Page"
        >
          <LastPageIcon style={{ fontSize: 25 }} />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(MDTablePaginationActions);
