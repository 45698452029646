import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { Field, initialize, change } from 'redux-form';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import WarningSignIcon from '@material-ui/icons/Warning';
import InfiniteScroll from 'react-infinite-scroller';
import {
  Icon,
} from '@sketchpixy/rubix';
import TagsSelectorField from '../Fields/TagsSelectorField.jsx';
import CustomField from '../Fields/CustomField.jsx';
import Tag from '../../Tag.jsx';
import { MATCH_TAG_MODE, VENDORS_LIST } from '../../../_config/consts';
import ListLoadingView from '../../ListLoadingView.jsx';
import MDRadioButtonsGroupField from './MDRadioButtonsGroup/MDRadioButtonsGroupField.js';


const ItemRow = ({ item, onSelect, isEaven, hasError, isSelected }) => {
  const fakeUserTag = {
    color: '#ba68c8',
    name: item && item.username ? item.username.toLowerCase() : '',
    type: 'user',
  };
  let backgroundColor = !isEaven ? '#FAFAFA' : 'white';
  backgroundColor = hasError ? '#ff7f7f' : backgroundColor;

  return (
    <div
      onClick={onSelect || null}
      className="card-noHover tag-element-selected"
      style={{ display: 'flex', zIndex: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 5, marginBottom: 0, marginTop: 0, cursor: onSelect ? 'pointer' : 'normal', backgroundColor: isSelected ? '#00800036' : backgroundColor }}
    >
      <div onClick={onSelect} style={{ display: 'flex', alignItems: 'center' }}>
        {onSelect &&
        <div>
          {isSelected ?
            <Icon
              glyph="icon-fontello-ok-circled"
              style={{ fontSize: 14, marginRight: 15, color: '#00987C' }}
            />
          :
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 5.5 }}>
              <div style={{ marginBottom: 2, width: 13, height: 13, borderRadius: 6.5, borderStyle: 'solid', borderWidth: 2, borderColor: 'gray' }} />
            </div>
            }
          </div>
        }
        <h5 style={{ margin: 0, color: '#3f3f3f' }}>{item.name || `${item.firstname} ${item.lastname}`}{item.email ? <h6 style={{ margin: 0, marginTop: 2, color: '#3f3f3f' }}>{item.email}</h6> : null}</h5>
        
      </div>
      <div className="tags-container" style={{ margin: 0, marginLeft: 55 }}>
        {item && item.username &&
          <Tag textStyle={{ fontSize: 12 }} style={{ paddingTop: 4, paddingBottom: 4 }} tag={fakeUserTag} />
        }
        {_.map(item.tags, tag =>
          <Tag
            textStyle={{ fontSize: 12 }}
            key={tag.id}
            tag={tag}
          />)}
      </div>
    </div>
  );
};

@connect()
class LocksSelectorField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: true,
      itemsSelected: props.initialValues || [],
    };
  }

  toggleListVisibility() {
    const { showList } = this.state;
    this.setState({ showList: !showList });
  }

  onItemSelected(item) {
    const { isSingleSelection, onSelectItem } = this.props;
    const { itemsSelected } = this.state;
    if (item) {
      if (_.find(itemsSelected, i => i.id === item.id)) { // if already selected
        const finalItems = _.filter(itemsSelected, i => i.id !== item.id);
        this.setState({ itemsSelected: finalItems }, () => onSelectItem(finalItems));
      } else if (isSingleSelection) {
        this.setState({ itemsSelected: [item] }, () => onSelectItem([item]));
      } else {
        const finalItems = [...itemsSelected, item];
        this.setState({ itemsSelected: finalItems }, () => onSelectItem(finalItems));
      }
    }
  }

  componentDidMount() {
    const { selectedTags, formName, selectedTagsFieldName, dispatch } = this.props;
    const isNotEmptySet = selectedTags && selectedTags.data && !_.isEmpty(selectedTags.data);
    if (!isNotEmptySet) {
      dispatch(change(formName, selectedTagsFieldName, []));
    } else {
      dispatch(change(formName, selectedTagsFieldName, selectedTags.data));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { initialValues: oldInitialValues, selectedTags: oldSelectedTags } = this.props;
    const { initialValues, selectedTags: newSelectedTags, formName, selectedTagsFieldName, dispatch } = nextProps;
    if (initialValues && oldInitialValues !== initialValues) {
      this.setState({ itemsSelected: initialValues });
    }
    if (formName && (!oldSelectedTags && newSelectedTags && newSelectedTags.data) || (selectedTagsFieldName && newSelectedTags && newSelectedTags.data && oldSelectedTags && oldSelectedTags.data && _.size(oldSelectedTags.data) !== _.size(newSelectedTags.data))) {
      dispatch(change(formName, selectedTagsFieldName, newSelectedTags));
    }
  }

  lockHasErrorCondition(lock) {
    return lock.vendor === VENDORS_LIST.ISEO_VEGA;
  }

  render() {
    const { name, form, tags, hideBottomSelectionSection, tagsType, lockHasErrorCondition, placeholder, isOptional, isSingleChoice, listTitle, emptyTagSelectionText, title, selectedTags, onTagsChange, onInputChange, onFilterModeChange, onFetchMoreItems, onSelectItem, selectionSectionTitle, selectionSectionSubtitle, selectionSectionEmptySubtitle, disabled } = this.props;
    const { showList, itemsSelected } = this.state;
    const hasElements = selectedTags && selectedTags.data && !_.isEmpty(selectedTags.data);
    const isEmptySet = selectedTags && selectedTags.data && _.isEmpty(selectedTags.data);
    const tagsPagination = selectedTags && selectedTags.pagination;
    return (
      <div>
        <div>
          <Entity
            name={name}
            multi={!isSingleChoice}
            componentClass={Field}
            componentAttribute="placeholder"
            entity={placeholder || 'insertItemsTags'}
            title={title || <Entity entity="genericTags" />}
            tags={tags}
            isDisabled={disabled}
            component={TagsSelectorField}
            tagType={tagsType}
            mandatory={!isOptional}
            onInputChange={value => onInputChange(value)}
            onHandleChange={values => onTagsChange(values)}
          />
          {onFilterModeChange &&
            <div style={{ marginLeft: 5 }}>
              <Field
                name="lockTagMatchingMode"
                title={<Entity entity="daysOfTheWeek" />}
                component={MDRadioButtonsGroupField}
                onHandleChange={values => onFilterModeChange(values)}
                containerstyle={{ paddingLeft: 15, marginTop: 10 }}
                horizontal
                options={[
                  {
                    label: <Entity entity="matchOneTag" />,
                    value: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
                  },
                  {
                    label: <Entity entity="matchEveryTag" />,
                    value: MATCH_TAG_MODE.EVERY_TAG,
                  },
                ]}
                disabled={disabled}
              />
            </div>
          }
          {hasElements &&
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
                <h4 style={{ fontSize: 15 }}><Entity entity={listTitle} /></h4>
                <h5 style={{ marginLeft: 5, marginTop: 14 }}>{`(${tagsPagination && tagsPagination.totalElements ? tagsPagination.totalElements : 0})`}</h5>
              </div>
              <div onClick={() => this.toggleListVisibility()} style={{ cursor: 'pointer', marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h5 className="link-label">
                  <Entity key={showList} entity={showList ? 'hide' : 'show'} />
                </h5>
                <Icon
                  style={{ marginLeft: 5, color: '#90CAF9' }}
                  className="edit-icon"
                  glyph={showList ? 'icon-fontello-eye-off' : 'icon-fontello-eye-1'}
                />
              </div>
            </div>
          }
        </div>
        <div>
          {hasElements && showList &&
            <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
              <div style={{ maxHeight: 200, overflowY: 'scroll' }}>
                <InfiniteScroll
                  pageStart={0}
                  useWindow={false}
                  initialLoad={false}
                  loadMore={() => onFetchMoreItems(tagsPagination.number + 1)}
                  hasMore={tagsPagination && tagsPagination.number + 1 < tagsPagination.totalPages}
                  loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    {_.map(selectedTags.data, (item, index) =>
                      <ItemRow
                        key={item.id}
                        item={item}
                        hasError={lockHasErrorCondition ? lockHasErrorCondition(item) : false}
                        isEaven={index % 2 === 0}
                        isSelected={itemsSelected && _.find(itemsSelected, i => i.id === item.id)}
                        onSelect={onSelectItem ? () => this.onItemSelected(item) : null}
                      />)}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
            }
        </div>
        {isEmptySet && emptyTagSelectionText &&
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <WarningSignIcon style={{ color: 'orange', fontSize: 20 }} />
            <h5 style={{ marginLeft: 10, marginTop: 12, color: 'orange' }}><Entity entity={emptyTagSelectionText} /></h5>
          </div>
        }
        <div>
        {onSelectItem && !hideBottomSelectionSection &&
          <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column' }}>
            <h4 style={{ marginTop: 0, color: '#3f3f3f', marginBottom: 10, fontWeight: 'bold' }}><Entity entity={selectionSectionTitle || 'selectionSectionTitle'} /></h4>
            <h5 style={{ marginTop: 0, color: '#3f3f3f', fontWeight: 200 }}>
              <Entity key={itemsSelected && !_.isEmpty(itemsSelected)} entity={itemsSelected && !_.isEmpty(itemsSelected) ? selectionSectionSubtitle || 'selectionSectionSubtitle' : selectionSectionEmptySubtitle || 'selectionSectionEmptySubtitle'} /></h5>
            {_.map(itemsSelected, (item, index) => <ItemRow key={index} item={item} />)}
          </div>
        }
        </div>
      </div>
    );
  }
} 

export default LocksSelectorField;
