/* eslint-disable react/prop-types */
// @ts-nocheck
import { Card, CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import BarChartIcon from '@material-ui/icons/InsertChart';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import * as CloudCreditsActions from '../../redux/actions/cloudCredits.actions';
import { BLUE, GREEN } from '../../_config/consts';
import { elaborateConsumptionChartData, getColorFromThemeName } from '../../_config/utils';
import ScrollableBarChart from '../Charts/ScrollableBarChart.jsx';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';
import WalletChartFilters from './ChartFilters/WalletChartFilters.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 20,
    paddingTop: 30,
    justifyContent: 'center',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  chartContainer: {
    paddingTop: 10,
  },
  refreshHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 25,
    color: '#3f3f3f',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px #f1f1f1 solid',
    marginTop: 10,
    paddingBottom: 10,
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 5,
    marginLeft: 0,
  },
  listBodyContainer: {
    maxHeight: 400,
    overflow: 'scroll',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const WalletConsumptionList = ({ creditUsageData, classes }) => {
  return (
    <div className={classes.listBodyContainer}>
      {creditUsageData && !_.isEmpty(creditUsageData) ? (
        <div>
          {_.map(creditUsageData, (walletUsage) => (
            <div className={classes.row}>
              <div className={classes.rowBodyContainer}>
                <h4 className={classes.rowTitle}>{<Entity entity="creditConsumptionTypeDescription" data={{ type: walletUsage.usageType }} />}</h4>
                <h5 className={classes.tableBodyText} style={{ fontSize: 16, marginTop: 10 }}>{<Entity entity="consumptionCreditsDescription" data={{ credits: walletUsage.quantity }}/>}</h5>
              </div>
              <h5 className={classes.tableBodyText} style={{ color: '#3f3f3f' }}>{moment(walletUsage.createdAt).format('LL')}</h5>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

const EmptyAnalyticsView = () => (
  <div style={{ height: 100, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <BarChartIcon style={{ color: '#bdbdbd', fontSize: 50 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFound" /></h3>
  </div>
);

@connect(state => ({ creditUsage: state.cloudCredits.wallet.usage, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class WalletConsumptionSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isRefreshing: false,
    };
  }

  onRefreshData() {
    const { dispatch } = this.props;
    dispatch(CloudCreditsActions.fetchWalletCreditsUsage());
  }

  onExportConsumptionData(format) {
    const { dispatch } = this.props;
    dispatch(CloudCreditsActions.exportWalletUsageData(format));
  }

  onFilterData(filters) {
    const { dispatch } = this.props;
    const { fromDate, toDate } = filters;
    dispatch(CloudCreditsActions.setWalletUsageFilter('fromDate', fromDate));
    dispatch(CloudCreditsActions.setWalletUsageFilter('toDate', toDate));
    dispatch(CloudCreditsActions.fetchWalletCreditsUsage());
  }

  render() {
    const { creditUsage, classes, themeName } = this.props;
    const { isRefreshing } = this.state;
    const consumptionData = elaborateConsumptionChartData(creditUsage.content);
    const textColor = getColorFromThemeName(themeName);
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1, marginRight: 20 }}>
          <div className={classes.header}>
            <div className={classes.refreshHeader}>
              <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="consumptionHistory" /></h3>
            </div>
            <div className={classes.refreshHeader}>
              <SimpleExportMenu
                titleContainerStyle={{ marginRight: 25, marginTop: 5 }}
                iconStyle={{ color: textColor || GREEN }}
                exportTitleStyle={{ color: textColor || GREEN }}
                onExportLogs={format => this.onExportConsumptionData(format)}
              />
              <IconButton
                disabled={isRefreshing}
                style={{ opacity: isRefreshing ? 0.5 : 1 }}
                onClick={() => this.onRefreshData()}
              >
                <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
              </IconButton>
            </div>
          </div>
          <WalletChartFilters
            onSearch={(filters) => this.onFilterData(filters)}
          />
          <div className={classes.chartContainer}>
            {creditUsage.content && !_.isEmpty(creditUsage.content) ? (
              <ScrollableBarChart
                height={100}
                data={_.cloneDeep(consumptionData)}
                dataSetSize={24}
                showLegend={false}
              />
              
            ) : <EmptyAnalyticsView />}
          </div>
          {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
          <WalletConsumptionList
            creditUsageData={creditUsage.content}
            classes={classes}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}


export default withStyles(styles)(WalletConsumptionSection);
