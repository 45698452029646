module.exports = {
  SAVE_ACCESS_PROFILES: 'SAVE_ACCESS_PROFILES',
  APPEND_ACCESS_PROFILES: 'APPEND_ACCESS_PROFILES',
  SAVE_ACCESS_PROFILE: 'SAVE_ACCESS_PROFILE',
  SET_ACCESS_PROFILES_PAGINATION_DATA: 'SET_ACCESS_PROFILES_PAGINATION_DATA',
  SELECT_ACCESS_PROFILE: 'SELECT_ACCESS_PROFILE',
  SET_ACCESS_PROFILES_FILTER: 'SET_ACCESS_PROFILES_FILTER',
  UPDATE_ACCESS_PROFILE: 'UPDATE_ACCESS_PROFILE',
  RESET_ACCESS_PROFILES_FILTERS: 'RESET_ACCESS_PROFILES_FILTERS',
  RESET_ACCESS_PROFILES_DATA: 'RESET_ACCESS_PROFILES_DATA',
  RESET_ACCESS_PROFILE_PAGINATION_DATA: 'RESET_ACCESS_PROFILE_PAGINATION_DATA',
  SET_ACCESS_PROFILES_OPERATIONAL_MODE: 'SET_ACCESS_PROFILES_OPERATIONAL_MODE',
  SAVE_ACCESS_PROFILE_GUESTS_DATA: 'SAVE_ACCESS_PROFILE_GUESTS_DATA',
  APPEND_ACCESS_PROFILE_GUESTS_DATA: 'APPEND_ACCESS_PROFILE_GUESTS_DATA',
  SAVE_ACCESS_PROFILE_GUESTS_DATA_PAGINATION: 'SAVE_ACCESS_PROFILE_GUESTS_DATA_PAGINATION',
  RESET_ACCESS_PROFILE_GUESTS_DATA: 'RESET_ACCESS_PROFILE_GUESTS_DATA',
};
