// @ts-nocheck
import { Card, CircularProgress, createTheme, IconButton, LinearProgress, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import {
  FormControl
} from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { BLUE, GREEN, ORANGE } from '../../_config/consts';
import AreasCapacityIconCustom from '../CustomIcons/AreasCapacityIconCustom.jsx';
import MDAccordion from '../MDAccordion/MDAccordion.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  containerCollapsed: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    paddingBottom:0,
    marginTop: 20,
    alignItems: 'center',
    position: 'relative',
    transition: 'max-height 1s ease',
  },
  containerExpanded: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    padding: 15,
    paddingBottom:0,
    marginTop: 20,
    position: 'relative',
    overflow: 'scroll',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  progressAreaOccupancy: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '30px !important',
    width: '30px !important',
  },
  headerText: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
  },
  areasContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom:10,
    marginBottom:45,
    width: 'max-content'
  },
  areaContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: 10,
    padding: 25,
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
    alignItems: 'flex-start',
    overflowY: 'scroll',
    backgroundColor: 'rgb(250, 250, 250)'
  },
  refreshHeader: {
    position: 'absolute',
    right: 10,
    top: 5,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '30%',
    marginTop: 10,
    borderRadius: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  tableColumnText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  barColorPrimary: {
    backgroundColor: '#009688',
  },
  barColorError: {
    backgroundColor: '#e45353',
  },
  barColorWarning: {
    backgroundColor: ORANGE,
  },
  barColorLoading: {
    backgroundColor: '#AAAAAA',
  },
  expandIcon: {
    width:50,
    height:50,
  },
  expandIconContainer: {
    '&:hover': {
      background: 'linear-gradient(to left, rgba(230, 230, 230, 0) 0%, rgba(230, 230, 230, 0.8) 50%, rgba(230, 230, 230, 0) 100%)',
    }
  }
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});


class AreasListView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredAreas: props.areas,
      filterText: '',
      expanded: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { areas: newAreas } = nextProps;
    const { areas: oldAreas } = this.props;
    if (newAreas !== oldAreas) {
      this.setState({ filteredAreas: newAreas });
    }
  }

  onSearchAreas(text) {
    const { areas } = this.props;
    this.setState({ filterText: text });
    const filteredAreas = _.filter(areas, area => area.name.toLowerCase().indexOf(text) > -1);
    this.setState({ filteredAreas });
  }

  onSearchReset() {
    const { areas } = this.props;
    this.setState({ filterText: '', filteredAreas: areas });
  }

  manageCardExpansion() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const { areas, users, classes, textColor, isRefreshingAccessChartData, lastUpdate, onShowGuestLogs, onRefreshMetric, areasOccupancy } = this.props;
    const { filteredAreas, filterText } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.containerExpanded} elevation={3}
          style={{ opacity: isRefreshingAccessChartData ? 0.5 : 1 }}>
          <div className={classes.header}>
          <AreasCapacityIconCustom style={{ width: 35, color: textColor || '#3f3f3f' }} />
            <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f' }}><Entity entity="areaCapacityMonitoring" /></h3>
          </div>
          {areas && !_.isEmpty(areas) ? (
            <div className={classes.searchHeader}>
              <Card className={classes.searchContainer}>
                <FormControl
                  type="text"
                  value={filterText}
                  placeholder={L20NContext.getSync('searchAreaByTitle')}
                  className="form-control-noBorder"
                  style={{ width: '100%', borderRadius: 5 }}
                  onChange={e => this.onSearchAreas(e.target.value)}
                />
                {filterText ? (
                  <IconButton style={{ height: 20, width: 20, marginLeft: -60, marginRight: 10 }} onClick={() => this.onSearchReset()}>
                    <CloseIcon />
                  </IconButton>
                ) : null}
                <SearchIcon style={{ marginLeft: filterText ? 0 : -30 }} />
              </Card>
            </div>
          ) : null}
          <div style={{ overflow: 'scroll' }}>
            <div className={classes.areasContainer}>
              {_.map(filteredAreas, (area) => {
                const areasOccupancySearch = areasOccupancy.filter(e=>e.id===area.id)
                const currentAreaOccupancy = areasOccupancySearch.length?areasOccupancySearch[0]:undefined

                const currentAreaOccupancyUsersNumber = currentAreaOccupancy?currentAreaOccupancy.userIds.length:0;
                const currentAreaCapacity = area.capacity&&area.capacity>0?area.capacity:1;
                const currentAreaOccupancyPercentage = 100*(currentAreaOccupancyUsersNumber?currentAreaOccupancyUsersNumber/currentAreaCapacity:0)
                
                const currentAreaOccupancyUsersList = [
                  ...(currentAreaOccupancy&&currentAreaOccupancy.userIds?currentAreaOccupancy.userIds.map(e=>{return {id:e,active:true}}):[]),
                  ...(currentAreaOccupancy&&currentAreaOccupancy.expiredIds?currentAreaOccupancy.expiredIds.map(e=>{return {id:e,active:false}}):[]),
                ]
                
                const isWarning = currentAreaOccupancyPercentage>=75
                const isError = currentAreaOccupancyPercentage>=100

                let barClassCss = isWarning ? classes.barColorWarning : classes.barColorPrimary;
                barClassCss = isError ? classes.barColorError : barClassCss;
                barClassCss = currentAreaOccupancy ? barClassCss : classes.barColorLoading
                return(
                  <Card className={classes.areaContainer} elevation={1}>
                    <div style={{width:'100%',display:'flex',flexDirection:'column',position:'sticky',top:0,backgroundColor:'rgb(250, 250, 250)'}}>
                      <h3 style={{fontWeight:'bold',fontSize:20,marginTop:5}}>{area.name}</h3>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%', marginTop:10, marginBottom:10}}>
                        <h3 style={{fontWeight:'bold',fontSize:18,color:textColor,margin:0,marginRight:10}}>{!currentAreaOccupancy ? '-' : currentAreaOccupancyUsersNumber+"/"+currentAreaCapacity}</h3>
                        <LinearProgress
                          variant={currentAreaOccupancy? 'determinate' : 'indeterminate'}
                          value={Math.min(100,currentAreaOccupancyPercentage)}
                          style={{ height: 15, borderRadius: 10, width:'100%' }}
                          classes={{ barColorPrimary: barClassCss }}
                        />
                      </div>
                    </div>
                    <div style={{width:'100%'}}>
                      {!currentAreaOccupancy?(
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:150,width:'100%'}}>
                          <CircularProgress color="primary" className={classes.progressAreaOccupancy} />
                        </div>
                      ):null}
                      {currentAreaOccupancyUsersList.length?(
                        <MDAccordion
                          title={(
                            <div>
                              <h4 className={classes.rowTitle} style={{ fontSize: 15 }}><Entity entity="guests" /></h4>
                            </div>
                          )}
                          containerstyle={{ marginTop: 10, marginLeft: 2 }}
                          innerContainerStyle={{ backgroundColor: '#fdfdfd', paddingTop: 0, margin: 0 }}
                          titleStyle={{ fontSize: 15, margin: 0 }}
                          iconStyle={{ fontSize: 17 }}
                          defaultExpanded={false}
                          elevation={1}
                        >
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="guest" /></h5></TableCell>
                              <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="lastAreaInTimestamp" /></h5></TableCell>
                              <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="lastAreaInLock" /></h5></TableCell>
                              <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="areaUserStatus" /></h5></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {_.map(currentAreaOccupancyUsersList, (user) => {
                              const isActive = user.active;
                              const userSearch = users.filter(e=>e.id===user.id)
                              const currentUser = userSearch?userSearch[0]:undefined
                              if (!currentUser)
                                return null
                              const lockInEvents = currentUser.guestEvents.filter(e=>area.smartLocksInIds.some(f=>f===e.lockId))
                              const lastAccessTimestamp = _.last(lockInEvents) && _.last(lockInEvents).timestamp;
                              const lastAccessLockName = _.first(lockInEvents) && _.first(lockInEvents).data && _.first(lockInEvents).data.lock && _.first(lockInEvents).data.lock.name;
                              return (
                              <TableRow
                                key={currentUser.id}
                                className={classes.row}
                                hover
                                classes={{
                                  selected: classes.rowSelected,
                                  hover: classes.rowHovered,
                                }}
                                onClick={() => onShowGuestLogs(currentUser)}
                              >
                                <TableCell>
                                  <h5 style={{ color: '#3f3f3f' }}>
                                    {currentUser.invitationCode ? <Entity entity="invitationCodeLog" data={{ code: currentUser.invitationCode }} /> :
                                  (
                                    `${currentUser.firstname || '--'} ${currentUser.lastname || '--'} - ${currentUser.email || '--'}`
                                  )}
                                  </h5>
                                </TableCell>
                                <TableCell>
                                  <h5 style={{ margin: 0, color: '#3f3f3f' }}>{lastAccessTimestamp ? moment(lastAccessTimestamp).format('LLL') : '---'}</h5>
                                </TableCell>
                                <TableCell>
                                  <h5 style={{ margin: 0, color: '#3f3f3f' }}>{lastAccessLockName ? `${lastAccessLockName}` : '---'}</h5>
                                </TableCell>
                                <TableCell>
                                  <Card style={{padding:5,display:'flex',alignItems:'center',justifyContent:'center',
                                    backgroundColor:isActive?GREEN:'grey',opacity:'0.7'}}>
                                    <h5 style={{ margin: 0, color: '#3f3f3f', fontWeight:'bold', color:'white' }}>
                                      {`${L20NContext.getSync(isActive?'areaUserActive':'areaUserExpired')}`}
                                    </h5>
                                  </Card>
                                </TableCell>
                              </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                        </MDAccordion>
                      ):(
                        currentAreaOccupancy?(
                          <div style={{display:'flex', alignItems:'center', marginTop: 20, justifyContent:'center', width:'100%' }}>
                            <h4 style={{ fontStyle:'italic', fontSize:15 }}>{L20NContext.getSync('areaIsEmpty')}</h4>
                          </div>
                      ):null)}
                    </div>
                  </Card>
                )
              })}
            </div>
          </div>
          <div className={classes.refreshHeader}>
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}:{moment(lastUpdate).format('LLL')}</h5>
            <IconButton
              disabled={isRefreshingAccessChartData}
              style={{ opacity: isRefreshingAccessChartData ? 0.5 : 1 }}
              onClick={() => onRefreshMetric('smartLocksEvents')}
            >
              <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
            </IconButton>
          </div>
          {isRefreshingAccessChartData ? <CircularProgress color="primary" className={classes.progress} /> : null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(AreasListView);
