import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MapResourcePoint from './MapResourcePoint.jsx';
import MapResourceViewPoint from './MapResourceViewPoint.jsx';


export default function ResourceMapViewer({ mapResources, hideCard, availableResources, onBookResource }) {
  const [dropCanvasBbox, setDropCanvasBbox] = useState({});
  const domainMedia = useSelector((state) => state.domainMedia.selectedDomainMedia);
  const resources = mapResources ? mapResources : useSelector((state) => state.resources.data);
  const imageSource = domainMedia && domainMedia.data ? `data:image/jpeg;base64,${domainMedia.data}` : '';

  const setDropCanvasBBoxInState = () => {
    const dropCanvas = document.getElementById("dropCanvas");
    setDropCanvasBbox(dropCanvas ? dropCanvas.getBoundingClientRect() : {});
  }

  useEffect(() => {
    setDropCanvasBBoxInState();
    window.addEventListener('resize', setDropCanvasBBoxInState);
    return () => window.removeEventListener('resize', setDropCanvasBBoxInState);
  }, []);

  useEffect(() => {
    setDropCanvasBBoxInState();
  }, [domainMedia]);

  return (
    <div className={`floorMapCanvasContainer ${hideCard || "card-noHover"}`} style={{  overflow: 'scroll', position: 'relative', margin: 20, marginLeft:0, marginTop: 0, padding: 10, height: '95vh', zIndex: 0 }}>
      <img id="dropCanvas" src={imageSource} style={{ height: '100%', display: 'flex', flex: 1, objectFit: 'contain' }} onLoad={() => setDropCanvasBBoxInState()} />
      {_.map(resources && resources.content, (resource) =>  {
        if (!resource.locationCoordinates || !resource.locationMedia || resource.locationMedia.id !== domainMedia.id) {
          return null;
        }
        const resourceX = resource.locationCoordinates.split(';')[0];
        const resourceY = resource.locationCoordinates.split(';')[1];
        return dropCanvasBbox ? (
          <MapResourceViewPoint
            key={resource.id}
            resource={resource}
            x={resourceX * dropCanvasBbox.width}
            y={resourceY * dropCanvasBbox.height }
            isAvailable={_.find(availableResources, availableResource => availableResource.id === resource.id)}
            onBookResource={() => onBookResource(resource)}
        />
        ) : null
      })}
    </div>
  )
}
