import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { GREEN, RED, SUBSCRIPTION_TYPES } from '../../../_config/consts';
import { localizeHelpCenterLink } from '../../../_config/utils.js';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as UserActions from '../../../redux/actions/user.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import CheckOutlineIconCustom from '../../CustomIcons/CheckOutlineIconCustom.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import { IconButton } from '@material-ui/core';
import CopyIconCustom from '../../CustomIcons/CopyIconCustom.jsx';


@connect(state => ({ language: state.settings.language, integrations: state.user.activeIntegration }))
class ZapfloorSettingsOperationalSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isActive: null,
      additionalInfo: null
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const isActive = dispatch(UserActions.userHasIntegrationActive(SUBSCRIPTION_TYPES.ZAPFLOOR_INTEGRATION));
    const additionalInfo = dispatch(UserActions.getIntegrationAdditionalInfo(SUBSCRIPTION_TYPES.ZAPFLOOR_INTEGRATION));
    this.setState({ isActive, additionalInfo });
  }


  onDeactivateIntegrationRequest() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="disableIntegrationConfirm" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeactivateIntegration(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeactivateIntegration() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const deactivationDTO = dispatch(UserActions.getActiveIntegration(SUBSCRIPTION_TYPES.ZAPFLOOR_INTEGRATION));
      if (deactivationDTO && deactivationDTO.id) {
        await dispatch(UserActions.disableIntegration(deactivationDTO.id));
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(UserActions.fetchCurrentActiveIntegrations());
        this.setState({ isActive: false });
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedSuccess" /></h6>),
          },
        }));
      } else {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
          },
        }));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
        },
      }));
    }
  }

  onOpenActivationInfo() {
    const helpURL = localizeHelpCenterLink('activate-optix-integration');
    window.open(helpURL);
  }

  onGoBackToLogin() {
    this.setState({ resourceTypes: null, optixToken: null })
  }

  async onActivateIntegration() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(UserActions.activateZapfloorIntegration());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(UserActions.fetchCurrentActiveIntegrations());
      this.setState({ isActive: true });
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="integrationActivateSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
        },
      }));
    }
  }

  render() {
    const { isActive, additionalInfo } = this.state;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25, paddingTop: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <img alt="nexudus" src="/imgs/common/integrationLogos/zapfloor-logo.png" style={{ height: 90, alignSelf: 'center' }} />
        {isActive ? (
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <div style={{ width: '70%', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CheckOutlineIconCustom style={{ color: GREEN, width: 30, marginRight: 10 }} />
              <h4 style={{ color: GREEN, fontWeight: 'bold', fontSize: 20 }}><Entity entity="integrationCorrectlyConfigured" /></h4>
            </div>
            {additionalInfo && additionalInfo.url && additionalInfo.token ?  (
              <div style={{ margin: 20, marginLeft: 30, width: '90%', marginRight: 30, padding: 20, borderRadius: 10, backgroundColor: '#f0f0f0'  }}>
                <h4 style={{ marginBottom: 0, fontWeight: 'bold' }}><Entity entity="integrationConnectedSystem" /></h4>
                <h4 style={{ marginTop: 0 }}>{additionalInfo.url} <CopyToClipboard text={additionalInfo.url}>
                <IconButton>
                  <CopyIconCustom style={{ width: 20, color: '#3f3f3f' }} />
                </IconButton>
                </CopyToClipboard></h4>
                <h4 style={{ marginBottom: 0, fontWeight: 'bold' }}><Entity entity="integrationAPIKey" /></h4>
                <h4 style={{ marginTop: 0, marginBottom: 0 }}>{additionalInfo.token} <CopyToClipboard text={additionalInfo.token}>
                <IconButton>
                  <CopyIconCustom style={{ width: 20, color: '#3f3f3f' }} />
                </IconButton>
                </CopyToClipboard></h4>
              </div>
            ): null}
            <MDButton
              title={<Entity key={isActive} entity="disableIntegrationTitle" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor={RED}
              containerStyle={{ margin: 0, marginTop: 10, width: '80%' }}
              onClick={() => this.onDeactivateIntegrationRequest()}
            />
          </div>
        ) : (
            <div style={{ width: '70%', margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h4 style={{ margin: 0, marginTop: 20, marginBottom: 10 }}><Entity style={{ fontWeight: 'bold' }} entity="activateIntegrationTitle" data={{ integrationName: 'Zapfloor'}} /></h4>
            <h4 style={{ margin: 0, textAlign: 'center' }}><Entity entity="activateTenupIntegrationDescription" data={{ integrationName: 'Zapfloor'}} /></h4>
            <MDButton
              title={<Entity key={isActive} entity="activate" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor={GREEN}
              containerStyle={{ margin: 0, marginTop: 20, width: '80%' }}
              onClick={() => this.onActivateIntegration()}
            />
            </div>
          )}
      </div>
    );
  }
}

export default ZapfloorSettingsOperationalSection;
