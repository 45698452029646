import React from 'react';

const SortIconCustom = ({ style, className }) => (
  <svg style={{ ...style }} className={className} viewBox="0 0 24 24">
    <path fill="currentColor" d="M18 21L14 17H17V7H14L18 3L22 7H19V17H22M2 19V17H12V19M2 13V11H9V13M2 7V5H6V7H2Z" />
  </svg>
);

export default SortIconCustom;

