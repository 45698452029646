import qs from 'qs';
import { axiosInstance } from './rest';
import axios from 'axios';

let cardsExportCancelToken;

export function fetchCardsForExport(params) {
  cardsExportCancelToken = axios.CancelToken.source();
  const config = {
    params,
    timeout: 0,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
    cancelToken: cardsExportCancelToken.token,
  };
  return axiosInstance.get('/api/v2/standardDevices', config);
}

export function getCardsExportCancelToken() {
  return cardsExportCancelToken;
}
