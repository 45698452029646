import _ from 'lodash';
import { SPECIAL_ALL_ENTITIES, HIDDEN_PERMISSIONS, PERMISSION_ENTITIES, PERMISSIONS } from '../_config/consts';

export default class PermissionsParserV0 {
  static parse(permissionDTO) {
    const stringPermission = permissionDTO.name;
    if (!_.isString(stringPermission)) {
      throw new Error('Input permission must be a string');
    }

    const lastSeparatorIndex = stringPermission.lastIndexOf('_');
    let entity = stringPermission;
    let permission = '';
    if (lastSeparatorIndex > 0) {
      entity = stringPermission.substring(0, lastSeparatorIndex);
      permission = stringPermission.substring(lastSeparatorIndex + 1);
    }

    let permissions = [{
      subject: entity,
      action: permission,
      id: permissionDTO.id,
    }];

    // _.includes(DEFAULT_PERMISSIONS, stringPermission)
    if (_.includes(HIDDEN_PERMISSIONS, entity) || ((permission === PERMISSIONS.ALL || permission === PERMISSIONS.ME) && !_.includes(SPECIAL_ALL_ENTITIES, entity))) {
      permissions = [];
    }

    return permissions;
  }

  static parseWithAll(permissionDTO, allPermissions) {
    const stringPermission = permissionDTO.name;
    if (!_.isString(stringPermission)) {
      throw new Error('Input permission must be a string');
    }

    const lastSeparatorIndex = stringPermission.lastIndexOf('_');
    let entity = stringPermission;
    let permission = '';
    if (lastSeparatorIndex > 0) {
      entity = stringPermission.substring(0, lastSeparatorIndex);
      permission = stringPermission.substring(lastSeparatorIndex + 1);
    }

    let permissions = [{
      subject: entity,
      action: permission,
      id: permissionDTO.id,
    }];

    if (permission === 'ALL') {
      permissions = allPermissions[entity] || permissions;
    }

    return permissions;
  }

  static parseArray(stringPermissions) {
    if (!_.isArray(stringPermissions)) {
      throw new Error('Input permissions must be an array');
    }
    const permissions = [];
    _.each(stringPermissions, permission => permissions.push(...this.parse(permission)));
    return permissions;
  }

  static parseArrayWithAll(stringPermissions, allPermissions) {
    if (!_.isArray(stringPermissions)) {
      throw new Error('Input permissions must be an array');
    }
    const permissions = [];
    _.each(stringPermissions, permission => permissions.push(...this.parseWithAll(permission, allPermissions)));
    return permissions;
  }
}
