// @ts-nocheck
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { createTheme, withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BLUE, TICKET_PRIORITY_COLORS, TICKET_STATUS_COLORS } from '../../_config/consts';
import { getColorFromThemeName } from '../../_config/utils';
import SortIconCustom from '../CustomIcons/SortIconCustom.jsx';
import UnsortIconCustom from '../CustomIcons/UnsortIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';

import * as TicketActions from '../../redux/actions/ticket.actions';
import SortAscendingIconCustom from '../CustomIcons/SortAscendingIconCustom.jsx';
import SortDescendingIconCustom from '../CustomIcons/SortDescendingIconCustom.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumnHeaderText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeaderArrow: {
    padding: 10,
    marginLeft: 10,
  },
  tableColumnHeaderArrowIcon: {
    fontSize: 20,
    width: 20,
  },
  tableColumnHeaderSortIcon: {
    width: 24,
  },
  tableHeader: {
    padding: 5,
    paddingLeft: 25,
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: '#E53935',
    fontSize: 12,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ticketRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 45,
    height: 45,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});
@connect((state) => ({ tickets: state.tickets,
  viewLoading: state.utils.viewLoading, companyConfigurations: state.user.companyConfigurations, themeName: state.settings.items.theme.data.themeName }))

class TicketTableView extends React.Component {
  
  async handleOrderChange(column) {
    const { onOrderChanged, dispatch } = this.props;
    const { tickets: { data: { sorting: {orderBy, orderDir} } } } = this.props;
    const orderDir_new = orderBy===column?(orderDir==='ASC'?'DESC':'ASC'):'ASC';
    await dispatch(TicketActions.setOrder(column, orderDir_new));
    onOrderChanged()
  };

  async onResetOrder() {
    const { onOrderChanged, dispatch } = this.props;
    await dispatch(TicketActions.setOrder(undefined, undefined));
    onOrderChanged()
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(TicketActions.setOperationalMode(false));
  }

  render() {
    const {
      classes,
      tickets: { data: { content: ticketData, pagination: { number: currentPage, size, totalPages, totalElements }, filters, sorting: {orderBy, orderDir} } },
      tickets: { selectedTicket },
      tickets: { ticketManagers: { content: ticketManagers } },
      onFetchTicketOnPage,
      onSelectTicket,
      onRefreshTickets,
      onNewTicket,
      viewLoading,
      themeName,
      statusArray,
      prioritiesArray,
      categoriesArray,
    } = this.props;
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 200, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(ticketData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell className={classes.tableHeader} colSpan={3}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="ticketsList" /></h3>
                        <IconButton onClick={() => onRefreshTickets()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                        {/* {orderBy && 
                          <IconButton onClick={() => this.onResetOrder()} style={{ marginLeft: 5, marginTop: 5 }}>
                            <UnsortIconCustom style={{ color: 'white', width: 25 }} />
                          </IconButton>
                        } */}
                      </div>
                    </TableCell>
                    <TableCell />
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="ticketTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchTicketOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchTicketOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell style={{minWidth:150}}><h4 className={classes.tableColumnHeaderText}><Entity entity="ticketStatus" /></h4></TableCell>
                    <TableCell style={{ minWidth:300 }}>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="ticketTitle" /></h4>
                        {/* {orderBy === 'id'?(
                          <IconButton onClick={() => this.handleOrderChange('id')} style={{opacity: 1}} className={classes.tableColumnHeaderArrow}>
                            {orderDir==='ASC'||orderBy!=='id'? <SortDescendingIconCustom className={classes.tableColumnHeaderArrowIcon}/> : <SortAscendingIconCustom className={classes.tableColumnHeaderArrowIcon}/>}
                          </IconButton>
                        ):(
                          <IconButton onClick={() => this.handleOrderChange('id')} style={{opacity: 0.6}} className={classes.tableColumnHeaderArrow}>
                            {<SortIconCustom className={classes.tableColumnHeaderSortIcon}/>}
                          </IconButton>
                        )} */}
                      </div>
                    </TableCell>
                    <TableCell style={{minWidth:300}}><h4 className={classes.tableColumnHeaderText}><Entity entity="openBy" /></h4></TableCell>
                    <TableCell style={{minWidth:150}}><h4 className={classes.tableColumnHeaderText}><Entity entity="ticketPriority" /></h4></TableCell>
                    <TableCell style={{minWidth:150}}><h4 className={classes.tableColumnHeaderText}><Entity entity="ticketCategory" /></h4></TableCell>
                    <TableCell style={{minWidth:150}}><h4 className={classes.tableColumnHeaderText}><Entity entity="ticketAssignee" /></h4></TableCell>
                    <TableCell style={{minWidth:150}}><h4 className={classes.tableColumnHeaderText}><Entity entity="ticketLastUpdate" /></h4></TableCell>
                    <TableCell style={{minWidth:150}}><h4 className={classes.tableColumnHeaderText}><Entity entity="ticketSubmitted" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(ticketData, (ticket) => {
                    const ticketCategoryValue = ticket.categories&&ticket.categories.length>0?ticket.categories[0].name:""
                    return (
                      <TableRow
                        key={ticket.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={() => onSelectTicket(ticket)}
                        selected={selectedTicket && selectedTicket.id === ticket.id}
                      >
                        <TableCell>
                          <div className={classes.ticketRowContainer}>
                            <div>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 5 }}>
                                <h5 style={{ fontWeight: 'bold', fontSize: 12, padding: 10, borderRadius:5, 
                                  backgroundColor:'rgba('+TICKET_STATUS_COLORS[ticket.status]+',0.8)', color: 'white'}}>
                                  {statusArray.filter(e=>e.value===ticket.status).length>0?
                                  statusArray.filter(e=>e.value===ticket.status)[0].label:ticket.status}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell style={{maxWidth:0}}>
                          <div className={classes.ticketRowContainer} >
                            <h5 style={{fontSize: 15, margin: 0, marginTop: 5, marginRight: 5, lineBreak:'anywhere' }}>{`#${ticket.id} - ${ticket.title}`}</h5>
                          </div>
                        </TableCell>
                        <TableCell style={{maxWidth:0}}>
                          <div className={classes.ticketRowContainer} >
                          <h5 style={{ marginTop: 7 }}>{ticket.requester}</h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.ticketRowContainer}>
                            {ticket.priority?(
                              <h5 style={{ fontWeight: 'bold', fontSize: 13, padding: 5, borderRadius:10, textDecoration: 'underline', color:'rgba('+TICKET_PRIORITY_COLORS[ticket.priority]+',0.8)'}}>
                                  {prioritiesArray.filter(e=>e.value===ticket.priority).length>0?
                                  prioritiesArray.filter(e=>e.value===ticket.priority)[0].label.toUpperCase():ticket.priority.toUpperCase()}
                              </h5>
                            ):null}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.ticketRowContainer} >
                            <h5 style={{ fontSize: 13, margin: 0, marginTop: 5, marginRight: 5, lineBreak:'anywhere' }}>
                              {categoriesArray.filter(e=>e.value===ticketCategoryValue).length>0?
                              categoriesArray.filter(e=>e.value===ticketCategoryValue)[0].label.toUpperCase():ticketCategoryValue}
                            </h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.ticketRowContainer}>
                            <h5 style={{ fontWeight: 'bold', fontSize: 15, margin: 0, marginTop: 5, marginRight: 5 }}>
                              {ticket.assignee?(
                                ticketManagers.filter(e=>e.username&&e.email===ticket.assignee).length>0?
                                `${ticketManagers.filter(e=>e.email===ticket.assignee)[0].firstname} ${ticketManagers.filter(e=>e.email===ticket.assignee)[0].lastname}`:`${ticket.assignee}`
                              ):""}
                            </h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.ticketRowContainer}>
                            <h5 style={{ fontSize: 14, margin: 0, marginTop: 5, marginRight: 5 }}>
                              {`${moment(ticket.updatedAt).format('LLL')}`}
                            </h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.ticketRowContainer}>
                            <div>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 5 }}>
                                <h5 style={{ fontSize: 14, margin: 0, marginTop: 5, marginRight: 5 }}>
                                  {`${moment(ticket.createdAt).format('LLL')}`}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <ReactTooltip delayShow={200} effect="float" />
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="ticketTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchTicketOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchTicketOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title={filters && !filters.search ? 'noTickets' : 'noTicketsFound'}
                    subtitle={filters && !filters.search ? 'noTicketsDescription' : 'noTicketsFoundSearchDescription'}
                    onNewEntity={filters && !filters.search && onNewTicket ? () => onNewTicket() : null}
                    newEntityTitle="addTicket"
                  /> ) : (
                    <ListLoadingView />
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TicketTableView);
