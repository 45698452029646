// @ts-nocheck
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { createTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloudIcon from '@material-ui/icons/ImportExport';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import RoleIcon from '@material-ui/icons/VerifiedUser';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import { BLUE, MOBILE_UDID_STATES, PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';
import { getColorFromThemeName, getLockImage } from '../../_config/utils';
import CustomFieldsDisplayView from '../CustomFields/CustomFieldsDisplayView.jsx';
import DeleteOutlineIconCustom from '../CustomIcons/DeleteOutlineIconCustom.jsx';
import DeviceRemoveIconCustom from '../CustomIcons/DeviceRemoveIconCustom.jsx';
import SelectMultipleCheckBoxIconCustom from '../CustomIcons/SelectMultipleCheckBoxIconCustom.jsx';
import UserCheckIconCustom from '../CustomIcons/UserCheckIconCustom.jsx';
import UserDisableIconCustom from '../CustomIcons/UserDisableIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import Tag from '../Tag.jsx';
import RemoveEmailIconCustom from '../CustomIcons/RemoveEmailIconCustom.jsx';
import AccessProfilesIconCustom from '../CustomIcons/AccessProfilesIconCustom.jsx';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import UnsortIconCustom from '../CustomIcons/UnsortIconCustom.jsx';
import SortIconCustom from '../CustomIcons/SortIconCustom.jsx';

import * as AreaActions from '../../redux/actions/area.actions';
import SortAscendingIconCustom from '../CustomIcons/SortAscendingIconCustom.jsx';
import SortDescendingIconCustom from '../CustomIcons/SortDescendingIconCustom.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumnHeaderText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeaderArrow: {
    padding: 10,
    marginLeft: 10,
  },
  tableColumnHeaderArrowIcon: {
    fontSize: 20,
    width: 20
  },
  tableColumnHeaderSortIcon: {
    width: 24,
  },
  tableHeader: {
    padding: 5,
    paddingLeft: 25,
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: '#E53935',
    fontSize: 12,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  areaRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  areaLocksListContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: -5,
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 45,
    height: 45,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  lockContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid grey',
    padding: 3,
    paddingRight: 6,
    borderRadius: 10,
    backgroundColor: 'white',
    marginRight: 5,
    marginLeft: 5,
    marginTop: 3,
    marginBottom: 3,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});
@connect((state) => ({ areas: state.areas,
  viewLoading: state.utils.viewLoading, companyConfigurations: state.user.companyConfigurations, themeName: state.settings.items.theme.data.themeName }))

class AreaTableView extends React.Component {
  
  async handleOrderChange(column) {
    const { onOrderChanged, dispatch } = this.props;
    const { areas: { data: { sorting: {orderBy, orderDir} } } } = this.props;
    const orderDir_new = orderBy===column?(orderDir==='ASC'?'DESC':'ASC'):'ASC';
    await dispatch(AreaActions.setOrder(column, orderDir_new));
    onOrderChanged()
  };

  async onResetOrder() {
    const { onOrderChanged, dispatch } = this.props;
    await dispatch(AreaActions.setOrder(undefined, undefined));
    onOrderChanged()
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
    }
  }


  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(AreaActions.setOperationalMode(false));
  }

  render() {
    const {
      classes,
      areas: { data: { content: areaData, pagination: { number: currentPage, size, totalPages, totalElements }, filters, sorting: {orderBy, orderDir} } },
      areas: { selectedArea },
      areas: { locks: { content: locks }},
      onFetchAreaOnPage,
      onSelectArea,
      onRefreshAreas,
      onNewArea,
      onDeleteArea,
      viewLoading,
      themeName,
    } = this.props;
    const headerColor = getColorFromThemeName(themeName);
    const canEditCreateAreas = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.AREA);
    return (
      <div className="list-view details-section" style={{ top: 150, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(areaData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell className={classes.tableHeader} colSpan={2}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="areasList" /></h3>
                        <IconButton onClick={() => onRefreshAreas()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                        {/* {orderBy && 
                          <IconButton onClick={() => this.onResetOrder()} style={{ marginLeft: 5, marginTop: 5 }}>
                            <UnsortIconCustom style={{ color: 'white', width: 25 }} />
                          </IconButton>
                        } */}
                      </div>
                    </TableCell>
                    <TableCell />
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="areaTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchAreaOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchAreaOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ minWidth:220 }}>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="areaName" /></h4>
                        {/* {orderBy === 'name'?(
                          <IconButton onClick={() => this.handleOrderChange('name')} style={{opacity: 1}} className={classes.tableColumnHeaderArrow}>
                            {orderDir==='ASC'||orderBy!=='name'? <SortDescendingIconCustom className={classes.tableColumnHeaderArrowIcon}/> : <SortAscendingIconCustom className={classes.tableColumnHeaderArrowIcon}/>}
                          </IconButton>
                        ):(
                          <IconButton onClick={() => this.handleOrderChange('name')} style={{opacity: 0.6}} className={classes.tableColumnHeaderArrow}>
                            {<SortIconCustom className={classes.tableColumnHeaderSortIcon}/>}
                          </IconButton>
                        )} */}
                      </div>
                    </TableCell>
                    <TableCell style={{ minWidth:300, maxWidth:400 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="areaLocksIn" /></h4></TableCell>
                    <TableCell style={{ minWidth:300, maxWidth:400 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="areaLocksOut" /></h4></TableCell>
                    <TableCell style={{ minWidth:200 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="areaCapacity" /></h4></TableCell>
                    <TableCell style={{ minWidth:200 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="areaTimeout" /></h4></TableCell>
                    <TableCell style={{ minWidth:250 }}><h4 className={classes.tableColumnHeaderText}><Entity entity="areaResetTime" /></h4></TableCell>
                    {canEditCreateAreas ? <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="actions" /></h4></TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(areaData, (area) => {
                    const locksIn = _.chain(area.smartLocksInIds)
                      .map(lockId => _.find(locks, { id: lockId }))
                      .compact()
                      .sortBy(lock => lock.name.toLowerCase())
                      .value();

                    const locksOut = _.chain(area.smartLocksOutIds)
                      .map(lockId => _.find(locks, { id: lockId }))
                      .compact()
                      .sortBy(lock => lock.name.toLowerCase())
                      .value();


                    let resetTimeString = area.resetTime + area.resetTimeTimezone
                    try {
                      const hour = area.resetTime
                      const timezone = area.resetTimeTimezone
                      const date = moment.tz(`${hour}:00`, 'HH:mm', timezone);
                      const offset = date.format('Z');
                      resetTimeString = `${date.format('HH:mm')} ${timezone} (${offset})`;
                    } catch (e) {
                    }

                    let timeoutString = `${area.timeout} ${L20NContext.getSync('minutes')}`
                    if (area.timeout%(24*60)===0) {
                      timeoutString = `${area.timeout/(24*60)} ${L20NContext.getSync('days')}`
                    }
                    else if (area.timeout%60===0) {
                      timeoutString = `${area.timeout/60} ${L20NContext.getSync('hours')}`
                    }

                    const capacityString = `${area.capacity?area.capacity:0} ${L20NContext.getSync('users')}`

                    return (
                      <TableRow
                        key={area.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={() => onSelectArea(area)}
                        selected={selectedArea && selectedArea.id === area.id}
                      >
                        <TableCell style={{maxWidth:0}}>
                          <div className={classes.areaRowContainer}>
                            <h5 style={{ fontSize: 17, fontWeight: 'bold', margin: 15, marginLeft: 0, marginRight: 0, lineBreak:'anywhere' }}>{`${area.name}`}</h5>
                          </div>
                        </TableCell>
                        <TableCell style={{}}>
                          <div className={classes.areaRowContainer} >
                            <div className={classes.areaLocksListContainer} >
                              {_.map(locksIn, (lock) => {
                                return (
                                  <Tag
                                    tag={lock.lockTag}
                                    style={{ padding: 5 }}
                                    textStyle={{ fontSize: 14 }}
                                  />
                                )
                              })}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell style={{}}>
                          <div className={classes.areaRowContainer} >
                            <div className={classes.areaLocksListContainer} >
                              {_.map(locksOut, (lock) => {
                                return (
                                  <Tag
                                    tag={lock.lockTag}
                                    style={{ padding: 5 }}
                                    textStyle={{ fontSize: 14 }}
                                  />
                                )
                              })}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.areaRowContainer}>
                            <h5 style={{fontSize: 16, margin: 0, lineBreak:'auto' }}>
                              {`${capacityString}`}
                            </h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.areaRowContainer}>
                            <h5 style={{fontSize: 16, margin: 0, lineBreak:'auto' }}>
                              {`${timeoutString}`}
                            </h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.areaRowContainer}>
                            <h5 style={{fontSize: 16, margin: 0, lineBreak:'auto' }}>{`${resetTimeString}`}</h5>
                          </div>
                        </TableCell>
                        {canEditCreateAreas ? (
                          <TableCell>
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                onDeleteArea(area.id);
                              }}
                              className={classes.button}
                            >
                              <Entity entity="delete" />
                              <DeleteIcon style={{ fontSize: 15, marginLeft: 5 }} />
                            </Button>
                          </TableCell>
                        ) : null}
                        <ReactTooltip delayShow={200} effect="float" />
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="areaTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchAreaOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchAreaOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title={filters && !filters.search ? 'noAreas' : 'noAreasFound'}
                    subtitle={filters && !filters.search ? 'noAreasDescription' : 'noAreasFoundSearchDescription'}
                    onNewEntity={filters && !filters.search && onNewArea ? () => onNewArea() : null}
                    newEntityTitle="addArea"
                  /> ) : (
                    <ListLoadingView />
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AreaTableView);
