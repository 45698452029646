import { eventActions as EventsActions } from '@bottega52/bookey-redux-module';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { connect } from 'react-redux';
import { change, initialize, reset, submit } from 'redux-form';
import EventDetails from '../../../components/forms/Bookey/EventDetails.jsx';
import EventForm from '../../../components/forms/Bookey/EventForm.jsx';
import MDButton from '../../../components/MDButton/MDButton.jsx';
import OperationalView from '../../../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../../../components/PresentationalView/PresentationalViewHeader.jsx';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import * as formatter from '../../../_config/formatter';
import { localizeHelpCenterLink } from '../../../_config/utils.js';

let filterTimeout;

@connect(state => ({ themeName: state.settings.items.theme.data.themeName, events: state.events }))
class EventsAgenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      startingDate: moment().toDate(),
      showOperativeSection: false,
      showEventDetails: false,
      showDetailsSection: false,
      event: {},
      calendarEvents: [],
      currentMonth: moment().month(),
    };
  }

  async componentWillMount() {
    const currentDate = moment();
    await this.fetchEventsByFilter(currentDate);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { events: { data: { content: currentEvents } } } = this.props;
    const { events: { data: { content: previousEvents } } } = prevProps;
    if (currentEvents && previousEvents && currentEvents !== previousEvents) {
      const calendarEvents = _.map(currentEvents, (event, key) => (
        {
          id: event.id,
          hexColor: '#2a7394',
          title: event.title,
          start: moment(event.fromDate).toDate(),
          end: moment(event.toDate).toDate(),
        }));
      this.setState({ calendarEvents });
    }
  }

  setCurrentMonth(date) {
    this.setState({
      currentMonth: moment(date).month(),
    });
  }

  async fetchEventWhenMonthChange(date) {
    const { currentMonth } = this.state;
    if (moment(date).month() !== currentMonth) {
      try {
        await this.fetchEventsByFilter(date);
      } finally {
        this.setCurrentMonth(date);
      }
    }
  }

  async fetchEventsByFilter(date) {
    const { dispatch } = this.props;
    const beginDate = moment(date).startOf('month').subtract(2, 'years').valueOf();
    const endDate = moment(date).endOf('month').add(2, 'years').valueOf();
    dispatch(EventsActions.setEventFilter('fromDate', beginDate));
    dispatch(EventsActions.setEventFilter('toDate', endDate));
    setTimeout(async () => {
      const { events: { filters } } = this.props;
      await dispatch(EventsActions.fetchAllEvents(filters));
    }, 300);
  }

  async onSelectEvent(event) {
    const { dispatch, events } = this.props;
    const selectedEvent = _.find(events && events.data && events.data.content, eventState => eventState.id === event.id);
    let eventToShow = selectedEvent;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const eventMedia = await dispatch(EventsActions.fetchEventMedia(selectedEvent.id));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      eventToShow = { ...selectedEvent, media: eventMedia };
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
    await dispatch(EventsActions.setSelectedEvent(eventToShow));
    this.setState({ showDetailsSection: true, showEventForm: false });
  }

  async onSelectDateInterval(dateInterval) {
    const { dispatch, } = this.props;
    const formattedDates = formatter.formatOutputData(formatter.RESERVATION_DATES, dateInterval);
    let { dateFrom, dateTo } = formattedDates;
    
    // if (moment(dateFrom).diff(moment(), 'days') < 0) {
    //   dispatch(ModalActions.showModal({
    //     modalType: 'ERROR_ALERT',
    //     modalProps: {
    //       anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    //       message: (<h6 className="snack-title"><Entity entity="errorEventBeforeToday" /></h6>),
    //     },
    //   }));
    //   return;
    // }

    if (moment(dateFrom).isSame(dateTo)) {
      dateTo = moment(dateFrom).add('days', 1).valueOf();
    }

    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      dispatch(change('EventForm', 'dateFrom', dateFrom));
      dispatch(change('EventForm', 'dateTo', dateTo));
      dispatch(change('EventForm', 'timeTo', dateTo));
      dispatch(change('EventForm', 'timeFrom', dateFrom));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showDetailsSection: false, showEventForm: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }


  onSelectCalendarInterval(calendarInterval) {
    const dateFrom = moment(calendarInterval.start).valueOf();
    const dateTo = moment(calendarInterval.end).valueOf();
    const startTime = moment().valueOf();
    const endTime = moment().add(1, 'hours').valueOf();
    this.onSelectDateInterval({ dateFrom, dateTo, startTime, endTime });
  }

  onDeleteEvent(eventId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteEventConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteEventConfirmed(eventId),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteEventConfirmed(eventId) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(EventsActions.deleteEvent(eventId));
      dispatch(ModalActions.hideModal());
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorDeletingEvent" /> },
      }));
    } finally {
      this.setState({ showDetailsSection: false, showEventForm: false });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async createEvent(event, media) {
    const { dispatch } = this.props;
    const createdEvent = await dispatch(EventsActions.createEvent(event));
    if (media) {
      try {
        await dispatch(EventsActions.createEventMedia(createdEvent.id, media));
      } catch (error) {
        // TODO: handle error in photo upload
      }
    }
    return createdEvent;
  }

  async editEvent(event, media) {
    const { dispatch } = this.props;
    const createdEvent = await dispatch(EventsActions.editEvent(event));
    if (media && media instanceof File) {
      try {
        await dispatch(EventsActions.createEventMedia(createdEvent.id, media));
      } catch (error) {
        // TODO: handle error in photo upload
      }
    }
    return createdEvent;
  }

  async onEventSubmit(eventData) {
    const { dispatch } = this.props;
    const { showDetailsSection, currentMonth } = this.state;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      // Edit or save event
      const formattedEvent = formatter.formatOutputData(formatter.EVENT, eventData);
      let newEvent;
      let successMessage = 'eventSavedSuccess';
      if (formattedEvent.id) {
        newEvent = await this.editEvent(formattedEvent, eventData.media);
        const media = await dispatch(EventsActions.fetchEventMedia(newEvent.id));
        newEvent.media = media;
        successMessage = 'eventEditSuccess';
      } else {
        newEvent = await this.createEvent(formattedEvent, eventData.media);
        this.fetchEventsByFilter(moment().month(currentMonth));
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={successMessage} /></h6>),
        },
      }));
      this.setState({ showEventForm: false });
      // Update event form
      if (showDetailsSection) this.onSelectEvent(newEvent);
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorSavingEvent" /> },
      }));
    }
  }

  onStartModifyEvent(event) {
    const { dispatch } = this.props;
    const formattedEvent = formatter.formatInputData(formatter.INIT_EVENT_FORM, event);
    dispatch(initialize('EventForm', formattedEvent));
    this.setState({ showEventForm: true });
  }

  newEventFromButton() {
    const { dispatch } = this.props;
    dispatch(reset('EventForm'));
    const dateFrom = moment().valueOf();
    const dateTo = dateFrom;
    const startTime = dateFrom;
    const endTime = moment().add(1, 'hours').valueOf();
    this.onSelectDateInterval({ dateFrom, dateTo, startTime, endTime });
  }

  eventStyleGetter(event, start, end, isSelected) {
    const style = {
      backgroundColor: event.hexColor,
      borderRadius: '0px',
      opacity: 1,
      color: 'white',
      border: '0.5px solid white',
      display: 'block',
    };
    return {
      style,
    };
  }

  async onFilterEvents(name) {
    const { dispatch } = this.props;
    dispatch(EventsActions.setEventFilter('title', name));
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      const { events: { filters } } = this.props;
      await dispatch(EventsActions.fetchAllEvents(filters));
    }, 500);
  }

  async onSearchReset() {
    this.onFilterEvents(null);
  }

  onOpenEventsInfo() {
    const formattedURL = localizeHelpCenterLink('bookey-create-and-manage-events');
    window.open(formattedURL);
  }

  render() {
    const { startingDate, showEventForm, showDetailsSection, calendarEvents } = this.state;
    const { dispatch, themeName, events } = this.props;
    const localizer = momentLocalizer(moment);

    return (
      <div style={{ marginLeft: 0, marginTop: 40, height: 820, padding: 0 }}>
        <PresentationalViewHeader
          themeName={themeName}
          searchPlaceholderEntityName="nameEvent"
          newEntityTitle="newEvent"
          onFilterChange={value => this.onFilterEvents(value)}
          onSearchReset={() => this.onSearchReset()}
          onNewEntity={() => this.newEventFromButton()}
          onInfo={() => this.onOpenEventsInfo()}
        />
        <BigCalendar
          events={calendarEvents}
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          selectable
          showMultiDayTimes
          culture={moment.locale()}
          style={{ height: 700, padding: 15, paddingTop: 50 }}
          className="calendar-view"
          defaultDate={startingDate}
          onSelectEvent={event => this.onSelectEvent(event)}
          onSelectSlot={dateInterval => this.onSelectCalendarInterval(dateInterval)}
          eventPropGetter={
            (event, start, end, isSelected) => this.eventStyleGetter(event, start, end, isSelected)
          }
          onNavigate={(date) => this.fetchEventWhenMonthChange(date)}
          messages={{
            today: <Entity entity="calendarLabels" data={{ type: 'today' }} />,
            previous: '<',
            next: '>',
            month: <Entity entity="calendarLabels" data={{ type: 'month' }} />,
            week: <Entity entity="calendarLabels" data={{ type: 'week' }} />,
            day: <Entity entity="calendarLabels" data={{ type: 'day' }} />,
            agenda: <Entity entity="calendarLabels" data={{ type: 'agenda' }} />,
            noEventsInRange: <Entity entity="emptyEventsAgenda" />,
          }}
        />
        <OperationalView
          title={<Entity entity="eventDetails" />}
          isVisible={showDetailsSection}
          themeName={themeName}
          style={{ marginTop: 0 }}
          onClose={() => this.setState({ showDetailsSection: false })}
        >
          <EventDetails
            event={events && events.selectedEvent}
            onDeleteEvent={() => this.onDeleteEvent(events && events.selectedEvent && events.selectedEvent.id)}
            onEditEvent={() => this.onStartModifyEvent(events && events.selectedEvent)}
          />
        </OperationalView>
        <OperationalView
          title={<Entity entity="event" />}
          isVisible={showEventForm}
          themeName={themeName}
          style={{ marginTop: 0 }}
          onClose={() => this.setState({ showEventForm: false })}
        >
          <EventForm
            dateHasBeenChanged={dateInterval => this.onSelectDateInterval(dateInterval)}
            onSubmit={eventData => this.onEventSubmit(eventData)}
          />
          <MDButton
            title={<Entity entity="save" />}
            containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
            style={{ height: 45, borderRadius: 0 }}
            onClick={() => dispatch(submit('EventForm'))}
          />
        </OperationalView>

      </div>
    );
  }
}

export default EventsAgenda;