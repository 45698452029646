import axios from 'axios';
import qs from 'qs';
import { axiosInstance } from './rest';


export const eagleEyeInstance = axios.create({
  timeout: 30000,
});

export let eagleEyeAccessToken = '';

export function setEagleEyeAccessToken(accessToken) {
  eagleEyeAccessToken = accessToken;
}


export function fetchCameras(params, cameraType) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get(`/api/v2/cameras/${cameraType}`, restParams);
}

export function fetchCameraMedia(cameraId, cameraType, params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get(`/api/v2/cameras/${cameraType}/${cameraId}/media`, restParams);
}

export function fetchCameraLiveFeed(cameraId, cameraType) {
  return axiosInstance.get(`/api/v2/cameras/${cameraType}/${cameraId}/feeds`);
}

export function fetchMediaCookieUrl(baseUrl) {
  const config = {
    headers: {
      'Authorization': `Bearer ${eagleEyeAccessToken}`,
    },
    withCredentials: true,
  };
  return axios.get(`https://${baseUrl}/api/v3.0/media/session`, config);
}

export function setMediaCookie(cookieURL) {
  const config = {
    headers: {
      'Authorization': `Bearer ${eagleEyeAccessToken}`,
    },
    withCredentials: true,
  };
  return axios.get(cookieURL, config );
}


export function setBaseUrl(baseURL) {
  eagleEyeInstance.defaults.baseURL = baseURL;
}