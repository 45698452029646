import _ from 'lodash';
import React from 'react';
import Select, { Creatable, AsyncCreatable } from 'react-select';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import { HelpBlock } from '@sketchpixy/rubix';


class SelectableField extends React.Component {
  onHandleChange(value) {
    const { onSelect, input } = this.props;
    if (input) {
      input.onChange(value);
    }
    if (onSelect) {
      onSelect(value);
    }
  }

  onInputChange(value) {
    const { onInputChange } = this.props;
    if (onInputChange) {
      onInputChange(value);
    }
  }

  render() {
    const { input, meta, canCreateAsync, title, multi, options, createNewOptionText,  helpText, emptyMessage, onValueClick, mandatory, placeholder, canCreate, onNewOption, onLoadOptions, titleStyle, containerstyle } = this.props;
    const hasError = meta.touched && meta.error;
    let { value } = input;
    if (!multi) {
      if (_.isArray(input.value) && input.value.length) {
        value = _.first(input.value);
      }
    }

    return (
      <div style={{ zIndex: 10000, ...containerstyle }}>
        {title && (
        <div style={{ display: 'flex' }}>
          <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', fontSize: 16, ...titleStyle }}>{title}</h4>
          {mandatory && <h5 style={{ color: 'red' }}>*</h5>}
        </div>
        )}
        {canCreate ? (
          <Creatable
            value={value}
            multi={multi}
            placeholder={placeholder}
            noResultsText={emptyMessage || <Entity entity="noResultsFound" />}
            onHandleChange={tag => this.onHandleChange(tag)}
            onLoadOptions={onLoadOptions ? text => onLoadOptions(text) : null}
            promptTextCreator={tagName => `${createNewOptionText ? L20NContext.getSync(createNewOptionText): L20NContext.getSync('createNewOption')} ${_.isObject(tagName) ? '' : `"${tagName}"`} `}
            onValueClick={val => this.onHandleChange(val)}
            onChange={val => this.onHandleChange(val)}
            onInputChange={val => this.onInputChange(val)}
            options={options}
            hasError={hasError}
            onBlur={() => input.onBlur(input.value)}
            onNewOptionClick={data => onNewOption(data)}
            {...this.props}
          />
        ) : null}
        {canCreateAsync ? (
          <AsyncCreatable
            value={value}
            multi={multi}
            placeholder={placeholder}
            noResultsText={emptyMessage || <Entity entity="noResultsFound" />}
            onHandleChange={tag => this.onHandleChange(tag)}
            onLoadOptions={onLoadOptions ? text => onLoadOptions(text) : null}
            promptTextCreator={tagName => `${createNewOptionText ? L20NContext.getSync(createNewOptionText): L20NContext.getSync('createNewOption')} ${_.isObject(tagName) ? '' : `"${tagName}"`} `}
            onValueClick={val => this.onHandleChange(val)}
            onChange={val => this.onHandleChange(val)}
            onInputChange={val => this.onInputChange(val)}
            options={options}
            hasError={hasError}
            onBlur={() => input.onBlur(input.value)}
            onNewOptionClick={data => onNewOption(data)}
            {...this.props}
          />
        ) : null}
        {!canCreateAsync && !canCreate ? (
          <Select
            value={value}
            multi={multi}
            placeholder={placeholder}
            noResultsText={emptyMessage || <Entity entity="noResultsFound" />}
            onHandleChange={tag => this.onHandleChange(tag)}
            onLoadOptions={onLoadOptions ? text => onLoadOptions(text) : null}
            onValueClick={onValueClick ? val => onValueClick(val) : null}
            onChange={val => this.onHandleChange(val)}
            onInputChange={val => this.onInputChange(val)}
            options={options}
            hasError={hasError}
            onBlur={(e) => {
              e.preventDefault();
              input.onBlur(input.value);
            }}
            {...this.props}
          />
        ): null}
        {helpText ? <HelpBlock style={{ lineHeight: 1.1, fontSize: 13, color: '#3f3f3f', fontWeight: '200', marginTop: 10 }}>{helpText}</HelpBlock> : null}
        {hasError && <h5 style={{ color: 'red', marginTop: 10 }}><Entity entity={meta.error} /></h5>}
      </div>
    );
  }
}

export default SelectableField;
