import { Card, Divider, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import EmptyProgramIcon from '@material-ui/icons/AssignmentLate';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import InfoOutline from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { GREEN, RED, SMARTLOCK_PROGRAM_STATUSES } from '../../../_config/consts';
import SmartphoneCheckIconCustom from '../../CustomIcons/SmartphoneCheckIconCustom.jsx';
import MDAccordion from '../../MDAccordion/MDAccordion.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField';
import MDSwitchField from '../Fields/MDSwitch/MDSwitchField.jsx';
import LockOfficeModeSubProgramForm from './LockOfficeModeSubProgramForm.jsx';


const validate = (values) => {
  const errors = {};

  if (values.availableOffline && values.availableOfflineMinutes <= 0) {
    errors.availableOfflineMinutes = 'required';
  }
  return errors;
};

const onRenderSubPrograms = (props) => {
  const { fields, meta: { touched, submitFailed, error }, formData, is12HoursFormat, onDeleteProgramRequest, onInitializeNewProgram } = props;
  const numberOfPrograms = _.size(fields);
  return (
    <div>
      {numberOfPrograms === 0 ? (
        <div>
          <div style={{ display: 'flex', marginTop: 20, flexDirection: 'column', alignItems: 'center' }}>
            <EmptyProgramIcon style={{ color: '#a5a3a3', fontSize: 35 }} />
            <h4 style={{ fontWeight: 'bold', textAlign: 'center', color: '#a5a3a3' }}>{<Entity entity="noProgramsFoundForLock" />}</h4>
            <h4 style={{ marginTop: 0, fontSize: 17 }}>{<Entity style={{ color: '#a5a3a3', textAlign: 'center' }} entity="noProgramsFoundForLockDescription" />}</h4>
          </div>
        </div>
        ) : null}
      {fields.map((program, index) => (
        <div key={`program${index + 1}`}>
          <MDAccordion
            title={<div>
              <Entity entity={`program${index + 1}`} />
              <IconButton
                style={{ marginLeft: 10, marginBottom: 5 }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!formData.isEditing) {
                    fields.remove(index);
                  } else { // if is editing an office mode configuration -> show confirmation modal
                    onDeleteProgramRequest(index);
                  }
                }}
              >
                <DeleteIcon style={{ fontSize: 20, color: RED }} />
              </IconButton>
            </div>}
            containerstyle={{ marginTop: 10 }}
            innerContainerStyle={{ backgroundColor: '#fdfdfd' }}
            defaultExpanded
            titleStyle={{ fontSize: 25 }}
          >
            <LockOfficeModeSubProgramForm
              program={program}
              programIndex={index}
              formData={formData.programs[index]}
              is12HoursFormat={is12HoursFormat}
            />
          </MDAccordion>
        </div>
      ))}
      {numberOfPrograms < 2 ? (
        <Card
          style={{ height: 60, marginBottom: 20, marginTop: 10, padding: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          className="card card-rounded"
          onClick={() => {
            fields.push({});
            onInitializeNewProgram(numberOfPrograms);
          }}
        >
          <AddIcon style={{ color: '#4DB6AC', marginRight: 15, fontSize: 20 }} />
          <h4 style={{ fontWeight: 'bold' }}><Entity entity="addProgram" /></h4>
        </Card>
      ) : null}
    </div>
  );
};

@reduxForm({ form: 'LockOfficeModeForm', validate, destroyOnUnmount: false })
class LockOfficeModeForm extends React.Component {

  render() {
    const { formData, is12HoursFormat, onOpenOfficeModeInfoModal, onDeleteSubProgramRequest, onRefreshOfficeModeProgramStatus, onInitializeNewOfficeModeSubProgram } = this.props;
    const programStatus = formData && formData.status;
    let statusColor = programStatus !== SMARTLOCK_PROGRAM_STATUSES.TO_BE_SYNCED ? '#43A047' : '#EF6C00';
    statusColor = programStatus === SMARTLOCK_PROGRAM_STATUSES.FAILED ? 'red' : statusColor;
    return (
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
          <h2 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f' }}><Entity entity="officeMode" /></h2>
          <IconButton onClick={() => onOpenOfficeModeInfoModal()} style={{ marginTop: 5, marginLeft: 5 }}>
            <InfoOutline style={{ color: '#3f3f3f', fontSize: 20 }} />
          </IconButton>
        </div>
        <h4 style={{ color: '#3f3f3f', fontSize: 18, lineHeight: '1.3em' }}>
          <Entity entity="officeModeDescription" />
        </h4>
        <Divider style={{ marginTop: 10, marginBottom: 10, height: 2 }} />
        {formData && formData.isEditing && programStatus ? (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, fontSize: 18 }}><Entity entity="cardStatus" /></h4>
              <IconButton onClick={() => onRefreshOfficeModeProgramStatus()} style={{ marginTop: 2 }}>
                <RefreshIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
              </IconButton>
            </div>
            <h4 style={{ textTransform: 'uppercase', fontWeight: 'bold', marginTop: 0, fontSize: 15, marginBottom: 0, color: statusColor }}>
              <Entity
                key={`status_${programStatus}`}
                entity="smartLockProgramStatus"
                data={{ status: programStatus }}
              />
            </h4>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {programStatus !== SMARTLOCK_PROGRAM_STATUSES.SYNCED ? (
                <WarningIcon key={programStatus} style={{ marginBottom: 2, color: statusColor }} />)
                : (
                  <CheckIcon key={programStatus} style={{ marginBottom: 2, color: statusColor }} />
                )}
              <h5 style={{ lineHeight: '1.4em', fontSize: 16, marginLeft: 10 }}>
                <Entity entity="smartLockProgramStatusDescription" data={{ status: programStatus }} />
              </h5>
            </div>
            <Divider style={{ marginTop: 10, marginBottom: 20, height: 2 }} />
          </div>
        ) : null}
        <div style={{ marginBottom: 200 }}>
          <MDAccordion
            title={<Entity entity="authorizedUserForOfficeMode" />}
            containerstyle={{ marginTop: 10 }}
            defaultExpanded
            innerContainerStyle={{ backgroundColor: '#fdfdfd', paddingTop: 0 }}
            titleStyle={{ fontSize: 25 }}
          >
            <div style={{ paddingLeft: 15 }}>
              <h4 style={{ color: '#3f3f3f', margin: 0, fontSize: 16 }}><Entity entity="authorizedUserForOfficeModeDescriptions" /></h4>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 10, marginTop: 15 }}>
                <SmartphoneCheckIconCustom style={{ width: 20, color: GREEN }} />
                <h4 style={{ color: GREEN, lineHeight: '1.4em', margin: 0, marginLeft: 10, fontSize: 16, fontWeight: 'bold' }}><Entity entity="mangersAuthorizedToOfficeMode" /></h4>
              </div>
              <h4 style={{ color: '#3f3f3f', margin: 0, fontSize: 16, marginTop: 20 }}><Entity entity="authorizeCardsForOfficeModeDescription" /></h4>
              <Field
                name="allDeviceOptionActive"
                label={<Entity entity="authorizeCardsForOfficeMode" />}
                titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
                component={MDSwitchField}
              />
            </div>
          </MDAccordion>
          <FieldArray
            name="programs"
            component={onRenderSubPrograms}
            formData={formData}
            is12HoursFormat={is12HoursFormat}
            onInitializeNewProgram={programId => onInitializeNewOfficeModeSubProgram(programId)}
            onDeleteProgramRequest={programId => onDeleteSubProgramRequest(programId)}
          />
        </div>
      </div>
    );
  }
} 

export default LockOfficeModeForm;
