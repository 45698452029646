import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardIcon from '@material-ui/icons/CreditCard';
import PinIcon from '@material-ui/icons/Apps';
import LockIcon from '@material-ui/icons/LockOpen';
import UserIcon from '@material-ui/icons/PersonOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import * as UtilActions from '../../redux/actions/utils.actions.js';
import { CARD_TYPES, CREDENTIAL_RULE_STATUSES, BLUE, RESERVATION_STATUSES, RESERVATION_STATUSES_PARAMETERS } from '../../_config/consts';
import Tag from '../Tag.jsx';
import { isUserWithoutEmail } from '../../_config/utils';

const styles = {
  circularProgress: {
    color: BLUE,
  },
};
@connect((state) => ({ settings: state.settings, companyConfigurations: state.user.companyConfigurations, }))
class CardDetailsRow extends React.Component {
  render() {
    const { card, onClick, isSelected, classes, companyConfigurations, dispatch } = this.props;
    const user = card.user;
    const isSuperError = _.isEmpty(card.credentialRule.lockTags) || !user;
    const isLoadingState = !card.credentialRule.state;
    let statusColor = card.credentialRule.state !== CREDENTIAL_RULE_STATUSES.NOT_UPDATED ? '#43A047' : '#EF6C00';
    statusColor = isSuperError ? 'red' : statusColor;
    statusColor = isLoadingState ? 'gray' : statusColor;

    const isUserDeleted = card && card.user && card.user.email && !_.includes(card.user.email, '@');

    let statusLabel = card.credentialRule.state === CREDENTIAL_RULE_STATUSES.NOT_UPDATED ? 'notUpdated' : 'updated';
    statusLabel = card.deleted ? 'cardDeleting' : statusLabel;
    statusLabel = isSuperError ? 'credentialRuleInvalid' : statusLabel;
    statusLabel = isLoadingState ? 'loading' : statusLabel;

    const { credentialRule: credential } = card;
    const days = credential && credential.daysOfTheWeek && _.orderBy(credential.daysOfTheWeek);
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
      selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
    }));

    const isInvalidNoTags = _.isEmpty(credential.lockTags) || _.isEmpty(credential.guestTags);
    statusLabel = isInvalidNoTags ? 'credentialRuleInvalid' : statusLabel;
    statusColor = isInvalidNoTags ? 'red' : statusColor;

    const isCardMappingEnabled = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.enabled;
    const cardIdsMap = companyConfigurations && companyConfigurations.customCardsMapping && companyConfigurations.customCardsMapping.cardIdsMap ? companyConfigurations.customCardsMapping.cardIdsMap : [];

    const originalCard = isCardMappingEnabled ? _.find(cardIdsMap, cardToTranslate => cardToTranslate.luckeyCardId === card.deviceId) : null;
    const validityDateFormat = dispatch(UtilActions.getCredentialValidityDateFormat());
    return (
      <Card
        className={`card card-rounded ${isSelected && 'card-selected'}`}
        style={{ borderRadius: 10, borderLeft: `4px solid ${statusColor}`, padding: 0, marginBottom: 20, opacity: isSuperError || isInvalidNoTags || !card.credentialRule.state ? 0.5 : 1 }}
        onClick={() => onClick()}
        elevation={2}
      >
        <div className="card-header access-card-header" style={{ paddingRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <div>
            <div style={{ display: 'flex' }}>
              {originalCard && originalCard.originalId ? (
                <h2 style={{ flex: 1, fontSize: 20, fontWeight: 'bold', marginRight: -10, marginLeft: 15, marginTop: 0, marginBottom: 0, color: '#3f3f3f' }}>
                  {`${originalCard && originalCard.originalId} -`}
                </h2>
              ) : null}
              <h2 style={{ flex: 1, fontSize: 20, fontWeight: 'bold', marginLeft: 15, marginTop: 0, marginBottom: 0, color: '#3f3f3f' }}>
                {`#${card.deviceId}`}
              </h2>
            </div>
            {card.notes && (
              <div style={{ marginLeft: 17, marginRight: 15, marginTop: 10 }}>
                <h5 style={{ margin: 0, marginTop: 2, wordBreak: 'break-word', color: '#3f3f3f', fontWeight: 'normal' }}>
                  {card.notes}
                </h5>
              </div>
            )}
          </div>
          <div style={{ marginTop: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h6 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                {`${moment(credential.dateInterval.from).format(validityDateFormat)} - ${moment(credential.dateInterval.to).format(validityDateFormat)}`}
              </h6>
              <h6 style={{ color: '#3f3f3f', margin: 0, marginRight: 7 }}>{` | ${moment(credential.timeIntervalFrom).format('LT')} - ${moment(credential.timeIntervalTo).format('LT')}`}</h6>
            </div>
            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
              {_.map(daysOptions, option => (
                <h6 key={option.label} style={{ margin: 0, marginRight: 7, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
                  {option.label.toUpperCase()}
                </h6>
                ))}
            </div>
          </div>
        </div>
        <div className="separator" />
        <div className="card-body" style={{ paddingBottom: 10, position: 'relative' }}>
          <div>
            {isLoadingState && <CircularProgress className={classes.circularProgress} style={{ color: 'gray', position: 'absolute', right: 30, top: 20 }} />}
            <h6 style={{ color: 'red', margin: 10, fontSize: 13, fontWeight: 'bold', marginTop: 0, textAlign: 'right', position: 'absolute', right: 0, top: 0 }}>
              <Entity
                key={`status_${card.credentialRule.state}`}
                style={{ textTransform: 'uppercase', color: statusColor }}
                entity={statusLabel}
              />
            </h6>
          </div>
          <div className="card-inner-body" >
            {user ?
              <div className="card-tag-presentational-container" style={{ marginBottom: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <UserIcon style={{ color: '#3f3f3f', fontSize: 22 }} />
                  <h4 style={{ fontSize: 16, color: '#3f3f3f', marginLeft: 5 }}>
                    <Entity entity="authorizedUser" />
                  </h4>
                </div>
                <div className="access-card-user-container" style={{ width: '90%' }}>
                  {card && card.user && (
                    <h5 style={{ margin: 5, fontWeight: 'bold', color: '#3f3f3f' }}>
                      {!isUserDeleted ? `${card.user.firstname} ${card.user.lastname} ${isUserWithoutEmail(card.user) ? '': ` - ${card.user.email}`}` : <Entity entity="userDeleted" />}
                    </h5>
                  )}
                  {card && card.credentialRule && !_.isEmpty(card.credentialRule.guestTags) &&
                    <div className="tags-container" style={{ marginTop: 0, marginBottom: 0 }}>
                      {_.map(card.credentialRule.guestTags, tag =>
                        <Tag
                          key={tag.id}
                          tag={tag}
                        />,
                      )}
                    </div>
                  }
                </div>
              </div> :
              <div>
                <h4 className="card-title"><Entity entity="unassignedCard" /></h4>
              </div>
            }
            {card && card.credentialRule && !_.isEmpty(card.credentialRule.lockTags) &&
            <div className="card-tag-presentational-container" style={{ marginTop: 15 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LockIcon style={{ color: '#3f3f3f', fontSize: 22, marginRigtht: 5 }} />
                <h4 style={{ fontSize: 16, color: '#3f3f3f', margin: 0, marginLeft: 5 }}>
                  <Entity entity="selectedLocks" />
                </h4>
              </div>
              <div className="tags-container">
                {_.map(card.credentialRule.lockTags, tag =>
                  <Tag
                    key={tag.id}
                    tag={tag}
                  />)}
              </div>
            </div>
            }
          </div>
        {card.credentialRule && (
          <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
            <div className="credential-status-triangle-container" style={{ borderColor: `transparent transparent ${statusColor} transparent` }}>
              {card.type === CARD_TYPES.ISEO_PIN ?
               (
                <PinIcon style={{ color: 'white', position: 'absolute', fontSize: 18, bottom: 10, right: 10 }} />
               ) :
              (
                <CardIcon style={{ color: 'white', position: 'absolute', fontSize: 18, bottom: 10, right: 10 }} />
              )
              }
            </div>
          </div>
        )}
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(CardDetailsRow);
