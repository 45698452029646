import Card from '@material-ui/core/Card';
import LockIcon from '@material-ui/icons/LockOpen';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { RESERVATION_STATUSES, RESERVATION_STATUSES_PARAMETERS } from '../../_config/consts.js';
import Tag from '../../components/Tag.jsx';
import * as UtilActions from '../../redux/actions/utils.actions.js';
import TimeRangeView from '../Cards/TimeRangeView.jsx';
import AccessProfilesIconCustom from '../CustomIcons/AccessProfilesIconCustom.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';

@connect((state) => ({ language: state.settings.language }))
class AccessProfileRow extends React.Component {
  render() {
    const { key, accessProfile, onClick, isSelected, language, dispatch } = this.props;
    const days = accessProfile && accessProfile.daysOfTheWeek && _.orderBy(accessProfile.daysOfTheWeek);
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
      selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
    }));
    const isInvalidNoTags = _.isEmpty(accessProfile.lockTags);
    const credentialStatus = isInvalidNoTags ? RESERVATION_STATUSES.NO_TAGS : accessProfile.status;
    const validityDateFormat = dispatch(UtilActions.getCredentialValidityDateFormat());
    
    return (
      <Card
        key={key}
        className={`card card-rounded ${isSelected && 'card-selected'}`}
        style={{ borderRadius: 10, opacity: isInvalidNoTags ? 0.5 : 1, marginBottom: 20, position: 'relative', borderLeft: `4px solid ${RESERVATION_STATUSES_PARAMETERS[credentialStatus].color}`, padding: 0 }}
        onClick={() => onClick()}
        elevation={2}
      >
        <div className="card-header access-card-header" style={{ paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
          <div style={{ marginLeft: 15, paddingTop: 10, maxWidth: '45%' }}>
            <h2 className="card-title" style={{ wordBreak: 'break-all', whiteSpace: 'unset', fontSize: 20, marginTop: 5, marginLeft: 0, marginBottom: 0, color: '#3f3f3f' }}>{accessProfile.name}</h2>
          </div>
        </div>
        <div className="separator" style={{ marginTop: 10 }} />
        {accessProfile.credentialDateFrom && accessProfile.credentialDateTo && (
          <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h5 style={{ color: '#3f3f3f', fontWeight: 'normal', margin: 0, marginLeft: 0 }}>
                {`${moment(accessProfile.credentialDateFrom).format(validityDateFormat)} - ${moment(accessProfile.credentialDateTo).format(validityDateFormat)}`}
              </h5>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
            <TimeRangeView
              timeRange={accessProfile}
              daysContainerStyle={{ justifyContent: 'flex-start' }}
            />
            {accessProfile.additionalTimeRange ? (
              <TimeRangeView
                timeRange={accessProfile.additionalTimeRange}
                daysContainerStyle={{ justifyContent: 'flex-start' }}
                containerStyle={{ marginLeft: 20 }}
              />
            ) : null}
            </div>
          </div>
        )}
        <div className="card-body" style={{ paddingTop: 10, paddingBottom: 10, position: 'relative' }}>
          <div className="card-inner-body" style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'flex-start' }}>
            <div className="card-tag-presentational-container" style={{ marginTop: 0, marginRight: 17 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 0 }}>
                <LockIcon style={{ color: '#3f3f3f', fontSize: 20, marginRigtht: 2 }} />
                <h4 style={{ fontSize: 16, color: '#3f3f3f', margin: 0, marginLeft: 5 }}>
                  <Entity entity="selectedLocks" />
                </h4>
              </div>
              <div className="tags-container">
                {_.map(accessProfile.lockTags, tag =>
                  <Tag
                    key={tag.id}
                    tag={tag}
                  />)}
              </div>
            </div>
            <div className="card-tag-presentational-container" style={{ marginTop: 0, marginRight: 17 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 0 }}>
                <UsersIconCustom style={{ color: '#3f3f3f', width: 20, marginRigtht: 2 }} />
                <h4 style={{ fontSize: 16, color: '#3f3f3f', margin: 0, marginLeft: 5 }}>
                  <Entity entity="accessProfilesUsers" />
                </h4>
              </div>
              <div className="tags-container">
                <h4 style={{ fontWeight: 'bold', color: '#3f3f3f' }}>
                {accessProfile.guestsNumber}
                </h4>
              </div>
            </div>
          </div>
        </div>
        {credentialStatus && (
          <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
            <div className="credential-status-triangle-container" style={{ borderColor: `transparent transparent ${RESERVATION_STATUSES_PARAMETERS[credentialStatus].color} transparent` }}>
              <AccessProfilesIconCustom style={{ width: 20, color: 'white', position: 'absolute', fontSize: 18, bottom: 10, right: 10 }} />
            </div>
          </div>
        )}
      </Card>
    );
  }
} 

export default AccessProfileRow;
