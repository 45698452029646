// @ts-nocheck
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getColorFromThemeName } from '../../../_config/utils';
import EmptyView from '../../EmptyView.jsx';
import ListLoadingView from '../../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  button: {
    color: 'red',
    fontSize: 15,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
  },
  tableCell: {
    marginTop: 10, 
    marginBottom: 10, 
    color: '#3f3f3f', 
    fontSize: 16
  }
});

const reservationStatusArray = {
  'DELETED_BY_USER': <Entity entity={`reservationStatus_DELETED_BY_USER`} />,
  'DELETED_BY_USER_WITH_PENALTY': <Entity entity={`reservationStatus_DELETED_BY_USER_WITH_PENALTY`} />,
  'LOST': <Entity entity={`reservationStatus_LOST`} />,
  'SUCCESSFUL': <Entity entity={`reservationStatus_SUCCESSFUL`} />,
  'PENDING_CONFIRMATION': <Entity entity={`reservationStatus_PENDING_CONFIRMATION`} />,
  'NOT_CONFIRMED': <Entity entity={`reservationStatus_NOT_CONFIRMED`} />,
}

@connect((state) => ({ viewLoading: state.utils.viewLoading, reservations: state.reservations, themeName: state.settings.items.theme.data.themeName }))
class ReservationTAbleView extends React.Component {

  componentWillUnmount() {
    const { dispatch } = this.props;
  }

  render() {
    const {
      classes,
      parsedReservations,
      reservations: { data: { pagination: { number: currentPage, size, totalPages, totalElements }, filters } },
      reservations: { selectedReservation },
      viewLoading,
      themeName,
      onNewReservation,
      onSelectReservation,
      //onFetchInvitationsOnPage,
    } = this.props;
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(parsedReservations) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell colSpan={3}>
                      <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="reservations" /></h3>
                    </TableCell>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="invitationsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchInvitationsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchInvitationsOnPage(page)}
                        />
                      )}
                    />
                    </TableRow>
                  <TableRow>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="reservationDate" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="reservationResource" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="resourceType" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="reservationUser" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="reservationStartTime" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="reservationEndTime" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="reservationState" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="resourceWorkspace" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="reservatonCheckInTime" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(parsedReservations, (reservation) => {
                    return (
                      <TableRow
                        key={reservation.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={event => onSelectReservation(reservation)}
                        selected={selectedReservation && selectedReservation.id === reservation.id}
                      >
                        <TableCell>
                          <h5 className={classes.tableCell}>
                            {reservation.fromDate?moment(reservation.fromDate).format('YYYY-MM-DD'):"-"}
                          </h5>
                        </TableCell>
                        <TableCell>
                          <h5 className={classes.tableCell}>
                            {reservation.resource?reservation.resource.name:""}
                          </h5>
                        </TableCell>
                        <TableCell>
                          <h5 className={classes.tableCell}>
                            {reservation.resource&&reservation.resource.type?reservation.resource.type.name:""}
                          </h5>
                        </TableCell>
                        <TableCell>
                          <h5 className={classes.tableCell}>
                            {`${reservation.user&&reservation.user.firstname?reservation.user.firstname:""} ${reservation.user&&reservation.user.lastname?reservation.user.lastname:""} ${!reservation.user?"-":""}`}
                          </h5>
                        </TableCell>
                        <TableCell>
                          <h5 className={classes.tableCell}>
                            {reservation.fromDate?moment(reservation.fromDate).format('HH:mm'):"-"}
                          </h5>
                        </TableCell>
                        <TableCell>
                          <h5 className={classes.tableCell}>
                            {reservation.toDate?moment(reservation.toDate).format('HH:mm'):"-"}
                          </h5>
                        </TableCell>
                        <TableCell>
                          <h5 className={classes.tableCell}>
                            {reservation.state&&reservationStatusArray[reservation.state]?reservationStatusArray[reservation.state]:reservation.state}
                          </h5>
                        </TableCell>
                        <TableCell>
                          <h5 className={classes.tableCell}>
                            {reservation.resource?reservation.resource.workspace:""}
                          </h5>
                        </TableCell>
                        <TableCell>
                          <h5 className={classes.tableCell}>
                            {reservation.checkInTime?moment(reservation.checkInTime).format('HH:mm'):"-"}
                          </h5>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    {/* 
                    <TableCell colSpan={12} style={{height: 52}}>
                      
                    </TableCell>
                    */}
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="invitationsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchInvitationsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchInvitationsOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title="noReservations"
                    onNewEntity={onNewReservation ? () => onNewReservation() : null}
                    newEntityTitle="createReservation"
                    subtitle="startAddReservations"
                    iconName="icon-simple-line-icons-user-following"
                  />) : <ListLoadingView />}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ReservationTAbleView);
