import { Divider } from '@material-ui/core';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { PERMISSIONS, PERMISSION_ENTITIES, RED } from '../../_config/consts';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import MDButton from '../MDButton/MDButton.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import CustomField from './Fields/CustomField.jsx';
import DefaultTimeProfileForm from './TimeProfiles/DefaultTimeProfileForm.jsx';

const validate = (values) => {
  const errors = {};
  if (values) {
    if (!values.name) {
      errors.name = 'required';
    }
    if (!values.lockTags || (values.lockTags && _.isEmpty(values.lockTags))) {
      errors.lockTags = 'required';
    }
    if (!values.credentialTimeframe
        || !values.credentialTimeframe.startDate
        || !values.credentialTimeframe.endDate) {
      errors.credentialTimeframe = 'reservationDateIntevalErrorMessage';
    }
    
    if (values.credentialTimeframe && values.credentialTimeframe.endDate && moment(values.credentialTimeframe.endDate).isAfter('2099-12-31')) {
      errors.credentialTimeframe = 'errorEndDateTooBig';
    }
    
    if (!values.timeIntervalFrom) {
      errors.timeIntervalFrom = 'required';
    }

    if (!values.timeIntervalTo) {
      errors.timeIntervalTo = 'required';
    }
    if (values.timeIntervalTo && values.timeIntervalFrom) {
      const toTime = moment(values.timeIntervalTo);
      const fromTime = moment(values.timeIntervalFrom);
      const toDate = moment(0).hour(toTime.hour()).minute(toTime.minute()).second(0);
      const fromDate = moment(0).hour(fromTime.hour()).minute(fromTime.minute()).second(0);
      if (toDate.isSameOrBefore(fromDate)) {
        errors.timeIntervalTo = 'Interval not valid';
      }
    }
  }

  return errors;
};

@reduxForm({ form: 'AccessProfileForm', validate })
@connect(state => ({ form: state.form.AccessProfileForm }))
class AccessProfileForm extends React.Component {

  render() {
    const {
      form,
      availableLockTags,
      onLockTagsChange,
      locksFilteredByTags,
      onLockFilterModeChange,
      onLockInputChange,
      onFetchMoreLocks,
      onDeleteAccessProfile,
      formData,
      is12HoursFormat,
      onInitializeNewTimeRange,
      dispatch,
    } = this.props;
    const isEditing = form && form.values && form.values.deviceBackendId;
    const canDeleteAccessProfile = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.ACCESS_PROFILES);
    return (
      <Form>
        <FormCardContainer
          title="accessProfileTitle"
          subtitle="accessProfileSubtitle"
          containerStyle={{ marginTop: 0 }}
        >
          <Entity
            componentClass={Field}
            name="name"
            componentAttribute="placeholder"
            entity="insertAccessProfileName"
            title={<Entity entity="accessProfileName" />}
            component={CustomField}
            type="text"
            disabled={!canDeleteAccessProfile}
            mandatory
          />
          <Divider style={{ marginTop: 20 }} />
          <DefaultTimeProfileForm
            formData={formData}
            form={form}
            formName="AccessProfileForm"
            credentialTimeframeText="accessProfileTimeFrame"
            is12HoursFormat={is12HoursFormat}
            onInitializeNewTimeRange={(timeRangeId, fieldName) => onInitializeNewTimeRange(timeRangeId, fieldName)}
            canEdit={canDeleteAccessProfile}
            availableLockTags={availableLockTags}
            locksFilteredByTags={locksFilteredByTags && locksFilteredByTags[0]}
            onFetchMoreLocks={page => onFetchMoreLocks(page)}
            onLockTagsChange={values => onLockTagsChange(values)}
            onLockInputChange={value => onLockInputChange(value)}
            onLockFilterModeChange={values => onLockFilterModeChange(values)}
          />
        </FormCardContainer>
        {onDeleteAccessProfile && canDeleteAccessProfile && isEditing && (
          <FormCardContainer
            title="deleteCredential"
            subtitle="deleteCredentialCardWarning"
          >
            <MDButton
              title={<Entity entity="deleteCard" />}
              backgroundColor={RED}
              onClick={() => onDeleteAccessProfile()}
            />
          </FormCardContainer>
        )}
      </Form>
    );
  }
} 

export default AccessProfileForm;
