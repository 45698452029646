// @ts-nocheck
import { withStyles } from '@material-ui/core';
import { Col, Grid, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { submit } from 'redux-form';
import { EXTERNAL_IDENTITY_PROVIDERS, GREEN } from '../../_config/consts.js';
import { isMobileBrowser } from '../../_config/utils';
import EmailSendIconCustom from '../../components/CustomIcons/EmailSendIconCustom.jsx';
import LoginWithChainels from '../../components/Login/LoginWithChainels.jsx';
import LoginWithCobot from '../../components/Login/LoginWithCobot.jsx';
import LoginWithEmailOTP from '../../components/Login/LoginWithEmailOTP.jsx';
import LoginWithNexudus from '../../components/Login/LoginWithNexudus.jsx';
import LoginWithOfficeRnd from '../../components/Login/LoginWithOfficeRnd.jsx';
import LoginWithSAML from '../../components/Login/LoginWithSAML.jsx';
import MDButton from '../../components/MDButton/MDButton.jsx';
import BasicModal from '../../components/Modals/BasicModal.jsx';
import LoginForm from '../../components/forms/LoginForm/LoginForm.jsx';
import PasswordRecoveryForm from '../../components/forms/LoginForm/PasswordRecoveryForm.jsx';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
    backgroundColor: 'white',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30,
    margin: 100,
    backgroundColor: 'white'
  },
  image: {
    height: 50,
    alignSelf: 'flex-start',
    marginBottom: 20,
  },
});

@withRouter
@connect(state => ({ userCredentials: state.user.userLogin, companyConfigurations: state.user.companyConfigurations }))
class Login extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    document.addEventListener('keydown', e => this.onSubmitPressed(e), false);
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', e => this.onSubmitPressed(e), false);
  }
  
  onSubmitPressed(e) {
    if (e.keyCode === 13) {
      const { dispatch } = this.props;
      dispatch(submit('LoginForm'));
    }
  }

  makeLogin(loginData) {
    const { dispatch } = this.props;
    const { email, password } = loginData;
    dispatch(UserActions.verifyUser(email, password));
  }

  getIDPLoginButton(idp) {
    if (!_.includes(idp.availablePlatforms, 'FE')) {
      return null;
    }

    if (idp && idp.type) {
      switch (idp.type) {
        case EXTERNAL_IDENTITY_PROVIDERS.SAML:
          return <LoginWithSAML idp={idp} />;
        case EXTERNAL_IDENTITY_PROVIDERS.NEXUDUS:
          return <LoginWithNexudus idp={idp} />;
        case EXTERNAL_IDENTITY_PROVIDERS.OTP_EMAIL:
          return <LoginWithEmailOTP idp={idp} />;
        case EXTERNAL_IDENTITY_PROVIDERS.COBOT:
          return <LoginWithCobot idp={idp} />;
        case EXTERNAL_IDENTITY_PROVIDERS.OFFICERND:
          return <LoginWithOfficeRnd idp={idp} />;
        case EXTERNAL_IDENTITY_PROVIDERS.CHAINELS:
          return <LoginWithChainels idp={idp} />;
        default:
          return null;
      }
    }
  }

  showForgotPassword() {
    //this.passwordForgetModal.open();
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="passwordForget" />,
        content: (
          <div style={{ marginTop: 50, display: 'flex', flexDirection: 'column' }}>
            <EmailSendIconCustom style={{ width: 60, alignSelf: 'center', color: GREEN }} />
            <h4 style={{ color: '#3f3f3f', marginBottom: 0, textAlign: 'center' }}>
              <Entity entity="passwordForgetDescription" />
            </h4>
            <PasswordRecoveryForm
              onSubmit={recoveryData => this.sendResetPassowrd(recoveryData)}
            />
            <MDButton
              backgroundColor="#157495"
              title={<Entity entity="passwordRecoveryButton" />}
              onClick={() => dispatch(submit('PasswordRecoveryForm'))}
            />
          </div>
        ),
        onConfirmText: <Entity entity="gotcha" />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async sendResetPassowrd(recoveryData) {
    const { dispatch } = this.props;
    dispatch(ModalActions.hideModal());
    await dispatch(UserActions.forgotPassword(recoveryData.email));
  }

  onGoToInvitationLogin() {
    const { dispatch } = this.props;
    dispatch(push('/loginInvitation'))
  }


  render() {
    const { dispatch, classes, companyConfigurations } = this.props;
    const isMobile = isMobileBrowser();
    const year = new Date().getFullYear();
    const externalIDP = !_.isEmpty(companyConfigurations) && companyConfigurations.externalIDP;
    const invitationsActive = companyConfigurations && companyConfigurations.invitationsActive;
    return (
      <div id="auth-container" style={{ height: '100vh', paddingTop: 0, backgroundImage: isMobile ? 'unset' : 'url(/imgs/common/loginBackground.jpg)', backgroundSize: 'cover' }} className="login">
        <Grid>
          <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Col
              id="login-container"
              xs={12}
              sm={12}
              style={{
                height: isMobile ? '100vh' : 'unset',
                width: isMobile ? null : 900,
                opacity: isMobile ? 1 : 0.9,
                margin: isMobile ? 0 : 100,
                overflow: 'scroll',
                position: 'relative',
                borderRadius: isMobile ? 0 : 10,
                boxShadow: isMobile ? 'unset' : '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
              }}
            >
              <img src="/imgs/common/logoJagoWhite.png" alt="Sofia" style={{ margin: 10, marginTop: 60, marginBottom: 15, alignSelf: 'center', height: 65 }} />
              <img src="/imgs/common/tagLine.png" alt="SofiaTagline" style={{ alignSelf: 'center', height: isMobile ? 13 : 23 }} />
              <div style={{ padding: 25, paddingTop: 0, paddingBottom: 0, marginBottom: 25, marginTop: 25 }}>
                <LoginForm
                  onSubmit={loginData => this.makeLogin(loginData)}
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Entity onClick={() => this.showForgotPassword()} entity="passwordForget" className="link-label" style={{ color: 'white', textDecoration: 'underline' }} />
                </div>
                <MDButton
                  title="LOGIN"
                  titleStyle={{ fontWeight: 'bold' }}
                  containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                  onClick={() => dispatch(submit('LoginForm'))}
                />
                {invitationsActive && isMobile ? (
                  <MDButton
                    type="submit"
                    backgroundColor="white"
                    title={<Entity entity="loginWithInvitationCode" />}
                    titleStyle={{ color: '#3f3f3f', fontWeight: 'bold', marginRight: 10 }}
                    containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                    onClick={() => this.onGoToInvitationLogin()}
                  />
                ) : null}
                {_.map(externalIDP, idp => this.getIDPLoginButton(idp))}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src="/imgs/common/logoSofiaWhite.png" alt="Sofia" style={{ height: 12, marginRight: 4 }} />
                  <h6 style={{ fontSize: 10, margin: 0, marginTop: 3, color: 'white' }}>©{year}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
        <BasicModal
          ref={(m) => { this.passwordForgetModal = m; }}
          title={<h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}><Entity entity="passwordForget" /></h3>}
          body={
            <div>
              <h4 style={{ color: '#3f3f3f', marginTop: 0, marginBottom: 20 }}>
                <Entity entity="passwordForgetDescription" />
              </h4>
              <PasswordRecoveryForm
                onSubmit={recoveryData => this.sendResetPassowrd(recoveryData)}
              />
              <MDButton
                backgroundColor="#157495"
                title={<Entity entity="passwordRecoveryButton" />}
                onClick={() => dispatch(submit('PasswordRecoveryForm'))}
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(Login);
