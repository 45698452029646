import { Card, Divider, List, ListItem, Popper } from '@material-ui/core';
import CaretIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import BookOpenIconCustom from './CustomIcons/BookOpenIconCustom.jsx';
import MessageAlertIconCustom from './CustomIcons/MessageAlertIconCustom.jsx';


@connect((state) => ({ user: state.user.data }))
class HelpMenuDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      anchorEl: null,
    };
  }

  render() {
    const { isOpen, anchorEl} = this.state;
    const { onOpenOnlineGuide, onOpenSupportTicket } = this.props;
    
    return (
      <div
        onMouseEnter={(e) => this.setState({ anchorEl: e.currentTarget, isOpen: true }) }
        onMouseLeave={(e) => this.setState({ anchorEl: null, isOpen: false })}
      >
        <div
          aria-controls="language-menu"
          aria-haspopup="true"
          onClick={(e) => this.setState({ anchorEl: e.currentTarget, isOpend: true })}
        >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4, marginRight: 10, marginLeft: 20 }}>
            <HelpIcon style={{ marginRight: 10, marginBottom: 0, fontSize: 23, color: 'white'}} />
            <h4 style={{ color: 'white', fontWeight: 'bold', marginTop: 12 }}><Entity entity="help" /></h4>
            <CaretIcon style={{ marginBottom: -2, fontSize: 20, color: 'white', marginLeft: 10 }} />
          </div>
        </div>
        <Popper
          open={isOpen}
          anchorEl={anchorEl}
          disablePortal={true}
          placement='bottom-start'
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: false,
            }
          }}
          style={{ zIndex: 9999, minWidth: 350, marginLeft: 150, paddingTop: 10 }}
        >
          <Card style={{ backgroundColor: 'white' }}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem key='flag-header' header style={{ backgroundColor: '#f0f0f0', marginTop: -10, height: 40 }}>
                <h5 style={{ margin: 0 }}><Entity entity="support" /></h5>
                </ListItem>
              <Divider />
              <ListItem
                key="help-0"
                button
                onClick={() => {
                  this.setState({ isOpen: false });
                  onOpenOnlineGuide();
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, alignItems: 'center', height: 45 }}>
                  <BookOpenIconCustom style={{ color: '#3f3f3f', width: 20, marginRight: 10 }} />
                  <h4 style={{ color: '#3f3f3f', margin: 0, marginTop: 0, fontSize: 16 }}><Entity entity="onlineManual" /></h4>
                </div>
              </ListItem>
              <ListItem
                key="help-1"
                button
                onClick={() => {
                  this.setState({ isOpen: false });
                  onOpenSupportTicket();
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, alignItems: 'center', height: 45 }}>
                  <MessageAlertIconCustom style={{ color: '#3f3f3f', width: 20, marginRight: 10 }} />
                  <h4 style={{ color: '#3f3f3f', margin: 0, marginTop: 0, fontSize: 16 }}><Entity entity="reportProblem" /></h4>
                </div>
              </ListItem>
            </List>
          </Card>
        </Popper>
      </div>
    );
  }
} 

export default HelpMenuDropdown;
