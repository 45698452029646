import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { withStyles } from '@material-ui/core';
import { BLUE } from '../_config/consts';

const styles = {
  circularProgress: {
    color: BLUE,
  },
};

const ListLoadingView = ({ classes, containerStyle }) => (
  <div style={{ marginTop: 40, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingVertical: 5, ...containerStyle }}>
    <h4 style={{ textAlign: 'center', color: '#3f3f3f' }}><Entity entity="loading" /></h4>
    <CircularProgress className={classes.circularProgress} />
  </div>
);

export default withStyles(styles)(ListLoadingView);
