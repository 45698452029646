
import React from 'react';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import StandardDeviceValidationForm from '../forms/StandardDeviceValidationForm/StandardDeviceValidationForm.jsx';
import { reduxForm, submit } from 'redux-form';
import { hasFormWritePermission } from '../../_config/utils.js';
import { PERMISSION_ENTITIES } from '../../_config/consts.js';
import MDButton from '../MDButton/MDButton.jsx';
import StandardDeviceValidationStatus from './StandardDeviceValidationStatus.jsx';
import { Divider } from '@material-ui/core';

const validate = (values) => {
  const errors = {};
  if (!values.validationMode || values.validationMode === '') {
    errors.validationMode = 'required';
  }
  return errors;
};

@reduxForm({ form: 'DeviceValidationPeriodViewForm', validate })
@connect((state) => ({ form: state.form.DeviceValidationPeriodViewForm, language: state.settings.language }))
class DeviceValidationPeriodView extends React.Component {
  
  render() {
    const { form, dispatch, selectedDevice, onOpenValidationInfo } = this.props;
    return (
      <div style={{ paddingRight: 20 }}>
        <StandardDeviceValidationStatus
          validationInfo={selectedDevice && selectedDevice.validationInfo}
          validationMode={selectedDevice && selectedDevice.validationMode}
          validationPeriod={selectedDevice && selectedDevice.validationPeriod}
        />
        <StandardDeviceValidationForm
          form={form}
          formName="DeviceValidationPeriodViewForm"
          hideCard
          onInfo={() => onOpenValidationInfo()}
        />
        {hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, true) && (
          <MDButton
            title={<Entity entity="save" />}
            containerStyle={{ width: '80%', margin: 'auto', marginTop: 30 }}
            style={{ height: 45, borderRadius: 0 }}
            onClick={() => dispatch(submit('DeviceValidationPeriodViewForm'))}
          />
        )}
      </div>
    );
  }
} 

export default DeviceValidationPeriodView;
