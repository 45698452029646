module.exports = {
  SET_SPINNER_VISIBILITY: 'SET_SPINNER_VISIBILITY',
  SET_SELECTED_LANGUAGE: 'SET_SELECTED_LANGUAGE',
  SET_VIEW_LOADING: 'SET_VIEW_LOADING',
  SET_LOADING_PROGRESS_VALUE: 'SET_LOADING_PROGRESS_VALUE',
  SET_LOADING_PROGRESS_VISIBLE: 'SET_LOADING_PROGRESS_VISIBLE',
  SET_LOADING_BACKGROUND_VISIBLE: 'SET_LOADING_BACKGROUND_VISIBLE',
  ADD_BACKGROUND_TASK: 'ADD_BACKGROUND_TASK',
  REMOVE_BACKGROUND_TASK: 'REMOVE_BACKGROUND_TASK',
  SET_WHATS_NEW_VISIBLE: 'SET_WHATS_NEW_VISIBLE',
  SET_SUPPORT_TICKET_FORM_VISIBLE: 'SET_SUPPORT_TICKET_FORM_VISIBLE',
};
