import { Card, createTheme, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import InstallIcon from '@material-ui/icons/Build'
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { BLUE, GREEN } from '../../_config/consts';
import MDSwitchView from '../forms/Fields/MDSwitch/MDSwitchView.jsx';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    margin: 10,
    padding: 10,
    paddingBottom: 15,
    marginBottom: 15,
    marginLeft: 20,
    marginRight: 20,
    borderLeft: '4px solid #157495',
    width: 'fit-content',
  },
  textContainer: {
  },
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 25,
  },
  descriptionText: {
    color: '#3f3f3f',
    margin: 0,
    fontWeight: 'normal',
    marginTop: 10,
  },
  switchView: {
    marginLeft: 40,
    marginTop: 10,
  },
  verticalDivider: {
    width: 2,
    backgroundColor: '#e2e2e2',
    height: 60,
    marginLeft: 40,
  },
  icon: {
    fontSize: 35,
    marginRight: 20,
    marginLeft: 10,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const InstallationModeView = ({ installationMode, classes, onToggleInstallationMode }) => {
  const statusColor = installationMode ? GREEN : '#bdbdbd';
  return (
    <MuiThemeProvider theme={theme}>
      <Card className={`${classes.container} card card-rounded installation-view-container`} style={{ borderLeft: `6px solid ${statusColor}` }} onClick={() => onToggleInstallationMode(!installationMode)}>
        <InstallIcon className={classes.icon} style={{ color: statusColor }} />
        <div className={classes.textContainer}>
          <h2 className={classes.titleText}><Entity entity="installationMode" /></h2>
          <h4 className={classes.descriptionText}><Entity entity="installationModeDescription" /></h4>
        </div>
        <div className={classes.verticalDivider} />
        <div className={classes.switchView}>
          <MDSwitchView
            checked={installationMode}
            label={!installationMode ? <Entity key={installationMode} entity="notActive" /> : <Entity key={installationMode} entity="active" style={{ marginRight: 38 }} />}
            titleStyle={{ fontWeight: 'bold', color: statusColor }}
            onSelect={value => onToggleInstallationMode(value)}
          />
      </div>
      </Card>
    </MuiThemeProvider>
  );
};
export default withStyles(styles)(InstallationModeView);
