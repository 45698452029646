import IconButton from '@material-ui/core/IconButton';
import ShowIcon from '@material-ui/icons/Visibility';
import HideIcon from '@material-ui/icons/VisibilityOff';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';

const validate = values => {
  const errors = {};

  if (!values.token) {
    errors.token = 'required';
  }
  return errors;
};

@reduxForm({ form: 'OptixLoginForm', validate })
class OptixLoginForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isPasswordVisible: false,
    };
  }

  onTogglePasswordVisibility() {
    const { isPasswordVisible } = this.state;
    this.setState({ isPasswordVisible: !isPasswordVisible });
  }

  render() {
    const { isPasswordVisible } = this.state;
    return (
      <div>
        <div style={{ marginTop: 10, display: 'flex', position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Entity
            componentClass={Field}
            name="token"
            type={isPasswordVisible ? 'text' : 'password'}
            componentAttribute="placeholder"
            entity=""
            title="Optix Organization Token"
            containerStyle={{ width: '100%' }}
            component={CustomField}
          />
          <IconButton style={{ position: 'absolute', right: '5px', bottom: '9px' }} onClick={() => this.onTogglePasswordVisibility()}>
            {!isPasswordVisible ? <ShowIcon style={{ color: '#3f3f3f', fontSize: 20 }} /> : <HideIcon style={{ color: '#3f3f3f', fontSize: 20 }} />}
          </IconButton>
        </div>
      </div>
    );
  }
} 

export default OptixLoginForm;
