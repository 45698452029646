import React from 'react';
import { useDrag } from 'react-dnd';

import Card from '@material-ui/core/Card';
import { DND_ELEMENTS, GREEN } from '../../_config/consts';
import { getLockImage } from '../../_config/utils.js';
import MapIconCustom from '../CustomIcons/MapIconCustom.jsx';

export default function MapLockRow({ name, id, model, locationMedia }) {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: DND_ELEMENTS.SMART_LOCK_ROW,
      item: { name, id },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    []
  )
  return (
    <Card
      ref={dragRef}
      className={`card card-rounded`}
      style={{ borderRadius: 10, opacity, position: 'relative' }}
      elevation={2}
    >
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img className="lock-icon" style={{ width: 40, height: 40 }} src={getLockImage(model)} />
          <h4 className="card-title" style={{ margin: 2, marginTop: 1, fontSize: 16, marginLeft: 10 }}>{name}</h4>
        </div>
        {locationMedia ? <MapIconCustom style={{ width: 20, position:'absolute', top: 5, right: 5, color: GREEN }} /> : null}
      </div>
    </Card>
  );
}
