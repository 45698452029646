// @ts-nocheck
import AppBar from '@material-ui/core/AppBar';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import UsersIcon from '@material-ui/icons/PeopleOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, submit } from 'redux-form';
import { BLUE, LANGUAGES, PERMISSION_ENTITIES } from '../../_config/consts';
import { hasFormWritePermission } from '../../_config/utils';
import AccessProfileForm from '../../components/forms/AccessProfileForm.jsx';
import * as CredentialActions from '../../redux/actions/credential.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import MDButton from '../../components/MDButton/MDButton.jsx';
import ClipboardIconCustom from '../../components/CustomIcons/ClipboardIconCustom.jsx';
import AccessProfilesUserList from './AccessProfilesUserList.jsx';
import UsersIconCustom from '../../components/CustomIcons/UsersIconCustom.jsx';

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

@connect(state => ({
  credential: state.credentials.selectedCredential,
  lockTags: state.tags.lock.data,
  userTags: state.tags.user.data,
  language: state.settings.language,
  accessProfiles: state.accessProfiles,
  form: state.form.AccessProfileForm,
}))
class AccessProfilesOperationalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,

    };
  }

  onInitializeNewTimeRange(timeRangeId, fieldName) {
    const { dispatch } = this.props;
    dispatch(change('AccessProfileForm', `${fieldName}[${timeRangeId}].daysOfTheWeek`, [1, 2, 3, 4, 5]));
    dispatch(change('AccessProfileForm', `${fieldName}[${timeRangeId}].timeIntervalFrom`, moment().startOf('day').valueOf()));
    dispatch(change('AccessProfileForm', `${fieldName}[${timeRangeId}].timeIntervalTo`, moment().endOf('day').valueOf()));
  }

  

  fetchLocksByTags(page, index, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchLocksByTags());
    dispatch(CredentialActions.fetchLocksByTagsStardardCredentialsForm('ACCESS_PROFILE', index, page, append));
  }

  filterLockTags(value) {
    const { dispatch } = this.props;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 200, true));
  }

  render() {
    const { userTags, form, lockTags, onDeleteAccessProfile, accessProfiles: { selectedAccessProfile }, language, credential, dispatch, onAccessProfileFormSubmit } = this.props;
    const { selectedTab } = this.state;
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          {selectedAccessProfile && selectedAccessProfile.id ? (
            <AppBar position="sticky" color="default">
              <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                scrollable
                onChange={(e, index) => this.setState({ selectedTab: index })}
                style={{ paddingLeft: 25 }}
                TabIndicatorProps={{
                  style: { display: 'none' }
                }}
              >
                <Tab icon={<ClipboardIconCustom style={{ width: 25 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="cardInformation" /></h5>} />
                {selectedAccessProfile && selectedAccessProfile.id &&
                  <Tab icon={<UsersIconCustom style={{ width: 25 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="accessProfilesUsers" /></h5>} />
                }
              </Tabs>
            </AppBar>
          ) : null}
          <div style={{ marginBottom: 500, padding: 20, display: selectedTab === 0 ? 'block' : 'none' }}>
            <AccessProfileForm
              accessProfile={selectedAccessProfile}
              onSubmit={accessProfileData => onAccessProfileFormSubmit(accessProfileData)}
              availableLockTags={lockTags}
              locksFilteredByTags={credential.locksFilteredByTags}
              formData={form && form.values}
              is12HoursFormat={is12HoursFormat}
              onFetchMoreLocks={(page, index) => this.fetchLocksByTags(page, index, true)}
              onLockInputChange={value => this.filterLockTags(value)}
              onLockTagsChange={(_, index) => this.fetchLocksByTags(0, index)}
              onLockFilterModeChange={(_, index) => this.fetchLocksByTags(0, index)}
              onDeleteAccessProfile={() => onDeleteAccessProfile(selectedAccessProfile.id)}
              onInitializeNewTimeRange={(timeRangeId, fieldName) => this.onInitializeNewTimeRange(timeRangeId, fieldName)}
            />
            <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
              <MDButton
                title={<Entity entity="save" />}
                containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
                style={{ height: 45, borderRadius: 0 }}
                onClick={() => dispatch(submit('AccessProfileForm'))}
              />
            </div>
          </div>
          {selectedAccessProfile.id && selectedTab === 1 && (
            <div style={{ padding: 20 }}>
              <AccessProfilesUserList />
            </div>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
} 

export default AccessProfilesOperationalSection;
