module.exports = {
  SAVE_RESOURCES: 'SAVE_RESOURCES',
  SAVE_RESOURCE: 'SAVE_RESOURCE',
  SAVE_RESOURCES_TYPES: 'SAVE_RESOURCES_TYPES',
  APPEND_RESOURCES_TYPES: 'APPEND_RESOURCES_TYPES',
  SET_RESOURCES_TYPES_PAGINATION_DATA: 'SET_RESOURCES_TYPES_PAGINATION_DATA',
  RESET_RESOURCES_DATA: 'RESET_RESOURCES_DATA',
  SET_RESOURCES_FILTER: 'SET_RESOURCES_FILTER',
  RESET_RESOURCES_FILTER: 'RESET_RESOURCES_FILTER',
  SET_SELECTED_RESOURCE: 'SET_SELECTED_RESOURCE',
  SET_RESOURCES_PAGINATION_DATA: 'SET_RESOURCES_PAGINATION_DATA',
  SET_RESOURCES_CURRENT_PAGE: 'SET_RESOURCES_CURRENT_PAGE',
  UPDATE_RESOURCE: 'UPDATE_RESOURCE',
  APPEND_RESOURCES: 'APPEND_RESOURCES',
  SAVE_RESOURCE_AVAILABILITY: 'SAVE_RESOURCE_AVAILABILITY',
  RESET_RESOURCE_AVAILABILITY: 'RESET_RESOURCE_AVAILABILITY',
  FETCH_RESOURCE_TYPES_TAGS_BY_FILTER: 'FETCH_RESOURCE_TYPES_TAGS_BY_FILTER',
  CANCEL_RESOURCES_TYPES_BY_FILTER: 'CANCEL_RESOURCES_TYPES_BY_FILTER',
};
