import React from 'react';
import { withStyles, RadioGroup, Card } from '@material-ui/core';
import _ from 'lodash';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import Warning from '@material-ui/icons/Warning';
import MDButton from '../MDButton/MDButton.jsx';
import { GREEN, RED } from '../../_config/consts';
import Tag from '../Tag.jsx';
import MDRadioButtonView from '../forms/Elements/MDRadioButtonsGroup/MDRadioButtonView.js';


const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  titleText: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'left',
  },
  sectionText: {
    margin: 0,
    marginRight: 5,
    fontSize: 18,
    lineHeight: 1.4,
    color: '#878787',
    textAlign: 'left',
  },
  exampleContainer: {
    marginTop: 15,
    borderRadius: 5,
  },
  exampleText: {
    marginLeft: 12,
    marginTop: 10,
    fontSize: 15,
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  warningTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: -20,
  },
  guestCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestDataText: {
    fontWeight: 'bold',
    margin: 0,
    color: '#3f3f3f',
    marginTop: 2,
  },
});

const UserRow = ({ item, isEaven }) => {
  const fakeUserTag = {
    color: '#ba68c8',
    name: item && item.username ? item.username.toLowerCase() : '',
    type: 'user',
  };
  const backgroundColor = !isEaven ? '#FAFAFA' : 'white';
  return (
    <div
      className="card-noHover tag-element-selected"
      style={{ display: 'flex', zIndex: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 5, marginBottom: 0, marginTop: 0, backgroundColor }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h5 style={{ margin: 0, color: '#3f3f3f' }}>{item.name || `${item.firstname} ${item.lastname}`}{item.email ? <h6 style={{ margin: 0, marginTop: 2, color: '#3f3f3f' }}>{item.email}</h6> : null}</h5>
        
      </div>
      <div className="tags-container" style={{ margin: 0, marginLeft: 55 }}>
        {item && item.username &&
          <Tag textStyle={{ fontSize: 12 }} style={{ paddingTop: 4, paddingBottom: 4 }} tag={fakeUserTag} />
        }
      </div>
    </div>
  );
};

class GuestEnableDisableBatchView extends React.Component {

  render() { 
    const { classes, guests, isDisabling, onEnableGuestsBatchConfirm, onDisableGuestsBatchConfirm } = this.props;
    return (
      <div style={{ marginLeft: 13, marginBottom: 30, padding: 10 }}>
        <div className={classes.warningTitleContainer}>
          <Warning style={{ color: 'orange', fontSize: 25 }} />
          <h2 className={classes.titleText} style={{ margin: 0, marginLeft: 10, fontSize: 25 }}><Entity entity="warning" /></h2>
        </div>
        <div className={classes.guestCardContainer}>
          <h2 className={classes.sectionText} style={{ color: '#3f3f3f', fontWeight: 'bold', fontSize: 20 }}><Entity entity={isDisabling ? "guestsBatchToDisable" : "guestsBatchToEnable"} data={{ number: _.size(guests) }} /></h2>
        </div>
        <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10, marginTop: 20 }}>
          <div style={{ maxHeight: 200, overflowY: 'scroll' }}>
            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
              {_.map(guests, (item, index) =>
                <UserRow
                  item={item}
                  isEaven={index % 2 === 0}
                />,
              )}
            </div>
          </div>
        </div>
        <h2 className={classes.sectionText} style={{ marginTop: 20 }}><Entity entity={isDisabling ? "disableUsersWarning" : "enableUsersWarning"} />.</h2>
        {isDisabling ? (
          <MDButton
            title={<Entity entity="confirmDisableUsers" />}
            backgroundColor={RED}
            onClick={() => onDisableGuestsBatchConfirm()}
          />
        ) : (
          <MDButton
            title={<Entity entity="confirmEnableUsers" />}
            backgroundColor={GREEN}
            onClick={() => onEnableGuestsBatchConfirm()}
          />
        )}
      </div>
    );
  }
}
export default withStyles(styles)(GuestEnableDisableBatchView);
