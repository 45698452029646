import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import TimeIcon from '@material-ui/icons/AccessTime';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import LockIcon from '@material-ui/icons/LockOpen';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { CARDS_CREDENDIAL_RULES_STATUSES } from '../../../_config/consts';
import { elaborateCredentialDaysArray, getLockImage } from '../../../_config/utils';
import CardCredentialStatus from '../../Credentials/CardCredentialStatus.jsx';
import KeyOutlinedIconCustom from '../../CustomIcons/KeyOutlinedIconCustom.jsx';

@connect((state) => ({ settings: state.settings }))
class GuestF9000CredentialRow extends React.Component {
  render() {
    const { credential, hiddenHeader, onSelectF90000CredentialRule } = this.props;
    const days = elaborateCredentialDaysArray(credential);
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
      selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
    }));
    let statusColor = credential.state !== CARDS_CREDENDIAL_RULES_STATUSES.TO_BE_INSERTED ? '#43A047' : '#EF6C00';
    statusColor = credential.state === CARDS_CREDENDIAL_RULES_STATUSES.FAILED ? 'red' : statusColor;

    const lockSection = (
      <div style={{ paddingRight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {credential && credential.smartLock && credential.smartLock.model ? (
          <img className="lock-icon" style={{ marginRight: 10, marginLeft: 4, width: 30, height: 30 }} src={getLockImage(credential.smartLock.model)} />
        ) : (
          <LockIcon style={{ fontSize: 30, color: '#3f3f3f', paddingLeft: 5, marginRight: 10 }} />
        )}
        <h4 style={{ margin: 0, color: '#3f3f3f', textAlign: 'center' }}>{credential.smartLock.name}</h4>
      </div>
    );


    return (
      <Card
        className={`card card-rounded ${!onSelectF90000CredentialRule && 'card-noHover'}`}
        onClick={onSelectF90000CredentialRule ? () => onSelectF90000CredentialRule() : null}
        style={{ marginBottom: 30, borderRadius: 10, borderLeft: `4px solid ${statusColor}`, marginLeft: 2, marginRight: 2 }}
        elevation={3}
      >
        <div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {!hiddenHeader ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <KeyOutlinedIconCustom style={{ width: 33, color: '#3f3f3f', paddingLeft: 5, marginRight: 5 }} />
                <h4 style={{ fontWeight: 'bold', color: '#3f3f3f', margin: 0, marginTop: 4, marginLeft: 2, marginBottom: 4 }}><Entity entity="f9000Key" /></h4>
              </div>
            ) : lockSection}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <CardCredentialStatus
                status={credential.state}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 5, marginBottom: 5, height: 2 }} />
          <div style={{ paddingTop: 5, flexDirection: 'column', paddingBottom: 5, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', marginTop: 15 }}>
            {!hiddenHeader ? lockSection : null}
            <div style={{ marginTop: !hiddenHeader ? 15 : 0, display: 'flex', alignItems: 'center' }}>
              <CalendarIcon style={{ fontSize: 30, color: '#3f3f3f', paddingLeft: 5, marginRight: 10 }} />
              <h5 style={{ margin: 0, color: '#3f3f3f' }}>{`${moment(credential.fromDate).format('LL')} - ${moment(credential.toDate).format('LL')}`}</h5>
              <TimeIcon style={{ marginLeft: 20, fontSize: 25, color: '#3f3f3f', marginRight: 10 }} />
              <h5 style={{ margin: 0, color: '#3f3f3f' }}>{`${moment().startOf('day').add(credential.timeInSecondsFrom, 'seconds').format('LT')}-${moment().startOf('day').add(credential.timeInSecondsTo, 'seconds').format('LT')}`}</h5>
              <div style={{ marginLeft: 20, display: 'flex' }}>
                {_.map(daysOptions, option => (
                  <h5 style={{ margin: 0, marginRight: 5, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
                    {option.label.toUpperCase()}
                  </h5>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
} 

export default GuestF9000CredentialRow;
