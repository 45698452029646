module.exports = {
  SAVE_USERS_CUSTOM_FIELDS: 'SAVE_USERS_CUSTOM_FIELDS',
  SAVE_USERS_CUSTOM_FIELDS_PAGINATION: 'SAVE_USERS_CUSTOM_FIELDS_PAGINATION',
  SET_USERS_CUSTOM_FIELDS_FILTERS: 'SET_USERS_CUSTOM_FIELDS_FILTERS',
  RESET_USERS_CUSTOM_FIELDS_FILTERS: 'RESET_USERS_CUSTOM_FIELDS_FILTERS',
  SAVE_LOCKS_CUSTOM_FIELDS: 'SAVE_LOCKS_CUSTOM_FIELDS',
  SAVE_LOCKS_CUSTOM_FIELDS_PAGINATION: 'SAVE_LOCKS_CUSTOM_FIELDS_PAGINATION',
  SET_LOCKS_CUSTOM_FIELDS_FILTERS: 'SET_LOCKS_CUSTOM_FIELDS_FILTERS',
  RESET_LOCKS_CUSTOM_FIELDS_FILTERS: 'RESET_LOCKS_CUSTOM_FIELDS_FILTERS',
};
