import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 50,
  },
  title: {
    color: 'white',
    margin: 0,
    marginTop: 10,
  },
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 80,
  },
  progressBar: {
    color: 'white',
    width: 220,
  },
  progressText: {
    marginLeft: 10,
    color: 'white',
    margin: 0,
    width: 70,
  },
};

const theme = createTheme({
  palette: {
    primary: { 500: '#5AC0B1' },
  },
  typography: {
    useNextVariants: true,
  },
});

const ProgressBar = ({ title, classes, progress }) => (
  <MuiThemeProvider theme={theme}>
    <div className={classes.container}>
      <img src="/imgs/common/logoJagoWhite.png" alt="Luckey" className={classes.logo} />
      <div className={classes.progressBarContainer}>
        <LinearProgress color="primary" className={classes.progressBar} variant="determinate" value={progress} />
        <h4 className={classes.progressText}>{`${progress || '0'} %`}</h4>
        <CircularProgress style={{ color: 'white', marginLeft: 5 }} />
      </div>
      {title ? <h4 className={classes.title}><Entity entity={title} /></h4> : null}
    </div>
  </MuiThemeProvider>
);

export default withStyles(styles)(ProgressBar);
