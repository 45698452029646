import React, { Component } from 'react';
import { Card, withStyles, IconButton, createTheme } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import LockIcon from '@material-ui/icons/LockOpen';
import { CircularProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { BLUE } from '../../../_config/consts';
import { MuiThemeProvider } from '@material-ui/core/styles';


const styles = theme => ({
  titleText: {
    margin: 0,
    color: '#3f3f3f',
    marginLeft: 10,
    marginTop: 5,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    borderRadius: 10,
    margin: 5,
    justifyContent: 'center',
  },
  icon: {
    fontSize: 27,
    marginTop: 2,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '38px !important',
    width: '38px !important',
  },
  chartsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  refreshButton: {
    position: 'absolute',
    right: 5,
    top: -5,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  refreshIcon: {
    fontSize: 20,
    color: '#3f3f3f',
  },
  separator: {
    margin: 10,
    marginBottom: 0,
    height: 2,
  },
  linkText: {
    textAlign: 'center',
    paddingTop: 12,
    margin: 0,
    paddingBottom: 4,
    width: '100%',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

class SmartLocksMetricChart extends Component {
  render() {
    const { metric: { count }, isRefreshing, classes, onMetricClick, onShortcutClick, onRefreshMetric } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Card elevation={3} className={classes.container}>
          <div style={{ cursor: 'pointer' }} onClick={onMetricClick ? () => onMetricClick('/locks') : null}>
            <div className={classes.header}>
              <div className={classes.titleContainer} >
                <LockIcon className={classes.icon} />
                <h3 className={classes.titleText}><Entity entity="locks" /></h3>
              </div>
              <IconButton
                disabled={isRefreshing}
                style={{ opacity: isRefreshing ? 0.5 : 1 }}
                className={classes.refreshButton}
                onClick={(e) => {
                  e.stopPropagation();
                  onRefreshMetric('smartLocks');
                }}
              >
                <RefreshIcon className={classes.refreshIcon} />
              </IconButton>
            </div>
            <div className={classes.chartsContainer}>
              {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : <h1 className={classes.countText}>{count}</h1>}
            </div>
          </div>
          <hr className={classes.separator} />
          <h4 className={`link-label ${classes.linkText}`} style={{ fontWeight: 'bold' }}  onClick={() => onShortcutClick()}><Entity entity="showAll" /></h4>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(SmartLocksMetricChart);
