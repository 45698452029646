import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { GREEN } from '../../../_config/consts';
import MDButton from '../../MDButton/MDButton.jsx';

const StoreItemManualActivationView = ({ requestToQuote, onRequestQuotation, isFreeItem, containerStyle, onRequestActivation }) => (
  <div style={{ marginTop: 0, paddingTop: 10, ...containerStyle }}>
    {!requestToQuote ? (
      <div>
        {!isFreeItem ? (
          <h4 style={{ color: 'gray', fontWeight: '100', textAlign: 'center', fontSize: 16 }}><Entity entity="requestActivationManualDisclaimer" /></h4>
        ) : null}
        <MDButton
          title={<Entity entity="requestActivation" />}
          containerStyle={{ marginTop: 0 }}
          titleStyle={{ fontSize: 17, fontWeight: 'bold' }}
          backgroundColor={GREEN}
          style={{ marginTop: 0, height: 50 }}
          onClick={() => onRequestActivation()}
        />
      </div>
    ) : (
      <MDButton
        title={<Entity entity="requestQuotation" />}
        containerStyle={{ marginTop: 0 }}
        titleStyle={{ fontSize: 17, fontWeight: 'bold' }}
        backgroundColor={GREEN}
        style={{ marginTop: 0, height: 50 }}
        onClick={() => onRequestQuotation()}
      />
    )}
  </div>
);
export default StoreItemManualActivationView;
