import React, { PropTypes } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';

export default class AirbnbDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDateChange(date) {
    // this.setState({ startDate, endDate });
    this.props.onChange(date);
  }

  onFocusChange({ focused }) {
    this.setState({ focused });
    this.props.onFocus(focused);
  }

  render() {
    const { focused } = this.state;
    const { value } = this.props;

    return (
      <div>
        <SingleDatePicker
          {...this.props}
          date={value ? moment(value) : null}
          isOutsideRange={() => false}
          numberOfMonths={1}
          focused={focused}
          hideKeyboardShortcutsPanel
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
        />
      </div>
    );
  }
}
