import { Col, Grid, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import SpinnerOverlay from '../components/Spinner/SpinnerOverlay.jsx';
import { submit } from 'redux-form';
import Card from '@material-ui/core/Card';
import UserIcon from '@material-ui/icons/AccountCircle';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UserActions from '../redux/actions/user.actions';
import PasswordRecoveryForm from '../components/forms/LoginForm/PasswordRecoveryForm.jsx';
import MDButton from '../components/MDButton/MDButton.jsx';
import { isMobileBrowser } from '../_config/utils.js';

@connect((state) => ({ }))
class ActivateAccountView extends React.Component {
  
  async sendResetPassowrd(recoveryData) {
    const { dispatch } = this.props;
    await dispatch(UserActions.forgotPassword(recoveryData.email));
    dispatch(ModalActions.showModal({
      modalType: 'SUCCESS_MODAL',
      modalProps: { message: 'activateAccountSuccess' },
    }));
  }

  render() {
    const { dispatch } = this.props;
    const isMobile = isMobileBrowser();
    return (
      <div id="auth-container" style={{ backgroundSize: 'cover', backgroundImage: `url(/imgs/common/loginBackground.jpg)`, height: '100vh', overflow: 'scroll', display: 'flex', alignItems: 'center', backgroundColor: '#12637f', paddingTop: 70, flexDirection: 'column' }} className="login">
        <Grid style={{ width: '100%' }}>
          <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Card style={{ width: isMobile ? null : 900, background: 'linear-gradient(#1B97C2, #157495)', opacity: 0.9, borderRadius: 10, padding: 20, marginLeft: isMobile ? 10 : 100, marginRight: isMobile ? 10 : 100, display: 'flex', flexDirection: 'column' }}>
              <img src="/imgs/common/logoJagoWhite.png" alt="Sofia" style={{ alignSelf: 'center', height: 60 }} />
              <img src="/imgs/common/tagLine.png" alt="SofiaTagline" style={{ alignSelf: 'center', height: 13 }} />
              <div style={{ marginTop: 40, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <UserIcon style={{ color: 'white', fontSize: 35 }} />
                <h3 style={{ fontSize: isMobile ? 18 : 24, color: 'white', textAlign: 'center', margin: 0, marginBottom: 10, padding: 5, fontWeight: 'bold' }}>
                  <Entity entity="activateYourAccount" />
                </h3>
                <h4 style={{ lineHeight: '1.2em', color: 'white', textAlign: 'center', margin: 0, padding: 5, fontSize: 15 }}>
                  <Entity entity="activateYourAccountDescription" />
                </h4>
              </div>
              <div style={{ maxWidth: 400, width: '100%', padding: 20, alignSelf: 'center' }}>
                <PasswordRecoveryForm
                  onSubmit={recoveryData => this.sendResetPassowrd(recoveryData)}
                />
                <MDButton
                  title={<Entity entity="sendActivationEmail" />}
                  onClick={() => dispatch(submit('PasswordRecoveryForm'))}
                />
              </div>
            </Card>
          </Row>
        </Grid>
        <SpinnerOverlay />
      </div>
    );
  }
} 

export default ActivateAccountView;
