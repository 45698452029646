import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { PERMISSION_ENTITIES, PERMISSIONS, VIEW_MODES } from '../_config/consts';
import AreaTableView from '../components/CapacityManagement/AreaTableView.jsx';
import AreasOperationalSection from '../components/CapacityManagement/AreasOperationalSection.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import * as AreaActions from '../redux/actions/area.actions';
import * as FloorPlansActions from '../redux/actions/floorplans.actions.js';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';

let filterTimeout;
@connect((state) => ({ areas: state.areas, floorPlans: state.floorPlans,routing: state.router, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName, }))
class AreasManagement extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('areasViewMode');
    this.state = {
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    };
  }
  async componentWillMount() {
    const { dispatch, routing, areas } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    dispatch(AreaActions.selectArea({}));
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      dispatch(AreaActions.resetAreasFilters());
      await dispatch(AreaActions.fetchAllLocks());
      await dispatch(AreaActions.fetchAreas());
      await dispatch(FloorPlansActions.fetchFloorPlans());
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
    const areaId = parsed.areaId;
    const newEntity = parsed.newEntity;
    if (areaId) {
      const newArea = areas.filter(e=>e.id===areaId).length>0?areas.filter(e=>e.id===areaId)[0]:undefined;
      if (newArea) {
        this.selectArea(newArea);
      }
    }
    if (newEntity) {
      this.onCreateNewArea();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(AreaActions.resetAreasFilters());
    dispatch(AreaActions.selectArea({}));
  }

  async fetchAreas(page = 0, append = false) {
    const { dispatch } = this.props;
    try {
      this.setState({ viewLoading: true });
      await dispatch(AreaActions.fetchAreas());
      this.setState({ viewLoading: false });
    } catch (error) {
      this.setState({ viewLoading: false });
    }
  }

  async closeOperationalSection() {
    const { dispatch } = this.props;
    await dispatch(AreaActions.setOperationalMode(false));
    await dispatch(AreaActions.selectArea({}));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(AreaActions.setOperationalMode(true));
  }

  async onSetFilter(field, value, useTimeout) {
    const { dispatch } = this.props;
    if (value && ((_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2) || (_.isNumber(value) && value>0))) {
      await dispatch(AreaActions.setFilter(field, value));
      if (useTimeout) {
        if (filterTimeout) clearTimeout(filterTimeout);
        filterTimeout = setTimeout(async () => {
          await this.fetchAreas()
        }, 500);
      }
      else {
        dispatch(UtilsActions.setSpinnerVisibile(true));
        await this.fetchAreas()
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    } else {
      if (!useTimeout)
        dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(AreaActions.setFilter(field, undefined));
      await this.fetchAreas()
      if (!useTimeout)
        dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onResetFilters() {
    const { dispatch } = this.props;
    await dispatch(AreaActions.resetAreasFilters());
    dispatch(initialize('AreasFilters', {}));
    await dispatch(AreaActions.fetchAreas());
  }

  async selectArea(area) {
    const { dispatch, areas } = this.props;
    const selectedArea = areas.selectedArea
    if (selectedArea&&selectedArea.id===area.id)
      return;
    try {
      dispatch(AreaActions.selectArea(area));
      dispatch(initialize('AreaDetailsViewForm', area));
      this.openOperationalSection()
    } catch (error) {
      dispatch(AreaActions.selectArea({}));
    } finally {
      
    }
  }

  async onAppendAreas(page) {
    const { dispatch } = this.props;
    const append = true;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(AreaActions.fetchAreas(page, append));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onFetchAreaOnPage(page) {
    const { dispatch } = this.props;
    dispatch(AreaActions.fetchAreas(page));
  }

  async onAreaEditConfirmed(areaId,areaData) {
    const { dispatch, areas } = this.props;
    const data = areaData;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const areaResponse = await dispatch(AreaActions.editArea(areaId,data));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'areaPropertiesAssigned' }} /></h6>),
        },
      }));
      this.closeOperationalSection()
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onAreaCreateConfirmed(areaData) {
    const { dispatch, areas } = this.props;
    const data = areaData;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(AreaActions.createArea(data));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'areaCreated' }} /></h6>),
        },
      }));
      if (areas.isOperationalMode) {
        this.closeOperationalSection()
      }
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(AreaActions.resetAreasFilters());
      await dispatch(AreaActions.fetchAreas());
    }
  }

  async onDeleteAreaRequest(selectedAreaId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="deleteAreaDataMessage" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onDeleteAreaConfirm(selectedAreaId),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteAreaConfirm(selectedAreaId) {  
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(AreaActions.deleteArea(selectedAreaId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(AreaActions.setOperationalMode(false));
      dispatch(AreaActions.selectArea({}));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'areaDeleted' }} /></h6>),
        },
      }));
      this.onRefreshAreas()
    } catch (error) {
      let errorMessage = 'areaDeleteError';
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }
  
  async onRefreshAreas() {
    const { areas } = this.props;
    const page = areas && areas.data && areas.data.pagination && areas.data.pagination.number;
    this.onFetchAreaOnPage(page);
  }

  onOrderChanged() {
    const { dispatch } = this.props;
    dispatch(AreaActions.fetchAreas())
  }

  async onCreateNewArea() {
    const { dispatch } = this.props;
    dispatch(AreaActions.selectArea({}));
    dispatch(initialize('AreaDetailsViewForm', {}));
    this.openOperationalSection()
  }

  onShowInfo() {
    /* TODO */
    /*
    const helpURL = localizeHelpCenterLink('luckey-floor-plans');
    window.open(helpURL);
    */
  }

  render() {
    const { areas, themeName } = this.props;
    const selectedAreaId = areas?.selectedArea?.id
    const selectedAreaName = areas?.selectedArea?.name;
    const canEditCreateAreas = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.AREA);
    return (
      <div style={{ backgroundColor: 'white'}}>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={canEditCreateAreas ? () => this.onCreateNewArea() : null}
          newEntityTitle="addArea"
          newEntityIconName="icon-simple-line-icons-user-following"
          onSearchReset={() => this.onResetFilters()}
          onFilterChange={value => this.onSetFilter('search', _.trim(value), true)}
          onSearchSubmit={() => this.fetchAreas()}
          onInfo={() => this.onShowInfo()}
        />
        <AreaTableView
          onSelectArea={area => this.selectArea(area)}
          onFetchAreaOnPage={page => this.onFetchAreaOnPage(page)}
          onRefreshAreas={() => this.onRefreshAreas()}
          onNewArea={canEditCreateAreas ? () => this.onCreateNewArea() : null}
          onDeleteArea={(areaId) => {this.onDeleteAreaRequest(areaId)}}
          onOrderChanged={() => this.onOrderChanged()}
        />
        <OperationalView
          themeName={themeName}
          isVisible={areas.isOperationalMode}
          onClose={() => this.closeOperationalSection()}
          onDelete={selectedAreaName && canEditCreateAreas ? () => this.onDeleteAreaRequest(selectedAreaId) : null}
          style={{ margin: 0, padding: 0 }}
          title={<Entity entity={selectedAreaName?selectedAreaName:"createArea"} data={{ name: 'areasOperational' }} key={selectedAreaName} />}
        >
          <AreasOperationalSection
            themeName={themeName}
            onAreaEditConfirmed={(areaId,AreaData)=>this.onAreaEditConfirmed(areaId,AreaData)}
            onAreaCreateConfirmed={(AreaData)=>this.onAreaCreateConfirmed(AreaData)}
            onRefreshAreas={()=>this.onRefreshAreas()}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default AreasManagement;
