import IconButton from '@material-ui/core/IconButton';
import ShowIcon from '@material-ui/icons/Visibility';
import HideIcon from '@material-ui/icons/VisibilityOff';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';

const validate = values => {
  const errors = {};

  if (!values.clientId) {
    errors.clientId = 'required';
  }

  if (!values.clientSecret) {
    errors.clientSecret = 'required';
  }

  if (!values.tenantId) {
    errors.tenantId = 'required';
  }

  if (!values.principalEmail) {
    errors.principalEmail = 'required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.principalEmail)) {
    errors.principalEmail = 'invalidEmailAddress';
  }
  return errors;
};

@reduxForm({ form: 'Office365ActivationForm', validate })
class Office365ActivationForm extends React.Component {
  
  render() {
    return (
      <div>
        <Entity
          name="principalEmail"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertAccountEmail"
          title={<Entity entity="email" />}
          helpText={<Entity entity="userEmailCalendarDescription" />}
          component={CustomField}
        />
        <Entity
          name="clientId"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertClientId"
          title="Client ID"
          component={CustomField}
        />
        <Entity
          name="clientSecret"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertClientSecret"
          title="Client Secret"
          component={CustomField}
        />
        <Entity
          name="tenantId"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertClientId"
          title="Tenant ID"
          component={CustomField}
        />
      </div>
    );
  }
} 

export default Office365ActivationForm;
