import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';

const validate = values => {
  const errors = {};

  if (!values.accessToken) {
    errors.accessToken = 'required';
  }


  return errors;
};

@reduxForm({ form: 'MewsActivationForm', validate })
class MewsActivationForm extends React.Component {
  
  render() {
    return (
      <div>
        <Entity
          name="accessToken"
          componentClass={Field}
          componentAttribute="placeholder"
          entity=""
          title="Mews API Key"
          component={CustomField}
        />
      </div>
    );
  }
} 

export default MewsActivationForm;
