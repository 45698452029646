import React from 'react';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import CheckIcon from '@material-ui/icons/CheckCircle';
import MDButton from '../../MDButton/MDButton.jsx';

const GatewayNotificationAppliedView = ({ notification, onClose }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <CheckIcon
      style={{ color: '#4DB6AC', fontSize: 50 }}
    />
    <h4 style={{ color: '#3f3f3f', marginTop: 10, fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
      <Entity entity="notificationOpenApplied" />
    </h4>
    <MDButton
      title={<Entity entity="close" />}
      onClick={() => onClose()}
    />
  </div>
);

export default GatewayNotificationAppliedView;
