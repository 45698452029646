import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React, { Component } from 'react';
import { submit, initialize, change, reset } from 'redux-form';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as PlatformUserActions from '../../redux/actions/platformUsers.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as RolesActions from '../../redux/actions/roles.actions';
import PlatformUserForm from '../forms/PlatformUserForm.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import { connect } from 'react-redux';
import RolesOperationalSection from '../Roles/RolesOperationalSection.jsx';
import OperationalView from '../OperationalView/OperationalView.jsx';
import { LICENSE_TYPES, PERMISSION_ENTITIES, SUBSCRIPTION_TYPES } from '../../_config/consts';
import { hasFormWritePermission } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';

@connect((state) => ({ form: state.form.PlatformUserForm, companyConfigurations: state.user.companyConfigurations, themeName: state.settings.items.theme.data.themeName  }))
class PlatformUsersOperationalSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRoleCreationOperationalOpen: false,
    };
  }

  async onSavePlatformUser(values) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      if (values.id) {
        await dispatch(PlatformUserActions.editPlatformUser(values));
      } else {
        await dispatch(PlatformUserActions.createPlatformUser(values));
      }
      dispatch(PlatformUserActions.setOperationalMode(false));
      dispatch(PlatformUserActions.setSelectedPlatformUser({}));
      dispatch(PlatformUserActions.fetchPlatformUsers());
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onEnablePlatformUser(user) {
    this.onResetPassword(user);
  }

  onConfirmResetPassword(user) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'resetPasswordConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onResetPassword(user),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };

    dispatch(ModalActions.showModal(params));
  }

  async onResetPassword(user) {
    const { dispatch } = this.props;
    try {
      dispatch(UserActions.resetPassword(user.id));
      dispatch(PlatformUserActions.setOperationalMode(false));
      dispatch(PlatformUserActions.setSelectedPlatformUser({}));
    } catch (error) {}
  }

  

  async onNewRole(value) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const permissions = await dispatch(RolesActions.fetchPermissions());
      dispatch(RolesActions.setSelectedRole({ ...permissions }));
      dispatch(initialize('RoleForm', {
        name: value,
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ isRoleCreationOperationalOpen: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onNewRoleCreated(value) {
    const { dispatch } = this.props;
    const newRole = { value: String(value.id), label: value.name };
    dispatch(change('PlatformUserForm', 'userRole', newRole));
    dispatch(change('PlatformUserForm', 'roleIds', [value.id]));
    this.setState({ isRoleCreationOperationalOpen: false });
  }

  onSelectGuestToPromote(guest) {
    const { dispatch } = this.props;
    if (!guest) {
      dispatch(reset('PlatformUserForm'));
      dispatch(change('PlatformUserForm', 'canPromoteGuests', true));
      dispatch(change('PlatformUserForm', 'isGuestManager', false));
    } else {
      dispatch(change('PlatformUserForm', 'id', guest.id));
      dispatch(change('PlatformUserForm', 'firstname', guest.firstname));
      dispatch(change('PlatformUserForm', 'lastname', guest.lastname));
      dispatch(change('PlatformUserForm', 'email', guest.email));
      dispatch(change('PlatformUserForm', 'username', guest.username));
      dispatch(change('PlatformUserForm', 'isGuestManager', true));
    }
  }

  render() {
    const { selectedUser, dispatch, themeName, onDisableUserRequest, companyConfigurations, onDeleteUser } = this.props;
    const { isRoleCreationOperationalOpen } = this.state;
    const isEditing = selectedUser.id;
    const isACLPluginActive = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENTERPRISE]);
    return (
      <div>
        <PlatformUserForm
          platformUser={selectedUser}
          isDisabledRoleSection={!isACLPluginActive}
          onConfirmResetPassword={() => this.onConfirmResetPassword(selectedUser)}
          onDisableUser={() => onDisableUserRequest(selectedUser)}
          onEnableUser={() => this.onEnablePlatformUser(selectedUser)}
          onDeleteUser={() => onDeleteUser(selectedUser)}
          onSubmit={values => this.onSavePlatformUser(values)}
          onNewRole={value => this.onNewRole(value)}
          onGuestSelected={guest => this.onSelectGuestToPromote(guest)}
        />
        {hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing) &&
          <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
            <MDButton
              style={{ height: 45, borderRadius: 0 }}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              title={<Entity entity="save" />}
              onClick={() => dispatch(submit('PlatformUserForm'))}
            />
          </div>
        }
        <OperationalView
          title={<Entity entity="sectionTitle" data={{ name: 'roleOperational' }} />}
          themeName={themeName}
          isVisible={isRoleCreationOperationalOpen}
          onClose={() => this.setState({ isRoleCreationOperationalOpen: false })}
        >
          <RolesOperationalSection
            onRoleCreationSuccess={newRole => this.onNewRoleCreated(newRole)}
          />
        </OperationalView>
      </div>
    );
  }
}

export default PlatformUsersOperationalSection;