import { axiosInstance } from "./rest";

export function fetchLatestAgreements(params) {
  const paramsToSend = {
    params,
  };
  return axiosInstance.get('api/v2/agreements/last', paramsToSend);
}

export function checkUpdatedAgreements(params) {
  const paramsToSend = {
    params,
  };
  return axiosInstance.get('api/v2/agreements/check', paramsToSend);
}

export function acceptAgreements(data) {
  return axiosInstance.post('api/v2/agreements', data);
}
