// @ts-nocheck
import { IconButton } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Cancel';
import LockIcon from '@material-ui/icons/LockOpen';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { Modal } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { LICENSE_TYPES, OFFLINE_TIME_UNIT, SMARTLOCKS_PROGRAM_TYPES, VIEW_MODES } from '../_config/consts.js';
import { calculateCompaniesToAddAndRemove, canConfigureOfficeMode, convertValueToNewTimeUnit } from '../_config/utils.js';
import CameraLiveFeedView from '../components/Cameras/CameraLiveFeedView.jsx';
import FloorPlanViewer from '../components/FloorPlans/FloorPlanViewer.jsx';
import LockRemoteOpenView from '../components/Locks/LockRemoteOpenView.jsx';
import LockSharingView from '../components/Locks/LockSharingView.jsx';
import LocksListView from '../components/Locks/LocksListView.jsx';
import LocksOperationalSection from '../components/Locks/LocksOperationalSection.jsx';
import LocksTableView from '../components/Locks/LocksTableView.jsx';
import BasicModal from '../components/Modals/BasicModal.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import TranslatableOption from '../components/forms/Fields/TranslatableOption.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as CamerasActions from '../redux/actions/cameras.actions';
import * as FloorPlansActions from '../redux/actions/floorplans.actions.js';
import * as GatewayActions from '../redux/actions/gateways.actions';
import * as LockActions from '../redux/actions/lock.actions';
import * as LogEventsActions from '../redux/actions/logEvents.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import LocksTagsBatchError from '../components/Locks/LocksTagsBatchError.jsx';
import LocksTagsBatchView from '../components/Locks/LocksTagsBatchView.jsx';

let filterTimeout;
const batteryLevelFilterOptions = [
  { id: 0, name: 'ALL', value: 'ALL', label: 'batteryLevel' },
  { id: 1, name: 'EMPTY', value: 0, label: '0%' },
  { id: 2, name: 'LOW', value: 25, label: '25%' },
  { id: 3, name: 'MID', value: 50, label: '50%' },
  { id: 4, name: 'MIDHIGH', value: 75, label: '75%' },
  { id: 5, name: 'HIGH', value: 100, label: '100%' },
  { id: 6, name: 'PLUGGED', value: 'PLUGGED', label: 'plugged' },
];
@connect((state) => ({ routing: state.router, cameras: state.cameras, locks: state.locks, companyConfigurations: state.user.companyConfigurations, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName, customFields: state.customFields.locks.content, floorPlans: state.floorPlans }))
class Locks extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('lockViewMode');
    this.state = {
      selectedTags: null,
      showCameraLiveFeed: false,
      showLockSharingSection: false,
      floorPlanSmartLocks: [],
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    };
  }
  async componentWillMount() {
    const { routing, onSelectLock, dispatch } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    const lockId = parsed.lockId;
    dispatch(LockActions.selectLock({}));
    dispatch(LockActions.resetLocksFilters());
    this.onFetchLocks();
    if (lockId) {
      const lockDetails = await dispatch(LockActions.fetchSmartLockDetails(lockId));
      this.onSelectLock(lockDetails);
    }
  }


  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(LockActions.selectLock({}));
    dispatch(LockActions.resetLocksFilters());
    dispatch(LockActions.setOperationalMode(false));
  }

  async onFetchLocks() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(LockActions.fetchLocks());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onAppendLocks(page) {
    const { dispatch } = this.props;
    const append = true;
    try {
      dispatch(UtilsActions.setViewLoading(true));
      await dispatch(LockActions.fetchLocks(page, append));
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onSetFilter(field, value) {
    const { dispatch } = this.props;
    if (value && ((_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2) || _.isBoolean(value))) {
      dispatch(LockActions.setFilter(field, value));
      if (filterTimeout) clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => {
        dispatch(UtilsActions.setViewLoading(true));
        await dispatch(LockActions.fetchLocks());
        dispatch(UtilsActions.setViewLoading(false));
      }, 500);
    } else {
      dispatch(LockActions.setFilter(field, null));
      await dispatch(LockActions.fetchLocks());
    }
  }

  async onFilterLocksByTag(tags) {
    const { dispatch } = this.props;
    if (tags && !_.isEmpty(tags)) {
      dispatch(UtilsActions.setViewLoading(true));
      const formattedTag = _.map(tags, tag => tag.value);
      this.onSetFilter('tagIds', formattedTag);
    } else {
      this.onSetFilter('tagIds', null);
      dispatch(UtilsActions.setViewLoading(true));
      await dispatch(LockActions.fetchLocks());
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(LockActions.setOperationalMode(false));
    dispatch(LockActions.selectLock({}));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(LockActions.setOperationalMode(true));
  }

  async onOpenLockRequest(lock) {
    this.closeOperationalSection();
    if (lock && lock.configuration && lock.configuration.remoteOpenEnabled) {
      this.onOpenLockRemotely(lock);
    } else {
      this.onOpenLockByGateway(lock);
    }
  }

  async onOpenLockRemotely(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LockActions.onOpenFullOnlineLockRemote(lock));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="doorOpenSuccess" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="doorOpenFailed" /></h6>),
        },
      }));
    }
  }

  async onOpenLockByGateway(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const gatewayDetailed = await dispatch(GatewayActions.getGatewayDetails(lock.gatewayId));
      dispatch(GatewayActions.setSelectedGateway(gatewayDetailed));
      dispatch(LockActions.selectLock(lock));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.lockOpenModal.open();
    } catch (error) {
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onSelectLock(lock) {
    const { dispatch } = this.props;
    let lockToSave = lock;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      lockToSave = await dispatch(LockActions.fetchSmartLockDetails(lock.id));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
    if (lockToSave.gatewayId) {
      try {
        const gatewayDetailed = await dispatch(GatewayActions.getGatewayDetails(lock.gatewayId));
        lockToSave = {
          ...lockToSave,
          gateway: gatewayDetailed,
        };
      } catch (error) {

      }
    }
    if (canConfigureOfficeMode(lock)) {
      lockToSave = await dispatch(LockActions.fetchSmartLockPrograms(lockToSave));
      const smartLockOptions = await dispatch(LockActions.fetchSmartLockOptions(lockToSave, 'ALL_DEVICES'));
      lockToSave = {
        ...lockToSave,
        options: smartLockOptions,
      };
      this.onInitializeLockOfficeModeForm(lockToSave);
    }
    if (lock.shared) {
      lockToSave = await dispatch(LockActions.fetchSharedSubcompaniesForLock(lockToSave));
    }
    dispatch(LockActions.selectLock(lockToSave));
    // if (lockToSave && !lockToSave.troubleshootingArticle) {
    //   dispatch(LockActions.fetchSmartLockTroubleShootingArticle(lockToSave));
    // }
    dispatch(LockActions.setOperationalMode(true));
    this.setState({ showEditLockNameSection: false });
    const initialFromDate = moment().subtract(1, 'month').valueOf();
    dispatch(LogEventsActions.setSmartLocksEventsFilter('fromDate', initialFromDate));
    dispatch(LogEventsActions.setSmartLocksEventsFilter('smartLockId', lock.id));
    dispatch(LogEventsActions.fetchSmartLocksEvents());
    dispatch(initialize('LockTagsForm', lock));
    dispatch(initialize('LockNameInlineForm', { name: lock.name }));
    dispatch(UtilsActions.setSpinnerVisibile(false));
    this.onInitializeLockOfflineForm(lock);
  }

  onInitializeLockOfflineForm(lock) {
    const { dispatch } = this.props;
    const selectedTimeUnit = localStorage.getItem('offlineTimeUnitSelected');
    let availableOfflineMinutes = lock.availableOfflineMinutes;
    try {
      availableOfflineMinutes = convertValueToNewTimeUnit(availableOfflineMinutes, OFFLINE_TIME_UNIT.minutes, selectedTimeUnit);
    } catch (error) {}
    dispatch(initialize('LockOfflineAvailabilityForm', {
      availableOffline: lock.availableOffline,
      availableOfflineMinutes,
      timeUnit: selectedTimeUnit || 'minutes',
    }));
  }

  onInitializeLockOfficeModeForm(lock) {
    const { dispatch } = this.props;
    const officeModePrograms = lock.programs && _.filter(lock.programs, program => program.type === SMARTLOCKS_PROGRAM_TYPES.OFFICE_MODE);
    const allDeviceOption = lock.options && _.filter(lock.options, option => option.deviceId === 'ALL_DEVICES' && option.feature === 'TOGGLE_PASSAGE_MODE');
    if (!officeModePrograms || _.isEmpty(officeModePrograms)) {
      dispatch(initialize('LockOfficeModeForm', {
        programs: [],
        isEditing: false,
        allDeviceOptionActive: !_.isEmpty(allDeviceOption),
      }));
    } else {
      const officeModeFirstProgram = officeModePrograms && _.first(officeModePrograms);
      dispatch(initialize('LockOfficeModeForm', {
        programs: officeModeFirstProgram.data,
        isEditing: true,
        allDeviceOptionActive: !_.isEmpty(allDeviceOption),
        ...officeModeFirstProgram,
      }));
    }
  }
  

  onResetFilters() {
    const { dispatch } = this.props;
    dispatch(LockActions.selectLock({}));
    dispatch(LockActions.resetLocksFilters());
    this.onFetchLocks();
  }

  async onFilterLocksByBatteryLevel(batteryLevel) {
    const { dispatch } = this.props;
    if (batteryLevel === 'ALL') {
      dispatch(LockActions.setFilter('batteryMin', undefined));
      dispatch(LockActions.setFilter('batteryMax', undefined));
      dispatch(LockActions.setFilter('powerSourceType', undefined));
    } else if (batteryLevel === 'PLUGGED') {
      dispatch(LockActions.setFilter('batteryMin', undefined));
      dispatch(LockActions.setFilter('batteryMax', undefined));
      dispatch(LockActions.setFilter('powerSourceType', 'PLUGGED'));
    } else {
      dispatch(LockActions.setFilter('powerSourceType', 'BATTERY'));
      dispatch(LockActions.setFilter('batteryMin', batteryLevel));
      dispatch(LockActions.setFilter('batteryMax', batteryLevel));
    }
    dispatch(UtilsActions.setViewLoading(true));
    await dispatch(LockActions.fetchLocks());
    dispatch(UtilsActions.setViewLoading(false));
  }

  onFetchLocksForPage(page) {
    const { dispatch } = this.props;
    dispatch(LockActions.fetchLocks(page));
  }

  onRefreshLocks() {
    const { dispatch } = this.props;
    const { locks } = this.props;
    const page = locks && locks.data && locks.data.pagination && locks.data.pagination.number;
    dispatch(LockActions.fetchLocks(page));
  }

  onOrderChanged() {
    const { dispatch } = this.props;
    dispatch(LockActions.fetchLocks())
  }

  onLocksListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    LockActions.setLocksViewMode(selectedViewMode);
    if (activeTab === 0) {
      dispatch(LockActions.resetLocksPaginationData());
      dispatch(LockActions.fetchLocks(0));
    }
  }

  onExportElements(format) {
    const { dispatch } = this.props;
    dispatch(LockActions.exportSmartLocks(format));
  }

  async onCustomFieldSearch(value, customFieldKey) {
    const { dispatch } = this.props;
    const customAttributesTerm = value;
    const customAttributesFields = [customFieldKey];
    dispatch(LockActions.setFilter('customAttributesTerm', customAttributesTerm));
    dispatch(LockActions.setFilter('customAttributesFields', customAttributesFields));
    setTimeout(async () => await dispatch(LockActions.fetchLocks()), 300);
  }

  async onOpenCameraFeed(cameraId) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CamerasActions.fetchCameras());
      const updatedCamera = await dispatch(CamerasActions.fetchCameraAdditionalData(cameraId));
      if (updatedCamera.liveFeedURL) {
        dispatch(CamerasActions.setSelectedCamera(updatedCamera));
      } else {
        const liveFeedURL = await dispatch(CamerasActions.fetchCameraLiveFeed(cameraId));
        dispatch(CamerasActions.setSelectedCamera({ id: cameraId, ...updatedCamera, ...liveFeedURL }));
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showCameraLiveFeed: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorGettingCameraLiveFeed" /></h6>),
        },
      }));
    }
  }

  async onOpenSharingSection() {
    const { locks: { selectedLock }, dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const updatedLock = await dispatch(LockActions.fetchSharedSubcompaniesForLock(selectedLock));
      dispatch(LockActions.selectLock(updatedLock));
      dispatch(initialize('LockSharingForm', {
        subcompaniesIds: updatedLock && _.map(updatedLock.subcompanies, sub => sub.id),
      }))
      this.setState({ showLockSharingSection: true });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorFetchingLockSharingOptions" /></h6>),
        },
      }));
    }
  }

  async onSaveLockSharingOptions(sharingOptions) {
    const { dispatch, locks: { selectedLock } } = this.props;
    try {
      const originalSubcompanies = _.map(selectedLock.subcompanies, subcompany => subcompany.id);
      const operations = calculateCompaniesToAddAndRemove(sharingOptions.subcompaniesIds, originalSubcompanies);
      if (operations.addedIds && !_.isEmpty(operations.addedIds)) {
        dispatch(UtilsActions.setSpinnerVisibile(true));
        await dispatch(LockActions.shareSmartLockToSubCompanies(operations.addedIds, selectedLock.id));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
      if (operations.removedIds && !_.isEmpty(operations.removedIds)) {
        const subcomaniesIdsToRemove = _.filter(selectedLock.subcompanies, subcompany => _.includes(operations.removedIds, subcompany.id));
        const smartLocksIdsToRemove = _.map(subcomaniesIdsToRemove, subcompany => subcompany.lockIdInCompany);
        dispatch(UtilsActions.setSpinnerVisibile(true));
        await dispatch(LockActions.removeSharedLockFromSubCompanies(smartLocksIdsToRemove));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
      
      const lockToSave = await dispatch(LockActions.fetchSharedSubcompaniesForLock(selectedLock));
      dispatch(LockActions.selectLock(lockToSave));
      this.setState({ showLockSharingSection: false });
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="sharingLockSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="sharingLockError" /></h6>),
        },
      }));
    }
  }

  async onShowLockMap(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(LockActions.selectLock(lock));
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const floorPlanSmartLocks = await dispatch(LockActions.fetchLocksOnFloorPlan(lock.locationMedia.id));
      this.setState({ floorPlanSmartLocks })
      dispatch(FloorPlansActions.setSelectedFloorPlan(lock.locationMedia))
      this.lockMapModal.open();
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch(error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onSetLockTagsVisibility(lock, isVisible) {
    const { dispatch } = this.props; 
    dispatch(LockActions.updateLockInState({ ...lock, showAllTags: isVisible }));
  }

  onAddTagsBatchRequest(locks) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        content: (<LocksTagsBatchView removeMode={false} locks={locks} onAddTagsLocksBatchConfirm={(tags) => {this.onAddTagsLocksBatchConfirm(locks, tags)}} />),
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onRemoveTagsBatchRequest(locks) {
    const { dispatch } = this.props;
    const tags = []
    locks.forEach(lock => {
      lock.tags.forEach(tag => {
        tags.push(tag)
      })
    })
    const tagsU = _.uniqBy(tags, 'id')
    dispatch(initialize('LockBatchTagsForm',{removableTags: tagsU, deletingTags: []}));
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        content: (<LocksTagsBatchView removeMode={true} locks={locks} onRemoveTagsLocksBatchConfirm={(tags) => {this.onRemoveTagsLocksBatchConfirm(locks, tags)}} />),
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onAddTagsLocksBatchConfirm(locks, tags) {
    const { dispatch, locks: { data: { pagination: { number }}} } = this.props;
    const uniqueLocks = _.uniqBy(locks, 'id')
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LockActions.addTagsBatchLocks(uniqueLocks, tags));
      dispatch(ModalActions.hideModal());
      dispatch(LockActions.fetchLocks(number));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(ModalActions.hideModal());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const params = {
        modalType: 'CONTENT_MODAL',
        modalProps: {
          content: (<LocksTagsBatchError locks={uniqueLocks} onClose={() => dispatch(ModalActions.hideModal())} />),
          modalStyle: { height: '90% !important' },
          onOutsideClick: () => dispatch(ModalActions.hideModal()),
        },
      };
      dispatch(ModalActions.showModal(params));
    }
  }

  async onRemoveTagsLocksBatchConfirm(locks, tags) {
    const { dispatch, locks: { data: { pagination: { number }}} } = this.props;
    const uniqueLocks = _.uniqBy(locks, 'id')
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LockActions.removeTagsBatchLocks(uniqueLocks, tags));
      dispatch(ModalActions.hideModal());
      dispatch(LockActions.fetchLocks(number));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(ModalActions.hideModal());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const params = {
        modalType: 'CONTENT_MODAL',
        modalProps: {
          content: (<LocksTagsBatchError locks={uniqueLocks} onClose={() => dispatch(ModalActions.hideModal())} />),
          modalStyle: { height: '90% !important' },
          onOutsideClick: () => dispatch(ModalActions.hideModal()),
        },
      };
      dispatch(ModalActions.showModal(params));
    }
  }

  render() {
    const { locks, locks: { selectedLock }, floorPlans: { selectedFloorPlan }, dispatch, themeName, viewLoading, customFields, cameras: { selectedCamera } } = this.props;
    const { showCameraLiveFeed, activeTab, floorPlanSmartLocks, showLockSharingSection } = this.state;
    const numberOfLocks = locks && locks.data && locks.data.pagination && locks.data.pagination.totalElements ? locks.data.pagination.totalElements : 0;
    const locksData = locks && locks.data && locks.data.content;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          searchPlaceholderEntityName="lockName"
          isLoading={viewLoading}
          isLockTagsMultiSelectionActive
          onSelectLockTag={tags => this.onFilterLocksByTag(tags)}
          onFilterChange={value => this.onSetFilter('name', value)}
          onSelectLockHasGateway={value => this.onSetFilter('hasGateway', value)}
          onSearchReset={() => this.onResetFilters()}
          onCustomFieldSearch={(value, customFieldKey) => this.onCustomFieldSearch(value, customFieldKey)}
          showEntitiesNumber
          customFields={customFields}
          onSelectOption={batteryLevelOption => this.onFilterLocksByBatteryLevel(batteryLevelOption)}
          selectableFieldName="batteryLevel"
          onExportElements={!isLuckeyLite ? format => this.onExportElements(format) : null}
          numberOfElements={numberOfLocks}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <LockIcon style={{ fontSize: 22 }} />
              <h4 style={{ marginTop: 3 }}>{numberOfLocks}</h4>
            </div>
          }
          selectableOptions={_.map(batteryLevelFilterOptions, status =>
            <Entity
              key={status.name}
              componentClass={TranslatableOption}
              value={status.value}
              componentAttribute="text"
              entity={status.label}
            />)}
        />
        {locksData && !_.isEmpty(locksData) && !isLuckeyLite ? (
          <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onLocksListModeChange(index)}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          </div>
        ) : null}
        {activeTab === 0 && !isLuckeyLite ? (
          <LocksTableView
            themeName={themeName}
            onOpenLock={lock => this.onOpenLockRequest(lock)}
            onSelectLock={lock => this.onSelectLock(lock)}
            onRefreshLocks={() => this.onRefreshLocks()}
            onFetchLocksOnPage={page => this.onFetchLocksForPage(page)}
            onShowCameraLive={cameraId => this.onOpenCameraFeed(cameraId)}
            onShowLockMap={lock => this.onShowLockMap(lock)}
            onSetLockTagsVisibility={(lock, isVisible) => this.onSetLockTagsVisibility(lock, isVisible)}
            onAddTagsBatch={locks => this.onAddTagsBatchRequest(locks)}
            onRemoveTagsBatch={locks => this.onRemoveTagsBatchRequest(locks)}
            onOrderChanged={() => this.onOrderChanged()}
          />
          ) : null}
        {activeTab === 1 || isLuckeyLite ? (
          <LocksListView
            listContainerStyle={{ top: isLuckeyLite ? 140 : 190 }}
            onOpenLock={lock => this.onOpenLockRequest(lock)}
            onSelectLock={lock => this.onSelectLock(lock)}
            onAppendLocks={page => this.onAppendLocks(page)}
          />
        ) : null}
        <OperationalView
          themeName={themeName}
          isVisible={locks.isOperationalMode}
          style={{ margin: 0, padding: 0 }}
          onRefresh={() => this.onSelectLock(locks.selectedLock)}
          onClose={() => this.closeOperationalSection()}
          title={<Entity entity="sectionTitle" data={{ name: 'locksOperational' }} />}
        >
          <LocksOperationalSection
            onOpenLock={lock => this.onOpenLockRequest(lock)}
            onInitializeLockOfflineForm={lock => this.onInitializeLockOfflineForm(lock)}
            onShowCameraFeed={cameraId => this.onOpenCameraFeed(cameraId)}
            onShowLockMap={lock => this.onShowLockMap(lock)}
            onOpenLockSharingSettings={() => this.onOpenSharingSection()}
          />
        </OperationalView>
        <OperationalView
          themeName={themeName}
          isVisible={showLockSharingSection}
          style={{ margin: 0, padding: 0 }}
          onClose={() => this.setState({ showLockSharingSection: false })}
          title={<Entity entity="shareSmartLock" data={{ name: 'locksOperational' }} />}
        >
          <LockSharingView
            onSaveLockSharingOptions={sharingOptions => this.onSaveLockSharingOptions(sharingOptions)}
          />
        </OperationalView>
        <BasicModal
          ref={(m) => { this.lockOpenModal = m; }}
          hideCloseButton
          body={
            <div>
              <LockRemoteOpenView
                onCloseModal={() => {
                  this.lockOpenModal.close();
                  dispatch(LockActions.selectLock({}));
                }}
              />
            </div>
          }
        />
        <BasicModal
          ref={(m) => { this.lockMapModal = m; }}
          hideCloseButton
          body={
            <div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
                {selectedFloorPlan ? <h3 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f' }} >{selectedFloorPlan.name}</h3> : null}
                <IconButton onClick={() => this.lockMapModal.close()}>
                  <CloseIcon style={{ color: '#e95841', fontSize: 35 }} />
                </IconButton>
              </div>
              <FloorPlanViewer
                selectedElements={[selectedLock]}
                highLightSelectedElements
                floorPlanSmartLocks={floorPlanSmartLocks}
                onCloseModal={() => {
                  this.lockOpenModal.close();
                  dispatch(LockActions.selectLock({}));
                }}
              />
            </div>
          }
        />
        <Modal
          show={showCameraLiveFeed}
          className="alert-container full-height-modal"
          onHide={() => {
            this.setState({ showCameraLiveFeed: false });
            dispatch(CamerasActions.setSelectedCamera({}));
          }}
        >
          <CameraLiveFeedView
            camera={selectedCamera}
            onCloseModal={() => {
              this.setState({ showCameraLiveFeed: false });
              dispatch(CamerasActions.setSelectedCamera({}));
            }}
          />
        </Modal>
      </div>
    );
  }
} 

export default Locks;
