import { Col, Form, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';


const validate = (values) => {
  const errors = {};
  return errors;
};

@reduxForm({ form: 'AnalyticsTableFilters', validate })
@connect(state => ({ form: state.form.AnalyticsTableFilters }))
class AnalyticsTableFilters extends React.Component {
  render() {
    const { onDatesChanged, hideDate, hideHighlight, type } = this.props;
    return (
      <Form>
        <input style={{ display: 'none' }} type="username" name="username" />
        {!hideDate ? (
          <Col xs={4} sm={4}>
            <Entity
              componentClass={Field}
              title={<Entity entity="dateInterval" />}
              name="credentialTimeframe"
              component={CustomField}
              type="airbnbDateRange"
              onHandleChange={onDatesChanged}
              style={{ marginBottom: 20 }}
              minimumNights={0}
              // showClearDates
              isOutsideRange={() => false}
            />
          </Col>
        ) : null}
        {!hideHighlight ? (
          <div>
            {type === 'users' ? (
              <Col xs={4} sm={4}>
                <Entity
                  componentClass={Field}
                  name="userFilter"
                  componentAttribute="placeholder"
                  entity="insertDescription"
                  title={<Entity entity="guestNameSearch" />}
                  component={CustomField}
                  type="text"
                />
              </Col>
            ) : null}
            {type === 'resources' ? (
              <Col xs={4} sm={4}>
                <Entity
                  componentClass={Field}
                  name="resourceFilter"
                  componentAttribute="placeholder"
                  entity="insertDescription"
                  title={<Entity entity="searchResource" />}
                  component={CustomField}
                  type="text"
                />
              </Col>
            ) : null}
            <Col xs={4} sm={4}>
              <Entity
                componentClass={Field}
                name="highlightGreaterThan"
                componentAttribute="placeholder"
                entity="insertDescription"
                title={<Entity entity="highlightUsage" />}
                component={CustomField}
                type="text"
              />
            </Col>
          </div>
        ) : null}
      </Form>
    );
  }
} 

export default AnalyticsTableFilters;
