/* eslint-disable max-len */
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { change } from 'redux-form';
import * as XLSX from 'xlsx';
import { EXPORT_FORMATS, GREEN, ORANGE, RED } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import { saveDataToLocalStorage } from '../../_config/utils';
import * as ModalActions from './modal.actions';
import {
  SAVE_AREAS,
  APPEND_AREAS,
  SET_AREAS_PAGINATION_DATA,
  RESET_AREAS_PAGINATION_DATA,
  SELECT_AREA,
  RESET_AREAS_FILTERS,
  SET_AREA_FILTER,
  UPDATE_AREA,
  SET_AREA_OPERATIONAL_MODE,
  SAVE_LOCKS,
  FETCH_AREA_LOCKS_BY_TAGS,
  CANCEL_FETCH_AREA_LOCKS_BY_TAGS,
  SET_AREA_LOCKS_FILTERED_BY_TAGS,
  APPEND_AREA_LOCKS_FILTERED_BY_TAGS,
  SET_AREA_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
  SAVE_AREA_OCCUPANCY,
  RESET_AREAS_OCCUPANCY,
  CANCEL_FETCH_AREAS_OCCUPANCY,
  FETCH_AREAS_OCCUPANCY,
  SET_AREAS_OCCUPANCY_LOADING,
  SAVE_AREA_OCCUPANCY_HOURLY_DATA,
  SAVE_AREA_OCCUPANCY_HOURLY_AREA_SELECTED,
  SET_AREA_OCCUPANCY_HOURLY_FILTER,
  RESET_AREA_OCCUPANCY_HOURLY_FILTERS,
  SET_AREA_ORDER,
} from './actionTypes/area';


export function saveAreas(areas) {
  return {
    type: SAVE_AREAS,
    areas
  };
}

export function appendAreas(areas) {
  return {
    type: APPEND_AREAS,
    areas
  };
}

export function setAreasPaginationData(pagination) {
  return {
    type: SET_AREAS_PAGINATION_DATA,
    pagination,
  };
}

export function resetAreasPaginationData() {
  return {
    type: RESET_AREAS_PAGINATION_DATA,
  };
}

export function selectArea(area) {
  return {
    type: SELECT_AREA,
    area,
  };
}

export function resetAreasFilters() {
  return { type: RESET_AREAS_FILTERS };
}

export function setOperationalMode(value) {
  return {
    type: SET_AREA_OPERATIONAL_MODE,
    value,
  };
}

export function setFilter(name, value) {
  return {
    type: SET_AREA_FILTER,
    name,
    value,
  };
}

export function updateAreaOnState(area) {
  return {
    type: UPDATE_AREA,
    area,
  };
}

export function saveLocks(locks) {
  return {
    type: SAVE_LOCKS,
    locks
  };
}

export function cancelFetchLocksByTags() {
  return {
    type: CANCEL_FETCH_AREA_LOCKS_BY_TAGS,
  };
}

export function fetchLocksByTags(tags, lockTagMatchingMode, page = 0, append = false, index = 0) {
  return {
    type: FETCH_AREA_LOCKS_BY_TAGS,
    tags,
    index,
    lockTagMatchingMode,
    page,
    append,
  };
}

export function setLocksFilteredByTags(locks, index = 0) {
  return {
    type: SET_AREA_LOCKS_FILTERED_BY_TAGS,
    index: index,
    locks,
  };
}

export function appendLocksFilteredByTags(locks, index = 0) {
  return {
    type: APPEND_AREA_LOCKS_FILTERED_BY_TAGS,
    index: index,
    locks,
  };
}

export function setLocksFilteredByTagsPaginationData(pagination, index) {
  return {
    type: SET_AREA_LOCKS_FILTERED_BY_TAGS_PAGINATION_DATA,
    index: index || 0,
    pagination,
  };
}

export function cancelFetchAllAreasOccupancy() {
  return {
    type: CANCEL_FETCH_AREAS_OCCUPANCY,
  };
}

export function fetchAllAreasOccupancy(areas) {
  return {
    type: FETCH_AREAS_OCCUPANCY,
    areas,
  };
}

export function resetAreasOccupancy() {
  return {
    type: RESET_AREAS_OCCUPANCY,
  };
}

export function saveAreaOccupancy(area) {
  return {
    type: SAVE_AREA_OCCUPANCY,
    area
  };
}

export function setAreasOccupancyLoading(loading) {
  return {
    type: SET_AREAS_OCCUPANCY_LOADING,
    loading,
  };
}

export function saveAreaOccupancyHourlyAreaSelected(area) {
  return {
    type: SAVE_AREA_OCCUPANCY_HOURLY_AREA_SELECTED,
    area
  };
}

export function saveAreaOccupancyHourlyData(hours) {
  return {
    type: SAVE_AREA_OCCUPANCY_HOURLY_DATA,
    hours
  };
}

export function setAreaOccupancyHourlyFilter(name, value) {
  return {
    type: SET_AREA_OCCUPANCY_HOURLY_FILTER,
    name,
    value,
  };
}

export function resetAreaOccupancyHourlyFilters() {
  return { type: RESET_AREA_OCCUPANCY_HOURLY_FILTERS };
}

export function setOrder(orderBy, orderDir) {
  return {
    type: SET_AREA_ORDER,
    orderBy,
    orderDir,
  };
}










export function fetchAreas(page = 0, append = false, pageSize = 1000) {
  return async (dispatch, getState) => {
    try {
      const filters = structuredClone(getState().areas.data.filters);
      const orderBy = getState().areas.data.sorting.orderBy;
      const orderDir = orderBy?getState().areas.data.sorting.orderDir:undefined;
      const params = {
        page,
        pageSize,
        ...filters,
        orderBy,
        orderDir,
      };
      const response = await RestService.fetchAreas(params);
      if (response.data && response.data.content) {
        const formattedAreas = _.map(response.data.content, area => formatter.formatInputData(formatter.AREA, { ...area }));
        if (append) {
          dispatch(appendAreas(formattedAreas));
        } else {
          dispatch(saveAreas(formattedAreas));
        }
        dispatch(setAreasPaginationData(_.omit(response.data, 'content')));
        return formattedAreas;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function editArea(areaId,data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.AREA, data);
      const response = await RestService.editArea(areaId,formattedData);
      if (response && response.data) {
        const formattedArea = formatter.formatInputData(formatter.AREA, { ...response.data });
        dispatch(updateAreaOnState({
          ...formattedArea
        }));
        return formattedArea;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createArea(data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.AREA, data);
      const response = await RestService.createArea(formattedData);
      if (response && response.data) {
        const formattedArea = formatter.formatInputData(formatter.AREA, { ...response.data });
        return formattedArea;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function deleteArea(areaId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.deleteArea(areaId);
      if (response && response.data) {
        const formattedArea = formatter.formatInputData(formatter.AREA, { ...response.data });
        dispatch(updateAreaOnState({
          ...formattedArea
        }));
        return formattedArea;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function setAreasViewMode(viewMode) {
  saveDataToLocalStorage('areasViewMode', viewMode);
}

export function fetchAllLocks() {
  return async (dispatch, getState) => {
    try {
      let pageCounter = 0;
      const pageSize = 100;
      let formattedLocks = []
      while(pageCounter<10) {
        const params = {
          page: pageCounter,
          pageSize
        };
        const response = await RestService.fetchLocks(params);
        if (response.data && response.data.content) {
          const formattedLocksTemp = _.map(response.data.content, lock => formatter.formatInputData(formatter.LOCK, { ...lock }));
          formattedLocks = [...formattedLocks,...formattedLocksTemp]
          if (response.data.last)
            break;
          pageCounter++;
        }
        else 
          break;
      }
      dispatch(saveLocks(formattedLocks));
      return formattedLocks;
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function setLocksFilteredByTagsEpic(locks, index, pagination, append = false) {
  return (dispatch, getState) => {
    if (append) {
      dispatch(appendLocksFilteredByTags(locks, index));
    } else {
      dispatch(setLocksFilteredByTags(locks, index));
    }
    dispatch(setLocksFilteredByTagsPaginationData(pagination, index));
  };
}

export function setAreaOccupancyEpic(occupancy,lastAreaID) {
  return (dispatch, getState) => {
    dispatch(saveAreaOccupancy(occupancy));
    if (lastAreaID===occupancy.id)
      dispatch(setAreasOccupancyLoading(false));
  };
}

export function fetchAreaOccupancyHourly(area) {
  return async (dispatch, getState) => {
    try {
      //const filters = structuredClone(getState().areas.occupancyHourly.filters);
      //const startTimeLocalDate = filters.startTime?new Date(filters.startTime):undefined
      //const endTimeLocalDate = filters.endTime?new Date(filters.endTime):undefined
      const filters = structuredClone(getState().metrics.accessChartData.filters);
      const startTimeLocalDate = filters.startDate?new Date(filters.startDate):undefined
      const endTimeLocalDate = filters.endDate?new Date(filters.endDate):undefined
      const startTime = startTimeLocalDate?moment.utc({
        year: startTimeLocalDate.getFullYear(),
        month: startTimeLocalDate.getMonth(),
        day: startTimeLocalDate.getDate(),
        hour: startTimeLocalDate.getHours(),
        minute: startTimeLocalDate.getMinutes(),
        second: startTimeLocalDate.getSeconds(),
        millisecond: startTimeLocalDate.getMilliseconds()
      }).valueOf():undefined;
      const endTime = endTimeLocalDate?moment.utc({
        year: endTimeLocalDate.getFullYear(),
        month: endTimeLocalDate.getMonth(),
        day: endTimeLocalDate.getDate(),
        hour: endTimeLocalDate.getHours(),
        minute: endTimeLocalDate.getMinutes(),
        second: endTimeLocalDate.getSeconds(),
        millisecond: endTimeLocalDate.getMilliseconds()
      }).valueOf():undefined;
      const params = {
        startTime,
        endTime,
      };
      const response = await RestService.fetchAreaOccupancyHourly(area.id,params);
      if (response.data) {
        const labels = _.times(24, n => moment().hours(n).minutes(0).format('LT'));

        const dataGreen = new Array(24).fill(0);
        const dataOrange = new Array(24).fill(0);
        const dataRed = new Array(24).fill(0);

        const maxOccupancyPerHour = {};

        response.data.forEach(entry => {
          const date = new Date(entry.hour);
          const hour = date.getHours();
          
          if (!maxOccupancyPerHour[hour] || entry.occupancy > maxOccupancyPerHour[hour].occupancy) {
            maxOccupancyPerHour[hour] = entry;
          }
        });

        Object.values(maxOccupancyPerHour).forEach(entry => {
          const date = new Date(entry.hour);
          const hour = date.getHours();
          const occupancyPercentage = (entry.occupancy / area.capacity) * 100;

          if (occupancyPercentage < 75) {
            dataGreen[hour] = entry.occupancy;
          } else if (occupancyPercentage >= 75 && occupancyPercentage < 100) {
            dataOrange[hour] = entry.occupancy;
          } else if (occupancyPercentage >= 100) {
            dataRed[hour] = entry.occupancy;
          }
        });

        const formattedAreaOccupancyHourly = {
          labels: labels,
          datasets: [
            {
              label: L20NContext.getSync('areaHourlyOccupancyUsers'),
              backgroundColor: 'rgba(77,182,172,0.2)',
              borderColor: 'rgba(77,182,172,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(77,182,172,0.4)',
              hoverBorderColor: 'rgba(77,182,172,1)',
              data: dataGreen
            },
            {
              label: L20NContext.getSync('areaHourlyOccupancyUsers'),
              backgroundColor: 'rgba(255,167,38,0.2)',
              borderColor: 'rgba(255,167,38,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(255,167,38,0.4)',
              hoverBorderColor: 'rgba(255,167,38,1)',
              data: dataOrange
            },
            {
              label: L20NContext.getSync('areaHourlyOccupancyUsers'),
              backgroundColor: 'rgba(211,47,47,0.2)',
              borderColor: 'rgba(211,47,47,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(211,47,47,0.4)',
              hoverBorderColor: 'rgba(211,47,47,1)',
              data: dataRed
            }
          ]
        };

        dispatch(saveAreaOccupancyHourlyData(formattedAreaOccupancyHourly));
        return formattedAreaOccupancyHourly;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}