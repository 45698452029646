import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';

const validate = values => {
  const errors = {};

  if (!values.orgSlug) {
    errors.orgSlug = 'required';
  }
  if (!values.clientId) {
    errors.clientId = 'required';
  }
  if (!values.clientSecret) {
    errors.clientSecret = 'required';
  }
  return errors;
};

@reduxForm({ form: 'OfficeRndActivationForm', validate })
export default class OfficeRndActivationForm extends React.Component {
  render() {
    return (
      <div>
        <Entity
          name="orgSlug"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertOfficeRNDAdminSite"
          title="OfficeRnD Admin Portal"
          component={CustomField}
          helpText={<Entity entity="insertOfficeRNDAdminSiteInfo" />}
        />
        <Entity
          name="clientId"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertCobotToken"
          title="OfficeRnD Client Id"
          component={CustomField}
        />
        <Entity
          name="clientSecret"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertCobotToken"
          title="OfficeRnD Client Secret"
          component={CustomField}
        />
      </div>
    );
  }
}
