// @ts-nocheck
import LocationIcon from '@material-ui/icons/Business';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts.js';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import MDButton from '../../MDButton/MDButton.jsx';
import Permission from '../../Permission/Permission.jsx';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';


const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  } else if (_.trim(values.name).length > 255) {
    errors.name = 'maximumLenghtTwelve';
  }
  return errors;
};

@reduxForm({ form: 'LockSharingForm', validate })
class LockSharingForm extends React.Component {
  render() {
    const { onValidateForm, subcompanies } = this.props;
    return (
      <Form>
        <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10, marginTop: 10 }}>
          <Field
            name="subcompaniesIds"
            component={SelectableRowField}
            options={{
              content: _.map(subcompanies, (company) => (
                {
                  id: company.id,
                  type: <Entity entity="subcompany" />,
                  customOptionElement: (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <LocationIcon style={{ color: '#3f3f3f', marginRight: 10, fontSize: 22 }} />
                      <h4 style={{ color: '#3f3f3f', fontWeight: 'bold' }}>{company.plantName}</h4>
                    </div>
                  ),
                }
              )),
              pagination: { last: true },
            }}
            listContainerStyle={{ marginTop: 0 }}
            listStyle={{ marginTop: 0 }}
            emptyOptionsText={<Entity entity="noCompaniesToSelect" />}
          />
        </div>
        <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.SMART_LOCK} abilityHelper={AbilityProvider.getAbilityHelper()}>
          <MDButton
            title={<Entity entity="save" />}
            onClick={() => onValidateForm()}
          />
        </Permission>
      </Form>
    );
  }
} 

export default LockSharingForm;
