// @ts-nocheck
import { Card, CircularProgress, createTheme, IconButton, LinearProgress, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import BarChartIcon from '@material-ui/icons/InsertChart';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import {
  FormControl
} from '@sketchpixy/rubix';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { BLUE, GREEN } from '../../_config/consts';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';
import DateRangeView from '../forms/Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    margin: 0,
    marginTop: 15,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginTop: 0,
    marginLeft: 10,
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  progressAreaOccupancy: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '30px !important',
    width: '30px !important',
  },
  headerText: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
  },
  chartContainer: {
    width: '100%',
    maxHeight: 335,
    minHeight: 335,
    overflowY: 'scroll',
    marginTop: 20,
  },
  refreshHeader: {
    position: 'absolute',
    right: 10,
    top: 5,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '30%',
    marginTop: 10,
    borderRadius: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  tableColumnText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});


class BookeyReservationsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    const { reservations, classes, textColor, isRefreshing, lastUpdate, onRefreshMetric, onExportAnalytics } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1 }} elevation={3}>
          <div className={classes.header}>
            <h4 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="reservationsList" /></h4>
            <SimpleExportMenu
              titleContainerStyle={{ marginLeft: 50, marginTop: 0 }}
              iconStyle={{ color: '#1B97C2' }}
              exportTitleStyle={{ color: '#1B97C2' }}
              onExportLogs={format => onExportAnalytics(format)}
            />
          </div>
          <div className={classes.chartContainer}>
            {reservations && !_.isEmpty(reservations) ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="ID" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationTitle" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationResource" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationUser" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationDate" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationStartTime" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationEndTime" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationState" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="resourceType" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="resourceWorkspace" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservatonCheckInTime" /></h5></TableCell>
                    <TableCell><h5 className={classes.tableColumnText} style={{ color: textColor || 'gray' }}><Entity entity="reservationCreatedAt" /></h5></TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                {_.map(reservations, (reservation) => {
                  return (
                  <TableRow
                    key={reservation.id}
                    className={classes.row}
                    hover
                    classes={{
                      selected: classes.rowSelected,
                      hover: classes.rowHovered,
                    }}
                    onClick={() => {}}
                  >
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.id}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.title}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.resource?reservation.resource.name:""}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.userId}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.fromDate?moment(reservation.fromDate).format('YYYY-MM-DD'):"-"}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.fromDate?moment(reservation.fromDate).format('HH:mm'):"-"}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.toDate?moment(reservation.toDate).format('HH:mm'):"-"}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.state}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.resource&&reservation.resource.type?reservation.resource.type.name:""}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.resource?reservation.resource.workspace:""}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.checkInTime?moment(reservation.checkInTime).format('HH:mm'):"-"}
                      </h5>
                    </TableCell>
                    <TableCell>
                      <h5 style={{ margin: 0, color: '#3f3f3f' }}>
                        {reservation.createdAt?moment(reservation.createdAt).format('YYYY-MM-DD HH:mm'):"-"}
                      </h5>
                    </TableCell>
                  </TableRow>
                  )
                })}
              </TableBody>
              </Table>
            ) : null}
          </div>
          <div className={classes.refreshHeader}>
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 2 }}>{<Entity entity="lastUpdate" />}:{moment(lastUpdate).format('LLL')}</h5>
            <IconButton
              disabled={isRefreshing}
              style={{ opacity: isRefreshing ? 0.5 : 1 }}
              onClick={() => onRefreshMetric()}
            >
              <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
            </IconButton>
          </div>
          {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(BookeyReservationsList);
