// @ts-nocheck
import React, { Component } from 'react';
import {
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
import {
  FormControl,
  Icon,
} from '@sketchpixy/rubix';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import CheckIcon from '@material-ui/icons/Done';
import CopyIcon from '@material-ui/icons/FileCopyOutlined.js';
import MDButton from '../MDButton/MDButton.jsx';
import { BLUE, GREEN, ORANGE } from '../../_config/consts';
import CustomField from '../forms/Fields/CustomField.jsx';
import Warning from '@material-ui/icons/Warning.js';
import { IconButton } from '@material-ui/core';

export default class RecoverPasswordLinkShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  onCopy() {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);
  }
 
  render() {
    const { url } = this.props;
    const { copied, shareText } = this.state;
    return (
      <div>
        <h4 style={{ color: '#3f3f3f', fontSize: 17, marginLeft: 15 }}><Entity entity="sharePasswordLinkDescription" /></h4>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, borderRadius: 10, backgroundColor: 'rgb(241, 241, 241)', padding: 5, paddingLeft: 15, marginRight: 25, marginLeft: 15 }}>
          <h4>{url}</h4>
          <CopyToClipboard text={url} onCopy={() => this.onCopy()}>
            <div style={{ marginLeft: 15 }}>
              <IconButton>
                {copied ? <CheckIcon style={{ fontSize: 20 }} /> : <CopyIcon style={{ fontSize: 20 }} />}
              </IconButton>
            </div>
          </CopyToClipboard>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginLeft: 15 }}>
          <Warning style={{ color: ORANGE, fontSize: 20 }} />
          <h4 style={{ color: '#3f3f3f', margin: 0, marginLeft: 5, marginTop: 0 }}><Entity entity="sharePasswordLinkWarning" /></h4>
        </div>
        <div style={{ paddingTop: 20, display: 'flex', alignItems: 'center', marginLeft: 15 }}>
          <WhatsappShareButton url={url} style={{ marginLeft: 0, marginRight: 10 }}>
            <WhatsappIcon round size={36} />
          </WhatsappShareButton>
          <EmailShareButton url={url} style={{ marginRight: 10 }}>
            <EmailIcon round size={36} />
          </EmailShareButton>
          <TelegramShareButton url={url} style={{ marginRight: 10 }}>
            <TelegramIcon round size={36} />
          </TelegramShareButton>
        </div>
      </div>
    );
  }
}
