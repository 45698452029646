import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import {
  APPEND_PLATFORM_USERS,
  REMOVE_PLATFORM_USER,
  RESET_PLATFORM_USERS_DATA,
  RESET_PLATFORM_USERS_FILTERS,
  RESET_PLATFORM_USERS_PAGINATION_DATA,
  SAVE_PLATFORM_USER,
  SAVE_PLATFORM_USERS,
  SAVE_PLATFORM_USERS_PAGINATION_DATA,
  SET_PLATFORM_USERS_FILTER,
  SET_PLATFORM_USERS_OPERATIONAL_MODE,
  SET_SELECTED_PLATFORM_USER,
  UPDATE_PLATFORM_USER,
  SET_PLATFORM_USERS_ORDER,
} from './actionTypes/platformUsers';
import * as ModalsActions from './modal.actions';

export function savePlatformUsers(users) {
  return {
    type: SAVE_PLATFORM_USERS,
    users,
  };
}

export function savePlatformUsersPagination(pagination) {
  return {
    type: SAVE_PLATFORM_USERS_PAGINATION_DATA,
    pagination,
  };
}

export function appendPlatformUsers(users) {
  return {
    type: APPEND_PLATFORM_USERS,
    users,
  };
}

export function setSelectedPlatformUser(user) {
  return {
    type: SET_SELECTED_PLATFORM_USER,
    user,
  };
}

export function setOperationalMode(value) {
  return {
    type: SET_PLATFORM_USERS_OPERATIONAL_MODE,
    value,
  };
}

export function removePlatformUser(userId) {
  return {
    type: REMOVE_PLATFORM_USER,
    userId,
  };
}

export function updatePlatformUser(user) {
  return {
    type: UPDATE_PLATFORM_USER,
    user,
  };
}

export function savePlatformUser(user) {
  return {
    type: SAVE_PLATFORM_USER,
    user,
  };
}

export function setPlatformUsersFilter(field, value) {
  return {
    type: SET_PLATFORM_USERS_FILTER,
    field,
    value,
  };
}

export function setOrder(orderBy, orderDir) {
  return {
    type: SET_PLATFORM_USERS_ORDER,
    orderBy,
    orderDir,
  };
}

export function resetPlatformUsersFilters() {
  return { type: RESET_PLATFORM_USERS_FILTERS };
}

export function resetPlatformUsersData() {
  return { type: RESET_PLATFORM_USERS_DATA };
}

export function resetPlatformUsersPaginationData() {
  return { type: RESET_PLATFORM_USERS_PAGINATION_DATA };
}

export function fetchPlatformUsers(page = 0, pageSize = 60) {
  return async (dispatch, getState) => {
    const filters = getState().platformUsers.data.filters;
    const orderBy = getState().platformUsers.data.sorting.orderBy;
    const orderDir = orderBy?getState().platformUsers.data.sorting.orderDir:undefined;
    const params = {
      page,
      pageSize,
      ...filters,
      orderBy,
      orderDir,
    };
    try {
      const userResponse = await RestService.fetchPlatformUsers(params);
      if (userResponse.data && userResponse.data.content) {
        const usersData = _.filter(userResponse.data.content, user => user.email !== 'support+readonly@sofialocks.com' && user.email !== 'support@sofialocks.com');
        const initialPagination = _.omit(userResponse.data, 'content');
        const finalPagination = {
          ..._.omit(initialPagination, 'totalElements'),
          totalElements: _.size(usersData),
        };
        dispatch(savePlatformUsers(usersData));
        dispatch(savePlatformUsersPagination(finalPagination));
        return userResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAndAppendPlatformUsers(page = 0, pageSize = 60) {
  return async (dispatch, getState) => {
    const filters = getState().platformUsers.data.filters;
    const params = {
      page,
      pageSize,
      ...filters,
    };
    try {
      const userResponse = await RestService.fetchPlatformUsers(params);
      if (userResponse.data && userResponse.data.content) {
        const usersData = _.filter(userResponse.data.content, user => user.email !== 'support+readonly@sofialocks.com' && user.email !== 'support@sofialocks.com');
        const initialPagination = _.omit(userResponse.data, 'content');
        const finalPagination = {
          ..._.omit(initialPagination, 'totalElements'),
          totalElements: _.size(usersData),
        };
        dispatch(appendPlatformUsers(usersData));
        dispatch(savePlatformUsersPagination(finalPagination));
        return userResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function createPlatformUser(values) {
  return async (dispatch, getState) => {
    let userRoleIds = [];
    if (values.isGuestManager) {
      userRoleIds = _.isEmpty(values.roleIds) ? [5] : [...values.roleIds, 5];
    } else {
      userRoleIds = _.isEmpty(values.roleIds) ? [] : _.filter(values.roleIds, roleId => roleId !== 5);
    }
    const formattedUser = {
      ...values,
      username: values.email,
      roleIds: _.uniq(userRoleIds),
    };
    try {
      const userResponse = await RestService.createPlatformUser(formattedUser);
      if (userResponse && userResponse.data) {
        const formattedNewUser = formatter.formatInputData(formatter.GUEST, userResponse.data);
        dispatch(savePlatformUser(formattedNewUser));
        dispatch(ModalsActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'managerCreated' }} /></h6>),
          },
        }));
        return formattedNewUser;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function editPlatformUser(values) {
  return async (dispatch, getState) => {
    try {
      let updatedRoles = [];
      if (values.isGuestManager) {
        updatedRoles = _.isEmpty(values.roleIds) ? [5] : [...values.roleIds, 5];
      } else {
        updatedRoles = _.isEmpty(values.roleIds) ? [] : _.filter(values.roleIds, roleId => roleId !== 5);
      }
      const preformattedValues = {
        ...values,
        roleIds: _.uniq(updatedRoles),
      };
      const formattedUser = formatter.formatOutputData(formatter.PLATFORM_USER, preformattedValues);
      const userResponse = await RestService.editPlatformUser(formattedUser.id, formattedUser);
      if (userResponse && userResponse.data) {
        const formattedEditedUser = formatter.formatInputData(formatter.GUEST, userResponse.data);
        dispatch(updatePlatformUser(formattedEditedUser));
        dispatch(ModalsActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'managerUpdated' }} /></h6>),
          },
        }));
        return formattedEditedUser;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function removeManagerAccessToUser(values) {
  return async (dispatch, getState) => {
    try {
      const preformattedValues = {
        ...values,
        roleIds: [5],
      };
      const formattedUser = formatter.formatOutputData(formatter.PLATFORM_USER, preformattedValues);
      const userResponse = await RestService.editPlatformUser(formattedUser.id, formattedUser);
      if (userResponse && userResponse.data) {
        const formattedEditedUser = formatter.formatInputData(formatter.GUEST, userResponse.data);
        dispatch(ModalsActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'managerUpdated' }} /></h6>),
          },
        }));
        return formattedEditedUser;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}
