import Immutable from 'seamless-immutable';

import {
  SHOW_MODAL,
  HIDE_MODAL,
} from '../actions/actionTypes/modal';

const initialState = Immutable({
  data: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return state.set('data', action.data);
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
}
