import { Card, CircularProgress, createTheme, IconButton, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import BarChartIcon from '@material-ui/icons/InsertChart';
import WarningIcon from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React, { Component } from 'react';
import { BLUE } from '../../../_config/consts';
import PieChart from '../../Charts/PieChart.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  subtitleText: {
    margin: 0,
    fontWeight: '100',
    color: '#3f3f3f',
    marginTop: 5,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    padding: 20,
    paddingBottom: 0,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,

  },
  chartContainer: {
    paddingTop: 10,
  },
  refreshHeader: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
    color: '#3f3f3f',
  },
  warningContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    marginLeft: -10,
  },
  warningIcon: {
    fontSize: 20,
    color: 'red',
  },

});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyAnalyticsView = () => (
  <div style={{ height: 100, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <BarChartIcon style={{ color: '#bdbdbd', fontSize: 50 }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFound" /></h3>
  </div>
);


class SmartLocksBatteryChart extends Component {

  onElementClick(chartElement) {
    const { batteryChartData, onDataClick } = this.props;
    if (onDataClick && chartElement) {
      const elementIndex = _.isArray(chartElement) ? chartElement[0]['_index'] : chartElement['_index'];
      const elementName = batteryChartData.labels[elementIndex];
      if (elementName) {
        onDataClick(elementName);
      }
    }
  }
  render() {
    const { batteryChartData, textColor, isRefreshing, onRefreshMetric, classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshing ? 0.5 : 1 }} elevation={3}>
          <div className={classes.header}>
            <h4 className={classes.titleText} style={{ color: textColor || '#3f3f3f', marginTop: 0 }}><Entity entity="locksBatteryStatusTitle" /></h4>
          </div>
          <div className={classes.chartContainer}>
            {batteryChartData && !_.isEmpty(batteryChartData) ? (
              <PieChart
                chartData={_.cloneDeep(batteryChartData)}
                showLegend
                legendPosition="bottom"
                onElementClick={(chartElement) => this.onElementClick(chartElement)}
              />
            ) : <EmptyAnalyticsView />}
          </div>
          {batteryChartData && (batteryChartData.hasEmptyBatteryLocks || batteryChartData.hasLowBatteryLocks) ? (
            <div className={classes.warningContainer}>
              <div>
                <h5 style={{ color: 'red', textAlign: 'center', fontSize: 10, marginTop: 5, marginBottom: 3, marginLeft: 15, lineHeight: '1.2' }}>
                  {batteryChartData.hasEmptyBatteryLocks ? <Entity entity="smartlocksBatteryEmptyDetected" /> : <Entity entity="smartlocksBatteryLowDetected" />}
                </h5>
                <h5 style={{ color: 'red', textAlign: 'center', fontSize: 10, marginTop: 0, marginLeft: 15, lineHeight: '1.2' }}>
                  <Entity entity="replaceBatteryAsap" />
                </h5>
              </div>
            </div>
          ) : null}
          <div className={classes.refreshHeader}>
            <IconButton
              disabled={isRefreshing}
              style={{ opacity: isRefreshing ? 0.5 : 1 }}
              onClick={() => onRefreshMetric()}
            >
              <RefreshIcon className={classes.refreshIcon} style={{ color: textColor || BLUE }} />
            </IconButton>
          </div>
          {isRefreshing ? <CircularProgress color="primary" className={classes.progress} /> : null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(SmartLocksBatteryChart);
