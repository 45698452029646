import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInputInlineField from '../Fields/TextInputInline/TextInputInlineField.jsx';


const validate = values => {
  const errors = {};
  if (!values.name || values.name.length === 0) {
    errors.name = 'required';
  } else if (_.trim(values.name).length > 255) {
    errors.name = 'maximumLenghtTwelve';
  }
  return errors;
};

@reduxForm({ form: 'GatewayNameInlineForm', validate })
class GatewayNameInlineForm extends React.Component {
  render() {
    const { onValidateForm, disabled, hideEditIcon } = this.props;
    return (
      <Form>
        <Entity
          componentClass={Field}
          name="name"
          componentAttribute="placeholder"
          entity="insertName"
          component={TextInputInlineField}
          type="text"
          onSubmit={() => onValidateForm()}
          showErrorLabel
          disabled={disabled}
          hideEditIcon={hideEditIcon}
        />
      </Form>
    );
  }
} 

export default GatewayNameInlineForm;
