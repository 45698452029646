import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { GREEN } from '../../../_config/consts';
import MDButton from '../../MDButton/MDButton.jsx';

const StoreItemSemiAutoActivationView = ({ onRequestDemo, containerStyle, onRequestSemiAutomaticActivation }) => (
  <div style={{ marginTop: 0, paddingTop: 10, ...containerStyle }}>
    <MDButton
      title={<Entity entity="requestActivation" />}
      containerStyle={{ marginTop: 0 }}
      titleStyle={{ fontSize: 17, fontWeight: 'bold' }}
      backgroundColor={GREEN}
      style={{ marginTop: 0, height: 50 }}
      onClick={() => onRequestSemiAutomaticActivation()}
    />
  </div>
);
export default StoreItemSemiAutoActivationView;
