import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { connect } from 'react-redux';
import * as TagsManagementActions from '../../../redux/actions/tagsManagement.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import * as ModalActions from '../../../redux/actions/modal.actions';
import ListLoadingView from '../../ListLoadingView.jsx';
import TagManagementRow from '../TagManagementRow.jsx';
import TranslatableOption from '../../../components/forms/Fields/TranslatableOption.jsx';
import EmptyView from '../../EmptyView.jsx';
import SearchBarForm from '../../forms/SearchBarForm.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import { initialize } from 'redux-form';
import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/HelpOutline';
import TagsExplanationView from '../TagsExplanationView.jsx';
import Permission from '../../Permission/Permission.jsx';
import { GREEN, PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider';

let filterTimeout;
@connect(state => ({
  tagsManagement: state.tagsManagement,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
  companyConfigurations: state.user.companyConfigurations,
}))
class GuestsTagsManagementList extends React.Component {

  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(TagsManagementActions.fetchGuestTagsCategories());
    dispatch(TagsManagementActions.setOperationalMode(false));
    this.onFetchGuestTags();
    
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(TagsManagementActions.resetGuestsTagsFilter());
  }

  async onFetchGuestTags() {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setViewLoading(true));
      dispatch(TagsManagementActions.setSelectedTag(null));
      dispatch(TagsManagementActions.resetGuestsTagsData());
      await dispatch(TagsManagementActions.fetchGuestSpecialTags());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onAppendGuestsTags(page) {
    const { dispatch } = this.props;
    dispatch(TagsManagementActions.fetchAndAppendGuestSpecialTags(page));
  }

  onCloseOperationalSection() {
    const { dispatch } = this.props;
    dispatch(TagsManagementActions.setOperationalMode(false));
  }

  onFilterGuestTags(text) {
    const { dispatch } = this.props;
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      dispatch(TagsManagementActions.setGuestsTagsFilter('name', _.trim(text)));
      await dispatch(TagsManagementActions.fetchGuestSpecialTags());
    }, 300);
  }

  async onFilterGuestTagsByCategory(value) {
    const { dispatch } = this.props;
    if (value==="__ANY__")
      await dispatch(TagsManagementActions.setGuestsTagsFilter('type', undefined));
    else 
      await dispatch(TagsManagementActions.setGuestsTagsFilter('type', value));
    await dispatch(TagsManagementActions.fetchGuestSpecialTags());
  }

  async onResetGuestTagsFilter() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(TagsManagementActions.resetGuestsTagsFilter());
      dispatch(TagsManagementActions.resetGuestsTagsData());
      await dispatch(TagsManagementActions.fetchGuestSpecialTags());
      dispatch(UtilsActions.setViewLoading(false));
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onNewGuestTag() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    dispatch(TagsManagementActions.setSelectedTag(null));
    dispatch(TagsManagementActions.resetTagGuestsData());
    dispatch(initialize('TagForm', { }));
    await dispatch(TagsManagementActions.fetchGuestTagsCategories());
    dispatch(UtilsActions.setSpinnerVisibile(false));
    dispatch(TagsManagementActions.setOperationalMode(true));
  }

  async onSelectTag(tag) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    await dispatch(TagsManagementActions.fetchGuestTagsCategories());
    const guests = await dispatch(TagsManagementActions.fetchGuestsByTag(tag.id));
    dispatch(TagsManagementActions.setSelectedTag({ ...tag, guests }));
    dispatch(initialize('TagForm', { ...tag, type: { name: tag.type }, color: { hex: tag.color } }));
    dispatch(UtilsActions.setSpinnerVisibile(false));
    dispatch(TagsManagementActions.setOperationalMode(true));
  }

  onShowGuestTagHelpModal() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="guestTagsInfoTitle" />,
        content: <TagsExplanationView tagType="guests" />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  render() {
    const { companyConfigurations, tagsManagement: { selectedTag, userTags: { pagination: guestTagsPagination, content: guestTagsData }, userTagsCategories: { content: guestTagsCategories } }, themeName, viewLoading } = this.props;

    const tagCategories = [
      (<Entity
        key={"anyTagCategoryFilter"}
        componentClass={TranslatableOption}
        value={"__ANY__"}
        componentAttribute="text"
        entity={"anyTagCategoryFilter"}
      />),
      ..._.map(_.sortBy(guestTagsCategories, (tag => tag.name.toLowerCase())), tag =>
        <option value={tag.name} key={tag.name}>
          {tag.name}
        </option>
      )
    ]
    const canCreateTag = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER_TAG);
    return (
      <div>
        <div className="shadowed" style={{ marginRight: 0, padding: 5, paddingLeft: 15, backgroundColor: '#f0f0f0', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SearchBarForm
            onFilterChange={text => this.onFilterGuestTags(text)}
            onSearchReset={() => this.onResetGuestTagsFilter()}
            onSelectGuestTagCategory={(value) => {this.onFilterGuestTagsByCategory(value)}}
            guestTagCategories={tagCategories}
            containerStyle={{ padding: 10 }}
          />
          <Permission do={[PERMISSIONS.CREATE]} on={PERMISSION_ENTITIES.USER_TAG} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MDButton
              backgroundColor={GREEN}
              containerStyle={{ margin: 0, marginLeft: 20, marginTop: 4 }}
              title={<Entity entity="newGuestGroup" />}
              onClick={() => this.onNewGuestTag()}
            />
          </Permission>
          <IconButton style={{ marginLeft: 10, marginTop: 5 }} onClick={() => this.onShowGuestTagHelpModal()}>
            <InfoIcon style={{ fontSize: 25 }} />
          </IconButton>
        </div>
        <div className="list-view details-section" style={{ width: '100%', top: 224, paddingBottom: 380 }}>
          <InfiniteScroll
            initialLoad={false}
            loadMore={() => this.onAppendGuestsTags(guestTagsPagination.number + 1)}
            hasMore={guestTagsPagination.number + 1 < guestTagsPagination.totalPages}
            loader={!viewLoading ? <ListLoadingView /> : null}
            useWindow={false}
          >
            <div style={{ width: '40%' }}>
              {!_.isEmpty(guestTagsData) ?
                _.map(guestTagsData, tag =>
                  <TagManagementRow
                    tagRowType="guests"
                    key={tag.id}
                    isSelected={selectedTag && selectedTag.id === tag.id}
                    tag={tag}
                    themeName={themeName}
                    onClick={() => this.onSelectTag(tag)}
                  />)
                  : (
                    <div>
                      {!viewLoading ? (
                        <EmptyView
                          title="noGroupTags"
                          onNewEntity={canCreateTag ? () => this.onNewGuestTag() : null}
                          newEntityTitle="addGroupTag"
                          subtitle="startAddGroups"
                          iconName="icon-simple-line-icons-user-following"
                        />) : <ListLoadingView />}
                    </div>
                  )
                }
            </div>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
} 

export default GuestsTagsManagementList;
