import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as GuestActions from '../../redux/actions/guest.actions';
import EmptyView from '../EmptyView.jsx';
import GuestDetailsRow from '../Guests/GuestDetailsRow.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';

@connect((state) => ({ viewLoading: state.utils.viewLoading, guests: state.guests, themeName: state.settings.items.theme.data.themeName }))
class GuestsListView extends React.Component {


  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(GuestActions.setOperationalMode(false));
  }

  render() {
    const { containerStyle, guests: { data: { content: guestData, pagination: { number: currentPage, totalPages }, filters } }, onAppendGuests, themeName, onSelectGuest, guests: { selectedGuest }, onNewGuest, viewLoading } = this.props;
    const canCreateGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 40, width: '90%', overflow: 'auto', ...containerStyle }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => setTimeout(() => onAppendGuests(currentPage + 1), 200)}
          hasMore={currentPage + 1 < totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '40%' }}>
            {!_.isEmpty(guestData) ?
            _.map(guestData, guest =>
              <GuestDetailsRow
                key={guest.id}
                guest={guest}
                isSelected={selectedGuest && selectedGuest.id === guest.id}
                themeName={themeName}
                onClick={() => onSelectGuest(guest)}
              />)
              : (
                <div>
                  {!viewLoading ? (
                    <EmptyView
                      title={filters && !filters.search ? 'noGuests' : 'noGuestsFound'}
                      subtitle={filters && !filters.search ? 'noGuestsDescription' : 'noGuestsFoundSearchDescription'}
                      onNewEntity={canCreateGuest ? () => onNewGuest() : null}
                      newEntityTitle="addGuest"
                      iconName="icon-simple-line-icons-user"
                    />) : <ListLoadingView />}
                </div>
              )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
} 

export default GuestsListView;
