module.exports = {
  SAVE_VISITORS: 'SAVE_VISITORS',
  APPEND_VISITORS: 'APPEND_COMMUNICATIONS',
  SAVE_VISITOR: 'SAVE_COMMUNICATION',
  SET_VISITORS_PAGINATION_DATA: 'SET_VISITORS_PAGINATION_DATA',
  SELECT_VISITOR: 'SELECT_VISITOR',
  SET_VISITORS_FILTER: 'SET_VISITORS_FILTER',
  UPDATE_VISITOR: 'UPDATE_VISITOR',
  RESET_VISITORS_FILTERS: 'RESET_VISITORS_FILTERS',
  RESET_VISITORS_DATA: 'RESET_VISITORS_DATA',
  RESET_VISITORS_PAGINATION_DATA: 'RESET_VISITORS_PAGINATION_DATA',
  SET_VISITORS_OPERATIONAL_MODE: 'SET_VISITORS_OPERATIONAL_MODE',
  SAVE_VISITORS_CHART_DATA: 'SAVE_VISITORS_CHART_DATA',
  SET_VISITORS_ORDER: 'SET_VISITORS_ORDER',
};
