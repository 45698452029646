import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { RED, PERMISSIONS, PERMISSION_ENTITIES, SPECIAL_ALL_ENTITIES } from '../../_config/consts';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import CustomField from './Fields/CustomField.jsx';
import MDCheckBoxGroupField from './Fields/MDCheckBox/MDCheckBoxGroupField.js';
import { connect } from 'react-redux';
import MDButton from '../MDButton/MDButton.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import WarningIcon from '@material-ui/icons/Warning';


const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  }
  return errors;
};

@connect(state => ({ selectedRole: state.roles.selectedRole, permissions: state.roles.editablePermissionsParsed, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
@reduxForm({ form: 'RoleForm', validate })
class RoleForm extends React.Component {
  render() {
    const { permissions, isSameRole, onDeleteRole, selectedRole } = this.props;
    const canDeleteRole = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.ROLE);
    const canEditRole = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.ROLE);
    return (
      <Form>
        {isSameRole && (
          <Card style={{ marginBottom: 15, backgroundColor: '#ff8484', display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <WarningIcon style={{ color: 'white' }} />
            <h4 style={{ color: 'white', fontWeight: 'bold', marginLeft: 15 }}><Entity entity="sameRoleEditWarning" /></h4>
          </Card>
        )}
        <FormCardContainer headerStyle={{ marginBottom: 20 }} hideCard title="roleName" subtitle="roleNameDescription">
          <Entity
            componentClass={Field}
            name="name"
            disabled={!canEditRole}
            componentAttribute="placeholder"
            entity="insertName"
            component={CustomField}
            />
        </FormCardContainer>
        <FormCardContainer
          title="rolePermissions"
          subtitle="rolePermissionsDescription"
          containerStyle={{ marginTop: 0 }}
          hideCard
        >
          {_.map(_.keys(permissions), (permissionKey) => {
            let entityPermissions = permissions[permissionKey];
            if (_.includes(SPECIAL_ALL_ENTITIES, permissionKey)) {
              entityPermissions = _.filter(entityPermissions, { action: PERMISSIONS.ALL });
            }

            const permissionPartitions = _.partition(entityPermissions, { action: PERMISSIONS.READ });
            const [readPermissions] = permissionPartitions;

            const options = [];

            _.each(permissionPartitions, (permissionPartition) => {
              if (permissionPartition && permissionPartition.length) {
                const permissionIds = _.map(permissionPartition, p => p.id);
                const joinedPermissions = permissionIds.join(',');
                options.push({
                  label: <Entity entity="permissions" data={{ permission: permissionPartition.length === 1 ? permissionPartition[0].action : 'CONTROL' }} />,
                  subtitle: <Entity entity="permissionDescription" data={{ permission: permissionPartition.length === 1 ? permissionPartition[0].action : 'CONTROL' }} />,
                  value: joinedPermissions,
                });
              }
            });
            
            return (
              <Card style={{ padding: 20, marginBottom: 15 }} elevation={3}>
                <h3 style={{ marginTop: 0, fontWeight: 'bold', color: '#3f3f3f' }}>
                  <Entity entity="permissionEntities" data={{ entity: permissionKey }} />
                </h3>
                <h4 style={{ fontSize: 16, margin: 0 }}><Entity entity="permissionEntitiesDescription" data={{ entity: permissionKey }} /></h4>
                <Divider style={{ marginTop: 10, height: 2 }} />
                <div style={{ padding: 10 }}>
                  <Field
                    name={permissionKey}
                    component={MDCheckBoxGroupField}
                    options={options}
                    disabled={!canEditRole}
                    toggleEnableOptionField={readPermissions[0] ? String(readPermissions[0].id) : null}
                    canSelectAll={false}
                    titleStyle={{ fontWeight: 'bold', fontSize: 18, marginTop: -3 }}
                  />
                </div>
              </Card>
            );
          })}
        </FormCardContainer>
        {selectedRole && selectedRole.id && canDeleteRole &&
          <FormCardContainer
            title="deleteRole"
            subtitle="deleteRoleWarning"
          >
            <MDButton
              title={<Entity entity="deleteRole" />}
              backgroundColor={RED}
              onClick={() => onDeleteRole(selectedRole.id)}
            />
          </FormCardContainer>
        }
      </Form>
    );
  }
} 

export default RoleForm;
