import LicenseIcon from '@material-ui/icons/BookmarkBorder';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';

const validate = values => {
  const errors = {};

  if (!values.selectedTier) {
    errors.selectedTier = 'required';
  }
  return errors;
};

@reduxForm({ form: 'ChangeTierForm', validate })
class ChangeTierForm extends React.Component {
  
  render() {
    const { tiers } = this.props;
    const tiersOptions = _.map(tiers, tier => ({
      name: tier.friendlyName,
      id: tier.id,
      customOptionElement: (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -5 }}>
            <LicenseIcon style={{ color: '#3f3f3f', marginRight: 0, fontSize: 25 }} />
            <div style={{ marginLeft: 10 }}>
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold' }}>{tier.friendlyName}</h4>
              <div style={{ display: 'flex', marginTop: 10, flexDirection: 'row', justifyContent: 'space-around' }}>
                <h4 style={{ color: '#3f3f3f', marginTop: 0 }}>
                  <Entity
                    key={tier.id}
                    entity="maxElements"
                    data={{
                      totalElements: tier.maxElements,
                    }}
                  />
                </h4>
                <h4 style={{ color: '#3f3f3f', marginTop: 0, marginLeft: 20 }}>
                  <Entity entity="monthlyConsumption"></Entity>
                  <span style={{ marginLeft: 0 }}> {tier.burnRate}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      ),
    }));
    return (
      <div style={{ height: '120vh', padding: 10}}>
        <div style={{ padding: 10, paddingTop: 0 }}>
          <h3 style={{ fontWeight: 'bold'}}>{<Entity entity="selectMainTier" />}</h3>
          <h4 style={{ marginBottom: 0 }}>{<Entity entity="selectMainTierDescription" />}</h4>
        </div>
        <Field
          name="selectedTier"
          component={SelectableRowField}
          isSingleChoice
          options={{
            content: tiersOptions,
          }}
          listContainerStyle={{ marginTop: 0 }}
          listStyle={{ marginTop: 0, maxHeight: 'unset', height: '120vh' }}
        />
      </div>
    );
  }
} 

export default ChangeTierForm;
