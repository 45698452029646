import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Row,
  Checkbox,
  HelpBlock,
} from '@sketchpixy/rubix';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import CustomField from '../Fields/CustomField.jsx';


const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'required';
  } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email)) {
    errors.email = 'emailInvalid';
  }

  return errors;
};

@reduxForm({ form: 'PasswordRecoveryForm', validate })
class PasswordRecoveryForm extends React.Component {
  render() {
    return (
      <div style={{ margin: 15 }}>
        <Entity
          name="email"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertAccountEmail"
          titleColor="#3f3f3f"
          component={CustomField}
        />
      </div>
    );
  }
} 

export default PasswordRecoveryForm;
