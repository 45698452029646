import qs from 'qs';
import { axiosInstance } from './rest';

export function fetchWalletAvailableCredits() {
  return axiosInstance.get('/api/v2/cloudCredits/wallet/credits/available');
}

export function fetchWalletInfo() {
  return axiosInstance.get('/api/v2/cloudCredits/wallet/info');
}

export function fetchWalletSubscriptions(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/cloudCredits/wallet/subscriptions', restParams);
}

export function createWalletSubscription(subscriptionDTO) {
  return axiosInstance.post('/api/v2/cloudCredits/wallet/subscriptions', subscriptionDTO);
}

export function deleteWalletSubscription(subscriptionId) {
  return axiosInstance.delete(`/api/v2/cloudCredits/wallet/subscriptions/${subscriptionId}`);
}

export function rechargeWallet(rechargeDTO) {
  return axiosInstance.post('/api/v2/cloudCredits/wallet/activate', rechargeDTO);
}

export function fetchWalletCredits(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/cloudCredits/wallet/credits', restParams);
}

export function fetchWalletCreditsUsage(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/cloudCredits/wallet/credits/usages', restParams);
}


export function fetchMarketPlaceSubscriptions(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/cloudCredits/subscriptions', restParams);
}
