import { Card, Divider, List, ListItem, Popper } from '@material-ui/core';
import CaretIcon from '@material-ui/icons/ExpandMore';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { LANGUAGES, LANGUAGE_DETAILS } from '../_config/consts';
import { setLanguage } from '../redux/actions/setting.actions';
import { saveLanguage } from '../redux/actions/user.actions';


@connect((state) => ({ user: state.user.data }))
class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFlag: 'United-Kingdom',
      isOpend: false,
      anchorEl: null,
      currentlyHovering: false,
    };
  }

  componentWillMount() {
    const { user } = this.props;
    if (user && user.languageType) {
      const language = LANGUAGE_DETAILS[user.languageType] || LANGUAGE_DETAILS[LANGUAGES.ENGLISH];
      this.setState({ selectedFlag: language.flag });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user: oldUser } = this.props;
    const { user: newUser } = nextProps;
    if (oldUser && newUser && oldUser.languageType !== newUser.languageType) {
      const language = LANGUAGE_DETAILS[newUser.languageType] || LANGUAGE_DETAILS[LANGUAGES.ENGLISH];
      this.setState({ selectedFlag: language.flag });
    }
  }

  handleSelect(flag) {
    const { dispatch, avoidSave } = this.props;
    this.setState({ selectedFlag: flag.flag });
    dispatch(setLanguage(flag.backend));
    if (!avoidSave) {
      dispatch(saveLanguage(flag.backend));
    }
    this.setState({ isOpend: false });
  }

  render() {
    const { isOpend, anchorEl} = this.state;
    const flagIcon = (
      <img src={`/imgs/app/flags/flags/flat/32/${this.state.selectedFlag}.png`} width='32' height='32' />
    );

    return (
      <div
        onMouseEnter={(e) => this.setState({ anchorEl: e.currentTarget, isOpend: true }) }
        onMouseLeave={(e) => this.setState({ anchorEl: null, isOpend: false })}
      >
        <div
          aria-controls="language-menu"
          aria-haspopup="true"
          onClick={(e) => this.setState({ anchorEl: e.currentTarget, isOpend: true })}
        >
          <div style={{ marginTop: 10 }}>
            {flagIcon}
            <CaretIcon style={{ color: 'white', marginBottom: -8, fontSize: 20, marginLeft: 10 }} />
          </div>
        </div>
        <Popper
          open={isOpend}
          anchorEl={anchorEl}
          disablePortal={true}
          placement='bottom-start'
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: 'viewport',
            }
          }}
          style={{ zIndex: 9999, minWidth: 350, marginLeft: 150, paddingTop: 10 }}
        >
          <Card style={{ backgroundColor: 'white' }}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem key='flag-header' header style={{ backgroundColor: '#f0f0f0', marginTop: -10, height: 40 }}>
                <h5 style={{ margin: 0 }}><Entity entity="languageMenuHeading" /></h5>
                </ListItem>
              <Divider />
              {_.map(_.keys(LANGUAGE_DETAILS), (key, i) => (
                <ListItem
                  key={i}
                  button
                  eventKey={LANGUAGE_DETAILS[key].flag}
                  onClick={(flag) => this.handleSelect(LANGUAGE_DETAILS[key])}
                  active={this.state.selectedFlag === LANGUAGE_DETAILS[key].flag}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                    <img src={`/imgs/app/flags/flags/flat/32/${LANGUAGE_DETAILS[key].flag}.png`} width='25' />
                    <h4 style={{ fontSize: 14, margin: 0, color: '#3f3f3f' }}><Entity className='lang-menu-text' entity='languageMenu' data={{ lang: LANGUAGE_DETAILS[key].lang }} style={{ marginLeft: 20 }} /></h4>
                  </div>
                </ListItem>
              ))}
            </List>
          </Card>
        </Popper>
      </div>
    );
  }
} 

export default LanguageSelector;
