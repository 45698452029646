/* eslint-disable max-len */
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { change } from 'redux-form';
import * as XLSX from 'xlsx';
import { EXPORT_FORMATS } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import { saveDataToLocalStorage } from '../../_config/utils';
import * as ModalActions from './modal.actions';
import {
  SAVE_TICKETS,
  APPEND_TICKETS,
  SET_TICKETS_PAGINATION_DATA,
  RESET_TICKETS_PAGINATION_DATA,
  SELECT_TICKET,
  RESET_TICKETS_FILTERS,
  SET_TICKET_FILTER,
  SAVE_TICKET_MANAGERS,
  UPDATE_TICKET,
  SET_TICKET_OPERATIONAL_MODE,
  SAVE_TICKET_CATEGORIES,
  UPDATE_TICKET_CATEGORY,
  SET_TICKET_CATEGORIES_FILTERS,
  RESET_TICKET_CATEGORIES_FILTERS,
  SET_TICKET_ORDER,
} from './actionTypes/ticket';


export function saveTickets(tickets) {
  return {
    type: SAVE_TICKETS,
    tickets
  };
}

export function appendTickets(tickets) {
  return {
    type: APPEND_TICKETS,
    tickets
  };
}

export function setTicketsPaginationData(pagination) {
  return {
    type: SET_TICKETS_PAGINATION_DATA,
    pagination,
  };
}

export function resetTicketsPaginationData() {
  return {
    type: RESET_TICKETS_PAGINATION_DATA,
  };
}

export function selectTicket(ticket) {
  return {
    type: SELECT_TICKET,
    ticket,
  };
}

export function resetTicketsFilters() {
  return { type: RESET_TICKETS_FILTERS };
}

export function setOperationalMode(value) {
  return {
    type: SET_TICKET_OPERATIONAL_MODE,
    value,
  };
}

export function setFilter(name, value) {
  return {
    type: SET_TICKET_FILTER,
    name,
    value,
  };
}

export function saveTicketManagers(ticketManagers) {
  return {
    type: SAVE_TICKET_MANAGERS,
    ticketManagers,
  };
}

export function updateTicketOnState(ticket) {
  return {
    type: UPDATE_TICKET,
    ticket,
  };
}

export function saveTicketCategories(categories) {
  return {
    type: SAVE_TICKET_CATEGORIES,
    categories
  };
}

export function updateTicketCategoryOnState(category) {
  return {
    type: UPDATE_TICKET_CATEGORY,
    category,
  };
}

export function setTicketCategoriesFilter(name, value) {
  return {
    type: SET_TICKET_CATEGORIES_FILTERS,
    name,
    value,
  };
}

export function resetTicketCategoriesFilters() {
  return {
    type: RESET_TICKET_CATEGORIES_FILTERS,
  };
}

export function setOrder(orderBy, orderDir) {
  return {
    type: SET_TICKET_ORDER,
    orderBy,
    orderDir,
  };
}













export function fetchAllTickets() {
  return async (dispatch, getState) => {
    try {
      let pageCounter = 0;
      const pageSize = 100;
      const filters = structuredClone(getState().tickets.data.filters);
      let formattedTickets = []
      while(pageCounter<10) {
        const params = {
          page: pageCounter,
          pageSize,
          ...filters,
        };
        const response = await RestService.fetchTickets(params);
        if (response.data && response.data.content) {
          const formattedTicketsTemp = _.map(response.data.content, ticket => formatter.formatInputData(formatter.TICKET, { ...ticket }));
          formattedTickets = [...formattedTickets,...formattedTicketsTemp]
          if (response.data.last)
            break;
          pageCounter++;
        }
        else 
          break;
      }
      dispatch(saveTickets(formattedTickets));
      return formattedTickets;
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchTickets(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = structuredClone(getState().tickets.data.filters);
      const orderBy = getState().tickets.data.sorting.orderBy;
      const orderDir = orderBy?getState().tickets.data.sorting.orderDir:undefined;
      const params = {
        page,
        pageSize,
        ...filters,
        orderBy,
        orderDir,
      };
      const response = await RestService.fetchTickets(params);
      if (response.data && response.data.content) {
        const formattedTickets = _.map(response.data.content, ticket => formatter.formatInputData(formatter.TICKET, { ...ticket }));
        if (append) {
          dispatch(appendTickets(formattedTickets));
        } else {
          dispatch(saveTickets(formattedTickets));
        }
        dispatch(setTicketsPaginationData(_.omit(response.data, 'content')));
        return formattedTickets;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchTicketManagers(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const params = {
      page,
      pageSize,
      roleIds: [1]
    };
    try {
      const userResponse = await RestService.fetchTicketManagers(params);
      if (userResponse.data && userResponse.data.content) {
        const usersData = _.filter(userResponse.data.content, user => user.email !== 'support+readonly@sofialocks.com' && user.email !== 'support@sofialocks.com');
        dispatch(saveTicketManagers(usersData));
        return userResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function editTicketAdmin(ticketId,data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.TICKET, data);
      const response = await RestService.editTicketAdmin(ticketId,formattedData);
      if (response && response.data) {
        const formattedTicket = formatter.formatInputData(formatter.TICKET, { ...response.data });
        dispatch(updateTicketOnState({
          ...formattedTicket
        }));
        return formattedTicket;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createTicket(data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.TICKET, data);
      const response = await RestService.createTicket(formattedData);
      if (response && response.data) {
        const formattedTicket = formatter.formatInputData(formatter.TICKET, { ...response.data });
        return formattedTicket;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

/* ENDPOINT USATO DA GUESTS
export function closeTicket(ticketId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.closeTicket(ticketId);
      if (response && response.data) {
        const formattedTicket = formatter.formatInputData(formatter.TICKET, { ...response.data });
        dispatch(updateTicketOnState({
          ...formattedTicket
        }));
        return formattedTicket;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}
*/

/* ENDPOINT USATO DA GUESTS
export function resolveTicket(ticketId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.resolveTicket(ticketId);
      if (response && response.data) {
        const formattedTicket = formatter.formatInputData(formatter.TICKET, { ...response.data });
        dispatch(updateTicketOnState({
          ...formattedTicket
        }));
        return formattedTicket;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}
*/

export function ticketNewMessage(ticketId,data) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.ticketNewMessage(ticketId,data);
      if (response && response.data) {
        const formattedTicket = formatter.formatInputData(formatter.TICKET, { ...response.data });
        dispatch(updateTicketOnState({
          ...formattedTicket
        }));
        return formattedTicket;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function setTicketsViewMode(viewMode) {
  saveDataToLocalStorage('ticketViewMode', viewMode);
}

export function exportTickets(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const tickets = await dispatch(fetchAllTickets());
      const exportData = [];
      _.each(tickets, (ticket) => {
        exportData.push({
          ID: ticket.id,
          Status: ticket.status,
          Categories: ticket.categories.map(item => item.name).join(';'),
          Title: ticket.title,
          'Creation Date': ticket.createdAt ? `${moment(ticket.createdAt).format('LLL')}` : '---',
          Priority: ticket.priority,
          Assignee: ticket.assignee,
          Requester: ticket.requester,
          'Last Update': ticket.updatedAt ? `${moment(ticket.updatedAt).format('LLL')}` : '---',
        });
      });
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = format.toLowerCase();
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { tickets: ws }, SheetNames: ['tickets'] };
      const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Tickets_Export.${fileExtension}`);
      return tickets;
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function fetchTicketCategories(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const filters = structuredClone(getState().tickets.ticketCategories.filters);
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await RestService.fetchTicketCategories(params);
      if (response.data && response.data.content) {
        const formattedCategories = _.map(response.data.content, category => formatter.formatInputData(formatter.TICKET_CATEGORY, { ...category }));
        dispatch(saveTicketCategories(formattedCategories));
        return formattedCategories;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createTicketCategory(data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.TICKET_CATEGORY, data);
      const response = await RestService.createTicketCategory(formattedData);
      if (response && response.data) {
        const formattedTicketCategory = formatter.formatInputData(formatter.TICKET_CATEGORY, { ...response.data });
        return formattedTicketCategory;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function editTicketCategory(categoryId,data) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.TICKET_CATEGORY, data);
      const response = await RestService.editTicketCategory(categoryId,formattedData);
      if (response && response.data) {
        const formattedTicketCategory = formatter.formatInputData(formatter.TICKET_CATEGORY, { ...response.data });
        dispatch(updateTicketCategoryOnState({
          ...formattedTicketCategory
        }));
        return formattedTicketCategory;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function deleteTicketCategory(categoryId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.deleteTicketCategory(categoryId);
      if (response && response.data) {
        const formattedTicketCategory = formatter.formatInputData(formatter.TICKET_CATEGORY, { ...response.data });
        return formattedTicketCategory;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchTicketDetails(ticketId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.fetchTicketDetails(ticketId);
      if (response && response.data) {
        const formattedTicket = formatter.formatInputData(formatter.TICKET, { ...response.data });
        dispatch(updateTicketOnState({
          ...formattedTicket
        }));
        return formattedTicket;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}