import { AppBar, Card, CircularProgress, createTheme, IconButton, Tab, Tabs, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import LockIcon from '@material-ui/icons/LockOpen';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import BarChartIcon from '@material-ui/icons/InsertChart';
import UsersIcon from '@material-ui/icons/People';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import {
  FormControl
} from '@sketchpixy/rubix';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { BLUE, GREEN } from '../../_config/consts';
import SimpleExportMenu from '../ExportMenus/SimpleExportMenu.jsx';
import DateRangeView from '../forms/Fields/DatePickers/DateRangePicker/DateRangeView.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';
import DailyActiveUsersList from './DailyActiveUsersList.jsx';
import DailyActiveSmartLocksList from './DailyActiveSmartLocksList.jsx';
import DailyActiveVisitorsList from './DailyActiveVisitorsList.jsx';
import VisitorsIconCustom from '../CustomIcons/VisitorsIconCustom.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  progressAreaOccupancy: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    height: '30px !important',
    width: '30px !important',
  },
  headerText: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
  },
  chartContainer: {
    width: '100%',
    maxHeight: 400,
    overflowY: 'scroll',
    marginTop: 10,
  },
  refreshHeader: {
    position: 'absolute',
    right: 10,
    top: 5,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '30%',
    marginTop: 10,
    borderRadius: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  tableColumnText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

class DailyActiveEntityList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

 

  render() {
    const { locks, users, visitors, classes, isVisitorsManagementActive, isRefreshingAccessChartData } = this.props;
    const { selectedTab } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} style={{ opacity: isRefreshingAccessChartData ? 0.5 : 1 }} elevation={3}>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            onChange={(e, index) => this.setState({ selectedTab: index })}
            style={{ alignSelf: 'flex-start' }}
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
          >
            <Tab icon={<UsersIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="dailyActiveUsers" />{` (${_.size(users)})`}</h5>} />
            {isVisitorsManagementActive ? <Tab icon={<VisitorsIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="activeVisitors" />{` (${_.size(visitors)})`}</h5>} /> : null}
            <Tab icon={<LockIcon style={{ fontSize: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="mostUsedLocksToday" />{` (${_.size(locks)})`}</h5>} />
            
          </Tabs>
          {selectedTab === 0 ? (
            <DailyActiveUsersList
              users={users}
              isHeaderHidden
              {...this.props}
            />
          ) : null}
          {selectedTab === 1 && isVisitorsManagementActive ? (
            <DailyActiveVisitorsList
              visitors={visitors}
              {...this.props}
            />
          ) : null}
          {(!isVisitorsManagementActive && selectedTab === 1) || (isVisitorsManagementActive && selectedTab === 2) ? (
            <DailyActiveSmartLocksList
              locks={locks}
              isHeaderHidden
              {...this.props}
            />
          ) : null}
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(DailyActiveEntityList);
