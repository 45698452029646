import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import {
  APPEND_INSTALLERS,
  REMOVE_INSTALLER,
  RESET_INSTALLERS_DATA,
  RESET_INSTALLERS_FILTERS,
  RESET_INSTALLERS_PAGINATION_DATA,
  SAVE_INSTALLER,
  SAVE_INSTALLERS,
  SAVE_INSTALLERS_PAGINATION_DATA,
  SET_INSTALLERS_FILTER,
  SET_INSTALLERS_OPERATIONAL_MODE,
  SET_SELECTED_INSTALLER,
  UPDATE_INSTALLER,
  SET_INSTALLERS_ORDER,
} from './actionTypes/installers';
import * as ModalsActions from './modal.actions';
import * as SettingsActions from './setting.actions';

export function saveInstallers(users) {
  return {
    type: SAVE_INSTALLERS,
    users,
  };
}

export function saveInstallersPagination(pagination) {
  return {
    type: SAVE_INSTALLERS_PAGINATION_DATA,
    pagination,
  };
}

export function appendInstallers(users) {
  return {
    type: APPEND_INSTALLERS,
    users,
  };
}

export function setSelectedInstaller(user) {
  return {
    type: SET_SELECTED_INSTALLER,
    user,
  };
}

export function setOperationalMode(value) {
  return {
    type: SET_INSTALLERS_OPERATIONAL_MODE,
    value,
  };
}

export function removeInstaller(userId) {
  return {
    type: REMOVE_INSTALLER,
    userId,
  };
}

export function updateInstaller(user) {
  return {
    type: UPDATE_INSTALLER,
    user,
  };
}

export function saveInstaller(user) {
  return {
    type: SAVE_INSTALLER,
    user,
  };
}

export function setInstallersFilter(field, value) {
  return {
    type: SET_INSTALLERS_FILTER,
    field,
    value,
  };
}

export function setOrder(orderBy, orderDir) {
  return {
    type: SET_INSTALLERS_ORDER,
    orderBy,
    orderDir,
  };
}

export function resetInstallersFilters() {
  return { type: RESET_INSTALLERS_FILTERS };
}

export function resetInstallersData() {
  return { type: RESET_INSTALLERS_DATA };
}

export function resetInstallersPaginationData() {
  return { type: RESET_INSTALLERS_PAGINATION_DATA };
}

export function fetchInstallers(page = 0, pageSize = 60) {
  return async (dispatch, getState) => {
    const filters = getState().installers.data.filters;
    const orderBy = getState().installers.data.sorting.orderBy;
    const orderDir = orderBy?getState().installers.data.sorting.orderDir:undefined;
    const params = {
      page,
      pageSize,
      ...filters,
      orderBy,
      orderDir,
    };
    try {
      const userResponse = await RestService.fetchInstallers(params);
      if (userResponse.data && userResponse.data.content) {
        dispatch(saveInstallers(userResponse.data.content));
        dispatch(saveInstallersPagination(_.omit(userResponse.data, 'content')));
        return userResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAndAppendInstallers(page = 0, pageSize = 60) {
  return async (dispatch, getState) => {
    const filters = getState().installers.data.filters;
    const params = {
      page,
      pageSize,
      ...filters,
    };
    try {
      const userResponse = await RestService.fetchInstallers(params);
      if (userResponse.data && userResponse.data.content) {
        dispatch(appendInstallers(userResponse.data.content));
        dispatch(saveInstallersPagination(_.omit(userResponse.data, 'content')));
        return userResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function createInstaller(values) {
  return async (dispatch, getState) => {
    const formattedUser = {
      ...values,
      roleIds: [2],
    };
    try {
      const userResponse = await RestService.createPlatformUser(formattedUser);
      if (userResponse && userResponse.data) {
        const formattedNewUser = formatter.formatInputData(formatter.GUEST, userResponse.data);
        dispatch(saveInstaller(formattedNewUser));
        dispatch(ModalsActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'managerCreated' }} /></h6>),
          },
        }));
        return formattedNewUser;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function editInstaller(values) {
  return async (dispatch, getState) => {
    try {
      const preformattedValues = {
        ...values,
        roleIds: [2],
      };
      const formattedUser = formatter.formatOutputData(formatter.PLATFORM_USER, preformattedValues);
      const userResponse = await RestService.editInstaller(formattedUser.id, formattedUser);
      if (userResponse && userResponse.data) {
        const formattedEditedUser = formatter.formatInputData(formatter.GUEST, userResponse.data);
        dispatch(updateInstaller(formattedEditedUser));
        dispatch(ModalsActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'managerUpdated' }} /></h6>),
          },
        }));
        return formattedEditedUser;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function onSetInstallationMode(isActive) {
  return async (dispatch, getState) => {
    try {
      const companyDTO = {
        installationMode: isActive,
      };
      const updateResponse = await RestService.updateSettings(companyDTO);
      if (updateResponse && updateResponse.data) {
        dispatch(SettingsActions.saveSetting('company', updateResponse.data));
        return updateResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}
