import _ from 'lodash';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';

const validate = values => {
  const errors = {};

  if (!values.business) {
    errors.business = 'required';
  }
  return errors;
};

@reduxForm({ form: 'AndcardsSelectOrganizationForm', validate })
class AndcardsSelectOrganizationForm extends React.Component {
  
  render() {
    const { organizations } = this.props;
    const organizationsOptions = _.map(organizations, organization => ({
      name: organization.title,
      id: organization.id,
      type: { name: 'Location' },
    }));
    return (
      <div>
        <Field
          name="organizations"
          component={SelectableRowField}
          isSingleChoice
          options={{
            content: organizationsOptions,
          }}
          listContainerStyle={{ marginTop: 0 }}
          listStyle={{ marginTop: 0 }}
        />
      </div>
    );
  }
} 

export default AndcardsSelectOrganizationForm;
