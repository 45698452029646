import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import CodeInputField from '../Fields/ CodeInputField.jsx';
import MDButton from '../../MDButton/MDButton.jsx';

const validate = values => {
  const errors = {};
  if (!values.pukCode) {
    errors.pukCode = 'required';
  } else if (values.pukCode.length < 6) {
    errors.pukCode = 'required';
  }
  return errors;
};

@reduxForm({ form: 'PukCodeForm', validate })
@connect(state => ({ form: state.form.PukCodeForm }))
class PukCodeForm extends React.Component {
  render() {
    const { dispatch, gateway } = this.props;
    return (
      <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ margin: 'auto' }}>
          <h4 style={{ margin: 0, marginBottom: 10, color: '#3f3f3f' }} >
            <Entity entity="insertGatewayPuk" data={{ gatewayName: gateway.name || '' }} />
          </h4>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Field
              name="pukCode"
              component={CodeInputField}
            />
          </div>
        </div>
        <MDButton
          title={<Entity entity="confirm" />}
          onClick={() => dispatch(submit('PukCodeForm'))}
        />
      </Form>
    );
  }
} 

export default PukCodeForm;
