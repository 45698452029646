import React from 'react';
import LocksTagsExplanationView from './TagsExplanationViews/LocksTagsExplanationView.jsx';
import GuestsTagsExplanationView from './TagsExplanationViews/GuestsTagsExplanationView.jsx';

const viewComponents = {
  guests: GuestsTagsExplanationView,
  locks: LocksTagsExplanationView,
};

export default class TagsExplanationView extends React.Component {
  render() {
    const { tagType } = this.props;
   
    const Component = viewComponents[tagType || 'guest'];

    return (
      Component ? <Component {...this.props } /> : null
    );
  }
}