import WarningIcon from '@material-ui/icons/Warning';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import * as RolesActions from '../../redux/actions/roles.actions';
import { RED, ORANGE } from '../../_config/consts.js';
import MDButton from '../MDButton/MDButton.jsx';
import SelectableField from './Fields/SelectableInput/SelectableField.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { formatDomainHostNameToFriendlyName } from '../../_config/utils';

const validate = values => {
  const errors = {};
  if (!values.masterId) {
    errors.masterId = 'required';
  }
  return errors;
};


@reduxForm({ form: 'MasterElectionForm', validate })
@connect(state => ({ form: state.form.MasterElectionForm, selectedCard: state.cards.selectedCard }))
class MasterElectionForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      plantsOptions: null,
      masterSelected: null,
    };
  }
  
  async componentDidMount() {
    const { selectedCard } = this.props;
    const companyPlants = selectedCard.validation.assigned;
    const companyWhitelistedPlants = _.filter(companyPlants, plant => plant !== selectedCard.validation.createdBy);
    const plantsFormatted = _.map(companyWhitelistedPlants, (plant) => {
      return { value: plant, label: _.startCase(formatDomainHostNameToFriendlyName(plant)) };
    });
    this.setState({ plantsOptions: plantsFormatted });
  }

  onMasterSelected(plant) {
    const { dispatch } = this.props;
    if (plant && plant.value) {
      dispatch(change('MasterElectionForm', 'masterId', [plant.value]));
      this.setState({ masterSelected: plant });
    } else {
      this.setState({ masterSelected: null });
    }
  }

  async fetchPlantsOptions(value) {
    const { selectedCard } = this.props;
    const companyPlants = selectedCard.validation.assigned;
    const companyWhitelistedPlants = _.filter(companyPlants, plant => plant !== selectedCard.validation.createdBy);
    const plantsFormatted = _.map(companyWhitelistedPlants, (plant) => {
      return { value: plant, label: _.startCase(formatDomainHostNameToFriendlyName(plant)) };
    });
    this.setState({ plantsOptions: plantsFormatted });
    return plantsFormatted;
  }

  render() {
    const { onDeleteCardWithMaster, onDeleteCardFromAllPlants } = this.props;
    const { plantsOptions, masterSelected } = this.state;
    return (
      <div style={{ padding: 15, paddingRight: 20 }}>
        <h2 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginTop: 10 }}><Entity entity="warning" /></h2>
        <h3 style={{ color: '#3f3f3f', lineHeight: 1.3, fontSize: 20, marginBottom: 25, marginTop: 5 }}><Entity entity="selectMasterWarning" /></h3>
        <FormCardContainer title="selectMasterPlant" subtitle="selectMasterPlantDescription" headerStyle={{ marginBottom: 10 }}>
          <Field
            name="masterId"
            title={<Entity entity="selectPlant" />}
            component={SelectableField}
            options={plantsOptions}
            mandatory
            placeholder={<Entity entity="searchPlant" />}
            containerstyle={{ marginBottom: 15 }}
            onSelect={role => this.onMasterSelected(role)}
            onInputChange={name => this.fetchPlantsOptions(name)}
          />
          {masterSelected ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InfoIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
              <h5 style={{ color: '#3f3f3f', marginLeft: 10 }}><Entity entity="masterSelectedOwnership" key={masterSelected.label} data={{ masterSelected: masterSelected.label }} /></h5>
            </div>) : null}
          <MDButton
            title={<Entity entity="deleteCardAndSetMaster" />}
            backgroundColor={ORANGE}
            disabled={!masterSelected}
            onClick={() => onDeleteCardWithMaster(masterSelected.value)}
          />
        </FormCardContainer>
        <FormCardContainer
          title="deleteCardFromPlantsTitle"
        >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <WarningIcon style={{ color: RED, fontSize: 40 }} />
            <h4 style={{ textAlign: 'center', color: RED, marginLeft: 10 }}><Entity entity="deleteCardAllPlantsDescription" /></h4>
          </div>
          <MDButton
            title={<Entity entity="deleteCardFromAllPlants" />}
            backgroundColor={RED}
            onClick={() => onDeleteCardFromAllPlants()}
          />
        </FormCardContainer>
      </div>
    );
  }
} 

export default MasterElectionForm;
