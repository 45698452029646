import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { COMMUNICATION_STATUSES_PARAMETERS } from '../../_config/consts';
import CommunicationIconCustom from '../CustomIcons/CommunicationIconCustom.jsx';

export default class CommunicationRow extends React.Component {
  render() {
    const { communication: { name, status, description, fromDate, toDate }, onClick, isSelected } = this.props;
    const cardClassName = isSelected ? 'card-selected' : '';
    const statusVariables = status && COMMUNICATION_STATUSES_PARAMETERS[status] ? COMMUNICATION_STATUSES_PARAMETERS[status] : null;
    return (
      <Card elevation={2} onClick={() => onClick()} style={{ borderRadius: 10, padding: 30, paddingLeft: 20 }} className={`card card-rounded ${cardClassName}`} >
        <div style={{ paddingRight: 10, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
          <CommunicationIconCustom
            style={{ width: 30, marginTop: 6, color: '#3f3f3f', alignSelf: 'flex-start' }}
          />
          <div style={{ flex: 1, marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <h4 className="card-title" style={{ margin: 0, color: '#3f3f3f', whiteSpace: 'pre-wrap' }}>{name}</h4>
              {fromDate && toDate ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {statusVariables && statusVariables.color && statusVariables.string ? (
                    <h5 style={{ color: statusVariables.color, textAlign: 'right', fontWeight: 'bold', margin: 0, marginBottom: 5, marginLeft: 5 }}>
                      <Entity key={statusVariables.string} entity={statusVariables.string} />
                    </h5>
                  ) : null}
                  <h5 style={{ color: '#3f3f3f', fontWeight: 'normal', margin: 0, marginLeft: 5 }}>
                    {`${moment(fromDate).format('DD MMMM YYYY')} - ${moment(toDate).format('DD MMMM YYYY')}`}
                  </h5>
                </div>
              ) : null}
            </div>
            <Divider style={{ marginTop: 10 }} />
            {description ? (
              
              <div style={{ marginTop: 15 }}>
                <h5 className="card-description" style={{ margin: 0, color: '#3f3f3f', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                  {_.truncate(description, { length: 300 })}
                </h5>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
    );
  }
}
