import { IconButton, List, ListItem } from '@material-ui/core';
import { AddCircleOutline, DeleteForever, Edit } from '@material-ui/icons';
import { Modal } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { initialize, reset } from 'redux-form';
import { GREEN } from '../../_config/consts.js';
import CameraLiveFeedView from '../../components/Cameras/CameraLiveFeedView.jsx';
import MapIconCustom from '../../components/CustomIcons/MapIconCustom.jsx';
import EmptyView from '../../components/EmptyView.jsx';
import FloorPlanEditor from '../../components/FloorPlans/FloorPlanEditor.jsx';
import FloorPlanViewer from '../../components/FloorPlans/FloorPlanViewer.jsx';
import ListLoadingView from '../../components/ListLoadingView.jsx';
import LockEventsOperationalSection from '../../components/Locks/LockEventsOperationalSection.jsx';
import LockRemoteOpenView from '../../components/Locks/LockRemoteOpenView.jsx';
import FloorPlanOperationalSection from '../../components/Maps/FloorPlanOperationalSection.jsx';
import BasicModal from '../../components/Modals/BasicModal.jsx';
import OperationalView from '../../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../../components/PresentationalView/PresentationalViewHeader.jsx';
import MDSwitchView from '../../components/forms/Fields/MDSwitch/MDSwitchView.jsx';
import * as CamerasActions from '../../redux/actions/cameras.actions.js';
import * as FloorPlansActions from '../../redux/actions/floorplans.actions.js';
import * as GatewayActions from '../../redux/actions/gateways.actions.js';
import * as LockActions from '../../redux/actions/lock.actions.js';
import * as LogEventsActions from '../../redux/actions/logEvents.actions.js';
import * as ModalActions from '../../redux/actions/modal.actions.js';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { localizeHelpCenterLink } from '../../_config/utils.js';

let filterTimeout;

const MenuItem = ({ isSelected, title, onClick, onEditFloorPlan, canEdit, onDeleteFloorPlan }) => (
  <div className={`sidebar-row ${isSelected ? 'sidebar-row-active' : ''}`}>
    <ListItem button selected={isSelected} style={{ borderRadius: 5, height: 50, margin: 0, padding: 0, paddingLeft: 20, justifyContent: 'space-between', display: 'flex', alignItems: 'center' }} onClick={() => onClick()}>
      <h4 className={`sidebar-row-text ${isSelected ? 'sidebar-row-text-active' : ''}`}>{title}</h4>
      {isSelected && canEdit ? (
        <div>
          <IconButton style={{ padding: 5}} onClick={(event) => { event.stopPropagation(); onEditFloorPlan(); }}>
            <Edit />
          </IconButton>
          <IconButton style={{ padding: 5, marginRight: 5, marginLeft: 5}} onClick={(event) => { event.stopPropagation; onDeleteFloorPlan(); }}>
            <DeleteForever />
          </IconButton>
        </div>
      ) : null}
    </ListItem>
  </div>
);

@connect((state) => ({ floorPlans: state.floorPlans, cameras: state.cameras, themeName: state.settings.items.theme.data.themeName, smartLocks: state.locks }))
class FloorPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOperationalMode: false,
      isMapBuilderEnabled: true,
      viewLoading: false,
      activeFloorIndex: 0,
      isEditorViewActive: false,
    };
  }

  async componentWillMount() {
    const { dispatch } = this.props;
    dispatch(FloorPlansActions.setSelectedFloorPlan({}));
    this.setState({ viewLoading: true });
    try {
      dispatch(FloorPlansActions.resetFloorPlanFilters());
      await dispatch(LockActions.fetchLocks(0, false, 100));
      await this.fetchFloorPlans();
      this.onChangeFloor(0)
      this.setState({ viewLoading: false });
    } catch (error) {
      this.setState({ viewLoading: false });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(FloorPlansActions.setFloorPlansFilter({}));
  }

  async fetchFloorPlans(page = 0, append = false) {
    const { dispatch } = this.props;
    try {
      this.setState({ viewLoading: true });
      await dispatch(FloorPlansActions.fetchFloorPlans(page));
      this.setState({ viewLoading: false });
    } catch (error) {
      this.setState({ viewLoading: false });
    }
  }
  
  onOpenOperationalSection() {
    this.setState({ isOperationalMode: true });
  }

  onCloseOperationalSection() {
    this.setState({ isOperationalMode: false });
  }
  
  onNewRource() {
    this.setState({ isOperationalMode: true });
  }

  onNewFloorPlanClicked() {
    const { dispatch } = this.props;
    dispatch(reset('FloorPlanForm'));
    this.onOpenOperationalSection();
  } 

  async setFilter(field, value) {
    const { dispatch } = this.props;
    try {
      dispatch(FloorPlansActions.setFloorPlansFilter(field, value));
      if (!value || (value.length >= 2)) {
        if (filterTimeout) clearTimeout(filterTimeout);
        filterTimeout = setTimeout(async () => {
          this.setState({ viewLoading: true });
          this.fetchFloorPlans();
          this.setState({ viewLoading: false });
        }, 500);
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }


  async resetFilters() {
    const { dispatch } = this.props;
    dispatch(FloorPlansActions.resetFloorPlanFilters());
    dispatch(FloorPlansActions.resetFloorPlanData());
    this.fetchFloorPlans();
  }

  onMapPressed(map) {
    console.log(map)
  }

  onDeleteMapRequest(map) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'warning',
        body: <Entity entity="deleteBookeyMapConfirm" />,
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onDeleteMapConfirm(map),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteMapConfirm(map) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(FloorPlansActions.deleteFloorPlan(map.id))
      await this.fetchFloorPlans();
      const { floorPlans: { data: { content } }} = this.props;
      if (!_.isEmpty(content)) {
        this.onChangeFloor(_.size(content) -1)
      }
      dispatch(LockActions.fetchLocks());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationOpenApplied" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationError" /></h6>),
        },
      }));
    }
  }

  async onAppendSmartLocks(page) {
    const { dispatch } = this.props;
    try {
      await dispatch(LockActions.fetchAndAppendLocksOnFloorPlan(page));
    } catch (error) {
    }
  }

  onEditMap(map) {
    const { dispatch } = this.props;
    dispatch(initialize('FloorPlanForm', {
      ...map,
      name: map.displayName || map.name,
      data: map.link,
    }));
    dispatch(FloorPlansActions.setSelectedFloorPlan(map));
    this.onOpenOperationalSection();
  }

  onSubmitMapForm(values) {
    if (values.id) {
      this.onModifyBookeyMap(values);
    } else {
      this.onCreateNewBookeyMap(values)
    }
  }

  async onCreateNewBookeyMap(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(FloorPlansActions.createFloorPlan({ name: values.name , file: values.data[0] }));
      await this.fetchFloorPlans();
      this.onCloseOperationalSection();
      dispatch(LockActions.fetchLocks());
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationOpenApplied" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      const { floorPlans: { data: { content: floorPlanData }}} = this.props;
      this.onChangeFloor(_.size(floorPlanData) -1);
      this.setState({ isEditorViewActive: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationError" /></h6>),
        },
      }));
    }
  }

  async onModifyBookeyMap(values) {
    const { dispatch, floorPlans: { selectedFloorPlan }, } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const floorPlanUpdated = await dispatch(FloorPlansActions.updateFloorPlan(selectedFloorPlan.id, { displayName: values.name }));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationOpenApplied" /></h6>),
        }}));
      await this.fetchFloorPlans();
      this.onCloseOperationalSection();
      const { floorPlans: { data: { content: floorPlansData } }, } = this.props;
      const floorPlanToSelect = _.find(floorPlansData, floorPlan => floorPlan.id === floorPlanUpdated.id);
      dispatch(FloorPlansActions.setSelectedFloorPlan(floorPlanToSelect));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch(error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationError" /></h6>),
        },
      }));
    }
  }

  async onPositionElementOnMap(smartLock, coordinates) {
    const { dispatch, floorPlans: { selectedFloorPlan }, smartLocks } = this.props;
    let smartLockToUpdate = smartLocks && smartLocks.data && smartLocks.data.content ? _.find(smartLocks.data.content, stateLock => stateLock.id === smartLock.id) : null;
    if (smartLockToUpdate) {
      const updateDTO = {
        locationMediaId: selectedFloorPlan.id,
        locationCoordinates: `${coordinates.x};${coordinates.y}`,
      }
      try {
        await dispatch(LockActions.updateLockLocationMedia(smartLock.id, updateDTO));
      } catch (error) {
        console.log(error)
      }
    }
  }

  async onRemoveElementFromMap(smartLock) {
    const { dispatch, smartLocks } = this.props;
    let smartLockToUpdate = smartLocks && smartLocks.data && smartLocks.data.content ? _.find(smartLocks.data.content, stateLock => stateLock.id === smartLock.id) : null;
    if (smartLockToUpdate) {
      const updateDTO = {
        locationMediaId: 0,
        locationCoordinates: "",
      }
      try {
        await dispatch(LockActions.updateLockLocationMedia(smartLock.id, updateDTO));
      } catch (error) {
        console.log(error)
      }
    }
  }

  async onChangeFloor(index) {
    const { dispatch, floorPlans: { data: { content: floorPlansData } }  } = this.props;
    this.setState({ activeFloorIndex: index});
    if (floorPlansData[index]) {
      dispatch(LockActions.fetchLocksOnFloorPlan(floorPlansData[index].id));
      dispatch(FloorPlansActions.setSelectedFloorPlan(floorPlansData[index]));
    }
  }

  async onOpenLockRequest(lock) {
    if (lock && lock.configuration && lock.configuration.remoteOpenEnabled) {
      this.onOpenLockRemotely(lock);
    } else {
      this.onOpenLockByGateway(lock);
    }
  }

  async onOpenLockRemotely(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LockActions.onOpenFullOnlineLockRemote(lock));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="doorOpenSuccess" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="doorOpenFailed" /></h6>),
        },
      }));
    }
  }

  async onOpenLockByGateway(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const gatewayDetailed = await dispatch(GatewayActions.getGatewayDetails(lock.gatewayId));
      dispatch(GatewayActions.setSelectedGateway(gatewayDetailed));
      dispatch(LockActions.selectLock(lock));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.lockOpenModal.open();
    } catch (error) {
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onOpenCameraFeed(cameraId) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CamerasActions.fetchCameras());
      const updatedCamera = await dispatch(CamerasActions.fetchCameraAdditionalData(cameraId));
      if (updatedCamera.liveFeedURL) {
        dispatch(CamerasActions.setSelectedCamera(updatedCamera));
      } else {
        const liveFeedURL = await dispatch(CamerasActions.fetchCameraLiveFeed(cameraId));
        dispatch(CamerasActions.setSelectedCamera({ id: cameraId, ...updatedCamera, ...liveFeedURL }));
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ showCameraLiveFeed: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorGettingCameraLiveFeed" /></h6>),
        },
      }));
    }
  }

  onToggleEditor(value) {
    this.setState({ isEditorViewActive: value });
  }

  async onOpenSmartLocksEvents(smartLock) {
    const { dispatch } = this.props;
    dispatch(LockActions.selectLock(smartLock));
    dispatch(LogEventsActions.resetSmartLocksEventsData());
    dispatch(LogEventsActions.resetSmartLocksEventsFilters());
    dispatch(LogEventsActions.setSmartLocksEventsFilter('smartLockId', smartLock.id));
    dispatch(LogEventsActions.setSmartLocksEventsFilter('fromDate', moment().subtract(1, 'month').valueOf()));
    await dispatch(LogEventsActions.fetchSmartLocksEvents());
    this.setState({ isLockEventSectionVisible: true });
  }

  onCloseSmartLockEventView() {
    this.setState({ isLockEventSectionVisible: false });
  }

  onLockClick(lockId) {
    const { dispatch } = this.props;
    dispatch(push(`/locks?lockId=${lockId}`));
  }


  onShowInfo() {
    const helpURL = localizeHelpCenterLink('luckey-floor-plans');
    window.open(helpURL);
  }

  render() {
    const { floorPlans: { data: { content: floorPlansData} }, smartLocks: { selectedLock },  cameras: { selectedCamera }, themeName, dispatch } = this.props;
    const { isOperationalMode, showCameraLiveFeed, viewLoading, activeFloorIndex, isEditorViewActive, isLockEventSectionVisible } = this.state; 
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onNewEntity={() => this.onNewFloorPlanClicked()}
          newEntityTitle="newFloorPlan"
          newEntityIconName="icon-simple-line-icons-user-following"
          onSearchReset={() => this.resetFilters()}
          onFilterChange={value => this.setFilter('name', value)}
          onSearchSubmit={() => this.fetchFloorPlans()}
          onInfo={() => this.onShowInfo()}
        />
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', paddingTop: 60, paddingLeft: 10, margin: 20, marginLeft: 10 }}>
          <h3 style={{ fontWeight: 'bold', margin: 0 }}><Entity entity="floorPlans" /></h3>
          <MDSwitchView
            label={<Entity key={isEditorViewActive} entity={!isEditorViewActive ? 'activateEditorMode' : 'editorModeActive'} />}
            checked={isEditorViewActive}
            titleStyle={{ fontWeight: isEditorViewActive ? 'bold' : 'normal', color: isEditorViewActive ? GREEN : '#3f3f3f' }}
            containerStyle={{ marginLeft: 20, marginTop: 5 }}
            onSelect={selected => this.onToggleEditor(selected)}
          />
        </div>
        {floorPlansData && !_.isEmpty(floorPlansData)  ? (
          <div style={{ display: 'flex', flexDirection: 'row', zIndex: 1 }}>
            <List component="nav" style={{ paddingBottom: 70, paddingTop: 0, width: 220 }}>
            {_.map(floorPlansData, (floorPlan, index) => (
              <MenuItem
                title={floorPlan.displayName || floorPlan.name}
                isSelected={activeFloorIndex === index}
                onClick={() => this.onChangeFloor(index)}
                canEdit={isEditorViewActive}
                onEditFloorPlan={() => this.onEditMap(floorPlan)}
                onDeleteFloorPlan={() => this.onDeleteMapRequest(floorPlan)}
                />
            ))}
            <IconButton onClick={() => this.onNewFloorPlanClicked()} style={{ margin: 'auto', display: 'flex', marginTop: 10 }}>
              <AddCircleOutline style={{ fontSize: 20, color: GREEN }} />
            </IconButton>
            </List>
            {isEditorViewActive ? (
              <FloorPlanEditor
                onPositionElementOnMap={(resource, coordinates) => this.onPositionElementOnMap(resource, coordinates)}
                onRemoveElementFromMap={(resource) => this.onRemoveElementFromMap(resource)}
                onAppendSmartLocks={page => this.onAppendSmartLocks(page)}
              />
            ) : (
              <FloorPlanViewer
                showActions
                selectedElements={[]}
                onOpenSmartLocksEvents={(element) => this.onOpenSmartLocksEvents(element)}
                onRemoteOpenRequest={element => this.onOpenLockRequest(element)}
                onOpenCameraFeed={element => this.onOpenCameraFeed(element.cameraId)}
                onOpenLockDetails={lockId => this.onLockClick(lockId)}
              />
            )}
          </div>
        ) : (
          <div>
          {!viewLoading ? (
            <EmptyView
              title="noFloorPlans"
              icon={<MapIconCustom style={{ color: '#a5a3a3', width: 50 }} />}
              newEntityTitle="newFloorPlan"
              subtitle="startCreatingFloorPlans"
              onNewEntity={() => this.onNewFloorPlanClicked()}
            />
          ): <ListLoadingView containerStyle={{ marginTop: 100 }} />}
          </div>
        )}
        <OperationalView
          themeName={themeName}
          isVisible={isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          title={<Entity entity="floorPlans" />}
        >
          <FloorPlanOperationalSection
            onSubmitMapForm={values => this.onSubmitMapForm(values)}
          />
        </OperationalView>
        <OperationalView
          themeName={themeName}
          isVisible={isLockEventSectionVisible}
          style={{ paddingLeft: 0, paddingRight: 0 }}
          onClose={() => this.onCloseSmartLockEventView()}
          title={<Entity entity="smartLockEvents" />}
        >
          <LockEventsOperationalSection
            smartLock={selectedLock}
            onLockClick={lockId => this.onLockClick(lockId)}
            onLoadMoreEvents={page => this.onAppendSmartLockEvents(page)}
            onSubmitMapForm={values => this.onSubmitMapForm(values)}
          />
        </OperationalView>
        <BasicModal
          ref={(m) => { this.lockOpenModal = m; }}
          hideCloseButton
          body={
            <div>
              <LockRemoteOpenView
                onCloseModal={() => {
                  this.lockOpenModal.close();
                  dispatch(LockActions.selectLock({}));
                }}
              />
            </div>
          }
        />
        <Modal
          show={showCameraLiveFeed}
          className="alert-container full-height-modal"
          onHide={() => {
            this.setState({ showCameraLiveFeed: false });
            dispatch(CamerasActions.setSelectedCamera({}));
          }}
        >
          <CameraLiveFeedView
            camera={selectedCamera}
            onCloseModal={() => {
              this.setState({ showCameraLiveFeed: false });
              dispatch(CamerasActions.setSelectedCamera({}));
            }}
          />
        </Modal>
      </div>
    );
  }
} 

export default FloorPlans;
