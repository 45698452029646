import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import errors from '../../_config/errors';
import Alert from './Alert.jsx';

export default class ErrorModal extends React.Component {
  hideModal() {
    const { hideModal } = this.props;
    hideModal();
  }

  render() {
    const { type, defaultMessage } = this.props;
    let { title, message } = errors[type] || {};

    return (
      <Alert
        type="error"
        visible
        onOutsideClick={() => this.hideModal()}
        title={title ? <Entity entity={title} /> : <Entity entity="warning" />}
        message={message ? <Entity entity={message} /> : (defaultMessage || <Entity entity="unexpectedErrorMessage" />)}
        onConfirm={() => this.hideModal()}
      />
    );
  }
}
