import {
  Icon,
  Table
} from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import TablePaginator from './TablePaginator.jsx';

const SortByButton = ({ title, onClick, value, selected }) => (
  <div onClick={() => onClick(value)} style={{ textDecoration: (selected ? 'underline' : 'none') }}>
    <h4 style={{ fontSize: 14, margin: 5, fontWeight: 'bold' }}><Entity key={title} entity={title} /></h4>
  </div>
);

const columns = [
  {
    title: 'name',
  },
  {
    title: 'surname',
  },
  {
    title: 'email',
  },
  {
    title: 'companyName',
  },
  {
    title: 'resource',
  },
  {
    title: 'totalReservations',
  },
  {
    title: 'lostReservations',
  },
  {
    title: 'successfulReservations',
  },
  {
    title: 'penaltyHours',
  },
  {
    title: 'usageHours',
  },
  {
    title: 'totalHours',
  },
];

export default class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsAccordions: [],
      sortBy: null,
    };
  }

  onRowClick(index, value) {
    const { onAccordionOpened } = this.props;
    const { rowsAccordions } = this.state;
    const accordions = _.reject(rowsAccordions, el => el === index);
    if (_.size(rowsAccordions) === _.size(accordions)) {
      accordions.push(index);
      if (onAccordionOpened) onAccordionOpened(index, value);
    }
    this.setState({ rowsAccordions: accordions });
  }

  onSetSortBy(value) {
    const { sortBy } = this.state;
    this.setState({ sortBy: sortBy === value ? null : value });
  }

  resourcePaginationSelected(index) {
    const { onFetchUsers } = this.props;
    if (onFetchUsers) onFetchUsers(index);
  }

  resourceDetailedPaginationSelected(userId, index) {
    const { onFetchDetailedUsers } = this.props;
    if (onFetchDetailedUsers) onFetchDetailedUsers(userId, index);
  }

  render() {
    const { rows, highlightGreaterThan, cachedGuestsMap, userFilter } = this.props;
    const { rowsAccordions, sortBy } = this.state;

    /*const sortedRows = (!_.isNull(sortBy) ? _.sortBy(rows, row => {
      const val = row.values[sortBy];
      return (_.isNumber(val) ? Number(val) : String(val.toLowerCase()));
    }) : rows);*/

    const users = userFilter ? _.filter(rows.data.content, row => cachedGuestsMap[row.userId] && (cachedGuestsMap[row.userId].firstname.toLowerCase().includes(userFilter.toLowerCase()) || cachedGuestsMap[row.userId].lastname.toLowerCase().includes(userFilter.toLowerCase()) || cachedGuestsMap[row.userId].email.toLowerCase().includes(userFilter.toLowerCase()))) : rows.data.content;
    return (
      <div>
        <Table responsive hover bordered>
          <thead>
            <tr>
              <th />
              {_.map(columns, (column, index) => {
                return (<th className="table-align-center" style={{ backgroundColor: '#f0f0f0' }}>
                  <SortByButton
                    title={column.title}
                    value={index}
                    selected={(sortBy === index)}
                    onClick={value => this.onSetSortBy(value)}
                  />
                </th>);
              })
              }
            </tr>
          </thead>
          <tbody>
            {_.map(users, (row, index) =>
              [<tr onClick={() => this.onRowClick(index, row)}>
                <td className="table-align-center">
                  <Icon
                    className="label-responsive"
                    style={{ fontSize: 15 }}
                    glyph={_.includes(rowsAccordions, index) ? 'icon-fontello-up-open' : 'icon-fontello-down-open'}
                  />
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14,
                  }}
                >
                  {(cachedGuestsMap[row.userId] && cachedGuestsMap[row.userId].firstname) || '/'}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {(cachedGuestsMap[row.userId] && cachedGuestsMap[row.userId].lastname) || '/'}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {(cachedGuestsMap[row.userId] && cachedGuestsMap[row.userId].email) || '/'}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {(cachedGuestsMap[row.userId] && cachedGuestsMap[row.userId].companyName) || '/'}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {row.resources}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {row.totalReservations}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {row.lostReservations}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    fontSize: 14
                  }}
                >
                  {row.totalReservations - row.lostReservations}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    backgroundColor: 'white',
                    fontSize: 14
                  }}
                >
                  {row.deletedByUserWithPenaltyHours + row.lostHours}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    backgroundColor: 'white',
                    fontSize: 14
                  }}
                >
                  {row.usageHours - row.lostHours}
                </td>
                <td
                  className="table-align-center label-responsive"
                  style={{
                    marginLeft: 15,
                    backgroundColor: (row.usageHours > highlightGreaterThan ? 'yellow' : 'white'),
                    fontWeight: 'bold',
                    fontSize: 14
                  }}
                >
                  {row.usageHours + row.deletedByUserWithPenaltyHours}
                </td>
              </tr>,
                _.includes(rowsAccordions, index) && rows.detailedDataById[row.userId] && _.map(rows.detailedDataById[row.userId].content, value =>
                  <tr>
                    <td></td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      -
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      -
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      -
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      -
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      {value.resource.name}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      {value.totalReservations}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      {value.lostReservations}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14
                      }}
                    >
                      {value.totalReservations - value.lostReservations}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14,
                        backgroundColor: 'white',
                      }}
                    >
                      {value.deletedByUserWithPenaltyHours + value.lostHours}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        fontSize: 14,
                        backgroundColor: 'white',
                      }}
                    >
                      {value.usageHours - value.lostHours}
                    </td>
                    <td
                      className="table-align-center label-responsive"
                      style={{
                        marginLeft: 15,
                        backgroundColor: (value.usageHours + value.deletedByUserWithPenaltyHours > highlightGreaterThan ? 'yellow' : 'white'),
                        fontWeight: 'bold',
                        fontSize: 14
                      }}
                    >
                      {value.usageHours + value.deletedByUserWithPenaltyHours}
                    </td>
                  </tr>),
                _.includes(rowsAccordions, index) && rows.detailedDataById[row.userId] &&
                  <tr>
                    <td colSpan={columns.length + 1} style={{ backgroundColor: 'white' }}>
                      <TablePaginator
                        style={{ marginBottom: 0, marginLeft: 40 }}
                        pagination={{
                          page: rows.detailedDataById[row.userId].pagination && rows.detailedDataById[row.userId].pagination.number ? rows.detailedDataById[row.userId].pagination.number + 1 : 1,
                          beginPages: 1,
                          endPages: 1,
                          sidePages: 3,
                          pages: rows.detailedDataById[row.userId].pagination && rows.detailedDataById[row.userId].pagination.totalPages ? rows.detailedDataById[row.userId].pagination.totalPages : 1,
                          onSelect: pageIndex => this.resourceDetailedPaginationSelected(row.userId, pageIndex),
                        }}
                      />
                    </td>
                  </tr>,
              ],

            )}
          </tbody>
        </Table>
        <TablePaginator
          style={{ marginBottom: 0 }}
          pagination={{
            page: rows.data.pagination && rows.data.pagination.number ? rows.data.pagination.number : 1,
            beginPages: 1,
            endPages: 1,
            sidePages: 3,
            pages: rows.data.pagination && rows.data.pagination.totalPages ? rows.data.pagination.totalPages : 1,
            onSelect: pageIndex => this.resourcePaginationSelected(pageIndex),
          }}
        />
      </div>
    );
  }
}
