import React from 'react';
import { connect } from 'react-redux';
import * as ModalActions from '../../redux/actions/modal.actions';
import ConfirmToContinueModal from './ConfirmToContinueModal.jsx';
import CredentialUpdateModal from './Credentials/CredentialUpdateModal.jsx';
import ErrorModal from './ErrorModal.jsx';
import GuestCreatedModal from './Guests/GuestCreatedModal.jsx';
import GuestUpdatedModal from './Guests/GuestUpdatedModal.jsx';
import InfoModal from './InfoModal.jsx';
import LockUpdatedModal from './Locks/LockUpdatedModal.jsx';
import MDErrorAlert from './MDAlerts/MDErrorAlert.jsx';
import MDSuccessAlert from './MDAlerts/MDSuccessAlert.jsx';
import PasswordConfirmedModal from './PasswordConfirmedModal.jsx';
import SuccessModal from './SuccessModal.jsx';
import TagCreatedModal from './Tags/TagCreatedModal.jsx';
import ManagerPasswordResetModal from './Users/ManagerPasswordResetModal.jsx';
import UserDisabledModal from './Users/UserDisabledModal.jsx';
import UserPasswordResetModal from './Users/UserPasswordResetModal.jsx';
import UserPasswordRestoreModal from './Users/UserPasswordRestoreModal.jsx';
import ContentModal from './ContentModal.jsx';
import MDWarningAlert from './MDAlerts/MDWarningAlert.jsx';
import WarningModal from './WarningModal.jsx';

const modalComponents = {
  ERROR_MODAL: ErrorModal,
  WARNING_MODAL: WarningModal,
  SUCCESS_MODAL: SuccessModal,
  GUEST_CREATED_MODAL: GuestCreatedModal,
  GUEST_UPDATED_MODAL: GuestUpdatedModal,
  LOCK_UPDATED_MODAL: LockUpdatedModal,
  TAG_CREATED_MODAL: TagCreatedModal,
  CREDENTIAL_UPDATED_MODAL: CredentialUpdateModal,
  PASSWORD_CONFIRMED_MODAL: PasswordConfirmedModal,
  USER_DISABLED_MODAL: UserDisabledModal,
  USER_PASSWORD_RESET_MODAL: UserPasswordResetModal,
  MANAGER_PASSWORD_RESET_MODAL: ManagerPasswordResetModal,
  USER_PASSWORD_RESTORE_MODAL: UserPasswordRestoreModal,
  CONFIRM_TO_CONTINUE_MODAL: ConfirmToContinueModal,
  ERROR_ALERT: MDErrorAlert,
  WARNING_ALERT: MDWarningAlert,
  SUCCESS_ALERT: MDSuccessAlert,
  INFO_MODAL: InfoModal,
  CONTENT_MODAL: ContentModal,
};

@connect((state) => ({ modals: state.modals.data }))
class BaseAlertModal extends React.Component {
  render() {
    const { modals, dispatch } = this.props;

    if (!modals.modalType) {
      return null;
    }

    const Component = modalComponents[modals.modalType];
    const componentProps = modals.modalProps;

    return (
      Component ? <Component {...componentProps} hideModal={() => dispatch(ModalActions.hideModal())} /> : null
    );
  }
} 

export default BaseAlertModal;
