import Card from '@material-ui/core/Card';
import Popover from '@material-ui/core/Popover';
import { LockOpen, Search } from '@material-ui/icons';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React, { useState } from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { ACCESSORIES_TYPES, BATTERY_IMAGES, BLUE, GREEN, PERMISSIONS, PERMISSION_ENTITIES, SMARTLOCK_DOOR_STATUS_MAP } from '../../_config/consts';
import { getLockImage } from '../../_config/utils.js';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import CameraIconCustom from '../CustomIcons/CameraIconCustom.jsx';
import SmartLocksEventsIconCustom from '../CustomIcons/SmartLocksEventsIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


const PowerIcon = ({ isPlugged, batteryLevel, powerImageStyle, batteryImageStyle, batteryContainerStyle }) =>
  (
    <div style={{ flexDirection: 'row', alignSelf: 'flex-end', paddingBottom: 2, ...batteryContainerStyle }}>
      {isPlugged ?
        <img alt="battery" src={'/imgs/common/batteryIcons/lightning.png'} style={{ height: 15, ...powerImageStyle }} />
        :
        <img alt="battery" src={BATTERY_IMAGES[batteryLevel]} style={{ height: 10, ...batteryImageStyle }} />
      }
    </div>
  );

export default function FloorViewerSmartLockPoint({ element, x, y, isSelected, highLightSelectedElements, showActions, onOpenSmartLocksEvents, onOpenCameraFeed, onOpenLockDetails, onRemoteOpenRequest, isSelectedAreaIN, isSelectedAreaOUT, onToggleAreaIN, onToggleAreaOUT }) {
  const [anchorEl, setAnchorEl] = useState(null);
  

  const handleClick = (event) => {
    if (isAreaLocksSelection)
      return;
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const lockColorAreaIN = '#00987C'
  const lockColorAreaOUT = '#98007C'
  
  const isF9000 = element.model === 'F9000';
  const isAreaLocksSelection = onToggleAreaIN && onToggleAreaOUT
  const canSendGatewayNotification = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.GATEWAY_NOTIFICATION);
  const isFullOnlineGate = element && element.configuration && element.configuration.remoteOpenEnabled;
  const canBeOpenRemotely = ((element.gatewayId && canSendGatewayNotification) || isFullOnlineGate);
  const showCameraActions = element.lockHasCamera && element.cameraId;
  const smartLockSensor = element && element.accessories && !_.isEmpty(element.accessories) && _.find(element.accessories, accessory => accessory.type === ACCESSORIES_TYPES.DOOR_SENSOR);
  const isDoorOpen = smartLockSensor && smartLockSensor.value === 'OPENED';
  const doorStatusVariables = smartLockSensor && smartLockSensor.value && SMARTLOCK_DOOR_STATUS_MAP[smartLockSensor.value] ? SMARTLOCK_DOOR_STATUS_MAP[smartLockSensor.value] : null;
  let backgroundColor = isSelected ? GREEN : isSelectedAreaIN ? lockColorAreaIN: isSelectedAreaOUT? lockColorAreaOUT: isAreaLocksSelection? 'grey': BLUE;
  backgroundColor = isDoorOpen && doorStatusVariables ? doorStatusVariables?.color : backgroundColor;
  let opacityLevel = 1;
  opacityLevel = isSelected ? 1 : 0.5;
  opacityLevel = highLightSelectedElements ? opacityLevel : 1;
  return (
    <>
      <Card
        data-tip={element.name}
        className={`card card-rounded`}
        style={{ margin: 0, position: 'absolute', left: x, top: y, opacity: opacityLevel, borderRadius: 40, width: 50, height: 50, backgroundColor: backgroundColor, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: isAreaLocksSelection?'default':'pointer' }}
        elevation={2}
        onClick={handleClick}
      >
        <img className="lock-icon" style={{ width: 40, height: 40 }} src={getLockImage(element.model)} />
      </Card>
      {element.battery !== undefined && element.battery !== -1 && !isF9000 ? (
        <Card
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10,  borderRadius: 40, width: 25, height: 25, position: 'absolute', left: x + 42, top: y - 15, backgroundColor: 'white' }}
          elevation={2}
        >
          <PowerIcon
            isPlugged={element.powerSourceType === 'PLUGGED'}
            batteryLevel={element.battery}
            powerImageStyle={{ width: 'unset', height: 15, marginRight: 0 }}
            batteryImageStyle={{ width: 'unset', height: 7 }}
            batteryContainerStyle={{ alignSelf: 'center' }}
          />
      </Card>
      ): null} 
      {element.cameraId && !isAreaLocksSelection ? (
        <Card
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10,  borderRadius: 40, width: 25, height: 25, position: 'absolute', left: x + 42, top: y + 25, backgroundColor: 'white' }}
          elevation={2}
        >
          <CameraIconCustom style={{ color: GREEN, width: 17 }} />
      </Card>
      ) : null}
      {smartLockSensor && doorStatusVariables && !isAreaLocksSelection ? (
        <Card
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10,  borderRadius: 40, width: 25, height: 25, position: 'absolute',left: x - 12, top: y + 25, backgroundColor: 'white' }}
          elevation={2}
        >
          {doorStatusVariables && doorStatusVariables.icon}
      </Card>
      ) : null}
      {isAreaLocksSelection ? (
        <Card
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10,  borderRadius: 40, width: 30, height: 30, position: 'absolute', left: x - 12, top: y + 25, backgroundColor: isSelectedAreaIN ? lockColorAreaIN : 'white', cursor:'pointer', opacity: isSelectedAreaIN? 1 : 0.6 }}
          elevation={2}
          onClick={()=>{onToggleAreaIN(element)}}
        >
          <MeetingRoomIcon style={{ fontSize: 17, marginRight: 0, color: isSelectedAreaIN ? 'white' : lockColorAreaIN, marginLeft: 0 }} />
        </Card>
      ) : null}
      {isAreaLocksSelection ? (
        <Card
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10,  borderRadius: 40, width: 30, height: 30, position: 'absolute', left: x + 32, top: y + 25, backgroundColor: isSelectedAreaOUT ? lockColorAreaOUT : 'white', cursor:'pointer',opacity: isSelectedAreaOUT? 1: 0.6 }}
          elevation={2}
          onClick={()=>{onToggleAreaOUT(element)}}
        >
          <ExitToAppIcon style={{ fontSize: 17, marginRight: 0, color: isSelectedAreaOUT ? 'white' : lockColorAreaOUT, marginLeft: 0 }}/>
        </Card>
      ) : null}
    <ReactTooltip delayShow={0} effect="float" />
      <Popover
        id={id}
        open={open}
        style={{ zoom: '80%'}}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 15 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <img className="lock-icon" style={{ width: 40, height: 40 }} src={getLockImage(element.model)} />
            <div className="card-inner-body" style={{ overflowWrap: 'break-word', paddingLeft: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
              <div>
                <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', marginBottom: 0, marginTop: 0 }}>{`${element.name}`}</h4>
                {smartLockSensor && doorStatusVariables ? (
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 15, marginTop: 5 }}>
                    {doorStatusVariables && doorStatusVariables.icon}
                    <Entity key={doorStatusVariables.title} style={{ fontSize: 12, marginLeft: 5, fontWeight: 'bold', color: doorStatusVariables.color }} entity={doorStatusVariables.title} />
                  </div>
                ): null}
              </div>
              {element.battery !== undefined && element.battery !== -1 && !isF9000 ? (
                <PowerIcon
                  isPlugged={element.powerSourceType === 'PLUGGED'}
                  batteryLevel={element.battery}
                  batteryContainerStyle={{ alignSelf: 'center', marginLeft: 10}}
                />
              ): null}
              </div>
          </div>
          {showActions ? (
            <div>
              {canBeOpenRemotely ? (
                <MDButton
                  title={<Entity entity="openLock" />}
                  containerStyle={{ width: '100%',  margin: 0, marginTop: 20 }}
                  style={{ height: 35, borderRadius: 0  }}
                  icon={<LockOpen style={{ width: 20, color: 'white', marginLeft: 10 }} />}
                  backgroundColor={GREEN}
                  onClick={() => {
                    handleClick();
                    onRemoteOpenRequest(element);
                  }}
                />
              ) : null}
              {showCameraActions ? (
                <MDButton
                  title="LIVE"
                  containerStyle={{ width: '100%',  margin: 0, marginTop: 20 }}
                  icon={<CameraIconCustom style={{ width: 20, color: 'white', marginLeft: 10 }} />}
                  style={{ height: 35, borderRadius: 0  }}
                  backgroundColor={GREEN}
                  onClick={() => {
                    handleClick();
                    onOpenCameraFeed(element);
                  }}
                />
              ): null}
              <MDButton
                title={<Entity entity="showEvents" />}
                containerStyle={{ width: '100%',  margin: 0, marginTop: 20 }}
                style={{ height: 35, borderRadius: 0  }}
                icon={<SmartLocksEventsIconCustom style={{ width: 20, color: 'white', marginLeft: 10 }} />}
                backgroundColor={BLUE}
                onClick={() => {
                  handleClick();
                  onOpenSmartLocksEvents(element);
                }}
              />
              <MDButton
                containerStyle={{ width: '100%',  margin: 0, marginTop: 20 }}
                backgroundColor={BLUE}
                title={<Entity entity="lockDetails" />}
                style={{ height: 35, borderRadius: 0  }}
                icon={<Search style={{ color: 'white', fontSize: 20, marginLeft: 10 }} />}
                onClick={() => {
                  handleClick();
                  onOpenLockDetails(element)
                }}
              />
              {onToggleAreaIN ? (
                <MDButton
                  containerStyle={{ width: '100%',  margin: 0, marginTop: 20 }}
                  backgroundColor={'#00987C'}
                  title={<Entity entity="lockDetails" />}
                  style={{ height: 35, borderRadius: 0  }}
                  icon={<Search style={{ color: 'white', fontSize: 20, marginLeft: 10 }} />}
                  onClick={() => {
                    handleClick();
                    onToggleAreaIN(element)
                  }}
                />
              ): null}
              {onToggleAreaOUT ? (
                <MDButton
                  containerStyle={{ width: '100%',  margin: 0, marginTop: 20 }}
                  backgroundColor={'#98007C'}
                  title={<Entity entity="lockDetails" />}
                  style={{ height: 35, borderRadius: 0  }}
                  icon={<Search style={{ color: 'white', fontSize: 20, marginLeft: 10 }} />}
                  onClick={() => {
                    handleClick();
                    onToggleAreaOUT(element)
                  }}
                />
              ): null}
            </div>
          ) : null}
        </div>
      </Popover>
    </>
  );
}
