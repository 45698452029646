import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm, reset } from 'redux-form';
import * as GuestActions from '../../../redux/actions/guest.actions';
import * as EventsActions from '../../../redux/actions/logEvents.actions';
import CustomField from '../Fields/CustomField.jsx';
import DatePickerField from '../Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';
import TranslatableOption from '../Fields/TranslatableOption.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import EventsExportMenu from '../../ExportMenus/EventsExportMenu.jsx';
@connect(state => ({}))
@reduxForm({ form: 'AdminEventsSearchBar' })
class AdminEventsSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guestsAndCardsOptions: [],
      cardsOptions: [],
      locksOptions: [],
      guestTagsOptions: [],
      isMenuOpen: false,
    };
  }
  async onFilterGuestsAndCards(value) {
    const { dispatch } = this.props;
    dispatch(GuestActions.setFilter('search', value));
    const guests = await dispatch(GuestActions.fetchGuests());
    const entities = [...guests];
    const options = _.map(entities, actor => ({ value: actor.id, label: `${actor.firstname} ${actor.lastname} ${actor.email}` }));
    this.setState({ guestsAndCardsOptions: options });
    return options;
  }

  onSelectEntity(guest) {
    const { dispatch, onFetchLogs } = this.props;
    dispatch(EventsActions.setAdminEventsFilter('entityId', guest));
    onFetchLogs();
  }

  onSearchReset() {
    const { dispatch, onSearchReset } = this.props;
    dispatch(reset('AdminEventsSearchBar'));
    onSearchReset();
  }

  onHandleFilerChange(value, field) {
    const { onFilterChange, onResetFilterField } = this.props;
    if (value) {
      if (field === 'eventType' && value === 'ALL') return onFilterChange(field, undefined);
      return onFilterChange(field, value);
    }
    return onResetFilterField(field);
  }

  render() {
    const { themeName, dispatch, eventTypesOptions, onResetFilterField, numberOfEvents, exportAdminEvents } = this.props;
    const themeClass = `list-view-header bg-${themeName}-theme`;
    const { guestsAndCardsOptions } = this.state;
    return (
      <div className={themeClass} style={{ width: '100%' }}>
        <div style={{ minHeight: 70, padding: 0, display: 'flex', flexDirection: 'row', marginLeft: 0, alignItems: 'center' }}>
          <div style={{ marginTop: 5, marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Field
              name="guestSelected"
              component={SelectableField}
              title={<Entity entity="selectUser" />}
              placeholder={<Entity entity="selectUserTag" />}
              titleStyle={{ color: 'white', fontSize: 16 }}
              options={guestsAndCardsOptions}
              containerstyle={{ minWidth: 300, marginRight: 10 }}
              onInputChange={value => this.onFilterGuestsAndCards(value)}
              onSelect={guestData => this.onSelectEntity(guestData && guestData.value ? guestData.value : null)}
            />
            <Field
              id="eventType"
              name="eventType"
              title={<h4 style={{ fontWeight: 'bold', fontSize: 16, color: 'white', marginTop: 27 }}><Entity entity="eventType" /></h4>}
              component={CustomField}
              className="form-control-select"
              componentClass="select"
              onHandleChange={value => this.onHandleFilerChange(value, 'eventType')}
            >
              {_.map(eventTypesOptions, (type) =>
                <Entity
                  key={type.value}
                  componentClass={TranslatableOption}
                  value={type.value}
                  componentAttribute="text"
                  entity={type.entity}
                />)}
            </Field>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: -6 }}>
            <div style={{ marginLeft: 15, marginBottom: 5, marginRight: 15 }}>
              <h4 style={{ fontWeight: 'bold', fontSize: 16, color: 'white' }}><Entity entity="start" /></h4>
              <Field
                id="fromDate"
                name="fromDate"
                showClearDate
                placeholder={'--/--/----'}
                dateContainer={{ marginTop: 0 }}
                showDefaultInputIcon
                onHandleChange={(value) => {
                  dispatch(change('AdminEventsSearchBar', 'fromDate', value));
                  if (value) {
                    this.onHandleFilerChange(moment(value).startOf('day').valueOf(), 'fromDate');
                  } else {
                    onResetFilterField('fromDate');
                  }
                }}
                component={DatePickerField}
              />
            </div>
            <div style={{ marginLeft: 5, marginBottom: 5, marginRight: 10 }}>
              <h4 style={{ fontWeight: 'bold', fontSize: 16, color: 'white' }}><Entity entity="end" /></h4>
              <Field
                id="toDate"
                name="toDate"
                showClearDate
                placeholder={'--/--/----'}
                dateContainer={{ marginTop: 0 }}
                showDefaultInputIcon
                onHandleChange={(value) => {
                  dispatch(change('AdminEventsSearchBar', 'toDate', value));
                  if (value) {
                    this.onHandleFilerChange(moment(value).endOf('day').valueOf(), 'toDate');
                  } else {
                    onResetFilterField('toDate');
                  }
                }}
                component={DatePickerField}
              />
            </div>
          </div>
          <MDButton
            style={{ marginTop: 35 }}
            containerStyle={{ margin: 0, marginLeft: 5, marginRight: 10 }}
            backgroundColor="#D32F2F"
            title={<Entity entity="resetFilters" />}
            onClick={() => this.onSearchReset()}
          />
          {exportAdminEvents ? (
            <div style={{ marginLeft: 25, marginTop: 25 }}>
              <EventsExportMenu
                numberOfElements={numberOfEvents}
                onExportLogs={format => exportAdminEvents(format)}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
} 

export default AdminEventsSearchBar;
