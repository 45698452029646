import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_CARDS,
  APPEND_CARDS,
  SAVE_CARD,
  SET_CARD_PAGINATION_DATA,
  SELECT_CARD,
  SET_CARD_OPERATIONAL_MODE,
  APPEND_CARD_CREDENTIALS,
  SAVE_CARD_CREDENTIALS,
  SET_CARD_CREDENTIALS_PAGINATION_DATA,
  UPDATE_CARD_CREDENTIAL_STATUS,
  SAVE_CARD_VALIDATION_STATUS,
  SET_CARD_FILTER,
  RESET_CARD_FILTERS,
  RESET_CARDS_DATA,
  UPDATE_CARD_IN_STATE,
  REMOVE_CARD_FROM_STATE,
  UPDATE_CARD_NOTES_IN_STATE,
  RESET_CARD_PAGINATION_DATA,
  SET_SELECTED_HYPERKEY,
  SET_HYPERKEYS_FILTER,
  RESET_HYPERKEYS_FILTERS,
  SAVE_HYPERKEYS_PAGINATION_DATA,
  SAVE_HYPERKEYS,
  APPEND_HYPERKEYS,
  SAVE_HYPERKEY,
  SAVE_HYPERKEY_VALIDATION_STATUS,
  REMOVE_KEY_FROM_STATE,
  UPDATE_KEY_CREDENTIAL_STATUS,
  APPEND_KEY_CREDENTIALS,
  SAVE_KEY_CREDENTIALS,
  SET_KEY_CREDENTIALS_PAGINATION_DATA,
  UPDATE_KEY_NOTES_IN_STATE,
  UPDATE_KEY_IN_STATE,
  SAVE_PINS,
  APPEND_PINS,
  SAVE_PINS_PAGINATION_DATA,
  SET_SELECTED_PIN,
  SET_PINS_FILTER,
  RESET_PINS_FILTERS,
  APPEND_PIN_CREDENTIALS,
  SAVE_PIN_CREDENTIALS,
  SET_PIN_CREDENTIALS_PAGINATION_DATA,
  UPDATE_PIN_NOTES_IN_STATE,
  UPDATE_PIN_CREDENTIAL_STATUS,
  UPDATE_PIN_IN_STATE,
  RESET_PINS_PAGINATION_DATA,
  SAVE_PIN,
  SAVE_STANDARD_DEVICES_VALIDATION_DEFAULTS,
} from '../actions/actionTypes/card';

const initialState = Immutable({
  cardsData: {
    data: [],
    pagination: {},
    filters: {},
  },
  hyperKeysData: {
    data: [],
    pagination: {},
    filters: {},
  },
  pinsData: {
    data: [],
    pagination: {},
    filters: {},
  },
  selectedCard: {},
  selectedPin: {},
  selectedHyperKey: {},
  isOperationalMode: false,
  validationPeriodDefaults: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_CARDS:
      return state.setIn(['cardsData', 'data'], action.cards);
    case APPEND_CARDS:
      return state.setIn(['cardsData', 'data'], [...state.cardsData.data, ...action.cards]);
    case SAVE_CARD:
      return state.setIn(['cardsData', 'data'], [action.card, ...state.cardsData.data]);
    
    case SET_CARD_PAGINATION_DATA:
      return state.setIn(['cardsData', 'pagination'], action.pagination);
    case RESET_CARD_PAGINATION_DATA:
      return state.setIn(['cardsData', 'pagination'], {});
    case SELECT_CARD:
      return state.set('selectedCard', action.card);
    case SAVE_CARD_VALIDATION_STATUS:
      return state.setIn(['selectedCard', 'validationInfo'], action.data);
    case SET_CARD_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case APPEND_CARD_CREDENTIALS:
      return state.setIn(['selectedCard', 'credentials', 'data'], [...state.selectedCard.credentials.data, ...action.credentials]);
    case SAVE_CARD_CREDENTIALS:
      return state.setIn(['selectedCard', 'credentials', 'data'], action.credentials);
    case SET_CARD_CREDENTIALS_PAGINATION_DATA:
      return state.setIn(['selectedCard', 'credentials', 'currentPage'], action.currentPage)
                  .setIn(['selectedCard', 'credentials', 'totalPages'], action.totalPages);
    case UPDATE_CARD_CREDENTIAL_STATUS: {
      const cardToUpdateIndex = _.findIndex(state.cardsData.data, { id: action.cardId });
      if (cardToUpdateIndex >= 0) {
        return state.setIn(['cardsData', 'data', cardToUpdateIndex, 'credentialRule', 'state'], action.status);
      }
      return state;
    }
    case UPDATE_CARD_IN_STATE: {
      const index = _.findIndex(state.cardsData.data, data => data.id === action.card.id);
      if (index !== -1) {
        return state.setIn(['cardsData', 'data', index], action.card);
      }
      return state;
    }
    case UPDATE_CARD_NOTES_IN_STATE: {
      const cardToUpdateIndex = _.findIndex(state.cardsData.data, { id: action.cardId });
      if (cardToUpdateIndex >= 0) {
        return state.setIn(['cardsData', 'data', cardToUpdateIndex, 'notes'], action.notes);
      }
      return state;
    }
    case REMOVE_CARD_FROM_STATE: {
      const cards = _.filter(state.cardsData.data, card => card.id !== action.cardId);
      return state.setIn(['cardsData', 'data'], cards);
    }
    case REMOVE_KEY_FROM_STATE: {
      const cards = _.filter(state.hyperKeysData.data, card => card.id !== action.keyId);
      return state.setIn(['hyperKeysData', 'data'], cards);
    }
    case SET_CARD_FILTER:
      return state.setIn(['cardsData', 'filters', action.field], action.value);
    case RESET_CARD_FILTERS:
      return state.setIn(['cardsData', 'filters'], {});
    case RESET_CARDS_DATA:
      return state.setIn(['cardsData', 'data'], []).setIn(['cardsData', 'pagination'], {});
    case SAVE_HYPERKEYS:
      return state.setIn(['hyperKeysData', 'data'], action.keys);
    case SAVE_HYPERKEY:
      return state.setIn(['hyperKeysData', 'data'], [action.key, ...state.hyperKeysData.data]);
    case APPEND_HYPERKEYS:
      return state.setIn(['hyperKeysData', 'data'], [...state.hyperKeysData.data, ...action.keys]);
    case SET_SELECTED_HYPERKEY:
      return state.set('selectedHyperKey', action.key);
    case SAVE_HYPERKEY_VALIDATION_STATUS:
        return state.setIn(['selectedHyperKey', 'validationInfo'], action.data);
    case SET_KEY_CREDENTIALS_PAGINATION_DATA:
      return state.setIn(['selectedHyperKey', 'credentials', 'currentPage'], action.currentPage)
                  .setIn(['selectedHyperKey', 'credentials', 'totalPages'], action.totalPages);
    case SET_HYPERKEYS_FILTER:
      return state.setIn(['hyperKeysData', 'filters', action.field], action.value);
    case SAVE_HYPERKEYS_PAGINATION_DATA:
      return state.setIn(['hyperKeysData', 'pagination'], action.pagination);
    case RESET_HYPERKEYS_FILTERS:
      return state.setIn(['hyperKeysData', 'filters'], {});
    case UPDATE_KEY_CREDENTIAL_STATUS: {
      const keyToUpdateIndex = _.findIndex(state.hyperKeysData.data, { id: action.keyId });
      if (keyToUpdateIndex >= 0) {
        return state.setIn(['cardsData', 'data', keyToUpdateIndex, 'credentialRule', 'state'], action.status);
      }
      return state;
    }
    case UPDATE_KEY_IN_STATE: {
      const index = _.findIndex(state.hyperKeysData.data, data => data.id === action.key.id);
      if (index !== -1) {
        return state.setIn(['hyperKeysData', 'data', index], action.key);
      }
      return state;
    }
    case SAVE_KEY_CREDENTIALS:
      return state.setIn(['selectedHyperKey', 'credentials', 'data'], action.credentials);
    case APPEND_KEY_CREDENTIALS:
      return state.setIn(['selectedHyperKey', 'credentials', 'data'], [...state.selectedHyperKey.credentials.data, ...action.credentials]);
    case UPDATE_KEY_NOTES_IN_STATE: {
      const cardToUpdateIndex = _.findIndex(state.hyperKeysData.data, { id: action.keyId });
      if (cardToUpdateIndex >= 0) {
        return state.setIn(['hyperKeysData', 'data', cardToUpdateIndex, 'notes'], action.notes);
      }
      return state;
    }
    case SAVE_PINS:
      return state.setIn(['pinsData', 'data'], action.pins);
    case APPEND_PINS:
      return state.setIn(['pinsData', 'data'], [...state.pinsData.data, ...action.pins]);
    case SAVE_PIN:
      return state.setIn(['pinsData', 'data'], [action.pin, ...state.pinsData.data]);
    case SET_SELECTED_PIN:
      return state.set('selectedPin', action.pin);
    case SET_PIN_CREDENTIALS_PAGINATION_DATA:
      return state.setIn(['selectedPin', 'credentials', 'currentPage'], action.currentPage)
                  .setIn(['selectedPin', 'credentials', 'totalPages'], action.totalPages);
    case SET_PINS_FILTER:
      return state.setIn(['pinsData', 'filters', action.field], action.value);
    case SAVE_PINS_PAGINATION_DATA:
      return state.setIn(['pinsData', 'pagination'], action.pagination);
    case RESET_PINS_PAGINATION_DATA:
      return state.setIn(['pinsData', 'pagination'], {});
    case RESET_PINS_FILTERS:
      return state.setIn(['pinsData', 'filters'], {});
    case UPDATE_PIN_CREDENTIAL_STATUS: {
      const keyToUpdateIndex = _.findIndex(state.pinsData.data, { id: action.pinId });
      if (keyToUpdateIndex >= 0) {
        return state.setIn(['cardsData', 'data', keyToUpdateIndex, 'credentialRule', 'state'], action.status);
      }
      return state;
    }
    case UPDATE_PIN_IN_STATE: {
      const index = _.findIndex(state.pinsData.data, data => data.id === action.pin.id);
      if (index !== -1) {
        return state.setIn(['pinsData', 'data', index], action.pin);
      }
      return state;
    }
    case SAVE_PIN_CREDENTIALS:
      return state.setIn(['selectedPin', 'credentials', 'data'], action.credentials);
    case APPEND_PIN_CREDENTIALS:
      return state.setIn(['selectedPin', 'credentials', 'data'], [...state.selectedPin.credentials.data, ...action.credentials]);
    case UPDATE_PIN_NOTES_IN_STATE: {
      const cardToUpdateIndex = _.findIndex(state.pinsData.data, { id: action.pinId });
      if (cardToUpdateIndex >= 0) {
        return state.setIn(['pinsData', 'data', cardToUpdateIndex, 'notes'], action.notes);
      }
      return state;
    }
    case SAVE_STANDARD_DEVICES_VALIDATION_DEFAULTS:
      return state.set('validationPeriodDefaults', action.defaults);
    default:
      return state;
  }
}
