import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_GUESTS,
  APPEND_GUESTS,
  SAVE_GUEST,
  SELECT_GUEST,
  SET_GUESTS_PAGINATION_DATA,
  SET_GUEST_OPERATIONAL_MODE,
  APPEND_GUEST_CREDENTIALS,
  SAVE_GUEST_CREDENTIALS,
  SET_GUEST_CREDENTIALS_PAGINATION_DATA,
  SET_GUEST_FILTER,
  UPDATE_SELECTED_GUEST_TAG,
  REMOVE_GUEST,
  UPDATE_GUEST,
  RESET_GUESTS_FILTERS,
  CACHE_GUEST_DETAILS,
  RESET_GUESTS_DATA,
  CACHE_REMOVE_GUEST_DETAILS,
  SAVE_GUEST_ACCESS_METHODS,
  RESET_GUESTS_PAGINATION_DATA,
  SET_IS_LOADING_CREDENTIALS,
  RESET_GUEST_CREDENTIALS_DATA,
  SET_GUEST_CREDENTIAL_LIST_FILTER,
  RESET_GUEST_CREDENTIAL_LIST_FILTERS,
  SET_GUEST_ORDER,
} from '../actions/actionTypes/guest';
import { CREDENTIAL_TYPES } from '../../_config/consts';

const initialState = Immutable({
  data: {
    content: [],
    filters: {},
    pagination: {},
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    }
  },
  cachedGuestsMap: {},
  selectedGuest: {
    credentials: {
      filters: {
        type: CREDENTIAL_TYPES.SMART,
      },
    },
  },
  isOperationalMode: false,
  isLoadingCredentials: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_GUESTS:
      return state.setIn(['data', 'content'], action.guests);
    case APPEND_GUESTS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.guests]);
    case SAVE_GUEST: {
      const totalUsers = state.data.pagination && !_.isUndefined(state.data.pagination.totalElements) ? state.data.pagination.totalElements + 1 : 0;
      return state.setIn(['data', 'content'], [action.guest, ...state.data.content]).setIn(['data', 'pagination', 'totalElements'], totalUsers);
    }
    case SET_GUESTS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SELECT_GUEST:
      return state.set('selectedGuest', action.guest);
    case CACHE_GUEST_DETAILS:
      return state.setIn(['cachedGuestsMap', action.guestId], action.guestDetails);
    case CACHE_REMOVE_GUEST_DETAILS: {
      const filteredCache = _.filter(state.datcachedGuestsMap, user => user.id !== action.guestId);
      return state.set('cachedGuestsMap', filteredCache);
    }

    case UPDATE_SELECTED_GUEST_TAG: {
      const index = _.findIndex(state.selectedGuest.tags, { id: action.tag.id });
      return state.setIn(['selectedGuest', 'tags', index], action.tag);
    }
    case REMOVE_GUEST: {
      const users = _.filter(state.data.content, user => user.id !== action.guestId);
      const totalUsers = state.data.pagination && state.data.pagination.totalElements !== 0 ? state.data.pagination.totalElements - 1 : state.data.pagination.totalElements;
      return state.setIn(['data', 'content'], users).setIn(['data', 'pagination', 'totalElements'], totalUsers);
    }
    case UPDATE_GUEST: {
      const index = _.findIndex(state.data.content, data => data.id === action.guest.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.guest);
      }
      return state;
    }
    case SET_IS_LOADING_CREDENTIALS:
      return state.set('isLoadingCredentials', action.isLoading);
    case SET_GUEST_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case APPEND_GUEST_CREDENTIALS:
      return state.setIn(['selectedGuest', 'credentials', action.credentialCategory, 'data'], [...state.selectedGuest.credentials.data, ...action.credentials]);
    case SAVE_GUEST_CREDENTIALS:
      return state.setIn(['selectedGuest', 'credentials', action.credentialCategory, 'data'], action.credentials);
    case SET_GUEST_CREDENTIAL_LIST_FILTER:
      return state.setIn(['selectedGuest', 'credentials', action.credentialCategory, 'filters', action.field], action.value);
    case RESET_GUEST_CREDENTIAL_LIST_FILTERS:
      return state.setIn(['selectedGuest', 'credentials', action.credentialCategory, 'filters'], {});
    case RESET_GUEST_CREDENTIALS_DATA:
      return state.setIn(['selectedGuest', 'credentials'], {});
    case SAVE_GUEST_ACCESS_METHODS:
      return state.setIn(['selectedGuest', 'accessMethods'], action.accessMethods);
    case SET_GUEST_CREDENTIALS_PAGINATION_DATA:
      return state.setIn(['selectedGuest', 'credentials', action.credentialCategory, 'currentPage'], action.currentPage)
                  .setIn(['selectedGuest', 'credentials', action.credentialCategory, 'totalPages'], action.totalPages);
    case SET_GUEST_FILTER:
      return state.setIn(['data', 'filters', action.name], action.value);
    case SET_GUEST_ORDER:
      return state.setIn(['data', 'sorting', 'orderBy'], action.orderBy)
                  .setIn(['data', 'sorting', 'orderDir'], action.orderDir);
    case RESET_GUESTS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case RESET_GUESTS_DATA:
      return state.setIn(['data', 'content'], {}).setIn(['data', 'pagination'], {});
    case RESET_GUESTS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    default:
      return state;
  }
}
