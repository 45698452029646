import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SAVE_ADMIN_EVENTS,
  SAVE_ADMIN_EVENTS_PAGINATION,
  APPEND_ADMIN_EVENTS,
  RESET_ADMIN_EVENTS,
  SET_ADMIN_EVENT_FILTERS,
  RESET_ADMIN_EVENT_FILTERS,
  SAVE_SMART_LOCKS_EVENTS,
  APPEND_SMART_LOCKS_EVENTS,
  SAVE_SMART_LOCKS_EVENTS_PAGINATION,
  RESET_SMART_LOCKS_EVENTS,
  SET_SMART_LOCKS_EVENT_FILTER,
  RESET_SMART_LOCKS_EVENT_FILTERS,
  CLEAR_SMART_LOCKS_EVENT_FILTER,
  CLEAR_ADMIN_EVENT_FILTER,
  SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS,
  APPEND_SUBCOMPANIES_SMART_LOCKS_EVENTS,
  SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS_PAGINATION,
  RESET_SUBCOMPANIES_SMART_LOCKS_EVENTS,
  SET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER,
  CLEAR_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER,
  RESET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTERS,
} from '../actions/actionTypes/logEvents';

const initialState = Immutable({
  adminEvents: {
    content: [],
    pagination: {},
    filters: {},
  },
  smartLocksEvents: {
    content: [],
    pagination: {},
    filters: {},
  },
  subcompaniesSmartLocksEvents: {
    content: [],
    pagination: {},
    filters: {},
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ADMIN_EVENTS:
      return state.setIn(['adminEvents', 'content'], action.events);
    case APPEND_ADMIN_EVENTS:
      return state.setIn(['adminEvents', 'content'], [...state.adminEvents.content, ...action.events]);
    case SAVE_ADMIN_EVENTS_PAGINATION:
      return state.setIn(['adminEvents', 'pagination'], action.pagination);
    case RESET_ADMIN_EVENTS:
      return state.setIn(['adminEvents', 'content'], initialState.adminEvents.content).setIn(['adminEvents', 'pagination'], initialState.adminEvents.pagination);
    case SET_ADMIN_EVENT_FILTERS:
      return state.setIn(['adminEvents', 'filters', action.field], action.value);
    case RESET_ADMIN_EVENT_FILTERS:
      return state.setIn(['adminEvents', 'filters'], initialState.adminEvents.filters);
    case SAVE_SMART_LOCKS_EVENTS:
      return state.setIn(['smartLocksEvents', 'content'], action.events);
    case APPEND_SMART_LOCKS_EVENTS:
      return state.setIn(['smartLocksEvents', 'content'], [...state.smartLocksEvents.content, ...action.events]);
    case SAVE_SMART_LOCKS_EVENTS_PAGINATION:
      return state.setIn(['smartLocksEvents', 'pagination'], action.pagination);
    case RESET_SMART_LOCKS_EVENTS:
      return state.setIn(['smartLocksEvents', 'content'], initialState.smartLocksEvents.content).setIn(['smartLocksEvents', 'pagination'], initialState.smartLocksEvents.pagination);
    case SET_SMART_LOCKS_EVENT_FILTER:
      return state.setIn(['smartLocksEvents', 'filters', action.field], action.value);
    case CLEAR_SMART_LOCKS_EVENT_FILTER: {
      const newFilters = _.omit(state.smartLocksEvents.filters, action.field);
      return state.setIn(['smartLocksEvents', 'filters'], newFilters);
    }
    case CLEAR_ADMIN_EVENT_FILTER: {
      const newFilters = _.omit(state.adminEvents.filters, action.field);
      return state.setIn(['adminEvents', 'filters'], newFilters);
    }
    case RESET_SMART_LOCKS_EVENT_FILTERS:
      return state.setIn(['smartLocksEvents', 'filters'], initialState.smartLocksEvents.filters);
    case SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS:
      return state.setIn(['subcompaniesSmartLocksEvents', 'content'], action.events);
    case APPEND_SUBCOMPANIES_SMART_LOCKS_EVENTS:
      return state.setIn(['subcompaniesSmartLocksEvents', 'content'], [...state.subcompaniesSmartLocksEvents.content, ...action.events]);
    case SAVE_SUBCOMPANIES_SMART_LOCKS_EVENTS_PAGINATION:
      return state.setIn(['subcompaniesSmartLocksEvents', 'pagination'], action.pagination);
    case RESET_SUBCOMPANIES_SMART_LOCKS_EVENTS:
      return state.setIn(['subcompaniesSmartLocksEvents', 'content'], initialState.subcompaniesSmartLocksEvents.content).setIn(['subcompaniesSmartLocksEvents', 'pagination'], initialState.subcompaniesSmartLocksEvents.pagination);
    case SET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER:
      return state.setIn(['subcompaniesSmartLocksEvents', 'filters', action.field], action.value);
    case CLEAR_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTER: {
      const newFilters = _.omit(state.subcompaniesSmartLocksEvents.filters, action.field);
      return state.setIn(['subcompaniesSmartLocksEvents', 'filters'], newFilters);
    }
    case RESET_SUBCOMPANIES_SMART_LOCKS_EVENT_FILTERS:
      return state.setIn(['subcompaniesSmartLocksEvents', 'filters'], initialState.subcompaniesSmartLocksEvents.filters);
    default:
      return state;
  }
}
