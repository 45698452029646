import Card from '@material-ui/core/Card';
import AtlasIcon from '@material-ui/icons/Airplay';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { ATLAS_NETWORK_STATUS_PARAMETERS } from '../../../_config/consts';

export default class AtlasGatewayRow extends React.Component {
  render() {
    const { atlas: { name, description, networkStatus }, onClick, isSelected } = this.props;
    const cardClassName = isSelected ? 'card-selected' : '';
    const statusParameters = ATLAS_NETWORK_STATUS_PARAMETERS[networkStatus];
    const borderLeftStyle = statusParameters && statusParameters.color ? `solid 4px ${statusParameters.color}` : 'unset';
    return (
      <Card elevation={2} onClick={() => onClick()} style={{ borderRadius: 10, padding: 30, paddingLeft: 20, borderLeft: borderLeftStyle, position: 'relative' }} className={`card card-rounded ${cardClassName}`} >
        <div className="card-body">
          <div style={{ padding: 0 }}>
            <AtlasIcon
              style={{ fontSize: 30, color: '#3f3f3f', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            />
          </div>
          {statusParameters && statusParameters.title ? (
            <div className="resourceInfoRow-typeTag" style={{ position: 'absolute', right: 10, top: 0, backgroundColor: statusParameters.color }}>
              <h5 style={{ color: 'white', margin: 0 }}><Entity entity={statusParameters.title} /></h5>
            </div>
          ) : null}
          <div className="card-inner-body">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h4 className="card-title" style={{ margin: 0, color: '#3f3f3f', whiteSpace: 'pre-wrap' }}>{name}</h4>
            </div>
            {description ? <h5 className="card-title" style={{ marginBottom: 0, fontSize: 16, marginTop: 10, color: '#3f3f3f', fontWeight: 'normal', marginLeft: 0, whiteSpace: 'pre-wrap' }}>{description}</h5> : null}
          </div>
        </div>
      </Card>
    );
  }
}
