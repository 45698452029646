
import axios from 'axios';
import { getIseoZendeskParameters } from './utils';


export const axiosInstance = axios.create({
  timeout: 30000,
  baseURL: getIseoZendeskParameters().url,
});

export function createZendeskTicket(ticketDTO) {
  const { apiKey, email } = getIseoZendeskParameters();
  const config = {
    auth: {
      username: `${email}/token`,
      password: apiKey,
    },
  };
  return axiosInstance.post('/api/v2/tickets.json', ticketDTO, config);
}