// @ts-nocheck
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SET_MARKETPLACE_SUBSCRIPTIONS,
  SET_MARKETPLACE_SUBSCRIPTIONS_PAGINATION,
  SET_MARKETPLACE_SUBSCRIPTIONS_FILTER,
  RESET_MARKETPLACE_SUBSCRIPTIONS_FILTER,
  SET_WALLET_CREDITS,
  SET_WALLET_CREDITS_PAGINATION,
  SET_WALLET_CREDITS_FILTER,
  RESET_WALLET_CREDITS_FILTER,
  SET_WALLET_USAGE,
  SET_WALLET_INFO,
  SET_WALLET_USAGE_PAGINATION,
  SET_WALLET_USAGE_FILTER,
  RESET_WALLET_USAGE_FILTER,
  SET_WALLET_SUBSCRIPTIONS,
  SET_WALLET_SUBSCRIPTIONS_PAGINATION,
  SET_WALLET_SUBSCRIPTIONS_FILTER,
  RESET_WALLET_SUBSCRIPTIONS_FILTER,
  SET_WALLET_AVAILABLE_CREDITS,
  SET_WALLET_BURN_RATE,
  SET_WALLET_BURN_RATE_DAILY,
  SET_WALLET_EVENTS,
  SET_WALLET_EVENTS_PAGINATION,
  SET_WALLET_EVENTS_FILTER,
  RESET_WALLET_EVENTS_FILTERS,
  SAVE_MARKETPLACE_ITEMS_CONTENT,
  SAVE_MARKETPLACE_ITEM_CONTENT,
  SAVE_MARKETPLACE_BANNER_CONTENT,
} from '../actions/actionTypes/cloudCredits';

const initialState = Immutable({
  marketPlace: {
    subscriptions: {
      content: [],
      pagination: {},
      filters: {},
    },
    itemsContent: [],
    bannersContent: [],
  },
  wallet: {
    availableCredits: undefined,
    burnRate: 0,
    burnRateDaily: 0,
    info: {},
    credits: {
      content: [],
      pagination: {},
      filters: {
        orderDir: 'DESC',
      },
    },
    events: {
      content: [],
      pagination: {},
      filters: {
        includeDeleted: true,
        orderDir: 'DESC',
      },
    },
    subscriptions: {
      content: [],
      pagination: {},
      filters: {},
    },
    usage: {
      content: [],
      pagination: {},
      filters: {
        orderDir: 'DESC',
      },
    },
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MARKETPLACE_SUBSCRIPTIONS:
      return state.setIn(['marketPlace', 'subscriptions', 'content'], action.subscriptions);
    case SET_MARKETPLACE_SUBSCRIPTIONS_PAGINATION:
      return state.setIn(['marketPlace', 'subscriptions', 'pagination'], action.pagination);
    case SET_MARKETPLACE_SUBSCRIPTIONS_FILTER:
      return state.setIn(['marketPlace', 'subscriptions', 'filters', action.field], action.value);
    case RESET_MARKETPLACE_SUBSCRIPTIONS_FILTER:
      return state.setIn(['marketPlace', 'subscriptions', 'filters'], initialState.marketPlace.subscriptions.filters);
    case SAVE_MARKETPLACE_ITEMS_CONTENT:
      return state.setIn(['marketPlace', 'itemsContent'], action.content);
    case SAVE_MARKETPLACE_BANNER_CONTENT: {
      const index = _.findIndex(state.marketPlace.bannersContent, data => data.id === action.item.id && data.language === action.item.language);
      if (index !== -1) {
        return state.setIn(['marketPlace', 'bannersContent', index], action.item);
      }
      return state.setIn(['marketPlace', 'bannersContent'], [...state.marketPlace.bannersContent, action.item]);
    }
    case SAVE_MARKETPLACE_ITEM_CONTENT: {
      const index = _.findIndex(state.marketPlace.itemsContent, data => data.id === action.item.id && data.language === action.item.language);
      if (index !== -1) {
        return state.setIn(['marketPlace', 'itemsContent', index], action.item);
      }
      return state.setIn(['marketPlace', 'itemsContent'], [...state.marketPlace.itemsContent, action.item]);
    }
    case SET_WALLET_CREDITS:
      return state.setIn(['wallet', 'credits', 'content'], action.credits);
    case SET_WALLET_INFO:
      return state.setIn(['wallet', 'info'], action.walletInfo);
    case SET_WALLET_CREDITS_PAGINATION:
      return state.setIn(['wallet', 'credits', 'pagination'], action.pagination);
    case RESET_WALLET_CREDITS_FILTER:
      return state.setIn(['wallet', 'credits', 'filters'], initialState.wallet.credits.filters);
    case SET_WALLET_CREDITS_FILTER:
      return state.setIn(['wallet', 'credits', 'filters', action.field], action.value);
    case SET_WALLET_SUBSCRIPTIONS:
      return state.setIn(['wallet', 'subscriptions', 'content'], action.subscriptions);
    case SET_WALLET_SUBSCRIPTIONS_PAGINATION:
      return state.setIn(['wallet', 'subscriptions', 'pagination'], action.pagination);
    case SET_WALLET_SUBSCRIPTIONS_FILTER:
      return state.setIn(['wallet', 'subscriptions', 'filters', action.field], action.value);
    case RESET_WALLET_SUBSCRIPTIONS_FILTER:
      return state.setIn(['wallet', 'subscriptions', 'filters'], initialState.wallet.subscriptions.filters);
    case SET_WALLET_BURN_RATE:
      return state.setIn(['wallet', 'burnRate'], action.burnRate);
    case SET_WALLET_BURN_RATE_DAILY:
      return state.setIn(['wallet', 'burnRateDaily'], action.burnRate);
    case SET_WALLET_USAGE:
      return state.setIn(['wallet', 'usage', 'content'], action.usage);
    case SET_WALLET_USAGE_PAGINATION:
      return state.setIn(['wallet', 'usage', 'pagination'], action.pagination);
    case SET_WALLET_USAGE_FILTER:
      return state.setIn(['wallet', 'usage', 'filters', action.field], action.value);
    case RESET_WALLET_USAGE_FILTER:
      return state.setIn(['wallet', 'usage', 'filters'], initialState.wallet.usage.filters);
    case SET_WALLET_AVAILABLE_CREDITS:
      return state.setIn(['wallet', 'availableCredits'], action.credits);
    case SET_WALLET_EVENTS:
      return state.setIn(['wallet', 'events', 'content'], action.events);
    case SET_WALLET_EVENTS_PAGINATION:
      return state.setIn(['wallet', 'events', 'pagination'], action.pagination);
    case SET_WALLET_EVENTS_FILTER:
      return state.setIn(['wallet', 'events', 'filters', action.field], action.value);
    case RESET_WALLET_EVENTS_FILTERS:
      return state.setIn(['wallet', 'events', 'filters'], initialState.wallet.events.filters);
    default:
      return state;
  }
}
