import { Card, createTheme, withStyles } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { BLUE, GREEN, STORE_ITEMS_PROVISIONING_TYPES, STORE_ITEMS_STATUS_MAP, STORE_ITEMS_TYPES, STORE_ITEMS_TYPES_MAP } from '../../_config/consts';
import CloseCircleIconCustom from '../CustomIcons/CloseCircleIconCustom.jsx';
import RemoveIconCustom from '../CustomIcons/RemoveIconCustom.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginBottom: 5,
  },
  container: {
    borderRadius: 10,
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 15,
  },
  cardBodyContainer: {
    padding: 20,
    paddingBottom: 10,
  },
  descriptionText: {
    color: '#3f3f3f',
    margin: 0,
    fontWeight: '100',
    fontSize: 10,
    marginTop: 10,
    lineHeight: '1.5em',
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  logo: {
    position: 'absolute',
    height: 27,
    right: 10,
    top: 10,
  },
  media: {
    height: 170,
    backgroundPosition: 'center',
    borderBottom: '1px solid #e3e3e3'
  },
  activeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  activeText: {
    color: GREEN,
    margin: 0,
    marginRight: 5,
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const StoreItemCardStatusView = ({ isActive, isCompatible, classes, status }) => (
  <div style={{ margin: 3, marginLeft: 0, display: 'flex', flexDirection: 'row'}}>
    {isActive ? (
      <div className={classes.activeContainer}>
        <h4 style={{ fontSize: 15, margin: 0 }}><Entity className={classes.activeText} entity="storeItemActive" /></h4>
        <CheckIcon style={{ fontSize: 18, color: GREEN }} />
      </div>
      ) : (
      <div className={classes.activeContainer}>
        <Entity className={classes.activeText} style={{ color: '#bdbdbd' }} entity="inactive" />
        <RemoveIconCustom style={{ color: '#bdbdbd', width: 18, marginTop: 1 }} />
      </div>
    )}
    {!isCompatible && isActive ? (
      <div className={classes.activeContainer} style={{ marginLeft: 20 }}>
        <Entity className={classes.activeText} entity="storeItemNotCompatible" style={{ color: '#bdbdbd' }} />
        <CloseCircleIconCustom style={{ width: 18, color: '#bdbdbd' }} />
      </div>
    ) : null}
  </div>
);


const StoreItemTypeView = ({ itemType }) => (
  <Card style={{ display: 'flex', flexDirection: 'row', padding: 8, paddingBottom: 0, paddingTop: 3, position: 'absolute', marginTop: 5, marginRight: 5, top: 5, right: 5, backgroundColor: BLUE }}>
    <h4 style={{ fontSize: 15, margin: 0, marginTop: 2, color: 'white', marginRight: 5, fontWeight: 'bold' }}><Entity entity={STORE_ITEMS_TYPES_MAP[itemType].nameSingle} /></h4>
    <span style={{ color: 'white' }}>{STORE_ITEMS_TYPES_MAP[itemType].icon}</span>
  </Card>
);

const AddonStatusView = ({ status }) => (
  <div style={{ display: 'flex', flexDirection: 'row', padding: 8, paddingBottom: 0, paddingTop: 3, marginTop: 5 }}>
    <h4 style={{ fontSize: 15, margin: 0, marginBottom: 4, marginTop: 0, color: STORE_ITEMS_STATUS_MAP[status].color, marginRight: 5, fontWeight: 'bold' }}><Entity entity={STORE_ITEMS_STATUS_MAP[status].name} /></h4>
    <span style={{ color: STORE_ITEMS_STATUS_MAP[status].color }}>{STORE_ITEMS_STATUS_MAP[status].icon}</span>
  </div>
);


const StoreItemCard = ({ classes, item, onPress, isCompatible }) => {
  if (item.visible === false) return null;
  return (
    <MuiThemeProvider theme={theme}>
      <Card className="card card-rounded" style={{ borderRadius: 10, marginTop: 3, height: 380, width: '31%', marginRight: 15, marginLeft: 10, padding: 0, marginBottom: 30, position: 'relative' }} onClick={() => onPress()} elevation={3}>
        <div className={classes.container}>
          <CardMedia
            className={classes.media}
            image={item && item.previewImage ? item.previewImage : item.coverImage}
          />
          <div className={classes.cardBodyContainer}>
            <div className={classes.titleContainer}>
              <h3 className={classes.titleText}>{item.renderedTitle ? item.renderedTitle : <Entity entity={item.title} />}</h3>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            {item.type !== STORE_ITEMS_TYPES.HARDWARE ? (
              <StoreItemCardStatusView
                classes={classes}
                isActive={item.isActive}
                isCompatible={isCompatible}
                status={item.status}
              />
            ) : null}
              <StoreItemTypeView
                itemType={item.type}
              />
              {item.status ? (
                <AddonStatusView
                  status={item.status}
                />
              ): null}
            </div>
            {item.preview ? (
              <div style={{ marginTop: 5 }}className="storeCardContentContainer" dangerouslySetInnerHTML={{ __html: item.preview }} />
              ) : (<h4 className={classes.descriptionText}><Entity entity={item.shortDescription} /></h4>)}
          </div>
        </div>
      </Card>
    </MuiThemeProvider>
  );
};
export default withStyles(styles)(StoreItemCard);
