import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import TimeIcon from '@material-ui/icons/AccessTime';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import LockIcon from '@material-ui/icons/LockOpen';
import moment from 'moment';
import React from 'react';
import { RESOURCE_IMAGE_MAP, BLUE } from '../../_config/consts';
import { Domain } from '@material-ui/icons';

const styles = {
  media: {
    height: 160,
  },
};

const ResourceInfoRow = ({ classes, checkInLock, isSelected, resource: { description, workspace, imageUrl, type, media, name, dateInterval, timeInterval }, onSelect }) => {
  const timeIntervalFrom = moment().startOf('day').add(timeInterval.from, 'seconds').valueOf();
  const timeIntervalTo = moment().startOf('day').add(timeInterval.to, 'seconds').valueOf();
  const fakeUserTag = {
    color: '#ba68c8',
    name: type && type.name ? type.name.toLowerCase() : '',
  };
  let imageSource = imageUrl && RESOURCE_IMAGE_MAP[imageUrl] ? RESOURCE_IMAGE_MAP[imageUrl] : null;
  imageSource = media ? `data:image/jpeg;base64,${media}` : imageSource;
  return (
    <Card className={`card card-rounded resourceInfoRow-card ${isSelected && 'card-selected'}`} style={{ borderRadius: 10 }} elevation={2}>
      <div onClick={() => onSelect()}>
        {imageSource ? (
          <CardMedia
            className={classes.media}
            component="img"
            image={imageSource}
          />
        ) : null}
        <div className="resourceInfoRow-header" style={{ position: 'relative', marginTop: 5, justifyContent: 'space-between', alignItems: 'center' }}>
          <div className="resourceInfoRow-AvatarTitle">
            <div className="resourceInfoRow-titleType">
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold' }}>{name}</h4>
              <div className="resourceInfoRow-typeTag">
                <h5 style={{ color: 'white', margin: 0 }}>{type.name}</h5>
              </div>
              {description && <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 15 }}>{description}</h5>}
            </div>
          </div>
        </div>
        <div className="resourceInfoRow-info">
          {workspace && workspace.length ? (
            <div className="resourceInfoRow-info-col">
              <Domain className="resourceInfoRow-info-col-icon" style={{ fontSize: 25 }} />
              <h6 style={{ color: '#3f3f3f', margin: 0, marginLeft: 10, textAlign: 'center' }}>{workspace}</h6>
            </div>
          ) : null}
          {checkInLock &&
            <div className="resourceInfoRow-info-col">
              <LockIcon className="resourceInfoRow-info-col-icon" style={{ fontSize: 25 }} />
              <h6 style={{ color: '#3f3f3f', margin: 0, marginLeft: 10, textAlign: 'center' }}>{checkInLock.name}</h6>
            </div>
          }
          <div className="resourceInfoRow-info-col">
            <CalendarIcon className="resourceInfoRow-info-col-icon" style={{ fontSize: 25 }} />
            <div style={{ marginLeft: 10 }}>
              <h6 style={{ color: '#3f3f3f', margin: 0, textAlign: 'center' }}>{moment(dateInterval.from).format('L')}</h6>
              <h6 style={{ color: '#3f3f3f', margin: 0, textAlign: 'center' }}>{moment(dateInterval.to).format('L')}</h6>
            </div>
          </div>
          <div className="resourceInfoRow-info-col">
            <TimeIcon className="resourceInfoRow-info-col-icon" style={{ fontSize: 25 }} />
            <div style={{ marginLeft: 10 }}>
              <h6 style={{ color: '#3f3f3f', margin: 0, textAlign: 'center' }}>{moment(timeIntervalFrom).format('LT')}</h6>
              <h6 style={{ color: '#3f3f3f', margin: 0, textAlign: 'center' }}>{moment(timeIntervalTo).format('LT')}</h6>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default withStyles(styles)(ResourceInfoRow);
