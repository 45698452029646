import React from 'react';
import {
  Icon,
} from '@sketchpixy/rubix';

import { getContrastYIQ } from '../_config/utils';
import { TIMED_TAG_STATUSES } from '../_config/consts';
import CalendarNormalIcon from '@material-ui/icons/Event';
import UserIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';

export default class Tag extends React.Component {
  render() {
    const { tag, style, textStyle, className } = this.props;
    let opacity = 1;
    if (tag && (tag.state === TIMED_TAG_STATUSES.EXPIRED || tag.state === TIMED_TAG_STATUSES.NOT_VALID)) {
      opacity = 0.3;
    }
    const isTimedTag = tag && (tag.timeFrom || tag.timeTo) ? true : false;
    const isLockTag = tag && tag.type === 'lock';
    const isUserTag = tag && tag.type === 'user';
    const textColor = getContrastYIQ(tag.color);

    return (
      <div
        key={tag.id}
        className={className ? `tag ${className}` : 'tag'}
        style={{
          marginTop: 5,
          marginBottom: 2,
          padding: 5,
          alignItems: 'center',
          backgroundColor: tag.color,
          borderColor: tag.color,
          display: 'inline-flex',
          opacity,
          color: `${textColor}`,
          ...style,
        }}
      >
        {isTimedTag &&
          <CalendarNormalIcon style={{ fontSize: 15, marginRight: 5 }} />
        }
        {isUserTag && <UserIcon style={{ fontSize: 15, marginRight: 5 }} /> }
        {isLockTag && <LockIcon style={{ fontSize: 15, marginRight: 5 }} />}
        <h5 style={{ margin: 0, fontSize: 14, ...textStyle }}>{tag.name}</h5>
      </div>
    );
  }
}
