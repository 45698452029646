module.exports = {
  SAVE_INSTALLERS: 'SAVE_INSTALLERS',
  SAVE_INSTALLERS_PAGINATION_DATA: 'SET_INSTALLERS_PAGINATION_DATA',
  APPEND_INSTALLERS: 'APPEND_INSTALLERS',
  SET_SELECTED_INSTALLER: 'SET_SELECTED_INSTALLER',
  SET_INSTALLERS_OPERATIONAL_MODE: 'SET_INSTALLERS_OPERATIONAL_MODE',
  REMOVE_INSTALLER: 'REMOVE_INSTALLER',
  UPDATE_INSTALLER: 'UPDATE_INSTALLER',
  SET_INSTALLERS_FILTER: 'SET_INSTALLERS_FILTER',
  RESET_INSTALLERS_FILTERS: 'RESET_INSTALLERS_FILTERS',
  RESET_INSTALLERS_DATA: 'RESET_INSTALLERS_DATA',
  SAVE_INSTALLER: 'SAVE_INSTALLER',
  RESET_INSTALLERS_PAGINATION_DATA: 'RESET_INSTALLERS_PAGINATION_DATA',
  SET_INSTALLERS_ORDER: 'SET_INSTALLERS_ORDER',
};
