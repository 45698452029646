import React from 'react';
import moment from 'moment';
import TimeIcon from '@material-ui/icons/AccessTime';
import { TimePicker } from 'antd';
import { LANGUAGES } from '../../../../_config/consts';
import { connect } from 'react-redux';

@connect(state => ({ language: state.settings.language }))
class TimePickerField extends React.Component {
  
  /* onChange(value) {
    const { input } = this.props;
    input.onChange(moment(value).valueOf());
  }

  onHandleChange(value) {
    const { onHandleChange } = this.props;
    onHandleChange(moment(value).valueOf());
  }; */

  onHandleChange(e, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(e);
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }

  render() {
    const { input, containerStyle, title, mandatory, language, iconStyle, titleStyle } = this.props;
    const value = input.value ? input.value : moment();
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    return (
      <div style={{ ...containerStyle }}>
        {title &&
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, alignItems: 'center' }}>
          <TimeIcon style={{ marginRight: 10, color: '#3f3f3f', ...iconStyle }} />
          <h4 style={{ margin: 0, color: '#3f3f3f', ...titleStyle }}>{title}{mandatory ? <span style={{ color: '#D71F4B' }}>*</span> : ''}</h4>
        </div>}
        <TimePicker
          showSecond={false}
          defaultValue={moment(value)}
          placeholder={`${moment(value).format('LT')}` || `${moment().format('LT')}`}
          className="time-picker"
          format={is12HoursFormat ? 'h:mm a' : 'HH:mm'}
          {...this.props}
          allowClear={false}
          onChange={(value) => this.onHandleChange(value)}
          use12Hours={is12HoursFormat}
          onClose={(value) => this.onHandleChange(value)}
        />
      </div>
    );
  }
} 

export default TimePickerField;
