import React from 'react';
import { useDrag } from 'react-dnd';

import Card from '@material-ui/core/Card';
import { GREEN, RED, RESOURCE_IMAGE_MAP, DND_ELEMENTS } from '../../_config/consts';
import MapIconCustom from '../CustomIcons/MapIconCustom.jsx';

export default function MapResourceRow({ name, id, imageUrl, media, locationMedia, selectedMapId }) {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: DND_ELEMENTS.RESOURCE_ROW,
      item: { name, id },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    []
  )
  let imageSource = imageUrl && RESOURCE_IMAGE_MAP[imageUrl] ? RESOURCE_IMAGE_MAP[imageUrl] : null;
  imageSource = media ? `data:image/jpeg;base64,${media}` : imageSource;
  const isAssignedToThisMap = locationMedia && locationMedia.id === selectedMapId;
  return (
    <Card
      ref={dragRef}
      className={`card card-rounded`}
      style={{ borderRadius: 10, padding: 10, paddingTop: 15, paddingBottom: 15, opacity, position: 'relative' }}
      elevation={2}
    >
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {imageSource ? <div className="card card-rounded" style={{ backgroundImage: `url(${imageSource})`, backgroundSize: 'cover', width: 40, height: 40, borderRadius: 50, marginBottom: 0, marginRight: 10 }} /> : null}
          <h4 className="card-title" style={{ margin: 2, marginTop: 5 }}>{name}</h4>
        </div>
        {locationMedia ? <MapIconCustom style={{ width: 20, position:'absolute', top: 5, right: 5, color: isAssignedToThisMap ? GREEN : RED }} /> : null}
      </div>
    </Card>
  );
}
