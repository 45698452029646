import React from 'react';
import _ from 'lodash';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import * as AccessoriesAPI from '../../_config/accessoriesAPI';
import * as ModalActions from './modal.actions';
import * as UtilsActions from './utils.actions'
import { ACCESSORIES_TYPES, BACKGROUND_TASK_TYPES, EXPORT_FORMATS } from '../../_config/consts';
import {
  SAVE_ACCESSORIES,
  APPEND_ACCESSORIES,
  SAVE_ACCESSORIES_PAGINATION,
  SET_SELECTED_ACCESSORY,
  SET_ACCESSORIES_FILTER,
  RESET_ACCESSORIES_FILTERS,
  RESET_ACCESSORIES_DATA,
  UPDATE_ACCESSORY,
  REMOVE_ACCESSORY,
  SAVE_ACCESSORY_HISTORY,
  APPEND_ACCESSORY_HISTORY,
  SAVE_ACCESSRY_HISTORY_PAGINATION,
  SET_ACCESSORY_HISTORY_FILTER,
  RESET_ACCESSORY_HISTORY_FILTER,
  RESET_ACCESSORY_HISTORY_DATA,
} from './actionTypes/accessories';
import moment from 'moment';

export function saveAccessories(accessories) {
  return {
    type: SAVE_ACCESSORIES,
    accessories,
  };
}

export function appendAccessories(accessories) {
  return {
    type: APPEND_ACCESSORIES,
    accessories,
  };
}

export function updateAccessoryInState(accessory) {
  return {
    type: UPDATE_ACCESSORY,
    accessory,
  };
}

export function removeAccessoryFromState(accessoryId) {
  return {
    type: REMOVE_ACCESSORY,
    accessoryId,
  };
}

export function saveAccessoriesPagination(pagination) {
  return {
    type: SAVE_ACCESSORIES_PAGINATION,
    pagination,
  };
}

export function setSelectedAccessory(accessory) {
  return {
    type: SET_SELECTED_ACCESSORY,
    accessory,
  };
}

export function setAccessoriesFilter(field, value) {
  return {
    type: SET_ACCESSORIES_FILTER,
    field,
    value
  };
}

export function resetAccessoriesFilters() {
  return {
    type: RESET_ACCESSORIES_FILTERS,
  };
}

export function resetAccessoriesData() {
  return {
    type: RESET_ACCESSORIES_DATA,
  };
}

export function saveAccessoryHistory(history) {
  return {
    type: SAVE_ACCESSORY_HISTORY,
    history,
  };
}

export function appendAccessoryHistory(history) {
  return {
    type: APPEND_ACCESSORY_HISTORY,
    history,
  };
}

export function saveAccessoryHistoryPagination(pagination) {
  return {
    type: SAVE_ACCESSRY_HISTORY_PAGINATION,
    pagination,
  };
}

export function setAccessoryHistoryFilter(field, value) {
  return {
    type: SET_ACCESSORY_HISTORY_FILTER,
    field,
    value,
  };
}

export function resetAccessoryHistoryFilters() {
  return { type: RESET_ACCESSORY_HISTORY_FILTER };
}

export function resetAccessoryHistoryData() {
  return { type: RESET_ACCESSORY_HISTORY_DATA };
}

export function fetchAccessories(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const { accessories: { data: { filters } } } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await AccessoriesAPI.fetchAccessories(params);
      if (response && response.data && response.data.content) {
        dispatch(saveAccessories(response.data.content));
        dispatch(saveAccessoriesPagination(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchStatusSensorAccessories(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const { accessories: { data: { filters } } } = getState();
      const params = {
        type: ACCESSORIES_TYPES.DOOR_SENSOR,
        page,
        pageSize,
      };
      const response = await AccessoriesAPI.fetchAccessories(params);
      if (response && response.data && response.data.content) {
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAndAppendAccessories(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const { accessories: { data: { filters } } } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await AccessoriesAPI.fetchAccessories(params);
      if (response && response.data && response.data.content) {
        dispatch(appendAccessories(response.data.content));
        dispatch(saveAccessoriesPagination(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchLockAccessories(lockId) {
  return async (dispatch, getState) => {
    try {
      const params = {
        entityId: lockId,
      };
      const response = await AccessoriesAPI.fetchAccessories(params);
      if (response && response.data && response.data.content) {
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function fetchAllAccessories(page = 0, pageSize = 50) {
  return async (dispatch) => {
    try {
      let params = {
        page,
        pageSize,
      };
      let accessoriesResponse = await AccessoriesAPI.fetchAccessories(params);
      if (accessoriesResponse.data) {
        let pagination = _.omit(accessoriesResponse.data, 'content');
        const accessories = [...accessoriesResponse.data.content];
        while (!accessoriesResponse.data.last) {
          params = {
            ...params,
            page: accessoriesResponse.data.number + 1,
          };
          accessoriesResponse = await AccessoriesAPI.fetchAccessories(params);
          accessories.push(...accessoriesResponse.data.content);
          pagination = _.omit(accessoriesResponse.data, 'content');
        }
        dispatch(saveAccessoriesPagination(pagination));
        dispatch(saveAccessories(accessories));
        return accessories;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function createAccessory(accessoryDTO) {
  return async (dispatch, getState) => {
    try {
      const response = await AccessoriesAPI.createAccessory(accessoryDTO);
      if (response.data) {
        dispatch(updateAccessoryInState(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function deleteAccessory(accessoryId) {
  return async (dispatch, getState) => {
    try {
      const response = await AccessoriesAPI.deleteAccessory(accessoryId);
      if (response.data) {
        dispatch(removeAccessoryFromState(accessoryId));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAccessoryDetails(accessoryId) {
  return async (dispatch, getState) => {
    try {
      const response = await AccessoriesAPI.fetchAccessoryDetails(accessoryId);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function refreshSmartLockStatusSensorValue(lock, accessoryId) {
  return async (dispatch, getState) => {
    try {
      const accessoryUpdated = await dispatch(fetchAccessoryDetails(accessoryId));
      const locksAccessoriesToUpdate = _.filter(lock.accessories, accessory => accessory.id !== accessoryId);
      const smartLocksWithSensors = {
        ...lock,
        accessories: [
          ...locksAccessoriesToUpdate,
          accessoryUpdated
        ],
      };
      return smartLocksWithSensors;
    } catch (error) {
      throw error;
    }
  };
}

export function updateAccessory(accessoryDTO) {
  return async (dispatch, getState) => {
    try {
      const response = await AccessoriesAPI.updateAccessory(accessoryDTO.id, accessoryDTO);
      if (response.data) {
        dispatch(updateAccessoryInState(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function fetchResourceAccessories(resourceId) {
  return async (dispatch, getState) => {
    try {
      const params = {
        entityId: resourceId,
        page: 0,
        pageSize: 50,
      };
      const response = await AccessoriesAPI.fetchAccessories(params);
      if (response && response.data && response.data.content) {
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAccessoryHistory(accessoryId, page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const { accessories: { history: { filters } } } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await AccessoriesAPI.fetchAccessoryHistory(accessoryId, params);
      if (response && response.data && response.data.content) {
        dispatch(saveAccessoryHistory(response.data.content));
        dispatch(saveAccessoryHistoryPagination(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAndAppendAccessoryHistory(accessoryId, page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      const { accessories: { history: { filters } } } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await AccessoriesAPI.fetchAccessoryHistory(accessoryId, params);
      if (response && response.data && response.data.content) {
        dispatch(appendAccessoryHistory(response.data.content));
        dispatch(saveAccessoryHistoryPagination(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllSensorHistory(accessoryId, page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    let stopFetching = false;
    const { accessories: { history: { filters } } } = getState();
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'exportingAdminEvents',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        ...filters
      };
      let response = await AccessoriesAPI.fetchAccessoryHistory(accessoryId, params);
      if (response && response.data && response.data.content && !_.isEmpty(response.data.content)) {
        const sensorHistory = [...response.data.content];
        let pagination = _.omit(response.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          response = await AccessoriesAPI.fetchAccessoryHistory(params);
          sensorHistory.push(...response.data.content);
          pagination = _.omit(response.data, 'content');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { sensorHistory, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function exportAccessoryHistory(accessoryId, format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { sensorHistory, stopFetching } = await dispatch(fetchAllSensorHistory(accessoryId));
      if (!stopFetching) {
        const exportData = [];
        _.each(sensorHistory, (event) => {
          exportData.push({
            'Status': L20NContext.getSync('doorStatuses', { status: event.value }),
            Date: moment(event.timestamp).format('LLL'),
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { events: ws }, SheetNames: ['events'] };
        const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Door Status Report.${fileExtension}`);
        return sensorHistory;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}