import UserIcon from '@material-ui/icons/AccountBox';
import CompanyIcon from '@material-ui/icons/Business';
import { Col, Form, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts.js';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import CustomField from './Fields/CustomField.jsx';


const validate = (values) => {
  const errors = {};
  return errors;
};

@reduxForm({ form: 'CompanySettingsForm', validate })
class CompanySettingsForm extends React.Component {
  render() {
    const canEditCompanyData = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.COMPANY);
    return (
      <div>
        <FormCardContainer
          title="companyContactTitle"
          subtitle="companyContactDescription"
          icon={<UserIcon style={{ color: '#3f3f3f', fontSize: 20 }} />}

        >
          <Entity
            componentClass={Field}
            type="text"
            name="mainFirstname"
            componentAttribute="placeholder"
            entity="insertName"
            title={<Entity entity="name" />}
            component={CustomField}
            disabled={!canEditCompanyData}
          />
          <Entity
            componentClass={Field}
            type="text"
            name="mainLastname"
            componentAttribute="placeholder"
            entity="insertSurname"
            title={<Entity entity="surname" />}
            component={CustomField}
            disabled={!canEditCompanyData}
          />
          <Entity
            componentClass={Field}
            type="text"
            name="mainEmail"
            componentAttribute="placeholder"
            entity="insertEmail"
            title={<Entity entity="email" />}
            component={CustomField}
            disabled={!canEditCompanyData}
          />
        </FormCardContainer>
        <FormCardContainer
          title="yourCompanyData"
          icon={<CompanyIcon style={{ color: '#3f3f3f', fontSize: 20 }} />}
        >
          <Form>
            <input style={{ display: 'none' }} type="username" name="username" />
            <Entity
              componentClass={Field}
              type="text"
              name="name"
              componentAttribute="placeholder"
              entity="insertBusinessName"
              title={<Entity entity="businessName" />}
              component={CustomField}
              disabled={!canEditCompanyData}
            />
            <Entity
              componentClass={Field}
              type="text"
              name="email"
              componentAttribute="placeholder"
              entity="insertEmail"
              title={<Entity entity="email" />}
              component={CustomField}
              disabled={!canEditCompanyData}
            />
            <Entity
              componentClass={Field}
              type="text"
              name="vat"
              componentAttribute="placeholder"
              entity="insertVatNumber"
              title={<Entity entity="vatNumber" />}
              component={CustomField}
              disabled={!canEditCompanyData}
            />
            <Entity
              componentClass={Field}
              type="text"
              name="taxCode"
              componentAttribute="placeholder"
              entity="insertTaxCode"
              title={<Entity entity="taxCode" />}
              component={CustomField}
              disabled={!canEditCompanyData}
            />
            <Row>
              <Col xs={12} sm={6}>
                <Entity
                  componentClass={Field}
                  type="text"
                  name="address"
                  componentAttribute="placeholder"
                  entity="insertAddress"
                  title={<Entity entity="address" />}
                  component={CustomField}
                  disabled={!canEditCompanyData}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Entity
                  componentClass={Field}
                  type="text"
                  name="city"
                  componentAttribute="placeholder"
                  entity="insertCity"
                  title={<Entity entity="city" />}
                  component={CustomField}
                  disabled={!canEditCompanyData}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4}>
                <Entity
                  componentClass={Field}
                  type="text"
                  name="province"
                  componentAttribute="placeholder"
                  entity="insertProvince"
                  title={<Entity entity="province" />}
                  component={CustomField}
                  disabled={!canEditCompanyData}
                />
              </Col>
              <Col xs={12} sm={4}>
                <Entity
                  componentClass={Field}
                  type="text"
                  name="zipCode"
                  componentAttribute="placeholder"
                  entity="insertCap"
                  title={<Entity entity="cap" />}
                  component={CustomField}
                  disabled={!canEditCompanyData}
                />
              </Col>
              <Col xs={12} sm={4}>
                <Entity
                  componentClass={Field}
                  type="text"
                  name="state"
                  componentAttribute="placeholder"
                  entity="insertState"
                  title={<Entity entity="state" />}
                  component={CustomField}
                  disabled={!canEditCompanyData}
                />
              </Col>
            </Row>
            <Entity
              componentClass={Field}
              type="text"
              name="phone"
              componentAttribute="placeholder"
              entity="insertPhone"
              title={<Entity entity="phone" />}
              component={CustomField}
              disabled={!canEditCompanyData}
            />
            <Entity
              componentClass={Field}
              type="text"
              name="fax"
              componentAttribute="placeholder"
              entity="insertFax"
              title={<Entity entity="fax" />}
              component={CustomField}
              disabled={!canEditCompanyData}
            />
          </Form>
        </FormCardContainer>
      </div>
    );
  }
} 

export default CompanySettingsForm;
