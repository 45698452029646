import { IconButton } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import * as ModalActions from '../../redux/actions/modal.actions.js';
import * as UserActions from '../../redux/actions/user.actions.js';
import * as UtilsActions from '../../redux/actions/utils.actions';
import MDButton from '../MDButton/MDButton.jsx';
import SupportTicketForm from '../forms/SupportTicketForm.jsx';
import SupportFromVar from '../forms/SupportFromVar.jsx';
import { SOFIA_CONTACTS } from '../../_config/consts.js';


@connect(state => ({ isSupportTicketFormVisible: state.utils.isSupportTicketFormVisible, companyInfo: state.user.companyInfo }))
export default class SupportTicketOperationalSection extends React.Component {

  componentDidMount() {
    document.addEventListener('keydown', e => this.onKeyPressed(e), false);
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', e => this.onKeyPressed(e), false);
  }
  
  onKeyPressed(e) {
    if (e.key === 'Escape') {
      const { dispatch } = this.props;
      dispatch(UtilsActions.setSupportTicketFormVisible(false));
    }
  }
  
  async onOpenSupportTicket(ticketData) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.createIseoZendeskTicket(ticketData));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(UtilsActions.setSupportTicketFormVisible(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="supportTicketCreateSuccess"/></h6>)
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="supportTicketCreateError" /></h6>),
        },
      }));
    }
  }

  render() {
    const { dispatch, themeName, isSupportTicketFormVisible, companyInfo } = this.props;
    return (
      <ReactCSSTransitionGroup
        style={{ paddingBottom: 0 }}
        transitionName="bounce"
        transitionEnterTimeout={0}
        transitionLeaveTimeout={0}
      >
        {isSupportTicketFormVisible &&
          <div className="operational-view" style={{ top: 0, zoom: '80%', padding: 0, margin: 0 }}>
            <div className={`operational-view-title-container bg-${themeName || 'default'}-theme`}>
              <div style={{ display: 'flex', alignItems: 'center', flex: 1, flexDirection: 'row', height: 60 }}>
                <IconButton onClick={() => dispatch(UtilsActions.setSupportTicketFormVisible(false))} style={{ marginLeft: 10 }}>
                  <Cancel style={{ color: 'white', fontSize: 30 }} />
                </IconButton>
                <div style={{ marginLeft: 15 }}>
                  <h3 style={{ fontWeight: 'bold', color: 'white', margin: 0, alignSelf: 'center', textAlign: 'center' }}><Entity entity="reportProblem" /></h3>
                </div>
              </div>
            </div>
            {companyInfo.varName && companyInfo.varSupportEmail !== SOFIA_CONTACTS.SUPPORT ? (
              <div className="operational-view-container" style={{ padding: 0, paddingBottom: 70, paddingLeft: 20, paddingRight: 40 }}>
                <SupportFromVar
                  companyInfo={companyInfo}
                />
              </div>
            ):(
              <div className="operational-view-container" style={{ padding: 0, paddingBottom: 70, paddingLeft: 20, paddingRight: 40 }}>
                <SupportTicketForm
                  onSubmit={ticketData => this.onOpenSupportTicket(ticketData)}
                />
                <MDButton
                  title={<Entity entity="newTicketForm" />}
                  onClick={() => dispatch(submit('SupportTicketForm'))}
                />
              </div>
            )}
            
          </div>
        }
      </ReactCSSTransitionGroup>
    );
  }
}
