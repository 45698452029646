import CheckIcon from '@material-ui/icons/CheckCircle';
import ReceivedIcon from '@material-ui/icons/CloudDownload';
import SendIcon from '@material-ui/icons/CloudUpload';
import TransitIcon from '@material-ui/icons/Send';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { GATEWAYS_NOTIFICATION_STATUSES } from '../../../_config/consts';
import { elaborateNotificationProgressPercentage } from '../../../_config/utils';

export default class NotificationStatusProgressBar extends React.Component {
  render() {
    const { notification } = this.props;
    const progress = elaborateNotificationProgressPercentage(notification && notification.status);
    return (
      <ProgressBar
        percent={progress}
        filledBackground="linear-gradient(to right, #009688, #80CBC4)"
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <div className="progress-bar-step-container" style={{ opacity: accomplished ? 1 : 0.5 }}>
              <SendIcon className="open-remote-icon" style={{ fontSize: 30 }} />
              <h4 className="open-remote-text">
                <Entity entity="notificationStatuses" data={{ status: GATEWAYS_NOTIFICATION_STATUSES.WAITING }} />
              </h4>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div className="progress-bar-step-container" style={{ opacity: accomplished ? 1 : 0.5 }}>
              <TransitIcon className="open-remote-icon" style={{ fontSize: 30 }} />
              <h4 className="open-remote-text">
                <Entity entity="notificationStatuses" data={{ status: GATEWAYS_NOTIFICATION_STATUSES.IN_TRANSIT }} />
              </h4>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div className="progress-bar-step-container" style={{ opacity: accomplished ? 1 : 0.5 }}>
              <ReceivedIcon className="open-remote-icon" style={{ fontSize: 30 }} />
              <h4 className="open-remote-text">
                <Entity entity="notificationStatuses" data={{ status: GATEWAYS_NOTIFICATION_STATUSES.RECEIVED }} />
              </h4>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <div className="progress-bar-step-container" style={{ opacity: accomplished ? 1 : 0.5 }}>
              <CheckIcon className="open-remote-icon" style={{ fontSize: 30 }} />
              <h4 className="open-remote-text">
                <Entity entity="notificationStatuses" data={{ status: GATEWAYS_NOTIFICATION_STATUSES.APPLIED }} />
              </h4>
            </div>
          )}
        </Step>
      </ProgressBar>
    );
  }
}
