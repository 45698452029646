import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { change, initialize, submit } from 'redux-form';
import { PERMISSIONS, PERMISSION_ENTITIES, SUBSCRIPTION_TYPES } from '../../../_config/consts';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import * as SettingsActions from '../../../redux/actions/setting.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import * as UserActions from '../../../redux/actions/user.actions.js';
import * as ModalActions from '../../../redux/actions/modal.actions.js';
import MDButton from '../../MDButton/MDButton.jsx';
import Permission from '../../Permission/Permission.jsx';
import SystemSettingsForm from '../../forms/SystemSettingsForm.jsx';


@connect((state) => ({ form: state.form.SystemSettingsForm }))
class SystemSettingsOperationalSection extends React.Component {


  componentWillMount() {
    const { dispatch, setting } = this.props;
    dispatch(SettingsActions.fetchSettingsByType('systemSettings'))
    .then((response) => {
      let invitationLimit = 0;
      let guestInvitationLimitDefault = response.guestInvitationLimitDefault;
      if (response && response.guestInvitationLimitDefault && response.guestInvitationLimitDefault > 0) {
        invitationLimit = response.guestInvitationLimitDefault;
        guestInvitationLimitDefault = 1;
      }
      dispatch(initialize('SystemSettingsForm', { ...response, invitationLimit, guestInvitationLimitDefault }));
    });
  }

  async saveCompanySettings(values) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(SettingsActions.updateSettings(values));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateSuccessfully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateError" /></h6>),
        },
      }));
    }
  }

  onSelectInvitationGuestOption(value) {
    const { dispatch } = this.props; 
    switch (value) {
      case 'DISABLED':
        dispatch(change('SystemSettingsForm', 'guestInvitationLimitDefault', 0));
        break;
      case 'ENABLED_NO_LIMIT':
        dispatch(change('SystemSettingsForm', 'guestInvitationLimitDefault', -1));
        break;
      case 'ENABLED_WITH_LIMIT':
        dispatch(change('SystemSettingsForm', 'guestInvitationLimitDefault', 1));
        break;
    }
  }

  onHandleChangeInvitationLimit(value) {
    const { dispatch } = this.props; 
    dispatch(change('SystemSettingsForm', 'guestInvitationLimitDefault', value));
  }

  render() {
    const { dispatch, form } = this.props;
    const isDeviceManagementActive = dispatch(UtilsActions.isDeviceManagementActive());
    const isInvitationActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.INVITATIONS));
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        <SystemSettingsForm
          formValues={form}
          isDeviceManagementActive={isDeviceManagementActive}
          isInvitationActive={isInvitationActive}
          onSelectInvitationGuestOption={value => this.onSelectInvitationGuestOption(value)}
          onHandleChangeInvitationLimit={value => this.onHandleChangeInvitationLimit(value)}
          onSubmit={values => this.saveCompanySettings(values)}
        />
        <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
          <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.COMPANY} abilityHelper={AbilityProvider.getAbilityHelper()}>
            <MDButton
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              title={<Entity entity="save" />}
              onClick={() => dispatch(submit('SystemSettingsForm'))}
            />
          </Permission>
          </div>
      </div>
    );
  }
} 

export default SystemSettingsOperationalSection;
