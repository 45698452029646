import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import FormSubmitBar from '../forms/Elements/FormSubmitBar.jsx';
import OperationalViewHeader from './OperationalViewHeader.jsx';

export default class OperationalView extends React.Component {

  componentDidMount() {
    document.addEventListener('keydown', e => this.onKeyPressed(e), false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', e => this.onKeyPressed(e), false);
  }

  onKeyPressed(e) {
    if (e.key === 'Escape') {
      const { onClose } = this.props;
      onClose();
    }
  }

  render() {
    const { title, onClose, onRefresh, onDelete, onInfo, themeName, children, operationaViewStyle, isVisible, onCancel, onConfirm, style } = this.props;
    return (
      <ReactCSSTransitionGroup
        style={{ paddingBottom: 0 }}
        transitionName="bounce"
        transitionEnterTimeout={0}
        transitionLeaveTimeout={0}
      >
        {isVisible &&
          <div className="operational-view" style={{ ...operationaViewStyle }}>
            <OperationalViewHeader
              onClose={() => onClose()}
              onRefresh={onRefresh ? () => onRefresh() : null}
              onDelete={onDelete ? () => onDelete() : null}
              onInfo={onInfo ? () => onInfo() : null}
              themeName={themeName}
              title={title}
            />
            <div className="operational-view-footer">
              {(onConfirm || onCancel) &&
                <FormSubmitBar
                  onConfirm={onConfirm}
                  onCancel={onCancel}
                />
              }
            </div>
            <div className="operational-view-container" style={style}>
              {children}
            </div>
            
          </div>
        }
      </ReactCSSTransitionGroup>
    );
  }
}
