import { withStyles } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import MDCheckBoxView from './MDCheckBoxView.js';

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  titleText: {
    margin: 0,
    marginRight: 5,
    fontSize: 15,
    fontWeight: 'bold',
  },
});

class MDCheckBoxGroupField extends React.Component {
  onHandleChange(e, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(e);
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }

  onClick(o) {
    const { input, isSingleChoice, toggleEnableOptionField } = this.props;
    const checkedItems = isSingleChoice && !_.isArray(o.value) ? null : (input.value || []);
    if (_.includes(checkedItems, o.value) || _.isEqual(checkedItems, o.value)) { // if already selected
      let updatedItems = _.filter(checkedItems, i => i !== o.value);
      if (toggleEnableOptionField && o.value === toggleEnableOptionField) {
        updatedItems = [];
      }
      if (_.isArray(o.value)) {
        updatedItems = [];
      }
      this.onHandleChange(updatedItems, input);
    } else if (isSingleChoice) {
      this.onHandleChange(o.value, input);
    } else {
      this.onHandleChange([...checkedItems, o.value], input);
    }
  }

  onSelectAll() {
    const { input, options, selectAllValue } = this.props;
    const values = _.map(options, option => option.value);
    const valuesSelected = selectAllValue ? [...values, selectAllValue] : values;
    const checkedItems = input.value || [];
    if (_.isEqual(checkedItems, valuesSelected)) {
      this.onHandleChange([], input);
    } else {
      this.onHandleChange(valuesSelected, input);
    }
  }

  render() {
    const { toggleEnableOptionField, options, input, title, selectAllValue, titleStyle, subtitleStyle, isSingleChoice, canSelectAll, containerstyle, horizontal, meta, mandatory, classes, showErrorLabel, disabled } = this.props;
    const checkedItems = input.value || [];
    const hasError = meta.touched && meta.error ? true : false;
    const values = _.map(options, option => option.value);
    const valuesSelected = selectAllValue ? [...values, selectAllValue] : values;
    return (
      <div style={{ ...containerstyle }}>
        <FormGroup>
          <div className={classes.titleContainer}>
            <h4 className={classes.titleText} style={{ color: hasError ? 'red' : 'black' }}>{title}</h4>
            {mandatory && <span style={{ color: '#D71F4B' }}>*</span>}
          </div>
          {!isSingleChoice && canSelectAll && (
            <div>
              <MDCheckBoxView
                key="all"
                disabled={disabled}
                checked={_.isEqual(checkedItems, valuesSelected)}
                onSelect={() => this.onSelectAll()}
                label={<Entity entity="selectAll" />}
              />
              <hr style={{ borderWidth: 3, margin: 0, marginBottom: 10 }} />
            </div>
          )}
          <div style={{ display: !horizontal ? 'table-column' : 'flex', flexDirection: horizontal ? 'row' : 'column', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            {_.map(options, option => (
              <MDCheckBoxView
                key={option.key ? option.key : option.value}
                disabled={disabled || (toggleEnableOptionField && option.value !== toggleEnableOptionField && !_.includes(checkedItems, toggleEnableOptionField))}
                checked={_.includes(checkedItems, option.value) || _.isEqual(checkedItems, option.value)}
                onSelect={() => this.onClick(option)}
                label={option.label}
                subtitle={option.subtitle}
                titleStyle={titleStyle}
                subtitleStyle={subtitleStyle}
              />
            ),
            )}
          </div>
        </FormGroup>
        
        {showErrorLabel && hasError && <h6 style={{ color: 'red', marginTop: 4, marginLeft: 10 }}>{meta.error}</h6>}
      </div>
    );
  }
}

export default withStyles(styles)(MDCheckBoxGroupField);
