/* eslint-disable no-await-in-loop */
import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import {
  SAVE_COMMUNICATIONS,
  APPEND_COMMUNICATIONS,
  SET_COMMUNICATIONS_PAGINATION_DATA,
  SELECT_COMMUNICATION,
  SET_COMMUNICATIONS_FILTER,
  UPDATE_COMMUNICATION,
  RESET_COMMUNICATIONS_FILTERS,
  RESET_COMMUNICATIONS_DATA,
  RESET_COMMUNICATION_PAGINATION_DATA,
  SET_COMMUNICATIONS_OPERATIONAL_MODE,
} from './actionTypes/communications';
import * as UtilsActions from '../actions/utils.actions';
import * as formatter from '../../_config/formatter';
import * as CommunicationsAPI from '../../_config/communicationsAPI';
import * as ModalActions from '../actions/modal.actions';
import { BACKGROUND_TASK_TYPES, EXPORT_FORMATS, ITEM_DELIVERY_TYPES_NAMES, ITEM_TYPES_NAMES } from '../../_config/consts';
import { saveDataToLocalStorage } from '../../_config/utils';

export function setCommunicationsOperationalMode(value) {
  return {
    type: SET_COMMUNICATIONS_OPERATIONAL_MODE,
    value,
  };
}

export function saveCommunications(communications) {
  return {
    type: SAVE_COMMUNICATIONS,
    communications,
  };
}

export function appendCommunications(communications) {
  return {
    type: APPEND_COMMUNICATIONS,
    communications,
  };
}

export function saveCommunicationsPaginationData(pagination) {
  return {
    type: SET_COMMUNICATIONS_PAGINATION_DATA,
    pagination,
  };
}

export function setCommunicationsFilter(field, value) {
  return {
    type: SET_COMMUNICATIONS_FILTER,
    field,
    value,
  };
}

export function setSelectedCommunication(communication) {
  return {
    type: SELECT_COMMUNICATION,
    communication,
  };
}

export function updateCommunicationOnState(communication) {
  return {
    type: UPDATE_COMMUNICATION,
    communication,
  };
}

export function resetCommunicationsData() {
  return {
    type: RESET_COMMUNICATIONS_DATA,
  };
}

export function resetCommunicationsFilters() {
  return {
    type: RESET_COMMUNICATIONS_FILTERS,
  };
}

export function resetCommunicationsPagination() {
  return {
    type: RESET_COMMUNICATION_PAGINATION_DATA,
  };
}

export function fetchCommunications(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().communications.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const communicationResponse = await CommunicationsAPI.getCommunications(params);
      if (communicationResponse && communicationResponse.data && communicationResponse.data.content) {
        const formattedCommunications = _.map(communicationResponse.data.content, communication => formatter.formatInputData(formatter.COMMUNICATION, communication));
        dispatch(saveCommunications(formattedCommunications));
        dispatch(saveCommunicationsPaginationData(_.omit(communicationResponse.data, 'content')));
        return communicationResponse.data.content;
      }
      throw new Error();

    } catch (error) {
      throw error;
    }
  }
}

export function fetchAndAppendCommunications(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().communications.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const communicationResponse = await CommunicationsAPI.getCommunications(params);
      if (communicationResponse && communicationResponse.data && communicationResponse.data.content) {
        const formattedCommunications = _.map(communicationResponse.data.content, communication => formatter.formatInputData(formatter.COMMUNICATION, communication));
        dispatch(appendCommunications(formattedCommunications));
        dispatch(saveCommunicationsPaginationData(_.omit(communicationResponse.data, 'content')));
        return communicationResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllCommunicationsForExport(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().communications.data.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'creatingCardsExportFile',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        ...filters,
      };
      let communicationsResponse = await CommunicationsAPI.getCommunications(params);
      if (communicationsResponse && communicationsResponse.data && communicationsResponse.data.content && !_.isEmpty(communicationsResponse.data.content)) {
        const communications = [...communicationsResponse.data.content];
        let pagination = _.omit(communicationsResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          communicationsResponse = await CommunicationsAPI.getCommunications(params);
          communications.push(...communicationsResponse.data.content);
          pagination = _.omit(communicationsResponse.data, 'data');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { communications, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function createCommunication(communicationData) {
  return async (dispatch, getState) => {
    try {
      const formattedItemOutDTO = formatter.formatOutputData(formatter.COMMUNICATION, communicationData);
      const createResponse = await CommunicationsAPI.createCommunication(formattedItemOutDTO);
      if (createResponse && createResponse.data) {
        return createResponse.data;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function updateCommunication(communicationId, communicationData) {
  return async (dispatch, getState) => {
    try {
      const formattedItemOutDTO = formatter.formatOutputData(formatter.COMMUNICATION, communicationData);
      const updateResponse = await CommunicationsAPI.updateCommunication(communicationId, formattedItemOutDTO);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteCommunication(communicationId) {
  return async (dispatch, getState) => {
    try {
      const deleteResponse = await CommunicationsAPI.deleteCommunication(communicationId);
      if (deleteResponse && deleteResponse.data) {
        return deleteResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function getCommunicationDetails(communicationId) {
  return async (dispatch, getState) => {
    try {
      const detailsResponse = await CommunicationsAPI.getCommunicationDetails(communicationId);
      if (detailsResponse && detailsResponse.data) {
        return detailsResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function exportCommunications(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { communications, stopFetching } = await dispatch(fetchAllCommunicationsForExport());
      if (!stopFetching) {
        const exportData = [];
        _.each(communications, (communication) => {
          exportData.push({
            ID: communication.id,
            Name: communication.name,
            Description: communication.description,
            'Dates': communication.fromDate && communication.toDate ? `${moment(communication.fromDate).format('DD MMMM YYYY')} - ${moment(communication.toDate).format('DD MMMM YYYY')}` : '---',
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { communications: ws }, SheetNames: ['communications'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Communication_Export.${fileExtension}`);
        return communications;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function setCommunicationsViewMode(viewMode) {
  saveDataToLocalStorage('communicationsViewMode', viewMode);
}