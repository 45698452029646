import qs from 'qs';
import { axiosInstance } from './rest';
import axios from 'axios';

let guestsCSVCancelToken;
let guestsXLSXCancelToken;

export function fetchUsers(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/users', restParams);
}

export function exportGuestsXLSX(params) {
  guestsXLSXCancelToken = axios.CancelToken.source();
  const config = {
    params,
    timeout: 0,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
    cancelToken: guestsXLSXCancelToken.token,
  };
  return axiosInstance.get('/api/v2/users/', config);
}

export function exportGuestsCSV(params) {
  guestsCSVCancelToken = axios.CancelToken.source();
  const config = {
    params,
    timeout: 0,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
    cancelToken: guestsCSVCancelToken.token,
  };
  return axiosInstance.get('/api/v2/users/csv', config);
}

export function importGuestsCSV(csvFile) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 0,
  };
  const fileData = new FormData();
  fileData.append('file', csvFile);
  return axiosInstance.post('/api/v2/users/import/csv', fileData, config);
}

export function getGuestCSVCancelToken() {
  return guestsCSVCancelToken;
}

export function getGuestXLSXCancelToken() {
  return guestsXLSXCancelToken;
}
