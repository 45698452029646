// @ts-nocheck
import React from 'react';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from '@sketchpixy/rubix';
import { Input } from 'antd';
import { IconButton } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { BLUE } from '../../../../_config/consts';

export default class TextInputInlineField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isHover: false,
      editedValue: null, 

    };
  }
  handleChange(value) {
    const { onHandleChange, input } = this.props;
    if (onHandleChange) onHandleChange(value);
    input.onChange(value);
    setTimeout(() => this.setState({ editedValue: value }), 500);
  }

  handleKeyDown(event) {
    const { onSubmit } = this.props;
    if (event.keyCode === 13) {
      this.onSubmitInput();
    }
  }

  onBlurInput() {
    this.onSubmitInput();
  }

  onSubmitInput() {
    const { meta, onSubmit } = this.props;
    const { editedValue } = this.state;
    if (meta.error) {
      this.setState({ isErrorState: true });
    } else {
      this.setState({ isEditing: false, isHover: false });
      if (editedValue) {
        onSubmit();
        this.setState({ editedValue: null });
      }
    }
  }

  render() {
    const { input, meta, disabled, showErrorLabel, placeholderStyle, containerStyle, placeholder, hideEditIcon } = this.props;
    const { isEditing, isHover } = this.state;
    let validationState;
    if (meta.error) {
      validationState = 'error';
    } else {
      validationState = 'success';
    }
    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...containerStyle }}
        onMouseEnter={!disabled ? () => this.setState({ isHover: true }) : null}
        onMouseLeave={!disabled ? () => this.setState({ isHover: false }) : null}
      >
        {isEditing ? (
          <div
            onBlur={() => this.onBlurInput()}
            onKeyDown={e => this.handleKeyDown(e)}
          >
            <FormGroup
              controlId="formBasicText"
              validationState={validationState}
              style={{ marginBottom: 0, paddingBottom: 0 }}
            >
              <FormControl
                type="text"
                value={input.value}
                onChange={e => this.handleChange(e.target.value)}
                style={{ fontSize: 24 }}
                {...this.props}
              />
              {showErrorLabel && meta.error && <h6 style={{ color: 'red', marginTop: 4, marginBottom: 0 }}><Entity entity={meta.error} /></h6>}
            </FormGroup>
          </div>
        ) : (
          <div
            onClick={disabled ? null : () => this.setState({ isEditing: true })}
            className={disabled ? '' : 'input-inline'}
          >
            <h3 style={{ fontWeight: 'bold', color: isHover ? '#3661ff' : '#3f3f3f', textDecoration: isHover ? 'underline' : 'none', margin: 0, ...placeholderStyle }}>{input.value || placeholder}</h3>
          </div>
        )}
        {!disabled && !hideEditIcon ? (
          <IconButton style={{ height: 30, width: 30, marginBottom: 0, marginLeft: 10 }} onClick={() => this.setState({ isEditing: !isEditing })}>
            <Edit style={{ fontSize: 17 }} />
          </IconButton>
        ) : null}
      </div>
    );
  }
}