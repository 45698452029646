import React from 'react';
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Checkbox,
} from '@sketchpixy/rubix';
import { CirclePicker } from 'react-color';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

export default class ColorSelectorField extends React.Component {
  render() {
    const { input, meta, name, title,
      id, placeholder, color,
      helpText, mandatory, active } = this.props;
    let validationState;
    if (meta.touched) {
      if (meta.error) {
        validationState = 'error';
      } else {
        validationState = 'success';
      }
    }
    return (
      <FormGroup controlId={id} validationState={validationState}>
        <ControlLabel style={{ color: '#3f3f3f', fontSize: 16 }}>{title}{mandatory ? <span style={{ color: '#D71F4B' }}>*</span> : ''}</ControlLabel>
        <CirclePicker
          color={color}
          width="100%"
          {...input}
        />
        {helpText ? <HelpBlock>{helpText}</HelpBlock> : null}
        {meta.touched && meta.error && (
          <ControlLabel style={{ color: 'deepred' }}>
            <h6 style={{ color: 'red', marginTop: 4, marginBottom: 0 }}>
              <Entity key={meta.error} entity={meta.error} />
            </h6>
          </ControlLabel>
        )}
      </FormGroup>
    );
  }
}
