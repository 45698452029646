import Card from '@material-ui/core/Card';
import GatewayIcon from '@material-ui/icons/Cast';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';
import React from 'react';
import { formatGatewayVersion, getHyperGateImage } from '../../_config/utils';
import { HYPERGATE_DEVICE_TYPES } from '../../_config/consts';

export default class GatewayRow extends React.Component {
  render() {
    const { gateway: { name, deviceId, lastConnectionDate, swVersion, kernelVersion, iseoSdkVersion, deviceType, deviceVersion }, onClick, isSelected } = this.props;
    const cardClassName = isSelected ? 'card-selected' : '';
    const difference = lastConnectionDate ? moment(moment().valueOf()).diff(moment(lastConnectionDate), 'minutes') : 0;
    const isWarning = difference > 5;
    return (
      <Card onClick={() => onClick()} elevation={2} style={{ borderRadius: 10, padding: 30, paddingLeft: 20, borderLeft: `solid 4px ${isWarning ? 'red' : 'transparent '}` }} className={`card card-rounded ${cardClassName}`} >
        <div className="card-body">
            <div className="rounded-view" >
              <img className="lock-icon" style={{ marginTop: 0, width: '100%', height: '100%', borderRadius: '50%' }} src={getHyperGateImage(deviceType)} />
            </div>
          <div className="card-inner-body">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <h4 className="card-title" style={{ margin: 0, color: '#3f3f3f', whiteSpace: 'pre-wrap' }}>{name}</h4>
            </div>
            {deviceId ? <h5 className="card-title" style={{ marginBottom: 0, fontSize: 16, marginTop: 10, color: '#3f3f3f', fontWeight: 'normal', marginLeft: 0, whiteSpace: 'pre-wrap' }}>{deviceId}</h5> : null}
              <div style={{ marginTop: 5, marginBottom: 5, display: 'flex', marginLeft: 2, alignItems: 'center' }}>
                <h4 style={{ fontSize: 16, margin: 0, color: '#3f3f3f' }}><Entity entity="firmwareVersion" />: </h4>
                <h4 style={{ fontSize: 16, margin: 0, color: '#3f3f3f', marginLeft: 2  }}>{swVersion ? swVersion : formatGatewayVersion(deviceVersion)}</h4>
              </div>
              {lastConnectionDate && (
                <div style={{ display: 'flex', marginLeft: 2, alignItems: 'center' }}>
                  <h4 style={{ margin: 0, fontSize: 16, color: '#3f3f3f' }}><Entity entity="lastConnectionDate" /></h4>
                  <h4 style={{ margin: 0, fontSize: 16, color: '#3f3f3f' }}>{`: ${moment(lastConnectionDate).format('LLL')}`}</h4>
                  {isWarning && <WarningIcon style={{ marginLeft: 5, width: 20, color: 'red' }} />}
                </div>
              )}
            </div>
        </div>
      </Card>
    );
  }
}
