
import { Card, createTheme, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Domain } from '@material-ui/icons';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React, { Component } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { BLUE } from '../../_config/consts';
import { getPlantName } from '../../_config/utils.js';
import TreeNodeCustom from '../../routes/TreeNodes/TreeNode.jsx';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '99.9%',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTextIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  icon: {
    fontSize: 40,
    color: '#3f3f3f',
  },
  countText: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 35,
  },
  progress: {
    margin: 0,
    marginTop: 0,
    padding: 0,
    left: '50%',
    position: 'absolute',
    height: '38px !important',
    width: '38px !important',
    zIndex: 10000,
  },
  headerText: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
  },
  chartContainer: {
    width: '100%',
    maxHeight: 400,
    overflowY: 'scroll',
    marginTop: 30,
  },
  refreshHeader: {
    position: 'absolute',
    right: 10,
    top: 5,
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  refreshIcon: {
    fontSize: 30,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '30%',
    marginTop: 10,
    borderRadius: 5,
  },
  guestRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  tableColumnText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});


class SubcompaniesOrganizationChart extends Component {

  render() {
    const { subcompanies, userDomains, classes, textColor, onTreeNodeClick  } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container} elevation={3}>
          <div className={classes.header}>
            <h3 className={classes.titleText} style={{ color: textColor || '#3f3f3f' }}><Entity entity="systemHierarchy" /></h3>
          </div>
          <div className={classes.chartContainer}>
            <Tree
              label={(
                <TreeNodeCustom
                  title={getPlantName()}
                  isRoot
                  containerStyle={{ width: 'fit-content', margin: 'auto'}}
                  icon={<Domain style={{ fontSize: 22, color: 'white' }} />}
                />
              )}
            >
              {_.map(subcompanies, subCompany => {
                const canChangeDomain = _.find(userDomains, domain => domain.hostName === subCompany.hostname)
                return (
                  <TreeNode
                    label={(
                      <TreeNodeCustom
                        title={subCompany.plantName}
                        canChangeDomain={canChangeDomain}
                        subtitle={canChangeDomain ? <Entity entity="youreAdminForDomain" /> : <Entity entity="yourCannotNavigateDomain" />}
                        onTreeNodeClick={canChangeDomain ? () => onTreeNodeClick(subCompany) : null}
                        icon={<Domain style={{ fontSize: 22, color: canChangeDomain ? 'white' : '#3f3f3f' }} />}
                      />
                    )}
                  />
                )}
              )}
            </Tree>
          </div>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(SubcompaniesOrganizationChart);
