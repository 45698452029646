import TermsIcon from '@material-ui/icons/AccountBox';
import PrivacyIcon from '@material-ui/icons/VerifiedUser';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import * as AgreementsActions from '../../../redux/actions/agreements.actions';
import { AGREEMENT_DEFAULT_LINKS, AGREEMENT_TYPES, BLUE, LANGUAGE_DETAILS } from '../../../_config/consts';
import FormCardContainer from '../../forms/Elements/FormCardContainer.jsx';


@connect(state => ({ language: state.settings.language }))
class PrivacyAndTermsOperationalSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: null,
      privacyUrl: null,
      termsUrl: null,
    };
  }

  async componentWillMount() {
    const { language, dispatch } = this.props;
    let selectedLanguage = 'en';
    if (language) {
      selectedLanguage = LANGUAGE_DETAILS[language].lang;
    }
    const languageSuffix = selectedLanguage === 'it' ? 'it' : 'en';
    try {
      const latestPrivacyAgreements = await dispatch(AgreementsActions.fetchLatestAgreements({ type: AGREEMENT_TYPES.PRIVACY }));
      const latestTermsAgreements = await dispatch(AgreementsActions.fetchLatestAgreements({ type: AGREEMENT_TYPES.TERM }));
      this.setState({ selectedLanguage, privacyUrl: `${latestPrivacyAgreements[0].link}/${languageSuffix}`, termsUrl: `${latestTermsAgreements[0].link}/${languageSuffix}` });
    } catch (error) {
      const termsUrl = `${AGREEMENT_DEFAULT_LINKS.TERM}/${languageSuffix}`;
      const privacyUrl = `${AGREEMENT_DEFAULT_LINKS.PRIVACY}/${languageSuffix}`;
      this.setState({ selectedLanguage, privacyUrl, termsUrl });
    }
  }

  render() {
    const {privacyUrl, termsUrl } = this.state;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        <FormCardContainer
          title="privacyAndTermsTitle"
          subtitle="privacyAndTermsSubtitle"
        >
          <div>
            <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <PrivacyIcon style={{ fontSize: 30, marginRight: 20 }} />
              <h4 style={{ margin: 0, marginLeft: 5 }}>
                <a
                  style={{ color: BLUE, cursor: 'pointer', textDecoration: 'underline' }}
                  href={privacyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Entity entity="privacyUrl" />
                </a>
              </h4>
            </div>
            <div style={{ marginTop: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <TermsIcon style={{ fontSize: 30, marginRight: 20 }} />
              <h4 style={{ margin: 0, marginLeft: 5 }}>
                <a
                  style={{ color: BLUE, cursor: 'pointer', textDecoration: 'underline' }}
                  href={termsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Entity entity="termsUrl" />
                </a>
              </h4>
            </div>
          </div>
        </FormCardContainer>
      </div>
    );
  }
}

export default PrivacyAndTermsOperationalSection;