import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import * as GatewaysActions from '../../redux/actions/gateways.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import GatewayRow from './GatewayRow.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import EmptyView from '../EmptyView.jsx';


@connect((state) => ({ themeName: state.settings.items.theme.data.themeName }))
class GatewaysListView extends React.Component {

  componentWillUnmount() {
    this.closeOperationalSection();
  }

  appendGateways(page) {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.fetchAndAppendGatewaysWithDetails(page));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(GatewaysActions.setOperationalMode(false));
  }

  render() {
    const { gateways: { content: gatewaysData, pagination }, viewLoading, onOpenGatewayLandingView, selectedGateway, listContainerStyle, onSelectGateway } = this.props;
    return (
      <div className="list-view details-section" style={{ top: 140, paddingBottom: 60, width: '90%', ...listContainerStyle }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => this.appendGateways(pagination.number + 1)}
          hasMore={pagination.number + 1 < pagination.totalPages}
          loader={<ListLoadingView />}
          useWindow={false}
        >
          <div style={{ width: '40%' }}>
            {!_.isEmpty(gatewaysData) ?
            _.map(gatewaysData, gateway =>
              <GatewayRow
                key={gateway.id}
                gateway={gateway}
                isSelected={selectedGateway && selectedGateway.id === gateway.id}
                onClick={() => onSelectGateway(gateway)}
              />)
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title="noGatewayFound"
                    subtitle="startAddGateway"
                    iconName="icon-simple-line-icons-layers"
                    newEntityTitle="discoverMore"
                    onNewEntity={() => onOpenGatewayLandingView()}
                  />) : <ListLoadingView />}
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
} 

export default GatewaysListView;
