import React from 'react';
import { connect } from 'react-redux';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import MDButton from '../../components/MDButton/MDButton.jsx';
import * as SettingsActions from '../../redux/actions/setting.actions';
import BasicSSOButton from '../ButtonSSO/BasicButtonSSO.jsx';

@connect((state) => ({ settings: state.settings }))
class LoginWithSAML extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(SettingsActions.fetchSAMLRequest());
  }

  render() {
    const { idp, settings } = this.props;
    if (!settings.samlRequestConfig || !settings.samlRequestConfig.idpUrl) {
      return null;
    }

    const idpUrl = new URL(settings.samlRequestConfig.idpUrl);
    idpUrl.searchParams.set('RelayState', 'WEB_PORTAL');

    return (
      <form
        method="POST"
        action={idpUrl}
      >
        <input type="hidden" name="SAMLRequest" value={settings.samlRequestConfig.encodedRequest} />
        <input type="hidden" name="RelayState" value="WEB_PORTAL" />
        <BasicSSOButton
          type="submit"
          idp={idp}
        />
      </form>
    );
  }
} 

export default LoginWithSAML;
