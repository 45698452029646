import React from 'react';
import _ from 'lodash';
import {
  Form,
} from '@sketchpixy/rubix';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

import CustomField from '../Fields/CustomField.jsx';

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  }
  return errors;
};


@reduxForm({ form: 'ResourceTypeForm', validate })
@connect(state => ({ form: state.form.ResourceTypeForm }))
class ResourceTypeForm extends React.Component {
  render() {
    return (
      <Form>
        <Entity
          componentClass={Field}
          name="name"
          componentAttribute="placeholder"
          entity="insertName"
          title={<Entity entity="typeName" />}
          component={CustomField}
          mandatory
        />
      </Form>
    );
  }
} 

export default ResourceTypeForm;
