import _ from 'lodash';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';

const validate = values => {
  const errors = {};

  if (!values.business) {
    errors.business = 'required';
  }
  return errors;
};

@reduxForm({ form: 'Office365SelectCalendarForm', validate })
class Office365SelectCalendarForm extends React.Component {
  
  render() {
    const { calendars } = this.props;
    const calendarsOptions = _.map(calendars, calendar => ({
      name: calendar.name,
      id: calendar.id,
      type: { name: 'Calendar' },
    }));
    return (
      <div>
        <Field
          name="calendars"
          component={SelectableRowField}
          isSingleChoice
          options={{
            content: calendarsOptions,
          }}
          listContainerStyle={{ marginTop: 0 }}
          listStyle={{ marginTop: 0 }}
        />
      </div>
    );
  }
} 

export default Office365SelectCalendarForm;
