import React from 'react';
import { Field, reduxForm, initialize, submit } from 'redux-form';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Cancel from '@material-ui/icons/Cancel';
import StarIcon from '@material-ui/icons/StarBorder';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { connect } from 'react-redux';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import { PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts';
import TagsSelectorField from '../Fields/TagsSelectorField.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import { hasFormWritePermission } from '../../../_config/utils.js';
import BasicModal from '../../Modals/BasicModal.jsx';
import TagForm from '../TagForm.jsx';
import * as TagActions from '../../../redux/actions/tag.actions';
import * as LockActions from '../../../redux/actions/lock.actions';
import * as ModalActions from '../../../redux/actions/modal.actions';
import TagsExplanationView from '../../TagsManagement/TagsExplanationView.jsx';

@connect((state) => ({ lock: state.locks.selectedLock, lockTags: state.tags.lock }))
@reduxForm({ form: 'LockTagsForm' })
class LockTagsForm extends React.Component {

  onShowLockTagsInfo() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="locksTagsInfoTitle" />,
        content: <TagsExplanationView tagType="locks" />,
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  render() {
    const { onLockInputChange, lockTags, dispatch, onShowTagModal } = this.props;
    const canEditLock = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.SMART_LOCK);
    const canCreateLockTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.SMART_LOCK_TAG);
    const canEditLockTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.SMART_LOCK_TAG);
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
          <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>{<Entity entity="lockSpecialTag" />}</h4>
          <IconButton onClick={() => this.onShowLockTagsInfo()}>
            <InfoIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
          </IconButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Entity
            componentClass={Field}
            multi
            name="tags"
            componentAttribute="placeholder"
            entity="insertLockTags"
            disabled
            component={TagsSelectorField}
            tagType="LOCK"
            tags={lockTags.data}
            isDisabled={!canEditLock}
            onNewTag={canCreateLockTags ? values => onShowTagModal(values, 'NEW') : null}
            onEditTag={canEditLockTags ? values => onShowTagModal(values, 'EDIT') : null}
            onInputChange={onLockInputChange}
            containerStyle={{ width: '100%', margin: 0 }}
          />
          {canEditLock ? (
            <MDButton
              title={<Entity entity="save" />}
              disabled={!hasFormWritePermission(PERMISSION_ENTITIES.SMART_LOCK, true)}
              style={{ height: 35, borderRadius: 5 }}
              containerStyle={{ margin: 10 }}
              onClick={() => dispatch(submit('LockTagsForm'))}
            />
          ) : null}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
          <StarIcon style={{ fontSize: 17, color: '#3f3f3f', marginRight: 5 }} />
          <h5 style={{ color: '#3f3f3f', fontSize: 13, fontWeight: '200', margin: 0 }}><Entity entity="lockSpecialTagDescription" /></h5>
        </div>
      </div>
    );
  }
} 

export default LockTagsForm;