import React from 'react';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { RESOURCE_IMAGE_MAP } from './../../../../_config/consts';
import Card from '@material-ui/core/Card';

const SelectableRow = ({ title, name, type, onClick, style, isChecked, resourceMedia, hasError, customOptionElement, resourceImage, rowStyle }) => {
  let backgroundColor =  hasError ? 'rgb(255, 109, 123)' : 'white';
  backgroundColor = isChecked ? 'aliceblue' : backgroundColor;
  let imageSource = resourceImage && RESOURCE_IMAGE_MAP[resourceImage] ? RESOURCE_IMAGE_MAP[resourceImage] : null;
  imageSource = resourceMedia ? `data:image/jpeg;base64,${resourceMedia}` : imageSource;
  return (
    <Card
      className="selectable-row"
      onClick={() => onClick()}
      style={{ margin: 15, padding: 10, backgroundColor: backgroundColor, display: 'flex', alignItems: 'center', maxHeight: 70, borderRadius: 10, ...rowStyle }}
    >
      <div className="resourceSelectableRow-header">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {imageSource ? <img src={imageSource} style={{ width: 'auto', height: 45 }} /> : null}
          <div className="resourceInfoRow-titleType">
            {customOptionElement ?
              <div>{customOptionElement}</div>
              :
              <div>
                {title ? <h4 style={{ color: '#3f3f3f', fontWeight: 'bold' }}>{title}</h4> : null}
                {name ? <h4 style={{ color: '#3f3f3f', fontWeight: 'bold' }}>{name}</h4> : null}
                {type ? <h5 style={{ color: '#3f3f3f', marginTop: 5 }}>{type}</h5> : null}
              </div>
            }
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
          {isChecked ?
            <CheckIcon style={{ fontSize: 30, marginRight: 25, color: 'green', height: 53 }} />
            :
            <div style={{ display: 'flex', alignItems: 'center', height: 53 }}>
              <div style={{ width: 25, height: 25, borderRadius: 12, borderStyle: 'solid', borderWidth: 2, borderColor: !hasError ? 'gray' : 'red', marginRight: 27 }} />
            </div>
          }
        </div>
      </div>
    </Card>
  );
};

export default SelectableRow;
