import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SET_SELECTED_GATEWAY,
  SAVE_GATEWAYS,
  APPEND_GATEWAYS,
  SAVE_GATEWAYS_PAGINATION,
  UPDATE_GATEWAY,
  REMOVE_GATEWAY,
  RESET_GATEWAYS_DATA,
  SET_GATEWAYS_FILTER,
  RESET_GATEWAYS_FILTERS,
  SET_GATEWAYS_OPERATIONAL_MODE,
  SET_SELECTED_GATEWAY_NOTIFICATION,
  SAVE_WIRED_GATEWAYS,
  APPEND_WIRED_GATEWAYS,
  SAVE_WIRED_GATEWAYS_PAGINATION,
  SET_SELECTED_WIRED_GATEWAY,
  RESET_WIRED_GATEWAYS_DATA,
  SET_WIRED_GATEWAYS_FILTER,
  RESET_WIRED_GATEWAYS_FILTERS,
  UPDATE_WIRED_GATEWAY,
  RESET_GATEWAYS_PAGINATION_DATA,
  SET_GATEWAYS_ORDER
} from '../actions/actionTypes/gateways';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {},
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    }
  },
  wired: {
    content: [],
    pagination: {},
    filters: {},
    selectedWiredGateway: null,
  },
  selectedGateway: null,
  notifications: {
    selectedGatewayNotification: null,
  },
  isOperationalMode: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_GATEWAYS:
      return state.setIn(['data', 'content'], action.gateways);
    case APPEND_GATEWAYS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.gateways]);
    case RESET_GATEWAYS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    case SAVE_WIRED_GATEWAYS:
      return state.setIn(['wired', 'content'], action.gateways);
    case APPEND_WIRED_GATEWAYS:
      return state.setIn(['wired', 'content'], [...state.wired.content, ...action.gateways]);
    case SAVE_WIRED_GATEWAYS_PAGINATION:
      return state.setIn(['wired', 'pagination'], action.pagination);
    case SET_WIRED_GATEWAYS_FILTER:
      return state.setIn(['wired', 'filters', action.field], action.value);
    case SET_SELECTED_WIRED_GATEWAY:
      return state.setIn(['wired', 'selectedWiredGateway'], action.gateway);
    case RESET_WIRED_GATEWAYS_DATA:
      return state.setIn(['wired', 'content'], {});
    case RESET_WIRED_GATEWAYS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case REMOVE_GATEWAY: {
      const gateways = _.filter(state.data.content, gatewayState => gatewayState.id !== action.gatewayId);
      return state.setIn(['data', 'content'], gateways);
    }
    case UPDATE_GATEWAY: {
      const index = _.findIndex(state.data.content, data => data.id === action.gateway.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.gateway);
      }
      return state.setIn(['data', 'content'], [...state.data.content, action.gateway]);
    }
    case UPDATE_WIRED_GATEWAY: {
      const index = _.findIndex(state.wired.content, data => data.deviceId === action.gateway.deviceId);
      if (index !== -1) {
        return state.setIn(['wired', 'content', index], action.gateway);
      }
      return state.setIn(['wired', 'content'], [...state.wired.content, action.gateway]);
    }
    case SAVE_GATEWAYS_PAGINATION:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SET_GATEWAYS_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case SET_GATEWAYS_ORDER:
      return state.setIn(['data', 'sorting', 'orderBy'], action.orderBy)
                  .setIn(['data', 'sorting', 'orderDir'], action.orderDir);
    case RESET_GATEWAYS_FILTERS:
      return state.setIn(['data', 'filters'], {});
    case RESET_GATEWAYS_DATA:
      return state.setIn(['data', 'content'], []);
    case SET_SELECTED_GATEWAY:
      return state.set('selectedGateway', action.gateway);
    case SET_GATEWAYS_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_SELECTED_GATEWAY_NOTIFICATION:
      return state.setIn(['notifications', 'selectedGatewayNotification'], action.notification);
    default: return state;
  }
}
