
import { IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { BLUE, GREEN, RED } from '../../_config/consts';
import { formatGatewayVersion, getColorFromThemeName, getHyperGateImage } from '../../_config/utils';
import SortIconCustom from '../CustomIcons/SortIconCustom.jsx';
import UnsortIconCustom from '../CustomIcons/UnsortIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import Tag from '../Tag.jsx';

import * as GatewaysActions from '../../redux/actions/gateways.actions';
import SortAscendingIconCustom from '../CustomIcons/SortAscendingIconCustom.jsx';
import SortDescendingIconCustom from '../CustomIcons/SortDescendingIconCustom.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumnHeaderText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeaderArrow: {
    padding: 10,
    marginLeft: 10,
  },
  tableColumnHeaderArrowIcon: {
    fontSize: 20,
    width: 20,
  },
  tableColumnHeaderSortIcon: {
    width: 24,
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    fontSize: 10,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: BLUE,
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    marginRight: 10,
    width: 'max-content',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lockRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 65,
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  rowBatteryLevel: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    justifycontent: 'center',
    alignItems: 'center',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, gateways: state.gateways, themeName: state.settings.items.theme.data.themeName }))
class GatewaysTableView extends React.Component {
  
  async handleOrderChange(column) {
    const { onOrderChanged, dispatch } = this.props;
    const { gateways: { data: { sorting: {orderBy, orderDir} } } } = this.props;
    const orderDir_new = orderBy===column?(orderDir==='ASC'?'DESC':'ASC'):'ASC';
    await dispatch(GatewaysActions.setOrder(column, orderDir_new));
    onOrderChanged()
  };

  async onResetOrder() {
    const { onOrderChanged, dispatch } = this.props;
    await dispatch(GatewaysActions.setOrder(undefined, undefined));
    onOrderChanged()
  }

  render() {
    const { classes, viewLoading, gateways: { selectedGateway, data: { content: gatewaysData, pagination: { number: currentPage, size, totalPages, totalElements }, sorting: {orderBy, orderDir} } }, themeName, onFetchGatewaysOnPage, onSelectGateway, onRefreshGateways, onOpenGatewayLandingView } = this.props;
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(gatewaysData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell colSpan={1}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}>{'HyperGates'}</h3>
                        <IconButton onClick={() => onRefreshGateways()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                        {orderBy && 
                          <IconButton onClick={() => this.onResetOrder()} style={{ marginLeft: 5, marginTop: 5 }}>
                            <UnsortIconCustom style={{ color: 'white', width: 25 }} />
                          </IconButton>
                        }
                      </div>
                    </TableCell>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="gatewaysTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchGatewaysOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          lastPage={totalPages - 1}
                          iconColor="white"
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchGatewaysOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="name" /></h4>
                        {orderBy === 'name'?(
                          <IconButton onClick={() => this.handleOrderChange('name')} style={{opacity: 1}} className={classes.tableColumnHeaderArrow}>
                            {orderDir==='ASC'||orderBy!=='name'? <SortDescendingIconCustom className={classes.tableColumnHeaderArrowIcon}/> : <SortAscendingIconCustom className={classes.tableColumnHeaderArrowIcon}/>}
                          </IconButton>
                        ):(
                          <IconButton onClick={() => this.handleOrderChange('name')} style={{opacity: 0.6}} className={classes.tableColumnHeaderArrow}>
                            {<SortIconCustom className={classes.tableColumnHeaderSortIcon}/>}
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="gatewayDeviceType" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="locks" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="firmwareVersion" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="hyperGateIseoSdkVersion" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="lastConnectionDate" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(gatewaysData, (gateway) => {
                    const difference = gateway.lastConnectionDate ? moment(moment().valueOf()).diff(moment(gateway.lastConnectionDate), 'minutes') : 0;
                    const statusColor = difference < 5 ? GREEN : RED;
                    const gatewaysSmartLocks = gateway.smartLocks;
                    return (
                      <TableRow
                        key={gateway.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={event => onSelectGateway(gateway)}
                        selected={selectedGateway && selectedGateway.id === gateway.id}
                      >
                        <TableCell>
                          <div className={classes.lockRowContainer}>
                            <img alt="gateway" className="lock-icon" style={{ width: 45, height: 45, marginRight: 15 }} src={getHyperGateImage(gateway.deviceType)} />
                            <div style={{ marginRight: 10 }}>
                              <div>
                                <h5 style={{ fontWeight: 'bold', margin: 0, fontSize: 19 }}>{gateway.name}</h5>
                                <h5 style={{ fontSize: 15, margin: 0, marginTop: 10 }}>{gateway.deviceId}</h5>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {gateway.deviceType ? (
                            <h5 style={{ fontSize: 15, margin: 0 }}><Entity entity="gatewayDeviceTypes" data={{ type: gateway.deviceType }} />{gateway.highFlex ? ' - High Flex' : null}</h5>)
                            : '---'}
                        </TableCell>
                        <TableCell>
                          {gatewaysSmartLocks && !_.isEmpty(gatewaysSmartLocks) ? (
                            <div className="tags-container">
                              {_.map(gatewaysSmartLocks, lock =>
                                <Tag
                                  tag={lock.lockTag}
                                  style={{ padding: 5 }}
                                  textStyle={{ fontSize: 14 }}
                                />)}
                            </div>
                          ): null}
                        </TableCell>
                        <TableCell>
                          <h5 style={{ fontSize: 15, margin: 0 }}>{gateway.swVersion ? gateway.swVersion : formatGatewayVersion(gateway.deviceVersion)}</h5>
                        </TableCell>
                        <TableCell>
                          <h5 style={{ fontSize: 15, margin: 0 }}>{gateway.iseoSdkVersion ? gateway.iseoSdkVersion : '---'}</h5>
                        </TableCell>
                        <TableCell>
                        {gateway.lastConnectionDate ? (
                          <div>
                            <h4 style={{ fontSize: 15, margin: 0, fontWeight: 'bold', color: statusColor }}>
                              {moment(gateway.lastConnectionDate).format('LLL')}
                            </h4>
                          </div>
                        ) : '---'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ background: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="gatewaysTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchGatewaysOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          iconColor="white"
                          onChangePage={(event, page) => onFetchGatewaysOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
            <>
              {viewLoading ? (
                <ListLoadingView />
              ) : (
                <EmptyView
                  title="noGatewayFound"
                  subtitle="startAddGateway"
                  iconName="icon-simple-line-icons-layers"
                  newEntityTitle="discoverMore"
                  onNewEntity={() => onOpenGatewayLandingView()}
                />
              )} 
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GatewaysTableView);
