import Card from '@material-ui/core/Card';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import LockIcon from '@material-ui/icons/LockOpen';
import UsersIcon from '@material-ui/icons/PeopleOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import Tag from '../../components/Tag.jsx';
import * as UtilActions from '../../redux/actions/utils.actions.js';
import { RESERVATION_STATUSES_PARAMETERS, RESERVATION_STATUSES, LANGUAGES } from '../../_config/consts.js';
import SmartphoneCredentialIcon from '@material-ui/icons/PhonelinkLock';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';

@connect((state) => ({ language: state.settings.language }))
class CredentialDetailsRow extends React.Component {
  render() {
    const { key, credential, onClick, isSelected, language, dispatch } = this.props;
    const days = credential && credential.daysOfTheWeek && _.orderBy(credential.daysOfTheWeek);
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
      selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
    }));
    const isInvalidNoTags = _.isEmpty(credential.lockTags) || _.isEmpty(credential.guestTags);
    const credentialStatus = isInvalidNoTags ? RESERVATION_STATUSES.NO_TAGS : credential.status;
    const validityDateFormat = dispatch(UtilActions.getCredentialValidityDateFormat());
    
    return (
      <Card
        key={key}
        className={`card card-rounded ${isSelected && 'card-selected'}`}
        style={{ borderRadius: 10, opacity: isInvalidNoTags ? 0.5 : 1, marginBottom: 20, position: 'relative', borderLeft: `4px solid ${RESERVATION_STATUSES_PARAMETERS[credentialStatus].color}`, padding: 0 }}
        onClick={() => onClick()}
        elevation={2}
      >
        <div className="card-header access-card-header" style={{ paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
          <div style={{ marginLeft: 15, paddingTop: 10, maxWidth: '45%' }}>
            <h2 className="card-title" style={{ wordBreak: 'break-all', whiteSpace: 'unset', fontSize: 20, marginTop: 5, marginLeft: 0, marginBottom: 0, color: '#3f3f3f' }}>{credential.name}</h2>
            {credential.description && <h5 className="card-title" style={{ wordBreak: 'break-all', whiteSpace: 'unset', marginBottom: 0, marginTop: 5, fontStyle: 'italic', color: '#3f3f3f', fontWeight: 'normal', marginLeft: 0 }}>{credential.description}</h5>}
          </div>
          {credential.credentialDateFrom && credential.credentialDateTo && (
            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h6 style={{ color: '#3f3f3f', fontWeight: 'normal', margin: 0, marginLeft: 5 }}>
                  {`${moment(credential.credentialDateFrom).format(validityDateFormat)} - ${moment(credential.credentialDateTo).format(validityDateFormat)}`}
                </h6>
                <h6 style={{ color: '#3f3f3f', fontWeight: 'normal', margin: 0, marginLeft: 5 }}>
                  {`| ${moment(credential.timeIntervalFrom).format('LT')} - ${moment(credential.timeIntervalTo).format('LT')}`}
                </h6>
              </div>
              <div style={{ marginTop: 10, marginLeft: 5, display: 'flex', justifyContent: 'flex-end' }}>
                {_.map(daysOptions, option => (
                  <h6 key={option.label} style={{ margin: 0, marginRight: 5, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
                    {option.label.toUpperCase()}
                  </h6>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="separator" style={{ marginTop: 10 }} />
        <div className="card-body" style={{ paddingTop: 10, paddingBottom: 10, position: 'relative' }}>
          <h6 style={{ color: 'red', margin: 10, fontSize: 13, fontWeight: 'bold', marginTop: 0, textAlign: 'right', position: 'absolute', right: 0, top: 0 }}>
            <Entity key={`status_${credentialStatus}`} style={{ textTransform: 'uppercase', color: RESERVATION_STATUSES_PARAMETERS[credentialStatus].color }} entity={RESERVATION_STATUSES_PARAMETERS[credentialStatus].string} />
          </h6>
          <div className="card-inner-body" style={{ width: '100%' }}>
            <div className="card-tag-presentational-container">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <UsersIconCustom style={{ color: '#3f3f3f', width: 20 }} />
                <h4 style={{ margin: 0, fontSize: 16, color: '#3f3f3f', marginLeft: 5 }}>
                  <Entity entity="authorizedUsers" />
                </h4>
              </div>
              {_.isEmpty(credential.guestTags) ? (
                <div className="tags-container">
                  <h6 style={{ fontWeight: 'bold', margin: 0, marginLeft: 5, fontSize: 14, color: 'red' }}><Entity entity="emptyTagOrUser" /></h6>
                </div>
                ) : (
                  <div className="tags-container">
                    {_.map(credential.guestTags, tag =>
                      <Tag
                        key={tag.id}
                        tag={tag}
                      />,
                  )}
                  </div>
                )}
            </div>
            <div className="card-tag-presentational-container" style={{ marginTop: 10, marginRight: 17 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <LockIcon style={{ color: '#3f3f3f', fontSize: 20, marginRigtht: 2 }} />
                <h4 style={{ fontSize: 16, color: '#3f3f3f', margin: 0, marginLeft: 5 }}>
                  <Entity entity="selectedLocks" />
                </h4>
              </div>
              <div className="tags-container">
                {_.map(credential.lockTags, tag =>
                  <Tag
                    key={tag.id}
                    tag={tag}
                  />)}
              </div>
            </div>
          </div>
        </div>
        {credentialStatus && (
          <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
            <div className="credential-status-triangle-container" style={{ borderColor: `transparent transparent ${RESERVATION_STATUSES_PARAMETERS[credentialStatus].color} transparent` }}>
              <SmartphoneCredentialIcon style={{ color: 'white', position: 'absolute', fontSize: 18, bottom: 10, right: 10 }} />
            </div>
          </div>
        )}
      </Card>
    );
  }
} 

export default CredentialDetailsRow;
