import React from "react";
import moment from "moment";
import _ from 'lodash';

const TimeRangeView = ({ timeRange, daysContainerStyle, containerStyle }) => {
  const days = timeRange && timeRange.daysOfTheWeek && _.orderBy(timeRange.daysOfTheWeek);
  const daysOptions = _.map(moment.weekdays(true), weekday => ({
    label: moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday()),
    selected: _.indexOf(days, moment().isoWeekday(weekday).isoWeekday()) !== -1,
  }));
  return (
    <div style={{ ...containerStyle }}>
      <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 5 }}>{`${moment(timeRange.timeIntervalFrom).format('LT')} - ${moment(timeRange.timeIntervalTo).format('LT')}`}</h5>
      <div style={{ marginTop: 5, display: 'flex', justifyContent: 'flex-end', ...daysContainerStyle }}>
        {_.map(daysOptions, option => (
          <h6 key={option.label} style={{ margin: 0, marginRight: 7, color: option.selected ? '#3f3f3f' : 'gray', fontWeight: option.selected ? 'bold' : 'normal' }}>
            {option.label.toUpperCase()}
          </h6>
        ))}
      </div>
    </div>
  )
};

export default TimeRangeView;