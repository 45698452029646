import React from 'react';
import { withStyles } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import AnalyticsRuleExplanationTable from './AnalyticsRuleExplanationTable.jsx';
import { localizeHelpCenterLink } from '../../_config/utils.js';


const styles = theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  titleText: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'left',
  },
  sectionText: {
    margin: 0,
    marginRight: 5,
    fontSize: 16,
    lineHeight: 1.4,
    color: '#3f3f3f',
    textAlign: 'left',
  },
  exampleContainer: {
    marginTop: 15,
    borderRadius: 5,
  },
  exampleText: {
    marginLeft: 12,
    marginTop: 10,
    fontSize: 15,
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

const BookeyAnalyticsExplanationView = ({ classes }) => {
  const helpURL = localizeHelpCenterLink('bookey-analytics-and-reservation-rules');
  return (
    <div style={{ marginLeft: 13, marginBottom: 30, marginRight: 20 }}>
      <h2 className={classes.titleText}><Entity entity="penaltyHours" /></h2>
      <h2 className={classes.sectionText}><Entity entity="penaltyHoursExplanation" /></h2>
      <h2 className={classes.titleText}><Entity entity="usageHours" /></h2>
      <h2 className={classes.sectionText}><Entity entity="usageHoursExplanation" /></h2>
      <h2 className={classes.titleText}><Entity entity="reservationCheckinRules" /></h2>
      <AnalyticsRuleExplanationTable />
      <h5
        className="link-label"
        style={{ margin: 'auto', width: 'fit-content', marginTop: 20, fontSize: 16, textDecoration: 'underline' }}
        onClick={() => window.open(helpURL)}
      >
        <Entity entity="findOutMore" />
      </h5>
    </div>
  );
}

export default withStyles(styles)(BookeyAnalyticsExplanationView);
