import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import MDButton from '../../components/MDButton/MDButton.jsx';
import EmailSendIconCustom from '../CustomIcons/EmailSendIconCustom.jsx';

@connect((state) => ({ settings: state.settings }))
class LoginWithEmailOTP extends React.Component {
  
  onLoginWithEmailOTP() {
    const { dispatch } = this.props;
    dispatch(push('/loginOTPEmail'));
  }

  render() {
    const { idp, dispatch } = this.props;

    return (
      <MDButton
        type="submit"
        backgroundColor="#fff"
        title={L20NContext.getSync(idp.labelToken)}
        icon={<EmailSendIconCustom style={{ width: 20, marginLeft: 10, color: '#3f3f3f' }} />}
        containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
        titleStyle={{ color: '#3f3f3f', fontWeight: 'bold' }}
        onClick={() => this.onLoginWithEmailOTP()}
      />
    );
  }
} 

export default LoginWithEmailOTP;
