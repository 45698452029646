module.exports = {
  SAVE_GUESTS: 'SAVE_GUESTS',
  APPEND_GUESTS: 'APPEND_GUESTS',
  SAVE_GUEST: 'SAVE_GUEST',
  SELECT_GUEST: 'SELECT_GUEST',
  UPDATE_SELECTED_GUEST_TAG: 'UPDATE_SELECTED_GUEST_TAG',
  SET_GUEST_OPERATIONAL_MODE: 'SET_GUEST_OPERATIONAL_MODE',
  SET_GUESTS_PAGINATION_DATA: 'SET_GUESTS_PAGINATION_DATA',
  APPEND_GUEST_CREDENTIALS: 'APPEND_GUEST_CREDENTIALS',
  SAVE_GUEST_CREDENTIALS: 'SAVE_GUEST_CREDENTIALS',
  SET_GUEST_CREDENTIALS_PAGINATION_DATA: 'SET_GUEST_CREDENTIALS_PAGINATION_DATA',
  RESET_GUEST_CREDENTIALS_DATA: 'RESET_GUEST_CREDENTIALS_DATA',
  SET_GUEST_CREDENTIAL_LIST_FILTER: 'SET_GUEST_CREDENTIAL_LIST_FILTER',
  RESET_GUEST_CREDENTIAL_LIST_FILTERS: 'RESET_GUEST_CREDENTIAL_LIST_FILTERS',
  SET_GUEST_FILTER: 'SET_GUEST_FILTER',
  SET_GUEST_ORDER: 'SET_GUEST_ORDER',
  REMOVE_GUEST: 'REMOVE_GUEST',
  UPDATE_GUEST: 'UPDATE_GUEST',
  CACHE_GUEST_DETAILS: 'CACHE_GUEST_DETAILS',
  RESET_GUESTS_FILTERS: 'RESET_GUESTS_FILTERS',
  RESET_GUESTS_DATA: 'RESET_GUESTS_DATA',
  CACHE_REMOVE_GUEST_DETAILS: 'CACHE_REMOVE_GUEST_DETAILS',
  RESET_GUESTS_PAGINATION_DATA: 'RESET_GUESTS_PAGINATION_DATA',
  SAVE_GUEST_ACCESS_METHODS: 'SAVE_GUEST_ACCESS_METHODS',
  SET_IS_LOADING_CREDENTIALS: 'SET_IS_LOADING_CREDENTIALS',
};
