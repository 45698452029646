module.exports = {
  SAVE_CAMERAS: 'SAVE_CAMERAS',
  APPEND_CAMERAS: 'APPEND_CAMERAS',
  SAVE_CAMERAS_PAGINATION_DATA: 'SAVE_CAMERAS_PAGINATION_DATA',
  SET_SELECTED_CAMERA: 'SET_SELECTED_CAMERA',
  RESET_CAMERA_DATA: 'RESET_CAMERA_DATA',
  SET_CAMERA_FILTER: 'SET_CAMERA_FILTER',
  RESET_CAMERA_FILTERS: 'RESET_CAMERA_FILTERS',
  UPDATE_CAMERA: 'UPDATE_CAMERA',
  SAVE_ALL_CAMERAS: 'SAVE_ALL_CAMERAS',
  SAVE_SMART_LOCKS_WITH_CAMERAS: 'SAVE_SMART_LOCKS_WITH_CAMERAS',
};
