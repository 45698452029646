import React from 'react';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import { connect } from 'react-redux';
import { Form, Button, Col } from '@sketchpixy/rubix';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import * as ModalActions from '../../../redux/actions/modal.actions';
import DropFileField from '../Fields/DropFileField.jsx';
import FormCardContainer from '../Elements/FormCardContainer.jsx';


const validate = (values) => {
  const errors = [];
  if (values) {
    if (!values.media) {
      errors.media = 'required';
    }
  }
  return errors;
};


@reduxForm({ form: 'ImageUploadForm', validate })
@connect(state => ({ form: state.form.ImageUploadForm }))
class ImageUploadForm extends React.Component {

  render() {
    const { form, onDeleteFile, previewSectionTitle, previewSectionSubtitle, previewTitle, previewDescription, dispatch } = this.props;

    let imagePreview = form && form.values && form.values.media;
    if (imagePreview && _.isArray(imagePreview)) {
      imagePreview = imagePreview[0].preview;
    } else if (imagePreview && _.isString(imagePreview)) {
      imagePreview = `data:image/jpeg;base64,${form.values.media}`;
    } else if (imagePreview) {
      imagePreview = form.values.media.preview;
    }

    return (
      <Form>
        <FormCardContainer
          title="loadAnImage"
          subtitle="uploadImageSubtitle"
          hideCard
        >
          <Field
            accept="image/*"
            className="drop-file-container"
            activeClassName="drop-file-container-active"
            name="media"
            maxSize={1048576}
            component={DropFileField}
            onFileRejected={() => dispatch(ModalActions.showModal({
              modalType: 'ERROR_MODAL',
              modalProps: { type: 'FILE_TOO_LARGE' },
            }))}
            onDeleteFile={f => onDeleteFile(f)}
          >
            <h5 style={{ marginBottom: 5 }}><Entity entity="dropFile" /></h5>
            <h5 style={{ marginTop: 0 }}><Entity entity="orSelect" /></h5>
          </Field>
        </FormCardContainer>
        <hr style={{ borderTop: '2px solid #eee' }} />
        <FormCardContainer
          title={previewSectionTitle || 'preview'}
          subtitle={previewSectionSubtitle || 'resourceImagePreviewSubtitle'}
          hideCard
        >
          <div className="card-noHover" style={{ padding: 0, display: 'flex', flexDirection: 'column', margin: 'auto', maxWidth: '70%', justifyContent:'flex-start', alignItems: 'center' }}>
            <div style={{ maxHeight: 150, overflow: 'hidden', width: '100%' }}>
              {imagePreview && <img alt="preview" src={imagePreview} style={{ width: '100%', height: 'auto' }} />}
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', padding: 15, flexDirection: 'column', alignItems: 'flex-start' }}>
              <h4 style={{ color: '#3f3f3f' }}>{previewTitle || <Entity entity="previewTitle" />}</h4>
              <h5 style={{ color: '#3f3f3f', marginTop: 5 }}>{previewDescription || <Entity entity="previewSubtitle" />}</h5>
            </div>
          </div>
        </FormCardContainer>
      </Form>
    );
  }
} 

export default ImageUploadForm;
