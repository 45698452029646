import React from 'react';
import { ACCESS_METHODS } from '../../../_config/consts.js';
import AccessMethodCard from './AccessMethodCard.jsx';
import AccessMethodSmartphone from './AccessMethodSmartphone.jsx';
import ItemAccessMethod from './ItemAccessMethod.jsx';
import F9000AccessMethod from './F9000AccessMethod.jsx';
import PINAccessMethod from './PINAccessMethod.jsx';

const rowComponents = {
  [ACCESS_METHODS.SMART]: AccessMethodSmartphone,
  [ACCESS_METHODS.STANDARD]: AccessMethodCard,
  [ACCESS_METHODS.ITEM]: ItemAccessMethod,
  [ACCESS_METHODS.F9000]: F9000AccessMethod,
  [ACCESS_METHODS.PIN]: PINAccessMethod,
};

export default class AccessMethodRow extends React.Component {

  render() {
    const { type, className } = this.props;
    if (!type) {
      return null;
    }
    const Component = rowComponents[type];

    return (
      <div>
        {Component ? <Component className={className} {...this.props} /> : null}
      </div>
    );
  }
}
