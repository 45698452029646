import _ from 'lodash';
import { Observable } from 'rxjs';
import { from } from 'rxjs/observable/from';
import { of } from 'rxjs/observable/of';
import { mergeMap, concatMap, switchMap, map, delay, takeUntil, catchError, finalize, endWith } from 'rxjs/operators';
import {
  FETCH_AREA_LOCKS_BY_TAGS,
  CANCEL_FETCH_AREA_LOCKS_BY_TAGS,
  FETCH_AREAS_OCCUPANCY,
  CANCEL_FETCH_AREAS_OCCUPANCY,
} from '../redux/actions/actionTypes/area';

import * as RestService from '../_config/rest';
import {
  setLocksFilteredByTagsEpic,
  setAreaOccupancyEpic,
} from '../redux/actions/area.actions';
import { rxFetch } from './utils';
import moment from 'moment';

export const fetchAreaLocksByTagsEpic = (action$, store) =>
  action$.ofType(FETCH_AREA_LOCKS_BY_TAGS)
    .debounceTime(500)
    .switchMap((action) => {
      const { lockTagMatchingMode, page, append, index } = action;
      let tags = action.tags;
      if (!_.isArray(tags)) {
        tags = [tags];
      }

      if (tags && tags.length) {
        const params = {
          tagIds: _.map(tags, tag => tag.id || tag),
          page,
          lockTagMatchingMode,
        };

        return rxFetch(RestService.fetchLocksByTags(params))
          .map((response) => {
            if (response.data && response.data.content) {
              const locks = response.data.content;
              const pagination = _.omit(response.data, 'content');
              return setLocksFilteredByTagsEpic(locks, index, pagination, append);
            }
            return setLocksFilteredByTagsEpic([], index);
          })
          .takeUntil(action$.ofType(CANCEL_FETCH_AREA_LOCKS_BY_TAGS))
          .catch(() => Observable.empty());
      }
      return Observable.of(setLocksFilteredByTagsEpic([], index));
    });

export const fetchAllAreasOccupancyEpic = (action$, store) =>
  action$.ofType(FETCH_AREAS_OCCUPANCY)
    .pipe(
      switchMap((action) => {
        const { areas } = action;

        const params = {
          toTimestamp: moment().valueOf()
        };
        
        const lastAreaID = areas.length > 0 ? areas[areas.length - 1].id : undefined;
        return from(areas).pipe(
          concatMap((area) => 
            of(area).pipe(
              delay(300),
              switchMap(() => 
                rxFetch(RestService.fetchAreaOccupancy(area.id, params)).pipe(
                  map((response) => {
                    if (response.data) {
                      response.data.id = area.id;
                      return setAreaOccupancyEpic(response.data, lastAreaID);
                    }
                    return Observable.empty();
                  }),
                  takeUntil(action$.ofType(CANCEL_FETCH_AREAS_OCCUPANCY)),
                  catchError(() => Observable.empty())
                )
              )
            )
          ),
          finalize(() => {
            
          })
        );
      })
    );

