/* eslint-disable max-len */
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider';
import { ITEM_TYPES, PERMISSIONS, PERMISSION_ENTITIES } from '../../../_config/consts.js';
import TagsSelectorListField from '../Elements/TagsSelectorListField.jsx';
import CustomField from '../Fields/CustomField.jsx';
import DatePickerField from '../Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import MDSwitchField from '../Fields/MDSwitch/MDSwitchField.jsx';
import TranslatableOption from '../Fields/TranslatableOption.jsx';


const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  } else if (_.trim(values.name).length > 255) {
    errors.name = 'maximumLenghtTwelve';
  }
  if (values.masterCardNumber && _.trim(values.masterCardNumber).length >= 10) {
    errors.masterCardNumber = 'maximumLenghtTen';
  }
  if (!values.type) {
    errors.type = 'required';
  }
  return errors;
};


@reduxForm({ form: 'ItemRegistryForm', validate })
@connect(state => ({}))
class ItemRegistryForm extends React.Component {
  render() {
    const { selectedType, dispatch, onFetchMoreGuests, onGuestTagsChange, itemTypesOptions, deliveryTypeOptions, onGuestInputChange, availableUserTags, guestsFilteredByTags, form } = this.props;
    const canCrudItems = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.ITEM);
    return (
      <Form style={{ padding: 10 }}>
        <input style={{ display: 'none' }} type="username" name="username" />
        <Entity
          componentClass={Field}
          name="name"
          componentAttribute="placeholder"
          entity="insertItemName"
          title={<Entity entity="itemName" />}
          component={CustomField}
          type="text"
          disabled={!canCrudItems}
          mandatory
        />
        <Field
          id="type"
          name="type"
          title={<Entity entity="itemType" />}
          component={CustomField}
          mandatory
          disabled={!canCrudItems}
          className="form-control-select"
          componentClass="select"
        >
          {_.map(itemTypesOptions, type =>
            <Entity
              key={type.value}
              componentClass={TranslatableOption}
              value={type.value}
              componentAttribute="text"
              entity={type.label}
            />)
          }
        </Field>
        {selectedType === ITEM_TYPES.MASTER_CARD ? (
          <div>
            <Entity
              componentClass={Field}
              name="masterCardId"
              componentAttribute="placeholder"
              entity="insertMasterCardId"
              disabled={!canCrudItems}
              title={<Entity entity="masterCardId" />}
              component={CustomField}
              type="text"
            />
            <Entity
              componentClass={Field}
              name="masterCardNumber"
              componentAttribute="placeholder"
              entity="insertMasterCardNumber"
              disabled={!canCrudItems}
              title={<Entity entity="masterCardNumber" />}
              component={CustomField}
              type="text"
            />
          </div>
        ) : null}
        {selectedType === ITEM_TYPES.PHYSICAL_KEY || selectedType === ITEM_TYPES.PHYSICAL_KEY_MK ? (
          <Entity
            componentClass={Field}
            name="physicalKeyId"
            componentAttribute="placeholder"
            entity="insertPhysicalKeyId"
            disabled={!canCrudItems}
            title={<Entity entity="physicalKeyId" />}
            component={CustomField}
            type="text"
          />
        ) : null}
        <Entity
          componentClass={Field}
          name="description"
          componentAttribute="placeholder"
          entity="itemDescriptionPlaceholder"
          disabled={!canCrudItems}
          title={<Entity entity="itemDescription" />}
          component={CustomField}
          type="text"
        />
        {selectedType === ITEM_TYPES.PHYSICAL_KEY || selectedType === ITEM_TYPES.PHYSICAL_KEY_MK || selectedType === ITEM_TYPES.MASTER_CARD ? (
          <div>
            <div style={{ marginTop: 20, marginBottom: 12 }}>
              <h5 style={{ fontWeight: 'bold', fontSize: 16, color: '#3f3f3f', margin: 0 }}>{<Entity entity="resourceSmartLocks" />}</h5>
              <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 8, fontWeight: 'lighter' }}><Entity entity="itemLocksDescription" /></h5>
            </div>
            <Entity
              componentClass={Field}
              name="associatedLocks"
              componentAttribute="placeholder"
              disabled={!canCrudItems}
              entity="insertAssociatedLocks"
              title=""
              component={CustomField}
              type="text"
            />
          </div>
        ) : null}
        <div style={{ marginTop: 20, marginBottom: 12 }}>
          <h5 style={{ fontWeight: 'bold', fontSize: 16, color: '#3f3f3f', margin: 0 }}>{<Entity entity="itemUser" />}</h5>
          <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 8, fontWeight: 'lighter' }}><Entity entity="itemUserDescription" /></h5>
        </div>
        <TagsSelectorListField
          name="guestTags"
          formName="ItemRegistryForm"
          selectedTagsFieldName="selectedGuestTags"
          tags={availableUserTags}
          selectedTags={guestsFilteredByTags}
          form={form}
          isOptional
          disabled={!canCrudItems}
          title=" "
          tagsType="GUEST"
          placeholder="insertGuestTagsForCard"
          listTitle="selectedUsers"
          onFetchMoreItems={page => onFetchMoreGuests(page)}
          onTagsChange={values => onGuestTagsChange(values)}
          onInputChange={value => onGuestInputChange(value)}
          isSingleChoice
        />
        <Field
          id="deliveredOn"
          title={<Entity entity="itemDate" />}
          subtitle={<Entity entity="itemDateDescription" />}
          name="deliveredOn"
          disabled={!canCrudItems}
          placeholder="--/--/----"
          containerStyle={{ marginTop: 25 }}
          titleStyle={{ fontSize: 16 }}
          onHandleChange={value => dispatch(change('ItemRegistryForm', 'deliveredOn', value))}
          component={DatePickerField}
        />
        <div style={{ marginTop: 30 }}>
          <div style={{ marginTop: 0, marginBottom: 12 }}>
            <h5 style={{ fontWeight: 'bold', fontSize: 16, color: '#3f3f3f', margin: 0 }}>{<Entity entity="itemDeliveryMode" />}</h5>
            <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 8, fontWeight: 'lighter' }}><Entity entity="itemDeliveryModeDescription" /></h5>
          </div>
          <Field
            id="type"
            name="deliveryMode"
            title=""
            component={CustomField}
            disabled={!canCrudItems}
            className="form-control-select"
            componentClass="select"
          >
            {_.map(deliveryTypeOptions, type =>
              <Entity
                key={type.value}
                componentClass={TranslatableOption}
                value={type.value}
                componentAttribute="text"
                entity={type.label}
              />)
            }
          </Field>
        </div>
        <Field
          name="signatureCollected"
          disabled={!canCrudItems}
          label={<Entity entity="signatureCollected" />}
          titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
          component={MDSwitchField}
        />
        <Entity
          componentClass={Field}
          name="notes"
          title={<Entity entity="itemNotes" />}
          componentAttribute="placeholder"
          entity="insertItemsNotes"
          disabled={!canCrudItems}
          component={CustomField}
          containerStyle={{ marginTop: 10 }}
          type="textarea"
          className="form-control-custom"
        />
      </Form>
    );
  }
} 

export default ItemRegistryForm;
