import { IconButton } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { GREEN, RED, SUBSCRIPTION_TYPES } from '../../../_config/consts';
import { localizeHelpCenterLink } from '../../../_config/utils.js';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as UserActions from '../../../redux/actions/user.actions';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import ArrowLeftIconCustom from '../../CustomIcons/ArrowLeftIconCustom.jsx';
import CheckOutlineIconCustom from '../../CustomIcons/CheckOutlineIconCustom.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import CobotActivationForm from '../../forms/Integrations/CobotActivationForm.jsx';
import CobotResourceSelectionForm from '../../forms/Integrations/CobotResourceSelectionForm.jsx';


@connect(state => ({ language: state.settings.language }))
class CobotSettingsOperationalSection extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isActive: null,
      resourceTypes: null,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const isActive = dispatch(UserActions.userHasIntegrationActive(SUBSCRIPTION_TYPES.COBOT_INTEGRATION));
    this.setState({ isActive });
  }

  async onFetchCobotReources(loginData) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const resourceTypes = await dispatch(UserActions.getCobotResourceCategories(loginData.spaceName, loginData.code));
      if (resourceTypes) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        this.setState({ resourceTypes, cobotData: loginData });
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="notificationError" /></h6>),
        },
      }));
    }
  }

  async onActivateCobotIntegration(selectedResourceTypesIds, coldStartResources) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    const { cobotData } = this.state;
    try {
      await dispatch(UserActions.activateCobotIntegration(cobotData.code, cobotData.spaceName, cobotData.clientId, cobotData.clientSecret, cobotData.syncAllUsers, coldStartResources, selectedResourceTypesIds));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(UserActions.fetchCurrentActiveIntegrations());
      this.setState({ isActive: true });
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationConnectionError" /></h6>),
        },
      }));
    }
  }

  onDeactivateIntegrationRequest() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="disableIntegrationConfirm" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeactivateIntegration(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeactivateIntegration() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const cobotIntegrationDTO = dispatch(UserActions.getActiveIntegration(SUBSCRIPTION_TYPES.COBOT_INTEGRATION));
      if (cobotIntegrationDTO && cobotIntegrationDTO.id) {
        await dispatch(UserActions.disableIntegration(cobotIntegrationDTO.id));
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(UserActions.fetchCurrentActiveIntegrations());
        this.setState({ isActive: false, showNexudusLogin: false });
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedSuccess" /></h6>),
          },
        }));
      } else {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
          },
        }));
      }
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="integrationDeactivatedError" /></h6>),
        },
      }));
    }
  }

  onOpenActivationInfo() {
    const helpURL = localizeHelpCenterLink('activate-cobot-integration');
    window.open(helpURL);
  }

  onGoBackToLogin() {
    this.setState({ resourceTypes: [] });
  }

  render() {
    const { isActive, resourceTypes } = this.state;
    const { dispatch } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25, paddingTop: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <img alt="nexudus" src="/imgs/common/integrationLogos/cobot.png" style={{ height: 50, alignSelf: 'center' }} />
        {isActive ? (
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <div style={{ width: '70%', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CheckOutlineIconCustom style={{ color: GREEN, width: 30, marginRight: 10 }} />
              <h4 style={{ color: GREEN, fontWeight: 'bold', fontSize: 20 }}><Entity entity="integrationCorrectlyConfigured" /></h4>
            </div>
            <MDButton
              title={<Entity key={isActive} entity="disableIntegrationTitle" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor={RED}
              containerStyle={{ margin: 0, marginTop: 10, width: '80%' }}
              onClick={() => this.onDeactivateIntegrationRequest()}
            />
          </div>
        ) : (
          <div style={{ width: '70%', margin: 'auto' }}>
            <h4 style={{ color: '#3f3f3f', textAlign: 'center', marginTop: 20, marginBottom: 30 }}><Entity entity="connectToCobotDescription" /></h4>
            {!resourceTypes || _.isEmpty(resourceTypes) ? (
              <div>
                <div style={{ marginTop: 20 }}>
                  <CobotActivationForm
                    onSubmit={cobotData => this.onFetchCobotReources(cobotData)}
                  />
                </div>
                <MDButton
                  title={<Entity key={isActive} entity="connectYourAccount" />}
                  titleStyle={{ marginTop: 0, fontSize: 13 }}
                  backgroundColor="#145ba2"
                  containerStyle={{ margin: 0, marginTop: 10 }}
                  onClick={() => dispatch(submit('CobotActivationForm'))}
                />
                <h5
                  className="link-label"
                  style={{ margin: 'auto', width: 'fit-content', marginTop: 20, fontSize: 16, textDecoration: 'underline' }}
                  onClick={() => this.onOpenActivationInfo()}
                >
                  <Entity entity="findOutMore" />
                </h5>
              </div>
            ) : (
          <div>
            <div style={{ marginTop: 20 }}>
              <CobotResourceSelectionForm
                resourceTypes={resourceTypes}
                onSubmit={activationData => this.onActivateCobotIntegration(activationData.resources, activationData.coldStartResources)}
              />
            </div>
            <MDButton
              title={<Entity key={resourceTypes} entity="connectYourAccount" />}
              titleStyle={{ marginTop: 0, fontSize: 13 }}
              backgroundColor="#145ba2"
              containerStyle={{ margin: 0, marginTop: 10 }}
              onClick={() => dispatch(submit('CobotResourceSelectionForm'))}
            />
            <IconButton onClick={() => this.onGoBackToLogin()}>
              <ArrowLeftIconCustom style={{ color: '#3f3f3f', width: 20, marginRight: 5 }} />
            </IconButton>
            <Entity style={{ color: '#3f3f3f', marginTop: 2 }} entity="backToLogin" />
          </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default CobotSettingsOperationalSection;
