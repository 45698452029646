import React from 'react';
import PrivacyIcon from '@material-ui/icons/Lock';
import TermsIcon from '@material-ui/icons/VerifiedUser';
import SupportIcon from '@material-ui/icons/Build';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

const PrivacyTermsView = ({ color }) => {
  return (
    <div>
      <h3 style={{ color: '#3f3f3f', marginTop: 0, marginBottom: 20 }}><Entity entity="consultPrivacy" /></h3>
      <div
        style={{ marginBottom: 10, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onClick={() => window.open("https://jago.cloud/legal/luckey/host/terms")}
      >
        <TermsIcon style={{ color: '#3f3f3f', marginRight: 10 }} />
        <h4 style={{ textDecoration: 'underline', color: '#3f3f3f' }}><Entity entity="termOfUse" /></h4>
      </div>
      <div
        style={{ marginBottom: 10, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onClick={() => window.open("https://jago.cloud/legal/luckey/host/privacy")}
      >
        <PrivacyIcon style={{ color: '#3f3f3f', marginRight: 10 }} />
        <h4 style={{ textDecoration: 'underline', color: '#3f3f3f' }}><Entity entity="privacyPolicy" /></h4>
      </div>
      <div
        style={{ marginBottom: 10, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onClick={() => window.open("https://www.sofialocks.com/contacts.html")}
      >
        <SupportIcon style={{ color: '#3f3f3f', marginRight: 10 }} />
        <h4 style={{ textDecoration: 'underline', color: '#3f3f3f' }}><Entity entity="support" /></h4>
      </div>
    </div>
  );
};
export default PrivacyTermsView;
