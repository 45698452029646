import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import React from 'react';
import { connect } from 'react-redux';
import EmptyView from '../EmptyView.jsx';

@connect(state => ({ }))
class LicenseSectionView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      licenseList: [],
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', e => this.onKeyPressed(e), false);
    try {
      const licenseJson = require('../../licenses/licenses.json');
      const formattedLicenses = _.map(licenseJson, (license, key) => {
        var name = key.substring(0, key.lastIndexOf("@"));
        var version = key.substring(key.lastIndexOf("@") + 1, key.length);
        return {
          ...license,
          key,
          name,
          version,
        };
      });
      this.setState({ licenseList: formattedLicenses });
    } catch (error) {
      this.setState({ licenseList: [] });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', e => this.onKeyPressed(e), false);
  }

  onKeyPressed(e) {
    if (e.key === 'Escape') {
      const { onClose } = this.props;
      onClose();
    }
  }

  closeOperationalSection() {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const { themeName } = this.props;
    const { licenseList } = this.state;
    
    return (
      <div className="operational-view">
        <div className={`operational-view-title-container bg-${themeName}-theme`}>
          <IconButton onClick={() => this.closeOperationalSection()} style={{ marginLeft: 10 }}>
            <Cancel style={{ color: 'white', fontSize: 30 }} />
          </IconButton>
          <h3 className="operational-view-title">
            <Entity entity="softwareLicenses" />
          </h3>
        </div>
        <div className="operational-view-container">
          <h4 style={{ marginLeft: 10 }}><Entity entity="softwareLicensesDescription" /></h4>
          {!_.isEmpty(licenseList) ? (
            <div className="list-view" style={{ marginTop: 10, paddingTop: 10 }}>
              {_.map(licenseList, license => (
                <Card
                  style={{ padding: 12, marginLeft: 10, marginRight: 10 }}
                  className="card card-rounded"
                  onClick={() => window.open(license.licenseUrl)}
                >
                  <div style={{ padding: 10 }}>
                    <h4 style={{ margin: 0, marginTop: 0, fontWeight: 'bold', color: '#3f3f3f' }}>{license.name}</h4>
                    <h5 style={{ margin: 0, marginTop: 5, fontWeight: 'light' }}>{license.licenses}</h5>
                    <h5 style={{ margin: 0, marginTop: 5, fontWeight: 'light' }}>v. {license.version}</h5>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <EmptyView
              title="noLicenseFound"
              subtitle="noLicenseFoundDescription"
              iconName="icon-simple-line-icons-user-following"
            />
          )}
        </div>
      </div>
    );
  }
} 

export default LicenseSectionView;
