// @ts-nocheck
/* eslint-disable comma-dangle */
import React from 'react';
import _ from 'lodash';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import InfiniteScroll from 'react-infinite-scroller';
import LogEventRow from './LogEventRow.jsx';
import EmptyView from '../EmptyView.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BLUE } from '../../_config/consts';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import * as CamerasActions from '../../redux/actions/cameras.actions';

const styles = {
  circularProgress: {
    color: BLUE,
  },
};

@connect(() => ({}))
class LogEventsList extends React.Component {
  render() {
    const { dispatch, events, classes, viewLoading, onLockClick, onShowCameraSnippet, onInvitationCodeClick, onUserClick, pagination: { number, last }, eventType, listStyle, onLoadMore } = this.props;
    return (
      <div style={{ overflowY: 'auto', height: '120vh', paddingLeft: 15, paddingRight: 5, paddingBottom: 220, ...listStyle }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={onLoadMore ? () => onLoadMore(number + 1) : null}
          hasMore={!last}
          useWindow={false}
          loader={onLoadMore ?
            <div style={{ marginTop: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingVertical: 5, }}>
              <h4 style={{ textAlign: 'center', color: '#3f3f3f' }}><Entity entity="loadingLogs" /></h4>
              <CircularProgress className={classes.circularProgress} />
            </div> : null
          }
        >
          <div>
            {!_.isEmpty(events) ?
              _.map(events, (log, key) => {
                const lockHasCameraAssociated = dispatch(CamerasActions.lockHasCameraAssociatedCheck(log.lockId));
                return (
                  <LogEventRow
                    key={log.id}
                    event={log}
                    type={eventType}
                    onLockClick={lockId => onLockClick(lockId)}
                    onUserClick={userId => onUserClick(userId)}
                    lockHasCameraAssociated={lockHasCameraAssociated}
                    onShowCameraSnippet={(lockId, event) => onShowCameraSnippet(lockId, event)}
                    onInvitationCodeClick={invitationCode => onInvitationCodeClick(invitationCode)}
                  />
                );
              })
                : (
                  <div>
                    {!viewLoading && (
                      <EmptyView title="noLogs" subtitle="tryResetSearchFilter" />
                    )}
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

export default withStyles(styles)(LogEventsList);
