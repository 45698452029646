import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import LauncIcon from '@material-ui/icons/Launch';
import { submit, change } from 'redux-form';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import { Parser as Json2csvParser } from 'json2csv';
import TagForm from '../../forms/TagForm.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import * as ModalActions from '../../../redux/actions/modal.actions';
import * as TagsManagementActions from '../../../redux/actions/tagsManagement.actions';
import TagLocksForm from '../../forms/TagLocksForm.jsx';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { PERMISSIONS, RED, PERMISSION_ENTITIES, MAX_LOCKS_TAGS_LITE_LIMIT, LICENSE_TYPES } from '../../../_config/consts';
import FormCardContainer from '../../forms/Elements/FormCardContainer.jsx';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import Permission from '../../Permission/Permission.jsx';
import { hasFormWritePermission } from '../../../_config/utils.js';

@connect((state) => ({
  tagsManagement: state.tagsManagement,
  locksTagsNumber: state.tagsManagement.lockTags.number, 
}))
class LocksTagsOperationalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isListLoading: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(TagsManagementActions.fetchLocksSpecialTagsNumber());
  }

  async onSaveTag(values) {
    if (values.id) {
      this.onUpdateTag(values);
    } else {
      this.onCreateNewTag(values);
    }
  }

  async onUpdateTag(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(TagsManagementActions.updateLockSpecialTag(values.id, values));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(TagsManagementActions.setSelectedTag(null));
      dispatch(TagsManagementActions.setOperationalMode(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'tagUpdated' }} /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onCreateNewTag(values) {
    const { dispatch, locksTagsNumber } = this.props;
    const hasActiveIntegrations = dispatch(UtilsActions.hasIntegrationWithSynchronizationPlugin())
    const isCreateTagForbidden = !hasActiveIntegrations && AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    if (isCreateTagForbidden && locksTagsNumber >= MAX_LOCKS_TAGS_LITE_LIMIT) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="tooManyTagsForLicenseError" /> },
      }));
    } else {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      try {
        const updatedTag = await dispatch(TagsManagementActions.createLockSpecialTag(values));
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(TagsManagementActions.setSelectedTag({ ...updatedTag, locksNumber: 0 }));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'tagCreated' }} /></h6>),
          },
        }));
      } catch (error) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    }
  }

  onLoadMoreTagLocks(page) {
    const { dispatch, tagsManagement: { selectedTag } } = this.props;
    dispatch(TagsManagementActions.fetchAndAppendLocksByTag(selectedTag.id, page));
  }

  async onRemoveLock(lock) {
    const { dispatch, tagsManagement: { selectedTag } } = this.props;
    try {
      this.setState({ isListLoading: true });
      await dispatch(TagsManagementActions.removeLockFromTag(selectedTag, lock));
      this.setState({ isListLoading: false });
    } catch (error) {
      this.setState({ isListLoading: false });
    }
  }

  async onConfirmAddLock(lock) {
    const { dispatch, tagsManagement: { selectedTag } } = this.props;
    this.setState({ isListLoading: true });
    try {
      await dispatch(TagsManagementActions.addLockToTag(selectedTag, lock));
      dispatch(change('TagLocksForm', 'lockTagSelectedSearch', null));
      this.setState({ isListLoading: false });
    } catch (error) {
      this.setState({ isListLoading: false });
    }
  }

  async onDownloadLocksCSV() {
    const { dispatch } = this.props;
    const fields = [
      {
        label: L20NContext.getSync('name'),
        value: 'name',
      }, {
        label: L20NContext.getSync('model'),
        value: 'model',
      }, {
        label: L20NContext.getSync('serialNumber'),
        value: 'serialNumber',
      }];
    const opts = { fields };
    const { tagsManagement: { selectedTag } } = this.props;
    try {
      const { locks, stopFetching } = await dispatch(TagsManagementActions.fetchAllLocksByTag(selectedTag.id));
      if (!stopFetching) {
        const output = new Json2csvParser(opts).parse(locks);
        const blob = new Blob([output], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, `LocksTag-${selectedTag.name || ''}-Export.csv`);
      }
    } catch (error) {
      let errorMessage = 'unexpectedErrorMessage';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(UtilsActions.setLoadingProgressVisible(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }

  async onDeleteLockTag(tagId) {  
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(TagsManagementActions.deleteLockTag(tagId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(TagsManagementActions.setSelectedTag(null));
      dispatch(TagsManagementActions.setOperationalMode(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'tagDeleted' }} /></h6>),
        },
      }));
    } catch (error) {
      let errorMessage = 'errorDeleteTag';
      if (error && error.response && error.response.data && error.response.data.code && error.response.data.code === 10604) errorMessage = 'errorDeleteTagUsedInCredentialRule';
      if (error && error.response && error.response.data && error.response.data.code && error.response.data.code === 10605) errorMessage = 'errorDeleteTagWithLocks';
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
    }
  }

  onOpenLockDetails(item) {
    window.open(`/locks?lockId=${item.id}`);
  }

  render() {
    const { dispatch, tagsManagement: { selectedTag, lockTags: { locks: { content, pagination } }, lockTagsCategories: { content: categories } } } = this.props;
    const { isListLoading } = this.state;
    const hasDeleteTagPermission = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.SMART_LOCK_TAG);
    const canReadLocks = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const canSaveLockTag = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE, PERMISSIONS.CREATE], PERMISSION_ENTITIES.SMART_LOCK_TAG);
    const canEditSmartCredentials = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.CREDENTIAL_RULE);
    const canEditCardCredentials = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canUpdateSmartLocks = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.SMART_LOCK);
    const canModifyLockInTag = canEditSmartCredentials && canUpdateSmartLocks && canEditCardCredentials;
    const canDeleteTag = hasDeleteTagPermission && canEditSmartCredentials && canUpdateSmartLocks && canEditCardCredentials;
    return (
      <div>
        <FormCardContainer title="insertTagDataTitle" subtitle="insertLockTagDataSubtitle">
          <TagForm
            tagType="lock"
            categories={categories}
            isDisabled={!canSaveLockTag}
            doAsyncValidate={selectedTag && _.isEmpty(selectedTag)}
            onSubmit={values => this.onSaveTag(values)}
          />
          {canSaveLockTag ? (
            <MDButton
              title={<Entity entity="saveTagData" />}
              containerStyle={{ width: '100%', margin: 0, marginTop: 30 }}
              style={{ height: 40, borderRadius: 0 }}
              onClick={() => dispatch(submit('TagForm'))}
            />
          ) : null}
        </FormCardContainer>
        {selectedTag && selectedTag.id && canDeleteTag ? (
          <FormCardContainer title="deleteTagDataTitle" subtitle="deleteLockTagDataSubtitle">
            <MDButton
              title={<Entity entity="delete" />}
              backgroundColor={RED}
              containerStyle={{ width: '100%', margin: 0, marginTop: 30 }}
              style={{ height: 40, borderRadius: 0 }}
              onClick={() => this.onDeleteLockTag(selectedTag.id)}
            />
          </FormCardContainer>
        ) : null}
        <Permission do={[PERMISSIONS.READ]} on={PERMISSION_ENTITIES.SMART_LOCK} abilityHelper={AbilityProvider.getAbilityHelper()}>
          <TagLocksForm
            items={content}
            canAddLocks={selectedTag && selectedTag.id && canModifyLockInTag}
            pagination={pagination}
            isListLoading={isListLoading}
            onFetchMoreItems={(page) => this.onLoadMoreTagLocks(page)}
            onAddLockToTag={(lock) => this.onConfirmAddLock(lock)}
            onDownloadListCSV={() => this.onDownloadLocksCSV()}
            rowElement={(item, index) => (
              <div style={{ backgroundColor: index % 2 === 0 ? 'white' : 'ghostwhite', borderBottom: '1px solid', borderTop: '1px solid', alignItems: 'center', justifyContent: 'space-between', display: 'flex', padding: 5, borderColor: '#f0f0f0' }} key={item.id}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h5 style={{ color: '#3f3f3f', marginLeft: 10 }}>{`${item.name}`}</h5>
                  {canReadLocks ? <IconButton style={{ marginLeft: 10 }} onClick={() => this.onOpenLockDetails(item)}><LauncIcon style={{ width: 20, color: '#90CAF9' }} /></IconButton> : null}
                </div>
                {canModifyLockInTag ? (
                  <IconButton
                    style={{ color: RED }}
                    onClick={() => this.onRemoveLock(item)}
                  >
                    <DeleteIcon style={{ fontSize: 20 }} />
                  </IconButton>
                ) : null}
              </div>
            )}
          />
        </Permission>
      </div>
    );
  }
} 

export default LocksTagsOperationalSection;
