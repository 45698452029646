import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  SAVE_AGREEMENTS,
  SAVE_ACCEPTED_AGREEMENTS,
  RESET_AGREEMENTS,
  SET_AGREEMENT_FILTER,
  RESET_AGREEMENT_FILTERS,
} from '../actions/actionTypes/agreements';

const initialState = Immutable({
  data: [],
  acceptedAgreements: [],
  filters: {},
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_AGREEMENTS:
      return state.set('data', action.agreements);
    case SAVE_ACCEPTED_AGREEMENTS:
      return state.set('acceptedAgreements', action.agreements);
    case SET_AGREEMENT_FILTER:
      return state.setIn(['filters', action.field], action.value);
    case RESET_AGREEMENT_FILTERS:
      return state.set('filters', {});
    case RESET_AGREEMENTS:
      return initialState;
    default:
      return state;
  }
}
