import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Cancel';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { BLUE, MATCH_TAG_MODE, RED, VISIBLE_TAGS_NUMBER } from '../../../_config/consts';
import { getLockImage } from '../../../_config/utils.js';
import * as ModalActions from '../../../redux/actions/modal.actions.js';
import * as FloorPlansActions from '../../../redux/actions/floorplans.actions.js';
import ListLoadingView from '../../ListLoadingView.jsx';
import LockDetailsRow from '../../Locks/LockDetailsRow.jsx';
import Tag from '../../Tag.jsx';
import TagsSelectorField from '../Fields/TagsSelectorField.jsx';
import MDRadioButtonsGroupField from './MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import MapIconCustom from '../../CustomIcons/MapIconCustom.jsx';


const ItemRow = ({ item, toggleLockIn, toggleLockOut, removeLock, isEven, isSelectedIN, isSelectedOUT, onShowItemDetails, showLockTagsMode }) => {
  const fakeUserTag = {
    color: '#ba68c8',
    name: item && item.username ? item.username.toLowerCase() : '',
    type: 'user',
  };
  let backgroundColor = !isEven ? '#FAFAFA' : 'white';
  const showMoreTagsSectionVisible = item && item.tags && _.size(item.tags) > VISIBLE_TAGS_NUMBER;
  return (
    <div
      className="card-noHover tag-element-selected"
      style={{ display: 'flex', zIndex: 0, flexDirection: 'row', alignItems: 'center', margin: 5, marginBottom: 0, marginTop: 0,  backgroundColor: isSelectedIN ? '#f0f0f0' : isSelectedOUT? '#f0f0f0' : backgroundColor }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {toggleLockIn&&toggleLockOut&&
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
            <IconButton style={{ marginRight: 10, padding: 8 }} onClick={toggleLockIn? toggleLockIn : null}>
              <MeetingRoomIcon style={{ fontSize: 20, color: isSelectedIN ? '#00987C' : 'gray' }}/>
            </IconButton>
            <IconButton style={{ marginRight: 10, padding: 8}} onClick={toggleLockOut ? toggleLockOut : null}>
              <ExitToAppIcon style={{ fontSize: 20, color: isSelectedOUT ? '#98007C' : 'gray' }}/>
            </IconButton>
          </div>
        }
        {removeLock &&
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 0, cursor: 'pointer' }}>
            <CloseIcon
              glyph="icon-fontello-ok-circled"
              style={{ fontSize: 20, marginRight: 15, color: RED }}
              onClick={removeLock?removeLock:null}
            />
          </div>
        }
      </div>
      {showLockTagsMode ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h5 style={{ margin: 0, color: '#3f3f3f', width: 'max-content' }}>
            {item.name || `${item.firstname} ${item.lastname}`}
            {item.email ? <h6 style={{ margin: 0, marginTop: 2, color: '#3f3f3f' }}>{item.email}</h6> : null}
          </h5>
        </div>
      ) : (
        <div style={{position: 'relative',display: 'flex',flexDirection: 'row',alignItems: 'center',
          padding: 3,paddingRight: 6,borderRadius: 10,
          marginRight: 5,marginLeft: 5,marginTop: 3,marginBottom: 3,}}>
          <img alt="lock" className="lock-icon" style={{ width: 30, height: 30, marginRight: 10 }} src={getLockImage(item.model)} />
          <div style={{ marginRight: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'max-content' }}>
              <h5 style={{ fontWeight: 'normal', fontSize: 14, margin:0 }}>{item.name}</h5>
            </div>
          </div>
        </div>
      )}
      <span style={{flexGrow:1}}></span>
      {showLockTagsMode ? (
        <div className="tags-container" style={{ margin: 0, marginLeft: 55 }}>
          {item && item.username &&
            <Tag textStyle={{ fontSize: 12 }} style={{ paddingTop: 4, paddingBottom: 4 }} tag={fakeUserTag} />
          }
          {_.map(_.slice(item.tags, 0, VISIBLE_TAGS_NUMBER), tag =>
            <Tag
              textStyle={{ fontSize: 12 }}
              key={tag.id}
              tag={tag}
            />)}
          {showMoreTagsSectionVisible ? (
            <h5
              onClick={(e) => {
                e.stopPropagation();
                onShowItemDetails(item);
              }}
              className="link-label"
              style={{ color: BLUE, marginLeft: 10, marginTop: 18 }}>
                ...<Entity entity="showXMore" data={{ number: _.size(item.tags) - VISIBLE_TAGS_NUMBER }} />
              </h5>
          ): null}
        </div>
      ) : null}
    </div>
  );
};

@connect((state) => ({ floorPlans: state.floorPlans }))
class AreaLocksSelectorField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsSelectedIN: props.initialValuesIN || [],
      itemsSelectedOUT: props.initialValuesOUT || [],
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(FloorPlansActions.fetchFloorPlans());
  }

  onToggleLockIn(item) {
    const { onToggleLockInId, onToggleLockOutId } = this.props;
    const { itemsSelectedIN, itemsSelectedOUT } = this.state;
    if (item) {
      if (_.find(itemsSelectedIN, i => i.id === item.id)) { // if already selected
        const finalItems = _.filter(itemsSelectedIN, i => i.id !== item.id);
        this.setState({ itemsSelectedIN: finalItems }, () => onToggleLockInId(finalItems));
      } else {
        const finalItems = [...itemsSelectedIN, item];
        this.setState({ itemsSelectedIN: finalItems }, () => onToggleLockInId(finalItems));
        // Nel caso devo anche rimuoverlo dall'altra lista
        if (_.find(itemsSelectedOUT, i => i.id === item.id)) {
          const finalItems = _.filter(itemsSelectedOUT, i => i.id !== item.id);
          this.setState({ itemsSelectedOUT: finalItems }, () => onToggleLockOutId(finalItems));
        }
      }
    }
  }

  onToggleLockOut(item) {
    const { onToggleLockInId, onToggleLockOutId } = this.props;
    const { itemsSelectedIN, itemsSelectedOUT } = this.state;
    if (item) {
      if (_.find(itemsSelectedOUT, i => i.id === item.id)) { // if already selected
        const finalItems = _.filter(itemsSelectedOUT, i => i.id !== item.id);
        this.setState({ itemsSelectedOUT: finalItems }, () => onToggleLockOutId(finalItems));
      } else {
        const finalItems = [...itemsSelectedOUT, item];
        this.setState({ itemsSelectedOUT: finalItems }, () => onToggleLockOutId(finalItems));
        // Nel caso devo anche rimuoverlo dall'altra lista
        if (_.find(itemsSelectedIN, i => i.id === item.id)) {
          const finalItems = _.filter(itemsSelectedIN, i => i.id !== item.id);
          this.setState({ itemsSelectedIN: finalItems }, () => onToggleLockInId(finalItems));
        }
      }
    }
  }

  onRemovelock(item) {
    const { onToggleLockInId, onToggleLockOutId } = this.props;
    const { itemsSelectedIN, itemsSelectedOUT } = this.state;
    if (_.find(itemsSelectedIN, i => i.id === item.id)) {
      const finalItems = _.filter(itemsSelectedIN, i => i.id !== item.id);
      this.setState({ itemsSelectedIN: finalItems }, () => onToggleLockInId(finalItems));
    }
    if (_.find(itemsSelectedOUT, i => i.id === item.id)) {
      const finalItems = _.filter(itemsSelectedOUT, i => i.id !== item.id);
      this.setState({ itemsSelectedOUT: finalItems }, () => onToggleLockOutId(finalItems));
    }
  }


  componentWillReceiveProps(nextProps) {
    const { initialValuesIN: oldInitialValuesIN, initialValuesOUT: oldInitialValuesOUT } = this.props;
    const { initialValuesIN, initialValuesOUT } = nextProps;
    if (initialValuesIN && oldInitialValuesIN !== initialValuesIN)
      this.setState({ itemsSelectedIN: initialValuesIN });
    if (initialValuesOUT && oldInitialValuesOUT !== initialValuesOUT)
      this.setState({ itemsSelectedOUT: initialValuesOUT });
  }

  onShowLockDetails(item) {
    const { dispatch } = this.props;
    const lockTag = {
      color: '#ba68c8',
      name: item && item.name ? item.name : '',
      type: 'lock',
    };
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="lockSpecialTag" />,
        content: <LockDetailsRow lock={{ ...item, lockTag }} />,
        onConfirmText: <Entity entity="gotcha" />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
        onConfirm: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  render() {
    const { name, floorPlans: { data: { content: floorPlansData } }, selectorFieldName, onShowFloors, tags, placeholder, isOptional, isSingleChoice, listTitle, title, selectedTags, onTagsChange, onInputChange, onFilterModeChange, onFetchMoreItems, selectionSectionTitleIN, selectionSectionSubtitleIN, selectionSectionEmptySubtitleIN, selectionSectionTitleOUT, selectionSectionSubtitleOUT, selectionSectionEmptySubtitleOUT, disabled } = this.props;
    const { itemsSelectedIN, itemsSelectedOUT } = this.state;
    const hasElements = selectedTags && selectedTags.data && !_.isEmpty(selectedTags.data);
    const tagsPagination = selectedTags && selectedTags.pagination;
    const tagsSelectorFieldName = selectorFieldName || 'lockTagMatchingMode';
    const sortedItemsSelectedIN = _.sortBy(itemsSelectedIN, item => item.name.toLowerCase());
    const sortedItemsSelectedOUT = _.sortBy(itemsSelectedOUT, item => item.name.toLowerCase());
    return (
      <div>
        <div>
          <Entity
            name={name}
            multi={!isSingleChoice}
            componentClass={Field}
            componentAttribute="placeholder"
            entity={placeholder || 'insertItemsTags'}
            title={title || <Entity entity="genericTags" />}
            tags={tags}
            isDisabled={disabled}
            component={TagsSelectorField}
            tagType={'LOCK'}
            mandatory={!isOptional}
            onInputChange={value => onInputChange(value)}
            onHandleChange={onTagsChange ? values => onTagsChange(values) : null}
          />
          {onFilterModeChange &&
            <div style={{ marginLeft: 5 }}>
              <Field
                name={tagsSelectorFieldName}
                title={<Entity entity="daysOfTheWeek" />}
                component={MDRadioButtonsGroupField}
                onHandleChange={values => onFilterModeChange(values)}
                containerstyle={{ paddingLeft: 15, marginTop: 10 }}
                horizontal
                options={[
                  {
                    label: <Entity key={'LOCK'} entity={'matchOneTag'} />,
                    value: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
                  },
                  {
                    label: <Entity key={'LOCK'} entity={'matchEveryTag'} />,
                    value: MATCH_TAG_MODE.EVERY_TAG,
                  },
                ]}
                disabled={disabled}
              />
            </div>
          }
          {floorPlansData && !_.isEmpty(floorPlansData) ? (
            <div style={{display:'flex', flexDirection:'row', marginTop:0, marginBottom: 0, alignItems:'center'}}>
              <h4
                onClick={() => onShowFloors()}
                className="link-label"
                style={{ fontSize: 15, marginLeft: 0, color: 'cornflowerblue', cursor: 'pointer', textDecoration: 'underline' }}
                >
                  <Entity entity="areaSelectLocksFromMaps" />
                </h4>
              <MapIconCustom style={{ width: 20, color: 'cornflowerblue', marginLeft: 10 }} />
            </div>
          ) : null}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
              <h5 style={{ fontSize: 15 }}><Entity entity={listTitle} /></h5>
              <h5 style={{ marginLeft: 5, marginTop: 14 }}>
                {hasElements?`(${tagsPagination && tagsPagination.totalElements ? tagsPagination.totalElements : 0})`:""}
              </h5>
            </div>
          </div>
        </div>
        <div>
        {hasElements ? (
          <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
            <div style={{ minHeight:220, maxHeight: 220, overflowY: 'scroll' }}>
              <InfiniteScroll
                pageStart={0}
                useWindow={false}
                initialLoad={false}
                loadMore={() => onFetchMoreItems(tagsPagination.number + 1)}
                hasMore={tagsPagination && tagsPagination.number + 1 < tagsPagination.totalPages}
                loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
              >
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                  {_.map(selectedTags.data, (item, index) =>
                    <ItemRow
                      key={item.id}
                      item={item}
                      isEven={index % 2 === 0}
                      isSelectedIN={(itemsSelectedIN && _.find(itemsSelectedIN, i => i.id === item.id))}
                      isSelectedOUT={(itemsSelectedOUT && _.find(itemsSelectedOUT, i => i.id === item.id))}
                      toggleLockIn={() => this.onToggleLockIn(item)}
                      toggleLockOut={() => this.onToggleLockOut(item)}
                      onShowItemDetails={() => this.onShowLockDetails(item)}
                      showLockTagsMode={true}
                    />)}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        ):(
          <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10, minHeight:220, maxHeight: 220, 
            display:'flex',alignItems:'center',justifyContent:'center',fontStyle:'italic',fontSize:'1.1em'}}>
            <Entity key={hasElements} entity={hasElements?listTitle:'areaLocksNoSearch'} />
          </div>
        )}
        </div>
        <div style={{display:'flex',flexDirection:'row'}}>
          <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', width:'50%' }}>
            <div style={{display:'flex',flexDirection:'row'}}>
              <MeetingRoomIcon
                glyph="icon-fontello-ok-circled"
                style={{ fontSize: 20, marginRight: 15, color: '#00987C', marginLeft: 2 }}
              />
              <h4 style={{ marginTop: 0, color: '#3f3f3f', marginBottom: 10, fontWeight: 'bold' }}><Entity entity={selectionSectionTitleIN} /></h4>
              <h4 style={{ marginTop: 0, marginBottom: 10, fontWeight: 'bold', color:'rgb(215, 31, 75)' }}>*</h4>
            </div>
            <h5 style={{ marginTop: 0, color: '#3f3f3f', fontWeight: 200, marginLeft: 5 }}>
              <Entity key={JSON.stringify(itemsSelectedIN)} entity={itemsSelectedIN && !_.isEmpty(itemsSelectedIN) ? selectionSectionSubtitleIN : selectionSectionEmptySubtitleIN} 
                style={{color: itemsSelectedIN && !_.isEmpty(itemsSelectedIN)?'':'rgb(215, 31, 75)', fontWeight:itemsSelectedIN && !_.isEmpty(itemsSelectedIN)?'normal':'bold'}}/>
            </h5>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: 220, maxHeight: 220, overflowY: 'scroll' }}>
              {sortedItemsSelectedIN.length>0?_.map(sortedItemsSelectedIN, (item, index) => <ItemRow key={index} item={item} removeLock={() => this.onRemovelock(item)} showLockTagsMode={false}/>):
              (<div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10, minHeight:220, maxHeight: 220, borderTopRightRadius:0, borderBottomRightRadius:0 }}></div>)}
            </div>
          </div>
          
          <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', width:'50%' }}>
            <div style={{display:'flex',flexDirection:'row'}}>
              <ExitToAppIcon
                glyph="icon-fontello-ok-circled"
                style={{ fontSize: 20, marginRight: 15, color: '#98007C', marginLeft: 2 }}
              />
              <h4 style={{ marginTop: 0, color: '#3f3f3f', marginBottom: 10, fontWeight: 'bold' }}><Entity entity={selectionSectionTitleOUT} /></h4>
              <h4 style={{ marginTop: 0, color: '#3f3f3f', marginBottom: 10, fontWeight: 'bold', color:'rgb(215, 31, 75)' }}>*</h4>
            </div>
            <h5 style={{ marginTop: 0, color: '#3f3f3f', fontWeight: 200, marginLeft: 5 }}>
              <Entity key={JSON.stringify(itemsSelectedOUT)} entity={itemsSelectedOUT && !_.isEmpty(itemsSelectedOUT) ? selectionSectionSubtitleOUT: selectionSectionEmptySubtitleOUT} 
                style={{color: itemsSelectedOUT && !_.isEmpty(itemsSelectedOUT)?'':'rgb(215, 31, 75)', fontWeight:itemsSelectedOUT && !_.isEmpty(itemsSelectedOUT)?'normal':'bold'}}/>
            </h5>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: 220, maxHeight: 220, overflowY: 'scroll' }}>
              {sortedItemsSelectedOUT.length>0?_.map(sortedItemsSelectedOUT, (item, index) => <ItemRow key={index} item={item} removeLock={() => this.onRemovelock(item)} showLockTagsMode={false} />):
              (<div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10, minHeight:220, maxHeight: 220, borderTopLeftRadius:0, borderBottomLeftRadius:0 }}></div>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
} 

export default AreaLocksSelectorField;
