// @ts-nocheck
import { withStyles } from '@material-ui/core';
import _ from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import LockIcon from '@material-ui/icons/LockOpen';
import UsersIcon from '@material-ui/icons/PeopleOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { initialize, submit } from 'redux-form';
import CustomFieldsListView from '../../components/CustomFields/CustomFieldsListView.jsx';
import CustomFieldsFiltersForm from '../../components/forms/CustomFields/CustomFieldsFiltersForm.jsx';
import CustomFieldsForm from '../../components/forms/CustomFields/CustomFieldsForm.jsx';
import OperationalView from '../../components/OperationalView/OperationalView.jsx';
import * as CustomFieldsActions from '../../redux/actions/customFields.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import { CUSTOM_FIELDS_ENTITY_TYPES } from '../../_config/consts';
import UsersIconCustom from '../../components/CustomIcons/UsersIconCustom.jsx';

const styles = {
  container: {
  },
  boldText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
};
@withRouter
@connect(state => ({ customFields: state.customFields, themeName: state.settings.items.theme.data.themeName }))
class CustomFieldsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isViewLoading: false,
      isFormVisible: false,
      selectedTab: 0,
    };
  }

  componentDidMount() {
    this.onFetchCustomFields();
  }

  async onFetchCustomFields(entityType) {
    try {
      const { dispatch } = this.props;
      this.setState({ isViewLoading: true });
      await dispatch(CustomFieldsActions.fetchCustomFields(entityType));
      this.setState({ isViewLoading: false });
    } catch (error) {
      this.setState({ isViewLoading: false });
    }
  }

  onSelectTab(tab) {
    this.setState({ selectedTab: tab });
    const entity = tab === 0 ? CUSTOM_FIELDS_ENTITY_TYPES.USER : CUSTOM_FIELDS_ENTITY_TYPES.LOCK;
    this.onFetchCustomFields(entity);
  }

  onCreateNewCustomField() {
    this.setState({ isFormVisible: true });
  }

  onCustomFieldClick(customField) {
    const { dispatch } = this.props;
    dispatch(initialize('CustomFieldsForm', customField));
    this.setState({ isFormVisible: true });
  }

  async onCustomFieldFormSubmit(formData) {
    const { selectedTab } = this.state;
    const { dispatch } = this.props;
    const isEditing = formData.id;
    const entity = selectedTab === 0 ? CUSTOM_FIELDS_ENTITY_TYPES.USER : CUSTOM_FIELDS_ENTITY_TYPES.LOCK;
    const customFieldDTO = {
      entity,
      ...formData,
    };
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      if (isEditing) {
        await dispatch(CustomFieldsActions.editCustomField(customFieldDTO.id, customFieldDTO));
      } else {
        await dispatch(CustomFieldsActions.createCustomField(customFieldDTO));
      }
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity key={isEditing} entity={isEditing ? 'customFieldEditSuccess' : 'customFieldCreateSuccess'} /></h6>),
        },
      }));
      this.setState({ isFormVisible: false });
      this.onFetchCustomFields(entity);
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity key={isEditing} entity={isEditing ? 'customFieldEditError' : 'customFieldCreateError'} /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onSearch(searchValue) {
    const { selectedTab } = this.state;
    const { dispatch } = this.props;
    _.mapKeys(searchValue, (value, key) => {
      if (selectedTab === 0) {
        dispatch(CustomFieldsActions.setUsersCustomFieldFilter(key, value));
      } else {
        dispatch(CustomFieldsActions.setLocksCustomFieldFilter(key, value));
      }
    });
    const entity = selectedTab === 0 ? CUSTOM_FIELDS_ENTITY_TYPES.USER : CUSTOM_FIELDS_ENTITY_TYPES.LOCK;
    this.onFetchCustomFields(entity);
  }

  onSearchReset() {
    const { selectedTab } = this.state;
    const { dispatch } = this.props;
    if (selectedTab === 0) {
      dispatch(CustomFieldsActions.resetUsersCustomFieldFilters());
    } else {
      dispatch(CustomFieldsActions.resetLocksCustomFieldFilters());
    }
    const entity = selectedTab === 0 ? CUSTOM_FIELDS_ENTITY_TYPES.USER : CUSTOM_FIELDS_ENTITY_TYPES.LOCK;
    this.onFetchCustomFields(entity);
  }

  render() {
    const { classes, themeName, dispatch, customFields: { locks: { content: locksCustomFieldsData, pagination: locksCustomFieldsPagination }, users: { content: usersCustomFieldsData, pagination: usersCustomFieldsPagination } } } = this.props;
    const { selectedTab, isViewLoading, isFormVisible } = this.state;
    return (
      <div className={classes.container}>
        <div style={{ marginTop: 0 }}>
          <AppBar position="absolute" color="default" style={{ top: 65, zIndex: 10 }}>
            <Tabs
              value={selectedTab}
              textColor="primary"
              indicatorColor="primary"
              scrollable
              onChange={(e, index) => this.onSelectTab(index)}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab icon={<UsersIconCustom style={{ width: 28 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="usersCustomFields" /></h5>} />
              <Tab icon={<LockIcon style={{ fontSize: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="locksCustomFields" /></h5>} />
            </Tabs>
          </AppBar>
        </div>
        <div style={{ position: 'absolute', top: 135, right: 0, left: 0 }}>
          <CustomFieldsFiltersForm
            onSubmit={searchValue => this.onSearch(searchValue)}
            onSearchReset={() => this.onSearchReset()}
            onCreateNew={() => this.onCreateNewCustomField()}
          />
        </div>
        <div style={{ marginTop: 160 }}>
          <CustomFieldsListView
            isViewLoading={isViewLoading}
            customFieldsData={selectedTab === 0 ? usersCustomFieldsData : locksCustomFieldsData}
            pagination={selectedTab === 0 ? usersCustomFieldsPagination : locksCustomFieldsPagination}
            onRowClick={(customField) => this.onCustomFieldClick(customField)}
            onCreateNew={() => this.onCreateNewCustomField()}
          />
        </div>
        <OperationalView
          themeName={themeName}
          isVisible={isFormVisible}
          onClose={() => this.setState({ isFormVisible: false })}
          style={{ margin: 0, padding: 0 }}
          title={<Entity key={selectedTab} entity={selectedTab === 0 ? "userCustomField" : "lockCustomField"} />}
        >
          <div style={{ padding: 10, paddingBottom: '30%' }}>
            <CustomFieldsForm
              onSubmit={formData => this.onCustomFieldFormSubmit(formData)}
              onValidateForm={() => dispatch(submit('CustomFieldsForm'))}
            />
          </div>
        </OperationalView>
      </div>
    )
  }

}

export default withStyles(styles)(CustomFieldsPage);
