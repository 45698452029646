import PrivacyIcon from '@material-ui/icons/Lock';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { submit, change, destroy } from 'redux-form';
import AgreementsForm from '../../components/forms/AgreementsForm.jsx';
import * as UtilActions from '../../redux/actions/utils.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as AgreementsActions from '../../redux/actions/agreements.actions';
import MDButton from '../../components/MDButton/MDButton.jsx';
import { BLUE, AGREEMENT_TYPES, LANGUAGES } from '../../_config/consts';

@connect(state => ({ language: state.settings.language, agreements: state.agreements, acceptedAgreements: state.agreements.acceptedAgreements }))
class TermsAndConditionsView extends React.Component {

  componentWillMount() {
    const { acceptedAgreements, dispatch } = this.props;
    if (_.isEmpty(acceptedAgreements)) dispatch(push('/'));
  }
  
  async componentDidMount() {
    const { dispatch } = this.props;
    dispatch(UtilActions.setSpinnerVisibile(true));
    try {
      const latestAgreements = await dispatch(AgreementsActions.fetchLatestAgreements({ type: AGREEMENT_TYPES.TERM }));
      _.each(latestAgreements, (a) => {
        dispatch(change('AgreementsForm', a.id, false));
      });
    } finally {
      dispatch(UtilActions.setSpinnerVisibile(false));
    }
  }

  onAcceptTermsAndCoditions() {
    const { dispatch } = this.props;
    dispatch(submit('AgreementsForm'));
  }

  onAcceptTermsAndCoditionsConfirm(values) {
    const { dispatch, acceptedAgreements } = this.props;
    let formattedAgreements = [];
    _.each(_.keys(values), (k) => {
      if (values[k]) {
        formattedAgreements = [...formattedAgreements, k];
      }
    });
    dispatch(AgreementsActions.saveAcceptedAgreements([...formattedAgreements, ...acceptedAgreements]));
    setTimeout(() => this.saveAgreements(), 500); // mini delay for redux state inconsistency
  }

  async saveAgreements() {
    const { dispatch, acceptedAgreements } = this.props;
    dispatch(UtilActions.setSpinnerVisibile(true));
    try {
      await dispatch(AgreementsActions.acceptAgreements({ ids: acceptedAgreements }));
      dispatch(UtilActions.setSpinnerVisibile(false));
      dispatch(destroy('AgreementsForm'));
      dispatch(push('/'));
      dispatch(AgreementsActions.resetAgreements());
    } catch (error) {
      dispatch(UtilActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorAcceptTermsAndPrivacy" /> },
      }));
    }
  }

  backToLogin() {
    const { dispatch } = this.props;
    dispatch(push('/login'));
  }

  render() {
    const { agreements, language } = this.props;
    const languageSuffix = language && language === LANGUAGES.ITALIAN ? 'it' : 'en';
    return (
      <div style={{ overflow: 'scroll', display: 'flex', flexDirection: 'column', backgroundColor: 'white', height: '100vh', padding: 10 }}>
        <img src="/imgs/common/logoJagoDark.png" alt="Sofia" style={{ margin: 'auto', marginTop: 60, marginBottom: 15, alignSelf: 'center', height: 60 }} />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
          <PrivacyIcon style={{ color: '#3f3f3f' }} />
          <h3 style={{ color: '#3f3f3f', textAlign: 'center', margin: 0, marginLeft: 10, fontWeight: 'bold' }}>
            <Entity entity="termOfUse" />
          </h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {agreements && agreements.data ?
            <div>
              {agreements.data.length ?
                <iframe scrolling="yes" style={{ borderColor: '#1EBE9C' }} src={`${agreements.data[0].link}/${languageSuffix}`} width="800" height="450" />
              : null}
              <AgreementsForm
                agreements={agreements.data}
                onSubmit={values => this.onAcceptTermsAndCoditionsConfirm(values)}
              />
            </div>
          : null}
        </div>
        <MDButton
          title="accept"
          onClick={() => this.onAcceptTermsAndCoditions()}
        />
      </div>
    );
  }
}

export default TermsAndConditionsView;
