// @ts-nocheck
/* eslint-disable react/prop-types */
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Line } from 'react-chartjs-2';

const ScrollableLineChart = (props) => {
  const {
    data,
    dataSetSize,
    xAxeTitle,
    yAxeTitle,
    showLegend,
    chartMaxWidth,
    chartMaxHeight,
    chartOptions,
  } = props;
  const maxWidth = chartMaxWidth || window.screen.width - 250 - 20; // 280 sidebar menu, 80 margin and padding
  const chartWidth = dataSetSize * 20 < maxWidth ? maxWidth : dataSetSize * 20;

  return (
    <div style={{ zoom: '125%', overflowX: 'scroll', width: '100%', marginBottom: 10 }}>
      <PerfectScrollbar style={{ paddingBottom: 15 }}>
        <div style={{ overflowX: 'scroll', margin: 'auto', width: chartWidth }}>
          <Line
            data={data}
            height={chartMaxHeight || 300}
            onElementsClick={props.onElementClick ? (elems) => props.onElementClick(elems) : null}
            type="line"
            tension={0}
            options={{
              responsive: true,
              events: ['click', 'mousemove'],
              maintainAspectRatio: false,
              legend: {
                display: showLegend,
                position: 'top',
              },
              scales: {
                xAxes: [{
                  categorySpacing: 2,
                  scaleLabel: {
                    display: xAxeTitle,
                    labelString: xAxeTitle,
                  },
                }],
                yAxes: [{
                  ticks: {
                    stepSize: 1,
                    min: 0,
                  },
                  scaleLabel: {
                    display: yAxeTitle,
                    labelString: yAxeTitle,
                  },
                }],
              },
              ...chartOptions,
            }}
          />
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default ScrollableLineChart;
