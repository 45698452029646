module.exports = {
  SAVE_ROLES: 'SAVE_ROLES',
  SAVE_ROLES_PAGINATION_DATA: 'SAVE_ROLES_PAGINATION_DATA',
  APPEND_ROLES: 'APPEND_ROLES',
  SET_SELECTED_ROLE: 'SET_SELECTED_ROLE',
  SET_ROLES_OPERATIONAL_MODE: 'SET_ROLES_OPERATIONAL_MODE',
  SAVE_PERMISSIONS: 'SAVE_PERMISSIONS',
  SAVE_PERMISSIONS_PAGINATION: 'SAVE_PERMISSIONS_PAGINATION',
  APPEND_PERMISSIONS: 'APPEND_PERMISSIONS',
  UPDATE_ROLE_IN_STATE: 'UPDATE_ROLE_IN_STATE',
  SET_ROLES_FILTER_FIELD: 'SET_ROLES_FILTER_FIELD',
  RESET_ROLES_FILTERS: 'RESET_ROLES_FILTERS',
  REMOVE_ROLE_FROM_STATE: 'REMOVE_ROLE_FROM_STATE',
  SAVE_PERMISSIONS_PARSED: 'SAVE_PERMISSIONS_PARSED',
  SAVE_EDITABLE_PERMISSIONS_PARSED: 'SAVE_EDITABLE_PERMISSIONS_PARSED',
};
