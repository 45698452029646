import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Warning from '@material-ui/icons/Warning';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as CardActions from '../../redux/actions/card.actions';
import { ITEM_DELIVERY_TYPES_NAMES, ITEM_TYPES_NAMES, PERMISSIONS, PERMISSION_ENTITIES } from '../../_config/consts';
import { getColorFromThemeName, isUserWithoutEmail } from '../../_config/utils';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import RemoveEmailIconCustom from '../CustomIcons/RemoveEmailIconCustom.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    minHeight: 80,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  button: {
    color: 'red',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    width: 'max-content',
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, itemRegistry: state.itemRegistry, themeName: state.settings.items.theme.data.themeName }))
class ItemRegistryTableView extends React.Component {


  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
  }

  render() {
    const {
      classes,
      viewLoading,
      themeName,
      onDeleteItem,
      onNewItem,
      itemRegistry: { selectedItem, data: { content: registryData, pagination: { number: currentPage, size, totalPages, totalElements } }},
      onSelectItem,
      onRefreshRegistry,
      onFetchItemsOnPage,
    } = this.props;
    const canCrudItems = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.ITEM);
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 140, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(registryData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell colSpan={3}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="itemRegistryTitle" /></h3>
                        <IconButton onClick={() => onRefreshRegistry()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="registryItemsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchItemsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchItemsOnPage(page)}
                        />
                      )}
                    />
                    </TableRow>
                  <TableRow>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="name" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="itemType" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="resourceSmartLocks" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="authorizedUser" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="deliveryAndSignature" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="itemDate" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="itemNotes" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnText}><Entity entity="actions" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(registryData, (registryItem) => {
                    const hasKeyId = registryItem.masterCardId || registryItem.masterCardNumber || registryItem.physicalKeyId;
                    const isUserDeleted = registryItem && registryItem.recipient && registryItem.recipient.email && !_.includes(registryItem.recipient.email, '@');
                    return (
                      <TableRow
                        key={registryItem.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={event => onSelectItem(registryItem)}
                        selected={selectedItem && selectedItem.id === registryItem.id}
                      >
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ marginTop: hasKeyId ? 0 : 15, marginBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h5 style={{ fontWeight: 'bold', lineHeight: '1.5', fontSize: 14 }}>{`${registryItem.name}`}</h5>
                            {hasKeyId ? <h5 style={{ fontSize: 14, margin: 0 }}>{`${registryItem.masterCardId || ''} ${registryItem.masterCardNumber || ''} ${registryItem.physicalKeyId || ''}`}</h5> : null}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h5 style={{ fontSize: 14, fontWeight: 'bold' }}>{<Entity entity={ITEM_TYPES_NAMES[registryItem.type]} />}</h5>
                            {registryItem.description ? <h5 style={{ fontSize: 14, marginTop: 5 }}>{registryItem.description}</h5> : null}
                          </div>
                        </TableCell>
                        <TableCell>
                          {registryItem.associatedLocks ? (
                            <div className={classes.guestRowContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <h5 style={{ fontSize: 14 }}>{`${registryItem.associatedLocks}`}</h5>
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', alignItems: 'center' }}>
                            {isUserDeleted ? (
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Warning style={{ fontSize: 13, color: 'red', marginTop: 2 }} />
                                <h5 style={{ fontWeight: 'bold', margin: 0, marginLeft: 5, fontSize: 14, color: 'red' }}><Entity entity="userDeleted" /></h5>
                              </div>
                            ) : (
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {registryItem && registryItem.recipient ? (
                                  <div>
                                    <h5 style={{ fontWeight: 'bold', fontSize: 14 }}>{`${registryItem.recipient.firstname} ${registryItem.recipient.lastname}`}</h5>
                                    {isUserWithoutEmail(registryItem.recipient) ?  (
                                      <div style={{ display: 'flex', marginTop: 7, marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}>
                                        <RemoveEmailIconCustom style={{ color: '#3f3f3f', width: 15, marginRight: 5 }} />
                                        <h5 style={{ color: '#3f3f3f', margin: 0 }}><Entity entity="noEmailSet" /></h5>
                                      </div>
                                    ) : (<h5 style={{ marginTop: 0 }}>{registryItem.recipient.email}</h5>)}
                                  </div>
                                  ) : (
                                    <div>
                                      <h5>{'---'}</h5>
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ paddingBottom: 6, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            {registryItem.deliveryMode ? <h5 style={{ fontSize: 14, fontWeight: 'bold' }}>{<Entity entity={ITEM_DELIVERY_TYPES_NAMES[registryItem.deliveryMode]} />}</h5> : null}
                            {registryItem.signatureCollected ? <h5 style={{ fontSize: 14, marginTop: 0 }}>{<Entity key={registryItem.signatureCollected} entity={registryItem.signatureCollected ? 'signatureCollectedTrue' : 'signatureCollectedFalse'} />}</h5> : null}
                          </div>
                        </TableCell>
                        <TableCell>
                          {registryItem.deliveredOn ? (
                            <div className={classes.guestRowContainer} style={{ paddingBottom: 6, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <h5 style={{ color: '#3f3f3f', margin: 0, marginTop: 5 }}>{`${moment(registryItem.deliveredOn).format('LL')}`}</h5>
                            </div>
                          ): null}
                        </TableCell>
                        <TableCell>
                          <div className={classes.guestRowContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h5 style={{ fontSize: 14 }}>{`${registryItem.notes || ''}`}</h5>
                          </div>
                        </TableCell>
                        <TableCell>
                          {canCrudItems ? (
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                onDeleteItem(registryItem.id);
                              }}
                              className={classes.button}
                            >
                              <Entity entity="delete" />
                              <DeleteIcon style={{ fontSize: 15, marginLeft: 5 }} />
                            </Button>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="registryItemsTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchItemsOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchItemsOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title="noItemsRegistry"
                    onNewEntity={canCrudItems ? () => onNewItem() : null}
                    newEntityTitle="addItemsToRegistry"
                    subtitle="startAddItems"
                    iconName="icon-simple-line-icons-user-following"
                />) : <ListLoadingView />}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ItemRegistryTableView);
