import React from 'react';
import { connect } from 'react-redux';
import ReservationDetails from '../../../components/forms/Bookey/ReservationDetails.jsx';
import * as CredentialActions from '../../../redux/actions/credential.actions';
import { MATCH_TAG_MODE } from './../../../_config/consts';


@connect(state => ({ resources: state.resources.data.content, credential: state.credentials.selectedCredential, guests: state.guests, locksMapDetails: state.locks.cachedLocksMap, user: state.user }))
class ReservationDetailsView extends React.Component {

  fetchLocksByTags(values, mode, page = 0, append = false) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchLocksByTags());
    const lockTagMatchingMode = mode || MATCH_TAG_MODE.EVERY_TAG;
    const tags = values;
    dispatch(CredentialActions.fetchLocksByTags(tags, lockTagMatchingMode, page, append));
  }

  render() {
    const { reservation, user, onDeleteReservation, onSendCheckinReminder, onCheckInReservation, credential, guests, onDeleteCheckInReservation, locksMapDetails } = this.props;
    return (
      <ReservationDetails
        reservation={reservation}
        guests={guests}
        user={user}
        locksFilteredByTags={credential.locksFilteredByTags}
        onDeleteReservation={onDeleteReservation?(reservationId) => onDeleteReservation(reservationId):null}
        onCheckInReservation={onCheckInReservation?(reservationId) => onCheckInReservation(reservationId):null}
        onSendCheckinReminder={onSendCheckinReminder?(reservationId) => onSendCheckinReminder(reservationId):null}
        onDeleteCheckInReservation={onDeleteCheckInReservation?(reservationId) => onDeleteCheckInReservation(reservationId):null}
        checkInLock={locksMapDetails}
        onFetchMoreItems={(page) => this.fetchLocksByTags(credential.locksFilteredByTags, MATCH_TAG_MODE.EVERY_TAG, page, true)}
      />
    );
  }
}

export default ReservationDetailsView;
