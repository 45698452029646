import { axiosInstance } from './rest';

export function getAccessProfiles(params) {
  const requestData = {
    params,
  };
  return axiosInstance.get('/api/v2/accessProfiles', requestData);
}

export function createAccessProfile(accessProfileDTO) {
  return axiosInstance.post('/api/v2/accessProfiles', accessProfileDTO);
}

export function deleteAccessProfile(accessProfileId) {
  return axiosInstance.delete(`/api/v2/accessProfiles/${accessProfileId}`);
}

export function updateAccessProfile(accessProfileId, accessProfileDTO) {
  return axiosInstance.put(`/api/v2/accessProfiles/${accessProfileId}`, accessProfileDTO);
}
