import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CircularProgress, withStyles } from '@material-ui/core';

const MDButton = ({ backgroundColor, onClick, title, icon, titleStyle, containerStyle, disabled, variant, type, style, isLoading, spinnerStyle }) => {

  return (
    <div style={{ margin: 20, ...containerStyle }}>
      <Button
        disabled={disabled}
        variant={variant || 'contained'}
        color="primary"
        type={type}
        fullWidth
        onClick={onClick ? (e) => onClick(e) : null}
        style={{ minHeight: 36, backgroundColor: backgroundColor ? backgroundColor : '#4DB6AC', ...style }}
      >
          <div style={{ display: 'flex', alignItems: 'center', position: 'relative'}}>
            <h5 style={{ margin: 0, color: 'white', textAlign: 'center', opacity: isLoading ? 0.5 : 1, ...titleStyle }}>
              {title}
            </h5>
            {icon}
            {isLoading ? <CircularProgress  style={{ position: 'absolute', left: '35%', color: 'white', padding: 5, marginLeft: 5, ...spinnerStyle }} size={25} /> : null}
          </div>
      </Button>
    </div>
  );
};

export default MDButton;
