import React from 'react';
import Paginator from 'react-pagify';
import pagifyBootstrapPreset from 'react-pagify-preset-bootstrap';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import segmentize from 'segmentize';

export default class TablePaginator extends React.Component {
  render() {
    return (
      <Paginator.Context
        {...pagifyBootstrapPreset}
        segments={segmentize(this.props.pagination)}
        onSelect={(page, event) => {
          event.preventDefault();
          if (page) {
            this.props.pagination.onSelect(page);
          }
        }}
        style={this.props.style}
      >
        <Paginator.Button
          className={this.props.pagination.page > 1 ? '' : 'disabled'}
          page={this.props.pagination.page > 1 ? this.props.pagination.page - 1 : 1}
        >
          <h5 style={{ margin: 0 }}><Entity entity="previous" /></h5>
        </Paginator.Button>
        <Paginator.Segment field="beginPages" />
        <Paginator.Ellipsis className="ellipsis" previousField="beginPages" nextField="previousPages" />
        <Paginator.Segment field="previousPages" />
        <Paginator.Segment field="centerPage" className="active" />
        <Paginator.Segment field="nextPages" />
        <Paginator.Ellipsis previousField="nextPages" nextField="endPages" />
        <Paginator.Segment field="endPages" />
        <Paginator.Button
          className={this.props.pagination.page + 1 <= this.props.pagination.pages ? '' : 'disabled'}
          page={this.props.pagination.page + 1 <= this.props.pagination.pages ? this.props.pagination.page + 1 : this.props.pagination.pages}
        >
          <h5 style={{ margin: 0 }}><Entity entity="next" /></h5>
        </Paginator.Button>
      </Paginator.Context>
    );
  }
}
