import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { Field, reduxForm } from 'redux-form';
import { Form } from '@sketchpixy/rubix';
import React from 'react';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CustomField from '../Fields/CustomField.jsx';
import DropFileField from '../Fields/DropFileField.jsx';

const validate = (values) => {
  const errors = {};

  return errors;
};

@reduxForm({ form: 'BookeyMapForm', validate })
class BookeyMapForm extends React.Component {
  render() {
    const { onFileRejected, imagePreview, onDeleteFile } = this.props;
    return (
      <Form>
        <Entity
          id="name"
          name="name"
          componentClass={Field}
          componentAttribute="placeholder"
          entity="insertName"
          title={<Entity entity="floorPlanName" />}
          component={CustomField}
          mandatory
        />
        <h3 style={{ fontWeight: 'bold', fontSize: 14, color: '#3f3f3f' }}><Entity entity="bookeyMapImage" /></h3>
        {imagePreview ? (
          <Card style={{ width: 'fit-content', padding: 20, display: 'flex', justifyContent: 'center', margin: 'auto', marginBottom: 20 }}>
            <img src={imagePreview} style={{ width: 'auto', maxHeight: 150 }} />
          </Card>
        ): null}
        <Field
          name="data"
          accept="image/*"
          className="drop-file-container"
          activeClassName="drop-file-container-active"
          maxSize={1048576}
          component={DropFileField}
          onFileRejected={() => onFileRejected()}
          onDeleteFile={f => onDeleteFile(f)}
        >
            <h5 style={{ marginBottom: 5 }}><Entity entity="dropFile" /></h5>
            <h5 style={{ marginTop: 0 }}><Entity entity="orSelect" /></h5>
        </Field>
      </Form>
    );
  }
} 

export default BookeyMapForm;
