import _ from 'lodash';

import {
  SHOW_MODAL,
  HIDE_MODAL,
} from './actionTypes/modal';

export function showModal(data) {
  return {
    type: SHOW_MODAL,
    data,
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}
