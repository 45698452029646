import { analyticActions as AnalyticActions, reservationActions as ReservationActions, resourceActions as ResourceActions } from '@bottega52/bookey-redux-module';
import { AppBar, Grid, withStyles } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CheckInIcon from '@material-ui/icons/CheckCircleOutline.js';
import BarChartIcon from '@material-ui/icons/InsertChart';
import AnalyticsIcon from '@material-ui/icons/ShowChart';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, initialize } from 'redux-form';
import { getColorFromThemeName } from '../../_config/utils.js';
import BookeyAnalyticsExplanationView from '../../components/Analytics/BookeyAnalyticsExplanationView.jsx';
import ScrollableBarChart from '../../components/Charts/ScrollableBarChart.jsx';
import AgendaIconCustom from '../../components/CustomIcons/AgendaIconCustom.jsx';
import CloseCircleIconCustom from '../../components/CustomIcons/CloseCircleIconCustom.jsx';
import CopyIconCustom from '../../components/CustomIcons/CopyIconCustom.jsx';
import UsersIconCustom from '../../components/CustomIcons/UsersIconCustom.jsx';
import WarningOutlineIconCustom from '../../components/CustomIcons/WarningOutlineIconCustom.jsx';
import BaseMetricChart from '../../components/MetricCharts/BaseMetricChart.jsx';
import BookeyBestResourcesList from '../../components/MetricCharts/BookeyBestResourcesList.jsx';
import BookeyBestUsersList from '../../components/MetricCharts/BookeyBestUsersList.jsx';
import OperationalView from '../../components/OperationalView/OperationalView.jsx';
import ResourcesTable from '../../components/Tables/ResourcesTable.jsx';
import UsersTable from '../../components/Tables/UsersTable.jsx';
import MetricCard from '../../components/Visitors/MetricCard.jsx';
import AnalyticsDataFilters from '../../components/forms/Analytics/AnalyticsDataFilters.jsx';
import AnalyticsTableFilters from '../../components/forms/Analytics/AnalyticsTableFilters.jsx';
import TranslatableOption from '../../components/forms/Fields/TranslatableOption.jsx';
import * as ResourceTypesEpic from '../../epics/resourcesTypes.epics';
import * as CredentialActions from '../../redux/actions/credential.actions';
import * as GuestActions from '../../redux/actions/guest.actions';
import * as MetricsActions from '../../redux/actions/metrics.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as ReservationsActions from '../../redux/actions/reservations.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import BookeyReservationsList from './BookeyReservationsList.jsx';
import ReservationDetailsView from './Reservations/ReservationDetailsView.jsx';

const styles = theme => ({
  chartsContainer: {
    flexWrap: 'unset !important',
  },
});

let filterTimeout;
const formatFormFilters = (form) => {
  if (!form || !form.values) {
    return {};
  }

  const { values } = form;
  return formatFilters(values);
};

const isReservationNotDeleted = (status) => !status.startsWith("DELETED");
const isReservationPenalty = (status) => status.endsWith("WITH_PENALTY");
const isReservationLost = (status) => status==="LOST";

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const formatFilters = (filters) => ({
  fromDate: moment(filters.fromDate).startOf('day').valueOf(),
  toDate: moment(filters.toDate).endOf('day').valueOf(),
  userIds: filters.userIds,
  workspace: filters.workspace&&filters.workspace!=="__ANY__"?filters.workspace:undefined,
  typeId: filters.type&&filters.type!=="__ANY__"?filters.type:undefined,
});


const EmptyAnalyticsView = () => (
  <div style={{ height: 100, flexDirection: 'column', margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 80 }}>
    <BarChartIcon style={{ color: '#a5a3a3', fontSize: 60 }} />
    <h3 style={{ color: '#a5a3a3', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFound" /></h3>
    <h4 style={{ color: '#a5a3a3', fontWeight: 'bold', marginTop: 0 }}><Entity entity="noAnalyticsFoundSelectDate" /></h4>
  </div>
)

@connect((state) => ({
  cachedGuestsMap: state.guests.cachedGuestsMap,
  themeName: state.settings.items.theme.data.themeName,
  tableFilters: state.form.AnalyticsTableFilters,
  filters: state.form.AnalyticsDataFilters,
  analytics: state.analytics,
  metrics: state.metrics.bookeyAnalytics,
  workspaces: state.resources.workspaces, 
  resourcesTypes: state.resourceTypes.data.content,
  reservations: state.reservations,
}))
class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      chartData: [],
      reservationAnalytics: {},
      resources: [],
      reservationAnalyticsRefreshing: false,
      reservationAnalyticsRefreshing_cards: false,
      reservationAnalyticsRefreshing_reservations: false,
      reservationAnalyticsRefreshing_resources: false,
      reservationAnalyticsRefreshing_users: false,
      cardsPrevPercText: undefined,
      weeklyWarningON: false,
      reservationsListOpen: false,
      reservationsListData: [],
      reservationsListTitle: "",
      showOperativeSection: false,
      showReservationDetails: false,
      showUnavailabilityDetails: false,
    };
  }

  async componentWillMount() {
    const { dispatch } = this.props;
    const initialValues = { fromDate: moment().startOf('month'), toDate: moment().endOf('month') };
    await dispatch(ResourceActions.fetchResourcesWorkspaces(0, 50));
    await dispatch(ResourceTypesEpic.fetchResourcesTypesByFilter(undefined, 50));
    await this.fetchResources()
    await dispatch(initialize('AnalyticsDataFilters', initialValues));
    this.fetchReservationAnalytics();
  }

  async fetchResources(filters = {}) {
    const { dispatch } = this.props;
    try {
      const resources = await dispatch(ResourceActions.fetchAllResources(filters));
      this.setState({ resources })
      return resources;
    } catch (error) {
      return []
    }
  }

  fetchAnalyticsByTab(filters) {
    const { activeTab } = this.state;
    switch (activeTab) {
      case 0:
        this.fetchReservationAnalytics();
        break;
      case 2:
        this.fetchResourceAnalytics(0, filters);
        break;
      case 1:
        this.fetchUserAnalytics(0, filters);
        break;
      default:
        break;
    }
  }

  onResetFilters() {
    const { dispatch } = this.props;
    dispatch(change('AnalyticsDataFilters', 'fromDate', moment().startOf('month')));
    dispatch(change('AnalyticsDataFilters', 'toDate', moment().endOf('month')));
    dispatch(change('AnalyticsDataFilters', 'companyName', ''));
    dispatch(change('AnalyticsDataFilters', 'userIds', null));
    dispatch(change('AnalyticsDataFilters', 'workspace', '__ANY__'));
    dispatch(change('AnalyticsDataFilters', 'type', '__ANY__'));
    setTimeout(() => this.fetchAnalyticsByTab(), 500);
  }

  onRefreshAnalytics() {
    const { filters } = this.props;
    this.fetchAnalyticsByTab(filters)
  }

  onOpenReservationsList(reservations, title) {
    this.setState({ reservationsListData: reservations })
    this.setState({ reservationsListTitle: title })
    this.setState({ reservationsListOpen: true })
  }

  selectAnalyticTab(key, filters) {
    this.setState({ activeTab: key }, () => this.fetchAnalyticsByTab(filters));
  }

  async fetchReservationAnalytics() {
    const { dispatch, filters: formFilters } = this.props;
    const formattedFilters = formatFormFilters(formFilters);
    const fromDate = formattedFilters.fromDate
    const toDate = formattedFilters.toDate
    const intervalLenghtDays = moment(toDate).diff(moment(fromDate), 'days')
    const toDatePrev = moment(fromDate).subtract(1,'days').endOf('day').valueOf();
    const fromDatePrev = moment(toDatePrev).subtract(intervalLenghtDays, 'days').startOf('day').valueOf();
    const reservationsFilters = {
      fromDate: fromDate,
      toDate: toDate,
      includeDeleted: true,
      workspace: formattedFilters.workspace,
      resourceTypeId: formattedFilters.typeId,
      onlyPersonal: false,
    };
    const reservationsFiltersPrev = {
      fromDate: fromDatePrev,
      toDate: toDatePrev,
      includeDeleted: true,
      workspace: formattedFilters.workspace,
      resourceTypeId: formattedFilters.typeId,
      onlyPersonal: false,
    };

    this.setState({ reservationAnalyticsRefreshing: true })
    this.setState({ reservationAnalyticsRefreshing_cards: true })
    this.setState({ reservationAnalyticsRefreshing_reservations: true })
    this.setState({ reservationAnalyticsRefreshing_resources: true })
    this.setState({ reservationAnalyticsRefreshing_users: true })

    try {
      await dispatch(MetricsActions.cancelFetchBookeyUsers());
      await dispatch(MetricsActions.resetBookeyUsers());

      await this.fetchResources({
        typeId: formattedFilters.typeId?formattedFilters.typeId:undefined, 
        workspace: formattedFilters.workspace?formattedFilters.workspace:undefined
      })
      const { resources } = this.state;

      this.setState({ cardsPrevPercText: `${L20NContext.getSync('bookeyDashboardCardsPreviousInterval')} ${moment(fromDatePrev, 'x').format('LL')} - ${moment(toDatePrev, 'x').format('LL')}` })

      const reservations = await dispatch(ReservationActions.fetchReservations(0, 1000, { ...reservationsFilters }));
      const reservationsPrev = await dispatch(ReservationActions.fetchReservations(0, 1000, { ...reservationsFiltersPrev }));

      await dispatch(MetricsActions.fetchBookeyAnalyticsCards(reservations, reservationsPrev, resources));
      this.setState({ reservationAnalyticsRefreshing_cards: false })

      await sleep(200);

      const validReservations = _.filter(reservations, (res) => 
        (isReservationPenalty(res.state) || isReservationLost(res.state) || isReservationNotDeleted(res.state))
      );
      const validReservationsPrev = _.filter(reservationsPrev, (res) => 
        (isReservationPenalty(res.state) || isReservationLost(res.state) || isReservationNotDeleted(res.state))
      );

      await dispatch(MetricsActions.fetchBookeyAnalyticsReservations(validReservations, validReservationsPrev, fromDate, toDate));
      this.setState({ reservationAnalyticsRefreshing_reservations: false })
      await sleep(600);  

      await dispatch(MetricsActions.fetchBookeyAnalyticsResources(validReservations, validReservationsPrev, resources, fromDate, toDate));
      this.setState({ reservationAnalyticsRefreshing_resources: false })
      await sleep(200);

      await dispatch(MetricsActions.fetchBookeyAnalyticsUsers(validReservations));
      const uniqueUserIds = _.uniq(_.map([...validReservations,...validReservationsPrev], 'userId'));
      if (uniqueUserIds && uniqueUserIds.length) {
        await dispatch(MetricsActions.setBookeyUsersLoading(true));
        await dispatch(MetricsActions.fetchBookeyUsers(uniqueUserIds))
      }
      await sleep(200);
      this.setState({ reservationAnalyticsRefreshing_users: false })

      // Finish
      const { reservationAnalytics } = this.state;
      this.setState({ 
        reservationAnalytics: {
          ...reservationAnalytics,
          reservationsAnalyticsLastUpdate: moment(),
  
          validReservations: validReservations,
          validReservationsPrev: validReservationsPrev,
        }
      });
      this.setState({ reservationAnalyticsRefreshing: false })
    } 
    catch (error) {
      this.setState({ reservationAnalyticsRefreshing: false })
      this.setState({ reservationAnalyticsRefreshing_cards: false })
      this.setState({ reservationAnalyticsRefreshing_reservations: false })
      this.setState({ reservationAnalyticsRefreshing_resources: false })
      this.setState({ reservationAnalyticsRefreshing_users: false })
      throw error;
    }
  }

  async fetchResourceAnalytics(page, filters) {
    const { dispatch, filters: formFilters } = this.props;
    const formattedFilters = formatFormFilters(formFilters);
    const analyticsFilters = {
      ..._.omit(formattedFilters, 'userIds'),
      ..._.omit(filters, 'userIds'),
    };
    try {
      const analytics = await dispatch(AnalyticActions.fetchResourceAnalytics(page, 20, analyticsFilters));
      const chartLabels = _.map(analytics, analyticData => (analyticData && analyticData.resource && analyticData.resource.name ? analyticData.resource.name : '--'));
      const chartDataSet = [{
        label: L20NContext.getSync('totalReservations'),
        backgroundColor: 'rgba(27,151,194,0.2)',
        borderColor: 'rgba(27,151,194,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(27,151,194,0.4)',
        hoverBorderColor: 'rgba(27,151,194,1)',
        data: _.map(analytics, analy => analy.totalReservations),
      },
      {
        label: L20NContext.getSync('usageHours'),
        backgroundColor: 'rgba(0,150,136,0.2)',
        borderWidth: 1,
        borderColor: 'rgba(0,150,136,1)',
        hoverBackgroundColor: 'rgba(0,150,136,0.4)',
        hoverBorderColor: 'rgba(0,150,136,1)',
        data: _.map(analytics, analy => (analy.usageHours - analy.lostHours)),
      },
      {
        label: L20NContext.getSync('penaltyHours'),
        backgroundColor: 'rgba(233,88,65,0.2)',
        borderColor: 'rgba(233,88,65,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(233,88,65,0.4)',
        hoverBorderColor: 'rgba(233,88,65,1)',
        data: _.map(analytics, analy => (analy.deletedByUserWithPenaltyHours + analy.lostHours)),
      },
    ];
      const chartData = {
        labels: chartLabels,
        datasets: chartDataSet,
      };
      this.setState({ chartData });
    } catch (error) {
      throw error;
    }
  }

  async fetchUserAnalytics(page, filters) {
    const { dispatch, filters: formFilters } = this.props;
    const formattedFilters = formatFormFilters(formFilters);
    try {
      let analytics = [];
      if (formattedFilters && formattedFilters.userIds && !_.isEmpty(formattedFilters.userIds)) {
        analytics = await dispatch(AnalyticActions.searchUserAnalytics(page, 20, { ...formattedFilters, ...filters }));
      } else {
        analytics = await dispatch(AnalyticActions.fetchUserAnalytics(page, 20, { ...formattedFilters, ...filters }));
      }
      await dispatch(GuestActions.cacheGuestsDetailsIfNeededFromAnalytics(analytics));
      const { cachedGuestsMap } = this.props;
      const chartLabels = _.map(analytics, analyticData => (analyticData && analyticData.userId && cachedGuestsMap[analyticData.userId] ? `${cachedGuestsMap[analyticData.userId].firstname} ${cachedGuestsMap[analyticData.userId].lastname}` : '--'));
      const chartDataSet = [
        {
          label: L20NContext.getSync('totalReservations'),
          backgroundColor: 'rgba(27,151,194,0.2)',
          borderColor: 'rgba(27,151,194,1)',
          hoverBackgroundColor: 'rgba(27,151,194,0.4)',
          hoverBorderColor: 'rgba(27,151,194,1)',
          borderWidth: 1,
          data: _.map(analytics, analy => analy.totalReservations),
        },
        {
          label: L20NContext.getSync('usageHours'),
          backgroundColor: 'rgba(0,150,136,0.2)',
          borderColor: 'rgba(0,150,136,1)',
          hoverBackgroundColor: 'rgba(0,150,136,0.4)',
          hoverBorderColor: 'rgba(0,150,136,1)',
          borderWidth: 1,
          data: _.map(analytics, analy => (analy.usageHours - analy.lostHours)),
        },
        {
          label: L20NContext.getSync('penaltyHours'),
          backgroundColor: 'rgba(233,88,65,0.2)',
          borderColor: 'rgba(233,88,65,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(233,88,65,0.4)',
          hoverBorderColor: 'rgba(233,88,65,1)',
          data: _.map(analytics, analy => (analy.deletedByUserWithPenaltyHours + analy.lostHours)),
        },
      ];
      const chartData = {
        labels: chartLabels,
        datasets: chartDataSet,
      };
      this.setState({ chartData });
    } catch (error) {
      throw error;
    }
  }

  async fetchDetailedAnalyticsByResource(resourceId, page) {
    const { dispatch, filters } = this.props;
    const formattedFilters = formatFormFilters(filters);
    try {
      const analytics = await dispatch(AnalyticActions.fetchDetailedAnalyticsByResource(resourceId, page, 20, _.omit(formattedFilters, 'userIds')));
      _.each(analytics, (a) => dispatch(GuestActions.cacheGuestDetailsIfNeeded(a.userId)));
    } catch (error) {
      throw error;
    }
  }
  
  async fetchDetailedAnalyticsByUser(userId, page) {
    const { dispatch, filters } = this.props; 
    const formattedFilters = formatFormFilters(filters);
    try {
      await dispatch(AnalyticActions.fetchDetailedAnalyticsByUser(userId, page, 20, _.omit(formattedFilters, 'userIds')));
    } catch (error) {
      throw error;
    }
  }

  async tableAccordionOpened(index, value) {
    const { activeTab } = this.state;
    const { analytics } = this.props;
    if (activeTab === 2) {
      const selectedAnalytic = analytics.resource.data.content[index];
      if (selectedAnalytic) {
        await this.fetchDetailedAnalyticsByResource(selectedAnalytic.resource.id);
      }
    } else if (activeTab === 1) {
      const selectedAnalytic = analytics.user.data.content[index];
      if (selectedAnalytic) {
        await this.fetchDetailedAnalyticsByUser(selectedAnalytic.userId);
      }
    }
  }

  async handleDatesChange(fromDate, toDate, refreshAnalytics) {
    const { dispatch } = this.props;
    const { activeTab } = this.state;
    const startDate = fromDate ? moment(fromDate).startOf('day').valueOf() : moment().startOf('month').valueOf();
    const endDate = toDate ? moment(toDate).endOf('day').valueOf() : moment().endOf('month').valueOf();
    await dispatch(change('AnalyticsDataFilters', 'fromDate', startDate));
    await dispatch(change('AnalyticsDataFilters', 'toDate', endDate));
    if (!refreshAnalytics) return;
    if (activeTab===0) {
      this.setState({ reservationAnalyticsRefreshing: true })
      setTimeout(async () => {
        this.fetchAnalyticsByTab();
      }, 100);
    }
    else {
      this.fetchAnalyticsByTab({
        fromDate: startDate,
        toDate: endDate,
      });
    }
    
  }

  async onSelectWorkspace(value) {
    const { dispatch } = this.props;
    this.setState({ reservationAnalyticsRefreshing: true })
    await dispatch(change('AnalyticsDataFilters', 'workspace', value));
    this.fetchAnalyticsByTab();
  }

  async onSelectResourceType(value) {
    const { dispatch } = this.props;
    this.setState({ reservationAnalyticsRefreshing: true })
    await dispatch(change('AnalyticsDataFilters', 'type', value));
    this.fetchAnalyticsByTab();
  }

  async exportResourcesAnalytics(format) {
    const { dispatch, filters } = this.props;
    const formattedFilters = formatFormFilters(filters);
    dispatch(ReservationsActions.exportResourceAnalytics(`Resource-Reservations-export-${moment().toLocaleString()}`, format, _.omit(formattedFilters, 'userIds')));
  }

  async exportUsersAnalytics(format) {
    const { dispatch, filters } = this.props;
    const formattedFilters = formatFormFilters(filters);
    dispatch(ReservationsActions.exportUsersAnalytics(`Users-Reservations-export-${moment().toLocaleString()}`, format, formattedFilters));
  }


  exportReservations(format) {
    const { dispatch, metrics } = this.props;
    const reservationsData = metrics && metrics.reservations && metrics.reservations.reservationsData ? metrics.reservations.reservationsData : [];
    dispatch(ReservationsActions.exportReservations(`Reservations-export-${moment().toLocaleString()}`, format, reservationsData));
  }

  async onExportAnalytics(format) {
    const { activeTab } = this.state;

    if (activeTab === 2) {
      await this.exportResourcesAnalytics(format);
    } else if (activeTab === 1) {
      await this.exportUsersAnalytics(format);
    } else if (activeTab === 0) {
      await this.exportReservations(format);
    } 
  }

  onShowAnalyticsInfo() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="bookeyAnalyticsTitle" />,
        content: <BookeyAnalyticsExplanationView />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onSearchGuestsFromCompanyName(value) {
    const { dispatch } = this.props;
    if (value && value.length >= 1) {
      if (filterTimeout) clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => {
        const guests = await dispatch(GuestActions.fetchGuestsByCompanyName(value));
        const guestIds = _.map(guests, guest => guest.id);
        dispatch(change('AnalyticsDataFilters', 'userIds', guestIds));
        this.fetchUserAnalytics(0);
      }, 500);
    } else {
      dispatch(change('AnalyticsDataFilters', 'userIds', []));
      setTimeout(async () => {
        this.fetchUserAnalytics(0);
      }, 500);
    }
  }

  async onSelectReservation(reservation) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      let reservationResource = reservation && reservation.resource;
      const resourceHasCustomMedia = reservationResource && reservationResource.customMedia;
      if (resourceHasCustomMedia) {
        const newMedia = await dispatch(ResourceActions.fetchResourceMedia(reservation.resource.id));
        reservationResource = {
          ...reservationResource,
          media: newMedia,
        }
      }
      await dispatch(ReservationActions.setSelectedReservation({...reservation, resource: reservationResource }));
      try {
        await dispatch(GuestActions.cacheGuestDetailsIfNeeded(reservation.userId));
      } catch (error) {
      }
      await dispatch(CredentialActions.cancelFetchLocksByTags());
      await dispatch(CredentialActions.fetchLocksByTags(reservation.resource.resourceSmartLockTags, reservation.resource.lockTagMatchingMode));
      this.setState({ showReservationDetails: true, showOperativeSection: false, showUnavailabilityDetails: false });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    this.setState({ showOperativeSection: false });
    this.setState({ showReservationDetails: false });
  }

  render() {
    const { filters, tableFilters, analytics, cachedGuestsMap, classes, themeName, resourcesTypes, workspaces, metrics, dispatch, reservations: { selectedReservation } } = this.props;
    const { activeTab, chartData, reservationAnalytics, 
      reservationAnalyticsRefreshing, reservationAnalyticsRefreshing_cards, 
      reservationAnalyticsRefreshing_resources, reservationAnalyticsRefreshing_users, reservationAnalyticsRefreshing_reservations, 
      cardsPrevPercText
    } = this.state;
    const areResourcesAnalyticsValid = analytics && analytics.resource && analytics.resource.data && analytics.resource.data.content && !_.isEmpty(analytics.resource.data.content);
    const areUsersAnalyticsDataValid = analytics && analytics.user && analytics.user.data && analytics.user.data.content && !_.isEmpty(analytics.user.data.content);

    const workspaceTitleLabel = dispatch(UtilsActions.getResourcesWorkspaceLabel());
    const workspaceOptions = [{ label: workspaceTitleLabel, value: '__ANY__' }, ..._.map(_.compact(workspaces), workspace => ({ label: workspace, value: workspace }))];

    const reservationsAnalyticsLastUpdate = reservationAnalytics?.lastUpdate;

    const totalReservations = metrics.cards?.totalReservations;
    const totalHoursSuccessful = metrics.cards?.totalHoursSuccessful;
    const totalHoursWithPenalty = metrics.cards?.totalHoursWithPenalty;
    const totalHoursLost = metrics.cards?.totalHoursLost;
    
    const totalReservationsPrevPerc = metrics.cards?.totalReservationsPrevPerc;
    const totalHoursSuccessfulPrevPerc = metrics.cards?.totalHoursSuccessfulPrevPerc;
    const totalHoursWithPenaltyPrevPerc = metrics.cards?.totalHoursWithPenaltyPrevPerc;
    const totalHoursLostPrevPerc = metrics.cards?.totalHoursLostPrevPerc;
    
    const topUsers = metrics.users&&metrics.users.topUsers?metrics.users.topUsers:[]
    const usersDetails = metrics.usersDetails?metrics.usersDetails:[]
    const topUsers_parsed = topUsers.map(reservationUser=>{
      const userSearch = usersDetails.filter(e=>e.id.toString()===reservationUser.userId)
      return {
        ...reservationUser,
        user:userSearch.length?userSearch[0]:undefined
      }
    })

    const topResources = metrics.resources&&metrics.resources.topResources?metrics.resources.topResources:[];

    const resourceTypesReservationTimeChartData = metrics.resources?.resourceTypesReservationTimeChartData
    const resourceTypesUtilizationChartData = metrics.resources?.resourceTypesUtilizationChartData
    const resourceTypesReservationTimeChartDataPrev = metrics.resources?.resourceTypesReservationTimeChartDataPrev
    const resourceTypesUtilizationChartDataPrev = metrics.resources?.resourceTypesUtilizationChartDataPrev

    const resourceReservationTimeChartData = metrics.resources?.resourceReservationTimeChartData
    const resourceUtilizationChartData = metrics.resources?.resourceUtilizationChartData
    const resourceReservationTimeChartDataPrev = metrics.resources?.resourceReservationTimeChartDataPrev
    const resourceUtilizationChartDataPrev = metrics.resources?.resourceUtilizationChartDataPrev
    
    const reservationsPieChartData = metrics.reservations?.reservationsPieChartData

    const reservationsHourlyChartData = metrics.reservations?.reservationsHourlyChartData
    const hoursHourlyChartData = metrics.reservations?.hoursHourlyChartData
    const reservationsHourlyChartData_avg = metrics.reservations?.reservationsHourlyChartData_avg
    const hoursHourlyChartData_avg = metrics.reservations?.hoursHourlyChartData_avg

    const reservationsHourlyChartDataPrev = metrics.reservations?.reservationsHourlyChartDataPrev
    const hoursHourlyChartDataPrev = metrics.reservations?.hoursHourlyChartDataPrev
    const reservationsHourlyChartData_avgPrev = metrics.reservations?.reservationsHourlyChartData_avgPrev
    const hoursHourlyChartData_avgPrev = metrics.reservations?.hoursHourlyChartData_avgPrev

    const reservationsDayChartData = metrics.reservations?.reservationsDayChartData
    const hoursDayChartData = metrics.reservations?.hoursDayChartData
    const reservationsDayChartData_avg = metrics.reservations?.reservationsDayChartData_avg
    const hoursDayChartData_avg = metrics.reservations?.hoursDayChartData_avg

    const reservationsDayChartDataPrev = metrics.reservations?.reservationsDayChartDataPrev
    const hoursDayChartDataPrev = metrics.reservations?.hoursDayChartDataPrev
    const reservationsDayChartData_avgPrev = metrics.reservations?.reservationsDayChartData_avgPrev
    const hoursDayChartData_avgPrev = metrics.reservations?.hoursDayChartData_avgPrev

    const reservationsMonthChartData = metrics.reservations?.reservationsMonthChartData
    const hoursMonthChartData = metrics.reservations?.hoursMonthChartData
    const reservationsMonthChartData_avg = metrics.reservations?.reservationsMonthChartData_avg
    const hoursMonthChartData_avg = metrics.reservations?.hoursMonthChartData_avg

    const reservationsMonthChartDataPrev = metrics.reservations?.reservationsMonthChartDataPrev
    const hoursMonthChartDataPrev = metrics.reservations?.hoursMonthChartDataPrev
    const reservationsMonthChartData_avgPrev = metrics.reservations?.reservationsMonthChartData_avgPrev
    const hoursMonthChartData_avgPrev = metrics.reservations?.hoursMonthChartData_avgPrev

    const weeklyWarningON = metrics.reservations?.weeklyWarningON

    const textColor = getColorFromThemeName(themeName);

    return (
      <div style={{ marginTop: 0, marginLeft: 0, overflowY: 'scroll', overflowX: 'hidden', height: '120vh' }}>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, index) => this.selectAnalyticTab(index,filters)}
          style={{marginLeft: 20}}
          TabIndicatorProps={{
            style: { display: 'none' }
          }}
        >
          <Tab icon={<AnalyticsIcon style={{ fontSize: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="analytics" /></h5>} />
          <Tab icon={<UsersIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="users" /></h5>} />
          <Tab icon={<CopyIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="resources" /></h5>} />
        </Tabs>
        <AppBar position="sticky" color="default" style={{ padding: 10,  marginLeft: -20, width: '120%', zIndex: 100 }}>
          <AnalyticsDataFilters
            hideCompanyName={activeTab === 0 || activeTab === 2}
            hideResourceType={activeTab !== 0}
            hideWorkspace={activeTab !== 0}
            disableComponents={activeTab === 0 && reservationAnalyticsRefreshing}
            startDate={filters && filters.values && filters.values.fromDate}
            endDate={filters && filters.values && filters.values.toDate}
            onDatesChanged={(fromDate, toDate, refreshAnalytics) => this.handleDatesChange(fromDate, toDate, refreshAnalytics)}
            onResetDateFilters={() => this.onResetFilters()}
            onSearchGuestsFromCompanyName={(value) => this.onSearchGuestsFromCompanyName(value)}
            onResetFilters={() => this.onResetFilters()}
            onShowAnalyticsInfo={() => this.onShowAnalyticsInfo()}
            onExportAnalytics={(format) => this.onExportAnalytics(format)}
            onSelectWorkspace={value => this.onSelectWorkspace(value)}
            workspaceOptions={_.map(workspaceOptions, workspace =>
              <Entity
                key={workspace.value}
                componentClass={TranslatableOption}
                value={workspace.value}
                componentAttribute="text"
                entity={workspace.label}
              />)}
            onSelectResourceType={value => this.onSelectResourceType(value)}
            resourceTypeOptions={[
              (<Entity
                key={"anyResourceTypeFilter"}
                componentClass={TranslatableOption}
                value={"__ANY__"}
                componentAttribute="text"
                entity={"anyResourceTypeFilter"}
              />),
              ..._.map(_.sortBy(resourcesTypes, (type => type.name.toLowerCase())), type =>
                <option value={type.id} key={type.id}>
                  {type.name}
                </option>
              )
            ]
            }
          />
        </AppBar>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginTop:40 }}>
          {activeTab === 2 ? (
            <div style={{ marginBottom: 100, paddingRight: 20 }}>
              {areResourcesAnalyticsValid ?
                <ScrollableBarChart
                  height={100}
                  data={chartData}
                  dataSetSize={_.size(analytics.resource.data.content)}
                  legend
                /> : (
                  <EmptyAnalyticsView />
                )}
              {areResourcesAnalyticsValid ? (
                <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                  <AnalyticsTableFilters hideDate type="resources" />
                  <ResourcesTable
                    highlightGreaterThan={tableFilters && tableFilters.values && tableFilters.values.highlightGreaterThan}
                    resourceFilter={tableFilters && tableFilters.values && tableFilters.values.resourceFilter}
                    rows={analytics.resource}
                    onAccordionOpened={(index, value) => this.tableAccordionOpened(index, value)}
                    cachedGuestsMap={cachedGuestsMap}
                    onFetchResources={page => this.fetchResourceAnalytics(page, true)}
                    onFetchDetailedResources={(resourceId, page) => this.fetchDetailedAnalyticsByResource(resourceId, page)}
                  />
                </div>
              ) : null}
              </div>
          ):null} 
          {activeTab === 1? (
            <div style={{ marginBottom: 100, paddingRight: 20 }}>
              {areUsersAnalyticsDataValid ?
                <ScrollableBarChart
                  height={100}
                  data={chartData}
                  dataSetSize={_.size(analytics.user.data.content)}
                  legend
                /> : (
                  <EmptyAnalyticsView />
                )}
              {areUsersAnalyticsDataValid ? (
                <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                  <AnalyticsTableFilters hideDate type="users" />
                  <UsersTable
                    highlightGreaterThan={tableFilters && tableFilters.values && tableFilters.values.highlightGreaterThan}
                    userFilter={tableFilters && tableFilters.values && tableFilters.values.userFilter}
                    rows={analytics.user}
                    onAccordionOpened={(index, value) => this.tableAccordionOpened(index, value)}
                    cachedGuestsMap={cachedGuestsMap}
                    onFetchUsers={page => this.fetchUserAnalytics(page, true)}
                    onFetchDetailedUsers={(userId, page) => this.fetchDetailedAnalyticsByUser(userId, page)}
                  />
                </div>
              ) : null}
            </div>
          ):null}
          {activeTab === 0? (
            <div style={{ marginBottom: 100, paddingRight: 20 }}>
              <Grid container spacing={2} className={classes.chartsContainer} style={{marginBottom:20}}>
                <Grid item md={3} xs={12}>
                  <MetricCard
                    title={<Entity entity="reservations" />}
                    metricNumber={totalReservations}
                    metricNumberPrevPerc={totalReservationsPrevPerc}
                    icon={<AgendaIconCustom style={{ width: 30 }} />}
                    isRefreshing={reservationAnalyticsRefreshing_cards}
                    prevPercText={cardsPrevPercText}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <MetricCard
                    title={<Entity entity="reservationsHoursUsed" />}
                    metricNumber={totalHoursSuccessful}
                    metricNumberPrevPerc={totalHoursSuccessfulPrevPerc}
                    icon={<CheckInIcon style={{ fontSize: 30 }} />}
                    isRefreshing={reservationAnalyticsRefreshing_cards}
                    prevPercText={cardsPrevPercText}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <MetricCard
                    title={<Entity entity="reservationsHoursLost" />}
                    metricNumber={totalHoursLost}
                    metricNumberPrevPerc={totalHoursLostPrevPerc}
                    icon={<CloseCircleIconCustom style={{ width: 30 }} />}
                    isRefreshing={reservationAnalyticsRefreshing_cards}
                    prevPercText={cardsPrevPercText}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <MetricCard
                    title={<Entity entity="reservationsHoursPenalty" />}
                    metricNumber={totalHoursWithPenalty}
                    metricNumberPrevPerc={totalHoursWithPenaltyPrevPerc}
                    icon={<WarningOutlineIconCustom style={{ width: 30 }} />}
                    isRefreshing={reservationAnalyticsRefreshing_cards}
                    prevPercText={cardsPrevPercText}
                  />
                </Grid>
              </Grid>
              <div style={{ margin:5, marginBottom:20}}>
                <BaseMetricChart
                  metricType="bookeyHourlyReservations"
                  textColor={textColor}
                  warningTextWeekly={weeklyWarningON?L20NContext.getSync('bookeyReservationsTrendWeeklyWarning'):undefined}
                  reservationsHourlyChartData={reservationsHourlyChartData}
                  hoursHourlyChartData={hoursHourlyChartData}
                  reservationsHourlyChartData_avg={reservationsHourlyChartData_avg}
                  hoursHourlyChartData_avg={hoursHourlyChartData_avg}
                  reservationsHourlyChartDataPrev={reservationsHourlyChartDataPrev}
                  hoursHourlyChartDataPrev={hoursHourlyChartDataPrev}
                  reservationsHourlyChartData_avgPrev={reservationsHourlyChartData_avgPrev}
                  hoursHourlyChartData_avgPrev={hoursHourlyChartData_avgPrev}
                  reservationsDayChartData={reservationsDayChartData}
                  hoursDayChartData={hoursDayChartData}
                  reservationsDayChartData_avg={reservationsDayChartData_avg}
                  hoursDayChartData_avg={hoursDayChartData_avg}
                  reservationsDayChartDataPrev={reservationsDayChartDataPrev}
                  hoursDayChartDataPrev={hoursDayChartDataPrev}
                  reservationsDayChartData_avgPrev={reservationsDayChartData_avgPrev}
                  hoursDayChartData_avgPrev={hoursDayChartData_avgPrev}
                  reservationsMonthChartData={reservationsMonthChartData}
                  hoursMonthChartData={hoursMonthChartData}
                  reservationsMonthChartData_avg={reservationsMonthChartData_avg}
                  hoursMonthChartData_avg={hoursMonthChartData_avg}
                  reservationsMonthChartDataPrev={reservationsMonthChartDataPrev}
                  hoursMonthChartDataPrev={hoursMonthChartDataPrev}
                  reservationsMonthChartData_avgPrev={reservationsMonthChartData_avgPrev}
                  hoursMonthChartData_avgPrev={hoursMonthChartData_avgPrev}
                  lastUpdate={reservationsAnalyticsLastUpdate}
                  isRefreshing={reservationAnalyticsRefreshing_reservations}
                  onRefreshMetric={() => this.onRefreshAnalytics()}
                  onDataClick={(reservations,title) => this.onOpenReservationsList(reservations,title)}
                />
              </div>
              <Grid container spacing={2} className={classes.chartsContainer} style={{marginBottom:20, display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                <Grid item md={6} xs={12}>
                  <div style={{ margin:5 }}>
                    <BookeyBestResourcesList
                      textColor={textColor}
                      resources={topResources}
                      isRefreshing={reservationAnalyticsRefreshing_resources}
                      lastUpdate={reservationsAnalyticsLastUpdate}
                      onRefreshMetric={() => this.onRefreshAnalytics()}
                    />
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div style={{ margin:5 }}>
                    <BookeyBestUsersList
                      textColor={textColor}
                      users={topUsers_parsed}
                      isRefreshing={reservationAnalyticsRefreshing_users}
                      lastUpdate={reservationsAnalyticsLastUpdate}
                      onRefreshMetric={() => this.onRefreshAnalytics()}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.chartsContainer} style={{marginBottom:20, display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                <Grid item md={9} xs={12}>
                  <div style={{ margin:5 }}>
                    <BaseMetricChart
                      metricType="resourceTypesChartData"
                      textColor={textColor}
                      filters={filters}
                      resourceTypesReservationTimeChartData={resourceTypesReservationTimeChartData}
                      resourceTypesUtilizationChartData={resourceTypesUtilizationChartData}
                      resourceTypesReservationTimeChartDataPrev={resourceTypesReservationTimeChartDataPrev}
                      resourceTypesUtilizationChartDataPrev={resourceTypesUtilizationChartDataPrev}
                      resourceReservationTimeChartData={resourceReservationTimeChartData}
                      resourceUtilizationChartData={resourceUtilizationChartData}
                      resourceReservationTimeChartDataPrev={resourceReservationTimeChartDataPrev}
                      resourceUtilizationChartDataPrev={resourceUtilizationChartDataPrev}
                      lastUpdate={reservationsAnalyticsLastUpdate}
                      forcedChartWidth={(3*(window.screen.width - 350)/4)/1.25}
                      isRefreshing={reservationAnalyticsRefreshing_resources}
                      onRefreshMetric={() => this.onRefreshAnalytics()}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  {reservationsPieChartData && !_.isEmpty(reservationsPieChartData) ? (
                    <div style={{ margin:5 }}>
                      <BaseMetricChart
                        metricType="reservationsPieChartData"
                        textColor={textColor}
                        reservationsPieChartData={reservationsPieChartData}
                        lastUpdate={reservationsAnalyticsLastUpdate}
                        isRefreshing={reservationAnalyticsRefreshing_reservations}
                        onRefreshMetric={() => this.onRefreshAnalytics()}
                      />
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          ):null}
        </div>
        <OperationalView
          themeName={themeName}
          isVisible={this.state.reservationsListOpen}
          onClose={() => this.setState({reservationsListOpen:false})}
          title={<span><Entity entity="reservations" />{` (${this.state.reservationsListTitle})`}</span>}
        >
          <BookeyReservationsList
            reservations={this.state.reservationsListData}
            usersDetails={usersDetails}
            onRowClick={reservation => this.onSelectReservation(reservation)}
          />
        </OperationalView>
        <OperationalView
          themeName={themeName}
          isVisible={this.state.showReservationDetails}
          onClose={() => this.closeOperationalSection()}
          title={<Entity entity="reservationDetails" />}
        >
          <ReservationDetailsView
            reservation={selectedReservation}
            onDeleteReservation={null}
            onCheckInReservation={null}
            onDeleteCheckInReservation={null}
            onSendCheckinReminder={null}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default withStyles(styles)(Analytics);