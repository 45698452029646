import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import _ from 'lodash';
import { DateRangePicker } from 'react-dates';
import { BLUE, GREEN, RED } from '../../../_config/consts';
import MDButton from '../../MDButton/MDButton.jsx';
import { Button } from '@material-ui/core';

const SelectionButton = ({ isSelected, title, classes, onSelect }) => (
  <Button
    variant={isSelected ? 'contained' : 'outlined'}
    style={{
      backgroundColor: isSelected ? BLUE : 'white',
      width: 'fit-content',
      padding: 1,
      paddingLeft: 5,
      paddingRight: 5,
      border: '1px #1B97C2 solid',
      borderRadius: 200,
      marginLeft: 5,
      marginRight: 5,
      minWidth: 53,
      height: 35,
    }}
    onClick={() => onSelect()}
  >
    <h5 style={{ margin: 0, color: isSelected ? 'white' : BLUE }}>{title}</h5>
  </Button>
)


export default class WalletFiltersOptionSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: undefined,
    }
  }
  
  onSelectOption(index, option) {
    const { selectedIndex } = this.state;
    if (selectedIndex === index) {
      const { onResetOptionSelection } = this.props;
      this.setState({ selectedIndex: undefined });
      onResetOptionSelection()
    } else {
      this.setState({ selectedIndex: index });
      const { onSelectOption } = this.props;
      onSelectOption(option);
    }
  }

  render() {
    const { options, classes } = this.props;
    const { selectedIndex } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
        {_.map(options, (option, index) => (
          <SelectionButton
            key={option.title}
            classes={classes}
            title={option.title}
            isSelected={index === selectedIndex}
            onSelect={() => this.onSelectOption(index, option)}
          />
        ))}
      </div>
    );
  }
}