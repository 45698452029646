
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Cancel';
import { Modal } from '@sketchpixy/rubix';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, destroy, initialize } from 'redux-form';
import CardsExplanationView from '../components/Cards/CardsExplanationView.jsx';
import PinIconCustom from '../components/CustomIcons/PinIconCustom.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PinsExplanationView from '../components/Pins/PinsExplanationView.jsx';
import PinsListView from '../components/Pins/PinsListView.jsx';
import PinsOperationalSection from '../components/Pins/PinsOperationalSection.jsx';
import PinsTableView from '../components/Pins/PinsTableView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as CardActions from '../redux/actions/card.actions';
import * as CredentialActions from '../redux/actions/credential.actions';
import * as GuestsActions from '../redux/actions/guest.actions';
import * as LogEventsActions from '../redux/actions/logEvents.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as SettingsActions from '../redux/actions/setting.actions';
import * as TagActions from '../redux/actions/tag.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import { CARD_TYPES, DEFAULT_CREDENTIAL_TIME_FRAME, LICENSE_TYPES, MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES, RED, VIEW_MODES } from '../_config/consts';
import { saveDataToLocalStorage } from '../_config/utils.js';
import { IconButton } from '@material-ui/core';
import PinShareSection from '../components/Pins/PinShareSection.jsx';


let filterTimeout;
@connect(state => ({ cards: state.cards, companyConfigurations: state.user.companyConfigurations, languange: state.settings.language, viewLoading: state.utils.viewLoading, routing: state.router, themeName: state.settings.items.theme.data.themeName }))
class PinsView extends React.Component {
  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('pinsViewMode');
    this.state = {
      showDeletedCardDetails: false,
      showMasterElectionForm: false,
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
      showSharingSection: false,
      selectedPinToShare: null,
    };
  }

  async componentWillMount() {
    const { dispatch, routing } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    dispatch(CardActions.setSelectedPin({}));
    dispatch(UtilsActions.setViewLoading(true));
    try {
      dispatch(GuestsActions.resetGuestsFilters());
      dispatch(CardActions.resetPinsFilters());

      await dispatch(CardActions.fetchPins());
      dispatch(SettingsActions.fetchCompanyMetrics());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
    this.closeOperationalSection();

    const searchGuest = parsed.searchGuest;
    if (searchGuest) {
      try {
        const guest = JSON.parse(searchGuest);
        const guestOption = { value: guest.id, label: `${guest.firstname} ${guest.lastname} ${guest.email}` };
        this.onSelectGuest(guestOption);
        dispatch(change('SearchBarForm', 'guestSelected', guestOption));
      } catch (error) {}
    }
    const deviceId = parsed.deviceId;
    if (deviceId) {
      const pin = await dispatch(CardActions.fetchPinFromDeviceId(deviceId));
      if (pin) {
        this.onSelectPin(pin);
      }
    }
    
    const newElementForGuest = parsed.newElementForGuest;
    if (newElementForGuest) {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      try {
        const guestTagId = JSON.parse(newElementForGuest);
        await dispatch(CredentialActions.fetchGuestsByTags({ id: guestTagId }, 'AT_LEAST_ONE_TAG'));
        const guestTagDetails = await dispatch(TagActions.fetchGuestTagDetails(guestTagId));
        this.onNewCredentialButtonClicked();
        dispatch(change('PinCredentialsForm', 'guestTags', guestTagDetails));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } catch (error) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    }
    const newEntity = parsed.newEntity;
    if (newEntity) {
      this.onNewCredentialButtonClicked();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CardActions.setSelectedPin({}));
  }

  onNewCredentialButtonClicked() {
    const { dispatch } = this.props;
    dispatch(CardActions.setSelectedPin({}));
    dispatch(CredentialActions.selectCredential({}));
    dispatch(initialize('PinCredentialsForm', {
      timeIntervalFrom: moment().startOf('day').valueOf(),
      timeIntervalTo: moment().endOf('day').valueOf(),
      dateIntervalTimeFrom: moment().startOf('day').valueOf(),
      dateIntervalTimeTo: moment().endOf('day').valueOf(),
      daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
      lockTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
      type: CARD_TYPES.GENERIC_CARD,
      credentialTimeframe: DEFAULT_CREDENTIAL_TIME_FRAME,
    }));
    if (this.cardsOperationalSection) {
      this.cardsOperationalSection.getWrappedInstance().selectTab(0);
    }
    dispatch(CardActions.setOperationalMode(true));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(true));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
    dispatch(CardActions.setSelectedPin({}));
  }

  async fetchPinsCredentials() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    dispatch(CardActions.setOperationalMode(false));
    try {
      dispatch(CardActions.setSelectedPin({}));
      await dispatch(CardActions.fetchPins());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
      dispatch(CardActions.setOperationalMode(false));
    }
  }

  async onSetPinsFilter(field, value) {
    const { dispatch } = this.props;
    if (value && ((_.isArray(value) && !_.isEmpty(value)) || (_.isString(value) && value.length >= 2))) {
      dispatch(CardActions.setPinsFilter(field, _.trim(value)));
      if (filterTimeout) clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => {
        this.setState({ viewLoading: true });
        await this.fetchPinsCredentials();
        this.setState({ viewLoading: false });
      }, 500);
    } else {
      dispatch(CardActions.setPinsFilter(field, null));
      await this.fetchPinsCredentials();
    }
  }

  async onSelectGuest(guestValue) {
    const { dispatch } = this.props;
    if (guestValue) {
      dispatch(CardActions.setPinsFilter('userId', guestValue.value));
      this.setState({ viewLoading: true });
      await this.fetchPinsCredentials();
      this.setState({ viewLoading: false });
    } else {
      dispatch(CardActions.setPinsFilter('userId', undefined));
      await this.fetchPinsCredentials();
    }
  }

  async onSelectLocksTags(tags) {
    const { dispatch } = this.props;
    if (tags && !_.isEmpty(tags)) {
      dispatch(UtilsActions.setViewLoading(true));
      const formattedTag = _.map(tags, tag => tag.value);
      this.onSetPinsFilter('lockTagIds', formattedTag);
    } else {
      this.onSetPinsFilter('lockTagIds', null);
      dispatch(UtilsActions.setViewLoading(true));
      await this.fetchPinsCredentials();
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onSelectPin(pin) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    this.setState({ showDeletedCardDetails: false });
    try {
      dispatch(CardActions.setSelectedPin(pin));
      dispatch(CardActions.fetchPinStatus(pin));
      dispatch(LogEventsActions.resetSmartLocksEventsData());
      await dispatch(CardActions.fetchPinsCredentials(pin));
      let newCredendialDefaults;
      if (!pin.credentialRule) {
        newCredendialDefaults = {
          timeIntervalFrom: moment().startOf('day').valueOf(),
          timeIntervalTo: moment().endOf('day').valueOf(),
          dateIntervalTimeFrom: moment().startOf('day').valueOf(),
          dateIntervalTimeTo: moment().endOf('day').valueOf(),
          daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
          lockTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
        };
      }
      // Fetch guests and locks by tag to show them when credential is selected
      const lockTags = pin.credentialRule ? pin.credentialRule.lockTags : [];
      const guestTags = pin.credentialRule ? pin.credentialRule.guestTags : [];
      const lockMatchingMode = pin.credentialRule ? pin.credentialRule.lockTagMatchingMode : MATCH_TAG_MODE.AT_LEAST_ONE_TAG;

      const formDefaultValues = {
        deviceBackendId: pin.id,
        deviceId: pin.deviceId,
        selectedGuestTags: guestTags,
        selectedLocksTags: lockTags,
        
        type: pin.type,
        notes: pin.notes,
        ...pin.credentialRule,
        ...newCredendialDefaults,
      };

      dispatch(initialize('PinCredentialsForm', formDefaultValues));
      dispatch(CredentialActions.fetchLocksByTags(lockTags, lockMatchingMode));
      dispatch(CredentialActions.fetchGuestsByTags(guestTags, MATCH_TAG_MODE.EVERY_TAG));
      dispatch(CardActions.setOperationalMode(true));
    } catch (error) {
      dispatch(CardActions.setSelectedPin({}));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onCreatePinCredentialFromModel(pin) {
    const { dispatch } = this.props;
    
    dispatch(destroy('PinCredentialsForm'));
    dispatch(CardActions.setSelectedPin({}));
    dispatch(CredentialActions.selectCredential({}));
    const lockTags = pin.credentialRule ? pin.credentialRule.lockTags : [];
    const lockMatchingMode = pin.credentialRule ? pin.credentialRule.lockTagMatchingMode : MATCH_TAG_MODE.AT_LEAST_ONE_TAG;
    const formInitValues = {
      selectedLocksTags: lockTags,
      type: pin.type,
      notes: pin.notes,
      guestTags: [],
      guestTagIds: [],
      selectedType: pin.type,
      ..._.omit(pin.credentialRule, ['guestTags', 'guestTagMatchingMode', 'guestTagIds']),
      ..._.omit(pin, ['id', 'deviceId', 'deviceBackendId', 'guestTags', 'guestTagMatchingMode', 'guestTagIds']),
    };
    dispatch(initialize('PinCredentialsForm', formInitValues));
    dispatch(CredentialActions.fetchLocksByTags(lockTags, lockMatchingMode));
    dispatch(CardActions.setOperationalMode(true));
  }
  

  async onSearchReset() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    this.setState({ viewLoading: true });
    dispatch(CardActions.resetPinsFilters());
    await dispatch(CardActions.fetchPins());
    this.setState({ viewLoading: false });
    dispatch(UtilsActions.setViewLoading(false));
  }


  onExportElements(format) {
    const { dispatch } = this.props;
    dispatch(CardActions.exportPins(format));
  }
  

  onFetchPinsOnPage(page) {
    const { dispatch } = this.props;
    dispatch(CardActions.fetchPins(page));
  }

  onSelectOriginalCard(cardOptionValue) {
    if (!cardOptionValue) {
      this.onSetPinsFilter('deviceId', '');
    } else {
      const { luckeyCardId } = cardOptionValue;
      this.onSetPinsFilter('deviceId', luckeyCardId);
    }
  }

  async deleteCard(cardId) {
    const { dispatch } = this.props;
    await dispatch(CardActions.deleteCard(cardId));
    dispatch(CardActions.fetchPins());
    dispatch(CardActions.setSelectedPin({}));
  }

  onDeletePinRequest(card) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteCardConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.deleteCard(card.id),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  setPinsViewMode(viewMode) {
    saveDataToLocalStorage('pinsViewMode', viewMode);
  }

  onPinListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    this.setPinsViewMode(selectedViewMode);
    if (activeTab === 0) {
      dispatch(CardActions.resetPinsPaginationData());
      dispatch(CardActions.fetchPins(0));
    }
  }

  onShowInfoModal() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="pins" />,
        content: <PinsExplanationView />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onSharePin(pin) {
    this.setState({ selectedPinToShare: pin, showSharingSection: true });
  }

  render() {
    const { cards, cards: { selectedPin }, themeName, viewLoading } = this.props;
    const { activeTab, selectedPinToShare, showSharingSection } = this.state;
    const canCreateNewCard = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canSearchGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const numberOfCards = cards && cards.pinsData && cards.pinsData.pagination && cards.pinsData.pagination.totalElements ? cards.pinsData.pagination.totalElements : 0;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          isLockTagsMultiSelectionActive
          newEntityTitle="newPin"
          newEntityIconName="icon-simple-line-icons-credit-card"
          searchPlaceholderEntityName="cardNumber"
          searchGuestContainerStyle={{ width: 280 }}
          isLoading={viewLoading}
          onInfo={() => this.onShowInfoModal()}
          onSearchReset={() => this.onSearchReset()}
          onNewEntity={canCreateNewCard ? () => this.onNewCredentialButtonClicked() : null}
          onSearchSubmit={() => this.fetchPinsCredentials()}
          onFilterChange={value => this.onSetPinsFilter('deviceId', value)}
          onSelectGuest={canSearchGuest ? value => this.onSelectGuest(value) : null}
          onSelectLockTag={tags => this.onSelectLocksTags(tags)}
          onExportElements={!isLuckeyLite ? format => this.onExportElements(format) : null}
          numberOfElements={numberOfCards}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <PinIconCustom style={{ width: 24, color: 'white' }} />
              <h4 style={{ marginTop: 0 }}>{numberOfCards}</h4>
            </div>
          }
        />
        {!isLuckeyLite && cards && cards.pinsData && !_.isEmpty(cards.pinsData) ? (
          <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onPinListModeChange(index)}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          </div>
        ) : null}
        {activeTab === 0 && !isLuckeyLite ? (
          <PinsTableView
            onNewPin={() => this.onNewCredentialButtonClicked()}
            onSelectPin={(pin) => this.onSelectPin(pin)}
            onFetchPinsOnPage={page => this.onFetchPinsOnPage(page)}
            onRefreshPins={() => this.fetchPinsCredentials()}
            onDeletePin={(pin) => this.onDeletePinRequest(pin)}
            onSharePin={pin => this.onSharePin(pin)}
            onCreatePinCredentialFromModel={pin => this.onCreatePinCredentialFromModel(pin)}
            onShowInfoModal={() => this.onShowInfoModal()}
          />
          ) : null}
        {activeTab === 1 || isLuckeyLite ? (
          <PinsListView
            listContainerStyle={{ top: isLuckeyLite ? 140 : 190 }}
            onSelectPin={(pin) => this.onSelectPin(pin)}
            onNewPinClick={() => this.onNewCredentialButtonClicked()}
            onShowInfoModal={() => this.onShowInfoModal()}
          />) : null}
        <OperationalView
          title={<Entity entity="pinOperationalTitle" />}
          themeName={themeName}
          isVisible={cards.isOperationalMode}
          style={{ margin: 0, padding: 0 }}
          onRefresh={selectedPin ? () => this.onSelectPin(selectedPin) : null}
          onClose={() => this.closeOperationalSection()}
        >
          <PinsOperationalSection
            ref={(c) => { this.cardsOperationalSection = c; }}
            onDeletePinRequest={pin => this.onDeletePinRequest(pin)}
            onSharePin={pin => this.onSharePin(pin)}
          />
        </OperationalView>
        <Modal show={showSharingSection} onHide={() => this.setState({ showSharingSection: false })}>
          <div style={{ padding: 20, paddingTop: 10 }}>
            <div style={{ display: 'flex', marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <h2 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="sharePinCode" /></h2>
              <IconButton onClick={() => this.setState({ showSharingSection: false })}>
                <CloseIcon style={{ color: RED, fontSize: 40 }} />
              </IconButton>
            </div>
            <PinShareSection pinCode={selectedPinToShare} />
          </div>
        </Modal>
      </div>
    );
  }
} 

export default PinsView;
