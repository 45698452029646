import React from 'react';
import { Entity } from '@sketchpixy/rubix/lib/L20n';

import Alert from './Alert.jsx';

export default class PasswordConfirmedModal extends React.Component {
  hideModal() {
    const { hideModal } = this.props;
    hideModal();
  }

  onConfirm() {
    const { onSuccess } = this.props;
    this.hideModal();
    if (onSuccess) {
      onSuccess();
    }
  }

  render() {
    const { onSuccess } = this.props;
    return (
      <Alert
        type="success"
        visible
        onOutsideClick={() => this.hideModal()}
        title={<Entity entity="modalTitle" data={{ modal: 'passwordConfirmed' }} />}
        message={<Entity entity="modalMessage" data={{ modal: 'passwordConfirmed' }} />}
        onConfirm={() => this.onConfirm()}
      />
    );
  }
}
