import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import MDButton from '../MDButton/MDButton.jsx';
import Tag from '../Tag.jsx';
import FormCardContainer from '../forms/Elements/FormCardContainer.jsx';
import { BLUE, RED } from '../../_config/consts.js';
import { IconButton } from '@material-ui/core';
import DeleteOutlineIconCustom from '../CustomIcons/DeleteOutlineIconCustom.jsx';

const ImportGuestsCSVMissingTagsView = ({ tagsToCreate, onConfirmCreateTags, onRemoveTag, onCancelCreateTags }) => (
  <FormCardContainer
    title="importGuestsFromCSV"
  >
    <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', marginBottom: 0 }}>
      {<Entity entity="newTagsDetected" />}
    </h4>
    <h4 style={{ color: '#3f3f3f', fontSize: 15, marginTop: 5 }}>
      {<Entity entity="newTagsDetectedDescription" />}
    </h4>
    <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
      <div style={{ maxHeight: 200, overflowY: 'scroll' }}>
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          {_.map(_.uniqBy(tagsToCreate, 'name'), (tag, index) => {
            const backgroundColor = index % 2 !== 0 ? '#FAFAFA' : 'white';
            return (
              <div
                className="card-noHover tag-element-selected"
                style={{ display: 'flex', zIndex: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: 5, marginBottom: 0, marginTop: 0, backgroundColor }}
              >
                <Tag
                  key={tag.name}
                  tag={tag}
                />
                <IconButton onClick={() => onRemoveTag(tag.name)}>
                  <DeleteOutlineIconCustom style={{ color: RED, width: 25 }} />
                </IconButton>
              </div> 
            )
          })}
        </div>
      </div>
    </div>
    <MDButton
      title={<Entity entity="createTags" />}
      containerStyle={{ margin: 0, marginTop: 20 }}
      onClick={() => onConfirmCreateTags()}
    />
    <MDButton
      title={<Entity entity="ignoreAndContinue" />}
      backgroundColor={BLUE}
      containerStyle={{ margin: 0, marginTop: 20 }}
      onClick={() => onCancelCreateTags()}
    />
  </FormCardContainer>
)

export default ImportGuestsCSVMissingTagsView;