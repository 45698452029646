/* eslint-disable react/prop-types */
// @ts-nocheck
import { Card, withStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import LockIcon from '@material-ui/icons/LockOpen';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ReactTooltip from 'react-tooltip';
import { submit } from 'redux-form';
import { BLUE, GREEN, LICENSE_TYPES, ORANGE, PERMISSION_ENTITIES, RED } from '../../_config/consts';
import { formatWalletSubscriptionsToReadableEntity, getColorFromThemeName, getIconFromSubscriptionResource, hasFormWritePermission } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as CloudCreditsActions from '../../redux/actions/cloudCredits.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import CreditsIconCustom from '../CustomIcons/CreditsIconCustom.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import OperationalView from '../OperationalView/OperationalView.jsx';
import ChangeTierForm from '../forms/CloudCredits/ChangeTierForm.jsx';
import MainSubscriptionCounter from './MainSubscriptionCounter.jsx';
import WalletSubscriptionRemoveView from './WalletSubscriptionRemoveView.jsx';

const styles = {
  container: {
    paddingTop: 0,
    borderRadius: 10,
    marginTop: 5,
    paddingBottom: 10,
    width: '100%',
  },
  subscriptionContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    width: 'fit-content',
    marginRight: 40,
    paddingLeft: 15,
  },
  cardTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  boldText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  subscriptionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 0,
    margin: 20,
    padding: 3,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 20,
  },
  cardInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%'
  },
  consumptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
  },
  consumptionText: {
    color: ORANGE,
    margin: 0,
  },
  licenseContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20
  },
  mainTierLimitContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subSubscriptionContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    minWidth: 320,
    marginRight: 40,
    paddingLeft: 15,
  },
};

const SubscriptionCard = ({ subscription, onRemoveSubscription, classes }) => {
  const icon = getIconFromSubscriptionResource(subscription.resource);
  if (!subscription) return null;
  const subcriptionFormatted = formatWalletSubscriptionsToReadableEntity(subscription);
  return (
    <Card className={classes.subSubscriptionContainer}>
      <div className={classes.cardInnerContainer}>
        {icon}
        <div style={{ marginLeft: 20 }}>
          <h4 className={classes.boldText}>{subcriptionFormatted.friendlyName}</h4>
          <div className={classes.consumptionContainer}>
            <CreditsIconCustom style={{ color: ORANGE, width: 20, marginRight: 5 }} />
            <h4 className={classes.consumptionText}>
              <Entity entity="walletCreditsConsumptionText" data={{ credits: subscription.burnRate }} />
            </h4>
          </div>
          {subscription.canBeDeactivated ? (
            <MDButton
              key={subscription.id}
              title={<Entity entity="removeSubscription" />}
              containerStyle={{ margin: 0, marginTop: 10 }}
              titleStyle={{ fontSize: 12, fontWeight: 'bold' }}
              backgroundColor={RED}
              style={{ marginTop: 5, height: 15 }}
              onClick={() => onRemoveSubscription()}
            />
          ) : null}
        </div>
      </div>
    </Card>
  );
};

const MainLicenseCard = ({ subscription, walletInfo, isLuckeyLite, classes, onUpgradeTierRequest }) => {
  if (!subscription) return null;
  const icon = getIconFromSubscriptionResource(subscription.resource);
  const numberOfUsers = walletInfo && walletInfo.numOfUsers ? walletInfo.numOfUsers : 0;
  const numberOfLocks = walletInfo && walletInfo.numOfLocks ? walletInfo.numOfLocks : 0;
  const numberOfElements = numberOfUsers + numberOfLocks;
  return (
    <Card className={classes.subscriptionContainer} style={{ borderLeft: `4px solid ${GREEN}`, paddingRight: 40, width: '90%' }}>
      <div className={classes.cardInnerContainer}>
        {icon}
        <div style={{ marginLeft: 10, width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <h3 className={classes.boldText}>{isLuckeyLite ? 'Luckey Basic' : 'Luckey Enterprise'}</h3>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20 }}>
                <p style={{ margin: 0, marginTop: 0, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('numberOfUsersInYourSystem')}>
                  <UsersIconCustom style={{ width: 25, marginRight: 5, color: '#3f3f3f' }} />
                  <h3 style={{ margin: 0, color: '#3f3f3f', fontSize: 20, fontWeight: 'bold' }}>{walletInfo.numOfUsers}</h3>
                </p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <p style={{ margin: 0, marginTop: 0, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('numberOfSmartLocksInYourSystem')}>
                  <LockIcon style={{ fontSize: 22, marginRight: 5, marginBottom: 2, color: '#3f3f3f' }} />
                  <h3 style={{ margin: 0, color: '#3f3f3f', fontSize: 20, fontWeight: 'bold' }}>{walletInfo.numOfLocks}</h3>
                </p>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
            {subscription.maxUsers ? (
              <div className={classes.licenseContainer}>
                <UsersIconCustom style={{ color: '#3f3f3f', width: 20, marginRight: 10 }} />
                <h4 style={{ margin: 0, color: '#3f3f3f' }}>{subscription.maxUsers} <Entity entity="users" /></h4>
              </div>
              ) : null}
            {subscription.maxLocks ? (
              <div className={classes.licenseContainer}>
                <LockIcon style={{ color: '#3f3f3f', marginRight: 10, fontSize: 20 }} />
                <h4 style={{ margin: 0, color: '#3f3f3f' }}>{subscription.maxLocks} <Entity entity="locks" /></h4>
              </div>
              ) : null}
            {subscription.maxElements ? (
              <div className={classes.licenseContainer}>
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 10, alignItems: 'center' }}>
                  <UsersIconCustom style={{ color: '#3f3f3f', width: 20, marginRight: 5 }} />+
                  <LockIcon style={{ color: '#3f3f3f', fontSize: 20 }} />
                </div>
                <h4 style={{ margin: 0, color: '#3f3f3f' }}>{subscription.maxElements} <Entity entity="users" /> + <Entity entity="locks" /></h4>
              </div>
              ) : null}
          </div>
          <div className={classes.consumptionContainer}>
            <h4 className={classes.consumptionText}><Entity entity="monthlyConsumption" />:</h4>
            <CreditsIconCustom style={{ color: ORANGE, width: 20, marginRight: 5, marginLeft: 5 }} />
            <h4 className={classes.consumptionText}>
              <Entity entity="walletCreditsConsumptionText" data={{ credits: subscription.burnRate }} />
            </h4>
          </div>
          <div className={classes.mainTierLimitContainer}>
            {subscription.maxUsers ? (
              <MainSubscriptionCounter
                used={numberOfUsers}
                max={subscription.maxUsers}
                title={<Entity entity="activeUsers" />}
              />
            ) : null}
            {subscription.maxLocks ? (
              <MainSubscriptionCounter
                used={numberOfLocks}
                max={subscription.maxLocks}
                title={<Entity entity="installedLocks" />}
              />
              ) : null}
            {subscription.maxElements ? (
              <MainSubscriptionCounter
                used={numberOfElements}
                max={subscription.maxElements}
                title={<Entity entity="activeUsersAndLocks" />}
              />
              ) : null}
            <MDButton
              key={subscription.id}
              title={<Entity entity="changeMainSubscription" />}
              containerStyle={{ margin: 0, marginTop: 20 }}
              titleStyle={{ fontSize: 12, fontWeight: 'bold' }}
              backgroundColor={BLUE}
              style={{ marginTop: 5, height: 15 }}
              onClick={() => onUpgradeTierRequest()}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

@connect(state => ({ subscriptions: state.cloudCredits.wallet.subscriptions, walletInfo: state.cloudCredits.wallet.info, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class WalletSubscriptionsSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSelectTierFormOpen: false,
      availableTiers: [],
    }
  }

  onExploreMarketplace() {
    const { dispatch } = this.props;
    dispatch(push('/luckeyStore'));
  }

  onRemoveSubscriptionRequest(item) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="deleteSubscriptionRequest" />,
        content: <WalletSubscriptionRemoveView onRemoveSubscriptionConfirm={feedbackText => this.onRemoveSubscriptionManualConfirm(item, feedbackText)} />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  // TO USE FOR DIRECTLY REMOVE SUBSCRIPTION USING BE
  async onRemoveSubscriptionConfirm(item, feedbackText) {
    const { dispatch, onRefreshWallet } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CloudCreditsActions.deleteWalletSubscription(item));
      await dispatch(UserActions.fetchLicenseInfo());
      await onRefreshWallet();
      dispatch(ModalActions.hideModal());
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.hideModal());
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="removeSubscriptionError" /></h6>),
        },
      }));
    }

    if (feedbackText) {
      dispatch(CloudCreditsActions.sendRemovedSubscriptionFeedback(item.subscription, feedbackText));
    }
  }

  // TO USE FOR OPEN A REQUEST FOR REMOVE SUBSCRIPTION
  async onRemoveSubscriptionManualConfirm(item, feedbackText) {
    const { dispatch, onRefreshWallet } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CloudCreditsActions.sendRemovedSubscriptionRequest(item.subscription, feedbackText));
      await dispatch(UserActions.fetchLicenseInfo());
      await onRefreshWallet();
      dispatch(ModalActions.hideModal());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="deleteSubscriptionManualRequestSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.hideModal());
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="removeSubscriptionError" /></h6>),
        },
      }));
    }
  }

  async onUpgradeTierRequest() {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const subscriptions = await dispatch(CloudCreditsActions.fetchCloudCreditsTiers());
      this.setState({ availableTiers: subscriptions })
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ isSelectTierFormOpen: true });
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onChangeTierConfirm(values) {
    const { dispatch } = this.props;
    const { availableTiers } = this.state;
    const { selectedTier: selectedTierId } = values;
    if (selectedTierId) {
      try {
        const selectedTier = _.find(availableTiers, tier => tier.id === selectedTierId);
        dispatch(UtilsActions.setSpinnerVisibile(true));
        await dispatch(CloudCreditsActions.sendChangeTierRequest(selectedTier));
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            message: (<h6 className="snack-title"><Entity entity="demoRequestSuccess" /></h6>),
          },
        }));
        this.setState({ isSelectTierFormOpen: false });
      } catch (error) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            message: (<h6 className="snack-title"><Entity entity="unexpectedErrorMessage" /></h6>),
          },
        }));
      }
    }
  }

  render() {
    const { subscriptions, classes, walletInfo, themeName, dispatch } = this.props;
    const { isSelectTierFormOpen, availableTiers } = this.state;
    const subscriptionsData = subscriptions && subscriptions.content;
    const textColor = getColorFromThemeName(themeName);
    const partitionedSubscriptionData = _.partition(subscriptionsData, sub => sub && sub.subscription && sub.subscription.resource === 'DOMAIN');
    const mainLicenseSubscription = partitionedSubscriptionData ? _.first(partitionedSubscriptionData[0]) : null;
    const pluginSubscriptions = partitionedSubscriptionData && partitionedSubscriptionData[1] ? partitionedSubscriptionData[1] : null;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    return (
      <Card className={classes.container}>
        {mainLicenseSubscription && mainLicenseSubscription ? (
          <div>
            <div className={classes.headerContainer}>
              <h3 className={classes.boldText} style={{ color: textColor }}><Entity entity="yourMainSubscription" /></h3>
            </div>
            <div style={{ marginLeft: 25 }}>
              <MainLicenseCard
                subscription={mainLicenseSubscription.subscription}
                classes={classes}
                walletInfo={walletInfo}
                isLuckeyLite={isLuckeyLite}
                onUpgradeTierRequest={() => this.onUpgradeTierRequest()}
              />
            </div>
          </div>
        ) : null}
        <div style={{ marginTop: 30 }}>
          <div className={classes.headerContainer}>
            <h3 className={classes.boldText} style={{ color: textColor, marginRight: 20 }}><Entity entity="yourPlugins" /></h3>
            {pluginSubscriptions ? (
              <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => this.onExploreMarketplace()}>
                <h4 style={{ textAlign: 'center' }} className="link-label" ><Entity entity="exploreMarketPlace" /></h4>
                <LaunchIcon style={{ fontSize: 17, marginLeft: 5, marginTop: 2, color: '#90CAF9' }} />
              </div>
            ) : null}
          </div>
          {!pluginSubscriptions || _.isEmpty(pluginSubscriptions) ? (
            <div style={{ alignItems: 'center' }}>
              <h3 className={classes.boldText} style={{ textAlign: 'center' }}><Entity entity="noExtensionsYet" /></h3>
              <h4 style={{ marginTop: 10, color: '#3f3f3f', textAlign: 'center' }}><Entity entity="exploreMarketPlaceDescription" /></h4>
              <h4 style={{ marginTop: 5, textAlign: 'center' }} className={`link-label`} onClick={() => this.onExploreMarketplace()}><Entity entity="exploreMarketPlace" /></h4>
            </div>
          ) : null}
          <PerfectScrollbar style={{ paddingBottom: 15 }}>
            <div className={classes.subscriptionsContainer} style={{ overflowY: 'scroll', width: '100%' }}>
              {_.map(partitionedSubscriptionData[1], (subscription) => subscription.subscription.visible ? (
                <SubscriptionCard
                  classes={classes}
                  subscription={subscription.subscription}
                  onRemoveSubscription={() => this.onRemoveSubscriptionRequest(subscription)}
                />
                ) : null)}
            </div>
          </PerfectScrollbar>
        </div>
        <ReactTooltip delayShow={200} effect="float" />
        <OperationalView
          themeName={themeName}
          isVisible={isSelectTierFormOpen}
          onClose={() => this.setState({ isSelectTierFormOpen: false })}
          operationaViewStyle={{ zIndex: 10000 }}
          style={{ margin: 0, padding: 0 }}
          title={<Entity entity="changeMainSubscription" />}
        >
          <ChangeTierForm
            tiers={availableTiers}
            onSubmit={values => this.onChangeTierConfirm(values)}
          />
          {hasFormWritePermission(PERMISSION_ENTITIES.DOMAIN_CLOUD_CREDITS, true) ? (
            <MDButton
              title={<Entity entity="save" />}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              onClick={() => dispatch(submit('ChangeTierForm'))}
            />
          ) : null}
          </OperationalView>
      </Card>
    );
  }
}


export default withStyles(styles)(WalletSubscriptionsSection);
