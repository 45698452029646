// @ts-nocheck
import { CircularProgress, createTheme, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { BLUE, GREEN, TICKETS_DEFAULT_CATEGORIES } from '../../_config/consts';
import MessageAlertIconCustom from '../CustomIcons/MessageAlertIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';

const styles = theme => ({
  container: {
    overflowY: 'auto',
    height: '120vh',
    paddingLeft: 15,
    paddingBottom: 220,
    paddingRight: 20,
    paddingTop: 0,
  },
  rowContainer: {
    padding: 20,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginBottom: 15,
  },
  rowTitle: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 0,
  },
  rowDescription: {
    color: '#3f3f3f',
    margin: 0,
    fontSize: 16,
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const EmptyTicketCategoriesView = ({ onCreateNew }) => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 20, marginTop: 50, marginRight: 50 }}>
    <MessageAlertIconCustom style={{ color: '#bdbdbd', width: 50, alignSelf: 'center' }} />
    <h3 style={{ color: '#bdbdbd', fontWeight: 'bold', textAlign: 'center', marginTop: 0 }}><Entity entity="noTicketCategoriesFound" /></h3>
    <h4 style={{ color: '#bdbdbd', fontWeight: 'normal',lineHeight: '1.3em', textAlign: 'center', marginTop: 0 }}><Entity entity="ticketCategoriesDescription" /></h4>
    <MDButton
      title={<Entity entity="createNewTicketCategory" />}
      titleStyle={{ marginTop: 0, fontSize: 13 }}
      backgroundColor={GREEN}
      containerStyle={{ margin: 0, marginTop: 10 }}
      onClick={() => onCreateNew()}
    />
  </div>
);

const TicketCategoryRow = ({ ticketCategory, classes, onRowClick }) => (
  <Card className={`card card-rounded ${classes.rowContainer}`} 
    style={{cursor:ticketCategory.default?'default':'pointer'}} 
    onClick={() => {if(!ticketCategory.default) onRowClick()}}>
    <h4 className={classes.rowTitle}>
      {L20NContext.getSync(TICKETS_DEFAULT_CATEGORIES.filter(e=>e.value===ticketCategory.name).length>0?
      TICKETS_DEFAULT_CATEGORIES.filter(e=>e.value===ticketCategory.name)[0].label:ticketCategory.name)}
    </h4>
    {ticketCategory.default?(
      <h4 className={classes.rowTitle} style={{marginLeft:0 ,marginTop:10, color: BLUE, fontSize:'1.1em'}}>{L20NContext.getSync('ticketCategoryDefault')}</h4>
    ):null}
  </Card>
);

class TicketCategoriesListView extends React.Component {
  render() {
    const { ticketCategoriesData, onRowClick, onCreateNew, classes } = this.props;
    return (
      <div className={classes.container}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => {}}
          hasMore={false}
          useWindow={false}
          loader={
            <div style={{ marginTop: 0, backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingVertical: 5, }}>
              <h4 style={{ textAlign: 'center', color: '#3f3f3f' }}><Entity entity="loadingLogs" /></h4>
              <CircularProgress className={classes.circularProgress} />
            </div>
          }
        >
          <div>
            {!_.isEmpty(ticketCategoriesData) ?
              _.map(ticketCategoriesData, (ticketCategory) =>
                <TicketCategoryRow
                  key={ticketCategory.id}
                  ticketCategory={ticketCategory}
                  classes={classes}
                  onRowClick={() => onRowClick(ticketCategory)}
                />)
                : (
                  <div>
                    <EmptyTicketCategoriesView
                      onCreateNew={() => onCreateNew()}
                    />
                  </div>
                )
              }
          </div>
        </InfiniteScroll>
      </div>
    );
  }

}
export default withStyles(styles)(TicketCategoriesListView);