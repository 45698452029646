import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import GenericDoorEventRow from './GenericDoorEventRow.jsx';
import OpenSuccessWithOutcomeEventRow from './OpenSuccessWithOutcomeEventRow.jsx';
import OpenFailedWithOutcomeEventRow from './OpenFailedWithOutcomeEventRow.jsx';
import { isSuccessEventFromSplittedOutcome, splitFieldsFromOutcome } from '../../../_config/utils';


@connect(() => ({}))
class DoorEventRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: props.event,
    };
  }

  getComponentFromEventOutcome(splittedOutcome) {
    if (isSuccessEventFromSplittedOutcome(splittedOutcome)) {
      return OpenSuccessWithOutcomeEventRow;
    } else {
      return OpenFailedWithOutcomeEventRow;
    }
  }

  
  render() {
    const { event } = this.state;
    if (!event || !event.eventType) {
      return null;
    }
    let Component = GenericDoorEventRow;
    const { eventOutcome } = event;
    let splittedOutcome = {};
    if (eventOutcome && !_.includes(event.eventType, 'PASSAGE_MODE')) { // workaround to manage passage mode events with wrong outcome JAGO_MOBILE_0_0
      splittedOutcome = splitFieldsFromOutcome(eventOutcome);
      Component = this.getComponentFromEventOutcome(splittedOutcome);
    }
    const isAnonymized = event && !event.data || (event.data && !event.data.actor && !event.data.lock);
    return (
      <div>
        {Component ? <Component isAnonymized={isAnonymized} event={event} splittedOutcome={splittedOutcome} {...this.props} /> : null}
      </div>
    );
  }
} 

export default DoorEventRow;
