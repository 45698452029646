import React from 'react';
import Select, { Creatable } from 'react-select';

import { Entity } from '@sketchpixy/rubix/lib/L20n';
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Checkbox,
} from '@sketchpixy/rubix';

class CustomOption extends React.Component {
  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  }

  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  }

  render() {
    const { option } = this.props;
    const gravatarStyle = {
      borderRadius: 3,
      display: 'inline-block',
      color: 'white',
      marginRight: 10,
      height: 20,
      fontSize: 12,
      padding: '0 7px',
      position: 'relative',
      backgroundColor: option.color,
      verticalAlign: 'middle',
    };
    return (
      <div
        className={this.props.className}
        onMouseDown={(e) => this.handleMouseDown(e)}
        onMouseEnter={(e) => this.handleMouseEnter(e)}
        onMouseMove={(e) => this.handleMouseMove(e)}
        title={option.title}
      >
        {option.name}
      </div>
    );
  }
}

class CustomValue extends React.Component {

  render() {
    const { value, onRemove } = this.props;
    return (
      <div
        className="Select-value"
        title={value.title}
        style={{ borderColor: value.color, backgroundColor: 'white' }}
      >
        <span
          className="Select-value-label"
        >
          <span>{value.name}</span>
        </span>
      </div>
    );
  }
}


export default class TagCategorySelectorField extends React.Component {
  onHandleChange(value, input) {
    const { onHandleChange } = this.props;
    if (input) {
      input.onChange(value);
    }
    if (onHandleChange) {
      onHandleChange(value);
    }
  }

  render() {
    const { input, meta, name, title,
      id, placeholder, categories, isCreatable,
      helpText, mandatory, active, isDisabled } = this.props;
    let validationState;
    if (meta.touched) {
      if (meta.error) {
        validationState = 'error';
      } else {
        validationState = 'success';
      }
    }

    return (
      <FormGroup controlId={id} validationState={validationState}>
        <ControlLabel style={{ color: '#3f3f3f', fontSize: 16 }}>{title}{mandatory ? <span style={{ color: '#D71F4B' }}>*</span> : ''}</ControlLabel>
        { isCreatable ?
          <Creatable
            {...input}
            placeholder={placeholder}
            options={categories ? categories.asMutable({ deep: true }) : []}
            value={input.value}
            labelKey="name"
            valueKey="name"
            disabled={isDisabled}
            promptTextCreator={(value) => `Create category "${value}"`}
            onChange={(value) => { this.onHandleChange(value, input); }}
            onBlur={() => input.onBlur(input.value)}
          /> :
          <Select
            {...input}
            placeholder={placeholder}
            labelKey="name"
            valueKey="name"
            disabled={isDisabled}
            options={categories ? categories.asMutable({ deep: true }) : []}
            value={input.value}
            onChange={(value) => { this.onHandleChange(value, input); }}
            onBlur={() => input.onBlur(input.value)}
          />
        }
        {helpText ? <HelpBlock>{helpText}</HelpBlock> : null}
        {meta.touched && meta.error && (
          <ControlLabel style={{ color: 'deepred' }}>
            <h6 style={{ color: 'red', marginTop: 4, marginBottom: 0 }}>
              <Entity key={meta.error} entity={meta.error} />
            </h6>
          </ControlLabel>
        )}
      </FormGroup>
    );
  }
}
