import CheckIcon from '@material-ui/icons/Done';
import LauncIcon from '@material-ui/icons/Launch';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BLUE, GREEN, ORANGE } from '../../_config/consts';
import ShareIconCustom from '../CustomIcons/ShareIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import WarningOutlineIconCustom from '../CustomIcons/WarningOutlineIconCustom.jsx';
import { localizeHelpCenterLink } from '../../_config/utils.js';

class LockTroubleShootingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  onCopy() {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);
  }

  render() {
    const { article } = this.props;
    const { copied } = this.state;
    return (
      <div>
        {article && article.renderedContent ? (
          <div style={{ paddingBottom: 60 }}>
            <div style={{ padding: 20, paddingBottom: 0, display: 'flex', flexDirection: 'column', marginTop: 10 }}>
              <h3 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f', marginBottom: 5 }}>{article.renderedTitle ? article.renderedTitle : <Entity entity={article.title} />}</h3>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CopyToClipboard text={article.link} onCopy={() => this.onCopy()}>
                  <MDButton
                    title={copied ? <Entity key={copied} entity="copied" /> : <Entity key={copied} entity="shareWith" />}
                    backgroundColor={copied ? GREEN : BLUE}
                    onClick={() => null}
                    containerStyle={{ marginLeft: 0 }}
                    style={{ color: 'white', fontSize: 15, marginRight: 10 }}
                    icon={copied ? <CheckIcon style={{ marginLeft: 10 }} /> : <ShareIconCustom style={{ width: 20, marginLeft: 10 }} />}
                  />
                </CopyToClipboard>
                <MDButton
                  title={<Entity entity="openLock" />}
                  backgroundColor={BLUE}
                  onClick={() => window.open(article.link)}
                  containerStyle={{ marginLeft: 0 }}
                  style={{ color: 'white', fontSize: 15, marginRight: 10 }}
                  icon={<LauncIcon style={{ fontSize: 18, marginLeft: 10 }} />}
                />
              </div>
            </div>
            <div className="storeContentContainer" style={{ overflow: 'scroll', padding: 20, paddingTop: 0, marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: article.renderedContent }} />
          </div>
        ): (
        <div style={{ padding: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <WarningOutlineIconCustom  style={{ color: ORANGE, width: 50 }} />
          <h3 style={{ fontWeight: 'bold', color: '#3f3f3f', marginTop: 10, textAlign: 'center' }}><Entity entity="noTroubleShootingArticleFound" /></h3>
          <h4 style={{ color: '#3f3f3f', margin: 0, textAlign: 'center' }}><Entity entity="noTroubleShootingArticleFoundDescription" /></h4>
          <MDButton
            title="Help Center"
            backgroundColor={BLUE}
            icon={<LauncIcon style={{ marginLeft: 10 }}/>}
            onClick={() => window.open(localizeHelpCenterLink(''))}
          />

        </div>
        )}
      </div>
    )
  }
}

export default LockTroubleShootingView;