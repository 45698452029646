import React from 'react';
import _ from 'lodash';
import AbilityProvider from '../permissionsUtils/AbilityProvider';
import { LICENSE_TYPES, PERMISSIONS, PERMISSION_ENTITIES } from '../_config/consts';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

@connect(state => ({ userLicense: state.user.license, user: state.user.data }))
class DefaultRootView extends React.Component {
  componentWillMount() {
    const { dispatch, user } = this.props;
    const canReadGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const canReadLocks = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const canReadGateways = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.GATEWAY);
    const canReadAccesRules = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.CREDENTIAL_RULE);
    const canReadCards = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canReadLogs = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.LOG);
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const isLuckeyEngine = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENGINE]);
    const isLuckeyRFID = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.RFID]);
    const isGuestManager = user && _.size(user.roles)  === 1 && _.find(user.roles, role => role.id === 5);
    if (canReadGuest || canReadLocks || canReadCards || canReadLogs) {
      if (isLuckeyLite || isLuckeyRFID) return dispatch(push('/guests'));
      if (isLuckeyEngine) return dispatch(push('/settings'));
      return dispatch(push('/dashboard'));
    }
    if (canReadLocks) return dispatch(push('/locks'));
    if (canReadGateways) return dispatch(push('/gateways'));
    if (canReadAccesRules) return dispatch(push('/credentials'));
    if (canReadCards) return dispatch(push('/cards'));
    if (canReadLogs) return dispatch(push('/adminEvents'));
    if (isGuestManager) return dispatch(push('/remoteOpen'))
    return dispatch(push('/settings'));
  }

  render() {
    return (<div />);
  }
} 

export default DefaultRootView;
