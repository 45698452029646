import { resourceActions as ResourceActions } from '@bottega52/bookey-redux-module';
import { IconButton } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import CloseIcon from '@material-ui/icons/HighlightOff';
import InfoIcon from '@material-ui/icons/HelpOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import * as GuestActions from '../../../redux/actions/guest.actions';
import { BLUE } from '../../../_config/consts';
import MDButton from '../../MDButton/MDButton.jsx';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';
import { localizeHelpCenterLink } from '../../../_config/utils';
import CustomField from '../Fields/CustomField.jsx';

let filterTimeout;
@connect(state => ({}))
@reduxForm({ form: 'ReservationSearchBar' })
class ReservationSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guestsOptions: [],
      resourcesOptions: [],
    };
  }
  async onFilterGuests(value) {
    const { dispatch } = this.props;
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      dispatch(GuestActions.setFilter('search', value));
      const guests = await dispatch(GuestActions.fetchGuests());
      const entities = [...guests];
      const options = _.map(entities, entity => ({ value: entity.id, label: entity.email || entity.name }));
      this.setState({ guestsOptions: options });
      return options;
    }, 500);
  }

  async onFilterResource(value) {
    const { dispatch } = this.props;
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      const resources = await dispatch(ResourceActions.fetchResourcesWithMedia(0, 100, { name: value }));
      const options = _.map(resources, resource => ({ value: resource.id, label: resource.name }));
      this.setState({ resourcesOptions: options });
      return options;
    }, 500);
  }

  onSearchReset() {
    const { dispatch, onSearchReset } = this.props;
    dispatch(reset('ReservationSearchBar'));
    onSearchReset();
  }

  onHandleFilerChange(value, field) {
    const { onFilterChange, onResetFilterField } = this.props;
    if (value) {
      if (field === 'eventType' && value === 'ALL') return onFilterChange(field, undefined);
      return onFilterChange(field, value);
    }
    return onResetFilterField(field);
  }

  openReservationHelpCenter() {
    const formattedURL = localizeHelpCenterLink('bookey-reservations-check-in-and-access-credentials');
    window.open(formattedURL);
  }

  render() {
    const { themeName, onSelectResource, onSelectGuest, onNewReservation, onSelectWorkspace, workspaceOptions, } = this.props;
    const themeClass = `list-view-header bg-${themeName}-theme`;
    const { guestsOptions, resourcesOptions } = this.state;
    return (
      <div className={themeClass} style={{ width: '100%' }}>
        <div style={{ minHeight: 70, padding: 0, display: 'flex', flexDirection: 'row', marginLeft: 0, alignItems: 'center' }}>
          <div style={{ marginTop: 15, marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Field
              name="guestSelected"
              component={SelectableField}
              noCache
              options={guestsOptions}
              placeholder={<Entity entity="selectUserTag" />}
              titleStyle={{ color: 'white' }}
              containerstyle={{ minWidth: 450, marginRight: 10 }}
              onInputChange={value => this.onFilterGuests(value)}
              onSelect={guestData => onSelectGuest(guestData && guestData.value ? guestData.value : null)}
            />
            <Field
              id="selectWorkspace"
              name={"workspaceSelected"}
              title={''}
              component={CustomField}
              className="form-control-select"
              componentClass="select"
              onHandleChange={value => onSelectWorkspace(value)}
              containerStyle={{ marginRight: 20, marginLeft: 10, marginBottom: -12 }}
            >
              {workspaceOptions}
            </Field>
            <Field
              name="resourceSelected"
              component={SelectableField}
              noCache
              options={resourcesOptions}
              placeholder={<Entity entity="resource" />}
              titleStyle={{ color: 'white' }}
              containerstyle={{ minWidth: 240, marginRight: 10 }}
              onInputChange={value => this.onFilterResource(value)}
              onSelect={resource => onSelectResource(resource && resource.value ? resource.value : null)}
            />
          </div>
          <MDButton
            style={{ marginTop: 5 }}
            containerStyle={{ margin: 0, marginLeft: 5, marginRight: 10 }}
            backgroundColor="#D32F2F"
            icon={<CloseIcon style={{ fontSize: 20 }} />}
            onClick={() => this.onSearchReset()}
          />
          {onNewReservation ? (
            <MDButton
              style={{ marginTop: 5 }}
              containerStyle={{ margin: 0, marginLeft: 15, marginRight: 10 }}
              backgroundColor={BLUE}
              icon={<CalendarIcon style={{ fontSize: 20, marginLeft: 15 }} />}
              title={<Entity entity="newReservation" />}
              onClick={() => onNewReservation()}
            />
          ) : null}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconButton onClick={() => this.openReservationHelpCenter()} style={{ marginLeft: 10, marginTop: 3 }}>
              <InfoIcon style={{ fontSize: 30, color: 'white' }} />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
} 

export default ReservationSearchBar;
