import UsersIcon from '@material-ui/icons/PeopleOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { isUserWithoutEmail } from '../../_config/utils';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as AccessProfilesActions from '../../redux/actions/accessProfiles.actions';
import ListLoadingView from '../../components/ListLoadingView.jsx';
import UsersIconCustom from '../../components/CustomIcons/UsersIconCustom.jsx';


const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'required';
  }

  if (!values.type) {
    errors.type = 'required';
  }

  if (!values.color) {
    errors.color = 'required';
  }

  return errors;
};


@connect(state => ({ accessProfiles: state.accessProfiles }))
class AccessProfilesUserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListLoading: false,
    };
  }

  async onFetchMoreItems(page) {
    const { dispatch, accessProfiles: { selectedAccessProfile } } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    this.setState({ isListLoading: true });
    try {
      await dispatch(AccessProfilesActions.fetchGuestsByAccessProfiles(selectedAccessProfile.id, page));
      dispatch(UtilsActions.setViewLoading(false));
      this.setState({ isListLoading: false });
    } catch (error) {
      dispatch(UtilsActions.setViewLoading(false));
      this.setState({ isListLoading: false });
    }
  }

  render() {
    const { accessProfiles: { guests: { content: guestsData, pagination }} } = this.props;
    const { isListLoading } = this.state;
    return (
      <div>
        {!_.isEmpty(guestsData) ? (
          <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10, marginRight: 20 }}>
            <div style={{ maxHeight: 400, padding: 5, overflowY: 'scroll' }}>
              <InfiniteScroll
                pageStart={0}
                useWindow={false}
                initialLoad={false}
                loadMore={() => this.onFetchMoreItems(pagination.number + 1)}
                hasMore={pagination.number + 1 < pagination.totalPages}
                loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
              >
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                  {_.map(guestsData, (item, index) => (
                      <div
                        style={{ backgroundColor: index % 2 === 0 ? 'white' : 'ghostwhite', borderBottom: '1px solid', borderTop: '1px solid', alignItems: 'center', justifyContent: 'space-between', display: 'flex', padding: 5, borderColor: '#f0f0f0' }}
                        key={item.id}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <h5 style={{ color: '#3f3f3f', marginLeft: 10 }}>{`${item.firstname} ${item.lastname} ${isUserWithoutEmail(item) ? '': ` - ${item.email}`}`}</h5>
                        </div>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          <div>
            {!isListLoading ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <UsersIconCustom style={{ color: '#3f3f3f', marginTop: 3, width: 35 }} />
                <h4 style={{ color: '#3f3f3f', textAlign: 'center' }}>
                  <Entity entity="noUsersForProfile" />
                </h4>
              </div>
            ) : <ListLoadingView />}
          </div>
        )}
      </div>
    );
  }
} 

export default AccessProfilesUserList;