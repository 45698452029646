import { Divider } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { ORANGE } from '../../_config/consts';

export default class GuestAddCSVMessage extends React.Component {
  render() {
    const { usersWithErrors } = this.props;
    
    return (
      <div style={{ marginTop: -10 }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <WarningIcon style={{ color: ORANGE, fontSize: 60 }} />
          <h3 style={{ color: '#3f3f3f', marginTop: 5, textAlign: 'center', fontWeight: 'bold' }}>{<Entity entity="importCSVEndWithWarning" />}</h3>
          <h4 style={{ color: '#3f3f3f', textAlign: 'center', margin: 0 }}>{<Entity entity="importCSVEndWithWarningDescription" />}</h4>
        </div>
        {usersWithErrors && !_.isEmpty(usersWithErrors) && (
          <div>
            <Divider style={{ marginTop: 20 }} />
            <h4 style={{ marginLeft: 10, color: '#3f3f3f', fontWeight: 'bold', textAlign: 'left' }}><Entity entity="warning" /></h4>
            <h4 style={{ marginLeft: 10, color: '#3f3f3f', textAlign: 'left', marginTop: 0 }}>{<Entity entity="importCSVUsersWarningTitle" />}</h4>
            {_.map(usersWithErrors, (userError) => {
              const guest = userError && userError.user;
              return <h4 style={{ color: '#3f3f3f', textAlign: 'left', marginLeft: 20 }} key={guest.email}>{` • ${guest.firstname} ${guest.lastname} - ${guest.email}`}</h4>;
            })}
          </div>
        )}
      </div>
    );
  }
}
