module.exports = {
  SET_SETTING_OPERATIONAL_MODE: 'SET_SETTING_OPERATIONAL_MODE',
  SELECT_SETTING: 'SELECT_SETTING',
  SAVE_SETTING: 'SAVE_SETTING',
  SAVE_LOGO: 'SAVE_LOGO',
  SAVE_LANGUAGE: 'SAVE_LANGUAGE',
  SAVE_SAML_REQUEST_CONFIG: 'SAVE_SAML_REQUEST_CONFIG',
  SAVE_COMPANY_SUBCOMPANIES: 'SAVE_COMPANY_SUBCOMPANIES',
  SAVE_WHATS_NEW_CONTENT: 'SAVE_WHATS_NEW_CONTENT',
  SAVE_TROUBLESHOOTING_CONTENT: 'SAVE_TROUBLESHOOTING_CONTENT',
  SAVE_WHATS_NEW_HAS_UPDATE: 'SAVE_WHATS_NEW_HAS_UPDATE',
};
