import { axiosInstance } from './rest';

export function fetchRoles(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get('/api/v2/roles', restParams);
}
export function fetchPermissions(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get('/api/v2/permissions', restParams);
}

export function fetchRoleDetails(roleId) {
  return axiosInstance.get(`/api/v2/roles/${roleId}`);
}

export function deleteRole(roleId) {
  return axiosInstance.delete(`/api/v2/roles/${roleId}`);
}

export function updateRole(roleId, roleDTO) {
  return axiosInstance.put(`/api/v2/roles/${roleId}`, roleDTO);
}

export function createRole(roleDTO) {
  return axiosInstance.post('/api/v2/roles/', roleDTO);
}
