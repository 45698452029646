// @ts-nocheck
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { DateRangePicker } from 'react-dates';
import { GREEN, RED } from '../../../_config/consts';
import MDButton from '../../MDButton/MDButton.jsx';
import WalletFiltersOptionSelection from './WalletFiltersOptionSelection.jsx';

const filtersOptions = [
  {
    title: 'Q1',
    value: 1,
  },
  {
    title: 'Q2',
    value: 2,
  },
  {
    title: 'Q3',
    value: 3,
  },
  {
    title: 'Q4',
    value: 4,
  },
  {
    title: moment().year().toString(),
    value: 0,
  },
];


export default class WalletChartFilters extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      searchData: {
        fromDate: moment().startOf('M').valueOf(),
        toDate: moment().endOf('M').valueOf(),
      }
    }
  }
  
  onDatesChange(dates) {
    if (dates) {
      this.setState({ searchData: { fromDate: moment(dates.startDate).valueOf(), toDate: moment(dates.endDate).valueOf() }});
    }
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  onSearchReset() {
    const searchData = {
      fromDate: moment().startOf('M').valueOf(),
      toDate: moment().endOf('M').valueOf(),
    };
    this.setState({ searchData });
    const { onSearch } = this.props;
    onSearch(searchData);
  }

  onSubmitForm() {
    const { searchData } = this.state;
    const { onSearch } = this.props;
    onSearch(searchData);
  }

  onSelectDateFilterOption(option) {
    if (option.value === 0) {
      const fromDate = moment().startOf('year').valueOf();
      const toDate = moment().endOf('year').valueOf();
      this.setState({ searchData: { fromDate, toDate } }, () => this.onSubmitForm());
    } else {
      const fromDate = moment().quarter(Number(option.value)).startOf('month').valueOf();
      const toDate = moment().quarter(Number(option.value)).endOf('quarter').valueOf();
      this.setState({ searchData: { fromDate, toDate } }, () => this.onSubmitForm());
    }
  }

  render() {
    const { focusedInput, searchData } = this.state;
    return (
      <div style={{ marginBottom: 20 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <DateRangePicker
            startDateId="start_date_id"
            displayFormat="DD/MM/YYYY"
            endDateId="end_date_id"
            startDate={searchData.fromDate ? moment(searchData.fromDate) : null}
            endDate={searchData.toDate ? moment(searchData.toDate) : null}
            onDatesChange={(dates) => this.onDatesChange(dates)}
            onFocusChange={focusInput => this.setState({ focusedInput: focusInput })}
            focusedInput={focusedInput}
            hideKeyboardShortcutsPanel
            isOutsideRange={() => false}
          />
          <MDButton
            containerStyle={{ margin: 0, marginLeft: 15, marginRight: 10 }}
            backgroundColor={GREEN}
            title={<Entity entity="search" />}
            onClick={() => this.onSubmitForm()}
          />
          <MDButton
            containerStyle={{ margin: 0, marginLeft: 15, marginRight: 10 }}
            backgroundColor={RED}
            title={<Entity entity="resetFilters" />}
            onClick={() => this.onSearchReset()}
          />
        </div>
        <WalletFiltersOptionSelection
          options={filtersOptions}
          onSelectOption={option => this.onSelectDateFilterOption(option)}
          onResetOptionSelection={() => this.onSearchReset()}
        />
    </div>
    )
  }
}