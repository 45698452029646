import { hideModal } from '@bottega52/redux-modals';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ConfirmToContinueModal from './ConfirmToContinueModal.jsx';
import CredentialUpdateModal from './Credentials/CredentialUpdateModal.jsx';
import ErrorModal from './ErrorModal.jsx';
import GuestCreatedModal from './Guests/GuestCreatedModal.jsx';
import GuestUpdatedModal from './Guests/GuestUpdatedModal.jsx';
import InfoModal from './InfoModal.jsx';
import LockUpdatedModal from './Locks/LockUpdatedModal.jsx';
import MDErrorAlert from './MDAlerts/MDErrorAlert.jsx';
import MDSuccessAlert from './MDAlerts/MDSuccessAlert.jsx';
import PasswordConfirmedModal from './PasswordConfirmedModal.jsx';
import SuccessModal from './SuccessModal.jsx';
import TagCreatedModal from './Tags/TagCreatedModal.jsx';
import ManagerPasswordResetModal from './Users/ManagerPasswordResetModal.jsx';
import UserDisabledModal from './Users/UserDisabledModal.jsx';
import UserPasswordResetModal from './Users/UserPasswordResetModal.jsx';
import UserPasswordRestoreModal from './Users/UserPasswordRestoreModal.jsx';
import ContentModal from './ContentModal.jsx';
import MDWarningAlert from './MDAlerts/MDWarningAlert.jsx';

const modalComponents = {
  ERROR_MODAL: ErrorModal,
  SUCCESS_MODAL: SuccessModal,
  GUEST_CREATED_MODAL: GuestCreatedModal,
  GUEST_UPDATED_MODAL: GuestUpdatedModal,
  LOCK_UPDATED_MODAL: LockUpdatedModal,
  TAG_CREATED_MODAL: TagCreatedModal,
  CREDENTIAL_UPDATED_MODAL: CredentialUpdateModal,
  PASSWORD_CONFIRMED_MODAL: PasswordConfirmedModal,
  USER_DISABLED_MODAL: UserDisabledModal,
  USER_PASSWORD_RESET_MODAL: UserPasswordResetModal,
  MANAGER_PASSWORD_RESET_MODAL: ManagerPasswordResetModal,
  USER_PASSWORD_RESTORE_MODAL: UserPasswordRestoreModal,
  CONFIRM_TO_CONTINUE_MODAL: ConfirmToContinueModal,
  ERROR_ALERT: MDErrorAlert,
  WARNING_ALERT: MDWarningAlert,
  SUCCESS_ALERT: MDSuccessAlert,
  INFO_MODAL: InfoModal,
  CONTENT_MODAL: ContentModal,
};
@connect((state) => ({ modals: state.advancedModals.data }))
class AdvancedAlertModal extends React.Component {
  render() {
    const { modals, dispatch } = this.props;
    if (!_.size(modals)) {
      return null;
    }
    const components = _.map(modals, (modal) => {
      if (!modal.id || !modal.data) return null;
      const { modalType, modalProps } = modal.data;
      const Component = modalComponents[modalType];
      return <Component key={modal.id} {...modalProps} dispatch={dispatch} hideModal={() => hideModal(modal.id)} />;
    });
    return (_.size(components) ? <div>{components}</div> : null);
  }
} 

export default AdvancedAlertModal;
