import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import MDButton from '../MDButton/MDButton.jsx';
import GoogleSSOButton from './GoogleSSOButton.jsx';
import MicrosoftSSOButton from './MicrosoftSSOButton.jsx';

const brandedButtonsComponents = {
  GOOGLE: GoogleSSOButton,
  MICROSOFT: MicrosoftSSOButton,
};

const BasicSSOButton = ({ idp }) => {
  if (!idp || !idp.brand || !brandedButtonsComponents[idp.brand]) {
    return (
      <MDButton
        type="submit"
        title={L20NContext.getSync(idp.labelToken)}
        containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20 }}
      />
    );
  }
  const Component = brandedButtonsComponents[idp.brand];
  return <Component idp={idp} />;
};

export default BasicSSOButton;
