import { Col, Grid, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { submit } from 'redux-form';
import ConfirmPasswordForm from '../../components/forms/ConfirmPasswordForm.jsx';
import SpinnerOverlay from '../../components/Spinner/SpinnerOverlay.jsx';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as ModalActions from '../../redux/actions/modal.actions';

@connect((state) => ({ userCredentials: state.user.userLogin, routing: state.router }))
class ResetPassword extends React.Component {
  
  async confirmPassword(values) {
    const { dispatch, routing } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
      const token = parsed.token;
      const userId = parsed.userId;
      await dispatch(UserActions.confirmPassword(userId, token, values));
      dispatch(replace('/resetPassLanding'));
    } catch (error) {
      let errorMessage = 'errorSetPassword';
      if (error && error.response && error.response.data && error.response.data.code && error.response.data.code === 10105) errorMessage = 'setPasswordTokenExpired';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity={errorMessage} /> },
      }));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  render() {
    const { dispatch } = this.props;
    return (
      <div id="auth-container" style={{ height: '100vh', overflow: 'scroll', display: 'flex', alignItems: 'center', backgroundColor: '#12637f', paddingTop: 30, flexDirection: 'column' }} className="login">
        <Grid style={{ width: '100%' }}>
          <Row>
            <Col style={{ display: 'flex', flexDirection: 'column' }}>
              <img src="/imgs/common/logoJagoWhite.png" alt="Sofia" style={{ alignSelf: 'center', height: 60 }} />
              <h4 style={{ color: 'white', textAlign: 'center', margin: 0, padding: 5, fontWeight: 'bold', fontSize: 15 }}>
                <Entity entity="setYourPassword" />
              </h4>
              <div style={{ maxWidth: 400, width: '100%', padding: 20, alignSelf: 'center' }}>
                <ConfirmPasswordForm
                  onValidateForm={() => dispatch(submit('ConfirmPasswordForm'))}
                  onSubmit={(values) => this.confirmPassword(values)}
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <SpinnerOverlay />
      </div>
    );
  }
} 

export default ResetPassword;
