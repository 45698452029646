// @ts-nocheck
import { Card, IconButton, withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import CalendarIcon from '@material-ui/icons/EventAvailable';
import CloseIcon from '@material-ui/icons/HighlightOff';
import MailIcon from '@material-ui/icons/MailOutline';
import SendIcon from '@material-ui/icons/Send';
import { Form, Icon } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Field, change, reduxForm, submit } from 'redux-form';
import { BLUE, RED, TICKET_STATUS_COLORS } from '../../_config/consts.js';
import * as ModalActions from '../../redux/actions/modal.actions';
import ArrowDownLineIconCustom from '../CustomIcons/ArrowDownLineIconCustom.jsx';
import PlatformUserIconCustom from '../CustomIcons/PlatformUserIconCustom.jsx';
import UserOutlineIconCustom from '../CustomIcons/UserOutlineIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import DropFileMultiField from '../forms/Fields/DropFileMultiField.jsx';
import SelectableField from '../forms/Fields/SelectableInput/SelectableField.jsx';
import ArrowUpLineIconCustom from '../CustomIcons/ArrowUpLineIconCustom.jsx';

const styles = theme => ({
  attachment: {
    backgroundColor: '#BBBBBB',
    color: 'white',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: BLUE,
    }
  },
});

const validate = values => {
  const errors = {};
  if (!values.priority) {
    errors.priority = 'required';
  }

  if (!values.assignee) {
    errors.assignee = 'required';
  }

  if (!values.category) {
    errors.category = 'required';
  }

  return errors;
};

@reduxForm({ form: 'TicketDetailsViewForm', validate })
@connect(state => ({ form: state.form.TicketDetailsViewForm, tickets: state.tickets, companyConfigurations: state.user.companyConfigurations }))
class TicketDetailsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isNewMessageOpen: false,
      newMessage: "",
      activeHoverIndex: -1,
    }
  }

  onTicketEditConfirmed() {
    const { dispatch } = this.props;
    dispatch(submit('TicketDetailsViewForm'))
  }

  onCloseTicketClick() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="closeTicketConfirmation" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onCloseTicketRequest(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onCloseTicketRequest() {
    const { onCloseTicketRequest } = this.props;
    onCloseTicketRequest()
  }

  onResolveTicketClick() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="resolveTicketConfirmation" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onResolveTicketRequest(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onResolveTicketRequest() {
    const { onResolveTicketRequest } = this.props;
    onResolveTicketRequest()
  }

  onOpenTicketClick() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="openTicketConfirmation" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onOpenTicketRequest(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onOpenTicketRequest() {
    const { onOpenTicketRequest } = this.props;
    onOpenTicketRequest()
  }

  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Rimuovo la prima parte, i.e. "data:application/pdf;base64,"
      let resultAsString = (reader.result);
      let resultSplitted = resultAsString.split(',');
      if (resultSplitted.length>1)
        resolve(resultSplitted[1])
    };
    reader.onerror = reject;
  });

  async onNewMessageRequest() {
    const { onNewMessageRequest, form } = this.props;
    const { newMessage } = this.state;
    if (newMessage) {
      const attachments = {}
      if (form && form.values && form.values.image && _.isArray(form.values.image)) {
        for (const item of form.values.image) {
          try {
            let fileName = item.name
            if (fileName && attachments[fileName]===undefined) {
              let encodedFile = await this.toBase64(item)
              attachments[fileName] = encodedFile
            }
          } catch(e) {
            console.error("Error encoding file:", e);
            continue
          }
        }
      }
      try {
        await onNewMessageRequest(newMessage,attachments);
        this.setState({ newMessage: "" });
      } catch (error) {
        console.log("ono nel catch")
      }
    }
    
  }

  onDeleteFile(fileIndex) {
    const { dispatch } = this.props;
    const { form, } = this.props;
    dispatch(change('TicketDetailsViewForm', 'image', form.values.image.filter((item, i) => i !== fileIndex)));
  }

  onFileRejected() {
    const { dispatch } = this.props;
    dispatch(ModalActions.showModal({
      modalType: 'ERROR_MODAL',
      modalProps: { type: 'FILE_TOO_LARGE' },
    }));
  }

  render() {
    const { classes, dispatch, ticket, tickets, prioritiesArray, categoriesArray, statusArray, form, formStyle, canEditTicket} = this.props;
    const { newMessage, activeHoverIndex } = this.state;
    const createdAtDate = ticket.createdAt ? moment(ticket.createdAt).format('LL') : null;
    const comments = ticket && ticket.comments && _.isArray(ticket.comments) && !_.isEmpty(ticket.comments) ? ticket.comments:[]
    const canResolve = ticket && ticket.status==="PENDING" && canEditTicket;
    const canClose = ticket && (ticket.status==="OPEN" || ticket.status==="PENDING") && canEditTicket;
    const canOpen = ticket && (ticket.status==="CLOSED" || ticket.status==="RESOLVED") && canEditTicket;
    const canEdit = ticket && (ticket.status==="OPEN" || ticket.status==="PENDING") && canEditTicket;

    let newMessageAttachments = form && form.values && form.values.image;
    if (newMessageAttachments && _.isArray(newMessageAttachments))
      newMessageAttachments = newMessageAttachments.map(e=>{return e.name})
    else
      newMessageAttachments = []
    let allAttachments = []
    for (const comment of ticket.comments) {
      if (comment.attachments)
        allAttachments = [...allAttachments,...Object.entries(comment.attachments).map(([key, value]) => ({name: key,url: value}))]
    }

    const assigneesArray = tickets && tickets.ticketManagers && tickets.ticketManagers.content ? 
      _.map(tickets.ticketManagers.content, manager => ({ label: manager.username, value: manager.email, id: manager.id })) : []

    return (
      <Form style={formStyle} autoComplete="off">
        <div className="card card-noHover" style={{ backgroundColor: '#F8F9FA', position: 'relative', padding: 15, marginLeft: 15, marginRight: 20, marginTop: 10, marginBottom: 20 }}>
          <div style={{ marginBottom: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
              <h2 style={{ fontSize: 25, marginTop: 0, color: '#3f3f3f', marginBottom: 0, fontWeight: 'bold', overflow:'hidden',textOverflow:'ellipsis' }}>
                {`#${ticket.id} - ${ticket.title}`}
              </h2>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {<MailIcon style={{ color: '#3f3f3f', fontSize: 18, marginTop: 2 }}/>}
              <h4 style={{ fontSize: 17, lineHeight: 1.3, margin: 0, marginLeft: 5, marginRight: 5, marginBottom: 2, color: '#3f3f3f', fontWeight: '200' }}>{ticket.requester}</h4>
              {createdAtDate ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10, marginBottom: 2 }}>
                  |
                  <CalendarIcon style={{ marginLeft: 10, marginTop: 2, color: '#3f3f3f', fontSize: 18 }} />
                  <h4 style={{ fontSize: 17, lineHeight: 1.3, margin: 0, marginLeft: 5, color: '#3f3f3f', fontWeight: 200 }}>
                    <Entity key={ticket.id} entity="createdAt" data={{ date: createdAtDate }} />
                  </h4>
                </div>
              ):null}
            </div>
          </div>
          <div>
            <Divider style={{ marginTop: 0, marginBottom: 15 }} />
            <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>{<Entity entity="ticketState" />}</h4>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <div style={{flexGrow:6}}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5, marginBottom: 5 }}>
                <h5 style={{ fontWeight: 'bold', fontSize: 12, padding: 10, borderRadius:5, 
                  backgroundColor:'rgba('+TICKET_STATUS_COLORS[ticket.status]+',0.8)', color: 'white'}}>
                  {statusArray.filter(e=>e.value===ticket.status).length>0?
                  statusArray.filter(e=>e.value===ticket.status)[0].label:ticket.status}
                </h5>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                {canResolve?(
                  <MDButton
                    title={<Entity entity="resolveTicket" />}
                    containerStyle={{ margin: 0, marginRight: 20 }}
                    titleStyle={{ marginTop: 2 }}
                    icon={<CheckCircleOutlineOutlined style={{ fontSize: 22, color: 'white', marginLeft: 10, marginTop: 0 }} />}
                    style={{ minHeight: 30 }}
                    onClick={() => {this.onResolveTicketClick()}}
                  />
                ):null}
                {canClose?(
                  <MDButton
                    title={<Entity entity="closeTicket" />}
                    containerStyle={{ margin: 0, marginRight: 20, marginLeft: 40 }}
                    icon={<CloseIcon style={{ fontSize: 22, color: 'white', marginLeft: 10, marginTop: 0 }} />}
                    style={{ minHeight: 30 }}
                    titleStyle={{ marginTop: 2 }}
                    backgroundColor={RED}
                    onClick={() => {this.onCloseTicketClick()}}
                  />
                ):null}
                {canOpen?(
                  <MDButton
                    title={<Entity entity="openTicket" />}
                    containerStyle={{ margin: 0, marginRight: 20, marginLeft: 40 }}
                    icon={<CloseIcon style={{ fontSize: 22, color: 'white', marginLeft: 10, marginTop: 0 }} />}
                    style={{ minHeight: 30 }}
                    backgroundColor={BLUE}
                    onClick={() => {this.onOpenTicketClick()}}
                  />
                ):null}
              </div>
            </div>
          </div>
          {canEdit?(
            <div style={{marginTop: 15}}>
              <Divider style={{ marginTop: 0, marginBottom: 15 }} />
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>{<Entity entity="ticketAssignee" />}</h4>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', marginTop: 10 }}>
                <div style={{flexGrow: 1}}>
                  <Field
                    name="assignee"
                    component={SelectableField}
                    noCache
                    options={assigneesArray}
                    placeholder={<Entity entity="ticketAssignee" />}
                    style={{ marginTop: 0 }}
                    onInputChange={null}
                    onSelect={null}
                  />
                </div>
              </div>
            </div>
          ):null}
          {canEdit?(
            <div style={{marginTop: 15}}>
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>{<Entity entity="ticketPriority" />}</h4>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', marginTop: 10 }}>
                <div style={{flexGrow: 1}}>
                  <Field
                    name="priority"
                    component={SelectableField}
                    noCache
                    options={prioritiesArray}
                    placeholder={<Entity entity="ticketPriority" />}
                    style={{ marginTop: 0 }}
                    onInputChange={null}
                    onSelect={null}
                  />
                </div>
              </div>
            </div>
          ):null}
          {canEdit?(
            <div style={{marginTop: 15}}>
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}>{<Entity entity="ticketCategory" />}</h4>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', marginTop: 10 }}>
                <div style={{flexGrow: 1}}>
                  <Field
                    name="category"
                    component={SelectableField}
                    noCache
                    options={categoriesArray}
                    placeholder={<Entity entity="ticketCategory" />}
                    style={{ marginTop: 0 }}
                    onInputChange={null}
                    onSelect={null}
                  />
                </div>
              </div>
            </div>
          ):null}
          {canEdit?(
            <MDButton
              title={<Entity entity="save" />}
              style={{ height: 35, borderRadius: 5 }}
              containerStyle={{ margin: 0, marginTop: 10, marginBottom: 10 }}
              onClick={() => {this.onTicketEditConfirmed()}}
            />
          ):null}
          <ReactTooltip delayShow={200} effect="float" />
        </div>
        <div id="comments-header" style={{ padding: 20, paddingTop: 10, paddingBottom: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
            <h2 style={{ fontSize: 25, marginTop: 0, color: '#3f3f3f', marginBottom: 0, fontWeight: 'bold' }}>
              <Entity entity="ticketComments" />
            </h2>
            {_.size(comments) > 5 ? (
              <IconButton
                style={{ marginTop: 3 }}
                onClick={() => {
                  const lastCommentId = _.size(comments) - 1;
                  const el = document.getElementById(lastCommentId);
                  if (el) {
                    el.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
              >
              <ArrowDownLineIconCustom style={{ width: 22, height:22, color: '#3f3f3f' }} />
            </IconButton>
            ) : null}
          </div>
          <div style={{ backgroundColor: '#f0f0f0', borderRadius: 10, paddingTop: 10, paddingBottom: 10, marginTop: 5 }}>
            {_.map(comments, (comment, index) => {
              const commentFromTicketManager = tickets && tickets.ticketManagers && tickets.ticketManagers.content && 
                tickets && tickets.ticketManagers && tickets.ticketManagers.content.some(e=>e.email===comment.user)
              const commentTime = comment.time ? moment(comment.time).format('LLL') : null;
              const commentAttachments = comment.attachments?Object.entries(comment.attachments).map(([key, value]) => ({name: key,url: value})):[];
              return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div id={index} style={{display:'flex',flexDirection:'row', justifyContent:commentFromTicketManager?'end':'start', zIndex: 0}}>
                    <div className="card card-noHover" 
                      style={{ backgroundColor: commentFromTicketManager?'#F8F9FA':'aliceblue', position: 'relative', padding: 15, marginLeft: 15, marginRight: 20, marginTop: 10, marginBottom: 5, minWidth:'40%', maxWidth:'80%', borderRadius: 10}}>
                      <div style={{display:'flex',flexDirection:commentFromTicketManager?'row-reverse':'row', alignItems:'center'}}>
                        {commentFromTicketManager?(
                          <PlatformUserIconCustom style={{ width: 22, height:22, marginRight: 5, color: '#3f3f3f' }}/>
                        ):(
                          <UserOutlineIconCustom style={{ width: 22, height:22, marginRight: 5, color: '#3f3f3f' }} />
                        )}
                        <h4 style={{ fontWeight:'bold', fontSize: 17, lineHeight: 1.3, margin: 0, marginLeft: 5, marginRight: 5, marginBottom: 2, color: '#3f3f3f' }}>{comment.user}</h4>
                      </div>
                      <h4 style={{ fontSize: 14, lineHeight: 1.3, margin: 0, marginLeft: 5, color: '#3f3f3f', fontWeight: 200, marginTop: 5, textAlign:commentFromTicketManager?'right':'left' }}>{commentTime?commentTime:null}</h4>
                      <Divider style={{ marginTop: 10, marginBottom: 10, marginLeft: -15, marginRight: -15 }} />
                      <div style={{display:'flex',flexDirection:'row', alignItems:'center',
                        padding: 5, paddingRight: commentFromTicketManager?15:5}}>
                        <h4 style={{ fontSize: 17, lineHeight: 1.3, margin: 0, marginLeft: 5, color: '#3f3f3f', whiteSpace: "pre-wrap", lineBreak:'anywhere' }}>
                          {comment.comment}
                        </h4>
                      </div>
                      {commentAttachments && !_.isEmpty(commentAttachments) ? (
                        <div style={{ width:'fit-content',paddingTop:10 }}>
                          {_.map(commentAttachments, (attachment) => {
                            return (
                              <div onClick={(e)=>{window.open(`${attachment.url}`, '_blank')}} style={{ cursor: 'pointer' }}>
                                <img src={attachment.url}  style={{ width: '50%'}} />
                              </div>
                            )
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {_.size(comments) > 5 && index === _.size(comments) - 1 ? (
                    <IconButton
                      style={{ margin: 5, alignSelf:commentFromTicketManager?'flex-end':'flex-start', }}
                      onClick={() => {
                        const el = document.getElementById('comments-header');
                        if (el) {
                          el.scrollIntoView({ behavior: 'smooth' })
                        }
                      }}
                    >
                    <ArrowUpLineIconCustom style={{ width: 22, height:22, color: '#3f3f3f' }} />
                  </IconButton>
                  ) : null}
                </div>
                )
              })}
          </div>
        </div>
        {canEditTicket ? (
          <div className="card card-noHover" style={{ backgroundColor: '#F8F9FA', position:'sticky', bottom:0, width:'100%', padding:20, paddingBottom:80, zIndex:10000, display:'flex', flexDirection:'column'}}>
            <div style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center'}}>
              <TextareaAutosize 
                value={newMessage}
                onChange={(e)=>{this.setState({ newMessage: e.target.value })}}
                aria-label="ticket-message-textarea" 
                minRows={3}
                maxRows={5}
                placeholder={L20NContext.getSync('messagePlaceholder')} 
                style={{flexGrow:1, resize:'none', padding:10, fontSize: 17, lineHeight: 1.3, color: '#3f3f3f', fontWeight: 200, border:'1px solid '+BLUE, borderRadius:5 }}
              />
              <MDButton
                backgroundColor={BLUE}
                icon={<SendIcon style={{ width: 20, color: 'white', marginLeft: 10, marginTop: 0 }} />}
                title={<Entity entity="ticketSendMessage" />}
                style={{ height: 35, borderRadius: 5 }}
                containerStyle={{ margin: 0, marginTop: 5, marginBottom: 5, marginLeft: 10 }}
                onClick={() => {this.onNewMessageRequest()}}
              />
            </div>
            <div>
              <h4 style={{ fontWeight: 'bold', fontSize: 16, color: '#3f3f3f', margin: 0, marginTop: 10, marginBottom: newMessageAttachments && _.isArray(newMessageAttachments) && newMessageAttachments.length>0? 0:10}}>
                <Entity entity="ticketAttachments" />
              </h4>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                {newMessageAttachments && _.isArray(newMessageAttachments) ? _.map(newMessageAttachments, (attachment, index) =>
                  <Card style={{ width: 'fit-content', padding: 10, display: 'flex', alignItems: 'center', margin: 10 }}
                    key={index}
                    onMouseEnter={() => this.setState({activeHoverIndex: index})}
                    onMouseLeave={() => this.setState({activeHoverIndex: -1})}>
                    <Icon
                      onClick={e => this.onDeleteFile(index)}
                      style={{ marginRight: '5px', color: 'red', fontSize: 20, cursor: 'pointer' }}
                      glyph="icon-fontello-cancel-circled-1"
                    />
                    <h5>{attachment}</h5>
                  </Card>
                ) : null}
              </div>
              <Field
                name="image"
                accept="image/*"
                className="drop-file-container"
                activeClassName="drop-file-container-active"
                multiple={true}
                maxSize={2*1048576}
                component={DropFileMultiField}
                onFileRejected={() => this.onFileRejected()}
                onDeleteFile={null}
                style={{}}
              >
                  <h5 style={{ marginBottom: 5 }}><Entity entity="dropFiles" /></h5>
                  <h5 style={{ marginTop: 0, marginBottom: 5 }}><Entity entity="orSelect" /></h5>
                  <h5 style={{ marginTop: 0 }}>(<Entity entity="maxUploadSizeDynamic" data={{ megabytes: 2 }} />)</h5>
              </Field>
            </div>
          </div>
        ): null}
      </Form>
    );
  }
} 

export default withStyles(styles)(TicketDetailsView);
