import axios from 'axios';
import { axiosInstance } from './rest';
import { generateRandomNumber, getInvitationBaseUrl } from './utils';

export function fetchInvitations(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get('/api/v2/invitations', restParams);
}

export function createInvitation(invitationDTO) {
  return axiosInstance.post('/api/v2/invitations', invitationDTO);
}

export function deleteInvitation(invitationId) {
  return axiosInstance.delete(`/api/v2/invitations/${invitationId}`);
}

export function fetchInvitationDevices(invitationId, params) {
  const restParams = {
    params,
  };
  return axiosInstance.get(`/api/v2/invitations/${invitationId}/devices`, restParams);
}


export const invitationsInstance = axios.create({
  timeout: 30000,
  headers: { 'SL-UUID': String(generateRandomNumber(6)) },
  baseURL: getInvitationBaseUrl(),
});

export function setBaseUrl(baseURL) {
  invitationsInstance.defaults.baseURL = baseURL;
}

export function redeemInvitationCode(codeDTO) {
  return invitationsInstance.post('/api/v2/invitations/redeem', codeDTO);
}


