// @ts-nocheck
import _ from 'lodash';
import { createTheme, Divider, IconButton, withStyles, Card } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { BLUE, GREEN, ORANGE, RED } from '../../_config/consts';
import MDButton from '../MDButton/MDButton.jsx';
import CreditsIconCustom from '../CustomIcons/CreditsIconCustom.jsx';
import { connect } from 'react-redux';
import ArrowRightIconCustom from '../CustomIcons/ArrowRightIconCustom.jsx';
import Warning from '@material-ui/icons/Warning';

const styles = theme => ({
  container: {
    borderRadius: 10,
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 15,
  },
  title: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'center',
  },
  creditsInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  creditsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  costText: {
    fontSize: 30,
    color: GREEN,
    margin: 0,
  },
  costDescriptionText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    margin: 0,
  },
  recurrentText: {
    color: ORANGE,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 0,
    marginTop: 20
  },
  warningContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
  },
  creditCardContainer: {
    width: 'fit-content',
    margin: 'auto',
    padding: 20,
    borderRadius: 10,
    paddingLeft: 40,
    paddingRight: 40,
    marginTop: 30,
    borderLeft: '4px solid #4DB6AC',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

@connect(state => ({ wallet: state.cloudCredits.wallet }))
class StoreActivationView extends React.Component {
  render() {
    const { classes, item, wallet, onActivateSubscription, onRechargeWalletRequest } = this.props;
    const isItemFree = item.fixedPrice === 0 && item.burnRateDaily === 0 && item.burnRate === 0;
    const isCreditInsufficient = !isItemFree && wallet.availableCredits <= item.fixedPrice;
    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ padding: 20 }}>
          <div className={classes.container}>
            <h1 className={classes.title} style={{ margin: 0 }}><Entity entity="activateSubscription" /></h1>
            <h2 className={classes.title} style={{ margin: 0, fontSize: 27, marginTop: 10, fontWeight: 'normal' }}><Entity entity={item.title} /></h2>
            <Card className={classes.creditCardContainer}>
              <h2 className={classes.title} style={{ textAlign: 'center', marginTop: 0, marginBottom: 20 }}><Entity entity="howYourWalletChange" /></h2>
              <div className={classes.creditsContainer}>
                <div className={classes.creditsInnerContainer} style={{ marginRight: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h4 className={classes.costText}><Entity entity="walletCreditsConsumptionText" data={{ credits: wallet.burnRate }} /></h4>
                  </div>
                  <h4 className={classes.costDescriptionText}><Entity entity="currentBurnRate" /></h4>
                </div>
                <ArrowRightIconCustom style={{ width: 50, color: '#3f3f3f' }} />
                <div className={classes.creditsInnerContainer} style={{ marginLeft: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h4 className={classes.costText}><Entity entity="walletCreditsConsumptionText" data={{ credits: wallet.burnRate + item.burnRate }} /></h4>
                  </div>
                  <h4 className={classes.costDescriptionText}><Entity entity="updatedBurnRate" /></h4>
                </div>
              </div>
              <h4 className={classes.recurrentText}><Entity entity="extensionCost" data={{ credits: item.fixedPrice }} /><CreditsIconCustom style={{ color: ORANGE, width: 20, marginLeft: 2 }} /></h4>
            </Card>
            {isCreditInsufficient ? (
              <div className={classes.warningContainer}>
                <Warning style={{ color: RED, fontSize: 30 }} />
                <h4 className={classes.title}><Entity entity="unsufficientCredit" /></h4>
                <MDButton
                  backgroundColor={GREEN}
                  onClick={() => onRechargeWalletRequest()}
                  style={{ width: 400 }}
                  title={<Entity entity="rechargeWallet" />}
                />
              </div>
            ) : null}
            <div className={classes.requestDemoContainer}>
              <MDButton
                title={<Entity entity="activateNow" />}
                containerStyle={{ marginTop: 40, marginBottom: 0 }}
                titleStyle={{ fontSize: 17, fontWeight: 'bold' }}
                backgroundColor={GREEN}
                disabled={isCreditInsufficient}
                style={{ marginTop: 0, height: 50 }}
                onClick={() => onActivateSubscription()}
              />
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}
export default withStyles(styles)(StoreActivationView);
