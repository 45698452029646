// @ts-nocheck
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import GatewayIcon from '@material-ui/icons/Cast';
import LockGroupIcon from '@material-ui/icons/Layers';
import Checkbox from '@material-ui/core/Checkbox';

import { IconButton } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import RefreshIcon from '@material-ui/icons/Autorenew';
import OfflineIcon from '@material-ui/icons/CloudOff';
import HyperTagIcon from '@material-ui/icons/Nfc';
import SmartClassicIcon from '@material-ui/icons/WifiTethering';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ACCESSORIES_TYPES, BATTERY_IMAGES, BLUE, GREEN, PERMISSION_ENTITIES, PERMISSIONS, SMARTLOCK_DOOR_STATUS, SMARTLOCK_DOOR_STATUS_MAP, SOURCE_TYPE, VISIBLE_TAGS_NUMBER } from '../../_config/consts';
import { getColorFromThemeName, getLockImage, getStatusColorFromBatteryLevel } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import CustomFieldsDisplayView from '../CustomFields/CustomFieldsDisplayView.jsx';
import CameraIconCustom from '../CustomIcons/CameraIconCustom.jsx';
import KeyOutlinedIconCustom from '../CustomIcons/KeyOutlinedIconCustom.jsx';
import MapIconCustom from '../CustomIcons/MapIconCustom.jsx';
import PrivacyIconCustom from '../CustomIcons/PrivacyIconCustom.jsx';
import ShareIconCustom from '../CustomIcons/ShareIconCustom.jsx';
import SmartReaderIconCustom from '../CustomIcons/SmartReaderIconCustom.jsx';
import SortIconCustom from '../CustomIcons/SortIconCustom.jsx';
import UnsortIconCustom from '../CustomIcons/UnsortIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import Tag from '../Tag.jsx';

import * as LockActions from '../../redux/actions/lock.actions';
import SortAscendingIconCustom from '../CustomIcons/SortAscendingIconCustom.jsx';
import SortDescendingIconCustom from '../CustomIcons/SortDescendingIconCustom.jsx';
import SelectMultipleCheckBoxIconCustom from '../CustomIcons/SelectMultipleCheckBoxIconCustom.jsx';
import TagIconCustom from '../CustomIcons/TagIconCustom.jsx';
import TagRemoveIconCustom from '../CustomIcons/TagRemoveIconCustom.jsx';
import TagAddIconCustom from '../CustomIcons/TagAddIconCustom.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumnHeaderText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeaderArrow: {
    padding: 10,
    marginLeft: 10,
  },
  tableColumnHeaderArrowIcon: {
    fontSize: 20,
    width: 20
  },
  tableColumnHeaderSortIcon: {
    width: 24,
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    fontSize: 10,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: BLUE,
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    marginRight: 10,
    width: 'max-content',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lockRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  rowBatteryLevel: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    justifycontent: 'center',
    alignItems: 'center',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, locks: state.locks, themeName: state.settings.items.theme.data.themeName }))
class LocksTableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionModeActive: false,
      selectedAllActive: false,
      selectedRows: [],
    }
  }
  
  async handleOrderChange(column) {
    const { onOrderChanged, dispatch } = this.props;
    const { locks: { data: { sorting: {orderBy, orderDir} } } } = this.props;
    const orderDir_new = orderBy===column?(orderDir==='ASC'?'DESC':'ASC'):'ASC';
    await dispatch(LockActions.setOrder(column, orderDir_new));
    onOrderChanged()
  };

  async onResetOrder() {
    const { onOrderChanged, dispatch } = this.props;
    await dispatch(LockActions.setOrder(undefined, undefined));
    onOrderChanged()
  }

  onToggleTableSelection() {
    const { selectionModeActive, selectedRows, selectedAllActive } = this.state;
    const selection = !selectionModeActive ? selectedRows : [];
    const selectAllValue = !selectionModeActive ? selectedAllActive : false;
    this.setState({ selectionModeActive: !selectionModeActive, selectedRows: selection, selectedAllActive: selectAllValue });
  }

  onToggleRowSelection(isSelected, lock) {
    const { selectedRows } = this.state;
    const newSelection = selectedRows;
    if (!isSelected) {
      newSelection.push(lock);
    } else {
      const index = _.findIndex(newSelection, existingLock => existingLock.id === lock.id);
      _.pullAt(newSelection, index);
    }
    this.setState({ selectedRows: newSelection });
  }

  onSelectAll(isSelected) {
    const { selectedRows } = this.state;
    let newSelection = selectedRows;
    if (!isSelected) {
      const { locks: { data: { content: locksData } } } = this.props;
      newSelection = _.clone(locksData);
    } else {
      newSelection = [];
    }
    this.setState({ selectedRows: newSelection, selectedAllActive: !isSelected });
  }

  onAddTagsBatch(locks) {
    const { onAddTagsBatch } = this.props;
    this.setState({ selectedRows: [], selectionModeActive: false });
    onAddTagsBatch(locks);
  }

  onRemoveTagsBatch(locks) {
    const { onRemoveTagsBatch } = this.props;
    this.setState({ selectedRows: [], selectionModeActive: false });
    onRemoveTagsBatch(locks);
  }

  render() {
    const { classes, locks: { selectedLock, data: { content: locksData, pagination: { number: currentPage, size, totalPages, totalElements }, sorting: {orderBy, orderDir} } }, themeName, onFetchLocksOnPage, onShowCameraLive, onSelectLock, onOpenLock, onRefreshLocks, onSetLockTagsVisibility, onShowLockMap } = this.props;
    const { selectionModeActive, selectedAllActive, selectedRows } = this.state;
    const canSendGatewayNotification = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.GATEWAY_NOTIFICATION);
    const canReadLockTags = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK_TAG);
    const canEditLocks = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.SMART_LOCK);
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(locksData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell colSpan={selectionModeActive ? 2 : 1}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="locks" /></h3>
                        <IconButton onClick={() => onRefreshLocks()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                        {canEditLocks ? (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <IconButton onClick={() => this.onToggleTableSelection()} style={{ marginTop: 5 }}>
                              <SelectMultipleCheckBoxIconCustom style={{ color: 'white', width: 24 }} />
                            </IconButton>
                            {selectionModeActive && !_.isEmpty(selectedRows) ? (
                              <div style={{ marginLeft: 8, display: 'flex', alignItems: 'center', marginTop: 3 }}>
                                <IconButton onClick={() => this.onAddTagsBatch(selectedRows)}>
                                  <TagAddIconCustom style={{ color: 'white', width: 25 }} />
                                </IconButton>
                              </div>
                            ) : null}
                            {selectionModeActive && !_.isEmpty(selectedRows) ? (
                              <div style={{ marginLeft: 8, display: 'flex', alignItems: 'center', marginTop: 3 }}>
                                <IconButton onClick={() => this.onRemoveTagsBatch(selectedRows)}>
                                  <TagRemoveIconCustom style={{ color: 'white', width: 25 }} />
                                </IconButton>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                        {orderBy && 
                          <IconButton onClick={() => this.onResetOrder()} style={{ marginLeft: 5, marginTop: 5 }}>
                            <UnsortIconCustom style={{ color: 'white', width: 25 }} />
                          </IconButton>
                        }
                      </div>
                    </TableCell>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="locksTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchLocksOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          lastPage={totalPages - 1}
                          iconColor="white"
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchLocksOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  <TableRow>
                    {selectionModeActive ? (
                      <TableCell padding="checkbox">
                        <Checkbox classes={{root: 'custom-checkbox-root'}} color="primary" checked={selectedAllActive} onChange={checked => this.onSelectAll(selectedAllActive)} />
                      </TableCell>
                    ) : null}
                    <TableCell>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="lockName" /></h4>
                        {orderBy === 'name'?(
                          <IconButton onClick={() => this.handleOrderChange('name')} style={{opacity: 1}} className={classes.tableColumnHeaderArrow}>
                            {orderDir==='ASC'||orderBy!=='name'? <SortDescendingIconCustom className={classes.tableColumnHeaderArrowIcon}/> : <SortAscendingIconCustom className={classes.tableColumnHeaderArrowIcon}/>}
                          </IconButton>
                        ):(
                          <IconButton onClick={() => this.handleOrderChange('name')} style={{opacity: 0.6}} className={classes.tableColumnHeaderArrow}>
                            {<SortIconCustom className={classes.tableColumnHeaderSortIcon}/>}
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="powerSource" /></h4></TableCell>
                    {canReadLockTags ? <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="lockTags" /></h4></TableCell> : <TableCell />}
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="actions" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(locksData, (lock) => {
                    const isRowSelected = _.indexOf(selectedRows, lock) !== -1;
                    const isF9000 = lock && lock.model === 'F9000';
                    const textStatusColor = getStatusColorFromBatteryLevel(lock.battery);
                    const isConnectedToGateway = lock.gatewayId;
                    const isFullOnlineGate = lock && lock.configuration && lock.configuration.remoteOpenEnabled;
                    const canBeOpenRemotely = (lock.gatewayId && canSendGatewayNotification) || isFullOnlineGate;
                    const lockGroup = lock && lock.configuration && lock.configuration.group ? lock.configuration.group : '';
                    const lockSensor = lock.accessories ? _.find(lock.accessories, accessory => accessory.type === ACCESSORIES_TYPES.DOOR_SENSOR) : null;
                    const lockReader = lock.accessories ? _.find(lock.accessories, accessory => accessory.type === ACCESSORIES_TYPES.BT_READER) : null;
                    const doorStatusVariables = lockSensor && lockSensor.value && SMARTLOCK_DOOR_STATUS_MAP[lockSensor.value] ? SMARTLOCK_DOOR_STATUS_MAP[lockSensor.value] : SMARTLOCK_DOOR_STATUS_MAP[SMARTLOCK_DOOR_STATUS.UNKNOWN];
                    const showDoorStatus = doorStatusVariables && lockSensor;
                    const showMoreTagsSectionVisible = lock && lock.tags && _.size(lock.tags) > VISIBLE_TAGS_NUMBER;
                    const visibleTags = lock && lock.showAllTags ? lock.tags : _.slice(lock.tags, 0, VISIBLE_TAGS_NUMBER);
                    return (
                      <TableRow
                        key={lock.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={selectionModeActive ? () => this.onToggleRowSelection(isRowSelected, lock) : (event) => onSelectLock(lock)}
                        selected={selectedLock && selectedLock.id === lock.id}
                      >
                        {selectionModeActive ? (
                          <TableCell padding="checkbox">
                            <Checkbox classes={{root: 'custom-checkbox-root'}} color="primary" checked={isRowSelected} onChange={() => this.onToggleRowSelection(isRowSelected, lock)} />
                          </TableCell>
                        ) : null}
                        <TableCell>
                          <div className={classes.lockRowContainer}>
                            <div style={{ position: 'relative' }}>
                              <img alt="lock" className="lock-icon" style={{ width: 45, height: 45, marginRight: 15 }} src={getLockImage(lock.model)} />
                              {lockReader ? (
                                <div className="lock-icon" style={{ width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 4, top: 26, backgroundColor: '#f0f0f0' }}>
                                  <img style={{ width: 15, height: 15 }} src={getLockImage(lockReader.model)} />
                                </div>
                                ) : null}
                            </div>
                            <div style={{ marginRight: 10, marginLeft: 5 }}>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'max-content' }}>
                                <h5 style={{ fontWeight: 'bold', fontSize: 19 }}>{lock.name}</h5>
                                <div style={{ marginTop: 12.5, marginBottom: 12.5, marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  {showDoorStatus ? (
                                    <p style={{ margin: 0, alignSelf: 'flex-start', display: 'flex', marginBottom: 0, width: 'max-content' }}>
                                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 15 }}>
                                        {doorStatusVariables.icon}
                                        <Entity key={doorStatusVariables.title} style={{ fontSize: 13, marginLeft: 5, fontWeight: 'bold', color: doorStatusVariables.color }} entity={doorStatusVariables.title} />
                                      </div>
                                    </p>
                                  ) : null}
                                  {isFullOnlineGate ? (
                                    <p style={{ margin: 0, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('lockIsSmartClassicOrOnline')}><SmartClassicIcon style={{ fontSize: 20, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 15 }} /></p>
                                    ) : null}
                                  {isF9000 ? (
                                    <p style={{ margin: 0, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('lockIsMechatronicCylinder')}><KeyOutlinedIconCustom style={{ width: 20, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 15 }} /></p>
                                  ) : null}
                                  {lock.hyperTagId ? (
                                    <p style={{ margin: 0, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('lockHasHyperTag')}><HyperTagIcon style={{ fontSize: 18, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 15 }} /></p>
                                    ) : null}
                                  {lock.availableOffline ? (
                                    <p style={{ margin: 0, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('availableOffline')}><OfflineIcon style={{ fontSize: 18, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 0, marginRight: 15 }} /></p>
                                  ) : null}
                                  {lock.privacyModeEnabled ? (
                                    <p style={{ margin: 0, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('privacyModeEnabled')}><PrivacyIconCustom style={{ width: 19, alignSelf: 'flex-end', color: '#3f3f3f', marginBottom: 1, marginRight: 15 }} /></p>
                                  ) : null}
                                  {isConnectedToGateway ? (
                                    <p style={{ margin: 0, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('lockIsConnectedToGateway')}><GatewayIcon style={{ fontSize: 16, color: '#3f3f3f', marginTop: 1, marginRight: 15 }} /></p>
                                    ) : null}
                                  {lock.lockHasCamera ? (
                                    <p style={{ margin: 0, marginTop: 2, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('lockIsConnectedToCamera')}><CameraIconCustom style={{ width: 18, alignSelf: 'flex-end', color: '#3f3f3f', marginTop: -2, marginBottom: 0, marginRight: 15 }} /></p>
                                  ) : null}
                                  {/* {lockReader ? (
                                    <p style={{ margin: 0, marginTop: 2, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('lockIsConnectedWithAReader')}><SmartReaderIconCustom style={{ width: 18, alignSelf: 'flex-end', color: '#3f3f3f', marginTop: -2, marginBottom: 0, marginRight: 15 }} /></p>
                                    ) : null} */}
                                  {lock.shared || lock.sharedFromHostname ? (
                                    <p style={{ margin: 0, marginTop: 1, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('lockIsSharedWithDomain')}><ShareIconCustom style={{ width: 18, alignSelf: 'flex-end', color: '#3f3f3f', marginTop: -2, marginBottom: 0, marginRight: 15 }} /></p>
                                  ) : null}
                                </div>
                              </div>
                              <h5 style={{ fontSize: 13, marginTop: 0 }}>{lock.serialNumber}</h5>
                              <CustomFieldsDisplayView
                                textStyle={{ fontSize: 13, fontWeight: 'normal' }}
                                hideIcon
                                containerStyle={{ minWidth: 400, marginLeft: -5, marginTop: -5, flexWrap: 'wrap', marginBottom: 10 }}
                                customFields={lock.customFields}
                              />
                              {lockGroup ? (
                                <div style={{ marginTop: -5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <LockGroupIcon style={{ width: 19, color: '#3f3f3f', marginBottom: 1 }} />
                                  <h5 style={{ fontSize: 15, margin: 0, marginLeft: 5 }}>{_.startCase(lockGroup)}</h5>
                                </div>
                              ) : null}
                            </div>
                            {lock.locationMedia ? (
                              <IconButton onClick={(e) => { e.stopPropagation(); onShowLockMap(lock);}}>
                                <MapIconCustom style={{ width: 20, color: GREEN }} />
                              </IconButton>
                            ) : null}
                          </div>
                        </TableCell>
                        <TableCell>
                          {!isF9000 ? (
                            <div className={classes.rowWithAction}>
                              {lock.powerSourceType === SOURCE_TYPE.BATTERY && lock.battery !== undefined && lock.battery !== -1 ? (
                                <div className={classes.rowBatteryLevel}>
                                  <img alt="battery" src={BATTERY_IMAGES[lock.battery]} style={{ width: 32, height: 12.5 }} />
                                  <h5 style={{ margin: 0, marginLeft: 10, color: textStatusColor, fontWeight: 'bold' }}>{lock.battery}%</h5>
                                </div>
                              ) : null}
                              {lock.powerSourceType === SOURCE_TYPE.PLUGGED ? (
                                <div className={classes.rowBatteryLevel}>
                                  <img alt="battery" src={'/imgs/common/batteryIcons/lightning.png'} style={{ width: 15, height: 25, marginRight: 10 }} />
                                  <h5 style={{ color: 'goldenrod', fontWeight: 'bold' }}><Entity key={lock.id} entity="powerSourceTypes" data={{ type: lock.powerSourceType }} /></h5>
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {canReadLockTags ?
                            <div className="row-tags-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                              {lock && lock.lockTag && (
                                <Tag
                                  tag={lock.lockTag}
                                  style={{ padding: 5 }}
                                  textStyle={{ fontSize: 14 }}
                                />
                              )}
                              {_.map(visibleTags, tag =>
                                <Tag
                                  key={tag.id}
                                  tag={tag}
                                  style={{ padding: 5 }}
                                  textStyle={{ fontSize: 14 }}
                                />,
                              )}
                              {showMoreTagsSectionVisible ? (
                                <div>
                                  {lock && lock.showAllTags ? (
                                    <h5
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onSetLockTagsVisibility(lock, false);
                                    }}
                                    className="link-label"
                                    style={{ color: BLUE, marginLeft: 10, marginTop: 18 }}>
                                      <Entity entity="showLess" />
                                    </h5>
                                  ) : (
                                    <h5
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onSetLockTagsVisibility(lock, true);
                                      }}
                                      className="link-label"
                                      style={{ color: BLUE, marginLeft: 10, marginTop: 18 }}>
                                        ...<Entity entity="showXMore" data={{ number: _.size(lock.tags) - VISIBLE_TAGS_NUMBER }} />
                                      </h5>
                                    )}
                                </div>
                              ): null}
                            </div>
                            : null}
                        </TableCell>
                        <TableCell>
                          {canBeOpenRemotely ? (
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                onOpenLock(lock);
                              }}
                              className={classes.button}
                              style={{ color: GREEN }}
                            >
                              <Entity entity="openLock" />
                            </Button>
                          ) : null}
                          {lock.lockHasCamera && lock.cameraId && (
                            <div className={classes.rowWithAction} style={{ justifyContent: 'flex-start' }}>
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onShowCameraLive(lock.cameraId);
                                }}
                                className={classes.button}
                                style={{ color: BLUE, marginTop: 5 }}
                              >
                                {'LIVE'}
                              </Button>
                            </div>
                          )}
                        </TableCell>
                        <ReactTooltip delayShow={200} effect="float" />
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ background: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="locksTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchLocksOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          iconColor="white"
                          onChangePage={(event, page) => onFetchLocksOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <EmptyView
                title="noLock"
                iconName="icon-simple-line-icons-lock"
                subtitle="startAddLock"
              />
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LocksTableView);
