import React, { Component } from 'react';
import _ from 'lodash';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { IconButton } from '@material-ui/core';

const FormCardContainer = ({ title, icon, iconButtonAction, subtitle, children, hideCard, iconButtonActionStyle, headerStyle, titleStyle, containerStyle, titleContainerStyle, subtitleStyle }) =>
  (
  <div className={!hideCard && "card card-noHover"} style={{ padding: 15, margin: 5, marginTop: 15, ...containerStyle }}>
    <div style={{ marginBottom: 10, ...headerStyle }}>
      {title && _.isString(title) &&
      <div style={{ display: 'flex', alignItems: 'center', ...titleContainerStyle }}>
        <h2 style={{ fontSize: 25, marginTop: 0, color: '#3f3f3f', marginBottom: 10, fontWeight: 'bold', ...titleStyle }}>
          <Entity entity={title} />
        </h2>
        <div style={{ marginLeft: 10 }}>
          {iconButtonAction && icon ? (
            <IconButton onClick={() => iconButtonAction()} style={{ marginBottom: 4, ...iconButtonActionStyle }}>
              {icon}
            </IconButton>
          ) : null}
          {icon && !iconButtonAction ? icon : null}
        </div>
      </div>
      }
      {subtitle && _.isString(subtitle) &&
      <h4 style={{ fontSize: 17, lineHeight: 1.4, marginTop: 0, color: '#3f3f3f', fontWeight: 200, ...subtitleStyle }}>
        <Entity entity={subtitle} />
      </h4>
      }
    </div>
    {children}
  </div>
    );

export default FormCardContainer;
