import React from 'react';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { Modal, Button } from '@sketchpixy/rubix';

import Alert from '../Alert.jsx';

export default class GuestUpdatedModal extends React.Component {
  hideModal() {
    const { hideModal } = this.props;
    hideModal();
  }

  render() {
    return (
      <Alert
        type="success"
        visible
        onOutsideClick={() => this.hideModal()}
        title={<Entity entity="modalTitle" data={{ modal: 'guestUpdated' }} />}
        message={<Entity entity="modalMessage" data={{ modal: 'guestUpdated' }} />}
        onConfirm={() => this.hideModal()}
      />
    );
  }
}
