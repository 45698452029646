
import { Warning } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/HelpOutline';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, reduxForm, submit } from 'redux-form';
import { BLUE, CARD_TYPES, ORANGE, PERMISSION_ENTITIES, PERMISSIONS, RED, SUBSCRIPTION_TYPES } from '../../_config/consts';
import { elaborateForbiddenDaysString, generateRandomNumber, hasFormWritePermission, isLockSupportingPIN } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as UserActions from '../../redux/actions/user.actions';
import * as ModalActions from '../../redux/actions/modal.actions.js';
import ShareIconCustom from '../CustomIcons/ShareIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import CredentialTimeSelector from './Elements/CredentialTimeSelector.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import LocksSelectorField from './Elements/LocksSelectorField.jsx';
import TagsSelectorListField from './Elements/TagsSelectorListField.jsx';
import CustomField from './Fields/CustomField.jsx';
import MDCheckBoxField from './Fields/MDCheckBox/MDCheckBoxField';
import MDSwitchField from './Fields/MDSwitch/MDSwitchField.jsx';
import GuestNoEmailForm from './GuestNoEmailForm.jsx';
import { Divider, Slider } from '@material-ui/core';
import DiceIconCustom from '../CustomIcons/DiceIconCustom.jsx';
import CodeInputField from './Fields/ CodeInputField.jsx';


const marks = [
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
];
const validate = (values) => {
  const errors = {};
  if (values) {
    if (!values.deviceId) {
      errors.deviceId = 'required';
    } else if (values.deviceId  && (values.deviceId.length < 4 || values.deviceId.length > 7)) {
      errors.deviceId = 'genericPINNumberInvalid';
    }
    if (!values.guestTags || (values.guestTags && _.isEmpty(values.guestTags))) {
      errors.guestTags = 'required';
    }

    if (!values.lockTags || (values.lockTags && _.isEmpty(values.lockTags))) {
      errors.lockTags = 'required';
    }


    if (!values.credentialTimeframe
        || !values.credentialTimeframe.startDate
        || !values.credentialTimeframe.endDate) {
      errors.credentialTimeframe = 'reservationDateIntevalErrorMessage';
    }
    
    if (values.credentialTimeframe && values.credentialTimeframe.endDate && moment(values.credentialTimeframe.endDate).isAfter('2099-12-31')) {
      errors.credentialTimeframe = 'errorEndDateTooBig';
    }
    
    if (!values.timeIntervalFrom) {
      errors.timeIntervalFrom = 'required';
    }

    if (!values.timeIntervalTo) {
      errors.timeIntervalTo = 'required';
    }
    if (values.timeIntervalTo && values.timeIntervalFrom) {
      const toTime = moment(values.timeIntervalTo);
      const fromTime = moment(values.timeIntervalFrom);
      const toDate = moment(0).hour(toTime.hour()).minute(toTime.minute()).second(0);
      const fromDate = moment(0).hour(fromTime.hour()).minute(fromTime.minute()).second(0);
      if (toDate.isSameOrBefore(fromDate)) {
        errors.timeIntervalTo = 'Interval not valid';
      }
    }
  }

  return errors;
};

@reduxForm({ form: 'PinCredentialsForm', validate })
@connect(state => ({ form: state.form.PinCredentialsForm }))
class PinCredentialsForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showGuestNoEmailForm: false,
      pinDigits: props.maxPinLength ? props.maxPinLength : 4,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(change('PinCredentialsForm', 'type', CARD_TYPES.ISEO_PIN));
  }
  
  onToggleShowGuestNoEmailForm(value) {
    this.setState({ showGuestNoEmailForm: value });
  }

  onChangePinLenght(pinDigits) {
    this.setState({ pinDigits })
  }

  onGenerateRandomPin() {
    const { dispatch } = this.props;
    const { pinDigits } = this.state;
    const newValue = generateRandomNumber(pinDigits);
    dispatch(change('PinCredentialsForm', 'deviceId', newValue));
  }

  onSubmitForm() {
    const { dispatch, form, maxPinLength } = this.props;
    const deviceId = form && form.values && form.values.deviceId ? form.values.deviceId : null;
    if (deviceId && maxPinLength && maxPinLength !== 0 && String(deviceId).length !== maxPinLength) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { 
          defaultMessage: <Entity entity="pinLenghtMustBeMaxLenght" data={{ maxPinLength }} />
          ,
        }
      }));
    } else {
      dispatch(submit('PinCredentialsForm'));
    }
  }

  render() {
    const {
      form,
      availableLockTags,
      availableUserTags,
      onGuestTagsChange,
      onLockTagsChange,
      guestsFilteredByTags,
      locksFilteredByTags,
      onLockFilterModeChange,
      onGuestInputChange,
      onLockInputChange,
      onFetchMoreGuests,
      onFetchMoreLocks,
      onDeleteCard,
      onSavePinNotes,
      onSharePinWithCommunication,
      onSharePin,
      dispatch,
      onCreateAnonymousUserForPin,
      maxPinLength
    } = this.props;
    const { showGuestNoEmailForm, pinDigits } = this.state;
    const notes = form && form.values && form.values.notes ? form.values.notes : '';
    const deviceId = form && form.values && form.values.deviceId ? form.values.deviceId : null;
    const isEditing = form && form.values && form.values.deviceBackendId;
    const canDeleteStandardDevices = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canReadUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const canReadLock = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const guestTags = form && form.values && form.values.guestTags;
    const dateFrom = form && form.values && form.values.credentialTimeframe && form.values.credentialTimeframe.startDate;
    const dateTo = form && form.values && form.values.credentialTimeframe && form.values.credentialTimeframe.endDate;
    const timeFrom = form && form.values && form.values.timeIntervalFrom;
    const timeTo = form && form.values && form.values.timeIntervalTo;
    const daysString = form && form.values && form.values.daysOfTheWeek && elaborateForbiddenDaysString(form.values.daysOfTheWeek) ? elaborateForbiddenDaysString(form.values.daysOfTheWeek) : '';
    const smartLocksSelected = form && form.values && form.values.selectedLocksTags;
    const locksHaveError = smartLocksSelected && smartLocksSelected.data ? _.find(smartLocksSelected.data, smartLock => !isLockSupportingPIN(smartLock)) : false;
    const isCommunicationPluginActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.COMMUNICATIONS));
    const hideGuestNoEmailSwitch = isEditing || !_.isEmpty(guestTags);
    const isPinLenghtFixed = maxPinLength && maxPinLength !== 0;
    return (
      <Form>
        <FormCardContainer
          title="pinData"
          headerStyle={{ marginBottom: 20 }}
          iconButtonAction={() => onSharePin()} //onSharePinWithCommunication({ deviceId, dateFrom, dateTo })}
          iconButtonActionStyle={{ marginBottom: 6 }}
          icon={isEditing ? <ShareIconCustom style={{ width: 20, color: BLUE }} /> : null}
        >
          {isPinLenghtFixed ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Entity
                componentClass={Field}
                name="deviceId"
                componentAttribute="placeholder"
                entity="insertPinCode"
                title={<Entity entity="pinCodeUnlock" />}
                component={CustomField}
                helpText={isEditing ? null : <Entity entity="fixedPinHelpText" data={{ maxPinLength }} />}
                textFormattingFunction={value => value.replace(/\s+/g, '').substring(0, maxPinLength)}
                type="number"
                mandatory
                disabled={form && form.values && form.values.deviceBackendId}
              />
            <MDButton
              style={{ marginBottom: 15,  width: 'fit-content', marginTop: 0, alignSelf: 'flex-end' }}
              backgroundColor={BLUE}
              icon={<DiceIconCustom style={{ width: 20, marginLeft: 10 }} />}
              title={<Entity entity="generate" />}
              onClick={() => this.onGenerateRandomPin()}
            />
            </div>
            ) : (
            <Entity
              componentClass={Field}
              name="deviceId"
              componentAttribute="placeholder"
              entity="insertPinCode"
              title={<Entity entity="pinCodeUnlock" />}
              component={CustomField}
              helpText={isEditing ? null : <Entity entity="cardFieldHelpText" data={{ type: CARD_TYPES.ISEO_PIN }} />}
              textFormattingFunction={value => value.replace(/\s+/g, '')}
              type="number"
              mandatory
              disabled={form && form.values && form.values.deviceBackendId}
            />
          )}
          {!isEditing && !isPinLenghtFixed ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5, marginLeft: 3 }}>
              <div style={{ width: '50%' }}>
                <h5 style={{ fontWeight: 'bold', fontSize: 16 }}><Entity entity="numberOfDigits" /></h5>
                <div style={{ paddingLeft: 10 }}>
                  <Slider
                    step={1}
                    min={4}
                    max={7}
                    value={pinDigits}
                    marks={marks}
                    aria-label="Large"
                    onChange={(e, value) => this.onChangePinLenght(value)}
                  />
                </div>
              </div>
              <MDButton
                style={{ marginLeft: 20, width: 'fit-content', marginTop: 10 }}
                backgroundColor={BLUE}
                icon={<DiceIconCustom style={{ width: 20, marginLeft: 10 }} />}
                title={<Entity entity="generate" />}
                onClick={() => this.onGenerateRandomPin()}
              />
            </div>
          ) : null}
        </FormCardContainer>
        <FormCardContainer
          title="cardDescription"
          subtitle="cardDescriptionSubtitle"
        >
          <Entity
            componentClass={Field}
            name="notes"
            componentAttribute="placeholder"
            entity="insertCardDescription"
            component={CustomField}
            type="textarea"
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
            className="form-control-custom"
          />
          {isEditing && hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing) && (
            <MDButton
              title={<Entity entity="saveNotes" />}
              backgroundColor={BLUE}
              onClick={() => onSavePinNotes(notes)}
            />
          )}
        </FormCardContainer>
        <FormCardContainer
          title="cardTagGuestTitle"
          subtitle="cardTagGuestSubtitle"
        >
          <TagsSelectorListField
            name="guestTags"
            formName="PinCredentialsForm"
            selectedTagsFieldName="selectedGuestTags"
            tags={availableUserTags}
            selectedTags={guestsFilteredByTags}
            form={form}
            tagsType="GUEST"
            title="guestTagsCard"
            placeholder="insertGuestTagsForCard"
            listTitle="selectedUsers"
            emptyTagSelectionText={canReadUser ? 'noUsersForSelectedTag' : null}
            onFetchMoreItems={page => onFetchMoreGuests(page)}
            onTagsChange={values => onGuestTagsChange(values)}
            onInputChange={value => onGuestInputChange(value)}
            isSingleChoice
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
          />
          {hideGuestNoEmailSwitch ? null : (
            <Field
              name="withoutEmail"
              label={<Entity entity="createNewUserForPIN" />}
              titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
              containerstyle={{ marginTop: 10 }}
              component={MDSwitchField}
              activeText="createNewUserForCardWarning"
              activeTextIcon={<Warning style={{ color: ORANGE, marginRight: 10, marginTop: 0, fontSize: 20 }} />}
              onHandleChange={value => this.onToggleShowGuestNoEmailForm(value)}
            />
          )}
          {showGuestNoEmailForm && !hideGuestNoEmailSwitch ? (
            <div>
              <GuestNoEmailForm
                onSubmit={(guestData) => onCreateAnonymousUserForPin(guestData)}
              />
              <MDButton
                title={<Entity entity="createUser" />}
                backgroundColor={BLUE}
                disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing)}
                onClick={() => dispatch(submit('GuestNoEmailForm'))}
              />
            </div>
          ) : null}
        </FormCardContainer>
        <FormCardContainer
          title="cardLockTitle"
          subtitle="cardTagLockSubtitle"
        >
          <LocksSelectorField
            name="lockTags"
            formName="PinCredentialsForm"
            selectedTagsFieldName="selectedLocksTags"
            tags={availableLockTags}
            selectedTags={locksFilteredByTags && locksFilteredByTags[0]}
            form={form}
            tagsType="LOCK"
            placeholder="insertLockTagsForCredential"
            listTitle="selectedLocks"
            emptyTagSelectionText={canReadLock ? 'noLocksSelectedWithTag' : null}
            title="lockTags"
            lockHasErrorCondition={lock => !isLockSupportingPIN(lock)}
            onFetchMoreItems={page => onFetchMoreLocks(page)}
            onTagsChange={values => onLockTagsChange(values)}
            onInputChange={value => onLockInputChange(value)}
            onFilterModeChange={values => onLockFilterModeChange(values)}
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
          />
          {locksHaveError ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Warning style={{ color: ORANGE, marginRight: 5, fontSize: 20 }} />
              <h5 style={{ color: ORANGE, fontWeight: 'bold' }}><Entity entity="selectedLocksPINIncompatibleError" /></h5>
            </div>
          ) : null}
        </FormCardContainer>
        <FormCardContainer title="credentialTimeAndDate">
          <CredentialTimeSelector
            formName="PinCredentialsForm"
            canEdit={hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
          />
          {dateFrom && dateTo ? (
            <div style={{ paddingTop: 10, borderTop: '2px solid #157495', marginLeft: 15, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <InfoIcon style={{ color: '#3f3f3f', marginRight: 12, fontSize: 20 }} />
              <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: '#3f3f3f' }}>
                <Entity
                  entity="hyperKeyVerbalDescription"
                  key={`${dateFrom}-${dateTo}-${timeFrom}-${timeTo}`}
                  data={{
                    dateFrom: moment(dateFrom).format('LL'),
                    dateTo: moment(dateTo).format('LL'),
                    timeFrom: moment(timeFrom).format('LT'),
                    timeTo: moment(timeTo).format('LT'),
                  }}
                />
                {!daysString ? '' : <span style={{ marginLeft: 5 }}><Entity entity="except" />{daysString}</span>}
              </h5>
            </div>
          ) : null}
        </FormCardContainer>
        {isCommunicationPluginActive && !isEditing ? (
          <FormCardContainer title="sharePinInformation" subtitle="sharePinInformationDescription">
            <Field
              name="sharePinWithCommunication"
              label={<Entity entity="sharePinAfterCreation" />}
              titleStyle={{ fontSize: 16, color: '#3f3f3f' }}
              component={MDCheckBoxField}
            />
          </FormCardContainer>
        ) : null}
        {hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing) && (
          <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
            <MDButton
              title={<Entity entity="save" />}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0 }}
              disabled={locksHaveError}
              onClick={() => this.onSubmitForm()}
            />
          </div>
        )
        }
        {onDeleteCard && canDeleteStandardDevices && (
          <FormCardContainer
            title="deleteCredential"
            subtitle="deleteCredentialCardWarning"
          >
            <MDButton
              title={<Entity entity="deletePinCode" />}
              backgroundColor={RED}
              onClick={() => onDeleteCard()}
            />
          </FormCardContainer>
        )}
      </Form>
    );
  }
} 

export default PinCredentialsForm;
