import { Card, createTheme, IconButton, LinearProgress, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import EnableIcon from '@material-ui/icons/NotificationsActive';
import DisableIcon from '@material-ui/icons/NotificationsOff';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { BLUE, ORANGE } from '../../../_config/consts';
import { getNotificationTypeStatus } from '../../../_config/utils';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 25,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '110%',
    borderRadius: 10,
    margin: 10,
    padding: 15,
    justifyContent: 'center',
    position: 'relative',
    borderLeft: '4px solid #157495',
  },
  descriptionText: {
    color: '#3f3f3f',
    margin: 0,
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  logo: {
    position: 'absolute',
    height: 30,
    right: 10,
    top: 10,
  },
  barColorPrimary: {
    backgroundColor: '#009688',
  },
  barColorError: {
    backgroundColor: '#e45353',
  },
  barColorWarning: {
    backgroundColor: ORANGE,
  },
  totalText: {
    marginTop: 10,
    color: '#157495',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
  },
  disableWarningContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
    position: 'absolute',
    top: 0,
    right: 2,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

class UsersAndLockLicenseCard extends React.Component {
  constructor(props) {
    super(props);
    const isUserPlusLockWarningModalDisabled = getNotificationTypeStatus('isUserPlusLockWarningModalDisabled');
    this.state = {
      isUserPlusLockWarningModalDisabled,
    };
  }

  onToggleNotificationStatus() {
    const { isUserPlusLockWarningModalDisabled } = this.state;
    localStorage.setItem('isUserPlusLockWarningModalDisabled', !isUserPlusLockWarningModalDisabled);
    this.setState({ isUserPlusLockWarningModalDisabled: JSON.parse(!isUserPlusLockWarningModalDisabled) });
  }

  render() {
    const { isUserPlusLockWarningModalDisabled } = this.state;
    const { classes, isEnterpise, license } = this.props;
    const usedKeysPerc = parseInt(((license.used / license.quantity) * 100), 10);
    const isWarning = usedKeysPerc < 95 && usedKeysPerc >= 80;
    const isError = usedKeysPerc >= 100;
    let statusColor = isWarning ? ORANGE : '#009688';
    statusColor = isError ? '#e45353' : statusColor;
    let barClassCss = isWarning ? classes.barColorWarning : classes.barColorPrimary;
    barClassCss = isError ? classes.barColorError : barClassCss;
    return (
      <MuiThemeProvider theme={theme}>
        <Card className={classes.container}>
          <h2 className={classes.titleText}><Entity entity="usersAndLocksPackage" data={{ users: isEnterpise ? '' : license.quantity }} /></h2>
          <div style={{ marginTop: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <h4 className={classes.totalText} style={{ color: statusColor }}><Entity entity="activeUsersAndLocks" /></h4>
              <h4 className={classes.totalText} style={{ color: statusColor }}>{`${license.used} / ${license.quantity}`}</h4>
            </div>
            <LinearProgress
              variant="determinate"
              value={usedKeysPerc}
              style={{ height: 15, borderRadius: 10 }}
              classes={{ barColorPrimary: barClassCss }}
            />
            <div key={`cont-${isUserPlusLockWarningModalDisabled}`} className={classes.disableWarningContainer}>
              <IconButton onClick={() => this.onToggleNotificationStatus()}>
                {isUserPlusLockWarningModalDisabled ? <DisableIcon style={{ fontSize: 22, color: '#e45353' }} /> : <EnableIcon style={{ fontSize: 22, color: '#009688' }} />}
              </IconButton>
            </div>
          </div>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(UsersAndLockLicenseCard);
