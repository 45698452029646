module.exports = {
  SAVE_TICKETS: 'SAVE_TICKETS',
  APPEND_TICKETS: 'APPEND_TICKETS',
  SET_TICKETS_PAGINATION_DATA: 'SET_TICKETS_PAGINATION_DATA',
  RESET_TICKETS_PAGINATION_DATA: 'RESET_TICKETS_PAGINATION_DATA',
  SELECT_TICKET: 'SELECT_TICKET',
  RESET_TICKETS_FILTERS: 'RESET_TICKETS_FILTERS',
  SET_TICKET_FILTER: 'SET_TICKET_FILTER',
  SAVE_TICKET_MANAGERS: 'SAVE_TICKET_MANAGERS',
  UPDATE_TICKET: 'UPDATE_TICKET',
  SET_TICKET_OPERATIONAL_MODE: 'SET_TICKET_OPERATIONAL_MODE',
  SAVE_TICKET_CATEGORIES: 'SAVE_TICKET_CATEGORIES',
  UPDATE_TICKET_CATEGORY: 'UPDATE_TICKET_CATEGORY',
  SET_TICKET_CATEGORIES_FILTERS: 'SET_TICKET_CATEGORIES_FILTERS',
  RESET_TICKET_CATEGORIES_FILTERS: 'RESET_TICKET_CATEGORIES_FILTERS',
  SET_TICKET_ORDER: 'SET_TICKET_ORDER',
};
