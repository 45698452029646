import { Card, IconButton } from '@material-ui/core';
import { Edit, Warning } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, change, reduxForm, submit } from 'redux-form';
import Immutable from 'seamless-immutable';
import { BLUE, CARD_TYPES, ORANGE, PERMISSIONS, PERMISSION_ENTITIES, RED, VENDORS_LIST } from '../../_config/consts';
import { areSmartLocksSelectedOverlapping, hasFormWritePermission, isLockSupportingRFID, localizeHelpCenterLink } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as UtilsActions from '../../redux/actions/utils.actions';
import AccessProfilesIconCustom from '../CustomIcons/AccessProfilesIconCustom.jsx';
import MDAccordion from '../MDAccordion/MDAccordion.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import TagsSelectorListField from './Elements/TagsSelectorListField.jsx';
import CustomField from './Fields/CustomField.jsx';
import MDSwitchField from './Fields/MDSwitch/MDSwitchField.jsx';
import SelectableField from './Fields/SelectableInput/SelectableField.jsx';
import SelectableRowField from './Fields/SelectableRows/SelectableRowField.jsx';
import TranslatableOption from './Fields/TranslatableOption.jsx';
import GuestNoEmailForm from './GuestNoEmailForm.jsx';
import AdditionalTimeProfileForm from './TimeProfiles/AdditionalTimeProfileForm.jsx';
import DefaultTimeProfileForm from './TimeProfiles/DefaultTimeProfileForm.jsx';
import StandardDeviceValidationForm from './StandardDeviceValidationForm/StandardDeviceValidationForm.jsx';

const validate = (values) => {
  const errors = {};
  if (values) {
    if (!values.deviceId) {
      errors.deviceId = 'required';
    }
    if (!values.type) {
      errors.type = 'required';
    } else if ((values.type === CARD_TYPES.ISEO_CARD || values.type === CARD_TYPES.ISEO_CARD_DESFIRE) && values.deviceId && (!(/^\d+$/.test(values.deviceId)) || _.includes(values.deviceId, ' ')) ) {
      errors.deviceId = 'ISEOcardNumberinvalid';
    } else if (values.type === CARD_TYPES.GENERIC_CARD && values.deviceId && !((/^[0-9A-F]{8}$/i.test(values.deviceId)) || (/^[0-9A-F]{14}$/i.test(values.deviceId)))) {
      errors.deviceId = 'genericCardNumberInvalid';
    } else if (values.type === CARD_TYPES.GENERIC_CARD_DESFIRE && values.deviceId && !(/^[0-9A-F]{14}$/i.test(values.deviceId))) {
      errors.deviceId = 'genericCardNumberInvalid';
    }

    if (!values.guestTags || (values.guestTags && _.isEmpty(values.guestTags))) {
      errors.guestTags = 'required';
    }

    if (!values.lockTags || (values.lockTags && _.isEmpty(values.lockTags))) {
      errors.lockTags = 'required';
    }


    if (!values.credentialTimeframe
        || !values.credentialTimeframe.startDate
        || !values.credentialTimeframe.endDate) {
      errors.credentialTimeframe = 'reservationDateIntevalErrorMessage';
    }
    
    if (values.credentialTimeframe && values.credentialTimeframe.endDate && moment(values.credentialTimeframe.endDate).isAfter('2099-12-31')) {
      errors.credentialTimeframe = 'errorEndDateTooBig';
    }
    
    if (!values.timeIntervalFrom) {
      errors.timeIntervalFrom = 'required';
    }

    if (!values.timeIntervalTo) {
      errors.timeIntervalTo = 'required';
    }
    if (values.timeIntervalTo && values.timeIntervalFrom) {
      const toTime = moment(values.timeIntervalTo);
      const fromTime = moment(values.timeIntervalFrom);
      const toDate = moment(0).hour(toTime.hour()).minute(toTime.minute()).second(0);
      const fromDate = moment(0).hour(fromTime.hour()).minute(fromTime.minute()).second(0);
      if (toDate.isSameOrBefore(fromDate)) {
        errors.timeIntervalTo = 'Interval not valid';
      }
    }
  }

  return errors;
};

const onRenderTimeProfiles = (props) => {
  const { fields, onInitializeNewTimeRange, areSelectedSmartLocksOverlappingError, limitedTimeProfileNumber, isArgoMultiTimeProfileActive, availableLockTags, locksFilteredByTags, onFetchMoreLocks, onLockTagsChange, onLockInputChange, onLockFilterModeChange, areDeviceDatesLocked, canEdit, formData, is12HoursFormat, onDeleteTimeProfileRequest, onInitializeNewTimeProfile } = props;
  const numberOfTimeProfile = _.size(fields);
  const filteredAvailableLockTags = isArgoMultiTimeProfileActive ? _.filter(_.cloneDeep(availableLockTags), tag => tag.type === 'lock') : availableLockTags;
  return (
    <div>
      {fields && fields.map((timeProfile, index) => {
      const locksHaveError = locksFilteredByTags && locksFilteredByTags[index+1] && locksFilteredByTags[index+1].data ? _.find(locksFilteredByTags[index+1].data, smartLock => !isLockSupportingRFID(smartLock)) : false;
        return (
          <div key={`timeProfile${index + 1}`}>
            <MDAccordion
              title={<div>
                <Entity key={index+1} entity="timeProfileNumber" data={{ number: index + 2 }} />
                <IconButton
                  style={{ marginLeft: 10, marginBottom: 5 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!formData.isEditing) {
                      fields.remove(index);
                    } else { // if is editing an office mode configuration -> show confirmation modal
                      onDeleteTimeProfileRequest(index);
                    }
                  }}
                >
                  <DeleteIcon style={{ color: RED, fontSize: 20 }} />
                </IconButton>
              </div>}
              containerstyle={{ marginTop: 10 }}
              innerContainerStyle={{ backgroundColor: '#fdfdfd' }}
              defaultExpanded
              titleStyle={{ fontSize: 25 }}
            >
              <AdditionalTimeProfileForm
                formName="CardCredentialForm"
                timeProfile={timeProfile}
                timeProfileIndex={index}
                areDeviceDatesLocked={areDeviceDatesLocked}
                isArgoMultiTimeProfileActive={isArgoMultiTimeProfileActive}
                canEdit={canEdit}
                formData={formData && formData.additionalTimeProfiles ? formData.additionalTimeProfiles[index] : null}
                is12HoursFormat={is12HoursFormat}
                availableLockTags={Immutable(filteredAvailableLockTags)}
                areSelectedSmartLocksOverlappingError={areSelectedSmartLocksOverlappingError}
                locksFilteredByTags={locksFilteredByTags && _.size(locksFilteredByTags) > index + 1 ? locksFilteredByTags[index + 1] : undefined}
                onFetchMoreLocks={page => onFetchMoreLocks(page, index + 1)}
                onLockTagsChange={values => onLockTagsChange(values, index + 1)}
                onLockInputChange={value => onLockInputChange(value, index + 1)}
                lockHasErrorCondition={lock => !isLockSupportingRFID(lock)}
                hasSelectedIncompatibleLocks={locksHaveError}
                onLockFilterModeChange={values => onLockFilterModeChange(values, index + 1)}
                onInitializeNewTimeRange={(timeRangeId, fieldName) => onInitializeNewTimeRange(timeRangeId, fieldName)}
              />
            </MDAccordion>
          </div>
        )
      })}
      {numberOfTimeProfile < limitedTimeProfileNumber ? (
        <Card
          style={{ height: 60, marginBottom: 20, marginTop: 10, padding: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          className="card card-rounded"
          onClick={() => {
            fields.push({});
            onInitializeNewTimeProfile(numberOfTimeProfile);
          }}
        >
          <AddIcon style={{ color: '#4DB6AC', marginRight: 15, fontSize: 25 }} />
          <h4 style={{ fontWeight: 'bold' }}><Entity entity="newTimeProfile" /></h4>
        </Card>
      ) : null}
    </div>
  );
};


const accessProfileOptions = [
  {},
  {
    id: -1,
    customOptionElement: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -5 }}>
        <Edit style={{ color: '#3f3f3f', marginRight: 10, fontSize: 22 }} />
        <h4 style={{ color: '#3f3f3f', fontWeight: 'bold' }}><Entity entity="customProfile" /></h4>
      </div>
    ),
  },
];

@reduxForm({ form: 'CardCredentialForm', validate })
@connect(state => ({ form: state.form.CardCredentialForm }))
class CardCredentialForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cardIdsMapState: props.cardIdsMap,
      showGuestNoEmailForm: false,
    };
  }

  async onFilterCardIds(cardId) {
    const { cardIdsMap } = this.props;
    const filteredIds = _.filter(cardIdsMap, card => card.originalId.includes(cardId));
    this.setState({ cardIdsMapState: filteredIds });
    return filteredIds;
  }

  onSelectCardIdToTranslate(selectedCard) {
    const { dispatch } = this.props;
    if (selectedCard) {
      const luckeyId = selectedCard && selectedCard && selectedCard.value && selectedCard.value.luckeyCardId;
      dispatch(change('CardCredentialForm', 'deviceId', luckeyId));
    } else {
      dispatch(change('CardCredentialForm', 'deviceId', ''));
    }
  }

  onToggleShowGuestNoEmailForm(value) {
    this.setState({ showGuestNoEmailForm: value });
  }

  onOpenInfoValidation() {
    const helpURL = localizeHelpCenterLink('rfid-cards-validation-period');
    window.open(helpURL);
  }

  onShowCardTypeInfo() {
    const helpURL = localizeHelpCenterLink('supported-rfid-cards');
    window.open(helpURL);
  }

  render() {
    const {
      form,
      availableLockTags,
      availableUserTags,
      onGuestTagsChange,
      onLockTagsChange,
      guestsFilteredByTags,
      locksFilteredByTags,
      onLockFilterModeChange,
      onGuestInputChange,
      onLockInputChange,
      onFetchMoreGuests,
      onFetchMoreLocks,
      onDeleteCard,
      onSaveCardNotes,
      onVerifyDeviceId,
      isCardMappingEnabled,
      cardIdsMap,
      cardTypes,
      formData,
      is12HoursFormat,
      onInitializeNewTimeProfile,
      onInitializeNewTimeRange,
      dispatch,
      onCreateAnonymousUserForCard,
      systemHasV364Locks,
      onSelectAccessProfile,
      accessProfilesData
    } = this.props;
    const { cardIdsMapState, showGuestNoEmailForm } = this.state;
    const selectedType = form && form.values && form.values.type;
    const notes = form && form.values && form.values.notes ? form.values.notes : '';
    const guestTags = form && form.values && form.values.guestTags;
    const areDeviceDatesLocked = form && form.values && form.values.areDeviceDatesLocked ? form.values.areDeviceDatesLocked : null;
    const plantMaster = form && form.values && form.values.plantMaster ? form.values.plantMaster : null;
    const isEditing = form && form.values && form.values.deviceBackendId;
    const canDeleteStandardDevices = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.STANDARD_DEVICE);
    const canReadUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const isAdditionalTimeProfileActive = dispatch(UtilsActions.isMultiTimeProfileActive());
    const isArgoAdditionalTimeProfileActive = dispatch(UtilsActions.isArgoMultiTimeProfileActive());
    const isAccessProfilesActive = dispatch(UtilsActions.isAccessProfileAddonActive());
    const areSelectedSmartLocksOverlapping = isArgoAdditionalTimeProfileActive ? areSmartLocksSelectedOverlapping(locksFilteredByTags) : false;
    const hideGuestNoEmailSwitch = isEditing || !_.isEmpty(guestTags);
    const selectedGuestAccessProfileId = guestsFilteredByTags && guestsFilteredByTags.data && guestsFilteredByTags.data[0] ? guestsFilteredByTags.data[0].accessProfileId : null;
    const selectedGuestAccessProfile = selectedGuestAccessProfileId ? _.find(accessProfilesData, { id: selectedGuestAccessProfileId }) : null;
    const isUsingAccessProfile = form && form.values && form.values.accessProfileSelection && form.values.accessProfileSelection === selectedGuestAccessProfileId;
    const hasSelectedV364Locks =  locksFilteredByTags && locksFilteredByTags[0] && locksFilteredByTags[0].data && _.find(locksFilteredByTags[0].data, smartLock => smartLock.vendor === VENDORS_LIST.ISEO_VEGA);
    const locksHaveError = locksFilteredByTags && locksFilteredByTags[0] && locksFilteredByTags[0].data ? _.find(locksFilteredByTags[0].data, smartLock => !isLockSupportingRFID(smartLock)) : false;
    if (selectedGuestAccessProfile && _.size(accessProfileOptions) <= 2) {
      accessProfileOptions[0] = {
        id: selectedGuestAccessProfile.id,
        customOptionElement: (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -5 }}>
            <AccessProfilesIconCustom style={{ color: '#3f3f3f', marginRight: 10, width: 22 }} />
            <h4 style={{ color: '#3f3f3f', fontWeight: 'bold' }}>{selectedGuestAccessProfile.name}</h4>
          </div>
        ),
      };
    }

    return (
      <Form>
        <FormCardContainer
          title="cardData"
          headerStyle={{ marginBottom: 20 }}
        >
          <Field
            id="type"
            name="type"
            title={<Entity entity="cardType" />}
            component={CustomField}
            helpText={(
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10 }} onClick={() => this.onShowCardTypeInfo()}>
                <InfoIcon style={{ marginRight: 5, color: 'darkblue' }} />
                <h3 className="link-label" style={{ color: 'darkblue', fontWeight: '100', fontSize: 13, margin: 0 }}>{<Entity entity="cardTypeHelp" />}</h3>
              </div>
            )}
            mandatory
            className="form-control-select"
            componentClass="select"
            disabled={isEditing}
          >
            {_.map(cardTypes, (type, index) =>
              <Entity
                key={type}
                componentClass={TranslatableOption}
                value={type}
                componentAttribute="text"
                entity="cardTypes"
                data={{ type }}
              />)
            }
          </Field>

          {isCardMappingEnabled && selectedType === cardTypes.GENERIC_CARD && !_.isEmpty(cardIdsMap) ? (
            <Field
              name="originalCardId"
              title={<Entity entity="insertCardOriginalId" />}
              component={SelectableField}
              options={_.map(cardIdsMapState, card => ({ value: card, label: card.originalId }))}
              titleStyle={{ fontSize: 16 }}
              helpText={<Entity entity="insertCardOriginalIdDescription" />}
              placeholder={<Entity entity="search" />}
              disabled={form && form.values && form.values.deviceBackendId}
              containerstyle={{ minWidth: 300, marginRight: 20, marginBottom: 20 }}
              onInputChange={value => this.onFilterCardIds(value)}
              onSelect={value => this.onSelectCardIdToTranslate(value)}
            />
          ) : null}
          <Entity
            componentClass={Field}
            name="deviceId"
            componentAttribute="placeholder"
            entity="insertCardNumber"
            title={<Entity key={selectedType} entity="cardFieldTitle" data={{ type: selectedType || selectedType === cardTypes.GENERIC_CARD || selectedType === cardTypes.GENERIC_CARD_DESFIRE }} />}
            component={CustomField}
            helpText={isEditing ? null : <Entity key={selectedType} entity="cardFieldHelpText" data={{ type: selectedType || cardTypes.GENERIC_CARD || selectedType === cardTypes.GENERIC_CARD_DESFIRE }} />}
            textFormattingFunction={value => value.replace(/\s+/g, '')}
            type={form && form.values && (form.values.type === cardTypes.GENERIC_CARD || form.values.type === cardTypes.GENERIC_CARD_DESFIRE) ? 'text' : 'number'}
            mandatory
            disabled={form && form.values && form.values.deviceBackendId}
            onInputBlur={(value) => onVerifyDeviceId(value)}
          />
          
        </FormCardContainer>
        <FormCardContainer
          title="cardDescription"
          subtitle="cardDescriptionSubtitle"
        >
          <Entity
            componentClass={Field}
            name="notes"
            componentAttribute="placeholder"
            entity="insertCardDescription"
            component={CustomField}
            type="textarea"
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
            className="form-control-custom"
          />
          {isEditing && hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing) && (
            <MDButton
              title={<Entity entity="saveNotes" />}
              backgroundColor={BLUE}
              onClick={() => onSaveCardNotes(notes)}
            />
          )}
        </FormCardContainer>
        <FormCardContainer
          title="cardTagGuestTitle"
          subtitle="cardTagGuestSubtitle"
        >
          <TagsSelectorListField
            name="guestTags"
            formName="CardCredentialForm"
            selectedTagsFieldName="selectedGuestTags"
            tags={availableUserTags}
            selectedTags={guestsFilteredByTags}
            form={form}
            tagsType="GUEST"
            title="guestTagsCard"
            placeholder="insertGuestTagsForCard"
            listTitle="selectedUsers"
            emptyTagSelectionText={canReadUser ? 'noUsersForSelectedTag' : null}
            onFetchMoreItems={page => onFetchMoreGuests(page)}
            onTagsChange={values => onGuestTagsChange(values)}
            onInputChange={value => onGuestInputChange(value)}
            isSingleChoice
            disabled={!hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
          />
          {hideGuestNoEmailSwitch ? null : (
            <Field
              name="withoutEmail"
              label={<Entity entity="createNewUserForCard" />}
              titleStyle={{ fontSize: 16, color: '#3f3f3f', fontWeight: 'bold' }}
              containerstyle={{ marginTop: 10 }}
              component={MDSwitchField}
              activeText="createNewUserForCardWarning"
              activeTextIcon={<Warning style={{ color: ORANGE, marginRight: 10, marginTop: 0, fontSize: 20 }} />}
              onHandleChange={value => this.onToggleShowGuestNoEmailForm(value)}
            />
          )}
          {showGuestNoEmailForm && !hideGuestNoEmailSwitch ? (
            <div>
              <GuestNoEmailForm
                onSubmit={(guestData) => onCreateAnonymousUserForCard(guestData)}
              />
              <MDButton
                title={<Entity entity="createUser" />}
                backgroundColor={BLUE}
                disabled={!hasFormWritePermission(PERMISSION_ENTITIES.USER, isEditing)}
                onClick={() => dispatch(submit('GuestNoEmailForm'))}
              />
            </div>
          ) : null}
        </FormCardContainer>
        <FormCardContainer
          title={isAdditionalTimeProfileActive ? "mainTimeProfileTitle" : "defaultTimeProfileTitle"}
          subtitle="defaultTimeProfileSubtitle"
          >
          {selectedGuestAccessProfile && isAccessProfilesActive ? (
            <div>
              <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', marginBottom: 0, marginTop: 20 }}>{<Entity entity="selectAccessProfileOrCustom" />}</h4>
            <Field
              name="accessProfileSelection"
              component={SelectableRowField}
              onHandleChange={value => onSelectAccessProfile(value)}
              isSingleChoice
              rowStyle={{ maxHeight: 55, maxWidth: '80%' }}
              options={{
                content: accessProfileOptions,
              }}
              listContainerStyle={{ marginTop: 0 }}
              listStyle={{ marginTop: 0 }}
            />
            </div>
          ) : null}
          <DefaultTimeProfileForm
            formData={formData}
            form={form}
            formName="CardCredentialForm"
            is12HoursFormat={is12HoursFormat}
            onInitializeNewTimeProfile={timeProfileId => onInitializeNewTimeProfile(timeProfileId)}
            onInitializeNewTimeRange={(timeRangeId, fieldName) => onInitializeNewTimeRange(timeRangeId, fieldName)}
            areDeviceDatesLocked={areDeviceDatesLocked}
            canEdit={hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing) && !isUsingAccessProfile}
            availableLockTags={availableLockTags}
            locksFilteredByTags={locksFilteredByTags && locksFilteredByTags[0]}
            onFetchMoreLocks={page => onFetchMoreLocks(page)}
            lockHasErrorCondition={lock => !isLockSupportingRFID(lock)}
            locksHaveError={locksHaveError}
            onLockTagsChange={values => onLockTagsChange(values)}
            onLockInputChange={value => onLockInputChange(value)}
            onLockFilterModeChange={values => onLockFilterModeChange(values)}
          />
        </FormCardContainer>
        {(isAdditionalTimeProfileActive && systemHasV364Locks) || isArgoAdditionalTimeProfileActive ? (
          <FieldArray
            name="additionalTimeProfiles"
            component={onRenderTimeProfiles}
            formData={formData}
            form={form}
            is12HoursFormat={is12HoursFormat}
            limitedTimeProfileNumber={isArgoAdditionalTimeProfileActive ? 1 : 5}
            isArgoMultiTimeProfileActive={isArgoAdditionalTimeProfileActive}
            areDeviceDatesLocked={areDeviceDatesLocked}
            canEdit={hasFormWritePermission(PERMISSION_ENTITIES.STANDARD_DEVICE, isEditing)}
            availableLockTags={availableLockTags}
            areSelectedSmartLocksOverlappingError={areSelectedSmartLocksOverlapping}
            locksFilteredByTags={locksFilteredByTags}
            onFetchMoreLocks={(page, index) => onFetchMoreLocks(page, index)}
            onLockTagsChange={(values, index) => onLockTagsChange(values, index)}
            onLockInputChange={(value, index) => onLockInputChange(value, index)}
            onLockFilterModeChange={(values, index) => onLockFilterModeChange(values, index)}
            onInitializeNewTimeProfile={programId => onInitializeNewTimeProfile(programId)}
            onInitializeNewTimeRange={(timeRangeId, fieldName) => onInitializeNewTimeRange(timeRangeId, fieldName)}
          />
        ) : null}
        {!isEditing && systemHasV364Locks && hasSelectedV364Locks ? (
          <StandardDeviceValidationForm
            form={form}
            formName="CardCredentialForm"
            onInfo={() => this.onOpenInfoValidation()}
          />
        ) : null}
        {onDeleteCard && canDeleteStandardDevices && (
          <FormCardContainer
            title="deleteCredential"
            subtitle="deleteCredentialCardWarning"
          >
            <MDButton
              title={<Entity entity="deleteCard" />}
              backgroundColor={RED}
              onClick={() => onDeleteCard(plantMaster)}
            />
          </FormCardContainer>
        )}
      </Form>
    );
  }
} 

export default CardCredentialForm;
