import moment from 'moment';
import Immutable from 'seamless-immutable';

import {
  SAVE_METRIC,
  RESET_METRICS,
  SAVE_ACCESS_CHART_DATA,
  SAVE_ACCESS_CHART_DATA_CAPACITY_MANAGEMENT,
  SAVE_ACCESS_CHART_USERS,
  SAVE_ACCESS_CHART_USERS_CAPACITY_MANAGEMENT,
  SAVE_ACCESS_CHART_LOCKS,
  SAVE_LOCKS_BATTERY_CHART_DATA,
  SAVE_ACCESS_CHART_FILTER,
  RESET_ACCESS_CHART_FILTER,
  SAVE_ACCESSORIES_CHART_DATA,
  SAVE_BOOKEY_ANALYTICS_CARDS,
  SAVE_BOOKEY_ANALYTICS_RESOURCES,
  SAVE_BOOKEY_ANALYTICS_USERS,
  SAVE_BOOKEY_ANALYTICS_RESERVATIONS,
  SAVE_BOOKEY_USERS,
  RESET_BOOKEY_USERS,
  SET_BOOKEY_USERS_LOADING,
  SAVE_ACCESS_CHART_ACTIVE_VISITORS
} from '../actions/actionTypes/metrics';

const initialState = Immutable({
  data: {
    smartLocksEvents: {
      data: [],
    },
    adminEvents: {
      data: [],
    },
    guests: null,
    smartLocks: null,
    standardDevices: null,
    hyperKeys: null,
    pins: null,
    smartPhoneRules: null,
  },
  accessoriesChartData: {
    data: [],
  },
  locksBatteryChart: {
    data: [],
    lastUpdate: 0,
  },
  accessChartData: {
    data: [],
    lastUpdate: 0,
    users: [],
    visitors: [],
    locksChartData: [],
    filters: {
      startDate: 0,
      endDate: 0,
    },
  },
  accessChartDataCapacityManagement: {
    data: [],
    lastUpdate: 0,
    users: [],
  },
  bookeyAnalytics: {
    cards: {
      totalReservations: null,
      totalHoursSuccessful: null,
      totalHoursWithPenalty: null,
      totalDeletedReservations: null,
      totalHoursLost: null,

      totalReservationsPrev: null,
      totalHoursSuccessfulPrev: null,
      totalHoursWithPenaltyPrev: null,
      totalDeletedReservationsPrev: null,
      totalHoursLostPrev: null,

      totalReservationsPrevPerc: null,
      totalHoursSuccessfulPrevPerc: null,
      totalHoursWithPenaltyPrevPerc: null,
      totalDeletedReservationsPrevPerc: null,
      totalHoursLostPrevPerc: null,
    },
    resources: {
      resourcesUsagePieChartData: null,

      resourceTypesParsed: null,
      resourceTypesParsedPrev: null,

      topResources: null,
      topResourcesPrev: null,
      
      resourceTypesReservationTimeChartData: null,
      resourceTypesUtilizationChartData: null,
      resourceTypesReservationTimeChartDataPrev: null,
      resourceTypesUtilizationChartDataPrev: null,

      resourceReservationTimeChartData: null,
      resourceUtilizationChartData: null,
      resourceReservationTimeChartDataPrev: null,
      resourceUtilizationChartDataPrev: null,
    },
    users: {
      topUsers: null,
    },
    usersDetails: [],
    usersDetailsLoading: false,
    reservations: {
      reservationsHourlyChartData: null,
      hoursHourlyChartData: null,
      reservationsHourlyChartData_avg: null,
      hoursHourlyChartData_avg: null,
  
      reservationsHourlyChartDataPrev: null,
      hoursHourlyChartDataPrev: null,
      reservationsHourlyChartData_avgPrev: null,
      hoursHourlyChartData_avgPrev: null,

      reservationsDayChartData: null,
      hoursDayChartData: null,
      reservationsDayChartData_avg: null,
      hoursDayChartData_avg: null,

      reservationsDayChartDataPrev: null,
      hoursDayChartDataPrev: null,
      reservationsDayChartData_avgPrev: null,
      hoursDayChartData_avgPrev: null,

      reservationsMonthChartData: null,
      hoursMonthChartData: null,
      reservationsMonthChartData_avg: null,
      hoursMonthChartData_avg: null,

      reservationsMonthChartDataPrev: null,
      hoursMonthChartDataPrev: null,
      reservationsMonthChartData_avgPrev: null,
      hoursMonthChartData_avgPrev: null,
      
      reservationsPieChartData: null,
    }
  }
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_METRIC:
      return state.setIn(['data', action.metricName], action.metricValue);
    case SAVE_ACCESS_CHART_DATA:
      return state.setIn(['accessChartData', 'data'], action.chartData).setIn(['accessChartData', 'lastUpdate'], action.lastUpdate);
    case SAVE_ACCESS_CHART_DATA_CAPACITY_MANAGEMENT:
      return state.setIn(['accessChartDataCapacityManagement', 'data'], action.chartData).setIn(['accessChartData', 'lastUpdate'], action.lastUpdate);
    case SAVE_ACCESS_CHART_USERS:
      return state.setIn(['accessChartData', 'users'], action.users);
    case SAVE_ACCESS_CHART_USERS_CAPACITY_MANAGEMENT:
      return state.setIn(['accessChartDataCapacityManagement', 'users'], action.users);
    case SAVE_ACCESS_CHART_LOCKS:
      return state.setIn(['accessChartData', 'locksChartData'], action.locks);
    case SAVE_ACCESS_CHART_ACTIVE_VISITORS:
    return state.setIn(['accessChartData', 'visitors'], action.visitors);
    case SAVE_ACCESS_CHART_FILTER:
      return state.setIn(['accessChartData', 'filters', action.field], action.value);
    case SAVE_ACCESSORIES_CHART_DATA:
      return state.setIn(['accessoriesChartData', 'data'], action.data);
    case RESET_ACCESS_CHART_FILTER:
      return state.setIn(['accessChartData', 'filters'], initialState.accessChartData.filters);
    case SAVE_LOCKS_BATTERY_CHART_DATA:
      return state.setIn(['locksBatteryChart', 'data'], action.batteryChartData).setIn(['locksBatteryChart', 'lastUpdate'], action.lastUpdate);
    case SAVE_BOOKEY_ANALYTICS_CARDS:
      return state.setIn(['bookeyAnalytics', 'cards'], action.data);
    case SAVE_BOOKEY_ANALYTICS_RESOURCES:
      return state.setIn(['bookeyAnalytics', 'resources'], action.data);
    case SAVE_BOOKEY_ANALYTICS_USERS:
      return state.setIn(['bookeyAnalytics', 'users'], action.data);
    case SAVE_BOOKEY_ANALYTICS_RESERVATIONS:
      return state.setIn(['bookeyAnalytics', 'reservations'], action.data);
    case SAVE_BOOKEY_USERS:
      return state.setIn(['bookeyAnalytics', 'usersDetails'],[...state.bookeyAnalytics.usersDetails, ...action.users])
    case RESET_BOOKEY_USERS:
      return state.setIn(['bookeyAnalytics', 'usersDetails'], []);
    case SET_BOOKEY_USERS_LOADING:
      return state.setIn(['bookeyAnalytics', 'usersDetailsLoading'],action.loading)
    case RESET_METRICS:
      return initialState;
    default:
      return state;
  }
}
