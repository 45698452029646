import Immutable from 'seamless-immutable';
import _ from 'lodash';

import {
  SAVE_PLATFORM_USERS,
  SAVE_PLATFORM_USERS_PAGINATION_DATA,
  APPEND_PLATFORM_USERS,
  SET_SELECTED_PLATFORM_USER,
  SET_PLATFORM_USERS_OPERATIONAL_MODE,
  REMOVE_PLATFORM_USER,
  UPDATE_PLATFORM_USER,
  SAVE_PLATFORM_USER,
  SET_PLATFORM_USERS_FILTER,
  RESET_PLATFORM_USERS_FILTERS,
  RESET_PLATFORM_USERS_DATA,
  RESET_PLATFORM_USERS_PAGINATION_DATA,
  SET_PLATFORM_USERS_ORDER,
} from '../actions/actionTypes/platformUsers';

const initialState = Immutable({
  data: {
    content: [],
    pagination: {},
    filters: {
      roleIds: [1], // Default filter value it's manager
    },
    sorting: {
      orderBy: undefined,
      orderDir: undefined,
    }
  },
  selectedUser: {},
  isOperationalMode: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_PLATFORM_USERS:
      return state.setIn(['data', 'content'], action.users);
    case APPEND_PLATFORM_USERS:
      return state.setIn(['data', 'content'], [...state.data.content, ...action.users]);
    case RESET_PLATFORM_USERS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], {});
    case SAVE_PLATFORM_USER:
      return state.setIn(['data', 'content'], [action.user, ...state.data.content]);
    case SAVE_PLATFORM_USERS_PAGINATION_DATA:
      return state.setIn(['data', 'pagination'], action.pagination);
    case SET_SELECTED_PLATFORM_USER:
      return state.set('selectedUser', action.user);
    case REMOVE_PLATFORM_USER: {
      const users = _.filter(state.data.content, user => user.id !== action.userId);
      return state.setIn(['data', 'content'], users);
    }
    case UPDATE_PLATFORM_USER: {
      const index = _.findIndex(state.data.content, data => data.id === action.user.id);
      if (index !== -1) {
        return state.setIn(['data', 'content', index], action.user);
      }
      return state;
    }
    case SET_PLATFORM_USERS_OPERATIONAL_MODE:
      return state.set('isOperationalMode', action.value);
    case SET_PLATFORM_USERS_FILTER:
      return state.setIn(['data', 'filters', action.field], action.value);
    case SET_PLATFORM_USERS_ORDER:
      return state.setIn(['data', 'sorting', 'orderBy'], action.orderBy)
                  .setIn(['data', 'sorting', 'orderDir'], action.orderDir);
    case RESET_PLATFORM_USERS_FILTERS:
      return state.setIn(['data', 'filters'], initialState.filters);
    case RESET_PLATFORM_USERS_DATA:
      return state.set('data', initialState.data);
    default:
      return state;
  }
}
